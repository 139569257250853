import React from 'react';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import cn from 'classnames';
import { renderDatePicker } from '../../../../../../../../components/ReduxForm';
import { SYSTEM_EPOCH } from '../../../../../../../../services/constants/constants';
import { addDays } from '../../../../../../../../services/utils/DateHelpers';
import moment from 'moment';

type Props = {
  onChange?(val: string): void;
  name?: string;
  label?: string;
  maxDaysAhead?: number;
  className?: string;
};

const TradeTime = ({ onChange, name = 'tradeTime', label = 'Transaction date', maxDaysAhead = 0, className }: Props) => {
  return (
    <FormFieldWrapper label={label} required={true} className={cn(className, s.minField)}>
      <Field
        defaultValue={moment().subtract(1, 'days')}
        name={name}
        hideWeekends={false}
        className={s.datePicker}
        component={renderDatePicker}
        placeholder={'Date'}
        minDate={SYSTEM_EPOCH}
        maxDate={addDays(maxDaysAhead, new Date())}
        onChange={(e: any) => onChange?.(e)}
      />
    </FormFieldWrapper>
  );
};

export default TradeTime;
