import cn from 'classnames';
import React, { Fragment, PureComponent } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Button, DropDown } from '../../../../../components/UIWidgets';

import { PATHS } from '../../../../../router/paths';

interface IProps {
  instrument: IInstrument;
  onRemove: (instrument: IInstrument) => void;
}

class InstrumentActionsCell extends PureComponent<IProps> {
  renderDropDownContent = (instrument: IInstrument): React.ReactNode => {
    const { onRemove } = this.props;
    return (
      <Fragment>
        <Link
          to={generatePath(PATHS.instruments.editInstrument.path, { instrumentId: instrument.id })}
          className={'drop-down__item'}
        >
          <Button variant="empty">Edit</Button>
        </Link>
        <Button
          onClick={() => {
            onRemove(instrument);
          }}
          variant="empty"
          className={cn('drop-down__item', 'button--remove')}
        >
          Remove
        </Button>
      </Fragment>
    );
  };

  render() {
    const { instrument } = this.props;
    return <DropDown>{this.renderDropDownContent(instrument)}</DropDown>;
  }
}

export default InstrumentActionsCell;
