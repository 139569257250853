import { ReduxHelper } from '../utils/ReduxHelper';

import { getApiUrl } from '../constants/endpoints';

export class RiskAssetClassesAction {
  static readonly RISK_ASSET_CLASSES_REQUEST = 'RISK_ASSET_CLASSES_REQUEST';
  static readonly RISK_ASSET_CLASSES_SUCCESS = 'RISK_ASSET_CLASSES_SUCCESS';
  static readonly RISK_ASSET_CLASSES_ERROR = 'RISK_ASSET_CLASSES_ERROR';

  static fetchAllRiskAssetClasses() {
    return ReduxHelper.createHttpRequestActionBundle<ISector[]>(
      {
        url: getApiUrl('riskassetclass.all'),
      },
      RiskAssetClassesAction.RISK_ASSET_CLASSES_REQUEST,
      RiskAssetClassesAction.RISK_ASSET_CLASSES_SUCCESS,
      RiskAssetClassesAction.RISK_ASSET_CLASSES_ERROR
    );
  }
}
