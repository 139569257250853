import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IRootState } from '../../services/store';
import cn from 'classnames';
import s from './PortfolioTransfer.module.css';
import { PortfolioActions } from '../../services/actions';
import PortfoliosList from './components/PortfoliosList';
import UserList from './components/UserList';
import { Loader } from '../../components';
import { getCustomApiUrl } from '../../services/constants/endpoints';
import { toast } from 'react-toastify';
import { sendRequest } from '../../services/hooks/useApi';

const PortfolioAssign = () => {
  const match = useRouteMatch<{ portfolioId: string }>();
  const { portfolioInfo } = useSelector(createSelector(mapStateToProps, (st) => st));
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
  const [includeAssigned, setIncludeAssigned] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    dispatch(PortfolioActions.fetchPortfolioInfo(match.params.portfolioId));
  }, [match.params.portfolioId]);

  const { isFetching: isPortfolioFetching, data: portfolio } = portfolioInfo;

  const submit = () => {
    const updateUrl = getCustomApiUrl('workgroups.assign', [
      { idLabel: ':workgroupId', idValue: `${portfolio?.workgroup?.id}` },
      { idLabel: ':clientId', idValue: `${selectedUsers[0]}` },
    ]);
    sendRequest(updateUrl, { method: 'PUT' })
      .then(() => {
        toast.success('Successfully assigned portfolio');
        window.history.back();
      })
      .catch((error) => {
        toast.error('An error occurred whilst trying to assign portfolio');
        console.log({ error });
      });
  };

  const workgroup = portfolio?.workgroup;

  const onSelectUser = (user: IAccountUser) => {
    setSelectedUsers([user.id ?? 'unknown']);
  };

  const toggleIncludeAssigned = () => setIncludeAssigned((prev) => !prev);

  return (
    <div style={{ width: 500 }}>
      <h1>Client assignment</h1>
      <div style={{ height: '2rem' }} />
      {isPortfolioFetching ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={cn(s.row)}>
            <div className={cn(s.rowLabel)}>Client Account:</div>
            <div>{workgroup?.name}</div>
          </div>
        </React.Fragment>
      )}

      <div className={cn(s.info)}>Assign Portfolios in client account {workgroup?.name} to:</div>

      <UserList
        role={'CUSTOMER'}
        selectedUsers={selectedUsers}
        onSelectUser={onSelectUser}
        includeAssigned={includeAssigned}
      />
      <div className={cn(s.checkboxSurround)}>
        <input
          type="checkbox"
          checked={includeAssigned}
          onChange={toggleIncludeAssigned}
          className={cn(s.checkbox)}
          // name={"includeAssigned"}
        />

        <span onClick={toggleIncludeAssigned}>Include clients with existing portfolios</span>
      </div>

      <div style={{ height: '2rem' }} />
      <PortfoliosList />

      <div className={cn(s.buttonSurround)}>
        <div onClick={() => history.push('/portfolios')} className={cn(s.cancelButton)}>
          Cancel
        </div>
        <button disabled={!selectedUsers.length} onClick={submit}>
          Confirm
        </button>
      </div>

      {/*<JSONTree data={{ portfolioInfo, portfoliosInWorkgroup }}  shouldExpandNode={(path) => path[0] === "root" || path[0] === "data" || path[0] === "workgroup"} />*/}
    </div>
  );
};
const mapStateToProps = (state: IRootState) => ({
  portfolioInfo: state.portfolio.portfolioInfo,
});

export default PortfolioAssign;
