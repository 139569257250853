import { IActionPayload } from '../../../models/redux/IActionPayload';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { EquityActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';

const INITIAL_EQUITY_STATE: any = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const equityReducerDefault = ReduxHelper.createHttpRequestWithPeriodReducer<ILoadingPeriodDataState<IBarData[]>>(
  INITIAL_EQUITY_STATE,
  EquityActions.DIVIDENDS_REQUEST,
  EquityActions.DIVIDENDS_SUCCESS,
  EquityActions.DIVIDENDS_ERROR,
  EquityActions.DIVIDENDS_RESET
);

const equityReducers = (state = INITIAL_EQUITY_STATE, action: IActionPayload) => {
  switch (action.type) {
    case EquityActions.DIVIDENDS_CHANGE_PERIOD: {
      return {
        ...state,
        period: action.payload.period,
        currency: action.payload.currency,
      };
    }
    default:
      return equityReducerDefault(state, action);
  }
};

export default equityReducers;
