import React, { PureComponent } from 'react';
import s from './Loader.module.css';

interface IProps {
  color?: string;
}
class Loader extends PureComponent<IProps> {
  render() {
    return (
      <div className={s.loader}>
        <div style={{ border: `2px solid ${this.props.color ?? 'var(--border-color)'}` }} />
        <div style={{ border: `2px solid ${this.props.color ?? 'var(--border-color)'}` }} />
      </div>
    );
  }
}

export default Loader;
