import { combineReducers } from 'redux';
import { ReduxHelper } from '../../utils/ReduxHelper';
import { AlternativesActions } from '../../actions';

import { IBarsChartDataState } from '../../../models/redux/IBarsChartDataState';

const alternativesReducers = combineReducers({
  distribution: ReduxHelper.createHttpRequestReducer<IBarsChartDataState>(
    ReduxHelper.createInitialState([]),
    AlternativesActions.DISTRIBUTION_REQUEST,
    AlternativesActions.DISTRIBUTION_SUCCESS,
    AlternativesActions.DISTRIBUTION_ERROR,
    AlternativesActions.DISTRIBUTION_RESET
  ),
});

export default alternativesReducers;
