import cn from 'classnames';
import React, { Fragment, FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Loader } from '../../../../../components/UIWidgets/Loader';
import s from '../WidgetsBlock.module.css';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../components/ContextHelp';

export interface IWidgetProps {
  colNum?: number;
  className?: string;
  full?: boolean;
  isDataLoaded?: boolean;
  title?: string;
  subtitle?: string;
  titleInfo?: string;
  renderHeaderTools?: () => ReactNode;
}

type IProps = IWidgetProps;

const Widget: FunctionComponent<PropsWithChildren<IProps>> = ({
  className,
  full,
  title,
  titleInfo,
  subtitle,
  isDataLoaded = true,
  renderHeaderTools,
  colNum = 6,
  children,
}) => {
  return (
    <div
      style={!full ? { gridColumn: `span ${colNum}` } : {}}
      className={cn(s.widgetItem, className, {
        [s.widgetItemFull]: full,
      })}
    >
      {(title || renderHeaderTools) && (
        <header className={s.widgetsHeader}>
          <h3>
            {title} <small>{subtitle}</small>
            {titleInfo === undefined ? (
              ''
            ) : titleInfo.length === 0 ? (
              ''
            ) : (
              <ContextHelpIcon title={title || ''} elementId={title || 'tmpID'} />
            )}
          </h3>
          <div className={s.widgetsHeaderTools}>{renderHeaderTools ? renderHeaderTools() : <Fragment />}</div>
        </header>
      )}
      <div className={s.widgetsContent}>{isDataLoaded ? children : <Loader />}</div>
      <ContextHelpTooltip elementId={title || 'tmpID'} tooltipTitle={title} tooltipText={titleInfo} />
    </div>
  );
};

export default React.memo(Widget);
