import { Popover, Whisper, Button } from 'rsuite';
import React, { FC, MutableRefObject, useRef } from 'react';
import s from './PopoverDropdown.module.scss';
import cn from 'classnames';
import { isSafari } from 'react-device-detect';

interface IOwnProps {
  placeholderContent?: React.ReactNode;
  parentRef?: React.RefObject<HTMLDivElement>;
  [x: string]: any;
}

const Speaker = ({ content, ...props }: any) => {
  return <Popover {...props}>{content}</Popover>;
};

const PopoverDropdown: FC<IOwnProps> = React.forwardRef(
  ({ placeholderContent, parentRef, ...props }: IOwnProps, ref: any) => {
    const renderButton = () => {
      if (isSafari) {
        return placeholderContent ? (
          <div className={cn(s.customBtn)}>{placeholderContent}</div>
        ) : (
          <div className={cn(s.dropBtn)} />
        );
      }
      return placeholderContent ? (
        <Button icon={placeholderContent} appearance={'subtle'} className={cn(s.customBtn)}>
          {placeholderContent}
        </Button>
      ) : (
        <Button className={cn(s.dropBtn)} />
      );
    };

    return (
      <div {...props} onClick={(e) => e.preventDefault()}>
        <Whisper
          container={ref?.current}
          trigger="click"
          placement={'auto'}
          speaker={<Speaker content={props.children} />}
        >
          {renderButton()}
        </Whisper>
      </div>
    );
  }
);

export default PopoverDropdown;
