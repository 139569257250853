import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { currencyValueFormatter } from '../../../../../../../../services/utils/CurrencyHelpers';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

interface IProps {
  loadOptions: any;
  disabled?: boolean;
  instrument: IOption<IAsset>;
}

const OptionUnderlying: FC<IProps> = ({ loadOptions, instrument, disabled }) => {
  return (
    <FormFieldWrapper label="Underlying" className={cn(s.nameField, s.fieldsWrapper, s.underlyingField)} required={true}>
      <Field
        name={'instrument'}
        loadOptions={loadOptions}
        component={renderAutocomplete}
        disabled={disabled}
        format={(value: any) => {
          if (!value || (value && value.name === null)) {
            return '';
          }
          return {
            ...value,
            name: `${value.name} ${
              value.code
                ? `[${value.code} - ${currencyValueFormatter(
                    instrument.value.pointValue,
                    ((instrument.value.currency as string) || instrument.value.valuationCurrency) ?? ''
                  )}]`
                : ''
            }`,
          };
        }}
        inputProps={{
          className: 'input--small',
        }}
      />
    </FormFieldWrapper>
  );
};

export default OptionUnderlying;
