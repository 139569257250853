import cn from 'classnames';
import React, { Fragment, FunctionComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Button } from '../../../../../../../components/UIWidgets/Button';
import s from '../Transaction.module.scss';

type IProps = WrappedFieldProps & { disabled: boolean; values: Array<{ text: string; value: any; subText?: string }> };

const renderToggleBtns: FunctionComponent<IProps> = ({ input, meta, values, disabled = false }: IProps) => {
  const value: any = input.value;
  const haveError: boolean = meta.error && meta.touched;

  return (
    <Fragment>
      <div className={s.tradeToggle}>
        {values.map((item, id) => (
          <Button
            disabled={disabled}
            key={id}
            active={value === item.value}
            size="small"
            className={cn(s.formBtn, s.tradeToggleBtn, {
              [s.active]: value === item.value,
              [s.smallLineHeight]: !!item.subText,
            })}
            onClick={() => input.onChange(item.value)}
          >
            {`${item.text}`}
            <br />
            {item.subText ? item.subText : ``}
          </Button>
        ))}
      </div>
      {haveError && <div className="input__error-text">{meta.error}</div>}
    </Fragment>
  );
};

export default renderToggleBtns;
