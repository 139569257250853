import React from 'react';
import { getBetaColor } from '../getBetaColor';

export function BarsArrows(props: any) {
  const barColors = props.barColors;
  const filledSlots = props.barsData;
  const isNegativeBetas = props.isNegativeBetas;

  // console.log('**** BAR DATA ******');
  // console.log(filledSlots);

  const inRange = (val: number, min: number, max: number) => {
    return (val - min) * (val - max) <= 0;
  };

  const getMarkerColor = (val: number) => getBetaColor(val, barColors);

  const markers = filledSlots.map((el: any, index: number) => {
    if (el.emptySlot) {
      return '';
    } else {
      return (
        <marker key={index} id={'arrowhd' + index} markerWidth="10" markerHeight="8" refX="0" refY="4" orient="auto">
          <polygon points="0 0, 5 4, 0 8" fill={getMarkerColor(el.beta)} />
        </marker>
      );
    }
  });
  const lineSets = filledSlots.map((el: any, index: number) => {
    if (el.emptySlot) {
      return '';
    } else {
      const xStart = index * 10 + 5;
      let xEnd = (100 * el.beta) / 3;

      // adjusting coordinates to a cartesian type axis
      if (isNegativeBetas) {
        if (el.beta > 0) {
          xEnd = (100 * el.beta) / 6 + (100 / 6) * 3;
        } else {
          xEnd = (100 / 6) * 3 - (100 * Math.abs(el.beta)) / 6;
        }
      }

      return (
        <g key={index}>
          <line
            x1={xStart + '%'}
            y1="100%"
            x2={xStart + '%'}
            y2="90%"
            strokeWidth="2"
            stroke={getMarkerColor(el.beta)}
          />
          <line x1={xStart + '%'} y1="90%" x2={xEnd + '%'} y2="30%" strokeWidth="2" stroke={getMarkerColor(el.beta)} />
          <line
            x1={xEnd + '%'}
            y1="30%"
            x2={xEnd + '%'}
            y2="20%"
            stroke={getMarkerColor(el.beta)}
            strokeWidth="2"
            markerEnd={'url(#arrowhd' + index + ')'}
          />
        </g>
      );
    }
  });
  return (
    <svg width="100%" height="100%">
      <defs>{markers}</defs>
      {lineSets}
    </svg>
  );
}
