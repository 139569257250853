import React from 'react';
import { Widget } from '../../../../../../../../components';
import OptionsPage from '../../components/Options/OptionsPage';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { IPortfolioInfoState } from '../../../../../../../../../../services/reducers/PortfolioReducers';
import useApi from '../../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../../../services/utils/PeriodHelper';
import { ALLOCATION_TYPES, DetalizationType } from '../../../../../../../../../../services/constants/constants';
import { LineData } from '../../../../../../../../../../models/LineData';
import { Loader } from '../../../../../../../../../../components';
import { Helmet } from 'react-helmet';

interface IProps {
  portfolioInfo: IPortfolioInfoState;
  portfolioId: string;
  renderCurrencySelector: () => React.ReactNode;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  profitPeriod: IPeriod;
  portfolioCurrency: { value: string; label: string };
}

const EquityOptions = ({
  portfolioCurrency,
  portfolioId,
  portfolioInfo,
  profitPeriod,
  renderCurrencySelector,
  portfolioCurrencyFormatter,
}: IProps) => {
  const getLoadingDataState = (item: any) => ({
    data: item.data,
    isFetching: item.isLoading,
    isFetched: item.isFetched,
    error: item.error,
  });

  const optionsPnlByMonth = useApi(getApiUrl('portfolio.profit.chart'), {
    method: 'POST',
    data: {
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(profitPeriod, 'MONTH_2_MONTH'),
      asset: {
        type: 'AssetSubClass',
        id: 'EqOptions',
      },
      custodians: [],
    },
  });
  const optionsPerformance = useApi(getApiUrl('portfolio.performance'), {
    method: 'POST',
    data: {
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(profitPeriod),
      asset: { id: 'EqOptions', type: ALLOCATION_TYPES.AssetSubClass },
      custodians: [],
      detalization: DetalizationType.Daily,
    },
  });

  const optionsDashboard = useApi(getApiUrl('portfolio.profit.options'), {
    method: 'GET',
    params: {
      portfolioId,
    },
  });

  const mapFunction = (responseData: { data: LineData }): LineData => responseData.data;

  const isLoading = optionsPnlByMonth.isLoading || optionsPerformance.isLoading || optionsDashboard.isLoading;
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Widget renderHeaderTools={renderCurrencySelector}>
      <Helmet><title>illio - Option Details</title></Helmet>
      <OptionsPage
        portfolioCurrency={portfolioCurrency}
        portfolioInfo={portfolioInfo}
        profitPeriod={profitPeriod}
        optionsPerformance={getLoadingDataState({ ...optionsPnlByMonth, data: mapFunction(optionsPerformance.data) })}
        optionsPnlByMonth={getLoadingDataState(optionsPnlByMonth)}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        optionsDashboard={getLoadingDataState(optionsDashboard)}
      />
    </Widget>
  );
};

export default EquityOptions;
