import React, { ReactNode, useEffect, useRef, useState } from 'react';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { Loader } from '../../../../../../../../components/UIWidgets/Loader';
import FeesAndChargesTable from './components/FeesAndChargesTable';
import FeesAndChargesChart from './components/FeesAndChargesChart';
import { Card } from '@iliotech/storybook';
import { Helmet } from 'react-helmet';

enum BarType {
  Solid = 'Solid',
  Hatching = 'Histing',
  Line = 'Line',
}

interface IProps {
  baseCurrency: ICurrency;
  selectedCustodians: string[];
  renderCurrencySelector: () => ReactNode;
  eChartsRef: any;
  portfolioId: string;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  historyPeriod: IPeriod;
  selectedCurrency: string;
}

const FeesAndCharges = ({
  historyPeriod,
  selectedCurrency,
  portfolioCurrencyFormatter,
  renderCurrencySelector,
  eChartsRef,
  portfolioId,
  selectedCustodians,
  baseCurrency,
}: IProps) => {
  const prevCustodian = useRef<string[]>([]);
  const [chartData, setChartData] = useState<IBarData[]>([]);

  useEffect(() => {
    // avoid multiple re-renders comparing old and cur
    if (prevCustodian.current.join() !== selectedCustodians.join()) {
      feesSinceInception.refetch();
      portfolioFees.refetch();
    }
    prevCustodian.current = selectedCustodians;
  }, [selectedCustodians]);

  useEffect(() => {
    feesSinceInception.refetch();
    portfolioFees.refetch();
  }, [portfolioId, selectedCurrency]);

  useEffect(() => {
    portfolioFees.refetch();
  }, [historyPeriod]);

  const portfolioFees = useApi(
    getApiUrl('portfolio.fees'),
    {
      method: 'POST',
      data: {
        portfolioId,
        currency: selectedCurrency === 'All' ? null : selectedCurrency.toUpperCase(),
        period: historyPeriod,
        custodians: selectedCustodians,
      },
    },
    {
      onSuccess: (fees: IFeesData) => {
        const flatData: IBarData[] = [...fees]
          .sort((a, b) => a.feeName.localeCompare(b.feeName))
          .map(
            (fee) =>
              ({
                name: fee.feeName,
                type: BarType.Solid,
                id: fee.feeName,
                data: fee.barData,
              } as IBarData)
          );

        setChartData(flatData);
      },
    }
  );

  const feesSinceInception = useApi(getApiUrl('portfolio.feesSinceInception'), {
    method: 'POST',
    data: {
      portfolioId,
      currency: selectedCurrency === 'All' ? null : selectedCurrency.toUpperCase(),
      custodians: selectedCustodians,
    },
  });

  if (portfolioFees.isFetching || feesSinceInception.isFetching) {
    return <Loader />;
  }

  return (
    <div style={{ flexDirection: 'column' }}>
      <Helmet><title>illio - Fees & Charges</title></Helmet>

      <Card>
        <FeesAndChargesChart
          eChartsRef={eChartsRef}
          selectedCurrency={selectedCurrency}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          chartData={chartData}
          renderIncomePeriod={renderCurrencySelector}
        />
      </Card>
      <div style={{ height: 20 }} />
      <FeesAndChargesTable
        selectedCurrencySymbol={selectedCurrency !== 'All' ? selectedCurrency : baseCurrency.symbol}
        feesSinceInception={feesSinceInception.data!}
      />
      <div style={{ height: 100 }} />
    </div>
  );
};

export default FeesAndCharges;
