import { AnyAction, combineReducers } from 'redux';

import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions, DashboardViewActions } from '../../actions';
import { PERIOD_TYPE, PROFIT_VIEW } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';

export interface IPortfolioState {
  profit: ILoadingPeriodDataState<IBarData[]>;
  profitChart: ILoadingPeriodDataState<IBarData[]>;
  wealth: ILoadingDataState<IDashboardWealth>;
  profitViewType: PROFIT_VIEW;
}

const INITIAL_WEALTH_INFO: IDashboardWealth = {
  total: { value: 0, diff: 0 },
  income: { value: 0, diff: 0 },
};

const INITIAL_PROFIT_STATE: ILoadingPeriodDataState<IBarData[]> = {
  ...ReduxHelper.createInitialState([]),
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const profitReducers = ReduxHelper.createHttpRequestWithPeriodReducer<ILoadingPeriodDataState<IBarData[]>>(
  INITIAL_PROFIT_STATE,
  DashboardActions.PROFIT_REQUEST,
  DashboardActions.PROFIT_SUCCESS,
  DashboardActions.PROFIT_ERROR,
  DashboardActions.SET_PROFIT_PERIOD,
  DashboardActions.PROFIT_RESET
);

const profitChartReducers = ReduxHelper.createHttpRequestWithPeriodReducer<ILoadingPeriodDataState<IBarData[]>>(
  INITIAL_PROFIT_STATE,
  DashboardActions.PROFIT_CHART_REQUEST,
  DashboardActions.PROFIT_CHART_SUCCESS,
  DashboardActions.PROFIT_CHART_ERROR,
  DashboardActions.SET_PROFIT_PERIOD,
  DashboardActions.PROFIT_CHART_RESET
);

const INITIAL_PROFIT_VIEW = PROFIT_VIEW.ASSET;

const profitViewTypeReducer = (state = INITIAL_PROFIT_VIEW, action: AnyAction) => {
  switch (action.type) {
    case DashboardViewActions.CHANGE_PROFIT_CHART_VIEW:
      return action.payload;
    case DashboardViewActions.RESET_PROFIT_CHART_VIEW:
      return INITIAL_PROFIT_VIEW;
    default:
      return state;
  }
};

const portfolioDashboard = combineReducers<IPortfolioState>({
  profit: profitReducers,
  profitChart: profitChartReducers,
  wealth: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IDashboardWealth>>(
    ReduxHelper.createInitialState(INITIAL_WEALTH_INFO),
    DashboardActions.DASHBOARD_WEALTH_REQUEST,
    DashboardActions.DASHBOARD_WEALTH_SUCCESS,
    DashboardActions.DASHBOARD_WEALTH_ERROR,
    DashboardActions.DASHBOARD_WEALTH_RESET
  ),
  profitViewType: profitViewTypeReducer,
});

export default portfolioDashboard;
