import { ReduxHelper } from '../utils/ReduxHelper';

import { SectorActions } from '../actions/SectorActions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

export interface ISectorState extends ILoadingDataState {
  data: ISector[];
}

const INITIAL_STATE: ISectorState = ReduxHelper.createInitialState<ISector[]>([]);

const sectorReducer = ReduxHelper.createHttpRequestReducer<ISectorState>(
  INITIAL_STATE,
  SectorActions.SECTOR_REQUEST,
  SectorActions.SECTOR_SUCCESS,
  SectorActions.SECTOR_ERROR
);

export default sectorReducer;
