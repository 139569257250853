export const currencyFormat = (value: number, digits: number): string =>
  value.toFixed(digits).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const currencyValueFormatter = (pointValue: number, currencyValue: string) =>
  pointValue ? (pointValue < 1 ? currencyValue.replace(/.$/, currencyValue.slice(2).toLowerCase()) : currencyValue) : '';

export const currencyFormatterV2 = (value: number, decimalPos: number, separatorPos: number) => {
  const re = '\\d(?=(\\d{' + (separatorPos || 3) + '})+' + (decimalPos > 0 ? '\\.' : '$') + ')';
  return value.toFixed(Math.max(0, ~~decimalPos)).replace(new RegExp(re, 'g'), '$&,');
};
