import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';
import { ALLOCATION_TYPES } from '../constants/constants';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { MODELS } from '../mock/scenarios';
import { IScenarioBasicConfig } from '../../models/IScenarioBasicConfig';
import { Dispatch } from 'redux';
import { IAllocationDict } from '../../models/IAllocationDict';
import { IScenarioModel } from '../reducers/ScenariosReducers';

export class ScenariosActions {
  static readonly SCENARIO_MODELS_REQUEST = 'SCENARIO_MODELS_REQUEST';
  static readonly SCENARIO_MODELS_SUCCESS = 'SCENARIO_MODELS_SUCCESS';
  static readonly SCENARIO_MODELS_ERROR = 'SCENARIO_MODELS_ERROR';
  static readonly SCENARIO_MODELS_RESET = 'SCENARIO_MODELS_RESET';

  static readonly SCENARIO_ALLOCATIONS_REQUEST = 'SCENARIO_ALLOCATIONS_REQUEST';
  static readonly SCENARIO_ALLOCATIONS_SUCCESS = 'SCENARIO_ALLOCATIONS_SUCCESS';
  static readonly SCENARIO_ALLOCATIONS_ERROR = 'SCENARIO_ALLOCATIONS_ERROR';
  static readonly SCENARIO_ALLOCATIONS_RESET = 'SCENARIO_ALLOCATIONS_RESET';

  static readonly SCENARIO_PROFIT_REQUEST = 'SCENARIO_PROFIT_REQUEST';
  static readonly SCENARIO_PROFIT_SUCCESS = 'SCENARIO_PROFIT_SUCCESS';
  static readonly SCENARIO_PROFIT_ERROR = 'SCENARIO_PROFIT_ERROR';
  static readonly SCENARIO_PROFIT_RESET = 'SCENARIO_PROFIT_RESET';

  static readonly SCENARIO_APPLY_SCENARIO_CONFIG = 'SCENARIO_APPLY_SCENARIO_CONFIG';
  static readonly SCENARIO_SELECT_HISTORY_EVENT = 'SCENARIO_SELECT_HISTORY_EVENT';
  static readonly SCENARIO_SELECT_HISTORY_PERIOD = 'SCENARIO_SELECT_HISTORY_PERIOD';
  static readonly SCENARIO_CONFIG_RESET = 'SCENARIO_CONFIG_RESET';

  static fetchScenarioAllocationsList(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IAllocationDict>(
      {
        url: getApiUrl('portfolio.allocations'),
        data: {
          portfolioId,
          withChildren: false,
          allocations: [ALLOCATION_TYPES.AssetClass, ALLOCATION_TYPES.Region, ALLOCATION_TYPES.Currency],
        },
      },
      ScenariosActions.SCENARIO_ALLOCATIONS_REQUEST,
      ScenariosActions.SCENARIO_ALLOCATIONS_SUCCESS,
      ScenariosActions.SCENARIO_ALLOCATIONS_ERROR
    );
  }

  static fetchPortfolioModels(portfolioId: string): IActionPayload<{ data: IScenarioModel[] }> {
    return {
      type: ScenariosActions.SCENARIO_MODELS_SUCCESS,
      payload: { data: MODELS },
    };
  }

  static fetchProfit(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('portfolio.profit.assetClass'),
        data: {
          portfolioId,
        },
      },
      ScenariosActions.SCENARIO_PROFIT_REQUEST,
      ScenariosActions.SCENARIO_PROFIT_SUCCESS,
      ScenariosActions.SCENARIO_PROFIT_ERROR
    );
  }

  static applyScenarioConfig(settings: IScenarioBasicConfig): IActionPayload<IScenarioBasicConfig> {
    return {
      type: ScenariosActions.SCENARIO_APPLY_SCENARIO_CONFIG,
      payload: settings,
    };
  }

  static selectHistoryEvent(event: string): IActionPayload<string> {
    return {
      type: ScenariosActions.SCENARIO_SELECT_HISTORY_EVENT,
      payload: event,
    };
  }

  static selectHistoryPeriod(months: number): IActionPayload<number> {
    return {
      type: ScenariosActions.SCENARIO_SELECT_HISTORY_PERIOD,
      payload: months,
    };
  }

  static resetAllocations(): IActionPayload {
    return {
      type: ScenariosActions.SCENARIO_ALLOCATIONS_RESET,
    };
  }

  static resetConfig(): IActionPayload {
    return {
      type: ScenariosActions.SCENARIO_CONFIG_RESET,
    };
  }

  static resetModels(): IActionPayload {
    return {
      type: ScenariosActions.SCENARIO_MODELS_RESET,
    };
  }

  static resetProfit(): IActionPayload {
    return {
      type: ScenariosActions.SCENARIO_PROFIT_RESET,
    };
  }

  static resetScenarioData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(ScenariosActions.resetModels());
      dispatch(ScenariosActions.resetProfit());
      dispatch(ScenariosActions.resetConfig());
      dispatch(ScenariosActions.resetAllocations());
    };
  }
}

export default ScenariosActions;
