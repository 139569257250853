import * as React from 'react';
import { Button } from '../UIWidgets';
import s from './AddCustodianButton.module.scss';
import cn from 'classnames';
import { useModal } from '../../services/context/ModalContext';

interface IProps {
  extraStyles?: React.CSSProperties;
}

const AddCustodianButton = ({ extraStyles }: IProps) => {
  const { setOpen } = useModal();

  return (
    <div>
      <div style={extraStyles} onClick={() => setOpen(true)}>
        <Button className={cn(s.addCustodianBtn)} size={'xsmall'}>
          <p>+ Add custodian</p>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(AddCustodianButton);
