import React, { PureComponent } from 'react';
import s from './InfoBlock.module.css';
import { FormatHelper } from '../../../../services/utils/FormatHelper';

interface IProps {
  image: string;
  title: string;
  value: number | string;
  diff?: number;
}

class InfoBlock extends PureComponent<IProps> {
  render() {
    const { image, title, value, diff } = this.props;
    return (
      <div className={s.infoBlock}>
        <div className={s.infoBlockImage}>
          <img src={image} alt={title} />
        </div>
        <div className={s.infoBlockContent}>
          <div className={s.infoBlockTitle}>{title}</div>
          <div className={s.infoBlockValue}>
            {value}
            {!!diff && <span className={s.infoBlockDiff}>{FormatHelper.formatPercentage(diff)}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default InfoBlock;
