interface IProps<D> {
  id: string | number;
  group?: Array<NestedSet<D>>;
  label?: string;
  nodeRenderer?: (nestedSet: NestedSet<D>) => any;
  data?: D;
}

class NestedSet<D> {
  id: string | number;
  label: string | null;
  group: Array<NestedSet<D>> | null;
  nodeRenderer: any;
  data: D | null;

  constructor(props: IProps<D>) {
    this.id = props.id;
    this.group = null;
    this.label = null;
    this.data = null;
    this.nodeRenderer = null;

    if (props.label) {
      this.label = props.label;
    }

    if (props.data) {
      this.data = props.data;
    }

    if (props.data) {
      this.data = props.data;
    }

    if (props.group) {
      this.group = props.group;
    }

    if (props.nodeRenderer) {
      this.nodeRenderer = props.nodeRenderer;
    }
  }

  getByIndexes(indexes: number[]): NestedSet<D> {
    if (indexes.length === 0) {
      return this;
    }
    const reducesIndexes: number[] = [...indexes];
    const [index] = reducesIndexes.splice(0, 1);
    if (!this.group) {
      throw new Error('Path is not correct');
    }
    return this.group[index].getByIndexes(reducesIndexes);
  }

  getFirstByPredicate(predicate: (nestedSet: NestedSet<D>) => boolean): NestedSet<D> | null {
    if (this.group) {
      for (const childSet of this.group) {
        if (predicate(childSet)) {
          return childSet;
        } else {
          const setFromGroup = childSet.getFirstByPredicate(predicate);
          if (setFromGroup) {
            return setFromGroup;
          }
        }
      }
    }
    return null;
  }
}

export { NestedSet };
