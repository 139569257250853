import { ReduxHelper } from '../utils/ReduxHelper';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

import { RealAssetActions } from '../actions/RealAssetActions';
import { IRealAsset } from '../../models/IRealAsset';

export type IPortfolioInfoState = ILoadingDataState<IPortfolio | null>;

export type IRealAssetsState = ILoadingDataState<IRealAsset[] | null>;

const RealAssetReducer = ReduxHelper.createHttpRequestReducer<IRealAssetsState>(
  ReduxHelper.createInitialState(null),
  RealAssetActions.REAL_ASSETS_LIST_REQUEST,
  RealAssetActions.REAL_ASSETS_LIST_SUCCESS,
  RealAssetActions.REAL_ASSETS_LIST_ERROR,
  RealAssetActions.REAL_ASSETS_LIST_RESET
);

export default RealAssetReducer;
