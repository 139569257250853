import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IRealAsset } from '../../models/IRealAsset';
const API_BASE = '';

/**
 * @param accessToken bearer token from cognito
 * @param endpoint full endpoint after api/v1/
 * @param method currently only "get" or "post"
 */
const request = <T, U extends {} | null>(
  endpoint: string,
  method: 'post' | 'get' | 'put' | 'patch' | 'delete' = 'get',
  baseUri = API_BASE
) => async (data: U | {} = {}): Promise<AxiosResponse<T>> => {
  const headerConfig: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  data = data ?? {};

  switch (method) {
    case 'put': {
      return axios.put(`${baseUri}/${endpoint}`, data, headerConfig);
    }
    case 'post': {
      return axios.post(`${baseUri}/${endpoint}`, data, headerConfig);
    }
    case 'patch': {
      return axios.patch(`${baseUri}/${endpoint}`, data, headerConfig);
    }
    case 'delete': {
      return axios.delete(`${baseUri}/${endpoint}`, { ...headerConfig, data });
    }
  }

  return Auth.currentSession().then((session) => {
    axios.defaults.timeout = 20000;
    axios.defaults.headers.common.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
    return axios.get(`${baseUri}/${endpoint}`, headerConfig);
  });
};

const api = {
  instruments: {
    list: request<IServerResponse<IRealAsset[]>, { assetClassId?: AssetClassId; nonMarket?: boolean }>(
      'api/v1/real-asset-instrument',
      'get'
    ),
    findByAssetSubClassId: <U extends {} = {}>(assetSubclassId: string, data?: U) =>
      request<IServerResponse<IRealAsset[]>, {}>(
        `api/v1/real-asset-instrument/search`
        // `api/v1/real-asset-instrument/search/findByAssetSubClass?assetSubClass=${assetSubclassId}`
      )(data),
    findByAssetClassId: <U extends {} = {}>(assetClassId: string, data?: U) =>
      request<IRealAsset[], {}>(`api/v1/real-asset-instrument/search?assetClass=${assetClassId}`)(data),
    create: request<any, IRealAsset>('api/v1/real-asset-instrument', 'post'),
    update: (instrumentId: string) => request<any, IRealAsset>(`api/v1/real-asset-instrument/${instrumentId}`, 'put'),
    get: (instrumentId: string) => request<IRealAsset, null>(`api/v1/real-asset-instrument/${instrumentId}`),
    prices: {
      get: (instrumentId: string) =>
        request<{ prices: IPrice[] }, {}>(`api/v1/real-asset-instrument/${instrumentId}/price`, 'get')(),
    },
  },
  prices: {
    create: (instrumentId: string) => request<any, IPrice>(`api/v1/real-asset-instrument/${instrumentId}/price`, 'post'),
    update: (instrumentId: string) => request<any, IPrice>(`api/v1/real-asset-instrument/${instrumentId}/price`, 'post'),
    delete: (instrumentId: string, date: string) =>
      request<any, IPrice>(`api/v1/real-asset-instrument/${instrumentId}/price?at=${date}`, 'delete')({ at: date }),
    get: (instrumentId: string) => request<any, IPrice>(`api/v1/real-asset-instrument/${instrumentId}/price`, 'get')(),
  },
};

export default api;
