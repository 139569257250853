import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Loader, PageHeader } from '../../../../components';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../components/HOC/withPortfolioInfo';
import { CognitoHelper } from '../../../../services/utils/CognitoHelper';

import Select from 'react-select';
import { Card } from '@iliotech/storybook';
import { IPortfolioScenarioData } from './interfaces';
import SlidersBlock from './components/SlidersBlock';
import ChartLegend from './components/ChartLegend';
import BarChart from './components/BarChart';

import { API_URL } from '../../../../services/constants/endpoints';
import { getMockedScenarioData } from './MockedScenarioData';
import s from './RiskScenarios.module.scss';
import { reduxErrorStatusHandler } from '../../../../services/utils/ReduxHelper';
import Analytics from '../../../../services/utils/Analytics';
import {
  DASHBOARD_PORTFOLIO_HELP_TEXT,
  SCENARIO_CONTAINER_HELP_TEXT,
} from '../../../../services/constants/tooltipContextHelp';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../components/ContextHelp';
import { SCENARIO_DISPLAY_OPTIONS } from '../../../../services/constants/constants';
import { PortfolioHeader } from '../../components';
import { PortfolioSubHeader } from '../../components/PortfolioHeader/PortfolioSubHeader';

interface IState {
  loading: boolean;
  error: string;
  portfolioId: string;
  portfolioName: string;
  portfolioBaseCcy: string;
  portfolioBaseCcySymbol: string;
  portfolioBenchmarkIndexName: string;
  portfolioTotalPnL: number;
  portfolioTotalPerformance: number;
  portfolioTotalWealth: number;
  portfolioRisk: number;
  portfolioScenarioData: IPortfolioScenarioData;
  selectedDataset: any[];
  selectedDatasetOption: string;
  totalMarketValueBase: number;
  affectedByVolatilityChange: boolean;
  affectedByYieldChange: boolean;
  requiresBackendCalculations: boolean;
  bar1Correction: number;
  bar2Correction: number;
  bar3Correction: number;
  bar4Correction: number;
  bar5Correction: number;
  mockedApiResponse: boolean;
  dynamicVolatilityScore: number;
}

type IProps = IWithPortfolioInfoProps;

class RiskScenariosContainer extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      error: '',
      portfolioId: '',
      portfolioName: '',
      portfolioBaseCcy: '',
      portfolioBaseCcySymbol: '',
      portfolioBenchmarkIndexName: '',
      portfolioTotalPnL: 0,
      portfolioTotalPerformance: 0,
      portfolioTotalWealth: 0,
      portfolioRisk: 0,
      portfolioScenarioData: getMockedScenarioData(),
      selectedDataset: getMockedScenarioData().sectors.entries,
      selectedDatasetOption: 'sectors',
      totalMarketValueBase: 0,
      affectedByVolatilityChange: true,
      affectedByYieldChange: true,
      requiresBackendCalculations: false,
      bar1Correction: 0,
      bar2Correction: 0,
      bar3Correction: 0,
      bar4Correction: 0,
      bar5Correction: 0,
      mockedApiResponse: false,
      dynamicVolatilityScore: 0,
    };
  }

  componentDidMount() {
    Analytics.trackPageView('Sensitivity Analysis');
    // This is needed for using local mocked data in development but may come useful for the automated tests as well
    if (!this.state.mockedApiResponse) {
      CognitoHelper.withAuthorisationToken(() => {
        this.setState({ loading: true });
        this._fetchData()
          .then((result) => {
            this.setState({
              portfolioScenarioData: result[0],
              selectedDataset: result[0].sectors.entries,
              totalMarketValueBase: result[0].totalMarketValueBase,
              affectedByYieldChange: result[0].affectedByYieldChange,
              affectedByVolatilityChange: result[0].affectedByVolatilityChange,
              requiresBackendCalculations: result[0].requiresBackendCalculations,
              dynamicVolatilityScore: result[1],
            });
            this.setState({ loading: false });
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return error;
            }
            reduxErrorStatusHandler(error);
            this.setState({
              error: `${error}`,
              loading: false,
            });
          });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      loading,
      error,
      portfolioId,
      selectedDataset,
      totalMarketValueBase,
      affectedByVolatilityChange,
      affectedByYieldChange,
      requiresBackendCalculations,
      bar1Correction,
      bar2Correction,
      bar3Correction,
      bar4Correction,
      bar5Correction,
    } = this.state;

    const baseCurrencyName = this.state.portfolioScenarioData.baseCurrencyName;
    const baseCurrencySymbol = this.state.portfolioScenarioData.baseCurrencySymbol;

    const scenarioSelectTypes = [
      { value: 'sectors', label: 'By Sector' },
      { value: 'assets', label: 'By Asset class' },
      { value: 'countries', label: 'By Country' },
      { value: 'currencies', label: 'By Currency' },
    ];

    const categorySelectStyles = {
      option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? '#1c202e' : '#3699f8',
        backgroundColor: '#d7e0ff',
        padding: 6,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        border: 0,
        margin: 0,
        padding: 4,
        textAlign: 'right',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        width: 132,
        backgroundColor: '#1c202e',
        borderColor: state.isSelected ? '#d7e0ff' : '#1c202e',
        margin: 0,
        padding: 0,
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        color: '#ffffff',
        backgroundColor: '#1c202e',
      }),
      singleValue: (provided: any, state: any) => ({
        color: '#ffffff',
        backgroundColor: '#1c202e',
        border: 0,
        margin: 0,
        padding: 0,
      }),
    };

    if (loading) {
      return (
        <Fragment>
          <div className={s.dashboardTitleContainer}>
            <h3>Loading portfolio data...</h3>
          </div>
          <Loader />
        </Fragment>
      );
    }
    if (error) {
      return (
        <Fragment>
          <div className={s.dashboardTitleContainer}>
            <h4>There was an error loading data.</h4>
            <p>{this.state.error}</p>
          </div>
          <p>
            <button onClick={this.reloadPortfolioScenario}>Click here to try again.</button>
          </p>
        </Fragment>
      );
    }

    const formattedRiskScoreValue =
      typeof this.state.dynamicVolatilityScore === 'object'
        ? 'Insufficient Data'
        : this.state.dynamicVolatilityScore !== null
        ? String(this.state.dynamicVolatilityScore) + '%'
        : 'calculating...';

    const stats: IStat[] = [
      {
        value: this.state.portfolioTotalPnL,
        label: 'Inception P&L',
        colored: true,
        type: 'currency',
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoInceptionPnL },
      },
      {
        value: this.state.portfolioTotalPerformance,
        label: 'Performance (TWR)',
        type: 'percentage',
        colored: true,
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
      },
      {
        value: this.state.portfolioTotalWealth,
        label: 'Total Portfolio Value',
        type: 'currency',
        colored: true,
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
      },
      {
        value: formattedRiskScoreValue,
        label: 'Portfolio Volatility',
        colored: true,
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPortfolioVolatility },
      },
    ];

    return (
      <div>
        <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false} />

        <PortfolioSubHeader stats={stats} title={'Sensitivity Analysis'} />

        <div>
          <div className={s.SlidersBlockContainer}>
            <SlidersBlock
              benchmarkName={this.props.portfolioInfo.benchmarkIndex.name}
              currency={baseCurrencyName}
              onChange={this.handleSlidersChange}
              affectedByYieldChange={affectedByYieldChange}
              affectedByVolatilityChange={affectedByVolatilityChange}
            />
          </div>
          <div className={s.categorySelectorBlock}>
            <Select
              styles={categorySelectStyles}
              defaultValue={scenarioSelectTypes[0]}
              options={scenarioSelectTypes}
              onChange={this.handleSelectDataType}
            />
          </div>
          <Card>
            <div>
              <h3>
                Beta adjusted
                <ContextHelpIcon title={'Beta adjusted'} elementId={'Beta adjusted'} />
              </h3>
              <ContextHelpTooltip
                elementId={'Beta adjusted'}
                tooltipTitle={'Beta adjusted'}
                tooltipText={SCENARIO_CONTAINER_HELP_TEXT.chartBetaAdjusted}
              />
            </div>

            <div className={s.chartLegendContainer}>
              <ChartLegend
                selectedDataset={selectedDataset}
                totalMarketValueBase={totalMarketValueBase}
                bar1Correction={bar1Correction}
                bar2Correction={bar2Correction}
                bar3Correction={bar3Correction}
                bar4Correction={bar4Correction}
                bar5Correction={bar5Correction}
                baseCurrencySymbol={baseCurrencySymbol}
                baseCurrencyName={baseCurrencyName}
                portfolioTotalWealth={this.state.portfolioTotalWealth}
                isDataFromBackend={requiresBackendCalculations}
              />
            </div>
            <BarChart
              barData={selectedDataset}
              baseValue={totalMarketValueBase}
              bar1Correction={bar1Correction}
              bar2Correction={bar2Correction}
              bar3Correction={bar3Correction}
              bar4Correction={bar4Correction}
              bar5Correction={bar5Correction}
              baseCurrencyName={baseCurrencyName}
              baseCurrencySymbol={baseCurrencySymbol}
              isDataFromBackend={requiresBackendCalculations}
            />
          </Card>
        </div>
      </div>
    );
  }

  private _fetchData(): Promise<any> {
    const { portfolioInfo } = this.props;
    const portfolioId = portfolioInfo.id;

    this.setState({
      portfolioName: portfolioInfo.name,
      portfolioId: portfolioInfo.id,
      portfolioBaseCcy: portfolioInfo.currency.name,
      portfolioBaseCcySymbol: portfolioInfo.currency.symbol,
      portfolioBenchmarkIndexName: portfolioInfo.benchmarkIndex.name,
      portfolioTotalPnL: portfolioInfo.totalProfitAndLoss,
      portfolioTotalPerformance: portfolioInfo.performance,
      portfolioTotalWealth: portfolioInfo.totalWealth,
      portfolioRisk: portfolioInfo.risk,
    });

    return Promise.all([this._getPortfolioScenario(portfolioId), this.getVolatilityScore(portfolioId)]);
  }

  private _getPortfolioScenario = (portId: string) => {
    this.setState({ loading: true });

    // This endpoint is used when we don't have Options in the portfolio and Scenario values are calculated in the frontend directly when we move a UI slider
    const scenarioMultipliersProviderEndpoint =
      API_URL + 'api/v1/positions/portfolio/' + portId + '/scenarios/coefficients';

    const cancelTokenSource = axios.CancelToken.source();
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });

    return apiRequest.get(scenarioMultipliersProviderEndpoint, {}).then((result) => {
      return result.data;
    });
  };

  private _getScenarioCalculations = (portfolioId: string) => {
    // This endpoint is used when we have Options in the portfolio and Scenario values needs to be recalculated in the backend when we move a UI slider
    const scenarioCalculatedValuesEndpoint = API_URL + 'api/v1/positions/portfolio/' + portfolioId + '/scenarios/values';

    const cancelTokenSource = axios.CancelToken.source();
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });

    const sliderValues = {
      equityPercent: this.state.bar1Correction,
      fxPercent: this.state.bar2Correction,
      yieldPercent: this.state.bar3Correction,
      volatilityPercent: this.state.bar5Correction,
    };

    CognitoHelper.withAuthorisationToken(() => {
      apiRequest
        .post(scenarioCalculatedValuesEndpoint, sliderValues)
        .then((result) => {
          this.setState({
            portfolioScenarioData: result.data,
            totalMarketValueBase: result.data.totalMarketValueBase,
            selectedDataset:
              this.state.selectedDatasetOption === SCENARIO_DISPLAY_OPTIONS.sectors
                ? result.data.sectors.entries
                : this.state.selectedDatasetOption === SCENARIO_DISPLAY_OPTIONS.assets
                ? result.data.assetClasses.entries
                : this.state.selectedDatasetOption === SCENARIO_DISPLAY_OPTIONS.countries
                ? result.data.countries.entries
                : this.state.selectedDatasetOption === SCENARIO_DISPLAY_OPTIONS.currencies
                ? result.data.currencies.entries
                : result.data.sectors.entries,
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return error;
          }
          reduxErrorStatusHandler(error);
          this.setState({
            error: `${error}`,
          });
        });
    });
  };

  reloadPortfolioScenario = () => {
    const portfolioId = this.state.portfolioId.toString();
    this._getPortfolioScenario(portfolioId);
  };

  handleSlidersChange = (sliderValue: number, sliderId: number) => {
    const portfolioId = this.state.portfolioId.toString();

    // Equity Slider
    if (sliderId === 1) {
      this.setState({ bar1Correction: sliderValue }, () => {
        if (this.state.requiresBackendCalculations) {
          this._getScenarioCalculations(portfolioId);
        }
      });
    }
    // Fx Slider
    if (sliderId === 2) {
      this.setState({ bar2Correction: sliderValue }, () => {
        if (this.state.requiresBackendCalculations) {
          this._getScenarioCalculations(portfolioId);
        }
      });
    }
    // Yield Slider
    if (sliderId === 3) {
      this.setState({ bar3Correction: sliderValue }, () => {
        if (this.state.requiresBackendCalculations) {
          this._getScenarioCalculations(portfolioId);
        }
      });
    }
    // Volatility Slider
    if (sliderId === 5) {
      this.setState({ bar5Correction: sliderValue }, () => {
        if (this.state.requiresBackendCalculations) {
          this._getScenarioCalculations(portfolioId);
        }
      });
    }
  };

  handleSelectDataType = (selectedOption: any) => {
    if (selectedOption.value === SCENARIO_DISPLAY_OPTIONS.sectors) {
      this.setState({
        selectedDataset: this.state.portfolioScenarioData.sectors.entries,
        selectedDatasetOption: SCENARIO_DISPLAY_OPTIONS.sectors,
      });
    }
    if (selectedOption.value === SCENARIO_DISPLAY_OPTIONS.assets) {
      this.setState({
        selectedDataset: this.state.portfolioScenarioData.assetClasses.entries,
        selectedDatasetOption: SCENARIO_DISPLAY_OPTIONS.assets,
      });
    }
    if (selectedOption.value === SCENARIO_DISPLAY_OPTIONS.countries) {
      this.setState({
        selectedDataset: this.state.portfolioScenarioData.countries.entries,
        selectedDatasetOption: SCENARIO_DISPLAY_OPTIONS.countries,
      });
    }
    if (selectedOption.value === SCENARIO_DISPLAY_OPTIONS.currencies) {
      this.setState({
        selectedDataset: this.state.portfolioScenarioData.currencies.entries,
        selectedDatasetOption: SCENARIO_DISPLAY_OPTIONS.currencies,
      });
    }
  };

  /* private _fetchDynamicVolatility(portfolioId: string): Promise<any> {
    return Promise.all([this.getVolatilityScore(portfolioId)]);
  }*/

  // ToDo: This is a TEMP implementation only to avoid UNDEFINED score in the dashboard (ILLDV001-1607). Until being replaced by a Pusher method.
  getVolatilityScore = (portId: string) => {
    const volatilityScoreEndpoint = 'api/risk/v1/volatility/score/portfolio/';
    const API_VOLATILITY_SCORE = API_URL + volatilityScoreEndpoint;

    const cancelTokenSource = axios.CancelToken.source();
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });

    return apiRequest.get(API_VOLATILITY_SCORE + portId, {}).then((result) => {
      return result.data;
    });
  };
}

export default withPortfolioInfo(connect()(RiskScenariosContainer));
