import cn from 'classnames';
import React, { ButtonHTMLAttributes, PureComponent } from 'react';
import s from './Button.module.scss';

type Variant =
  | 'default'
  | 'outline'
  | 'outline-warning'
  | 'empty'
  | 'outline-info'
  | 'outline-disabled'
  | 'dark'
  | 'light';
type Size = 'xsmall' | 'small' | 'medium' | 'big';

interface IOwnProps {
  active: boolean;
  variant: Variant;
  size: Size;
  type: string;
  disabled: boolean;
}

type IProps = ButtonHTMLAttributes<HTMLButtonElement> & IOwnProps;

class Button extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    active: true,
    className: '',
    type: 'button',
    variant: 'default',
    size: 'medium',
    onClick: () => {},
  };

  render() {
    const { active, children, className, type, variant, size, disabled, ...otherProps } = this.props;

    return (
      <button
        type={type}
        className={cn(className, s.btn, s[variant], s[size], {
          [s.active]: active,
          [s.disabled]: disabled,
        })}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
}

export default Button;
