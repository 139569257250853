import React from 'react';
import { useCurrencies } from '../../../../../../../../services/hooks/apiHooks/useCurrencies';
import cn from 'classnames';
import s from './CryptoLeg.module.css';

import { AutoCompleteField } from './AutoCompleteField';
import { useInstrumentSearch } from '../../../../../../../../services/hooks/apiHooks/useInstrumentSearch';
import { NumberField } from './NumberField';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

interface IOwnProps {
  side?: 'buy' | 'sell';
  includeFiat?: boolean;
}

export const CryptoLeg = ({ side = 'buy', includeFiat = false }: IOwnProps) => {
  const { currencyInstruments } = useCurrencies();
  const [cryptos, setCryptos] = React.useState<IOption<{ sourceId: string; sourceData: string }>[]>([]);
  const [query, setQuery] = React.useState('');

  const { search } = useInstrumentSearch();

  React.useEffect(() => {
    search(query, 'crypto').then((result) => {
      if (Array.isArray(result)) {
        setCryptos(
          result.map((asset) => ({
            id: asset.code + asset.currency,
            name: `${asset.name} [${asset.code}]`,
            value: asset.sourceId,
          }))
        );
      }
    });
  }, [query]);

  const options = [...(includeFiat ? currencyInstruments : []), ...cryptos];

  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.horizontal)}>
        <div style={{ marginRight: '0.6rem' }}>
          <AutoCompleteField
            name={`${side}Currency`}
            options={query ? options : []}
            onTyping={setQuery}
            value={null}
            label={'Cryptocurrency'}
            placeholder={'Search'}
            required={true}
          />
        </div>
        <NumberField label={'Quantity'} name={`${side}Quantity`} required={true} maxDecimals={7} />
      </div>
    </div>
  );
};
