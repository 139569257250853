import { Data, BarChartSeries } from "./IBarChart";

export const getValuesInterval = (series: Data) => {
  let min = 0,
    max = 0;

  series.forEach(({ values }) => {
    values.forEach(({ end }) => {
      if (end < min) {
        min = end;
      } else if (end > max) {
        max = end;
      }
    });
  });

  return { minValue: min, maxValue: max };
};

export const getFormattedData = (series: BarChartSeries) => {
  if(!series[0]){
    return []
  }
  const nEntries = series[0].values.length;
  const data = new Array(nEntries);
  for (let i = 0; i < nEntries; i++) {
    const element = {
      label: new Date(series[0].values[i].x),
      values: series.reduce(
        ({ values, p, n }, series) => {
          const value = series.values[i]?.y ?? 0;
          const start = value < 0 ? n : p;
          const end = start + value;
          const element = {
            label: series.values[i]?.x || "",
            color: series.color,
            series: series.label,
            value,
            end,
            start,
          };
          // @ts-ignore
          values.push(element);
          return {
            values,
            n: value < 0 ? end : n,
            p: value > 0 ? end : p,
          };
        },
        { values: [], p: 0, n: 0 }
      ).values,
    };
    data[i] = element;
  }

  return data;
};

export const uniformSeriesData = (series: BarChartSeries) => {
  if (series.length < 2) {
    return series;
  }

  const valuesArrays = series.map(({ values }) => values.slice());
  const lastDate = getLastDate(valuesArrays);

  let i = 0;
  let smallest: Date;
  do {
    smallest = smallestDateAtIndex(valuesArrays, i);
    valuesArrays.forEach((array) => {
      if (array.length <= i || array[i].x > smallest) {
        array.splice(i, 0, { x: smallest, y: undefined as any });
      }
    });
    i++;
  } while (smallest < lastDate);

  return series.map((series, index) => ({
    ...series,
    values: valuesArrays[index],
  }));
};

type SmallestDate = {
  x: string | Date;
  y: number;
}[][];

const smallestDateAtIndex = (array: SmallestDate, index: number) => {
  let smallest: Date;
  array.forEach((values) => {
    if (
      values.length > index &&
      (smallest === undefined || new Date(values[index].x).getTime() < smallest.getTime())
    ) {
      smallest = new Date(values[index].x);
    }
  });
  // @ts-ignore
  return smallest;
};

type LastDate = {
  x: string | Date;
  y: number;
}[][];
const getLastDate = ([first, ...rest]: LastDate) => {
  let last = first[first.length - 1].x;

  rest.forEach((values) => {
    if (values[values.length - 1].x > last) {
      last = values[values.length - 1].x;
    }
  });
  return last;
};

export const numberWithCommas = (value: number) => {
  const neg = value < 0;
  const [int, dec] = (neg ? -value : value).toString().split(/[.]/);

  const combine = (s: string) => {
    if (!dec) {
      return `${neg ? "-" : ""}${s}`;
    }
    return `${neg ? "-" : ""}${s}.${dec}`;
  };

  const len = int.length;
  if (len > 9) {
    const m = int.match(/(.*)(...)(...)(...)/);
    if (m) {
      return combine(`${m[1]},${m[2]},${m[3]},${m[4]}`);
    }
  }

  if (len > 6) {
    const m = int.match(/(.*)(...)(...)/);
    if (m) {
      return combine(`${m[1]},${m[2]},${m[3]}`);
    }
  }

  if (len > 3) {
    const m = int.match(/(.*)(...)/);
    if (m) {
      return combine(`${m[1]},${m[2]}`);
    }
  }

  return combine(int);
};

export const delayed = (proc: ({ x, y }: { x: number; y: number }) => void) => {
  let timeout: NodeJS.Timeout | null = null;
  let latestArg: { x: number; y: number };

  return (arg: { x: number; y: number } | null) => {
    if (!arg) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      return;
    }

    latestArg = arg;
    if (timeout) {
      return;
    }

    timeout = setTimeout(() => {
      timeout = null;
      latestArg && proc(latestArg);
    }, 200);
  };
};
