import React, { PureComponent } from 'react';
import { IAllocationDict } from '../../../../models/IAllocationDict';
import { AllocationsList } from '../AllocationsList';
import { IAllocationListProps } from '../AllocationsList/AllocationsList';
import { Widget } from '../WidgetsBlock/Widget';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { Select } from '../../../../components/UIWidgets/Select';
import s from '../StructureWidget/StructureWidget.module.scss';
import { GEOGRAPHY_ALLOCATION_TYPES, GEOGRAPHY_TYPES } from '../../../../services/constants/constants';

interface IMapStateToProps {
  allocations: IAllocationDict;
}

interface IOwnProps {
  allocations: IAllocationDict;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  onChangeAllocateGeographyBy: (event: any) => void;
  currentAllocateGeographyByValue: GEOGRAPHY_ALLOCATION_TYPES;
}

type IProps = Partial<Pick<IAllocationListProps, 'inRow'>> &
  IMapStateToProps &
  IOwnProps &
  Omit<IWidgetProps, 'renderHeaderTools' | 'isDataLoaded'>;

class AllocationsWidgetContainer extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    inRow: 4,
    full: true,
  };

  render() {
    const { allocations, colNum, full, title, titleInfo } = this.props;
    // const rowCount = this._computeRows();

    const geographyTypes = GEOGRAPHY_TYPES;

    const geographySelectedValue =
      geographyTypes.filter((geographyType) => geographyType.value === this.props.currentAllocateGeographyByValue)[0] ??
      geographyTypes[0];

    return (
      <Widget title={title} colNum={colNum} full={full} titleInfo={titleInfo}>
        <div className={s.selectorsContainer}>
          {/*<div className={s.selectWidget}></div>*/}
          <div className={s.selectWidget}>
            <div className={s.selectLabel}>Allocate Geography by:</div>
            <Select
              className={s.dropdownBox}
              options={geographyTypes}
              size={'default'}
              onChange={this.props.onChangeAllocateGeographyBy}
              selectedValue={geographySelectedValue}
            />
          </div>
          <div className={s.selectWidget} />
        </div>

        <AllocationsList
          allocations={allocations}
          inRow={5}
          portfolioCurrencyFormatter={this.props.portfolioCurrencyFormatter}
        />
      </Widget>
    );
  }

  // private _computeRows() {
  //   const { allocations, inRow } = this.props;
  //
  //   const countLength = Object.keys(allocations).length;
  //   return countLength > 1 ? countLength : inRow;
  // }
}

export default AllocationsWidgetContainer;
