import React, { useEffect, useState } from 'react';
import { PortfolioHeader, Widget } from '../../components';

import { IWithPortfolioInfoProps } from '../../../../components/HOC/withPortfolioInfo';
import { IPortfolioInfoState, IPortfolioStructureState } from '../../../../services/reducers/PortfolioReducers';

import s from './TwrPerformance.module.scss';
import { DASHBOARD_PORTFOLIO_HELP_TEXT } from '../../../../services/constants/tooltipContextHelp';
import { currencyFormat } from '../../../../services/utils/CurrencyHelpers';
import { fetchDynamicVolatility } from '../../../../services/utils/RiskHelpers';
import axios, { AxiosPromise } from 'axios';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { reduxErrorStatusHandler } from '../../../../services/utils/ReduxHelper';
import PerformanceChartWidget from '../../components/PerformanceChartWidget/PerformanceChartWidget';
import { LineData } from '../../../../models/LineData';
import { CognitoHelper } from '../../../../services/utils/CognitoHelper';
import Analytics from '../../../../services/utils/Analytics';
import { ASSET_LABEL_NAMES } from '../../../../services/constants/assetClasses';
import { ALLOCATION_TYPES, DetalizationType } from '../../../../services/constants/constants';
import { getApiUrl } from '../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../services/utils/PeriodHelper';
import { Loader } from '../../../../components';
import { IWidgetProps } from '../../components/WidgetsBlock/Widget/Widget';
import { Card } from '@iliotech/storybook';
import { PortfolioSubHeader } from '../../components/PortfolioHeader/PortfolioSubHeader';
import useApi from '../../../../services/hooks/useApi';
import { usePortfolio } from '../../../../services/hooks/usePortfolio';
import { usePeriod } from '../../../../services/context/PeriodContext';
import { useCustodiansFilter } from '../../../../services/hooks/useCustodiansFilter';

interface IMapStateToProps {
  portfolioAssetsIds: IPortfolioInfoState;
  portfolioStructureAssetIds: IPortfolioStructureState;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  isLoaded: boolean;
  indexPerformance: LineData;
  portfolioPerformance: LineData;
  performancePeriod: IPeriod;
  indexName: string;
}

interface IDispatchToProps {
  changePerformancePeriod: (period: IPeriod) => void;
  // fetchPortfolioInfo: (portfolioId: string, custodians?: string[]) => AxiosPromise<IPortfolio>;

  fetchPortfolioPerformance: (portfolioId: string, period: IPeriod, custodians?: string[]) => AxiosPromise<LineData>;
  fetchIndexPerformance: (indexId: string, period: IPeriod, custodians?: string[]) => AxiosPromise<LineData>;

  resetDashboard: () => void;
}

type Props = IMapStateToProps &
  IDispatchToProps &
  IWithPortfolioInfoProps &
  Exclude<IWidgetProps, 'type | renderHeaderTools'>;

const TwrPerformanceContainer = ({ ...widgetProps }: Props) => {
  const { portfolioInfo, portfolioCurrencyFormatter } = usePortfolio();
  const portfolio = portfolioInfo.data!;
  const { period } = usePeriod();
  const { selectedCustodiansArray } = useCustodiansFilter();
  const [portfolioAssetsIds, setPortfolioAssetsIds] = useState<string[]>([]);
  const [volatilityScore, setVolatilityScore] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [assetsPerformance, setAssetsPerformance] = useState([]);
  const [error, setError] = useState();

  const portfolioPerformance = useApi(
    getApiUrl('portfolio.performance'),
    {
      method: 'POST',
      data: {
        portfolioId: portfolio?.id,
        period: PeriodHelper.preparePeriodForRequest(period),
        detalization: DetalizationType.Daily,
        custodians: selectedCustodiansArray || [],
      },
    },
    {
      enabled: false,
    }
  );

  const indexPerformance = useApi(
    getApiUrl('dashboard.indexPerformance'),
    {
      method: 'POST',
      data: {
        id: portfolio?.id,
        period: PeriodHelper.preparePeriodForRequest(period),
        detalization: DetalizationType.Daily,
        custodians: selectedCustodiansArray || [],
      },
    },
    {
      enabled: false,
    }
  );

  function _fetchData(): Promise<any> {
    const portfolioId = portfolio?.id;

    const assetType: ALLOCATION_TYPES = ALLOCATION_TYPES.AssetClass;
    console.log('portfolioAssetsIds', portfolioAssetsIds);
    const fetchAssetsValues: any = [];
    portfolioAssetsIds.map((el) =>
      fetchAssetsValues.push(_fetchPortfolioPerformanceByAsset(portfolioId, { id: el, type: assetType }))
    );
    console.log('fetchAssetsValues', fetchAssetsValues);
    return Promise.all(fetchAssetsValues);
  }

  const _fetchPortfolioPerformanceByAsset = (portfolioId: string, asset?: { id: string; type: ALLOCATION_TYPES }) => {
    setIsLoading(true);
    const url = getApiUrl('portfolio.performance');
    const data = {
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(period),
      asset,
      detalization: DetalizationType.Daily,
    };

    const formattedResponse: any[] | PromiseLike<any[]> = [];

    const cancelTokenSource = axios.CancelToken.source();
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });

    return apiRequest.post(url, data).then((result) => {
      formattedResponse.push({ assetId: asset ? asset.id : 'Portfolio', assetPerformance: result.data.data });
      return formattedResponse;
    });
  };

  const firstFetch = () => {
    return Promise.all([indexPerformance.refetch(), portfolioPerformance.refetch()]).then(() => {
      if (portfolioInfo) {
        setPortfolioAssetsIds([...portfolio.allocation.AssetClass].map((item: any) => item.id));
      }

      Analytics.trackPageView('TWR Dashboard');

      fetchDynamicVolatility(portfolio.id).then((val: any) => {
        if (val[0] === null || isNaN(val[0]) || val[0].insufficientData) {
          setVolatilityScore(1000);
        } else {
          setVolatilityScore(val[0]);
        }
      });
    });
  };

  useEffect(() => {
    if (portfolioAssetsIds.length) {
      CognitoHelper.withAuthorisationToken(() => {
        setIsLoading(true);
        _fetchData()
          .then((result) => {
            setAssetsPerformance(result);
            setIsLoading(false);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              return err;
            }
            reduxErrorStatusHandler(error);
            setIsLoading(false);
            setError(error);
          });
      });
    }
  }, [portfolioAssetsIds]);

  useEffect(() => {
    // reset dashboard
    if (!portfolioInfo.isFetched) {
      return;
    }
    firstFetch();
  }, [portfolioInfo.isFetched, period]);

  const loading = portfolioPerformance.isLoading || indexPerformance.isLoading || isLoading;

  if (loading) {
    return <Loader />;
  }

  const performanceData = assetsPerformance;

  const volScore = volatilityScore === 10000 ? 'Insufficient Data' : volatilityScore + '%';

  const chartsBlock = performanceData.map((asset: any, idx: number) => {
    const chartName = ASSET_LABEL_NAMES[asset[0].assetId];
    const assetPerformanceData = asset[0].assetPerformance;

    const numToSkip =
      assetPerformanceData.findIndex(
        (entry: [number, number, number, number]) => entry[1] !== 0 || entry[2] !== 0 || entry[3] !== 0
      ) - 1;
    const startDate = assetPerformanceData[numToSkip]?.[0] ?? '2018-01-01';
    const indexNumToSkip = (indexPerformance.data?.data || []).findIndex(
      (entry: any) => (entry[0] as string).localeCompare(startDate) > -1
    );
    // console.log(assetPerformanceData[0]?.[0], indexPerformance[0]?.[0])
    // console.log(assetPerformanceData[numToSkip]?.[0], indexPerformance[numToSkip]?.[0])

    const adjustedIndexPerformance = getAdjustedIndexPerformance(indexPerformance.data?.data, indexNumToSkip, startDate);

    return (
      <Card className={s.chartBox} key={idx}>
        <Widget colNum={4} title={chartName}>
          <PerformanceChartWidget
            selectedPeriod={period}
            isDataLoaded={!loading}
            portfolioPerformance={assetPerformanceData.slice(Math.max(numToSkip, 0))}
            indexPerformance={adjustedIndexPerformance}
            indexName={portfolio.benchmarkIndex.name}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            hidePeriodSelector={true}
            performanceToggle={0}
            useWidgetTitle={true}
            customLegendTitles={['Asset Class']}
          />
        </Widget>
      </Card>
    );
  });

  chartsBlock.unshift(
    <Card key={-1}>
      <Widget colNum={8} title={'Total Performance'} titleInfo={DASHBOARD_PORTFOLIO_HELP_TEXT.ChartPerformance}>
        <PerformanceChartWidget
          selectedPeriod={period}
          isDataLoaded={true}
          portfolioPerformance={portfolioPerformance.data?.data || []}
          indexPerformance={indexPerformance.data?.data}
          indexName={portfolio.benchmarkIndex.name}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          hidePeriodSelector={true}
          performanceToggle={0}
          useWidgetTitle={true}
        />
      </Widget>
    </Card>
  );

  const stats = [
    {
      value: portfolioCurrencyFormatter(portfolio.totalProfitAndLoss),
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: currencyFormat(portfolio.performance, 2),
      label: 'Performance (TWR)',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
    },
    {
      value: portfolioCurrencyFormatter(portfolio.totalWealth),
      label: 'Portfolio Value',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
    },
    {
      value: volScore,
      label: 'Portfolio Volatility',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPortfolioVolatility },
    },
  ];

  return (
    <div>
      <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false} />
      <PortfolioSubHeader stats={stats} title={'Time Weighted Return'} />

      <div className={s.chartBox}>
        {/*<Widget colNum={8} title={'Total Performance'} titleInfo={DASHBOARD_PORTFOLIO_HELP_TEXT.ChartPerformance}>
            <PerformanceChartWidgetContainer hidePeriodSelector={true} performanceToggle={0} />
          </Widget>*/}
        <Widget {...widgetProps}>
          <div>{chartsBlock}</div>
        </Widget>
      </div>
    </div>
  );
};

const getAdjustedIndexPerformance = (indexPerformance: LineData, numToSkip: number, startDate: string) => {
  if (!numToSkip || !indexPerformance || numToSkip > indexPerformance.length - 1) {
    return indexPerformance;
  }

  const data: LineData = [[startDate, 0]];
  // console.log([indexPerformance[numToSkip][0], 0])
  let i = numToSkip + 1;

  let delta: number;
  let curr: number;
  let prev: number;
  let tracker: number = 1;

  while (i < indexPerformance.length) {
    prev = (indexPerformance[i - 1][1] + 100) / 100;
    curr = (indexPerformance[i][1] + 100) / 100;

    delta = curr - prev;

    tracker *= 1 + delta / prev;

    // console.log({curr, prev, delta, tracker, val: (tracker - 1) * 100})

    data.push([indexPerformance[i][0], (tracker - 1) * 100]);

    i++;
  }

  return data;
};

export default TwrPerformanceContainer;
