import React, { ReactNode, useEffect, useState } from 'react';
import s from '../../../Dashboard.module.scss';
import { Widget } from '../../../../../../../components';
import {
  DASHBOARD_PORTFOLIO_FEES_HELP_TEXT,
  DASHBOARD_PORTFOLIO_INCOME_HELP_TEXT,
} from '../../../../../../../../../services/constants/tooltipContextHelp';
import ReactEcharts from 'echarts-for-react';
import { ToggableChartLegend } from '../../../../../../../../../components/ToggableChartLegend';
import { getBarColor, getIncomeChartOptions } from '../../../../../../Reports/reportsChartOptions';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import { currencyFormatterV2 } from '../../../../../../../../../services/utils/CurrencyHelpers';
import useBarDataToggableLegend from '../../../../../../../../../services/hooks/useBarDataToggableLegend';

enum BarType {
  Solid = 'Solid',
  Hatching = 'Histing',
  Line = 'Line',
}

interface IProps {
  eChartsRef: any;
  chartData: IBarData[];
  renderIncomePeriod: () => ReactNode;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency: string;
}

const FeesAndCharges = ({
  selectedCurrency,
  chartData,
  renderIncomePeriod,
  eChartsRef,
  portfolioCurrencyFormatter,
}: IProps) => {
  const { toggledData, legend, onLegendItemClick } = useBarDataToggableLegend({
    chartData,
    portfolioCurrencyFormatter,
    selectedCurrency,
    toggableTotal: false,
  });

  return (
    <Widget
      title="Fees and Charges"
      className={s.defaultContainer}
      renderHeaderTools={renderIncomePeriod}
      titleInfo={DASHBOARD_PORTFOLIO_FEES_HELP_TEXT.ChartInfo}
    >
      <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
      {chartData && (
        <ReactEcharts
          style={{ height: '420px', width: '100%', flexGrow: '1' }}
          option={getIncomeChartOptions(
            toggledData,
            portfolioCurrencyFormatter,
            selectedCurrency,
            undefined,
            true,
            true
          )}
          ref={eChartsRef}
          notMerge={true}
          lazyUpdate={true}
        />
      )}
    </Widget>
  );
};

export default FeesAndCharges;
