import { ReduxHelper } from '../utils/ReduxHelper';

import { AssetsActions } from '../actions/AssetsActions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { RiskAssetClassesAction } from '../actions/RiskAssetClasses';

export interface IRiskAssetsClassesState extends ILoadingDataState {
  data: IAssetClass[];
}

const INITIAL_STATE: IRiskAssetsClassesState = ReduxHelper.createInitialState<IAssetClass[]>([]);

const riskAssetClassesReducer = ReduxHelper.createHttpRequestReducer<IRiskAssetsClassesState>(
  INITIAL_STATE,
  RiskAssetClassesAction.RISK_ASSET_CLASSES_REQUEST,
  RiskAssetClassesAction.RISK_ASSET_CLASSES_SUCCESS,
  RiskAssetClassesAction.RISK_ASSET_CLASSES_ERROR
);

export default riskAssetClassesReducer;
