import React from 'react';

export function TagsArrows(props: any) {
  const { color, portfolioBeta, slotPosition, nrOfAxisSlots, isNegativeBetas, axisTicks } = props;

  const xStart = (slotPosition * 100) / nrOfAxisSlots + 100 / nrOfAxisSlots / 2;
  let xEnd = (100 * portfolioBeta) / axisTicks;

  // Adjusting coordinates to a cartesian type axis
  if (isNegativeBetas) {
    if (portfolioBeta >= 0) {
      xEnd = (100 * portfolioBeta) / 6 + (100 / 6) * 3;
    } else {
      xEnd = (100 / 6) * 3 - (100 * Math.abs(portfolioBeta)) / 6;
    }
  }

  return (
    <svg width="100%" height="100%">
      <defs>
        <marker id={'arrowhdPF'} markerWidth="10" markerHeight="8" refX="0" refY="4" orient="auto">
          <polygon points="0 0, 5 4, 0 8" fill={color} />
        </marker>
      </defs>
      <line x1={xStart + '%'} y1="0" x2={xStart + '%'} y2="10%" strokeWidth="2" stroke={color} />
      <line x1={xStart + '%'} y1="10%" x2={xEnd + '%'} y2="70%" strokeWidth="2" stroke={color} />
      <line
        x1={xEnd + '%'}
        y1="70%"
        x2={xEnd + '%'}
        y2="80%"
        stroke={color}
        strokeWidth="2"
        markerEnd={'url(#arrowhdPF)'}
      />
    </svg>
  );
}
