import ReactEcharts from 'echarts-for-react';
import React, { Fragment, useEffect } from 'react';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { ILegendItemProps } from '../../../../../../../../components/ChartLegend/components/LegendItem';
import { FormatHelper, VALUE_TYPE } from '../../../../../../../../services/utils/FormatHelper';
import { LegendVariant } from '../../../../../../../../services/constants/charts';
import { getProfitChartOptions, getWaterFallChartOptions } from '../../../../../../components/ProfitChart/chartConfigs';
import { ChartLegend } from '../../../../../../../../components/ChartLegend';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';
import { Loader } from '../../../../../../../../components';

interface IProps {
  isWaterFall: boolean;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  legendVariant: string;
  onItemClick?(data: [string, number, number]): void;
  performanceToggle?: number | 1;
  showLegend?: boolean | true;
  period: IPeriod;
  selectedCustodians: string[];
  portfolioId: string;
}
const SummaryProfitChart = ({
  portfolioId,
  period,
  showLegend,
  performanceToggle,
  legendVariant,
  isWaterFall = false,
  portfolioCurrencyFormatter,
  onItemClick,
  selectedCustodians,
}: IProps) => {
  const profitChartData = useApi(
    getApiUrl('portfolio.profit.chart'),
    {
      method: 'POST',
      data: {
        portfolioId,
        period: PeriodHelper.preparePeriodForRequest(period, 'MONTH_2_MONTH'),
        custodians: selectedCustodians || [],
        ok: true,
      },
    },
    { enabled: false }
  );

  useEffect(() => {
    if (portfolioId) {
      profitChartData.refetch();
    }
  }, [period, selectedCustodians, portfolioId]);

  function getLegend(chartData: IBarData[], variant: string): ILegendItemProps[] {
    let totalValue = 0;
    let legendCompiled = chartData.map((item) => {
      const value: number = item.data.reduce<number>((acum: number, cur: [string, number]) => {
        return acum + cur[1];
      }, 0);

      totalValue += value;

      const splitData =
        performanceToggle === 0
          ? chartData[0].data.map((el: any[]) => [el[0], el[2]])
          : chartData[0].data.map((el: any[]) => [el[0], el[1]]);

      const totalVal = splitData.reduce<number>((acum: number, cur: any[]) => {
        return acum + cur[1];
      }, 0);

      const toggledValue =
        performanceToggle === 0
          ? `${value > 0 ? '+' : ''}${FormatHelper.round(totalVal, 100)}%`
          : portfolioCurrencyFormatter(totalVal);

      const legendItemName = performanceToggle === 0 ? 'Portfolio' : performanceToggle === 1 ? 'P&L' : item.name;

      return {
        name: legendItemName,
        hatching: item.type === BarType.Hatching,
        value: toggledValue,
        color: '#6677cc',
      };
    });

    if (variant === LegendVariant.Totals) {
      legendCompiled = [
        {
          name: 'Total',
          hatching: false,
          value: portfolioCurrencyFormatter(totalValue),
          color: '#6677cc',
        },
      ];
    }

    return legendCompiled;
  }

  const hasLegend = showLegend === undefined ? 'true' : 'false';

  let legend = <span />;

  if (profitChartData.isFetching || !profitChartData.data) {
    return <Loader />;
  }

  if (showLegend || showLegend === undefined) {
    legend = <ChartLegend legend={getLegend(profitChartData.data, legendVariant)} />;
  }

  const chartOpt = isWaterFall
    ? getWaterFallChartOptions(profitChartData.data, portfolioCurrencyFormatter)
    : getProfitChartOptions(profitChartData.data, VALUE_TYPE.MONEY, portfolioCurrencyFormatter, performanceToggle);

  return (
    <div style={{ flex: 1 }}>
      <Fragment>
        {legend}
        <ReactEcharts
          style={{ flex: 1, height: '420px', width: '100%', maxHeight: '350px' }}
          option={{
            ...chartOpt,
          }}
          onEvents={{
            click: (o) => onItemClick?.(o.data),
          }}
          notMerge={true}
        />
      </Fragment>
    </div>
  );
};

export default SummaryProfitChart;
