import * as React from 'react';
import s from './EsgLegend.module.scss';
import Env from '../../../../../../static/images/icons/esg/e.svg';
import Esg from '../../../../../../static/images/icons/esg/esg.svg';
import S from '../../../../../../static/images/icons/esg/s.svg';
import G from '../../../../../../static/images/icons/esg/g.svg';
import { Popover, Whisper } from 'rsuite';

interface IProps {
  loaded: boolean;
}

const EsgElement = ({ icon, text, loaded }: { icon: string; text: string; loaded: boolean }) => {
  const speaker = <Popover title={text} />;

  return (
    <Whisper trigger="hover" placement={'auto'} speaker={speaker}>
      <div className={s.esgElement} style={{ opacity: loaded ? 1 : 0 }}>
        <div className={s.iconContainer}>
          <img src={icon} />
        </div>
      </div>
    </Whisper>
  );
};

const EsgLegend = ({ loaded }: IProps) => {
  return (
    <div className={s.container}>
      <EsgElement loaded={loaded} icon={Esg} text={'ESG Total score'} />
      <EsgElement loaded={loaded} icon={Env} text={'Environmental score'} />
      <EsgElement loaded={loaded} icon={S} text={'Social score'} />
      <EsgElement loaded={loaded} icon={G} text={'Governance score'} />
    </div>
  );
};

export default EsgLegend;
