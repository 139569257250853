import React from 'react';
import useApi from '../useApi';
import { API, getApiUrl } from '../../constants/endpoints';

const DEFAULT_STATE = {
  capabilities: [{ key: 'Equities' }],
  actions: [],
  preferences: {},
  accounts: [],
  capabilityNames: [],
  isFetching: true,
  metadata: {
    domains: [],
    customSourceData: [],
    allowedViews: [],
  },
};

export const useCapabilities = () => {
  const request = useApi<IUserCapabilities>(getApiUrl('userCapabilities'), {}, {staleTime: 1000 * 60 * 5});

  const [state, setState] = React.useState<IUserCapabilities & { capabilityNames: string[]; isFetching: boolean }>(
    DEFAULT_STATE
  );

  React.useEffect(() => {
    if (request.data) {
      const capabilityNames = request.data?.capabilities?.map((capability) => capability.key) ?? [];
      setState({
        ...request.data,
        capabilityNames,
        isFetching: request.isFetching,
      });
    }
  }, [request.dataUpdatedAt]);

  return state;
};
