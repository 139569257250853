import {
  IPortfolioAssetClassItem,
  IPortfolioPnlByAssetClass,
} from '../../../../../../../../models/IPortfolioPnlByAttribution';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import React, { useEffect, useState } from 'react';
import { ASSET_LABEL_NAMES } from '../../../../../../../../services/constants/assetClasses';
import ReactEcharts from 'echarts-for-react';
import { getColor, getPnlByAssetClassChartOptionsNew } from './PnlByAssetClassChartConfigNew';
import { IWidgetProps } from '../../../../../../components/WidgetsBlock/Widget/Widget';
import { ToggableChartLegend } from '../../../../../../../../components/ToggableChartLegend';

interface IOwnProps {
  chartData: IPortfolioPnlByAssetClass;
  selectedPeriod: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  hidePeriodSelector?: boolean;
  onItemClick?(dateString: string): void;
}

type IProps = IOwnProps & Exclude<IWidgetProps, 'type | renderHeaderTools'>;

const PnlByAssetClassChartNew = ({
  chartData,
  selectedPeriod,
  portfolioCurrencyFormatter,
  hidePeriodSelector,
  onItemClick,
  ...widgetProps
}: IProps) => {
  const eChart = React.createRef<ReactEcharts>();
  const [toggledData, setToggledData] = useState<IPortfolioPnlByAssetClass>(chartData);
  const [hideTotal, setHideTotal] = useState<boolean>(false);
  const [legend, setLegend] = useState<{ color: string; label: string; value: string }[]>([]);

  useEffect(() => {
    setToggledData(chartData);
  }, [chartData]);

  useEffect(() => {
    setLegend(getLegend());
  }, [toggledData, hideTotal]);

  const isItemToggled = (key: string) => toggledData.data.some((item) => ASSET_LABEL_NAMES[item.assetClass] === key);

  const removeItem = (key: string) => {
    setToggledData({
      ...toggledData,
      data: [...toggledData.data.filter((item) => ASSET_LABEL_NAMES[item.assetClass] !== key)],
    });
  };

  const addItem = (key: string) => {
    const itemToAdd = chartData.data.find((item: any) => ASSET_LABEL_NAMES[item.assetClass] === key);

    if (itemToAdd) {
      setToggledData({
        ...toggledData,
        data: [itemToAdd, ...toggledData.data],
      });
    }
  };

  const toggleTotal = () => {
    setHideTotal((v) => !v);
  };

  const onLegendItemClick = (key: string) => {
    if (key === 'Total') {
      toggleTotal();
      return;
    }
    const hasItem = isItemToggled(key);

    if (hasItem) {
      removeItem(key);
    } else {
      addItem(key);
    }
  };

  const getLegend = () => {
    const getAssetClassName = (asset: IPortfolioAssetClassItem): string | undefined => {
      if (asset.assetClass && asset.assetClass in ASSET_LABEL_NAMES) {
        return ASSET_LABEL_NAMES[asset.assetClass];
      }
      return asset.assetClass;
    };

    const total = toggledData.data.reduce((acc, item) => acc + item.assetClassTotal, 0);
    const assembledLegend = [
      {
        label: 'Total',
        value: portfolioCurrencyFormatter(total),
        color: hideTotal ? 'white' : '#a9b2d1',
      },
    ];

    chartData.data.map((el: any, idx: number) => {
      assembledLegend.push({
        label: getAssetClassName(el) || '',
        value: portfolioCurrencyFormatter(el.assetClassTotal),
        color: isItemToggled(getAssetClassName(el) || '') ? getColor(el) || '' : 'white',
      });
    });

    return assembledLegend;
  };

  return (
    <>
      <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
      <ReactEcharts
        ref={eChart}
        onEvents={{
          click: ({ data }) => {
            onItemClick?.(data[0]);
          },
        }}
        style={{ height: '420px', width: '100%', flex: '1', maxHeight: '400px' }}
        option={getPnlByAssetClassChartOptionsNew(selectedPeriod, toggledData, portfolioCurrencyFormatter, hideTotal)}
      />
    </>
  );
};

export default PnlByAssetClassChartNew;
