import { AxiosPromise } from 'axios';
import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IAllocationDict } from '../../../../../../../models/IAllocationDict';

import { ITopListItem } from '../../../../../../../models/ITopListItem';
import { LineData } from '../../../../../../../models/LineData';
import { DashboardActions } from '../../../../../../../services/actions';
import {
  ASSET_CLASSES_CONFIG,
  ASSET_SUB_CLASSES_CONFIG,
  IAssetClassOptions,
  IAssetSubClassOptions,
} from '../../../../../../../services/constants/assets';
import { ALLOCATION_TYPES } from '../../../../../../../services/constants/constants';
import { ASSETS } from '../../../../../../../services/constants/assetClasses';
import { TRANSACTION_TYPES } from '../../../../../../../services/mock/transactionTypes';
import { IRootState } from '../../../../../../../services/store';
import { AsyncActionDispatch } from '../../../../../../../services/utils/ReduxHelper';
import moment from 'moment';

export interface IAssetReportProps {
  noData: boolean;
  assetDataLoaded: boolean;
  portfolioId: string;
}

interface IMapStateToProps {
  allocations: IAllocationDict;
  performancePeriod: IPeriod;
  profitPeriod: IPeriod;
  topListByPnl: ITopListItem[];
  topListByPnlOrder: IOrder;
}

interface IDispatchToProps {
  fetchPortfolioPerformanceByAsset: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod
  ) => AxiosPromise<LineData>;
  fetchIndexPerformance: (
    indexId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod
  ) => AxiosPromise<LineData>;
  fetchProfit: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod
  ) => AxiosPromise<IBarData[]>;
  fetchAllocationsList: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    allocations: ALLOCATION_TYPES[]
  ) => AxiosPromise<IAllocationDict>;
  fetchTotalValues: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string }
  ) => AxiosPromise<IDashboardAssetClass>;
  fetchBreakdownReport: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string }
  ) => AxiosPromise<IBreakdownReport>;
  resetAssetSceneData: () => void;
}

type WrappedProps = RouteComponentProps<{
  portfolioId: string;
  classId: string;
  subClassId?: string;
}> &
  IMapStateToProps &
  IDispatchToProps;

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    topListByPnl: state.dashboard.topListByPnl.data,
    topListByPnlOrder: state.dashboard.topListByPnl.order,
    allocations: state.dashboard.allocations.data,
    performancePeriod: state.dashboard.performance.period,
    profitPeriod: state.dashboard.profitAttribution.period,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPortfolioPerformanceByAsset: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod
  ) => dispatch(DashboardActions.fetchPortfolioPerformanceByAsset(portfolioId, asset, period)),
  fetchProfit: (portfolioId: string, asset: { type: ALLOCATION_TYPES; id: string }, period: IPeriod) =>
    dispatch(DashboardActions.fetchProfitAttributionByAsset(portfolioId, asset, period)),
  fetchIndexPerformance: (indexId: string, asset: { type: ALLOCATION_TYPES; id: string }, period: IPeriod) =>
    dispatch(DashboardActions.fetchIndexPerformanceByAsset(indexId, asset, period)),
  fetchAllocationsList: (
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    allocations: ALLOCATION_TYPES[]
  ) => {
    if (asset.id === ASSETS.cash) {
      const allocationsMod = allocations.filter((a) => a !== 'IndustrySector');
      return dispatch(DashboardActions.fetchAllocationsByAsset(portfolioId, asset, allocationsMod));
    }

    if (asset.id === ASSETS.fund) {
      allocations.push(ALLOCATION_TYPES.FundStrategy);
    }

    if (asset.id === TRANSACTION_TYPES.equities) {
      allocations.push(ALLOCATION_TYPES.ETFSubClass);
    }

    if (asset.id === ASSETS.realEstate) {
      allocations = [
        ALLOCATION_TYPES.AssetSubClass,
        ALLOCATION_TYPES.Region,
        ALLOCATION_TYPES.Currency,
        ALLOCATION_TYPES.Custodian,
        ALLOCATION_TYPES.PropertySector,
        ALLOCATION_TYPES.Industry,
      ];
    }
    return dispatch(DashboardActions.fetchAllocationsByAsset(portfolioId, asset, allocations));
  },
  fetchBreakdownReport: (portfolioId: string, asset: { type: ALLOCATION_TYPES; id: string }) =>
    dispatch(DashboardActions.fetchBreakdownReport(portfolioId, asset)),
  fetchTotalValues: (portfolioId: string, asset: { type: ALLOCATION_TYPES; id: string }) =>
    dispatch(DashboardActions.fetchTotalValues(portfolioId, asset)),
  resetAssetSceneData: () => dispatch(DashboardActions.resetAssetSceneData()),
});

export function withAssetLogic<T extends IAssetReportProps>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<any> {
  const wrapper: FunctionComponent<T & WrappedProps> = (props) => {
    const {
      topListByPnl,
      allocations,
      match: {
        params: { portfolioId, classId, subClassId },
      },
    } = props;

    const noData = topListByPnl.length;

    return (
      <div>
        <AssetDataLoader {...props} {...{ portfolioId, classId, subClassId }}>
          {({ assetDataLoaded }) => (
            <WrappedComponent
              {...props}
              assetDataLoaded={assetDataLoaded}
              allocations={allocations}
              portfolioId={portfolioId}
              noData={noData}
            />
          )}
        </AssetDataLoader>
      </div>
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(React.memo(wrapper) as any));
}

type DataLoaderProps = {
  portfolioId: string;
  classId: string;
  subClassId?: string;
  children({ assetDataLoaded }: { assetDataLoaded: boolean }): React.ReactElement;
};
const AssetDataLoader: FC<IMapStateToProps & IDispatchToProps & DataLoaderProps> = (props) => {
  const [generalDataLoaded, setGeneralDataLoaded] = useState(false);
  const [performanceDataLoaded, setPerformanceDataLoaded] = React.useState<boolean>(false);
  const [profitDataLoaded, setProfitDataLoaded] = React.useState<boolean>(false);

  const {
    topListByPnl,
    allocations,
    performancePeriod,
    profitPeriod,
    fetchPortfolioPerformanceByAsset,
    fetchIndexPerformance,
    fetchProfit,
    fetchAllocationsList,
    fetchTotalValues,
    fetchBreakdownReport,
    resetAssetSceneData,
    portfolioId,
    classId,
    subClassId,
    children,
  } = props;

  const assetId = subClassId ? subClassId : classId;
  const AssetInfo: IAssetClassOptions | IAssetSubClassOptions = subClassId
    ? ASSET_SUB_CLASSES_CONFIG[subClassId]
    : ASSET_CLASSES_CONFIG[classId];

  const type: ALLOCATION_TYPES = subClassId ? ALLOCATION_TYPES.AssetSubClass : ALLOCATION_TYPES.AssetClass;

  useEffect(() => {
    let isCancelled = false;
    Promise.all([
      fetchAllocationsList(portfolioId, { id: assetId, type }, AssetInfo.allocations!),
      fetchBreakdownReport(portfolioId, { id: assetId, type }),
      fetchTotalValues(portfolioId, { id: assetId, type }),
    ]).then(() => {
      if (!isCancelled) {
        setGeneralDataLoaded(true);
      }
    });

    return () => {
      isCancelled = true;
      resetAssetSceneData();
    };
  }, []);

  useEffect(() => {
    let isCancelledPeriod = false;

    fetchProfit(portfolioId, { id: assetId, type }, profitPeriod).then(() => {
      if (!isCancelledPeriod) {
        setProfitDataLoaded(true);
      }
    });
    return () => {
      isCancelledPeriod = true;
    };
  }, [profitPeriod]);

  const toDate = performancePeriod.to ? moment(performancePeriod.to).format() : '';
  const fromDate = performancePeriod.from ? moment(performancePeriod.from).format() : '';

  useEffect(() => {
    let isCancelledPerformance = false;

    Promise.all([
      fetchPortfolioPerformanceByAsset(portfolioId, { id: assetId, type }, performancePeriod),
      fetchIndexPerformance(portfolioId, { id: assetId, type }, performancePeriod),
    ]).then(() => {
      if (!isCancelledPerformance) {
        setPerformanceDataLoaded(true);
      }
    });
    return () => {
      isCancelledPerformance = true;
    };
  }, [toDate, fromDate, performancePeriod.type]);

  const assetDataLoaded = generalDataLoaded && performanceDataLoaded && profitDataLoaded;

  if (!children) {
    return null;
  }

  return children({ assetDataLoaded });
};

export default withAssetLogic;
