import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment from 'moment';

import { BarType, DEFAULT_TOOLTIP_OPTIONS } from '../../../../../../../../../../../../services/constants/charts';
import { ICurrencyFormatter } from '../../../../../../../../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../../../../../../../../services/utils/ChartHelper';
import { ColorsHelper } from '../../../../../../../../../../../../services/utils/ColorsHelper';
import { FormatHelper } from '../../../../../../../../../../../../services/utils/FormatHelper';

type FormatterParam = echarts.EChartOption.Tooltip.Format;

export function getChartOptions(
  chartData: any[],
  portfolioCurrencyFormatter: ICurrencyFormatter,
  selectedCurrency: string = 'All',
  longColor: string = '05a8aa',
  isCrypto: boolean = false
): echarts.EChartOption {
  const chartOpt = ChartHelper.getChartOptions();
  if (!chartData.length) {
    return chartOpt;
  }

  const chartSeries: any = [];

  chartData[0].data.map((item: any) => {
    chartSeries.push([
      item.date,
      item.longTotal,
      item.shortTotal,
      item.longTotal + item.shortTotal,
      item.longDetails.concat(item.shortDetails),
    ]);
  });

  const series1Color = longColor; // Long Positions
  const series2Color = '#3b4680'; // Short Positions
  const series3Color = '#c2b261'; // Dividends Total

  const categories = chartSeries.map((value: any) => value[0]);
  const longPositionsSeries = chartSeries.map((value: any) => value[1]);
  const shortPositionsSeries = chartSeries.map((value: any) => value[2]);
  const totalIncomeSeries = chartSeries.map((value: any) => value[3]);

  const mergeOpt: echarts.EChartOption = {
    xAxis: {
      data: categories,
      axisLabel: {
        formatter(value: string, index: number): string {
          return moment(value).format('MMM') + `'` + moment(value).format('YY');
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return selectedCurrency === 'All'
            ? portfolioCurrencyFormatter(value)
            : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(value, 0);
        },
      },
    },
    series: [
      {
        name: 'Long Positions',
        data: longPositionsSeries,
        type: 'bar',
        stack: 'one',
        barMaxWidth: 24,
        itemStyle: {
          color: series1Color,
        },
      },
      {
        name: 'Short Positions',
        data: shortPositionsSeries,
        type: 'bar',
        stack: 'one',
        barMaxWidth: 24,
        itemStyle: {
          color: series2Color,
        },
      },
      ...(!isCrypto
        ? [
            {
              name: 'Total Income',
              data: totalIncomeSeries,
              type: 'line',
              itemStyle: {
                color: series3Color,
              },
            },
          ]
        : []),
    ],

    tooltip: {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (params: FormatterParam | FormatterParam[]): string => {
        let tooltipText = '';

        const textGen = (item: FormatterParam, instruments: any = [], longVal: any, shortVal: any) => {
          const value = Array.isArray(item.value) ? item.value[1] : item.value;

          const seriesMarker: string = ChartHelper.getTooltipLegend(item);
          const seriesName = moment(item.name).format('MMM') + `'` + moment(item.name).format('YY');

          const longPosMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: ${series1Color}"></span>`;
          const longPosVal =
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(longVal)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(longVal, 0);
          const shortPosMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: ${series2Color}"></span>`;
          const shortPosVal =
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(shortVal)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(shortVal, 0);
          const totalMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: ${series3Color}"></span>`;
          const totalVal =
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(longVal + shortVal)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(longVal + shortVal, 0);

          const instrumentsText: any = [];

          instruments.map((i: any) => {
            const marker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: ${series1Color}"></span>`;
            const val = isCrypto
              ? ' ' +
                i.valueLocal.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 6,
                }) +
                ' / ' +
                portfolioCurrencyFormatter(i.valueBase)
              : i.valueLocal !== i.valueBase
              ? i.currencyLocal.symbol +
                ' ' +
                FormatHelper.formatNumberAsValue(i.valueLocal, 0) +
                ' / ' +
                portfolioCurrencyFormatter(i.valueBase)
              : portfolioCurrencyFormatter(i.valueBase);
            instrumentsText.push(`${marker}${i.instrumentName}: ${val}`);
          });

          if (isCrypto) {
            tooltipText += `${seriesMarker}${seriesName}: ${totalVal}<br></br>Assets:<br> ${instrumentsText.join(
              '<br>'
            )}`;
          } else {
            tooltipText += `${seriesMarker}${seriesName}: ${totalVal}<br><br>${longPosMarker}Long Positions: ${longPosVal}<br>${shortPosMarker}Short Positions: ${shortPosVal}<br>${totalMarker}Total: ${totalVal}<br></br>Assets:<br> ${instrumentsText.join(
              '<br>'
            )}`;
          }
        };

        if (Array.isArray(params)) {
          const longVal = params[0].value;
          const shortVal = params[1].value;
          const instruments = chartSeries[params[0].dataIndex!];
          textGen(params[0], instruments[instruments.length - 1], longVal, shortVal);
        } else {
          // @ts-ignore
          textGen(params);
        }
        return tooltipText;
      },
    },
  };
  return merge({}, chartOpt, mergeOpt);
}
