import { combineReducers } from 'redux';

import { IPortfolioErrorsTrade, IPortfolioTrade, IPortfolioValidatedTrade } from '../../models/IPortfolioTrade';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { ILoadingPaginationDataState } from '../../models/redux/ILoadingPaginationDataState';
import { SnapshotActions } from '../actions';
import { ReduxHelper } from '../utils/ReduxHelper';
import uploadTemplatesReducer, { IUploadTemplatesState } from './uploadTemplatesReducer';
import uploadTemplateResultReducer, { IUploadTemplateResultState } from './UploadTemplateResultReducer';

type ITradesState = ILoadingPaginationDataState<IPortfolioTrade>;
type ISnapshotsState = ILoadingPaginationDataState<IPortfolioSnapshot>;
type IUploadTradesState = ILoadingDataState<null>;
type ITradeOptions = ILoadingDataState<null>;
type ISnapshotFxRateState = ILoadingDataState<IFxRate | null>;
type ISavedTradesState = ILoadingPaginationDataState<IPortfolioValidatedTrade>;
type ISearchAssetsState = ILoadingDataState<IAsset[]>;
type ISearchOptionState = ILoadingDataState<IOptionIdentifier[] | null>;
type ISearchWarrantState = ILoadingDataState<IOptionIdentifier[] | null>;
type ICashAdjustmentTypesState = ILoadingDataState<IAdjustmentType[]>;

interface ILoadProcessState {
  progress: number;
}

interface IEditUnconfirmedTradeState {
  trade: IPortfolioTrade;
  errors: IErrors;
  warnings: IErrors;
  userInputs: IErrors;
  rowErrors: IPortfolioErrorsTrade[];
  isEditing: boolean;
}

export interface ISnapshotState {
  snapshots: ISnapshotsState;
  trades: ITradesState;
  uploadTemplates: IUploadTemplatesState;
  uploadTemplateResult: IUploadTemplateResultState;
  snapshotEdit: {
    savedTrades: ISavedTradesState;
    uploadTrades: IUploadTradesState;
    uploadingProcess: ILoadProcessState;
    searchAssets: ISearchAssetsState;
    searchOptions: ISearchOptionState;
    searchWarrants: ISearchWarrantState;
    searchBonds: ISearchAssetsState;
    fxRate: ISnapshotFxRateState;
    endOfDayPrice: ITradeOptions;
    previewSnapshots: ISnapshotsState;
    editedUnconfirmedTrade: IEditUnconfirmedTradeState;
    cashAdjustmentTypes: ICashAdjustmentTypesState;
  };
  snapshotFilters: Record<string, string>;
}

const INITIAL_SEARCH_STATE: ISearchAssetsState = { ...ReduxHelper.createInitialState([]) };

const searchAssets = ReduxHelper.createHttpRequestReducer<ISearchAssetsState>(
  INITIAL_SEARCH_STATE,
  SnapshotActions.SEARCH_ASSET_BY_NAME_REQUEST,
  SnapshotActions.SEARCH_ASSET_BY_NAME_SUCCESS,
  SnapshotActions.SEARCH_ASSET_BY_NAME_ERROR,
  SnapshotActions.SEARCH_ASSET_BY_NAME_RESET
);

function uploadingProcess(state = { progress: 0 }, action: IActionPayload) {
  switch (action.type) {
    case SnapshotActions.SNAPSHOT_SET_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.payload.progress,
      };
    case SnapshotActions.SNAPSHOT_UPLOAD_SUCCESS: {
      return { progress: 0 };
    }
    default:
      return state;
  }
}

const searchBonds = ReduxHelper.createHttpRequestReducer<ISearchAssetsState>(
  INITIAL_SEARCH_STATE,
  SnapshotActions.SEARCH_BONDS_BY_NAME_REQUEST,
  SnapshotActions.SEARCH_BONDS_BY_NAME_SUCCESS,
  SnapshotActions.SEARCH_BONDS_BY_NAME_ERROR,
  SnapshotActions.SEARCH_BONDS_BY_NAME_RESET
);

const INITIAL_WARRANT_SEARCH_STATE: ISearchWarrantState = { ...ReduxHelper.createInitialState([]) };

const searchWarrants = ReduxHelper.createHttpRequestReducer<ISearchWarrantState>(
  INITIAL_WARRANT_SEARCH_STATE,
  SnapshotActions.SEARCH_WARRANT_BY_NAME_REQUEST,
  SnapshotActions.SEARCH_WARRANT_BY_NAME_SUCCESS,
  SnapshotActions.SEARCH_WARRANT_BY_NAME_ERROR,
  SnapshotActions.SEARCH_WARRANT_BY_NAME_RESET
);

const INITIAL_OPTION_SEARCH_STATE: ISearchOptionState = { ...ReduxHelper.createInitialState(null) };

const searchOptions = ReduxHelper.createHttpRequestReducer<ISearchOptionState>(
  INITIAL_OPTION_SEARCH_STATE,
  SnapshotActions.SEARCH_OPTIONS_REQUEST,
  SnapshotActions.SEARCH_OPTIONS_SUCCESS,
  SnapshotActions.SEARCH_OPTIONS_ERROR,
  SnapshotActions.SEARCH_OPTIONS_RESET
);

function editedUnconfirmedTrade(
  state = {
    trade: null,
    errors: {},
    warnings: {},
    userInputs: {},
    rowErrors: [],
    isEditing: false,
  },
  action: IActionPayload
) {
  switch (action.type) {
    case SnapshotActions.START_EDIT_UNCONFIRMED_TRADE:
      return {
        ...state,
        trade: action.payload.trade,
        errors: action.payload.errors,
        warnings: action.payload.warnings,
        userInputs: action.payload.userInputs,
        rowErrors: action.payload.rowErrors,
        isEditing: true,
      };
    case SnapshotActions.FINISH_EDIT_UNCONFIRMED_TRADE: {
      return {
        trade: null,
        errors: {},
        warnings: {},
        userInputs: {},
        rowErrors: [],
        isEditing: false,
      };
    }
    default:
      return state;
  }
}

function savedTrades(
  state = ReduxHelper.createInitialPaginationState<IPortfolioValidatedTrade>([], {
    name: 'isCorrect',
    direction: 'ASC',
  }),
  action: IActionPayload
) {
  switch (action.type) {
    case SnapshotActions.SAVED_TRADES_REQUEST:
      if (state.cancelTokenSource) {
        state.cancelTokenSource.cancel();
      }
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        cancelTokenSource: action.payload.cancelTokenSource,
        error: null,
      };
    case SnapshotActions.SAVED_TRADES_SUCCESS:
      const { order, content, ...rest } = action.payload.data;
      const newContent = action.payload.data.content;

      return {
        ...state,
        ...rest,
        isFetching: false,
        isFetched: true,
        cancelTokenSource: null,
        error: null,
        content: action.payload.data.page === 0 ? action.payload.data.content : [...state.content, ...newContent],
      };
    case SnapshotActions.SAVED_TRADES_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        cancelTokenSource: null,
        error: action.payload.error,
      };
    case SnapshotActions.SAVED_TRADES_RESET:
      if (state.cancelTokenSource) {
        state.cancelTokenSource.cancel();
      }
      return {
        ...ReduxHelper.createInitialPaginationState<IPortfolioValidatedTrade>([], {
          name: 'isCorrect',
          direction: 'ASC',
        }),
      };
    case SnapshotActions.SAVED_TRADES_CHANGE_SORT:
      return { ...state, order: action.payload };
    default:
      return state;
  }
}

const DEFAULT_SNAPSHOT_FILTERS: Record<string, string> = {
  assetClass: '',
  currency: '',
  country: '',
  custodian: '',
  riskAssetClass: '',
  investmentVehicle: '',
  position: 'open',
  operation: '',
  transactionsFrom: '',
  transactionsTo: '',
  positionsFrom: '',
  positionsTo: '',
  sector: '',
  search: '',
};
type SnapshotFilterAction =
  | {
      type:
        | typeof SnapshotActions.UPDATE_SNAPSHOT_FILTER
        | typeof SnapshotActions.RESET_SNAPSHOT_FILTERS
        | typeof SnapshotActions.ALMOST_RESET_SNAPSHOT_FILTERS;
      payload: { key: string; value: string };
    }
  | { type: typeof SnapshotActions.UPDATE_SNAPSHOT_DATE_FILTER; payload: { from: string; to: string } };

const snapshotFilters = (state = DEFAULT_SNAPSHOT_FILTERS, action: SnapshotFilterAction) => {
  switch (action.type) {
    case SnapshotActions.RESET_SNAPSHOT_FILTERS: {
      return DEFAULT_SNAPSHOT_FILTERS;
    }

    case SnapshotActions.ALMOST_RESET_SNAPSHOT_FILTERS: {
      return {
        ...DEFAULT_SNAPSHOT_FILTERS,
        positionsFrom: state.positionsFrom,
        positionsTo: state.positionsTo,
      };
    }

    case SnapshotActions.UPDATE_SNAPSHOT_DATE_FILTER: {
      return {
        ...state,
        positionsFrom: action.payload.from,
        positionsTo: action.payload.to,
      };
    }

    case SnapshotActions.UPDATE_SNAPSHOT_FILTER:
      if (action.payload?.key) {
        return {
          ...state,
          [action.payload.key]: action.payload.value,
        };
      }
  }
  return state;
};

export default combineReducers<ISnapshotState>({
  snapshots: ReduxHelper.createHttpPaginationRequestReducer<ISnapshotsState>(
    ReduxHelper.createInitialPaginationState([], {
      name: 'name',
      direction: 'ASC',
    }),
    SnapshotActions.SNAPSHOT_REQUEST,
    SnapshotActions.SNAPSHOT_SUCCESS,
    SnapshotActions.SNAPSHOT_ERROR,
    SnapshotActions.SNAPSHOT_RESET,
    SnapshotActions.SNAPSHOT_CHANGE_SORT
  ),
  trades: ReduxHelper.createHttpPaginationRequestReducer<ITradesState>(
    ReduxHelper.createInitialPaginationState([], {
      name: 'tradeTime',
      direction: 'DESC',
    }),
    SnapshotActions.TRADES_REQUEST,
    SnapshotActions.TRADES_SUCCESS,
    SnapshotActions.TRADES_ERROR,
    SnapshotActions.TRADES_RESET,
    SnapshotActions.TRADES_CHANGE_SORT
  ),
  snapshotFilters,
  uploadTemplates: uploadTemplatesReducer,
  uploadTemplateResult: uploadTemplateResultReducer,

  snapshotEdit: combineReducers({
    savedTrades,
    uploadingProcess,
    searchAssets,
    searchBonds,
    searchOptions,
    searchWarrants,
    uploadTrades: ReduxHelper.createHttpRequestReducer(
      ReduxHelper.createInitialState(null),
      SnapshotActions.SNAPSHOT_UPLOAD_REQUEST,
      SnapshotActions.SNAPSHOT_UPLOAD_SUCCESS,
      SnapshotActions.SNAPSHOT_UPLOAD_ERROR
    ),
    fxRate: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IFxRate | null>>(
      ReduxHelper.createInitialState(null),
      SnapshotActions.FX_RATE_REQUEST,
      SnapshotActions.FX_RATE_SUCCESS,
      SnapshotActions.FX_RATE_ERROR,
      SnapshotActions.FX_RATE_RESET
    ),
    endOfDayPrice: ReduxHelper.createHttpRequestReducer(
      ReduxHelper.createInitialState(null),
      SnapshotActions.PRICE_REQUEST,
      SnapshotActions.PRICE_SUCCESS,
      SnapshotActions.PRICE_ERROR,
      SnapshotActions.PRICE_RESET
    ),
    previewSnapshots: ReduxHelper.createHttpPaginationRequestReducer<ISnapshotsState>(
      ReduxHelper.createInitialPaginationState([], {
        name: 'name',
        direction: 'ASC',
      }),
      SnapshotActions.SNAPSHOT_PREVIEW_REQUEST,
      SnapshotActions.SNAPSHOT_PREVIEW_SUCCESS,
      SnapshotActions.SNAPSHOT_PREVIEW_ERROR,
      SnapshotActions.SNAPSHOT_PREVIEW_RESET,
      SnapshotActions.SNAPSHOT_PREVIEW_CHANGE_SORT
    ),
    cashAdjustmentTypes: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IAdjustmentType[]>>(
      ReduxHelper.createInitialState([]),
      SnapshotActions.FETCH_ADJUSTMENT_TYPES_REQUEST,
      SnapshotActions.FETCH_ADJUSTMENT_TYPES_SUCCESS,
      SnapshotActions.FETCH_ADJUSTMENT_TYPES_ERROR
    ),
    editedUnconfirmedTrade,
  }),
});
