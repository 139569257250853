import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Loader, Tab, Tabs } from '../../../../components';

import { API_URL } from '../../../../services/constants/endpoints';

import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../components/HOC/withPortfolioInfo';

import { VolatilityScoreChart } from './components/VolatlityScoreChart';
import { SimulatedReturnChart } from './components/SimulatedReturnChart';
import { Card } from '@iliotech/storybook';

import s from './RiskContainer.module.scss';
import Analytics from '../../../../services/utils/Analytics';
import {
  DASHBOARD_PORTFOLIO_HELP_TEXT,
  RISK_CONTAINER_HELP_TEXT,
} from '../../../../services/constants/tooltipContextHelp';
import { PortfolioHeader } from '../../components';
import { HistoricDrawdownChart } from './components/HistoricDrawdownChart';
import PerformanceAndVolatilityTable from './components/PerformanceAndVolatilityTable/PerformanceAndVolatilityTable';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../components/ContextHelp';
import { PortfolioSubHeader } from '../../components/PortfolioHeader/PortfolioSubHeader';
import { usePortfolio } from '../../../../services/hooks/usePortfolio';
import useApi from '../../../../services/hooks/useApi';
import { usePeriod } from '../../../../services/context/PeriodContext';
import moment from 'moment';

const API_BENCHMARK_VOLATILITY = API_URL + 'api/risk/v1/volatility/portfolio/';
const API_RETURN = API_URL + 'api/risk/v1/returns/portfolio/';
// const API_VOLATILITY_SCORE = API_URL + 'api/risk/v1/volatility/score/portfolio/';

type IProps = IWithPortfolioInfoProps;
const RiskContainer = ({}) => {
  Analytics.trackPageView('Risk Dashboard');
  const { portfolioInfo } = usePortfolio();
  const { period } = usePeriod();
  const portfolio = portfolioInfo.data as IPortfolio;
  const cancelTokenSource = axios.CancelToken.source();
  const [sectionId, setSectionId] = useState(0);
  const periodType = sectionId === 0 ? 'DAILY' : 'MONTHLY';
  const periodFrom = period.from
    ? moment(period.from).format('YYYY-MM-DD')
    : moment(portfolio?.inceptionDate).format('YYYY-MM-DD');

  const portfolioHistoricVolatility = useApi(
    API_BENCHMARK_VOLATILITY +
      portfolio?.id +
      '/historic' +
      '?includeInstrumentScatterData=' +
      true +
      '&dataFrequency=' +
      periodType +
      '&fromDate=' +
      periodFrom +
      '&toDate=' +
      moment(period.to).format('YYYY-MM-DD'),
    {
      method: 'get',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    },
    {
      enabled: !!portfolio?.id,
    }
  );

  const portfolioHistoricStats = useApi(
    API_BENCHMARK_VOLATILITY +
      portfolio?.id +
      '/historic' +
      '?dataFrequency=' +
      periodType +
      '&toDate=' +
      moment(period.to).format('YYYY-MM-DD') +
      '&stats=true',
    {
      method: 'get',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    },
    {
      enabled: !!portfolio?.id,
    }
  );

  const simulatedHistoricReturnData = useApi(
    API_RETURN +
      portfolio?.id +
      '/histogram/historic' +
      '?dataFrequency=' +
      periodType +
      '&toDate=' +
      moment(period.to).format('YYYY-MM-DD'),
    {
      method: 'get',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    },
    {
      enabled: !!portfolio?.id,
    }
  );

  useEffect(() => {
    if (portfolio?.id) {
      portfolioHistoricVolatility.refetch();
      simulatedHistoricReturnData.refetch();
      portfolioHistoricStats.refetch();
    }
  }, [period, sectionId]);

  const loading =
    !portfolioHistoricVolatility.data ||
    !simulatedHistoricReturnData.data ||
    !portfolioHistoricStats.data ||
    portfolioInfo.isFetching;

  const error = portfolioHistoricVolatility.isError || simulatedHistoricReturnData.isError;

  const renderTabs = (): React.ReactNode => {
    return (
      <Tabs activeTabIndex={sectionId || undefined} onTabSelected={setSectionId}>
        <Tab key={0} label="DAILY" />
        <Tab key={1} label="MONTHLY" />
      </Tabs>
    );
  };

  const renderSections = (tabId: number | null) => {
    const portfolioBenchmarkIndexName = portfolio.benchmarkIndex.name || '';
    const insufficientData = portfolioHistoricVolatility.data.insufficientData;
    const insufficientDataMessage = portfolioHistoricVolatility.data.message;

    switch (tabId) {
      case 0:
        if (insufficientData) {
          return (
            <div>
              <h4> {insufficientDataMessage} </h4>
            </div>
          );
        } else {
          return (
            <div className={s.defaultContainer}>
              <div className={s.containerRow}>
                <Card
                  style={{
                    flex: 1,
                    marginRight: 10,
                  }}
                >
                  <div className={s.scoreChartContainer}>
                    <VolatilityScoreChart
                      data={portfolioHistoricVolatility.data}
                      portfolioBenchmarkIndexName={portfolio.benchmarkIndex.name}
                      chartTitle={'Portfolio and Benchmark Volatility'}
                      helpText={RISK_CONTAINER_HELP_TEXT.ChartRiskScoreGaugeDaily}
                    />
                  </div>
                </Card>
                <Card style={{ flex: 1, marginLeft: 10 }}>
                  <div className={s.simulatedReturnChartContainer}>
                    <SimulatedReturnChart
                      data={simulatedHistoricReturnData.data}
                      chartTitle={'Distribution of daily returns'}
                      helpText={RISK_CONTAINER_HELP_TEXT.HistogramDistributionOfSimulatedReturnDaily}
                    />
                  </div>
                </Card>
              </div>

              <Card>
                <div className={s.chartContainer} style={{ textAlign: 'center' }}>
                  <h3>
                    Daily Performance Index and Drawdown
                    <ContextHelpIcon
                      title={'Daily Performance Index and Drawdown'}
                      elementId={'HistoricDrawDownChartDaily'}
                    />
                  </h3>
                  <div style={{ textAlign: 'left' }}>
                    <HistoricDrawdownChart chartData={portfolioHistoricVolatility.data} chartVariant={'DAILY'} />
                    <ContextHelpTooltip
                      elementId={'HistoricDrawDownChartDaily'}
                      tooltipTitle={'Daily Performance Index and Drawdown'}
                      tooltipText={RISK_CONTAINER_HELP_TEXT.HistoricDrawDownChartDaily}
                    />
                  </div>
                </div>
              </Card>
              <PerformanceAndVolatilityTable
                endDate={period.to}
                tableData={portfolioHistoricStats.data}
                inceptionTWR={portfolio.performance}
                portfolioBenchmarkIndexName={portfolioBenchmarkIndexName}
                dataVariant={'DAILY'}
              />
            </div>
          );
        }
      case 1:
        if (portfolioHistoricVolatility.data.insufficientData) {
          return (
            <div className={s.periodContainer}>
              <h4> {insufficientDataMessage} </h4>
            </div>
          );
        } else {
          return (
            <div className={s.defaultContainer}>
              <div className={s.containerRow}>
                <Card style={{ flex: 1, marginRight: 10 }}>
                  <div className={s.scoreChartContainer}>
                    <VolatilityScoreChart
                      data={portfolioHistoricVolatility.data}
                      portfolioBenchmarkIndexName={portfolio.benchmarkIndex.name}
                      chartTitle={'Portfolio and Benchmark Volatility'}
                      helpText={RISK_CONTAINER_HELP_TEXT.ChartRiskScoreGaugeMonthly}
                    />
                  </div>
                </Card>
                <Card style={{ flex: 1, marginLeft: 10 }}>
                  <div className={s.simulatedReturnChartContainer}>
                    <SimulatedReturnChart
                      data={simulatedHistoricReturnData.data}
                      chartTitle={'Distribution of monthly returns'}
                      helpText={RISK_CONTAINER_HELP_TEXT.HistogramDistributionOfSimulatedReturnMonthly}
                    />
                  </div>
                </Card>
              </div>

              <Card className={s.chartContainer} style={{ textAlign: 'center' }}>
                <h3>
                  Monthly Performance Index and Drawdown
                  <ContextHelpIcon
                    title={'Monthly Performance Index and Drawdown'}
                    elementId={'HistoricDrawDownChartMonthly'}
                  />
                </h3>
                <div style={{ textAlign: 'left' }}>
                  <HistoricDrawdownChart chartData={portfolioHistoricVolatility.data} chartVariant={'MONTHLY'} />
                  <ContextHelpTooltip
                    elementId={'HistoricDrawDownChartMonthly'}
                    tooltipTitle={'Monthly Performance Index and Drawdown'}
                    tooltipText={RISK_CONTAINER_HELP_TEXT.HistoricDrawDownChartMonthly}
                  />
                </div>
              </Card>
              <PerformanceAndVolatilityTable
                endDate={period.to}
                tableData={portfolioHistoricStats.data}
                inceptionTWR={portfolio.performance}
                portfolioBenchmarkIndexName={portfolioBenchmarkIndexName}
                dataVariant={'MONTHLY'}
              />
            </div>
          );
        }
    }
  };

  const portfolioHeader = <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false} />;

  if (loading) {
    return (
      <div>
        {portfolioHeader}

        <Loader />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        {portfolioHeader}
        <div className={s.dashboardTitleContainer}>
          <h4>There was an error loading data. Please try again later.</h4>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  const stats: IStat[] = [
    {
      value: portfolio?.totalProfitAndLoss,
      label: 'Inception P&L',
      colored: true,
      type: 'currency',
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: portfolio?.performance,
      label: 'Performance (TWR)',
      type: 'percentage',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
    },
    {
      value: portfolio?.totalWealth,
      label: 'Total Portfolio Value',
      type: 'currency',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
    },
  ];

  return (
    <div>
      {portfolioHeader}
      <PortfolioSubHeader title={'Portfolio volatility'} stats={stats} />

      {renderTabs()}

      <div className={s.defaultContainer}>{renderSections(sectionId)}</div>
    </div>
  );
};

export default RiskContainer;
