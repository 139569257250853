import * as React from 'react';
import AssetImage from '../../scenes/Portfolio/scenes/Snapshot/components/SnapshotTable/components/AssetImage';

interface IProps {
  instrument: IPortfolioSnapshot;
  size?: number;
}

const InstrumentImage = ({ instrument, size = 25 }: IProps) => {
  const getImageUrl = (img: string) => `https://cdn.illio.com/${img}`;

  if (instrument.icon) {
    return <img height={size} width={size} style={{ marginRight: 5 }} src={getImageUrl(instrument.icon)} />;
  }

  return (
    <AssetImage
      height={size}
      width={size}
      style={{ marginRight: 5 }}
      assetClass={instrument.assetClass!}
      altName={instrument.assetClass}
    />
  );
};

export default InstrumentImage;
