import React, { PureComponent, ReactNode, Fragment } from 'react';
import Slider, { HandleProps, SliderProps } from 'rc-slider';

import s from './SliderBlock.module.scss';

interface IProps {
  sliderOpt: SliderProps;
  value: number;
  onChange: (value: number) => void;
  label?: string;
  units?: string;
}

interface IState {
  currentValue: number;
}

class SliderBlock extends PureComponent<IProps, IState> {
  static readonly defaultProps: Partial<IProps> = {
    value: 0,
    onChange: () => {},
  };

  readonly state: IState = {
    currentValue: 0,
  };

  componentDidMount(): void {
    this.setState({
      currentValue: this.props.value,
    });
  }

  renderHandler = (props: HandleProps): ReactNode => {
    const { className, vertical, offset } = props;
    const style = vertical ? { bottom: offset + '%' } : { left: offset + '%' };
    return (
      <Fragment>
        <div className={s.sliderHandlerAreaWrapper}>
          <div className={s.sliderHandlerArea} style={style} />
        </div>
        <div className={className} style={style} />
      </Fragment>
    );
  };

  handlerChangeValue = (value: number) => {
    this.setState({
      currentValue: value,
    });
  };

  render() {
    const { label, units, sliderOpt, onChange } = this.props;
    let { currentValue } = this.state;

    if (sliderOpt.min && currentValue < sliderOpt.min) {
      currentValue = sliderOpt.min;
    }

    if (sliderOpt.max && currentValue > sliderOpt.max) {
      currentValue = sliderOpt.max;
    }

    return (
      <div className={s.sliderBlock}>
        <div className={s.sliderBlockHeader}>{label}</div>
        <div className={s.sliderBlockContent}>
          <div className={s.sliderBlockSlider}>
            <Slider
              value={currentValue}
              handle={this.renderHandler}
              onChange={this.handlerChangeValue}
              onAfterChange={onChange}
              {...sliderOpt}
            />
          </div>
          <div className={s.sliderBlockSliderValue}>
            {currentValue} {units}
          </div>
        </div>
      </div>
    );
  }
}

export default SliderBlock;
