import React, { CSSProperties, PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { ClickOutside } from '../../ClickOutside';
import { Button } from '../Button';
import { Portal } from '../../Portal';

import { DOMHelpers } from '../../../services/utils/DOMHelpers';

import fadeTransition from '../../../transitions/fade.module.css';
import s from './DropDown.module.scss';

enum CONTENT_POSITION {
  BOTTOM,
  TOP,
  NONE,
}

interface IProps {
  portalContainer?: HTMLElement;
  yOffset: number;
  buttonClassName?: string;
  placeholderContent?: React.ReactNode;
}

interface IState {
  showContent: boolean;
  dropContentStyle: CSSProperties;
}

class DropDown extends PureComponent<IProps, IState> {
  static readonly defaultProps: Partial<IProps> = {
    yOffset: 12,
    buttonClassName: '',
  };

  readonly state: Readonly<IState> = {
    showContent: false,
    dropContentStyle: {},
  };

  private _scrolledParent: HTMLElement | null | undefined = undefined;
  private _wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  private _contentRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount(): void {}

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (prevState.showContent !== this.state.showContent && this._wrapperRef.current) {
      if (!this._scrolledParent) {
        this._scrolledParent = DOMHelpers.getScrollParent(this._wrapperRef.current);
      }

      if (!this.state.showContent) {
        this._removeAllListeners();
      } else {
        window.addEventListener('resize', this.handleScroll);
        window.addEventListener('keydown', this.handleGlobalKeydown);
        if (this._scrolledParent) {
          this._scrolledParent.addEventListener('scroll', this.handleScroll);
        }
      }
    }
  }

  componentWillUnmount(): void {
    this._removeAllListeners();
  }

  toggleContent = (event: React.MouseEvent<any>) => {
    if (this.state.showContent) {
      return;
    }

    this.setState(
      {
        showContent: !this.state.showContent,
      },
      () => {
        this.setState({ dropContentStyle: this._calcPosition() });
      }
    );

    event.preventDefault();
  };

  handleScroll = () => {
    if (!this.state.showContent) {
      return;
    }

    this.setState({ dropContentStyle: this._calcPosition() });
  };

  handleGlobalKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.setState({ showContent: false });
    }
  };

  handleClickOutside = () => {
    this.setState({ showContent: false });
  };

  render() {
    const { showContent, dropContentStyle } = this.state;
    const { buttonClassName } = this.props;
    return (
      <div
        className={s.dropWrapper}
        ref={this._wrapperRef}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <ClickOutside clickOutside={this.handleClickOutside}>
          <div onClick={this.toggleContent}>
            {this.props.placeholderContent ? (
              this.props.placeholderContent
            ) : (
              <Button className={cn(s.dropBtn, buttonClassName)} variant="empty" />
            )}
          </div>
        </ClickOutside>
        <CSSTransition in={showContent} classNames={fadeTransition} timeout={200} unmountOnExit={true}>
          <Portal target={this.props.portalContainer}>
            <div ref={this._contentRef} className={s.dropContent} style={dropContentStyle}>
              {this.props.children}
            </div>
          </Portal>
        </CSSTransition>
      </div>
    );
  }

  private _removeAllListeners() {
    document.removeEventListener('click', this.handleClickOutside, false);
    window.removeEventListener('resize', this.handleScroll);
    window.removeEventListener('keydown', this.handleGlobalKeydown);

    if (this._scrolledParent) {
      this._scrolledParent.removeEventListener('scroll', this.handleScroll);
    }
  }

  private _calcPosition(): CSSProperties {
    const style: CSSProperties = {};

    if (!this._wrapperRef.current) {
      return style;
    }

    const { yOffset } = this.props;
    const wrapperCoords: ClientRect = this._wrapperRef.current.getBoundingClientRect();
    switch (this._getContentPosition()) {
      case CONTENT_POSITION.BOTTOM:
        style.top = wrapperCoords.bottom + yOffset;
        style.left = wrapperCoords.right;
        style.transform = 'translateX(-100%)';
        return style;
      case CONTENT_POSITION.TOP:
        style.top = wrapperCoords.bottom - wrapperCoords.height - yOffset;
        style.left = wrapperCoords.right;
        style.transform = 'translateX(-100%) translateY(-100%)';
        return style;
      case CONTENT_POSITION.NONE:
        style.display = 'none';
        return style;
      default:
        return style;
    }
  }

  private _getContentPosition(): CONTENT_POSITION {
    if (!this._wrapperRef.current || !this._contentRef.current) {
      return CONTENT_POSITION.BOTTOM;
    }

    let position: CONTENT_POSITION = CONTENT_POSITION.BOTTOM;
    const wrapperCoords: ClientRect = this._wrapperRef.current.getBoundingClientRect();
    const { height: dropContentHeight } = this._contentRef.current.getBoundingClientRect();
    const { height: containerHeight, top } = this._scrolledParent
      ? this._scrolledParent.getBoundingClientRect()
      : {
          height: 0,
          top: 0,
        };

    const isWrapperInViewPort = containerHeight > wrapperCoords.bottom - top || wrapperCoords.top - top < 0;
    const isDropDownInViewPort = containerHeight < wrapperCoords.bottom + this.props.yOffset + dropContentHeight;

    if (this._scrolledParent && !isWrapperInViewPort) {
      position = CONTENT_POSITION.NONE;
    }

    if (isDropDownInViewPort && position === CONTENT_POSITION.BOTTOM) {
      position = CONTENT_POSITION.TOP;
    }

    return position;
  }
}

export default DropDown;
