const getAssetIdentifier = (item: IUploadTemplateResult) => {
  let specifier = item.country ?? '';
  switch (item.assetSubClass) {
    case 'CashSubs':
      specifier = item.currency;
      break;
    case 'CshFXSpot':
      specifier = `${item.otherTicker}${item.buySell}`;
  }
  return `${item.ticker}${specifier}`;
};

export default getAssetIdentifier;
