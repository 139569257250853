import React, { Fragment, PureComponent } from 'react';

import { TRANSACTION_TYPES } from '../../../../../../../../../../services/mock/transactionTypes';
import { IPortfolioInfoState } from '../../../../../../../../../../services/reducers/PortfolioReducers';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { FormatHelper } from '../../../../../../../../../../services/utils/FormatHelper';
import { PositionHistoryWidgetContainer } from '../../../../../../../../components/PositionHistoryWidget';
import { RealizedPnlWidgetContainer } from '../../../../../../../../components/RealizedPnlWidget';
import CumulativePnLChartContainer from './components/CumulativePnL/CumulativePnLChartContainer';
import StockVsBuysChartContainer from './components/StockVsBuysChart/StockVsBuysChartContainer';
import s from './PnlBreakdownDetail.module.css';
import { Card } from '@iliotech/storybook';

export interface IInfoElement {
  title: string;
  value: string | number;
  currencyName?: string;
  currencySymbol?: string;
}

function InfoElement(props: IInfoElement) {
  return (
    <span className={s.item}>
      {props.title}:
      <b className={s.value}>
        {props.currencySymbol} {props.value} {props.currencyName}
      </b>
    </span>
  );
}

interface IProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioInfo: IPortfolioInfoState;
  breakdownPosition: IBreakdownPosition;
  classId?: string;
}

class PnlBreakdownDetail extends PureComponent<IProps> {
  render() {
    return (
      <Fragment>
        <div className={s.line} />
        <div className={s.assetsDashboardGrid}>
          <div className={s.InfoContainer}>
            <InfoElement
              title={this.props.classId === TRANSACTION_TYPES.fixedIncome ? 'Nominal' : 'Open QTY'}
              value={FormatHelper.localePriceValueFormatter(this.props.breakdownPosition.openQuantity, 2, 0)}
            />
            <InfoElement
              title="Local price"
              value={FormatHelper.localePriceValueFormatter(this.props.breakdownPosition.currentPrice, 2, 0)}
              // currencyName={this.props.breakdownPosition.currency.name}
            />
            <InfoElement
              title="Position value"
              value={FormatHelper.formatNumber(this.props.breakdownPosition.positionValue)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
            <InfoElement
              title={
                this.props.classId === TRANSACTION_TYPES.fixedIncome
                  ? 'Total Income'
                  : this.props.classId === TRANSACTION_TYPES.funds
                  ? 'Total distributions'
                  : 'Total dividends'
              }
              value={FormatHelper.formatNumber(this.props.breakdownPosition.totalDividends)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
            <InfoElement
              title="FX P&L"
              value={FormatHelper.formatNumber(this.props.breakdownPosition.fxProfitAndLoss, 0)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
            <InfoElement
              title="Price P&L"
              value={FormatHelper.formatNumber(this.props.breakdownPosition.pnlPriceBase, 0)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
            <InfoElement
              title="Realised P&L"
              value={FormatHelper.formatNumber(this.props.breakdownPosition.realizedPnl, 0)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
            <InfoElement
              title="Unrealised P&L"
              value={FormatHelper.formatNumber(this.props.breakdownPosition.unrealizedPnl, 0)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
            <InfoElement
              title="Total P&L"
              value={FormatHelper.formatNumber(this.props.breakdownPosition.totalPnl, 0)}
              currencySymbol={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''}
            />
          </div>
        </div>

        <div className={s.defaultContainer}>
          <div className={s.containerRow}>
            <div className={s.containerColumn}>
              <Card style={{ height: 550, marginBottom: '1.5rem' }}>
                <StockVsBuysChartContainer
                  stockPricesData={this.props.breakdownPosition.stockPrices}
                  income={this.props.breakdownPosition.income}
                  splitsData={this.props.breakdownPosition.splits}
                  earnEventsData={this.props.breakdownPosition.earns}
                  buysAndSellsData={this.props.breakdownPosition.buysAndSells}
                  instrumentCurrency={this.props.breakdownPosition.currency}
                  baseCurrency={this.props.breakdownPosition.portfolioCurrency}
                  instrumentName={this.props.breakdownPosition.instrumentName}
                  assetClassId={this.props.classId}
                />
              </Card>
            </div>
            <div className={s.containerColumn}>
              <Card style={{ height: 550, marginBottom: '1.5rem' }}>
                <PositionHistoryWidgetContainer />
              </Card>
            </div>
          </div>
          <div className={s.containerRow}>
            <div className={s.containerColumn}>
              <Card style={{ height: 550 }}>
                <CumulativePnLChartContainer
                  cumulativePnl={this.props.breakdownPosition.cumulativePnl}
                  baseCurrency={(this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.name) || ''}
                  baseCurrencySymbol={
                    (this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || ''
                  }
                  instrumentName={this.props.breakdownPosition.instrumentName}
                  assetClassId={this.props.classId}
                />
              </Card>
            </div>
            <div className={s.containerColumn}>
              <Card style={{ height: 550 }}>
                <RealizedPnlWidgetContainer />
              </Card>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PnlBreakdownDetail;
