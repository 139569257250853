import { IPortfoliosFilters } from '../../models/IPortfoliosFilters';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { PortfolioActions } from '../actions';
import { AppActions } from '../actions/AppActions';
import { PortfolioType } from '../constants/constants';
import { ReduxHelper } from '../utils/ReduxHelper';

export type IPortfoliosListState = ILoadingDataState<IPortfolio[]> & { filters: IPortfoliosFilters };

const INITIAL_PORTFOLIOS_LIST: IPortfoliosListState = {
  ...ReduxHelper.createInitialState([]),
  filters: { type: PortfolioType.BUSINESS, text: '' },
};

const portfoliosListDefault = ReduxHelper.createHttpRequestReducer<IPortfoliosListState>(
  INITIAL_PORTFOLIOS_LIST,
  PortfolioActions.PORTFOLIOS_LIST_REQUEST,
  PortfolioActions.PORTFOLIOS_LIST_SUCCESS,
  PortfolioActions.PORTFOLIOS_LIST_ERROR,
  PortfolioActions.PORTFOLIOS_LIST_RESET
);

const portfoliosListReducer = (
  state: IPortfoliosListState = INITIAL_PORTFOLIOS_LIST,
  action: IActionPayload
): IPortfoliosListState => {
  switch (action.type) {
    case PortfolioActions.PORTFOLIOS_FILTERS_CHANGE:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case AppActions.LOGOUT_SUCCESS:
      return INITIAL_PORTFOLIOS_LIST;
    default:
      return portfoliosListDefault(state, action);
  }
};

export default portfoliosListReducer;
