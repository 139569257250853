import React, { FunctionComponent, PropsWithChildren } from 'react';
import s from '../WorkGroupManager.module.scss';
import warning from '../../../static/images/warning.svg';
import valid from '../../../static/images/valid.svg';
import { Card } from '@iliotech/storybook';
import { Checkbox } from 'rsuite';
export interface INotificationContainer {
  applyFilters: (filters: IWorkGroupWarningTypes[]) => void;
  activeWarnings: IWorkGroupWarningTypes[];
  warnings: IWarning[];
}

type IProps = PropsWithChildren<INotificationContainer>;

const WarningsContainer: FunctionComponent<IProps> = ({ warnings, applyFilters, activeWarnings }) => {
  const renderWarnings: () => any = () => {
    if (warning.length === 0) {
      return (
        <span style={{ color: 'white' }} className={s.warningItem}>
          You have 0 warnings
        </span>
      );
    }
    return warnings.map((w, index) => (
      <Checkbox
        style={{ justifyContent: 'flex-start' }}
        checked={activeWarnings.includes(w.type)}
        onChange={(value, checked) => {
          applyFilters(
            activeWarnings.includes(w.type) ? activeWarnings.filter((a) => a !== w.type) : [...activeWarnings, w.type]
          );
        }}
        className={s.warningItem}
        key={`warning-${index}`}
      >
        <p style={{ fontWeight: 'bold', justifyContent: 'flex-start', marginTop: 2 }}>
          {w.type === 'UNASSIGNED'
            ? `You have ${w.count} unassigned workgroups`
            : w.type === 'NEGATIVES'
            ? `You have ${w.count} workgroups with negative values`
            : `You have ${w.count} workgroups with trades in the blotter`}
        </p>
      </Checkbox>
    ));
  };

  return (
    <Card className={s.notificationContainer} style={{ paddingTop: 10, paddingBottom: 10 }}>
      <img src={warnings.length > 0 ? warning : valid} className={s.warningSign} />
      <div className={s.notificationList}>{renderWarnings()}</div>
    </Card>
  );
};

export default React.memo(WarningsContainer);
