import { ReduxHelper } from '../utils/ReduxHelper';

import { getApiUrl } from '../constants/endpoints';

export class UploadTemplateActions {
  static readonly UPLOAD_TEMPLATE_LIST_REQUEST = 'UPLOAD_TEMPLATE_LIST_REQUEST';
  static readonly UPLOAD_TEMPLATE_LIST_SUCCESS = 'UPLOAD_TEMPLATE_LIST_SUCCESS';
  static readonly UPLOAD_TEMPLATE_LIST_ERROR = 'UPLOAD_TEMPLATE_LIST_ERROR';
  static readonly UPLOAD_TEMPLATE_REQUEST = 'UPLOAD_TEMPLATE_REQUEST';
  static readonly UPLOAD_TEMPLATE_SUCCESS = 'UPLOAD_TEMPLATE_SUCCESS';
  static readonly UPLOAD_TEMPLATE_ERROR = 'UPLOAD_TEMPLATE_ERROR';

  static fetchUploadTemplates() {
    return ReduxHelper.createHttpRequestActionBundle<ICurrency[]>(
      {
        url: getApiUrl('app.uploadTemplates.all'),
        method: 'GET',
      },
      UploadTemplateActions.UPLOAD_TEMPLATE_LIST_REQUEST,
      UploadTemplateActions.UPLOAD_TEMPLATE_LIST_SUCCESS,
      UploadTemplateActions.UPLOAD_TEMPLATE_LIST_ERROR
    );
  }

  static uploadTemplate(data: IUploadTemplateData) {
    return ReduxHelper.createHttpRequestActionBundle<ICurrency[]>(
      {
        url: getApiUrl('app.uploadTemplates.upload'),
        method: 'POST',
        data,
      },
      UploadTemplateActions.UPLOAD_TEMPLATE_REQUEST,
      UploadTemplateActions.UPLOAD_TEMPLATE_SUCCESS,
      UploadTemplateActions.UPLOAD_TEMPLATE_ERROR
    );
  }
}
