import React, { CSSProperties, FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

type IProps = RouteProps & {
  isAllowed: boolean;
};

const style: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  textAlign: 'center',
  justifyContent: 'center',
};

const ProtectedRoute: FunctionComponent<IProps> = ({ isAllowed, ...props }) => {
  return isAllowed ? <Route {...props} /> : <Route {...props} render={() => <Redirect to="/" />} />;
};

export default ProtectedRoute;
