import { createSelector } from 'reselect';
import { IOption } from '../../components/UIWidgets/Autocomplete';

import { IRootState } from '../store';

export function getPortfolioIndex(state: IRootState): IIndex[] {
  return state.portfolioIndex.data;
}

export const getPortfolioIndexOptions = createSelector<IRootState, IIndex[], Array<IOption<IIndex>>>(
  getPortfolioIndex,
  (indexList: IIndex[]): Array<IOption<IIndex>> => {
    return indexList.map((index) => {
      return {
        id: index.code,
        value: index,
        name: index.name,
      };
    });
  }
);
