import React, { FunctionComponent, useContext } from 'react';
// import { useAccount } from './AuthContext';
import { sendRequest } from '../hooks/useApi';
import { getApiUrl } from '../constants/endpoints';
import { DEFAULT_PREFERENCES } from '../constants/preferences';

interface IPreferencesContext {
  preferences: IUserPreferences;
  updateCorePreference<T extends keyof CorePreference>(key: T, value: CorePreference[T]): Promise<any>;
  updateMiscPreference<T extends keyof MiscPreference>(key: T, value: MiscPreference[T]): Promise<any>;
  fetchUserPreferences(): Promise<any>;
}

const noop = (functionName: string) => () => {
  console.log(`${functionName} not initialised`);
  return Promise.reject("Not ready");
};
const PreferencesContext = React.createContext<IPreferencesContext>({
  updateCorePreference: noop('updateCorePreference'),
  updateMiscPreference: noop('updateMiscPreference'),
  fetchUserPreferences: noop('fetchUserPreferences'),
  preferences: DEFAULT_PREFERENCES,
});

export const PreferencesContextProvider: FunctionComponent = ({ children }) => {
  const [preferences, setPreferences] = React.useState<IUserPreferences>(DEFAULT_PREFERENCES);

  const fetchUserPreferences = () => {
    return sendRequest(getApiUrl('user.preferences'), { method: 'GET' }).then((result) => {
      const misc: Record<string, any> = {};
      Object.entries(result?.misc ?? {}).forEach(([key, value]) => {
        misc[key] = JSON.parse(String(value));
      });
      const unpackedPreferences = {
        ...result,
        misc,
      };
      setPreferences(unpackedPreferences);
    });
  };

  const savePreferences = (newPreferences: IUserPreferences) => {
    const misc: Record<string, string> = {};

    Object.entries(newPreferences.misc).forEach(([key, value]) => {
      misc[key] = JSON.stringify(value);
    });

    return sendRequest(
      getApiUrl('user.preferences'),
      { method: 'PUT' },
      {
        ...newPreferences,
        misc,
      }
    )
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setPreferences(newPreferences);
      })
  };

  const updateCorePreference: IPreferencesContext['updateCorePreference'] = (key, value) => {
    if (!preferences) {
      console.log('Unable to update preferences - preferences not yet initialised');
      return Promise.resolve(preferences);
    }
    const newPreferences: IUserPreferences = {
      ...preferences,
      [key]: value,
    };

    return savePreferences(newPreferences);
  };

  const updateMiscPreference: IPreferencesContext['updateMiscPreference'] = (key, value) => {
    if (!preferences) {
      console.log('Unable to update miscellaneous preferences - preferences not yet initialised');
      return Promise.resolve(preferences);
    }
    const newPreferences: IUserPreferences = {
      ...preferences,
      misc: {
        ...preferences.misc,
        [key]: value,
      },
    };

    return savePreferences(newPreferences);
  };

  return (
    <PreferencesContext.Provider
      value={{ preferences, updateCorePreference, updateMiscPreference, fetchUserPreferences }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(PreferencesContext);
