import { useEffect, useState } from 'react';
import { currencyFormatterV2 } from '../utils/CurrencyHelpers';
import { getBarColor } from '../../scenes/Portfolio/scenes/Reports/reportsChartOptions';
import { ICurrencyFormatter } from '../selectors/portfolio';

interface IProps {
  selectedCurrency:string;
  portfolioCurrencyFormatter:ICurrencyFormatter;
  chartData:IBarData[]
  customBarDataTotal?: (  current: IBarData) => number;
  toggableTotal?:boolean;
}

const useBarDataToggableLegend = ({chartData, portfolioCurrencyFormatter,selectedCurrency,customBarDataTotal,toggableTotal}: IProps) => {
  const [toggledData, setToggledData] = useState<IBarData[]>(chartData);
  const [hideTotal, setHideTotal] = useState<boolean>(false);
  const [legend,setLegend] = useState<{color: string, label: string, value: string}[]>([]);

  useEffect(() => {
    setToggledData(chartData)
  },[ chartData])

  useEffect(() => {
    setLegend(getLegend())
  },[ toggledData])

  const isItemToggled = (key: string) => toggledData.some((item) => item.name === key);

  const removeItem = (key: string) => {
    setToggledData([...toggledData.filter((item) => item.name !== key)]);
  };

  const addItem = (key: string) => {
    const itemToAdd = chartData.find((item: any) => item.name === key);

    if (itemToAdd) {
      setToggledData([ ...toggledData,itemToAdd]);
    }
  };

  const toggleTotal = () => {
    setHideTotal((v) => !v);
  };

  const onLegendItemClick = (key: string) => {
    if (key === 'Total' && toggableTotal) {
      toggleTotal();
      return;
    }
    const hasItem = isItemToggled(key);

    if (hasItem) {
      removeItem(key);
    } else {
      addItem(key);
    }
  };

  const formatCurrency = (val: number) => {
    if (selectedCurrency === 'All' || selectedCurrency === undefined) {
      return portfolioCurrencyFormatter(val);
    }
    return selectedCurrency + ' ' + currencyFormatterV2(val, 0, 3);
  };

  const getLegend = () => {
    const getBarName = (barData: IBarData): string | undefined => {
      return barData.name;
    };
    let barDataTotal=(current: IBarData) => current.data.reduce((acc, curr) => acc + curr[1], 0);
    if(customBarDataTotal){
      barDataTotal = customBarDataTotal
    }

    const total = toggledData.reduce((acc, curr) => acc + barDataTotal(curr), 0);

    const assembledLegend = [
      {
        label: 'Total',
        value: formatCurrency(total),
        color: hideTotal ? 'white' : '#a9b2d1',
      },
    ];

    chartData.filter((el) => barDataTotal(el) !== 0).map((el: IBarData, idx: number) => {
      assembledLegend.push({
        label: getBarName(el) || '',
        value: formatCurrency(barDataTotal(el)),
        color: isItemToggled(getBarName(el) || '') ? getBarColor(el) || '' : 'white',
      });
    });

    return assembledLegend;
  };


  return {toggledData, legend, onLegendItemClick}
}

export default useBarDataToggableLegend
