import cn from 'classnames';
import React, { Fragment, PureComponent, useState } from 'react';
import { StylesConfig } from 'react-select/lib/styles';
import { Button, DatePicker, Select } from '..';

import { PERIOD_TYPE } from '../../services/constants/constants';
import { PeriodHelper } from '../../services/utils/PeriodHelper';
import { IOptionType } from '../UIWidgets/Select/Select';
import s from './Period.module.scss';
import moment from 'moment';

type ViewType = 'select' | 'tabs';

interface IProps {
  options: Array<IOptionType<PERIOD_TYPE>>;
  onPeriodChange: (period: IPeriod) => void;
  viewList?: ViewType;
  selectedPeriod?: IPeriod;
  hideWeekends?: boolean;
  inceptionDate?: string;
}

const Period = ({ options, onPeriodChange, viewList = 'select', selectedPeriod, inceptionDate }: IProps) => {
  const _rangesRef: React.RefObject<HTMLDivElement> = React.createRef();
  const [range, setRange] = useState<IPeriod | undefined>(selectedPeriod);

  const handleChangePeriod = (period: PERIOD_TYPE) => {
    onPeriodChange({ ...PeriodHelper.getPeriod(period), type: period });
  };

  const handleDatePickerBlur = () => {
    if (!range || !selectedPeriod) {
      return;
    }

    const checkedPeriod = _validatePeriod(range);

    onPeriodChange({ ...checkedPeriod, type: selectedPeriod.type });
  };

  const handleDatepickerChange = (date: Date | null, type: 'to' | 'from') => {
    console.log('date', date, type);
    // TODO: not the best decision but it fix problem in tablets (onBlur not fired after click event). Maybe better is to use another component
    setTimeout(() => {
      if (_rangesRef.current) {
        handleDatePickerBlur();
      }
    });

    setRange({ ...selectedPeriod!, [type]: date });
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const renderDatePickers = () => {
    const fromDate = selectedPeriod
      ? selectedPeriod.from
      : moment().subtract(4, 'month').add(1, 'day').format('DD-MMM-YYYY');
    const toDate = selectedPeriod ? selectedPeriod.to : new Date();

    return (
      <div ref={_rangesRef} className={s.datePickers}>
        <DatePicker
          hideWeekends={true}
          max={new Date()}
          min={inceptionDate ? new Date(inceptionDate) : undefined}
          className={s.datePickerItem}
          value={fromDate}
          onChange={(date) => handleDatepickerChange(date.value, 'from')}
        />
        <span className={s.datePickersSeparator} />
        <DatePicker
          value={toDate}
          hideWeekends={true}
          max={new Date()}
          min={fromDate || (inceptionDate && new Date(inceptionDate)) || undefined}
          onChange={(date) => handleDatepickerChange(date.value, 'to')}
          className={s.datePickerItem}
        />
      </div>
    );
  };

  function renderSelect() {
    const selectedType: PERIOD_TYPE | undefined = selectedPeriod && selectedPeriod.type;
    const selectedValue: IOptionType<PERIOD_TYPE> | null | undefined =
      selectedPeriod !== undefined ? options.find((item) => item.value === selectedType) : null;

    const selectStyle: StylesConfig =
      selectedType === PERIOD_TYPE.MANUAL
        ? { singleValue: () => ({ display: 'none' }) }
        : { singleValue: () => ({ display: 'block', color: 'var(--text-white)' }) };

    return (
      <Fragment>
        {selectedType === PERIOD_TYPE.MANUAL && selectedPeriod && renderDatePickers()}
        <Select
          size="small"
          styles={selectStyle}
          options={options}
          selectedValue={selectedValue}
          onChange={handleChangePeriod}
        />
      </Fragment>
    );
  }

  function renderTabs() {
    const currentPeriod = (selectedPeriod && selectedPeriod.type) || options[0].value;

    return (
      <ul className={s.tabsList}>
        {options.map((item, idx) => {
          const isActive = item.value === currentPeriod;
          return (
            <li className={cn([s.tabsItem], { [s.active]: isActive })} key={idx}>
              <Button
                className={s.tabsBtn}
                type="button"
                variant="empty"
                onClick={() => {
                  handleChangePeriod(item.value);
                }}
              >
                {item.label}
              </Button>
            </li>
          );
        })}
      </ul>
    );
  }

  function _validatePeriod(period: IPeriod): IPeriod {
    let validPeriod: IPeriod = period;

    period.from = !period.from || period.from > new Date() ? new Date() : period.from;
    period.to = !period.to || period.to > new Date() ? new Date() : period.to;

    if (validPeriod.from && validPeriod.to && validPeriod.from > validPeriod.to) {
      validPeriod = { ...validPeriod, to: validPeriod.from, from: validPeriod.to };
    }

    return validPeriod;
  }

  let periodFrom = '';
  let periodTo = '';

  if (selectedPeriod) {
    periodFrom = moment(selectedPeriod.from).format('DD-MMM-YYYY');
    periodTo = moment(selectedPeriod.to).format('DD-MMM-YYYY');
  }

  return (
    <div>
      <div className={s.periodWrapper}>
        <span className={s.periodLabel}>Period</span>
        {viewList === 'select' && renderSelect()}
        {viewList === 'tabs' && renderTabs()}
      </div>
      <div className={s.periodDetail}>{periodFrom + ' to ' + periodTo}</div>
    </div>
  );
};

export default Period;
