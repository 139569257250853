import React, { FunctionComponent } from 'react';
import { IOptionType } from '../../../../../../components/UIWidgets/Select/Select';
import { ITableColumn } from '../../../../../../components/UIWidgets/TableWrapper/models';

import SnapshotHeader from './components/SnapshotHeader';
import TableColumnsFilter from '../TableColumnsFilter/TableColumnsFilter';
import { getSnapshotColumns } from './snapshotTableColumns';
import { filterSnapshots } from './filterSnapshots';
import { IRootState } from '../../../../../../services/store';
import { AxiosPromise } from 'axios';
import { IPaginationContent } from '../../../../../../models/redux/ILoadingPaginationDataState';
import { Loader } from '../../../../../../components/UIWidgets/Loader';

import { Table } from '@iliotech/storybook';
import { PositionDetailRow } from './components/PositionDetailRow/PositionDetailRow';
import { useTypedSelector } from '../../../../../../services/hooks/useTypedSelector';
import { usePreferences } from '../../../../../../services/context/PreferencesContext';
import { DEFAULT_POSITION_FIELDS } from '../../../../../../services/constants/preferences';
import { generatePath, useHistory } from 'react-router-dom';
import { PATHS } from '../../../../../../router/paths';
import { TRANSACTION_TYPES } from '../../../../../../services/mock/transactionTypes';

interface IOwnProps {
  snapshots: IPortfolioSnapshot[];
  snapshotsOrder: IOrder;
  onSortData: (order: IOrder) => void;
  portfolioCurrency: ICurrency;
  assetsClasses: IAssetClass[];
  portfolioInfo: IRootState['portfolio']['portfolioInfo']['data'];
  updateSnapshotFilter(key: string, value: string | undefined): void;
  resetSnapshotFilters(): void;
  snapshotFilters: IRootState['snapshot']['snapshotFilters'];
  fetchPortfolioSnapshots: (settings: {
    portfolioId: string;
    sort: IOrder;
    page?: number;
    confirmed?: boolean;
    size?: number;
    fromDate?: Date;
    toDate?: Date;
  }) => AxiosPromise<IPaginationContent<IPortfolioSnapshot>>;
  fetchPositionsAndTransactions(): void;
  snapshotsLoading?: boolean;
}

const SnapshotTable: FunctionComponent<IOwnProps> = ({
  snapshots,
  snapshotsOrder,
  onSortData,
  portfolioCurrency,
  assetsClasses,
  portfolioInfo,
  snapshotFilters,
  updateSnapshotFilter,
  resetSnapshotFilters,
  fetchPortfolioSnapshots,
  fetchPositionsAndTransactions,
  snapshotsLoading,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]); // Managing state here to avoid duplication across FullSnapshotTable
  const { transactions } = useTypedSelector((state) => ({ transactions: state.snapshot.trades.content }));
  const { preferences } = usePreferences();
  const history = useHistory();

  const onRowClick = (position: IPortfolioSnapshot) => {
    const cleanedAssetClass = position.assetClass?.replace(/([ \-])+/g, '') ?? '';

    const hasDetailsPage =
      [
        TRANSACTION_TYPES.alternatives,
        TRANSACTION_TYPES.equities,
        TRANSACTION_TYPES.funds,
        TRANSACTION_TYPES.fixedIncome,
        TRANSACTION_TYPES.cryptoCurrencies
      ]
        .includes(cleanedAssetClass);

    if(hasDetailsPage){
      history.push(
        generatePath(PATHS.portfolio.dashboard.breakdownDetailDashboard.path, {
          portfolioId: portfolioInfo?.id,
          classId: cleanedAssetClass,
          positionId: position.positionId || '/',
        })
      )
    } else {
      toggleRow(position.positionId)();
    }

  };

  const toggleRow = (id?: string) => (e?: any) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if(!id) {
      return;
    }
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys([id]);
    }
  };

  const fetchSnapshots = () => {
    fetchPortfolioSnapshots({
      portfolioId: portfolioInfo?.id || '',
      sort: snapshotsOrder,
      fromDate: snapshotFilters.positionsFrom ? new Date(snapshotFilters.positionsFrom) : undefined,
      toDate: snapshotFilters.positionsTo ? new Date(snapshotFilters.positionsTo) : undefined,
    });
  };

  const renderRowExpanded = (position: IPortfolioSnapshot) => {
    return (
      <PositionDetailRow
        portfolioId={portfolioInfo?.id || ''}
        position={position}
        transactions={transactions}
        fetchPositionsAndTransactions={fetchPositionsAndTransactions}
      />
    );
  };

  React.useEffect(() => {
    if (portfolioInfo?.id && snapshotsOrder) {
      fetchSnapshots();
    }
  }, [portfolioInfo?.id, snapshotFilters.positionsFrom, snapshotFilters.positionsTo, snapshotsOrder]);

  const availableAssetClasses = getAvailableAssetClasses(snapshots, assetsClasses);

  const filteredSnapshots = snapshots.filter(filterSnapshots(snapshotFilters, portfolioCurrency.name));
  const tableFilterOptions = getDistinctProperties(snapshots, [
    'currencyNative',
    'country',
    'custodian',
    'sector',
    'riskAssetClass',
    'investmentVehicle',
  ]);
  const updateFilter = (key: string) => (value: string | undefined) => updateSnapshotFilter(key, value);

  const headerFilters = [
    {
      label: 'Instrument Asset Class',
      options: availableAssetClasses,
      key: 'instrumentAssetClass',
      onChange: updateFilter('instrumentAssetClass'),
      minWidth: 150,
    },
    {
      label: 'Risk Asset Class',
      options: tableFilterOptions.riskAssetClass,
      key: 'riskAssetClass',
      onChange: updateFilter('riskAssetClass'),
      minWidth: 150,
    },
    {
      label: 'Investment Vehicle',
      options: tableFilterOptions.investmentVehicle,
      key: 'investmentVehicle',
      onChange: updateFilter('investmentVehicle'),
      minWidth: 150,
    },
    {
      label: 'Currency',
      options: [
        ...tableFilterOptions.currencyNative,
        { label: `Non-${portfolioInfo?.currency.name}`, value: 'non-base' },
      ],
      key: 'currency',
      onChange: updateFilter('currency'),
    },
    {
      label: 'Country',
      options: tableFilterOptions.country,
      key: 'country',
      onChange: updateFilter('country'),
    },
    {
      label: 'Custodian',
      options: tableFilterOptions.custodian,
      key: 'custodian',
      onChange: updateFilter('custodian'),
      minWidth: 150,
    },
    {
      label: 'Sector',
      options: tableFilterOptions.sector,
      key: 'sector',
      onChange: updateFilter('sector'),
      minWidth: 150,
    },
    {
      label: 'Position',
      options: [
        { label: 'Open', value: 'open' },
        { label: 'Closed', value: 'closed' },
        { label: 'All', value: '' },
        { label: 'Long', value: 'long' },
        { label: 'Short', value: 'short' },
      ],
      key: 'position',
      onChange: updateFilter('position'),
    },
  ];

  const activeColumns: ITableColumn[] = [];
  const allCols: ITableColumn[] = getSnapshotColumns(
    portfolioCurrency.symbol,
    portfolioInfo!.id,
    assetsClasses,
    expandedRowKeys,
    toggleRow
  );

  const matchingOptCols: ITableColumn[] = [];
  const visiblePositionFields = preferences.misc?.visiblePositionFields ?? DEFAULT_POSITION_FIELDS;

  allCols.forEach((col) => {
    if (!col.isOptional) {
      activeColumns.push(col);
    } else {
      matchingOptCols.push(col);
    }
    const columnSetting = visiblePositionFields[col.dataKey];
    if (columnSetting) {
      activeColumns.push(col);
    }
  });

  return (
    <div style={{ width: '100%', position: 'relative' }} className="snapshotTable">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <SnapshotHeader
          inceptionDate={portfolioInfo?.inceptionDate!}
          filterSettings={headerFilters}
          filterValues={snapshotFilters}
          updateFilter={updateFilter}
          resetFilters={resetSnapshotFilters}
        />
        <div style={{ paddingBottom: '0.5rem' }}>
          <TableColumnsFilter
            optionalColumns={matchingOptCols}
            preferenceKey={'visiblePositionFields'}
            defaults={DEFAULT_POSITION_FIELDS}
          />
        </div>
      </div>

      <Table
        columns={activeColumns}
        className={'position-table-surround'}
        data={filteredSnapshots}
        rowKey={'positionId'}
        expandedRowKeys={expandedRowKeys}
        rowExpandedHeight={280}
        renderRowExpanded={renderRowExpanded}
        rowHeight={24}
        headerHeight={50}
        onSortColumn={(sortBy, sortDirection) => {
          onSortData({
            name: sortBy,
            direction: sortDirection.toUpperCase() as 'ASC' | 'DESC',
          });
        }}
        sortColumn={snapshotsOrder.name}
        sortDirection={snapshotsOrder.direction.toLowerCase()}
        onRowClick={onRowClick}
      />

      {snapshotsLoading && (
        <div
          style={{
            position: 'absolute',
            height: '20%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 200,
              height: 80,
              borderRadius: 'var(--btn-border-radius)',
              backgroundColor: 'rgba(255,255,255,0.2)',
            }}
          >
            <Loader color={'var(--bg-dark)'} />
          </div>
        </div>
      )}
    </div>
  );
};

type PortfolioProperty = keyof IPortfolioSnapshot;

const getAvailableAssetClasses = (snapshots: IPortfolioSnapshot[], assetsClasses: IAssetClass[]) => {
  const availableAssetClasses: { [index: string]: IOptionType } = {};
  const emptyOption = { label: 'All', value: '' };

  // * Note - should cache for computational efficiency, to avoid iterating over assetClasses if subclass is already evaluated

  // * From each snapshot entry, derive its assetSubClass and associated assetClass
  snapshots.forEach((snapshot) => {
    const assetClass = assetsClasses.filter((o) => o.id === snapshot.instrumentAssetClassId)[0];
    if (assetClass && typeof availableAssetClasses[assetClass.id] === 'undefined') {
      availableAssetClasses[assetClass.id] = { label: assetClass.name, value: assetClass.id };
    }
  });
  const options = Object.entries(availableAssetClasses).map(([label, value]) => value);
  return [emptyOption, ...options];
};

// * Get a map whose keys are attributes of a portfolioSnapshot entry, and values are Select options of all present values
const getDistinctProperties = <T extends keyof IPortfolioSnapshot>(
  snapshots: IPortfolioSnapshot[],
  properties: PortfolioProperty[]
) => {
  const options: { [index: string]: { [index: string]: IOptionType } } = {};
  properties.forEach((property) => {
    options[property] = { default: { label: 'All', value: '' } };
    const propertyOptions = options[property];
    snapshots.forEach((snapshot) => {
      const option = snapshot[property] as string;
      if (typeof propertyOptions[option] === 'undefined') {
        propertyOptions[option] = { label: option, value: option };
      }
    });
  });

  const distinctProperties: { [index: string]: IOptionType[] } = {};

  Object.entries(options).map(([prop, values]) => {
    distinctProperties[prop] = Object.entries(values).map(([_, value]) => value);
  });

  return distinctProperties;
};

export default SnapshotTable;
