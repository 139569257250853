import React, { FunctionComponent, memo } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { generateDashboardMenu } from '../../../../../../../../services/constants/navigation';
import cn from 'classnames';
import s from './DashboardMenu.module.css';
import { IRootState } from '../../../../../../../../services/store';
import { connect, useDispatch } from 'react-redux';
import { Icon, Popover, Whisper } from 'rsuite';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { useTypedSelector } from '../../../../../../../../services/hooks/useTypedSelector';
import { DashboardActions } from '../../../../../../../../services/actions';
import { ALLOCATION_TYPES } from '../../../../../../../../services/constants/constants';

interface IOwnProps {
  assetsIds?: string[];
  selectedAssetId?: string;
}

interface IMapStateToProps {
  accountsData?: IRootState['accounts']['data'];
}

type Props = IOwnProps & IMapStateToProps;
type AllocationInfo = { name: string; id: string; value: number; percentage: number };

const DashboardMenu: FunctionComponent<Props> = ({ selectedAssetId }) => {
  const { portfolioAssetClassIds, portfolioStructure, portfolioInfo } = usePortfolio();

  const { optionsPerformance, period } = useTypedSelector((state) => ({
    optionsPerformance: state.dashboard?.options?.performance,
    period: state.dashboard.profitAttribution.period,
  }));

  const dispatch = useDispatch();

  const portfolioId = portfolioInfo.data?.id;

  React.useEffect(() => {
    if (portfolioId) {
      dispatch(
        DashboardActions.fetchPortfolioPerformanceByAsset(
          portfolioId,
          { id: 'EqOptions', type: ALLOCATION_TYPES.AssetSubClass },
          period,
          [],
          'OPTIONS_PERFORMANCE'
        )
      );
    }
  }, [portfolioId]);

  const lastOptionsResult = optionsPerformance?.data?.slice(-1)[0];
  let hasOptions = true;

  if (
    !lastOptionsResult ||
    (lastOptionsResult && lastOptionsResult[1] === 0 && lastOptionsResult[2] === 0 && lastOptionsResult[3] === 0)
  ) {
    hasOptions = false;
  }

  const menuItems = generateDashboardMenu(portfolioAssetClassIds, hasOptions).sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const [allocations, setAllocations] = React.useState<Record<string, AllocationInfo>>({});

  React.useEffect(() => {
    const newAllocations: Record<string, AllocationInfo> = {};

    for (const allocation of portfolioInfo.data?.allocation?.AssetClass) {
      newAllocations[allocation.id] = allocation;
    }

    setAllocations(newAllocations);
  }, [portfolioInfo]);

  return (
    <div>
      <ul className={s.DashboardMenu}>
        {menuItems.map((item) => {
          // const speaker = !item.submenu?.length ? (
          //   <div />
          // ) : (
          //   <Popover className={cn(s.assetClassPopover)}>
          //     <div className={cn(s.ulSurround)}>
          //       <ul className={cn(s.ul)}>
          //         {item.submenu.map((sub) => (
          //           <Link key={sub.id} to={`${item.path}/${sub.id}`}>
          //             <li>{sub.title}</li>
          //           </Link>
          //         ))}
          //       </ul>
          //     </div>
          //   </Popover>
          // );

          return (
            <li key={item.id} className={cn(s.DashboardMenuItem, item.id === selectedAssetId && s.selected)}>
              <div>
                <div className={s.AssetClassHeader}>
                  {!!item.icon && <img className={s.icon} src={item.icon} alt={item.title} title={item.title} />}
                  <div>
                    <div className={s.assetClassTitle}>{item.title}</div>
                    {allocations[item.id] && <div className={s.AssetClassTitle}>{allocations[item.id].percentage}%</div>}
                  </div>
                  {/*{item.submenu && item.submenu.length > 1 && <Icon className={cn(s.caretDown)} icon={'caret-down'} />}*/}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({portfo}, null, 2)}</pre>*/}
    </div>
  );
};
const mapStateToProps = (state: IRootState) => ({
  accountsData: state.accounts?.data,
});
// TODO: Disconnect and pass color prop from parent
export default connect(mapStateToProps)(DashboardMenu);
