import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderToggleBtns } from '../index';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  options: Array<{ text: string; value: any; subText?: string }>;
}

const SettlementOptions: FC<IProps> = ({ options }) => {
  return (
    <FormFieldWrapper className={s.settlementField} label="Settlement Account">
      <Field name="settlementOption" values={options} className="input--small" component={renderToggleBtns} />
    </FormFieldWrapper>
  );
};

export default SettlementOptions;
