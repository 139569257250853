import React, { Fragment, useEffect, useState } from 'react';

import { ASSETS } from '../../../../../../../../services/constants/assetClasses';

import s from './RealEstate.module.scss';
import { Loader, Period, Select, Tab, Tabs } from '../../../../../../../../components';
import Analytics from '../../../../../../../../services/utils/Analytics';
import {
  DASHBOARD_EQUITY_HELP_TEXT,
  DASHBOARD_REALESTATE_HELP_TEXT,
} from '../../../../../../../../services/constants/tooltipContextHelp';
import { getTopPositionsColumns } from './topPositionsColumns';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { GlobalPnl } from '../Shared/GlobalPnl';
import { realEstateCellRenderer } from './utils/realEstateCellRenderer';
import { GlobalStructure } from '../Shared/GlobalStructure';
import { GlobalIncome } from '../Shared/GlobalIncome';
import { RouteComponentProps, withRouter } from 'react-router';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import AssetReportWrapper from '../../components/AssetReportWrapper/AssetReportWrapper';
import { PortfolioHeader } from '../../../../../../components';
import { getTopPositionsByPnLColumns } from '../Equilty/topPositionsColumns';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';

type IProps = RouteComponentProps<{ page: string }>;

const RealEstate = ({ match }: IProps) => {
  const page = match?.params?.page || 'pnl';
  const {
    isFetched,
    hasData,
    isLoading,
    portfolioCurrencyFormatter,
    dashboardInfo,
    portfolioInfo,
    refetchTopPositions,
  } = usePortfolio(ASSETS.realEstate);

  const portfolioId = portfolioInfo.data?.id;
  const instrumentCurrencies = portfolioInfo.data?.instrumentCurrencies ? portfolioInfo.data?.instrumentCurrencies : [];
  const { period, updatePeriod } = usePeriod();
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'All', label: 'All' });

  useEffect(() => {
    if (portfolioId) {
      refetchTopPositions?.();
    }
  }, [portfolioId]);

  const handlePeriodChange = (currentPeriod: IPeriod) => {
    updatePeriod(currentPeriod);
  };

  const handleCurrencyFilterChange = (e: string) => {
    setSelectedCurrency({ value: e, label: e });
  };

  const renderCurrencySelector = (): React.ReactNode => {
    // ToDO: The Income period needs to have its OWN Period redux variable as it may differ from the performance period used in Performance chart. it's a Potential BUG
    const currencyOptions = [{ value: 'All', label: 'All' }];
    instrumentCurrencies.map((el) => currencyOptions.push({ value: el, label: el }));

    return (
      <Fragment>
        <span className={s.currencyFilterLabel}>Currency:</span>
        <Select
          options={currencyOptions}
          size="small"
          className={s.currencyFilter}
          selectedValue={selectedCurrency}
          onChange={handleCurrencyFilterChange}
        />
      </Fragment>
    );
  };

  const renderSections = (tabId: string) => {
    switch (tabId) {
      case 'pnl':
        Analytics.trackPageView('Real Estate P&L');
        return (
          <GlobalPnl
            period={period}
            handlePeriodChange={handlePeriodChange}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsByPnLColumns}
            cellRenderer={realEstateCellRenderer}
            assetClass={ASSETS.realEstate}
          />
        );
      case 'structure':
        Analytics.trackPageView('Real Estate Structure');
        return (
          <GlobalStructure
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsColumns}
            cellRenderer={realEstateCellRenderer}
            assetClass={ASSETS.realEstate}
          />
        );
      case 'income':
        Analytics.trackPageView('Real Estate Income');
        return (
          <GlobalIncome
            portfolioId={portfolioId!}
            renderCurrencySelector={renderCurrencySelector}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCurrency={selectedCurrency}
            profitPeriod={period}
            assetClass={ASSETS.realEstate}
            chartOneInfo={DASHBOARD_EQUITY_HELP_TEXT.IncomeDistributions}
            chartTwoInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartEstimatedIncomeNextYear}
          />
        );
    }
  };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo.totalPnlBase.value);
  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_REALESTATE_HELP_TEXT.PnLTotal },
    },
    {
      value: total,
      label: 'Total Real Estate',
      colored: true,
      info: { text: DASHBOARD_REALESTATE_HELP_TEXT.InfoTotal },
    },
  ];

  return (
    <AssetReportWrapper title="Real Estate" allLoaded={isFetched} noData={hasData}>
      <PortfolioHeader title="Real Estate" />
      <div>
        <PortfolioSubHeader stats={stats} title={page || 'pnl'} />
        <div className={s.assetsDashboardGrid}>{renderSections(page)}</div>
      </div>
    </AssetReportWrapper>
  );
};

export default withRouter(RealEstate);
