import React, { Fragment } from 'react';
import { blur, WrappedFieldProps } from 'redux-form';
import { Autocomplete } from '../UIWidgets/Autocomplete';
import { IAutocompleteProps } from '../UIWidgets/Autocomplete/Autocomplete';

type IProps = WrappedFieldProps & IAutocompleteProps & { editMode?: boolean; defaultValue?: any };

function renderAutocomplete({
  meta,
  input: { value, onChange, onBlur, ...inputBaseProps },
  inputProps = {},
  inputDisabled,
  defaultValue,
  ...props
}: IProps) {
  const _value = value || defaultValue || null;

  const haveError = props.editMode ? meta.error && meta.pristine : meta.error && meta.touched;
  const haveWarn = props.editMode ? meta.warning && meta.pristine : meta.warning && meta.touched;

  const _onBlur = () => {
    setTimeout(() => {
      if (!_value) {
        meta.dispatch(blur(meta.form, inputBaseProps.name, undefined, true));
      }
    }, 100);
  };

  return (
    <Fragment>
      <Autocomplete
        {...props}
        inputDisabled={inputDisabled}
        placeholder={inputProps.placeholder}
        haveError={haveError}
        haveWarn={haveWarn}
        disableReset={!!inputProps.required}
        value={_value}
        onSelect={onChange}
        // @ts-ignore
        inputProps={{
          ...inputBaseProps,
          ...inputProps,
          onBlur: _onBlur,
        }}
      />
      {haveError && <div className="input__error-text">{meta.error}</div>}
      {haveWarn && <div className="input__warning-text">{meta.warning}</div>}
    </Fragment>
  );
}

export default renderAutocomplete;
