import * as React from 'react';
import { useCurrencies } from '../../../../../../../../services/hooks/useCurrencies';
import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';
import { SelectPicker } from 'rsuite';
import s from './CurrencyComboBox.module.scss';
import { useState } from 'react';

interface IProps {
  onAdd: (item: ICurrency) => void;
}

const CurrencyComboBox = ({ onAdd }: IProps) => {
  const { currencies } = useCurrencies();
  const currencyOptions: IOptionType<ICurrency>[] = currencies.map((c) => ({
    label: c.name,
    value: c,
  }));

  const [currentValue, setCurrentValue] = useState('');
  const onSelect = (item: any) => {
    onAdd(item);
  };

  return (
    <SelectPicker
      cleanable={false}
      className={s.selectWrapper}
      placeholder={'Select Currency'}
      searchable={false}
      data={currencyOptions}
      value={currentValue}
      onSelect={(item) => onSelect(item)}
    />
  );
};

export default CurrencyComboBox;
