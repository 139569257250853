import cn from 'classnames';
import React, { Fragment, FunctionComponent, ReactText } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { UserHelpers } from '../../../services/utils/UserHelpers';
import s from '../Sidebar.module.css';
import CapabilitiesHelper from '../../../services/utils/CapabilitiesHelper';
import { IRootState } from '../../../services/store';
import { Popover, Whisper } from 'rsuite';

import { FaChevronUp } from 'react-icons/all';
import { usePreferences } from '../../../services/context/PreferencesContext';

const PROTOCOL_REGEX = /(http(s?):\/\/)|(ftp(s?):\/\/).*/g;

const Menu: FunctionComponent<{
  items: IMenuItem[];
  userCapabilities?: IRootState['userCapabilities']['data'];
  portfolioHasNegativePnl?: boolean;
  lessThanSixMonths?: boolean;
  lineHeight?: ReactText;
  indent?: ReactText;
  showLabel?: boolean;
}> = ({
  items,
  userCapabilities,
  lineHeight,
  indent,
  portfolioHasNegativePnl,
  showLabel,
  lessThanSixMonths,
}): React.ReactElement => {
  return (
    <Fragment>
      {items
        .filter((o) => !o.disabled)
        .map((item) => {
          const {
            id,
            title,
            icon,
            path,
            exact,
            collapsible,
            allowedRoles,
            requiredCapabilities,
            sensibleToPnlValue,
            minimumSixMonths,
            ...rest
          } = item;
          const hasPermission = !allowedRoles || UserHelpers.checkPermissions(allowedRoles as UserRole[]);
          const hasCapability = requiredCapabilities?.length
            ? requiredCapabilities.every((action) => CapabilitiesHelper.isActionAllowed(action, userCapabilities))
            : true;

          const useAbsoluteUrl = path.startsWith('http');

          const subMenu =
            rest.submenu?.filter(
              (i) =>
                !i.requiredCapabilities?.length ||
                i.requiredCapabilities.every((action) => CapabilitiesHelper.isActionAllowed(action, userCapabilities))
            ) || [];

          if (!showLabel) {
            subMenu.unshift({
              id,
              title,
              path,
              exact,
              allowedRoles,
              requiredCapabilities,
              sensibleToPnlValue,
            });
          }

          const hasSubMenu = !!subMenu?.length && showLabel;

          const speaker = subMenu?.length ? (
            <Popover>
              <SubMenu items={subMenu} portfolioHasNegativePnl={portfolioHasNegativePnl} />
            </Popover>
          ) : (
            <div />
          );

          // const speaker = <div />
          const Icon = icon;

          if (hasPermission && hasCapability) {
            if (collapsible) {
              return (
                <CollapsibleMenuSection
                  showLabel={showLabel}
                  item={item}
                  portfolioHasNegativePnl={portfolioHasNegativePnl}
                  lessThanSixMonths={lessThanSixMonths}
                />
              );
            }
            return (
              <MenuItem
                key={id}
                {...{
                  id,
                  title,
                  path,
                  exact,
                  useAbsoluteUrl,
                  speaker,
                  icon,
                  showLabel,
                  hasSubMenu,
                  height: lineHeight,

                  indent,
                }}
                // icon={<img className={s.icon} src={icon} alt={title} title={title} height={20} width={20} />}
                icon={!!Icon && <Icon size={'1rem'} />}
                disabledByTime={minimumSixMonths && lessThanSixMonths}
                disabled={portfolioHasNegativePnl && sensibleToPnlValue}
              />
            );
          }
        })}
    </Fragment>
  );
};

interface ICollapsibleMenuSectionProps {
  item: IMenuItem;
  showLabel?: boolean;
  portfolioHasNegativePnl?: boolean;
  lessThanSixMonths?: boolean;
}

export const CollapsibleMenuSection = ({
  item,
  showLabel,
  portfolioHasNegativePnl,
  lessThanSixMonths,
}: ICollapsibleMenuSectionProps) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const { preferences, updateMiscPreference } = usePreferences();

  React.useEffect(() => {
    if (typeof preferences?.misc?.menu !== 'undefined') {
      setCollapsed(!preferences.misc?.menu?.expandedSections?.[item.id]);
    }
  }, [preferences?.misc]);

  const { title, icon: Icon, submenu } = item;
  const allLinks: IMenuItem[] = [];

  const toggleExpanded = () => {
    setCollapsed((prev) => {
      updateMiscPreference('menu', {
        ...preferences.misc?.menu,
        expandedSections: {
          ...preferences.misc?.menu?.expandedSections,
          [item.id]: prev,
        },
      });
      return !prev;
    });
  };

  let speaker = <div />;
  if (!showLabel) {
    submenu?.forEach((i) => {
      if (!!i.submenu?.length) {
        i.submenu.map((sub) => allLinks.push({ ...sub, title: `${i.title}: ${sub.title}` }));
      } else {
        allLinks.push(i);
      }
    });

    speaker = (
      <Popover>
        <SubMenu items={allLinks} portfolioHasNegativePnl={portfolioHasNegativePnl} />
      </Popover>
    );
  }
  return (
    <Whisper speaker={speaker} trigger={'hover'} placement={'right'} enterable={true}>
      <div style={{ paddingLeft: showLabel ? 0 : 6 }}>
        <div onClick={toggleExpanded} className={cn(s.menuLink, showLabel && s.showLabel)}>
          <div className={cn(s.iconSurround)}>{<Icon />}</div>
          {showLabel && (
            <React.Fragment>
              <div className={s.navigationText}>{title}</div>
              <div style={{ flex: 1 }} />
              <div className={cn(s.collapseIconSurround, collapsed && s.collapsed)}>
                <FaChevronUp size={12} />
              </div>
            </React.Fragment>
          )}
        </div>
        {!collapsed && showLabel && (
          <Menu
            lineHeight={'1.75rem'}
            items={submenu ?? []}
            showLabel={showLabel}
            indent={'1rem'}
            portfolioHasNegativePnl={portfolioHasNegativePnl}
            lessThanSixMonths={lessThanSixMonths}
          />
        )}
      </div>
    </Whisper>
  );
};

interface IMenuItemProps extends IMenuItem {
  // id: string;
  // path: string;
  exact?: boolean;
  speaker?: React.ReactElement;
  showLabel?: boolean;
  useAbsoluteUrl?: boolean;
  disabled?: boolean;
  disabledByTime?: boolean;
  hasSubMenu?: boolean;
  height?: ReactText;
  indent?: ReactText;
}

export const MenuItem = ({
  id,
  title,
  path,
  exact,
  useAbsoluteUrl,
  speaker,
  showLabel,
  icon,
  disabled,
  hasSubMenu,
  disabledByTime,
  submenu,
  height,
  indent,
}: IMenuItemProps) => {
  return (
    <Whisper id={id} speaker={speaker ?? <div />} placement={'right'} trigger={'hover'} enterable={true}>
      <div style={{ paddingLeft: showLabel ? 0 : 6, height }}>
        <MenuLink
          to={path}
          id={id}
          exact={!!exact}
          useAbsoluteUrl={useAbsoluteUrl}
          disabled={disabled}
          disabledByTime={disabledByTime}
        >
          <div
            className={cn(s.menuLink, showLabel && s.showLabel, showLabel && hasSubMenu && s.hasSubMenu)}
            style={{ height, minHeight: typeof height !== 'undefined' ? height : '2.2rem' }}
          >
            <div className={cn(s.iconSurround)}>{icon}</div>
            {/*<div className={s.navigationText}>{showLabel ? title : title.substr(0,2)}</div>*/}
            {showLabel && (
              <div className={s.navigationText} style={{ paddingLeft: indent }}>
                {title}
              </div>
            )}
          </div>
        </MenuLink>
      </div>
    </Whisper>
  );
};

const SubMenu = ({ items, portfolioHasNegativePnl }: { items?: IMenuItem[]; portfolioHasNegativePnl?: boolean }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <div>
      <ul className={cn(s.ul)}>
        {items?.map((item) => {
          if (item.sensibleToPnlValue && portfolioHasNegativePnl) {
            return (
              <li key={item.id} className={cn(s.subMenuLink)}>
                ({item.title} unavailable with negative P&L)
              </li>
            );
          }
          return (
            <Link key={item.id} to={item.path}>
              <li className={cn(s.subMenuLink)}>{item.title}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

interface IMenuLinkProps {
  to: string;
  id: string;
  exact: boolean;
  useAbsoluteUrl?: boolean;
  disabled?: boolean;
  disabledByTime?: boolean;
}

const MenuLink: FunctionComponent<IMenuLinkProps> = ({
  useAbsoluteUrl,
  to,
  id,
  exact,
  disabled,
  disabledByTime,
  children,
}) => {
  const location = useLocation();
  if (disabledByTime) {
    return (
      <Whisper
        placement={'right'}
        trigger={'hover'}
        speaker={<Popover>Portfolio inception date is less than 6 months, you cannot see this page</Popover>}
      >
        <div className={cn(s.disabled)}>{children}</div>
      </Whisper>
    );
  } else if (disabled) {
    return (
      <Whisper
        placement={'right'}
        trigger={'hover'}
        speaker={<Popover>Invalid portfolio state, please add a subscription to fund trades</Popover>}
      >
        <div className={cn(s.disabled)}>{children}</div>
      </Whisper>
    );
  }

  if (useAbsoluteUrl) {
    return (
      <a href={to} target={'_blank'}>
        {children}
      </a>
    );
  }

  return (
    <NavLink
      to={to.startsWith('/administration') ? { pathname: to, state: { prevPath: location.pathname } } : to}
      id={id}
      exact={exact} // if exact then a url /portfolio !== /portfolio/1234 for the purposes of applying active format
      activeClassName={s.active}
      // className={cn( active && s.active)}
    >
      {children}
    </NavLink>
  );
};

export default React.memo(Menu);
