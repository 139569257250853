import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';

export class AssetsActions {
  static readonly ASSETS_REQUEST = 'ASSETS_REQUEST';
  static readonly ASSETS_SUCCESS = 'ASSETS_SUCCESS';
  static readonly ASSETS_ERROR = 'ASSETS_ERROR';
  static readonly ASSETS_RESET = 'ASSETS_RESET';

  static readonly ASSETS_SUBCLASS_REQUEST = 'ASSETS_SUBCLASS_REQUEST';
  static readonly ASSETS_SUBCLASS_SUCCESS = 'ASSETS_SUBCLASS_SUCCESS';
  static readonly ASSETS_SUBCLASS_ERROR = 'ASSETS_SUBCLASS_ERROR';

  static readonly LIGHT_ASSETS_REQUEST = 'LIGHT_ASSETS_REQUEST';
  static readonly LIGHT_ASSETS_SUCCESS = 'LIGHT_ASSETS_SUCCESS';
  static readonly LIGHT_ASSETS_ERROR = 'LIGHT_ASSETS_ERROR';

  static fetchAllAssetsClasses() {
    return ReduxHelper.createHttpRequestActionBundle<IAssetClass[]>(
      {
        url: getApiUrl('assets.all'),
      },
      AssetsActions.ASSETS_REQUEST,
      AssetsActions.ASSETS_SUCCESS,
      AssetsActions.ASSETS_ERROR
    );
  }

  static fetchAllAssetSubClasses() {
    return ReduxHelper.createHttpRequestActionBundle<IAssetSubClass[]>(
      {
        url: getApiUrl('assets.subclass.all'),
      },
      AssetsActions.ASSETS_SUBCLASS_REQUEST,
      AssetsActions.ASSETS_SUBCLASS_SUCCESS,
      AssetsActions.ASSETS_SUBCLASS_ERROR
    );
  }

  static fetchAllAssetLight() {
    return ReduxHelper.createHttpRequestActionBundle<IAssetClass[]>(
      {
        method: 'GET',
        url: getApiUrl('assets.light'),
      },
      AssetsActions.LIGHT_ASSETS_REQUEST,
      AssetsActions.LIGHT_ASSETS_SUCCESS,
      AssetsActions.LIGHT_ASSETS_ERROR
    );
  }
}
