import React, { Fragment } from 'react';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import s from '../../Transaction.module.scss';
import cn from 'classnames';
import { SYSTEM_EPOCH } from '../../../../../../../../services/constants/constants';
import { addDays } from '../../../../../../../../services/utils/DateHelpers';
import moment from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker } from '../../../../../../../../components';

type Props = {
  onChange?(val: string): void;
  name?: string;
  label?: string;
  maxDaysAhead?: number;
  className?: string;
};

const TradeTime = ({ onChange, name = 'tradeTime', label = 'Transaction date', maxDaysAhead = 0, className }: Props) => {
  const { control } = useFormContext();

  return (
    <FormFieldWrapper label={label} required={true} className={cn(className, s.tradeTime)}>
      <Controller
        name={name}
        defaultValue={moment().subtract(1, 'days')}
        control={control}
        as={(props) => {
          const { value, onChange: _onChange, error } = props;
          return (
            <Fragment>
              <DatePicker
                hideWeekends={false}
                className={s.datePicker}
                placeholder={'Date'}
                disabled={false}
                value={value ? new Date(moment(value).valueOf()) : null}
                onChange={(date) => {
                  _onChange(moment(date.value!).format('YYYY-MM-DD'));
                  onChange?.(moment(date.value!).format('YYYY-MM-DD'));
                }}
                min={SYSTEM_EPOCH}
                max={addDays(maxDaysAhead, new Date())!}
              />
              {/*{haveError && <div className="input__error-text">{meta.error}</div>}*/}
              {/*{haveWarn && <div className="input__warning-text">{meta.warning}</div>}*/}
            </Fragment>
          );
        }}
      />
    </FormFieldWrapper>
  );
};

export default TradeTime;
