import React, { Fragment, PureComponent } from 'react';

import s from './VolatilityContributionChart.module.css';

import { XAxisCanvas } from './components/XAxisCanvas';
import { YAxisCanvas } from './components/YAxisCanvas';
import { BarColumn } from './components/BarColumn';
import { BarArrows } from './components/BarArrows';
import { PortfolioVsBenchmarkBands } from './components/PortfolioVsBenchmarkBands';

import { IPortfolioVolatilityData, IPortfolioAssetClass } from '../../interfaces';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';

interface IProps {
  data: IPortfolioVolatilityData;
  period?: string;
  chartTitle: string;
  helpText?: string;
}

class VolatilityContributionChart extends PureComponent<IProps, IPortfolioAssetClass> {
  constructor(props: IProps) {
    super(props);
  }

  composeSlots = (riskBand: number, bandItems: IPortfolioAssetClass[], currentSlots: number[]) => {
    let preferredSlot = riskBand;
    let tmpSlots = [...currentSlots];

    if (riskBand === 1) {
      preferredSlot = 0;
    }
    if (riskBand === 7) {
      preferredSlot = 11;
    }

    // If more than 1 element for same band, we'll start so the middle element will be just above the appropriate band.
    // Or if left-side overflow occurs start from position zero.
    preferredSlot = preferredSlot - Math.floor(bandItems.length / 2);
    if (preferredSlot < 0) {
      preferredSlot = 0;
    }
    if (preferredSlot + bandItems.length > tmpSlots.length - 1) {
      preferredSlot = preferredSlot - (preferredSlot + bandItems.length - tmpSlots.length);
    }

    // Checking if the optimal slot is not already occupied. If so, find the nearest available to the right.
    if (tmpSlots[preferredSlot] !== 0) {
      for (let i = preferredSlot; i <= tmpSlots.length - 1; i++) {
        if (tmpSlots[i] === 0) {
          preferredSlot = i;
          break;
        }
      }
    }

    // Checking for Right-side overflow and readjust the slots if needed
    if (preferredSlot + bandItems.length > tmpSlots.length - 1) {
      tmpSlots = [...this.rearrangeSlots(tmpSlots)];
    }

    bandItems.forEach((el, index, bandElements) => {
      tmpSlots[preferredSlot] = el.id;
      preferredSlot++;
    });

    return tmpSlots;
  };

  rearrangeSlots = (currentSlots: number[]) => {
    const tmpSlots = [...currentSlots];

    for (let i = tmpSlots.length - 1; i >= 0; i--) {
      if (tmpSlots[i] !== 0 && tmpSlots[i - 1] === 0) {
        tmpSlots[i - 1] = tmpSlots[i];
        tmpSlots[i] = 0;
        break;
      }
    }

    // console.log('---- Slots AFTER Rearranged ----');
    // console.log(tmpSlots);

    return tmpSlots;
  };

  render() {
    const nrOfBands = 7;
    const barData = this.props.data.portfolio.assets;
    let slots = barData.length <= 12 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : barData.map((item) => 0);
    // Sort the bars by Bands and then by in-band percentages
    if (barData) {
      barData.sort((a, b) => {
        if (a.riskBand === b.riskBand) {
          return a.riskBandPercentage - b.riskBandPercentage;
        }
        return a.riskBand > b.riskBand ? 1 : -1;
      });
    }

    // Occupying the slots
    for (let i = 1; i <= nrOfBands; i++) {
      const bandItems = barData.filter((item) => item.riskBand === i);
      if (bandItems.length > 0) {
        slots = [...this.composeSlots(i, bandItems, slots)];
      }
    }

    // Array containing all the Bar Objects with empty bar slots for no bars to show
    const slotsFilledWithBars: any[] = [];
    slots.forEach((el) => {
      if (el !== 0) {
        slotsFilledWithBars.push(barData.find((obj) => obj.id === el));
      } else {
        slotsFilledWithBars.push({ emptySlot: true });
      }
    });

    // console.log('---- Slots filled with Bars ----');
    // console.log(slotsFilledWithBars);

    const chartTitle = this.props.chartTitle;

    return (
      <Fragment>
        <h3>
          {chartTitle}
          <ContextHelpIcon title={chartTitle} elementId={chartTitle} />
        </h3>
        <div className={s.ChartContainer}>
          <YAxisCanvas />
          <div className={s.BarsCanvas}>
            {slotsFilledWithBars.map((bar, index) => {
              if (bar.emptySlot) {
                return <div key={index} className={s.BarColumn} />;
              } else {
                return (
                  <BarColumn
                    key={index}
                    esmaBand={bar.riskBand}
                    barWeight={bar.weight}
                    barLegend={bar.name}
                    volatility={bar.volatility}
                    isShort={bar.isShort}
                  />
                );
              }
            })}
          </div>
          <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={this.props.helpText} />
          <div className={s.ArrowsCanvas}>
            <BarArrows bars={slotsFilledWithBars} />
          </div>
          <XAxisCanvas />
          <PortfolioVsBenchmarkBands
            portfolioBand={this.props.data.portfolio.riskBand}
            portfolioPercentage={this.props.data.portfolio.riskBandPercentage}
            benchmarkBand={this.props.data.benchmark.riskBand}
            benchmarkPercentage={this.props.data.benchmark.riskBandPercentage}
            numberOfBands={7}
          />
        </div>
      </Fragment>
    );
  }
}

export default VolatilityContributionChart;
