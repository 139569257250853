import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { IRootState } from '../../../../../../../../services/store';
import { useTypedSelector } from '../../../../../../../../services/hooks/useTypedSelector';

interface IProps {
  disabled?: boolean;
  defaultOption?: IOption<string>;
  baseCurrenciesOnly?: boolean;
  onChange?(value: IOption<string>): void;
  includeNok?: boolean;
  editMode: boolean;
}

type Props = IProps;
const CurrencyList: FC<Props> = ({ disabled, onChange, baseCurrenciesOnly = false, editMode, includeNok }) => {
  const { currencies } = useTypedSelector((state: IRootState) => ({
    currencies: state.currency.data,
  }));

  const filteredCurrencies = baseCurrenciesOnly
    ? includeNok
      ? currencies.filter((c) => c.isBase || c.name === 'NOK')
      : currencies.filter((c) => c.isBase)
    : currencies;

  console.log({ filteredCurrencies, includeNok });
  const currencyOptions: Array<IOption<string>> = filteredCurrencies.map((c) => ({
    id: c.name,
    name: `${c.name} (${c.symbol})`,
    value: c.name,
  }));

  return (
    <FormFieldWrapper label="Currency" className={cn(s.smallField, s.fieldsWrapper)} required={true}>
      <Field
        component={renderAutocomplete}
        name="currency"
        options={currencyOptions}
        theme="inverse"
        disableReset={true}
        disabled={disabled || editMode}
        inputDisabled={false}
        inputProps={{
          theme: 'inverse',
          className: 'input--small',
          placeholder: 'Select',
        }}
        onChange={typeof onChange === 'function' ? (onChange as any) : undefined}
      />
    </FormFieldWrapper>
  );
};

export default CurrencyList;
