import cn from 'classnames';
import React, { Component, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ClickOutside } from '../../../../components/ClickOutside';
import { Button, DropDown, Input } from '../../../../components/UIWidgets';

import { PATHS } from '../../../../router/paths';
import { ASSETS } from '../../../../services/constants/assetClasses';
import { currencyFormatterV2 } from '../../../../services/utils/CurrencyHelpers';
import { Utils } from '../../../../services/utils/Utils';
import alternativesPic from '../../../../static/images/assets/alternatives.svg';
import cashPic from '../../../../static/images/assets/cash.svg';
import commoditiesPic from '../../../../static/images/assets/commodities.svg';
import creditPic from '../../../../static/images/assets/credit.svg';
import cryptoPic from '../../../../static/images/assets/crypto-currency.svg';
import equityPic from '../../../../static/images/assets/equity.svg';
import fundPic from '../../../../static/images/assets/fund.svg';
import realAssetsPic from '../../../../static/images/assets/realAssets.svg';
import realEstatePic from '../../../../static/images/assets/realEstate.svg';
import sharedPic from '../../../../static/images/assets/shared.svg';
import clientPic from '../../../../static/images/client.svg';
import investPic from '../../../../static/images/dashboard/investV2.svg';
import calcPic from '../../../../static/images/dashboard/calcV2.svg';
import s from './PortfolioItem.module.scss';
import { IRootState } from '../../../../services/store';
import CapabilitiesHelper from '../../../../services/utils/CapabilitiesHelper';
import InfoBlockNoCard from '../../../Portfolio/components/InfoBlockV2/InfoBlockNoCard';
import { Popover, Whisper } from 'rsuite';

const ASSETS_PICS = {
  alternatives: alternativesPic,
  adjustments: cashPic,
  cash: cashPic,
  commodities: commoditiesPic,
  credit: creditPic,
  fixedIncome: creditPic,
  equity: equityPic,
  cryptoCurrencies: cryptoPic,
  fund: fundPic,
  realAssets: realAssetsPic,
  realEstate: realEstatePic,
  shared: sharedPic,
};

interface IAsset {
  children: any;
  id: string;
  name: string;
  percentage: number;
  value: number;
}

interface IProps {
  portfolio: IPortfolio;
  userCapabilities: IRootState['userCapabilities']['data'];
  onEdit(portfolio: IPortfolio): void;
  onDelete(portfolio: IPortfolio): void;
  isD2c?: boolean;
}

interface IState {
  editMode: boolean;
  editText: string;
}

class PortfolioItem extends Component<IProps, IState> {
  readonly state: IState = {
    editMode: false,
    editText: '',
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { portfolio } = this.props;
    if (prevProps.portfolio !== portfolio) {
      this.setEditMode(false);
    }
  }

  setEditMode = (value: boolean) => {
    const { portfolio } = this.props;
    this.setState({
      editMode: value,
      editText: value ? portfolio.name : '',
    });
  };

  // handleStatusChanged = (status: PortfolioAccessType) => {
  //   const { portfolio, onEdit } = this.props;
  //   if (portfolio.accessType as number === status) {
  //     return;
  //   }
  //
  //   onEdit({ ...portfolio, accessType: status });
  // };

  handlerNameChange = (value: string) => {
    this.setState({
      editText: value,
    });
  };

  handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.props.onEdit({ ...this.props.portfolio, name: this.state.editText });
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.props.onEdit({ ...this.props.portfolio, name: this.state.editText });
    }
  };

  renderDropDownContent = (): ReactNode => {
    const { portfolio, onDelete } = this.props;

    const canDeletePortfolio = CapabilitiesHelper.isActionAllowed('DELETE_PORTFOLIO', this.props.userCapabilities);

    return (
      <Fragment>
        {canDeletePortfolio && (
          <Fragment>
            <Link
              to={{
                pathname: PATHS.portfoliosManager.editSettings.path.replace(':portfolioId', portfolio.id),
              }}
              className={'drop-down__item'}
            >
              <Button variant="empty">Preferences</Button>
            </Link>
            {/*<Link*/}
            {/*  to={{*/}
            {/*    pathname: PATHS.portfoliosManager.portfolioTransfer.path.replace(':portfolioId', portfolio.id),*/}
            {/*  }}*/}
            {/*  className={'drop-down__item'}*/}
            {/*>*/}
            {/*  <Button variant="empty">Transfer Owner</Button>*/}
            {/*</Link>*/}
            {!this.props.isD2c && (
              <Link
                to={{
                  pathname: PATHS.portfoliosManager.portfolioShare.path.replace(':portfolioId', portfolio.id),
                }}
                className={'drop-down__item'}
              >
                <Button variant="empty">Manager Sharing</Button>
              </Link>
            )}
            {!portfolio.workgroup.clientUser && !this.props.isD2c && (
              <Link
                to={{
                  pathname: PATHS.portfoliosManager.portfolioAssign.path.replace(':portfolioId', portfolio.id),
                }}
                className={'drop-down__item'}
              >
                <Button variant="empty">Assign to Client</Button>
              </Link>
            )}
            <div className={cn('drop-down__item', 'button--remove')}>
              <Button variant="empty" onClick={() => onDelete(portfolio)}>
                Delete
              </Button>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  getAssetPic(assetClass: string) {
    return (ASSETS_PICS as any)[assetClass];
  }

  renderAsset(asset: IAsset) {
    const assetClass = Utils.getKeyByValue(ASSETS, asset.id);

    return (
      <Whisper placement={'bottom'} speaker={<Popover>{asset.name}</Popover>}>
        <div className={s.assetItem} key={asset.id}>
          <img
            style={{ width: 23 }}
            src={assetClass && this.getAssetPic(assetClass)}
            alt={asset.name}
            // data-tip={asset.name}
          />
          <p> {asset.percentage}%</p>
        </div>
      </Whisper>
    );
  }

  renderPerformanceWidget() {
    const { portfolio } = this.props;

    return (
      portfolio && (
        <Fragment>
          <div className={s.performanceContainer}>
            <div className={s.pnlPerformance}>
              <InfoBlockNoCard
                image={investPic}
                title="Profit & loss"
                value={currencyFormatterV2(portfolio.totalProfitAndLoss || 0, 0, 3)}
                colorValue={true}
                currency={portfolio.currency.name}
                /* contextHelpText={PORTFOLIOS_SUMMARY_HELP_TEXT.InfoPnL}*/
              />
              <InfoBlockNoCard
                image={calcPic}
                title="Value"
                value={currencyFormatterV2(portfolio.totalWealth || 0, 0, 3)}
                colorValue={true}
                currency={portfolio.currency.name}
              />
            </div>
            <div className={s.portfolioStructure}>
              <header className={s.portfolioStructureHeader}>Portfolio structure</header>
              <div className={s.portfolioStructureContent}>
                {portfolio.allocation?.AssetClass?.length ? (
                  <Fragment>
                    {portfolio.allocation.AssetClass.map((asset: IAsset) => this.renderAsset(asset))}{' '}
                    {portfolio.allocation.AssetClass.length >= 4 && <span className={s.more}>+ MORE</span>}
                  </Fragment>
                ) : (
                  <small className={s.noData}>
                    <i>No data to display</i>
                  </small>
                )}
              </div>
            </div>
            {/* <div className={s.moreDetails}>MORE DETAILS</div>*/}
          </div>
        </Fragment>
      )
    );
  }

  renderPortfolioTitle(portfolio: IPortfolio) {
    if (this.props.isD2c) {
      return (
        <Fragment>
          <div className={cn(s.portfolioName, 'title-medium')}>{portfolio.name}</div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className={cn(s.portfolioName, 'title-medium')}>
          {portfolio.clientName && <img alt="Client" className={s.titleIcon} src={clientPic} />}
          {portfolio.clientName && `${portfolio.clientName}${!portfolio.workgroup?.clientUser ? ' (unassigned)' : ''}: `}
          {portfolio.name}
        </div>
      </Fragment>
    );
  }

  render() {
    const { portfolio } = this.props;
    const { editMode, editText } = this.state;
    const canDeletePortfolio = CapabilitiesHelper.isActionAllowed('DELETE_PORTFOLIO', this.props.userCapabilities);

    return (
      <div className={s.wrapper}>
        <header className={s.header}>
          <div className={s.infoWrapper}>
            {editMode ? (
              <ClickOutside clickOutside={() => this.setEditMode(false)}>
                <div className={s.editWrapper}>
                  <Input
                    focus={true}
                    className={s.editInp}
                    value={editText}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.handlerNameChange(e.target.value)}
                  />
                  <Button variant="empty" className={s.saveChangesBtn} onClick={this.handleBtnClick} />
                </div>
              </ClickOutside>
            ) : (
              this.renderPortfolioTitle(portfolio)
            )}
            {canDeletePortfolio && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <DropDown>{this.renderDropDownContent()}</DropDown>
              </div>
            )}
          </div>
        </header>
        <footer className={s.footer}>{this.renderPerformanceWidget()}</footer>
      </div>
    );
  }
}

export default PortfolioItem;
