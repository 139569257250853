import React, { useState } from 'react';
import { ASSETS } from '../../../../../../../../services/constants/assetClasses';

import { DASHBOARD_COMM_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import moment from 'moment';
import { Loader, Period } from '../../../../../../../../components';
import { getTopPositionsByPnLColumns } from '../CryptoCurrency/topPositionsColumns';
import { commoditiesCellRenderer } from './utils/CommoditiesCellRenderer';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { INITIAL_PERIOD } from '../../../../../../../../services/reducers/DashboardReducers/PerformanceReducers';
import { GlobalPnlWithBreakdownPositions } from '../Shared/GlobalPnlWithBreakdownPositions';
import AssetReportWrapper from '../../components/AssetReportWrapper/AssetReportWrapper';
import { PortfolioHeader } from '../../../../../../components';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';

const Commodities = () => {
  const { hasData, isLoading, portfolioCurrencyFormatter, dashboardInfo, portfolioInfo } = usePortfolio(
    ASSETS.commodities
  );
  const { period, updatePeriod } = usePeriod();
  const portfolioId = portfolioInfo.data?.id;

  const handlePeriodChange = (currentPeriod: IPeriod) => {
    updatePeriod(currentPeriod);
  };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo.totalPnlBase.value);

  const stats: IStat[] = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_COMM_HELP_TEXT.InfoPnL },
    },
    { value: total, label: 'Total Commodity Value', colored: true, info: { text: DASHBOARD_COMM_HELP_TEXT.InfoTotal } },
    {
      value: dashboardInfo.inceptionPerformance || 0,
      label: 'Performance (TWR)',
      colored: true,
      type: 'percentage',
      info: { text: DASHBOARD_COMM_HELP_TEXT.InfoPerf },
    },
  ];

  return (
    <AssetReportWrapper title="Commodities" allLoaded={!isLoading} noData={hasData}>
      <PortfolioHeader title="Commodities" />
      <div>
        <PortfolioSubHeader stats={stats} title={'Commodities'} />
        <GlobalPnlWithBreakdownPositions
          period={period}
          handlePeriodChange={handlePeriodChange}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          portfolioId={portfolioId!}
          getTopPositionColumns={getTopPositionsByPnLColumns}
          cellRenderer={commoditiesCellRenderer}
          assetClass={ASSETS.commodities}
        />
      </div>
    </AssetReportWrapper>
  );
};

export default Commodities;
