import React, { FunctionComponent, useState } from 'react';
import PriceEntry from './PriceEntry';
import { v4 as uuid } from 'uuid';

interface IProps {
  prices: IPrice[];
  setPrices: (prices: IPrice[]) => void;
  dateLabel?: string;
  priceLabel?: string;
}

const PriceEntryContainer: FunctionComponent<IProps> = ({ prices, setPrices, dateLabel, priceLabel }) => {
  const [showWeekends, setShowWeekends] = useState(false);
  const addPrice = () => setPrices([...prices, { code: uuid() }]);
  const deletePrice = (code: string) => setPrices([...prices].filter((price) => price.code !== code));
  const updatePrice = (code: string, newPrice: number) => {
    const newPrices = prices.map((price) => (price.code !== code ? price : { ...price, price: newPrice }));
    setPrices(newPrices);
  };
  const updateDate = (code: string, valuationDate: Date) => {
    const newPrices = prices.map((price) =>
      price.code !== code ? price : { ...price, valuationDate: new Date(valuationDate) }
    );
    setPrices(newPrices);
  };
  const sortPrices = () => {
    const sortedPrices = [...prices].sort((a, b) =>
      typeof a.valuationDate === 'undefined'
        ? 1
        : typeof b.valuationDate === 'undefined'
        ? -1
        : new Date(a.valuationDate) > new Date(b.valuationDate)
        ? 1
        : -1
    );
    setPrices(sortedPrices);
  };
  return (
    <PriceEntry
      onAddClick={addPrice}
      {...{
        prices,
        deletePrice,
        updateDate,
        updatePrice,
        sortPrices,
        showWeekends,
        setShowWeekends,
        dateLabel,
        priceLabel,
      }}
    />
  );
};

export default PriceEntryContainer;
