import { AxiosPromise } from 'axios';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import InstrumentMarket from './InstrumentMarket';

import { Utils } from '../../../../../../services/utils/Utils';

import { MarketActions } from '../../../../../../services/actions';
import { AsyncActionDispatch } from '../../../../../../services/utils/ReduxHelper';
import { AppActions } from '../../../../../../services/actions/AppActions';

import { IRootState } from '../../../../../../services/store';

import { IPaginationContent } from '../../../../../../models/redux/ILoadingPaginationDataState';

interface IMapStateToProps {
  instrumentInfo: IInstrument | null;
  marketData: IMarket[];
  marketDataIsFetched: boolean;
  marketPeriod: IPeriod;
  marketOrder: IOrder;
}

interface IDispatchToProps {
  fetchInstrumentMarket: (
    instrumentId: string,
    order: IOrder,
    period: IPeriod,
    page?: number
  ) => AxiosPromise<IPaginationContent<IMarket>>;
  changePricePeriod: (period: IPeriod) => void;
  createMarket: (market: IMarket) => AxiosPromise<IMarket>;
  updateMarket: (market: IMarket) => AxiosPromise<IMarket>;
  deleteMarket: (market: IMarket) => AxiosPromise<void>;
  changeMarketSort: (order: IOrder) => void;
  showNotification: (notification: INotification) => void;
  resetMarketList: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps;

class InstrumentMarketContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    this.fetchMarketDate();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { instrumentInfo, marketOrder, marketPeriod } = this.props;
    if (
      (prevProps.instrumentInfo !== instrumentInfo && instrumentInfo && instrumentInfo.id) ||
      prevProps.marketPeriod !== marketPeriod ||
      prevProps.marketOrder !== marketOrder
    ) {
      this.fetchMarketDate();
    }
  }

  componentWillUnmount(): void {
    this.props.resetMarketList();
  }

  handleOnMarketSave = (market: IMarket): AxiosPromise<IMarket> => {
    return this.props.createMarket(market).then((response) => {
      this.fetchMarketDate();
      return response;
    });
  };

  handleOnMarketUpdate = (market: IMarket): AxiosPromise<IMarket> => {
    return this.props.updateMarket(market).then((response) => {
      this.fetchMarketDate();
      return response;
    });
  };

  handleOnMarketDelete = (market: IMarket) => {
    const { deleteMarket } = this.props;
    Utils.createConfirm({
      text: `Do you really want to remove <b>${market.closeTimestamp}</b>? This action cannot be undone.`,
      confirmBtnText: 'Remove',
      onConfirm: () => {
        deleteMarket(market).then(() => {
          this.fetchMarketDate();
        });
      },
    });
  };

  render() {
    const {
      instrumentInfo,
      marketData,
      marketDataIsFetched,
      marketPeriod,
      marketOrder,
      changePricePeriod,
      changeMarketSort,
    } = this.props;

    if (!instrumentInfo || !instrumentInfo.id) {
      return <Fragment />;
    }

    return (
      <InstrumentMarket
        instrumentId={instrumentInfo.id}
        marketData={marketData}
        marketDataIsFetched={marketDataIsFetched}
        marketPeriod={marketPeriod}
        marketOrder={marketOrder}
        onPeriodChange={changePricePeriod}
        onMarketSave={this.handleOnMarketSave}
        onMarketUpdate={this.handleOnMarketUpdate}
        onMarketDelete={this.handleOnMarketDelete}
        onChangeMarketSort={changeMarketSort}
      />
    );
  }

  fetchMarketDate = () => {
    const { instrumentInfo, marketOrder, marketPeriod, fetchInstrumentMarket } = this.props;
    if (!instrumentInfo || !instrumentInfo.id) {
      return;
    }
    fetchInstrumentMarket(instrumentInfo.id, marketOrder, marketPeriod);
  };
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    instrumentInfo: state.instruments.instrumentInfo.info.data,
    marketData: state.instruments.instrumentInfo.market.content,
    marketDataIsFetched: state.instruments.instrumentInfo.market.isFetched,
    marketPeriod: state.instruments.instrumentInfo.market.period,
    marketOrder: state.instruments.instrumentInfo.market.order,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchInstrumentMarket: (instrumentId: string, order: IOrder, period: IPeriod) =>
    dispatch(MarketActions.fetchAllMarkets(instrumentId, order, period)),
  changePricePeriod: (period: IPeriod) => dispatch(MarketActions.changeMarketPeriod(period)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  createMarket: (market: IMarket) => dispatch(MarketActions.createMarket(market)),
  updateMarket: (market: IMarket) => dispatch(MarketActions.updateMarket(market)),
  deleteMarket: (market: IMarket) => dispatch(MarketActions.deleteMarket(market)),
  changeMarketSort: (order: IOrder) => dispatch(MarketActions.changeMarketSort(order)),
  resetMarketList: () => dispatch(MarketActions.resetMarketList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentMarketContainer);
