import * as React from 'react';
import s from './CryptoIncome.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';
import { Card } from '@iliotech/storybook';
import { PortfolioHeader, Widget } from '../../../../../../components';
import { DividendsChart } from '../Equilty/scenes/PnlBreakdownDetail/components/DividendsChart';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';
import { Loader } from '../../../../../../../../components';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import { AssetReportWrapper } from '../../components';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { ASSETS } from '../../../../../../../../services/constants/assetClasses';
import { useEffect } from 'react';

type IProps = RouteComponentProps<{ portfolioId: string }>;

const CryptoIncome = ({ match }: IProps) => {
  const { isLoading, hasData, refetchTopPositions } = usePortfolio(ASSETS.cryptoCurrencies);
  const { portfolioCurrencyFormatter, dashboardInfo, portfolioInfo } = usePortfolioInfo();
  const portfolioId = portfolioInfo.data?.id;
  const { period } = usePeriod();

  const defaultPeriod = PeriodHelper.preparePeriodForRequest(period, 'DEFAULT');

  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo.totalPnlBase.value);
  const lifetimeDividends = portfolioCurrencyFormatter(dashboardInfo.past.value);

  useEffect(() => {
    if (portfolioId) {
      refetchTopPositions?.();
    }
  }, [portfolioId]);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: total,
      label: 'Current funds value',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoTotalEquity },
    },
    {
      value: lifetimeDividends,
      label: 'Lifetime distributions',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
  ];

  const dividends = useApi(
    getApiUrl('equity.dividendsDetailed'),
    {
      method: 'POST',
      data: {
        portfolioId,
        assetClass: 'CryptoCurrencies',
        period: {
          from: defaultPeriod.from,
          to: defaultPeriod.to,
        },
      },
    },
    {
      enabled: !!portfolioId,
    }
  );

  if (dividends.isLoading || !portfolioId) {
    return <Loader />;
  }

  return (
    <AssetReportWrapper
      title="Crypto Currency"
      allLoaded={!isLoading}
      noData={hasData}
      message={"You haven't added any crypto earning yet"}
    >
      <div>
        <PortfolioHeader title="Equity" />
        <PortfolioSubHeader stats={stats} title={'Crypto Income'} />
        <Widget>
          <Card>
            <Widget className={s.defaultContainer}>
              <Widget title={'Crypto Earn Income'} className={s.defaultContainer}>
                <DividendsChart
                  chartData={[dividends.data?.[0]]}
                  assetClass={'CryptoCurrency'}
                  portfolioCurrencyFormatter={portfolioCurrencyFormatter}
                />
              </Widget>
            </Widget>
          </Card>
        </Widget>
      </div>
    </AssetReportWrapper>
  );
};

export default withRouter(CryptoIncome);
