import React, { Fragment } from 'react';

import s from '../BetaVsPortfolioBenchmarkChart.module.css';
import { TagsArrows } from './TagsArrows';
import { getBetaColor } from '../getBetaColor';

export function VolatilityTagCanvas(props: any) {
  // const barColors = ['#0077b2', '#fb9900', '#f27800', '#e13800', '#a50009'];

  const portfolioBeta = props.portfolioBeta;
  const isNegativeBetas = props.isNegativeBetas;

  const color = getBetaColor(props.portfolioBeta, props.barColors);

  let slots = [0, 0, 0, 0, 0];

  if (isNegativeBetas) {
    if (portfolioBeta >= -3 && portfolioBeta < -2) {
      slots = [1, 0, 0, 0, 0];
    }
    if (portfolioBeta >= -2 && portfolioBeta < -1) {
      slots = [0, 1, 0, 0, 0];
    }
    if (portfolioBeta >= -1 && portfolioBeta < 1) {
      slots = [0, 0, 1, 0, 0];
    }
    if (portfolioBeta >= 1 && portfolioBeta < 2) {
      slots = [0, 0, 0, 1, 0];
    }
    if (portfolioBeta >= 2) {
      slots = [0, 0, 0, 0, 1];
    }
  } else {
    if (portfolioBeta >= 0 && portfolioBeta < 0.5) {
      slots = [1, 0, 0, 0, 0];
    }
    if (portfolioBeta >= 0.5 && portfolioBeta < 0.8) {
      slots = [0, 1, 0, 0, 0];
    }
    if (portfolioBeta >= 0.8 && portfolioBeta < 1.8) {
      slots = [0, 0, 1, 0, 0];
    }
    if (portfolioBeta >= 1.8 && portfolioBeta < 2.2) {
      slots = [0, 0, 0, 1, 0];
    }
    if (portfolioBeta >= 2.2) {
      slots = [0, 0, 0, 0, 1];
    }
  }

  return (
    <Fragment>
      <div className={s.volatilityTagContainer}>
        {slots.map((val, index) => {
          if (val === 0) {
            return <div key={index} className={s.volatilityTagSlot} />;
          } else {
            return (
              <div key={index} className={s.volatilityTagSlot}>
                <div className={s.volatilityTag} style={{ backgroundColor: color }}>
                  Portfolio BETA <br />
                  <strong>{portfolioBeta}</strong>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={s.TagsArrowsContainer}>
        <TagsArrows
          slotPosition={slots.indexOf(1)}
          portfolioBeta={portfolioBeta}
          color={getBetaColor(portfolioBeta, props.barColors)}
          axisTicks={3}
          nrOfAxisSlots={5}
          isNegativeBetas={isNegativeBetas}
        />
      </div>
    </Fragment>
  );
}
