import moment from 'moment';
import { IPortfolioErrorsTrade, IPortfolioTrade, IPortfolioValidatedTrade } from '../../models/IPortfolioTrade';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { IPaginationContent } from '../../models/redux/ILoadingPaginationDataState';
import { TABLE_PAGE_SIZE } from '../constants/constants';
import { getApiUrl } from '../constants/endpoints';
import { IRootState } from '../store';
import Analytics from '../utils/Analytics';
import { AsyncActionDispatch, ReduxHelper } from '../utils/ReduxHelper';

const setCalculatedToTradeDTO = (
  data: IPaginationContent<IPortfolioSnapshot>
): IPaginationContent<IPortfolioSnapshot> => {
  const preparedContent = data.content.map((snapshot: IPortfolioSnapshot) => {
    return { ...snapshot, calculated: true };
  });
  return { ...data, content: preparedContent };
};

class SnapshotActions {
  static readonly TRADES_REQUEST = 'TRADES_REQUEST';
  static readonly TRADES_SUCCESS = 'TRADES_SUCCESS';
  static readonly TRADES_ERROR = 'TRADES_ERROR';
  static readonly TRADES_RESET = 'TRADES_RESET';
  static readonly TRADES_CHANGE_SORT = 'TRADES_CHANGE_SORT';

  static readonly SAVED_TRADES_REQUEST = 'SAVED_TRADES_REQUEST';
  static readonly SAVED_TRADES_SUCCESS = 'SAVED_TRADES_SUCCESS';
  static readonly SAVED_TRADES_ERROR = 'SAVED_TRADES_ERROR';
  static readonly SAVED_TRADES_RESET = 'SAVED_TRADES_RESET';
  static readonly SAVED_TRADES_CHANGE_SORT = 'SNAPSHOT_CHANGE_SNAPSHOT_SORT';

  static readonly ADD_SAVED_TRADES_REQUEST = 'ADD_SAVED_TRADES_REQUEST';
  static readonly ADD_SAVED_TRADES_SUCCESS = 'ADD_SAVED_TRADES_SUCCESS';
  static readonly ADD_SAVED_TRADES_ERROR = 'ADD_SAVED_TRADES_ERROR';

  static readonly DELETE_SAVED_TRADES_REQUEST = 'DELETE_SAVED_TRADES_REQUEST';
  static readonly DELETE_SAVED_TRADES_SUCCESS = 'DELETE_SAVED_TRADES_SUCCESS';
  static readonly DELETE_SAVED_TRADES_ERROR = 'DELETE_SAVED_TRADES_ERROR';

  static readonly SNAPSHOT_CONFIRM_REQUEST = 'SNAPSHOT_CONFIRM_REQUEST';
  static readonly SNAPSHOT_CONFIRM_SUCCESS = 'SNAPSHOT_CONFIRM_SUCCESS';
  static readonly SNAPSHOT_CONFIRM_ERROR = 'SNAPSHOT_CONFIRM_ERROR';

  static readonly SNAPSHOT_DISCARD_REQUEST = 'SNAPSHOT_DISCARD_REQUEST';
  static readonly SNAPSHOT_DISCARD_SUCCESS = 'SNAPSHOT_DISCARD_SUCCESS';
  static readonly SNAPSHOT_DISCARD_ERROR = 'SNAPSHOT_DISCARD_ERROR';

  static readonly SNAPSHOT_UPLOAD_REQUEST = 'SNAPSHOT_UPLOAD_REQUEST';
  static readonly SNAPSHOT_UPLOAD_SUCCESS = 'SNAPSHOT_UPLOAD_SUCCESS';
  static readonly SNAPSHOT_UPLOAD_ERROR = 'SNAPSHOT_UPLOAD_ERROR';
  static readonly SNAPSHOT_SET_UPLOAD_PROGRESS = 'SNAPSHOT_SET_UPLOAD_PROGRESS';

  static readonly SNAPSHOT_REQUEST = 'SNAPSHOT_REQUEST';
  static readonly SNAPSHOT_SUCCESS = 'SNAPSHOT_SUCCESS';
  static readonly SNAPSHOT_ERROR = 'SNAPSHOT_ERROR';
  static readonly SNAPSHOT_RESET = 'SNAPSHOT_RESET';
  static readonly SNAPSHOT_CHANGE_SORT = 'SNAPSHOT_CHANGE_SORT';

  static readonly SEARCH_WARRANT_BY_NAME_REQUEST = 'SEARCH_WARRANT_BY_NAME_REQUEST';
  static readonly SEARCH_WARRANT_BY_NAME_SUCCESS = 'SEARCH_WARRANT_BY_NAME_SUCCESS';
  static readonly SEARCH_WARRANT_BY_NAME_ERROR = 'SEARCH_WARRANT_BY_NAME_ERROR';
  static readonly SEARCH_WARRANT_BY_NAME_RESET = 'SEARCH_WARRANT_BY_NAME_RESET';

  static readonly SEARCH_ASSET_BY_NAME_REQUEST = 'SEARCH_ASSET_BY_NAME_REQUEST';
  static readonly SEARCH_ASSET_BY_NAME_SUCCESS = 'SEARCH_ASSET_BY_NAME_SUCCESS';
  static readonly SEARCH_ASSET_BY_NAME_ERROR = 'SEARCH_ASSET_BY_NAME_ERROR';
  static readonly SEARCH_ASSET_BY_NAME_RESET = 'SEARCH_ASSET_BY_NAME_RESET';

  static readonly SEARCH_BONDS_BY_NAME_REQUEST = 'SEARCH_BONDS_BY_NAME_REQUEST';
  static readonly SEARCH_BONDS_BY_NAME_SUCCESS = 'SEARCH_BONDS_BY_NAME_SUCCESS';
  static readonly SEARCH_BONDS_BY_NAME_ERROR = 'SEARCH_BONDS_BY_NAME_ERROR';
  static readonly SEARCH_BONDS_BY_NAME_RESET = 'SEARCH_BONDS_BY_NAME_RESET';

  static readonly SEARCH_OPTIONS_REQUEST = 'SEARCH_OPTIONS_REQUEST';
  static readonly SEARCH_OPTIONS_SUCCESS = 'SEARCH_OPTIONS_SUCCESS';
  static readonly SEARCH_OPTIONS_ERROR = 'SEARCH_OPTIONS_ERROR';
  static readonly SEARCH_OPTIONS_RESET = 'SEARCH_OPTIONS_RESET';

  static readonly FX_RATE_REQUEST = 'FX_RATE_REQUEST';
  static readonly FX_RATE_SUCCESS = 'FX_RATE_SUCCESS';
  static readonly FX_RATE_ERROR = 'FX_RATE_ERROR';
  static readonly FX_RATE_RESET = 'FX_RATE_RESET';

  static readonly PRICE_REQUEST = 'PRICE_REQUEST';
  static readonly PRICE_SUCCESS = 'PRICE_SUCCESS';
  static readonly PRICE_ERROR = 'PRICE_ERROR';
  static readonly PRICE_RESET = 'PRICE_RESET';

  static readonly SNAPSHOT_PREVIEW_REQUEST = 'SNAPSHOT_PREVIEW_REQUEST';
  static readonly SNAPSHOT_PREVIEW_SUCCESS = 'SNAPSHOT_PREVIEW_SUCCESS';
  static readonly SNAPSHOT_PREVIEW_ERROR = 'SNAPSHOT_PREVIEW_ERROR';
  static readonly SNAPSHOT_PREVIEW_CHANGE_SORT = 'SNAPSHOT_PREVIEW_CHANGE_SORT';
  static readonly SNAPSHOT_PREVIEW_RESET = 'SNAPSHOT_PREVIEW_RESET';

  static readonly START_EDIT_UNCONFIRMED_TRADE = 'START_EDIT_UNCONFIRMED_TRADE';
  static readonly FINISH_EDIT_UNCONFIRMED_TRADE = 'FINISH_EDIT_UNCONFIRMED_TRADE';

  static readonly UPDATE_SAVED_TRADES_REQUEST = 'UPDATE_SAVED_TRADES_REQUEST';
  static readonly UPDATE_SAVED_TRADES_SUCCESS = 'UPDATE_SAVED_TRADES_SUCCESS';
  static readonly UPDATE_SAVED_TRADES_ERROR = 'UPDATE_SAVED_TRADES_ERROR';

  static readonly AMEND_TRADE_REQUEST = 'AMEND_TRADE_REQUEST';
  static readonly AMEND_TRADE_SUCCESS = 'AMEND_TRADE_SUCCESS';
  static readonly AMEND_TRADE_ERROR = 'AMEND_TRADE_ERROR';

  static readonly DELETE_TRADE_REQUEST = 'DELETE_TRADE_REQUEST';
  static readonly DELETE_TRADE_SUCCESS = 'DELETE_TRADE_SUCCESS';
  static readonly DELETE_TRADE_ERROR = 'DELETE_TRADE_ERROR';

  static readonly FETCH_ADJUSTMENT_TYPES_REQUEST = 'FETCH_ADJUSTMENT_TYPES_REQUEST';
  static readonly FETCH_ADJUSTMENT_TYPES_SUCCESS = 'FETCH_ADJUSTMENT_TYPES_SUCCESS';
  static readonly FETCH_ADJUSTMENT_TYPES_ERROR = 'FETCH_ADJUSTMENT_TYPES_ERROR';

  static readonly UPDATE_SNAPSHOT_FILTER = 'UPDATE_SNAPSHOT_FILTER';
  static readonly UPDATE_SNAPSHOT_DATE_FILTER = 'UPDATE_SNAPSHOT_DATE_FILTER';
  static readonly RESET_SNAPSHOT_FILTERS = 'RESET_SNAPSHOT_FILTERS';
  static readonly ALMOST_RESET_SNAPSHOT_FILTERS = 'ALMOST_RESET_SNAPSHOT_FILTERS';

  static fetchPortfolioSnapshots(
    portfolioId: string,
    order: IOrder,
    page: number = 0,
    confirmed: boolean = true,
    size = 1000,
    fromDate?: Date,
    toDate?: Date
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IPortfolioSnapshot>>(
      {
        url: getApiUrl('snapshot.snapshots'),
        data: {
          portfolioId,
          page,
          size,
          order,
          confirmed,
          fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
          toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
        },
      },
      SnapshotActions.SNAPSHOT_REQUEST,
      SnapshotActions.SNAPSHOT_SUCCESS,
      SnapshotActions.SNAPSHOT_ERROR
    );
  }

  static changeSnapshotSort(sort: IOrder): IActionPayload<IOrder> {
    return { type: SnapshotActions.SNAPSHOT_CHANGE_SORT, payload: sort };
  }

  static resetPortfolioSnapshot(): IActionPayload {
    return {
      type: SnapshotActions.SNAPSHOT_RESET,
    };
  }

  static fetchPortfolioTrades(portfolioId: string, order: IOrder, page: number = 0) {
    return (dispatch: AsyncActionDispatch, getState: () => IRootState) => {
      return dispatch(
        ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IPortfolioTrade>>(
          {
            url: getApiUrl('snapshot.trades.all'),
            data: {
              portfolioId,
              page,
              size: TABLE_PAGE_SIZE,
              order,
            },
          },
          SnapshotActions.TRADES_REQUEST,
          SnapshotActions.TRADES_SUCCESS,
          SnapshotActions.TRADES_ERROR
        )
      );
    };
  }

  static changeTradesSort(sort: IOrder): IActionPayload<IOrder> {
    return { type: SnapshotActions.TRADES_CHANGE_SORT, payload: sort };
  }

  static fetchPortfolioSavedTrades(portfolioId: string, order: IOrder, page: number = 0) {
    return (dispatch: AsyncActionDispatch, getState: () => IRootState) => {
      return dispatch(
        ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IPortfolioValidatedTrade>>(
          {
            url: getApiUrl('snapshot.tempTrades.all'),
            data: {
              portfolioId,
              page,
              size: TABLE_PAGE_SIZE,
              order,
            },
          },
          SnapshotActions.SAVED_TRADES_REQUEST,
          SnapshotActions.SAVED_TRADES_SUCCESS,
          SnapshotActions.SAVED_TRADES_ERROR
        )
      );
    };
  }

  static changeSavedTradesSort(sort: IOrder): IActionPayload<IOrder> {
    return { type: SnapshotActions.SAVED_TRADES_CHANGE_SORT, payload: sort };
  }

  static confirmSavedTrades(portfolioId: string, tradeKeys?: number[]) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: 'v3/portfolio/' + portfolioId + '/trades/confirm',
        data: {
          tradeIds: tradeKeys,
        },
      },
      SnapshotActions.SNAPSHOT_CONFIRM_REQUEST,
      SnapshotActions.SNAPSHOT_CONFIRM_SUCCESS,
      SnapshotActions.SNAPSHOT_CONFIRM_ERROR
    );
  }

  static discardSavedTrades(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('snapshot.discardTrades'),
        data: {
          portfolioId,
        },
      },
      SnapshotActions.SNAPSHOT_DISCARD_REQUEST,
      SnapshotActions.SNAPSHOT_DISCARD_SUCCESS,
      SnapshotActions.SNAPSHOT_DISCARD_ERROR
    );
  }

  static resetPortfolioTrades(): IActionPayload {
    return {
      type: SnapshotActions.TRADES_RESET,
    };
  }

  static saveTrades(portfolioId: string, trades: IPortfolioTrade[]) {
    Analytics.track('Save Trades');
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('snapshot.trades.add'),
        data: {
          portfolioId,
          trades: trades.map((item) => ({
            tradeTime: item.tradeTime,
            sourceId: item.optionTicker?.sourceId || item.instrument?.sourceId,
            quantity: Math.abs(item.quantity),
            price: item.price,
            commission: item.commission,
            fxRate: item.fxRate,
            notes: item.notes,
            custodian: item.custodian?.id ? item.custodian.id : item.custodian ?? null,
            operation: item.operation,
            tradeCosts: item.tradeCosts,
            settlementDate: item.settlementDate,
            settlementOption: item.settlementOption,
            accruedInterestLocal:
              typeof !item.accruedInterestLocal === 'undefined' ||
              (typeof item.accruedInterestLocal === 'number' && item.accruedInterestLocal < 0)
                ? null
                : item.accruedInterestLocal,
            currency: item.currency,
            otherSourceId: item.otherSourceId,
            otherQuantity: item.otherQuantity,
          })),
        },
      },
      SnapshotActions.ADD_SAVED_TRADES_REQUEST,
      SnapshotActions.ADD_SAVED_TRADES_SUCCESS,
      SnapshotActions.ADD_SAVED_TRADES_ERROR
    );
  }

  static removeTrades(portfolioId: string, trades: IPortfolioTrade[]) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('snapshot.tempTrades.remove'),
        data: {
          portfolioId,
          keys: trades.map((item) => item.key),
        },
      },
      SnapshotActions.DELETE_SAVED_TRADES_REQUEST,
      SnapshotActions.DELETE_SAVED_TRADES_SUCCESS,
      SnapshotActions.DELETE_SAVED_TRADES_ERROR
    );
  }

  static resetSavedTrades(): IActionPayload {
    return {
      type: SnapshotActions.SAVED_TRADES_RESET,
    };
  }

  static uploadTrades(portfolioId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('portfolioId', portfolioId);

    return (dispatch: AsyncActionDispatch) => {
      return dispatch(
        ReduxHelper.createHttpRequestActionBundle<void>(
          {
            url: getApiUrl('snapshot.uploadTrades'),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            onUploadProgress: (progressEvent) => dispatch(SnapshotActions.setProgress(progressEvent)),
          },
          SnapshotActions.SNAPSHOT_UPLOAD_REQUEST,
          SnapshotActions.SNAPSHOT_UPLOAD_SUCCESS,
          SnapshotActions.SNAPSHOT_UPLOAD_ERROR
        )
      );
    };
  }

  static setProgress(progress: ProgressEvent): IActionPayload<{ progress: number }> {
    return {
      type: SnapshotActions.SNAPSHOT_SET_UPLOAD_PROGRESS,
      payload: { progress: Math.round((progress.loaded * 100) / progress.total) },
    };
  }

  static searchAssetByName(name: string) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('instruments.find'),
        data: {
          text: name,
        },
      },
      SnapshotActions.SEARCH_ASSET_BY_NAME_REQUEST,
      SnapshotActions.SEARCH_ASSET_BY_NAME_SUCCESS,
      SnapshotActions.SEARCH_ASSET_BY_NAME_ERROR
    );
  }

  static dynamicSearchWarrant(name: string, date: string) {
    const url = `/api/v1/instruments/warrants?query=${name}&tradeDate=${date}`;

    return ReduxHelper.createHttpRequestActionBundle<IOptionIdentifier[]>(
      {
        url,
        method: 'GET',
      },
      SnapshotActions.SEARCH_WARRANT_BY_NAME_REQUEST,
      SnapshotActions.SEARCH_WARRANT_BY_NAME_SUCCESS,
      SnapshotActions.SEARCH_WARRANT_BY_NAME_ERROR
    );
  }

  static dynamicSearchAssetByName(name: string, type: string, tradeTime?: string) {
    const modifiable = <T>(val: T) => (predicate: () => boolean, newValue: T) => (predicate() ? newValue : val);
    let updateIf = modifiable(
      `${getApiUrl('instruments.query')}?query=${name}&${type}=true${tradeTime ? `&tradeDate=${tradeTime}` : ''}`
    );
    let url = '';

    url = updateIf(() => type === 'realAssets', `${getApiUrl('realAssets.list')}?searchTerm=${name}&status=ACTIVE`);

    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url,
        method: 'GET',
        data: {
          text: name,
        },
      },
      SnapshotActions.SEARCH_ASSET_BY_NAME_REQUEST,
      SnapshotActions.SEARCH_ASSET_BY_NAME_SUCCESS,
      SnapshotActions.SEARCH_ASSET_BY_NAME_ERROR
    );
  }

  static dynamicSearchRealAssetByName(name: string) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('realAssets.list'),
        method: 'GET',
        params: {
          searchTerm: name,
        },
      },
      SnapshotActions.SEARCH_ASSET_BY_NAME_REQUEST,
      SnapshotActions.SEARCH_ASSET_BY_NAME_SUCCESS,
      SnapshotActions.SEARCH_ASSET_BY_NAME_ERROR
    );
  }

  static resetSearchedAssets(): IActionPayload {
    return { type: SnapshotActions.SEARCH_ASSET_BY_NAME_RESET };
  }

  static dynamicSearchBondsByName(name: string, currency: string) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('instruments.query') + '/bonds?query=' + name + '&currency=' + currency,
        method: 'GET',
        data: {
          text: name,
        },
      },
      SnapshotActions.SEARCH_BONDS_BY_NAME_REQUEST,
      SnapshotActions.SEARCH_BONDS_BY_NAME_SUCCESS,
      SnapshotActions.SEARCH_BONDS_BY_NAME_ERROR
    );
  }

  static resetSearchedBonds(): IActionPayload {
    return { type: SnapshotActions.SEARCH_BONDS_BY_NAME_RESET };
  }

  static searchOptions(
    underlyingSourceId: string,
    underlyingSourceData: string,
    quoteDate: string,
    optionType: string,
    year: number,
    code: string
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('instruments.options'),
        method: 'GET',
        params: {
          underlyingSourceId,
          underlyingSourceData,
          quoteDate,
          optionType: optionType === 'P' ? 'PUT' : optionType,
          year,
          code,
        },
      },
      SnapshotActions.SEARCH_OPTIONS_REQUEST,
      SnapshotActions.SEARCH_OPTIONS_SUCCESS,
      SnapshotActions.SEARCH_OPTIONS_ERROR
    );
  }

  static dynamicSearchOptions(
    underlyingSourceId: string,
    underlyingSourceData: string,
    date: string,
    optionType: string,
    strikePrice: number,
    root: string
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('instruments.options'),
        method: 'GET',
        params: {
          underlyingSourceId,
          underlyingSourceData,
          date,
          optionType: optionType === 'P' ? 'PUT' : 'CALL',
          strikePrice,
          root,
        },
      },
      SnapshotActions.SEARCH_OPTIONS_REQUEST,
      SnapshotActions.SEARCH_OPTIONS_SUCCESS,
      SnapshotActions.SEARCH_OPTIONS_ERROR
    );
  }

  static resetSearchedOptions(): IActionPayload {
    return { type: SnapshotActions.SEARCH_OPTIONS_RESET };
  }

  static fetchFxRate(fromCurrency: string, toCurrency: string, date: string) {
    return ReduxHelper.createHttpRequestActionBundle<any>(
      {
        url: getApiUrl('snapshot.fxRate.get'),
        method: 'get',
        params: {
          from: fromCurrency,
          to: toCurrency,
          date,
        },
      },
      SnapshotActions.FX_RATE_REQUEST,
      SnapshotActions.FX_RATE_SUCCESS,
      SnapshotActions.FX_RATE_ERROR
    );
  }

  static changeFxRate(rate: number | null): IActionPayload {
    return {
      type: SnapshotActions.FX_RATE_SUCCESS,
      payload: {
        data: {
          fxRate: rate,
          firstFxRate: rate,
          secondFxRate: rate,
        },
      },
    };
  }

  static resetFxRate(): IActionPayload {
    return {
      type: SnapshotActions.FX_RATE_RESET,
    };
  }

  static fetchPrice(sourceId: string, sourceData: string, date: string) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('snapshot.price.get'),
        method: 'get',
        params: {
          sourceId,
          sourceData,
          date,
        },
      },
      SnapshotActions.PRICE_REQUEST,
      SnapshotActions.PRICE_SUCCESS,
      SnapshotActions.PRICE_ERROR
    );
  }

  static changePrice(rate: number | null): IActionPayload {
    return {
      type: SnapshotActions.PRICE_SUCCESS,
      payload: {
        data: {
          price: rate,
          firstPrice: rate,
          secondPrice: rate,
        },
      },
    };
  }

  static resetPrice(): IActionPayload {
    return {
      type: SnapshotActions.PRICE_RESET,
    };
  }

  static fetchPortfolioPreviewSnapshots(
    portfolioId: string,
    order: IOrder,
    page: number = 0,
    confirmed: boolean = true
  ) {
    const mapResponseFunc = (data: IPaginationContent<IPortfolioSnapshot>): IPaginationContent<IPortfolioSnapshot> =>
      setCalculatedToTradeDTO(data);
    return ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IPortfolioSnapshot>>(
      {
        url: getApiUrl('snapshot.preview'),
        data: {
          portfolioId,
          page,
          size: TABLE_PAGE_SIZE,
          order,
        },
      },
      SnapshotActions.SNAPSHOT_PREVIEW_REQUEST,
      SnapshotActions.SNAPSHOT_PREVIEW_SUCCESS,
      SnapshotActions.SNAPSHOT_PREVIEW_ERROR,
      mapResponseFunc
    );
  }

  static changePreviewSnapshotSort(sort: IOrder): IActionPayload<IOrder> {
    return { type: SnapshotActions.SNAPSHOT_PREVIEW_CHANGE_SORT, payload: sort };
  }

  static resetPreviewPortfolioSnapshot(): IActionPayload {
    return {
      type: SnapshotActions.SNAPSHOT_PREVIEW_RESET,
    };
  }

  static startEditUnconfirmedTrade(trade: IPortfolioTrade, errorList: IPortfolioErrorsTrade[]): IActionPayload {
    const errors: IErrors = {};
    const warnings: IErrors = {};
    const userInputs: IErrors = {};
    const rowErrors: IPortfolioErrorsTrade[] = [];

    if (errorList) {
      errorList.forEach((value) => {
        if (value.type !== 'FIELD') {
          rowErrors.push(value);
        }

        if (value.userInput) {
          userInputs[value.fieldName] =
            typeof value.userInput === 'object' ? JSON.stringify(value.userInput) : String(value.userInput);
        }

        // TODO: workround
        if (value.fieldName === 'exchangeRateValue') {
          value.fieldName = 'fxRate';
        }

        // warnings[value.fieldName] = value.message; // TODO: CHANGE TO SWITCH BELOW
        switch (value.status) {
          case 'WARN':
            warnings[value.fieldName] = value.message;
            break;
          case 'ERROR':
            errors[value.fieldName] = value.message;
            break;
        }
      });
    }

    return {
      type: SnapshotActions.START_EDIT_UNCONFIRMED_TRADE,
      payload: {
        trade,
        errors,
        warnings,
        rowErrors,
        userInputs,
      },
    };
  }

  static finishEditUnconfirmedTrade(): IActionPayload {
    return {
      type: SnapshotActions.FINISH_EDIT_UNCONFIRMED_TRADE,
    };
  }

  static updateTempTrade(portfolioId: string, trades: IPortfolioTrade[]) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('snapshot.tempTrades.update'),
        data: {
          portfolioId,
          trades: trades.map((item) => {
            let sourceId =
              item.optionTicker?.sourceId ||
              (item.instrument.value
                ? item.instrument.value.sourceId
                : {
                    sourceId: item.instrument.sourceId || item.instrument.code,
                    sourceData: item.instrument.sourceData,
                  });

            if (sourceId.sourceId.sourceId) {
              // :-(
              sourceId = sourceId.sourceId;
            }

            return {
              // TODO: to be reviewed later (item.instrument.id or item.instrument.instrumentId)
              // instrumentId: item.instrument.id || item.instrument.instrumentId,
              sourceId,
              tradeTime: item.tradeTime,
              quantity: Math.abs(item.quantity),
              price: item.price,
              commission: item.commission,
              fxRate: item.fxRate,
              notes: item.notes,
              custodian: item.custodian?.id ? item.custodian.id : item.custodian ?? null,
              currency: item.currency,
              operation: item.operation,
              tradeCosts: item.tradeCosts,
              key: item.key,
              settlementOption: item.settlementOption,
              // baseSettlementCurrency: item.baseSettlementCurrency === 'true' ? true : false,
              settlementDate: item.settlementDate,
              accruedInterestLocal:
                typeof !item.accruedInterestLocal === 'undefined' ||
                (typeof item.accruedInterestLocal === 'number' && item.accruedInterestLocal < 0)
                  ? null
                  : item.accruedInterestLocal,
              otherSourceId: item.otherSourceId,
              otherQuantity: item.otherQuantity,
            };
          }),
        },
      },
      SnapshotActions.UPDATE_SAVED_TRADES_REQUEST,
      SnapshotActions.UPDATE_SAVED_TRADES_SUCCESS,
      SnapshotActions.UPDATE_SAVED_TRADES_ERROR
    );
  }

  static amendTrade(portfolioId: string, key: string) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('snapshot.trades.amend'),
        data: {
          portfolioId,
          key,
        },
      },
      SnapshotActions.AMEND_TRADE_REQUEST,
      SnapshotActions.AMEND_TRADE_SUCCESS,
      SnapshotActions.AMEND_TRADE_ERROR
    );
  }

  static removeTrade(tradeId: number) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioTrade[]>(
      {
        url: getApiUrl('snapshot.trades.remove'),
        data: {
          tradeId,
        },
        method: 'DELETE',
      },
      SnapshotActions.DELETE_TRADE_REQUEST,
      SnapshotActions.DELETE_TRADE_SUCCESS,
      SnapshotActions.DELETE_TRADE_ERROR
    );
  }

  static updateSnapshotFilter = (key: string, value: string | undefined) => ({
    type: SnapshotActions.UPDATE_SNAPSHOT_FILTER,
    payload: { key, value },
  });

  static updateSnapshotDateFilter = (from: string, to: string) => ({
    type: SnapshotActions.UPDATE_SNAPSHOT_DATE_FILTER,
    payload: { from, to },
  });

  static resetSnapshotFilters = (keepDates = false) =>
    keepDates
      ? {
          type: SnapshotActions.ALMOST_RESET_SNAPSHOT_FILTERS,
        }
      : {
          type: SnapshotActions.RESET_SNAPSHOT_FILTERS,
        };

  static fetchAdjustmentTypes = (currencyString: string) =>
    ReduxHelper.createHttpRequestActionBundle<IAdjustmentType[]>(
      {
        url: `${getApiUrl('snapshot.adjustments.searchTypes')}?currency=${currencyString}`,
        data: {
          currency: currencyString,
        },
        method: 'GET',
      },
      SnapshotActions.FETCH_ADJUSTMENT_TYPES_REQUEST,
      SnapshotActions.FETCH_ADJUSTMENT_TYPES_SUCCESS,
      SnapshotActions.FETCH_ADJUSTMENT_TYPES_ERROR
    );
}

export default SnapshotActions;
