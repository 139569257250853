import ReactEcharts from 'echarts-for-react';
import React, { Fragment, PureComponent } from 'react';
import { ChartLegend } from '../../../../../../../../../../../../components/ChartLegend';
import {
  ILegendItemProps,
  LegendType,
} from '../../../../../../../../../../../../components/ChartLegend/components/LegendItem';

import { ICurrencyFormatter } from '../../../../../../../../../../../../services/selectors/portfolio';
import { getChartOptions } from './DividendsChartConfig';

import { IOptionType } from '../../../../../../../../../../../../components/UIWidgets/Select/Select';
import { FormatHelper } from '../../../../../../../../../../../../services/utils/FormatHelper';
import { ASSET_COLORS_ENUM } from '../../../../../../../../../../../../services/constants/assetClasses';
import { AssetClass } from '../../../../../../../../../../../../services/constants/assetClasses';

interface IProps {
  chartData: any[];
  hideLegend?: boolean | undefined;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency?: IOptionType<any>;
  assetClass: string;
}

class DividendsChart extends PureComponent<IProps> {
  render() {
    const { chartData, portfolioCurrencyFormatter, assetClass } = this.props;

    const dataForLegend: ILegendItemProps[] = [];

    let longPositionsTotal: number = 0;
    let shortPositionsTotal: number = 0;

    // @ts-ignore
    chartData[0].data.map((elem) => {
      longPositionsTotal += elem.longTotal;
      shortPositionsTotal += elem.shortTotal;
    });

    const dividendsTotal = longPositionsTotal + shortPositionsTotal;

    const selectedCurrencyName = this.props.selectedCurrency ? this.props.selectedCurrency.value : 'All';

    let longColor: string;

    switch (assetClass) {
      case 'Equities':
        longColor = '#05a8aa';
        break;
      case 'Funds':
        longColor = '#593c8f';
        break;
      case 'FixedIncome':
        longColor = '#832161';
        break;
      case 'CryptoCurrency':
        longColor = ASSET_COLORS_ENUM.CryptoCurrencies;
        break;
      default:
        longColor = '#05a8aa';
    }

    if (assetClass !== 'CryptoCurrency') {
      dataForLegend.push({
        name: 'Total',
        type: LegendType.LINE,
        hatching: false,
        color: '#c2b261',
        value:
          selectedCurrencyName === 'All'
            ? portfolioCurrencyFormatter(dividendsTotal)
            : FormatHelper.formatCurrencyShort(dividendsTotal, selectedCurrencyName, 1),
      });
    }
    dataForLegend.push({
      name: 'Long Positions',
      type: LegendType.BAR,
      hatching: false,
      color: longColor,
      value:
        selectedCurrencyName === 'All'
          ? portfolioCurrencyFormatter(longPositionsTotal)
          : FormatHelper.formatCurrencyShort(longPositionsTotal, selectedCurrencyName, 1),
    });

    if (assetClass !== 'CryptoCurrency') {
      dataForLegend.push({
        name: 'Short Positions',
        type: LegendType.BAR,
        hatching: false,
        color: '#3b4680',
        value:
          selectedCurrencyName === 'All'
            ? portfolioCurrencyFormatter(shortPositionsTotal)
            : FormatHelper.formatCurrencyShort(shortPositionsTotal, selectedCurrencyName, 1),
      });
    }

    const legend = dataForLegend;

    // Using Mock Data
    // const testChartData = dividendsData;

    return (
      <Fragment>
        {!this.props.hideLegend ? <ChartLegend legend={legend} align="left" /> : null}
        <ReactEcharts
          style={{ height: '100%', width: '100%', flexGrow: '1' }}
          option={getChartOptions(
            chartData,
            portfolioCurrencyFormatter,
            selectedCurrencyName,
            longColor,
            assetClass === 'CryptoCurrency'
          )}
          notMerge={true}
          lazyUpdate={true}
        />
      </Fragment>
    );
  }
}

export default DividendsChart;
