import { Dispatch } from 'redux';

import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';
import { PeriodHelper } from '../utils/PeriodHelper';

import { DashboardActions } from './index';

import { IActionPayload } from '../../models/redux/IActionPayload';

import { IReportInfo } from '../reducers/ReportsReducers';

class ReportsActions {
  static readonly REPORT_HISTORY_REQUEST = 'REPORT_HISTORY_REQUEST';
  static readonly REPORT_HISTORY_SUCCESS = 'REPORT_HISTORY_SUCCESS';
  static readonly REPORT_HISTORY_ERROR = 'REPORT_HISTORY_ERROR';
  static readonly REPORT_HISTORY_RESET = 'REPORT_HISTORY_RESET';
  static readonly SET_HISTORY_PERIOD = 'SET_HISTORY_PERIOD';

  static readonly REPORT_INCOME_REQUEST = 'REPORT_INCOME_REQUEST';
  static readonly REPORT_INCOME_SUCCESS = 'REPORT_INCOME_SUCCESS';
  static readonly REPORT_INCOME_ERROR = 'REPORT_INCOME_ERROR';
  static readonly REPORT_INCOME_RESET = 'REPORT_INCOME_RESET';
  static readonly SET_INCOME_PERIOD = 'SET_INCOME_PERIOD';

  static readonly REPORT_INFO_REQUEST = 'REPORTQUITY_INFO_REQUEST';
  static readonly REPORT_INFO_SUCCESS = 'REPORT_INFO_SUCCESS';
  static readonly REPORT_INFO_ERROR = 'REPORT_INFO_ERROR';
  static readonly REPORT_INFO_RESET = 'REPORT_INFO_RESET';

  static fetchHistory(portfolioId: string, period: IPeriod, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('reports.history'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          custodians: custodians || [],
        },
      },
      ReportsActions.REPORT_HISTORY_REQUEST,
      ReportsActions.REPORT_HISTORY_SUCCESS,
      ReportsActions.REPORT_HISTORY_ERROR
    );
  }

  static fetchIncomeData(portfolioId: string, period: IPeriod, custodians?: string[], selectedCurrency?: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('reports.income'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          custodians: custodians || [],
          currency: selectedCurrency === 'All' ? undefined : selectedCurrency,
        },
      },
      ReportsActions.REPORT_INCOME_REQUEST,
      ReportsActions.REPORT_INCOME_SUCCESS,
      ReportsActions.REPORT_INCOME_ERROR
    );
  }

  static fetchReportInIo(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IReportInfo>(
      {
        url: getApiUrl('reports.info'),
        data: {
          portfolioId,
        },
      },
      ReportsActions.REPORT_INFO_REQUEST,
      ReportsActions.REPORT_INFO_SUCCESS,
      ReportsActions.REPORT_INFO_ERROR
    );
  }

  static changeHistoryPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: ReportsActions.SET_HISTORY_PERIOD,
      payload: period,
    };
  }

  static changeIncomePeriod(period: IPeriod, currency?: string): IActionPayload<any> {
    return {
      type: ReportsActions.SET_INCOME_PERIOD,
      payload: { period, currency },
    };
  }

  static resetIncome(): IActionPayload {
    return {
      type: ReportsActions.REPORT_INCOME_RESET,
    };
  }

  static resetHistory(): IActionPayload {
    return {
      type: ReportsActions.REPORT_HISTORY_RESET,
    };
  }

  static resetReportInfo(): IActionPayload {
    return {
      type: ReportsActions.REPORT_INFO_RESET,
    };
  }

  static resetReportData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(DashboardActions.resetTopList());
      dispatch(ReportsActions.resetIncome());
      dispatch(ReportsActions.resetHistory());
      dispatch(ReportsActions.resetReportInfo());
    };
  }
  static resetAllData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(DashboardActions.resetTopList());
      dispatch(ReportsActions.resetIncome());
      dispatch(ReportsActions.resetHistory());
      dispatch(ReportsActions.resetReportInfo());
    };
  }
}

export default ReportsActions;
