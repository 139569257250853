export const formatFxConvention = (fxRate: IFxRate | null) => {
  if (!fxRate) {
    return {
      rate: null,
      fromToFxRate: null,
      convention: ' (N/A)',
      fromCurrency: null,
      toCurrency: null,
    };
  }

  if (fxRate.fxRate === 1) {
    return {
      rate: 1,
      fromToFxRate: 1,
      convention: '',
      fromCurrency: null,
      toCurrency: null,
    };
  }
  const fxCurrencies = [];
  fxCurrencies[fxRate.fromCurrency.hierarchy] = fxRate.fromCurrency.name;
  fxCurrencies[fxRate.toCurrency.hierarchy] = fxRate.toCurrency.name;

  return {
    rate: fxRate.firstFxRate,
    fromToFxRate: fxRate.fromToFxRate,
    convention:
      fxRate.fromCurrency.name === fxRate.toCurrency.name ? '' : ` (${fxCurrencies.filter(Boolean).join('-')})`,
    fromCurrency: fxRate.fromCurrency,
    toCurrency: fxRate.toCurrency,
  };
};
