import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';

import Credit from './Credit';
import { AssetReportWrapper } from '../../components/AssetReportWrapper';
import { withAssetLogic } from '../../HOC';

import { CreditActions } from '../../../../../../../../services/actions';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';

import { IRootState } from '../../../../../../../../services/store';

import { IAssetReportProps } from '../../HOC/withAssetLogic';
import { LineData } from '../../../../../../../../models/LineData';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';

interface IMapStateToProps {
  coupons: IBarData[];
  dashboardInfo: IDashboardAssetClass;
  repayments: IBarData | null;
  maturityPeriod: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  fetchCoupons: (portfolioId: string) => AxiosPromise<IBarData[]>;
  fetchMaturityData: (portfolioId: string, period: IPeriod) => AxiosPromise<{ data: LineData }>;
  fetchRepaymentsData: (portfolioId: string) => AxiosPromise<IBarData[]>;
  resetCreditData: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps & IAssetReportProps;

interface IState {
  allLoaded: boolean;
}

class CreditContainer extends PureComponent<IProps, IState> {
  readonly state: IState = {
    allLoaded: false,
  };

  componentDidMount(): void {
    this._fetchData().then(() => {
      this.setState({ allLoaded: true });
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { portfolioId, maturityPeriod, fetchMaturityData } = this.props;

    if (prevProps.maturityPeriod !== maturityPeriod) {
      fetchMaturityData(portfolioId, maturityPeriod);
    }
  }

  componentWillUnmount(): void {
    this.props.resetCreditData();
  }

  render() {
    const { allLoaded } = this.state;
    const { coupons, repayments, dashboardInfo, assetDataLoaded, noData, portfolioCurrencyFormatter } = this.props;

    return (
      <AssetReportWrapper title="Credit" allLoaded={assetDataLoaded && allLoaded} noData={noData}>
        <Credit
          coupons={coupons}
          repayments={repayments}
          dashboardInfo={dashboardInfo}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        />
      </AssetReportWrapper>
    );
  }

  private _fetchData(): Promise<any> {
    const { portfolioId, maturityPeriod, fetchCoupons, fetchMaturityData, fetchRepaymentsData } = this.props;

    return Promise.all([
      fetchCoupons(portfolioId),
      fetchMaturityData(portfolioId, maturityPeriod),
      fetchRepaymentsData(portfolioId),
    ]);
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    coupons: state.dashboard.credit.coupons.data,
    repayments: state.dashboard.credit.repayments.data,
    dashboardInfo: state.dashboard.totalValues.info.data,
    maturityPeriod: state.dashboard.credit.maturity.period,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchCoupons: (portfolioId: string) => dispatch(CreditActions.fetchCoupons(portfolioId)),
  fetchMaturityData: (portfolioId: string, period: IPeriod) =>
    dispatch(CreditActions.fetchMaturityData(portfolioId, period)),
  fetchRepaymentsData: (portfolioId: string) => dispatch(CreditActions.fetchRepaymentsData(portfolioId)),
  resetCreditData: () => dispatch(CreditActions.resetCreditData()),
});

export default withAssetLogic(connect(mapStateToProps, mapDispatchToProps)(CreditContainer));
