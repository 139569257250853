import axios, { AxiosPromise } from 'axios';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IPortfoliosFilters } from '../../models/IPortfoliosFilters';
import { PortfolioActions } from '../../services/actions';
import { AppActions } from '../../services/actions/AppActions';
import { FILTER_VIEW, PortfolioType, SORT_VIEW, UserRole } from '../../services/constants/constants';
import { IRootState } from '../../services/store';
import { AsyncActionDispatch } from '../../services/utils/ReduxHelper';
import { Utils } from '../../services/utils/Utils';
import PortfoliosManager from './PortfoliosManager';
import { NotificationType } from '../../models/NotifictionType';

interface IMapStateToProps {
  isFetched: boolean;
  isFetching?: boolean;
  portfoliosList: IPortfolio[];
  portfoliosFilters: IPortfoliosFilters;
  authInfo: IAuthInfo | null;
  userCapabilities: IRootState['userCapabilities']['data'];
}

interface IDispatchToProps {
  changePortfoliosFilters: (fitlers: IPortfoliosFilters) => void;
  fetchPortfoliosList: (type: PortfolioType) => AxiosPromise<IPortfolio[]>;
  updatePortfolio: (portfolio: IPortfolio) => AxiosPromise<IPortfolio>;
  deletePortfolio: (portfolio: IPortfolio) => AxiosPromise<any>;
  resetPortfoliosList: () => void;
  showNotification: (notification: INotification) => void;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps<{ workgroupId: string }>;

interface IState {
  sortBy: SORT_VIEW;
  filterBy: FILTER_VIEW;
}

class PortfoliosManagerContainer extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      sortBy: SORT_VIEW.NAME,
      filterBy: FILTER_VIEW.ALL,
    };
  }

  componentDidMount(): void {
    if (this.props.authInfo && this.props.authInfo.role) {
      // this._fetchData();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const {
      portfoliosFilters: { type },
    } = this.props;

    if (prevProps.portfoliosFilters.type !== type || prevProps.location !== this.props.location) {
      // this._fetchData();
    }

    if (
      this.props.authInfo &&
      this.props.authInfo.role &&
      (!prevProps.authInfo || (prevProps.authInfo && this.props.authInfo.role !== prevProps.authInfo.role))
    ) {
      // this._fetchData();
    }
  }

  handleFiltersChanged = (filters: IPortfoliosFilters) => {
    this.props.changePortfoliosFilters(filters);
  };

  handleUpdatePortfolio = (portfolio: IPortfolio) => {
    this.props.updatePortfolio(portfolio).then(() => {
      this._fetchData();
    });
  };

  handleSortByChange = (sortBy: SORT_VIEW) => this.setState({ sortBy });
  handleFilterByChange = (filterBy: FILTER_VIEW) => this.setState({ filterBy });

  render() {
    const { isFetched, isFetching, portfoliosList, portfoliosFilters, authInfo, match } = this.props;
    const { sortBy, filterBy } = this.state;
    // const sortDirection = sortBy === SORT_VIEW.NAME ? -1 : 1;
    // const portfoliosByWorkgroupId = portfoliosList.filter((p) => p.workgroup.id.toString() === match.params.workgroupId);
    return (
      <PortfoliosManager
        showNotification={this.props.showNotification}
        authInfo={authInfo}
        workgroupId={match.params.workgroupId}
        portfoliosFilters={portfoliosFilters}
        onFiltersChanged={this.handleFiltersChanged}
        onPortfolioEdit={this.handleUpdatePortfolio}
        onSortBy={this.handleSortByChange}
        onFilterBy={this.handleFilterByChange}
        sortBy={sortBy}
        filterBy={filterBy}
        userCapabilities={this.props.userCapabilities}
      />
    );
  }

  private _fetchData() {
    const userType =
      this.props.authInfo &&
      (this.props.authInfo.role === UserRole.ADMIN || this.props.authInfo.role === UserRole.MANAGER)
        ? 'CLIENT'
        : 'PERSONAL';

    this.props.fetchPortfoliosList(userType as PortfolioType);
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    isFetched: state.portfoliosList.isFetched,
    isFetching: state.portfoliosList.isFetching,
    portfoliosList: state.portfoliosList.data,
    portfoliosFilters: state.portfoliosList.filters,
    authInfo: state.app.authInfo.data,
    userCapabilities: state.userCapabilities.data,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPortfoliosList: (type: PortfolioType) => {
    axios.defaults.headers['illio-selected-workgroup'] = '';
    return dispatch(PortfolioActions.fetchPortfoliosList(type));
  },
  changePortfoliosFilters: (filters: IPortfoliosFilters) =>
    dispatch(PortfolioActions.changePortfoliosListFilters(filters)),
  resetPortfoliosList: () => dispatch(PortfolioActions.resetPortfoliosList()),
  deletePortfolio: (portfolio: IPortfolio) => dispatch(PortfolioActions.deletePortfolio(portfolio)),
  updatePortfolio: (portfolio: IPortfolio) => dispatch(PortfolioActions.updatePortfolio(portfolio)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfoliosManagerContainer));
