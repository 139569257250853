import cn from 'classnames';
import React, { FunctionComponent, PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import CloseButton from '../../components/CloseButton/CloseButton';
import s from './PriceEntry.module.css';
import { SYSTEM_EPOCH } from '../../../../services/constants/constants';
import { Input } from '../../../../components';

interface IProps {
  prices: IPrice[];
  showWeekends: boolean;
  dateLabel?: string;
  priceLabel?: string;

  onAddClick(): void;
  updatePrice(code: string, closePrice: number): void;
  updateDate(code: string, priceDate: Date): void;
  deletePrice(code: string): void;
  sortPrices(): void;
  setShowWeekends(val: boolean): void;
}

interface IDateInputProps {
  value?: string;
  onClick?: () => void;
}

// class DateInput extends PureComponent<IDateInputProps> {
//   render() {
//     const { value, onClick } = this.props;
//     return <input className={cn('input')} value={value} onClick={onClick} onChange={() => {}} />;
//   }
// }

const PriceEntry: FunctionComponent<IProps> = ({
  onAddClick,
  sortPrices,
  deletePrice,
  updateDate,
  updatePrice,
  prices,
  showWeekends,
  dateLabel,
  priceLabel,
  // setShowWeekends,
}) => {
  return (
    <React.Fragment>
      {/*<div className={cn(s.checkboxSurround)}>*/}
      {/*  <span>Enable weekends:</span>*/}
      {/*  <input*/}
      {/*    type={'checkbox'}*/}
      {/*    className={cn(s.checkbox)}*/}
      {/*    name={'showWeekendsCheck'}*/}
      {/*    checked={showWeekends}*/}
      {/*    onChange={(e) => setShowWeekends(e.target.checked)}*/}
      {/*  />*/}
      {/*</div>*/}
      {Array.isArray(prices) && prices.length > 0 && (
        <table style={{ borderSpacing: '0.4rem' }}>
          <thead>
            <tr>
              <th>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Date</span>
                  <button className={cn(s.button, s.narrow)} onClick={sortPrices}>
                    Sort
                  </button>
                </div>
              </th>
              <th>{priceLabel}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {prices.map((price) => (
              <PriceRow
                key={price.code}
                price={price}
                onChangePrice={(value) => updatePrice(price.code, value)}
                onChangeDate={(date) => updateDate(price.code, date)}
                onDelete={() => deletePrice(price.code)}
                showWeekends={showWeekends}
                lastOne={prices.length === 1}
                dateLabel={dateLabel}
                priceLabel={priceLabel}
              />
            ))}
          </tbody>
        </table>
      )}
      <button className={cn(s.button)} onClick={onAddClick}>
        Add Row
      </button>
    </React.Fragment>
  );
};

interface IPriceRowProps {
  price: IPrice;
  onChangePrice: (value: number) => void;
  onChangeDate: (date: Date) => void;
  onDelete: () => void;
  showWeekends: boolean;
  lastOne?: boolean;
  dateLabel?: string;
  priceLabel?: string;
}

const isWeekday = (date: Date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const PriceRow = ({
  price,
  onDelete,
  onChangePrice,
  onChangeDate,
  showWeekends,
  lastOne = false,
  dateLabel = 'Price Date',
  priceLabel = 'Close Price',
}: IPriceRowProps) => {
  return (
    <tr className={cn(s.priceRowSurround)}>
      <td>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          placeholderText={dateLabel}
          disabled={false}
          customInput={<Input />}
          autoComplete="off"
          filterDate={showWeekends ? undefined : isWeekday}
          calendarClassName="wm-datepicker"
          selected={price.valuationDate ? new Date(price.valuationDate) : undefined}
          onSelect={onChangeDate}
          disabledKeyboardNavigation={false}
          minDate={SYSTEM_EPOCH}
          maxDate={new Date()}
          showMonthYearDropdown={true}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '0, 10px',
            },
          }}
          onChange={(val) => (val ? onChangeDate(val) : undefined)}
        />
      </td>
      <td>
        <input
          data-testid={price.code}
          type={'text'}
          className={cn('input')}
          placeholder={priceLabel}
          value={price.price || ''}
          pattern={'0-3'}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            const reg = /^[0-9]*.?[0-9]*$/;
            if ((reg.test(value) || value === '') && value.substr(0, 2) !== '00') {
              onChangePrice((value as unknown) as number);
            }
          }}
        />
      </td>
      {lastOne ? null : (
        <td onClick={onDelete} className={cn(s.closeButtonSurround)}>
          <CloseButton className={cn(s.closeButton)} />
        </td>
      )}
    </tr>
  );
};

export default PriceEntry;
