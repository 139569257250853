import s from '../VolatilityContributionChart.module.css';
import cn from 'classnames';
import React, { Fragment } from 'react';

interface ITagBands {
  portfolioBand: number;
  portfolioPercentage: number;
  benchmarkBand: number;
  benchmarkPercentage: number;
  numberOfBands: number;
}

export function PortfolioVsBenchmarkBands(props: ITagBands) {
  const portfolioBand = props.portfolioBand;
  const portfolioPercentage = props.portfolioPercentage >= 100 ? 99 : props.portfolioPercentage;
  const benchmarkBand = props.benchmarkBand;
  const benchmarkPercentage = props.benchmarkPercentage >= 100 ? 99 : props.benchmarkPercentage;
  const numberOfBands = props.numberOfBands;

  const esmaColors = ['#138eb0', '#41d9a0', '#00b26f', '#d9bf41', '#fb9900', '#e17300', '#e13800', '#a50009'];

  const pfArrowEndPosition = (100 / numberOfBands) * (portfolioBand - 1) + (1 / numberOfBands) * portfolioPercentage;
  const bmArrowEndPosition = (100 / numberOfBands) * (benchmarkBand - 1) + (1 / numberOfBands) * benchmarkPercentage;

  const ArrowsStartPositions: number[] = [];
  for (let i = 1; i <= numberOfBands; i++) {
    ArrowsStartPositions.push((100 / numberOfBands) * i - 100 / (numberOfBands * 2));
  }

  let pfTagSlot = portfolioBand - 1;
  let bmTagSlot = benchmarkBand - 1;

  // Treating the case when Portfolio and Benchmark falls into the same Slot
  if (pfTagSlot === bmTagSlot) {
    if (portfolioPercentage < benchmarkPercentage) {
      if (bmTagSlot === numberOfBands - 1) {
        pfTagSlot--;
      } else {
        bmTagSlot++;
      }
    } else {
      if (bmTagSlot === numberOfBands - 1) {
        bmTagSlot--;
      } else {
        pfTagSlot++;
      }
    }
  }

  const pfArrowStartPosition = ArrowsStartPositions[pfTagSlot];
  const bmArrowStartPosition = ArrowsStartPositions[bmTagSlot];

  const arrowheads = (
    <defs>
      <marker id={'arrowheadBM'} markerWidth="10" markerHeight="8" refX="0" refY="4" orient="auto">
        <polygon points="0 0, 5 4, 0 8" fill={esmaColors[0]} />
      </marker>
      <marker id={'arrowheadPF'} markerWidth="10" markerHeight="8" refX="0" refY="4" orient="auto">
        <polygon points="0 0, 5 4, 0 8" fill={esmaColors[portfolioBand]} />
      </marker>
    </defs>
  );

  const lines = (
    <g>
      <line
        x1={bmArrowStartPosition + '%'}
        y1="100%"
        x2={bmArrowStartPosition + '%'}
        y2="75%"
        stroke={esmaColors[0]}
        strokeWidth="2"
      />
      <line
        x1={bmArrowStartPosition + '%'}
        y1="75%"
        x2={bmArrowEndPosition + '%'}
        y2="50%"
        stroke={esmaColors[0]}
        strokeWidth="2"
      />
      <line
        x1={bmArrowEndPosition + '%'}
        y1="50%"
        x2={bmArrowEndPosition + '%'}
        y2="25%"
        stroke={esmaColors[0]}
        strokeWidth="2"
        markerEnd="url(#arrowheadBM)"
      />

      <line
        x1={pfArrowStartPosition + '%'}
        y1="100%"
        x2={pfArrowStartPosition + '%'}
        y2="75%"
        stroke={esmaColors[portfolioBand]}
        strokeWidth="2"
      />
      <line
        x1={pfArrowStartPosition + '%'}
        y1="75%"
        x2={pfArrowEndPosition + '%'}
        y2="50%"
        stroke={esmaColors[portfolioBand]}
        strokeWidth="2"
      />
      <line
        x1={pfArrowEndPosition + '%'}
        y1="50%"
        x2={pfArrowEndPosition + '%'}
        y2="25%"
        stroke={esmaColors[portfolioBand]}
        strokeWidth="2"
        markerEnd="url(#arrowheadPF)"
      />
    </g>
  );
  const tags = [];
  for (let i = 0; i < 7; i++) {
    if (i === pfTagSlot) {
      tags.push(
        <div key={i} className={s.BenchmarkVsPortfolioSegment}>
          <div className={cn(s.BenchmarkVsPortfolioTag)} style={{ backgroundColor: esmaColors[portfolioBand] }}>
            {portfolioBand}
          </div>
          Portfolio Volatility
        </div>
      );
    } else if (i === bmTagSlot) {
      tags.push(
        <div key={i} className={s.BenchmarkVsPortfolioSegment}>
          <div className={cn(s.BenchmarkVsPortfolioTag, s.BenchmarkTag)}>{benchmarkBand}</div>
          Benchmark Volatility
        </div>
      );
    } else {
      tags.push(<div key={i} className={s.BenchmarkVsPortfolioSegment} />);
    }
  }
  return (
    <div className={s.BenchmarkVsPortfolioCanvas}>
      <svg width="100%" height="100%">
        {arrowheads}
        {lines}
      </svg>
      <div className={s.BenchmarkVsPortfolioSlots}>{tags}</div>
    </div>
  );
}
