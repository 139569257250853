import { ITableColumn } from '../../../../../../../../components/UIWidgets/TableWrapper/models';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';

export function getTopPositionsByPnLColumns(portfolioCurrencyFormatter: ICurrencyFormatter): ITableColumn[] {
  return [
    {
      label: 'Instrument Name',
      dataKey: 'instrumentName',
      flexGrow: 2,
      display: true,
      width: 400,
      disableSort: true,
    },
    {
      label: 'Asset Class',
      dataKey: 'assetClassId',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: true,
    },
    {
      label: `Current Value (${portfolioCurrencyFormatter()})`,
      dataKey: 'pnlMarketBase',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: true,
    },
    {
      label: `P&L (${portfolioCurrencyFormatter()})`,
      dataKey: 'pnlBase',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: false,
    },
    {
      label: '% of Portfolio P&L',
      dataKey: 'portfolioPercentage',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: true,
    },
  ];
}

export function getTopPositionsColumns(portfolioCurrencyFormatter: ICurrencyFormatter): ITableColumn[] {
  return [
    {
      label: 'Instrument',
      dataKey: 'name',
      flexGrow: 2,
      display: true,
      width: 400,
      disableSort: false,
    },
    {
      label: 'Custodian',
      dataKey: 'custodianName',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: false,
    },
    {
      label: 'Local Amount',
      dataKey: 'marketValueLocal',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: true,
    },
    {
      label: `Value (${portfolioCurrencyFormatter()})`,
      dataKey: 'marketValueBase',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: false,
    },
    {
      label: '% of Portfolio',
      dataKey: 'portfolioPercentage',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: true,
    },
  ];
}
