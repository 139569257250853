import React from 'react';
import s from './CryptoWallets.module.scss';
import { IoIosWallet, AiFillUsb } from 'react-icons/all';
import EarnWallet from '../../../../../../../../../static/images/icons/earn.svg';
import info from '../../../../../../../../../static/images/ic-info.svg';
import { usePortfolioInfo } from '../../../../../../../../../services/hooks/usePortfolioInfo';

const CryptoWallet = ({
  title,
  quantity,
  value,
  icon,
}: {
  title: string;
  quantity: number;
  value: number;
  icon: any;
}) => {
  const { portfolioCurrencyFormatter } = usePortfolioInfo();

  return (
    <div>
      <div>
        {icon}
        <span className={s.title}>{title}</span>
      </div>
      <div>
        <span>Quantity: {quantity}</span>
      </div>
      <div>
        <span>Value: {portfolioCurrencyFormatter(value)}</span>
      </div>
    </div>
  );
};

export const CryptoWallets = ({ position, portfolioId }: IPositionDetailsTab) => {
  return (
    <div>
      <CryptoWallet
        icon={<IoIosWallet />}
        title={'Main Wallet'}
        quantity={position.quantityAllocation.mainWallet}
        value={position.quantityAllocation.mainWallet * (position.currentPriceNative || 1)}
      />
      <div style={{ height: 20 }} />
      <CryptoWallet
        icon={<img style={{ height: 14, width: 14, marginTop: -5 }} src={EarnWallet} />}
        title={'Earn Wallet'}
        quantity={position.quantityAllocation.blockedWallet}
        value={position.quantityAllocation.blockedWallet * (position.currentPriceNative || 1)}
      />
      <div style={{ height: 20 }} />
      <CryptoWallet
        icon={<AiFillUsb />}
        title={'Cold Wallet'}
        quantity={position.quantityAllocation.coldWallet}
        value={position.quantityAllocation.coldWallet * (position.currentPriceNative || 1)}
      />
    </div>
  );
};
