import React, { FunctionComponent, useContext, useEffect } from 'react';

interface IModalContext {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSearch: (search: string) => void;
  search: string;
}

const noop = (functionName: string) => () => console.log(`${functionName} not initialised`);

const ModalContext = React.createContext<IModalContext>({
  open: false,
  setOpen: noop('setOpen'),
  setSearch: noop('setSearch'),
  search: '',
});

export const ModalContextProvider: FunctionComponent = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');

  useEffect(() => {
    if (search.length > 0) {
      console.log('OPEN', search);
      setOpen(true);
    }
  }, [search]);

  return <ModalContext.Provider value={{ open, setOpen, setSearch, search }}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);
