import React, { FC } from 'react';
import { IPortfolioTrade, TradeType } from '../../../../../../../models/IPortfolioTrade';
import dateFormat from 'dateformat';
import { IOption } from '../../../../../../../components/UIWidgets/Autocomplete';
import { AxiosPromise, AxiosResponse } from 'axios';
import { AsyncActionDispatch } from '../../../../../../../services/utils/ReduxHelper';
import { SnapshotActions } from '../../../../../../../services/actions';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../../../services/store';
import { getCustodianOptions } from '../../../../../../../services/selectors/snapshots';
import { initialize } from 'redux-form';
import {
  getPortfolioCurrencyCode,
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../../../../services/selectors/portfolio';
import { Utils } from '../../../../../../../services/utils/Utils';
import { NotificationType } from '../../../../../../../models/NotifictionType';
import { AppActions } from '../../../../../../../services/actions/AppActions';
import { IAssetEntryComponentProps } from './types/IAssetEntryComponentProps';
import { FORMS_NAME } from '../../../../../../../services/constants/forms';
import { PERSONAL_USE_CUSTODIAN } from '../../../../../../../services/constants/constants';
import { addDays } from '../../../../../../../services/utils/DateHelpers';

type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

interface IMapStateToProps {
  savedTradesOrder: any;
  portfolioInfo: IPortfolio | null;
  currencies: IRootState['currency']['data'];
  baseCurrencyCode: string | null;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  editedUnconfirmedTrade: UnconfirmedTrade;
}

interface IDispatchToProps {
  saveTrades(portfolioId: string, trades: IPortfolioTrade[]): Promise<AxiosResponse<IPortfolioTrade[]>>;
  fetchTradeBlotter(portfolioId: string, order: IOrder, page?: number): any;
  removeTrades(portfolioId: string, trades: IPortfolioTrade[]): AxiosPromise<void>;
  resetEditTrade(): void;
  resetForm(formName: string): (data: any) => void;
  updateTempTrade(portfolioId: string, trades: IPortfolioTrade[]): Promise<AxiosResponse<IPortfolioTrade[]>>;
  showNotification(notification: INotification): void;
}

interface IOwnProps {
  formName: string;
  ChildComponent:
    | React.FunctionComponent<IAssetEntryComponentProps<any>>
    | React.ComponentClass<IAssetEntryComponentProps<any>>;
  defaultValues?: any;
  editMode?: boolean;
  checkedOut?: boolean;
}

type Props = IMapStateToProps & IDispatchToProps & IOwnProps;

const AssetEntryContainer: FC<Props> = ({
  saveTrades,
  savedTradesOrder,
  fetchTradeBlotter,
  portfolioInfo,
  removeTrades,
  currencies,
  baseCurrencyCode,
  portfolioCurrencyFormatter,
  resetEditTrade,
  formName,
  resetForm,
  editedUnconfirmedTrade,
  updateTempTrade,
  ChildComponent,
  showNotification,
  defaultValues,
  editMode,
}) => {
  const allDefaults = React.useCallback<() => Partial<any>>(
    () => ({
      operation: TradeType.BUY.toUpperCase(),
      feeOperation: 'DEDUCTION',
      settlementOption: 'BASE',
      baseSettlementCurrency: 'BASE',
      adjustmentOperation: 'deduction',
      sellCurrency: {
        id: portfolioInfo?.currency.id,
        label: baseCurrencyCode,
        name: `${baseCurrencyCode} (${portfolioInfo?.currency.symbol ?? ''})`,
        value: baseCurrencyCode,
      },
      tradeTime: dateFormat(new Date(), 'yyyy-mm-dd'),
      // quantity: 0,
      type: 'CALL' as 'CALL',
      currency: portfolioInfo
        ? {
            id: portfolioInfo.currency.id,
            name: portfolioInfo.currency.name,
            value: portfolioInfo.currency.name,
          }
        : undefined,
      ...defaultValues,
    }),
    [formName, defaultValues]
  )();
  const resetChildForm = resetForm(formName);

  React.useEffect(() => {
    return () => {
      resetChildForm({ ...allDefaults });
    };
  }, [resetChildForm, allDefaults, formName]);

  const removeTrade = (trade: IPortfolioTrade) => {
    Utils.createConfirm({
      text: 'Do you really want to remove this item? This action cannot be undone.',
      confirmBtnText: 'Remove',
      onConfirm: () => {
        removeTrades(portfolioInfo!.id, [trade])
          .then(() => {
            resetEditTrade();
            fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
          })
          .catch(() => {
            showNotification({ text: "Trade hasn't been removed", type: NotificationType.ERROR });
          });
      },
    });
  };

  return (
    <ChildComponent
      // onSubmit={sub}
      initialValues={allDefaults}
      currencies={currencies}
      portfolioInfo={portfolioInfo}
      baseCurrencyCode={baseCurrencyCode}
      portfolioCurrencyFormatter={portfolioCurrencyFormatter}
      resetEditTrade={resetEditTrade}
      removeTrade={removeTrade}
      resetForm={resetChildForm}
      editedUnconfirmedTrade={editedUnconfirmedTrade}
      updateTempTrade={updateTempTrade}
      saveTrades={saveTrades}
      fetchTradeBlotter={fetchTradeBlotter}
      savedTradesOrder={savedTradesOrder}
      editMode={editMode}
    />
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    savedTradesOrder: state.snapshot.snapshotEdit.savedTrades.order,
    currencies: state.currency.data,
    portfolioInfo: state.portfolio.portfolioInfo.data,
    baseCurrencyCode: getPortfolioCurrencyCode(state),
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
    editedUnconfirmedTrade: state.snapshot.snapshotEdit.editedUnconfirmedTrade,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  saveTrades: (portfolioId: string, trades: IPortfolioTrade[]) =>
    dispatch(SnapshotActions.saveTrades(portfolioId, trades)),
  fetchTradeBlotter: (portfolioId: string, order: IOrder, page?: number) =>
    dispatch(SnapshotActions.fetchPortfolioSavedTrades(portfolioId, order, page)),
  resetEditTrade: () => dispatch(SnapshotActions.finishEditUnconfirmedTrade()),
  removeTrades: (portfolioId: string, trades: IPortfolioTrade[]) =>
    dispatch(SnapshotActions.removeTrades(portfolioId, trades)),
  resetForm: (formName: string) => (data: any) => dispatch(initialize(formName, data)),
  updateTempTrade: (portfolioId: string, trades: IPortfolioTrade[]) =>
    dispatch(SnapshotActions.updateTempTrade(portfolioId, trades)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetEntryContainer);
