import { AxiosPromise, AxiosResponse } from 'axios';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, generatePath } from 'react-router';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import get from 'lodash.get';

import InstrumentDetails from './InstrumentDetails';

import { FORMS_NAME } from '../../../../services/constants/forms';

import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../services/store';
import { InstrumentsActions } from '../../../../services/actions';

import { PATHS } from '../../../../router/paths';

import { AppActions } from '../../../../services/actions/AppActions';
import { getCurrencyOptions } from '../../../../services/selectors/currency';

import { IOption } from '../../../../components/UIWidgets/Autocomplete';
import { IFormValues } from './components/InstrumentForm/InstrumentForm';
import { getAssetClasses, getNestedSetFromAssetClasses } from '../../../../services/selectors/asset';

import { getIndustryOptions } from '../../../../services/selectors/industry';

import { getCountryOptions } from '../../../../services/selectors/country';

import { getUserOptions } from '../../../../services/selectors/instrument';

import { NestedSet } from '../../../../components/NestedList/NestedSet';
import firstOrDefault from '../../../../services/utils/firstOrDefault';
import { UserRole } from '../../../../services/constants/constants';
import { NotificationType } from '../../../../models/NotifictionType';

interface IMapStateToProps {
  instrumentInfo: IInstrument | null;
  instrumentFormData: IFormValues;
  isInstrumentFormValid: boolean;
  currencyOptions: Array<IOption<ICurrency>>;
  industryOptions: Array<IOption<IIndustry>>;
  countryOptions: Array<IOption<ICountry>>;
  userListOptions: Array<IOption<IUser>>;
  authInfo: IAuthInfo | null;
  nestedSetFromAssetClasses: NestedSet<any>;
  assetClasses: IAssetClass[];
}

interface IDispatchToProps {
  fetchInstrumentInfo: (id: string) => AxiosPromise<IInstrument>;
  createInstrument: (instrument: IInstrument) => AxiosPromise<IInstrument>;
  updateInstrument: (instrument: IInstrument) => AxiosPromise<IInstrument>;
  showNotification: (notification: INotification) => void;
  resetInstrumentInfo: () => void;
  fetchUsers: (search: string) => AxiosPromise<IUser[]>;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps<{ instrumentId?: string }>;

class InstrumentDetailsContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    const { match, authInfo, fetchInstrumentInfo, fetchUsers } = this.props;
    const instrumentId: string | undefined = match.params.instrumentId;

    if (authInfo && (authInfo.role === UserRole.ADMIN || authInfo.role === UserRole.MANAGER)) {
      fetchUsers('');
    }

    if (!instrumentId) {
      return;
    }

    fetchInstrumentInfo(instrumentId);
  }

  componentWillUnmount(): void {
    this.props.resetInstrumentInfo();
  }

  handleOnSave = () => {
    const {
      history,
      instrumentFormData,
      createInstrument,
      updateInstrument,
      showNotification,
      assetClasses,
    } = this.props;

    const entity: Partial<IInstrument> = {
      name: instrumentFormData.name,
      code: instrumentFormData.code,
      description: instrumentFormData.note,
      nonMarket: true,
    };

    if (instrumentFormData.user) {
      entity.userId = instrumentFormData.user.value.id;
    }

    if (instrumentFormData.industry) {
      entity.industrySector = instrumentFormData.industry.value;
    }

    if (!instrumentFormData.currency) {
      console.error('field currency is not defined');
      return;
    } else {
      entity.currency = instrumentFormData.currency.value;
    }

    if (!instrumentFormData.assetSubClass) {
      console.error('field assetSubClass is not defined');
      return;
    } else {
      const assetSubClass = instrumentFormData.assetSubClass.data;
      if (!assetSubClass) {
        console.error('field assetSubClass is not defined');
        return;
      }
      entity.assetSubClass = assetSubClass;
      const assetClass = firstOrDefault<IAssetClass>(
        assetClasses,
        (item) => item.id === (entity.assetSubClass as IAssetSubClass).assetClassId,
        undefined
      );
      if (!assetClass) {
        console.error('field assetClass is not defined');
        return;
      }
      entity.assetClass = assetClass;
    }

    if (!instrumentFormData.country) {
      console.error('field country is not defined');
      return;
    } else {
      entity.countryCode = instrumentFormData.country.value.code;
    }
    if (instrumentFormData.id) {
      entity.id = instrumentFormData.id;
      updateInstrument(entity as IInstrument)
        .then(() => {
          showNotification({
            text: 'Instrument has been updated',
            type: NotificationType.SUCCESS,
          });
        })
        .catch(() => {
          showNotification({
            text: `Instrument ${entity.name} hasn\'t been updated`,
            type: NotificationType.ERROR,
          });
        });
    } else {
      createInstrument(entity as IInstrument)
        .then((response: AxiosResponse<IInstrument>) => {
          history.push(generatePath(PATHS.instruments.path));
        })
        .catch((error) => {
          const errorMsg = get(error, 'response.data.error.data', "Instrument hasn't been created");

          showNotification({
            text: errorMsg,
            type: NotificationType.ERROR,
          });
        });
    }
  };

  render() {
    const {
      instrumentInfo,
      isInstrumentFormValid,
      currencyOptions,
      industryOptions,
      countryOptions,
      userListOptions,
      authInfo,
      fetchUsers,
      nestedSetFromAssetClasses,
    } = this.props;
    return (
      <InstrumentDetails
        instrumentInfo={instrumentInfo}
        isFormValid={isInstrumentFormValid}
        currencyOptions={currencyOptions}
        userListOptions={userListOptions}
        industryOptions={industryOptions}
        onSave={this.handleOnSave}
        countryOptions={countryOptions}
        authInfo={authInfo}
        searchUser={(search: string) => fetchUsers(search)}
        nestedSetFromAssetClasses={nestedSetFromAssetClasses}
      />
    );
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  const instrumentFormData: IFormValues = getFormValues(FORMS_NAME.instrument)(state) as IFormValues;
  return {
    instrumentFormData,
    isInstrumentFormValid: !Object.keys(getFormSyncErrors(FORMS_NAME.instrument)(state)).length,
    instrumentInfo: state.instruments.instrumentInfo.info.data,
    authInfo: state.app.authInfo.data,
    currencyOptions: getCurrencyOptions(state),
    industryOptions: getIndustryOptions(state),
    countryOptions: getCountryOptions(state),
    userListOptions: getUserOptions(state),
    nestedSetFromAssetClasses: getNestedSetFromAssetClasses(state),
    assetClasses: getAssetClasses(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchInstrumentInfo: (id: string) => dispatch(InstrumentsActions.fetchInstrumentById(id)),
  createInstrument: (instrument: IInstrument) => dispatch(InstrumentsActions.createInstrument(instrument)),
  updateInstrument: (instrument: IInstrument) => dispatch(InstrumentsActions.updateInstrument(instrument)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  resetInstrumentInfo: () => dispatch(InstrumentsActions.resetInstrumentInfo()),
  fetchUsers: (search: string) => dispatch(InstrumentsActions.fetchUsers(search)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstrumentDetailsContainer));
