import React, { FC, useState } from 'react';
import s from '../../Transaction.module.scss';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { connect, useDispatch } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import Amount from '../../components/ConnectedTransactionInputs/Amount';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import { renderToggleBtns } from '../../components';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import AssetSearch from '../../components/ConnectedTransactionInputs/AssetSearch';
import { getAssetOptions } from '../../../../../../../../services/selectors/snapshots';
import Price from '../../components/ConnectedTransactionInputs/Price';
import TradeCost from '../../components/ConnectedTransactionInputs/TradeCost';
import Commission from '../../components/ConnectedTransactionInputs/Commission';
import cn from 'classnames';
import SettlementOptions from '../../components/ConnectedTransactionInputs/SettlementOptions';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: ISimpleInstrumentFormValues;
  snapshotFxRate: IRootState['snapshot']['snapshotEdit']['fxRate']['data'];
  instrumentOptions: IOption<IAsset>[];
  assetClasses: IAssetClass[];
  assetSubClasses: IAssetSubClass[];
  formName: string;
  isSearchLoading?: boolean;
  isEditing?: boolean;
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
  dynamicSearchAssetByName(name: string, type: string): AxiosPromise<IPortfolioTrade[]>;
}

export interface ISimpleInstrumentFormValues {
  instrument?: IOption<IAsset>;
  other: string;
  tradeTime: string;
  fxRate: number | null;
  amount: number;
  price: number;
  currency?: string | ICurrency | null | undefined; // string; // Currency of instrument from blotter
  settlementOption: IPortfolioTrade['settlementOption'];
  tradeType: IAssetClass;
  operation: 'BUY' | 'SELL';
  custodian: string | IOption<ICustodian> | any;
  notes?: string;
  asset: 'CshAdjustment';
  key?: string;
  tradeCosts?: number;

  commission?: number;
}

type FormUpdater = <T extends keyof ISimpleInstrumentFormValues>(
  field: T,
  data: ISimpleInstrumentFormValues[T] | null
) => void;

type IDecimal = { quantityDecimals: number; priceDecimals: number };

type Props = IAssetEntryComponentProps<ISimpleInstrumentFormValues> &
  InjectedFormProps<ISimpleInstrumentFormValues> &
  IMapStateToProps &
  IDispatchToProps & {
    assetSearchType?: string;
    tradeTypeId?: string;
    formName?: string;
    quantityLabel?: string;
  };

const SimpleInstrumentEntry: FC<Props> = ({
  assetClasses,
  tradeTypeId,
  assetSubClasses,
  editMode,
  custodianOptions,
  baseCurrencyCode,
  formValues,
  resetForm,
  resetEditTrade,
  removeTrade,
  initialValues,
  snapshotFxRate,
  fetchFxRate,
  portfolioInfo,
  updateFormValue,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  updateTempTrade,
  instrumentOptions,
  assetSearchType,
  quantityLabel = 'Quantity',
  dynamicSearchAssetByName,
  isSearchLoading,
  isEditing,
}) => {
  const history = useHistory();
  const dispatch: AsyncActionDispatch = useDispatch();
  const [decimals, setDecimals] = useState<IDecimal>({
    quantityDecimals: 4,
    priceDecimals: 2,
  });
  const currencyValue = formValues?.instrument?.value?.currency;

  const fx = useFx();

  React.useEffect(() => {
    const foundAsset = [...assetClasses, ...assetSubClasses].find((i) => i.id === tradeTypeId);
    setDecimals({
      quantityDecimals: foundAsset?.quantityDecimals || 4,
      priceDecimals: foundAsset?.priceDecimals || 2,
    });
  }, [tradeTypeId]);

  React.useEffect(() => {
    resetForm(initialValues);
    const currency = initialValues.instrument?.value?.currency;
    if (typeof currency === 'string') {
      const conv = fx({ fromCurrencyName: currency, toCurrencyName: portfolioInfo?.currency?.name });
      updateFormValue('fxRate', conv.naturalToConvention(initialValues.fxRate ?? 1));
    }
  }, [initialValues]);

  const convention = fx({
    fromCurrencyName: formValues?.instrument?.value?.currency
      ? String(formValues?.instrument?.value?.currency)
      : undefined,
    toCurrencyName: portfolioInfo?.currency?.name,
  });

  const isFormInvalid = React.useCallback(() => {
    return (
      isValueInvalid(formValues.fxRate) ||
      isValueInvalid(formValues.amount) ||
      isValueInvalid(formValues.instrument?.id || formValues.instrument?.value?.id || formValues.instrument?.name)
    );
  }, [formValues?.fxRate, formValues?.amount, formValues?.instrument])();

  const onTyping = (str: string) => {
    dynamicSearchAssetByName(`${encodeURIComponent(str)}&n=${Math.random()}`, assetSearchType!);
  };

  // const searchAsset = async (str: string,  callback?: (v: Array<IOption<string>>) => void) => {
  //   try {
  //     const res = await dynamicSearchAssetByName(`${encodeURIComponent(str)}&n=${Math.random()}`, assetSearchType!);
  //     const assets: Array<any> = res.data.map((asset: any) => {
  //       let name = asset.name;
  //       // console.log(asset);
  //
  //       if (asset.code !== '' && asset.code !== null) {
  //         if (
  //           typeof asset.assetClass === 'string' &&
  //           (asset.assetClass === 'Alternatives' || asset.assetClass === TRANSACTION_TYPES.cryptoCurrencies)
  //         ) {
  //           name = `${asset.name} [${asset.code}]`;
  //         } else {
  //           name = `${asset.name} [${asset.code} - ${asset.currency}]`;
  //         }
  //       }
  //       return str;
  //       return {
  //         id: asset.code + asset.currency,
  //         name,
  //         value: asset,
  //       };
  //     });
  //     callback?.(assets);
  //   } catch (e) {
  //     callback?.([]);
  //   }
  // };

  const debouncedSearchAsset = debounce(onTyping, 200); // debounce(searchAsset, 200);

  const getFx = (tradeTime?: string, currency?: string) => {
    if (!portfolioInfo?.currency?.name || !currency || !tradeTime) {
      return;
    }
    fetchFxRate(portfolioInfo.currency.name, currency, tradeTime)
      .then((result) => {
        if (result.data?.firstFxRate) {
          updateFormValue('fxRate', result.data.firstFxRate);
        }
      })
      .catch(() => {});
  };

  const getPrice = (sourceId?: string, sourceData?: string, tradeTime?: string) => {
    if (!sourceId || !sourceData || !tradeTime || editMode) {
      return;
    }
    dispatch(SnapshotActions.fetchPrice(sourceId, sourceData, tradeTime)).then((result) => {
      if (typeof result.data === 'number') {
        updateFormValue('price', (result.data as unknown) as number);
      }
    });
  };

  const getPriceOnInstrumentChange = (instrument: any) => {
    const value = instrument?.value;
    if (!value) {
      return;
    }
    const { sourceId, sourceData } = value.sourceId ?? {};
    if (!sourceId || !sourceData || !formValues.tradeTime) {
      return;
    }
    const currency = value?.currency;
    updateFormValue('currency', currency);
    getPrice(sourceId, sourceData, formValues.tradeTime);
    getFx(formValues.tradeTime, currency);
  };

  const getPriceOnDateChange = (date: string) => {
    const source = formValues.instrument?.value?.sourceId;
    const { sourceId, sourceData } = source ?? {};
    if (!sourceId || !sourceData || !date) {
      return;
    }
    const currency = formValues.instrument?.value?.currency;

    // Feb '22 ILLDV001-5282 change requested to "always update the FX when the user changes the date"
    getFx(date, String(currency));
    if (initialValues.checkedOut) {
      return;
    }
    getPrice(sourceId, sourceData, date);
  };

  const availableSettlementOptions = React.useMemo(() => {
    const settlementBtns: Array<{ text: string; value: any; subText?: string }> = [
      { text: baseCurrencyCode ?? 'NA', value: 'BASE' },
      { text: `TRANSFER`, value: 'AUTO' },
    ];

    if (currencyValue && baseCurrencyCode !== currencyValue) {
      settlementBtns.splice(1, 0, { text: String(currencyValue) ?? 'NA', value: 'LOCAL' });
    }
    return settlementBtns;
  }, [baseCurrencyCode, currencyValue]);

  const updateTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      settlementDate: undefined,
      tradeType: formValues.tradeType,
      instrument: formValues.instrument,
      quantity: formValues.amount,
      fxRate: convention.conventionToNatural(formValues.fxRate ?? 1),
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      currency: formValues.currency,
      notes: formValues.notes,
      tradeCosts: formValues.tradeCosts ?? 0,
      commission: formValues.commission ?? 0,
      price: formValues.price,
      key: parseFloat(formValues.key ?? '0') as any,
    } as any;

    updateTempTrade!(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetEditTrade();
    });
  };

  const tradeIsInPortfolioCurrency = !currencyValue || baseCurrencyCode === currencyValue;

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption,
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value,
      quantity: formValues.amount,
      fxRate: convention.conventionToNatural(formValues.fxRate ?? 1),
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      custodian: formValues.custodian,
      currency: formValues.currency,
      notes: formValues.notes,
      tradeCosts: formValues.tradeCosts ?? 0,
      commission: formValues.commission ?? 0,
      price: formValues.price,
    } as any;

    saveTrades(portfolioInfo!.id, [trade]).then(() => {
      resetForm(initialValues);
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
    });
  };

  const grossTradeAmount = nz(formValues.price) * nz(formValues.amount) * n1(formValues.instrument?.value?.pointValue);

  const currencyLabel = convention.fromCurrency?.name ?? String(formValues.instrument?.value?.currency ?? '');
  const isPennyCurrency =
    (assetSearchType === 'equity' || assetSearchType === 'fund') &&
    formValues.instrument?.value?.pointValue === 0.01 &&
    formValues.instrument.value.currency === 'GBP';
  const pennyCurrencyLabel = isPennyCurrency
    ? `${currencyLabel.substr(0, 2)}${currencyLabel.substr(-1).toLowerCase()}`
    : currencyLabel;

  const fxDescription = convention.getDescription({ conventionRate: formValues.fxRate });
  const conversionString = convention.getConversionString({ conventionRate: formValues.fxRate }, grossTradeAmount);
  return (
    <form className={s.formGrid} onSubmit={editMode ? updateTrade : saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime onChange={getPriceOnDateChange} />
        <AssetSearch
          label={'Asset Name'}
          disabled={!!editMode && !isEditing}
          onTyping={debouncedSearchAsset}
          isLoading={isSearchLoading}
          instrumentOptions={instrumentOptions}
          onChange={getPriceOnInstrumentChange}
        />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper className={s.settlementField} label="Operation">
          <Field name="operation" values={OPERATIONS} className="input--small" component={renderToggleBtns} />
        </FormFieldWrapper>
        <Amount quantityDecimals={decimals?.quantityDecimals || 4} label={quantityLabel} />
        <Price
          quantityDecimals={decimals?.priceDecimals || 2}
          label={`Price ${pennyCurrencyLabel}`}
          sourceId={formValues.instrument?.value?.sourceId}
          tradeTime={formValues.tradeTime}
          editMode={editMode}
        />
        <Commission currencyValue={currencyLabel} />
        <TradeCost currencyCode={currencyLabel} />
        <FXRate label={fxDescription} disabled={tradeIsInPortfolioCurrency} />
        <SettlementOptions options={availableSettlementOptions} />
        <Custodian />
        <AddCustodianButton />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>
      <div className={s.formFooter}>
        <span className={cn('text-gray', s.amountWrapper)}>Trade Gross Amount: {conversionString}</span>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const nz = (val?: number | null) => parseFloat(String(val)) || 0;
const n1 = (val?: number | null) => parseFloat(String(val)) || 1;

const mapStateToProps = (formName: string) => (state: IRootState): IMapStateToProps => ({
  formValues: (state.form[formName].values ?? {}) as ISimpleInstrumentFormValues,
  snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data as any,
  instrumentOptions: getAssetOptions(state),
  assetClasses: state.assetsClasses.data,
  assetSubClasses: state.assetsSubClasses.data,
  isSearchLoading: state.snapshot.snapshotEdit.searchAssets?.isFetching,
  isEditing: state.snapshot.snapshotEdit.editedUnconfirmedTrade.isEditing,
  formName,
});

const mapDispatchToProps = (formName: string) => (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(formName, field, data));
  },
  dynamicSearchAssetByName: (name: string, type: string) =>
    dispatch(SnapshotActions.dynamicSearchAssetByName(name, type)),
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const OPERATIONS = [
  { text: 'Buy', value: 'BUY' },
  { text: 'Sell', value: 'SELL' },
];

export const createSimpleInstrumentForm = (
  formName: string,
  assetSearchType: string = 'equity',
  tradeTypeId: string,
  quantityLabel = 'Quantity'
) =>
  reduxForm<ISimpleInstrumentFormValues, IAssetEntryComponentProps<ISimpleInstrumentFormValues>>({
    form: formName,
    validate,
    destroyOnUnmount: true,
  })(
    connect(mapStateToProps(formName), mapDispatchToProps(formName), (ownProps, mapProps, dispatchProps) => ({
      ...ownProps,
      ...mapProps,
      ...dispatchProps,
      assetSearchType,
      tradeTypeId,
      formName,
      quantityLabel,
    }))(SimpleInstrumentEntry)
  );

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};
