import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Loader } from '../../../../components';
import { Card } from '@iliotech/storybook';
import { API_URL } from '../../../../services/constants/endpoints';

import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../components/HOC/withPortfolioInfo';

import { VolatilityScoreChart } from './components/VolatlityScoreChart';
import { VolatilityContributionChart } from './components/VolatilityContributionChart';
import { TopTenVolatilityChart } from './components/BetaVsPortfolioBenchmarkChart';
import { RollingVsVolatilityChart } from './components/RollingVsVolatilityChart';
import { SimulatedReturnChart } from './components/SimulatedReturnChart';
import { VolatilityPeriodToggle } from './components/VolatilityPeriodToggle';
import { ReturnVsRiskScatterChart } from './components/ReturnVsRiskScatterChartOld';

import { IPortfolioVolatilityData, IRollingVolatilityData } from './interfaces';
import { getMockedVolatilityData } from './MockedVolatilityData';
import { sampleSimulatedReturn } from './components/SimulatedReturnChart/__testdata__/sampleSimulatedReturnData';

import { reduxErrorStatusHandler } from '../../../../services/utils/ReduxHelper';
import { CognitoHelper } from '../../../../services/utils/CognitoHelper';

import s from './RiskContainer.module.scss';
import { ISimulatedReturnData } from './interfaces';
import Analytics from '../../../../services/utils/Analytics';
import {
  DASHBOARD_PORTFOLIO_HELP_TEXT,
  RISK_CONTAINER_HELP_TEXT,
} from '../../../../services/constants/tooltipContextHelp';
import { PortfolioHeader, Widget } from '../../components';
import moment from 'moment';
import PortfolioSummarySpoiler from './components/PortfolioSummarySpoiler/PortfolioSummarySpoiler';
import { PortfolioSubHeader } from '../../components/PortfolioHeader/PortfolioSubHeader';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../components/ContextHelp';

const API_BENCHMARK_VOLATILITY = API_URL + 'api/risk/v1/volatility/portfolio/';
const API_RETURN = API_URL + 'api/risk/v1/returns/portfolio/';
const API_VOLATILITY_SCORE = API_URL + 'api/risk/v1/volatility/score/portfolio/';

type IProps = IWithPortfolioInfoProps;

interface IState {
  loading: boolean;
  error: string;
  portfolioId: string;
  portfolioName: string;
  portfolioBaseCcy: string;
  portfolioBaseCcySymbol: string;
  portfolioBenchmarkIndexName: string;
  portfolioTotalPnL: number;
  portfolioTotalPerformance: number;
  portfolioTotalWealth: number;
  portfolioVolatilityData: IPortfolioVolatilityData;
  portfolioHistoricVolatilityData: IPortfolioVolatilityData;
  mockedApiResponse: boolean;
  rollingVolatilityData: IRollingVolatilityData[];
  rollingHistoricVolatilityData: IRollingVolatilityData[];
  simulatedReturnData: ISimulatedReturnData;
  simulatedHistoricReturnData: ISimulatedReturnData;
  volatilityPeriod: string;
  sectionId: number | null;
  insufficientData: boolean;
  insufficientDataMessage: string;
}

class BacktestContainer extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      error: '',
      portfolioId: '',
      portfolioName: '',
      portfolioBaseCcy: '',
      portfolioBaseCcySymbol: '',
      portfolioBenchmarkIndexName: '',
      portfolioTotalPnL: 0,
      portfolioTotalPerformance: 0,
      portfolioTotalWealth: 0,
      portfolioVolatilityData: getMockedVolatilityData(),
      portfolioHistoricVolatilityData: getMockedVolatilityData(),
      mockedApiResponse: false,
      rollingVolatilityData: [],
      rollingHistoricVolatilityData: [],
      simulatedReturnData: sampleSimulatedReturn,
      simulatedHistoricReturnData: sampleSimulatedReturn,
      volatilityPeriod: 'LONG',
      sectionId: 0,
      insufficientData: false,
      insufficientDataMessage: '',
    };
  }

  componentDidMount() {
    Analytics.trackPageView('Backtest Dashboard');
    // This is needed for using local mocked data in development but may come useful for the automated tests as well
    // ToDo: Switch to using the utility from RiskHelper (already used in TWR). Same refactoring needs applying for the rest of Dashboards.
    if (!this.state.mockedApiResponse) {
      CognitoHelper.withAuthorisationToken(() => {
        this.setState({ loading: true });
        this._fetchData()
          .then((result) => {
            this.setState({
              portfolioVolatilityData: result[0],
              rollingVolatilityData: result[1],
              simulatedReturnData: result[2],
            });
            this.setState({ loading: false });
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return error;
            }
            reduxErrorStatusHandler(error);
            this.setState({
              error: `${error}`,
              loading: false,
            });
          });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  toggleVolatilityPeriod = () => {
    if (this.state.volatilityPeriod === 'LONG') {
      this.setState({ volatilityPeriod: 'SHORT' });
    } else {
      this.setState({ volatilityPeriod: 'LONG' });
    }
    this.componentDidMount();
  };

  renderSections = () => {
    const {
      portfolioVolatilityData,
      portfolioHistoricVolatilityData,
      rollingVolatilityData,
      rollingHistoricVolatilityData,
      simulatedReturnData,
      simulatedHistoricReturnData,
      volatilityPeriod,
    } = this.state;

    const periodConverted = volatilityPeriod === 'LONG' ? '260-day ' : '100-day ';

    const simulatedAnnualisationPeriod =
      'Returns based on daily data from ' +
      moment(portfolioVolatilityData.portfolio.annualisationStartDate).format('DD-MMM-YYYY') +
      ' to ' +
      moment(portfolioVolatilityData.portfolio.annualisationEndDate).format('DD-MMM-YYYY');

    return (
      <div className={s.defaultContainer} style={{ position: 'relative' }}>
        <VolatilityPeriodToggle currentPeriod={volatilityPeriod} clickFunction={this.toggleVolatilityPeriod} />

        <div className={s.containerRow}>
          <Card style={{ flex: 1, marginRight: 10 }}>
            <div className={s.scoreChartContainer}>
              <VolatilityScoreChart
                data={portfolioVolatilityData}
                portfolioBenchmarkIndexName={this.state.portfolioBenchmarkIndexName}
                chartTitle={'Portfolio and Benchmark Volatility (daily)'}
                helpText={RISK_CONTAINER_HELP_TEXT.ChartRiskScoreGaugeDaily}
              />
            </div>
          </Card>
          <Card style={{ flex: 1, marginLeft: 10, paddingLeft: 35 }}>
            <div className={s.returnVsRiskScatterChartContainer}>
              <ReturnVsRiskScatterChart
                data={portfolioVolatilityData}
                chartPeriodText={simulatedAnnualisationPeriod}
                chartTitle={'Annualised Return vs Volatility (daily)'}
                helpText={RISK_CONTAINER_HELP_TEXT.ChartSimulatedPortfolioReturnVsVolatility}
              />
            </div>
          </Card>
        </div>
        <div className={s.containerRow}>
          <Card style={{ flex: 1, marginRight: 10 }}>
            <div className={s.simulatedReturnChartContainer}>
              <SimulatedReturnChart
                data={simulatedReturnData}
                chartTitle={'Distribution of simulated daily returns'}
                chartPeriodInfo={'(last 12 months)'}
                helpText={RISK_CONTAINER_HELP_TEXT.HistogramDistributionOfSimulatedReturnDaily}
              />
            </div>
          </Card>
          <Card style={{ display: 'flex', flex: 1, marginLeft: 10, paddingLeft: 35 }}>
            <div className={s.rollingVolatilityChartContainer}>
              <RollingVsVolatilityChart
                data={rollingVolatilityData}
                chartTitle={'Simulated 1 year return versus ' + periodConverted + 'volatility (over time)'}
                helpText={
                  periodConverted === '100-day '
                    ? RISK_CONTAINER_HELP_TEXT.ChartSimulatedReturnVsVolatility100Days
                    : RISK_CONTAINER_HELP_TEXT.ChartSimulatedReturnVsVolatility
                }
              />
            </div>
          </Card>
        </div>
      </div>
    );
  };

  render() {
    const { loading, error } = this.state;

    const portfolioHeader = <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false} />;

    const stats: IStat[] = [
      {
        value: this.state.portfolioTotalPnL,
        label: 'Inception P&L',
        colored: true,
        type: 'currency',
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoInceptionPnL },
      },
      {
        value: this.state.portfolioTotalPerformance,
        label: 'Performance (TWR)',
        type: 'percentage',
        colored: true,
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
      },
      {
        value: this.state.portfolioTotalWealth,
        label: 'Total Portfolio Value',
        type: 'currency',
        colored: true,
        info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
      },
    ];

    if (loading) {
      return (
        <div>
          {portfolioHeader}
          <div className={s.dashboardTitleContainer}>
            <h3>Loading portfolio data...</h3>
          </div>
          <Loader />
        </div>
      );
    }
    if (error) {
      return (
        <div>
          {portfolioHeader}
          <div className={s.dashboardTitleContainer}>
            <h4>There was an error loading data.</h4>
            <p>{this.state.error}</p>
          </div>
          <p>
            <button onClick={this.reloadPortfolioVolatility}>Click here to try again.</button>
          </p>
        </div>
      );
    }
    return (
      <div>
        {portfolioHeader}
        {/*<PageHeader title="Backtest" showPortfolioInfo={true}/>*/}
        <PortfolioSubHeader title={'Backtest'} stats={stats} />

        <div className={s.defaultContainer}>{this.renderSections()}</div>
      </div>
    );
  }

  private _fetchData(): Promise<any> {
    const { portfolioInfo } = this.props;
    const portfolioId = portfolioInfo.id;
    this.setState({
      portfolioName: portfolioInfo.name,
      portfolioId: portfolioInfo.id,
      portfolioBaseCcy: portfolioInfo.currency.name,
      portfolioBaseCcySymbol: portfolioInfo.currency.symbol,
      portfolioBenchmarkIndexName: portfolioInfo.benchmarkIndex.name,
      portfolioTotalPnL: portfolioInfo.totalProfitAndLoss,
      portfolioTotalPerformance: portfolioInfo.performance,
      portfolioTotalWealth: portfolioInfo.totalWealth,
    });

    return Promise.all([
      this.getPortfolioVolatility(portfolioId, false),
      this.getRollingVolatility(portfolioId, false),
      this.getSimulatedReturn(portfolioId, false),
      // this.getPortfolioVolatility(portfolioId, true),
      //  this.getRollingVolatility(portfolioId, true),
      //  this.getSimulatedReturn(portfolioId, true),
    ]);
  }

  getPortfolioVolatility = (portId: string, isHistoric: boolean) => {
    const cancelTokenSource = axios.CancelToken.source();
    const compiledApiUrl = isHistoric
      ? API_BENCHMARK_VOLATILITY + portId + '/historic/'
      : API_BENCHMARK_VOLATILITY + portId + '?term=' + this.state.volatilityPeriod;
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });
    return apiRequest.get(compiledApiUrl, {}).then((result) => {
      return result.data;
    });
  };

  getRollingVolatility = (portId: string, isHistoric: boolean) => {
    const cancelTokenSource = axios.CancelToken.source();
    const compiledApiUrl = isHistoric
      ? API_BENCHMARK_VOLATILITY + portId + '/rolling/return/historic'
      : API_BENCHMARK_VOLATILITY + portId + '/rolling/return?term=' + this.state.volatilityPeriod;
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });
    return apiRequest.get(compiledApiUrl, {}).then((result) => {
      return result.data;
    });
  };

  getSimulatedReturn = (portId: string, isHistoric: boolean) => {
    const cancelTokenSource = axios.CancelToken.source();
    const compiledApiUrl = isHistoric
      ? API_RETURN + portId + '/histogram/historic'
      : API_RETURN + portId + '/histogram';
    const apiRequest = axios.create({
      method: 'post',
      responseType: 'json',
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });
    return apiRequest.get(compiledApiUrl, {}).then((result) => {
      return result.data;
    });
  };

  reloadPortfolioVolatility = () => {
    const portfolioId = this.state.portfolioId.toString();
    this.getPortfolioVolatility(portfolioId, false);
    this.getPortfolioVolatility(portfolioId, true);
  };
}

export default withPortfolioInfo(connect()(BacktestContainer));
