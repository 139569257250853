import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import withPortfolioInfo, { IWithPortfolioInfoProps } from '../../../../components/HOC/withPortfolioInfo';
import cn from 'classnames';
import s from './PortfolioHeader.module.css';
import { DashboardMenu } from '../../scenes/Dashboard/scenes/PortfolioDashboard/components';
import { useSize } from 'react-use';
import { PnlStatus } from '../../../../components/PnlStatus';

interface IOwnProps {
  subClassId?: string;
  subtitle?: string;
  showDashboardMenu?: boolean;
}

type Props = IOwnProps &
  IWithPortfolioInfoProps &
  RouteComponentProps<{
    classId?: string;
  }>;

const PortfolioHeader: FunctionComponent<Props> = ({
  portfolioInfo,
  match,
  subtitle,
  showDashboardMenu = true,
  children,
}) => {
  const [sized, { height }] = useSize(
    <div className={cn(s.contentInner)}>
      <div className={cn(s.headerSurround)}>
        <h1 className={cn(s.header)}>
          <span>
            <PnlStatus portfolioInfo={portfolioInfo} modalActive={false} />
          </span>
          {portfolioInfo.name} <span className={cn(s.portfolioCurrency)}>{portfolioInfo.currency.name}</span>
        </h1>
        {!!subtitle && <h3>{subtitle}</h3>}

        <div style={{ height: 10 }} />
      </div>
      {showDashboardMenu && (
        <div className={cn(s.itemsSurround)}>
          <DashboardMenu selectedAssetId={match.params.classId ?? 'All'} />
        </div>
      )}
      {children}
    </div>
  );

  return (
    <React.Fragment>
      <div className={cn(s.surround)}>
        <div className={cn(s.content)}>{sized}</div>
      </div>
      <div style={{ height }} />
    </React.Fragment>
  );
};

interface IPageHeaderProps {
  subClassId?: string;
  title: string;
  subtitle?: string;
}

export const PageHeader: FunctionComponent<IPageHeaderProps> = ({ title, subtitle, children }) => {
  const [sized, { height }] = useSize(
    <div className={cn(s.contentInner)}>
      <div className={cn(s.headerSurround)}>
        <h1 className={cn(s.header)}>{title}</h1>
        {!!subtitle && <h3>{subtitle}</h3>}

        <div style={{ height: 10 }} />
      </div>
      {children}
    </div>
  );

  return (
    <React.Fragment>
      <div className={cn(s.surround)}>
        <div className={cn(s.content)}>{sized}</div>
      </div>
      <div style={{ height }} />
    </React.Fragment>
  );
};

export default withRouter(withPortfolioInfo(React.memo(PortfolioHeader)));
