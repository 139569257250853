import React, { PureComponent, useEffect } from 'react';
import { IWidgetProps } from '../../../../../../components/WidgetsBlock/Widget/Widget';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';
import { DetalizationType } from '../../../../../../../../services/constants/constants';
import useApi from '../../../../../../../../services/hooks/useApi';
import PerformanceChartWidget from '../../../../../../components/PerformanceChartWidget/PerformanceChartWidget';
import { Loader } from '../../../../../../../../components';

interface IOwnProps {
  period: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioId: string;
  selectedCustodians: string[];
  hidePeriodSelector: boolean;
  height?: number;
}

type IProps = IOwnProps &
  Pick<IWidgetProps, 'colNum' | 'title' | 'titleInfo' | 'full'> & { hidePeriodSelector?: boolean } & {
    performanceToggle?: number | 0;
  };

const SummaryPerformanceChart = ({
  selectedCustodians,
  portfolioId,
  period,
  performanceToggle,
  portfolioCurrencyFormatter,
  hidePeriodSelector,
  height,
}: IProps) => {
  const toggledPerformance: any[] = [];
  const pnl: any[] = [];
  const marketValueBase: any[] = [];
  const netInvestments: [string, number][] = [];

  const portfolioPerformance = useApi(
    getApiUrl('portfolio.performance'),
    {
      method: 'POST',
      data: {
        portfolioId,
        period: PeriodHelper.preparePeriodForRequest(period),
        detalization: DetalizationType.Daily,
        custodians: selectedCustodians || [],
      },
    },
    {
      enabled: false,
    }
  );

  const indexPerformance = useApi(
    getApiUrl('dashboard.indexPerformance'),
    {
      method: 'POST',
      data: {
        id: portfolioId,
        period: PeriodHelper.preparePeriodForRequest(period),
        detalization: DetalizationType.Daily,
        custodians: selectedCustodians || [],
      },
    },
    {
      enabled: false,
    }
  );

  const isLoading = portfolioPerformance.isLoading || indexPerformance.isLoading;
  const isLoaded = portfolioPerformance.isFetched && indexPerformance.isFetched;

  useEffect(() => {
    if (portfolioId) {
      portfolioPerformance.refetch();
      indexPerformance.refetch();
    }
  }, [period, portfolioId, JSON.stringify(selectedCustodians)]);

  if (isLoading) {
    return <Loader />;
  }

  const mode = performanceToggle === 0 ? 'PERFORMANCE' : 'VALUE';

  if (mode === 'VALUE') {
    // console.log({portfolioPerformance});
    portfolioPerformance?.data?.data?.forEach((el: any) => {
      toggledPerformance.push([el[0], el[2]]);
      marketValueBase.push([el[0], el[2]]);
      pnl.push([el[0], el[3]]);
      netInvestments.push([el[0], el[4]]);
    });
  } else {
    portfolioPerformance?.data?.data?.forEach((el: any) => {
      toggledPerformance.push([el[0], el[1]]);
    });
  }

  return (
    <React.Fragment>
      <PerformanceChartWidget
        // mode={performanceToggle === 1 ?}
        selectedPeriod={period}
        hidePeriodSelector={hidePeriodSelector}
        isDataLoaded={isLoaded}
        portfolioPerformance={toggledPerformance}
        marketValueBase={marketValueBase}
        netInvestments={netInvestments}
        performanceToggle={performanceToggle}
        indexName={indexPerformance.data?.name}
        indexPerformance={indexPerformance.data?.data}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        height={height}
        pnl={pnl}
      />
    </React.Fragment>
  );
};

export default SummaryPerformanceChart;
