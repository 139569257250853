import React, { FunctionComponent, useState } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import Combo from '../../../components/Combo/Combo';
import UnlistedTable, { REAL_ASSET_TABLE_STATE } from './UnlistedTable';
import { IRootState } from '../../../services/store';
import { IRealAssetsState } from '../../../services/reducers/RealAssetReducers';
import { AxiosPromise } from 'axios';
import { IRealAsset } from '../../../models/IRealAsset';
import { AsyncActionDispatch } from '../../../services/utils/ReduxHelper';
import { RealAssetActions } from '../../../services/actions/RealAssetActions';
import { generatePath } from 'react-router';

interface IProps {
  assetClassId: AssetClassId;
}

interface IMapProps {
  assetSubClassComboItems: Array<{ value: string; label: string }>;
  realAssets: IRealAssetsState;
}

interface IDispatchToProps {
  fetchRealAssets: () => AxiosPromise<IRealAsset[]>;
}

type Props = IProps & IMapProps & IDispatchToProps;

const UnlistedTableContainer: FunctionComponent<Props> = ({
  assetClassId,
  assetSubClassComboItems,
  realAssets,
  fetchRealAssets,
}) => {
  const [state, setState] = React.useState(REAL_ASSET_TABLE_STATE.LOADING);
  const { path, params } = useRouteMatch<{ portfolioId: string }>();
  const [assetSubClass, setAssetSubClass] = useState<any>('');
  const [instruments, setInstruments] = React.useState<IRealAssetsState['data']>(realAssets.data);

  React.useEffect(() => {
    if (realAssets.data) {
      if (!assetSubClass) {
        setInstruments(
          realAssets.data.filter((asset) => asset.assetClass === assetClassId || asset.riskAssetClass === assetClassId)
        );
      } else {
        setInstruments(realAssets.data.filter((asset) => asset.assetSubClass === assetSubClass));
      }
      setState(realAssets.data.length > 0 ? REAL_ASSET_TABLE_STATE.READY : REAL_ASSET_TABLE_STATE.EMPTY);
    }
  }, [realAssets, assetSubClass, assetClassId]);

  React.useEffect(() => {
    fetchRealAssets();
    setAssetSubClass('');
  }, [assetClassId]);

  return (
    <React.Fragment>
      <Route path={`${path}`} exact={true}>
        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <strong>Asset Subclass: {'\u00a0\u00a0'}</strong>
          <Combo
            style={{ width: '12rem' }}
            items={assetSubClassComboItems}
            name={''}
            onSelect={(item: IComboItem) => setAssetSubClass(item?.value ?? '')}
          />
          <Link to={generatePath(`${path}/add`, { assetClassId, portfolioId: params.portfolioId })}>
            <button style={{ marginLeft: '1rem' }}>+ CREATE NEW INSTRUMENT</button>
          </Link>
        </div>
        <UnlistedTable
          {...{
            state,
            instruments,
            assetClassId,
          }}
        />
        {realAssets.error && <pre>An error has occurred. Please try again later</pre>}
      </Route>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IProps) => ({
  assetSubClassComboItems: state.assetsSubClasses.data
    .filter((item) => item.assetClassId === ownProps.assetClassId)
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((subClass) => ({ value: subClass.id, label: subClass.name })),
  realAssets: state.realAssets,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchRealAssets: () => dispatch(RealAssetActions.fetchRealAssetsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlistedTableContainer);
