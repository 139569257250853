import React from 'react';

import cn from 'classnames';
import s from '../VolatilityContributionChart.module.css';

interface IBarColumn {
  esmaBand: number;
  barWeight: number;
  barLegend?: string;
  volatility?: string;
  isShort?: boolean;
}

export function BarColumn(props: IBarColumn) {
  const esmaColors = ['#138eb0', '#41d9a0', '#00b26f', '#d9bf41', '#fb9900', '#e17300', '#e13800', '#a50009'];
  const shortPositionColor = '#3863ff';

  const esmaBand = props.esmaBand;
  const barHeight = Math.round(props.barWeight * 2.25);
  const barLabel = props.barWeight < 1 ? '<1%' : props.barWeight + '%';
  const legendColor = props.isShort ? shortPositionColor : props.barWeight < 1 ? '#e13800' : '#d7e0ff';
  const legend = props.barLegend;
  const positionType = props.isShort ? 'Short' : 'Long';

  const isShortLabel = props.isShort ? <div style={{ color: shortPositionColor }}>Short</div> : '';

  return (
    <div className={s.BarColumn}>
      {isShortLabel}
      <div className={s.BarValueBox}>
        <div
          className={cn(s.BarValue, s.tooltip)}
          style={{
            height: barHeight + 'px',
            lineHeight: barHeight > 22 ? barHeight + 'px' : 20 + 'px',
            backgroundColor: esmaColors[esmaBand],
            borderColor: esmaColors[esmaBand],
          }}
        >
          <span className={s.tooltiptext}>
            {positionType} Position
            <br />
            Volatility: {props.volatility}
          </span>
          <span>{barLabel}</span>
        </div>
        <div className={s.BarLabel} style={{ borderColor: esmaColors[esmaBand], color: legendColor }}>
          {legend}
        </div>
      </div>
    </div>
  );
}
