import { required, password, passwordAreEqual } from '../../services/utils/FormValidations';

const validate = (value: IPasswordFormData): Record<string, string | undefined | Array<string | undefined>> => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.currentPassword = required(value.currentPassword) || password(value.currentPassword);
  errors.newPassword =
    required(value.newPassword) ||
    password(value.newPassword) ||
    passwordAreEqual(value.newPassword, value.newPassword2);
  errors.newPassword2 = required(value.newPassword2) || password(value.newPassword2);
  return errors;
};

export default validate;
