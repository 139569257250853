import React, { Fragment, PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { ChartLegend } from '../../../../../../components/ChartLegend';
import { ILegendItemProps, LegendType } from '../../../../../../components/ChartLegend/components/LegendItem';

import { IRollingVolatilityData } from './../../interfaces';

import { generateChartOptions } from './RollingVsVolatilityChartConfig';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';
import { RISK_CONTAINER_HELP_TEXT } from '../../../../../../services/constants/tooltipContextHelp';

interface IOwnProps {
  data: IRollingVolatilityData[];
  period?: string;
  chartTitle: string;
  helpText?: string;
}

type IProps = IOwnProps;

class RollingVsVolatilityChart extends PureComponent<IProps> {
  render() {
    const chartOpt = generateChartOptions(this.props.data);

    const legendOpt: ILegendItemProps[] = [
      { name: 'Benchmark Volatility', color: '#138eb0' },
      { name: 'Benchmark 1 year Return', color: '#c2b261' },
      { name: 'Portfolio Volatility', color: '#56b877' },
      { name: 'Portfolio 1 year Return', color: '#6677cc' },
    ];

    const chartTitle = this.props.chartTitle;
    const contextHelpText = this.props.helpText;

    return (
      <Fragment>
        <h3>
          {chartTitle}
          <ContextHelpIcon title={chartTitle} elementId={chartTitle} />
        </h3>
        <br />
        <br />
        <ChartLegend legend={legendOpt} type={LegendType.LINE} />
        <ReactEcharts
          style={{ flex: 1, height: '100%', width: '100%', maxHeight: '400px' }}
          option={chartOpt}
          notMerge={true}
        />
        <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={contextHelpText} />
      </Fragment>
    );
  }
}

export default RollingVsVolatilityChart;
