import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { IRootState } from '../store';
import app from './AppReducers';
import assetsClassesLightReducer from './AssetsClassLightReducer';
import assetsClassesReducer from './AssetsClassReducers';
import assetsSubClassesReducer from './AssetsSubClassReducer';
import countryReducer from './CountryReducer';
import currencyReducer from './CurrencyReducers';
import custodianReducer from './CustodiansReducers';
import dashboard from './DashboardReducers';
import industryReducer from './IndustryReducers';
import instrumentsReducer from './InstrumentsReducers';
import portfolioCurrencyReducer from './PortfolioCurrencyReducers';
import portfolioIndexReducer from './PortfolioIndexReducers';
import portfolio from './PortfolioReducers';
import portfoliosList from './PortfoliosListReducers';
import recommendations from './RecommendationsReducers';
import reports from './ReportsReducers';
import scenario from './ScenariosReducers';
import snapshot from './SnapshotReducers';
import users from './UsersReducers';
import realAssetsReducer from './RealAssetReducers';
import userCapabilitiesReducer from './UserCapabilitiesReducer';
import accountsReducer from './AccountsReducer';
import sectorReducer from './SectorsReducers';
import riskAssetClassesReducer from './RiskAssetClassesReducers';

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    sectors: sectorReducer,
    riskAssetClasses: riskAssetClassesReducer,
    form: reduxFormReducer,
    router: connectRouter(history),
    app,
    portfolio,
    dashboard,
    reports,
    scenario,
    risk: combineReducers({ recommendations }),
    portfoliosList,
    snapshot,
    users,
    custodians: custodianReducer,
    instrumentsLightClasses: assetsClassesLightReducer,
    assetsClasses: assetsClassesReducer,
    assetsSubClasses: assetsSubClassesReducer,
    currency: currencyReducer,
    portfolioCurrency: portfolioCurrencyReducer,
    industry: industryReducer,
    country: countryReducer,
    instruments: instrumentsReducer,
    portfolioIndex: portfolioIndexReducer,
    realAssets: realAssetsReducer,
    userCapabilities: userCapabilitiesReducer,
    accounts: accountsReducer,
  });

export default rootReducer;
