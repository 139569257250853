import Downshift from 'downshift';
import cn from 'classnames';
import s from './Combo.module.css';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseButton from './Widgets/CloseButton';

interface IProps {
  items: IComboItem[];
  name: string;
  placeholder?: string;
  defaultValue?: string | number;
  style?: React.CSSProperties;
  comboItemSurroundStyle?: React.CSSProperties;
  validationOptions?: {
    required?: string | boolean;
  };
  inputStyle?: React.CSSProperties;
  onSelect?(item?: IComboItem): void;
  onChangeText?(text: string): void;

  itemToString?: <K extends IComboItem>(item?: K | null) => string;
}

const defaultItemToString = (item?: IComboItem | null) =>
  item?.label || (typeof item?.value === 'undefined' ? '' : String(item?.value));

const getSelectedItem = (items: IComboItem[], value: string | number | undefined) =>
  typeof value === 'undefined'
    ? undefined
    : items.filter((item) => String(item.value) === String(value) || item.label === String(value))[0] ?? undefined;

/**
 * Combo will try to register itself to the nearest ancestral react-hook-form and fail silently
 * @param name
 * @param label
 * @param placeholder
 * @param onSelect - ({value: string}) => void
 * @param items {value: string; label?: string}[]
 * @param defaultValue
 * @param style: Style to be passed to div wrapping the combo
 * @param? itemToString (item: extends IComboItem) => string. Describes what should be displayed when an item is selected
 * @param? comboItemSurroundStyle: Style to be passed to div that contains combo matches from which to choose
 * @constructor
 */
const Combo = ({
  name,
  placeholder,
  onSelect,
  items,
  defaultValue,
  style,
  validationOptions = { required: false },
  itemToString = defaultItemToString,
  onChangeText,
  comboItemSurroundStyle,
}: IProps) => {
  const initialSelectedItem = getSelectedItem(items, defaultValue);
  const initialSelectedValue = initialSelectedItem ? initialSelectedItem.value : '';

  const { register, setValue } = useFormContext() || {
    register: () => {},
  };

  const [filter, setFilter] = useState('');
  const [selectedValue, setSelectedValue] = useState<string | number | undefined>(initialSelectedValue);
  const [selectedItem, setSelectedItem] = useState<IComboItem | undefined | null>(initialSelectedItem);

  const firstValue = items[0]?.value;
  React.useEffect(() => {
    const newSelectedItem = getSelectedItem(items, defaultValue);
    setSelectedItem(newSelectedItem);
    setSelectedValue(newSelectedItem?.value ?? '');
  }, [defaultValue, items.length, firstValue]);

  return (
    <Downshift
      onChange={(selection) => {
        if (typeof onSelect === 'function') {
          onSelect(selection ?? undefined);
        }
        setSelectedItem(selection);
        setSelectedValue(selection?.value);
        setValue?.(name, selection?.value);
      }}
      itemToString={itemToString}
      onInputValueChange={(inputValue) => {
        setFilter(inputValue);
        onChangeText?.(inputValue);

        // setSelectedValue(selectedItem?.value ?? inputValue);
      }}
      selectedItem={selectedItem ?? null}
    >
      {(downShiftMethods) => {
        const clear = () => {
          setSelectedItem(null);
          setSelectedValue('');
          setValue?.(name, undefined);

          downShiftMethods.clearSelection();
        };

        return (
          <div className={cn(s.comboSurround)} style={style}>
            <ComboBox
              {...{ downShiftMethods, setFilter, placeholder, clear, name, items, filter, comboItemSurroundStyle }}
            />
            {/*<div>{watch(name)}</div>*/}
            <input
              type={'text'}
              ref={register(validationOptions)}
              value={selectedValue}
              onChange={() => {}} // Stop TS complaining about being controlled
              name={name}
              data-testid={name}
              style={{ display: 'none' }}
            />
          </div>
        );
      }}
    </Downshift>
  );
};

const ComboBox = ({
  downShiftMethods,
  setFilter,
  placeholder,
  clear,
  name,
  items,
  filter,
  comboItemSurroundStyle,
  inputStyle,
}: any) => {
  const { getInputProps, isOpen, getItemProps, highlightedIndex, selectedItem, openMenu } = downShiftMethods;
  return (
    <div className={cn(s.comboContainer)}>
      <input
        type={'text'}
        style={inputStyle}
        className={cn(s.comboInput, isOpen ? s.open : null)}
        data-testid={`${name}Input`}
        {...getInputProps({
          onFocus: () => {
            setFilter('');
            openMenu();
          },
          onClick: () => {
            setFilter('');
            openMenu();
          },
        })}
        placeholder={placeholder}
      />
      <CloseButton onClick={clear} />

      {isOpen && (
        <div className={cn(s.comboItemSurround)} style={comboItemSurroundStyle}>
          {items
            .filter(
              (item: IComboItem) =>
                !filter ||
                String(item.value).toUpperCase().includes(filter.toUpperCase()) ||
                item.label?.toUpperCase().includes(filter.toUpperCase())
            )
            .map((item: IComboItem, index: number) => (
              <li
                {...getItemProps({
                  key: item.value,
                  index,
                  className: cn(s.comboItem),
                  item,
                  style: {
                    backgroundColor: highlightedIndex === index || selectedItem === item ? 'var(--dark-blue)' : '',
                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                  },
                })}
              >
                {item.label ?? item.value}
              </li>
            ))}
        </div>
      )}
    </div>
  );
};

export default Combo;
