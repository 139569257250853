/* eslint-disable */
import Pusher from "pusher-js";
export const PUSHER_ID = process.env.REACT_APP_PUSHER_ID;
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;

export const pusherComponent = new Pusher(PUSHER_ID, { cluster: PUSHER_CLUSTER });

export class PusherHandler {
  static pusherComponent ;

  static subscribeToChannel = (channelName) => {
    pusherComponent.subscribe(channelName);
  }

  static addListener(channelName, eventName, handler) {
    pusherComponent.channel(channelName).bind(eventName, handler);
  }

  static unbind(channelName){
    pusherComponent.unsubscribe(channelName)
  }

  static init = () => {
    PusherHandler.pusherComponent = new Pusher(PUSHER_ID, { cluster: PUSHER_CLUSTER })
  }
}

export default pusherComponent;
