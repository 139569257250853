import React from 'react';
import s from '../../Dashboard.module.scss';
import { CustodiansFilter } from '../CustodiansFilter';
import {
  DASHBOARD_EQUITY_HELP_TEXT,
  DASHBOARD_PORTFOLIO_HELP_TEXT,
} from '../../../../../../../../services/constants/tooltipContextHelp';
import { API_URL } from '../../../../../../../../services/constants/endpoints';
import useApi from '../../../../../../../../services/hooks/useApi';
import { Loader } from '../../../../../../../../components';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { useCustodiansFilter } from '../../../../../../../../services/hooks/useCustodiansFilter';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
interface IProps {
  page: string;
  hideCustodianFilter?: boolean;
}

const DashboardHeader = ({ page, hideCustodianFilter }: IProps) => {
  const {
    custodianOptions,
    selectedCustodians,
    selectedCustodiansArray,
    changeSelectedCustodians,
  } = useCustodiansFilter();

  const { portfolioInfo, portfolioCurrencyFormatter } = usePortfolio();

  const portfolio = portfolioInfo.data;
  const API_HISTORIC_VOLATILITY_SCORE = API_URL + 'api/risk/v1/volatility/historic/portfolio/score';
  const getVolatilityScore = useApi(
    API_HISTORIC_VOLATILITY_SCORE,
    {
      method: 'POST',
      data: {
        portfolioId: portfolioInfo.data?.id,
        custodians: selectedCustodiansArray || [],
      },
    },
    {
      enabled: !!portfolio?.id,
    }
  );

  if (getVolatilityScore.isLoading) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(portfolio?.totalWealth);

  const formattedRiskValue =
    typeof getVolatilityScore.data === 'object'
      ? 'Insufficient Data'
      : getVolatilityScore.data !== null
      ? String(getVolatilityScore.data) + '%'
      : 'calculating...';

  const lifetimeTWR = `${portfolio?.performance?.toFixed(0)}%`;
  const totalPnlBase = portfolioCurrencyFormatter(portfolio?.totalProfitAndLoss);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: lifetimeTWR,
      label: 'Performance (TWR)',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
    },
    {
      value: total,
      label: 'Portfolio Value',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
    },
    {
      value: formattedRiskValue,
      label: 'Portfolio Volatility',
      colored: false,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPortfolioVolatility },
    },
  ];

  let mergedCustodians = [...custodianOptions];
  if (portfolio) {
    // @ts-ignore
    mergedCustodians = [...mergedCustodians, ...portfolio.additionalCustodians];
  }

  return (
    <div style={{ flexDirection: 'row' }}>
      <PortfolioSubHeader stats={stats} title={page} />
      {!hideCustodianFilter && (
        <div className={s.dashboardCustodiansFilter}>
          <CustodiansFilter
            onCustodiansChanged={() => {}}
            custodians={mergedCustodians}
            selectedCustodians={selectedCustodians}
            changeSelectedCustodians={changeSelectedCustodians}
          />
        </div>
      )}
      {/*<div style={{ height: 30 }} />*/}
    </div>
  );
};

export default DashboardHeader;
