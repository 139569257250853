import memoizeOne from 'memoize-one';
import { LineData } from '../../../../../../../../../../../../models/LineData';
import moment, { Moment } from 'moment';

import {
  ICurrencyFormatter,
  getPortfolioCurrencyFormatter,
} from '../../../../../../../../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../../../../../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../../../../../../../../../services/utils/FormatHelper';
import merge from 'lodash.merge';
import echarts from 'echarts/lib/echarts';
import { PERIOD_TYPE } from '../../../../../../../../../../../../services/constants/constants';

const LABEL_WIDTH: number = 30;

enum AxisLabelType {
  DAY,
  MONTH,
  QUARTER,
}

// fixme: put all methods from file in class to avoid global definitions
const labelsMap: Map<number, { value: string; index: number }> = new Map<number, { value: string; index: number }>();

const filterAxisLabels = memoizeOne(
  (data: LineData, labelType: AxisLabelType): LineData => {
    switch (labelType) {
      case AxisLabelType.MONTH:
        return data.filter((item) => moment(item[0]).date() === 1);
      case AxisLabelType.QUARTER:
        return data.filter((item) => {
          const date: Moment = moment(item[0]);
          return date.month() % 3 === 0 && date.date() === 1;
        });
      default:
        return data;
    }
  }
);

const prepareXAisLabels = memoizeOne(
  (data: LineData, period: IPeriod): Map<number, { value: string; index: number }> => {
    labelsMap.clear();
    const labelType: AxisLabelType = getLabelAxisType(data, period);
    filterAxisLabels(data, labelType).forEach((item) =>
      labelsMap.set(moment(item[0]).startOf('day').valueOf(), {
        value: getAxisLabel(item[0], labelType),
        index: labelsMap.size + 1,
      })
    );
    return labelsMap;
  }
);

export function generateChartOptions(
  dataset: any[],
  instrumentName: string,
  baseCurrencySymbol: string,
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  const chartData = dataset;
  if (!chartData || !chartData.length) return {};

  const dataInView: LineData = [];
  const period: IPeriod = { from: chartData[0].date, to: chartData[chartData.length - 1].date, type: 1 };
  const chartWidth: number = 0;
  const stockName: string = instrumentName;

  chartData.map((el) => {
    dataInView.push([el.date, el.value]);
  });

  const chartOpt = ChartHelper.getTimelineChartOptions({
    tooltipValueType: VALUE_TYPE.MONEY,
    zoom: true,
    portfolioCurrencyFormatter,
  });

  const mergedOpt: any = {
    grid: {
      y2: 40,
      x2: 70,
      containLabel: false,
    },
    yAxis: {
      axisLabel: {
        fontSize: 9,
        formatter(value: number): string {
          return FormatHelper.formatCurrencyShort(value, baseCurrencySymbol);
        },
      },
    },
    xAxis: {
      interval: 3600 * 1000 * 24, // 1 day
      axisTick: {
        show: false,
      },
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
      },
    },
    series: [
      {
        ...ChartHelper.getLineSeriesOptionsWithRadialGradient(),
        name: stockName,
        data: dataInView,
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        filterMode: 'none',
      },
    ],
  };

  return merge({}, chartOpt, mergedOpt, getChangedChartOptions(dataInView, period, chartWidth));
}

function getChangedChartOptions(dataInView: LineData, period: IPeriod, chartWidth: number): echarts.EChartOption {
  const labelType: AxisLabelType = getLabelAxisType(dataInView, period);
  const filteredLabels = filterAxisLabels(dataInView, labelType);
  const canBeShown: number = chartWidth / LABEL_WIDTH;
  let showEveryIndex: number = Math.ceil(FormatHelper.round(filteredLabels.length / canBeShown, 10));

  if (!showEveryIndex) {
    showEveryIndex = 1;
  }

  return {
    xAxis: {
      axisLabel: {
        formatter(value: number): any {
          const formattedVal = moment(value);

          const from = moment(period.from ? period.from.toString() : '');
          const to = moment(period.to ? period.to.toString() : '');

          if (to.diff(from, 'days') < 19) {
            return formattedVal.format("DD'MMM'YY");
          } else if (to.diff(from, 'days') > 18 && to.diff(from, 'days') < 31) {
            return formattedVal.format('DD') === '01' || formattedVal.format('DD') === '15'
              ? formattedVal.format("MMM'YY")
              : '';
          } else if (to.diff(from, 'days') > 30 && to.diff(from, 'days') < 541) {
            return formattedVal.format('DD') === '01' ? formattedVal.format("MMM'YY") : '';
          } else {
            switch (formattedVal.format('M')) {
              case '1':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              case '4':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              case '7':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              case '10':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              default:
                return '';
            }
          }
        },
      },
    },
    dataZoom: [
      {
        minValueSpan: getZoomInterval(period) * showEveryIndex,
      },
    ],
  };
}

function getLabelAxisType(dataInView: LineData, period: IPeriod): AxisLabelType {
  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return AxisLabelType.MONTH;
    case PERIOD_TYPE.INCEPTION:
      return AxisLabelType.QUARTER;
    case PERIOD_TYPE.MANUAL:
      const firstDate = period.from ? period.from : undefined;
      const lastDate = period.to ? period.to : undefined;

      const diffMonths = moment(lastDate).diff(firstDate, 'months');
      if (!diffMonths) {
        return AxisLabelType.DAY;
      }
      if (diffMonths >= 1 && diffMonths <= 12) {
        return AxisLabelType.MONTH;
      }
      return AxisLabelType.QUARTER;
    default:
      return AxisLabelType.DAY;
  }
}

function getAxisLabel(value: string | number, labelType: AxisLabelType): string {
  const date: Moment = moment(value);
  switch (labelType) {
    case AxisLabelType.MONTH:
      return date.format("MMM'YY");
    case AxisLabelType.QUARTER:
      return `Q${date.quarter()}'${date.format('YY')}`;
    default:
      return date.format("DD MMM'YY");
  }
}

function getZoomInterval(period: IPeriod): number {
  const day: number = 3600 * 1000 * 24;
  const week: number = day * 7;
  const month: number = day * 31;
  const quarter: number = day * 92;

  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return month;
    case PERIOD_TYPE.INCEPTION:
      return quarter;
    case PERIOD_TYPE.MANUAL:
      if (!period.to || !period.from) {
        return week;
      }

      const diffMonths: number = moment(period.to).diff(period.from, 'months');
      const diffYears: number = moment(period.to).diff(period.from, 'years');
      if (!diffMonths) {
        return week;
      }
      if (diffYears <= 1) {
        return month;
      }
      return quarter;
    default:
      return week;
  }
}
