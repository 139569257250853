import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import s from './Notification.module.scss';
import { Portal } from '../../Portal';
import { ClickOutside } from '../../ClickOutside';
import { NotificationType } from '../../../models/NotifictionType';

interface IProps {
  notification: INotification;
  outSideClick: () => void;
}

const Notification: FunctionComponent<IProps> = ({ notification, outSideClick }) => {
  return (
    <Portal>
      <ClickOutside clickOutside={outSideClick}>
        <div
          className={cn(s.notificationWrapper, {
            [s.success]: notification.type === NotificationType.SUCCESS,
            [s.error]: notification.type === NotificationType.ERROR,
          })}
        >
          <div className={s.title}>{notification.type === NotificationType.SUCCESS ? 'Success!' : 'Error!'}</div>
          <div className={s.text}>{notification.text}</div>
        </div>
      </ClickOutside>
    </Portal>
  );
};

export default React.memo(Notification);
