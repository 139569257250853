import React, { Fragment, PureComponent } from 'react';
import cn from 'classnames';

import { PortfolioHeader, AllocationsList, ProfitChart } from '../../components';
import { ModelsList, ScenarioConfigContainer } from './components';

import { FormatHelper, VALUE_TYPE } from '../../../../services/utils/FormatHelper';

import { IAllocationDict } from '../../../../models/IAllocationDict';
import { IScenarioModel } from '../../../../services/reducers/ScenariosReducers';

import s from './Scenarios.module.css';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';

interface IProps {
  allocations: IAllocationDict;
  models: IScenarioModel[];
  profitChartData: IBarData[];
  totalPortfolio: number;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class Scenarios extends PureComponent<IProps> {
  render() {
    const { allocations, models, totalPortfolio, profitChartData, portfolioCurrencyFormatter } = this.props;

    return (
      <Fragment>
        <div className={s.settingsWrapper}>
          <PortfolioHeader title="Scenario analysis" />
          <div className={s.modelsListWrapper}>
            <ModelsList models={models} />
          </div>
          <div className={s.configWrapper}>
            <ScenarioConfigContainer />
          </div>
        </div>
        <div className={cn('widgets-block', s.profitWrapper)}>
          <div className="widgets-block__item">
            <header className="widgets-header">
              <h3>Profit and Loss</h3>
            </header>
            <div className="widgets-content widgets-content--row">
              <ProfitChart profitChartData={profitChartData} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
              <div className={s.totalPL}>
                <div className={s.totalImage} />
                <div>Total P&L</div>
                <div className={s.totalValue}>{FormatHelper.formatPercentage(totalPortfolio)}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="widgets-block">
          <div className="widgets-block__item">
            <h3>Portfolio structure changes</h3>
            <AllocationsList allocations={allocations} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Scenarios;
