import React, { FunctionComponent } from 'react';
import notesPic from '../../../../../../../static/images/ic-info.svg';

const NotesCell: FunctionComponent = () => {
  return (
    <div>
      <a data-tip={'true'} data-for={'row'}>
        <img src={notesPic} style={{ width: 14, height: 14 }} />
      </a>
    </div>
  );
};

export default React.memo(NotesCell);
