import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';

const INITIAL_STATE: ILoadingPeriodDataState<IBarData[]> = {
  ...ReduxHelper.createInitialState([]),
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const profitAttributionReducer = ReduxHelper.createHttpRequestWithPeriodReducer<ILoadingPeriodDataState<IBarData[]>>(
  INITIAL_STATE,
  DashboardActions.PROFIT_ATTRIBUTION_REQUEST,
  DashboardActions.PROFIT_ATTRIBUTION_SUCCESS,
  DashboardActions.PROFIT_ATTRIBUTION_ERROR,
  DashboardActions.SET_PROFIT_ATTRIBUTION_PERIOD,
  DashboardActions.PROFIT_ATTRIBUTION_RESET
);

export default profitAttributionReducer;
