import * as React from 'react';
import s from './PercentileHorizontal.module.scss';
import { useEffect, useState } from 'react';

interface IProps {
  percent: number;
  color: string;
  loaded: boolean;
}

const PercentileHorizontal = ({ percent, color }: IProps) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    animatePercentage();
  }, [currentPercentage]);

  const animatePercentage = () => {
    if (currentPercentage < percent) {
      setTimeout(() => setCurrentPercentage(currentPercentage + 0.1), 125);
    }
  };

  const renderPercentItems = () => {
    const items = [];
    const percentage = currentPercentage * 10;
    let i = 0;
    while (i < 10) {
      items.push(
        <div
          key={'horizontal-' + i}
          style={{ backgroundColor: i < percentage ? color : '#37383B' }}
          className={s.percentItem}
        />
      );
      i++;
    }

    return items;
  };

  return (
    <div className={s.percentBar}>
      {renderPercentItems()}
      <span>{(percent * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })}%-ile</span>
    </div>
  );
};

export default PercentileHorizontal;
