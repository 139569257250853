import { Dispatch } from 'redux';

import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';
import { PeriodHelper } from '../utils/PeriodHelper';

import { IActionPayload } from '../../models/redux/IActionPayload';

import { LineData } from '../../models/LineData';

class CreditActions {
  static readonly COUPONS_REQUEST = 'COUPONS_REQUEST';
  static readonly COUPONS_SUCCESS = 'COUPONS_SUCCESS';
  static readonly COUPONS_ERROR = 'COUPONS_ERROR';
  static readonly COUPONS_RESET = 'COUPONS_RESET';

  static readonly MATURITY_REQUEST = 'MATURITY_REQUEST';
  static readonly MATURITY_SUCCESS = 'MATURITY_SUCCESS';
  static readonly MATURITY_ERROR = 'MATURITY_ERROR';
  static readonly MATURITY_RESET = 'MATURITY_RESET';
  static readonly SET_MATURITY_PERIOD = 'SET_MATURITY_PERIOD';

  static readonly REPAYMENTS_REQUEST = 'REPAYMENTS_REQUEST';
  static readonly REPAYMENTS_SUCCESS = 'REPAYMENTS_SUCCESS';
  static readonly REPAYMENTS_ERROR = 'REPAYMENTS_ERROR';
  static readonly REPAYMENTS_RESET = 'REPAYMENTS_RESET';

  static fetchCoupons(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('credit.coupons'),
        data: {
          portfolioId,
        },
      },
      CreditActions.COUPONS_REQUEST,
      CreditActions.COUPONS_SUCCESS,
      CreditActions.COUPONS_ERROR
    );
  }

  static fetchMaturityData(portfolioId: string, period: IPeriod) {
    return ReduxHelper.createHttpRequestActionBundle<{ data: LineData }>(
      {
        url: getApiUrl('credit.maturity'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
        },
      },
      CreditActions.MATURITY_REQUEST,
      CreditActions.MATURITY_SUCCESS,
      CreditActions.MATURITY_ERROR
    );
  }

  static fetchRepaymentsData(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('credit.repayments'),
        data: {
          portfolioId,
        },
      },
      CreditActions.REPAYMENTS_REQUEST,
      CreditActions.REPAYMENTS_SUCCESS,
      CreditActions.REPAYMENTS_ERROR
    );
  }

  static setMaturityPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: CreditActions.SET_MATURITY_PERIOD,
      payload: period,
    };
  }

  static resetCoupons(): IActionPayload {
    return {
      type: CreditActions.COUPONS_RESET,
    };
  }

  static resetMaturity(): IActionPayload {
    return {
      type: CreditActions.MATURITY_RESET,
    };
  }

  static resetRepayments(): IActionPayload {
    return {
      type: CreditActions.REPAYMENTS_RESET,
    };
  }

  static resetCreditData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(CreditActions.resetCoupons());
      dispatch(CreditActions.resetMaturity());
      dispatch(CreditActions.resetRepayments());
    };
  }
}

export default CreditActions;
