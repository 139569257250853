import { useTypedSelector } from './useTypedSelector';
import { sendRequest } from './useApi';
import React from 'react';
import moment from 'moment';

type CurrencyPair = { fromCurrencyName?: string; toCurrencyName?: string };
type NaturalOrConventionRate =
  | { naturalRate: number; conventionRate?: undefined }
  | { naturalRate?: undefined; conventionRate: number | null };
type FxSearchPayload = {
  fromCurrency: ICurrency;
  toCurrency: ICurrency;
  firstFxRate: number;
  secondFxRate: number;
  fromToFxRate: number;
};

export const useFx = () => {
  const currencies = useTypedSelector((state) => state.currency.data);

  return React.useCallback(
    ({ fromCurrencyName, toCurrencyName }: CurrencyPair) => {
      const getCurrency = (code?: string) => currencies?.find((currency) => currency.name === code);
      const getHierarchy = (currency?: ICurrency) => currency?.hierarchy ?? 1000000;

      const fromCurrency = getCurrency(fromCurrencyName);
      const toCurrency = getCurrency(toCurrencyName);

      const fromHierarchy = getHierarchy(fromCurrency);
      const toHierarchy = getHierarchy(toCurrency);

      const isConvention = toHierarchy > fromHierarchy;

      const conventionFirstCurrency = isConvention ? fromCurrencyName : toCurrencyName;
      const conventionSecondCurrency = isConvention ? toCurrencyName : fromCurrencyName;

      function naturalToConvention(naturalRate: number) {
        return isConvention ? naturalRate : Math.round(100000 / naturalRate) / 100000;
      }

      function conventionToNatural(conventionRate: number) {
        return isConvention ? conventionRate : Math.round(100000 / conventionRate) / 100000;
      }

      function getDescription({ naturalRate, conventionRate }: NaturalOrConventionRate) {
        if (fromCurrencyName === toCurrencyName || !toCurrencyName || !fromCurrencyName) {
          return '';
        }
        let rate: any = conventionRate;
        if (typeof naturalRate !== 'undefined') {
          rate = naturalToConvention(naturalRate);
        }

        if (typeof rate === 'undefined') {
          return '';
        }
        if (typeof rate === 'number') {
          rate = rate.toLocaleString(undefined, { maximumFractionDigits: 4 });
        }
        return `1 ${conventionFirstCurrency} ~ ${rate} ${conventionSecondCurrency}`;
      }

      function getConversionString(
        { naturalRate, conventionRate }: NaturalOrConventionRate,
        fromAmount: number = 1,
        truncate: boolean = true
      ) {
        let rate: any = conventionRate || 1;
        const digits = truncate ? 0 : 6;

        if (typeof naturalRate !== 'undefined') {
          rate = naturalToConvention(naturalRate);
        }
        if (fromCurrencyName === toCurrencyName) {
          return `${format(isConvention ? fromAmount : fromAmount / rate, digits)} ${conventionFirstCurrency}`;
        }

        if (!!conventionSecondCurrency) {
          return `${format(isConvention ? fromAmount : fromAmount / rate, digits)} ${conventionFirstCurrency} / ${format(
            isConvention ? fromAmount * rate : fromAmount,
            digits
          )} ${conventionSecondCurrency}`;
        }
        return '';
      }

      function getRate(tradeTime: string): Promise<FxSearchPayload | null> {
        if (!!fromCurrencyName && !!toCurrencyName && tradeTime) {
          const time = moment(tradeTime).format('YYYY-MM-DD');
          return sendRequest<FxSearchPayload>(
            `/api/v1/fx-rate?from=${fromCurrencyName}&to=${toCurrencyName}&date=${time}`
          );
        }
        return Promise.resolve(null);
      }

      return {
        isConvention,

        fromCurrency,
        fromHierarchy,
        toCurrency,
        toHierarchy,

        naturalToConvention,
        conventionToNatural,
        getDescription,
        getConversionString,
        getRate,
      };

    },
    [currencies]
  );
};

function format(amount: number, maximumFractionDigits: number = 0) {
  return amount.toLocaleString(undefined, { maximumFractionDigits });
}
