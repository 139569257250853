class CapabilitiesHelper {
  // * Determines whether or not the current user has appropriate permissions to perform a certain type of trade
  static isTradeTypeAllowed = (tradeType: string, userCapabilities: IUserCapabilities) => {
    // Should be expanded to respect startDate and endDates
    return userCapabilities.capabilities.some((capability) => getTransactionType(capability.key) === tradeType);
  };

  // * Determines whether or not the current user has appropriate permissions to perform a certain action
  static isActionAllowed = (action: string, userCapabilities?: IUserCapabilities) => {
    if (typeof userCapabilities === 'undefined') {
      return false;
    }
    return userCapabilities?.actions && userCapabilities?.actions.indexOf(action) > -1;
  };

  static Actions = {
    ADD_PORTFOLIO: 'ADD_PORTFOLIO',
    SHARE_PORTFOLIO: 'SHARE_PORTFOLIO',
    DELETE_PORTFOLIO: 'DELETE_PORTFOLIO',
    TRANSFER_PORTFOLIO: 'TRANSFER_PORTFOLIO',
    ADD_TRADE: 'ADD_TRADE',
    EDIT_TRADE: 'EDIT_TRADE',
    ADD_REALASSET: 'ADD_REALASSET',
    ADD_OWNER: 'ADD_OWNER',
    ADD_MANAGER: 'ADD_MANAGER',
    ADD_CUSTOMER: 'ADD_CUSTOMER',
    LIST_USERS: 'LIST_USERS',
    LIST_ACCOUNTS: 'LIST_ACCOUNTS',
    EDIT_USER: 'EDIT_USER',
    MANAGE_OTC_INSTRUMENT: 'MANAGE_OTC_INSTRUMENT',
    MANAGE_OTC_USER: 'MANAGE_OTC_USER',
    VIEW_RISK: 'VIEW_RISK',
    VIEW_SCENARIOS: 'VIEW_SCENARIOS',
    ASSIGN_WORKGROUP: 'ASSIGN_WORKGROUP',
    EXPORT_TRANSACTIONS: 'EXPORT_TRANSACTIONS',
  };
}

const getTransactionType = (key: string) => {
  const mapping: Record<string, string> = {
    EquityFunds: 'Equities',
    Options: 'EqOptions',
  };

  return mapping[key] ?? key;
};

export default CapabilitiesHelper;
