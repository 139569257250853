import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import { toast } from 'react-toastify';

import { IActionPayload } from '../../models/redux/IActionPayload';

import { UserStatus } from '../constants/constants';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../store';
import { getApiUrl } from '../constants/endpoints';
import { CognitoHelper } from '../utils/CognitoHelper';

export class AppActions {
  static LOGIN_REQUEST = 'LOGIN_REQUEST';
  static LOGIN_SUCCESS = 'LOGIN_SUCCESS';
  static LOGIN_ERROR = 'LOGIN_ERROR';

  static LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
  static LOGOUT_ERROR = 'LOGOUT_ERROR';

  static UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS';

  static UPDATE_SUBSCRIPTION_INFO: UpdateSubscriptionInfoAction['type'] = 'UPDATE_SUBSCRIPTION_INFO';
  static UPDATE_SUBSCRIPTION_PRODUCTS: SubscriptionProductAction['type'] = 'UPDATE_SUBSCRIPTION_PRODUCTS';

  static SET_NOTIFICATION = 'SET_NOTIFICATION';
  static CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

  static TOGGLE_CONTEXT_HELP = 'TOGGLE_CONTEXT_HELP';

  static authorise = () => (dispatch: ThunkDispatch<IRootState, undefined, AuthAction>) => {
    CognitoHelper.withAuthorisationToken().then(() => {
      axios
        .post(getApiUrl('account.authorise'))
        // .post(getApiUrl('account.authorise'))
        .then((response) => {
          const value: AccountStatus = response?.data.issueCategory || 'NOT_SET';
          dispatch({
            type: 'SET_ACCOUNT_STATUS',
            value,
          });
          // console.log({ response });
        })
        .catch((error) => {
          // toast.error('Error getting authorisation status', { toastId: 'auth', updateId: 'auth' });
          console.log({ error });
        });
    });
  };

  static requestSubscription = () => (
    dispatch: ThunkDispatch<IRootState, undefined, AuthAction | SubscriptionProductAction>
  ) => {
    axios
      .post(getApiUrl('account.subscribe'))
      .then((response) => {
        // console.log({ response });
        const stripeId = response.data?.stripeId || '';
        const accountId = response.data?.accountId || '';
        const availableProducts = response.data?.products || [];
        dispatch({
          type: AppActions.UPDATE_SUBSCRIPTION_INFO,
          payload: {
            stripeId,
            accountId,
          },
        });
        dispatch({
          type: AppActions.UPDATE_SUBSCRIPTION_PRODUCTS,
          payload: availableProducts,
        });
      })
      .catch((error) => {
        toast.error('Error getting subscription status', { toastId: 'auth', updateId: 'auth' });
        console.log({ error });
      });
  };

  static subscribe = (accountId: string, paymentMethodId: string, priceId: string) => (
    dispatch: ThunkDispatch<IRootState, undefined, AuthAction | SubscriptionProductAction>
  ) => {
    // console.log('GOING TO SUBSCRIBE');
    axios
      .put(getApiUrl('account.subscribe'), {
        accountId,
        paymentMethodId,
        priceId,
      })
      .then((response) => {
        // console.log('subscribed', { response });
        dispatch(AppActions.authorise());
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  static updateAuthStatus = (value: AuthStatus) => (
    dispatch: ThunkDispatch<IRootState, undefined, AuthStatusAction>
  ) => {
    dispatch({
      type: 'SET_AUTH_STATUS',
      value,
    });
  };

  static updateAccountInfo(user: IUser) {
    return {
      type: AppActions.UPDATE_ACCOUNT_INFO_SUCCESS,
      payload: {
        data: user,
      },
    };
  }

  static login(email: string, firstName: string, lastName: string, role: string, status: string, id: string) {
    mixpanel.identify(id);
    mixpanel.people.set_once({ $distinct_id: id, $created: new Date() });

    return {
      type: AppActions.LOGIN_SUCCESS,
      payload: {
        data: {
          email,
          firstName,
          lastName,
          role,
          status,
        },
      },
    };
  }

  static logout() {
    return {
      type: AppActions.LOGOUT_SUCCESS,
      payload: {
        data: {
          email: '',
          firstName: '',
          lastName: '',
          role: '',
          status: UserStatus.UNCONFIRMED,
        },
      },
    };
  }

  static showNotification(notification: INotification): IActionPayload<INotification> {
    return { type: AppActions.SET_NOTIFICATION, payload: notification };
  }

  static clearNotification(): IActionPayload {
    return { type: AppActions.CLEAR_NOTIFICATION };
  }

  static toggleContextHelp(): IActionPayload {
    return {
      type: AppActions.TOGGLE_CONTEXT_HELP,
    };
  }
}
