import { BoxShadowProperty, Color } from 'csstype';
import { DEFAULT_TOOLTIP_OPTIONS } from '../../../../../services/constants/charts';
import echarts from 'echarts/lib/echarts';
import { ChartHelper } from '../../../../../services/utils/ChartHelper';

type FormatterParam = echarts.EChartOption.Tooltip.Format;
type SliderMap = {
  priceSlider: number;
  volatilitySlider: number;
  yieldSlider: number;
  fxSlider: number;
}

type InstrumentSliderData = {
  beta: number;
  fxMultiplier: number;
  marketValueBase: number;
  name: string;
  priceMultiplier1: number;
  yieldMultiplier: number;
}

export function generateChartOptions(dataset: any[], currencyName: string, currencySymbol: string, sliders: SliderMap) {
  const chartColor: Color = '#6677cc';
  const BarColor1: Color = '#6677cc';
  const BarColor2: Color = '#ccc202';

  const BarColor3: Color = '#59abb3';
  const textColor: Color = '#a9b2d1';
  const textSize = 11;

  const tooltipShadow: BoxShadowProperty = '0 2px 4px 0 rgba(0,0,0,0.1)';
  const tooltipBorderRadius = 10;

  const splitLineColor: Color = '#3c4871';
  const splitAreaColors: [Color, Color] = ['rgba(0, 0, 0, 0.1)', 'rgba(28, 32, 46, 0.1)'];

  const legendColor: Color = '#a9b2d1';

  const chartData = dataset;

  const chartSeries: any = [];

  // DON'T CHANGE THE ORDER OF ELEMENTS BELOW!
  chartData.map((el) => {
    chartSeries.push([
      el.name,
      el.priceMultiplier1,
      el.fxMultiplier,
      el.yieldMultiplier,
      el.volatilityMultiplier,
      el.totalMarketValue,
      el.instruments,
    ]);
  });

  // console.log(sliders)
  // console.log({ sliders, dataset })

  const categories = chartSeries.map((value: any) => value[0]);
  const priceSeries = chartSeries.map((value: any) => value[1]);
  const fxSeries = chartSeries.map((value: any) => value[2]);

  const yieldSeries = chartSeries.map((value: any) => value[3]);
  const volatilitySeries = chartSeries.map((value: any) => value[4]);

  const format = (val: number) => `${currencySymbol}${val.toLocaleString(undefined, {maximumFractionDigits: 0})}`;

  const chartOptions = {
    legend: {
      show: false,
      data: ['Price', 'FX'],
      top: 0,
      right: 0,
      textStyle: {
        color: legendColor,
      },
    },
    grid: {},
    tooltip: {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (params: FormatterParam | FormatterParam[]): string => {
        let tooltipText = '';

        const textGen = (
          item: FormatterParam,
          instruments: (InstrumentSliderData | string)[] = [],
          priceValue: any,
          fxValue: any,
          yieldValue: any,
          volatilityValue: any
        ) => {
          // ToDo : Import the app-wide PortfolioCurrencyFormatter to format the money values properly
          const value = Array.isArray(item.value) ? item.value[0] : item.value;
          // const valueText = value; // portfolioCurrencyFormatter(value);;

          const serieMarker: string = ChartHelper.getTooltipLegend(item);
          const serieName = item.seriesName;

          // const priceMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: #d9bf41"></span>`;
          // const priceVal =
          //   priceValue === -0
          //     ? 0
          //     : priceValue.toLocaleString(undefined, {
          //         minimumFractionDigits: 0,
          //         maximumFractionDigits: 2,
          //         currency: currencyName,
          //       });

          const fxMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: #d9bf41"></span>`;
          const fxVal =
            fxValue === -0
              ? 0
              : fxValue.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  currency: currencyName,
                });

          const yieldMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: #59abb3"></span>`;
          const yieldVal =
            yieldValue === -0
              ? 0
              : yieldValue.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  currency: currencyName,
                });

          // const volatilityMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: #cc6f66"></span>`;
          // const volatilityVal =
          //   volatilityValue === -0
          //     ? 0
          //     : volatilityValue.toLocaleString(undefined, {
          //         minimumFractionDigits: 0,
          //         maximumFractionDigits: 2,
          //         currency: currencyName,
          //       });

          const assetVal = value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            currency: currencyName,
          });
          const totalMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: #6677cc"></span>`;
          const totalVal = (value + fxValue + yieldValue + volatilityValue).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            currency: currencyName,
          });

          const instrumentsText: any = [];

          instruments.map((i) => {
            const marker = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:6px;height:6px;background-color: #AAA"></span>`;
            const details = typeof i === "string" ? i : `${i.name}: (${format(i.marketValueBase)}, β${i.beta}, ${format((i.priceMultiplier1 * sliders.priceSlider) + (i.fxMultiplier * sliders.fxSlider) + (i.yieldMultiplier * sliders.yieldSlider))})`;
            instrumentsText.push(`${marker}${details}`);
          });

          tooltipText += `${serieMarker}${serieName}: ${currencySymbol + assetVal}
            <br>${fxMarker}Fx: ${currencySymbol + fxVal}
            <br>${yieldMarker}Yield: ${currencySymbol + yieldVal}
            <br>${totalMarker}Total: ${currencySymbol + totalVal}<br>
            <br>Positions:<br> ${instrumentsText.join('<br>')}
            `;

        };

        /*
        Removed from the tooltipText var above until Business confirm this is what they want
        <br>${yieldMarker}Yield: ${currencySymbol + yieldVal}
        <br>${volatilityMarker}Volatility: ${currencySymbol + volatilityVal}*/

        if (Array.isArray(params)) {
          const priceVal = params[0].value;
          const fxVal = params[1].value;
          const yieldVal = params[2].value;
          const volatilityVal = params[3].value;
          const instruments = chartSeries[params[0].dataIndex!];

          textGen(params[0], instruments[instruments.length - 1], priceVal, fxVal, yieldVal, volatilityVal);
        } else {
          // @ts-ignore
          textGen(params);
        }

        // ToDo: Old formatter implementation. Please DON"T remove for now.
        /*res += params[0].name;
        let total = 0;

        for (let i = 0, l = params.length; i < l; i++) {
          res +=
            '<br/>' +
            params[i].marker +
            params[i].seriesName +
            ':&nbsp;' +
            params[i].value.toLocaleString('en-UK', { currency: currencyName });

            // @ts-ignore
          total += params[i].value }
        res +=
          '<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total:&nbsp' + total.toLocaleString('en-UK', { currency: currencyName });*/
        return tooltipText;
      },
      backgroundColor: 'var(--text-white)',
      borderColor: 'var(--warm-grey)',
      borderWidth: 1,
      extraCssText: `border-radius:${tooltipBorderRadius}px;` + `box-shadow: ${tooltipShadow};}`,
      axisPointer: {
        type: 'shadow',
        lineStyle: {
          color: chartColor,
          width: 1,
          type: 'dashed',
        },
      },
    },
    xAxis: {
      data: categories,
      name: '',
      axisLabel: {
        show: true,
        color: textColor,
        fontSize: textSize,
        interval: 0,
        rotate: 22,
        height: 100,
      },
      rich: {
        a: {
          height: 100,
          borderWidth: 2,
          borderColor: 'red',
        },
      },
      axisLine: { onZero: true },
      splitArea: {
        show: false,
        areaStyle: { color: splitAreaColors },
      },
      show: true,
      splitLine: { show: false },
      type: 'category',
      scale: false,
      boundaryGap: true,
    },
    yAxis: [
      {
        // Left Axis
        axisLabel: {
          color: textColor,
          fontSize: textSize,
          formatter: (v: number) => {
            return v.toLocaleString('en-UK', { currency: currencyName });
          },
        },
        axisLine: { show: false },
        position: 'left',
        splitLine: {
          show: true,
          lineStyle: {
            color: splitLineColor,
          },
        },
        show: true,
        type: 'value',
      },
      {
        // Right Axis
        axisLabel: {
          color: textColor,
          fontSize: textSize,
        },
        axisLine: {
          show: false,
        },
        position: 'right',
        splitLine: {
          show: true,
          lineStyle: {
            color: splitLineColor,
          },
        },
        show: true,
        type: 'value',
      },
    ],
    visualMap: [
      {
        // @ts-ignore
        type: 'piecewise',
        show: false,
        seriesIndex: 0,
        pieces: [
          {
            gt: 0,
            color: '#6677cc',
          },
          {
            lte: 0,
            color: '#515fa3',
          },
        ],
      },
      {
        // @ts-ignore
        type: 'piecewise',
        show: false,
        seriesIndex: 1,
        pieces: [
          {
            gt: 0,
            color: '#c2b261',
          },
          {
            lte: 0,
            color: '#8f844b',
          },
        ],
      },
    ],

    series: [
      {
        name: 'Price',
        type: 'bar',
        stack: 'one',
        itemStyle: {
          color: BarColor1,
        },
        barWidth: 30,
        data: priceSeries,
      },
      {
        name: 'FX',
        type: 'bar',
        stack: 'one',
        itemStyle: {
          color: BarColor2,
        },
        barWidth: 30,
        data: fxSeries,
      },
      {
        name: 'Yield',
        type: 'bar',
        stack: 'one',
        itemStyle: {
          color: BarColor3,
        },
        barWidth: 30,
        data: yieldSeries,
      },
      {
        name: 'Volatility',
        type: 'bar',
        stack: 'one',
        itemStyle: {
          color: '#cc6f66',
        },
        barWidth: 30,
        data: volatilitySeries,
      },
    ],
  };

  return chartOptions;
}
