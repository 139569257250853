import { IRouteConfig } from '../models/IRouteConfig';
import {
  InstrumentsUploadContainer,
  PortfolioContainer,
  PortfoliosManagerContainer,
  RecommendationsContainer,
  RiskContainer,
  RiskScenariosContainer,
  ScenariosContainer,
  UserSettingsContainer,
  WorkGroupManagerContainer,
} from '../scenes';
import { AssetDashboardContainer, DashboardPortfolioContainer } from '../scenes/Portfolio/scenes/Dashboard/scenes';
import { PnlBreakdownDetailContainer } from '../scenes/Portfolio/scenes/Dashboard/scenes/AssetsDashboard/scenes/Equilty/scenes/PnlBreakdownDetail';
import { SnapshotEditContainer, SnapshotInfoContainer } from '../scenes/Portfolio/scenes/Snapshot/scenes';
import { PATHS } from './paths';
import UnlistedContainer from '../scenes/Unlisted/UnlistedContainer';
import UnlistedModifyContainer from '../scenes/Unlisted/UnlistedModify/UnlistedModifyContainer';
import UniversalUploadHome from '../scenes/Portfolio/scenes/Snapshot/scenes/UniversalUpload/UniversalUploadHome';
import UniversalUploadTriage from '../scenes/Portfolio/scenes/Snapshot/scenes/UniversalUpload/UniversalUploadTriage/UniversalUploadTriage';
import { TwrContainer } from '../scenes/Portfolio/scenes/TWR';
import ReportGenerator from '../scenes/Portfolio/scenes/ReportGenerator/ReportGenerator';
import BacktestContainer from '../scenes/Portfolio/scenes/Risk/BacktestContainer';
import SensitivityContainer from '../scenes/Portfolio/scenes/Risk/SensitivityContainer';
import { AdminContainer } from '../scenes/Admin/AdminContainer';
import { PortfolioExplorer } from '../scenes/Portfolio/scenes/PortfolioExplorer/PortfolioExplorer';
import { PortfolioDashboard } from '../scenes/Portfolio/scenes/Dashboard/PortfolioDashboard/PortfolioDashboard';
import { Summary } from '../scenes/Portfolio/scenes/Dashboard/scenes/PortfolioDashboard/scenes/Summary';
import { DashboardNew } from '../scenes/Portfolio/scenes/DashboardNew/DashboardNew';
import Esg from '../scenes/Portfolio/scenes/Esg/Esg';
import PositionEntry from '../scenes/Portfolio/scenes/Snapshot/scenes/PositionEntry/PositionEntry';
import RiskAnalytics from '../scenes/Portfolio/scenes/RiskAnalytics/RiskAnalytics';
import { PnlExplorer } from '../scenes/Portfolio/scenes/PnlExplorer/PnlExplorer';
import EmptyPortfolio from '../scenes/Portfolio/scenes/EmptyPortfolio/EmptyPortfolio';
import CryptoIncome from '../scenes/Portfolio/scenes/Dashboard/scenes/AssetsDashboard/scenes/Crypto/CryptoIncome';
import { InsightsBrowser } from '../scenes/Portfolio/scenes/InsightsBrowser/InsightsBrowser';

export const SCENE_ROUTES: IRouteConfig[] = [
  {
    component: UserSettingsContainer,
    path: PATHS.account.settings.path,
    title: "",
    routes: [
      {
        redirect: {
          from: PATHS.account.path,
          to: PATHS.account.settings.path,
        },
      },
    ],
  },
  {
    component: WorkGroupManagerContainer,
    path: PATHS.workGroupsManager.path,
    title: "Workgroups",
  },
  {
    component: PortfoliosManagerContainer,
    path: PATHS.portfoliosManager.path,
    title: "Portfolio List",
  },
  {
    component: PortfolioContainer,
    path: PATHS.portfolio.path,
    title: "Portfolio",
    exact: false,
    routes: [
      {
        component: EmptyPortfolio,
        path: PATHS.portfolio.empty.path,
        title: "Empty Portfolio",
      },
      {
        component: RecommendationsContainer,
        path: PATHS.portfolio.recommendations.path,
        title: "Recommendations",
      },
      {
        component: ReportGenerator,
        path: PATHS.portfolio.reportGenerator.path,
        title: "Reports",
      },
      {
        component: RiskContainer,
        path: PATHS.portfolio.risk.path,
        title: "Historic Risk Analysis",
      },
      {
        component: RiskAnalytics,
        path: PATHS.portfolio.risk.analytics.path,
        title: "Risk Analytics",
      },
      {
        component: RiskScenariosContainer,
        path: PATHS.portfolio.risk_scenarios.path,
        title: "Sensitivity Analysis",
      },
      {
        component: BacktestContainer,
        path: PATHS.portfolio.backtest.path,
        title: "Backtest",
      },
      {
        component: SensitivityContainer,
        path: PATHS.portfolio.sensitivity.path,
        title: "Risk vs Return",
      },

      {
        component: PortfolioDashboard,
        path: PATHS.portfolio.pnl.path,
        title: "Portfolio",
      },
      // {
      //   component: PortfolioDashboard,
      //   path: PATHS.portfolio.dashboard.path,
      // },
      {
        component: PortfolioExplorer,
        path: PATHS.portfolio.explorer.path,
        title: "Allocation",
      },
      {
        component: PnlExplorer,
        path: PATHS.portfolio.pnlExplorer.path,
        title: "P&L Explorer",

      },
      {
        component: Esg,
        path: PATHS.portfolio.esgPage.path,
        title: "ESG",
      },
      {
        component: DashboardNew,
        path: PATHS.portfolio.dashboardNew.path,
        title: "Dashboard",
      },
      {
        component: Summary,
        path: PATHS.portfolio.performance.path,
        title: "Performance Summary",
      },
      // {
      //   component: DashboardPortfolioContainer,
      //   path: PATHS.portfolio.dashboard.path,
      //   exact: true,
      // },
      {
        component: TwrContainer,
        path: PATHS.portfolio.twr.path,
        title: "TWR",
      },
      {
        component: AssetDashboardContainer,
        path: PATHS.portfolio.dashboard.classDashboard.path,
        title: "Asset Dashboard",
      },
      {
        component: AssetDashboardContainer,
        path: [PATHS.portfolio.structure.path, PATHS.portfolio.income.path, PATHS.portfolio.fees.path],
        title: "Portfolio",
      },
      {
        component: CryptoIncome,
        path: PATHS.portfolio.income.crypto.path,
        title: "Crypto Income",
      },

      // {
      //   component: AssetDashboardContainer,
      //   path: PATHS.portfolio.dashboard.subClassDashboard.path,
      // },
      {
        component: PnlBreakdownDetailContainer,
        path: PATHS.portfolio.dashboard.breakdownDetailDashboard.path,
        title: "P&L Breakdown",
      },
      {
        component: ScenariosContainer,
        path: PATHS.portfolio.scenarios.path,
        title: "Scenarios",
      },
      {
        component: InsightsBrowser,
        path: PATHS.portfolio.insightsBrowser.path,
        title: "Insights",
      },
      {
        component: UnlistedContainer,
        path: PATHS.instruments.path,
        exact: false,
        routes: [
          {
            component: UnlistedModifyContainer,
            path: PATHS.instruments.addInstrument.path,
            exact: true,
            title: "Add Instrument",
          },
          {
            component: UnlistedModifyContainer,
            path: PATHS.instruments.editInstrument.path,
            exact: true,
            title: "Edit Instrument",
          },
          {
            component: InstrumentsUploadContainer,
            path: PATHS.instruments.uploadInstrument.path,
            exact: true,
            title: "Add Instrument",
          },
        ],
      },
      {
        component: AdminContainer,
        path: [PATHS.admin.account.path, PATHS.admin.unlisted.path, PATHS.admin.users.path],
        exact: false,
        title: "Admin",
      },

      {
        path: PATHS.portfolio.snapshot.path,
        routes: [
          {
            component: SnapshotInfoContainer,
            path: PATHS.portfolio.snapshot.path,
            title: "Positions",
          },
          {
            component: SnapshotInfoContainer,
            path: PATHS.portfolio.snapshot.positions.path,
            title: "Positions",
          },
          {
            component: SnapshotInfoContainer,
            path: PATHS.portfolio.snapshot.transactions.path,
            title: "Transactions",
          },
          {
            component: SnapshotEditContainer,
            path: PATHS.portfolio.snapshot.edit.path,
            title: "Add Transactions",
            exact: false,
          },
          {
            component: UniversalUploadHome,
            path: PATHS.portfolio.snapshot.upload.path,
            title: "Import transactions",
            exact: true,
          },
          {
            component: UniversalUploadTriage,
            path: PATHS.portfolio.snapshot.upload.process,
            title: "Prepare Upload",
            exact: true,
          },
          {
            component: PositionEntry,
            path: PATHS.portfolio.snapshot.positions.add.path,
            title: "Add Positions",
            exact: true,
          },
        ],
      },
    ],
  },
  {
    redirect: {
      to: PATHS.workGroupsManager.path,
    },
  },
];
