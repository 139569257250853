import get from 'lodash/get';
import { toast } from 'react-toastify';
import { history } from '../../store/history';
import { handle404 } from './handle404';

export function errorStatusHandler(e: any, store?: any) {
  const status = get(e, 'response.status');
  const message = get(e, 'response.data.error.message');
  switch (status) {
    case 401:
      store?.dispatch({ type: 'LOGOUT_SUCCESS' });
      store?.dispatch({ type: 'PORTFOLIOS_LIST_RESET' });
      break;
    case 403: {
      if (message) {
        toast.error(message);
      } else {
        history.push('/');
        toast.error('403 - operation not allowed', {
          toastId: 'operationNotAllowed',
        });
      }
      break;
    }
    case 404: {
      handle404(e);
      break;
    }
    default:
      toast.error(
        message ||
          `Sorry something has gone wrong, we've logged this and will investigate - please try refreshing your browser`,
        {
          toastId: 'unidentifiedError',
        }
      );

      break;
  }
  return e;
}
