import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import useApi from '../../../../../../../../services/hooks/useApi';

interface IProps {
  label: string;
  hint?: string;
  quantityDecimals?: number;
  sourceId?: ISourceId;
  tradeTime?: string;
  editMode?: boolean;
}

const Price: FC<IProps> = ({ quantityDecimals = 3, label, hint, sourceId, tradeTime, editMode }) => {
  const priceSearchEnabled = !!(
    editMode &&
    typeof sourceId?.sourceId === 'string' &&
    !!sourceId?.sourceData &&
    !!tradeTime &&
    sourceId?.sourceId !== 'undefined'
  );

  const priceData = useApi(
    `/api/v1/price?sourceId=${sourceId?.sourceId}&sourceData=${sourceId?.sourceData}&date=${tradeTime}`,
    {},
    {
      enabled: priceSearchEnabled,
    }
  );

  return (
    <FormFieldWrapper label={label} required={true} className={s.minField}>
      <Field
        placeholder="0"
        name="price"
        type="text"
        theme="inverse"
        component={renderInput}
        className="input--small"
        hint={hint}
        normalize={(value: number) => positiveNumber(floatNumberV2(value, quantityDecimals)).toString()}
      />
      {editMode && typeof priceData?.data === 'number' && (
        <div className={s.closePrice}>
          Close: {positiveNumber(floatNumberV2(priceData?.data, quantityDecimals)).toString()}
        </div>
      )}
    </FormFieldWrapper>
  );
};

export default Price;
