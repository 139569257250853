import React, { CSSProperties, Dispatch, SetStateAction } from 'react';

import { Table, Card } from '@iliotech/storybook';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import s from './TopBottomTable.module.scss';
import InstrumentImage from '../../../../../../components/InstrumentImage/InstrumentImage';
import { explorerDecimalNumberRenderer } from '../../../PortfolioExplorer/utils/explorerCurrencyRenderer';

interface IOwnProps {
  data: any[];
  loading?: boolean;
  title?: string;
  onSelectPosition: (p: IPortfolioSnapshot | null) => void;
  style?: CSSProperties;
}

const amountRenderer = (
  colored: boolean = false,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
  blueShorts = false
) => (rowData: any, dataKey: string) => {
  const val = rowData[dataKey];
  if (typeof val !== 'number') {
    return val;
  }

  let color;
  if (colored) {
    color = val < 0 ? 'var(--alert)' : val === 0 ? undefined : 'var(--text-success-color)';
  } else if (blueShorts) {
    color = val < 0 ? 'var(--light-blue)' : undefined;
  }
  return (
    <div
      style={{
        color,
        textAlign: 'right',
      }}
    >
      {val.toLocaleString(undefined, { maximumFractionDigits, minimumFractionDigits })}
    </div>
  );
};

const _nameRenderer = (rowData: any) => {
  return (
    <div style={{ flexDirection: 'row', display: 'flex' }}>
      <InstrumentImage size={15} instrument={rowData} />
      <div>{rowData.name}</div>
    </div>
  );
};

const COLUMNS: ColumnProps[] = [
  { label: 'Name', dataKey: 'name', cellRenderer: _nameRenderer, flexGrow: 1 },
  { label: 'Mkt Value', dataKey: 'amount', cellRenderer: amountRenderer(false, 0, 0, true), align: 'right', width: 85 },
  { label: 'P&L', dataKey: 'profitAndLoss', cellRenderer: amountRenderer(true), align: 'right', width: 85 },
  {
    label: 'Price',
    dataKey: 'currentPriceNative',
    cellRenderer: explorerDecimalNumberRenderer,
    align: 'right',
    width: 85,
  },
  {
    label: 'Price vs Cost',
    dataKey: 'priceGainPercentage',
    cellRenderer:
      // amountRenderer(true, 1, 1),
      (rowData: IPortfolioSnapshot) => {
        let val = rowData.priceGainPercentage ?? 0

        if ((rowData.amount ?? 0) < 0) {
          val = -val;
        }

        return (
          <div
            style={{
              color: val < 0 ? 'var(--alert)' : val === 0 ? undefined : 'var(--text-success-color)',
              textAlign: 'right',
            }}
          >
            {val === 0 || typeof val === 'undefined'
              ? ''
              : val.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%'}
          </div>
        );
      },
    align: 'right',
    width: 90,
  },
  // { label: 'Change', dataKey: 'priceMove', cellRenderer: _priceMoveRenderer, align: 'right', width: 70 },
  // { label: 'Ch %', dataKey: 'priceMovePercentage', cellRenderer: percentageRenderer(true), align: 'right', width: 60 },
];

export const TopBottomTable = ({ data, title, loading, onSelectPosition, style }: IOwnProps) => {
  return (
    <Card style={{ padding: '0.5rem', ...style }}>
      {!!title && <div>{title}</div>}
      <Table
        rowClassName={s.clickable}
        columns={COLUMNS}
        data={data ?? []}
        loading={loading}
        headerHeight={30}
        rowHeight={25}
        autoHeight={true}
        onRowClick={onSelectPosition}
        minHeight={155}
        // height={155}
      />
      {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
    </Card>
  );
};
