import * as React from 'react';
import { ICurrencyFormatter } from '../../services/selectors/portfolio';

const SharedTooltip = (props: any) => {
  const { points, portfolioCurrencyFormatter, serverData, baseCurrency, onSelectPosition } = props;

  const data = serverData as IPortfolioSnapshot[];

  return (
    <div>
      {points.map((point: any) => {
        const foundItem = data.find((item) => item.name === point.category);
        return (
          <div
            key={point.key}
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              onSelectPosition(foundItem);
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 5,
                  backgroundColor: point.point.color,
                  marginRight: 3.5,
                }}
              />
              <span> {point.category} </span>
            </div>
            {foundItem && (
              <>
                <div>Value: {portfolioCurrencyFormatter(foundItem.amount)}</div>
                <div>P&L: {portfolioCurrencyFormatter(foundItem.profitAndLoss)}</div>
                <div>Custodian: {foundItem.custodian}</div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const sharedTooltipRender = (
  context: any,
  portfolioCurrencyFormatter: ICurrencyFormatter,
  serverData: IPortfolioSnapshot[],
  baseCurrency: string,
  onSelectPosition: any
) => (
  <SharedTooltip
    {...context}
    portfolioCurrencyFormatter={portfolioCurrencyFormatter}
    serverData={serverData}
    onSelectPosition={onSelectPosition}
    baseCurrency={baseCurrency}
  />
);

export default sharedTooltipRender;
