import { ReduxHelper } from '../utils/ReduxHelper';

import { IndustryActions } from '../actions/IndustryActions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

export interface IIndustryState extends ILoadingDataState {
  data: IIndustry[];
}

const INITIAL_STATE: IIndustryState = ReduxHelper.createInitialState<IIndustry[]>([]);

const industryReducer = ReduxHelper.createHttpRequestReducer<IIndustryState>(
  INITIAL_STATE,
  IndustryActions.INDUSTRY_REQUEST,
  IndustryActions.INDUSTRY_SUCCESS,
  IndustryActions.INDUSTRY_ERROR
);

export default industryReducer;
