import React, { PureComponent } from 'react';
import cn from 'classnames';

import { BasicScenarioSettings, HistoricEvents } from './components';
import { IScenarioBasicConfig } from '../../models/IScenarioBasicConfig';
import { SETTINGS_VIEW_TYPE } from '../../services/constants/constants';
import { Button } from '../UIWidgets/Button';

import s from './ScenarioConfig.module.scss';

enum ConfigType {
  BASIC_SCENARIOS,
  HISTORIC_EVENTS,
}

interface IProps {
  basicSettings: IScenarioBasicConfig;
  slidersTypeView: SETTINGS_VIEW_TYPE;
  historyEvent: string | null;
  historyPeriod: number;
  onSettingsChanged: (settings: IScenarioBasicConfig) => void;
  onEventSelect: (event: string) => void;
  onEventPeriodChanged: (period: number) => void;
}

interface IState {
  selectedConfig: ConfigType;
}

class ScenarioConfig extends PureComponent<IProps, IState> {
  static readonly defaultProps: Partial<IProps> = {
    slidersTypeView: SETTINGS_VIEW_TYPE.ROW,
  };
  readonly state: IState = {
    selectedConfig: ConfigType.BASIC_SCENARIOS,
  };

  showConfigBlock(configType: ConfigType) {
    this.setState({ selectedConfig: configType });
  }

  render() {
    const { selectedConfig } = this.state;
    const {
      slidersTypeView,
      basicSettings,
      onSettingsChanged,
      historyEvent,
      historyPeriod,
      onEventSelect,
      onEventPeriodChanged,
    } = this.props;

    return (
      <div className={s.scenarioBlocks}>
        <header className={s.scenarioBlockHeader}>
          <h3>Configuration</h3>
          <div className={s.configTypes}>
            <Button
              active={selectedConfig === ConfigType.BASIC_SCENARIOS}
              size="small"
              className={cn(s.configTypeBtn, {
                [s.active]: selectedConfig === ConfigType.BASIC_SCENARIOS,
              })}
              onClick={() => this.showConfigBlock(ConfigType.BASIC_SCENARIOS)}
            >
              Basic scenarios
            </Button>
            <Button
              active={selectedConfig === ConfigType.HISTORIC_EVENTS}
              size="small"
              className={cn(s.configTypeBtn, {
                [s.active]: selectedConfig === ConfigType.HISTORIC_EVENTS,
              })}
              onClick={() => this.showConfigBlock(ConfigType.HISTORIC_EVENTS)}
            >
              Historic events
            </Button>
          </div>
        </header>
        <div className={cn(s.scenarioBlockContent, s.configItems)}>
          {selectedConfig === ConfigType.BASIC_SCENARIOS && (
            <BasicScenarioSettings
              settingsValues={basicSettings}
              viewType={slidersTypeView}
              onSettingsChanged={onSettingsChanged}
            />
          )}
          {selectedConfig === ConfigType.HISTORIC_EVENTS && (
            <HistoricEvents
              selectedEvent={historyEvent}
              period={historyPeriod}
              onEventSelect={onEventSelect}
              onPeriodChange={onEventPeriodChanged}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ScenarioConfig;
