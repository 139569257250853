import ReactEcharts from 'echarts-for-react';
import React, { Fragment, PureComponent } from 'react';
import { ChartLegend } from '../../../../components/ChartLegend';
import { ILegendItemProps } from '../../../../components/ChartLegend/components/LegendItem';

import { BarType } from '../../../../services/constants/charts';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { getChartOptions } from './chartOptions';
import { IOptionType } from '../../../../components/UIWidgets/Select/Select';
import { FormatHelper } from '../../../../services/utils/FormatHelper';

interface IProps {
  chartData: IBarData[];
  barWidth?: number;
  hideLegend?: boolean | undefined;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency?: IOptionType<any>;
  selectedPeriod?: IPeriod;
  dateFormat?: string;
  assetClass?: string;
}

class PastNextChart extends PureComponent<IProps> {
  render() {
    const { chartData, portfolioCurrencyFormatter, assetClass } = this.props;
    const selectedCurrencyName = this.props.selectedCurrency ? this.props.selectedCurrency.value : 'All';

    const legend = this._getLegend(chartData, portfolioCurrencyFormatter, selectedCurrencyName, '#832161');

    return (
      <Fragment>
        {!this.props.hideLegend ? <ChartLegend legend={legend} align="left" /> : null}
        <ReactEcharts
          option={
            this.props.selectedPeriod
              ? getChartOptions(
                  chartData,
                  portfolioCurrencyFormatter,
                  this.props.barWidth,
                  selectedCurrencyName,
                  this.props.selectedPeriod,
                  undefined,
                  '#832161'
                )
              : getChartOptions(
                  chartData,
                  portfolioCurrencyFormatter,
                  this.props.barWidth,
                  selectedCurrencyName,
                  undefined,
                  this.props.dateFormat,
                  '#832161'
                )
          }
          notMerge={true}
          lazyUpdate={true}
        />
      </Fragment>
    );
  }

  private _getLegend(
    chartData: IBarData[],
    portfolioCurrencyFormatter: ICurrencyFormatter,
    selectedCurrencyName: string,
    longColor: string = '#6677cc'
  ): ILegendItemProps[] {
    const totalLongVal = chartData.map((item) => {
      return item.data.reduce<number>((acum: number, cur: any) => {
        return acum + cur.longTotal;
      }, 0);
    });

    const totalShortVal = chartData.map((item) => {
      return item.data.reduce<number>((acum: number, cur: any) => {
        return acum + cur.shortTotal;
      }, 0);
    });

    return [
      {
        name: 'Long Positions',
        hatching: false,
        value:
          selectedCurrencyName === 'All'
            ? portfolioCurrencyFormatter(totalLongVal[0])
            : FormatHelper.formatCurrencyShort(totalLongVal[0], selectedCurrencyName, 1),
        color: longColor,
      },
      {
        name: 'Short Positions',
        hatching: false,
        value:
          selectedCurrencyName === 'All'
            ? portfolioCurrencyFormatter(totalShortVal[0])
            : FormatHelper.formatCurrencyShort(totalShortVal[0], selectedCurrencyName, 1),
        color: '#3b4680',
      },
    ];
  }
}

export default PastNextChart;
