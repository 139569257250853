import React, { FC } from 'react';
import { IRootState } from '../../../../../../../../services/store';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { connect } from 'react-redux';
import s from '../../Transaction.module.scss';
import { renderDatePicker, renderInput } from '../../../../../../../../components/ReduxForm';
import cn from 'classnames';
import { SYSTEM_EPOCH } from '../../../../../../../../services/constants/constants';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { getCustodianOptions } from '../../../../../../../../services/selectors/snapshots';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import { IPortfolioTrade, TradeType } from '../../../../../../../../models/IPortfolioTrade';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { renderToggleBtns } from '../../components';
import OptionUnderlying from '../../components/ConnectedTransactionInputs/OptionUnderlying';
import Multiplier from '../../components/ConnectedTransactionInputs/Multiplier';
import Operation from '../../components/ConnectedTransactionInputs/Operation';
import Quantity from '../../components/ConnectedTransactionInputs/Quantity';
import Price from '../../components/ConnectedTransactionInputs/Price';
import TradeCost from '../../components/ConnectedTransactionInputs/TradeCost';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import Commission from '../../components/ConnectedTransactionInputs/Commission';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import Year from '../../components/ConnectedTransactionInputs/Year';
import OptionsExpirySelector from '../../components/ConnectedTransactionInputs/OptionsExpirySelector';
import _ from 'lodash';
import OptionsStrikeSelector from '../../components/ConnectedTransactionInputs/OptionsStrikeSelector';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';

import { formatFxConvention } from '../../../../../../../../services/utils/FXHelper';
import { getMonthName } from '../../../../../../../../services/utils/DateHelpers';
import SettlementOptions from '../../components/ConnectedTransactionInputs/SettlementOptions';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { getFxConversionLabel } from '../../helpers';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import dateFormat from 'dateformat';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import debounce from 'lodash.debounce';
import { sendRequest } from '../../../../../../../../services/hooks/useApi';
import { TRANSACTION_TYPES } from '../../../../../../../../services/mock/transactionTypes';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: IOptionFormValues;
  custodianOptions: Array<IOption<ICustodian>>;
  availableDates: Array<IOption<{ month: number; date: number }>>;
  availableStrikes: Array<IOption<IOptionIdentifier>>;
  selectedOption: IOptionFormValues['option'];
  snapshotFxRate: IFxRate | null;
  baseCurrencyCode?: string | null; // move to AssetEntryContainer
  optionsLoading?: boolean;
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  updateTradeType(data: IOption<TradeType>): void;
  updateOption(data: IOption<IOptionIdentifier> | null): void;
  updateExpiryDate(data: IOption<number> | null): void;
  // resetForm(data: any): void;
  updatePrice(data: number | null): void;
  updateMultiplier(data: number | null): void;
  updateFxRate(rate: number | null): void;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
  fetchPrice(sourceId: string, sourceData: string, tradeTime: string): AxiosPromise<number>;
  dynamicSearchOptions(
    underlyingSourceId: string,
    underlyingSourceData: string,
    date: string,
    optionType: string,
    year: number,
    code: string
  ): AxiosPromise<IPortfolioTrade[]>;
  dynamicSearchAssetByName(name: string): AxiosPromise<IPortfolioTrade[]>;
}

type FormUpdater = <T extends keyof IOptionFormValues>(field: T, data: IOptionFormValues[T] | null) => void;

export interface IOptionFormValues {
  tradeType: IOption<TradeType>;
  currency?: string;
  instrument: IOption<IAsset>;
  tradeTime: string;
  commission: number;
  operation: string;
  quantity: number;
  custodian: string | IOption<ICustodian> | any; // taken from legacy form
  underlyingPrice?: string | number;
  pointValue?: number;
  price: string;
  tradeCosts: string;
  notes: string;
  settlementOption: SettlementOption;
  type: 'CALL' | 'PUT';
  year: IOption<number>;
  strike: IOption<number>;
  option: IOption<IOptionIdentifier>;
  expiryDate: IOption<{ month: number; date: number }>;
  multiplier: string;
  fxRate: string;
}

type Props = IAssetEntryComponentProps<IOptionFormValues> &
  IMapStateToProps &
  IDispatchToProps &
  InjectedFormProps<IOptionFormValues>;

const OptionEntry: FC<Props> = ({
  formValues,
  editMode,
  dynamicSearchAssetByName,
  dynamicSearchOptions,
  custodianOptions,
  availableDates,
  availableStrikes,
  updateOption,
  updateExpiryDate,
  updateTempTrade,
  updateFormValue,
  editedUnconfirmedTrade,
  resetForm,
  updatePrice,
  updateMultiplier,
  updateFxRate,
  selectedOption,
  optionsLoading,
  fetchFxRate,
  baseCurrencyCode,
  snapshotFxRate,
  portfolioCurrencyFormatter,
  resetEditTrade,
  removeTrade,
  currencies,
  portfolioInfo,
  saveTrades,
  fetchTradeBlotter,
  savedTradesOrder,
  initialValues,
  fetchPrice,
  checkedOut,
}) => {
  const { instrument, type, tradeTime, year, option } = formValues || {};

  const { sourceData, sourceId } = instrument?.value?.sourceId || {};
  const code = (instrument?.value as any)?.aliasCode || '';

  const instrumentCurrencyCode = String(
    (instrument?.value?.currency ??
      instrument?.value?.valuationCurrency ??
      (typeof initialValues.currency === 'string' ? initialValues.currency : (initialValues.currency as any)?.value)) ||
      ''
  );

  // const pointValue = instrument?.value?.pointValue;

  const fx = useFx();

  const convention = fx({ fromCurrencyName: instrumentCurrencyCode, toCurrencyName: baseCurrencyCode ?? undefined });

  const defaultValues = React.useCallback<() => Partial<any>>(
    () => ({
      operation: TradeType.BUY.toUpperCase(),
      settlementOption: 'BASE',
      baseSettlementCurrency: 'BASE',
      custodian: custodianOptions.find((custodian) => custodian.value.default === true) || custodianOptions[0],
      tradeTime: dateFormat(new Date(), 'yyyy-mm-dd'),
      quantity: 0,
      type: 'CALL' as 'CALL',
    }),
    [custodianOptions]
  )();

  // * If the user changes one of the defining characteristics of the option, search for valid options to choose from
  React.useEffect(() => {
    const optionSearchParamsValid = type && tradeTime && sourceData && sourceId && !!year;
    if (optionSearchParamsValid && !(editMode && checkedOut)) {
      updateOption(null);
      updateExpiryDate(null);
      dynamicSearchOptions(sourceId, sourceData, tradeTime, type, year.value, code);
    }
  }, [type, tradeTime, sourceData, sourceId, year, editMode]);

  // * If the user picks a new expiry date, clear the selected option
  React.useEffect(() => {
    if (!editMode) {
      updateOption(null);
    }
  }, [formValues?.expiryDate]);

  // * Load FX Rate for the day of the trade
  React.useEffect(() => {
    if (!!String(instrumentCurrencyCode) && baseCurrencyCode && tradeTime && !editMode) {
      fetchFxRate(String(instrumentCurrencyCode), baseCurrencyCode, tradeTime).then((result) => {
        if (result.data?.firstFxRate) {
          updateFxRate(result.data?.firstFxRate);
        }
      });
    }
  }, [instrumentCurrencyCode, baseCurrencyCode, tradeTime, sourceId, editMode]);

  // * When the user selects a new option, set the price to the EoD price for the selected option
  React.useEffect(() => {
    if (!!selectedOption?.value?.price && !editMode) {
      updateMultiplier(selectedOption?.value?.multiplier);
      if (selectedOption?.value?.sourceData === 'MarketRefinitivService') {
        // sendRequest(`/api/v1/${API.endpoints.currency.all}`, { method: 'POST' })
        const url = `/api/v1/price?sourceId=${selectedOption?.value?.id}&sourceData=MarketRefinitivService&date=${formValues.tradeTime}&external=true`;
        sendRequest(url)
          .then((result) => {
            if (typeof result === 'number') {
              updatePrice(result);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        updatePrice(selectedOption?.value?.price);
      }
    }
  }, [selectedOption, editMode]);

  // * Get the price of the underlying any time the underlying or transaction date changes
  React.useEffect(() => {
    if (sourceId && sourceData && tradeTime) {
      fetchPrice(sourceId, sourceData, tradeTime).then((result) => {
        if (typeof result.data === 'number') {
          updateFormValue('underlyingPrice', (result.data as unknown) as number);
        }
      });
    }
  }, [sourceId, sourceData, tradeTime]);

  React.useEffect(() => {
    resetForm(initialValues);
  }, [initialValues]);

  // const instrumentCurrencyCode = (instrument?.value?.currency || instrument?.value?.valuationCurrency) as string | undefined;
  const pointValue = initialValues?.pointValue ?? instrument?.value?.pointValue ?? 1;
  const fxRate = formatFxConvention(snapshotFxRate);
  const grossTradeAmount = React.useCallback(
    () => nz(formValues.price) * nz(formValues.quantity) * n1(formValues.multiplier) * n1(pointValue),
    [snapshotFxRate, formValues.price, formValues.quantity, formValues.multiplier, fxRate?.fromToFxRate, pointValue]
  )();
  const fxConversionLabel = React.useCallback(
    () =>
      getFxConversionLabel(
        currencies,
        baseCurrencyCode,
        typeof instrument?.value?.currency === 'string'
          ? instrument?.value?.currency
          : instrument?.value?.currency?.name,
        snapshotFxRate?.firstFxRate
      ),
    [currencies, baseCurrencyCode, instrument?.value?.currency, snapshotFxRate?.firstFxRate]
  )();

  const availableSettlementOptions = () => {
    const settlementBtns: Array<{ text: string; value: any; subText?: string }> = [
      { text: baseCurrencyCode ?? 'NA', value: 'BASE' },
      { text: `TRANSFER`, value: 'AUTO' },
    ];

    if (instrumentCurrencyCode && portfolioInfo?.currency?.name !== instrumentCurrencyCode) {
      settlementBtns.splice(1, 0, { text: instrumentCurrencyCode ?? 'NA', value: 'LOCAL' });
    }
    return settlementBtns;
  };

  const isFormInvalid = React.useCallback(() => {
    return (
      isValueInvalid(formValues.fxRate) ||
      isValueInvalid(formValues.quantity) ||
      isValueInvalid(formValues.price, true) ||
      formValues.option === null
    );
  }, [formValues.fxRate, formValues.quantity, formValues.price, formValues.option])();

  const handleSaveOption = (e: any) => {
    e.preventDefault?.();

    const portfolioId = portfolioInfo?.id ?? '';

    const instrumentCurrencyHierarchy = currencies?.find(
      (value) => value.name === formValues.instrument?.value?.currency
    )?.hierarchy;
    const baseCurrencyHierarchy = currencies?.find((value) => value.name === portfolioInfo?.currency.name)?.hierarchy;

    const _fxRate =
      (instrumentCurrencyHierarchy ?? 0) <= (baseCurrencyHierarchy ?? 0)
        ? parseFloat(formValues.fxRate)
        : 1 / parseFloat(formValues.fxRate);

    const trade: IPortfolioTrade = {
      // @ts-ignore
      tradeType: { id: 'EqOptions' },
      commission: formValues.commission,
      instrument: formValues.instrument.value,
      quantity: formValues.quantity,
      price: parseFloat(formValues.price),
      fxRate: _fxRate,
      currency: formValues.instrument?.value?.currency,
      tradeTime: formValues.tradeTime,
      settlementDate: undefined,
      settlementOption: formValues.settlementOption,
      operation: formValues.operation,
      custodian:
        typeof formValues.custodian === 'string'
          ? formValues.custodian
          : formValues.custodian?.value || (undefined as any),
      notes: formValues.notes,
      tradeCosts: parseFloat(formValues.tradeCosts),
      type: formValues.type,
      strikePrice: formValues.option.value.strike,
      expiryDate: `${formValues.year.value}-${formValues.expiryDate.value.month}-${formValues.expiryDate.value.date}`, // formValues.expiryDate,
      multiplier: parseFloat(formValues.multiplier),
      // @ts-ignore
      optionTicker: {
        sourceId: {
          sourceId: formValues.option.value.sourceId || formValues.option.value.id,
          sourceData: selectedOption?.value?.sourceData,
        },
      },
      otherSourceId: instrument?.value?.sourceId,
    };
    saveTrades(portfolioId, [trade]).then(() => {
      fetchTradeBlotter(portfolioId, savedTradesOrder);
      resetForm(defaultValues);
    });
  };

  const handleUpdateOption = (e: any) => {
    e.preventDefault?.();
    const portfolioId = portfolioInfo?.id ?? '';

    const trade: IPortfolioTrade = {
      ...editedUnconfirmedTrade?.trade,
      key: initialValues?.key,
      // @ts-ignore
      tradeType: { id: 'EqOptions' },
      commission: formValues.commission,
      instrument: formValues.instrument.value,
      quantity: formValues.quantity,
      price: parseFloat(formValues.price),
      fxRate: parseFloat(formValues.fxRate),
      currency: formValues.instrument?.value?.currency,
      tradeTime: formValues.tradeTime,
      settlementDate: undefined,
      operation: formValues.operation,
      settlementOption: formValues.settlementOption,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      notes: formValues.notes,
      tradeCosts: parseFloat(formValues.tradeCosts),
      type: formValues.type,
      strikePrice: formValues.option.value.strike,
      expiryDate: `${formValues.year.value}-${formValues.expiryDate.value.month}-${formValues.expiryDate.value.date}`, // formValues.expiryDate,
      multiplier: parseFloat(formValues.multiplier),
      optionTicker: {
        sourceId: {
          sourceId: formValues.option.value.sourceId || formValues.option.value.id,
          sourceData: selectedOption?.value?.sourceData,
        },
      }, // formValues.option.value,
    };

    updateTempTrade?.(portfolioId, [trade]).then(() => {
      fetchTradeBlotter(portfolioId, savedTradesOrder);
      resetEditTrade();
    });
  };

  const searchAsset = async (str: string, callback: (v: Array<IOption<IAsset>>) => void) => {
    try {
      const res = await dynamicSearchAssetByName(encodeURIComponent(str));

      const assets: Array<any> = res.data.map((asset: any) => {
        let name = asset.name;
        // console.log(asset);

        if (asset.code !== '' && asset.code !== null) {
          if (
            typeof asset.assetClass === 'string' &&
            (asset.assetClass === 'Alternatives' || asset.assetClass === TRANSACTION_TYPES.cryptoCurrencies)
          ) {
            name = `${asset.name} [${asset.code}]`;
          } else {
            name = `${asset.name} [${asset.code} - ${asset.currency}]`;
          }
        }
        return {
          id: asset.code + asset.currency,
          name,
          value: asset,
        };
      });
      callback(assets);
    } catch (e) {
      callback([]);
    }
  };

  const debouncedSearchAsset = debounce(searchAsset, 300);

  return (
    <form className={s.formGrid} onSubmit={editMode ? handleUpdateOption : handleSaveOption}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <FormFieldWrapper label="Transaction date" required={true} className={s.minField}>
          <Field
            name="tradeTime"
            className={s.datePicker}
            component={renderDatePicker}
            placeholder={'Date'}
            minDate={SYSTEM_EPOCH}
            maxDate={new Date()}
            hideWeekends={false}
          />
        </FormFieldWrapper>
        {!(editMode && checkedOut) && (
          <OptionUnderlying loadOptions={debouncedSearchAsset} instrument={instrument} disabled={editMode} />
        )}
        <FormFieldWrapper label="Type" className={cn(s.operationField, s.fieldsWrapper)} required={true}>
          <Field
            disabled={editMode && checkedOut}
            name="type"
            values={OPTION_TYPES}
            className="input--small"
            component={renderToggleBtns}
          />
        </FormFieldWrapper>
        <Year disabled={editMode && checkedOut} tradeTime={formValues.tradeTime} />
        <OptionsExpirySelector
          availableDates={availableDates}
          disabled={editMode && checkedOut}
          loading={optionsLoading}
        />
        {/* @ts-ignore */}
        <OptionsStrikeSelector
          availableStrikes={availableStrikes}
          disabled={editMode && checkedOut}
          underlyingPriceLabel={formValues.underlyingPrice ? `Spot: ${formValues.underlyingPrice}` : undefined}
        />
      </div>
      <div className={s.formRow}>
        <Multiplier quantityDecimals={instrument?.value?.assetClass?.quantityDecimals} />
        <Operation operationTypes={'BUY_SELL'} />
        <Quantity quantityDecimals={0} />
        <Price
          label={`Price ${getCurrencySymbol(instrumentCurrencyCode as string, pointValue)}`}
          sourceId={formValues.instrument?.value as any}
          tradeTime={formValues.tradeTime}
          editMode={editMode}
        />
        <FXRate label={fxConversionLabel} />
        <SettlementOptions options={availableSettlementOptions()} />
        <Commission currencyValue={instrumentCurrencyCode} />
        <TradeCost currencyCode={instrumentCurrencyCode} />
        <Custodian />
        <AddCustodianButton />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>

      <div className={s.formFooter}>
        <span className={cn('text-gray', s.amountWrapper)}>
          Trade Gross Amount: {instrumentCurrencyCode ?? ''}{' '}
          {grossTradeAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

function getCurrencySymbol(currencyCode?: string, pointValue?: number) {
  if (pointValue === 0.01 && currencyCode?.toUpperCase() === 'GBP') {
    return 'GBp';
  }
  return currencyCode ?? '';
}
const OPTION_TYPES = [
  {
    text: 'Call',
    value: 'CALL',
  },
  {
    text: 'Put',
    value: 'PUT',
  },
];

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.asset = required(value.instrument);
  errors.price = required(value.price) || number(value.price);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);

  return errors;
};

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};

const getAvailableDates = (optionsMatchingYear: IOptionIdentifier[] | null) => {
  if (!optionsMatchingYear) {
    return [];
  }
  const datesObj: Record<string, boolean> = {};
  optionsMatchingYear.forEach(
    (value) => (datesObj[`${String(value.expMonth).padStart(2, '0')}-${String(value.expDay).padStart(2, '0')}`] = true)
  );
  const availableDates: Array<IOption<{ month: number; date: number }>> = [];

  Object.entries(datesObj)
    .sort(([a], [b]) => a.localeCompare(b))
    .forEach(([key, value]) => {
      if (value) {
        const parts = key.split('-');
        availableDates.push({
          id: key,
          value: { month: parseInt(parts[0], 10), date: parseInt(parts[1], 10) },
          name: `${parts[1]} ${getMonthName(parts[0])}`,
        });
      }
    });
  return availableDates;
};

const mapStateToProps = (state: IRootState) => {
  const formValues = state.form.transactionOptionForm.values as IOptionFormValues;
  const optionsMatchingYear = state.snapshot.snapshotEdit.searchOptions.data;
  const availableDates = getAvailableDates(optionsMatchingYear);
  const expiryDate = formValues?.expiryDate;

  const optionsMatchingExpiry: Array<IOption<IOptionIdentifier>> =
    !!expiryDate?.value.month && optionsMatchingYear
      ? optionsMatchingYear
          .filter(
            (optionIdentifier) =>
              optionIdentifier.expMonth === expiryDate?.value?.month &&
              optionIdentifier.expDay === expiryDate?.value?.date
          )
          .map((optionIdentifier) => ({
            name: `${String(optionIdentifier.expMonth).padStart(2, '0')}-
            ${String(optionIdentifier.expDay).padStart(2, '0')}: ${optionIdentifier.name}`,
            id: optionIdentifier.id,
            value: optionIdentifier,
          }))
      : [];

  // @ts-ignore
  const availableStrikes: Array<IOption<IOptionIdentifier>> = _.uniqBy(
    optionsMatchingExpiry ?? [],
    (item) => item.value.name
  ).map(({ value }) => ({
    name: `${value.strike} Strike  --  [${value.name}]`, // String(value.strike),
    id: value.name,
    value,
  }));
  const strike = state.form.transactionOptionForm.values?.strike;

  return {
    custodianOptions: getCustodianOptions(state),
    snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data,
    formValues,
    availableDates,
    availableStrikes,
    strike,
    selectedOption: formValues.option,
    optionsLoading: state.snapshot.snapshotEdit.searchOptions.isFetching,
  };
};

const nz = (val?: string | number | null) => parseFloat(String(val)) || 0;
const n1 = (val?: string | number | null) => parseFloat(String(val)) || 1;

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateTradeType: (data: IOption<TradeType>) => dispatch(change(FORMS_NAME.transactionOption, 'tradeType', data)),
  updateOption: (data) => dispatch(change(FORMS_NAME.transactionOption, 'option', data)),
  updateExpiryDate: (data) => dispatch(change(FORMS_NAME.transactionOption, 'expiryDate', data)),
  updateFormValue: (key, data) => dispatch(change(FORMS_NAME.transactionOption, key, data)),
  // resetForm: (data: any) => {
  //   dispatch(initialize(FORMS_NAME.transactionOption, data));
  // },
  fetchPrice: (sourceId: string, sourceData: string, tradeTime: string) =>
    (dispatch(SnapshotActions.fetchPrice(sourceId, sourceData, tradeTime)) as any) as AxiosPromise<number>,

  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
  updatePrice: (data: number | null) => dispatch(change(FORMS_NAME.transactionOption, 'price', data)),
  updateMultiplier: (data: number | null) => dispatch(change(FORMS_NAME.transactionOption, 'multiplier', data)),
  updateFxRate: (data: number | null) => dispatch(change(FORMS_NAME.transactionOption, 'fxRate', data)),
  dynamicSearchOptions: (
    underlyingSourceId: string,
    underlyingSourceData: string,
    date: string,
    optionType: string,
    year: number,
    code: string
  ) => dispatch(SnapshotActions.searchOptions(underlyingSourceId, underlyingSourceData, date, optionType, year, code)),
  dynamicSearchAssetByName: (name: string) =>
    dispatch(SnapshotActions.dynamicSearchAssetByName(name, 'optionUnderlying')),
});

export default reduxForm<IOptionFormValues, IAssetEntryComponentProps<IOptionFormValues>>({
  form: FORMS_NAME.transactionOption,
  validate,
  destroyOnUnmount: false,
})(connect(mapStateToProps, mapDispatchToProps)(OptionEntry));
