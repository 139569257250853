import React, { Fragment, PureComponent, ReactNode } from 'react';
import { Period } from '../../../../../../../../components/Period';
import { Select } from '../../../../../../../../components/UIWidgets/Select';
import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';

import { CHARTS_PERIODS, PROFIT_VIEW } from '../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { ProfitChart } from '../../../../../../components/ProfitChart';
import { Widget } from '../../../../../../components/WidgetsBlock/Widget';
import { IWidgetProps } from '../../../../../../components/WidgetsBlock/Widget/Widget';
import { LegendVariant } from '../../../../../../../../services/constants/charts';

const viewOptions: Array<IOptionType<PROFIT_VIEW>> = [
  { value: PROFIT_VIEW.ASSET, label: 'By asset class' },
  { value: PROFIT_VIEW.ATTRIBUTION, label: 'By attribution' },
];

interface IOwnProps {
  assetData: IBarData[];
  attributionData: IBarData[];
  selectedPeriod: IPeriod;
  view: PROFIT_VIEW;
  onPeriodChange: (period: IPeriod) => void;
  onViewChange: (view: PROFIT_VIEW) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  hidePeriodSelector?: boolean;
}

type IProps = IOwnProps & Exclude<IWidgetProps, 'type | renderHeaderTools'>;

class PortfolioProfitWidget extends PureComponent<IProps> {
  handlePeriodChange = (period: IPeriod) => {
    this.props.onPeriodChange(period);
  };

  renderViewSelect = () => {
    const { view, onViewChange } = this.props;
    return (
      <Select
        size="small"
        options={viewOptions}
        selectedValue={viewOptions.find((item) => item.value === view)}
        onChange={onViewChange}
      />
    );
  };

  renderPerformancePeriod = (): ReactNode => {
    const { selectedPeriod } = this.props;
    return this.props.hidePeriodSelector ? null : (
      <Period options={CHARTS_PERIODS} selectedPeriod={selectedPeriod} onPeriodChange={this.handlePeriodChange} />
    );
  };

  renderTools = (): ReactNode => {
    return (
      <Fragment>
        {this.renderViewSelect()}
        {this.renderPerformancePeriod()}
      </Fragment>
    );
  };

  render() {
    const { view } = this.props;
    const { assetData, attributionData, colNum, portfolioCurrencyFormatter } = this.props;

    const chartData = view === PROFIT_VIEW.ASSET ? assetData : attributionData;
    const chartLegendVariant = view === PROFIT_VIEW.ASSET ? LegendVariant.Default : LegendVariant.Totals;

    return (
      <Widget colNum={colNum} renderHeaderTools={this.renderTools}>
        <ProfitChart
          profitChartData={chartData}
          isWaterFall={view === PROFIT_VIEW.ATTRIBUTION}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          legendVariant={chartLegendVariant}
        />
      </Widget>
    );
  }
}

export default PortfolioProfitWidget;
