import React, { Fragment } from 'react';
import { blur, WrappedFieldProps } from 'redux-form';
import ControlledAutocomplete, { IControlledAutocompleteProps } from '../ControlledAutocomplete/ControlledAutocomplete';

type IProps = WrappedFieldProps & IControlledAutocompleteProps & { editMode?: boolean };

function renderControlledAutocomplete({
  meta,
  input,
  inputProps = {},
  inputDisabled,
  fetchItems,
  defaultValue,
  ...props
}: IProps) {
  const haveError = props.editMode ? meta.error && meta.pristine : meta.error && meta.touched;
  const haveWarn = props.editMode ? meta.warning && meta.pristine : meta.warning && meta.touched;

  const _onBlur = () => {
    setTimeout(() => {
      if (!input.value) {
        meta.dispatch(blur(meta.form, input.name, undefined, true));
      }
    }, 100);
  };

  return (
    <Fragment>
      <ControlledAutocomplete
        {...props}
        fetchItems={fetchItems}
        disabled={inputDisabled}
        placeholder={inputProps.placeholder}
        haveError={haveError}
        haveWarn={haveWarn}
        disableReset={!!inputProps.required}
        value={input.value}
        onClear={() => {
          input.onChange(null);
        }}
        defaultValue={defaultValue}
        onItemClick={(item: any) => input.onChange(item)}
        // @ts-ignore
        inputProps={{
          ...input,
          ...inputProps,
          onBlur: _onBlur,
        }}
      />
      {haveError && <div className="input__error-text">{meta.error}</div>}
      {haveWarn && <div className="input__warning-text">{meta.warning}</div>}
    </Fragment>
  );
}

export default renderControlledAutocomplete;
