import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { DashboardActions } from '../../actions';
import { ReduxHelper } from '../../utils/ReduxHelper';

export interface IFundamentalsReportState extends ILoadingDataState {
  data: IFundamentalsReport[];
}

const INITIAL_STATE: IFundamentalsReportState = ReduxHelper.createInitialState<IFundamentalsReport[]>([]);

const fundamentalsReportReducer = ReduxHelper.createHttpRequestReducer<ILoadingDataState<IFundamentalsReport[]>>(
  INITIAL_STATE,
  DashboardActions.FUNDAMENTALS_REPORT_REQUEST,
  DashboardActions.FUNDAMENTALS_REPORT_SUCCESS,
  DashboardActions.FUNDAMENTALS_REPORT_ERROR
);

export default fundamentalsReportReducer;
