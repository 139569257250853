import React from 'react';
import { TopBottomTable } from '../../../Dashboard/PortfolioDashboard/components/TopBottomTable';
import { usePortfolioSnapshot } from '../../../../../../services/hooks/apiHooks/usePortfolioSnapshot';
import { useExplorerContext } from '../../../../../../services/context/ExplorerContext';

export const GainsLosses = ({expanded}: IExplorerWidgetProps) => {
  const { setSelectedPosition } = useExplorerContext();

  const snapshotsQuery = usePortfolioSnapshot();
  const snapshots = snapshotsQuery.data?.content ?? [];

  // const compare = (direction: 'asc' | 'desc') => (a: IPortfolioSnapshot, b: IPortfolioSnapshot) =>
  //   ((b.priceMovePercentage ?? (b.priceGain ?? -Infinity) * 100) -
  //     (a.priceMovePercentage ?? (a.priceGain ?? -Infinity) * 100)) *
  //   (direction === 'asc' ? -1 : 1);
  const compare = (direction: 'asc' | 'desc') => (a: any, b: any) => (b.gain - a.gain) * (direction === 'asc' ? -1 : 1);

  const topPositions = snapshots
    .filter((p) => (p.amount ?? 0) !== 0)
    .map((p) => ({ ...p, gain: ((p.amount ?? 0) < 0 ? -1 : 1) * (p.priceGainPercentage ?? 0) }))
    .sort(compare('desc'))
    .filter((p) => p.gain > 0)
    .slice(0, 5);
  const bottomPositions = snapshots
    .filter((p) => (p.amount ?? 0) !== 0)
    .map((p) => ({ ...p, gain: ((p.amount ?? 0) < 0 ? -1 : 1) * (p.priceGainPercentage ?? 0) }))
    .sort(compare('asc'))
    .filter((p) => p.gain < 0)
    .slice(0, 5);

  return (
    <div>
      <TopBottomTable
        data={topPositions}
        onSelectPosition={setSelectedPosition}
        loading={snapshotsQuery.isFetching}
        title={'Top 5 Positions by %age Price vs Cost'}
        style={{minHeight: expanded ? 200 : undefined}}
        key={"expanded"}
      />
      <div style={{ height: 10 }} />
      <TopBottomTable
        data={bottomPositions}
        onSelectPosition={setSelectedPosition}
        loading={snapshotsQuery.isFetching}
        title={'Bottom 5 Positions by %age Price vs Cost'}
        style={{minHeight: expanded ? 200 : undefined}}
        key={"expanded"}
      />
    </div>
  );
};
