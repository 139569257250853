import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { connect } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import Amount from '../../components/ConnectedTransactionInputs/Amount';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import CurrencyList from '../../components/ConnectedTransactionInputs/CurrencyList';
import { renderToggleBtns } from '../../components';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import { getFxConversionLabel } from '../../helpers';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: ISubscriptionFormValues;
  snapshotFxRate: IRootState['snapshot']['snapshotEdit']['fxRate']['data'];
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
}

export interface ISubscriptionFormValues {
  instrument?: IOption<Partial<IAsset>>;
  other: string;
  currency: IOption<string>;
  tradeTime: string;
  fxRate: number | null;
  amount: number;
  settlementOption: IPortfolioTrade['settlementOption'];
  tradeType: IAssetClass;
  operation: 'ADD' | 'WITHDRAW';
  custodian: string | IOption<ICustodian> | any;
  notes?: string;
  asset: 'SubscriptionWithdrawal';
  key?: string;
}

type FormUpdater = <T extends keyof ISubscriptionFormValues>(field: T, data: ISubscriptionFormValues[T] | null) => void;

type Props = IAssetEntryComponentProps<ISubscriptionFormValues> &
  InjectedFormProps<ISubscriptionFormValues> &
  IMapStateToProps &
  IDispatchToProps;

const SubscriptionEntry: FC<Props> = ({
  editMode,
  custodianOptions,
  baseCurrencyCode,
  formValues,
  resetForm,
  resetEditTrade,
  removeTrade,
  initialValues,
  snapshotFxRate,
  fetchFxRate,
  portfolioInfo,
  updateFormValue,
  currencies,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  updateTempTrade,
  checkedOut,
}) => {
  const currencyValue = formValues?.currency?.value;
  const tradeIsInPortfolioCurrency = !currencyValue || baseCurrencyCode === currencyValue;
  const fx = useFx();
  const convention = fx({ fromCurrencyName: currencyValue, toCurrencyName: portfolioInfo?.currency.name });

  const fxRate = tradeIsInPortfolioCurrency
    ? 1
    : convention.isConvention
    ? formValues.fxRate ?? 1
    : 1 / (formValues.fxRate ?? 1);

  React.useEffect(() => {
    resetForm(initialValues);

    if (typeof currencyValue === 'string') {
      updateFormValue('fxRate', convention.naturalToConvention(initialValues.fxRate ?? 1));
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (formValues.currency?.value) {
      setInstrument(formValues.currency?.value);
    } else {
      updateFormValue('fxRate', convention.naturalToConvention(initialValues.fxRate ?? 1));
    }
  }, [formValues.currency]);

  React.useEffect(() => {
    if (portfolioInfo?.currency?.name && !initialValues.instrument) {
      const currency = portfolioInfo.currency.name;
      onCurrencyChange({ value: currency, id: currency, name: currency, code: currency });
    }
  }, [portfolioInfo?.currency]);

  const isFormInvalid = React.useCallback(() => {
    return isValueInvalid(formValues.fxRate) || isValueInvalid(formValues.amount);
  }, [formValues.fxRate, formValues.amount, formValues.instrument])();

  const fxConversionLabel = React.useCallback(
    () =>
      getFxConversionLabel(currencies || [], baseCurrencyCode, formValues.currency?.value, snapshotFxRate?.firstFxRate),
    [currencies, baseCurrencyCode, formValues?.currency?.value, snapshotFxRate?.firstFxRate]
  )();

  const updateFxRate = (instrumentCurrency: string, date: string) => {
    if (!portfolioInfo?.currency || !instrumentCurrency || !date) {
      return;
    }

    if (portfolioInfo?.currency && portfolioInfo.currency?.name !== instrumentCurrency) {
      fetchFxRate(portfolioInfo?.currency?.name, instrumentCurrency, date).then((result) => {
        if (result?.data?.firstFxRate) {
          updateFormValue('fxRate', result.data.firstFxRate);
        }
      });
    }
  };

  const onTradeTimeChange = (time: string) => {
    if (formValues?.currency?.value && time) {
      updateFxRate(formValues?.currency?.value, time);
    }
  };

  const onCurrencyChange = (v: IOption<string>) => {
    if (!v?.value) {
      updateFormValue('instrument', null);
      return;
    }

    if (portfolioInfo?.currency?.name && formValues.tradeTime) {
      updateFxRate(v.value, formValues.tradeTime);
    }

    updateFormValue('currency', v);

    setInstrument(v.value);
  };

  const makeInstrumentOption = (curr: string) => {
    const instrumentId = `${curr}SUBS`;
    const instrumentName = `${curr} Subscription`;

    return {
      id: instrumentId,
      name: instrumentName,
      code: instrumentId,
      value: {
        instrumentId,
        code: instrumentId,
        name: instrumentName,
        sourceId: {
          sourceId: instrumentId,
          sourceData: 'SystemInstrumentService',
        },
      },
    };
  };

  const setInstrument = (curr: string) => {
    updateFormValue('instrument', makeInstrumentOption(curr));
  };

  const updateTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value ?? makeInstrumentOption(formValues.currency?.value)?.value,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: convention.conventionToNatural(formValues.fxRate ?? 1),
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      notes: formValues.notes,
      tradeCosts: 0,
      type: formValues.instrument?.name,
      commission: 0,
      price: 0,
      key: formValues.key,
    };

    updateTempTrade!(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetEditTrade();
    });
  };

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value ?? makeInstrumentOption(formValues.currency?.value)?.value,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: convention.conventionToNatural(formValues.fxRate ?? 1),
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      custodian:
        typeof formValues.custodian === 'string'
          ? formValues.custodian
          : formValues.custodian?.value || (undefined as any),
      notes: formValues.notes,
      tradeCosts: 0,
      type: formValues.instrument?.name,
      commission: 0,
      price: 0,
    };

    saveTrades(portfolioInfo!.id, [trade]).then(() => {
      resetForm(initialValues);
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
    });

    resetForm({ ...initialValues });
  };

  return (
    <form className={s.formGrid} onSubmit={editMode ? updateTrade : saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime onChange={onTradeTimeChange} />
        <FormFieldWrapper className={s.settlementField} label="Operation">
          <Field name="operation" values={OPERATIONS} className="input--small" component={renderToggleBtns} />
        </FormFieldWrapper>
        <Amount label={'Quantity'} />
        <CurrencyList editMode={!!editMode} disabled={editMode} onChange={onCurrencyChange} baseCurrenciesOnly={false} />
        <FXRate label={fxConversionLabel} disabled={tradeIsInPortfolioCurrency} />
        <Custodian />
        <AddCustodianButton />
      </div>

      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>

      <div className={s.formFooter}>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  formValues: state.form[FORMS_NAME.subscriptionWithdrawal].values as ISubscriptionFormValues,
  snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data as any, // .fxRate.data,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(FORMS_NAME.subscriptionWithdrawal, field, data));
  },
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const OPERATIONS = [
  { text: 'Deposit', value: 'ADD' },
  { text: 'Withdraw', value: 'WITHDRAW' },
];

export default reduxForm<ISubscriptionFormValues, IAssetEntryComponentProps<ISubscriptionFormValues>>({
  form: FORMS_NAME.subscriptionWithdrawal,
  validate,
  destroyOnUnmount: false,
})(connect(mapStateToProps, mapDispatchToProps)(SubscriptionEntry));

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};
