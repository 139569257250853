import React, { useEffect, useState } from 'react';
import { default as CreatableSelect, default as Select } from 'react-select';
import { IOption } from './IOption';
import AsyncSelect from 'react-select/async';

export interface IAutocompleteProps {
  options?: Array<IOption<any>>;
  value?: IOption<any> | null;
  onSelect: (option: IOption<any> | null) => void;
  onSelectOption?: (option: IOption<any> | string) => void;
  onTyping?: (search: string) => void;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  inverseTheme?: boolean;
  filter?: (search: string, option: IOption<any>) => boolean;
  haveError?: boolean;
  haveWarn?: boolean;
  disableReset?: boolean;
  disabled?: boolean;
  inputDisabled?: boolean;
  noBlur?: boolean;
  isLoading?: boolean;
  isCreatable?: boolean;
  placeholder?: string;
  filterOption?(option: any): boolean;
  loadOptions?: any;
  defaultOptions?: any;
}

function Autocomplete(props: IAutocompleteProps) {
  const {
    options,
    value,
    onTyping,
    onSelect,
    onSelectOption,
    disabled = false,
    isLoading = false,
    inputDisabled = false,
    disableReset = false,
    inverseTheme = true,
    isCreatable = false,
    placeholder,
    filterOption,
    loadOptions,
    defaultOptions,
  } = props;

  const [customValue, setCustomValue] = useState('');

  // const { onBlurCapture = null, onFocusCapture = null, ...inputProps } = props.inputProps || {
  //   disabled: false,
  //   className: '',
  //   inverseTheme: true,
  // };

  const styling = {
    option: (provided: any, _state: any) => {
      return {
        ...provided,
        borderBottom: 'none',
        color: 'white',
        background: _state.isFocused ? 'var(--dark-blue)' : 'var(--secondary-bg)',
        padding: 10,
      };
    },

    dropdownIndicator: (provided: any, _state: any) => {
      return {
        ...provided,
        ...(inputDisabled ? {} : { display: 'none' }),
      };
    },

    menu: (provided: any, _state: any) => {
      return {
        ...provided,
        margin: 1,

        '&>div': {
          padding: 0,
        },
      };
    },

    singleValue: (provided: any, _state: any) => {
      return {
        ...provided,
        color: '--text-light-color',
      };
    },

    input: (provided: any, _state: any) => {
      return {
        ...provided,
        color: '--text-light-color',
      };
    },

    indicatorSeparator: (provided: any, _state: any) => {
      return {
        ...provided,
        display: 'none',
      };
    },

    indicatorContainer: (provided: any, _state: any) => {
      return {
        ...provided,
        minHeight: 33,
        maxHeight: 33,
      };
    },

    control: (provided: any, _state: any) => {
      return {
        ...provided,
        width: '100%',
        borderRadius: 4,
        border: '1px solid var(--dark-two)',
        backgroundColor: inverseTheme ? 'var(--secondary-bg)' : 'var(--dark-two)',
        font: 'var(--small-text)',
        color: disabled ? 'var(--text-disabled-color)' : 'var(--text-light-color)',
        minHeight: 33,
        maxHeight: 33,
      };
    },
  };

  return (
    <div>
      {isCreatable ? (
        <CreatableSelect
          placeholder={placeholder}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
              case 'Tab':
                if (
                  onSelectOption &&
                  customValue !== '' &&
                  options?.filter((e) => e.name.toLowerCase().includes(customValue.toLowerCase())).length === 0
                ) {
                  onSelectOption(customValue);
                }
            }
          }}
          styles={styling}
          isClearable={!disableReset || disabled}
          onChange={(newValue: any) => {
            if (onSelectOption && newValue) {
              onSelectOption(newValue);
            }
          }}
          onInputChange={(inputValue: any) => {
            setCustomValue(inputValue);
          }}
          options={options}
          getOptionLabel={(option) => `${option.name}`}
        />
      ) : loadOptions ? (
        <AsyncSelect
          cacheOptions={true}
          loadOptions={loadOptions}
          value={value}
          placeholder={placeholder}
          styles={styling}
          defaultOptions={defaultOptions}
          isSearchable={!inputDisabled}
          isClearable={!disableReset || disabled}
          isDisabled={disabled}
          getOptionLabel={(option) => `${option.name}`}
          filterOption={filterOption}
          onChange={(newValue: any) => {
            if (onSelectOption) {
              onSelectOption(newValue);
              return;
            }
            onSelect(newValue);
          }}
        />
      ) : (
        <Select
          value={value}
          placeholder={placeholder}
          styles={styling}
          options={options}
          isSearchable={!inputDisabled}
          isLoading={isLoading}
          isClearable={!disableReset || disabled}
          isDisabled={disabled}
          getOptionLabel={(option) => `${option.name}`}
          filterOption={filterOption}
          onChange={(newValue: any) => {
            if (onSelectOption) {
              onSelectOption(newValue);
              return;
            }
            onSelect(newValue);
          }}
          onInputChange={onTyping}
        />
      )}
    </div>
  );
}

const AutocompleteMemoized = React.memo(Autocomplete);
export default Autocomplete;
