import cn from 'classnames';
import df from 'dateformat';
import React, { CSSProperties, FC, useEffect } from 'react';
import nextId from 'react-id-generator';
import s from './PortfolioUpcomingEvents.module.css';
import { usePortfolio } from '../../../../services/hooks/usePortfolio';
import useApi from '../../../../services/hooks/useApi';
import { getApiUrl, getCustomApiUrl } from '../../../../services/constants/endpoints';
import { Loader } from '../../../../components';
import { useCustodiansFilter } from '../../../../services/hooks/useCustodiansFilter';
import { useCurrencies } from '../../../../services/hooks/apiHooks/useCurrencies';
import { usePeriod } from '../../../../services/context/PeriodContext';
import { PeriodHelper } from '../../../../services/utils/PeriodHelper';
import { Card } from '@iliotech/storybook';
import { Button, Modal } from 'rsuite';
import { BiCalendarEvent, BiNews, BiExpandAlt, BiArrowBack } from 'react-icons/bi';
import { AiOutlineExpand } from 'react-icons/ai';
import Draggable from 'react-draggable';
import useCurrentWidth from '../../../../services/hooks/useCurrentWidth';
import InstrumentImage from '../../../../components/InstrumentImage/InstrumentImage';
import moment from 'moment';

type PortfolioEventItem = { id?: string; date: string; title: string; text: string };

const byDate = (a: PortfolioEventItem, b: PortfolioEventItem) => a.date.localeCompare(b.date);
const surroundStyle: CSSProperties = {
  position: 'relative',
  width: 400,
  maxHeight: 'calc(100vh - 454px)',
  overflow: 'scroll',
};

interface IProps {
  selectedPosition?: IPortfolioSnapshot | null;
  deselectPosition?: () => void;
}
const BREAKPOINT = 1800;

const PortfolioUpcomingEvents = ({ selectedPosition, deselectPosition }: IProps) => {
  const width = useCurrentWidth();
  const [collapsed, setCollapsed] = React.useState(width < BREAKPOINT);
  const { portfolioInfo, portfolioCurrencyFormatter } = usePortfolio();
  const portfolioId = portfolioInfo?.data?.id ?? '';
  const { period } = usePeriod();
  const { selectedCustodiansArray } = useCustodiansFilter();
  const { currencyList } = useCurrencies();
  const [expandedItem, setExpandedItem] = React.useState<IInstrumentNews | undefined>();
  const [pulse, setPulse] = React.useState(false);
  const pulseTimeout = React.useRef<NodeJS.Timeout>();
  const closeModal = () => setExpandedItem(undefined);

  const toggleCollapsed = (val: boolean) => () => setCollapsed(val);

  const renderModalContent = React.useMemo(() => {
    if (!expandedItem) {
      return null;
    }
    return (
      <div>
        <Modal.Header>
          <Modal.Title style={{ fontSize: 22 }} classPrefix={s.modalTitle}>
            {expandedItem.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={s.modalContainer}>
          <span className={s.modalBody}>{expandedItem.content}</span>
          <br />
          <br />
          {expandedItem.link && (
            <a className={s.link} target={'_blank'} href={expandedItem.link}>
              link to original article
            </a>
          )}
        </Modal.Body>
      </div>
    );
  }, [expandedItem]);

  const instrumentNews = useApi<IInstrumentNews[]>(
    getApiUrl('snapshot.news.getByInstrumentId'),
    {
      method: 'GET',
      params: {
        instrumentId: selectedPosition?.instrumentId,
        to: period ? PeriodHelper.preparePeriodForRequest(period).to : undefined,
      },
    },
    { enabled: false }
  );

  useEffect(() => {
    if (width < BREAKPOINT) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [width]);

  useEffect(() => {
    if (!!selectedPosition) {
      instrumentNews.refetch();
      setPulse(true);
      if (pulseTimeout.current) {
        clearTimeout(pulseTimeout.current!);
      }
      pulseTimeout.current = setTimeout(() => {
        setPulse(false);
      }, 1800);
    }
  }, [selectedPosition]);

  const portfolioEvents = useApi<IPortfolioEvent>(
    getCustomApiUrl(`portfolio.events`, [{ idLabel: `:portfolioId`, idValue: portfolioId }]),
    {
      method: 'POST',
      data: {
        custodians: selectedCustodiansArray,
      },
    },
    { enabled: !!portfolioId }
  );

  const { data, isFetching, error } = portfolioEvents;
  const getCurrencySymbol = React.useCallback(
    (currencyCode: string) => currencyList.find((c) => c.name === currencyCode)?.symbol ?? currencyCode,
    [currencyList]
  );

  const { dividends, earnings, ipos, splits, optionsData, fixedIncomeDatas } = data ?? {};

  const fiData = React.useMemo(() => {
    const coupons: PortfolioEventItem[] = [];
    const milestones: PortfolioEventItem[] = [];
    if (fixedIncomeDatas?.fixedIncomeData.length) {
      for (const item of fixedIncomeDatas?.fixedIncomeData) {
        item.nextCoupons.map((coupon) => {
          coupons.push({
            id: `${item.id}-${coupon.date}-${coupon.baseAmount}`,
            date: coupon.date,
            text: `${coupon.interest}% ${item.couponFrequency} * ${formatAmount(
              item.openQuantity,
              '',
              0
            )} nominal = ${formatAmount((coupon.interest / 100) * item.openQuantity, getCurrencySymbol(item.currency))}`,
            title: `${item.instrumentName} ${formatAmount(coupon.baseAmount, portfolioInfo.data?.currency?.symbol)}`,
          });
        });
        item.callDates.map((callDate) => {
          milestones.push({
            id: `${item.id}-${callDate.date}-${callDate.baseAmount}`,
            date: callDate.date,
            text: `Call redemption of ${callDate.principal}% * ${formatAmount(
              item.openQuantity,
              '',
              0
            )} nominal = ${formatAmount(
              (callDate.localAmount * callDate.principal) / 100,
              getCurrencySymbol(item.currency)
            )}`,
            title: `${item.instrumentName} ${formatAmount(callDate.baseAmount, portfolioInfo.data?.currency?.symbol)}`,
          });
        });
        if (item.maturityDate) {
          milestones.push({
            id: `${item.id}-${item.maturityDate.date}-${item.maturityDate.baseAmount}`,
            date: item.maturityDate.date,
            text: `Maturity of ${item.maturityDate.principal}% * ${formatAmount(
              item.maturityDate.localAmount,
              '',
              0
            )} nominal = ${formatAmount(
              (item.maturityDate.principal / 100) * item.maturityDate.localAmount,
              getCurrencySymbol(item.currency)
            )}`,
            title: `${item.instrumentName} ${formatAmount(
              item.maturityDate.baseAmount,
              portfolioInfo.data?.currency?.symbol
            )}`,
          });
        }
      }
    }

    coupons.sort(byDate);
    milestones.sort(byDate);

    return { coupons, milestones };
  }, [fixedIncomeDatas]);

  const renderDividends = React.useMemo(() => {
    const currentDividends = selectedPosition
      ? dividends
          ?.filter((d) => d.ticker === selectedPosition.code)
          ?.sort((a, b) => a.date.localeCompare(b.date))
          .slice(0, 1)
      : dividends?.sort((a, b) => a.date.localeCompare(b.date));

    if (currentDividends && currentDividends.length > 0) {
      return (
        <div style={{ marginBottom: 20 }}>
          <span className={cn(s.subheading)}>Portfolio Dividends by ex-date</span>
          {currentDividends.map((dividend) => {
            const {
              // id,
              name,
              baseAmount,
              localAmount,
              openQuantity,
              amountPerShare,
              date,
              ticker,
              displayId,
              currency,
            } = dividend;
            const localSymbol = getCurrencySymbol(currency);
            return (
              <div key={nextId()} className={cn(s.eventSurround)}>
                <EventDate date={date} size={'small'} />
                <div className={cn(s.eventDetails)}>
                  <strong>
                    {name} ({displayId || ticker}): {formatAmount(baseAmount, portfolioInfo?.data?.currency?.symbol)}
                  </strong>
                  <br />
                  {amountPerShare.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  {currency} / share *{' '}
                  {openQuantity.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  })}{' '}
                  shares held
                  {' = '}
                  <strong>{formatAmount(localAmount, localSymbol)}</strong>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }, [dividends, selectedPosition]);

  const renderInstrument = React.useMemo(() => {
    if (!selectedPosition) {
      return null;
    }

    if (collapsed) {
      return (
        <div className={s.collapsedIcon}>
          <InstrumentImage instrument={selectedPosition} />
        </div>
      );
    }
    return (
      <div>
        <div className={s.titleRow}>
          <InstrumentImage instrument={selectedPosition} />
          <h3>{selectedPosition.name}</h3>
        </div>
        <span className={cn(s.subheading)}>
          Total Position Value {portfolioCurrencyFormatter(selectedPosition.amount)} /{' '}
          {selectedPosition.percentOfPortfolio}% of NAV
        </span>
      </div>
    );
  }, [selectedPosition, collapsed]);

  const renderInstrumentNews = React.useMemo(() => {
    if ((!selectedPosition || !instrumentNews?.data?.length) && !instrumentNews.isLoading) {
      return null;
    }

    if (instrumentNews.isFetching) {
      return <Loader />;
    }
    const howManyNews = instrumentNews?.data?.slice(0, 10).length;

    if (collapsed) {
      return (
        <div>
          <div className={s.collapsedIcon}>
            <BiNews color={'var(--cloudy-blue)'} fontSize={25} style={{}} />
            <div className={s.indicatorWrapper}>
              <span className={s.indicator}>{howManyNews}</span>
            </div>
          </div>
        </div>
      );
    }

    const renderNew = (instrument: IInstrumentNews) => {
      return (
        <div
          className={s.newCard}
          onClick={() => {
            setExpandedItem(instrument);
            if (width < BREAKPOINT) {
              toggleCollapsed(true)();
            }
          }}
        >
          <span className={s.subheading}>{instrument.title}</span>
          <br />
          <div style={{ height: 5 }} />
          <span>{instrument.content.slice(0, 100)}...</span>
        </div>
      );
    };

    return (
      <div>
        <div className={s.titleRow}>
          <BiNews color={'var(--cloudy-blue)'} fontSize={18} style={{ marginRight: 5, marginBottom: 2.5 }} />
          <h3>Top News Stories on {moment().format('DD MMM YYYY')}</h3>
        </div>
        {instrumentNews?.data?.slice(0, 10).map((instrument) => renderNew(instrument))}
      </div>
    );
  }, [selectedPosition, instrumentNews, collapsed]);

  if (isFetching) {
    return null;
  }
  if (error || !data) {
    return null;
  }
  const renderItems = (items: PortfolioEventItem[], heading: string = 'Events') => {
    if (selectedPosition) {
      return null;
    }

    if (items.length > 0) {
      return (
        <div style={{ marginBottom: 20 }}>
          <span className={cn(s.subheading)}>{heading}</span>
          <ul>
            {items
              .sort((a, b) => a.date.localeCompare(a.date))
              .map((item, index) => {
                const { id, title, text } = item;
                const date = item.date;
                return (
                  <div key={`${nextId()}${id}`} className={cn(s.eventSurround)}>
                    <EventDate date={date} size={'small'} />
                    <div className={cn(s.eventDetails)}>
                      <span>
                        <strong>{title}</strong>
                        <br />
                        {text}
                      </span>
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
      );
    }
  };

  const getEventItem = (item: any[]) => {
    if (selectedPosition) {
      return item.filter((cur) => cur.ticker === selectedPosition.code);
    }
    return item;
  };

  if (portfolioEvents.isLoading || !data) {
    return (
      <div style={surroundStyle}>
        <Loader />
      </div>
    );
  }

  const hasUpcomingEvents = [
    getEventItem(splits || []),
    getEventItem(earnings || []),
    getEventItem(optionsData?.optionData || []),
    getEventItem(ipos || []),
  ].some((item) => item.length > 0);

  const howManyEvents = [
    getEventItem(splits || []),
    getEventItem(earnings || []),
    getEventItem(optionsData?.optionData || []),
    getEventItem(ipos || []),
  ].reduce((prev, cur) => prev + cur.length, 0);

  const renderMainContent = () => {
    if (collapsed) {
      return (
        <div onClick={toggleCollapsed(false)}>
          <div className={s.collapsedIcon}>
            <Button onClick={toggleCollapsed(false)} style={{ right: 6 }}>
              <AiOutlineExpand fontSize={15} />
            </Button>
          </div>
          {renderInstrument}
          {hasUpcomingEvents && (
            <div className={s.collapsedIcon} style={{ marginTop: 10, marginLeft: -1.7 }}>
              <BiCalendarEvent color={'var(--cloudy-blue)'} fontSize={28} style={{ marginLeft: 0 }} />
              <div className={s.indicatorWrapper}>
                <span className={s.indicator}>{howManyEvents}</span>
              </div>
            </div>
          )}
          {renderInstrumentNews}
        </div>
      );
    }
    const inner = () => {
      return (
        <Card className={cn(s.draggableContainer, s.noScrollbar, width < BREAKPOINT && s.collapsed)}>
          <div className={s.topRow}>
            {selectedPosition ? (
              <Button onClick={deselectPosition}>
                <BiArrowBack fontSize={10} />
              </Button>
            ) : (
              <span />
            )}
            <div className={cn(s.expandButton, s.expanded)}>
              <Button onClick={toggleCollapsed(true)}>
                <BiExpandAlt fontSize={10} />
              </Button>
            </div>
          </div>
          <div className={s.noScrollbar} style={surroundStyle}>
            {renderInstrument}
            {selectedPosition && <div style={{ height: 20 }} />}
            {hasUpcomingEvents && (
              <div className={s.titleRow}>
                <BiCalendarEvent
                  color={'var(--cloudy-blue)'}
                  fontSize={18}
                  style={{ marginRight: 5, marginBottom: 3.5 }}
                />
                <h3>{howManyEvents} Upcoming Events</h3>
              </div>
            )}
            {renderDividends}
            {splits &&
              renderItems(
                getEventItem(splits).map(({ id, date, name, newQuantity, oldQuantity }) => ({
                  id,
                  date,
                  title: name,
                  text: `${newQuantity} for ${oldQuantity} stock split`,
                })),
                'Splits'
              )}

            {earnings &&
              renderItems(
                getEventItem(earnings).map(({ id, name, ticker, reportDate }) => ({
                  id,
                  date: reportDate,
                  title: ticker,
                  text: `${name} earnings call on ${df(reportDate, 'dddd, dd mmm yyyy')}`,
                })),
                'Earnings calls'
              )}

            {optionsData?.optionData &&
              renderItems(
                getEventItem(optionsData?.optionData).map(
                  ({ id, expiryDate, instrumentName, marketValue, quantity, ticker, underlyingPrice }) => ({
                    id: `${id}-${expiryDate}`,
                    date: expiryDate,
                    title: instrumentName,
                    text: `Spot = ${formatAmount(underlyingPrice)}, ${quantity} held, current premium = ${marketValue}`, // JSON.stringify({ offerPrice, priceFrom, priceTo, share, ticker, currency }),
                  })
                ),
                'Option expiry'
              )}
            {ipos &&
              renderItems(
                getEventItem(ipos).map(
                  ({ startDate, name, offerPrice, currency, priceFrom, priceTo, share, ticker }) => ({
                    id: `${name}-${startDate}-${currency}`,
                    date: startDate,
                    title: name,
                    text: `Offering as ${ticker} on ${df(startDate, 'dddd, dd mmm yyyy')}`, // JSON.stringify({ offerPrice, priceFrom, priceTo, share, ticker, currency }),
                  })
                ),
                'Upcoming IPOs'
              )}
            {renderItems(fiData.coupons, 'Coupons')}
            {renderItems(fiData.milestones, 'Bond Milestones')}
            {renderInstrumentNews}
            {/*<pre>{JSON.stringify({ data }, null, 2)}</pre>*/}
          </div>
        </Card>
      );
    };
    return width < BREAKPOINT ? <Draggable>{inner()}</Draggable> : <div>{inner()}</div>;
  };

  return (
    <React.Fragment>
      <Card className={cn(s.container, collapsed && s.collapsed, collapsed && pulse && s.pulse)}>
        {renderMainContent()}
      </Card>
      <Modal full={true} show={!!expandedItem} onHide={closeModal} className={s.modalContainer}>
        {renderModalContent}
      </Modal>
    </React.Fragment>
  );
};

const EventDate: FC<{ date: string; size?: 'small' | 'large' }> = ({ date, size = 'large' }) => {
  return (
    <div className={cn(s.eventDateSurround)} style={{ ...(size === 'small' ? { height: 64, width: 32 } : {}) }}>
      <div className={cn(size === 'large' ? s.semiCircle : s.semiCircleSmall)} />
      <div className={cn(s.eventDate)}>
        <span style={{ fontSize: size === 'small' ? 14 : 24 }}>{df(new Date(date), 'dd')}</span>
        <br />
        <span>{df(new Date(date), 'mmm')}</span>
      </div>
    </div>
  );
};

const formatAmount = (val: number, currencySymbol?: string, decimals: number = 2) =>
  `${val < 0 ? '-' : ''}${currencySymbol ?? ''}${Math.abs(val).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}`;

export default PortfolioUpcomingEvents;
