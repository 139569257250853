import React, { Fragment } from 'react';
import s from '../VolatilityContributionChart.module.css';
import cn from 'classnames';

export function XAxisCanvas(props: any) {
  return (
    <Fragment>
      <div className={s.BandsCanvas}>
        <div className={s.BandStrip}>Band 1</div>
        <div className={cn(s.BandStrip, s.Esma2Bg)}>Band 2</div>
        <div className={cn(s.BandStrip, s.Esma3Bg)}>Band 3</div>
        <div className={cn(s.BandStrip, s.Esma4Bg)}>Band 4</div>
        <div className={cn(s.BandStrip, s.Esma5Bg)}>Band 5</div>
        <div className={cn(s.BandStrip, s.Esma6Bg)}>Band 6</div>
        <div className={cn(s.BandStrip, s.Esma7Bg)}>Band 7</div>
      </div>
      <div className={s.xAxisCanvas}>
        <div className={s.LabelStripX}>0</div>
        <div className={s.LabelStripX}>2%</div>
        <div className={s.LabelStripX}>5%</div>
        <div className={s.LabelStripX}>10%</div>
        <div className={s.LabelStripX}>15%</div>
        <div className={s.LabelStripX}>25%</div>
        <div className={s.LabelStripX}>
          50%<span className={s.xAxisLabelEnd}>100%</span>
        </div>
      </div>
    </Fragment>
  );
}
