import React, { ChangeEvent, FC } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import s from './FXPair.module.css';

import { renderAutocomplete, renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { useCurrencies } from '../../../../../../../../services/hooks/apiHooks/useCurrencies';

interface IProps {
  onQuantityChange(e: ChangeEvent<HTMLInputElement>): void;
  onCurrencyChange(option: { value: string }): void;
  editMode: boolean;
}

const FXSellLeg: FC<IProps> = ({ onQuantityChange, onCurrencyChange, editMode }) => {
  const { currencyOptions } = useCurrencies();

  return (
    <div className={cn(s.legSurround, s.horizontal)}>
      <div className={cn(s.border)} />

      <div className={cn(s.label)}>Sell</div>
      <FormFieldWrapper label={'Currency'} required={true} className={cn(s.field, s.wide)}>
        <Field
          disabled={editMode}
          placeholder="Select"
          name={`sellCurrency`}
          options={currencyOptions}
          component={renderAutocomplete}
          onChange={onCurrencyChange as any}
          inputProps={{
            className: 'input--small',
          }}
        />
      </FormFieldWrapper>
      <FormFieldWrapper label={'Quantity'} required={true} className={s.field}>
        <Field
          placeholder="0"
          name={`sellQuantity`}
          type="text"
          theme="inverse"
          component={renderInput}
          onChange={onQuantityChange}
          className="input--small"
          // onChange={(...args: any) => console.log({ args })}
          normalize={(val: number) => positiveNumber(floatNumberV2(val, 2)).toString()}
        />
      </FormFieldWrapper>
    </div>
  );
};

export default FXSellLeg;
