import React, { InputHTMLAttributes, PropsWithChildren, PureComponent } from 'react';
import cn from 'classnames';

import s from './RadioButton.module.scss';

interface IProps extends PropsWithChildren<InputHTMLAttributes<HTMLInputElement>> {
  label?: string;
}

class RadioButton extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    disabled: false,
  };

  render() {
    const { children, label, ...rest } = this.props;
    return (
      <label className={s.container}>
        <span className={cn(s.checkMark, { [s.checked]: rest.checked })} />
        <input type="radio" className={s.radioControl} {...rest} />
        {label ? label : ''}
        {children}
      </label>
    );
  }
}

export default RadioButton;
