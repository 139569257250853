import ReactEcharts from 'echarts-for-react';
import debounce from 'lodash.debounce';
import React, { PureComponent, ReactNode } from 'react';
import { ChartLegend } from '../../../../../../../../components/ChartLegend';
import { ILegendItemProps, LegendType } from '../../../../../../../../components/ChartLegend/components/LegendItem';
import { Period } from '../../../../../../../../components/Period';

import { LineData } from '../../../../../../../../models/LineData';
import { ASSET_COLORS, ASSETS, CHARTS_PERIODS } from '../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { Widget } from '../../../../../../../../scenes/Portfolio/components/WidgetsBlock/Widget';
import { IWidgetProps } from '../../../../../../../../scenes/Portfolio/components/WidgetsBlock/Widget/Widget';

import { getChangedChartOptions, getPnlByAttributionChartOptions } from './PnlByAttributionChartConfig';
import { IPortfolioPnlByAttribution } from '../../../../../../../../models/IPortfolioPnlByAttribution';

interface IOwnProps {
  portfolioPerformance: LineData;
  indexPerformance: LineData;
  chartData: IPortfolioPnlByAttribution;
  selectedPeriod: IPeriod;
  onPeriodChange: (period: IPeriod) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  indexName: string;
  hidePeriodSelector?: boolean;
  hideIncome?: boolean;
  onItemClick?(dateString: string): void;
}

type IProps = IOwnProps & Exclude<IWidgetProps, 'type | renderHeaderTools'>;

interface IState {
  chartWidth: number;
}

class PnlByAttributionChart extends PureComponent<IProps, IState> {
  static readonly defaultProps: Partial<IProps> = {
    // title: 'P&L by Attribution',
  };

  readonly state: IState = {
    chartWidth: 0,
  };

  private _echartsReact: React.RefObject<ReactEcharts> = React.createRef();

  constructor(props: IProps) {
    super(props);
    this.handleZoomChange = debounce(this.handleZoomChange, 200);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this._echartsReact) {
        throw Error('No echart component');
      }
      this._updateWidth();
    });
    window.addEventListener('resize', () => this._updateWidth());
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', () => this._updateWidth());
  }

  getLegend(
    portfolioData: LineData,
    indexData: LineData,
    portfolioPnlByAttribution: IPortfolioPnlByAttribution,
    portfolioCurrencyFormatter: ICurrencyFormatter
  ): ILegendItemProps[] {
    const portfolioValue = this._getLegendValue(portfolioData);
    const indexValue = this._getLegendValue(indexData);
    const { hideIncome } = this.props;
    const legend = [
      {
        name: 'Total',
        value: portfolioCurrencyFormatter(portfolioPnlByAttribution.total),
        color: '#a9b2d1',
      },
      {
        name: 'Price',
        value: portfolioCurrencyFormatter(portfolioPnlByAttribution.priceTotal),
        color: '#6677cc',
      },
      {
        name: 'Fx',
        value: portfolioCurrencyFormatter(portfolioPnlByAttribution.fxTotal),
        color: '#C2B361',
      },
    ];
    const income = {
      name: 'Income',
      value: portfolioCurrencyFormatter(portfolioPnlByAttribution.incomeTotal),
      color: '#59abb3',
    };
    return hideIncome ? legend : [...legend, income];
  }

  renderPerformancePeriod = (): ReactNode => {
    const { selectedPeriod, onPeriodChange } = this.props;
    return this.props.hidePeriodSelector ? null : (
      <Period options={CHARTS_PERIODS} selectedPeriod={selectedPeriod} onPeriodChange={onPeriodChange} />
    );
  };

  // TODO: should be used to filter labels on x Axis but it cause bad performance
  handleZoomChange = (e: any) => {
    const { portfolioPerformance } = this.props;
    if (!this._echartsReact.current) {
      return;
    }

    const { selectedPeriod } = this.props;
    const { chartWidth } = this.state;

    let zoomData = e;
    if (e.batch) {
      zoomData = e.batch[0];
    }
    const { start, end } = zoomData;
    const chartDataNum = portfolioPerformance.length - 1;
    const dataInView = portfolioPerformance.slice(
      Math.floor((chartDataNum * start) / 100),
      Math.floor((chartDataNum * end) / 100)
    );

    const echart: echarts.ECharts = (this._echartsReact.current as any).getEchartsInstance();
    echart.setOption(getChangedChartOptions(dataInView, selectedPeriod, chartWidth));
  };

  render() {
    const { chartWidth } = this.state;
    const {
      portfolioPerformance,
      indexPerformance,
      chartData,
      selectedPeriod,
      onPeriodChange,
      portfolioCurrencyFormatter,
      indexName,
      ...widgetProps
    } = this.props;

    return (
      <Widget renderHeaderTools={this.renderPerformancePeriod} {...widgetProps}>
        <ChartLegend
          legend={this.getLegend(portfolioPerformance, indexPerformance, chartData, portfolioCurrencyFormatter)}
          type={LegendType.BAR}
        />
        <ReactEcharts
          ref={this._echartsReact}
          onEvents={{
            datazoom: this.handleZoomChange,
            click: ({ data }) => {
              this.props.onItemClick?.(data[0]);
            },
          }}
          style={{ height: '420px', width: '100%', flex: '1', maxHeight: '400px' }}
          option={getPnlByAttributionChartOptions(
            portfolioPerformance,
            indexPerformance,
            selectedPeriod,
            chartWidth,
            chartData,
            portfolioCurrencyFormatter,
            indexName
          )}
        />
      </Widget>
    );
  }

  private _updateWidth() {
    // todo: expand ReactEcharts type definition
    if (!this._echartsReact.current) {
      return;
    }
    const echart: echarts.ECharts = (this._echartsReact.current as any).getEchartsInstance();
    this.setState({ chartWidth: echart.getWidth() });
  }

  private _getLegendValue(data: LineData): number {
    if (!data || !data.length) {
      return 0;
    }
    if (data.length === 1) {
      return data[0][1];
    }
    return data[data.length - 1][1] - data[0][1];
  }
}

export default PnlByAttributionChart;
