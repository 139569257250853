import axios, { AxiosPromise } from 'axios';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import WorkGroupManager from './WorkGroupManager';
import { PortfolioActions } from '../../services/actions';
import { IRootState } from '../../services/store';
import { AsyncActionDispatch } from '../../services/utils/ReduxHelper';
import { AppActions } from '../../services/actions/AppActions';
import { getAllCurrencyOptions, getPortfolioCurrencyOptions } from '../../services/selectors/portfolioCurrency';
import { IOption } from '../../components/UIWidgets/Autocomplete';
import { PortfolioCurrencyActions } from '../../services/actions/PortfolioCurrencyActions';

interface IMapStateToProps {
  isFetched: boolean;
  isFetching?: boolean;
  authInfo: IAuthInfo | null;
  currencyOptions: Array<IOption<ICurrency>>;
  userCapabilities: IRootState['userCapabilities']['data'];
}

interface IDispatchToProps {
  deletePortfolio: (portfolio: IPortfolio) => AxiosPromise<any>;
  showNotification: (notification: INotification) => void;
  fetchPortfolioCurrency: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps;

class PortfoliosManagerContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    this.props.fetchPortfolioCurrency();
    axios.defaults.headers['illio-selected-workgroup'] = '';
  }

  render() {
    const { isFetched, isFetching, authInfo } = this.props;

    return (
      <WorkGroupManager
        showNotification={this.props.showNotification}
        currencyOptions={this.props.currencyOptions}
        authInfo={authInfo}
        isFetched={isFetched}
        isFetching={isFetching}
        userCapabilities={this.props.userCapabilities}
      />
    );
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    isFetched: state.portfoliosList.isFetched,
    isFetching: state.portfoliosList.isFetching,
    authInfo: state.app.authInfo.data,
    userCapabilities: state.userCapabilities.data,
    currencyOptions: getPortfolioCurrencyOptions(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  deletePortfolio: (portfolio: IPortfolio) => dispatch(PortfolioActions.deletePortfolio(portfolio)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  fetchPortfolioCurrency: () => dispatch(PortfolioCurrencyActions.fetchPortfolioCurrency()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfoliosManagerContainer));
