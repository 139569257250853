import { createSelector } from 'reselect';
import { IOption } from '../../components/UIWidgets/Autocomplete';

import { TRANSACTION_TYPES } from '../mock/transactionTypes';
import { IRootState } from '../store';
import _ from 'lodash';

export function getWarrants(state: IRootState): IOptionIdentifier[] {
  return state.snapshot.snapshotEdit.searchWarrants.data!;
}

export function getAssets(state: IRootState): IAsset[] {
  return state.snapshot.snapshotEdit.searchAssets.data;
}

export function getBonds(state: IRootState): IAsset[] {
  return state.snapshot.snapshotEdit.searchBonds.data;
}

export function getCustodians(state: IRootState): ICustodian[] {
  return state.portfolio.portfolioCustodians.data;
}

export const getAssetOptions = createSelector<IRootState, IAsset[], Array<IOption<IAsset>>>(
  getAssets,
  (assets: IAsset[]) => {
    return assets.map((asset) => {
      let name = asset.name;
      // console.log(asset);

      if (asset.code !== '' && asset.code !== null) {
        if (
          typeof asset.assetClass === 'string' &&
          (asset.assetClass === 'Alternatives' || asset.assetClass === TRANSACTION_TYPES.cryptoCurrencies)
        ) {
          name = `${asset.name} [${asset.code}]`;
        } else {
          name = `${asset.name} [${asset.code} - ${asset.currency}]`;
        }
      }
      return {
        id: asset.code + asset.currency,
        name,
        value: asset,
      };
    });
  }
);

export const getBondsOptions = createSelector<IRootState, IAsset[], Array<IOption<IAsset>>>(
  getBonds,
  (bonds: IAsset[]) => {
    return bonds.map((bond) => ({
      id: bond.code + bond.currency,
      name: `${bond.name}${!!bond.code ? ` [${bond.code}]` : ''}`,
      value: bond,
    }));
  }
);

export const getCustodianOptions = createSelector<IRootState, ICustodian[], Array<IOption<ICustodian>>>(
  getCustodians,
  (custodians: ICustodian[]) => {
    return custodians.map((custodian: ICustodian) => ({
      id: custodian.id,
      name: custodian.name,
      default: custodian.default,
      value: custodian,
    }));
  }
);
