import * as React from 'react';
import s from './LegendCard.module.scss';
import { Card } from '@iliotech/storybook';
import cn from 'classnames';
import PercentileHorizontal from './PercentileHorizontal/PercentileHorizontal';
import PercentileVertical from './PercentileVertical/PercentileVertical';

interface IProps {
  icon: any;
  text: string;
  color: string;
  expanded: boolean;
  onClick: any;
  loaded: boolean;
  hasShort: boolean;
  percentiles: { long: number; short?: number };
}

const LegendCard = ({ icon, text, color, expanded, onClick, loaded, percentiles, hasShort }: IProps) => {
  return (
    <Card onClick={onClick} className={s.container}>
      <div className={s.innerContainer}>
        <img src={icon} />
        <div className={s.textWrapper}>
          <span className={s.title}>{text}</span>
          <div className={s.bar} style={{ backgroundColor: color, width: loaded ? 100 : 0 }} />
          {!expanded && <PercentileHorizontal loaded={loaded} percent={percentiles.long} color={color} />}
        </div>
      </div>
      <div className={cn(s.expandable, expanded && s.active)}>
        <div className={s.percentilesContainer}>
          <PercentileVertical type={percentiles.short ? 'Long' : ''} percent={percentiles.long} color={color} />
          {hasShort ? <PercentileVertical type={'Short'} percent={percentiles.short!} color={color} /> : null}
        </div>
      </div>
    </Card>
  );
};

export default LegendCard;
