import { BoxShadowProperty, Color } from 'csstype';
import echarts from 'echarts/lib/echarts';
import moment from 'moment';

const textColor: Color = '#a9b2d1';
const textSize = 11;

const tooltipColor: Color = '#555555';
const tooltipShadow: BoxShadowProperty = '0 2px 4px 0 rgba(0,0,0,0.1)';
const tooltipBorderRadius = 10;

export const chartColor: Color = '#6677cc';
export const chartColorProfit: Color = '#41d9a0';
export const chartColorLoss: Color = '#ff6972';
export const chartColorBuy: Color = '#41d9a0';
export const chartColorSell: Color = '#ff6972';

const splitLineColor: Color = '#3c4871';
const splitAreaColors: [Color, Color] = ['rgba(0, 0, 0, 0.1)', 'rgba(28, 32, 46, 0.1)'];

export const areaGradient: { from: Color; to: Color } = {
  from: '#4162d9',
  to: 'rgba(65, 98, 217, 0)',
};

const axisOffset = 5;

const legendColor: Color = '#a9b2d1';

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis = {
  axisLabel: {
    show: true,
    color: textColor,
    fontSize: textSize,
  },
  axisLine: {
    show: false,
  },
  offset: axisOffset,
  splitArea: {
    show: true,
    areaStyle: {
      color: splitAreaColors,
    },
  },
  show: true,
  splitLine: {
    show: false,
  },
  type: 'category',
};

export const DEFAULT_XAXIS_TIME_OPTIONS: echarts.EChartOption.XAxis = {
  ...DEFAULT_XAXIS_OPTIONS,
  axisLabel: {
    ...DEFAULT_XAXIS_OPTIONS.axisLabel,
    showMaxLabel: false,
    showMinLabel: false,
    formatter(value: number): string {
      return moment(value).format("DD MMM'YY");
    },
  },
  type: 'time',
};

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis = {
  axisLabel: {
    color: textColor,
    fontSize: textSize,
    margin: 10,
  },
  axisLine: {
    show: false,
  },
  offset: axisOffset,
  position: 'right',
  splitLine: {
    show: true,
    lineStyle: {
      color: splitLineColor,
    },
  },
  show: true,
  type: 'value',
  axisTick: {
    show: false,
  },
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  trigger: 'axis',
  show: true,
  padding: [3, 7],
  textStyle: {
    color: tooltipColor,
    fontSize: textSize,
  },
  backgroundColor: 'var(--text-white)',
  borderColor: 'var(--warm-grey)',
  borderWidth: 1,
  extraCssText: `border-radius:${tooltipBorderRadius}px;` + `box-shadow: ${tooltipShadow};}`,
  axisPointer: {
    type: 'line',
    lineStyle: {
      color: chartColor,
      width: 1,
      type: 'dashed',
    },
  },
};

export const DEFAULT_GRID_OPTIONS: object = {
  x: 20,
  y: 10,
  x2: 20,
  y2: 20,
  borderWidth: 0,
  containLabel: true,
};

export const DEFAULT_LINE_SERIES_OPTIONS: echarts.EChartOption.SeriesLine = {
  type: 'line',
  symbol: 'circle',
  symbolSize: 5,
  showSymbol: false,
  animation: true,
  animationDuration: 100,
  itemStyle: {
    color: chartColor,
  },
  data: [],
};

export const DEFAULT_BAR_SERIES_OPTIONS: echarts.EChartOption.SeriesBar = {
  itemStyle: {
    color: chartColor,
  },
  data: [],
};

export const DEFAULT_LEGEND_OPTIONS: object = {
  data: [],
  top: -5,
  left: 0,
  textStyle: {
    color: legendColor,
  },
};

export const DEFAULT_ZOOM_OPTIONS: echarts.EChartOption.DataZoom[] = [
  {
    type: 'inside',
    minValueSpan: 3600 * 24 * 1000 * 7,
    filterMode: 'none',
  },
];

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  legend: DEFAULT_LEGEND_OPTIONS,
  series: [],
};

export enum BarType {
  Solid = 'Solid',
  Hatching = 'Histing',
  Line = 'Line',
}

export enum LegendVariant {
  Default = 'Default',
  Totals = 'Totals',
}
