import { useTypedSelector } from './useTypedSelector';

const useCustodiansOption = () => {
  const { custodians, pureCustodians } = useTypedSelector((state) => ({
    custodians: state.portfolio.portfolioCustodians.data.map((c) => ({ id: c.id, name: c.name, value: c })),
    pureCustodians: state.portfolio.portfolioCustodians.data,
  }));

  return { custodians, pureCustodians };
};

export default useCustodiansOption;
