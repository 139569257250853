import useApi from './useApi';
import { getApiUrl } from '../constants/endpoints';
import React from 'react';

export const useSubClassLabel = () => {
  const { data } = useApi(getApiUrl('assets.subclass.all'), {
    method: 'POST',
  });

  const convertIdToLabel = React.useMemo(() => {
    return (id: string) => {
      if (!data?.length) {
        return id;
      }
      const found = data.find((item: IAssetSubClass) => item.id === id);
      if (!found) {
        return id;
      }
      return found.name;
    };
  }, [data]);

  return {
    convertIdToLabel,
  };
};
