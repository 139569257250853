// @ts-ignore
import { Color } from 'csstype'; // bad typescript csstype

export type AssetClass =
  | 'Adjustments'
  | 'Alternatives'
  | 'CashAndEquivalents'
  | 'Commodities'
  | 'Equities'
  | 'FixedIncome'
  | 'Funds'
  | 'CryptoCurrencies'
  | 'RealAssets'
  | 'RealEstate'
  | 'SubscriptionWithdrawal'
  | 'Futures'
  | 'MixedAllocation'
  | 'Fx'
  | 'Other';

export const ASSETS: Record<string, AssetClass> = {
  adjustments: 'Adjustments',
  alternatives: 'Alternatives',
  cash: 'CashAndEquivalents',
  commodities: 'Commodities',
  fixedIncome: 'FixedIncome',
  equity: 'Equities',
  fund: 'Funds',
  realEstate: 'RealEstate',
  realAssets: 'RealAssets',
  cryptoCurrencies: 'CryptoCurrencies',
  subscriptionWithdrawal: 'SubscriptionWithdrawal',
  futures: 'Futures',
  mixed: 'MixedAllocation',
  fx: 'Fx',
  other: 'Other',
};

export const ASSET_LABEL_NAMES: Record<string, Color> = {
  [ASSETS.alternatives]: 'Alternatives',
  [ASSETS.adjustments]: 'Cash Adjustments',
  [ASSETS.cash]: 'Cash And Equivalents',
  [ASSETS.commodities]: 'Commodities',
  [ASSETS.fixedIncome]: 'Fixed Income',
  [ASSETS.equity]: 'Equities',
  [ASSETS.fund]: 'Funds',
  [ASSETS.realEstate]: 'Real Estate',
  [ASSETS.realAssets]: 'Real Assets',
  [ASSETS.cryptoCurrencies]: 'Cryptocurrencies',
  [ASSETS.subscriptionWithdrawal]: 'Subscription Withdrawal',
  [ASSETS.futures]: 'Futures',
  [ASSETS.mixed]: 'Mixed Allocation',
  [ASSETS.fx]: 'FX trades',
};

export enum ASSET_COLORS_ENUM {
  Alternatives = '#56B877',
  CashAndEquivalents = '#D1C5C5',
  Adjustments = '#6D6DB1',
  Commodities = '#E68B99',
  FixedIncome = '#EBDB18',
  Equities = '#5AABB3',
  Funds = '#56B877',
  RealEstate = '#002CE5',
  RealAssets = '#A569D2',
  CryptoCurrencies = '#FF9F05',
  Futures = '#A7B9ED',
  MixedAllocation = '#ED7A34',
  Fx = '#D32697',
  Other = '#D0C9F8',
}
export const ASSET_COLORS: Record<string, Color> = {
  [ASSETS.alternatives]: ASSET_COLORS_ENUM.Alternatives,
  [ASSETS.cash]: ASSET_COLORS_ENUM.CashAndEquivalents,
  [ASSETS.adjustments]: ASSET_COLORS_ENUM.Adjustments,
  [ASSETS.fixedIncome]: ASSET_COLORS_ENUM.FixedIncome,
  [ASSETS.equity]: ASSET_COLORS_ENUM.Equities,
  [ASSETS.fund]: ASSET_COLORS_ENUM.Funds,
  [ASSETS.realEstate]: ASSET_COLORS_ENUM.RealEstate,
  [ASSETS.realAssets]: ASSET_COLORS_ENUM.RealAssets,
  [ASSETS.cryptoCurrencies]: ASSET_COLORS_ENUM.CryptoCurrencies,
  [ASSETS.commodities]: ASSET_COLORS_ENUM.Commodities,
  [ASSETS.futures]: ASSET_COLORS_ENUM.Futures,
  [ASSETS.mixed]: ASSET_COLORS_ENUM.Futures,
  [ASSETS.fx]: ASSET_COLORS_ENUM.Fx,
  [ASSETS.other]: ASSET_COLORS_ENUM.Other,
};
