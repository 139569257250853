import cn from 'classnames';
import lodashGet from 'lodash/get';
import React, { PureComponent } from 'react';
import {
  AutoSizer,
  Column,
  defaultTableHeaderRowRenderer,
  defaultTableRowRenderer,
  Index,
  IndexRange,
  InfiniteLoader,
  SortDirection,
  SortDirectionType,
  Table,
  TableCellProps,
  TableHeaderProps,
  TableHeaderRowProps,
  TableProps,
  TableRowProps,
} from 'react-virtualized';
import { IPortfolioTrade } from '../../../models/IPortfolioTrade';
import { TABLE_PAGE_SIZE } from '../../../services/constants/constants';
import { CellRenderer } from './components/CellRenderer';
import { ITableColumn } from './models';
import './TableWrapper.scss';

type IProps = Omit<TableProps, 'width' | 'height' | 'rowCount'> & {
  columns: ITableColumn[];
  totalCount: number;
  columnClass?: string;
  headerClass?: string;
  selectedTrade?: IPortfolioTrade;
  renderFooter?: () => React.ReactNode;
};

class TableWrapper extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    columns: [],
    tableData: [],
    sort: {},
    rowClick: () => {},
    cellRenderer: (data: TableCellProps) => {
      return <CellRenderer {...data} />;
    },
    rowRenderer: (data: TableRowProps) => {
      return defaultTableRowRenderer({ ...data });
    },
    headerRowRender: (data: TableHeaderRowProps) => {
      return defaultTableHeaderRowRenderer({
        ...data,
        className: cn('ReactVirtualized__Table__headerRow', 'table-header'),
      });
    },
    onSortData: (sortBy: string, sortDirection: SortDirectionType) => {},
    onLoadData: (page: number) => {},
    renderFooter: () => null,
  };

  sort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    const { onSortData } = this.props;
    onSortData(sortBy, sortDirection);
  };

  isRowLoaded = ({ index }: Index) => {
    const { tableData } = this.props;
    return !!tableData[index];
  };

  loadMoreRows = ({ startIndex }: IndexRange): Promise<any> => {
    const page = Math.ceil(startIndex / TABLE_PAGE_SIZE);
    const { onLoadData } = this.props;
    onLoadData(page);
    return Promise.resolve();
  };

  headerRenderer(data: TableHeaderProps) {
    const { dataKey, label, sortBy, sortDirection } = data;

    const sortClass = cn('sort-direction-column', {
      'sort-direction-column--acs': sortBy === dataKey && sortDirection === SortDirection.ASC,
      'sort-direction-column--desc': sortBy === dataKey && sortDirection === SortDirection.DESC,
    });

    return <div className={sortClass}>{label}</div>;
  }

  render() {
    const {
      cellRenderer,
      tableData,
      rowClick,
      columns,
      rowHeight,
      headerHeight,
      rowClassName,
      totalCount,
      sort,
      columnClass,
      headerClass,
      renderFooter,
      ...otherProps
    } = this.props;

    let sortEnabled = false;

    const tableColumns = columns
      .filter((item) => item.display)
      .map((column) => {
        if (!column.disableSort) {
          sortEnabled = true;
          // console.log("HAVE A SORT", column)
        }
        return (
          <Column
            className={cn('table-column', `table-column-${column.dataKey}`, columnClass)}
            headerClassName={cn('table-header__column', `table-header__column--${column.dataKey}`, headerClass)}
            cellDataGetter={({ dataKey, rowData }) => lodashGet(rowData, dataKey)}
            key={column.dataKey}
            label={column.label}
            dataKey={column.dataKey}
            flexGrow={column.flexGrow}
            flexShrink={column.flexShrink}
            width={column.dataKey.toLowerCase() === 'status' ? 40 : column.width}
            disableSort={column.disableSort}
            cellRenderer={cellRenderer}
            headerRenderer={this.headerRenderer}
          />
        );
      });

    return (
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            minimumBatchSize={TABLE_PAGE_SIZE}
            rowCount={totalCount}
          >
            {({ onRowsRendered, registerChild }) => {
              return (
                <div data-testid={'table-wrapper'}>
                  <Table
                    ref={registerChild}
                    width={width}
                    height={height}
                    headerHeight={headerHeight}
                    headerClassName={cn('table-header')}
                    rowHeight={rowHeight}
                    rowCount={tableData.length}
                    rowGetter={({ index }) => tableData[index]}
                    rowStyle={(info) => ({ background: info.index % 2 !== 0 ? 'trasparent' : 'var(--secondary-bg)' })}
                    rowClassName={({ index }) => {
                      return cn(
                        'table-row',
                        rowClassName,
                        {
                          'selected-row':
                            this.props.selectedTrade &&
                            tableData[index] &&
                            tableData[index].key === this.props.selectedTrade.key,
                        },
                        this.props.onRowClick && 'hoverable'
                      );
                    }}
                    sort={sortEnabled ? this.sort : undefined}
                    onRowsRendered={onRowsRendered}
                    onRowClick={this.props.onRowClick}
                    {...otherProps}
                  >
                    {tableColumns}
                  </Table>
                  {renderFooter && renderFooter()}
                </div>
              );
            }}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }
}

export default TableWrapper;
