import * as React from 'react';
import useApi from '../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../services/constants/endpoints';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { circleAndTextRenderer } from '../../utils/esgCircleRenderer';
import { useState } from 'react';
import { Table } from '@iliotech/storybook';
import { Loader } from '../../../../../../components';
import s from './TopBottomTables.module.scss';
interface IProps {
  type: 'Equities' | 'Funds';
  portfolioId?: string;
}

const COLUMNS: ColumnProps[] = [
  { label: 'ESG', dataKey: 'total', cellRenderer: circleAndTextRenderer, flexGrow: 1 },
  { label: 'Environmental', dataKey: 'e', cellRenderer: circleAndTextRenderer, flexGrow: 1 },
  { label: 'Social', dataKey: 's', cellRenderer: circleAndTextRenderer, flexGrow: 1 },
  { label: 'Governance', dataKey: 'g', cellRenderer: circleAndTextRenderer, flexGrow: 1 },
];

const TopBottomTables = ({ type, portfolioId }: IProps) => {
  const [topData, setTopData] = useState<any[]>([]);
  const [bottomData, setBottomData] = useState<any[]>([]);

  const parseTopData = (rankingData: EsgRankingResponse) => {
    const temp: any[] = [];
    rankingData.top.e.forEach((_, index) => {
      temp.push({
        e: { label: rankingData.top.e?.[index]?.name, value: rankingData.top.e?.[index]?.esg.e },
        s: { label: rankingData.top.s?.[index]?.name, value: rankingData.top.s?.[index]?.esg.s },
        g: { label: rankingData.top.g?.[index]?.name, value: rankingData.top.g?.[index]?.esg.g },
        total: { label: rankingData.top.total?.[index]?.name, value: rankingData.top.total?.[index]?.esg.total },
      });
    });
    setTopData(temp);
  };

  const parseBottomData = (rankingData: EsgRankingResponse) => {
    const temp: any[] = [];
    rankingData.bottom.e.forEach((_, index) => {
      temp.push({
        e: { label: rankingData.bottom.e?.[index]?.name, value: rankingData.bottom.e?.[index]?.esg.e },
        s: { label: rankingData.bottom.s?.[index]?.name, value: rankingData.bottom.s?.[index]?.esg.s },
        g: { label: rankingData.bottom.g?.[index]?.name, value: rankingData.bottom.g?.[index]?.esg.g },
        total: { label: rankingData.bottom.total?.[index]?.name, value: rankingData.bottom.total?.[index]?.esg.total },
      });
    });
    setBottomData(temp);
  };

  const { data, isFetching } = useApi<EsgRankingResponse>(
    getApiUrl(`portfolio.esg.ranking`),
    {
      method: 'GET',
      params: {
        assetClass: type,
        portfolioId: portfolioId || undefined,
      },
    },
    {
      onSuccess: (rankingData) => {
        parseTopData(rankingData);
        parseBottomData(rankingData);
      },
    }
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div className={s.tablesContainer}>
      <div className={s.tableContainer}>
        <h2 className={s.title}>
          Top {portfolioId ? 'Portfolio ' : 'Global '}
          {type === 'Funds' ? 'Funds' : 'Companies'}
        </h2>
        <Table columns={COLUMNS} data={topData} rowHeight={35} headerHeight={35} />
      </div>
      <div className={s.tableContainer}>
        <h2 className={s.title}>
          Bottom {portfolioId ? 'Portfolio ' : 'Global '}
          {type === 'Funds' ? 'Funds' : 'Companies'}
        </h2>
        <Table columns={COLUMNS} data={bottomData} rowHeight={35} headerHeight={35} />
      </div>
    </div>
  );
};

export default TopBottomTables;
