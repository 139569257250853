import { ReduxHelper } from '../utils/ReduxHelper';

import { getApiUrl } from '../constants/endpoints';

export class IndustryActions {
  static readonly INDUSTRY_REQUEST = 'INDUSTRY_REQUEST';
  static readonly INDUSTRY_SUCCESS = 'INDUSTRY_SUCCESS';
  static readonly INDUSTRY_ERROR = 'INDUSTRY_ERROR';

  static fetchAllIndustry() {
    return ReduxHelper.createHttpRequestActionBundle<IIndustry[]>(
      {
        url: getApiUrl('industrysector.all'),
      },
      IndustryActions.INDUSTRY_REQUEST,
      IndustryActions.INDUSTRY_SUCCESS,
      IndustryActions.INDUSTRY_ERROR
    );
  }
}
