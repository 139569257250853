import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';

import Alternatives from './Alternatives';
import { AssetReportWrapper } from '../../components/AssetReportWrapper';

import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import AlternativesActions from '../../../../../../../../services/actions/AlternativesActions';

import { IRootState } from '../../../../../../../../services/store';

import { IWithPortfolioInfoProps } from '../../../../../../../../components/HOC/withPortfolioInfo';

import { withAssetLogic, IAssetReportProps } from '../../HOC/withAssetLogic';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';

interface IMapStateToProps {
  distributions: IBarData[];
  dashboardInfo: IDashboardAssetClass;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  fetchDistributions: (portfolioId: string) => AxiosPromise<IBarData[]>;
  resetData: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps & IWithPortfolioInfoProps & IAssetReportProps;

interface IState {
  allLoaded: boolean;
}

class AlternativesContainer extends PureComponent<IProps, IState> {
  readonly state: IState = {
    allLoaded: false,
  };

  componentDidMount(): void {
    this._fetchData().then(() => {
      this.setState({ allLoaded: true });
    });
  }

  componentWillUnmount(): void {
    this.props.resetData();
  }

  render() {
    const { allLoaded } = this.state;
    const { distributions, dashboardInfo, assetDataLoaded, noData, portfolioCurrencyFormatter } = this.props;

    return (
      <AssetReportWrapper title="Alternatives" allLoaded={assetDataLoaded && allLoaded} noData={noData}>
        <Alternatives
          dashboardInfo={dashboardInfo}
          distributions={distributions}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        />
      </AssetReportWrapper>
    );
  }

  private _fetchData(): Promise<any> {
    const { portfolioId, fetchDistributions } = this.props;
    return Promise.all([fetchDistributions(portfolioId)]);
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    distributions: state.dashboard.alternatives.distribution.data,
    dashboardInfo: state.dashboard.totalValues.info.data,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchDistributions: (portfolioId: string) => dispatch(AlternativesActions.fetchDistributions(portfolioId)),
  resetData: () => dispatch(AlternativesActions.resetAlternativesData()),
});

export default withAssetLogic(connect(mapStateToProps, mapDispatchToProps)(AlternativesContainer));
