import React, { Fragment } from 'react';
import { Table } from '@iliotech/storybook';
import Whisper from 'rsuite/lib/Whisper';
import Popover from 'rsuite/lib/Popover/Popover';
import { IRootState } from '../../../../../../../../../../services/store';
import { IPortfolioInfoState } from '../../../../../../../../../../services/reducers/PortfolioReducers';
import { COLORS } from './OptionsConstants';
import { cloneDeep } from 'lodash';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../../../../../components/ContextHelp';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../../../services/constants/tooltipContextHelp';

const DEFAULT_COLUMN_WIDTH = 90;
const NUM_COLUMNS = 17;
const LABEL_COLUMN_WIDTH = 150;
const PREFERRED_WIDTH = (NUM_COLUMNS - 1) * DEFAULT_COLUMN_WIDTH + LABEL_COLUMN_WIDTH;

type OptionType = 'longCalls' | 'shortCalls' | 'longPuts' | 'shortPuts';

interface ISettingsProps {
  width?: number;
  optionsDashboard: IRootState['dashboard']['options']['dashboard'];
  portfolioInfo: IPortfolioInfoState;
}

const DEFAULT_VALUES: Record<string, { value: number }> = {
  initialPremium: { value: 0 },
  currentValue: { value: 0 },
  // costPrice: { value: 0 },
  // currentPrice: { value: 0 },
  notional: { value: 0 },
  deltaExposure: { value: 0 },
  pnlBase: { value: 0 },
};

const OptionsTables = ({ width = 800, optionsDashboard, portfolioInfo }: ISettingsProps) => {
  const optionsData = optionsDashboard.data;
  const currencySymbol = portfolioInfo.data?.currency?.symbol;

  const tables = React.useMemo(() => {
    const getOptions = (key: OptionType) => optionsData?.[key] ?? [];

    const getSubtotal = (arr: OptionDetails[], name: string) => {
      const currencyTotals: Record<string, Record<string, { value: number }>> = {};

      arr.forEach((data) => {
        if (!currencyTotals[data.currency]) {
          currencyTotals[data.currency] = cloneDeep(DEFAULT_VALUES);
        }

        const d = currencyTotals[data.currency as any];
        Object.keys(d).forEach((key) => {
          if (key !== 'currency') {
            if (!d[key]) {
              d[key] = { value: 0 };
            }
            // console.log(data.pnlBase);
            d[key].value += (data[key as 'initialPremium'] as any)?.value ?? (data[key as 'initialPremium'] as any);
          }
        });
        d.currency = data.currency as any;
      });

      return Object.entries(currencyTotals).map(
        ([currency, totals]) => (({ name: `${name} ${currency}`, ...totals } as any) as OptionDetails)
      );

      // return { data: ({ ...d, name } as any) as OptionDetails, emptyRow: false };
    };

    const longCalls = getOptions('longCalls');
    const shortCalls = getOptions('shortCalls');
    const longPuts = getOptions('longPuts');
    const shortPuts = getOptions('shortPuts');

    const totalLongCalls = getSubtotal(longCalls, 'Total Long Calls');
    const totalShortCalls = getSubtotal(shortCalls, 'Total Short Calls');
    // const totalLongs = getSubtotal([...longCalls, ...longPuts] as any, 'Total Longs');
    const totalLongPuts = getSubtotal(longPuts, 'Total Long Puts');
    const totalShortPuts = getSubtotal(shortPuts, 'Total Short Puts');
    // const totalShorts = getSubtotal([...shortCalls, ...shortPuts] as any, 'Total Shorts');

    return {
      longCalls: [...longCalls, ...totalLongCalls],
      longPuts: [...longPuts, ...totalLongPuts],
      shortCalls: [...shortCalls, ...totalShortCalls],
      shortPuts: [...shortPuts, ...totalShortPuts],
    };
  }, [optionsData]);

  const defaultWidth = width > PREFERRED_WIDTH ? (width - LABEL_COLUMN_WIDTH) / (NUM_COLUMNS - 1) : DEFAULT_COLUMN_WIDTH;
  const moneynessColumnWidth = (width - 50 - 100) / 4 / 2;

  const drawTable = (key: string, label: string, data: any, color: string) => {
    // const data = getOptions(key);

    return (
      <div style={{ marginBottom: '1rem' }}>
        {/* @ts-ignore */}
        <Table
          rowHeight={40}
          columns={getColumns(label, defaultWidth, currencySymbol)}
          data={data}
          {...{ headerSettings, bodySettings }}
          tableSettings={{
            ...tableSettings,
            width,
            height: Math.max(data.length, 1) * 32 + 34,
          }}
        />
      </div>
    );
  };

  const moneynessColumns = getMoneynessColumns(currencySymbol);

  const totalRow: any = {
    name: 'Total',
    initialPremium: optionsData?.totalPremium,
    currentValue: optionsData?.totalCurrentValue,
    deltaExposure: optionsData?.totalDeltaExposure,
    notional: optionsData?.totalNotional,
    pnlBase: optionsData?.totalPnlBase,
    currency: portfolioInfo.data?.currency.name,
  };

  return (
    <div style={{ width }}>
      {drawTable('longCalls', 'Long Calls', tables.longCalls, COLORS.LONG_CALL)}
      {drawTable('longPuts', 'Long Puts', tables.longPuts, COLORS.LONG_PUT)}
      {drawTable('shortCalls', 'Short Calls', tables.shortCalls, COLORS.SHORT_CALL)}
      {drawTable('shortPuts', 'Short Puts', tables.shortPuts, COLORS.SHORT_PUT)}
      <div style={{ marginBottom: '1rem' }}>
        {/* @ts-ignore */}
        <Table
          rowHeight={40}
          columns={getColumns('Grand Total', defaultWidth, currencySymbol)}
          data={[totalRow]}
          {...{ headerSettings, bodySettings }}
          tableSettings={{
            ...tableSettings,
            width,
            height: 32 + 34,
          }}
        />
      </div>
      {/*{drawTable('grandTotal', 'Total', , COLORS.SHORT_PUT)}*/}
      <div style={{ marginBottom: '1rem', display: 'flex', width: '100%' }}>
        {/* @ts-ignore */}

        <div style={{ width: '48%' }}>
          <h3>
            Calls <ContextHelpIcon title={'Calls'} elementId={'CallsTable'} />
          </h3>
          <div style={{ height: 20 }} />
          <ContextHelpTooltip
            elementId={'CallsTable'}
            tooltipTitle={'Calls'}
            tooltipText={DASHBOARD_EQUITY_HELP_TEXT.OptionsCallsTable}
          />
          {/* @ts-ignore */}
          <Table
            rowHeight={40}
            columns={moneynessColumns}
            data={optionsData?.callsByMoneyness ?? []}
            {...{ headerSettings }}
            bodySettings={{
              ...bodySettings,
              height: 40,
            }}
            tableSettings={{
              ...tableSettings,
              height: 420,
              rowHeight: 32,
              width: moneynessColumnWidth * 4 + 50,
            }}
          />
        </div>
        <div style={{ flex: 1 }} />
        <div style={{ width: '48%' }}>
          <h3>
            Puts <ContextHelpIcon title={'Puts'} elementId={'PutsTable'} />
          </h3>
          <div style={{ height: 20 }} />
          <ContextHelpTooltip
            elementId={'PutsTable'}
            tooltipTitle={'Puts'}
            tooltipText={DASHBOARD_EQUITY_HELP_TEXT.OptionsPutsTable}
          />
          <Table
            rowHeight={40}
            columns={moneynessColumns}
            data={optionsData?.putsByMoneyness ?? []}
            {...{ headerSettings }}
            bodySettings={{
              ...bodySettings,
              height: 40,
            }}
            tableSettings={{
              ...tableSettings,
              height: 420,
              rowHeight: 32,
              width: moneynessColumnWidth * 4 + 50,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const headerSettings = {
  fontSize: 10,
  fontFamily: 'var(--font-family)',
  backgroundColor: 'black',
  color: 'white',
  padding: 10,
  paddingTop: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const bodySettings = {
  fontSize: 10,
  fontFamily: 'var(--font-family)',
  backgroundColor: '#1D202E',
  color: 'white',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 20,
  borderColor: 'transparent',
  borderWidth: 5,
  margin: 0,
  firstRowColor: 'transparent',
  secondRowColor: 'transparent',
};

const tableSettings = {
  rowHeight: 32,
  headerHeight: 30,
  width: DEFAULT_COLUMN_WIDTH * NUM_COLUMNS + 120,
  height: 100,
};

const currencyCellRenderer = (currencySymbol: string | undefined, decimalPlaces: number = 2) => (
  rowData: any,
  dataKey: string
) => {
  // console.log('rowData', rowData);
  // console.log('datakey', dataKey);

  const value = rowData[dataKey]?.value ?? rowData[dataKey];
  if (!value) {
    return <div />;
  }
  return (
    <div>
      {currencySymbol ? (rowData.currency ? `${rowData.currency.value} ` : '') : ''}
      {value.toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      })}
    </div>
  );
};

const getColumns = (label: string, defaultWidth: number, currencySymbol: string = ''): any[] => [
  {
    dataKey: 'name',
    label,
    width: LABEL_COLUMN_WIDTH,
    align: 'center',
    fixed: 'left',
  },
  {
    dataKey: 'openQuantity',
    label: 'Position',
    width: defaultWidth,
    align: 'center',
  },
  {
    dataKey: 'currency',
    label: `Currency`,
    width: defaultWidth,
    // align: 'left',
  },
  {
    dataKey: 'underlyingSpotPrice',
    label: 'Underlying Spot',
    width: defaultWidth,
    // align: 'right',
  },
  {
    dataKey: 'initialPremium',
    label: `Initial Premium`,
    width: defaultWidth,
    // align: 'left',
    // cellRenderer: currencyCellRenderer(currencySymbol, 0),
    cellRenderer: currencyCellRenderer(undefined, 0),
  },
  {
    dataKey: 'initialPremiumBase',
    label: `Initial Premium (Base)`,
    width: defaultWidth,
    // align: 'left',
    // cellRenderer: currencyCellRenderer(currencySymbol, 0),
    cellRenderer: currencyCellRenderer(undefined, 0),
  },
  {
    dataKey: 'currentValueBase',
    label: `Current Premium`,
    width: defaultWidth,
    // align: 'left',
    // cellRenderer: currencyCellRenderer(currencySymbol, 0),
    cellRenderer: currencyCellRenderer(undefined, 0),
  },
  {
    dataKey: 'costPrice',
    label: `Cost Price`,
    width: defaultWidth,
    // align: 'left',
    cellRenderer: currencyCellRenderer(undefined),
  },
  {
    dataKey: 'currentPrice',
    label: `Current Price`,
    width: defaultWidth,
    // align: 'left',
    cellRenderer: currencyCellRenderer(undefined),
  },
  {
    dataKey: 'currentValue',
    label: `Current Value`,
    width: defaultWidth,
    cellRenderer: currencyCellRenderer(undefined, 0),
  },
  {
    dataKey: 'notional',
    label: `Notional`,
    width: defaultWidth,
    cellRenderer: currencyCellRenderer(undefined, 0),
  },
  {
    dataKey: 'deltaExposure',
    label: `Delta Exposure`,
    width: defaultWidth,
    cellRenderer: currencyCellRenderer(undefined, 0),
  },
  {
    dataKey: 'delta',
    label: 'Delta',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey: string) => {
      const value = rowData[dataKey]?.value ?? rowData[dataKey];
      return <div>{value ? `${(value * 100)?.toFixed(1)}%` : ''}</div>;
    },
  },
  {
    dataKey: 'impliedVol',
    label: 'Implied Vol',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey: string) => {
      const value = rowData[dataKey]?.value ?? rowData[dataKey];
      return <div>{value ? `${value.toFixed(1)}%` : ''}</div>;
    },
  },
  {
    dataKey: 'mat',
    label: 'Maturity (Yrs)',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey: string) => {
      const speaker = (
        <Popover>
          <div>Option close to expiry</div>
        </Popover>
      );
      return (
        <div>
          <Whisper speaker={speaker} trigger={'hover'}>
            {rowData.maturity <= 0.08 ? (
              <div style={{ color: 'var(--text-warning-color)' }}>{rowData.maturity}</div>
            ) : (
              <div>{rowData.maturity}</div>
            )}
          </Whisper>
        </div>
      );
    },
  },
  {
    dataKey: 'moneyness',
    label: 'Moneyness',
    fixed: 'right',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey = 'moneyness') => {
      const value = rowData[dataKey]?.value ?? rowData[dataKey];
      return (
        <div
        // style={{
        //   color:
        //     rowData.moneyness.value >= 103
        //       ? 'var(--text-success-color)'
        //       : rowData.moneyness.value <= 97
        //       ? 'var(--text-alert-color)'
        //       : undefined,
        // }}
        >
          {typeof value !== 'undefined' ? `${value}%` : ''}
        </div>
      );
    },
  },
  {
    dataKey: 'annualYield',
    label: 'Ann Yield',
    fixed: 'right',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey: string) => {
      const value = rowData[dataKey]?.value ?? rowData[dataKey];
      return <div>{value ? `${value.toFixed(2)}%` : ''}</div>;
    },
  },
  {
    dataKey: 'returnPercent',
    label: '% Return',
    fixed: 'right',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey: string) => {
      const value = rowData[dataKey]?.value ?? rowData[dataKey];
      return (
        <div style={{ color: value > 0 ? 'var(--text-success-color)' : 'var(--text-alert-color)' }}>
          {value ? `${value.toFixed(2)}%` : ''}
        </div>
      );
    },
  },
  {
    dataKey: 'pnlBase',
    label: `${currencySymbol} P&L`,
    fixed: 'right',
    width: defaultWidth,
    cellRenderer: (rowData: any, dataKey: string) => {
      const value = rowData[dataKey]?.value ?? rowData[dataKey];
      return (
        <div style={{ color: value > 0 ? 'var(--text-success-color)' : 'var(--text-alert-color)' }}>
          {currencySymbol ? `${currencySymbol} ` : ''}
          {value?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
      );
    },
  },
];

const getMoneynessColumns = (currencySymbol: string = ''): any => [
  {
    dataKey: 'label',
    label: 'Moneyness',
    flexGrow: 1,
    align: 'center',
  },
  {
    dataKey: 'premium',
    label: `Current Premium`,
    flexGrow: 1,
    align: 'center',
    cellRenderer: currencyCellRenderer(currencySymbol, 0),
  },
  {
    dataKey: 'notional',
    label: `Notional`,
    align: 'center',
    flexGrow: 1,
    cellRenderer: currencyCellRenderer(currencySymbol, 0),
  },
  {
    dataKey: 'deltaExposure',
    label: `Delta Exposure`,
    flexGrow: 1,
    align: 'center',
    cellRenderer: currencyCellRenderer(currencySymbol, 0),
  },
];

export default OptionsTables;
