import React from 'react';
import ReactDOM from 'react-dom';
import Confirm, { IConfirmOptions } from '../../components/UIWidgets/Confirm/Confirm';

import { Notification } from '../../components/UIWidgets/Notification';
import { ConfirmWithTextInput } from '../../components/UIWidgets/ConfirmWithTextInput';
import { IConfirmWithTextOptions } from '../../components/UIWidgets/ConfirmWithTextInput/ConfirmWithTextInput';

export class Utils {
  static downloadData(data: string, fileName: string) {
    const blob: Blob = new Blob([data], { type: 'text/plain' });
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.download = `${fileName}.txt`;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
    anchor.click();
  }

  static createConfirmWithInput(props: Partial<IConfirmWithTextOptions>): void {
    const wrapper: HTMLElement = document.body.appendChild(document.createElement('div'));

    const onSuccess = (v: string) => {
      Utils.removeComponentAtNode(wrapper);
      if (props.onConfirm && v !== props.defaultValue) {
        props.onConfirm(v);
      }
    };

    const onError = () => {
      Utils.removeComponentAtNode(wrapper);
      if (props.onCancel) {
        props.onCancel();
      }
    };

    ReactDOM.render(<ConfirmWithTextInput {...props} onCancel={onError} onConfirm={onSuccess} />, wrapper);
  }

  static createConfirm(props: Partial<IConfirmOptions>): void {
    const wrapper: HTMLElement = document.body.appendChild(document.createElement('div'));
    const onSuccess = () => {
      Utils.removeComponentAtNode(wrapper);
      if (props.onConfirm) {
        props.onConfirm();
      }
    };

    const onError = () => {
      Utils.removeComponentAtNode(wrapper);
      if (props.onCancel) {
        props.onCancel();
      }
    };

    ReactDOM.render(<Confirm {...props} onCancel={onError} onConfirm={onSuccess} />, wrapper);
  }

  static showNotification(notification: INotification): void {
    const wrapper: HTMLElement = document.body.appendChild(document.createElement('div'));
    ReactDOM.render(
      <Notification notification={notification} outSideClick={() => Utils.removeComponentAtNode(wrapper)} />,
      wrapper
    );
  }

  static removeComponentAtNode(wrapper: Element): void {
    ReactDOM.unmountComponentAtNode(wrapper);
    setTimeout(() => {
      wrapper.remove();
    });
  }

  static getKeyByValue(object: Record<string, string>, value: string) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  static getQuantityDecimals = (assetsClasses: IAssetClass[], tradeTypeId: string) => {
    return assetsClasses.find((assetClass: IAssetClass) => assetClass.id === tradeTypeId)?.quantityDecimals || 2;
  };

  static getPriceDecimals = (assetsClasses: IAssetClass[], tradeTypeId: string) => {
    return tradeTypeId !== undefined && tradeTypeId !== null
      ? assetsClasses.find((assetClass: IAssetClass) => assetClass.id === tradeTypeId)?.priceDecimals || 2
      : 2;
  };
}
