import cn from 'classnames';
import s from './ToggableChartLegend.module.scss';
import React from 'react';
import ToggableItem, { ILegendItem } from './components/ToggableItem';

interface IProps {
  legend: ILegendItem[];
  onItemPress: (key: string) => void;
}

const ToggableChartLegend = ({ legend, onItemPress }: IProps) => {
  return (
    <div className={cn(s.legend)}>
      {legend.map((legendItem, idx: number) => (
        <ToggableItem key={idx} item={legendItem} onPress={onItemPress} />
      ))}
    </div>
  );
};

export default ToggableChartLegend;
