import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  quantityDecimals: number;
}

const Multiplier: FC<IProps> = ({ quantityDecimals = 3 }) => {
  return (
    <FormFieldWrapper label="Multiplier" required={true} className={cn(s.minField)}>
      <Field
        name="multiplier"
        placeholder="0"
        type="number"
        className="input--small"
        theme="inverse"
        component={renderInput}
        disabled={true}
        normalize={(value: number) => positiveNumber(floatNumberV2(value, quantityDecimals)).toString()}
      />
    </FormFieldWrapper>
  );
};

export default Multiplier;
