import React, { FC } from 'react';
import cn from 'classnames';
import s from '../PortfolioTransfer.module.css';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../services/store';

const PortfoliosList = () => {
  const { portfolioInfo, portfoliosList } = useSelector(createSelector(mapStateToProps, (st) => st));

  const workgroup = portfolioInfo.data?.workgroup;

  const portfoliosInWorkgroup = React.useMemo(() => {
    if (!workgroup?.id) {
      return [];
    }
    return portfoliosList.filter((p) => p.workgroup.id === workgroup?.id);
  }, [workgroup?.id]);

  const height = `${Math.max(Math.min(portfoliosInWorkgroup.length, 4), 1) * 1.2 + 0.5}rem`;

  return (
    <div className={cn(s.row)} style={{ maxHeight: height }}>
      <div className={cn(s.rowLabel)}>
        {portfoliosInWorkgroup.length} Client Portfolio{portfoliosInWorkgroup.length > 1 ? `s` : ``}:
      </div>
      <div
        className={cn(s.userListScrollWrap)}
        style={{ flex: 1, border: '1px solid var(--text-color)', padding: 4, height }}
      >
        <div
          className={cn(s.userListSurround)}
          style={{ background: 'transparent', overflowY: portfoliosInWorkgroup.length < 4 ? 'clip' : 'hidden' }}
        >
          {portfoliosInWorkgroup.map((p) => (
            <div key={p.id} className={cn(s.portfolioRow)}>
              {p.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  portfolioInfo: state.portfolio.portfolioInfo,
  portfoliosList: state.portfoliosList.data,
});

export default PortfoliosList;
