import React from 'react';
import { useTypedSelector } from '../../services/hooks/useTypedSelector';
import AccountSelector from './AccountSelector/AccountSelector';
import SignInScreen from '../../scenes/Account/scenes/SigninScreen/SignInScreen';
import { TermsAcknowledgement } from './AccountSelector/TermsAcknowledgement';
import { useAccount } from '../../services/context/AuthContext';
import { usePreferences } from '../../services/context/PreferencesContext';
import { AccessDisallowed } from '../AccessDisallowed/AccessDisallowed';
import { useLocation, useParams } from 'react-router';
import { useIframeMode } from 'src/services/hooks/useIframeMode';

const REQUIRED_VIEW = 'enterprise';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const AuthGuard: React.FC = ({ children }) => {
  const { illioSelectedAccount, selectIllioAccount } = useAccount();
  let query = useQuery();
  const externalAccountId = query.get('externalAccountId');
  const { fetchUserPreferences } = usePreferences();
  const iframeMode = useIframeMode();
  const { authStatus, accounts, accountStatus, userCapabilities } = useTypedSelector((state) => ({
    authStatus: state.app.account.authStatus,
    accountStatus: state.app.account.accountStatus,
    accounts: state.accounts,
    userCapabilities: state.userCapabilities.data,
  }));

  const preSelectedAccount = React.useMemo(() => {
    if (externalAccountId) {
      return accounts.data.find((account: any) => account.externalId === externalAccountId)?.id;
    }
  }, [externalAccountId, accounts]);

  const accessRejected = React.useMemo(() => {
    const metadata = userCapabilities?.metadata;
    const canAccessThisFrontend = metadata?.allowedViews?.includes(REQUIRED_VIEW);

    return metadata && !canAccessThisFrontend;
  }, [userCapabilities]);

  React.useEffect(() => {
    if (authStatus === 'signedin') {
      fetchUserPreferences();
    }
  }, [authStatus]);

  React.useEffect(() => {
    if (preSelectedAccount) selectIllioAccount(preSelectedAccount!);
  }, [preSelectedAccount]);

  if (preSelectedAccount && !illioSelectedAccount) {
    return null;
  }
  if (authStatus !== 'signedin') {
    return <SignInScreen />;
  }

  if (illioSelectedAccount === null || typeof illioSelectedAccount === 'undefined' || accounts.isFetching) {
    return (
      <AccountSelector
        illioSelectedAccount={illioSelectedAccount || null}
        selectIllioAccount={selectIllioAccount}
        accounts={accounts}
      />
    );
  }

  if (accountStatus === 'TERMS_NOT_AGREED') {
    return <TermsAcknowledgement />;
  }

  if (accessRejected && !iframeMode) {
    return <AccessDisallowed />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
