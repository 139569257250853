import { AxiosPromise } from 'axios';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import InstrumentIncome from './InstrumentIncome';

import { Utils } from '../../../../../../services/utils/Utils';

import { IncomeActions } from '../../../../../../services/actions';
import { AsyncActionDispatch } from '../../../../../../services/utils/ReduxHelper';
import { AppActions } from '../../../../../../services/actions/AppActions';

import { IRootState } from '../../../../../../services/store';

import { IPaginationContent } from '../../../../../../models/redux/ILoadingPaginationDataState';
import { getCurrencyOptions } from '../../../../../../services/selectors/currency';
import { IOption } from '../../../../../../components/UIWidgets/Autocomplete';

import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { NotificationType } from '../../../../../../models/NotifictionType';

interface IMapStateToProps {
  instrumentInfo: IInstrument | null;
  incomeData: IIncome[];
  incomeDataIsFetched: boolean;
  incomePeriod: IPeriod;
  incomeOrder: IOrder;
  currencyOptions: Array<IOption<ICurrency>>;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  fetchInstrumentIncome: (
    instrumentId: string,
    order: IOrder,
    period: IPeriod,
    page?: number
  ) => AxiosPromise<IPaginationContent<IIncome>>;
  changePricePeriod: (period: IPeriod) => void;
  createIncome: (income: IIncome) => AxiosPromise<IIncome | void>;
  updateIncome: (income: IIncome) => AxiosPromise<IIncome>;
  deleteIncome: (income: IIncome) => AxiosPromise<void>;
  changeIncomeSort: (order: IOrder) => void;
  showNotification: (notification: INotification) => void;
  resetIncomeList: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps;

class InstrumentIncomeContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    this.fetchIncomeDate();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { instrumentInfo, incomeOrder, incomePeriod } = this.props;
    if (
      (prevProps.instrumentInfo !== instrumentInfo && instrumentInfo && instrumentInfo.id) ||
      prevProps.incomePeriod !== incomePeriod ||
      prevProps.incomeOrder !== incomeOrder
    ) {
      this.fetchIncomeDate();
    }
  }

  componentWillUnmount(): void {
    this.props.resetIncomeList();
  }

  handleOnIncomeSave = (income: IIncome): AxiosPromise<IIncome | void> => {
    const { showNotification, createIncome } = this.props;
    return createIncome(income)
      .then((response: any) => {
        this.fetchIncomeDate();
        return response;
      })
      .catch(() => {
        showNotification({
          text: "Income hasn't been added",
          type: NotificationType.ERROR,
        });
      });
  };

  handleOnIncomeUpdate = (income: IIncome): AxiosPromise<IIncome> => {
    return this.props.updateIncome(income).then((response) => {
      this.fetchIncomeDate();
      return response;
    });
  };

  handleOnIncomeDelete = (income: IIncome) => {
    const { deleteIncome } = this.props;
    Utils.createConfirm({
      text: `Do you really want to remove <b>${income.businessDate}</b>? This action cannot be undone.`,
      confirmBtnText: 'Remove',
      onConfirm: () => {
        deleteIncome(income).then(() => {
          this.fetchIncomeDate();
        });
      },
    });
  };

  render() {
    const {
      instrumentInfo,
      incomeData,
      incomePeriod,
      incomeOrder,
      currencyOptions,
      changePricePeriod,
      changeIncomeSort,
      portfolioCurrencyFormatter,
      incomeDataIsFetched,
    } = this.props;

    if (!instrumentInfo || !instrumentInfo.id) {
      return <Fragment />;
    }

    return (
      <InstrumentIncome
        instrumentId={instrumentInfo.id}
        instrumentCurrency={instrumentInfo.currency.id}
        incomeData={incomeData}
        incomeDataIsFetched={incomeDataIsFetched}
        incomePeriod={incomePeriod}
        incomeOrder={incomeOrder}
        currencyOptions={currencyOptions}
        onPeriodChange={changePricePeriod}
        onIncomeSave={this.handleOnIncomeSave}
        onIncomeUpdate={this.handleOnIncomeUpdate}
        onIncomeDelete={this.handleOnIncomeDelete}
        onChangeMarketSort={changeIncomeSort}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
      />
    );
  }

  fetchIncomeDate = () => {
    const { instrumentInfo, incomeOrder, incomePeriod, fetchInstrumentIncome } = this.props;
    if (!instrumentInfo || !instrumentInfo.id) {
      return;
    }
    fetchInstrumentIncome(instrumentInfo.id, incomeOrder, incomePeriod);
  };
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    instrumentInfo: state.instruments.instrumentInfo.info.data,
    incomeData: state.instruments.instrumentInfo.income.content,
    incomeDataIsFetched: state.instruments.instrumentInfo.income.isFetched,
    incomePeriod: state.instruments.instrumentInfo.income.period,
    incomeOrder: state.instruments.instrumentInfo.income.order,
    currencyOptions: getCurrencyOptions(state),
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchInstrumentIncome: (instrumentId: string, order: IOrder, period: IPeriod) =>
    dispatch(IncomeActions.fetchAllIncomes(instrumentId, order, period)),
  changePricePeriod: (period: IPeriod) => dispatch(IncomeActions.changeIncomePeriod(period)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  createIncome: (income: IIncome) => dispatch(IncomeActions.createIncome(income)),
  updateIncome: (income: IIncome) => dispatch(IncomeActions.updateIncome(income)),
  deleteIncome: (income: IIncome) => dispatch(IncomeActions.deleteIncome(income)),
  changeIncomeSort: (order: IOrder) => dispatch(IncomeActions.changeIncomeSort(order)),
  resetIncomeList: () => dispatch(IncomeActions.resetIncomeList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentIncomeContainer);
