import { useTypedSelector } from './useTypedSelector';
import React from 'react';
import { getPortfolioCurrencyFormatter } from '../selectors/portfolio';
import { ALLOCATION_TYPES } from '../constants/constants';
import useApi from './useApi';
import { getApiUrl } from '../constants/endpoints';
import { usePortfolioTopPositions } from './apiHooks/usePortfolioTopPositions';

export const usePortfolio = (assetClassId?: string) => {
  const [assetClassIds, setAssetClassIds] = React.useState<string[]>([]);
  const [portfolioCurrency, setPortfolioCurrency] = React.useState<string>('');
  const [portfolioSymbol, setPortfolioSymbol] = React.useState<string>('');
  const { portfolioInfo, portfolioStructure, portfolioCurrencyFormatter } = useTypedSelector((state) => ({
    portfolioInfo: state.portfolio.portfolioInfo,
    portfolioStructure: state.portfolio.portfolioStructure,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  }));

  const topPositionsByPnl = usePortfolioTopPositions(assetClassId ? { assetClassId } : undefined);

  const dashboardInfo = useApi(
    getApiUrl('dashboard.info'),
    {
      method: 'POST',
      data: {
        portfolioId: portfolioInfo.data?.id,
        asset: {
          type: ALLOCATION_TYPES.AssetClass,
          id: assetClassId,
        },
      },
    },
    {
      enabled: !!portfolioInfo.data?.id && !!assetClassId,
      refetchOnMount: false,
    }
  );

  React.useEffect(() => {
    const newPortfolioAssetIds: string[] = ['CashAndEquivalents'];

    Object.keys(portfolioStructure.data).forEach((el) => {
      if (el !== 'CashAndEquivalents') {
        newPortfolioAssetIds.push(el);
      }
    });

    setAssetClassIds(newPortfolioAssetIds);
  }, [portfolioStructure]);

  React.useEffect(() => {
    if (portfolioInfo.data?.id) {
      setPortfolioCurrency(portfolioInfo.data.currency.name);
      setPortfolioSymbol(portfolioInfo.data.currency.symbol);
    }
  }, [portfolioInfo]);

  const isLoading = portfolioInfo.isFetching || dashboardInfo.isLoading || topPositionsByPnl.isLoading;
  const isFetched = portfolioInfo.isFetched && topPositionsByPnl.isFetched && dashboardInfo.isFetched;
  const hasData = topPositionsByPnl?.data?.length > 0;

  return React.useMemo(
    () => ({
      refetchTopPositions: topPositionsByPnl.refetch,
      portfolioInfo,
      portfolioStructure,
      portfolioAssetClassIds: assetClassIds,
      portfolioCurrency,
      portfolioSymbol,
      dashboardInfo: dashboardInfo.data,
      portfolioCurrencyFormatter,
      hasData,
      isLoading,
      isFetched,
      // isLoading: portfolioInfo.isFetching || !dashboardInfo?.data.total?.value || topPositionsByPnl.isLoading,
      // isFetched: portfolioInfo.isFetched && topPositionsByPnl.isFetched && dashboardInfo?.data.total?.value,
    }),
    [
      topPositionsByPnl.refetch,
      portfolioInfo,
      portfolioStructure,
      assetClassIds,
      portfolioCurrency,
      portfolioSymbol,
      dashboardInfo,
      portfolioCurrencyFormatter,
      hasData,
      isLoading,
      isFetched,
    ]
  );
};
