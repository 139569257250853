import React, { Fragment, useEffect, useState } from 'react';
import s from '../../Cash.module.scss';
import { ProfitChart, TopListWidgetsContainer, Widget } from '../../../../../../../../components';
import { Card } from '@iliotech/storybook';
import TopPositionsChart from '../../TopPositionsChart';
import { DASHBOARD_CASH_HELP_TEXT } from '../../../../../../../../../../services/constants/tooltipContextHelp';
import { getTopPositionsColumns } from '../../topPositionsColumns';
import { cashCellRenderer } from '../../utils/CashCellRenderer';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { StylesConfig } from 'react-select/lib/styles';
import { Loader, Select } from '../../../../../../../../../../components';
import useApi from '../../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../../../services/utils/PeriodHelper';
import { ALLOCATION_TYPES } from '../../../../../../../../../../services/constants/constants';
import NoData from '../../../../components/NoData/NoData';

interface IProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioCurrency: string;
  portfolioSymbol: string;
  portfolioId: string;
  assetClass: string;
  profitPeriod: IPeriod;
}
const Summary = ({
  portfolioId,
  portfolioCurrency,
  portfolioSymbol,
  portfolioCurrencyFormatter,
  assetClass,
  profitPeriod,
}: IProps) => {
  const [selectedCashChartFilter, setSelectedCashChartFilter] = useState(0);
  const type: ALLOCATION_TYPES = ALLOCATION_TYPES.AssetClass;
  const [topListOrder, setTopListOrder] = useState<IOrder>({ direction: 'DESC', name: 'marketValueBase' });
  const profitChart = useApi(getApiUrl('portfolio.profit.chart'), {
    method: 'POST',
    data: {
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(profitPeriod, 'MONTH_2_MONTH'),
      assetClassId: assetClass,
    },
  });

  const topListRanked = useApi(
    getApiUrl('topPositions'),
    {
      method: 'POST',
      data: {
        filter: { id: assetClass, type },
        number: 100,
        order: topListOrder,
        portfolioId,
        custodians: [],
        valuationDate: undefined,
      },
    },
    { onSuccess: (data) => console.log('data', data) }
  );

  useEffect(() => {
    profitChart.refetch();
  }, [profitPeriod]);

  useEffect(() => {
    topListRanked.refetch();
  }, [topListOrder]);

  if (topListRanked.isLoading || profitChart.isLoading) {
    return <Loader />;
  }

  if (topListRanked.data.length === 0) {
    return <NoData />;
  }

  const filterCashPosByCat = (): React.ReactNode => {
    const selectStyle: StylesConfig = { singleValue: () => ({ display: 'block', color: 'var(--text-white)' }) };

    const options = [
      { value: 0, label: 'Currency' },
      { value: 1, label: 'Custodian' },
    ];

    return (
      <Fragment>
        <span className={s.filterLabel}>Filter by&nbsp;&nbsp;</span>
        <Select
          size="small"
          styles={selectStyle}
          options={options}
          selectedValue={options[selectedCashChartFilter]}
          onChange={setSelectedCashChartFilter}
        />
      </Fragment>
    );
  };
  return (
    <div className={s.singleContainer}>
      <div className={s.defaultContainer}>
        <Widget>
          <div className={s.defaultContainer}>
            <Card style={{ flex: 1, marginRight: 10 }}>
              <Widget
                className={s.topPositionsChartContainer}
                title={'Current Cash Positions (' + portfolioCurrency + ')'}
                colNum={4}
                renderHeaderTools={filterCashPosByCat}
              >
                <TopPositionsChart
                  chartData={topListRanked.data}
                  selectedFilter={selectedCashChartFilter}
                  portfolioCurrency={portfolioCurrency}
                  portfolioSymbol={portfolioSymbol}
                />
              </Widget>
            </Card>
            <Card style={{ flex: 1, marginLeft: 10 }}>
              <Widget
                className={s.profitChartContainer}
                title="P&L"
                colNum={4}
                titleInfo={DASHBOARD_CASH_HELP_TEXT.ChartPnL}
              >
                <ProfitChart
                  profitChartData={profitChart.data}
                  portfolioCurrencyFormatter={portfolioCurrencyFormatter}
                />
              </Widget>
            </Card>
          </div>
        </Widget>
      </div>
      <div style={{ height: 30 }} />
      <Widget title="Cash Positions" titleInfo={DASHBOARD_CASH_HELP_TEXT.TableTop10Positions}>
        <TopListWidgetsContainer
          topList={topListRanked.data}
          topListOrder={topListOrder}
          changeOrder={setTopListOrder}
          columns={getTopPositionsColumns(portfolioCurrencyFormatter)}
          cellRenderer={(data) => cashCellRenderer(data, portfolioCurrencyFormatter)}
          columnClass={s.tableColumn}
          headerClass={s.headerContainer}
        />
      </Widget>
    </div>
  );
};

export default Summary;
