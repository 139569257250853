import cn from 'classnames';
import React, { Fragment, PureComponent } from 'react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import nextId from 'react-id-generator';
import { generatePath, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { PATHS } from '../../router/paths';
import {
  DASHBOARD_EQUITY_HELP_TEXT,
  DASHBOARD_FIXED_INCOME_HELP_TEXT,
  DASHBOARD_FUND_HELP_TEXT,
} from '../../services/constants/tooltipContextHelp';
import { TRANSACTION_TYPES } from '../../services/mock/transactionTypes';
import { IPortfolioInfoState } from '../../services/reducers/PortfolioReducers';
import { currencyFormat } from '../../services/utils/CurrencyHelpers';
import { FormatHelper } from '../../services/utils/FormatHelper';
import fx from '../../static/images/icons/breakdown/ic-fx.svg';
import income from '../../static/images/icons/breakdown/ic-income.svg';
import price from '../../static/images/icons/breakdown/ic-price.svg';
import txn from '../../static/images/icons/breakdown/ic-txn.svg';
import sortDown from '../../static/images/icons/sort-numeric-down.svg';
import sortUp from '../../static/images/icons/sort-numeric-up.svg';
import sum from '../../static/images/icons/sum.svg';
import { ContextHelpIcon, ContextHelpTooltip } from '../ContextHelp';
import { Autocomplete, IOption } from '../UIWidgets/Autocomplete';
import { Input } from '../UIWidgets/Input';
import s from './PnlReport.module.scss';

const icons = {
  fx: <img src={fx} />,
  income: <img src={income} />,
  price: <img src={price} />,
  realized: <img src={txn} />,
  total: <img src={sum} style={{ maxHeight: 12, filter: 'invert(100%' }} />,
};

function dynamicSort(property: string, period: string) {
  if (property === '' || period === '') {
    return;
  }
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a: any, b: any) => {
    const result = a[period][property] < b[period][property] ? -1 : a[period][property] > b[period][property] ? 1 : 0;
    return result * sortOrder;
  };
}

export interface IProps {
  title: string;
  report: { data: IBreakdownReport[]; totals: IBreakdownTotalsPnl };
  portfolioInfo: IPortfolioInfoState;
  // onSearch: (value: string) => void;
  // searchValue: string;
  custodians: Array<IOption<ICustodian>>;
  classId: string;
  incomeLabel?: string;
}

interface IState {
  expand: string;
  selectedCustodian: any;
  selectedCurrency: any;
  selectedInstrument: any;
  instrumentSearchTermn: string;
  sortBy: { property: string; period: string };
}

class PnlReport extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      expand: 'default',
      selectedCustodian: '',
      selectedCurrency: '',
      selectedInstrument: '',
      instrumentSearchTermn: '',
      sortBy: {
        property: '',
        period: '',
      },
    };
  }

  toggleExpand = (item: string) => {
    const expand = this.state.expand === item ? 'default' : item;
    this.setState({ expand });
  };

  getDataTip = (value: number, type: string) => {
    switch (type) {
      case 'realized':
        return `Realised:  ${
          this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol
        } ${currencyFormat(value, 2)}`;

      case 'income':
        return `${this.props.incomeLabel || 'Income'}:  ${
          this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol
        } ${currencyFormat(value, 2)}`;

      default:
        return `${type.charAt(0).toUpperCase() + type.slice(1)}:  ${
          this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol
        } ${currencyFormat(value, 2)}`;
    }
  };

  renderDetailsColumn = (item: IBreakdownReport) => {
    return (
      <div
        key={nextId()}
        className={cn(s.cell, s.detailCell, {
          [s.blue]: item.quantity < 0,
          [s.extraHeight]: this.props.classId === TRANSACTION_TYPES.fixedIncome,
        })}
      >
        <div className={cn(s.titleContainer)}>
          <h4>
            <small>
              {item.name} [{item.code}]
            </small>
          </h4>
          <h4>
            <small>
              <b>Custodian: </b>
              {item.custodian}
            </small>
          </h4>
        </div>
        <div className={cn(s.details)}>
          <div>
            <h6>Last Price</h6>
            <h5>
              {FormatHelper.localePriceValueFormatter(item.lastPrice, 2, 0)}{' '}
              {item.currency.name === 'GBP' ? 'GBp' : item.currency.name}
            </h5>
          </div>
          <div>
            <h6>Cost price</h6>
            <h5>
              {FormatHelper.localePriceValueFormatter(item.costPrice, 2, 0)}{' '}
              {item.currency.name === 'GBP' ? 'GBp' : item.currency.name}
            </h5>
          </div>
          <div>
            <h6>{this.props.classId === TRANSACTION_TYPES.fixedIncome ? 'Nominal' : 'Quantity'}</h6>
            <h5>{FormatHelper.localePriceValueFormatter(item.quantity, 2, 0)}</h5>
          </div>
          <div>
            <h6>{this.props.classId === TRANSACTION_TYPES.fixedIncome ? 'Market Value (inc Accrued)' : 'Value'}</h6>
            <h5>
              {FormatHelper.localePriceValueFormatter(item.value, 0)}{' '}
              {this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.name}
            </h5>
          </div>
        </div>
        {this.props.classId === TRANSACTION_TYPES.fixedIncome ? (
          <div className={cn(s.details, s.detailsSecondRoiw)}>
            <div>
              <h6>Current Accrued Value</h6>
              <h5>
                {FormatHelper.localePriceValueFormatter(item.accruedInterest || 0, 0)}{' '}
                {item.currency.name === 'GBP' ? 'GBp' : item.currency.name}
                {/*{this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.name}*/}
              </h5>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  renderDetailCell = (value: any, type: string, positionId: string) => {
    let backgroundType = 'neutral';
    if (value > 0) {
      backgroundType = 'positive';
    } else if (value < 0) {
      backgroundType = 'negative';
    }

    return (
      <Fragment key={nextId()}>
        <div data-tip={this.getDataTip(value, type)} className={cn(s.iconsCell, s[backgroundType])}>
          {/*
          // Replace this when Fixed Income Detail needs to be disabled
          {this.props.classId === TRANSACTION_TYPES.fixedIncome ? (*/}
          {this.props.classId === undefined ? ( // this condition was added only to suport disabling of details for certain asset classes
            (icons as any)[type]
          ) : (
            <Link
              to={generatePath(PATHS.portfolio.dashboard.breakdownDetailDashboard.path, {
                portfolioId: this.props.portfolioInfo.data ? this.props.portfolioInfo.data.id : '',
                classId: this.props.classId,
                positionId,
              })}
              className={s.settingsButton}
            >
              {(icons as any)[type]}
            </Link>
          )}
        </div>
      </Fragment>
    );
  };

  setSort = (property: string, period: string) => {
    this.setState({ sortBy: { property: this.state.sortBy.property === property ? `-${property}` : property, period } });
    ReactTooltip.hide();
  };

  renderFilters = (value: number, type: string, period: string) => {
    let backgroundType = 'neutral';
    if (value > 0) {
      backgroundType = 'positive';
    } else if (value < 0) {
      backgroundType = 'negative';
    }

    const active = period === this.state.sortBy.period && type === this.state.sortBy.property.replace('-', '');

    return (
      <Fragment>
        <ReactTooltip type="light" effect="solid" />
        <div
          onClick={() => this.setSort(type, period)}
          data-event-off="click"
          data-tip={
            // type === 'realized'
            //   ? `Realised:  ${
            //       this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol
            //     } ${currencyFormat(value, 2)}`
            //   : `${type.charAt(0).toUpperCase() + type.slice(1)}:  ${
            //       this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol
            //     } ${currencyFormat(value, 2)}`
            this.getDataTip(value, type)
          }
          className={cn(s.iconsCell, s[backgroundType], { [s.transparent]: active })}
        >
          {(icons as any)[type]}
        </div>
      </Fragment>
    );
  };

  renderColumn = (period: string, title: string, report: IBreakdownReport[]) => {
    const filteredReport = this.getReportFiltered();

    // ToDo: Rewrite the way of calculating all totals instead of fetching direct BE values.

    let totalVal = 0;

    switch (title) {
      case 'Daily':
        totalVal = filteredReport.reduce((acc, obj) => acc + obj.daily, 0);
        break;
      case 'MTD':
        totalVal = filteredReport.reduce((acc, obj) => acc + obj.mtd.total, 0);
        break;
      case 'YTD':
        totalVal = filteredReport.reduce((acc, obj) => acc + obj.ytd.total, 0);
        break;
      case 'Inception':
        totalVal = filteredReport.reduce((acc, obj) => acc + obj.sinceInception.total, 0);
        break;
      default:
        totalVal = (this.props.report.totals as any)[`totalPnl${title}`];
    }

    // this probably got redundant as we started to calculate the Totals dynamically because of dynamic filters. After test cycle needs to be removed.
    const totalValues: IBreakdownPeriod = (this.props.report.totals as any)[`totalPnl${title}`];

    return (
      <div key={nextId()} className={cn(s.column, s.dailyColumn, { [s.expanded]: period === this.state.expand })}>
        <h4 className={cn(s.periodHeader, s.cell)} onClick={() => this.toggleExpand(period)}>
          {title}
          {period === this.state.expand ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
        </h4>
        <div className={cn(s.totalHeader, s.cell)}>
          <h4>
            P&L Total{' '}
            <span className={cn(s.totalValue, s.confirm, { [s.error]: totalVal < 0 })}>
              {this.props.portfolioInfo.data?.currency.symbol} {FormatHelper.localePriceValueFormatter(totalVal, 0)}
            </span>
          </h4>
          <div className={cn(s.totalContainer)}>
            <div className={cn(s.filters)}>
              {this.state.sortBy.period === period && this.state.sortBy.property[0] === '-' ? (
                <img src={sortDown} style={{ width: 16, filter: 'invert(100%)' }} />
              ) : (
                <img src={sortUp} style={{ width: 16, filter: 'invert(100%)' }} />
              )}
              {this.renderFilters(totalValues.price, 'price', period)}
              {this.renderFilters(totalValues.fx, 'fx', period)}
              {this.renderFilters(totalValues.income, 'income', period)}
              {this.renderFilters(totalValues.realized, 'realized', period)}
              {this.renderFilters(totalValues.total, 'total', period)}
            </div>
            <h4 className={cn(s.expandHeader)}>
              Price
              <span className={cn(s.confirm, { [s.error]: totalValues.price < 0 })}>
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter(totalValues.price, 0)}
              </span>
            </h4>
            <h4 className={cn(s.expandHeader)}>
              FX
              <span className={cn(s.confirm, { [s.error]: totalValues.fx < 0 })}>
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter(totalValues.fx, 0)}
              </span>
            </h4>
            <h4 className={cn(s.expandHeader)}>
              Income
              <span className={cn(s.confirm, { [s.error]: totalValues.income < 0 })}>
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter(totalValues.income, 0)}
              </span>
            </h4>
            <h4 className={cn(s.expandHeader)}>
              Realised
              <span className={cn(s.confirm, { [s.error]: totalValues.realized < 0 })}>
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter(totalValues.realized, 0)}
              </span>
            </h4>
            <h4 className={cn(s.expandHeader)}>
              Total
              <span className={cn(s.confirm, { [s.error]: totalValues.total < 0 })}>
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter(totalValues.total, 0)}
              </span>
            </h4>
          </div>
        </div>
        {report.map((item: IBreakdownReport) => (
          <div
            key={nextId()}
            className={cn(s.detailCell, s.cell, s.totalContainerExpanded, {
              [s.extraHeight]: this.props.classId === TRANSACTION_TYPES.fixedIncome,
            })}
          >
            <h4 className={cn(s.expandHeader)}>
              {this.props.portfolioInfo.data?.currency.symbol}{' '}
              {FormatHelper.localePriceValueFormatter((item as any)[period].price, 0)}
            </h4>
            <h4 className={cn(s.expandHeader)}>
              {this.props.portfolioInfo.data?.currency.symbol}{' '}
              {FormatHelper.localePriceValueFormatter((item as any)[period].fx, 0)}
            </h4>
            <h4 className={cn(s.expandHeader)}>
              {this.props.portfolioInfo.data?.currency.symbol}{' '}
              {FormatHelper.localePriceValueFormatter((item as any)[period].income, 0)}
            </h4>
            <h4 className={cn(s.expandHeader)}>
              {this.props.portfolioInfo.data?.currency.symbol}{' '}
              {FormatHelper.localePriceValueFormatter((item as any)[period].realized, 0)}
            </h4>
            <h4 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
              P&L
              <span className={cn(s.totalValue, s.confirm, { [s.error]: (item as any)[period].total < 0 })}>
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter((item as any)[period].total, 0)}
              </span>
            </h4>

            <div className={cn(s.onlyIcons)}>
              {['price', 'fx', 'income', 'realized'].map((type) =>
                this.renderDetailCell(((item as any)[period] as any)[type], type, item.positionId)
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  setSelectedCustodian = (selectedCustodian: any) => {
    this.setState({ selectedCustodian });
  };
  setSelectedCurrency = (selectedCurrency: any) => {
    this.setState({ selectedCurrency });
  };
  setSelectedInstrument = (selectedInstrument: any) => {
    this.setState({ selectedInstrument });
  };

  getReportFiltered = () => {
    return this.props.report.data
      .filter((item: IBreakdownReport) => {
        if (
          (!this.state.selectedCustodian || this.state.selectedCustodian.name === item.custodian) &&
          (!this.state.selectedCurrency || this.state.selectedCurrency.name === item.currency.name) &&
          (!this.state.selectedInstrument || this.state.selectedInstrument.name === `${item.name} ${item.code}`)
        ) {
          return item;
        }
      })
      .sort(dynamicSort(this.state.sortBy.property, this.state.sortBy.period));
  };

  render() {
    // @ts-ignore
    const filteredCurrencies = [...new Set(this.props.report.data.map((item) => item.currency.name))].map((x) => ({
      id: x,
      name: x,
      value: x,
    }));
    // @ts-ignore
    const filterdInstruments = [...new Set(this.props.report.data.map((item) => `${item.name} ${item.code}`))].map(
      (x) => ({
        id: x,
        name: x,
        value: x,
      })
    );
    const filteredReport = this.getReportFiltered();

    const helpText =
      this.props.classId === TRANSACTION_TYPES.fixedIncome
        ? DASHBOARD_FIXED_INCOME_HELP_TEXT.TablePnLBreakdown
        : this.props.classId === TRANSACTION_TYPES.equities
        ? DASHBOARD_EQUITY_HELP_TEXT.TablePnLBreakdown
        : this.props.classId === TRANSACTION_TYPES.funds
        ? DASHBOARD_FUND_HELP_TEXT.TablePnLBreakdown
        : '';

    return (
      <div className={s.container}>
        <div className={cn(s.detailsColumn, s.shadow)}>
          <div className={cn(s.headerTitleContainer, s.cell)}>
            <h3>
              {this.props.title} <ContextHelpIcon title={this.props.title} elementId={'lblPnLReport'} />
            </h3>
            <ContextHelpTooltip elementId={'lblPnLReport'} tooltipTitle={this.props.title} tooltipText={helpText} />
          </div>
          <div className={cn(s.subHeaderTitleContainer, s.cell)}>
            <div className={cn(s.titleContainer1)}>
              <div className={s.imputContainer}>
                <h4 className={s.dropdowmLabel}>Instrument search</h4>
                <div className={cn(s.dropdown)}>
                  <Autocomplete
                    options={filterdInstruments}
                    value={this.state.selectedInstrument}
                    onSelect={this.setSelectedInstrument}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className={s.imputContainer}>
                <h4 className={s.dropdowmLabel}>Custodian</h4>
                <div className={cn(s.dropdown)}>
                  <Autocomplete
                    options={this.props.custodians}
                    value={this.state.selectedCustodian}
                    onSelect={this.setSelectedCustodian}
                    inputProps={{
                      className: s.custodianFilter,
                      placeholder: 'Filter by custodian',
                    }}
                    placeholder="All"
                  />
                </div>
              </div>
              <div className={s.imputContainer}>
                <h4 className={s.dropdowmLabel}>Currency</h4>
                <div className={cn(s.dropdown)}>
                  <Autocomplete
                    options={filteredCurrencies}
                    value={this.state.selectedCurrency}
                    onSelect={this.setSelectedCurrency}
                    inputProps={{
                      className: s.custodianFilter,
                      placeholder: 'Filter by currency',
                    }}
                    placeholder="All"
                  />
                </div>
              </div>
            </div>
          </div>
          {filteredReport.map((item: IBreakdownReport) => this.renderDetailsColumn(item))}
        </div>

        <div className={cn(s.column, s.dailyColumn)}>
          <h4 className={cn(s.periodHeader, s.cell)}>Daily</h4>
          <div className={cn(s.totalHeader, s.cell)}>
            <h4>
              P&L Total
              <span
                className={cn(s.totalValue, s.confirm, {
                  [s.error]: filteredReport.reduce((acc, obj) => acc + obj.daily, 0) < 0,
                })}
              >
                {this.props.portfolioInfo.data?.currency.symbol}{' '}
                {FormatHelper.localePriceValueFormatter(
                  filteredReport.reduce((acc, obj) => acc + obj.daily, 0),
                  0
                )}
              </span>
            </h4>
            <h4>Total</h4>
          </div>
          {filteredReport.map((item: IBreakdownReport) => (
            <div
              key={nextId()}
              className={cn(s.detailCell, s.cell, {
                [s.extraHeight]: this.props.classId === TRANSACTION_TYPES.fixedIncome,
              })}
            >
              <h4 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                P&L
                <span className={cn(s.totalValue, s.confirm, { [s.error]: item.daily < 0 })}>
                  {this.props.portfolioInfo.data?.currency.symbol}{' '}
                  {FormatHelper.localePriceValueFormatter(item.daily, 0)}
                </span>
              </h4>
            </div>
          ))}
        </div>

        {[
          { type: 'mtd', title: 'MTD' },
          { type: 'ytd', title: 'YTD' },
          { type: 'sinceInception', title: 'Inception' },
        ].map((period) => this.renderColumn(period.type, period.title, filteredReport))}
      </div>
    );
  }
}

export default PnlReport;
