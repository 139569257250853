import React, { PureComponent, ReactElement, Fragment } from 'react';
import cn from 'classnames';

import s from './AllocationItem.module.scss';
import { FormatHelper } from '../../../../services/utils/FormatHelper';

export type IAllocationItemRenderProps = Omit<IProps, 'renderItem' | 'onItemClick'>;

interface IProps {
  id: string;
  connectId: string;
  name: string;
  value: number;
  moneyValue: any;
  diff?: number;
  isProfit?: boolean;
  onItemClick?: (id: string) => void;
  renderItem?: (props: IAllocationItemRenderProps) => ReactElement;
}

class AllocationItem extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    isProfit: true,
  };

  handlerClick = () => {
    const { id, onItemClick } = this.props;
    if (onItemClick) {
      onItemClick(id);
    }
  };

  render() {
    const { renderItem, onItemClick, ...props } = this.props;
    const itemVal = FormatHelper.round(props.value, 10);
    const moneyVal = this.props.moneyValue;

    return (
      <div
        onClick={this.handlerClick}
        className={cn(s.valueItemWrapper, {
          [s.bigger]: props.value > 20,
          [s.clicked]: onItemClick,
        })}
      >
        {renderItem ? (
          renderItem(props)
        ) : (
          <Fragment>
            <div className={s.itemName}>{props.name}</div>
            <div className={s.itemValue}>
              {moneyVal} ({itemVal > 1 || itemVal < 0 ? itemVal : '< 1'}%)
            </div>
            {props.diff && <div className={cn(s.itemDiff, { [s.profit]: props.isProfit })}>{props.diff} %</div>}
          </Fragment>
        )}

        <div id={`connect-to-${props.connectId}`} className={s.connector} />
      </div>
    );
  }
}

export default AllocationItem;
