import React from 'react';

interface IOwnProps {

}

export const AccessDisallowed = (props: IOwnProps) => {

  const href = getConsumerUrl(window.location.hostname);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ margin: '2rem 0' }}>You don't currently have access to this view of illio</h1>
      <p style={{ textAlign: 'center', display: 'inline' }}>Please visit our new dashboard, <a
        style={{ fontWeight: 'bold', textDecorationLine: 'underline !important' }} href={href}>here</a></p>
    </div>
  );
};


export const getConsumerUrl = (hostname: string) => {
  const parts = hostname.split('.');
  if (parts[0].includes('wealth')) {
    return 'https://app.illio.com';
  }
  if (parts[0].includes('dev')) {
    return 'https://app-dev.illio.com';
  }
  if (parts[0].includes('test')) {
    return 'https://app-test.illio.com';
  }
  if (parts[0].includes('stage')) {
    return 'https://app-stage.illio.com';
  }
  if (parts[0].includes('tryout')) {
    return 'https://app-tryout.illio.com';
  }
  if (parts[0].includes('localhost')) {
    return 'https://localhost:3000';
  }

  return 'https://app.illio.com';

};
