import React from 'react';
import { getApiUrl } from '../../constants/endpoints';
import { usePortfolio } from '../usePortfolio';
import { usePeriod } from '../../context/PeriodContext';
import useApi from '../useApi';
import { ALLOCATION_TYPES } from '../../constants/constants';

export const DEFAULT_AVAILABLE_ALLOCATION_TYPES: AllocationType[] = [
  ALLOCATION_TYPES.AssetClass,
  ALLOCATION_TYPES.Custodian,
  ALLOCATION_TYPES.Sectors,
  ALLOCATION_TYPES.Currency,
  ALLOCATION_TYPES.Region,
];

interface TDatePnlAllocation {
  date: string;
  pnl: number;
}

interface IPnLHistoryItem {
  name: string;
  code: string;
  pnlDateAllocation: TDatePnlAllocation[];
}

export type TPnlHistory = IPnLHistoryItem[];

export interface IResponseData {
  pnlHistory: TPnlHistory;
}

export const usePeriodPnLByAllocationName = (allocationType: AllocationType = ALLOCATION_TYPES.AssetClass) => {
  const url = getApiUrl('portfolio.getPnLByAllocation');
  const portfolio = usePortfolio();
  const portfolioId = portfolio.portfolioInfo?.data?.id;
  const { period } = usePeriod();

  const request = useApi<{}>(
    url,
    {
      method: 'POST',
      data: {
        allocationType,
        period,
        portfolioId,
      },
    },
    {
      enabled: !!portfolioId,
    }
  );

  const pnlByAllocation = request.data as IResponseData;

  return {
    pnlByAllocation,
    ...request,
  };
};
