import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import React, { Fragment, PureComponent } from 'react';
import { ChartLegend } from '../../../../../components/ChartLegend';
import { ILegendItemProps } from '../../../../../components/ChartLegend/components/LegendItem';

import { chartColorLoss, chartColorProfit, LegendVariant } from '../../../../../services/constants/charts';
import { ICurrencyFormatter } from '../../../../../services/selectors/portfolio';
import { VALUE_TYPE } from '../../../../../services/utils/FormatHelper';
import { getPnlChartOptions, getWaterFallPnlChartOptions } from './chartConfigs';

interface IProps {
  profitChartData: IBarData[];
  baseCurrencySymbol: string;
  isWaterFall: boolean;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  legendVariant: string;
  chartOptOverrides?: echarts.EChartOption;
}

class PnlChart extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    isWaterFall: false,
  };

  getLegend(chartData: IBarData[], variant: string): ILegendItemProps[] {
    let legendCompiled: any = chartData.map((item) => {
      if (item.name === 'Profit') {
        return {
          name: 'Realised Profit',
          color: chartColorProfit,
        };
      }
      {
        return {
          name: 'Realised Loss',
          color: chartColorLoss,
        };
      }
    });
    legendCompiled.push({ name: 'Unrealised', color: 'rgb(191, 127, 63)' });

    if (variant === LegendVariant.Totals) {
      legendCompiled = [
        {
          name: 'Total',
          color: '#49d8a0',
        },
      ];
    }

    return legendCompiled;
  }

  render() {
    const {
      profitChartData,
      baseCurrencySymbol,
      isWaterFall,
      portfolioCurrencyFormatter,
      chartOptOverrides,
    } = this.props;
    const legendVariant = this.props.legendVariant; // Display the Total instead of Increase and Decrease values
    const legend = this.getLegend(profitChartData, legendVariant);
    const chartOpt = isWaterFall
      ? getWaterFallPnlChartOptions(profitChartData, baseCurrencySymbol, portfolioCurrencyFormatter)
      : getPnlChartOptions(profitChartData, VALUE_TYPE.MONEY, portfolioCurrencyFormatter);
    return (
      <div style={{ flex: 1 }}>
        <Fragment>
          <ChartLegend legend={legend} align={'right'} />
          <ReactEcharts
            style={{ flex: 1, height: '420px', width: '100%', maxHeight: '350px' }}
            option={{ ...chartOpt, ...chartOptOverrides }}
            notMerge={true}
          />
        </Fragment>
      </div>
    );
  }
}

export default PnlChart;
