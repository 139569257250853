import React from 'react';
import cn from 'classnames';
import s from './BlotterSubmissionButtons.module.css';

import { Button, Confirm } from '../../../../../../../components';
import { useDispatch } from 'react-redux';
import { PortfolioActions, SnapshotActions } from '../../../../../../../services/actions';
import { useTypedSelector } from '../../../../../../../services/hooks/useTypedSelector';
import { PATHS } from '../../../../../../../router/paths';
import { useHistory } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useIframeMode } from '../../../../../../../services/hooks/useIframeMode';
import { sendRequest } from 'src/services/hooks/useApi';
import { BASE_URL } from 'src/services/constants/endpoints';

interface IOwnProps {
  selectedKeys: Record<string, boolean>;
  onSubmitTrades: (tradeIds: number[]) => void;
}
const BlotterSubmissionButtons = ({ selectedKeys, onSubmitTrades }: IOwnProps) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const iframeMode = useIframeMode();

  const { portfolioId, trades, tradesOrder } = useTypedSelector((state) => ({
    portfolioId: state.portfolio.portfolioInfo.data?.id,
    trades: state.snapshot.snapshotEdit.savedTrades.content,
    tradesOrder: state.snapshot.snapshotEdit.savedTrades.order,
  }));

  const errorTradesLength = trades.filter(
    (it) => it.errors !== null && it.errors.filter((error) => error.status !== 'INFO').length > 0
  ).length;
  const okTradesLength = trades.length - errorTradesLength;
  const tradeIds = Object.entries(selectedKeys)
    .filter(([_, value]) => value)
    .map(([key]) => parseFloat(key));

  const dispatch = useDispatch<ThunkDispatch<any, any, Action<Promise<any>>>>();
  const history = useHistory();

  const handleDiscard = () => {
    dispatch(SnapshotActions.discardSavedTrades(portfolioId!)).then(() => {
      dispatch(SnapshotActions.fetchPortfolioSavedTrades(portfolioId!, tradesOrder));
      // dispatch(SnapshotActions.finishEditUnconfirmedTrade()); ILLDV001-4595
    });
  };

  const onClose = () => {
    window.opener?.postMessage?.('close-window-from-iframe', '*');
  };

  const renderConfirmModal = () => {
    if (!showConfirmModal) {
      return null;
    }
    const onCancel = () => {
      setShowConfirmModal(false);
    };

    return (
      <div style={{ zIndex: 999 }}>
        <Confirm
          title={`${tradeIds.length} Trade${tradeIds.length !== 1 ? 's' : ''} will be saved!`}
          text={``}
          confirmBtnText="Submit"
          info={true}
          onCancel={onCancel}
          onConfirm={() => onSubmitTrades(tradeIds)}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {/*{renderConfirmModal()}*/}
      <div className={s.aligner}>
        {iframeMode ? (
          <div className={cn(s.leftAligner)}>
            <Button className={cn(s.titleBtn, s.titleBtnOffset, s.titleBtnRightMargin)} size="small" onClick={onClose}>
              Close
            </Button>
          </div>
        ) : (
          <div className={cn(s.leftAligner)} />
        )}
        <div className={cn(s.rightAligner)}>
          <div className={cn(s.summaryContainer)}>
            {okTradesLength > 0 && <p className={cn(s.summary)}>Valid trades: {okTradesLength}</p>}
            {errorTradesLength > 0 && (
              <p className={cn(s.summary, s.withError)}>Trades not ready: {errorTradesLength}</p>
            )}
          </div>

          <Button
            className={cn(s.titleBtn, s.titleBtnOffset, s.titleBtnRightMargin)}
            size="small"
            variant="outline-warning"
            onClick={handleDiscard}
            active={false}
            disabled={trades.length === 0}
          >
            Clear all
          </Button>
          <Button
            className={s.titleBtn}
            size="small"
            disabled={!tradeIds.length || okTradesLength === 0}
            onClick={() => onSubmitTrades(tradeIds)}
            active={true}
          >
            Submit selected
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlotterSubmissionButtons;
