import React, { Fragment, FunctionComponent, useState } from 'react';
import moment from 'moment';
import { AxiosPromise } from 'axios';

import { MarketsTable, MarketForm } from './components';
import { Period } from '../../../../../../components/Period';

import { IOptionType } from '../../../../../../components/UIWidgets/Select/Select';
import { IMarketForm } from './components/MarketForm/IMarketForm';
import { PERIOD_TYPE } from '../../../../../../services/constants/constants';

import s from './InstrumentMarket.module.css';

const CHARTS_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.MTD, label: 'MTD' },
  { value: PERIOD_TYPE.YTD, label: 'YTD' },
  { value: PERIOD_TYPE.YEAR, label: '1 Year' },
  { value: PERIOD_TYPE.MANUAL, label: 'Date range' },
];

interface IProps {
  instrumentId: string;
  marketData: IMarket[];
  marketDataIsFetched: boolean;
  marketPeriod: IPeriod;
  marketOrder: IOrder;
  onPeriodChange: (period: IPeriod) => void;
  onMarketSave: (market: IMarket) => AxiosPromise<IMarket>;
  onMarketUpdate: (market: IMarket) => AxiosPromise<IMarket>;
  onMarketDelete: (market: IMarket) => void;
  onChangeMarketSort: (order: IOrder) => void;
}

const InstrumentMarket: FunctionComponent<IProps> = ({
  instrumentId,
  marketData,
  marketDataIsFetched,
  marketPeriod,
  marketOrder,
  onMarketDelete,
  onMarketSave,
  onMarketUpdate,
  onPeriodChange,
  onChangeMarketSort,
}) => {
  const [selectedMarket, setSelectedMarket] = useState<IMarket | null>(null);

  const handleSaveMarket = (formData: IMarketForm) => {
    if (selectedMarket) {
      onMarketUpdate({
        ...selectedMarket,
        closeTimestamp: moment(formData.date).format('YYYY-MM-DD'),
        price: parseFloat(formData.price.toString()),
      }).then(() => {
        setSelectedMarket(null);
      });
    } else {
      onMarketSave({
        instrumentId,
        closeTimestamp: moment(formData.date).format('YYYY-MM-DD'),
        price: parseFloat(formData.price.toString()),
      });
    }
  };

  return (
    <Fragment>
      <h3 className={s.marketBlockTitle}>Price dynamic</h3>
      <div className={s.marketForm}>
        <MarketForm market={selectedMarket} onSubmit={handleSaveMarket} />
      </div>

      {/* <div className={s.marketChart}>
        <MarketsChart
          pricesData={[]}
          onPeriodChange={onPeriodChange}
        />
      </div> */}

      <div className={s.historyTitle}>
        <h3>Price history</h3>
        <Period options={CHARTS_PERIODS} onPeriodChange={onPeriodChange} selectedPeriod={marketPeriod} />
      </div>
      {marketDataIsFetched && !marketData.length && <>No data for current period</>}
      {marketData.length > 0 && (
        <div className={s.marketTable}>
          <MarketsTable
            marketData={marketData}
            marketOrder={marketOrder}
            onSortChange={onChangeMarketSort}
            onEditMarket={(market) => {
              setSelectedMarket(market);
            }}
            onRemoveMarket={onMarketDelete}
          />
        </div>
      )}
    </Fragment>
  );
};

export default React.memo(InstrumentMarket);
