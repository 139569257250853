import { ITableColumn } from '../../../../components/UIWidgets/TableWrapper/models';

export const getInstrumentColumns = (hasUserColumn: boolean): ITableColumn[] => {
  const columns = [
    {
      label: 'Code',
      dataKey: 'code',
      flexGrow: 2,
      width: 100,
      display: true,
      disableSort: false,
    },
    {
      label: 'Instrument',
      dataKey: 'name',
      flexGrow: 3,
      width: 250,
      display: true,
      disableSort: false,
    },
    {
      label: 'Asset Class',
      dataKey: 'assetClass.name',
      flexGrow: 1,
      width: 100,
      display: true,
      disableSort: false,
    },
    {
      label: 'Asset Sub Class',
      dataKey: 'assetSubClass.name',
      flexGrow: 1,
      width: 120,
      display: true,
      disableSort: false,
    },
    {
      label: 'Industry Sector',
      dataKey: 'industrySector.name',
      flexGrow: 1,
      width: 100,
      display: true,
      disableSort: false,
    },
    {
      label: 'Country',
      dataKey: 'country',
      flexGrow: 1,
      width: 80,
      display: true,
      disableSort: false,
    },
    {
      label: 'Currency',
      dataKey: 'currency.name',
      flexGrow: 1,
      width: 80,
      display: true,
      disableSort: false,
    },
    {
      label: '',
      dataKey: 'action',
      flexGrow: 1,
      width: 18,
      display: true,
      disableSort: true,
    },
  ];

  if (hasUserColumn) {
    columns.splice(0, 0, {
      label: 'Client',
      dataKey: 'user.fullName',
      flexGrow: 1,
      width: 100,
      display: true,
      disableSort: false,
    });
  }

  return columns;
};
