import React, { Fragment, FunctionComponent } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Button, ToggleLeftRight } from '..';
import s from './ReduxForm.module.scss';

interface IListItemProps {
  item: any;
  onRemove: ((item: any) => void) | null;
  onDefault: (item: any) => void;
  toggleDividends: (item: any) => void;
  toggleStateOnCreate: boolean;
}

const leftLabel = 'Auto-Generated';
const rightLabel = 'Manual Entry';
const ListItem: FunctionComponent<IListItemProps> = ({
  item,
  onRemove,
  onDefault,
  toggleDividends,
  toggleStateOnCreate,
  ...rest
}) => {
  return (
    <div className={s.itemContainer}>
      <h4 className={s.itemName}>
        {item.name}
        {item.default && '*'}
      </h4>
      {!item.temp && <span className={s.itemOption}>{!item.onlyManualDividends ? leftLabel : rightLabel}</span>}
      {item.temp && (
        <div className={s.itemOption}>
          <ToggleLeftRight
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            toggleCurrentPosition={
              item.onlyManualDividends !== undefined ? !item.onlyManualDividends : toggleStateOnCreate
            }
            clickFunction={() => toggleDividends(item)}
          />
        </div>
      )}

      <div className={s.itemActions}>
        <Button
          variant={item.default ? 'outline-info' : 'outline-disabled'}
          size="xsmall"
          onClick={() => onDefault(item)}
        >
          DEFAULT
        </Button>

        <AiFillCloseCircle
          size={16}
          color={item.default === false && onRemove ? '#a9b2d1' : 'transparent'}
          className={s.buttonStyle}
          onClick={item.default === false && onRemove ? () => onRemove(item) : undefined}
        />
      </div>
    </div>
  );
};

interface IProps {
  elements: any & { unremovable?: boolean };
  toggleStateOnCreate: boolean;
  onDefault: (item: any) => void;
  onRemove: (item: any) => void;
  toggleDividends: (item: any) => void;
}

const RenderList: FunctionComponent<IProps> = ({
  elements,
  onRemove,
  onDefault,
  toggleDividends,
  toggleStateOnCreate,
}: IProps) => {
  const ref: any = React.createRef();

  return elements && elements.length ? (
    <Fragment>
      <ul className={s.listContainer} ref={ref}>
        {elements.map((item: any) => (
          <ListItem
            key={item.code}
            item={item}
            toggleStateOnCreate={toggleStateOnCreate}
            onDefault={onDefault}
            toggleDividends={toggleDividends}
            onRemove={elements.length > 1 && !item.unremovable ? onRemove : null}
          />
        ))}
      </ul>
    </Fragment>
  ) : null;
};

export default React.memo(RenderList);
