import { Dispatch } from 'redux';
import { IAllocationDict } from '../../models/IAllocationDict';

import { IPortfolioPnlByAttribution } from '../../models/IPortfolioPnlByAttribution';

import { ITopListItem } from '../../models/ITopListItem';
import { LineData } from '../../models/LineData';
import { IActionPayload } from '../../models/redux/IActionPayload';
import {
  ALLOCATION_TYPES,
  DetalizationType,
  EQUITY_EXPOSURE_TYPES,
  GEOGRAPHY_ALLOCATION_TYPES,
} from '../constants/constants';
import { getApiUrl, getCustomApiUrl } from '../constants/endpoints';
import { PeriodHelper } from '../utils/PeriodHelper';
import { ReduxHelper } from '../utils/ReduxHelper';
import { DashboardViewActions } from './index';

class DashboardActions {
  static readonly TOPLIST_REQUEST = 'TOPLIST_REQUEST';
  static readonly TOPLIST_SUCCESS = 'TOPLIST_SUCCESS';
  static readonly TOPLIST_ERROR = 'TOPLIST_ERROR';
  static readonly TOPLIST_RESET = 'TOPLIST_RESET';
  static readonly TOPLIST_CHANGE_ORDER = 'TOPLIST_CHANGE_ORDER';
  static readonly TOPLIST_CHANGE_PERIOD = 'TOPLIST_CHANGE_PERIOD';

  static readonly TOPLIST_BY_PNL_REQUEST = 'TOPLIST_BY_PNL_REQUEST';
  static readonly TOPLIST_BY_PNL_SUCCESS = 'TOPLIST_BY_PNL_SUCCESS';
  static readonly TOPLIST_BY_PNL_ERROR = 'TOPLIST_BY_PNL_ERROR';
  static readonly TOPLIST_BY_PNL_RESET = 'TOPLIST_BY_PNL_RESET';
  static readonly TOPLIST_BY_PNL_CHANGE_ORDER = 'TOPLIST_BY_PNL_CHANGE_ORDER';
  static readonly TOPLIST_BY_PNL_CHANGE_PERIOD = 'TOPLIST_BY_PNL_CHANGE_PERIOD';

  static readonly ALLOCATIONS_REQUEST = 'ALLOCATIONS_REQUEST';
  static readonly ALLOCATIONS_SUCCESS = 'ALLOCATIONS_SUCCESS';
  static readonly ALLOCATIONS_ERROR = 'ALLOCATIONS_ERROR';
  static readonly ALLOCATIONS_RESET = 'ALLOCATIONS_RESET';

  static readonly EXPOSURE_ALLOCATIONS_REQUEST = 'EXPOSURE_ALLOCATIONS_REQUEST';
  static readonly EXPOSURE_ALLOCATIONS_SUCCESS = 'EXPOSURE_ALLOCATIONS_SUCCESS';
  static readonly EXPOSURE_ALLOCATIONS_ERROR = 'EXPOSURE_ALLOCATIONS_ERROR';
  static readonly EXPOSURE_ALLOCATIONS_RESET = 'EXPOSURE_ALLOCATIONS_RESET';

  static readonly PROFIT_REQUEST = 'PROFIT_REQUEST';
  static readonly PROFIT_SUCCESS = 'PROFIT_SUCCESS';
  static readonly PROFIT_ERROR = 'PROFIT_ERROR';
  static readonly PROFIT_RESET = 'PROFIT_RESET';
  static readonly SET_PROFIT_PERIOD = 'SET_PROFIT_PERIOD';

  static readonly PROFIT_CHART_REQUEST = 'PROFIT_CHART_REQUEST';
  static readonly PROFIT_CHART_SUCCESS = 'PROFIT_CHART_SUCCESS';
  static readonly PROFIT_CHART_ERROR = 'PROFIT_CHART_ERROR';
  static readonly PROFIT_CHART_RESET = 'PROFIT_CHART_RESET';

  static readonly PORTFOLIO_PNL_BY_ATTRIBUTION_REQUEST = 'PORTFOLIO_PNL_BY_ATTRIBUTION_REQUEST';
  static readonly PORTFOLIO_PNL_BY_ATTRIBUTION_SUCCESS = 'PORTFOLIO_PNL_BY_ATTRIBUTION_SUCCESS';
  static readonly PORTFOLIO_PNL_BY_ATTRIBUTION_ERROR = 'PORTFOLIO_PNL_BY_ATTRIBUTION_ERROR';
  static readonly PORTFOLIO_PNL_BY_ATTRIBUTION_RESET = 'PORTFOLIO_PNL_BY_ATTRIBUTION_RESET';
  static readonly SET_PORTFOLIO_PNL_BY_ATTRIBUTION_PERIOD = 'SET_PORTFOLIO_PNL_BY_ATTRIBUTION_PERIOD';

  static readonly SET_CUSTODIANS_FILTER = 'SET_CUSTODIANS_FILTER';
  static readonly RESET_CUSTODIANS_FILTER = 'RESET_CUSTODIANS_FILTER';

  static readonly PORTFOLIO_PNL_BY_ASSET_CLASS_REQUEST = 'PORTFOLIO_PNL_BY_ASSET_CLASS_REQUEST';
  static readonly PORTFOLIO_PNL_BY_ASSET_CLASS_SUCCESS = 'PORTFOLIO_PNL_BY_ASSET_CLASS_SUCCESS';
  static readonly PORTFOLIO_PNL_BY_ASSET_CLASS_ERROR = 'PORTFOLIO_PNL_BY_ASSET_CLASS_ERROR';
  static readonly PORTFOLIO_PNL_BY_ASSET_CLASS_RESET = 'PORTFOLIO_PNL_BY_ASSET_CLASS_RESET';
  static readonly SET_PORTFOLIO_PNL_BY_ASSET_CLASS_PERIOD = 'SET_PORTFOLIO_PNL_BY_ASSET_CLASS_PERIOD';

  static readonly PROFIT_ATTRIBUTION_REQUEST = 'PROFIT_ATTRIBUTION_REQUEST';
  static readonly PROFIT_ATTRIBUTION_SUCCESS = 'PROFIT_ATTRIBUTION_SUCCESS';
  static readonly PROFIT_ATTRIBUTION_ERROR = 'PROFIT_ATTRIBUTION_ERROR';
  static readonly PROFIT_ATTRIBUTION_RESET = 'PROFIT_ATTRIBUTION_RESET';
  static readonly SET_PROFIT_ATTRIBUTION_PERIOD = 'SET_PROFIT_ATTRIBUTION_PERIOD';

  static readonly PERFORMANCE_REQUEST = 'PERFORMANCE_REQUEST';
  static readonly PERFORMANCE_SUCCESS = 'PERFORMANCE_SUCCESS';
  static readonly PERFORMANCE_ERROR = 'PERFORMANCE_ERROR';
  static readonly PERFORMANCE_RESET = 'PERFORMANCE_RESET';
  static readonly SET_PERFORMANCE_PERIOD = 'SET_PERFORMANCE_PERIOD';
  static readonly RESET_PERFORMANCE_PERIOD = 'RESET_PERFORMANCE_PERIOD';

  static readonly CUMULATIVE_PERFORMANCE_BY_ASSET_REQUEST = 'CUMULATIVE_PERFORMANCE_BY_ASSET_REQUEST';
  static readonly CUMULATIVE_PERFORMANCE_BY_ASSET_SUCCESS = 'CUMULATIVE_PERFORMANCE_BY_ASSET_SUCCESS';
  static readonly CUMULATIVE_PERFORMANCE_BY_ASSET_ERROR = 'CUMULATIVE_PERFORMANCE_BY_ASSET_ERROR';
  static readonly CUMULATIVE_PERFORMANCE_BY_ASSET_RESET = 'CUMULATIVE_PERFORMANCE_BY_ASSET_RESET';
  static readonly CUMULATIVE_PERFORMANCE_BY_ASSET_CHANGE_PERIOD = 'CUMULATIVE_PERFORMANCE_BY_ASSET_CHANGE_PERIOD';

  static readonly INDEX_PERFORMANCE_REQUEST = 'INDEX_PERFORMANCE_REQUEST';
  static readonly INDEX_PERFORMANCE_SUCCESS = 'INDEX_PERFORMANCE_SUCCESS';
  static readonly INDEX_PERFORMANCE_ERROR = 'INDEX_PERFORMANCE_ERROR';
  static readonly INDEX_PERFORMANCE_RESET = 'INDEX_PERFORMANCE_RESET';

  static readonly DASHBOARD_WEALTH_REQUEST = 'DASHBOARD_WEALTH_REQUEST';
  static readonly DASHBOARD_WEALTH_SUCCESS = 'DASHBOARD_WEALTH_SUCCESS';
  static readonly DASHBOARD_WEALTH_ERROR = 'DASHBOARD_WEALTH_ERROR';
  static readonly DASHBOARD_WEALTH_RESET = 'DASHBOARD_WEALTH_RESET';

  static readonly DASHBOARD_TOTAL_VALUES_REQUEST = 'DASHBOARD_TOTAL_VALUES_REQUEST';
  static readonly DASHBOARD_TOTAL_VALUES_SUCCESS = 'DASHBOARD_TOTAL_VALUES_SUCCESS';
  static readonly DASHBOARD_TOTAL_VALUES_ERROR = 'DASHBOARD_TOTAL_VALUES_ERROR';
  static readonly DASHBOARD_TOTAL_VALUES_RESET = 'DASHBOARD_TOTAL_VALUES_RESET';

  static readonly PROFIT_REPORT_BREAKDOWN_REQUEST = 'PROFIT_REPORT_BREAKDOWN_REQUEST';
  static readonly PROFIT_REPORT_BREAKDOWN_SUCCESS = 'PROFIT_REPORT_BREAKDOWN_SUCCESS';
  static readonly PROFIT_REPORT_BREAKDOWN_ERROR = 'PROFIT_REPORT_BREAKDOWN_ERROR';

  static readonly FUNDAMENTALS_REPORT_REQUEST = 'FUNDAMENTALS_REPORT_REQUEST';
  static readonly FUNDAMENTALS_REPORT_SUCCESS = 'FUNDAMENTALS_REPORT_SUCCESS';
  static readonly FUNDAMENTALS_REPORT_ERROR = 'FUNDAMENTALS_REPORT_ERROR';

  static readonly BOND_FUNDAMENTALS_REPORT_REQUEST = 'BOND_FUNDAMENTALS_REPORT_REQUEST';
  static readonly BOND_FUNDAMENTALS_REPORT_SUCCESS = 'BOND_FUNDAMENTALS_REPORT_SUCCESS';
  static readonly BOND_FUNDAMENTALS_REPORT_ERROR = 'BOND_FUNDAMENTALS_REPORT_ERROR';

  static readonly PROFIT_REPORT_BREAKDOWN_POSITION_REQUEST = 'PROFIT_REPORT_BREAKDOWN_POSITION_REQUEST';
  static readonly PROFIT_REPORT_BREAKDOWN_POSITION_SUCCESS = 'PROFIT_REPORT_BREAKDOWN_POSITION_SUCCESS';
  static readonly PROFIT_REPORT_BREAKDOWN_POSITION_ERROR = 'PROFIT_REPORT_BREAKDOWN_POSITION_ERROR';
  static readonly PROFIT_REPORT_BREAKDOWN_POSITION_RESET = 'PROFIT_REPORT_BREAKDOWN_POSITION_RESET';

  static readonly INCOME_DETAILED_REQUEST = 'INCOME_DETAILED_REQUEST';
  static readonly INCOME_DETAILED_SUCCESS = 'INCOME_DETAILED_SUCCESS';
  static readonly INCOME_DETAILED_ERROR = 'INCOME_DETAILED_ERROR';
  static readonly INCOME_DETAILED_RESET = 'INCOME_DETAILED_RESET';

  static readonly INCOME_REPAYMENTS_REQUEST = 'INCOME_REPAYMENTS_REQUEST';
  static readonly INCOME_REPAYMENTS_SUCCESS = 'INCOME_REPAYMENTS_SUCCESS';
  static readonly INCOME_REPAYMENTS_ERROR = 'INCOME_REPAYMENTS_ERROR';
  static readonly INCOME_REPAYMENTS_RESET = 'INCOME_REPAYMENTS_RESET';

  static readonly FIXED_INCOME_YIELD_REQUEST = 'FIXED_INCOME_YIELD_REQUEST';
  static readonly FIXED_INCOME_YIELD_SUCCESS = 'FIXED_INCOME_YIELD_SUCCESS';
  static readonly FIXED_INCOME_YIELD_ERROR = 'FIXED_INCOME_YIELD_ERROR';
  static readonly FIXED_INCOME_YIELD_CHANGE_PERIOD = 'FIXED_INCOME_YIELD_CHANGE_PERIOD';
  static readonly FIXED_INCOME_YIELD_RESET = 'FIXED_INCOME_YIELD_RESET';

  static readonly OPTIONS_DASHBOARD_REQUEST = 'OPTIONS_DASHBOARD_REQUEST';
  static readonly OPTIONS_DASHBOARD_SUCCESS = 'OPTIONS_DASHBOARD_SUCCESS';
  static readonly OPTIONS_DASHBOARD_ERROR = 'OPTIONS_DASHBOARD_ERROR';
  static readonly OPTIONS_DASHBOARD_RESET = 'OPTIONS_DASHBOARD_RESET';

  static readonly OPTIONS_PNL_BY_MONTH_REQUEST = 'OPTIONS_PNL_BY_MONTH_REQUEST';
  static readonly OPTIONS_PNL_BY_MONTH_SUCCESS = 'OPTIONS_PNL_BY_MONTH_SUCCESS';
  static readonly OPTIONS_PNL_BY_MONTH_ERROR = 'OPTIONS_PNL_BY_MONTH_ERROR';
  static readonly OPTIONS_PNL_BY_MONTH_RESET = 'OPTIONS_PNL_BY_MONTH_RESET';

  static readonly OPTIONS_PERFORMANCE_REQUEST = 'OPTIONS_PERFORMANCE_REQUEST';
  static readonly OPTIONS_PERFORMANCE_SUCCESS = 'OPTIONS_PERFORMANCE_SUCCESS';
  static readonly OPTIONS_PERFORMANCE_ERROR = 'OPTIONS_PERFORMANCE_ERROR';
  static readonly OPTIONS_PERFORMANCE_RESET = 'OPTIONS_PERFORMANCE_RESET';

  static fetchTopListByPnl(
    portfolioId: string,
    order: IOrder | null = null,
    period: IPeriod,
    filter: { type: ALLOCATION_TYPES; id: string } | null = null,
    num: number = 10,
    custodians?: string[]
  ) {
    return ReduxHelper.createHttpRequestActionBundle<ITopListItem[]>(
      {
        url: getApiUrl('topPositionsByPnL'),
        data: {
          number: num,
          portfolioId,
          order: {
            name: order?.name,
            direction: order!.direction,
          },
          filter,
          period: PeriodHelper.preparePeriodForRequest(period),
          custodians: custodians || [],
        },
      },
      DashboardActions.TOPLIST_BY_PNL_REQUEST,
      DashboardActions.TOPLIST_BY_PNL_SUCCESS,
      DashboardActions.TOPLIST_BY_PNL_ERROR
    );
  }

  static fetchTopListRanked(
    portfolioId: string,
    filter: { type: ALLOCATION_TYPES; id: string } | null = null,
    order: IOrder | null = { name: 'marketValueBase', direction: 'DESC' },
    num: number = 10,
    custodians?: string[],
    period?: IPeriod
  ) {
    return ReduxHelper.createHttpRequestActionBundle<ITopListItem[]>(
      {
        url: getApiUrl('topPositions'),
        data: {
          filter,
          number: num,
          order,
          portfolioId,
          custodians: custodians || [],
          valuationDate: period ? PeriodHelper.preparePeriodForRequest(period).to : undefined,
        },
      },
      DashboardActions.TOPLIST_REQUEST,
      DashboardActions.TOPLIST_SUCCESS,
      DashboardActions.TOPLIST_ERROR
    );
  }

  static changeTopListPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return { type: DashboardActions.TOPLIST_CHANGE_PERIOD, payload: period };
  }

  static resetTopList(): IActionPayload {
    return {
      type: DashboardActions.TOPLIST_RESET,
    };
  }

  static changeTopListOrder(order: IOrder): IActionPayload<IOrder> {
    return { type: DashboardActions.TOPLIST_CHANGE_ORDER, payload: order };
  }

  static changeTopListByPnlOrder(order: IOrder): IActionPayload<IOrder> {
    return { type: DashboardActions.TOPLIST_BY_PNL_CHANGE_ORDER, payload: order };
  }

  static changeTopListByPnlPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return { type: DashboardActions.TOPLIST_BY_PNL_CHANGE_PERIOD, payload: period };
  }

  static changeFixedIncomeYieldPeriod(period: IPeriod, currency?: string) {
    return { type: DashboardActions.FIXED_INCOME_YIELD_CHANGE_PERIOD, payload: { period, currency } };
  }

  static changeCumulativePerformanceByAsset(period: IPeriod) {
    return { type: DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_CHANGE_PERIOD, payload: { period } };
  }

  static resetTopListByPnl(): IActionPayload {
    return {
      type: DashboardActions.TOPLIST_BY_PNL_RESET,
    };
  }

  static fetchProfit(portfolioId: string, period: IPeriod, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('portfolio.profit.assetClass'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          custodians: custodians || undefined,
        },
      },
      DashboardActions.PROFIT_REQUEST,
      DashboardActions.PROFIT_SUCCESS,
      DashboardActions.PROFIT_ERROR
    );
  }

  static fetchProfitChart(portfolioId: string, period: IPeriod, assetClassId?: string, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('portfolio.profit.chart'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period, 'MONTH_2_MONTH'),
          assetClassId,
          custodians: custodians || [],
        },
      },
      DashboardActions.PROFIT_CHART_REQUEST,
      DashboardActions.PROFIT_CHART_SUCCESS,
      DashboardActions.PROFIT_CHART_ERROR
    );
  }

  static fetchOptionsProfitChart(portfolioId: string, period: IPeriod, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('portfolio.profit.chart'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period, 'MONTH_2_MONTH'),
          asset: {
            type: 'AssetSubClass',
            id: 'EqOptions',
          },
          custodians: custodians || [],
        },
      },
      DashboardActions.OPTIONS_PNL_BY_MONTH_REQUEST,
      DashboardActions.OPTIONS_PNL_BY_MONTH_SUCCESS,
      DashboardActions.OPTIONS_PNL_BY_MONTH_ERROR
    );
  }

  static fetchPortfolioPnLByAttribution(
    portfolioId: string,
    period: IPeriod,
    assetClassId?: string,
    custodians?: string[]
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioPnlByAttribution>(
      {
        url: getApiUrl('portfolio.profit.pnlByAttribution'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period, 'MONTH_2_MONTH'),
          assetClassId,
          custodians: custodians || [],
        },
      },
      DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_REQUEST,
      DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_SUCCESS,
      DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_ERROR
    );
  }

  static fetchPortfolioPnLByAssetClass(portfolioId: string, period: IPeriod, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioPnlByAttribution>(
      {
        url: getApiUrl('portfolio.profit.pnlByAssetClass'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period, 'MONTH_2_MONTH'),
          custodians: custodians || [],
        },
      },
      DashboardActions.PORTFOLIO_PNL_BY_ASSET_CLASS_REQUEST,
      DashboardActions.PORTFOLIO_PNL_BY_ASSET_CLASS_SUCCESS,
      DashboardActions.PORTFOLIO_PNL_BY_ASSET_CLASS_ERROR
    );
  }

  static fetchProfitAttribution(portfolioId: string, period: IPeriod, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('portfolio.profit.attribution'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          custodians: custodians || [],
        },
      },
      DashboardActions.PROFIT_ATTRIBUTION_REQUEST,
      DashboardActions.PROFIT_ATTRIBUTION_SUCCESS,
      DashboardActions.PROFIT_ATTRIBUTION_ERROR
    );
  }

  static fetchProfitAttributionByAsset(
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod,
    custodians?: string[]
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('portfolio.profit.attribution'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          asset,
          custodians: custodians || [],
        },
      },
      DashboardActions.PROFIT_ATTRIBUTION_REQUEST,
      DashboardActions.PROFIT_ATTRIBUTION_SUCCESS,
      DashboardActions.PROFIT_ATTRIBUTION_ERROR
    );
  }

  static setProfitAttributionPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: DashboardActions.SET_PROFIT_ATTRIBUTION_PERIOD,
      payload: period,
    };
  }

  static fetchPortfolioPerformance(portfolioId: string, period: IPeriod, custodians?: string[]) {
    const mapFunction = (responseData: { data: LineData }): LineData => responseData.data;

    return ReduxHelper.createHttpRequestActionBundle<LineData>(
      {
        url: getApiUrl('portfolio.performance'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          detalization: DetalizationType.Daily,
          custodians: custodians || [],
        },
      },
      DashboardActions.PERFORMANCE_REQUEST,
      DashboardActions.PERFORMANCE_SUCCESS,
      DashboardActions.PERFORMANCE_ERROR,
      mapFunction
    );
  }

  static fetchPortfolioPerformanceByAsset(
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod,
    custodians?: string[],
    actionPrefix: string = 'PERFORMANCE'
  ) {
    const mapFunction = (responseData: { data: LineData }): LineData => responseData.data;

    return ReduxHelper.createHttpRequestActionBundle<LineData>(
      {
        url: getApiUrl('portfolio.performance'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
          asset,
          detalization: DetalizationType.Daily,
          custodians: custodians || [],
        },
      },
      `${actionPrefix}_REQUEST`,
      `${actionPrefix}_SUCCESS`,
      `${actionPrefix}_ERROR`,
      mapFunction
    );
  }

  static fetchCumulativePerformanceByAsset(portfolioId: string, assetClassId: string, period: IPeriod) {
    // const mapFunction = (responseData: { data: LineData }): LineData => responseData.data;

    const prepPeriod = PeriodHelper.preparePeriodForRequest(period);

    return ReduxHelper.createHttpRequestActionBundle<LineData>(
      // ToDo: replace the dirty URL composition with passsing the params object - change needs to be done on the backend at the same time
      {
        url: '/api/v1/twr/pnl/' + portfolioId,

        /* +
          assetClassId +
          '?fromDate=' +
          (prepPeriod.from ? prepPeriod.from : '') +
          '&toDate=' +
          (prepPeriod.to ? prepPeriod.to : ''),
*/
        // getApiUrl('portfolio.cumulativePerformance'),
        method: 'GET',
        params: {
          // portfolioId,
          assetClass: assetClassId,
          fromDate: prepPeriod.from ? prepPeriod.from : '',
          toDate: prepPeriod.to ? prepPeriod.to : '',
        },
        responseType: 'json',
      },
      DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_REQUEST,
      DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_SUCCESS,
      DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_ERROR
      // mapFunction
    );
  }

  static fetchIndexPerformance(indexId: string, period: IPeriod, custodians?: string[]) {
    const mapFunction = (responseData: { data: LineData; name: string }): any => responseData;

    return ReduxHelper.createHttpRequestActionBundle<LineData>(
      {
        url: getApiUrl('dashboard.indexPerformance'),
        data: {
          id: indexId,
          period: PeriodHelper.preparePeriodForRequest(period),
          detalization: DetalizationType.Daily,
          custodians,
        },
      },
      DashboardActions.INDEX_PERFORMANCE_REQUEST,
      DashboardActions.INDEX_PERFORMANCE_SUCCESS,
      DashboardActions.INDEX_PERFORMANCE_ERROR,
      mapFunction
    );
  }

  static fetchIndexPerformanceByAsset(
    indexId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    period: IPeriod,
    custodians?: string[]
  ) {
    const mapFunction = (responseData: { data: LineData; name: string }): any => responseData;

    return ReduxHelper.createHttpRequestActionBundle<LineData>(
      {
        url: getApiUrl('dashboard.indexPerformance'),
        data: {
          id: indexId,
          period: PeriodHelper.preparePeriodForRequest(period),
          asset,
          detalization: DetalizationType.Daily,
          custodians,
        },
      },
      DashboardActions.INDEX_PERFORMANCE_REQUEST,
      DashboardActions.INDEX_PERFORMANCE_SUCCESS,
      DashboardActions.INDEX_PERFORMANCE_ERROR,
      mapFunction
    );
  }

  static fetchAllocationsList(
    portfolioId: string,
    period: IPeriod,
    custodians?: string[],
    allocateBy?: EQUITY_EXPOSURE_TYPES,
    allocateGeographyBy?: GEOGRAPHY_ALLOCATION_TYPES
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IAllocationDict>(
      {
        url: getApiUrl('portfolio.allocations'),
        data: {
          portfolioId,
          withChildren: false,
          allocations: [
            ALLOCATION_TYPES.AssetClass,
            allocateGeographyBy,
            ALLOCATION_TYPES.Currency,
            ALLOCATION_TYPES.Custodian,
            ALLOCATION_TYPES.Sectors,
            ALLOCATION_TYPES.Industry,
          ],
          valuationDate: PeriodHelper.preparePeriodForRequest(period).to,
          custodians,
          allocateBy,
        },
      },
      DashboardActions.ALLOCATIONS_REQUEST,
      DashboardActions.ALLOCATIONS_SUCCESS,
      DashboardActions.ALLOCATIONS_ERROR
    );
  }

  static fetchAllocationsByAsset(
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    allocations: ALLOCATION_TYPES[]
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IAllocationDict>(
      {
        url: getApiUrl('portfolio.allocations'),
        data: {
          portfolioId,
          asset,
          withChildren: false,
          allocations,
        },
      },
      DashboardActions.ALLOCATIONS_REQUEST,
      DashboardActions.ALLOCATIONS_SUCCESS,
      DashboardActions.ALLOCATIONS_ERROR
    );
  }

  static resetAllocations(): IActionPayload {
    return {
      type: DashboardActions.ALLOCATIONS_RESET,
    };
  }

  static fetchExposureAllocations(
    portfolioId: string,
    asset: { type: ALLOCATION_TYPES; id: string },
    allocations: ALLOCATION_TYPES[],
    allocateBy: EQUITY_EXPOSURE_TYPES
  ) {
    return ReduxHelper.createHttpRequestActionBundle<IAllocationDict>(
      {
        url: getApiUrl('portfolio.allocations'),
        data: {
          portfolioId,
          asset,
          withChildren: false,
          allocations,
          allocateBy,
        },
      },
      DashboardActions.EXPOSURE_ALLOCATIONS_REQUEST,
      DashboardActions.EXPOSURE_ALLOCATIONS_SUCCESS,
      DashboardActions.EXPOSURE_ALLOCATIONS_ERROR
    );
  }

  static resetExposureAllocations(): IActionPayload {
    return {
      type: DashboardActions.EXPOSURE_ALLOCATIONS_RESET,
    };
  }

  static fetchPortfolioWealth(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IDashboardWealth>(
      {
        url: getApiUrl('portfolio.wealth'),
        data: { portfolioId },
      },
      DashboardActions.DASHBOARD_WEALTH_REQUEST,
      DashboardActions.DASHBOARD_WEALTH_SUCCESS,
      DashboardActions.DASHBOARD_WEALTH_ERROR
    );
  }

  static fetchTotalValues(portfolioId: string, asset: { type: ALLOCATION_TYPES; id: string }) {
    return ReduxHelper.createHttpRequestActionBundle<IDashboardAssetClass>(
      {
        url: getApiUrl('dashboard.info'),
        method: 'post',
        data: {
          portfolioId,
          asset,
        },
        responseType: 'json',
      },
      DashboardActions.DASHBOARD_TOTAL_VALUES_REQUEST,
      DashboardActions.DASHBOARD_TOTAL_VALUES_SUCCESS,
      DashboardActions.DASHBOARD_TOTAL_VALUES_ERROR
    );
  }

  static fetchBreakdownReport(portfolioId: string, asset: { type: ALLOCATION_TYPES; id: string }) {
    return ReduxHelper.createHttpRequestActionBundle<IBreakdownReport>(
      {
        url: getApiUrl('portfolio.profit.breakdown'),
        method: 'GET',
        params: {
          portfolioId,
          assetClass: asset.id,
        },
        responseType: 'json',
      },
      DashboardActions.PROFIT_REPORT_BREAKDOWN_REQUEST,
      DashboardActions.PROFIT_REPORT_BREAKDOWN_SUCCESS,
      DashboardActions.PROFIT_REPORT_BREAKDOWN_ERROR
    );
  }

  static fetchOptionsDashboardData(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IOptionsDashboardData>(
      {
        url: getApiUrl('portfolio.profit.options'),
        method: 'GET',
        params: {
          portfolioId,
        },
        responseType: 'json',
      },
      DashboardActions.OPTIONS_DASHBOARD_REQUEST,
      DashboardActions.OPTIONS_DASHBOARD_SUCCESS,
      DashboardActions.OPTIONS_DASHBOARD_ERROR
    );
  }

  static fetchFundamentalsReport(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IFundamentalsReport>(
      {
        url: getApiUrl('portfolio.fundamentals'),
        method: 'GET',
        params: {
          portfolioId,
        },
        responseType: 'json',
      },
      DashboardActions.FUNDAMENTALS_REPORT_REQUEST,
      DashboardActions.FUNDAMENTALS_REPORT_SUCCESS,
      DashboardActions.FUNDAMENTALS_REPORT_ERROR
    );
  }

  static fetchBondFundamentalsReport(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBondFundamentalsReport>(
      {
        url: getApiUrl('portfolio.bondFundamentals'),
        method: 'GET',
        params: {
          portfolioId,
        },
        responseType: 'json',
      },
      DashboardActions.BOND_FUNDAMENTALS_REPORT_REQUEST,
      DashboardActions.BOND_FUNDAMENTALS_REPORT_SUCCESS,
      DashboardActions.BOND_FUNDAMENTALS_REPORT_ERROR
    );
  }

  static fetchBreakdownPositionReport(portfolioId: string, positionId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBreakdownPosition>(
      {
        url: getCustomApiUrl('portfolio.profit.breakdownPosition', [
          { idLabel: ':portfolioId', idValue: portfolioId },
          { idLabel: ':positionId', idValue: positionId },
        ]),
        method: 'GET',
        responseType: 'json',
      },
      DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_REQUEST,
      DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_SUCCESS,
      DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_ERROR
    );
  }

  static resetfetchBreakdownPositionReport(): IActionPayload {
    return {
      type: DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_RESET,
    };
  }

  static fetchCoupons(portfolioId: string, assetClass: string, period: IPeriod, currency?: string) {
    const defaultPeriod = PeriodHelper.preparePeriodForRequest(period, 'DEFAULT');
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('income.detailed'),
        method: 'POST',
        data: {
          portfolioId,
          assetClass,
          period: {
            from: defaultPeriod.from,
            to: defaultPeriod.to,
          },
          currency: currency === 'All' ? undefined : currency,
        },
      },
      DashboardActions.INCOME_DETAILED_REQUEST,
      DashboardActions.INCOME_DETAILED_SUCCESS,
      DashboardActions.INCOME_DETAILED_ERROR
    );
  }

  static fetchRepayments(portfolioId: string, currency?: string, toFirstEvent?: boolean) {
    const fePos = toFirstEvent === undefined ? true : toFirstEvent;

    console.log('***POS FROM REDUCER****');
    console.log(toFirstEvent);

    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('income.repayments'),
        method: 'POST',
        data: {
          portfolioId,
          toFirstEvent: fePos,
          currency: currency || undefined,
        },
      },
      DashboardActions.INCOME_REPAYMENTS_REQUEST,
      DashboardActions.INCOME_REPAYMENTS_SUCCESS,
      DashboardActions.INCOME_REPAYMENTS_ERROR
    );
  }

  static fetchFixedIncomeYield(portfolioId: string, period: IPeriod, currency?: string) {
    const defaultPeriod = PeriodHelper.preparePeriodForRequest(period, 'DEFAULT');
    return ReduxHelper.createHttpRequestActionBundle<IFixedIncomeYield[]>(
      {
        url: getApiUrl('income.yield'),
        method: 'POST',
        data: {
          portfolioId,
          period: {
            from: defaultPeriod.from,
            to: defaultPeriod.to,
          },
          currency: currency === 'All' ? undefined : currency,
        },
      },
      DashboardActions.FIXED_INCOME_YIELD_REQUEST,
      DashboardActions.FIXED_INCOME_YIELD_SUCCESS,
      DashboardActions.FIXED_INCOME_YIELD_ERROR
    );
  }

  static setPerformancePeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: DashboardActions.SET_PERFORMANCE_PERIOD,
      payload: period,
    };
  }

  static setPnlByAttributionPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: DashboardActions.SET_PERFORMANCE_PERIOD,
      payload: period,
    };
  }

  static setProfitPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: DashboardActions.SET_PROFIT_PERIOD,
      payload: period,
    };
  }

  static setCustodiansFilter(custodians: Record<string, boolean>): IActionPayload<Record<string, boolean>> {
    return {
      type: DashboardActions.SET_CUSTODIANS_FILTER,
      payload: custodians,
    };
  }

  static resetCustodiansFilter(): IActionPayload<Record<string, boolean>> {
    return {
      type: DashboardActions.RESET_CUSTODIANS_FILTER,
      payload: {},
    };
  }

  static resetProfit(): IActionPayload {
    return {
      type: DashboardActions.PROFIT_RESET,
    };
  }

  static resetProfitAttribution(): IActionPayload {
    return {
      type: DashboardActions.PROFIT_ATTRIBUTION_RESET,
    };
  }

  static resetIndexPerformance(): IActionPayload {
    return {
      type: DashboardActions.INDEX_PERFORMANCE_RESET,
    };
  }

  static resetPerformance(): IActionPayload {
    return {
      type: DashboardActions.PERFORMANCE_RESET,
    };
  }

  static resetCoupons(): IActionPayload {
    return {
      type: DashboardActions.INCOME_DETAILED_RESET,
    };
  }
  static resetPerformancePeriod(): IActionPayload {
    return {
      type: DashboardActions.RESET_PERFORMANCE_PERIOD,
    };
  }

  static resetTotalValues(): IActionPayload {
    return {
      type: DashboardActions.DASHBOARD_TOTAL_VALUES_RESET,
    };
  }

  static resetFixedIncomeYieldValues(): IActionPayload {
    return {
      type: DashboardActions.FIXED_INCOME_YIELD_RESET,
    };
  }

  static resetAssetSceneData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(DashboardActions.resetCoupons());
      dispatch(DashboardActions.resetProfitAttribution());
      dispatch(DashboardActions.resetPerformance());
      dispatch(DashboardActions.resetIndexPerformance());
      dispatch(DashboardActions.resetPerformancePeriod());
      dispatch(DashboardActions.resetAllocations());
      dispatch(DashboardActions.resetTotalValues());
      dispatch(DashboardActions.resetFixedIncomeYieldValues());
      dispatch(DashboardActions.resetTopList());
    };
  }

  static resetAllData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(DashboardActions.resetProfit());
      dispatch(DashboardActions.resetCoupons());
      dispatch(DashboardActions.resetProfitAttribution());
      dispatch(DashboardActions.resetPerformance());
      dispatch(DashboardActions.resetIndexPerformance());
      dispatch(DashboardActions.resetPerformancePeriod());
      dispatch(DashboardActions.resetAllocations());
      dispatch(DashboardActions.resetTopList());
      dispatch(DashboardViewActions.resetProfitView());
    };
  }
}

export default DashboardActions;
