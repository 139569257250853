import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../../../../../components/HOC/withPortfolioInfo';
import { Button } from '../../../../../../../../components/UIWidgets/Button';
import { PATHS } from '../../../../../../../../router/paths';
import s from './NoData.module.css';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import CapabilitiesHelper from '../../../../../../../../services/utils/CapabilitiesHelper';

const NoData: FunctionComponent<IWithPortfolioInfoProps & { message?: string }> = ({
  portfolioInfo,
  message = "You haven't added any assets yet.",
}) => {
  const { actions } = useCapabilities();
  const canAddTrade = actions?.includes(CapabilitiesHelper.Actions.ADD_TRADE);

  return (
    <div className={s.noDataBlock}>
      <div className={s.noDataText}>
        {message}
        <br /> {canAddTrade ? 'Would you like to add some?' : 'Contact your Portfolio Manager to add trades'}
      </div>

      {canAddTrade && (
        <Link to={PATHS.portfolio.snapshot.edit.path.replace(':portfolioId', portfolioInfo.id)}>
          <Button size="small">Add</Button>
        </Link>
      )}
    </div>
  );
};

export default React.memo(withPortfolioInfo(NoData));
