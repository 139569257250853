import { combineReducers } from 'redux';
import { IAllocationDict } from '../../../models/IAllocationDict';

import { IOrderingTopListState } from '../../../models/ITopListState';
import { LineData } from '../../../models/LineData';
import { IBarsChartDataState } from '../../../models/redux/IBarsChartDataState';
import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { ReduxHelper } from '../../utils/ReduxHelper';
import alternativesReducers from './AlternativesReducers';

import creditReducers from './CreditReducers';
import equityReducers from './EquityReducers';
import fundamentalsReportReducer, { IFundamentalsReportState } from './FundamentalsReportReducer';
import bondFundamentalsReportReducer, { IBondFundamentalsReportState } from './BondFundamentalsReportReducer';

import incomeDetailedReducer from './IncomeDetailedReducers';
import incomeRepaymentReducer from './IncomeRepaymentReducers';
import incomeYieldToMaturityReducer from './IncomeYieldToMaturityReducers';
import fixedIncomeYieldReducer from './FixedIncomeYieldReducers';
import cumulativePerformanceByAssetReducer from './CumulativePerformanceByAssetReducer';
import performanceReducers, { IPerformanceState } from './PerformanceReducers';
import portfolioReducers, { IPortfolioState } from './PortfolioReducers';
import profitAttributionReducer from './ProfitAttributionReducers';
import portfolioCustodiansReducer from './PortfolioCustodiansReducer';
import topListByPnlReducer from './TopListByPnlReducer';
import topListReducer from './TopListReducers';
import totalValuesReducers from './TotalValuesReducers';
import portfolioPnlByAttributionReducer, { IPortfolioPnlByAttributionState } from './PortfolioPnlByAttributionReducer';
import portfolioPnlByAssetClassReducer, { IPortfolioPnlByAssetClassState } from './PortfolioPnlByAssetClassReducer';
import breakdownPositionReducer, { IBreakdownPositionState } from './BreakdownPositionReducer';
import profitReportBreakdonReducer, { IBreakdownReportState } from './ProfitReportBreakdonReducer';
import SelectedCustodiansReducers from './SelectedCustodiansReducers';
import optionsReducer, { IOptionsDashboardState, IOptionsPerformanceState } from './OptionsReducers';

export interface IDashboardState {
  breakdownPosition: IBreakdownPositionState;
  profitReportBreakdon: IBreakdownReportState;
  fundamentalsReport: IFundamentalsReportState;
  bondFundamentalsReport: IBondFundamentalsReportState;
  topList: IOrderingTopListState;
  topListByPnl: IOrderingTopListState;
  allocations: ILoadingDataState<IAllocationDict>;
  exposureAllocations: ILoadingDataState<IAllocationDict>;
  profitAttribution: ILoadingPeriodDataState<IBarData[]>;
  portfolioPnlByAttribution: IPortfolioPnlByAttributionState;
  portfolioPnlByAssetClass: IPortfolioPnlByAssetClassState;
  performance: IPerformanceState;
  portfolio: IPortfolioState;
  totalValues: {
    info: ILoadingDataState<IDashboardAssetClass>;
  };
  alternatives: {
    distribution: IBarsChartDataState;
  };
  equity: ILoadingPeriodDataState<IBarData[]>;
  options: {
    dashboard: IOptionsDashboardState;
    pnlByMonth: ILoadingDataState<IBarData[]>;
    performance: IOptionsPerformanceState;
  };
  incomeDetailed: ILoadingPeriodDataState<IBarData[]>;
  incomeRepayment: ILoadingDataState<IBarData[]>;
  incomeYieldToMaturity: ILoadingPeriodDataState<IBarData[]>;
  fixedIncomeYield: ILoadingPeriodDataState<IFixedIncomeYield[]>;
  cumulativePerformanceByAsset: ILoadingPeriodDataState<LineData>;
  credit: {
    coupons: IBarsChartDataState;
    maturity: ILoadingPeriodDataState<LineData>;
    repayments: ILoadingDataState<IBarData | null>;
  };
  selectedCustodians: Record<string, boolean>;
  userPortfolioCustodians: ICustodian[];
}

const dashboardReducer = combineReducers<IDashboardState>({
  breakdownPosition: breakdownPositionReducer,
  userPortfolioCustodians: portfolioCustodiansReducer,
  profitReportBreakdon: profitReportBreakdonReducer,
  fundamentalsReport: fundamentalsReportReducer,
  bondFundamentalsReport: bondFundamentalsReportReducer,
  topList: topListReducer,
  topListByPnl: topListByPnlReducer,
  profitAttribution: profitAttributionReducer,
  allocations: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IAllocationDict>>(
    ReduxHelper.createInitialState({}),
    DashboardActions.ALLOCATIONS_REQUEST,
    DashboardActions.ALLOCATIONS_SUCCESS,
    DashboardActions.ALLOCATIONS_ERROR,
    DashboardActions.ALLOCATIONS_RESET
  ),
  exposureAllocations: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IAllocationDict>>(
    ReduxHelper.createInitialState({}),
    DashboardActions.EXPOSURE_ALLOCATIONS_REQUEST,
    DashboardActions.EXPOSURE_ALLOCATIONS_SUCCESS,
    DashboardActions.EXPOSURE_ALLOCATIONS_ERROR,
    DashboardActions.EXPOSURE_ALLOCATIONS_RESET
  ),
  performance: performanceReducers,
  portfolioPnlByAttribution: portfolioPnlByAttributionReducer,
  portfolioPnlByAssetClass: portfolioPnlByAssetClassReducer,
  portfolio: portfolioReducers,
  alternatives: alternativesReducers,
  equity: equityReducers,
  options: optionsReducer,
  incomeDetailed: incomeDetailedReducer,
  incomeYieldToMaturity: incomeYieldToMaturityReducer,
  fixedIncomeYield: fixedIncomeYieldReducer,
  incomeRepayment: incomeRepaymentReducer,
  cumulativePerformanceByAsset: cumulativePerformanceByAssetReducer,
  credit: creditReducers,
  totalValues: totalValuesReducers,
  selectedCustodians: SelectedCustodiansReducers,
});

export default dashboardReducer;
