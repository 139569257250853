import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import s from './PageHeader.module.scss';
import { usePortfolio } from '../../services/hooks/usePortfolio';

export interface IPageHeader {
  className?: string;
  toolsWrapperClassName?: string;
  title?: string;
  subtitle?: string;
  showPortfolioInfo?: boolean;
}

type IProps = PropsWithChildren<IPageHeader>;

const PageHeader: FunctionComponent<IProps> = ({
  title: titleIn,
  subtitle,
  children,
  className,
  toolsWrapperClassName,
  showPortfolioInfo,
}) => {
  const { portfolioInfo } = usePortfolio();

  let title = titleIn;

  if (showPortfolioInfo) {
    title = `${portfolioInfo.data?.name}`;
  }

  return (
    <React.Fragment>
      <div className={cn(s.header, className)}>
        <div>
          <h1>
            {title}
            <div className={cn(s.portfolioCurrency)}>{portfolioInfo.data?.currency.name}</div>
          </h1>
          {showPortfolioInfo && <h3>{titleIn}</h3>}
          {subtitle ? <h3> {subtitle}</h3> : null}
        </div>
        <div className={cn(s.toolsWrapper, toolsWrapperClassName)}>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(PageHeader);
