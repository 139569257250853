export const DEFAULT_POSITION_FIELDS = {
  name: true,
  assetSubClass: false,
  sector: false,
  currencyNative: true,
  country: false,
  custodian: true,
  costPrice: true,
  currentPriceNative: true,
  pricingDate: false,
  priceGain: true,
  totalCostBase: false,
  percentOfPortfolio: false,
  profitAndLoss: true,
  notionBase: false,
  exposureBase: false,
  incomeHistoricBase: false,
  realisedBase: false,
};

export const DEFAULT_TRANSACTION_FIELDS = {
  currency: true,
  amount: true,
  multiplier: false,
  commission: true,
  tradeCosts: true,
  custodian: true,
};

export const DEFAULT_PREFERENCES: IUserPreferences = {
  dateType: 'yyyy-mm-dd',
  defaultToPerformance: false,
  currency: null,
  locale: 'en',
  misc: {
    visiblePositionFields: DEFAULT_POSITION_FIELDS,
    visibleTransactionFields: DEFAULT_TRANSACTION_FIELDS,
  },
};
