import { Dispatch } from 'redux';

import { IActionPayload } from '../../models/redux/IActionPayload';
import { getApiUrl } from '../constants/endpoints';
import { PeriodHelper } from '../utils/PeriodHelper';
import { ReduxHelper } from '../utils/ReduxHelper';

class EquityActions {
  static readonly DIVIDENDS_REQUEST = 'DIVIDENDS_REQUEST';
  static readonly DIVIDENDS_SUCCESS = 'DIVIDENDS_SUCCESS';
  static readonly DIVIDENDS_ERROR = 'DIVIDENDS_ERROR';
  static readonly DIVIDENDS_RESET = 'DIVIDENDS_RESET';
  static readonly DIVIDENDS_CHANGE_PERIOD = 'DIVIDENDS_CHANGE_PERIOD';

  static fetchDividends(portfolioId: string, assetClass: string = 'Equities', period: IPeriod, currency?: string) {
    // const queryType = assetClass === 'Funds' ? 'equity.fundDividends' : 'equity.equityDividends';

    const query = 'equity.dividendsDetailed';

    // ToDo: The New Endpoint for Dividends doesn't support null Dates (so we needed to adapt on the frontend.
    // ToDo: Consistency needs to be adopted across all the endpoints in this aspect.

    const defaultPeriod = PeriodHelper.preparePeriodForRequest(period, 'DEFAULT');

    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl(query),
        method: 'POST',
        data: {
          portfolioId,
          assetClass,
          period: {
            from: defaultPeriod.from,
            to: defaultPeriod.to,
          },
          currency: currency === 'All' ? undefined : currency,
        },
      },
      EquityActions.DIVIDENDS_REQUEST,
      EquityActions.DIVIDENDS_SUCCESS,
      EquityActions.DIVIDENDS_RESET
    );
  }

  static resetDividends(): IActionPayload {
    return {
      type: EquityActions.DIVIDENDS_RESET,
    };
  }

  static changeDividendsPeriod(period: IPeriod, currency?: string): IActionPayload<any> {
    return { type: EquityActions.DIVIDENDS_CHANGE_PERIOD, payload: { period, currency } };
  }

  static resetEquityData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(EquityActions.resetDividends());
    };
  }
}

export default EquityActions;
