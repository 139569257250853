import React, { Fragment, ReactElement } from 'react';
import { Redirect, Route } from 'react-router';
import { Helmet } from "react-helmet";

import { UserHelpers } from './UserHelpers';

import { ModalWindow } from '../../components/ModalWindow';
import { ProtectedRoute } from '../../components/ProtectedRoute';

import { IRouteConfig } from '../../models/IRouteConfig';
import { IModalWindowProps } from '../../components/ModalWindow/ModalWindow';

type RoutesElement = ReactElement | undefined;

export interface IRoutesArray {
  [index: number]: IRoutesArray | RoutesElement;
}

export interface IModalRouteConfig extends IRouteConfig {
  props?: IModalWindowProps;
}

export class RouterHelper {
  static createSceneRoutesFromConfig = (routes: IRouteConfig[]): IRoutesArray =>
    routes.map((route, i) => {
      const Component: React.ElementType = route.component || Fragment;
      return [
        route.component &&
          (route.allowedRoles ? (
            <ProtectedRoute
              exact={typeof route.exact !== 'undefined' ? route.exact : true}
              key={i}
              path={route.path}
              isAllowed={UserHelpers.checkPermissions(route.allowedRoles)}
              render={() => {
                return (
                  <React.Fragment>
                    <Helmet><title>{route.title ? `illio - ${route.title}` : "illio"}</title></Helmet>
                    <Component subRoutes={route.routes} />
                  </React.Fragment>
                );
              }}
            />
          ) : (
            <Route
              exact={typeof route.exact !== 'undefined' ? route.exact : true}
              key={i}
              path={route.path}
              render={() => {
                return (
                  <React.Fragment>
                    <Helmet><title>{route.title ? `illio - ${route.title}` : "illio"}</title></Helmet>
                    <Component subRoutes={route.routes} />
                  </React.Fragment>
                );
              }}
            />
          )),
        route.routes && RouterHelper.createSceneRoutesFromConfig(route.routes),
        route.redirect && (
          <Route key={i} exact={true} path={route.redirect.from} render={() => <Redirect to={route.redirect!.to} />} />
        ),
      ];
    });

  static createModalRoutesFromConfig = (routes: IModalRouteConfig[]): IRoutesArray =>
    routes.map((route, i) => {
      const Component: React.ElementType = route.component || Fragment;
      return (
        <Route
          exact={typeof route.exact !== 'undefined' ? route.exact : true}
          key={i}
          path={route.path}
          render={() => {
            return (
              <ModalWindow {...route.props}>
                <Component subRoutes={route.routes} />
              </ModalWindow>
            );
          }}
        />
      );
    });
}
