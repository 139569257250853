import * as React from 'react';
import s from './ProgressBars.module.scss';
import ProgressBar from './component/ProgressBar';
import { Loader } from '../../../../../../components';
import { ENVIRONMENTAL_COLOR, ESG_COLOR, GOVERNANCE_COLOR, SOCIAL_COLOR } from '../../utils/esgColors';

interface IProps {
  item: EsgItem;
  title: string;
  loaded: boolean;
  type: 'long' | 'short';
}

const ProgressBars = ({ item, title, loaded, type }: IProps) => {
  if (!item) {
    return null;
  }
  return (
    <div className={s.container} style={type === 'long' ? { marginRight: 60 } : { marginLeft: 60 }}>
      <h2 className={s.title}>{title}</h2>
      <div style={{ height: 50 }} />
      <ProgressBar loaded={loaded} percentage={item.esg.total} color={ESG_COLOR} />
      <div style={{ height: 60 }} />
      <ProgressBar loaded={loaded} percentage={item.esg.e} color={ENVIRONMENTAL_COLOR} />
      <div style={{ height: 60 }} />
      <ProgressBar loaded={loaded} percentage={item.esg.s} color={SOCIAL_COLOR} />
      <div style={{ height: 60 }} />
      <ProgressBar loaded={loaded} percentage={item.esg.g} color={GOVERNANCE_COLOR} />
      <div style={{ height: 60 }} />
      <span style={{ fontSize: 22 }}>Percentage of {type} positions with ESG scores</span>
      <div style={{ height: 25 }} />
      <ProgressBar
        loaded={loaded}
        percentage={item.percentage.ofWhichWithScores}
        color={type === 'long' ? '#506ED8' : '#324BA0'}
        withPercentage={true}
      />
      <div style={{ height: 25 }} />
    </div>
  );
};

export default ProgressBars;
