import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { DashboardActions } from '../../actions';
import { ReduxHelper } from '../../utils/ReduxHelper';

export interface IBondFundamentalsReportState extends ILoadingDataState {
  data: IBondFundamentalsReport[];
}

const INITIAL_STATE: IBondFundamentalsReportState = ReduxHelper.createInitialState<IBondFundamentalsReport[]>([]);

const bondFundamentalsReportReducer = ReduxHelper.createHttpRequestReducer<ILoadingDataState<IBondFundamentalsReport[]>>(
  INITIAL_STATE,
  DashboardActions.BOND_FUNDAMENTALS_REPORT_REQUEST,
  DashboardActions.BOND_FUNDAMENTALS_REPORT_SUCCESS,
  DashboardActions.BOND_FUNDAMENTALS_REPORT_ERROR
);

export default bondFundamentalsReportReducer;
