import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { InstrumentForm, InstrumentMarketContainer, InstrumentIncomeContainer } from './components';
import { PageHeader } from '../../../../components/PageHeader';
import { Button, Tab, Tabs } from '../../../../components/UIWidgets';
import { PATHS } from '../../../../router/paths';

import { IOption } from '../../../../components/UIWidgets/Autocomplete';

import { NestedSet } from '../../../../components/NestedList/NestedSet';

import s from './InstrumentDetails.module.css';

interface IProps {
  instrumentInfo: IInstrument | null;
  isFormValid: boolean;
  onSave: () => void;
  currencyOptions: Array<IOption<ICurrency>>;
  industryOptions: Array<IOption<IIndustry>>;
  countryOptions: Array<IOption<ICountry>>;
  userListOptions: Array<IOption<IUser>>;
  authInfo: IAuthInfo | null;
  searchUser: (search: string) => void;
  nestedSetFromAssetClasses: NestedSet<any>;
}

class InstrumentDetails extends PureComponent<IProps> {
  renderTabs = (): React.ReactNode => {
    return (
      <Tabs className={s.tabsWrapper}>
        <Tab label="Price">
          <div className={s.tabContent}>
            <InstrumentMarketContainer />
          </div>
        </Tab>
        <Tab label="Income">
          <div className={s.tabContent}>
            <InstrumentIncomeContainer />
          </div>
        </Tab>
      </Tabs>
    );
  };

  render() {
    const {
      instrumentInfo,
      isFormValid,
      onSave,
      currencyOptions,
      industryOptions,
      countryOptions,
      userListOptions,
      authInfo,
      searchUser,
      nestedSetFromAssetClasses,
    } = this.props;

    return (
      <div className="separate-page-content">
        <div className={cn('separate-page-content__item', 'separate-page-content__item--top')}>
          <PageHeader title={instrumentInfo ? 'Instrument details' : 'Instruments'}>
            {!instrumentInfo && (
              <Fragment>
                <Link to={PATHS.instruments.path}>
                  <Button className={s.titleBtn} size="small" variant="outline" active={false}>
                    Cancel
                  </Button>
                </Link>
                <Button className={s.titleBtn} size="small" disabled={!isFormValid} onClick={() => onSave()}>
                  Add
                </Button>
              </Fragment>
            )}
          </PageHeader>
          {/*<div className={s.description}>*/}
          {/*  You may add transactions one by one or import them from excel file in order to update your portfolio or you*/}
          {/*  can upload portfolio snapshot and use it to override current portfolio.*/}
          {/*</div>*/}
          <div className={s.instrumentFormWrapper}>
            <InstrumentForm
              instrument={instrumentInfo}
              onSubmit={onSave}
              currencyOptions={currencyOptions}
              industryOptions={industryOptions}
              countryOptions={countryOptions}
              userListOptions={userListOptions}
              authInfo={authInfo}
              searchUser={searchUser}
              nestedSetFromAssetClasses={nestedSetFromAssetClasses}
            />
          </div>
        </div>
        <div className={cn('separate-page-content__item', 'separate-page-content__item--bottom')}>
          <div className={s.tabs}>{this.renderTabs()}</div>
        </div>
      </div>
    );
  }
}

export default InstrumentDetails;
