import moment from 'moment-timezone';

import { DATE_FORMAT, PERIOD_TYPE } from '../constants/constants';

export class PeriodHelper {
  static formatDate(value: moment.MomentInput, format: string = DATE_FORMAT): string {
    return moment(value).format(format);
  }

  static isValidDate(value: moment.MomentInput): boolean {
    return moment(value).isValid();
  }

  static preparePeriodForRequest(period: IPeriod, periodType = 'DEFAULT'): IPeriodRequest {
    return period
      ? {
          from: period.from ? moment(period.from).format('YYYY-MM-DD') : period.from,
          to: period.to ? moment(period.to).format('YYYY-MM-DD') : period.to,
        }
      : { from: null, to: null };
  }

  static getPeriod(period: PERIOD_TYPE): Omit<IPeriod, 'type'> {
    const now = moment(moment().tz('Europe/London').toDate());
    let from: Date;

    switch (period) {
      case PERIOD_TYPE.THIRTY_DAYS: {
        from = moment(now).subtract(30, 'days').toDate();
        break;
      }
      case PERIOD_TYPE.MTD: {
        if (moment().date() === 1) {
          // ToDo: looks like a useless check - the current version of lib works just fine, not sure why substraction was done before? The IF to be removed. Same for YTD.
          // from = moment(now).subtract(1, 'month').startOf('month').toDate();
          from = moment(now).startOf('month').toDate();
        } else {
          from = moment(now).startOf('month').toDate();
        }
        break;
      }
      case PERIOD_TYPE.YTD:
        if (moment().date() !== 1) {
          // from = moment(now).subtract(1, 'year').startOf('year').toDate();
          from = moment(now).startOf('year').toDate();
        } else {
          from = moment(now).startOf('year').toDate();
        }
        break;
      case PERIOD_TYPE.LAST_YEAR:
        return {
          to: moment(now).subtract(1, 'year').endOf('year').toDate(),
          from: moment(now).subtract(1, 'year').startOf('year').toDate(),
        };
      case PERIOD_TYPE.YEAR:
        from = moment(now).subtract(1, 'years').add(1, 'days').toDate();
        break;
      case PERIOD_TYPE.THREE_YEARS:
        from = moment(now).subtract(3, 'years').add(1, 'days').toDate();
        break;
      case PERIOD_TYPE.INCEPTION:
        return {
          to: moment().toDate(),
          from: null,
        };
      case PERIOD_TYPE.THREE_MONTHS:
        from = moment(now).subtract(3, 'months').add(1, 'days').toDate();
        break;
      case PERIOD_TYPE.SIX_MONTHS:
        from = moment(now).subtract(6, 'months').add(1, 'days').toDate();
        break;
      case PERIOD_TYPE.A_DAY:
        const lastWorkDay =
          moment(now).day() === 0
            ? moment(now).subtract(2, 'days').toDate()
            : moment(now).day() === 1
            ? moment(now).subtract(3, 'days').toDate()
            : moment(now).subtract(1, 'days').toDate();
        return {
          to: lastWorkDay,
          from: lastWorkDay,
        };
      case PERIOD_TYPE.A_WEEK:
        from = moment(now).subtract(6, 'days').toDate();
        break;
      default:
        from = moment().subtract(4, 'month').add(1, 'day').toDate();
        break;
    }
    return { from, to: now.toDate() };
  }
}
