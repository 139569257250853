import { IOption } from '../../../../../components/UIWidgets/Autocomplete';

export type GroupingOption = 'sector' | 'assetClass' | 'country' | 'currency';

export const GROUPING_OPTIONS: Array<IOption<GroupingOption>> = [
  { id: 'sector', name: 'Sector', value: 'sector' },
  { id: 'assetClass', name: 'Asset Class', value: 'assetClass' },
  { id: 'country', name: 'Country', value: 'country' },
  { id: 'currency', name: 'Currency', value: 'currency' },
];

export type PositionGrouping = 'assetClass' | 'custodian';

export const POSITION_GROUPING_OPTIONS: Array<IOption<PositionGrouping>> = [
  { id: 'assetClass', name: 'Asset Class', value: 'assetClass' },
  { id: 'custodian', name: 'Custodian', value: 'custodian' },
];

type PeriodOption = 'sinceInception' | 'custom' | 'lastCalendarYear' | 'priorMonth';

export const PERIOD_OPTIONS: Array<IOption<PeriodOption>> = [
  { id: 'sinceInception', name: 'Since Inception', value: 'sinceInception' },
  { id: 'priorMonth', name: 'Prior Month', value: 'priorMonth' },
  { id: 'lastCalendarYear', name: 'Last Calendar Year', value: 'lastCalendarYear' },
  { id: 'custom', name: 'Custom', value: 'custom' },
];
