import { combineReducers } from 'redux';
import { ReduxHelper } from '../../utils/ReduxHelper';

import { CreditActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';

import { IBarsChartDataState } from '../../../models/redux/IBarsChartDataState';
import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';

import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { LineData } from '../../../models/LineData';

const INITIAL_MATURITY_STATE: ILoadingPeriodDataState<LineData> = {
  ...ReduxHelper.createInitialState([]),
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.YEAR), type: PERIOD_TYPE.YEAR },
};

const creditReducers = combineReducers({
  coupons: ReduxHelper.createHttpRequestReducer<IBarsChartDataState>(
    ReduxHelper.createInitialState([]),
    CreditActions.COUPONS_REQUEST,
    CreditActions.COUPONS_SUCCESS,
    CreditActions.COUPONS_ERROR,
    CreditActions.COUPONS_RESET
  ),
  maturity: ReduxHelper.createHttpRequestWithPeriodReducer<ILoadingPeriodDataState<LineData>>(
    INITIAL_MATURITY_STATE,
    CreditActions.MATURITY_REQUEST,
    CreditActions.MATURITY_SUCCESS,
    CreditActions.MATURITY_ERROR,
    CreditActions.SET_MATURITY_PERIOD,
    CreditActions.MATURITY_RESET
  ),
  repayments: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IBarData | null>>(
    ReduxHelper.createInitialState(null),
    CreditActions.REPAYMENTS_REQUEST,
    CreditActions.REPAYMENTS_SUCCESS,
    CreditActions.REPAYMENTS_ERROR,
    CreditActions.REPAYMENTS_RESET
  ),
});

export default creditReducers;
