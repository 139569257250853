import * as React from 'react';
import s from './TopEsgPortfolioTable.module.scss';
import { Table } from '@iliotech/storybook';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import {
  CIRCLE_AMBER,
  CIRCLE_AMBER_GREEN,
  CIRCLE_AMBER_RED,
  CIRCLE_GREEN,
  CIRCLE_RED,
  circleRenderer,
  getCircleColor,
} from '../../utils/esgCircleRenderer';
import { usePortfolioInfo } from '../../../../../../services/hooks/usePortfolioInfo';

interface IProps {
  data: EsgTopTenInstrument[];
}

const cellRenderer = (rowData: any, dataKey: any) => {
  return <span>{rowData[dataKey].toLocaleString(undefined, { maximumFractionDigits: 0 })}</span>;
};

const COLUMNS: (c: string) => ColumnProps[] = (currency: string) => [
  { label: 'Instrument Name', dataKey: 'instrumentName', flexGrow: 1 },
  { label: 'Exposure Amount (' + currency + ')', dataKey: 'netExposure', cellRenderer, flexGrow: 1 },
  { label: 'ESG', dataKey: 'esg', cellRenderer: circleRenderer('total'), align: 'center', flexGrow: 1 },
  { label: 'Environmental', dataKey: 'esg', cellRenderer: circleRenderer('e'), align: 'center', flexGrow: 1 },
  { label: 'Social', dataKey: 'esg', cellRenderer: circleRenderer('s'), flexGrow: 1, align: 'center' },
  { label: 'Governance', dataKey: 'esg', cellRenderer: circleRenderer('g'), flexGrow: 1, align: 'center' },
];

const TopEsgPortfolioTable = ({ data }: IProps) => {
  const circle = (color: string) => <div className={s.circle} style={{ backgroundColor: color }} />;
  const { portfolioInfo } = usePortfolioInfo();
  return (
    <div className={s.container}>
      <div className={s.headerRow}>
        <h2>ESG Scores of largest positions</h2>
        <div className={s.circlesRow}>
          <div className={s.circleContainer}>
            {circle(CIRCLE_RED)} <span style={{ marginLeft: 5 }}> 1-50</span>
          </div>
          <div className={s.circleContainer}>
            {circle(CIRCLE_AMBER_RED)} <span style={{ marginLeft: 5 }}>51-75</span>
          </div>
          <div className={s.circleContainer}>
            {circle(CIRCLE_GREEN)} <span style={{ marginLeft: 5 }}>76-100</span>
          </div>
        </div>
      </div>
      <div style={{ height: 10 }} />
      <Table columns={COLUMNS(portfolioInfo.data?.currency.symbol!)} data={data} rowHeight={35} headerHeight={35} />
    </div>
  );
};

export default TopEsgPortfolioTable;
