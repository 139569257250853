import cn from 'classnames';
import React, { PureComponent, useState } from 'react';
import nextId from 'react-id-generator';
import ReactTooltip from 'react-tooltip';
import { IPortfolioInfoState } from '../../services/reducers/PortfolioReducers';
import { FormatHelper } from '../../services/utils/FormatHelper';
import { Autocomplete, IOption } from '../UIWidgets/Autocomplete';
import s from './FundamentalsReport.module.scss';
import moment from 'moment';
import _ from 'lodash';
import { useCustodiansFilter } from '../../services/hooks/useCustodiansFilter';
import { TRANSACTION_TYPES } from '../../services/mock/transactionTypes';
import { capitaliseFirstLetter } from '../../services/utils/StringHelpers';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { Table } from '@iliotech/storybook/build/components/Table/Table';
import { Popover, Whisper } from 'rsuite';
import { explorerDecimalNumberRenderer } from '../../scenes/Portfolio/scenes/PortfolioExplorer/utils/explorerCurrencyRenderer';

export interface IProps {
  longs: IBondFundamentalsReport[];
  shorts: IBondFundamentalsReport[];
  portfolioInfo: IPortfolioInfoState;
  classId: string;
}

const formatDate = (date?: Date) => (!!date ? moment(date).format('DD-MMM-YYYY') : '');
const processData = (items?: IBondFundamentalsReport[]) =>
  items?.map(
    (item) =>
      ({
        ...item,
        currency: item.currency.name,
        coupon: item.couponRate + '%',
        maturity: formatDate(item.maturityDate),
        issue: formatDate(item.issueDate),
        yieldToMaturity: item.yieldToMaturity,
        yieldToCall: item.yieldToCall === null ? null : item.yieldToCall,
        duration: item.durationInYears,
        creditRating: item.creditRating,
        nextCouponDate: formatDate(item.nextCouponDate),
        nextCouponAmount:
          FormatHelper.localePriceValueFormatter(item.nextCouponAmountLocal, 0) + ' ' + item.currency.name,
        freq: capitaliseFirstLetter(item.couponFrequency?.replace('_', '-')),
        nextCallDate: item.nextCallDate === null ? null : formatDate(item.nextCallDate),
        nextCallPrice: FormatHelper.localePriceValueFormatter(item.nextCallPrice, 2),
        redemption: FormatHelper.formatNumber(item.redemption * 100, 1),
        countryOfRisk: item.countryOfRisk,
        industry: item.industry,
        custodian: item.custodian,
      } ?? [])
  );

const BondFundamentalsReport = ({ portfolioInfo, longs, shorts }: IProps) => {
  const { custodianOptions } = useCustodiansFilter();

  const [selectedCurrency, setSelectedCurrency] = useState<IOption<any> | null>(null);
  const [selectedCustodian, setSelectedCustodian] = useState<IOption<any> | null>(null);

  const renderDetailsColumn = (item: IBondFundamentalsReport) => {
    if (!item.nominal) {
      return (
        <div
          key={nextId()}
          className={cn(s.cell, s.detailCell, {
            [s.blue]: item.nominal < 0,
            [s.extraHeight]: true,
          })}
        >
          <span style={{ fontSize: 20, fontWeight: 500 }}>Weighted Average</span>
        </div>
      );
    }
    return (
      <div
        key={nextId()}
        className={cn(s.cell, s.detailCell, {
          [s.blue]: item.nominal < 0,
          [s.extraHeight]: true,
        })}
      >
        <div className={cn(s.splitColumn)}>
          <h4>
            <small>
              {item.name} [{item.code}]
            </small>
          </h4>
          <h4>
            <small>{item.custodian}</small>
          </h4>
        </div>
        <div className={cn(s.details)}>
          <div>
            <h6>Last Price</h6>
            <h5>
              {FormatHelper.localePriceValueFormatter(item.lastPrice, 2)}{' '}
              {item.currency.name === 'GBP' ? 'GBp' : item.currency.name}
            </h5>
          </div>
          <div>
            <h6>Cost price</h6>
            <h5>
              {FormatHelper.localePriceValueFormatter(item.costPrice, 2)}{' '}
              {item.currency.name === 'GBP' ? 'GBp' : item.currency.name}
            </h5>
          </div>
          <div>
            <h6>Nominal</h6>
            <h5>{item.nominal}</h5>
          </div>
          <div>
            <h6>Market Value (inc Accrued)</h6>
            <h5>
              {FormatHelper.localePriceValueFormatter(item.marketValueBaseIncludingAccrued, 0)}{' '}
              {portfolioInfo.data && portfolioInfo.data.currency.name}
            </h5>
          </div>
        </div>
      </div>
    );
  };

  const reportTable = React.useMemo(() => {
    const longHeader = !!longs?.length ? processData([{ ...longs[0], name: 'Weighted Average', code: 'Long' }]) : [];
    const shortHeader = !!shorts?.length ? processData([{ ...shorts[0], name: 'Weighted Average', code: 'Short' }]) : [];
    const longData = processData(longs?.slice(1));
    const shortData = processData(shorts?.slice(1));

    return [...longHeader, ...shortHeader, ...longData, ...shortData];
  }, []);

  const filteredReport = reportTable.filter(
    (item) =>
      (!selectedCustodian || selectedCustodian?.name === item.custodian) &&
      (!selectedCurrency || selectedCurrency?.name === item.currency)
  );

  const currencies = _.uniqBy(
    reportTable.map((item, idx) => ({ id: idx, name: item.currency, value: { id: idx, name: item.currency } })),
    'name'
  );

  const percentageCellRenderer = (rowData: IFundamentalsReport, dataKey: any) => {
    return explorerDecimalNumberRenderer(rowData, dataKey, false, true);
  };
  const columns = [
    {
      dataKey: 'code',
      label: 'Instrument',
      width: 200,
      flexGrow: 3,
      tooltip: <div>Asset Name</div>,
      // 'Asset name',
      cellRenderer: (rowData: IFundamentalsReport, dataKey: any) => (
        <Whisper
          speaker={
            <Popover>
              <div>
                {rowData.name} [{rowData.code}]
              </div>
            </Popover>
          }
        >
          <div style={{ color: rowData.exposure < 0 ? 'var(--light-blue)' : undefined }}>
            {rowData.name} [{rowData.code}]
          </div>
        </Whisper>
      ),
    },
    {
      dataKey: 'currency',
      label: 'Currency',
      // tooltip: ''
    },
    {
      dataKey: 'coupon',
      label: 'Coupon',
      // tooltip: ''
    },
    {
      dataKey: 'maturity',
      label: 'Maturity',
      // tooltip: ''
    },
    {
      dataKey: 'issue',
      label: 'Issue',
      // tooltip: ''
    },
    {
      dataKey: 'yieldToMaturity',
      label: 'Yield to Maturity',
      cellRenderer: percentageCellRenderer,
      align: 'right',
      // tooltip: ''
    },
    {
      dataKey: 'yieldToCall',
      label: 'Yield to First',
      align: 'right',
      cellRenderer: percentageCellRenderer,
      // tooltip: ''
    },
    {
      dataKey: 'duration',
      label: 'Dur to First (yrs)',
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
      // tooltip: ''
    },
    /*{
        dataKey: 'creditRating',
        label: 'Credit Rating',
        tooltip: "",
      },*/
    {
      dataKey: 'nextCouponDate',
      label: 'Next Coupon Date',

      // tooltip: ''
    },
    {
      dataKey: 'nextCouponAmount',
      label: 'Next Coupon Amount',
      align: 'right',

      // tooltip: ''
    },
    {
      dataKey: 'freq',
      label: 'Frequency',
      // tooltip: ''
    },
    {
      dataKey: 'nextCallDate',
      label: 'First Event',
      // tooltip: ''
    },
    {
      dataKey: 'nextCallPrice',
      label: 'First Event Price',
      align: 'right',

      // tooltip: ''
    },
    {
      dataKey: 'redemption',
      label: 'Redemption',
      // tooltip: ''
    },
    {
      dataKey: 'countryOfRisk',
      label: 'Country Of Risk',
      // tooltip: ''
    },
    {
      dataKey: 'industry',
      label: 'Industry',
      // tooltip: ''
    },
  ].map((c: any) =>
    typeof c.cellRenderer === 'function'
      ? c
      : ({
          ...c,
          align: 'right',
          cellRenderer: (rowData: any, dataKey: any) => (
            <div
              style={{
                textAlign: 'right',
                color: rowData.exposure < 0 ? 'var(--light-blue)' : undefined,
              }}
            >
              {rowData[dataKey]?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </div>
          ),
        } as ColumnProps)
  );

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: 516, display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
        <div style={{ width: 140 }}>
          <h3>Filter by</h3>
        </div>
        <div style={{ width: 100, marginRight: '1rem' }}>
          <Autocomplete
            options={currencies}
            value={selectedCurrency}
            onSelect={setSelectedCurrency}
            placeholder={'Currency'}
            inputProps={{
              className: s.custodianFilter,
              placeholder: 'Filter by currency',
            }}
          />
        </div>
      </div>

      <Table columns={columns} data={filteredReport} style={{ width: '100%' }} headerHeight={40} rowHeight={30} />
    </div>
  );
};

export default BondFundamentalsReport;
