import React, { FC } from 'react';
import TradeTypeSelector from '../../../../components/Transaction/components/TradeTypeSelector';
import { change, formValueSelector } from 'redux-form';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { IRootState } from '../../../../../../../../services/store';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { connect } from 'react-redux';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import CashAdjustmentEntry from '../../../../components/Transaction/Forms/CashAdjustment/CashAdjustmentEntry';
import AssetEntryContainer from '../../../../components/Transaction/Forms/AssetEntryContainer';
import OptionEntry from '../../../../components/Transaction/Forms/Option/OptionEntry';
import EquityFutureEntry from '../../../../components/Transaction/Forms/EquityFuture/EquityFutureEntry';
import SubscriptionEntry from '../../../../components/Transaction/Forms/Subscriptions/SubscriptionEntry';
import TransferEntry from '../../../../components/Transaction/Forms/Transfer/TransferEntry';
import FXEntry from '../../../../components/Transaction/Forms/FX/FXEntry';
import { createSimpleInstrumentContainer } from '../../../../components/Transaction/Forms/SimpleInstrument/SimpleInstrumentEditContainer';
import FixedIncomeEditContainer from '../../../../components/Transaction/Forms/FixedIncome/FixedIncomeEditContainer';
import moment from 'moment-business-days';
import dateFormat from 'dateformat';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import CapabilitiesHelper from '../../../../../../../../services/utils/CapabilitiesHelper';
import NoData from '../../../../../Dashboard/scenes/AssetsDashboard/components/NoData/NoData';
import WarrantEntry from '../../../../components/Transaction/Forms/Warrant/WarrantEntry';
import { UnconnectedAssetEntryContainer } from '../../../../components/Transaction/Forms/UnconnectedAssetEntryContainer';
import { CryptoSwapEntry } from '../../../../components/Transaction/Forms/CryptoSwap/CryptoSwapEntry';

interface IMapStateToProps {
  tradeType: IOption<TradeType>;
  capabilities: IRootState['userCapabilities']['data']['capabilities'];
}

interface IDispatchToProps {
  updateTradeType(data: IOption<TradeType>): void;
}

// interface IFormValues {
//   tradeType: IOption<TradeType>;
// }

type IProps = IMapStateToProps & IDispatchToProps;

const AddTransactionContainer: FC<IProps> = ({ updateTradeType, capabilities, tradeType }) => {
  const [_tradeType, _setTradeType] = React.useState<IOption<TradeType> | null>(EQUITIES_OPTION);

  React.useEffect(() => {
    if (tradeType?.value) {
      _setTradeType(tradeType);
    }

    return () => {
      _setTradeType(EQUITIES_OPTION);
    };
  }, [tradeType?.value]);

  const { actions } = useCapabilities();
  if (!actions?.includes(CapabilitiesHelper.Actions.ADD_TRADE)) {
    return <NoData message={' '} />;
  }

  const EquityEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.equity,
    'equity',
    _tradeType?.id.toString() || 'Equities'
  );
  const WarrantEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.warrant,
    'warrant',
    _tradeType?.id.toString() || 'Warrant'
  );
  const FundEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.funds,
    'fund',
    _tradeType?.id.toString() || 'Funds'
  );
  const CryptoEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.crypto,
    'crypto',
    _tradeType?.id.toString() || 'CryptoCurrencies'
  );
  const RealAssetEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.realAssets,
    'realAssets',
    _tradeType?.id.toString() || 'RealAssets'
  );
  const CommodityEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.commodity,
    'commodity',
    _tradeType?.id.toString() || 'Commodities',
    'Quantity (Oz)'
  );

  const renderForm = () => {
    // Decide which form to render, depending on the first form field (tradeType is one of a few different AssetClasses or SubAssetClasses)
    switch (_tradeType?.value) {
      case 'RealAssets':
        return <AssetEntryContainer formName={FORMS_NAME.realAssets} ChildComponent={RealAssetEditContainer} />;
      case 'Equities':
        return (
          <AssetEntryContainer formName={FORMS_NAME.equity} ChildComponent={EquityEditContainer} editMode={false} />
        );
      case 'Commodities':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.commodity}
            ChildComponent={CommodityEditContainer}
            editMode={false}
          />
        );
      case 'Funds':
        return <AssetEntryContainer formName={FORMS_NAME.funds} ChildComponent={FundEditContainer} editMode={false} />;
      case 'FixedIncome':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.fixedIncome}
            ChildComponent={FixedIncomeEditContainer}
            editMode={false}
            defaultValues={{ settlementDate: dateFormat(moment(new Date()).businessAdd(1).toDate(), 'yyyy-mm-dd') }}
          />
        );
      case 'CryptoCurrencies':
        return (
          <AssetEntryContainer formName={FORMS_NAME.crypto} ChildComponent={CryptoEditContainer} editMode={false} />
        );
      case 'EqOptions':
        return <AssetEntryContainer formName={FORMS_NAME.transactionOption} ChildComponent={OptionEntry} />;
      case 'EqWarrants':
        return <AssetEntryContainer formName={FORMS_NAME.warrant} ChildComponent={WarrantEntry} />;
      case 'CshAdjustments':
        return <AssetEntryContainer formName={FORMS_NAME.transactionFee} ChildComponent={CashAdjustmentEntry} />;
      case 'EqFutures':
        return <AssetEntryContainer formName={FORMS_NAME.equityFutures} ChildComponent={EquityFutureEntry} />;
      case 'CashAndEquivalents':
        return <AssetEntryContainer formName={FORMS_NAME.fx} ChildComponent={FXEntry} />;
      case 'Adjustments':
        return <AssetEntryContainer formName={FORMS_NAME.transactionFee} ChildComponent={CashAdjustmentEntry} />;
      case 'Transfer':
        return <AssetEntryContainer formName={FORMS_NAME.transfer} ChildComponent={TransferEntry} />;
      case 'SubscriptionWithdrawal':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.subscriptionWithdrawal}
            ChildComponent={SubscriptionEntry}
            defaultValues={{ operation: 'ADD', fxRate: 1 }}
          />
        );

      case 'CryptoSwap':
      default:
        return <UnconnectedAssetEntryContainer ChildComponent={CryptoSwapEntry} tradeType={tradeType?.value} />;
    }
  };

  const onTradeTypeChanged = (type: IOption<TradeType> | null) => {
    if (type?.value) {
      if (type.value !== tradeType?.value) {
        updateTradeType(type as IOption<TradeType>);
        _setTradeType(type);
      }
    }
  };

  return (
    <div style={{ width: '100%', marginTop: '1rem' }}>
      <div style={{ marginBottom: -53 }}>
        <TradeTypeSelector tradeType={_tradeType} onSelectOption={onTradeTypeChanged} capabilities={capabilities} />
      </div>
      {renderForm()}
    </div>
  );
};

const EQUITIES_OPTION: IOption<TradeType> = {
  value: 'Equities',
  id: 'Equities',
  name: 'Equities',
};

const mapStateToProps = (state: IRootState) => {
  const selector = formValueSelector(FORMS_NAME.transaction);
  const tradeType = selector(state, 'tradeType');
  return {
    tradeType,
    capabilities: state.userCapabilities.data?.capabilities || [],
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateTradeType: (data: IOption<TradeType>) => dispatch(change(FORMS_NAME.transaction, 'tradeType', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionContainer);
