import { combineReducers } from 'redux';
import { ReduxHelper } from '../utils/ReduxHelper';
import { IBarsChartDataState } from '../../models/redux/IBarsChartDataState';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { ScenariosActions } from '../actions';
import { IAllocationDict } from '../../models/IAllocationDict';
import { IScenarioConfigState } from '../../models/redux/IScenarioConfigState';

export interface IScenarioModel {
  name: string;
  risk: number;
}

type IModelsState = ILoadingDataState<IScenarioModel[]>;

export interface IScenarioState {
  modelsList: IModelsState;
  configurations: IScenarioConfigState;
  profit: IBarsChartDataState;
  allocations: ILoadingDataState<IAllocationDict>;
}

const INITIAL_SCENARIO_CONFIG: IScenarioConfigState = {
  basic: {
    credit: 0,
    interest: 0,
    price: 0,
    volatility: 0,
    fx: 0,
  },
  historyEvent: {
    selected: null,
    period: 0,
  },
};

const scenarioConfig = (
  state: IScenarioConfigState = INITIAL_SCENARIO_CONFIG,
  action: IActionPayload
): IScenarioConfigState => {
  switch (action.type) {
    case ScenariosActions.SCENARIO_APPLY_SCENARIO_CONFIG:
      return {
        ...state,
        basic: action.payload,
        historyEvent: INITIAL_SCENARIO_CONFIG.historyEvent,
      };
    case ScenariosActions.SCENARIO_SELECT_HISTORY_EVENT:
      const newEvent = action.payload !== state.historyEvent.selected ? action.payload : null;

      return {
        ...state,
        basic: INITIAL_SCENARIO_CONFIG.basic,
        historyEvent: { ...state.historyEvent, selected: newEvent },
      };
    case ScenariosActions.SCENARIO_SELECT_HISTORY_PERIOD:
      return {
        ...state,
        basic: state.historyEvent.selected ? INITIAL_SCENARIO_CONFIG.basic : state.basic,
        historyEvent: { ...state.historyEvent, period: action.payload },
      };
    default:
      return state;
  }
};

export default combineReducers<IScenarioState>({
  configurations: scenarioConfig,
  modelsList: ReduxHelper.createHttpRequestReducer<IModelsState>(
    ReduxHelper.createInitialState([]),
    ScenariosActions.SCENARIO_MODELS_REQUEST,
    ScenariosActions.SCENARIO_MODELS_SUCCESS,
    ScenariosActions.SCENARIO_MODELS_ERROR,
    ScenariosActions.SCENARIO_MODELS_RESET
  ),
  allocations: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IAllocationDict>>(
    ReduxHelper.createInitialState({}),
    ScenariosActions.SCENARIO_ALLOCATIONS_REQUEST,
    ScenariosActions.SCENARIO_ALLOCATIONS_SUCCESS,
    ScenariosActions.SCENARIO_ALLOCATIONS_ERROR,
    ScenariosActions.SCENARIO_ALLOCATIONS_RESET
  ),
  profit: ReduxHelper.createHttpRequestReducer<IBarsChartDataState>(
    ReduxHelper.createInitialState([]),
    ScenariosActions.SCENARIO_PROFIT_REQUEST,
    ScenariosActions.SCENARIO_PROFIT_SUCCESS,
    ScenariosActions.SCENARIO_PROFIT_ERROR,
    ScenariosActions.SCENARIO_PROFIT_RESET
  ),
});
