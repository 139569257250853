import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  currencyValue?: string;
}

const Commission: FC<IProps> = ({ currencyValue }) => {
  return (
    <FormFieldWrapper label={`Commission${currencyValue ? `, ${currencyValue}` : ''}`} className={s.commissionField}>
      <Field
        placeholder="0"
        name="commission"
        theme="inverse"
        className="input--small"
        component={renderInput}
        normalize={(value: number) => positiveNumber(floatNumberV2(value))}
      />
    </FormFieldWrapper>
  );
};

export default Commission;
