import { AxiosPromise } from 'axios';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps, withRouter } from 'react-router';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../../../components/HOC/withPortfolioInfo';
import { Loader } from '../../../../../../components/UIWidgets/Loader';

import { IPortfolioTrade } from '../../../../../../models/IPortfolioTrade';
import { IPaginationContent } from '../../../../../../models/redux/ILoadingPaginationDataState';
import { PATHS } from '../../../../../../router/paths';
import { SnapshotActions } from '../../../../../../services/actions';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { IRootState } from '../../../../../../services/store';
import Analytics from '../../../../../../services/utils/Analytics';
import { AsyncActionDispatch } from '../../../../../../services/utils/ReduxHelper';
import SnapshotInfo, { DataType } from './SnapshotInfo';

interface IMapStateToProps {
  portfolioSnapshotsSort: IOrder;
  portfolioTradesSort: IOrder;
  assetsClasses: IAssetClass[];
  portfolioSnapshots: IPortfolioSnapshot[];
  portfolioTrades: IPortfolioTrade[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  totalTrades: number;
  snapshotFilters: IRootState['snapshot']['snapshotFilters'];
  snapshotsLoading?: boolean;
  userCapabilities: IRootState['userCapabilities']['data'];
}

interface IDispatchToProps {
  changeTradesSort: (order: IOrder) => void;
  changeSnapshotSort: (order: IOrder) => void;
  fetchPortfolioTrades: (
    portfolioId: string,
    sort: IOrder,
    page?: number
  ) => AxiosPromise<IPaginationContent<IPortfolioTrade>>;
  resetPortfolioTrades: () => void;
  fetchPortfolioSnapshots: (settings: {
    portfolioId: string;
    sort: IOrder;
    page?: number;
    confirmed?: boolean;
    size?: number;
    fromDate?: Date;
    toDate?: Date;
  }) => AxiosPromise<IPaginationContent<IPortfolioSnapshot>>;
  resetPortfolioSnapshot: () => void;
  amendTrade: (portfolioId: string, key: string) => AxiosPromise;
  removeTrade: (tradeId: number) => AxiosPromise;
  updateSnapshotFilter(key: string, value: string | undefined): void;
  resetSnapshotFilters(): void;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps & IWithPortfolioInfoProps;

interface IState {
  allLoaded: boolean;
}

class SnapshotInfoContainer extends PureComponent<IProps, IState> {
  readonly state: IState = {
    allLoaded: true,
  };

  componentDidMount(): void {
    Analytics.trackPageView('Snapshot Info');

    this.props.fetchPortfolioTrades(this.props.portfolioInfo.id, this.props.portfolioTradesSort);

    // this._fetchTransactions().then(() => {
    //   this.setState({ allLoaded: true });
    // });
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { portfolioInfo, portfolioTradesSort, fetchPortfolioTrades } = this.props;
    if (!portfolioInfo || !portfolioInfo.id) {
      return;
    }

    if (prevProps.portfolioTradesSort !== portfolioTradesSort) {
      fetchPortfolioTrades(portfolioInfo.id, portfolioTradesSort);
    }

    // if (prevProps.portfolioSnapshotsSort !== portfolioSnapshotsSort) {
    //   fetchPortfolioSnapshots({ portfolioId: portfolioInfo.id, sort: portfolioSnapshotsSort });
    // }
  }

  componentWillUnmount(): void {
    const { resetPortfolioSnapshot, resetPortfolioTrades } = this.props;
    resetPortfolioSnapshot();
    resetPortfolioTrades();
  }

  handleLoadMoreData = (type: DataType, page: number) => {
    const {
      portfolioInfo,
      portfolioSnapshotsSort,
      portfolioTradesSort,
      fetchPortfolioSnapshots,
      fetchPortfolioTrades,
    } = this.props;
    if (!portfolioInfo || !portfolioInfo.id) {
      return;
    }

    if (type === DataType.SNAPSHOTS) {
      fetchPortfolioSnapshots({ portfolioId: portfolioInfo.id, sort: portfolioSnapshotsSort, page });
    }

    if (type === DataType.TRADES) {
      fetchPortfolioTrades(portfolioInfo.id, portfolioTradesSort, page);
    }
  };

  handleSortData = (type: DataType, sort: IOrder) => {
    const { changeSnapshotSort, changeTradesSort } = this.props;
    if (type === DataType.SNAPSHOTS) {
      changeSnapshotSort(sort);
    }

    if (type === DataType.TRADES) {
      changeTradesSort(sort);
    }
  };

  handleAmendTrade = (key: string) => {
    const { history, portfolioInfo, amendTrade } = this.props;

    amendTrade(portfolioInfo.id, key).then((e) => {
      history.push(
        generatePath(PATHS.portfolio.snapshot.edit.path, {
          portfolioId: portfolioInfo.id,
        })
      );
    });
  };

  handleRemoveTrade = (tradeId: number) => {
    const { portfolioTradesSort, portfolioInfo, fetchPortfolioTrades, removeTrade } = this.props;
    removeTrade(tradeId).then(() => fetchPortfolioTrades(portfolioInfo.id, portfolioTradesSort));
  };

  render() {
    const { allLoaded } = this.state;
    const {
      portfolioSnapshots,
      portfolioInfo,
      portfolioSnapshotsSort,
      totalTrades,
      portfolioTrades,
      portfolioTradesSort,
      portfolioCurrencyFormatter,
      assetsClasses,
    } = this.props;

    if (!allLoaded) {
      return <Loader />;
    }

    if (!portfolioInfo) {
      return <Fragment />;
    }

    return (
      <SnapshotInfo
        portfolioInfo={portfolioInfo}
        trades={portfolioTrades}
        totalTrades={totalTrades}
        tradesOrder={portfolioTradesSort}
        snapshots={portfolioSnapshots}
        snapshotsOrder={portfolioSnapshotsSort}
        onLoadData={this.handleLoadMoreData}
        onSortData={this.handleSortData}
        onAmendTrade={this.handleAmendTrade}
        onRemoveTrade={this.handleRemoveTrade}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        assetsClasses={assetsClasses}
        fetchData={this._fetchData}
        snapshotFilters={this.props.snapshotFilters}
        updateSnapshotFilter={this.props.updateSnapshotFilter}
        resetSnapshotFilters={this.props.resetSnapshotFilters}
        fetchPortfolioSnapshots={this.props.fetchPortfolioSnapshots}
        snapshotsLoading={this.props.snapshotsLoading}
        userCapabilities={this.props.userCapabilities}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }

  private _fetchData = () => {
    const {
      portfolioInfo,
      portfolioTradesSort,
      fetchPortfolioTrades,
      portfolioSnapshotsSort,
      fetchPortfolioSnapshots,
      snapshotFilters,
    } = this.props;

    return Promise.all([
      fetchPortfolioSnapshots({
        portfolioId: portfolioInfo.id,
        sort: portfolioSnapshotsSort,
        fromDate: snapshotFilters.positionsFrom ? new Date(snapshotFilters.positionsFrom) : undefined,
        toDate: snapshotFilters.positionsTo ? new Date(snapshotFilters.positionsTo) : undefined,
      }),
      fetchPortfolioTrades(portfolioInfo.id, portfolioTradesSort),
    ]);
  };
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    portfolioTrades: state.snapshot.trades.content,
    portfolioTradesSort: state.snapshot.trades.order,
    totalTrades: state.snapshot.trades.totalElements,
    portfolioSnapshots: state.snapshot.snapshots.content,
    snapshotsLoading: state.snapshot.snapshots.isFetching,
    portfolioSnapshotsSort: state.snapshot.snapshots.order,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
    assetsClasses: state.assetsClasses.data,
    snapshotFilters: state.snapshot.snapshotFilters,
    userCapabilities: state.userCapabilities.data,
    // portfolioSnapshotTablePreferences: state.portfolio.portfolioSnapshotTablePreferences,
    // portfolioTradesTablePreferences: state.portfolio.portfolioTradesTablePreferences,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPortfolioTrades: (portfolioId: string, sort: IOrder, page?: number) =>
    dispatch(SnapshotActions.fetchPortfolioTrades(portfolioId, sort, page)),
  changeTradesSort: (order: IOrder) => dispatch(SnapshotActions.changeTradesSort(order)),
  resetPortfolioTrades: () => dispatch(SnapshotActions.resetPortfolioTrades()),
  fetchPortfolioSnapshots: ({
    portfolioId,
    sort,
    page,
    confirmed = true,
    size = 1000,
    fromDate,
    toDate,
  }: {
    portfolioId: string;
    sort: IOrder;
    page?: number;
    confirmed?: boolean;
    size?: number;
    fromDate?: Date;
    toDate?: Date;
  }) => dispatch(SnapshotActions.fetchPortfolioSnapshots(portfolioId, sort, page, confirmed, size, fromDate, toDate)),
  changeSnapshotSort: (order: IOrder) => dispatch(SnapshotActions.changeSnapshotSort(order)),
  resetPortfolioSnapshot: () => dispatch(SnapshotActions.resetPortfolioSnapshot()),
  amendTrade: (portfolioId: string, key: string) => dispatch(SnapshotActions.amendTrade(portfolioId, key)),
  removeTrade: (tradeId: number) => dispatch(SnapshotActions.removeTrade(tradeId)),
  updateSnapshotFilter: (key: string, value: string | undefined) => {
    dispatch(SnapshotActions.updateSnapshotFilter(key, value));
  },
  resetSnapshotFilters: () => {
    dispatch(SnapshotActions.resetSnapshotFilters(true));
  },
});

export default withPortfolioInfo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SnapshotInfoContainer)));
