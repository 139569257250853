import ReactEcharts from 'echarts-for-react';
import React, { createRef, Fragment, useEffect, useState } from 'react';
import Select from '../../../../../../components/UIWidgets/Select/Select';

import { PortfolioHeader } from '../../../../components';
import s from './Dashboard.module.scss';
import FeesAndCharges from './scenes/FeesAndCharges/FeesAndCharges';
import { Income } from './scenes/Income';
import { Structure } from './scenes/Structure';
import { Pnl } from './scenes/Pnl';
import { DashboardHeader } from './components/DashboardHeader';
import { usePortfolio } from '../../../../../../services/hooks/usePortfolio';
import { Loader } from '../../../../../../components';
import { RouteComponentProps, withRouter } from 'react-router';
import { generatePath } from 'react-router-dom';
import { PATHS } from '../../../../../../router/paths';
import { useDispatch } from 'react-redux';
import { SnapshotActions } from '../../../../../../services/actions';
import { useCustodiansFilter } from '../../../../../../services/hooks/useCustodiansFilter';
import { usePeriod } from '../../../../../../services/context/PeriodContext';

type IRouteProps = RouteComponentProps<{ page: string }>;
type IProps = IRouteProps;

const DashboardPortfolio = ({ match, history }: IProps) => {
  const { selectedCustodiansArray } = useCustodiansFilter();

  const page = match?.params?.page || 'summary';
  const { portfolioInfo, isLoading, portfolioCurrencyFormatter } = usePortfolio();

  const [sectionId, setSectionId] = useState(page || 'summary');
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'All', label: 'All' });
  const eChartsRef = createRef<ReactEcharts>();
  const { period } = usePeriod();
  const dispatch = useDispatch();

  useEffect(() => {
    setSectionId(page);
  }, [page]);

  if (isLoading || !portfolioInfo.data?.id) {
    return <Loader />;
  }

  const instrumentCurrencies = portfolioInfo.data?.instrumentCurrencies ? portfolioInfo.data?.instrumentCurrencies : [];
  const portfolioId = portfolioInfo.data?.id;
  const baseCurrency = portfolioInfo.data?.currency;

  const updateSnapshotFilter = (from: string, to: string) => {
    dispatch(SnapshotActions.resetSnapshotFilters());
    dispatch(SnapshotActions.updateSnapshotFilter('position', ''));
    dispatch(SnapshotActions.updateSnapshotDateFilter(from, to));
  };

  const viewPositionHistory = (dateString: string) => {
    const [y, m, d] = dateString.split('-');
    if ([y, m, d].some((v) => typeof v === 'undefined')) {
      return;
    }
    updateSnapshotFilter(
      new Date(parseInt(y, 10), parseInt(m, 10) - 1, 1).toString(),
      new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10)).toString()
    );
    history.push(generatePath(PATHS.portfolio.snapshot.path, { portfolioId: portfolioInfo.data?.id }));
  };

  const handleCurrencyFilterChange = (e: string) => {
    setSelectedCurrency({ value: e, label: e });
  };

  const renderCurrencySelector = (): React.ReactNode => {
    const currencyOptions = [{ value: 'All', label: 'All' }];
    instrumentCurrencies.map((el: any) => currencyOptions.push({ value: el, label: el }));

    return (
      <Fragment>
        <span className={s.currencyFilterLabel}>{sectionId === 'income' && 'Instrument'} Currency:</span>
        <Select
          options={currencyOptions}
          size="small"
          className={s.currencyFilter}
          selectedValue={selectedCurrency}
          onChange={handleCurrencyFilterChange}
        />
      </Fragment>
    );
  };

  const renderSections = (tabId: string | null) => {
    switch (tabId) {
      case 'pnl':
        return (
          <Pnl
            period={period}
            portfolioId={portfolioId!}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            viewPositionHistory={viewPositionHistory}
          />
        );
      case 'structure':
        return (
          <Structure
            portfolioId={portfolioId!}
            historyPeriod={period}
            selectedCurrency={selectedCurrency}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCustodians={selectedCustodiansArray}
          />
        );
      case 'income':
        return (
          <Income
            incomePeriod={period}
            portfolioId={portfolioId!}
            selectedCurrency={selectedCurrency}
            selectedCustodians={selectedCustodiansArray}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            renderCurrencySelector={renderCurrencySelector}
            eChartsRef={eChartsRef}
          />
        );
      case 'fees':
        return (
          <FeesAndCharges
            baseCurrency={baseCurrency!}
            selectedCustodians={selectedCustodiansArray}
            historyPeriod={period}
            selectedCurrency={selectedCurrency.value}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            renderCurrencySelector={renderCurrencySelector}
            eChartsRef={eChartsRef}
            portfolioId={portfolioId!}
          />
        );
    }
  };

  return (
    <div>
      <PortfolioHeader title="All Assets" selectedAssetClass={'All'} />
      <DashboardHeader page={page} />
      <div style={{ flexDirection: 'row' }}>
        <div className={s.assetsDashboardGrid}>{renderSections(sectionId)}</div>
      </div>
    </div>
  );
};

export default withRouter(DashboardPortfolio);
