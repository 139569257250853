import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { Dispatch } from 'redux';

class AlternativesActions {
  static readonly DISTRIBUTION_REQUEST = 'DISTRIBUTION_REQUEST';
  static readonly DISTRIBUTION_SUCCESS = 'DISTRIBUTION_SUCCESS';
  static readonly DISTRIBUTION_ERROR = 'DISTRIBUTION_ERROR';
  static readonly DISTRIBUTION_RESET = 'DISTRIBUTION_RESET';

  static fetchDistributions(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IBarData[]>(
      {
        url: getApiUrl('alternatives.dividends'),
        data: {
          portfolioId,
        },
      },
      AlternativesActions.DISTRIBUTION_REQUEST,
      AlternativesActions.DISTRIBUTION_SUCCESS,
      AlternativesActions.DISTRIBUTION_RESET
    );
  }

  static resetDistributions(): IActionPayload {
    return {
      type: AlternativesActions.DISTRIBUTION_RESET,
    };
  }

  static resetAlternativesData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(AlternativesActions.resetDistributions());
    };
  }
}

export default AlternativesActions;
