import { ITableColumn } from '../../../../../../../../components/UIWidgets/TableWrapper/models';

export const INCOME_COLUMNS: ITableColumn[] = [
  {
    label: 'Date',
    dataKey: 'businessDate',
    flexGrow: 1,
    width: 150,
    display: true,
    disableSort: false,
  },
  {
    label: 'Income',
    dataKey: 'amount',
    flexGrow: 3,
    width: 250,
    display: true,
    disableSort: false,
  },
  {
    label: 'Income currency',
    dataKey: 'currency.name',
    flexGrow: 3,
    width: 250,
    display: true,
    disableSort: false,
  },
  {
    label: '',
    dataKey: 'priceAction',
    flexGrow: 0,
    width: 18,
    display: true,
    disableSort: true,
  },
];
