import merge from 'lodash.merge';

import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../../../services/utils/FormatHelper';
import moment from 'moment';
import { DEFAULT_BAR_SERIES_OPTIONS, DEFAULT_CHART_OPTIONS } from '../../../../../../services/constants/charts';

export function getPerformanceChartOptions(
  portfolioPerformance: IPerformanceData,
  recommendedPerformance: IPerformanceData,
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  const chartOpt = ChartHelper.getTimelineChartOptions({
    tooltipValueType: VALUE_TYPE.PERCENTAGE,
    zoom: true,
    portfolioCurrencyFormatter,
  });
  const mergeOpt: any = {
    grid: {
      left: '5%',
      y: 30,
    },
    legend: { data: ['Result portfolio', 'Personal portfolio'] },
    xAxis: {
      // data: categories,
      axisLabel: {
        formatter(value: string): string {
          const momentVal = moment(value);
          return momentVal.format("MMM'YY");
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return FormatHelper.formatPercentage(value);
        },
      },
    },
    series: [
      {
        ...ChartHelper.getLineSeriesOptionsWithRadialGradient(),
        data: portfolioPerformance.data,
        name: 'Result portfolio',
      },
      {
        ...ChartHelper.getLineSeriesOptions('#c2b261'),
        data: recommendedPerformance.data,
        name: 'Personal portfolio',
      },
    ],
  };
  return merge({}, chartOpt, mergeOpt);
}
