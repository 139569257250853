import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useApi from '../../../../services/hooks/useApi';
import { PortfolioHeader, Widget } from '../../components';
import { Card, Table } from '@iliotech/storybook';
import JSONTree from 'react-json-tree';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { Message } from 'rsuite';

interface IOwnProps {
  title?: string;
}

type Insight = {
  id: string;
  level2Id: string;
  category: 'Structure' | 'Income';
  analysis: string;
  label: string;
  thresholdInsight: string;
  level1Insight: string;
  level2Insight: string;
  clientOpinionInsight: string;
  fact: string;
  marketSentiment?: any;
  segmentedRank: {
    segment: 'Exposure',
    rank: number;
  };
  link: string;
  table?: {
    columns: InsightColumn[];
    rows: any[];
  }
}

type InsightColumn = {
  label: string;
  renderer: 'PLAIN_TEXT' | 'NUMBER' | 'POSITION_LOGO_LINK';
  dataKey: string;
  width?: number;
  grow?: number;
};

export const InsightsBrowser = (props: IOwnProps) => {

  const { params } = useRouteMatch<{ portfolioId: string }>();

  const {
    data,
    isLoading,
    refetch
  } = useApi<{ [insightCategory: string]: { insights: Insight[] } }>(`/api/insights/insight/${params?.portfolioId}/list/10`, {}, {
    enabled: !!params?.portfolioId
  });


  return (
    <div>
      <PortfolioHeader/>
      <div style={{ margin: '2rem 0' }}>
      <Message title={"Note - this page is only visible to illio Admins"} description={`Insights for portfolio ${params.portfolioId}`} />

      </div>
      {
        !!data && Object.entries(data).map(([key, value]) => {
          return (
            <div key={key} style={{ marginBottom: '5rem' }}>
              <h1 style={{ margin: '3rem 0 1rem' }}>{key}</h1>
              <div>
                {value?.insights?.sort((a, b) => (a.segmentedRank?.rank - b.segmentedRank?.rank)).map(insight => {

                  const tableColumns: ColumnProps[] = insight.table?.columns?.map(
                    column => ({
                      ...column,
                      width: column.width === null ? undefined : column.width,
                      flexGrow: 1,
                      cellRenderer: getRenderer(column),
                    })) || [];
                  return (
                    <Card key={insight.id} style={{ margin: '0.5rem 0', padding: '1rem' }}>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Stat label={'Category'} value={insight.category}/>
                        <Stat label={'Analysis'} value={insight.analysis}/>
                        <Stat label={'Segment'} value={insight.segmentedRank?.segment}/>
                        <Stat label={'Segment Rank'} value={insight.segmentedRank?.rank}/>
                        <Stat label={'Link'} value={insight.link || '(No link)'}/>
                        <Stat label={'L2ID'} value={insight.level2Id || '(None)'}/>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Stat label={'Label'} value={insight.label}/>

                      </div>
                      <Stat label={'Fact'} value={insight.fact || '(No fact provided)'}/>
                      <Stat label={'Threshold Insight'} value={insight.thresholdInsight}/>
                      {!!insight.clientOpinionInsight &&
                      <Stat label={'Client Opinion Insight'} value={insight.clientOpinionInsight}/>}
                      {!!insight.level1Insight && <Stat label={'Level 1 Insight'} value={insight.level1Insight}/>}
                      {!!insight.level2Insight && <Stat label={'Level 2 Insight'} value={insight.level2Insight}/>}
                      {
                        !!insight.table && <Table rowHeight={30} columns={tableColumns} data={insight.table.rows}/>
                      }

                    </Card>
                  );
                })}
              </div>
            </div>
          );
        })
      }
      <JSONTree data={{ data, isLoading }} shouldExpandNode={((keyPath, data1, level) => level < 4)}/>
    </div>
  );
};

const getRenderer = (column: InsightColumn) => {
  switch(column.renderer){
    case "POSITION_LOGO_LINK":
    return (row: any) => {
      const image = row?.[column.dataKey];
      if(!image){
        return <div>(no image)</div>
      }
      // return <div>{`https://cdn.illio.com/${image}`}</div>
      return <div><img style={{height: 20, width: 20}} src={`https://cdn.illio.com/${image}`}/></div>
    }
  }
  return undefined;
}

const Stat = ({ label, value }: { label: string; value: any }) => {
  return (
    <div style={{ margin: '0 2rem 0.5rem 0', minWidth: 150 }}><b>{label}</b> &nbsp;{value}</div>
  );
};
