import React, { Fragment, useEffect, useState } from 'react';
import { Loader, Select } from '../../../../../../../../components/UIWidgets';
import { ASSETS } from '../../../../../../../../services/constants/assetClasses';

import s from './FixedIncome.module.scss';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import Analytics from '../../../../../../../../services/utils/Analytics';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { GlobalPnl } from '../Shared/GlobalPnl';
import { getTopPositionsColumns } from '../Cash/topPositionsColumns';
import { fixedIncomeCellRenderer } from './utils';
import { GlobalStructure } from '../Shared/GlobalStructure';
import { GlobalPnlBreakdown } from '../Shared/GlobalPnlBreakdown';
import { GlobalFundamentals } from '../Shared/GlobalFundamentals';
import { getTopPositionsByPnLColumns } from './topPositionsColumns';
import { RouteComponentProps, withRouter } from 'react-router';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import AssetReportWrapper from '../../components/AssetReportWrapper/AssetReportWrapper';
import { PortfolioHeader } from '../../../../../../components';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';
import { FixedIncomeIncome } from './scenes/FixedIncomeIncome';

type IProps = RouteComponentProps<{ page: string }>;

const FixedIncome = ({ match }: IProps) => {
  const page = match?.params?.page;
  const {
    hasData,
    isLoading,
    isFetched,
    portfolioCurrencyFormatter,
    dashboardInfo,
    portfolioInfo,
    refetchTopPositions,
  } = usePortfolio(ASSETS.fixedIncome);
  const instrumentCurrencies = portfolioInfo.data?.instrumentCurrencies ? portfolioInfo.data?.instrumentCurrencies : [];
  const portfolioId = portfolioInfo.data?.id;
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'All', label: 'All' });
  const { period, updatePeriod } = usePeriod();

  useEffect(() => {
    if (portfolioId) {
      refetchTopPositions?.();
    }
  }, [portfolioId]);

  const handlePeriodChange = (currentPeriod: IPeriod) => {
    updatePeriod(currentPeriod);
  };

  const handleCurrencyFilterChange = (e: string) => {
    setSelectedCurrency({ value: e, label: e });
  };

  const renderCurrencySelector = (): React.ReactNode => {
    // ToDO: The Income period needs to have its OWN Period redux variable as it may differ from the performance period used in Performance chart. it's a Potential BUG
    const currencyOptions = [{ value: 'All', label: 'All' }];
    instrumentCurrencies.map((el: any) => currencyOptions.push({ value: el, label: el }));

    return (
      <Fragment>
        <span className={s.currencyFilterLabel}>Currency:</span>
        <Select
          options={currencyOptions}
          size="small"
          className={s.currencyFilter}
          selectedValue={selectedCurrency}
          onChange={handleCurrencyFilterChange}
        />
      </Fragment>
    );
  };

  const renderSections = (tabId: string) => {
    switch (tabId) {
      case 'pnl':
        Analytics.trackPageView('Fixed Income - P&L');
        return (
          <GlobalPnl
            period={period}
            handlePeriodChange={handlePeriodChange}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsByPnLColumns}
            cellRenderer={fixedIncomeCellRenderer}
            assetClass={ASSETS.fixedIncome}
          />
        );
      case 'structure':
        Analytics.trackPageView('Fixed Income - Structure');
        return (
          <GlobalStructure
            cellRenderer={fixedIncomeCellRenderer}
            assetClass={ASSETS.fixedIncome}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsColumns}
          />
        );
      case 'pnlBreakdown':
        Analytics.trackPageView('Fixed Income - Breakdown');
        return (
          <GlobalPnlBreakdown
            assetClass={ASSETS.fixedIncome}
            portfolioId={portfolioId!}
            portfolioInfo={portfolioInfo}
            incomeLabel={'Coupons'}
          />
        );
      case 'fundamentals':
        Analytics.trackPageView('Fixed Income - Fundamentals');
        return (
          <GlobalFundamentals assetClass={ASSETS.fixedIncome} portfolioId={portfolioId!} portfolioInfo={portfolioInfo} />
        );
      case 'income':
        Analytics.trackPageView('Fixed Income - Income');
        return (
          <FixedIncomeIncome
            renderCurrencySelector={renderCurrencySelector}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            currency={selectedCurrency}
            portfolioId={portfolioId!}
          />
        );
    }
  };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo?.total?.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo?.totalPnlBase?.value);
  const income = portfolioCurrencyFormatter(dashboardInfo?.past?.value);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: total,
      label: 'Total Fixed Income',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoTotalEquity },
    },
    {
      value: income,
      label: 'Lifetime Income',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
  ];

  return (
    <AssetReportWrapper
      title="Fixed Income"
      allLoaded={isFetched}
      noData={hasData}
      message={"You haven't added any bonds yet"}
    >
      <PortfolioHeader title="Fixed Income" />
      <div>
        <PortfolioSubHeader stats={stats} title={page} />
        <div className={s.assetsDashboardGrid}>{renderSections(page || 'pnl')}</div>
      </div>
    </AssetReportWrapper>
  );
};

export default withRouter(FixedIncome);
