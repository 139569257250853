import React from 'react';

interface IProps {
  width?: string;
  height?: string;
  className?: string;
}

export default function (props: IProps) {
  const width = props.width || '24px';
  const height = props.height || '24px';
  const className = props.className || '';

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-423.000000, -86.000000)">
          <g transform="translate(415.000000, 75.000000)">
            <g
              transform="translate(18.000000, 23.000000) rotate(-90.000000) translate(-18.000000, -23.000000) translate(6.000000, 11.000000)"
              stroke="#a9b2d1"
              strokeLinecap="square"
              strokeWidth="4"
            >
              <polyline id="Path" points="5.5 9.5 12 14.5 18.5 9.5" />
            </g>
            <rect id="Rectangle" x="0" y="0" width="46" height="46" />
          </g>
        </g>
      </g>
    </svg>
  );
}
