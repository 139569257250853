import { AxiosPromise } from 'axios';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps, withRouter } from 'react-router';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../../../components/HOC/withPortfolioInfo';
import { IBreadcrumb } from '../../../../../../components/UIWidgets/Breadcrumbs/model';

import { IPortfolioStructure } from '../../../../../../models/IPortfolioStructure';
import { IPortfolioValidatedTrade } from '../../../../../../models/IPortfolioTrade';
import {
  ILoadingPaginationDataState,
  IPaginationContent,
} from '../../../../../../models/redux/ILoadingPaginationDataState';
import { PATHS } from '../../../../../../router/paths';
import { PortfolioActions, SnapshotActions } from '../../../../../../services/actions';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { IRootState } from '../../../../../../services/store';
import { AsyncActionDispatch } from '../../../../../../services/utils/ReduxHelper';
import SnapshotEdit from './SnapshotEdit';
import Analytics from '../../../../../../services/utils/Analytics';

interface IMapStateToProps {
  tradesOrder: IOrder;
  isEditingTrade: boolean;
  editedUnconfirmedTrade: IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];
  savedTrades: ILoadingPaginationDataState<IPortfolioValidatedTrade> & { delta?: number; totalTradeCost?: number };
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioId?: string;
}

interface IDispatchToProps {
  changeTradesSort: (order: IOrder) => void;
  // confirm: (portfolioId: string, delta: number | undefined) => AxiosPromise<void>;
  // discard: (portfolioId: string) => AxiosPromise<void>;
  fetchTradeBlotter: (
    portfolioId: string,
    order: IOrder,
    page?: number
  ) => AxiosPromise<IPaginationContent<IPortfolioValidatedTrade>>;
  fetchPortfolioStructure: (portfolioId: string) => AxiosPromise<IPortfolioStructure>;
  fetchPortfolioInfo: (portfolioId: string) => AxiosPromise<IPortfolio>;
  resetTrades: () => void;
  resetEditTrade: () => void;
}

type IProps = RouteComponentProps & IWithPortfolioInfoProps & IDispatchToProps & IMapStateToProps;

class SnapshotEditContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    const { portfolioInfo, tradesOrder, fetchTradeBlotter } = this.props;
    fetchTradeBlotter(portfolioInfo.id, tradesOrder);
    Analytics.trackPageView('Snapshot Info');
  }

  componentWillUnmount(): void {
    this.props.resetTrades();
  }

  handleErrorsSort = (value: boolean) => {
    const { changeTradesSort } = this.props;
    let sort: IOrder = {
      name: 'tradeTime',
      direction: 'DESC',
    };
    if (value) {
      sort = {
        name: 'isCorrect',
        direction: 'ASC',
      };
    }

    changeTradesSort(sort);
  };

  _getBreadCrumbs = (): IBreadcrumb[] => {
    const {
      portfolioInfo: { id: portfolioId, name },
    } = this.props;

    return [
      {
        name,
        path: generatePath(PATHS.portfolio.dashboardNew.path, { portfolioId }),
      },
      {
        name: 'Portfolio snapshot',
        path: generatePath(PATHS.portfolio.snapshot.path, { portfolioId }),
      },
    ];
  };

  render() {
    const { tradesOrder, isEditingTrade, savedTrades, portfolioCurrencyFormatter } = this.props;

    return (
      <SnapshotEdit
        trades={savedTrades.content}
        tradesOrder={tradesOrder}
        // onConfirm={this.handleConfirm}
        // onDiscard={this.handleDiscard}
        onErrorsSort={this.handleErrorsSort}
        isEditingTrade={isEditingTrade}
        editedUnconfirmedTrade={this.props.editedUnconfirmedTrade}
        breadCrumbs={this._getBreadCrumbs()}
        delta={savedTrades.delta}
        totalTradeCost={savedTrades.totalTradeCost}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        resetEditTrade={this.props.resetEditTrade}
        portfolioId={this.props.portfolioId}
      />
    );
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  tradesOrder: state.snapshot.snapshotEdit.savedTrades.order,
  isEditingTrade: !!state.snapshot.snapshotEdit.editedUnconfirmedTrade,
  editedUnconfirmedTrade: state.snapshot.snapshotEdit.editedUnconfirmedTrade,
  savedTrades: state.snapshot.snapshotEdit.savedTrades,
  portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  portfolioId: state.portfolio.portfolioInfo.data?.id,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPortfolioStructure: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioStructure(portfolioId)),
  fetchPortfolioInfo: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioInfo(portfolioId)),
  // confirm: (portfolioId: string, delta: number | undefined) =>
  //   dispatch(SnapshotActions.confirmSavedTrades(portfolioId, delta)),
  // discard: (portfolioId: string) => dispatch(SnapshotActions.discardSavedTrades(portfolioId)),
  fetchTradeBlotter: (portfolioId: string, order: IOrder, page?: number) =>
    dispatch(SnapshotActions.fetchPortfolioSavedTrades(portfolioId, order, page)),
  changeTradesSort: (order: IOrder) => dispatch(SnapshotActions.changeSavedTradesSort(order)),
  resetTrades: () => dispatch(SnapshotActions.resetSavedTrades()),
  resetEditTrade: () => dispatch(SnapshotActions.finishEditUnconfirmedTrade()),
});

export default withRouter<any, any>(
  withPortfolioInfo(connect(mapStateToProps, mapDispatchToProps)(SnapshotEditContainer))
);
