import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment from 'moment';

import { FormatHelper, VALUE_TYPE } from '../../../../../../services/utils/FormatHelper';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../../services/utils/ChartHelper';
import { BarType } from '../../../../../../services/constants/charts';

export function getPositionChartOptions(
  chartData: IBarData[],
  baseCurrencySymbol: string,
  yAxisValueType: VALUE_TYPE = VALUE_TYPE.MONEY,
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  const chartOpt = ChartHelper.getChartOptions({
    tooltipValueType: yAxisValueType,
    portfolioCurrencyFormatter,
  });
  // @ts-ignore
  const mergeOpt: echarts.EChartOption = {
    xAxis: {
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
        interval: 0,
        formatter(value: string): any {
          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const momentVal = moment(value);
            return momentVal.format("MMM'YY");
          }
          const words = value.split(' ');
          if (words.length > 2) {
            return words[0];
          }
          return value;
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return FormatHelper.formatCurrencyShort(value, baseCurrencySymbol);
          // return portfolioCurrencyFormatter(value);
        },
      },
    },
    visualMap: {
      // @ts-ignore
      type: 'piecewise',
      show: false,
      pieces: [
        {
          gt: 0,
          color: '#6677cc',
        },
        {
          lte: 0,
          color: '#3b4680',
        },
      ],
    },
    series: prepareSeries(chartData),
  };

  return merge({}, chartOpt, mergeOpt);
}

function prepareSeries(data: IBarData[]): echarts.EChartOption.SeriesBar[] {
  return data.map((item: IBarData) => {
    const barOpt =
      item.type === BarType.Hatching ? ChartHelper.getHatchingBarSeriesOptions() : ChartHelper.getBarSeriesOptions();
    return {
      ...barOpt,
      name: item.name,
      data: item.data,
      stack: 'profit',
    };
  });
}
