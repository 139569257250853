import React, { Fragment, PureComponent } from 'react';
import s from './BetaVsPortfolioBenchmarkChart.module.css';

import { XAxisCanvas } from './components/XAxisCanvas';
import { VolatilityTagCanvas } from './components/VolatilityTagCanvas';
import { BarColumn } from './components/BarColumn';
import { BarsArrows } from './components/BarsArrows';
import { IPortfolioVolatilityData, IPortfolioTopInstrument } from '../../interfaces';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';
import { RISK_CONTAINER_HELP_TEXT } from '../../../../../../services/constants/tooltipContextHelp';

interface IProps {
  data: IPortfolioVolatilityData;
  portfolioBaseCcy: string;
  chartTitle: string;
  helpText?: string;
}

class BetaVsPortfolioBenchmarkChart extends PureComponent<IProps, IPortfolioTopInstrument> {
  constructor(props: IProps) {
    super(props);
  }

  distributeSlots = (barData: IPortfolioTopInstrument[], slots: any[], isNegativeBeta: boolean) => {
    let preferredSlot = 0;
    let baseSlots = [2, 4, 7];
    if (isNegativeBeta) {
      baseSlots = [2, 6, 8];
    }

    if (barData.length > 0) {
      let remainingInstrumentsNr = barData.length;
      let offset0 = 0;
      let offset1 = 0;
      let offset2 = 0;

      for (const instrument of barData) {
        // console.log('The Instrument:' + instrument.ticker);

        if (instrument.beta < 1) {
          preferredSlot = baseSlots[0] + offset0;
          offset0++;
        }
        if (instrument.beta >= 1 && instrument.beta <= 2) {
          preferredSlot = baseSlots[1] + offset1;
          offset1++;
        }
        if (instrument.beta > 2) {
          preferredSlot = baseSlots[2] + offset2;
          offset2++;
        }

        if (slots[preferredSlot] !== 0) {
          preferredSlot = slots.indexOf(0);
        }

        const overflow = remainingInstrumentsNr + preferredSlot - slots.length;
        if (overflow > 0) {
          preferredSlot = preferredSlot - overflow;
          slots[preferredSlot] = instrument;
          remainingInstrumentsNr--;
        } else {
          slots[preferredSlot] = instrument;
          remainingInstrumentsNr--;
        }
        // console.log('Preferred Slot: ' + preferredSlot);
      }
    }
    return slots;
  };

  render() {
    const barColors = [
      '#6397ff',
      '#0077b2',
      '#0077b2',
      '#fb9900',
      '#fb9900',
      '#F78D00',
      '#f27800',
      '#F48100',
      '#e13800',
      '#a50009',
    ];

    // const barColors = [
    //   '#a50009',
    //   '#e13800',
    //   '#F48100',
    //   '#fb9900',
    //   '#0077b2',
    //   '#0077b2',
    //   '#fb9900',
    //   '#F48100',
    //   '#e13800',
    //   '#a50009',
    //
    // ];

    let slots = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const barData = this.props.data.portfolio.topInstruments;
    const portfolioBeta = this.props.data.portfolio.beta;
    const baseCurrencySymbol = this.props.data.portfolio.baseCurrencySymbol;
    let minAxisVal = 0;
    const maxAxisVal = 3;

    // Sort bars by Beta values
    if (barData) {
      barData.sort((a, b) => {
        return a.beta - b.beta;
      });
    }

    const negativeInstrumentBetas = barData.filter((instr) => instr.beta < 0);
    let negativeBetaFlag = false;

    if (negativeInstrumentBetas.length > 0 || portfolioBeta < 0) {
      negativeBetaFlag = true;
      minAxisVal = -3;
    }

    // Occupying slots
    slots = this.distributeSlots(barData, slots, negativeBetaFlag);

    // Array containing all the Bar Objects with empty bar slots for no bars to show
    const slotsFilledWithBars: any[] = [];
    slots.forEach((el) => {
      if (el === 0) {
        slotsFilledWithBars.push({ emptySlot: true });
      } else {
        slotsFilledWithBars.push(el);
      }
    });

    const chartTitle = this.props.chartTitle;
    const contextHelpText = this.props.helpText;

    return (
      <Fragment>
        <h3>
          {chartTitle}
          <ContextHelpIcon title={chartTitle} elementId={chartTitle} />
        </h3>
        <div className={s.ChartContainer}>
          <VolatilityTagCanvas portfolioBeta={portfolioBeta} isNegativeBetas={negativeBetaFlag} barColors={barColors} />
          <XAxisCanvas minValue={minAxisVal} maxValue={maxAxisVal} />
          <div className={s.barArrowsContainer}>
            <BarsArrows barsData={slotsFilledWithBars} barColors={barColors} isNegativeBetas={negativeBetaFlag} />
          </div>
          <div className={s.BarsCanvas}>
            {slotsFilledWithBars.map((instrument, index) => {
              const tooltipPositionCorrection = index === slotsFilledWithBars.length - 1;
              // return null;
              if (instrument.emptySlot) {
                return <div className={s.BarColumn} key={index} />;
              } else {
                return (
                  <BarColumn
                    key={index}
                    slotPosition={index}
                    name={instrument.name}
                    ticker={instrument.ticker}
                    baseCurrencySymbol={baseCurrencySymbol}
                    totalValue={instrument.totalMarketValueBase}
                    beta={instrument.beta}
                    custodians={instrument.positions}
                    barColors={barColors}
                    tooltipDisplaced={tooltipPositionCorrection}
                  />
                );
              }
            })}
          </div>
        </div>
        <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={contextHelpText} />
      </Fragment>
    );
  }
}

export default BetaVsPortfolioBenchmarkChart;
