import React, { useEffect, useState } from 'react';
import s from '../shared.module.scss';
import { Card } from '@iliotech/storybook';
import { OptionsExposureWidgetContainer } from '../../../../../../../components/StructureWidget';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import { TopListWidgetsContainer, Widget } from '../../../../../../../components';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import { ASSET_CLASSES_CONFIG, IAssetClassOptions } from '../../../../../../../../../services/constants/assets';
import {
  ALLOCATION_TYPES,
  EQUITY_EXPOSURE_TYPES,
  GEOGRAPHY_ALLOCATION_TYPES,
} from '../../../../../../../../../services/constants/constants';
import { ASSET_LABEL_NAMES } from '../../../../../../../../../services/constants/assetClasses';
import useApi from '../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../services/constants/endpoints';
import { Loader } from '../../../../../../../../../components';
import { ITableColumn } from '../../../../../../../../../components/UIWidgets/TableWrapper/models';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';

interface IProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioId: string;
  getTopPositionColumns: (f: ICurrencyFormatter) => ColumnProps[];
  cellRenderer: (data: any, f: ICurrencyFormatter) => React.ReactNode;
  assetClass: string;
}

const GlobalStructure = ({
  assetClass,
  cellRenderer,
  portfolioCurrencyFormatter,
  portfolioId,
  getTopPositionColumns,
}: IProps) => {
  const [allocateBy, setAllocateBy] = useState(EQUITY_EXPOSURE_TYPES.ExposureBase);
  const [allocateGeographyBy, setAllocateGeographyBy] = useState<GEOGRAPHY_ALLOCATION_TYPES>(
    GEOGRAPHY_ALLOCATION_TYPES.Region
  );
  const [order, setOrder] = useState<IOrder>({ direction: 'DESC', name: 'marketValueBase' });
  const AssetInfo: IAssetClassOptions = ASSET_CLASSES_CONFIG[assetClass];
  const type: ALLOCATION_TYPES = ALLOCATION_TYPES.AssetClass;

  const allocations = useApi(getApiUrl('portfolio.allocations'), {
    method: 'POST',
    data: {
      portfolioId,
      asset: { id: assetClass, type },
      withChildren: false,
      allocations: AssetInfo.allocations,
      allocateBy,
    },
  });

  const topList = useApi(getApiUrl('topPositions'), {
    method: 'POST',
    data: {
      number: 10,
      portfolioId,
      order: {
        name: order?.name,
        direction: order!.direction,
      },
      filter: {
        id: assetClass,
        type,
      },
      custodians: [],
    },
  });

  useEffect(() => {
    topList.refetch();
  }, [order]);

  const onChangeAllocateBy = (comboValue: any): void => {
    setAllocateBy(comboValue);

    if (AssetInfo.allocations.indexOf(ALLOCATION_TYPES.Region) > -1) {
      // @ts-ignore
      AssetInfo.allocations[AssetInfo.allocations.indexOf(ALLOCATION_TYPES.Region)] = allocateGeographyBy;
    }

    allocations.refetch();
  };

  const onChangeAllocateGeographyBy = (comboValue: any): void => {
    setAllocateGeographyBy(comboValue);

    if (AssetInfo.allocations.indexOf(ALLOCATION_TYPES.Region) > -1) {
      AssetInfo.allocations[AssetInfo.allocations.indexOf(ALLOCATION_TYPES.Region)] = comboValue;
    }

    if (AssetInfo.allocations.indexOf(ALLOCATION_TYPES.RegionOfIssue) > -1) {
      AssetInfo.allocations[AssetInfo.allocations.indexOf(ALLOCATION_TYPES.RegionOfIssue)] = comboValue;
    }

    if (AssetInfo.allocations.indexOf(ALLOCATION_TYPES.MSCIRegion) > -1) {
      AssetInfo.allocations[AssetInfo.allocations.indexOf(ALLOCATION_TYPES.MSCIRegion)] = comboValue;
    }

    if (AssetInfo.allocations.indexOf(ALLOCATION_TYPES.MSCIClassification) > -1) {
      AssetInfo.allocations[AssetInfo.allocations.indexOf(ALLOCATION_TYPES.MSCIClassification)] = comboValue;
    }

    allocations.refetch();
  };

  if (allocations.isLoading || topList.isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ marginBottom: 80 }}>
      <div className={s.equityContainer}>
        <Card style={{ flex: 1 }}>
          <OptionsExposureWidgetContainer
            title={`${ASSET_LABEL_NAMES[assetClass]} Breakdown`}
            titleInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartEquityBreakdown}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            allocations={allocations.data.allocations}
            containsShorts={allocations.data.containsShorts}
            onChangeAllocateBy={onChangeAllocateBy}
            currentAllocateByValue={allocateBy}
            onChangeAllocateGeographyBy={onChangeAllocateGeographyBy}
            currentAllocateGeographyByValue={allocateGeographyBy!}
          />
        </Card>
      </div>
      <div style={{ height: 30 }} />
      <div style={{ maxHeight: 246 }}>
        <Widget title="Top 10 positions" titleInfo={DASHBOARD_EQUITY_HELP_TEXT.TableTop10ByStructure}>
          <TopListWidgetsContainer
            topList={topList.data}
            topListOrder={order}
            changeOrder={setOrder}
            showCategories={true}
            columns={getTopPositionColumns(portfolioCurrencyFormatter)}
            cellRenderer={(data) => cellRenderer(data, portfolioCurrencyFormatter)}
            columnClass={s.tableColumn}
            headerClass={s.headerContainer}
          />
        </Widget>
      </div>
    </div>
  );
};

export default GlobalStructure;
