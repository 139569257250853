import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderToggleBtns } from '../index';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { TradeType } from '../../../../../../../../models/IPortfolioTrade';

interface IProps {
  operationTypes: OperationTypes;
}

type OperationTypes = 'BUY_SELL' | 'ADD_WITHDRAW';

const Operation: FC<IProps> = ({ operationTypes }) => {
  return (
    <FormFieldWrapper
      label="Operation"
      className={cn(s.operationField, s.fieldsWrapper)}
      // hidden={!FIELDS.operation.includes(this.props.tradeType.id)}
    >
      <Field
        name="operation"
        values={TYPE_BUTTONS[operationTypes]}
        className="input--small"
        component={renderToggleBtns}
        disabled={false}
      />
    </FormFieldWrapper>
  );
};

const TYPE_BUTTONS: Record<OperationTypes, { text: string; value: string }[]> = {
  BUY_SELL: [
    {
      text: TradeType.BUY,
      value: TradeType.BUY.toUpperCase(),
    },
    {
      text: TradeType.SELL,
      value: TradeType.SELL.toUpperCase(),
    },
  ],
  ADD_WITHDRAW: [
    {
      text: TradeType.ADD,
      value: TradeType.ADD.toUpperCase(),
    },
    {
      text: TradeType.WITHDRAW,
      value: TradeType.WITHDRAW.toUpperCase(),
    },
  ],
};

export default Operation;
