export enum PORTFOLIOS_SUMMARY_HELP_TEXT {
  InfoPerformance = 'Time weighted return on your portfolio since inception',
  InfoPnL = 'Gain or loss on the net asset value (NAV) of the portfolio since inception',
}

export enum PORTFOLIO_CREATE_HELP_TEXT {
  lblBaseCurrency = 'Enter the default currency of your portfolio, in which all your assets will be valued, profits computed, and gains reported',
  lblPerformanceVsIndex = 'Select a benchmark index for comparing relative performance and to calculate relevant risk analytics',
  lblCustodian = 'Enter or Select the bank/s or institution/s which executes transactions and holds instruments for the investor, and/or enter different strategies for which you wish to aggregate Performance, P&L, Structure and Income by',
  lblDividends = 'illio can automatically generate dividends based on market information or dividends can be imported from broker files and manually entered. <br /> This is selected on a per custodian basis and cannot  be modified once chosen. <br /> If you are importing files from a supported broker you should select Manual / Imported',
  lblTrades = 'Whether trades are imported automatically from a connected broker or user entered',
}

export enum DASHBOARD_PORTFOLIO_HELP_TEXT {
  InfoInceptionPnL = 'Total gain or loss on the net asset value (NAV) of the portfolio or selected Custodian/Strategy since inception',
  InfoTotalWealth = 'The current value of all assets in the portfolio or selected Custodian/Strategy',
  InfoPortfolioVolatility = 'Measure of the historic variation of returns for the portfolio or selected Custodian/Strategy since inception',
  InfoPortfolioStructure = 'Percentage of portfolio or selected Custodian/Strategy represented by each asset class',
  InfoCustodiansFilter = 'Filter between individual and/or a combination of portfolio custodians, this will affect all graphs and tables on the page. If the user created different strategies, they will appear in the same list',
  InfoCustodianToggle = 'Filter between individual and/or a combination of portfolio custodians, this will affect all graphs and tables on the page <ul> if the user created different strategies, they will appear in the same list',
  ChartPerformance = 'The daily time weighted return of the portfolio or selected Custodian/Strategy  compared to the return of the selected benchmark index over the designated time period',
  InfoPerformance = 'The monthly time weighted return of the portfolio or selected Custodian/Strategy over the designated time period',
  ChartCumulativeDailyPnl = 'Cumulative Gain or Loss on net asset value (NAV) of your portfolio or selected Custodian/Strategy on a daily basis over the designated time period',
  ChartPnL = 'The monthly time weighted return of the portfolio or selected Custodian/Strategy over the designated time period',
  ChartMonthlyPnl = 'Gain or Loss on net asset value (NAV) of your portfolio or selected Custodian/Strategy on a month to month basis over the designated time period',
  WidgetUpcomingEvents = "Upcoming dividends and earnings calls in respect of assets held in your portfolio or selected Custodian/Strategy.<br/> In addition, information on announced IPO's in industries that feature in your portfolio’s sector exposure are also shown.",
  InfoPnl = 'Cumulative Gain or Loss on net asset value (NAV) of your portfolio or selected Custodian/Strategy on a daily basis over the designated time period',
  ChartPnlByAssetClass = 'Gain or loss on the net asset value  (NAV) of the portfolio or selected Custodian/Strategy attributed to each asset class over the designated time period.<br/>Where there is less than one month of data, the chart will show daily information',

  ChartPnlByAttribution = 'Gain or loss on the net asset value (NAV) of the portfolio or selected Custodian/Strategy over the designated time period attributed to changes in FX rates and market prices, and income received and/or paid.<br/>Where there is less than one month of data, the chart will show daily information',
}

export enum DASHBOARD_PORTFOLIO_PNL_HELP_TEXT {
  tableTop10 = 'The top 10 contributing instruments to portfolio or selected Custodian/Strategy P&L ranked in ascending or descending order',
}

export enum DASHBOARD_PORTFOLIO_STRUCTURE_HELP_TEXT {
  ChartPortfolioAllocation = 'Represents the composition of your portfolio or selected Custodian/Strategy displayed by different criteria<br/>' +
    '<ul>' +
    '<li><b>Asset Classes: </b>Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on asset classes</li>' +
    '<li><b>Geography: </b>Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on listing either Region of Risk, Region of Issue, MSCI Region of Risk or MSCI Classification</li>' +
    '<li><b>Currencies: </b>Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on currency denominations</li>' +
    '<li><b>Sectors: </b>Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on economic sectors</li>' +
    '<li><b>Custodian/Strategy: </b>Base currency and percentage allocation across the portfolio’s different Custodians/Strategies</li>' +
    '<li><b>Industry: </b>Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on industry</li>' +
    '<li><b>Industry: </b>Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on specific business industries</li>' +
    '</ul>',
  LegendAssetClasses = 'Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on asset classes',
  LegendGeography = 'Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on either Region of Risk, Region of Issue, MSCI Region of Risk or MSCI Classification',
  LegendCurrencies = 'Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on currency denominations',

  LegendSectors = 'Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on economic sectors',
  LegendCustodian = 'Base currency and percentage allocation across the portfolio’s different Custodians/Strategies',
  LegendIndustry = 'Base currency and percentage allocation of the portfolio or selected Custodian/Strategy based on specific business industries',
  TableTop10Positions = 'The top 10 positions in your portfolio or selected Custodian/Strategy based on current value',
  ChartPortfolioBreakdownHistory = 'Illustrates the composition of the portfolio or selected Custodian/Strategy Net Asset Value (NAV) split by asset class over the designated time period. <br/>Where there is less than one month of data, the chart will show daily information',
}

export enum DASHBOARD_PORTFOLIO_FEES_HELP_TEXT {
  ChartInfo = 'Shows Fees and Costs totalled by month and filtered by Custodian or Currency.  This chart is affected by the date range selection.',
  TableInfo = 'Shows Fees and Costs totalled by month and year and filtered by Custodian or Currency.  This table is not affected by the date range selection.',
}

export enum DASHBOARD_EQUITY_HELP_TEXT {
  InfoInceptionPnL = 'Gain or loss on the net asset value (NAV) of options held in the portfolio, since inception',
  InfoPerformanceTWR = 'Time Weighted Return of equities held in the portfolio, since inception',
  InfoTotalEquity = 'Represents the current value of your equity portfolio',
  InfoDividendsSinceInception = 'Sum of all dividends received and/or paid, since inception',
  InfoDividendsNextYear = 'Sum of all dividends forecast for the next 12 months',
  ChartPerformance = 'Time Weighted Return of equities in the portfolio compared to the Time Weighted Return of the selected benchmark index over a specific time period',
  ChartPnL = 'Month on month gain or loss on the net asset value (NAV) of the equities held in your portfolio over selected time period',
  TableTop10ByPnl = 'The top 10 contributors to Equity P&L',

  ChartEquityBreakdown = 'Represents the composition of your Equity portfolio broken down by different criteria' +
    '<ul>' +
    '<li><b>Subclasses: </b>Base currency and percentage allocation of equities in the portfolio based on subclasses</li>' +
    '<li><b>Geography: </b>Base currency and percentage allocation of equities in the portfolio based on listing jurisdiction (Country of listing)</li>' +
    '<li><b>Currencies: </b>Base currency and percentage allocation of equities in the portfolio based on currency denomination</li>' +
    '<li><b>Sectors: </b>Base currency and percentage allocation of equities in the portfolio based on industry sector</li>' +
    '<li><b>Custodian: </b>Base currency and percentage allocation of equities in the portfolio across different custodians</li>' +
    '<li><b>ETF Strategy: </b>Base currency and percentage allocation of ETFs in the portfolio based on investment/instrument type</li>' +
    '</ul>',
  TableTop10ByStructure = 'The top 10 positions within your Equity portfolio based on current value',
  IncomeDistributions = 'Income received and/or paid over the selected time period.',
  ChartEstimatedIncomeNextYear = 'Estimate of income to be received and/or paid for the following year',
  ChartDistributions = 'Distributions received and/or paid over the selected time period based on ex-dividend dates',
  ChartEstimatedDistributionsNextYear = 'Estimate of distributions to be received and/or paid for the following year',
  ChartCoupons = 'Coupons received and/or paid over the selected time period based on ex-dividend dates',
  ChartEstimatedCouponsNextYear = 'Estimate of coupons to be received and/or paid for the following year',
  ChartDividends = 'Dividends received and/or paid over the selected time period based on ex-dividend dates',
  ChartEstimatedDividendsNextYear = 'Estimate of dividends to be received and/or paid for the following year',
  TablePnLBreakdown = '<ul>' +
    '<li><b>FX: </b>P&L of instrument attributed to FX changes</li>' +
    '<li><b>Price: </b>P&L of instrument attributed to changes in price</li>' +
    '<li><b>Income: </b>P&L of instrument attributed to dividends received</li>' +
    '<li><b>Realised: </b>P&L of instrument that has been realised</li>' +
    '</ul>',
  ChartPnlPerformance = 'Performance (TWR) of equities in the portfolio compared to the performance of the selected benchmark index over a specific time period',
  ChartPnlPnl = 'Gain or loss on the net asset value (NAV) of the equities held in your portfolio over the selected time period attributed to' +
    '<ul>' +
    '<li><b>FX: </b>Changes in FX rates</li>' +
    '<li><b>Price: </b>Changes in the market price of assets</li>' +
    '<li><b>Income: </b>Dividends received and/or paid</li>' +
    '</ul>',
  ChartPnlOptions = 'Gain or loss on the net asset value (NAV) of the options held in your portfolio over the selected time period attributed to' +
    '<ul>' +
    '<li><b>FX: </b>Changes in FX rates</li>' +
    '<li><b>Price: </b>Changes in the market price of assets</li>' +
    '<li><b>Income: </b>Dividends received and/or paid</li>' +
    '</ul>',
  OptionsPremiumByMonthChart = 'Shows the Total Premiums received and paid for both long and shorts positions and the maturity month of those options.',
  OptionsSummaryChart = 'The first table provides a detailed analysis of all current option positions in the portfolio, showing important metrics such as but not limited to:' +
    '<ul>' +
    '<li><b>Delta: </b>Percentage change in option value based on a $1 change in the underlying stock</li>' +
    '<li><b>Notional: </b>Current value of Underlying * Multiplier * Number of Contracts</li>' +
    '<li><b>Delta adjusted exposure: </b>Notional * Delta</li>' +
    '<li><b>Implied Volatility: </b>Is the volatility of the underlying instrument, that when applied to the applicable pricing model, returns the current price of the option</li>' +
    '<li><b>Moneyness Percentage: </b>Is the probability that the option will expire in the money</li>' +
    '</ul>',
  OptionsCallsTable = 'Summarises all Call options by their percentage moneyness, totalling their relevant notionals, premiums, and delta-exposures.',
  OptionsPutsTable = 'Summarises all Put options by their percentage moneyness, totalling their relevant notionals, premiums, and delta-exposures.',
}

export enum EQUITY_PNL_BREAKDOWN_HELP_TEXT {
  ChartStockPriceByBuysSellsDividends = 'Compares the stock price and the FX adjusted stock price, with purchases as green triangles, sells as red triangles and dividends as blue triangles',
  ChartStockPriceByBuysSellsCoupons = 'Compares the stock price and the FX adjusted stock price, with purchases as green triangles, sells as red triangles and coupons as blue triangles',
  ChartPositionHistoryWaterfall = 'The waterfall shows the amount paid (green) and received (red) for purchases and sells respectively, with the current position value in orange',
  ChartCumulativePnlSinceInception = 'Shows the cumulative total profit & loss on the instrument, since the first transaction',
  ChartRealisedAndUnrealisedPnl = 'Illustrates the realised Profit & Loss and the current unrealised Profit & Loss for the instrument',
}

// Fund Dashboard - same as equity except "Equity" changes to funds and "Dividends" changes to "Distributions"

export enum DASHBOARD_FUND_HELP_TEXT {
  InfoInceptionPnL = 'Gain or loss on the net asset value (NAV) of funds held in the portfolio, since inception',
  InfoPerformanceTWR = 'Time Weighted Return of funds held in the portfolio, since inception',
  InfoTotalEquity = 'Represents the current value of your funds portfolio',
  InfoDividendsSinceInception = 'Sum of all Distributions received and/or paid, since inception',
  InfoDividendsNextYear = 'Sum of all Distributions forecast for the next 12 months',
  ChartPerformance = 'Time Weighted Return of funds in the portfolio compared to the Time Weighted Return of the selected benchmark index over a specific time period',
  ChartPnL = 'Month on month gain or loss on the net asset value (NAV) of the funds held in your portfolio over selected time period',
  TableTop10ByPnl = 'The top 10 contributors to Funds P&L',

  ChartEquityBreakdown = 'Represents the composition of your Funds portfolio broken down by different criteria' +
    '<ul>' +
    '<li><b>Subclasses: </b>Base currency and percentage allocation of funds in the portfolio based on subclasses</li>' +
    '<li><b>Geography: </b>Base currency and percentage allocation of funds in the portfolio based on listing jurisdiction (Country of listing)</li>' +
    '<li><b>Currencies: </b>Base currency and percentage allocation of funds in the portfolio based on currency denomination</li>' +
    '<li><b>Sectors: </b>Base currency and percentage allocation of funds in the portfolio based on industry sector</li>' +
    '<li><b>Custodian: </b>Base currency and percentage allocation of funds in the portfolio across different custodians</li>' +
    '<li><b>ETF Strategy: </b>Base currency and percentage allocation of ETFs in the portfolio based on investment/instrument type</li>' +
    '</ul>',
  TableTop10ByStructure = 'The top 10 positions within your Equity portfolio based on current value',

  ChartDividends = 'Dividends received and/or paid over the selected time period based on ex-dividend dates',
  ChartEstimatedDividendsNextYear = 'Estimate of dividends to be received and/or paid for the following year',
  TablePnLBreakdown = '<ul>' +
    '<li><b>FX: </b>P&L of instrument attributed to FX changes</li>' +
    '<li><b>Price: </b>P&L of instrument attributed to changes in price</li>' +
    '<li><b>Income: </b>P&L of instrument attributed to dividends received</li>' +
    '<li><b>Realised: </b>P&L of instrument that has been realised</li>' +
    '</ul>',
  ChartPnlPerformance = 'Performance of funds (TWR) of equities in the portfolio compared to the performance of the selected benchmark index over a specific time period',
  ChartPnlPnl = 'Gain or loss on the net asset value (NAV) of the funds held in your portfolio over the selected time period attributed to' +
    '<ul>' +
    '<li><b>FX: </b>Changes in FX rates</li>' +
    '<li><b>Price: </b>Changes in the market price of assets</li>' +
    '<li><b>Income: </b>Dividends received and/or paid</li>' +
    '</ul>',
}

export enum DASHBOARD_FIXED_INCOME_HELP_TEXT {
  InfoInceptionPnL = 'Total gain or loss on the net asset value (NAV) of the portfolio or selected Custodian/Strategy since inception',
  InfoTotal = 'Total value of all Fixed Income instrument positions in this portfolio',
  IncomeTotal = 'Total profit or loss due to coupons or interest since inception',
  ChartPerformance = 'Time Weighted Return of fixed income in the portfolio compared to the Time Weighted Return of the selected benchmark index over a specific time period',
  ChartPnL = 'Month on month gain or loss on the net asset value (NAV) of the fixed income held in your portfolio over selected time period',
  TableTop10ByPnl = 'The top 10 contributors to fixed income P&L',
  TablePnLBreakdown = '<ul>' +
    '<li><b>FX: </b>P&L of instrument attributed to FX changes</li>' +
    '<li><b>Price: </b>P&L of instrument attributed to changes in price</li>' +
    '<li><b>Income: </b>P&L of instrument attributed to coupons received</li>' +
    '<li><b>Realised: </b>P&L of instrument that has been realised</li>' +
    '</ul>',
}

export enum DASHBOARD_REALASSET_HELP_TEXT {
  PnLTotal = 'Total profit or loss from Real Assets in this portfolio since inception',
  InfoTotal = 'Total value of all Real Asset positions in this portfolio',
}

export enum DASHBOARD_REALESTATE_HELP_TEXT {
  PnLTotal = 'Total profit or loss from Real Estate in this portfolio since inception',
  InfoTotal = 'Total value of all Real Estate positions in this portfolio',
}

export enum DASHBOARD_CRYPTO_HELP_TEXT {
  ChartPriceByBuysSells = 'Shows the price over time, with buys (green triangles) and sells (red triangles)',
  ChartPositionHistoryWaterfall = 'The waterfall shows the quantity bought (green) and sold (red) or purchases and sells respectively, with the current position size in orange',
  ChartCumulativePnlSinceInception = 'Shows the cumulative total profit & loss on the instrument, since the first transaction',
  ChartRealisedAndUnrealisedPnl = 'Illustrates the realised Profit & Loss and the current unrealised Profit & Loss for the instrument',
  InfoTotal = 'Represents the current value of all the cryptocurrency positions in your portfolio',
  InfoPerf = 'Represents the total Time Weighted Return (TWR) performance on all cryptocurrency positions in your portfolio since inception',
  InfoPnL = 'Represents the total profit or loss on all cryptocurrency positions in your portfolio since inception',
}

export enum DASHBOARD_COMM_HELP_TEXT {
  ChartPriceByBuysSells = 'Shows the price over time, with buys (green triangles) and sells (red triangles)',
  ChartPositionHistoryWaterfall = 'The waterfall shows the quantity bought (green) and sold (red) or purchases and sells respectively, with the current position size in orange',
  ChartCumulativePnlSinceInception = 'Shows the cumulative total profit & loss on the instrument, since the first transaction',
  ChartRealisedAndUnrealisedPnl = 'Illustrates the realised Profit & Loss and the current unrealised Profit & Loss for the instrument',
  InfoTotal = 'Represents the current value of all the physical commodity positions in your portfolio',
  InfoPerf = 'Represents the total Time Weighted Return (TWR) performance on all physical commodity positions in your portfolio since inception',
  InfoPnL = 'Represents the total profit or loss on all physical commodity positions in your portfolio since inception',
}

export enum DASHBOARD_PORTFOLIO_INCOME_HELP_TEXT {
  ChartIncome = 'Month by month income received or paid in the form of interest, dividends, rent receipts and distributions on the portfolio or selected Custodian/Strategy over the designated time period',
  ChartEstimatedIncomeNextYear = 'Estimate of any income (inc. dividends, distributions, coupons, interest) to be received or paid on the portfolio in the next year based on the selected Custodians\nEstimates are based on current positions and a combination of declared and historic payments for each instrument',
}

export enum DASHBOARD_CASH_HELP_TEXT {
  InfoTotal = 'Total value of all Cash positions in this portfolio',
  LabelNetValue = 'Amount of cash held less cash borrowed',
  LabelShortValue = 'Total amount of cash borrowed',
  LabelLongValue = 'Total amount of cash held',
  ChartPnL = 'Gain or Loss on net asset value (NAV) of cash in your portfolio on a month to month basis over selected time period',
  TableTop10Positions = 'Top 10 cash positions, held or borrowed, in your portfolio',
}

export enum RISK_CONTAINER_HELP_TEXT {
  TogglePeriodChange = 'Select backtest time period (business days) for which simulated volatility is calculated, this will affect the Volatility Gauge and the Annualised Return vs Volatility scatter chart',
  ChartRiskScoreGaugeDaily = '<em><p>Volatility represents the historic variability of portfolio returns and is calculated by annualising the standard deviation of the portfolio’s daily return series over the time period selected</p>' +
    '<ul>' +
    '<li>We use the market return over the last year to calculate the return (without any rebalancing)</li>' +
    '<li>We use the same time period (1y) but calculate the rolling volatility over the chosen period (100 or 260 days</li>' +
    '</ul></em>' +
    '<em><p>Illio presents 7 bands to segment estimated portfolio volatility</p>' +
    '<ul>' +
    '<li>Band 1 = 0 – 2%</li>' +
    '<li>Band 2 = 2% - 5%</li>' +
    '<li>Band 3 = 5% - 10%</li>' +
    '<li>Band 4 = 10% - 15%</li>' +
    '<li>Band 5 = 15% - 25%</li>' +
    '<li>Band 6 = 25% - 50%</li>' +
    '<li>Band 7 = 50% - 100+%</li>' +
    '</ul></em>' +
    '<br/><h4>Portfolio to Benchmark ratio</h4>' +
    '<p>Ratio of Portfolio Volatility compared to the volatility of the selected benchmark</p>',
  ChartRiskScoreGaugeMonthly = 'Volatility represents the historic variability of portfolio returns and is calculated by annualising the standard deviation of the portfolio’s monthly return series over the time period selected</p>' +
    '<em><p>Illio presents 7 bands to segment estimated portfolio volatility</p>' +
    '<ul>' +
    '<li>Band 1 = 0 – 2%</li>' +
    '<li>Band 2 = 2% - 5%</li>' +
    '<li>Band 3 = 5% - 10%</li>' +
    '<li>Band 4 = 10% - 15%</li>' +
    '<li>Band 5 = 15% - 25%</li>' +
    '<li>Band 6 = 25% - 50%</li>' +
    '<li>Band 7 = 50% - 100%</li>' +
    '</ul></em>' +
    '<br/><h4>Portfolio to Benchmark ratio</h4>' +
    '<p>Ratio of Portfolio Volatility compared to the volatility of the selected benchmark</p>',
  HistogramDistributionOfSimulatedReturnDaily = 'Shows the frequency and spread of daily historic returns on the portfolio over the time period selected, with the mean highlighted by the white vertical line' +
    '<em><p>Illio takes the current portfolio positions and assumes they were held in the same quantities one year ago</p>' +
    '<ul>' +
    '<li>We use the market return over the last year to calculate the return (without any rebalancing)</li>' +
    '</ul>',
  HistogramDistributionOfSimulatedReturnMonthly = 'Shows the frequency and spread of monthly historic returns on the portfolio over the time period selected, with the mean highlighted by the white vertical line' +
    '<em><p>Illio takes the current portfolio positions and assumes they were held in the same quantities one year ago</p>' +
    '<ul>' +
    '<li>We use the market return over the last year to calculate the return (without any rebalancing)</li>' +
    '<li>We use the same time period (1y) but calculate the rolling volatility over the chosen period (100 or 260 days)</li>' +
    '</ul>',
  ChartSimulatedPortfolioReturnVsVolatility = 'The Risk / Return chart plots the Annualised Return of your portfolio and volatility since inception, along with the portfolio’s respective asset classes and benchmark.',
  ChartSimulatedReturnVsVolatility = 'Illustrates the 1 year simulated return and 260 day volatility, of your portfolio and benchmark, over time' +
    '<em><p>Illio takes the current portfolio positions and assumes they were held in the same quantities one year ago</p>' +
    '<ul>' +
    '<li>We use the market return over the last year to calculate the return (without any rebalancing)</li>' +
    '<li>We use the same time period (1y) but calculate the rolling volatility over the chosen period (100 or 260 days)</li>' +
    '</ul>',
  ChartSimulatedReturnVsVolatility100Days = 'Illustrates the 1 year simulated return and 100 day volatility, of your portfolio and benchmark, over time' +
    '<em><p>Illio takes the current portfolio positions and assumes they were held in the same quantities one year ago</p>' +
    '<ul>' +
    '<li>We use the market return over the last year to calculate the return (without any rebalancing)</li>' +
    '<li>We use the same time period (1y) but calculate the rolling volatility over the chosen period (100 or 260 days)</li>' +
    '</ul>',
  ChartBreakdownOfPortfolioVolatility = 'Illustrates the contribution to portfolio volatility from each asset class, whilst also displaying the volatility of the portfolio and the benchmark. The percentage represents the respective asset class proportion of total NAV.',
  ChartBetaVsPortfolioVolatility = 'Measures the systematic (non-diversifiable) risk of the portfolio’s top 10 instruments by value compared to the benchmark index' +
    '<p>' +
    '<h4>Beta</h4>' +
    ' Provides a measure of how sensitive the stock is to a move in the benchmark index based on historical performance, where a beta of 1.00 would suggest that the stock has historically moved in line with the benchmark index' +
    '</p>',
  HistoricDrawDownChartDaily = '<p>Shows the historical performance of the portfolio overlayed with the high performance watermark and drawdowns</p>' +
    '<p style="text-align: left"><b>Watermark</b> - Line representing the peak value of the portfolio over the time selected </p>' +
    '<p><b>Drawdown</b> - Represents the periodic decline in performance from a historical peak until the next historical peak</p>',
  HistoricDrawDownChartMonthly = '<p>Shows the historical performance of the portfolio overlayed with the high performance watermark and drawdowns</p>' +
    '<p><b>Watermark</b> - Line representing the peak value of the portfolio over the time period selected </p>' +
    '<p><b>Drawdown</b> - Represents the decline in performance from a historical peak until the next historical peak</p>',
  PerformanceAndVolatilityTable = `<p>Note that if 'up to' date is today then<br />numbers are subject to change as new end <br />of day data becomes available from exchanges.<p>`

}

export enum SCENARIO_CONTAINER_HELP_TEXT {
  SliderEquityPrice = '<ul>' +
    '<li>Units are a percentage move in the selected benchmark for the portfolio</li>' +
    '<li>Estimated beta adjusted P&L impact on the portfolio due to the change in the selected benchmark for the portfolio</li>' +
    '<li>Assets affected: Equities, Options, ETFs, Equity and Fixed Income Funds, Commodities,  Crypto-currencies and any user added (“OTC”) instruments of the stated asset types</li>' +
    '</ul>',
  SliderFx = '<li>Units are a percentage move in the FX rate between the base currency and all other currencies, where a +5% move represents every other currency appreciating by 5% against the base currency</li>' +
    '<li>Estimated P&L impact on the portfolio due to depreciation or appreciation of the portfolio’s non-base currencies against the base currency</li>' +
    '<li>Assets affected: All assets held in non-base foreign currencies</li>' +
    '</ul>',
  SliderYield = '<li>Units are a percentage move in the yield to first (“YTF”) for bonds where a 10% increase in yield will shift the YTF from 3% to 3.3%</li>' +
    '<li>Estimated P&L impact on the portfolio due to an increase or decrease in the yield to first of each bond in the portfolio</li>' +
    '<li>Assets affected: Bonds, Funds with known maturity schedules and rho for Options</li>' +
    '</ul>',
  SliderInterest = 'Impact on P&L due to changes in Interest Rates',
  SliderVolatility = '<ul>' +
    '<li>Units are a percentage move in implied volatility, where a +10% move will shift the implied volatility of an option from 20% to 22%</li>' +
    '<li>Estimated P&L impact on the portfolio due to an increase or decrease in implied volatility</li>' +
    '<li>Assets affected: Options</li>' +
    '</ul>',
  chartBetaAdjusted = 'The P&L impact of applicable assets affected by the selected change in the benchmark, is estimated by using the historical beta, which is a measure of the correlation and relationship between the variability of the price of an asset and the variability of the benchmark',
}
