import React, { Fragment, PureComponent } from 'react';

import s from './CustodiansFilter.module.scss';
import { Checkbox } from '../../../../../../../../components/UIWidgets/Checkbox';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../../../components/ContextHelp';
import { DASHBOARD_PORTFOLIO_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';

interface IProps {
  custodians: Array<IOption<ICustodian>>;
  changeSelectedCustodians: (selectedCustodians: Record<string, boolean>) => void;
  onCustodiansChanged: (newCustodians: string[]) => void;
  selectedCustodians: Record<string, boolean>;
}

interface IState {
  checkboxes: Record<string, boolean>;
  checklistVisible: boolean;
  selectedCustodiansLabel: string;
}

class CustodiansFilter extends PureComponent<IProps, IState> {
  getCustodiansFromProps = () =>
    Object.keys(this.props.selectedCustodians).length === 0 && this.props.selectedCustodians.constructor === Object
      ? this.props.custodians.reduce(
          (options, option) => ({
            ...options,
            [option.id]: true,
          }),
          {}
        )
      : this.props.selectedCustodians;

  readonly state: IState = {
    checkboxes: this.getCustodiansFromProps(),
    checklistVisible: false,
    selectedCustodiansLabel: 'All',
  };

  toggleChecklistVisibility = (): void => {
    this.setState(() => ({
      checkboxes: this.getCustodiansFromProps(),
      checklistVisible: !this.state.checklistVisible,
    }));
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.custodians !== this.props.custodians) {
      this.setState(() => ({
        checkboxes: this.getCustodiansFromProps(),
        checklistVisible: false,
        selectedCustodiansLabel: 'All',
      }));
    }
  }

  handleCheckboxChange = (event: any): void => {
    const { name } = event.target;
    let selectedCustodianNames = '';

    const selectedCustodianId = this.props.custodians.filter((c) => c.name === name)[0];

    let newSelectedCustodians = {
      ...this.state.checkboxes,
      [selectedCustodianId.id]: !this.state.checkboxes[selectedCustodianId.id],
    };

    const someSelected = Object.entries(newSelectedCustodians).some(([_key, val]) => val);

    if (!someSelected) {
      newSelectedCustodians = this.props.custodians.reduce(
        (options, option) => ({
          ...options,
          [option.id]: true,
        }),
        {}
      );
      selectedCustodianNames = 'All';
    }

    this.setState(() => ({ checkboxes: newSelectedCustodians, selectedCustodiansLabel: selectedCustodianNames }));
  };

  handleFormSubmit = (formSubmitEvent: any) => {
    formSubmitEvent.preventDefault();

    this.setState(() => ({
      checklistVisible: false,
    }));

    this.props.changeSelectedCustodians(this.state.checkboxes);

    const custodiansStringArray = Object.entries(this.state.checkboxes)
      .filter(([key, val]) => val)
      .map(([key]) => key);

    this.props.onCustodiansChanged(custodiansStringArray);

    const selectedCustodians = this.state.checkboxes;
    this.props.changeSelectedCustodians(selectedCustodians);
  };

  getCustodianNames = () => {
    let selectedCustodianNames = 'All';
    const selectedCustodians = this.props.custodians.filter((custodian) => this.props.selectedCustodians[custodian.id]);

    if (!selectedCustodians.length || selectedCustodians.length === this.props.custodians.length) {
      return selectedCustodianNames;
    } else {
      selectedCustodianNames = selectedCustodians
        .slice(0, 2)
        .map((custodian) => custodian.name)
        .join(selectedCustodians.length === 2 ? ' & ' : ', ');

      if (selectedCustodians.length > 2) {
        selectedCustodianNames = `${selectedCustodianNames} + ${selectedCustodians.length - 2} more`;
      }
    }
    return selectedCustodianNames;
  };

  createCheckboxes = () =>
    this.props.custodians.map((el: IOption<ICustodian>) => {
      return (
        <Checkbox
          label={el.name}
          name={el.name}
          checked={this.state.checkboxes[el.id]}
          onChange={this.handleCheckboxChange}
          key={el.id}
          className={s.checklistItem}
        />
      );
    });

  render() {
    const custodianNames = this.getCustodianNames();

    return (
      <div className={s.custodiansFilterContainer}>
        <div className={s.custodiansLabel}>
          <div className={s.ddLabel} onClick={this.toggleChecklistVisibility}>
            Custodian(s): <span className={s.custLabel}>{custodianNames}</span>
            <div className={s.comboPseudoArrow} />
          </div>
          <div className={s.custFilterIcon}>
            <ContextHelpIcon title={''} elementId={'custodiansFilterTitle'} />
          </div>
        </div>
        <div className={s.rightAligned}>
          <div className={this.state.checklistVisible ? s.checklistVisible : s.checklistHidden}>
            <form onSubmit={this.handleFormSubmit}>
              {this.createCheckboxes()}
              <div className={s.buttonsArea}>
                <button className={s.button} type="submit">
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
        <ContextHelpTooltip
          elementId={'custodiansFilterTitle'}
          tooltipTitle={'Filter Custodians'}
          tooltipText={DASHBOARD_PORTFOLIO_HELP_TEXT.InfoCustodiansFilter}
        />
      </div>
    );
  }
}

export default CustodiansFilter;
