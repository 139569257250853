import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';

import { getTreeMapChartOptions } from './treeMapChartOptions';
import { ICurrencyFormatter } from '../../services/selectors/portfolio';

export interface ITreeMapData extends echarts.EChartOption.SeriesTreemap.DataObject {
  id: string;
  percentage: number;
}

interface IProps {
  treeData: ITreeMapData[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class TreeMap extends PureComponent<IProps> {
  render() {
    const { treeData, portfolioCurrencyFormatter } = this.props;
    return (
      <ReactEcharts
        style={{ height: '100%', width: '100%', flexGrow: 1 }}
        option={getTreeMapChartOptions(treeData, portfolioCurrencyFormatter)}
      />
    );
  }
}

export default TreeMap;
