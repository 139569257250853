import ReactEcharts from 'echarts-for-react';
import React, { Fragment, PureComponent } from 'react';
import { ChartLegend } from '../../../../../../../../../../../../components/ChartLegend';
import {
  ILegendItemProps,
  LegendType,
} from '../../../../../../../../../../../../components/ChartLegend/components/LegendItem';

import { ICurrencyFormatter } from '../../../../../../../../../../../../services/selectors/portfolio';
import { generateChartOptions } from './CumulativePnLChartConfig';

interface IOwnProps {
  baseCurrency: string;
  baseCurrencySymbol: string;
  chartData: ICumulativePnl[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  instrumentName: string;
}

type IProps = IOwnProps;

interface IState {
  chartWidth: number;
}

class CumulativePnLChart extends PureComponent<IProps, IState> {
  readonly state: IState = {
    chartWidth: 0,
  };

  private _echartsReact: React.RefObject<ReactEcharts> = React.createRef();

  constructor(props: IProps) {
    super(props);
    // this.handleZoomChange = debounce(this.handleZoomChange, 200);
  }

  render() {
    const chartOpt = generateChartOptions(
      this.props.chartData,
      this.props.instrumentName,
      this.props.baseCurrencySymbol,
      this.props.portfolioCurrencyFormatter
    );
    const legendOpt: ILegendItemProps[] = [{ name: this.props.instrumentName, color: '#6677cc' }];

    return (
      <Fragment>
        <ChartLegend legend={legendOpt} type={LegendType.LINE} />
        <ReactEcharts
          ref={this._echartsReact}
          style={{ height: '420px', width: '100%', flex: '1', maxHeight: '400px' }}
          option={chartOpt}
        />
      </Fragment>
    );
  }

  private _updateWidth() {
    // todo: expand ReactEcharts type definition
    if (!this._echartsReact.current) {
      return;
    }
    const echart: echarts.ECharts = (this._echartsReact.current as any).getEchartsInstance();
    this.setState({ chartWidth: echart.getWidth() });
  }
}

export default CumulativePnLChart;
