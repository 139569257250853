import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { PortfolioIndexActions } from '../actions/PortfolioIndexActions';
import { ReduxHelper } from '../utils/ReduxHelper';

export interface IPortfolioIndexState extends ILoadingDataState {
  data: IIndex[];
}

const INITIAL_STATE: IPortfolioIndexState = ReduxHelper.createInitialState<IIndex[]>([]);

const portfolioIndexReducer = ReduxHelper.createHttpRequestReducer<IPortfolioIndexState>(
  INITIAL_STATE,
  PortfolioIndexActions.PORTFOLIO_INDEX_REQUEST,
  PortfolioIndexActions.PORTFOLIO_INDEX_SUCCESS,
  PortfolioIndexActions.PORTFOLIO_INDEX_ERROR
);

export default portfolioIndexReducer;
