import React from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { FormFieldWrapper } from '../../../../../../../../components';
import { useFormContext, Controller } from 'react-hook-form';
import Input from '../../../../../../../../components/UIWidgets/Input/Input';
import { floatNumberV2 } from '../../../../../../../../services/utils/FormNormalize';

interface IOwnProps {
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  maxDecimals?: number;
}

export const NumberField = ({ label, name, disabled, required, maxDecimals = 2 }: IOwnProps) => {
  const { control } = useFormContext();

  return (
    <div>
      <FormFieldWrapper label={label} required={required} className={cn(s.minField)}>
        <Controller name={name} control={control} as={DecimalInput} maxDecimals={maxDecimals} disabled={disabled} />
      </FormFieldWrapper>
    </div>
  );
};

const DecimalInput = ({ value, onChange, disabled, name, maxDecimals = 2, ...rest }: any) => {
  return (
    <Input
      autoComplete="off"
      name={name}
      disabled={disabled}
      value={value}
      placeholder={"Enter"}
      style={{backgroundColor: "var(--secondary-bg"}}
      onChange={({ target }) => {
        onChange(floatNumberV2(target.value, maxDecimals));
      }}
      {...rest}
    />
  );
};
