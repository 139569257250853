import React, { PureComponent, Fragment } from 'react';
import cn from 'classnames';
import { AllocationBar, IAllocationBar } from '../../../../components/AllocationBar';
import { ALLOCATION_BARS_OPTIONS } from '../../../../services/constants/constants';
import { IAllocationDict } from '../../../../models/IAllocationDict';

import s from './AllocationsList.module.scss';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';

export interface IAllocationListProps {
  allocations: IAllocationDict;
  inRow: number;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class AllocationsList extends PureComponent<IAllocationListProps> {
  static readonly defaultProps: Partial<IAllocationListProps> = {
    inRow: 3,
  };

  componentDidUpdate(prevProps: Readonly<IAllocationListProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.allocations !== this.props.allocations) {
      this.forceUpdate();
      this.renderItems();
    }
  }

  renderItems() {
    const { allocations, portfolioCurrencyFormatter } = this.props;

    return Object.keys(allocations).map((key) => {
      try {
        const barItems: IAllocationBar[] = allocations[key].map((item) => ({
          id: item.id ? item.id : '',
          name: item.name || '',
          value: item.percentage,
          moneyValue: typeof item.value === 'number' ? portfolioCurrencyFormatter(item.value) : '',
        }));

        const options = ALLOCATION_BARS_OPTIONS[key] || {};
        const color = options.color;
        // find a way to not check location
        const title =
          options.title === 'Sector' && window.location.pathname.includes('structure/All/structure')
            ? 'Sector/Strategy'
            : options.title;

        if (barItems.length !== 0) {
          return (
            <div key={key} className={s.allocationsListItem}>
              <AllocationBar items={barItems} title={title} prefix={key} color={color} />
            </div>
          );
        }
      } catch (e) {
        return <Fragment key={key} />;
      }
    });
  }

  render() {
    const { inRow } = this.props;

    return <div className={cn(s.allocationsList, { [s.wrap]: inRow > 4 })}>{this.renderItems()}</div>;
  }
}

export default AllocationsList;
