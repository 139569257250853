import React from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  currencyCode?: string;
}

const TradeCost = ({ currencyCode = '' }: IProps) => {
  return (
    <FormFieldWrapper label={`Other Charges${currencyCode ? `, ${currencyCode}` : ''}`} className={s.mediumField}>
      <Field
        placeholder="0"
        name="tradeCosts"
        theme="inverse"
        component={renderInput}
        className="input--small"
        normalize={(value: number) => positiveNumber(floatNumberV2(value))}
      />
    </FormFieldWrapper>
  );
};

export default TradeCost;
