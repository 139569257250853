import useApi from '../useApi';
import { getApiUrl } from '../../constants/endpoints';
import { ALLOCATION_TYPES } from '../../constants/constants';
import { useTypedSelector } from '../useTypedSelector';
import { usePeriod } from '../../context/PeriodContext';

export const usePortfolioTopPositions = (settings?: { assetClassId?: string; direction?: string; limit?: number }) => {
  const { portfolioInfo } = useTypedSelector((state) => ({
    portfolioInfo: state.portfolio.portfolioInfo,
  }));

  const period = usePeriod();

  return useApi(
    getApiUrl('topPositionsByPnL'),
    {
      method: 'POST',
      data: {
        number: typeof settings?.limit === 'number' ? settings.limit : 10,
        portfolioId: portfolioInfo.data?.id,
        period: period.period,
        order: {
          name: 'pnlBase',
          direction: settings?.direction ?? 'DESC',
        },
        filter: !!settings?.assetClassId
          ? {
              id: settings?.assetClassId,
              type: ALLOCATION_TYPES.AssetClass,
            }
          : undefined,
        custodians: [],
      },
    },
    {
      enabled: !!portfolioInfo.data?.id,
      refetchOnMount: false,
    }
  );
};
