import React from 'react';
import cn from 'classnames';
import s from './CryptoLeg.module.css';
import { CryptoLeg } from './CryptoLeg';

interface IOwnProps {
  includeFiat?: boolean;
}

export const CryptoPair = ({includeFiat = false} : IOwnProps) => {
  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.horizontal)}>
        <div className={cn(s.legSurround, s.horizontal)}>
          <div className={cn(s.border)} />
          <div className={cn(s.label)}>Buy</div>
          <CryptoLeg includeFiat={includeFiat} side={'buy'} />
        </div>
        <div className={cn(s.legSurround, s.horizontal)}>
          <div className={cn(s.border)} />
          <div className={cn(s.label)}>Sell</div>
          <CryptoLeg includeFiat={includeFiat} side={'sell'} />
        </div>
        <div className={cn(s.middle)} />
      </div>
    </div>
  );
};
