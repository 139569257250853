import { IRootState } from '../store';
import { createSelector } from 'reselect';
import { IOption } from '../../components/UIWidgets/Autocomplete';

export function getUsers(state: IRootState): IUser[] {
  return state.instruments.users.data;
}

export const getUserOptions = createSelector<IRootState, IUser[], Array<IOption<IUser>>>(getUsers, (users: IUser[]) => {
  return users.map((user: IUser) => ({
    id: user.id as string,
    name: `${user.firstName} ${user.lastName}`,
    value: user,
  }));
});
