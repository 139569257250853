import { AxiosPromise } from 'axios';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { BlotterTradesStatus } from '../../../../../../../components/BlotterTradesStatus';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../../../../components/HOC/withPortfolioInfo';
import { IOption } from '../../../../../../../components/UIWidgets/Autocomplete';

import {
  IPortfolioErrorsTrade,
  IPortfolioTrade,
  IPortfolioValidatedTrade,
} from '../../../../../../../models/IPortfolioTrade';
import { IPaginationContent } from '../../../../../../../models/redux/ILoadingPaginationDataState';
import { SnapshotActions } from '../../../../../../../services/actions';
import { AppActions } from '../../../../../../../services/actions/AppActions';
import { getPortfolioCurrencyFormatter } from '../../../../../../../services/selectors/portfolio';
import { getCustodianOptions } from '../../../../../../../services/selectors/snapshots';
import { IRootState } from '../../../../../../../services/store';
import { AsyncActionDispatch } from '../../../../../../../services/utils/ReduxHelper';
import { TempBlotterTable } from '../../../components';
import { NotificationType } from '../../../../../../../models/NotifictionType';

interface IMapStateToProps {
  trades: IPortfolioValidatedTrade[];
  selectedTrade: IPortfolioTrade;
  tradesOrder: IOrder;
  totalTrades: number;
  portfolioCurrencyFormatter: (price?: number) => string;
  custodianOptions: Array<IOption<ICustodian>>;
}

interface IDispatchToProps {
  changeTradesSort: (order: IOrder) => void;
  fetchSavedTrades: (
    portfolioId: string,
    order: IOrder,
    page?: number
  ) => AxiosPromise<IPaginationContent<IPortfolioValidatedTrade>>;
  removeTrades: (portfolioId: string, trades: IPortfolioTrade[]) => AxiosPromise<void>;
  showNotification: (notification: INotification) => void;
  editSavedTrade: (trade: IPortfolioTrade, errors: IPortfolioErrorsTrade[]) => any;
  resetEditTrade: () => void;
}

interface IOwnProps {
  onRemoveTransaction: (transaction: IPortfolioValidatedTrade) => void;
  onLoadData: (page: number) => void;
  onSortData: (order: IOrder) => void;
}

type IProps = IMapStateToProps & IDispatchToProps & IOwnProps & IWithPortfolioInfoProps;

class EditTradesTableContainer extends PureComponent<IProps> {
  componentDidMount() {
    const { portfolioInfo, tradesOrder, fetchSavedTrades } = this.props;
    fetchSavedTrades(portfolioInfo.id, tradesOrder);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { portfolioInfo, tradesOrder, fetchSavedTrades } = this.props;
    if (prevProps.tradesOrder !== tradesOrder || prevProps.portfolioInfo.id !== portfolioInfo.id) {
      fetchSavedTrades(portfolioInfo.id, tradesOrder);
    }
  }

  handleLoadMoreData = (page: number) => {
    const { portfolioInfo, tradesOrder, fetchSavedTrades } = this.props;
    fetchSavedTrades(portfolioInfo.id, tradesOrder, page);
  };

  handleEditTrade = (transaction: IPortfolioTrade, errors: IPortfolioErrorsTrade[]) => {
    const { editSavedTrade } = this.props;
    editSavedTrade(transaction, errors);
  };

  handleRemoveTradesNoConfirm = (transaction: IPortfolioTrade) => {
    const { portfolioInfo, removeTrades, tradesOrder, fetchSavedTrades, showNotification } = this.props;
    removeTrades(portfolioInfo.id, [transaction])
      .then(() => {
        fetchSavedTrades(portfolioInfo.id, tradesOrder);
      })
      .catch(() => {
        showNotification({ text: "Trade hasn't been removed", type: NotificationType.ERROR });
      });
  };

  handelOnGreen = () => {
    this.props.fetchSavedTrades(this.props.portfolioInfo.id, this.props.tradesOrder);
  };

  render() {
    const {
      trades,
      tradesOrder,
      totalTrades,
      portfolioInfo,
      changeTradesSort,
      portfolioCurrencyFormatter,
      fetchSavedTrades,
    } = this.props;
    return (
      <Fragment>
        <BlotterTradesStatus portfolioInfo={this.props.portfolioInfo} onGreen={this.handelOnGreen} />
        {trades.length > 0 && (
          <TempBlotterTable
            editable={true}
            trades={trades}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            totalTrades={totalTrades}
            tradesOrder={tradesOrder}
            onSortData={changeTradesSort}
            onLoadData={this.handleLoadMoreData}
            onEditTransaction={this.handleEditTrade}
            onRemoveTransaction={this.handleRemoveTradesNoConfirm}
            baseCurrency={this.props.portfolioInfo.currency}
            selectedTrade={this.props.selectedTrade}
            showNotification={this.props.showNotification}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  trades: state.snapshot.snapshotEdit.savedTrades.content,
  custodianOptions: getCustodianOptions(state),
  totalTrades: state.snapshot.snapshotEdit.savedTrades.totalElements,
  tradesOrder: state.snapshot.snapshotEdit.savedTrades.order,
  portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  selectedTrade: state.snapshot.snapshotEdit.editedUnconfirmedTrade.trade,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  changeTradesSort: (order: IOrder) => dispatch(SnapshotActions.changeSavedTradesSort(order)),
  removeTrades: (portfolioId: string, trades: IPortfolioTrade[]) =>
    dispatch(SnapshotActions.removeTrades(portfolioId, trades)),
  fetchSavedTrades: (portfolioId: string, order: IOrder, page?: number) =>
    dispatch(SnapshotActions.fetchPortfolioSavedTrades(portfolioId, order, page)),
  editSavedTrade: (trade: IPortfolioTrade, errors: IPortfolioErrorsTrade[]) =>
    dispatch(SnapshotActions.startEditUnconfirmedTrade(trade, errors)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  resetEditTrade: () => dispatch(SnapshotActions.finishEditUnconfirmedTrade()),
});

export default withPortfolioInfo(connect(mapStateToProps, mapDispatchToProps)(EditTradesTableContainer));
