import merge from 'lodash.merge';
import React, { PureComponent } from 'react';
import ReactSelect from 'react-select';
import { StylesConfig } from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { customStyles } from './selectOpt';
import { customSmallStyles } from './selectOptSmall';

export interface IOptionType<T = any> {
  label: string;
  value: T;
}

interface IProps {
  size: 'small' | 'default';
  options: IOptionType[];
  className?: string;
  placeholder?: string;
  selectedValue?: IOptionType | null;
  styles?: StylesConfig;
  onChange?: (value: any) => void;
}

class Select extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    size: 'default',
    styles: {},
  };

  handleChange = (selectedOption: ValueType<IOptionType>) => {
    const { onChange } = this.props;
    if (!onChange || Array.isArray(selectedOption)) {
      return;
    }

    // TODO: find better solution for types
    onChange((selectedOption as IOptionType).value);
  };

  render() {
    const { selectedValue, className, options, placeholder, size, styles } = this.props;
    const selectStyles = size === 'default' ? merge({}, customStyles, styles) : merge({}, customSmallStyles, styles);
    return (
      <ReactSelect
        value={selectedValue}
        className={className}
        isSearchable={false}
        options={options}
        styles={selectStyles}
        placeholder={placeholder}
        onChange={this.handleChange}
      />
    );
  }
}

export default Select;
