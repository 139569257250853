import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import s from './ContextHelp.module.scss';

interface IProps {
  elementId: string;
  tooltipTitle?: string;
  tooltipText?: string;
}

class ContextHelpTooltip extends PureComponent<IProps> {
  render() {
    const { elementId, tooltipTitle, tooltipText } = this.props;

    const txt = tooltipText !== undefined ? tooltipText : '';

    return (
      <ReactTooltip
        effect={'solid'}
        id={elementId}
        eventOff={'mouseout'}
        delayHide={1000}
        multiline={true}
        className={s.tooltipWrapper}
        backgroundColor={'#a9b2d1'}
        textColor={'#171b24'}
        getContent={(dataTip: string) => {
          return (
            <div className={s.tooltipContainer}>
              <h3>{tooltipTitle}</h3>
              <div className={s.tooltipHtml} dangerouslySetInnerHTML={{ __html: txt }} />
              <br />
              <br />
              <p className={s.link} onClick={() => window.open('https://illio.com/glossary', '_blank')}>
                See the complete Glossary...
              </p>
            </div>
          );
        }}
      />
    );
  }
}

const decodeEntities = (() => {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str: any) {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeHTMLEntities;
})();

export default ContextHelpTooltip;
