import React, { FC } from 'react';
import { Button } from '../../../../../../../components/UIWidgets/Button';
import cn from 'classnames';
import s from '../Transaction.module.scss';

interface IProps {
  isFormInvalid: boolean;
  editMode?: boolean;
  onCancel(): void;
  onRemove(): void;
  onUpdate?(): void;
  onSaveOverride?(): void;
}

const SaveUpdateButtons: FC<IProps> = ({ editMode, onRemove, onCancel, isFormInvalid, onSaveOverride, onUpdate }) => {
  if (editMode) {
    return (
      <React.Fragment>
        <Button size="small" variant="outline" className={cn(s.formBtn, s.removeBtn)} onClick={onRemove} active={false}>
          Remove
        </Button>
        <Button size="small" variant="outline" className={cn(s.formBtn, s.cancelBtn)} onClick={onCancel} active={false}>
          Cancel
        </Button>
        <Button size="small" className={cn(s.formBtn, s.addBtn)} type="submit" disabled={isFormInvalid} onClick={onUpdate}>
          Update
        </Button>
      </React.Fragment>
    );
  }
  return (
    <Button className={cn(s.formBtn, s.addBtn)} onClick={onSaveOverride} type="submit" disabled={isFormInvalid}>
      Save
    </Button>
  );
};

export default SaveUpdateButtons;
