import React, { useState, useEffect } from 'react';
import { Card } from '@iliotech/storybook';
import {
  usePeriodPnLByAllocationName,
  TPnlHistory,
} from '../../../../../../services/hooks/apiHooks/usePeriodPnLByAllocationName';
import { useExplorerContext } from '../../../../../../services/context/ExplorerContext';
import { ChartWithLegend } from './ChartWithLegend/ChartWithLegend';
import { useTypedSelector } from '../../../../../../services/hooks/useTypedSelector';
import { getPortfolioCurrency, getPortfolioCurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { ASSET_COLORS, ASSETS } from '../../../../../../services/constants/assetClasses';
import { usePeriod } from '../../../../../../services/context/PeriodContext';
import { DashboardTitle } from '../../../../../../components/UIWidgets/DashboardTitle/DashboardTitle';
import { usePortfolioInfo } from '../../../../../../services/hooks/usePortfolioInfo';

export const MultiLinePNL = ({ height = 400, expanded }: IExplorerWidgetProps) => {
  const { selectedAllocationType, donutData } = useExplorerContext();
  const { period } = usePeriod();

  const { portfolioCurrencyFormatter, portfolioCurrency } = useTypedSelector((state) => ({
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
    portfolioCurrency: getPortfolioCurrency(state),
  }));

  const { pnlByAllocation } = usePeriodPnLByAllocationName(selectedAllocationType as AllocationType);
  const [filterableAllocationList, setAllocationSlice] = useState<TPnlHistory>(pnlByAllocation?.pnlHistory || []);
  // resets filters when new data is coming
  const { portfolioInfo } = usePortfolioInfo();
  const riskAssetView = portfolioInfo.data?.riskAssetView;

  useEffect(() => {
    if (pnlByAllocation?.pnlHistory?.length) {
      setAllocationSlice(pnlByAllocation.pnlHistory);
    }
  }, [pnlByAllocation?.pnlHistory, selectedAllocationType, period]);

  const title = React.useMemo(() => {
    let val = 'P&L by ';
    switch (selectedAllocationType) {
      case 'AssetClass':
        if (riskAssetView === 'RISK_ASSET_CLASS') {
          val += 'Risk Asset Class';
        } else {
          val += 'Asset Class';
        }
        break;
      case 'Custodian':
      case 'Currency':
        val += selectedAllocationType;
        break;
      case 'IndustrySector':
        val += 'Sector';
        break;
      case 'Region':
        val += 'Region';
    }
    return val;
  }, [selectedAllocationType, riskAssetView]);

  const numberOfDates = React.useMemo(() => {
    return filterableAllocationList?.map((x) => x.pnlDateAllocation.length);
  }, [filterableAllocationList]);
  const indexRow = numberOfDates?.indexOf(Math.max(...numberOfDates));
  const allDates = React.useMemo(() => {
    const allocationByIndex = filterableAllocationList?.[indexRow];
    const parseDate = (val: { date: string }) => new Date(Date.parse(val.date));
    const allocationDates = allocationByIndex?.pnlDateAllocation?.map(parseDate);
    return allocationDates ?? [];
  }, [filterableAllocationList, indexRow]); // date "dd-mm-yyyy"
  const allSeries = React.useMemo(() => {
    if (!donutData) {
      return [];
    }
    const dataByAllocation = donutData?.[selectedAllocationType!];
    return (
      filterableAllocationList?.map((x) => {
        let foundColor;
        if (selectedAllocationType === 'AssetClass') {
          foundColor = dataByAllocation?.find((all: DonutMapEntry) => all.name === x?.code)?.color;
        } else {
          foundColor = dataByAllocation?.find((all: DonutMapEntry) => all.name === x?.name)?.color;
        }
        return {
          color: foundColor ?? ASSET_COLORS[ASSETS.other],
          name: x.name,
          data: x.pnlDateAllocation.map((_) => {
            return [_.date, _.pnl] as [string, number];
          }),
        };
      }) ?? []
    );
  }, [filterableAllocationList, selectedAllocationType, donutData]);
  if (!filterableAllocationList?.[indexRow]) {
    return null;
  }
  return (
    <Card style={{ height, padding: '10px 15px' }}>
      {/*{expanded && <h3>Profit & Loss</h3>}*/}
      <DashboardTitle title={title} style={{ marginBottom: 10 }} />
      <ChartWithLegend
        transitions={false}
        allSeries={allSeries}
        expanded={expanded}
        allDates={allDates}
        selectedCurrency={portfolioCurrency?.symbol as string}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
      />
    </Card>
  );
};
