import React from 'react';

import s from './PerformanceAndVolatilityTable.module.scss';
// import { VolatilityScoreChart } from '../VolatlityScoreChart';
import moment from 'moment';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';
import { RISK_CONTAINER_HELP_TEXT } from '../../../../../../services/constants/tooltipContextHelp';

interface IProps {
  tableData: any;
  inceptionTWR?: number;
  dataVariant?: string;
  portfolioBenchmarkIndexName?: string;
  endDate?: Date;
}

const PerformanceAndVolatilityTable = ({ tableData, portfolioBenchmarkIndexName, dataVariant, endDate }: IProps) => {
  const performanceData = tableData.portfolio.statistics;
  const sharpeRatio = tableData.portfolio.sharpeRatio;
  const avgAnnualReturn = tableData.portfolio.annualReturn;
  const annualVolatility = tableData.portfolio.volatility;
  const endDateString = moment(endDate).format('DD-MMM-YYYY');

  /*    console.log('*** PERFORMANCE DATA ***');
    console.log(performanceData);*/

  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>
        Portfolio stats (all up to {endDateString})
        <ContextHelpIcon title={''} elementId={'portfolioStatsLblHelp'} />
        <ContextHelpTooltip
          elementId="portfolioStatsLblHelp"
          tooltipTitle={''}
          tooltipText={RISK_CONTAINER_HELP_TEXT.PerformanceAndVolatilityTable}
        />
      </h3>
      <div className={s.tablesContainer}>
        <div>
          <h3>Performance</h3>
          <table className={s.dataTable}>
            <tbody>
              <tr>
                <td>Performance to {endDateString}</td>
                <td>
                  {performanceData.periodPerformance}
                  {performanceData.periodPerformance !== 'N/A' ? '%' : ''}
                </td>
              </tr>
              <tr>
                <td>Average Annual Return</td>
                <td>
                  {avgAnnualReturn}
                  {avgAnnualReturn !== 'N/A' ? '%' : ''}
                </td>
              </tr>
              <tr>
                <td>Return: Best {dataVariant === 'DAILY' ? 'Day' : 'Month'}</td>
                <td>
                  {performanceData.bestReturnForFrequency}
                  {performanceData.bestReturnForFrequency !== 'N/A' ? '%' : ''}
                </td>
              </tr>
              <tr>
                <td>Return: Worst {dataVariant === 'DAILY' ? 'Day' : 'Month'}</td>
                <td>
                  {performanceData.worstReturnForFrequency}
                  {performanceData.worstReturnForFrequency !== 'N/A' ? '%' : ''}
                </td>
              </tr>
              <tr>
                <td>% Up {dataVariant === 'DAILY' ? 'Days' : 'Months'}</td>
                <td>
                  {performanceData.percentageUpForFrequency}
                  {performanceData.percentageUpForFrequency !== 'N/A' ? '%' : ''}
                </td>
              </tr>
              <tr>
                <td>Longest Drawdown Period</td>
                <td>
                  {performanceData.longestDrawdownPeriod} {dataVariant === 'DAILY' ? 'days' : 'months'}
                </td>
              </tr>
              <tr>
                <td>Biggest Drawdown</td>
                <td>
                  {performanceData.biggestDrawdown}
                  {performanceData.biggestDrawdown !== 'N/A' ? '%' : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3>Volatility</h3>
          <table className={s.dataTable}>
            <tbody>
              <tr>
                <td>Annual Volatility</td>
                <td>{annualVolatility}%</td>
              </tr>
              <tr>
                <td>Sharpe Ratio (Based on avg. annual return)</td>
                <td>{sharpeRatio}</td>
              </tr>
              <tr>
                <td>1 Yr Rolling Beta (Daily Sampling)</td>
                <td>{performanceData.rollingBeta1Year}</td>
              </tr>
              <tr>
                <td>Outperformance {portfolioBenchmarkIndexName ? ' vs ' + portfolioBenchmarkIndexName : ''}</td>
                <td>
                  {performanceData.alpha}
                  {performanceData.alpha !== 'N/A' ? '%' : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PerformanceAndVolatilityTable;
