import { ReduxHelper } from '../../utils/ReduxHelper';
import { DashboardActions } from '../../actions';
import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { combineReducers } from 'redux';

export interface IOptionsDashboardState extends ILoadingDataState {
  data: IOptionsDashboardData | null;
}

const INITIAL_STATE: IOptionsDashboardState = ReduxHelper.createInitialState<IOptionsDashboardData | null>(null);

const optionsDetailsReducer = ReduxHelper.createHttpRequestReducer<IOptionsDashboardState>(
  INITIAL_STATE,
  DashboardActions.OPTIONS_DASHBOARD_REQUEST,
  DashboardActions.OPTIONS_DASHBOARD_SUCCESS,
  DashboardActions.OPTIONS_DASHBOARD_ERROR,
  DashboardActions.OPTIONS_DASHBOARD_RESET
);

export interface IOptionsPNLByMonthState extends ILoadingDataState {
  data: IBarData[];
}

const INITIAL_PNL_BY_MONTH_STATE: IOptionsPNLByMonthState = ReduxHelper.createInitialState<IBarData[]>([]);

const optionsPNLByMonthReducer = ReduxHelper.createHttpRequestReducer<IOptionsPNLByMonthState>(
  INITIAL_PNL_BY_MONTH_STATE,
  DashboardActions.OPTIONS_PNL_BY_MONTH_REQUEST,
  DashboardActions.OPTIONS_PNL_BY_MONTH_SUCCESS,
  DashboardActions.OPTIONS_PNL_BY_MONTH_ERROR,
  DashboardActions.OPTIONS_PNL_BY_MONTH_RESET
);

export interface IOptionsPerformanceState extends ILoadingDataState {
  data: [string, number, number, number][];
}

const INITIAL_PERFORMANCE_STATE: IOptionsPerformanceState = ReduxHelper.createInitialState<
  [string, number, number, number][]
>([]);

const optionsPerformanceReducer = ReduxHelper.createHttpRequestReducer<IOptionsPerformanceState>(
  INITIAL_PERFORMANCE_STATE,
  DashboardActions.OPTIONS_PERFORMANCE_REQUEST,
  DashboardActions.OPTIONS_PERFORMANCE_SUCCESS,
  DashboardActions.OPTIONS_PERFORMANCE_ERROR,
  DashboardActions.OPTIONS_PERFORMANCE_RESET
);

const optionsReducer = combineReducers({
  dashboard: optionsDetailsReducer,
  pnlByMonth: optionsPNLByMonthReducer,
  performance: optionsPerformanceReducer,
});

export default optionsReducer;
