import { combineReducers } from 'redux';
import { ReduxHelper } from '../utils/ReduxHelper';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import PortfolioActions from '../actions/PortfolioActions';

import { IPortfolioStructure } from '../../models/IPortfolioStructure';
// import { IPortfolio } from '../../models/IPortfolio';

// const INITIAL_PORTFOLIO_SNAPSHOT_TABLE_PREFERENCES = {
//   columnFilterState: [
//     { dataKey: 'assetSubClass', isVisible: false },
//     { dataKey: 'sector', isVisible: false },
//     { dataKey: 'currencyNative', isVisible: true },
//     { dataKey: 'country', isVisible: true },
//     { dataKey: 'custodian', isVisible: true },
//     { dataKey: 'costPrice', isVisible: true },
//     { dataKey: 'currentPriceNative', isVisible: true },
//     { dataKey: 'pricingDate', isVisible: true },
//     { dataKey: 'priceGain', isVisible: true },
//     { dataKey: 'totalCostBase', isVisible: false },
//     { dataKey: 'percentOfPortfolio', isVisible: false },
//     { dataKey: 'profitAndLoss', isVisible: true },
//     { dataKey: 'notionBase', isVisible: false },
//     { dataKey: 'exposureBase', isVisible: false },
//     { dataKey: 'incomeHistoricBase', isVisible: false },
//     { dataKey: 'realisedBase', isVisible: false },
//   ],
// };

// const INITIAL_PORTFOLIO_TRADES_TABLE_PREFERENCES = {
//   columnFilterState: [
//     { dataKey: 'currency', isVisible: true },
//     { dataKey: 'amount', isVisible: true },
//     { dataKey: 'multiplier', isVisible: false },
//     { dataKey: 'commission', isVisible: true },
//     { dataKey: 'tradeCosts', isVisible: true },
//     { dataKey: 'custodian', isVisible: true },
//   ],
// };

export type IPortfolioInfoState = ILoadingDataState<IPortfolio | null>;
export type IPortfolioStructureState = ILoadingDataState<IPortfolioStructure>;
export type ILinkedInfoState = ILoadingDataState<IUser[]>;
export type IPortfolioEventsState = ILoadingDataState<IPortfolioEvent>;
export type IPortfolioCustodiansState = ILoadingDataState<ICustodian[]>;

export interface IPortfolioClassesState {
  portfolioInfo: IPortfolioInfoState;
  linkedUsers: ILinkedInfoState;
  portfolioStructure: IPortfolioStructureState;
  portfolioEvents: IPortfolioEventsState;
  portfolioCustodians: IPortfolioCustodiansState;
}

export default combineReducers<IPortfolioClassesState>({
  portfolioInfo: ReduxHelper.createHttpRequestReducer<IPortfolioInfoState>(
    ReduxHelper.createInitialState(null),
    PortfolioActions.PORTFOLIO_INFO_REQUEST,
    PortfolioActions.PORTFOLIO_INFO_SUCCESS,
    PortfolioActions.PORTFOLIO_INFO_ERROR,
    PortfolioActions.PORTFOLIO_INFO_RESET
  ),
  portfolioEvents: ReduxHelper.createHttpRequestReducer<IPortfolioEventsState>(
    ReduxHelper.createInitialState({}),
    PortfolioActions.PORTFOLIO_EVENTS_REQUEST,
    PortfolioActions.PORTFOLIO_EVENTS_SUCCESS,
    PortfolioActions.PORTFOLIO_EVENTS_ERROR,
    PortfolioActions.PORTFOLIO_EVENTS_RESET
  ),

  portfolioStructure: ReduxHelper.createHttpRequestReducer<IPortfolioStructureState>(
    ReduxHelper.createInitialState({}),
    PortfolioActions.PORTFOLIO_STRUCTURE_REQUEST,
    PortfolioActions.PORTFOLIO_STRUCTURE_SUCCESS,
    PortfolioActions.PORTFOLIO_STRUCTURE_ERROR,
    PortfolioActions.PORTFOLIO_STRUCTURE_RESET
  ),
  linkedUsers: ReduxHelper.createHttpRequestReducer<ILinkedInfoState>(
    ReduxHelper.createInitialState([]),
    PortfolioActions.LINKED_USERS_REQUEST,
    PortfolioActions.LINKED_USERS_SUCCESS,
    PortfolioActions.LINKED_USERS_ERROR,
    PortfolioActions.LINKED_USERS_RESET
  ),
  portfolioCustodians: ReduxHelper.createHttpRequestReducer<IPortfolioCustodiansState>(
    ReduxHelper.createInitialState([]),
    PortfolioActions.PORTFOLIO_CUSTODIANS_REQUEST,
    PortfolioActions.PORTFOLIO_CUSTODIANS_SUCCESS,
    PortfolioActions.PORTFOLIO_CUSTODIANS_ERROR,
    PortfolioActions.PORTFOLIO_CUSTODIANS_RESET
  ),
});
