import { IPortfolioServerData } from '../../modals/PortfolioForms/PortfolioCreateForm/models';

import { IPortfoliosFilters } from '../../models/IPortfoliosFilters';
import { IPortfolioStructure } from '../../models/IPortfolioStructure';

import { IActionPayload } from '../../models/redux/IActionPayload';
import { PortfolioType } from '../constants/constants';
import { getApiUrl, getCustomApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';
import { PeriodHelper } from '../utils/PeriodHelper';
import axios from 'axios';

export class PortfolioActions {
  static readonly PORTFOLIO_INFO_REQUEST = 'PORTFOLIO_INFO_REQUEST';
  static readonly PORTFOLIO_INFO_SUCCESS = 'PORTFOLIO_INFO_SUCCESS';
  static readonly PORTFOLIO_INFO_ERROR = 'PORTFOLIO_INFO_ERROR';
  static readonly PORTFOLIO_INFO_RESET = 'PORTFOLIO_INFO_RESET';

  static readonly PORTFOLIO_CUSTODIANS_REQUEST = 'PORTFOLIO_CUSTODIANS_REQUEST';
  static readonly PORTFOLIO_CUSTODIANS_SUCCESS = 'PORTFOLIO_CUSTODIANS_SUCCESS';
  static readonly PORTFOLIO_CUSTODIANS_ERROR = 'PORTFOLIO_CUSTODIANS_ERROR';
  static readonly PORTFOLIO_CUSTODIANS_RESET = 'PORTFOLIO_CUSTODIANS_RESET';

  static readonly PORTFOLIO_STRUCTURE_REQUEST = 'PORTFOLIO_STRUCTURE_REQUEST';
  static readonly PORTFOLIO_STRUCTURE_SUCCESS = 'PORTFOLIO_STRUCTURE_SUCCESS';
  static readonly PORTFOLIO_STRUCTURE_ERROR = 'PORTFOLIO_STRUCTURE_ERROR';
  static readonly PORTFOLIO_STRUCTURE_RESET = 'PORTFOLIO_STRUCTURE_RESET';

  static readonly PORTFOLIO_EVENTS_REQUEST = 'PORTFOLIO_EVENTS_REQUEST';
  static readonly PORTFOLIO_EVENTS_SUCCESS = 'PORTFOLIO_EVENTS_SUCCESS';
  static readonly PORTFOLIO_EVENTS_ERROR = 'PORTFOLIO_EVENTS_ERROR';
  static readonly PORTFOLIO_EVENTS_RESET = 'PORTFOLIO_EVENTS_RESET';

  static readonly PORTFOLIO_CREATE_REQUEST = 'PORTFOLIO_CREATE_REQUEST';
  static readonly PORTFOLIO_CREATE_SUCCESS = 'PORTFOLIO_CREATE_SUCCESS';
  static readonly PORTFOLIO_CREATE_ERROR = 'PORTFOLIO_CREATE_ERROR';

  static readonly PORTFOLIO_DELETE_REQUEST = 'PORTFOLIO_DELETE_REQUEST';
  static readonly PORTFOLIO_DELETE_SUCCESS = 'PORTFOLIO_DELETE_SUCCESS';
  static readonly PORTFOLIO_DELETE_ERROR = 'PORTFOLIO_DELETE_ERROR';

  static readonly PORTFOLIO_UPDATE_REQUEST = 'PORTFOLIO_UPDATE_REQUEST';
  static readonly PORTFOLIO_UPDATE_SUCCESS = 'PORTFOLIO_UPDATE_SUCCESS';
  static readonly PORTFOLIO_UPDATE_ERROR = 'PORTFOLIO_UPDATE_ERROR';

  static readonly PORTFOLIOS_LIST_REQUEST = 'PORTFOLIOS_LIST_REQUEST';
  static readonly PORTFOLIOS_LIST_SUCCESS = 'PORTFOLIOS_LIST_SUCCESS';
  static readonly PORTFOLIOS_LIST_ERROR = 'PORTFOLIOS_LIST_ERROR';
  static readonly PORTFOLIOS_LIST_RESET = 'PORTFOLIOS_LIST_RESET';
  static readonly PORTFOLIOS_FILTERS_CHANGE = 'PORTFOLIOS_FILTERS_CHANGE';

  static readonly PORTFOLIO_CHANGE_ACCESS_REQUEST = 'PORTFOLIO_CHANGE_ACCESS_REQUEST';
  static readonly PORTFOLIO_CHANGE_ACCESS_SUCCESS = 'PORTFOLIO_CHANGE_ACCESS_SUCCESS';
  static readonly PORTFOLIO_CHANGE_ACCESS_ERROR = 'PORTFOLIO_CHANGE_ACCESS_ERROR';

  static readonly LINKED_USERS_REQUEST = 'LINKED_USERS_REQUEST';
  static readonly LINKED_USERS_SUCCESS = 'LINKED_USERS_SUCCESS';
  static readonly LINKED_USERS_ERROR = 'LINKED_USERS_ERROR';
  static readonly LINKED_USERS_RESET = 'LINKED_USERS_RESET';

  static readonly SET_NOTIFICATION = 'SET_NOTIFICATION';
  static readonly CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

  static readonly LOAD_PORTFOLIO_SNAPSHOT_TABLE_PREFERENCES = 'LOAD_PORTFOLIO_SNAPSHOT_TABLE_PREFERENCES';
  static readonly UPDATE_PORTFOLIO_SNAPSHOT_TABLE_PREFERENCES = 'UPDATE_PORTFOLIO_SNAPSHOT_TABLE_PREFERENCES';

  static readonly LOAD_PORTFOLIO_TRADES_TABLE_PREFERENCES = 'LOAD_PORTFOLIO_TRADES_TABLE_PREFERENCES';
  static readonly UPDATE_PORTFOLIO_TRADES_TABLE_PREFERENCES = 'UPDATE_PORTFOLIO_TRADES_TABLE_PREFERENCES';

  static fetchPortfolioCustodians(portfolioId: string, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<ICustodian[]>(
      {
        url: getApiUrl('portfolio.info', portfolioId),
        // url: getCustomApiUrl(`portfolio.info`, [{ idLabel: `:portfolioId`, idValue: portfolioId }]),
        method: 'POST',
        data: {
          custodians: custodians || [],
        },
      },
      PortfolioActions.PORTFOLIO_CUSTODIANS_REQUEST,
      PortfolioActions.PORTFOLIO_CUSTODIANS_SUCCESS,
      PortfolioActions.PORTFOLIO_CUSTODIANS_ERROR,
      (data) => {
        // console.group("*** PORTFOLIO INFO ***");
        // console.log("Work group", data?.workgroup);
        // console.log(data);
        // console.groupEnd();
        axios.defaults.headers['illio-selected-workgroup'] = data?.workgroup?.id;
        return data.custodians;
      }
    );
  }

  static fetchPortfolioInfo(portfolioId: string, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolio>(
      {
        url: getApiUrl('portfolio.info', portfolioId),
        // url: getCustomApiUrl(`portfolio.info`, [{ idLabel: `:portfolioId`, idValue: portfolioId }]),
        method: 'POST',
        data: {
          custodians: custodians || [],
        },
      },
      PortfolioActions.PORTFOLIO_INFO_REQUEST,
      PortfolioActions.PORTFOLIO_INFO_SUCCESS,
      PortfolioActions.PORTFOLIO_INFO_ERROR,
      (data) => {
        // console.group("*** PORTFOLIO INFO ***");
        // console.log("Work group", data?.workgroup);
        // console.log(data);
        // console.groupEnd();
        axios.defaults.headers['illio-selected-workgroup'] = data?.workgroup?.id;
        return data;
      }
    );
  }

  static resetPortfolioInfo(): IActionPayload {
    return { type: PortfolioActions.PORTFOLIO_INFO_RESET };
  }

  static fetchPortfolioStructure(portfolioId: string) {
    const mapResponseFunc = (data: { structure: IPortfolioStructure }) => {
      return data.structure;
    };

    return ReduxHelper.createHttpRequestActionBundle<IPortfolioStructure>(
      {
        url: getApiUrl('portfolio.structure'),
        data: {
          portfolioId,
        },
      },
      PortfolioActions.PORTFOLIO_STRUCTURE_REQUEST,
      PortfolioActions.PORTFOLIO_STRUCTURE_SUCCESS,
      PortfolioActions.PORTFOLIO_STRUCTURE_ERROR,
      mapResponseFunc
    );
  }

  static fetchPortfolioEvents(portfolioId: string, custodians?: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolioEvent[]>(
      {
        url: getCustomApiUrl(`portfolio.events`, [{ idLabel: `:portfolioId`, idValue: portfolioId }]),
        method: 'POST',
        data: {
          custodians: custodians || [],
        },
      },
      PortfolioActions.PORTFOLIO_EVENTS_REQUEST,
      PortfolioActions.PORTFOLIO_EVENTS_SUCCESS,
      PortfolioActions.PORTFOLIO_EVENTS_ERROR
    );
  }

  static createPortfolio(portfolioInfo: IPortfolioServerData) {
    const dataServer = portfolioInfo;

    const custodian = portfolioInfo.custodian && { ...portfolioInfo.custodian, default: true };

    if (portfolioInfo.custodian && portfolioInfo.customCustodian !== '') {
      delete dataServer.customCustodian;
    }

    return ReduxHelper.createHttpRequestActionBundle<IPortfolio>(
      {
        url: getApiUrl('portfolioManager.create'),
        data: { ...dataServer, custodian },
      },
      PortfolioActions.PORTFOLIO_CREATE_REQUEST,
      PortfolioActions.PORTFOLIO_CREATE_SUCCESS,
      PortfolioActions.PORTFOLIO_CREATE_ERROR
    );
  }

  static updatePortfolioSettings(portfolio: IPortfolio) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolio>(
      {
        url: getApiUrl('portfolioManager.updateSettings'),
        data: {
          portfolioId: portfolio.id,
          valid: true,
          name: portfolio.name,
          accessType: portfolio.accessType,
          custodians: portfolio.custodians,
          benchmarkIndex: portfolio.benchmarkIndex,
          riskAssetView: portfolio.riskAssetView,
        },
        method: 'PUT',
      },
      PortfolioActions.PORTFOLIO_UPDATE_REQUEST,
      PortfolioActions.PORTFOLIO_UPDATE_SUCCESS,
      PortfolioActions.PORTFOLIO_UPDATE_ERROR
    );
  }

  static updatePortfolio(portfolio: IPortfolio) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolio>(
      {
        url: getApiUrl('portfolioManager.update'),
        data: {
          portfolioId: portfolio.id,
          name: portfolio.name,
          accessType: portfolio.accessType,
        },
      },
      PortfolioActions.PORTFOLIO_UPDATE_REQUEST,
      PortfolioActions.PORTFOLIO_UPDATE_SUCCESS,
      PortfolioActions.PORTFOLIO_UPDATE_ERROR
    );
  }

  static deletePortfolio(portfolio: IPortfolio) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('portfolioManager.delete'),
        data: {
          portfolioId: portfolio.id,
        },
      },
      PortfolioActions.PORTFOLIO_DELETE_REQUEST,
      PortfolioActions.PORTFOLIO_DELETE_SUCCESS,
      PortfolioActions.PORTFOLIO_DELETE_ERROR
    );
  }

  static fetchPortfoliosList(portfolioType: PortfolioType) {
    return ReduxHelper.createHttpRequestActionBundle<IPortfolio[]>(
      {
        url: getApiUrl('portfolioManager.list'),
        data: {
          portfolioType: [portfolioType],
        },
        method: 'POST',
      },
      PortfolioActions.PORTFOLIOS_LIST_REQUEST,
      PortfolioActions.PORTFOLIOS_LIST_SUCCESS,
      PortfolioActions.PORTFOLIOS_LIST_ERROR
    );
  }

  static changePortfoliosListFilters(filters: IPortfoliosFilters): IActionPayload<IPortfoliosFilters> {
    return {
      type: PortfolioActions.PORTFOLIOS_FILTERS_CHANGE,
      payload: filters,
    };
  }

  static resetPortfoliosList(): IActionPayload {
    return {
      type: PortfolioActions.PORTFOLIOS_LIST_RESET,
    };
  }

  static changePortfolioAccess(portfolioId: string, usersIds: string[]) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('portfolioManager.link'),
        data: {
          userIds: usersIds,
          portfolioId,
        },
      },
      PortfolioActions.PORTFOLIO_CHANGE_ACCESS_REQUEST,
      PortfolioActions.PORTFOLIO_CHANGE_ACCESS_SUCCESS,
      PortfolioActions.PORTFOLIO_CHANGE_ACCESS_ERROR
    );
  }

  static fetchLinkedUsers(portfolioId: string) {
    return ReduxHelper.createHttpRequestActionBundle<IUser[]>(
      {
        url: getApiUrl('portfolioManager.linkedUsers'),
        data: {
          portfolioId,
        },
      },
      PortfolioActions.LINKED_USERS_REQUEST,
      PortfolioActions.LINKED_USERS_SUCCESS,
      PortfolioActions.LINKED_USERS_ERROR
    );
  }

  static resetLinkedUsera(): IActionPayload {
    return { type: PortfolioActions.LINKED_USERS_RESET };
  }
  static showNotification(notification: INotification): IActionPayload<INotification> {
    return { type: PortfolioActions.SET_NOTIFICATION, payload: notification };
  }
  static clearNotification(): IActionPayload {
    return { type: PortfolioActions.CLEAR_NOTIFICATION };
  }
}

export default PortfolioActions;
