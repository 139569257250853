export const applyTheme = (themeMetaData: IThemeMetaData) => {
  if (typeof window === 'undefined' || typeof document?.head === 'undefined') {
    console.log("Can't append css");
    return;
  }

  clearTheme();
  const node = document.createElement('style');

  const cssVars = Object.entries({ ...DEFAULT_THEME, ...(themeMetaData?.colors ?? {}) })
    .map(([key, value]) => `${key}: ${value};`)
    .join(`\n`);

  // console.log(cssVars);

  node.id = '_additional_styles';
  node.textContent = `
      :root {
        ${cssVars}
      }
    `;

  document.head.appendChild(node);
};

export const clearTheme = () => {
  const oldNode = document.getElementById('_additional_styles');
  if (oldNode) {
    document.head.removeChild(oldNode);
  }
};

const DEFAULT_THEME = {};

// const DEFAULT_THEME = {
//   '--bg-color': '#000',
//   '--text-color': '#fff',
//   '--secondary-bg': '#101820',
//   '--btn-bg-color': '#D8AE8B',
//   '--btn-border-color': '#D8AE8B',
//   '--text-on-secondary-bg': '#fff',
//   '--primary-color': '#D8AE8B',
// };
