import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import React, { Dispatch, SetStateAction } from 'react';
import Widget, { IWidgetProps } from '../../../../../../components/WidgetsBlock/Widget/Widget';
import { ToggableChartLegend } from '../../../../../../../../components/ToggableChartLegend';
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import s from '../PnlByInstrumentChartContainer/PnlByInstrumentChartContainer.module.scss';
import { COLORS } from '../../../AssetsDashboard/scenes/Equilty/components/Options/OptionsConstants';
import sharedTooltipRender from '../../../../../../../../components/SharedTooltip/SharedTooltip';
import 'hammerjs';
import useToggableLegend from '../../../../../../../../services/hooks/useToggableLegend';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';

interface IOwnProps {
  barData: IBarData[];
  serverData: IPortfolioSnapshot[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  hidePeriodSelector?: boolean;
  onItemClick?(dateString: string): void;
  onSelectPosition: Dispatch<SetStateAction<IPortfolioSnapshot | null>>;
}

type IProps = IOwnProps & Exclude<IWidgetProps, 'type | renderHeaderTools'>;

const PnlByInstruments = ({
  barData,
  serverData,
  onSelectPosition,
  portfolioCurrencyFormatter,
  hidePeriodSelector,
  ...widgetProps
}: IProps) => {
  const { portfolioInfo } = usePortfolio();

  const portfolioCurrency = portfolioInfo.data?.currency?.name ?? '';
  const { legend, toggledData, onLegendItemClick } = useToggableLegend({
    chartData: barData,
    portfolioCurrencyFormatter,
    selectedCurrency: portfolioCurrency,
  });

  const feedableData = React.useMemo(() => {
    const data: { name: string; value: number; color: string; id: string; instrumentId: string }[] = [];
    toggledData.forEach((item) => {
      const color: string = item.color!;
      const id = item.id || '';
      const instrumentId = item.instrumentId || '';

      const items: { name: string; value: number; color: string; id: string; instrumentId: string }[] = item.data.map(
        (inner) => ({
          name: inner[0],
          value: inner[1],
          instrumentId,
          color,
          id,
        })
      );
      data.push(...items);
    });

    return data.sort((a, b) => Math.abs(b.value) - Math.abs(a.value));
  }, [toggledData]);

  return (
    <Widget full={true} {...widgetProps}>
      <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
      <Chart
        onSeriesClick={(e) => {
          const foundItem = serverData.find((item) => item.name === e.category);
          // need to put the dataItem variables explicitely as they come from the prototype
          onSelectPosition?.({
            ...e.dataItem,
            ...foundItem,
          });
        }}
        axisDefaults={{ color: '#3a466d' }}
        className={s.chartContainer}
      >
        <ChartTooltip
          shared={true}
          render={(context: any) =>
            sharedTooltipRender(context, portfolioCurrencyFormatter, serverData, portfolioCurrency, onSelectPosition)
          }
        />
        <ChartArea background={'transparent'} />
        <ChartValueAxis>
          <ChartValueAxisItem
            majorGridLines={{
              color: '#3a466d',
            }}
          >
            <ChartCategoryAxisLabels color={COLORS.CLOUDY_BLUE} content={(e) => portfolioCurrencyFormatter(e.value)} />
          </ChartValueAxisItem>
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            axisCrossingValue={100}
            labels={{
              color: 'white',
              rotation: 'auto',
              content: (e) => (e.value.length > 22 ? e.value.substr(0, 22) + '...' : e.value),
            }}
            max={feedableData.length > 25 ? 25 : feedableData.length}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem width={0.1} data={feedableData} categoryField="name" />
        </ChartSeries>
      </Chart>
    </Widget>
  );
};

export default PnlByInstruments;
