import React from 'react';
import s from '../shared.module.scss';
import { PnlReport } from '../../../../../../../../../components/PnlReport';
import { IPortfolioInfoState } from '../../../../../../../../../services/reducers/PortfolioReducers';
import useApi from '../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../services/constants/endpoints';
import { Loader } from '../../../../../../../../../components';
import { getPortfolioBreakdownReport } from '../../Equilty/utils/getPortfolioBreakdownReport';
import { useCustodiansFilter } from '../../../../../../../../../services/hooks/useCustodiansFilter';

interface IProps {
  portfolioInfo: IPortfolioInfoState;
  portfolioId: string;
  assetClass: string;
  incomeLabel?: string;
}

const PnlBreakdown = ({ portfolioId, portfolioInfo, assetClass, incomeLabel = 'Income' }: IProps) => {
  const { custodianOptions } = useCustodiansFilter();

  const breakdownReport = useApi(getApiUrl('portfolio.profit.breakdown'), {
    method: 'GET',
    params: {
      portfolioId,
      assetClass,
    },
  });

  if (breakdownReport.isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ marginBottom: 80 }}>
      <div className={s.equityContainer}>
        <PnlReport
          title={`${assetClass === 'FixedIncome' ? 'Fixed Income' : assetClass} P&L Report`}
          report={getPortfolioBreakdownReport(breakdownReport.data)}
          portfolioInfo={portfolioInfo}
          custodians={custodianOptions}
          classId={assetClass}
          incomeLabel={incomeLabel}
        />
      </div>
    </div>
  );
};

export default PnlBreakdown;
