import { Widget } from '../../../../../../../components';
import s from '../../../Dashboard.module.scss';
import { DASHBOARD_PORTFOLIO_INCOME_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import ReactEcharts from 'echarts-for-react';
import React, { createRef } from 'react';
import { getIncomeChartOptions } from '../../../../../../Reports/reportsChartOptions';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import useBarDataToggableLegend from '../../../../../../../../../services/hooks/useBarDataToggableLegend';
import { ToggableChartLegend } from '../../../../../../../../../components/ToggableChartLegend';

interface IProps {
  incomeData: IBarData[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency: string;
  incomePeriod: IPeriod;
}

const NextYearIncomeChart = ({ incomeData, portfolioCurrencyFormatter, selectedCurrency }: IProps) => {
  const eChartsRef = createRef<any>();
  const { toggledData, legend, onLegendItemClick } = useBarDataToggableLegend({
    chartData: incomeData,
    portfolioCurrencyFormatter,
    selectedCurrency,
  });
  const incomePastOpt = getIncomeChartOptions(toggledData, portfolioCurrencyFormatter, selectedCurrency);

  return (
    <Widget
      title="Estimated Income Next 12 months"
      colNum={9}
      titleInfo={DASHBOARD_PORTFOLIO_INCOME_HELP_TEXT.ChartEstimatedIncomeNextYear}
    >
      <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
      <ReactEcharts
        ref={eChartsRef}
        style={{ height: '420px', width: '100%', flexGrow: '1' }}
        option={incomePastOpt}
        notMerge={true}
        lazyUpdate={true}
      />
    </Widget>
  );
};
export default NextYearIncomeChart;
