import React from 'react';
import cn from 'classnames';
import s from '../../UniversalUpload.module.css';
import Toggle from '../../../../../../../../components/Reusable/Toggle/Toggle';
import SimpleDropDown from '../../../../../../../../components/Reusable/SimpleDropDown/SimpleDropDown';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';
import useCustodiansOption from '../../../../../../../../services/hooks/useCustodiansOption';

interface IOwnProps {
  portfolioInfo: IRootState['portfolio']['portfolioInfo']['data'];
  changeSettlementCurrency(type: 'BASE' | 'LOCAL'): (value: SettlementOption) => void;
  baseCurrencySettlement: SettlementOption;
  localCurrencySettlement: SettlementOption;
  custodian: IOption<ICustodian> | null;
  setCustodian: React.Dispatch<React.SetStateAction<IOption<ICustodian> | null>>;
}

export const UploadSettings = ({
  portfolioInfo,
  changeSettlementCurrency,
  baseCurrencySettlement,
  localCurrencySettlement,
  custodian,
  setCustodian,
}: IOwnProps) => {
  const { custodians } = useCustodiansOption();
  return (
    <div>
      <div className={cn(s.settingsArea)}>
        <div className={cn(s.settingsRow)}>
          <span>Settle trades for instruments matching portfolio currency: </span>
          <div className={cn(s.toggleSurround)}>
            <Toggle
              values={[
                { text: portfolioInfo?.currency.name ?? 'Base', value: 'BASE' },
                {
                  text: 'Transfer',
                  value: 'AUTO',
                },
              ]}
              onChange={changeSettlementCurrency('BASE')}
              selectedValue={baseCurrencySettlement}
            />
          </div>
        </div>
        <div className={cn(s.settingsRow)}>
          <span>Settle trades for instruments in other currencies: </span>
          <div className={cn(s.toggleSurround)}>
            <Toggle
              values={[
                { text: portfolioInfo?.currency.name ?? 'Base', value: 'BASE' },
                {
                  text: 'Local',
                  value: 'LOCAL',
                },
                {
                  text: 'Transfer',
                  value: 'AUTO',
                },
              ]}
              onChange={changeSettlementCurrency('LOCAL')}
              selectedValue={localCurrencySettlement}
            />
          </div>
        </div>
        <div className={cn(s.settingsRow)}>
          <span>Default Custodian</span>
          <SimpleDropDown
            items={custodians}
            onSelect={({ selectedItem }) => setCustodian(selectedItem ?? null)}
            selectedItem={custodian ?? null}
          />
          <AddCustodianButton />
        </div>
      </div>
    </div>
  );
};
