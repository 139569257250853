import React, { CSSProperties, FC } from 'react';
import { IOption } from '../../UIWidgets/Autocomplete';
import { ClickOutside } from '../../ClickOutside';
import cn from 'classnames';
import s from '../../UIWidgets/DropDown/DropDown.module.scss';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '../../../transitions/fade.module.css';
import { Portal } from '../../Portal';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type SimpleDropDownProps<T> = {
  items: Array<IOption<T>>;
  selectedItem: IOption<T> | null;
  backgroundColor?: string;
  foreColor?: string;
  onSelect(item: { selectedItem?: IOption<T> | null }): void;
  disabled?: boolean;
  className?: string;
  dropDownClass?: string;
};

const SimpleDropDown = <T,>({
  items,
  onSelect,
  selectedItem,
  backgroundColor = 'transparent',
  foreColor,
  disabled,
  className,
  dropDownClass,
}: SimpleDropDownProps<T>): JSX.Element => {
  const [showContent, setShowContent] = React.useState(false);
  const contentRef = React.useRef(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [dropStyle, setDropStyle] = React.useState<CSSProperties>({});

  const handleSelect = (item: IOption<T>) => {
    onSelect({ selectedItem: item });
    setShowContent(false);
  };

  const show = () => {
    if (!wrapperRef.current) {
      return;
    }
    if (showContent) {
      setShowContent(false);
      return;
    }
    const wrapperCoords: ClientRect = wrapperRef.current.getBoundingClientRect();

    setDropStyle({
      top: wrapperCoords.bottom,
      left: wrapperCoords.left,
      width: wrapperCoords.width,
    });
    setShowContent(true);
  };

  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={wrapperRef}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <ClickOutside clickOutside={() => setShowContent(false)}>
        {/* Placeholder content */}
        <div
          onClick={() => !disabled && show()}
          style={{ width: '100%', display: 'flex', alignItems: 'center', backgroundColor }}
        >
          <button
            type="button"
            disabled={disabled}
            onClick={() => setShowContent(true)}
            style={{
              flex: 1,
              textAlign: 'left',
              backgroundColor: 'transparent',
              paddingTop: 6,
              paddingBottom: 6,
              overflow: 'hidden',
              color: foreColor,
            }}
          >
            {selectedItem?.name || `${items.length} Found`}
          </button>
          {items.length > 1 && (
            <FontAwesomeIcon color={'var(--darkgray)'} icon={faChevronDown} style={{ marginLeft: 20 }} />
          )}
        </div>
      </ClickOutside>

      {/* Dropdown content */}
      <CSSTransition in={showContent} classNames={fadeTransition} timeout={200} unmountOnExit={true}>
        <Portal target={undefined}>
          <div ref={contentRef} className={cn(s.dropContent, dropDownClass)} style={dropStyle}>
            {items.map((item, index) => (
              <div
                className={cn(s.row, index % 2 === 0 && s.alternate)}
                onClick={() => handleSelect(item)}
                key={`${item}${index}`}
              >
                {item.name}
              </div>
            ))}
          </div>
        </Portal>
      </CSSTransition>
    </div>
  );
};

export default SimpleDropDown;
