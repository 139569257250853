import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Period } from '../../../../components/Period';

import { DashboardActions } from '../../../../services/actions';
import { CHARTS_PERIODS } from '../../../../services/constants/constants';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { IRootState } from '../../../../services/store';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import { ProfitChart } from '../ProfitChart';
import { Widget } from '../WidgetsBlock/Widget';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';

interface IMapStateToProps {
  profitChartData: IBarData[];
  selectedPeriod: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  onPeriodChange: (period: IPeriod) => void;
}

type IProps = IMapStateToProps &
  IDispatchToProps &
  Pick<IWidgetProps, 'colNum' | 'title' | 'full'> & { hidePeriodSelector?: boolean; legendVariant?: string };

class ProfitAttributionWidgetContainer extends PureComponent<IProps> {
  renderPerformancePeriod = (): ReactNode => {
    const { selectedPeriod, onPeriodChange } = this.props;
    return this.props.hidePeriodSelector ? null : (
      <Period options={CHARTS_PERIODS} selectedPeriod={selectedPeriod} onPeriodChange={onPeriodChange} />
    );
  };

  render() {
    const { profitChartData, colNum, portfolioCurrencyFormatter } = this.props;
    return (
      <Widget colNum={colNum} renderHeaderTools={this.renderPerformancePeriod}>
        <ProfitChart
          isWaterFall={true}
          profitChartData={profitChartData}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          legendVariant={this.props.legendVariant}
        />
      </Widget>
    );
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    profitChartData: state.dashboard.profitAttribution.data,
    selectedPeriod: state.dashboard.profitAttribution.period,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  onPeriodChange: (period: IPeriod) => dispatch(DashboardActions.setProfitAttributionPeriod(period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitAttributionWidgetContainer);
