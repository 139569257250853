import to from 'await-to-js';
import { AxiosPromise } from 'axios';
import cn from 'classnames';
import s from '../../../components/DynamicForm/DynamicForm.module.css'; // TODO: Change to use own stylesheet
import dateFormat from 'dateformat';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import StageIndicator from '../../../components/StageIndicator/StageIndicator';
import { IRealAsset } from '../../../models/IRealAsset';
import { RealAssetActions } from '../../../services/actions/RealAssetActions';
import { IRootState } from '../../../services/store';
import { makeDateString } from '../../../services/utils/DateHelpers';
import { getFormGroups } from '../../../services/utils/getFormGroups';
import { sortPricesByCode, sortPricesByDate } from '../../../services/utils/PriceHelpers';
import api from '../../../services/utils/RealAssetsApi';
import { AsyncActionDispatch } from '../../../services/utils/ReduxHelper';
import UnlistedInstrumentModifyForm from './Form/UnlistedModifyForm';
import PriceEntryContainer from './PriceEntry/PriceEntryContainer';
import UnlistedReview from './Review/UnlistedReview';
import { toast } from 'react-toastify';

interface IProps {
  assetClassId: AssetClassId;
  // mode: 'add' | 'edit' | 'list';
  instrumentId?: string;
}

interface IMapProps {
  assetsSubClasses: IAssetSubClass[];
  currencyComboItems: IComboItem[];
  countryComboItems: IComboItem[];
  realAssets: IRealAsset[] | null;
}

interface IDispatchToProps {
  fetchRealAssets: () => AxiosPromise<IRealAsset[]>;
}

type Props = IProps & IMapProps & IDispatchToProps;

enum STAGES {
  HEADER,
  PRICE,
  INCOME,
  REVIEW,
  SUCCESS,
}

const DEFAULT_VALUES: any = {
  // assetSubClass: 'RaAircraft',
  // name: 'New Asset',
  // code: `Asset ${Math.floor(Math.random() * 999999)}`,
  // status: 'ACTIVE',
  // valuationDate: '12/2/2020',
  // currency: 'GBP',
  // price: 123,
};
const DEFAULT_PRICES: IPrice[] = makeDefaultPrices();
const DEFAULT_INCOME: IPrice[] = makeDefaultPrices();
function makeDefaultPrices(num: number = 5) {
  return Array.from({ length: num }).map(() => ({ code: uuid() }));
}

const UnlistedModifyContainer: FunctionComponent<Props> = ({
  assetsSubClasses,
  currencyComboItems,
  countryComboItems,
  fetchRealAssets,
  realAssets,
}) => {
  const { params, path } = useRouteMatch<{ instrumentId?: string; assetClassId?: AssetClassId }>();
  const { instrumentId = '', assetClassId } = params;
  const mode = path.split('/')?.[5] as 'edit' | 'add';

  const [stage, setStage] = useState(0);
  const [prices, setPrices] = useState<IPrice[]>(DEFAULT_PRICES);
  const [income, setIncome] = useState<IPrice[]>(DEFAULT_INCOME);
  const [existingPrices, setExistingPrices] = useState<IPrice[]>([]);
  const [defaultValues, setDefaultValues] = useState({
    ...DEFAULT_VALUES,
    ...(assetClassId === 'RealEstate' ? { assetSubClass: 'RePhysical' } : {}),
  });
  const [selectedUniqueIdType, setSelectedUniqueIdType] = React.useState<string | undefined>();

  const formContext = useForm();
  const { handleSubmit, getValues, triggerValidation, watch, setError, clearError, formState } = formContext;

  const assetSubClassesComboItems = assetsSubClasses
    .filter((subClass) => subClass.assetClassId === assetClassId)
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort(sortComboItems);

  const groups: FormGroup[] = React.useCallback(
    () =>
      getFormGroups(assetClassId!, {
        mode,
        assetSubClassesComboItems,
        currencyComboItems,
        countryComboItems,
        selectedUniqueIdType,
        setSelectedUniqueIdType,
        realAssets,
      }),
    [
      mode,
      assetSubClassesComboItems,
      currencyComboItems,
      countryComboItems,
      selectedUniqueIdType,
      realAssets,
      assetClassId,
    ]
  )();

  /* Delay rendering if we're editing and need to get the existing values **/
  const [ready, setReady] = useState<boolean>(mode !== 'edit');

  React.useEffect(() => {
    // call action to fetch unique ids
    fetchRealAssets();
  }, []);

  /* Get current instrument values and prices, if in edit mode **/
  React.useEffect(() => {
    (async () => {
      if (mode === 'edit') {
        try {
          const instrumentResult = await api.instruments.get(instrumentId)();
          setDefaultValues(instrumentResult.data);
          setReady(true);

          const newIncome: IPrice[] =
            instrumentResult?.data?.dividends
              ?.sort((a, b) => a.date.localeCompare(b.date))
              .map((item) => ({ code: item.date, valuationDate: new Date(item.date), price: item.amount })) || [];
          setIncome(newIncome);

          const pricesResult = await api.instruments.prices.get(instrumentId);
          const newPricesRaw: any = pricesResult?.data?.prices;
          if (Array.isArray(newPricesRaw) && newPricesRaw.length > 0) {
            const newPrices: IPrice[] = newPricesRaw
              .map(({ valuationDate, price }) => ({
                valuationDate,
                price,
                code: `${valuationDate.toLocaleString()}`,
              }))
              .sort(sortPricesByDate);
            setPrices(newPrices);
            setExistingPrices(newPrices);
          }
        } catch (error) {
          // console.log({ error });
        }
      }
    })();
  }, [mode, instrumentId]);

  React.useEffect(() => {
    // No need to do this if editing, as changing the code is disabled
    if (!!watch()?.code && mode !== 'edit') {
      const { code } = watch();
      const exists = (existingAssetCodes?.indexOf(code) ?? -1) > -1;
      if (exists) {
        toast.warn('Code [' + code + '] is already in use, please pick a different unique code.');
        setError('code', 'error');
      } else {
        clearError('code');
      }
    }
  }, [watch()?.code]);

  /* Navigate between the various screens of data entry & validation **/
  const moveStageBack = ({ toStage }: { toStage: number }) => setStage(toStage);
  const moveStageForward = async ({ fromStage, toStage }: { [index: string]: number }) => {
    let canMoveForwards = true;
    switch (fromStage) {
      case STAGES.HEADER:
        canMoveForwards = await triggerValidation();
        break;
    }
    if (canMoveForwards) {
      setStage(toStage);
    }
  };

  /* Remove prices without valid dates or with no value entered **/
  const getFilteredPrices = (values: IPrice[]) =>
    values.filter((value) => typeof value.price !== 'undefined' && typeof value.valuationDate !== 'undefined');

  /* Decide which prices should be created / updated / deleted. Return Promise.All **/
  const savePrices = (code: string): Promise<[any, any[] | undefined]> => {
    const actions: Array<Promise<any>> = [];

    const updated = getFilteredPrices(prices).sort(sortPricesByCode);
    const existing = existingPrices.sort(sortPricesByCode);

    let i = 0;
    let j = 0;
    while (i < updated.length && j < existing.length) {
      if (updated[i].code === existing[j].code) {
        const u = updated[i];
        if (u.valuationDate !== existing[j].valuationDate || u.price !== existing[j].price) {
          const { price, valuationDate } = updated[i];
          actions.push(
            api.prices.update(code)([{ code: updated[i].code, price, valuationDate: makeDateString(valuationDate) }])
          );
        }
        i++;
        j++;
      } else if (updated[i].code > existing[j].code) {
        actions.push(api.prices.delete(code, makeDateString(existing[j].valuationDate)));
        j++;
      } else {
        actions.push(
          api.prices.create(code)([
            {
              ...updated[i],
              valuationDate: dateFormat(updated[i].valuationDate, 'yyyy-mm-dd'),
            },
          ])
        );
        i++;
      }
    }

    while (i < updated.length) {
      actions.push(
        api.prices.create(code)([{ ...updated[i], valuationDate: dateFormat(updated[i].valuationDate, 'yyyy-mm-dd') }])
      );
      i++;
    }
    while (j < existing.length) {
      actions.push(api.prices.delete(code, makeDateString(existing[j++].valuationDate)));
    }
    return to(Promise.all(actions));
  };

  /* Save instrument on first round-trip, then save all prices if successful **/
  const onSubmit = async (values: any) => {
    const method = mode === 'add' ? api.instruments.create : api.instruments.update(instrumentId);
    const dividends = income
      .filter((item) => typeof item.valuationDate !== 'undefined' && typeof item.price !== 'undefined')
      .map((item) => ({ date: dateFormat(item.valuationDate, 'yyyy-mm-dd'), amount: item.price }));
    const [submitError, submitResult] = await to(
      method({
        assetClass: assetClassId,
        acquisitionCurrency: values.valuationCurrency,
        ...values,
        dividends,
      })
    );

    // @ts-ignore - axios `status` is expected to be boolean, but is an http status code
    if (submitError || !submitResult || ![200, 201].includes(submitResult.status)) {
      alert('Error! Unable to save');
    } else {
      const code = submitResult?.data?.id ?? '{}';
      let priceError: any = false;

      if (Array.isArray(prices) && prices.length > 0) {
        [priceError] = await savePrices(code);
      }
      if (!priceError) {
        setStage(STAGES.SUCCESS);
        fetchRealAssets();
      }
    }
  };

  const noPrices =
    (stage === 1 && prices && (!prices[0].hasOwnProperty('price') || !prices[0].hasOwnProperty('valuationDate'))) ||
    // tslint:disable-next-line: use-isnan
    (stage === 1 && prices && prices[0].hasOwnProperty('price') && isNaN(prices[0].price as number));

  const existingAssetCodes = realAssets?.length ? realAssets.map((element) => element.code) : undefined;

  if (!ready) {
    return (
      <React.Fragment>
        <h1>Loading...</h1>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h1 className={cn(s.title)}>{mode === 'edit' ? 'Edit instrument' : 'Create new instrument'}</h1>
      <StageIndicator
        onPressNext={moveStageForward}
        disableNext={noPrices || false}
        onPressPrevious={moveStageBack}
        currentStage={stage}
        stages={[{ name: 'Add header info' }, { name: 'Input prices' }, { name: 'Add income' }, { name: 'Review' }]}
      />
      <Hidable hidden={stage !== STAGES.HEADER}>
        <UnlistedInstrumentModifyForm
          {...{
            onSubmit: () => setStage(STAGES.HEADER + 1),
            defaultValues,
            assetClassId: assetClassId!,
            formContext,
            mode,
            groups,
          }}
        />
      </Hidable>
      <Hidable hidden={stage !== STAGES.PRICE}>
        <PriceEntryContainer {...{ prices, setPrices }} />
      </Hidable>

      <Hidable hidden={stage !== STAGES.INCOME}>
        <PriceEntryContainer
          dateLabel={'Income date'}
          priceLabel={'Amount'}
          {...{ prices: income, setPrices: setIncome }}
        />
      </Hidable>

      <br />
      <Hidable hidden={stage !== STAGES.REVIEW}>
        <UnlistedReview
          prices={getFilteredPrices(prices)}
          income={getFilteredPrices(income)}
          values={getValues()}
          groups={groups}
        />
        <button type="submit" className={'submit'} onClick={handleSubmit(onSubmit)}>
          Save
        </button>
      </Hidable>
      <Hidable hidden={stage !== STAGES.SUCCESS}>
        <h1>Success!</h1>
      </Hidable>
    </React.Fragment>
  );
};

const Hidable = ({ hidden, children }: { hidden: boolean; children: React.ReactNode }) => {
  const styles: CSSProperties = {};
  if (hidden) {
    styles.display = 'none';
  }

  return <div style={{ ...styles }}>{children}</div>;
};

const sortComboItems = (a: IComboItem, b: IComboItem) =>
  String(a.label ?? a.value).localeCompare(String(b.label ?? b.value));

const mapStateToProps = (state: IRootState, ownProps: IProps) => {
  // console.log({ ownProps });
  return {
    assetsSubClasses: state.assetsSubClasses.data,
    currencyComboItems: state.currency.data
      .map(({ name, symbol }) => ({
        value: name,
        label: `${name} (${symbol})`,
      }))
      .sort(sortComboItems),
    countryComboItems: state.country.data
      .map(({ alpha2code, countryName }: any) => ({
        value: alpha2code,
        label: `${alpha2code}: ${countryName}`,
      }))
      .sort(sortComboItems),
    realAssets: state.realAssets.data,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchRealAssets: () => dispatch(RealAssetActions.fetchRealAssetsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlistedModifyContainer);
