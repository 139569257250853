import cn from 'classnames';
import React, { FC, PureComponent, ReactNode } from 'react';
import { Button } from '../Button';
import s from './ConfirmWithTextInput.module.scss';
import { Input } from 'rsuite';

export type IConfirmWithTextOptions = IProps;

interface IProps {
  title?: string;
  text?: string;
  confirmBtnText?: string;
  defaultValue?: string;
  onConfirm?: (val: any) => void;
  onCancel?: () => void;
  info?: boolean;
  children?: ReactNode;
}

const ConfirmWithTextInput: FC<IProps> = ({
  confirmBtnText = 'Confirm',
  title = 'Are you sure?',
  text = '',
  onCancel,
  onConfirm,
  info,
  defaultValue,
}) => {
  const confirmRef = React.useRef<HTMLDivElement>(null);
  const [val, setVal] = React.useState(defaultValue || '');

  React.useEffect(() => {
    confirmRef.current?.focus();
  }, [confirmRef.current]);

  const handleSubmit = () => {
    if (val.length) {
      onConfirm!(val.trim());
    }
  };

  const onValueChange = (v: string) => {
    if (v.length < 50) {
      setVal(v);
    }
  };

  return (
    <div className={s.confirmWrapper}>
      <div className={s.confirmWindow}>
        <div className={s.confirmWindowTitle}>{title}</div>
        <div className={cn('text-xs', s.confirmWindowText)} dangerouslySetInnerHTML={{ __html: text }} />

        <div className={s.childrenContainer}>
          <Input className={s.textInput} type={'text'} value={val} onChange={onValueChange} />
        </div>

        <footer className={s.confirmWindowFooter}>
          {onCancel && (
            <Button variant="outline" className={s.cancelBtn} size="small" onClick={onCancel} active={false}>
              Cancel
            </Button>
          )}
          {onConfirm && (
            <div ref={confirmRef}>
              <Button className={cn(s.confirmBtn, { [s.infoBtn]: info })} size="small" onClick={handleSubmit}>
                {confirmBtnText}
              </Button>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

export default React.memo(ConfirmWithTextInput);
