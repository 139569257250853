import cn from 'classnames';
import React, { PureComponent } from 'react';
import { Button } from '../../../../../../components/UIWidgets';
import { IBreadcrumb } from '../../../../../../components/UIWidgets/Breadcrumbs/model';

import { IPortfolioValidatedTrade } from '../../../../../../models/IPortfolioTrade';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { EditTradesTableContainer, UploadTransactionContainer } from './components';
import s from './ShapshotEdit.module.scss';
import AddTransactionContainer from './components/AddTransaction/AddTransactionContainer';
import EditTransactionContainer from '../../components/EditTransaction/EditTransactionContainer';
import { IRootState } from '../../../../../../services/store';
import { Link, generatePath } from 'react-router-dom';
import { PATHS } from '../../../../../../router/paths';
import { PortfolioHeader } from '../../../../components';
import { FiLink } from 'react-icons/all';
import { RouteComponentProps, withRouter } from 'react-router';
import { ConnectedRouterProps } from 'connected-react-router';
import { useIframeMode } from '../../../../../../services/hooks/useIframeMode';

const tradeMode = {
  EDIT: 'EDIT',
  ADD: 'ADD',
  UPLOAD: 'UPLOAD',
};

interface IProps {
  trades: IPortfolioValidatedTrade[];
  tradesOrder: IOrder;
  // onConfirm: (delta: number | undefined) => void;
  // onDiscard: () => void;
  onErrorsSort: (value: boolean) => void;
  isEditingTrade: boolean;
  editedUnconfirmedTrade: IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];
  history: any;
  breadCrumbs: IBreadcrumb[];
  delta: number | undefined;
  totalTradeCost: number | undefined;
  portfolioCurrencyFormatter: ICurrencyFormatter;

  portfolioId?: string;
  resetEditTrade(): void;
}

interface IState {
  mode: string | null;
  hideConfirmModal: boolean;
  selectedOption: number;
  subscriptionValue: string;
}

type OwnProps = IProps & RouteComponentProps;
class SnapshotEdit extends PureComponent<OwnProps, IState> {
  readonly state: IState = {
    mode: tradeMode.ADD,
    hideConfirmModal: true,
    selectedOption: 0,
    subscriptionValue: '',
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { isEditingTrade } = this.props;

    if (
      isEditingTrade !== prevProps.isEditingTrade ||
      (this.props.editedUnconfirmedTrade?.trade?.key &&
        this.props.editedUnconfirmedTrade?.trade?.key !== prevProps.editedUnconfirmedTrade?.trade?.key)
    ) {
      if (isEditingTrade) {
        return this.setMode(tradeMode.EDIT);
      }
      return this.setMode(tradeMode.ADD);
    }
  }

  renderModes = () => {
    const { isEditingTrade } = this.props;
    // const mode = this.state.mode;
    const mode =
      this.props.editedUnconfirmedTrade?.trade?.key && this.state.mode !== tradeMode.UPLOAD
        ? tradeMode.EDIT
        : this.state.mode;

    switch (mode) {
      case tradeMode.EDIT:
        return (
          isEditingTrade && (
            <div className={s.modesWrapper}>
              <EditTransactionContainer />
            </div>
          )
        );
      case tradeMode.ADD:
        return (
          <div className={s.modesWrapper}>
            <AddTransactionContainer />
          </div>
        );
      case tradeMode.UPLOAD:
        return (
          <div className={s.modesWrapper}>
            <UploadTransactionContainer />
          </div>
        );
      default:
        return null;
    }
  };

  setMode = (mode: string | null) => {
    this.setState({ mode });
  };

  render() {
    const { mode } = this.state;
    const { trades, breadCrumbs, portfolioId, history } = this.props;
    const iframeMode = history?.location?.search?.includes('iframe-mode=true');
    const errorTradesLength = trades.filter(
      (it) => it.errors !== null && it.errors.filter((error) => error.status !== 'INFO').length > 0
    ).length;
    const okTradesLength = trades.length - errorTradesLength;

    const importLink = () => {
      if (iframeMode) {
        return PATHS.portfolio.snapshot.upload.path + '?iframe-mode=true';
      }
      return PATHS.portfolio.snapshot.upload.path;
    };
    return (
      <div>
        {/*{!this.state.hideConfirmModal && this.renderConfirmModal(okTradesLength)}*/}
        <PortfolioHeader title={''} showPortfolioInfo={true} showDashboardMenu={false}>
          <div style={{ display: 'flex', height: 35 }}>
            {!iframeMode && (
              <Button
                className={cn(s.titleBtnWide, s.titleBtnOffset)}
                size="small"
                variant="outline"
                onClick={() => {
                  this.props.resetEditTrade();
                  this.setMode(tradeMode.ADD);
                }}
                active={mode === tradeMode.ADD}
              >
                <i className={cn(s.icon, s.add)} />
                Add new
              </Button>
            )}
            <Link to={generatePath(importLink(), { portfolioId })}>
              <Button
                className={cn(s.titleBtnWide, s.btnContainerOffset)}
                style={{ marginRight: 0 }}
                size="small"
                variant="outline"
                active={mode === tradeMode.UPLOAD}
              >
                <i className={cn(s.icon, s.import)} />
                Import
              </Button>
            </Link>
            {!iframeMode && (
              <Link to={generatePath(PATHS.portfolio.snapshot.positions.add.path, { portfolioId })}>
                <Button
                  className={cn(s.titleBtnWide, s.btnContainerOffset)}
                  style={{ marginRight: 0 }}
                  size="small"
                  variant="outline"
                  active={mode === tradeMode.UPLOAD}
                >
                  <i className={cn(s.icon, s.add)} />
                  Add Positions
                </Button>
              </Link>
            )}
            {!iframeMode && (
              <Link
                to={{
                  pathname: '/administration/manage-account/OAuthManagement',
                  state: { prevPath: this.props.location.pathname },
                }}
              >
                <Button
                  active={false}
                  className={cn(s.titleBtnWide, s.btnContainerOffset)}
                  size="small"
                  variant="outline"
                >
                  <FiLink className={cn(s.icon)} />
                  Connect Broker
                </Button>
              </Link>
            )}
          </div>
        </PortfolioHeader>
        {mode && this.renderModes()}
        {trades.length > 0 && (
          <div className={s.tableWrapper}>
            <EditTradesTableContainer />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SnapshotEdit);
