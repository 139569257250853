import { combineReducers } from 'redux';

import { IActionPayload } from '../../models/redux/IActionPayload';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { AppActions } from '../actions/AppActions';
import { ReduxHelper } from '../utils/ReduxHelper';
import accountReducer from './AccountReducers';
import subscriptionProductsReducer from './SubscriptionProductReducer';

type IUserState = ILoadingDataState<IAuthInfo | null>;

export interface IAppState {
  authInfo: IUserState;
  appNotification: INotification | null;
  contextHelp: IContextHelpToggle | null;
  account: AuthState;
  availableProducts: SubscriptionProduct[];
}

const INITIAL_AUTH_STATE: IUserState = ReduxHelper.createInitialState(null);

const authInfoDefault = ReduxHelper.createHttpRequestReducer<IUserState>(
  INITIAL_AUTH_STATE,
  AppActions.LOGIN_REQUEST,
  AppActions.LOGIN_SUCCESS,
  AppActions.LOGIN_ERROR,
  AppActions.LOGOUT_SUCCESS
);

const authInfoReducers = (state: IUserState = INITIAL_AUTH_STATE, action: IActionPayload): IUserState => {
  switch (action.type) {
    case AppActions.UPDATE_ACCOUNT_INFO_SUCCESS:
      return { ...state, data: { ...state.data, ...action.payload.data } };
    default:
      return authInfoDefault(state, action);
  }
};

const appNotificationReducers = (state: INotification | null = null, action: IActionPayload): INotification | null => {
  switch (action.type) {
    case AppActions.SET_NOTIFICATION:
      return { ...state, ...action.payload };
    case AppActions.CLEAR_NOTIFICATION:
      return null;
    default:
      return state;
  }
};

const toggleContextHelpReducers = (
  state: IContextHelpToggle | any = { contextHelp: true },
  action: IActionPayload
): IContextHelpToggle | null => {
  switch (action.type) {
    case AppActions.TOGGLE_CONTEXT_HELP:
      if (state.contextHelp === null) {
        return Object.assign({}, state, { contextHelp: false });
      } else {
        return Object.assign({}, state, { contextHelp: !state.contextHelp });
      }
    default:
      return state;
  }
};

const appReducers = combineReducers<IAppState>({
  authInfo: authInfoReducers,
  appNotification: appNotificationReducers,
  account: accountReducer,
  availableProducts: subscriptionProductsReducer,
  contextHelp: toggleContextHelpReducers,
});

export default appReducers;
