import React, { useState } from 'react';

import { IPortfolioInfoState } from '../../services/reducers/PortfolioReducers';
import { Popover, Whisper } from 'rsuite';
import { IOption } from '../UIWidgets/Autocomplete';
import { useCustodiansFilter } from '../../services/hooks/useCustodiansFilter';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { Table } from '@iliotech/storybook/build/components/Table/Table';
import { explorerDecimalNumberRenderer } from '../../scenes/Portfolio/scenes/PortfolioExplorer/utils/explorerCurrencyRenderer';

export interface IProps {
  shorts: IFundamentalsReport[];
  longs: IFundamentalsReport[];
  portfolioInfo: IPortfolioInfoState;
  classId: string;
}

const decimalPercentRenderer = <T,>(rowData: T, dataKey: keyof T) => {
  const newRowData = { ...rowData, [dataKey]: ((rowData[dataKey] as any) as number) * 100 };
  return explorerDecimalNumberRenderer(newRowData, dataKey);
};

const percentRenderer = <T,>(rowData: T, dataKey: keyof T) => {
  return (
    <div style={{ textAlign: 'right' }}>
      {typeof rowData[dataKey] === 'number'
        ? (((rowData[dataKey] as any) as number) * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })
        : rowData[dataKey]}
    </div>
  );
};

const FundamentalsReport = ({ shorts, longs, portfolioInfo, classId }: IProps) => {
  {
    const { custodianOptions } = useCustodiansFilter();

    const [selectedCustodian, setSelectedCustodian] = useState<IOption<any> | null>(null);

    const filteredReport = React.useMemo(() => {
      const longHeader = longs?.[0];
      const shortHeader = shorts?.[0];

      return [
        ...(longHeader
          ? [{ ...longHeader, name: 'Weighted Average', code: 'Long', earningsPerShare: '', marketCapBn: '' }]
          : []),
        ...(shortHeader
          ? [
              {
                ...shortHeader,
                name: 'Weighted Average',
                code: 'Short',
                exposure: shortHeader.exposure,
                earningsPerShare: '',
                marketCapBn: '',
              },
            ]
          : []),
        ...longs?.slice(1).filter((item) => !selectedCustodian || selectedCustodian.name === item.custodian),
        ...shorts?.slice(1).filter((item) => !selectedCustodian || selectedCustodian.name === item.custodian),
      ];
    }, [longs, shorts, selectedCustodian]);

    const columns: ColumnProps[] = [
      {
        dataKey: 'code',
        label: 'Instrument',
        width: 280,
        flexGrow: 3,
        tooltip: <div>Asset Name</div>,
        fixed: true,
        // 'Asset name',
        cellRenderer: (rowData: IFundamentalsReport, dataKey: any) => (
          <Whisper
            speaker={
              <Popover>
                <div>
                  {rowData.name} [{rowData.code}]
                </div>
              </Popover>
            }
          >
            <div style={{ color: rowData.exposure < 0 ? 'var(--light-blue)' : undefined }}>
              {rowData.name} [{rowData.code}]
            </div>
          </Whisper>
        ),
      },

      {
        dataKey: 'exposure',
        // aggregate: {type: "sum"},
        flexGrow: 1,
        label: `${portfolioInfo.data?.currency?.name} Exposure`,
        tooltip: 'Total exposure to the asset',
        align: 'right',
        cellRenderer: (rowData: IFundamentalsReport, dataKey: any) => (
          <div
            style={{
              textAlign: 'right',
              color: rowData.exposure < 0 ? 'var(--light-blue)' : undefined,
            }}
          >
            {rowData.exposure?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          </div>
        ),
      },
      {
        dataKey: 'priceEarningsRatio',
        // aggregate: {type: "sum"},
        flexGrow: 1,
        label: 'PE Ratio',
        align: 'right',
        cellRenderer: explorerDecimalNumberRenderer,
        tooltip: "The price-earnings ratio (P/E ratio) relates a company's share price to its earnings per share",
      },
      {
        dataKey: 'priceGrowthRatio',
        flexGrow: 1,
        label: 'PEG Ratio',
        align: 'right',
        cellRenderer: explorerDecimalNumberRenderer,
        tooltip: 'The PEG ratio enhances the P/E ratio by adding in expected earnings growth into the calculation',
      },
      {
        dataKey: 'earningsPerShare',
        flexGrow: 1,
        label: 'EPS',
        align: 'right',
        cellRenderer: explorerDecimalNumberRenderer,
        tooltip:
          "Earnings per share (EPS) is a company's net profit divided by the number of common shares it has outstanding",
        // cellRenderer: ((rowData: any, rowKey: any) => {
        //   if(rowData?.name === "Weighted Average"){
        //
        //   }
        //   return <div>{rowData?.name}</div>
        // })
      },
      {
        dataKey: 'returnOnEquityTtm',
        flexGrow: 1,
        label: 'RoE (%)',
        align: 'right',
        tooltip:
          'ROE is considered a measure of how effectively management is using a company’s assets to create profits',
        cellRenderer: percentRenderer,
      },
      {
        dataKey: 'dividendYield',
        flexGrow: 1,
        label: 'Dividend Yield (%)',
        align: 'right',
        tooltip:
          'The dividend yield–displayed as a percentage is the amount of money a company pays shareholders for owning a share of its stock divided by its current stock price',
        cellRenderer: decimalPercentRenderer,
      },
      {
        dataKey: 'priceSalesTtm',
        flexGrow: 1,
        label: 'Price to Sales',
        align: 'right',
        cellRenderer: explorerDecimalNumberRenderer,
        tooltip:
          'The price-to-sales (P/S) ratio shows how much investors are willing to pay per dollar of sales for a stock',
      },
      {
        dataKey: 'priceBookMrq',
        flexGrow: 1,
        align: 'right',
        label: 'Price to Book',
        tooltip: "The P/B ratio measures the market's valuation of a company relative to its book value",
        cellRenderer: explorerDecimalNumberRenderer,
      },
      {
        dataKey: 'marketCapBn',
        // flexGrow: 1,
        width: 150,
        label: `Market Cap (${portfolioInfo.data?.currency.symbol}B)`,
        align: 'right',
        cellRenderer: explorerDecimalNumberRenderer,
        tooltip: 'Total amount of traded equity, measured in Billion USDs',
      },
    ].map((c: any) =>
      typeof c.cellRenderer === 'function'
        ? c
        : ({
            ...c,
            align: 'right',
            cellRenderer: (rowData: any, dataKey: any) => (
              <div
                style={{
                  textAlign: 'right',
                  color: rowData.exposure < 0 ? 'var(--light-blue)' : undefined,
                }}
              >
                {rowData[dataKey]?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </div>
            ),
          } as ColumnProps)
    );

    return (
      <div style={{ width: '100%' }}>
        <Table columns={columns} data={filteredReport} style={{ width: '100%' }} headerHeight={40} rowHeight={30} />
      </div>
    );
  }
};

export default FundamentalsReport;
