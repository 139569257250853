import moment from 'moment';
import React, { Fragment, FunctionComponent } from 'react';
import { defaultTableCellRenderer, SortDirectionType, TableCellProps } from 'react-virtualized';
import { TableWrapper } from '../../../../../../../../components/UIWidgets/TableWrapper';

import { DATE_FORMAT } from '../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { IncomeActionsCell } from './components';
import { INCOME_COLUMNS } from './incomeTableColumns';

interface IProps {
  incomeData: IIncome[];
  incomeOrder: IOrder;
  onSortChange: (order: IOrder) => void;
  onEditIncome: (income: IIncome) => void;
  onRemoveIncome: (income: IIncome) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IInstrumentTableCellProps extends TableCellProps {
  rowData: IIncome;
}

const IncomeTable: FunctionComponent<IProps> = ({
  incomeData,
  incomeOrder,
  onSortChange,
  onEditIncome,
  onRemoveIncome,
  portfolioCurrencyFormatter,
}) => {
  const cellRenderer = (data: IInstrumentTableCellProps): React.ReactNode => {
    const { dataKey, cellData, rowData } = data;

    switch (dataKey.toLowerCase()) {
      case 'priceaction':
        return <IncomeActionsCell market={rowData} onRemove={onRemoveIncome} onEdit={onEditIncome} />;
      case 'businessdate':
        return <Fragment>{moment(cellData).format(DATE_FORMAT)}</Fragment>;
      case 'amount':
        const amount = cellData
          ? portfolioCurrencyFormatter(Number(cellData), {
              currencyCode: rowData.currency && rowData.currency.name,
              round: 100,
            })
          : 0;
        return <Fragment>{amount}</Fragment>;
      default:
        return defaultTableCellRenderer(data);
    }
  };

  const handleSortChange = (sortBy: string, sortDirection: SortDirectionType) => {
    onSortChange({ name: sortBy, direction: sortDirection });
  };

  return (
    <TableWrapper
      columns={INCOME_COLUMNS}
      tableData={incomeData}
      totalCount={incomeData.length}
      headerHeight={24}
      rowHeight={24}
      autoHeight={true}
      sortBy={incomeOrder.name}
      sortDirection={incomeOrder.direction}
      cellRenderer={cellRenderer}
      onSortData={handleSortChange}
    />
  );
};

export default React.memo(IncomeTable);
