import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Select } from '../../../../../../../components/UIWidgets/Select';
import { IOptionType } from '../../../../../../../components/UIWidgets/Select/Select';
import { IPortfolioTrade } from '../../../../../../../models/IPortfolioTrade';
import { TRANSACTION_TYPES } from '../../../../../../../services/mock/transactionTypes';
import { ICurrencyFormatter } from '../../../../../../../services/selectors/portfolio';
import s from '../../SnapshotTable/components/SnapshotHeader.module.scss';
import { Input } from '../../../../../../../components/UIWidgets/Input';
import { DatePicker } from '../../../../../../../components/UIWidgets/DatePicker';
import moment from 'moment';
import { SYSTEM_EPOCH } from '../../../../../../../services/constants/constants';

interface IFilter {
  label: string;
  options: IOptionType[];
  value: string | undefined;
  onChange(val: string): void;
  minWidth?: number;
}

interface IPeriod {
  from: Date | null;
  to: Date | null;
}

interface IProps {
  trades: IPortfolioTrade[];
  filters: IFilter[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  resetFilters(): void;
  period: IPeriod;
  searchValue: string;
  onSearch: (value: string) => void;
  onPeriodChange: (from: Date | null, to: Date | null) => void;
  inceptionDate: string;
}

const TradeHeader: FunctionComponent<IProps> = ({
  trades,
  // updateFilters,
  portfolioCurrencyFormatter,
  filters,
  period,
  searchValue,
  resetFilters,
  onPeriodChange,
  onSearch,
  inceptionDate,
}) => {
  const [fromPeriod, setFromPeriod] = React.useState<Date | null>(moment(inceptionDate || SYSTEM_EPOCH).toDate());
  const [toPeriod, setToPeriod] = React.useState<Date | null>(new Date());

  const getValues = React.useCallback(() => {
    const positiveReducer = (property: 'netAmountBase') => (acc: number, curr: IPortfolioTrade) => {
      if (
        typeof curr === 'undefined' ||
        typeof curr[property] === 'undefined' ||
        curr.tradeType.id === TRANSACTION_TYPES.subscriptionAndRedemption
      ) {
        return acc;
      }
      return (curr[property] || 0) > 0 ? acc + (curr[property] || 0) : acc;
    };
    const negativeReducer = (property: 'netAmountBase') => (acc: number, curr: IPortfolioTrade) => {
      if (
        typeof curr === 'undefined' ||
        typeof curr[property] === 'undefined' ||
        curr.tradeType.id === TRANSACTION_TYPES.subscriptionAndRedemption
      ) {
        return acc;
      }
      return (curr[property] || 0) < 0 ? acc + (curr[property] || 0) : acc;
    };
    return {
      totalSpent: trades.reduce(negativeReducer('netAmountBase'), 0),
      totalReceive: trades.reduce(positiveReducer('netAmountBase'), 0),
    };
  }, [trades]);

  const applyDateFilter = () => onPeriodChange(fromPeriod, toPeriod);

  const resetDateFilter = () => {
    onPeriodChange(moment(inceptionDate).toDate(), new Date());
    setFromPeriod(moment(inceptionDate || SYSTEM_EPOCH).toDate());
    setToPeriod(new Date());
  };

  const { totalSpent, totalReceive } = getValues();

  return (
    <div data-testid={'trade-header'} className={cn(s.surround)}>
      {/* Filters */}
      <div className={cn(s.filterSurround)}>
        <div className={cn(s.dropdownSurround)}>
          <span className={cn(s.dropdownLabel)}>Instrument Search</span>
          <div className={cn(s.dropdown)}>
            <Input
              value={searchValue}
              placeholder="Search"
              className={cn(s.inputSearch)}
              type="search"
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
        </div>
        <div className={cn(s.dropdownSurround)}>
          <span className={cn(s.dropdownLabel)}>Transactions between</span>
          <div className={cn(s.dropdown)}>
            <DatePicker
              className={cn(s.dropdown, s.dateRangeField, s.picker)}
              placeholder="From"
              value={fromPeriod}
              onChange={({ value }) => setFromPeriod(value)}
              min={moment(inceptionDate).toDate()}
              max={new Date()}
              hideWeekends={false}
            />
            <div style={{ marginTop: '0.2rem' }}>
              <DatePicker
                className={cn(s.dropdown, s.dateRangeField, s.picker)}
                placeholder="To"
                value={toPeriod}
                onChange={({ value }) => setToPeriod(value)}
                min={period.from || moment(inceptionDate).toDate()}
                max={new Date()}
                hideWeekends={false}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={cn(s.dateButton, s.apply)} onClick={applyDateFilter}>
            Apply
          </div>
          <div className={cn(s.dateButton, s.reset)} onClick={resetDateFilter}>
            Reset
          </div>
        </div>
        {filters.map(({ label, onChange, options, value, minWidth }) => (
          <div key={label} className={cn(s.dropdownSurround)}>
            <span className={cn(s.dropdownLabel)}>{label}</span>
            <div className={cn(s.dropdown)} style={{ minWidth: typeof minWidth === 'undefined' ? 100 : minWidth }}>
              <Select
                onChange={onChange}
                options={options}
                selectedValue={options.filter((o) => o.value === value)[0]}
              />
            </div>
          </div>
        ))}

        <div className={cn(s.dropdownSurround)}>
          <span className={cn(s.dropdownLabel)} />
          <div className={cn(s.resetButton)} onClick={resetFilters}>
            Reset Filters
          </div>
        </div>
      </div>
      {/* Heading values */}
      <div style={{ display: 'flex' }}>
        <div className={cn(s.headerSurround)}>
          <div className={cn(s.headerLabel)}>Total Spent:</div>
          <div className={cn(s.headerValue)}>
            {totalSpent === 0 ? totalSpent : portfolioCurrencyFormatter(totalSpent * -1)}
          </div>
        </div>
        <div className={cn(s.headerSurround)}>
          <div className={cn(s.headerLabel)}>Total Received:</div>
          <div className={cn(s.headerValue)}>{portfolioCurrencyFormatter(totalReceive)}</div>
        </div>
      </div>
    </div>
  );
};

export default TradeHeader;
