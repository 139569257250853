import cn from 'classnames';
import React, { PureComponent } from 'react';
import s from './ChartLegend.module.scss';
import { LegendItem } from './components';
import { ILegendItemProps, LegendType } from './components/LegendItem';

interface IProps {
  legend: ILegendItemProps[];
  align?: 'left' | 'right' | 'center';
  type?: LegendType;
}

class ChartLegend extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    type: LegendType.BAR,
    align: 'left',
  };

  render() {
    const { legend, type, align } = this.props;
    const alignClass = align ? s[align] : '';
    return (
      <div className={cn(s.legend, alignClass)}>
        {legend.map((legendItem: ILegendItemProps, idx: number) => (
          <LegendItem key={idx} type={type} {...legendItem} />
        ))}
      </div>
    );
  }
}

export default ChartLegend;
