import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';

export class PortfolioIndexActions {
  static readonly PORTFOLIO_INDEX_REQUEST = 'PORTFOLIO_INDEX_REQUEST';
  static readonly PORTFOLIO_INDEX_SUCCESS = 'PORTFOLIO_INDEX_SUCCESS';
  static readonly PORTFOLIO_INDEX_ERROR = 'PORTFOLIO_INDEX_ERROR';

  static fetchPortfolioIndex() {
    return ReduxHelper.createHttpRequestActionBundle<IIndex[]>(
      {
        url: getApiUrl('portfolio.indexes'),
        method: 'GET',
      },
      PortfolioIndexActions.PORTFOLIO_INDEX_REQUEST,
      PortfolioIndexActions.PORTFOLIO_INDEX_SUCCESS,
      PortfolioIndexActions.PORTFOLIO_INDEX_ERROR
    );
  }
}
