import { Dispatch } from 'redux';
import { ITreeMapData } from '../../components/TreeMap/TreeMap';

import { IRecommendation } from '../../models/IRecommendation';
import { IScenarioBasicConfig } from '../../models/IScenarioBasicConfig';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { ALLOCATION_TYPES } from '../constants/constants';
import { getApiUrl } from '../constants/endpoints';
import { PeriodHelper } from '../utils/PeriodHelper';
import { ReduxHelper } from '../utils/ReduxHelper';

class RecommendationsActions {
  static readonly RESULT_PERFORMANCE_REQUEST = 'RESULT_PERFORMANCE_REQUEST';
  static readonly RESULT_PERFORMANCE_SUCCESS = 'RESULT_PERFORMANCE_SUCCESS';
  static readonly RESULT_PERFORMANCE_ERROR = 'RESULT_PERFORMANCE_ERROR';
  static readonly RESULT_PERFORMANCE_RESET = 'RESULT_PERFORMANCE_RESET';

  static readonly PERSONAL_PERFORMANCE_REQUEST = 'PERSONAL_PERFORMANCE_REQUEST';
  static readonly PERSONAL_PERFORMANCE_SUCCESS = 'PERSONAL_PERFORMANCE_SUCCESS';
  static readonly PERSONAL_PERFORMANCE_ERROR = 'PERSONAL_PERFORMANCE_ERROR';
  static readonly PERSONAL_PERFORMANCE_RESET = 'PERSONAL_PERFORMANCE_RESET';

  static readonly SET_RESULT_PERFORMANCE_PERIOD = 'SET_RESULT_PERFORMANCE_PERIOD';
  static readonly RESET_RESULT_PERFORMANCE_PERIODS = 'RESET_RESULT_PERFORMANCE_PERIODS';

  static readonly RECOMMENDATIONS_REQUEST = 'RECOMMENDATIONS_REQUEST';
  static readonly RECOMMENDATIONS_SUCCESS = 'RECOMMENDATIONS_SUCCESS';
  static readonly RECOMMENDATIONS_ERROR = 'RECOMMENDATIONS_ERROR';
  static readonly RECOMMENDATIONS_RESET = 'RECOMMENDATIONS_RESET';

  static readonly TOGGLE_RECOMMENDATION = 'TOGGLE_RECOMMENDATION';
  static readonly SELECT_RECOMMENDATION_ASSET_CLASS = 'SELECT_RECOMMENDATION_ASSET_CLASS';
  static readonly RESET_RECOMMENDATION_ASSET_CLASS = 'RESET_RECOMMENDATION_ASSET_CLASS';

  static readonly CLASSES_ALLOCATION_DATA_REQUEST = 'CLASSES_ALLOCATION_REQUEST';
  static readonly CLASSES_ALLOCATION_DATA_SUCCESS = 'CLASSES_ALLOCATION_DATA_SUCCESS';
  static readonly CLASSES_ALLOCATION_DATA_ERROR = 'CLASSES_ALLOCATION_DATA_ERROR';
  static readonly CLASSES_ALLOCATION_DATA_RESET = 'CLASSES_ALLOCATION_DATA_RESET';

  static readonly GEOGRAPHY_ALLOCATION_DATA_REQUEST = 'GEOGRAPHY_ALLOCATION_DATA_REQUEST';
  static readonly GEOGRAPHY_ALLOCATION_DATA_SUCCESS = 'GEOGRAPHY_ALLOCATION_DATA_SUCCESS';
  static readonly GEOGRAPHY_ALLOCATION_DATA_ERROR = 'GEOGRAPHY_ALLOCATION_DATA_ERROR';
  static readonly GEOGRAPHY_ALLOCATION_DATA_RESET = 'GEOGRAPHY_ALLOCATION_DATA_RESET';

  static readonly RECOMMENDATIONS_APPLY_SCENARIO_CONFIG = 'RECOMMENDATIONS_APPLY_SCENARIO_CONFIG';
  static readonly RECOMMENDATIONS_SELECT_HISTORY_EVENT = 'RECOMMENDATIONS_SELECT_HISTORY_EVENT';
  static readonly RECOMMENDATIONS_SELECT_HISTORY_PERIOD = 'RECOMMENDATIONS_SELECT_HISTORY_PERIOD';
  static readonly RECOMMENDATIONS_SCENARIO_RESET = 'RECOMMENDATIONS_SCENARIO_RESET';

  static fetchPersonalPerformance(portfolioId: string, period: IPeriod) {
    return ReduxHelper.createHttpRequestActionBundle<IPerformanceData>(
      {
        url: getApiUrl('recommendations.performance'),
        data: {
          portfolioId,
          period: PeriodHelper.preparePeriodForRequest(period),
        },
      },
      RecommendationsActions.PERSONAL_PERFORMANCE_REQUEST,
      RecommendationsActions.PERSONAL_PERFORMANCE_SUCCESS,
      RecommendationsActions.PERSONAL_PERFORMANCE_ERROR
    );
  }

  static fetchResultPerformance(portfolioId: string, assetsRecommendations: IRecommendation[] = [], period: IPeriod) {
    return ReduxHelper.createHttpRequestActionBundle<IPerformanceData>(
      {
        url: getApiUrl('recommendations.apply'),
        data: {
          portfolioId,
          recommendations: assetsRecommendations,
          period: PeriodHelper.preparePeriodForRequest(period),
        },
      },
      RecommendationsActions.RESULT_PERFORMANCE_REQUEST,
      RecommendationsActions.RESULT_PERFORMANCE_SUCCESS,
      RecommendationsActions.RESULT_PERFORMANCE_ERROR
    );
  }

  static setPerformancePeriod(period: IPeriod): IActionPayload<IPeriod> {
    return {
      type: RecommendationsActions.SET_RESULT_PERFORMANCE_PERIOD,
      payload: period,
    };
  }

  static resetPerformancePeriod(): IActionPayload {
    return {
      type: RecommendationsActions.RESET_RESULT_PERFORMANCE_PERIODS,
    };
  }

  static fetchPortfolioRecommendations(portfolioId: string) {
    const mapFunction = (responseData: { data: Record<string, IRecommendation> }): Record<string, IRecommendation> =>
      responseData.data;

    return ReduxHelper.createHttpRequestActionBundle<Record<string, IRecommendation>>(
      {
        url: getApiUrl('recommendations.list'),
        data: {
          portfolioId,
        },
      },
      RecommendationsActions.RECOMMENDATIONS_REQUEST,
      RecommendationsActions.RECOMMENDATIONS_SUCCESS,
      RecommendationsActions.RECOMMENDATIONS_ERROR,
      mapFunction
    );
  }

  static toggleRecommendation(
    assetClassId: string,
    recommendationIdx: number
  ): IActionPayload<{ assetClassId: string; recommendationIdx: number }> {
    return {
      type: RecommendationsActions.TOGGLE_RECOMMENDATION,
      payload: {
        assetClassId,
        recommendationIdx,
      },
    };
  }

  static selectAssetClass = (assetClass: IAssetClass) => {
    return {
      type: RecommendationsActions.SELECT_RECOMMENDATION_ASSET_CLASS,
      payload: {
        assetClass,
      },
    };
  };

  static resetSelectedAssetClass(): IActionPayload {
    return {
      type: RecommendationsActions.RESET_RECOMMENDATION_ASSET_CLASS,
    };
  }

  static fetchClassesAllocations(portfolioId: string, recommendations: IRecommendation[]) {
    return ReduxHelper.createHttpRequestActionBundle<ITreeMapData[]>(
      {
        url: getApiUrl('portfolio.allocations'),
        data: {
          portfolioId,
          recommendations,
          allocations: [ALLOCATION_TYPES.AssetClass],
        },
      },
      RecommendationsActions.CLASSES_ALLOCATION_DATA_REQUEST,
      RecommendationsActions.CLASSES_ALLOCATION_DATA_SUCCESS,
      RecommendationsActions.CLASSES_ALLOCATION_DATA_ERROR
    );
  }

  static fetchGeographyAllocations(portfolioId: string, recommendations: IRecommendation[]) {
    return ReduxHelper.createHttpRequestActionBundle<ITreeMapData[]>(
      {
        url: getApiUrl('portfolio.allocations'),
        data: {
          portfolioId,
          recommendations,
          allocations: [ALLOCATION_TYPES.Region],
        },
      },
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_REQUEST,
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_SUCCESS,
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_ERROR
    );
  }

  static applyScenarioConfig(settings: IScenarioBasicConfig): IActionPayload<IScenarioBasicConfig> {
    return {
      type: RecommendationsActions.RECOMMENDATIONS_APPLY_SCENARIO_CONFIG,
      payload: settings,
    };
  }

  static selectHistoryEvent(event: string): IActionPayload<string> {
    return {
      type: RecommendationsActions.RECOMMENDATIONS_SELECT_HISTORY_EVENT,
      payload: event,
    };
  }

  static selectHistoryPeriod(months: number): IActionPayload<number> {
    return {
      type: RecommendationsActions.RECOMMENDATIONS_SELECT_HISTORY_PERIOD,
      payload: months,
    };
  }

  static resetResultPerformance(): IActionPayload {
    return {
      type: RecommendationsActions.RESULT_PERFORMANCE_RESET,
    };
  }

  static resetPersonalPerformance(): IActionPayload {
    return {
      type: RecommendationsActions.PERSONAL_PERFORMANCE_RESET,
    };
  }

  static resetRecommendations(): IActionPayload {
    return {
      type: RecommendationsActions.RECOMMENDATIONS_RESET,
    };
  }

  static resetClassesAllocations(): IActionPayload {
    return {
      type: RecommendationsActions.CLASSES_ALLOCATION_DATA_RESET,
    };
  }

  static resetGeographyAllocations(): IActionPayload {
    return {
      type: RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_RESET,
    };
  }

  static resetScenario(): IActionPayload {
    return {
      type: RecommendationsActions.RECOMMENDATIONS_SCENARIO_RESET,
    };
  }

  static resetRecommendationData(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch) => {
      dispatch(RecommendationsActions.resetResultPerformance());
      dispatch(RecommendationsActions.resetPersonalPerformance());
      dispatch(RecommendationsActions.resetRecommendations());
      dispatch(RecommendationsActions.resetClassesAllocations());
      dispatch(RecommendationsActions.resetGeographyAllocations());
      dispatch(RecommendationsActions.resetScenario());
      dispatch(RecommendationsActions.resetSelectedAssetClass());
      dispatch(RecommendationsActions.resetPerformancePeriod());
    };
  }
}

export default RecommendationsActions;
