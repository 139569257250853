import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { FormatHelper } from '../../../../../../../services/utils/FormatHelper';

interface IProps {
  isSell: boolean;
  value: number;
  isCurrency?: boolean;
}

const TypeCell: FunctionComponent<IProps> = ({ isSell, value, isCurrency = false }: IProps) => {
  let formatedValue: string | number = value;
  if (isCurrency) {
    formatedValue = FormatHelper.formatNumber(Math.abs(value));
  } else {
    formatedValue = isSell && value < 0 ? value : Math.abs(value);
  }

  return <span className={cn({ 'text-error': isSell && value < 0 })}>{formatedValue}</span>;
};

export default React.memo(TypeCell);
