import { Color } from 'csstype';

export class CanvasHelpers {
  static getHatchingCanvas(color: Color): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 6;

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
    const pattern = this.getHatchingPattern(color);
    if (!ctx || !pattern) {
      return canvas;
    }

    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = pattern;
    ctx.fill();
    return canvas;
  }

  static getHatchingPattern(color: Color): CanvasPattern | null {
    const patternCanvas = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = patternCanvas.getContext('2d');
    if (!ctx) {
      return null;
    }

    patternCanvas.width = patternCanvas.height = 3;
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(3, 0);
    ctx.lineTo(0, 3);
    ctx.closePath();
    ctx.stroke();
    return ctx.createPattern(patternCanvas, 'repeat');
  }
}
