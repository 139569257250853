import { ReduxHelper } from '../utils/ReduxHelper';

import { getApiUrl } from '../constants/endpoints';

class CountryActions {
  static readonly COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
  static readonly COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
  static readonly COUNTRIES_ERROR = 'COUNTRIES_ERROR';

  static fetchAllCountries() {
    return ReduxHelper.createHttpRequestActionBundle<ICountry[]>(
      {
        url: getApiUrl('country.all'),
      },
      CountryActions.COUNTRIES_REQUEST,
      CountryActions.COUNTRIES_SUCCESS,
      CountryActions.COUNTRIES_ERROR
    );
  }
}

export default CountryActions;
