import { StructureWheel } from '../PortfolioExplorer/components/StructureWheel';
import { TopPositionBreakdown } from '../PortfolioExplorer/components/TopPositionBreakdown/TopPositionBreakdown';
import { AllocationBreakdown } from '../PortfolioExplorer/components/AllocationBreakdown/AllocationBreakdown';
import React from 'react';
import { Col, Grid, Modal, Row } from 'rsuite';
import s from '../PortfolioExplorer/PortfolioExplorer.module.scss';
import { ExplorerContextProvider, useExplorerContext } from '../../../../services/context/ExplorerContext';
import { PortfolioHeader } from '../../components';
import { DashboardHeader } from '../Dashboard/scenes/PortfolioDashboard/components/DashboardHeader';
import { ExpandButton } from '../../../../components/UIWidgets/ExpandButton';
import { MultiLinePNL } from '../PortfolioExplorer/components/MultiLinePNL/MultiLinePNL';
import { DashboardNewInner } from '../DashboardNew/DashboardNew';
import { useSafariHack } from '../../../../services/hooks/useSafariHack';
import PortfolioUpcomingEvents from '../../components/PortfolioUpcomingEvents/PortfolioUpcomingEvents';

const components = new Map();
components.set('StructureWheel', { Comp: StructureWheel, colWidth: 12, label: 'Structure pie chart' });
components.set('AllocationBreakdown', { Comp: AllocationBreakdown, colWidth: 12, label: 'Allocation breakdown' });

components.set('TopPositionBreakdown', { Comp: TopPositionBreakdown, colWidth: 12, label: 'Top position breakdown' });
components.set('MultiLinePNL', {
  Comp: MultiLinePNL,
  colWidth: 12,
});

export const PnlExplorerInner = () => {
  const [expandedItem, setExpandedItem] = React.useState<string | undefined>();
  const { flex } = useSafariHack();
  const { selectedPosition, setSelectedPosition } = useExplorerContext();

  const renderModalContent = React.useMemo(() => {
    const component = !!expandedItem ? components.get(expandedItem) : undefined;
    if (!component) {
      return null;
    }
    const { Comp } = component;
    return (
      <div>
        <Modal.Header>
          <Modal.Title classPrefix={s.modalTitle}>{component.label}</Modal.Title>
        </Modal.Header>
        <div style={{ height: 20 }} />
        <Comp height={800} pnl={true} expanded={true} />
      </div>
    );
  }, [expandedItem]);

  const closeModal = () => setExpandedItem(undefined);

  return (
    <div>
      <PortfolioHeader showDashboardMenu={false} />
      <DashboardHeader page={'P&L - Explorer'} hideCustodianFilter={true} />
      <div style={{ display: flex }}>
        <div style={{ flex: 1, marginRight: '0.25rem' }}>
          <Grid fluid={true}>
            <Row>
              {[...components].map(([key, value]) => {
                const { colWidth, Comp, mdHidden, lgHidden } = value;

                return (
                  <Col key={key} xs={24} lg={12} style={{ marginBottom: 10 }} {...{ mdHidden, lgHidden }}>
                    <div style={{ position: 'relative', height: 400 }}>
                      <Comp height={400} pnl={true} expanded={false} />
                      <ExpandButton onClick={() => setExpandedItem(key)} />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Grid>
        </div>

        <div style={{ height: '100%', overflow: 'hidden' }}>
          <PortfolioUpcomingEvents
            // setExpandedItem={setExpandedItem}
            deselectPosition={() => setSelectedPosition(null)}
            selectedPosition={selectedPosition}
          />
        </div>
      </div>
      <Modal full={true} show={!!expandedItem} onHide={closeModal} className={s.modalContainer}>
        {renderModalContent}
      </Modal>
    </div>
  );
};

export const PnlExplorer = () => {
  return (
    <ExplorerContextProvider>
      <PnlExplorerInner />
    </ExplorerContextProvider>
  );
};
