import { IPortfolioVolatilityData } from '../../interfaces';

export function getChartOptions(data: IPortfolioVolatilityData) {
  const minValue = 0;
  const maxValue = 7;

  // console.log('===PORTFOLIO VOLATILITY DATA===');
  console.log(data);

  const portfolioVolatility = data.portfolio.volatility;
  // const portfolioVolatility = dynamicVolatilityScore;

  const portfolioRiskBand = data.portfolio.riskBand;
  const portfolioRiskBandPercentage = data.portfolio.riskBandPercentage;

  const portfolioDataFrequency = data.portfolio.dataFrequency;
  const portfolioDataQuality = data.portfolio.dataQuality;
  const insufficientDataExposurePercentage = data.portfolio.insufficientDataExposurePercentage;

  const benchmarkVolatility = data.benchmark.volatility;
  const benchmarkRiskBand = data.benchmark.riskBand;
  const benchmarkRiskBandPercentage = data.benchmark.riskBandPercentage;
  const benchmarkIndexName = data.benchmark.indexName;

  const volatilityRatio = data.volatilityRatio;

  const needlePosition = Math.abs(portfolioRiskBand - 1 + portfolioRiskBandPercentage / 100);
  let benchmarkPosition = Math.abs((benchmarkRiskBand - 1) * 0.143 + (benchmarkRiskBandPercentage / 100) * 0.143);
  if (benchmarkPosition >= 0.99) {
    benchmarkPosition = 1;
  }

  const esmaBand = ['UNKNOWN', 'VERY LOW', 'LOW', 'LOW to MEDIUM', 'MEDIUM', 'MEDIUM to HIGH', 'HIGH', 'VERY HIGH'];

  const eChartOptions = {
    tooltip: {
      formatter: '{a}: ' + portfolioRiskBand,
    },
    series: [
      {
        name: 'Risk Band',
        type: 'gauge',
        min: minValue,
        max: maxValue,
        splitNumber: maxValue,
        data: [
          {
            value: needlePosition,
            name: 'Risk score',
          },
        ],
        title: {
          show: true,
          color: '#a9b2d1',
        },
        radius: '90%',
        startAngle: 225,
        endAngle: -95,
        axisLine: {
          show: true,
          lineStyle: {
            width: 22,
            color: [
              [0.12, '#41d9a0'],
              [0.24, '#00b370'],
              [0.36, '#d9bf41'],
              [0.48, '#fb9900'],
              [0.6, '#e17300'],
              [0.72, '#e34100'],
              [0.84, '#a50009'],
            ],
          },
        },
        splitLine: {
          show: true,
          length: 22,
          lineStyle: {
            color: '#1c202e',
            width: 2,
          },
        },
        axisTick: {
          show: false,
          length: 0,
          splitNumber: 7,
          lineStyle: {
            color: 'auto',
          },
        },
        axisLabel: {
          show: true,
          fontWeight: 'bold',
          backgroundColor: 'transparent',
          borderRadius: 2,
          color: '#fff',
          padding: 3,
          formatter: (v: number) => {
            switch (v + '') {
              case '0':
                return '0';
              case '1':
                return '2%';
              case '2':
                return '5%';
              case '3':
                return '10%';
              case '4':
                return '15%';
              case '5':
                return '25%';
              case '6':
                return '50%';
              case '7':
                return '100%';
            }
          },
        },
        pointer: {
          show: true,
          itemStyle: {
            color: 'auto',
          },
        },
        detail: {
          show: true,
          formatter: portfolioVolatility + '% Portfolio volatility',
          fontWeight: 'normal',
          fontSize: 16,
          color: '#a9b2d1',
        },
      },
      {
        name: 'Benchmark Volatility',
        type: 'gauge',
        radius: '100%',
        startAngle: 225,
        endAngle: -45,
        min: minValue,
        max: maxValue,
        splitNumber: maxValue,

        axisLine: {
          show: true,
          lineStyle: {
            width: 16,
            color: [
              [benchmarkPosition, '#138eb0'],
              [1, '#313750'],
            ],
          },
        },
        axisTick: {
          show: false,
          length: 0,
          splitNumber: 7,
          lineStyle: {
            color: 'auto',
          },
        },
        splitLine: {
          length: 10,
          lineStyle: {
            color: 'auto',
          },
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
        title: {
          offsetCenter: [0, '-330px'],
        },
        detail: {
          show: true,
          formatter:
            'Benchmark volatility (' +
            benchmarkVolatility +
            '%) \n\n {a|Portfolio vs Benchmark Ratio: ' +
            volatilityRatio +
            '}',
          fontWeight: 'normal',
          rich: {
            a: {
              color: '#a9b2d1',
              fontSize: 18,
            },
          },
          fontSize: 14,
          color: '#138eb0',
          offsetCenter: [0, 160],
        },
        data: [{ value: benchmarkVolatility, name: 'Benchmark' }],
      },
    ],
  };
  return eChartOptions;
}
