import { IFormValues, IProps } from './InstrumentForm';
import { UserRole } from '../../../../../../services/constants/constants';

export function validate(values: IFormValues, props: IProps) {
  const errors: any = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (!/[a-zA-z]{3,}/.test(values.name)) {
    errors.name = 'Please enter more meaningful name';
  }
  if (!values.code) {
    errors.code = 'Required';
  } else if (!/[a-zA-z]{3,}/.test(values.code)) {
    errors.code = 'Please enter more meaningful name';
  }
  if (!values.assetSubClass) {
    errors.assetSubClass = 'Required';
  }
  if (!values.currency) {
    errors.currency = 'Required';
  }
  if (!values.country) {
    errors.country = 'Required';
  }
  if (!values.industry) {
    errors.industry = 'Required';
  }

  const { authInfo } = props;
  if (authInfo && (authInfo.role === UserRole.ADMIN || authInfo.role === UserRole.MANAGER)) {
    if (!values.user) {
      errors.user = 'Required';
    }
  }

  return errors;
}
