import React, { useState, useRef, useEffect } from "react";
import Axis from "./components/axis";
import Bar from "./components/bar";
import { IBarChartProps, Tooltip } from "./IBarChart";
import { line } from "d3-shape";
import { select } from "d3-selection";
import { scaleLinear, scaleBand } from "d3-scale";
import Line from "./components/line";
import {
  uniformSeriesData,
  delayed,
  getValuesInterval,
  getFormattedData,
} from "./utils";
import dateFormat from "dateformat";

export function BarChart({ series, settings }: IBarChartProps) {
  const [tooltipState, setTooltipState] = useState<null | Tooltip>(null);

  const {
    height = 500,
    width = 500,
    yAxisUnit = "£",
    fontFamily = "Avenir",
    bgColor = "gray",
    labelColor = "purple",
    yTicks = 6,
    gridOpacity = 1,
    gridColor = "white",
    marginTop = 20,
    marginBottom = 20,
    marginRight = 20,
    marginLeft = 20,
    strokeWidth = 2,
    isLoading = false,
    lineColor = "gray",
    tooltipWidth = 100,
    tooltipHeight = 100,
    displayLine = false,
    indicatorLineColor = "yellow",
    tooltipFormatter,
  } = settings;

  const yLabelWidth = 80;

  series = uniformSeriesData(series);

  const values = getFormattedData(series);

  const svgRef = useRef<SVGSVGElement>(null) //as React.MutableRefObject<>;
  const lineRef = useRef<SVGSVGElement>(null) //as React.MutableRefObject<SVGSVGElement>;

  useEffect(() => {
    if(!svgRef.current){
      return;
    }
    const svg = select(svgRef.current);

    const handler = delayed(({ x, y }: { x: number; y: number }) => {
      if(!series[0]){
        return;
      }
      const index =
        x === width
          ? series.length - 1
          : Math.floor((x / width) * series[0].values.length);

      const arrayWithValue = series.filter((item) => item.values[index].y);

      setTooltipState({
        date: dateFormat(series[0].values[index].x, "yyyy-mm-dd"),
        left:
          x < width - 20 - tooltipWidth
            ? x + 20
            : Math.max(x - 20 - tooltipWidth, 0),
        top:
          y < height - 20 - tooltipHeight - marginTop - marginBottom
            ? y + 20
            : Math.max(y - 20 - tooltipHeight, 0),
        data: arrayWithValue.map((item) => {
          return {
            label: item.label,
            value: item.values[index].y,
            color: item.color,
          };
        }),
      });
    });

    svg.on("touchmove mousemove", (event: any) => {
      const x = event.offsetX - marginLeft;
      const y = event.offsetY - marginTop;
      if(!lineRef.current){
        return;
      }

      if (x < 0 || x > width) {
        setTooltipState(null);
        handler(null);
        lineRef.current.style.visibility = "hidden";
        return;
      }

      if (y < 0 || y > height) {
        setTooltipState(null);
        handler(null);
        lineRef.current.style.visibility = "hidden";
        return;
      }

      handler({ x, y });

      lineRef.current.style.visibility = "visible";
      lineRef.current.setAttribute("transform", `translate(${x}, 0)`);
    });

    svg.on("touchend mouseleave", () => {
      setTooltipState(null);
    });

    return () => {
      // svg.on("touchmove mousemove", null);
      // svg.on("touchmove mousemove", null);
    };
  }, [svgRef.current, width]);

  // console.log(values);
  if (isLoading || values.length === 0) {
    return (
      <div
        style={{
          backgroundColor: bgColor,
          width: width,
          height: height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontFamily: fontFamily,
            color: labelColor,
          }}
        >
          {isLoading ? "Loading..." : "Not Enough Data"}
        </h1>
      </div>
    );
  }

  const { minValue, maxValue } = getValuesInterval(values);

  const parentWidth = marginLeft + width + yLabelWidth + marginRight;
  const yHeight = height - marginTop - marginBottom;
  const dates = values.map(({ label }) => label);

  const xScale = scaleBand().domain(dates).range([0, width]).padding(0.1);
  const bandWidth = xScale.bandwidth();
  const barWidth = Math.min(bandWidth, 40);
  const bandGap = (bandWidth - barWidth) / 2;
  

  const yScale = scaleLinear()
    .domain([minValue, maxValue])
    .range([yHeight, 0])
    .nice();

  const xSettings = {
    scale: xScale,
    orient: "bottom" as "bottom" | "right",
    transform: `translate(0, ${yHeight})`,
    fontFamily,
    labelColor,
    gridOpacity,
    gridColor,
    width: width,
  };

  const ySettings = {
    scale: yScale,
    orient: "right" as "bottom" | "right",
    transform: `translate(${width}, 0)`,
    ticks: yTicks,
    width: width,
    currency: yAxisUnit,
    fontFamily,
    labelColor,
    gridOpacity,
    gridColor,
  };

  return (
    <div style={{ backgroundColor: bgColor, width: parentWidth }}>
      <div
        style={{
          display: "flex",
          paddingTop: marginTop,
        }}
      >
        {series.map(({ label, color }) => {
          return (
            <div
              style={{
                display: "flex",
                marginLeft: marginLeft,
                justifyContent: "center",
              }}
              key={`${label}-${color}`}
            >
              <div
                style={{
                  backgroundColor: color,
                  width: 15,
                  height: 15,
                  marginRight: 5,
                }}
              />
              <span style={{ color: labelColor }}>{label}</span>
            </div>
          );
        })}
      </div>

      <svg
        ref={svgRef}
        className="lineChartSvg"
        width={parentWidth}
        height={yHeight + marginTop + marginBottom}
      >
        <g transform={`translate(${marginLeft}, ${marginTop})`}>
          <g>
            <Axis {...xSettings} />
            <Axis {...ySettings} />
          </g>

          <g>
            <Bar
              data={values}
              xScale={xScale}
              yScale={yScale}
              barWidth={barWidth}
              bandGap={bandGap}
            />

            {displayLine && (
              <Line
                data={values}
                lineGenerator={line<any>()
                  .x(
                    (d: { label: string }) =>
                      (xScale(d.label) || 0) + barWidth / 2
                  )
                  .y((d: { values: { value: number }[] }) =>
                    yScale(
                      d.values.reduce(
                        (acc, { value }) => (!value ? acc : acc + value),
                        0
                      )
                    )
                  )}
                strokeWidth={strokeWidth}
                color={lineColor}
              />
            )}
          </g>

          {!tooltipState ? null : (
            <g>
              <foreignObject
                x={tooltipState.left}
                y={tooltipState.top}
                width={tooltipWidth}
                height={tooltipHeight}
              >
                {tooltipFormatter(tooltipState)}
              </foreignObject>
            </g>
          )}

          <g ref={lineRef} style={{ visibility: "hidden" }}>
            <line
              x1="0"
              y1="0"
              x2="0"
              y2={yHeight}
              stroke={indicatorLineColor}
              strokeDasharray="5,5"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
