import { ReduxHelper } from '../utils/ReduxHelper';

import { AssetsActions } from '../actions/AssetsActions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

export interface IAssetsClassesState extends ILoadingDataState {
  data: IAssetClass[];
}

const INITIAL_STATE: IAssetsClassesState = ReduxHelper.createInitialState<IAssetClass[]>([]);

const assetsClassesReducer = ReduxHelper.createHttpRequestReducer<IAssetsClassesState>(
  INITIAL_STATE,
  AssetsActions.ASSETS_REQUEST,
  AssetsActions.ASSETS_SUCCESS,
  AssetsActions.ASSETS_ERROR
);

export default assetsClassesReducer;
