import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';

import { TABLE_PAGE_SIZE } from '../constants/constants';

import { IPaginationContent } from '../../models/redux/ILoadingPaginationDataState';

import { IActionPayload } from '../../models/redux/IActionPayload';

import { IUpdateInstrumentDTO } from '../../models/DTO/IUpdateInstrumentDTO';

const instrumentToDTO = (instrument: IInstrument): IUpdateInstrumentDTO => {
  const savingInstrument: IUpdateInstrumentDTO = {
    id: instrument.id,
    assetSubClassId: instrument.assetSubClass.id,
    code: instrument.code,
    country: instrument.countryCode.toString(),
    currencyId: instrument.currency.id,
    description: instrument.description,
    geoRegionId: '7deae38e-46c2-4d14-bf80-1b2aa4c11514', // Global region
    industrySector: (instrument.industrySector || { id: null }).id.toString(),
    name: instrument.name,
    nonMarket: instrument.nonMarket,
    pointValue: 1.0,
  };
  if (instrument.userId) {
    savingInstrument.userId = instrument.userId;
  }
  return savingInstrument;
};

export class InstrumentsActions {
  static readonly INSTRUMENTS_REQUEST = 'INSTRUMENTS_REQUEST';
  static readonly INSTRUMENTS_SUCCESS = 'INSTRUMENTS_SUCCESS';
  static readonly INSTRUMENTS_ERROR = 'INSTRUMENTS_ERROR';
  static readonly INSTRUMENTS_RESET = 'INSTRUMENTS_RESET';
  static readonly INSTRUMENTS_CHANGE_SORT = 'INSTRUMENTS_CHANGE_SORT';
  static readonly INSTRUMENTS_SET_FILTERS = 'INSTRUMENTS_SET_FILTERS';

  static readonly INSTRUMENT_CREATE_REQUEST = 'INSTRUMENT_CREATE_REQUEST';
  static readonly INSTRUMENT_CREATE_SUCCESS = 'INSTRUMENT_CREATE_SUCCESS';
  static readonly INSTRUMENT_CREATE_ERROR = 'INSTRUMENT_CREATE_ERROR';

  static readonly INSTRUMENT_UPDATE_REQUEST = 'INSTRUMENT_UPDATE_REQUEST';
  static readonly INSTRUMENT_UPDATE_SUCCESS = 'INSTRUMENT_UPDATE_SUCCESS';
  static readonly INSTRUMENT_UPDATE_ERROR = 'INSTRUMENT_UPDATE_ERROR';

  static readonly INSTRUMENT_DELETE_REQUEST = 'INSTRUMENT_DELETE_REQUEST';
  static readonly INSTRUMENT_DELETE_SUCCESS = 'INSTRUMENT_DELETE_SUCCESS';
  static readonly INSTRUMENT_DELETE_ERROR = 'INSTRUMENT_DELETE_ERROR';

  static readonly INSTRUMENT_INFO_REQUEST = 'USER_INFO_REQUEST';
  static readonly INSTRUMENT_INFO_SUCCESS = 'USER_INFO_SUCCESS';
  static readonly INSTRUMENT_INFO_ERROR = 'USER_INFO_ERROR';
  static readonly INSTRUMENT_INFO_RESET = 'USER_INFO_RESET';

  static readonly INSTRUMENT_USER_LIST_REQUEST = 'INSTRUMENT_USER_LIST_REQUEST';
  static readonly INSTRUMENT_USER_LIST_SUCCESS = 'INSTRUMENT_USER_LIST_SUCCESS';
  static readonly INSTRUMENT_USER_LIST_ERROR = 'INSTRUMENT_USER_LIST_ERROR';

  static fetchAllInstruments(order: IOrder, filters: IInstrumentsFilters, page: number = 0) {
    function prepareFilters(localFilters: IInstrumentsFilters) {
      const serverFilters: IInstrumentsServerFilters = {
        nonMarket: true,
      };
      if (localFilters.currency) {
        serverFilters.currencyId = localFilters.currency.value.id;
      }
      if (localFilters.name) {
        serverFilters.name = localFilters.name;
      }
      if (localFilters.user) {
        serverFilters.userId = localFilters.user.id;
      }
      if (localFilters.code) {
        serverFilters.code = localFilters.code;
      }
      if (localFilters.assetClass) {
        serverFilters.assetClassId = localFilters.assetClass.id;
      }
      if (localFilters.assetSubClass) {
        serverFilters.assetSubClassId = localFilters.assetSubClass.id;
      }
      if (localFilters.country) {
        serverFilters.countryCode = localFilters.country.value.code;
      }
      if (localFilters.industrySector) {
        serverFilters.industrySectorId = localFilters.industrySector.value.id;
      }
      return serverFilters;
    }
    return ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IInstrument>>(
      {
        url: getApiUrl('instruments.list'),
        data: {
          page,
          size: TABLE_PAGE_SIZE,
          order,
          ...prepareFilters(filters),
        },
      },
      InstrumentsActions.INSTRUMENTS_REQUEST,
      InstrumentsActions.INSTRUMENTS_SUCCESS,
      InstrumentsActions.INSTRUMENTS_ERROR
    );
  }

  static changeInstrumentsSort(order: IOrder): IActionPayload<IOrder> {
    return { type: InstrumentsActions.INSTRUMENTS_CHANGE_SORT, payload: order };
  }

  static changeInstrumentsFilters(filters: IInstrumentsFilters): IActionPayload<IInstrumentsFilters> {
    return { type: InstrumentsActions.INSTRUMENTS_SET_FILTERS, payload: filters };
  }

  static createInstrument(instrument: IInstrument) {
    return ReduxHelper.createHttpRequestActionBundle<IInstrument>(
      {
        url: getApiUrl('instruments.create'),
        data: {
          ...instrumentToDTO(instrument),
        },
      },
      InstrumentsActions.INSTRUMENT_CREATE_REQUEST,
      InstrumentsActions.INSTRUMENT_CREATE_SUCCESS,
      InstrumentsActions.INSTRUMENT_CREATE_ERROR
    );
  }

  static updateInstrument(instrument: IInstrument) {
    return ReduxHelper.createHttpRequestActionBundle<IInstrument>(
      {
        url: getApiUrl('instruments.update'),
        data: {
          ...instrumentToDTO(instrument),
        },
      },
      InstrumentsActions.INSTRUMENT_UPDATE_REQUEST,
      InstrumentsActions.INSTRUMENT_UPDATE_SUCCESS,
      InstrumentsActions.INSTRUMENT_UPDATE_ERROR
    );
  }

  static deleteInstrument(instrument: IInstrument) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('instruments.delete'),
        data: {
          instrumentIds: [instrument.id],
        },
      },
      InstrumentsActions.INSTRUMENT_DELETE_REQUEST,
      InstrumentsActions.INSTRUMENT_DELETE_SUCCESS,
      InstrumentsActions.INSTRUMENT_DELETE_ERROR
    );
  }

  static resetInstrumentList(): IActionPayload {
    return { type: InstrumentsActions.INSTRUMENTS_RESET };
  }

  static resetInstrumentInfo(): IActionPayload {
    return { type: InstrumentsActions.INSTRUMENT_INFO_RESET };
  }

  static fetchInstrumentById(id: string) {
    const mapFunction = (requestData: Record<string, IInstrument>): IInstrument => {
      return requestData[id];
    };

    return ReduxHelper.createHttpRequestActionBundle<IInstrument>(
      {
        url: getApiUrl('instruments.get'),
        data: {
          instrumentIds: [id],
        },
      },
      InstrumentsActions.INSTRUMENT_INFO_REQUEST,
      InstrumentsActions.INSTRUMENT_INFO_SUCCESS,
      InstrumentsActions.INSTRUMENT_INFO_ERROR,
      mapFunction
    );
  }

  static resetInstrumentsList(): IActionPayload {
    return { type: InstrumentsActions.INSTRUMENT_INFO_RESET };
  }

  static fetchUsers(search: string) {
    return ReduxHelper.createHttpRequestActionBundle<IUser[]>(
      {
        url: getApiUrl('users.all'),
        data: {
          text: search,
        },
      },
      InstrumentsActions.INSTRUMENT_USER_LIST_REQUEST,
      InstrumentsActions.INSTRUMENT_USER_LIST_SUCCESS,
      InstrumentsActions.INSTRUMENT_USER_LIST_ERROR
    );
  }
}

export default InstrumentsActions;
