import React, { FC } from 'react';
import cn from 'classnames';
import s from '../ReportGenerator.module.css';
import { Button, InputNumber } from 'rsuite';
import SimpleDropDown from '../../../../../components/Reusable/SimpleDropDown/SimpleDropDown';
import { GROUPING_OPTIONS, GroupingOption, PERIOD_OPTIONS } from '../utils/options';
import { IPortfolioScenarioData } from '../../RiskScenarios/interfaces';
import { Toggle } from './Toggle';
import { floatNumberV2, positiveNumber } from '../../../../../services/utils/FormNormalize';

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
  data: IPortfolioScenarioData;
}

const DEFAULT_SENSITIVITY_OPTIONS = {
  equityPercent: -10,
  fxPercent: -2,
  yieldPercent: 0,
  volatilityPercent: 0,
};

const DEFAULT_GROUPING = GROUPING_OPTIONS[0];

const SensitivitySettings: FC<IOwnProps> = ({ settings, updateSettings, data }) => {
  const setDefault = () => {
    updateSettings({
      sensitivity: false,
      sensitivityGrouping: DEFAULT_GROUPING,
      sensitivityOptions: DEFAULT_SENSITIVITY_OPTIONS,
    });
  };

  const SensitivityToggle = ({ field }: { field: string }) => {
    return <Toggle field={field} settings={settings} updateSettings={updateSettings} />;
  };

  const updateSensitivitySettings = ({ field, value }: { field: string; value: string }) => {
    if(value[value.length - 1] === "."){
      return;
    }

    let newValue = value;
    if(value.includes(".")){
      if(isNaN(parseInt(value, 10))){
        return;
      }
      newValue = `${parseInt(value, 10)}`;
    }

    updateSettings({
      sensitivityOptions: {
        ...settings.sensitivityOptions,
        [field]: newValue,
      },
    });
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <h3>Sensitivity Analysis</h3>
      <br />
      <div className={cn(s.settingsRow)}>
        <span>Sensitivity page</span>
        <SensitivityToggle field={'sensitivity'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Group by</span>
        <SimpleDropDown
          disabled={!settings.sensitivity}
          items={GROUPING_OPTIONS}
          selectedItem={settings.sensitivityGrouping}
          onSelect={({ selectedItem }) => updateSettings({ sensitivityGrouping: selectedItem })}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span style={{ fontWeight: 'bold', marginTop: 10 }}>Scenario options</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Benchmark Move (%)</span>
        <InputNumber
          onChange={(v) => updateSensitivitySettings({ field: 'equityPercent', value: v.toString() })}
          className={s.inputNumber}
          value={settings.sensitivityOptions.equityPercent}
          defaultValue={'-10'}
          disabled={!settings.sensitivity}
          // onBlur={sanitize('equityPercent')}
          postfix={'%'}
          max={20}
          size={'sm'}
          min={-20}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Volatility (%)</span>
        <InputNumber
          onChange={(v) => updateSensitivitySettings({ field: 'volatilityPercent', value: v.toString() })}
          className={s.inputNumber}
          value={settings.sensitivityOptions.volatilityPercent}
          defaultValue={'25'}
          postfix={'%'}
          disabled={!data.affectedByVolatilityChange || !settings.sensitivity}
          max={50}
          size={'sm'}
          min={-50}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Yield (%)</span>
        <InputNumber
          onChange={(v) => updateSensitivitySettings({ field: 'yieldPercent', value: v.toString() })}
          className={s.inputNumber}
          value={settings.sensitivityOptions.yieldPercent}
          defaultValue={'25'}
          disabled={!data.affectedByYieldChange || !settings.sensitivity}
          postfix={'%'}
          size={'sm'}
          max={50}
          min={-50}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Fx vs {data.baseCurrencyName} (%)</span>
        <InputNumber
          onChange={(v) => updateSensitivitySettings({ field: 'fxPercent', value: v.toString() })}
          className={s.inputNumber}
          value={settings.sensitivityOptions.fxPercent}
          defaultValue={'-2'}
          disabled={!settings.sensitivity}
          max={10}
          size={'sm'}
          postfix={'%'}
          min={-10}
        />
      </div>
      <Button style={{ marginLeft: -8, marginTop: 5 }} size={'xs'} appearance={'primary'} onClick={setDefault}>
        Set Default
      </Button>
    </div>
  );
};

export default SensitivitySettings;
