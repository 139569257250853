import * as React from 'react';
import { usePortfolioInfo } from '../../../../../../services/hooks/usePortfolioInfo';
import PositionsHeader from './components/PositionsHeader/PositionsHeader';
import { useCustodiansFilter } from '../../../../../../services/hooks/useCustodiansFilter';
import { IOptionType } from '../../../../../../components/UIWidgets/Select/Select';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { read, utils, writeFile, WorkSheet } from 'xlsx';
import { getApiUrl, getCustomApiUrl } from '../../../../../../services/constants/endpoints';
import axios from 'axios';
import { Table } from '@iliotech/storybook';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { PositionEntryCellRenderer } from './utils/PositionEntryCellRenderer';
import s from './PositionEntry.module.scss';
import InstrumentSearchInput from './components/InstrumentSearchInput/InstrumentSearchInput';
import { IOption } from '../../../../../../components/UIWidgets/Autocomplete';
import { sendRequest } from '../../../../../../services/hooks/useApi';
import cn from 'classnames';
import { Button, Confirm } from '../../../../../../components';
import { toast } from 'react-toastify';
import { generatePath, useHistory } from 'react-router-dom';
import { PATHS } from '../../../../../../router/paths';
import moment from 'moment';
import CurrencyComboBox from './components/CurrencyComboBox/CurrencyComboBox';
import { Loader, Modal, SelectPicker } from 'rsuite';
import { Loader as Spinner } from '../../../../../../components/UIWidgets/Loader';
// Params List
import debounce from 'lodash.debounce';
export const SHARES_AND_FUNDS_PARAMS = '&equity=true&fund=true';
export const CRYPTO_PARAMS = '&crypto=true';
export const BONDS_PARAMS = '&fixedIncome=true';

enum INSTRUMENT_OPTIONS_ENUM {
  'Shares and Funds' = 'Shares and Funds',
  'Cash' = 'Cash',
  'Crypto' = 'Crypto',
  'Bonds' = 'Bonds',
}

const INSTRUMENT_OPTIONS_ARRAY = [
  INSTRUMENT_OPTIONS_ENUM['Shares and Funds'],
  INSTRUMENT_OPTIONS_ENUM.Cash,
  INSTRUMENT_OPTIONS_ENUM.Crypto,
  INSTRUMENT_OPTIONS_ENUM.Bonds,
];

const PositionEntry = () => {
  const history = useHistory();

  const [positionModal, setPositionModal] = useState<number | null>(null);
  const { custodianOptions } = useCustodiansFilter();
  const { portfolioInfo } = usePortfolioInfo();
  const [addingMore, setAddingMore] = useState(false);
  const instrumentOptions: IOptionType[] = INSTRUMENT_OPTIONS_ARRAY.map((item) => ({ label: item, value: item }));

  const options: IOptionType[] = React.useMemo(() => custodianOptions.map((c) => ({ label: c.name, value: c })), [
    custodianOptions,
  ]);

  const [instrumentType, setInstrumentType] = useState('');
  const selectedInstrumentType = instrumentOptions.find((c) => c.value === instrumentType);

  const params = React.useMemo(() => {
    if (instrumentType === INSTRUMENT_OPTIONS_ENUM['Shares and Funds']) {
      return SHARES_AND_FUNDS_PARAMS;
    }
    if (instrumentType === INSTRUMENT_OPTIONS_ENUM.Crypto) {
      return CRYPTO_PARAMS;
    }
    if (instrumentType === INSTRUMENT_OPTIONS_ENUM.Bonds) {
      return BONDS_PARAMS;
    }
  }, [instrumentType]);

  const searchBoxPlaceholder = React.useMemo(() => {
    if (instrumentType === INSTRUMENT_OPTIONS_ENUM['Shares and Funds']) {
      return 'Name or Ticker';
    }
    if (instrumentType === INSTRUMENT_OPTIONS_ENUM.Crypto) {
      return 'Name or Symbol';
    }
    if (instrumentType === INSTRUMENT_OPTIONS_ENUM.Bonds) {
      return 'Name or ISIN';
    }
  }, [instrumentType]);

  const [tradeTime, setTradeTime] = useState(new Date());
  const [custodian, setCustodian] = useState(options[0]?.value);
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState<'ERROR' | 'LOADING' | 'READY' | 'NOT_SET'>('NOT_SET');
  const [error, setError] = React.useState<string>();
  const [sheet, setSheet] = React.useState<WorkSheet>();
  const [tableData, setTableData] = React.useState<IPosition[]>([]);
  const [autoAllocate, setAutoAllocate] = React.useState(false);
  const [totalWeight, setTotalWeight] = React.useState(10000);

  const [rowDataStack, setRowDataStack] = React.useState<IPosition[]>([]);
  const [unparsedData, setUnparsedData] = React.useState<IPosition[]>([]);
  const [errorMessage, setErrorMessage] = React.useState('');

  const debouncedUpdate = useCallback(
    debounce((rowDataStackLocal: IPosition[]) => {
      updateRowsBatch(rowDataStackLocal);
    }, 200),
    []
  );

  useEffect(() => {
    if (rowDataStack.length) {
      debouncedUpdate(rowDataStack);
    }
  }, [rowDataStack]);

  const tableDataRef = useRef<any>(null);

  useEffect(() => {
    const instrumentFetchingTimeout = setInterval(() => {
      getOrCreateAllInstruments();
    }, 5000);
    return () => {
      clearInterval(instrumentFetchingTimeout);
    };
  }, []);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  useEffect(() => {
    updatePrices();
  }, [tradeTime]);

  useEffect(() => {
    if (options.length) {
      setCustodian(options.find((op) => op.value.default)?.value ?? options[0]?.value);
    }
  }, [options]);

  const updatePrices = async () => {
    const newData: IPosition[] = [];
    for (const row of tableData) {
      const currentItem = getCurrentInstrument(row);
      if (!currentItem || currentItem.isCurrency) {
        newData.push({
          ...row,
        });
        continue;
      }
      const price = await getPrice(currentItem!.sourceId?.sourceId, currentItem!.sourceId?.sourceData);
      const fxRate = await getFxRate(currentItem!.currency, portfolioInfo.data?.currency.name!);
      newData.push({
        ...row,
        price,
        fxRate: fxRate.fromToFxRate,
      });
    }
    updateTableData(newData);
  };

  // REQUESTS
  const getOrCreate = (sourceId: string, sourceData: string) => {
    const url = getCustomApiUrl('instruments.getOrCreate', [
      { idLabel: ':sourceId', idValue: sourceId },
      { idLabel: ':sourceData', idValue: sourceData },
    ]);

    return sendRequest(url);
  };
  // REQUESTS
  const getFxRate = (from: string, to: string) => {
    const url = `/api/v1/fx-rate?from=${from}&to=${to}&date=${moment(tradeTime).format('YYYY-MM-DD')}`;

    return sendRequest(url);
  };

  // REQUESTS :update original price
  const getPrice = (sourceId: string, sourceData: string) => {
    const url = getApiUrl('snapshot.price.get');

    return sendRequest(url, {
      params: {
        sourceId,
        sourceData,
        date: moment(tradeTime).format('YYYY-MM-DD'),
      },
    });
  };

  // HELPER to update table data and setting the index for row color
  const updateTableData = (data: IPosition[]) => {
    setTableData(data.map((d, i) => ({ ...d, index: i, isCurrency: d.isCurrency || d.assetSubClass === 'SubCash' })));
  };

  const getOrCreateAllInstruments = async () => {
    const instrumentsToGetOrCreate = tableDataRef.current.filter(
      (instrument: IPosition) =>
        instrument.instrumentStatus === 'DRAFT' || (instrument.selected && !instrument.instrumentStatus)
    );
    instrumentsToGetOrCreate.forEach((item: IPosition) => {
      getOrCreate(item.sourceId.sourceId, item.sourceId.sourceData).then((status) => {
        if (status.status !== 'DRAFT' && !!status.status) {
          // try to use previous instead of ref
          updateTableData([
            ...tableDataRef.current.filter(
              (subItem: IPosition) => subItem.sourceId?.sourceId !== item.sourceId.sourceId
            ),
            {
              ...tableDataRef.current.find(
                (subItem: IPosition) => subItem.sourceId?.sourceId === item.sourceId.sourceId
              ),
              instrumentStatus: status.status,
              instrumentId: status.id,
            },
          ]);
        }
      });
    });
  };

  // Function to batch updates rows
  const updateRowsBatch = (rowDataStackLocal: IPosition[]) => {
    const newTableData = tableDataRef.current.map((row: IPosition, index: number) => {
      const found = rowDataStackLocal.find((item) => item.index === index);
      if (found) {
        return { ...found, index, isCurrency: found.isCurrency || found.assetSubClass === 'SubCash' };
      }
      return row;
    });
    setTableData(newTableData);
    setRowDataStack([]);
  };

  // Function to add rowData to the update stack
  const addToUpdateStack = async (rowData: IPosition) => {
    setRowDataStack((curr) => [...curr, rowData]);
  };

  // FUNCTION used to update a row, update quantity, price or selectedAsset
  const updateRowData = async (rowData: IPosition, newData: IPosition) => {
    const newTableData = tableDataRef.current.map((item: IPosition) => (item.index === rowData.index ? newData : item));
    updateTableData(newTableData);
  };

  const removeRow = (rowData: IPosition) => {
    updateTableData(tableDataRef.current.filter((data: any) => data.index !== rowData.index));
  };

  const cellRendererCallBack = React.useCallback(
    PositionEntryCellRenderer({ updateRowData, removeRow, setPositionModal, addToUpdateStack }),
    [updateRowData, removeRow, setPositionModal, addToUpdateStack]
  );

  const TABLE_COLUMNS: ColumnProps[] = [
    {
      dataKey: 'status',
      label: '',
      flexGrow: 1,
      width: 20,
      cellRenderer: cellRendererCallBack,
    },
    {
      dataKey: 'ticker',
      label: 'Identifier',
      flexGrow: 3,
      width: 50,
      cellRenderer: cellRendererCallBack,
    },
    {
      dataKey: 'instrument',
      label: 'Name',
      flexGrow: 3,
      width: 50,
      cellRenderer: cellRendererCallBack,
    },
    { dataKey: 'quantity', label: 'Quantity', flexGrow: 2, width: 30, cellRenderer: cellRendererCallBack },
    { dataKey: 'price', label: 'Price', flexGrow: 2, width: 30, cellRenderer: cellRendererCallBack },
    { dataKey: 'closePrice', label: 'Close Price', flexGrow: 2, width: 50, cellRenderer: cellRendererCallBack },
    {
      dataKey: 'total',
      label: 'Total Value',
      flexGrow: 2,
      width: 30,
      cellRenderer: cellRendererCallBack,
    },
  ];

  useEffect(() => {
    if (sheet) {
      sendRawData();
    }
  }, [sheet]);

  const clear = React.useCallback(() => {
    {
      setState('NOT_SET');
      setSheet(undefined);
      setError(undefined);
    }
  }, [setState, setSheet]);

  /** Send data from the selected sheet to the server **/
  const sendRawData = () => {
    if (!sheet) {
      return;
    }
    const cleanSheet = { ...sheet };
    delete cleanSheet['!ref'];

    const rawData: IUploadTemplateData['rows'] = {};

    Object.entries(cleanSheet).forEach(([key, value]) => {
      const row = key.match(/([0-9])+/g)?.[0];
      if (row) {
        const cell = key.substr(0, key.length - row.length);
        if (!rawData[row]) {
          rawData[row] = {};
        }
        rawData[row][cell] = value.w ?? value.v;
      }
    });

    // @ts-ignore
    axios
      .post(getApiUrl('app.uploadTemplates.positions.upload'), {
        template: 'IllioInHousePosition',
        rows: rawData,
        portfolioId: portfolioInfo.data!.id,
        tradeDate: moment(tradeTime).format('YYYY-MM-DD'),
      })
      .then((result) => {
        if (result.data.unparsedRows) {
          setUnparsedData(result.data.unparsedRows);
          if (Object.values(result.data.unparsedRows).length > 0) {
            setErrorMessage(
              Object.values(result.data.unparsedRows).length +
                ` rows in the file have invalid values. \n Rather than try to guess what they should be we've prepared them to download into a separate file.`
            );
          }
        }
        if (result.data.parsedRows) {
          parseUpload(result.data.parsedRows);
          // console.log(result.data);
        }
      })
      .catch((err: any) => {
        console.log({ err });
      });
  };

  const onDrop = (acceptedFiles: File[]) => {
    clear();
    setState('LOADING');

    if (acceptedFiles.length > 1) {
      setError('Error loading worksheet');
      setState('ERROR');
    }

    const f = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) {
        return;
      }
      // @ts-ignore - incorrect types for FileReader.onload
      const data = new Uint8Array(e.target!.result);
      const wb = read(data, { type: 'array' });
      if (wb.SheetNames) {
        setState('READY');
        setSheet(wb.Sheets[wb.SheetNames[0]]);
      }
    };
    reader.readAsArrayBuffer(f);
  };

  const parseUpload = (data: IUploadPositionResponse[]) => {
    updateTableData(
      // @ts-ignore
      data.map((item) => {
        const instrumentId = item.searchResult.length > 0 ? item.searchResult[0].instrumentId : null;

        return {
          ...item.tradeImport,
          quantity: item.tradeImport.buySell === 'BUY' ? item.tradeImport.quantity : -item.tradeImport.quantity,
          instrumentId,
          historicPrice: item.tradeImport.price,
          searchResult: item.searchResult,
        };
      })
    );
  };

  const onAddCurrency = async (el: ICurrency | null, isModal?: boolean) => {
    if (!el) {
      return;
    }
    if (tableData.find((item) => item.name === el.name)) {
      return;
    }
    setAddingMore(true);
    const fxRate = await getFxRate(el.name, portfolioInfo.data?.currency.name!);
    const instrumentId = await getOrCreate(`${el.name}SUBS`, 'SystemInstrumentService');

    const identifier = `Cash (${el.name})`;

    const rowData = {
      quantity: '',
      price: 1,
      instrumentId: instrumentId?.id,
      ticker: identifier,
      historicPrice: 1,
      instrumentStatus: 'ACTIVE',
      isCurrency: true,
      code: identifier,
      name: el.name,
      currency: el.name,
      fxRate: fxRate.fromToFxRate,
    } as any;

    setAddingMore(false);

    if (isModal) {
      updateTableData(
        tableDataRef.current.map((item: IPosition) =>
          item.index === positionModal
            ? { ...rowData, quantity: item.quantity, index: item.index, historicPrice: item.historicPrice }
            : item
        )
      );
      setPositionModal(null);
    } else {
      updateTableData([...tableData, rowData]);
    }
  };

  const onAdd = async (el: IOption<IAdjustmentType> | null, isModal?: boolean) => {
    if (!el) {
      return;
    }

    const containsRow = tableData.find((item) => item.ticker === el.id);

    if (containsRow) {
      return;
    }
    setAddingMore(true);
    const price = await getPrice(el.value.sourceId.sourceId, el.value.sourceId.sourceData);

    const fxRate = await getFxRate(el.value.currency, portfolioInfo.data?.currency.name!);

    const status = await getOrCreate(el.value.sourceId.sourceId, el.value.sourceId.sourceData);

    const rowData = {
      ...el.value,
      quantity: '',
      price,
      ticker: el.id,
      historicPrice: price,
      instrumentStatus: status?.status || 'ERROR',
      instrumentId: status?.id,
      fxRate: fxRate.fromToFxRate,
    } as any;

    setAddingMore(false);

    if (isModal) {
      updateTableData(
        tableDataRef.current.map((item: IPosition) =>
          item.index === positionModal
            ? { ...rowData, quantity: item.quantity, index: item.index, historicPrice: item.historicPrice }
            : item
        )
      );
      setPositionModal(null);
    } else {
      updateTableData([...tableData, rowData]);
    }
  };

  const getCurrentInstrument = (item: IPosition) => item;

  const grossTradeAmount = useMemo(
    () =>
      tableData.reduce((prev, acc) => {
        const currentInstrument = getCurrentInstrument(acc);
        const fxRate = Number(currentInstrument?.fxRate || 1);
        return (
          prev +
          (currentInstrument
            ? acc.quantity *
              ((acc.historicPrice || acc.price || 1) * (fxRate || 1)) *
              (currentInstrument?.pointValue || 1)
            : 0)
        );
      }, 0),
    [tableData]
  );

  const enabledSave = useMemo(() => {
    return (
      tableData.length > 0 &&
      !!custodian &&
      !tableData.some(
        (item) =>
          !item.quantity ||
          (item.searchResult && !item.selected) ||
          (!item.historicPrice && !item.isCurrency) ||
          !(item.instrumentStatus === 'ACTIVE')
      )
    );
  }, [tableData, custodian]);

  const AddRow = ({ isModal }: { isModal?: boolean }) => {
    const preselected = isModal ? tableDataRef.current.find((item: IPosition) => item.index === positionModal) : null;
    return (
      <div className={s.addRow} style={isModal ? { justifyContent: 'center' } : {}}>
        {!isModal && <span className={s.addText}> Add</span>}
        <SelectPicker
          cleanable={false}
          className={s.selectWrapper}
          placeholder={'Select instrument type'}
          searchable={false}
          data={instrumentOptions}
          value={selectedInstrumentType?.value}
          onSelect={(item) => setInstrumentType(item)}
        />
        <div className={s.selectWrapper}>
          {instrumentType === INSTRUMENT_OPTIONS_ENUM.Cash ? (
            <CurrencyComboBox onAdd={(item) => onAddCurrency(item, isModal)} />
          ) : (
            <InstrumentSearchInput
              disabled={!instrumentType}
              presearch={preselected?.ticker || null}
              placeholder={searchBoxPlaceholder || 'Select instrument type first'}
              params={params!}
              onAdd={(item) => onAdd(item, isModal)}
            />
          )}
        </div>
      </div>
    );
  };

  const onClearPositions = () => {
    setTableData([]);
  };

  const onSavePositions = async () => {
    setLoading(true);
    const positionsPayload: IPositionsPayload[] = tableData.map((item) => {
      const currentInstrument = getCurrentInstrument(item);
      return {
        currency: portfolioInfo.data?.currency.name || '',
        custodian: custodian.id || custodian.value.id,
        operation:
          item.quantity < 0
            ? item.isCurrency
              ? 'WITHDRAW'
              : 'SELL'
            : item.buySell || (item.isCurrency ? 'ADD' : 'BUY'),
        fxRate: currentInstrument?.fxRate,
        sourceId: currentInstrument?.sourceId,
        instrumentId: currentInstrument!.instrumentId,
        price: item.isCurrency ? 0 : item.price,
        historicPrice: item.historicPrice!,
        quantity: Math.abs(Number(item.quantity)),
        notes: '',
      };
    });
    try {
      const howMany = await sendRequest(
        getApiUrl('app.uploadTemplates.positions.add'),
        {
          method: 'POST',
        },
        {
          positions: positionsPayload,
          portfolioId: portfolioInfo.data?.id,
          tradesDate: moment(tradeTime).format('YYYY-MM-DD'),
        }
      );
      setLoading(false);

      if (!howMany) {
        throw new Error();
      }
      toast.success(`Your ${howMany} positions were successfully added!`);
      history.push(generatePath(PATHS.portfolio.snapshot.positions.path, { portfolioId: portfolioInfo.data?.id }));
    } catch (e) {
      toast.error('Oops, could not add your positions');
      setLoading(false);
    }
  };

  const downloadInvalidEntries = () => {
    const dictionary = {
      A: 'Ticker',
      B: 'Quantity',
      C: 'Price',
      D: 'Custodian',
      error: 'error',
    };
    const toParse: any[] = [];
    Object.values(unparsedData).forEach((row) => {
      const toRet = {};
      Object.keys(dictionary).forEach((key) => {
        // @ts-ignore
        toRet[dictionary[key]] = row[key] || '';
      });

      toParse.push(toRet);
    });
    const newWorkbook = utils.book_new();
    const worksheetTrades = utils.json_to_sheet(toParse);
    utils.book_append_sheet(newWorkbook, worksheetTrades, 'Transactions');

    writeFile(
      newWorkbook,
      `[${portfolioInfo?.data!.name}]_[${portfolioInfo?.data!.clientName}]_Errored_Transactions_${moment().format(
        'DD-MMM-YYYY'
      )}.xlsx`
    );
  };

  return (
    <div>
      <PositionsHeader
        setTradeTime={setTradeTime}
        tradeTime={tradeTime!}
        totalWeight={totalWeight}
        setTotalWeight={setTotalWeight}
        checked={autoAllocate}
        onToggle={setAutoAllocate}
        onDrop={onDrop}
        custodian={custodian}
        setCustodian={setCustodian}
        options={options}
      />
      <div style={{ height: 30 }} />
      <div className={s.formFooter}>
        <span className={cn('text-gray', s.amountWrapper)}>
          Total Value:
          {' ' +
            portfolioInfo.data?.currency.symbol +
            ' ' +
            grossTradeAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
        <Button
          variant="outline-warning"
          disabled={!tableData.length}
          className={s.clearButton}
          size={'small'}
          onClick={onClearPositions}
        >
          Clear All
        </Button>
        <Button disabled={!enabledSave || loading} className={s.saveButton} size={'small'} onClick={onSavePositions}>
          Save
        </Button>
      </div>

      <Table className={s.table} headerHeight={30} rowHeight={35} columns={TABLE_COLUMNS} data={tableData} />

      {addingMore && tableData.length > 0 && (
        <div className={s.loaderContainer}>
          <span style={{ marginRight: 4 }}> Adding your asset, please wait.. </span> <Loader />
        </div>
      )}
      <AddRow />

      <Modal
        dialogClassName={s.modalOuterWrapper}
        className={s.modalContainer}
        full={false}
        show={!!positionModal || positionModal === 0}
        onHide={() => setPositionModal(null)}
      >
        <Modal.Header>
          <Modal.Title>Search Instrument</Modal.Title>
        </Modal.Header>
        <div className={s.modalWrapper}>
          <AddRow isModal={true} />
          {addingMore && <Spinner />}
        </div>
      </Modal>
      {!!errorMessage && (
        <div style={{ zIndex: 999 }}>
          <Confirm
            title={'Upload Issues found'}
            confirmBtnText="OK"
            info={true}
            onCancel={() => {
              setErrorMessage('');
            }}
            onConfirm={() => {
              setErrorMessage('');
            }}
          >
            <div>
              <span>{errorMessage}</span>
            </div>
            <Button style={{ marginTop: 20, marginBottom: 20 }} onClick={downloadInvalidEntries}>
              Download invalid entries
            </Button>
          </Confirm>
        </div>
      )}
    </div>
  );
};

export default PositionEntry;
