import React, { FC } from 'react';
import axios from 'axios';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import cn from 'classnames';
import s from './ReportGenerator.module.css';
import { Card } from '@iliotech/storybook';
import { PortfolioHeader } from '../../components/PortfolioHeader';
import { Loader } from '../../../../components/UIWidgets/Loader';
import { withPortfolioInfo } from '../../../../components/HOC';
import { IWithPortfolioInfoProps } from '../../../../components/HOC/withPortfolioInfo';
import { CognitoHelper } from '../../../../services/utils/CognitoHelper';
import { Button, Toggle } from 'rsuite';
import PNLSettings from './components/PNLSettings';
import RiskSettings from './components/RiskSettings';
import AllocationSettings from './components/AllocationSettings';
import SensitivitySettings from './components/SensitivitySettings';
import { GROUPING_OPTIONS, POSITION_GROUPING_OPTIONS } from './utils/options';
import useApi from '../../../../services/hooks/useApi';
import { API_URL } from '../../../../services/constants/endpoints';
import { useAccount } from '../../../../services/context/AuthContext';
import { usePeriod } from '../../../../services/context/PeriodContext';
import { SidePeriod } from '../../../../components/Sidebar/components/SidePeriod';
import moment from 'moment';
import { PERIOD_TYPE } from '../../../../services/constants/constants';
import { useCapabilities } from '../../../../services/hooks/apiHooks/useCapabilities';

const format = (d: Date) => (console.log('DATE', d), dateFormat(d, 'yyyy-mm-dd'));
// const today = new Date();
// const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

// const getEndOfLastMonth = () => new Date(today.getFullYear(), today.getMonth(), 0);
// const getTodayDate = () => new Date(today.getFullYear(), today.getMonth(), today.getDate());

const DEFAULT_SETTINGS = {
  performance: true,
  assetClassPnl: true,
  attributionPnl: true,
  returnVolatilityDaily: true,
  returnVolatilityMonthly: false,
  returnsHistogramDaily: false,
  returnsHistogramMonthly: false,
  allPnl: true,
  structure: true,
  openPositions: false,
  transactionHistory: false,
  sensitivity: false,
  overview: true,
  sensitivityOptions: {
    equityPercent: -10,
    fxPercent: -2,
    yieldPercent: 0,
    volatilityPercent: 0,
  },
  sensitivityGrouping: GROUPING_OPTIONS[0],
  positionGrouping: POSITION_GROUPING_OPTIONS[0],
  insights: false,
};

const ReportGenerator: FC<IWithPortfolioInfoProps> = ({ portfolioInfo }) => {
  const { preferences } = useCapabilities();
  const riskAssetView = portfolioInfo.riskAssetView ?? preferences.defaultRiskAssetView;
  const portfolioId = portfolioInfo?.id;
  const scenarioMultipliersProviderEndpoint =
    API_URL + 'api/v1/positions/portfolio/' + portfolioId + '/scenarios/coefficients';

  const { currentTheme } = useAccount();

  const { isFetching, data } = useApi(
    scenarioMultipliersProviderEndpoint,
    {},
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const [loading, setLoading] = React.useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false);
  const [settings, setSettings] = React.useState<Record<string, any>>(DEFAULT_SETTINGS);
  const [reportType, setReportType] = React.useState<ReportType>('DEFAULT');

  const { name } = portfolioInfo;
  const inceptionDate = portfolioInfo?.inceptionDate;

  const { period } = usePeriod();

  const updateSettings = (newSettings: Record<string, any>) => {
    setSettings({ ...settings, ...newSettings });
  };

  const runReport = async () => {
    setLoading(true);
    await CognitoHelper.withAuthorisationToken();

    // @ts-ignore;
    let env = window.environmentSettings.lambdaEnv;

    // to support local host
    if (env === '${REACT_APP_LAMBDA_ENVIRONMENT_NAME}') {
      const nodeEnv = process?.env?.NODE_ENV;

      switch (nodeEnv) {
        case 'development':
          env = 'dev';
          break;
        case 'production':
          env = 'stage';
          break;
        default:
          // @ts-ignore
          env = window.environmentSettings.lambdaEnv;
      }
    }

    // Switch next line for testing on localhost
    const url = `https://report-generator-lambda-${env || 'prod'}.illio.com/generate`;
    //const url = `http://localhost:3030/generate`;

    // * Ensure the end date is based on the current system time, if "since inception" is selected.
    let startDate = '';
    let endDate = '';

    if (period) {
      startDate = String(period.from ?? portfolioInfo?.inceptionDate);
      endDate = String(period.to);
    }

    const fromDate = startDate
      ? format(moment(startDate).toDate())
      : format(moment(portfolioInfo?.inceptionDate).toDate());
    const toDate = endDate ? format(moment(endDate).toDate()) : null;

    axios
      .post(
        url,
        {
          pages: {
            ...settings,
            sensitivityGrouping: settings.sensitivityGrouping.id,
            positionGrouping: settings.positionGrouping.id,
            transactionHistory: settings.transactionHistory ? 'REPORTING_PERIOD' : 'NONE',
          },
          assetType: riskAssetView === 'RISK_ASSET_CLASS' ? 'RiskAssetClass' : 'AssetClass',
          portfolioId,
          reportType,
          fromDate,
          toDate,
          isInception: period.type === PERIOD_TYPE.INCEPTION,
          // primaryColor: "#01693F",
          // primaryColor: "#206887",
          primaryColor: currentTheme?.colors?.['--bg-color'],
          logoUrl: currentTheme?.iconOnLight ?? currentTheme?.icon,
          // logoUrl: "https://westbayfg.com/wp-content/uploads/2020/02/westbay-logo.png"
        },
        {
          responseType: 'blob',
          timeout: 90000,
          headers: {
            Accept: 'application/pdf',
          },
        }
      )
      .then((response) => {
        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = objectURL;
        link.setAttribute('download', `${name} ${dateFormat(new Date(), 'yyyy-mm-dd')} .pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        toast.error('Sorry - we were unable to generate your report');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const allowAdvancedSettings = !reportType.includes('CONSUMER');

  const updateReportType = (type: ReportType, hideAdvancedSettings?: boolean) => {
    setReportType(type);
    if (hideAdvancedSettings) {
      setShowAdvancedSettings(false);
    }
  };

  if (isFetching) {
    return <Loader />;
  }
  return (
    <div>
      <PortfolioHeader subtitle={'Reports'} showPortfolioInfo={true} showDashboardMenu={false} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={cn(s.reportButtonSurround, 'bg', reportType === 'CONSUMER-1' && s.activeButton)}
          onClick={() => updateReportType('CONSUMER-1', true)}
        >
          Highlights
        </div>
        <div
          className={cn(s.reportButtonSurround, 'bg', reportType === 'CONSUMER-2' && s.activeButton)}
          onClick={() => updateReportType('CONSUMER-2', true)}
        >
          Standard
        </div>
        <div
          className={cn(s.reportButtonSurround, 'bg', reportType === 'DEFAULT' && s.activeButton)}
          onClick={() => updateReportType('DEFAULT')}
        >
          Custom
        </div>
        <div
          className={cn(s.reportButtonSurround, 'bg', reportType === 'EXECUTIVE' && s.activeButton)}
          onClick={() => updateReportType('EXECUTIVE')}
        >
          Custom (high level)
        </div>
      </div>
      <Card className={cn(s.settingsArea, showAdvancedSettings && s.wide)}>
        <h3>Report settings</h3>
        <br />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <SidePeriod inceptionDate={inceptionDate} />
          </div>
          <div style={{ flex: showAdvancedSettings ? 2 : 0 }} />
        </div>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center', marginTop: 10 }}>
          <Toggle onChange={(v) => updateSettings({ insights: v })} checked={settings.insights} />
          <span>Include insights</span>
        </div>

        {showAdvancedSettings && (
          <div>
            <div className={cn(s.advancedSettingsSurround)}>
              <PNLSettings reportType={reportType} settings={settings} updateSettings={updateSettings} />
              <RiskSettings settings={settings} updateSettings={updateSettings} />
              <AllocationSettings settings={settings} updateSettings={updateSettings} />
              <SensitivitySettings data={data} settings={settings} updateSettings={updateSettings} />
            </div>
          </div>
        )}
        {allowAdvancedSettings && (
          <div className={cn(s.settingsRow)}>
            <span />
            <Button
              appearance={'default'}
              size={'xs'}
              style={{ marginTop: 10 }}
              onClick={() => {
                setShowAdvancedSettings((prev) => !prev);
              }}
            >
              {showAdvancedSettings ? 'Basic Settings' : 'Advanced settings'}
            </Button>
          </div>
        )}
      </Card>

      <div>
        <article className={cn(s.reportButtonSurround, s.generate, 'bg')} onClick={runReport}>
          <span className={cn(s.reportButtonLabel)}>Generate Report</span>
        </article>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default withPortfolioInfo(ReportGenerator);
