import get from 'lodash.get';

export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

type IEndpoint = string | { [key: string]: IEndpoint };

// interface IAPIConfig {
//   url: string;
//   version: string;
//   endpoints: Record<string, IEndpoint>;
// }

export const API = {
  url: 'api',
  version: 'v1',
  endpoints: {
    portfolio: {
      esg: {
        ranking: 'esg/ranking',
        scores: 'esg/weighted-score/:portfolioId',
      },
      fees: 'portfolio/fees',
      feesSinceInception: 'portfolio/feesSinceInception',
      currencies: 'portfolio.currencies',
      indexes: 'portfolio.indexes',
      region: 'regions.all',
      info: 'portfolio',
      allocations: 'portfolio.allocation',
      getPnLByAllocation: 'portfolio.profit.history',
      fundamentals: 'portfolio.equity.fundamentals.new',
      bondFundamentals: 'portfolio.fixed-income.fundamentals.new',
      profit: {
        allocationBreakdown: 'portfolio.profit.allocation.breakdown',
        breakdownPosition: 'portfolio/:portfolioId/position/:positionId',
        breakdown: 'portfolio.pnl.breakdown',
        assetClass: 'portfolio.profit',
        attribution: 'portfolio.profit.breakdown',
        chart: 'portfolio.profit.chart',
        pnlByAttribution: 'portfolio.profit.attribution',
        pnlByAssetClass: 'portfolio.profit.assetClass',
        options: 'portfolio.equity.options', // from "portfolio-profit-controller
      },
      performance: 'portfolio.performance',
      cumulativePerformance: 'twr/pnl/v1',
      structure: 'portfolio.structure',
      wealth: 'portfolio.wealth',
      events: 'portfolio/:portfolioId/events',
    },
    portfolioManager: {
      list: 'portfolios',
      create: 'portfolio',
      delete: 'portfolio.delete',
      updateSettings: 'portfolio',
      update: 'portfolio.update',
      link: 'link.users',
      linkedUsers: 'linked.users',
      transfer: 'account/portfolio/transfer',
      share: 'account/portfolioShare',
    },
    instruments: {
      get: 'instrument.get',
      query: 'instruments',
      find: 'instrument.find',
      list: 'instrument.list',
      create: 'instrument.non-market.create',
      update: 'instrument.non-market.update',
      delete: 'instrument.non-market.delete',
      options: 'instruments/options',
      search: 'instruments/asset',
      getOrCreate: 'instrument/sourceId/:sourceId/sourceData/:sourceData',
    },
    market: {
      list: 'market.list',
      create: 'market.create',
      update: 'market.update',
      delete: 'market.delete',
    },
    income: {
      list: 'income.list',
      create: 'income.create',
      update: 'income.update',
      delete: 'income.delete',
      detailed: 'income/detailed',
      repayments: 'income/principal/repayments',
      // yieldToMaturity: 'income/yieldToMaturity/monthByMonth', // Seems redundant but needs a double-check
      yield: 'income/yieldToMaturity/monthByMonth',
    },
    cashflowLedger: 'cashflow-ledger',
    dashboard: {
      indexPerformance: 'index.performance',
      info: 'dashboard.info',
    },
    recommendations: {
      list: 'recommendation',
      performance: 'recommendation.performance',
      apply: 'recommendation.apply',
    },
    equity: {
      dividends: 'equity.dividends',
      dividendsDetailed: 'income/detailed',
      equityDividends: 'Equities/dividends',
      fundDividends: 'Funds/dividends',
    },
    credit: {
      coupons: 'credit.coupons',
      maturity: 'credit.ytm',
      repayments: 'credit.principal.repayments',
    },
    alternatives: {
      dividends: 'alternatives.dividends',
    },
    reports: {
      history: 'report.history',
      income: 'report.income',
      info: 'report.portfolio',
    },
    topList: 'position.top',
    topPositions: 'portfolio/positions.top.ranked',
    topPositionsByPnL: 'portfolio/positions.top.performance',
    snapshot: {
      snapshots: 'portfolio.snapshot',
      preview: 'portfolio.preview.snapshot',
      trades: {
        all: 'portfolio.trades',
        add: 'portfolio.add.trades',
        addFromPosition: 'portfolio.add.trade.from.position',
        addDividendsFromPosition: 'portfolio.add.dividends.from.position',
        addEarnsFromPosition: 'portfolio.add.earn.from.position',
        amend: 'portfolio.amend.trade',
        remove: 'portfolio.delete.trade',
      },
      tradeUpload: 'portfolio/:portfolioId/trades',
      tempTrades: {
        all: 'portfolio.trades.blotter',
        remove: 'portfolio.remove.trades',
        update: 'portfolio.update.trades',
      },
      uploadTrades: 'trades/upload',
      confirmTrades: 'portfolio.confirm.trades',
      discardTrades: 'portfolio.discard.unconfirmed.trades',
      fxRate: {
        get: 'fx-rate',
      },
      price: {
        get: 'price',
        getByInstrumentId: 'price/instrument',
      },
      news: {
        getByInstrumentId: 'news',
      },
      adjustments: {
        searchTypes: 'instruments/adjustments',
      },
    },
    assets: {
      all: 'asset.all',
      light: 'asset.light',
      subclass: {
        all: 'asset.subclass.all',
      },
    },
    realAssets: {
      list: 'real-asset-instrument/search',
    },
    users: {
      all: 'user.all.get',
      userInfo: 'user.get',
      create: 'user.create',
      update: 'user.update',
      delete: 'user.delete',
    },
    account: {
      authorise: 'authorise',
      subscribe: 'subscribe',
      updateInfo: 'user.self.update',
      users: {
        list: 'account/users',
        create: 'account/user',
      },
      terms: 'account/terms',
    },
    custodian: {
      all: 'custodian.all',
    },
    industrysector: {
      all: 'industrysector.all',
    },
    sector: {
      all: 'sector.all',
    },
    riskassetclass: {
      all: 'risk.asset.class.all',
    },
    currency: {
      all: 'currency.all',
    },
    country: {
      all: 'country.all',
    },
    user: {
      preferences: 'user/preferences',
    },
    userCapabilities: 'account/capabilities',
    accounts: 'account/summaries',
    app: {
      uploadTemplates: {
        positions: {
          add: 'portfolio.add.positions',
          upload: 'uploadPositionTemplateV2',
        },
        all: 'uploadTemplate',
        upload: 'uploadTemplateV2',
      },
    },
    workgroups: {
      updateName: 'workgroup/:workgroupId',
      get: 'workgroup/:workgroupId',
      list: 'workgroup/list',
      assign: 'workgroup/:workgroupId/client/:clientId',
      share: 'workgroup/:workgroupId/manager',
    },
  },
};

// export function getAuthUrl(endpoint: string) {
//   const endpointUrl = get(API.endpoints, endpoint);
//
//   if (!endpointUrl) {
//     throw new Error(`There is no ${endpoint} endpoint`);
//   }
//   return `${API_URL}${API.url}/${endpointUrl}`;
// }

export function getApiUrl(endpoint: string, portfolioId?: string) {
  const endpointUrl = get(API.endpoints, endpoint);

  if (!endpointUrl) {
    throw new Error(`There is no ${endpoint} endpoint`);
  }
  return `${API_URL}${BASE_URL ? `${BASE_URL}/` : ''}${API.url}/${API.version}/${endpointUrl}${
    portfolioId ? '/' + portfolioId : ''
  }`;
}

export function getCustomApiUrl(endpoint: string, params: Array<{ idLabel: string; idValue: string }>) {
  const endpointUrl = get(API.endpoints, endpoint);

  if (!endpointUrl) {
    throw new Error(`There is no ${endpoint} endpoint`);
  }

  let newEndpoint = `${API_URL}${BASE_URL ? `${BASE_URL}/` : ''}${API.url}/${API.version}/${endpointUrl}`;
  params.map((item) => {
    newEndpoint = newEndpoint.replace(item.idLabel, item.idValue);
  });

  return newEndpoint;
}

export function getMobileApiUrl(endpointUrl: string) {
  return `${API_URL}${BASE_URL ? `${BASE_URL}/` : ''}${API.url}/mobile/${API.version}/${endpointUrl}`;
}

export const getSysadminUrl = () => {
  const portString = window.location.hostname === 'localhost' ? `:3005` : '';
  return `${window.location.protocol}//${window.location.hostname}${portString}/admin`;
};
