import React, { FC } from 'react';
import cn from 'classnames';
import s from '../ReportGenerator.module.css';
import { Button, Checkbox } from 'rsuite';
import { Toggle } from './Toggle';

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
  reportType: ReportType;
}

const PNLSettings: FC<IOwnProps> = ({ settings, updateSettings, reportType = 'DEFAULT' }) => {
  const isAllSelected =
    reportType === 'DEFAULT'
      ? !!settings.performance && !!settings.assetClassPnl && !!settings.attributionPnl
      : !!settings.allPnl && !!settings.attributionPnl;

  const toggleAll = () => {
    if (reportType === 'DEFAULT') {
      updateSettings({ performance: !isAllSelected, assetClassPnl: !isAllSelected, attributionPnl: !isAllSelected });
    } else {
      updateSettings({ performance: !isAllSelected, allPnl: !isAllSelected });
    }
  };

  const PnlToggle = ({ field }: { field: string }) => (
    <Toggle field={field} settings={settings} updateSettings={updateSettings} />
  );

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <h3>P&L Attribution</h3>
      <br />
      <div className={cn(s.settingsRow)}>
        <span>Portfolio overview</span>
        <PnlToggle field={'overview'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Portfolio Performance</span>
        <PnlToggle field={'performance'} />
      </div>
      {reportType === 'EXECUTIVE' && (
        <div className={cn(s.settingsRow)}>
          <span>P&L by Attribution and Asset Class</span>
          <PnlToggle field={'allPnl'} />
        </div>
      )}
      {reportType === 'DEFAULT' && (
        <div className={cn(s.settingsRow)}>
          <span>P&L by Asset Class</span>
          <PnlToggle field={'assetClassPnl'} />
        </div>
      )}
      {reportType === 'DEFAULT' && (
        <div className={cn(s.settingsRow)}>
          <span>P&L by Attribution</span>
          <PnlToggle field={'attributionPnl'} />
        </div>
      )}
      <Button style={{ float: 'right' }} size={'xs'} appearance={'subtle'} onClick={toggleAll}>
        Select {isAllSelected ? 'none' : 'all'}
      </Button>
    </div>
  );
};

export default PNLSettings;
