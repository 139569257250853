import { AnyAction, combineReducers } from 'redux';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { ILoadingPeriodDataState } from '../../models/redux/ILoadingPeriodDataState';
import { ReportsActions } from '../actions';
import { PERIOD_TYPE } from '../constants/constants';
import { PeriodHelper } from '../utils/PeriodHelper';
import { ReduxHelper } from '../utils/ReduxHelper';

export interface IReportInfo {
  total: IDashboardReport;
  next: IDashboardReport;
  past: IDashboardReport;
}

type IReportInfoState = ILoadingDataState<IReportInfo>;

export interface IReportsState {
  income: ILoadingPeriodDataState<IBarData[]>;
  breakdownHistory: ILoadingPeriodDataState<IBarData[]>;
  info: IReportInfoState;
}

const INITIAL_REPORT_INFO: IReportInfo = {
  total: { value: 0, diff: 0 },
  next: { value: 0, diff: 0 },
  past: { value: 0, diff: 0 },
};

const INITIAL_HISTORY_STATE: ILoadingPeriodDataState<IBarData[]> = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const breakdownHistoryDefault = ReduxHelper.createHttpRequestReducer<ILoadingPeriodDataState<IBarData[]>>(
  INITIAL_HISTORY_STATE,
  ReportsActions.REPORT_HISTORY_REQUEST,
  ReportsActions.REPORT_HISTORY_SUCCESS,
  ReportsActions.REPORT_HISTORY_ERROR,
  ReportsActions.REPORT_HISTORY_RESET
);

const breakdownHistory = (state = INITIAL_HISTORY_STATE, action: AnyAction) => {
  switch (action.type) {
    case ReportsActions.SET_HISTORY_PERIOD:
      return {
        ...state,
        period: action.payload,
      };
    default:
      return breakdownHistoryDefault(state, action);
  }
};

const INITIAL_INCOME_STATE: ILoadingPeriodDataState<IBarData[]> = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const incomeDefault = ReduxHelper.createHttpRequestReducer<ILoadingPeriodDataState<IBarData[]>>(
  INITIAL_INCOME_STATE,
  ReportsActions.REPORT_INCOME_REQUEST,
  ReportsActions.REPORT_INCOME_SUCCESS,
  ReportsActions.REPORT_INCOME_ERROR,
  ReportsActions.REPORT_INCOME_RESET
);

const income = (state = INITIAL_INCOME_STATE, action: AnyAction) => {
  switch (action.type) {
    case ReportsActions.SET_INCOME_PERIOD:
      return {
        ...state,
        period: action.payload.period,
        currency: action.payload.currency,
      };
    default:
      return incomeDefault(state, action);
  }
};

export default combineReducers<IReportsState>({
  income,
  breakdownHistory,
  info: ReduxHelper.createHttpRequestReducer<IReportInfoState>(
    ReduxHelper.createInitialState(INITIAL_REPORT_INFO),
    ReportsActions.REPORT_INFO_REQUEST,
    ReportsActions.REPORT_INFO_SUCCESS,
    ReportsActions.REPORT_INFO_ERROR,
    ReportsActions.REPORT_INFO_RESET
  ),
});
