import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import s from './FormFieldWrapper.module.css';

interface IAddFormBlockProps {
  label: string;
  required?: boolean;
  className?: string;
  labelClassName?: string;
  hidden?: boolean | null;
}

const FormFieldWrapper: FunctionComponent<PropsWithChildren<IAddFormBlockProps>> = (props) => {
  const { className, children, label, required, hidden, labelClassName } = props;
  if (hidden === true) {
    return null;
  }
  return (
    <div className={cn(s.fieldWrapper, className)}>
      <div className={cn(s.fieldLabel, labelClassName)}>
        {label}
        <sup>{required ? '*' : ''}</sup>
      </div>
      <div className={s.fieldContent}>{children}</div>
    </div>
  );
};

export default React.memo(FormFieldWrapper);
