import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import FeeType from '../../components/ConnectedTransactionInputs/FeeType';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { connect } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import Amount from '../../components/ConnectedTransactionInputs/Amount';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';
import SettlementOptions from '../../components/ConnectedTransactionInputs/SettlementOptions';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import CurrencyList from '../../components/ConnectedTransactionInputs/CurrencyList';
import { renderToggleBtns } from '../../components';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: ICashAdjustmentFormValues;
  cashAdjustmentTypes: IRootState['snapshot']['snapshotEdit']['cashAdjustmentTypes']['data'];
  snapshotFxRate: IRootState['snapshot']['snapshotEdit']['fxRate']['data'];
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchCashAdjustmentTypes(currencyString: string): void;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
}

export interface ICashAdjustmentFormValues {
  instrument?: IOption<string>;
  other: string;
  currency: IOption<string>;
  tradeTime: string;
  fxRate: number | null;
  amount: number;
  settlementOption: IPortfolioTrade['settlementOption'];
  tradeType: IAssetClass;
  feeOperation: 'DEDUCTION' | 'REBATE';
  custodian: string | IOption<ICustodian> | any;
  notes?: string;
  asset: 'CshAdjustment';
  key?: string;
}

type FormUpdater = <T extends keyof ICashAdjustmentFormValues>(
  field: T,
  data: ICashAdjustmentFormValues[T] | null
) => void;

type Props = IAssetEntryComponentProps<ICashAdjustmentFormValues> &
  InjectedFormProps<ICashAdjustmentFormValues> &
  IMapStateToProps &
  IDispatchToProps;

const CashAdjustmentEntry: FC<Props> = ({
  editMode,
  custodianOptions,
  baseCurrencyCode,
  formValues,
  fetchCashAdjustmentTypes,
  cashAdjustmentTypes,
  resetForm,
  resetEditTrade,
  removeTrade,
  initialValues,
  snapshotFxRate,
  fetchFxRate,
  portfolioInfo,
  updateFormValue,
  currencies,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  updateTempTrade,
}) => {
  React.useEffect(() => {
    return () => resetForm(null);
  }, []);

  React.useEffect(() => {
    resetForm(initialValues);
    const currency = initialValues.currency?.name;
    if (typeof currency === 'string') {
      const conv = fx({ fromCurrencyName: currency, toCurrencyName: portfolioInfo?.currency?.name });
      updateFormValue('fxRate', conv.naturalToConvention(initialValues.fxRate ?? 1));
    }
  }, [initialValues]);

  const fx = useFx();
  const convention = fx({
    fromCurrencyName: formValues?.currency?.value,
    toCurrencyName: portfolioInfo?.currency?.name,
  });

  React.useEffect(() => {
    if (formValues?.currency?.value) {
      fetchCashAdjustmentTypes(formValues?.currency?.value);
    }
  }, [formValues?.currency?.value]);

  const isFormInvalid = React.useCallback(() => {
    return (
      formValues.fxRate === 0 ||
      isValueInvalid(formValues.amount) ||
      isValueInvalid(formValues.instrument?.id || formValues.instrument?.value || formValues.instrument?.name)
    );
  }, [formValues.fxRate, formValues.amount, formValues.instrument])();

  const currencyValue = formValues?.currency?.value;
  const tradeIsInPortfolioCurrency = !currencyValue || baseCurrencyCode === currencyValue;
  const absoluteFxRate = tradeIsInPortfolioCurrency
    ? 1
    : (convention.isConvention ? formValues.fxRate : 1 / (formValues.fxRate || 1)) ?? 1;

  const getFx = (tradeTime?: string, currency?: string) => {
    if (!portfolioInfo?.currency?.name || !currency || !tradeTime) {
      return;
    }
    fetchFxRate(portfolioInfo.currency.name, currency, tradeTime)
      .then((result) => {
        if (result.data?.firstFxRate) {
          updateFormValue('fxRate', result.data.firstFxRate);
        }
      })
      .catch(() => {});
  };

  const onTradeTimeChange = (date: string) => {
    const currency = formValues.currency?.value;
    if (initialValues.checkedOut) {
      return;
    }
    getFx(date, currency);
  };

  const onCurrencyChange = (currency: IOption<string>) => {
    const date = formValues.tradeTime;
    getFx(date, currency?.value);
    updateFormValue('instrument', null);
    updateFormValue('settlementOption', 'BASE');
  };

  const updateTrade = (e: any) => {
    e.preventDefault?.();

    const isConvention = convention?.isConvention;

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: absoluteFxRate,
      tradeTime: formValues.tradeTime,
      operation: formValues.feeOperation,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      notes: formValues.notes,
      tradeCosts: 0,
      type: formValues.instrument?.name,
      commission: 0,
      price: 0,
      key: formValues.key,
    };

    updateTempTrade!(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetEditTrade();
    });
  };

  const fxDescription = convention.getDescription({ conventionRate: formValues.fxRate });

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: absoluteFxRate,
      tradeTime: formValues.tradeTime,
      operation: formValues.feeOperation,
      custodian:
        typeof formValues.custodian === 'string'
          ? formValues.custodian
          : formValues.custodian?.value || (undefined as any),
      notes: formValues.notes,
      tradeCosts: 0,
      type: formValues.instrument?.name,
      commission: 0,
      price: 0,
    };

    saveTrades(portfolioInfo!.id, [trade]).then(() => {
      resetForm(initialValues);
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
    });
  };

  const availableSettlementOptions = () => {
    const settlementBtns: Array<{ text: string; value: any; subText?: string }> = [
      { text: baseCurrencyCode ?? 'NA', value: 'BASE' },
      { text: `TRANSFER`, value: 'AUTO' },
    ];

    if (!tradeIsInPortfolioCurrency) {
      settlementBtns.splice(1, 0, { text: currencyValue!, value: 'LOCAL' });
    }
    return settlementBtns;
  };
  return (
    <form className={s.formGrid} onSubmit={editMode ? updateTrade : saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime onChange={onTradeTimeChange} />
        <CurrencyList editMode={!!editMode} onChange={onCurrencyChange} baseCurrenciesOnly={true} includeNok={true} />
        <FeeType disabled={editMode} types={cashAdjustmentTypes} />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper className={s.settlementField} label="Operation">
          <Field name="feeOperation" values={OPERATIONS} className="input--small" component={renderToggleBtns} />
        </FormFieldWrapper>
        <Amount />
        <FXRate label={fxDescription} disabled={tradeIsInPortfolioCurrency} />
        <Custodian />
        <AddCustodianButton />
        <SettlementOptions options={availableSettlementOptions()} />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>
      {/*<h2>*/}
      {/*  {convention.isConvention ? "conv" : "not"} {absoluteFxRate}*/}
      {/*</h2>*/}
      <div className={s.formFooter}>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  formValues: state.form[FORMS_NAME.transactionFee].values as ICashAdjustmentFormValues,
  cashAdjustmentTypes: state.snapshot.snapshotEdit.cashAdjustmentTypes.data,
  snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data as any, // .fxRate.data,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(FORMS_NAME.transactionFee, field, data));
  },
  fetchCashAdjustmentTypes: (currencyString: string) => {
    dispatch(SnapshotActions.fetchAdjustmentTypes(currencyString));
  },
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const OPERATIONS = [
  { text: 'Deduction', value: 'DEDUCTION' },
  { text: 'Rebate', value: 'REBATE' },
];

export default reduxForm<ICashAdjustmentFormValues, IAssetEntryComponentProps<ICashAdjustmentFormValues>>({
  form: FORMS_NAME.transactionFee,
  validate,
  destroyOnUnmount: false,
})(connect(mapStateToProps, mapDispatchToProps)(CashAdjustmentEntry));

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};
