import echarts from 'echarts/lib/echarts';
import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Period } from '../../../../components';

import { DashboardActions } from '../../../../services/actions';
import { BarType } from '../../../../services/constants/charts';
import { CHARTS_PERIODS } from '../../../../services/constants/constants';
import { IPortfolioInfoState } from '../../../../services/reducers/PortfolioReducers';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import Widget, { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import HistoryChart from './HistoryChart/HistoryChart';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../components/ContextHelp';
import { EQUITY_PNL_BREAKDOWN_HELP_TEXT } from '../../../../services/constants/tooltipContextHelp';

interface IMapStateToProps {
  profitChartData: IBarData[];
  selectedPeriod: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  breakdownPosition: IBreakdownPosition;
  portfolioInfo: IPortfolioInfoState;
}

interface IDispatchToProps {
  onPeriodChange: (period: IPeriod) => void;
}

type IProps = IMapStateToProps &
  IDispatchToProps &
  Pick<IWidgetProps, 'colNum' | 'title' | 'full'> & { hidePeriodSelector?: boolean; legendVariant?: string };

class PositionHistoryWidgetContainer extends PureComponent<IProps> {
  renderPerformancePeriod = (): ReactNode => {
    const { selectedPeriod, onPeriodChange } = this.props;
    return this.props.hidePeriodSelector ? null : (
      <Period options={CHARTS_PERIODS} selectedPeriod={selectedPeriod} onPeriodChange={onPeriodChange} />
    );
  };

  render() {
    const { colNum, portfolioCurrencyFormatter } = this.props;
    const dataAll: Array<[string, number]> = [];
    const currentPosition: Array<[string, number]> = [];
    const baseCurrencySymbol: string =
      (this.props.portfolioInfo.data && this.props.portfolioInfo.data.currency.symbol) || '';
    this.props.breakdownPosition.buysAndSells.map((el) => {
      if (el.isBuy) {
        return dataAll.push([el.date, el.quantity]);
      } else {
        return dataAll.push([el.date, el.quantity * -1]);
      }
    });

    const positiveData: Array<[string, number]> =
      this.props.breakdownPosition.buysAndSells && this.props.breakdownPosition.current
        ? dataAll.map((el) => {
            if (el[1] >= 0) {
              return el;
            } else {
              return [el[0], 0];
            }
          })
        : [];
    positiveData.push([
      'Current',
      this.props.breakdownPosition.current.quantity < 0 ? this.props.breakdownPosition.current.quantity * -1 : 0,
    ]);

    const negativeData: Array<[string, number]> =
      this.props.breakdownPosition.buysAndSells && this.props.breakdownPosition.current
        ? dataAll.map((el) => {
            if (el[1] < 0) {
              return el;
            } else {
              return [el[0], 0];
            }
          })
        : [];
    negativeData.push([
      'Current',
      this.props.breakdownPosition.current.quantity >= 0 ? this.props.breakdownPosition.current.quantity * -1 : 0,
    ]);

    const chartDatas: IBarData[] = [
      { name: 'Buy', type: BarType.Solid, data: positiveData },
      { name: 'Sell', type: BarType.Hatching, data: negativeData },
    ];

    const chartOptOverrides = {
      tooltip: {
        formatter: (params: echarts.EChartOption.Tooltip.Format | echarts.EChartOption.Tooltip.Format[]) => {
          const data = Array.isArray(params) ? params[0] : params;
          const type = data.name === 'Current' ? 'Current' : data.seriesIndex === 3 ? 'Sell' : 'Buy';

          const value = Array.isArray(data.value) ? data.value[0] : data.value === undefined ? 0 : data.value; // * (data.seriesIndex === 3 ? -1 : 1);
          return `${type}: ${value.toLocaleString()}`;
        },
        backgroundColor: '#fff',
        borderColor: '#ccc',
        textStyle: {
          color: '#555',
          fontSize: 11,
        },
        borderWidth: 1,
        extraCssText: 'border-radius:4px;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);}',
        padding: [0, 3, 1, 7],
      },
    };

    const chartTitle = 'Position history waterfall';

    return (
      <Widget colNum={colNum}>
        <h3>
          {chartTitle}
          <ContextHelpIcon title={chartTitle || ''} elementId={chartTitle || 'tmpID'} />
        </h3>
        <ContextHelpTooltip
          elementId={chartTitle}
          tooltipTitle={chartTitle}
          tooltipText={EQUITY_PNL_BREAKDOWN_HELP_TEXT.ChartPositionHistoryWaterfall}
        />
        <HistoryChart
          isWaterFall={true}
          baseCurrencySymbol={baseCurrencySymbol}
          profitChartData={chartDatas}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          legendVariant={this.props.legendVariant}
          chartOptOverrides={chartOptOverrides}
        />
      </Widget>
    );
  }
}

const mapStateToProps: (state: any) => IMapStateToProps = (state: any) => {
  return {
    profitChartData: state.dashboard.profitAttribution.data,
    breakdownPosition: state.dashboard.breakdownPosition.data,
    selectedPeriod: state.dashboard.profitAttribution.period,
    portfolioInfo: state.portfolio.portfolioInfo,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  onPeriodChange: (period: IPeriod) => dispatch(DashboardActions.setProfitAttributionPeriod(period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PositionHistoryWidgetContainer);
