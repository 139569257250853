import React from 'react';
import useApi from '../useApi';
import { ICashflowItem, ICashflowAPIResponse } from '../../../models/IPortfolioTrade';
import { API, getApiUrl } from '../../constants/endpoints';

const DEFAULT_STATE = {
  cashflowData: [],
  isFetching: true,
};

interface ICashflowData {
  cashflowData: ICashflowItem[];
}

interface IHookProps {
  positionId: string;
  fromDate: string;
  toDate: string;
  quantity: number;
}

export const usePositionCashflow = ({ positionId, fromDate, toDate, quantity }: IHookProps) => {
  const request = useApi<ICashflowAPIResponse['data']>(getApiUrl('cashflowLedger', positionId), {
    method: 'GET',
    params: {
      from: fromDate ? new Date(fromDate).toISOString().split('T')[0] : null,
      to: toDate ? new Date(toDate).toISOString().split('T')[0] : null,
      quantity,
    },
  });
  const [state, setState] = React.useState<ICashflowData & { isFetching: boolean }>(DEFAULT_STATE);

  React.useEffect(() => {
    if (request.data) {
      setState({
        cashflowData: request.data,
        isFetching: request.isFetching,
      });
    }
  }, [request.dataUpdatedAt, request.data, request.isFetching]);

  return state;
};
