import React, { FunctionComponent } from 'react';
import { TableCellProps } from 'react-virtualized';

const CellRenderer: FunctionComponent<TableCellProps> = ({ cellData }: TableCellProps) => {
  const cellString: string = cellData == null ? '' : String(cellData);
  return (
    <span className="table-column__text" title={cellString}>
      {cellString}
    </span>
  );
};

export default CellRenderer;
