import { API_URL } from '../constants/endpoints';
import axios from 'axios';

export const fetchDynamicVolatility = (portfolioId: string, selectedCustodians?: string[]): any => {
  return Promise.all([getVolatilityScore(portfolioId, selectedCustodians)]);
};

const getVolatilityScore = (portId: string, selectedCustodians?: string[]) => {
  const volatilityScoreEndpoint = 'api/risk/v1/volatility/score/portfolio/';
  const API_VOLATILITY_SCORE = API_URL + volatilityScoreEndpoint;

  const API_HISTORIC_VOLATILITY_SCORE = API_URL + 'api/risk/v1/volatility/historic/portfolio/score';

  const cancelTokenSource = axios.CancelToken.source();
  const apiRequest = axios.create({
    method: 'post',
    responseType: 'json',
    withCredentials: true,
    cancelToken: cancelTokenSource.token,
  });

  return apiRequest
    .post(API_HISTORIC_VOLATILITY_SCORE, {
      portfolioId: portId,
      custodians: selectedCustodians || [],
    })
    .then((result) => {
      return result.data;
    });
};
