import React, { FC } from 'react';
import cn from 'classnames';
import s from './UniversalUpload.module.css';

import { useHistory } from 'react-router-dom';
import { PortfolioHeader } from '../../../../components/PortfolioHeader';
import { WorkBook, read } from 'xlsx';
import { UploadFile } from '../../../../../../components/Upload/components';
import { ALL_EXCEL_EXTENSIONS } from '../../../../../../components/Upload/components/UploadFile';
import { DropEvent } from 'react-dropzone';
import Combo from '../../../../../../components/Combo/Combo';
import { getSheetName, oneOf } from './universalUploadHelpers';
import Button from '../../../../../../components/UIWidgets/Button/Button';
import { IRootState } from '../../../../../../services/store';
import { AsyncActionDispatch } from '../../../../../../services/utils/ReduxHelper';
import { UploadTemplateActions } from '../../../../../../services/actions/UploadTemplateActions';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';
import { PageHeader } from '../../../../../../components';
import { useIframeMode } from '../../../../../../services/hooks/useIframeMode';

// ** Interfaces **/
interface IProps {
  portfolio: string;
}

interface IMapStateToProps {
  uploadTemplates: IRootState['snapshot']['uploadTemplates']['data'];
  uploadTemplateResult: IRootState['snapshot']['uploadTemplateResult']['data'];
  portfolioInfo: IRootState['portfolio']['portfolioInfo']['data'];
}

interface IDispatchToProps {
  fetchUploadTemplates(): void;
  uploadTemplate(data: IUploadTemplateData, portfolioId: string): AxiosPromise<any>;
}

type Props = IProps & IMapStateToProps & IDispatchToProps; // & IWithPortfolioInfoProps;

// ** Component **/
const UniversalUploadHome: FC<Props> = ({ fetchUploadTemplates, uploadTemplates, uploadTemplate, portfolioInfo }) => {
  console.log({ uploadTemplates });
  const iframeMode = useIframeMode();
  const downloadRef = React.useRef<HTMLAnchorElement>(null);
  const [state, setState] = React.useState<'ERROR' | 'LOADING' | 'READY' | 'NOT_SET'>('NOT_SET');
  const [error, setError] = React.useState<string>();
  const [fileName, setFileName] = React.useState<string>();
  const [workbook, setWorkbook] = React.useState<WorkBook>();
  const [sheetNames, setSheetNames] = React.useState<IComboItem[]>();
  const [selectedSheetIndex, setSelectedSheetIndex] = React.useState<number>();
  const [template, setTemplate] = React.useState<string>();

  const uploadTemplatesFiltered = React.useMemo(() => {
    if (iframeMode) {
      return uploadTemplates.filter((item) => item.key !== 'WestBayRestricted');
    }
    return uploadTemplates;
  }, [uploadTemplates, iframeMode]);
  const history = useHistory();

  const sheetName = getSheetName(workbook, selectedSheetIndex);
  const sheet = workbook?.Sheets[sheetName ?? ''];

  const portfolioId = portfolioInfo?.id;

  /** Load the currently supported templates from the server **/
  React.useEffect(() => {
    fetchUploadTemplates();
  }, []);

  /** If there's only one sheet, send the data to the server right away **/
  React.useEffect(() => {
    if (typeof selectedSheetIndex === 'number' && sheetNames?.length === 1) {
      sendRawData();
    }
  }, [selectedSheetIndex]);

  const clear = React.useCallback(() => {
    {
      setState('NOT_SET');
      setSheetNames(undefined);
      setWorkbook(undefined);
      setError(undefined);
      setTemplate(undefined);
    }
  }, [setState, setSheetNames, workbook]);

  /** Send data from the selected sheet to the server **/
  const sendRawData = () => {
    if (!sheet) {
      return;
    }
    const cleanSheet = { ...sheet };
    delete cleanSheet['!ref'];

    const rawData: IUploadTemplateData['rows'] = {};

    Object.entries(cleanSheet).forEach(([key, value]) => {
      const row = key.match(/([0-9])+/g)?.[0];
      if (row) {
        const cell = key.substr(0, key.length - row.length);
        if (!rawData[row]) {
          rawData[row] = {};
        }
        rawData[row][cell] = value.w ?? value.v;
      }
    });

    const parsedData = Object.assign(
      {},
      Object.values(rawData).filter((row) => row.A && row.B && row.C)
    );

    // @ts-ignore
    uploadTemplate({ template: template ?? '', rows: parsedData, portfolioId })
      .then((result) => {
        if (result.data) {
          if (iframeMode) {
            history.push('process?iframe-mode=true');
          } else {
            history.push('process');
          }
        }
      })
      .catch((err: any) => {
        console.log({ err });
      });
  };

  /** Handle the selection of a file from the dialog, or from being dragged and dropped onto browser **/
  const handleFileDrop = (templateName: string) => (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
    clear();
    setState('LOADING');
    setTemplate(templateName);

    if (acceptedFiles.length > 1) {
      setError('Error loading worksheet');
      setState('ERROR');
    }

    const f = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) {
        return;
      }

      // @ts-ignore - incorrect types for FileReader.onload
      const data = new Uint8Array(e.target!.result);
      const wb = read(data, { type: 'array' });
      if (wb.SheetNames) {
        setSheetNames(wb.SheetNames.map((name, index) => ({ label: name, value: index })));
        setWorkbook(wb);
        setState('READY');
        setFileName(f.name);
        setSelectedSheetIndex(0);
      }
    };
    reader.readAsArrayBuffer(f);
  };

  return (
    <div style={{ marginTop: 40 }}>
      <PortfolioHeader title={'Upload Transactions'} showPortfolioInfo={true} showDashboardMenu={false} />

      {oneOf(['NOT_SET', 'ERROR'], state) && (
        <div>
          <Button size={'small'} onClick={() => downloadRef.current?.click()} style={{ marginBottom: 10 }}>
            Download Template
          </Button>
          <div className={cn(s.dropAreaSurround)}>
            {uploadTemplatesFiltered
              ?.sort((a, b) => a.desc.localeCompare(b.desc))
              .map((t) => (
                <div key={t.key} className={cn(s.dropArea)}>
                  <UploadFile
                    allowedExtensions={ALL_EXCEL_EXTENSIONS}
                    onDrop={handleFileDrop(t.key)}
                    logoUrl={t.logoUrl}
                    logoBgColor={t.bgColor}
                    label={t.desc}
                  />
                </div>
              ))}
          </div>
        </div>
      )}

      {oneOf('READY', state) && sheetNames && sheetNames.length > 0 && (
        <div className={cn(s.settingsArea)}>
          <div className={cn(s.settingsRow)}>
            <strong>Selected file:</strong>
            <span>{fileName}</span>
          </div>
          <div className={cn(s.settingsRow)}>
            <strong>Selected sheet:</strong>
            <Combo
              items={sheetNames}
              name={'sheetName'}
              defaultValue={selectedSheetIndex}
              placeholder={'Select sheet'}
              onSelect={(item) => {
                setSelectedSheetIndex(item?.value as number);
              }}
              style={{ width: 200, marginLeft: 20 }}
            />
          </div>

          <div className={cn(s.settingsRow)}>
            <Button onClick={clear}>Clear</Button>

            {!sheet && <span>No sheet selected</span>}
            {!!sheet && <Button onClick={sendRawData}>Upload</Button>}
          </div>
        </div>
      )}
      <a
        onClick={(e) => e.stopPropagation()}
        href={`${process.env.PUBLIC_URL}/downloads/Trade_Upload_Template.xlsx`}
        download={true}
        ref={downloadRef}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  uploadTemplates: state.snapshot.uploadTemplates.data,
  uploadTemplateResult: state.snapshot.uploadTemplateResult.data,
  portfolioInfo: state.portfolio.portfolioInfo.data,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchUploadTemplates: () => dispatch(UploadTemplateActions.fetchUploadTemplates()),
  uploadTemplate: (data: IUploadTemplateData) => dispatch(UploadTemplateActions.uploadTemplate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniversalUploadHome);
