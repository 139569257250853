import React from 'react';
import { Table, Card } from '@iliotech/storybook';
import { useAllocationBreakdown } from '../../../../../../services/hooks/apiHooks/useAllocationBreakdown';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
// import { useExplorerContext } from '../../../../../../services/context/ExplorerContext';
// import { usePortfolio } from '../../../../../../services/hooks/usePortfolio';
import {
  explorerCurrencyRenderer,
  explorerPercentageRenderer,
  explorerShortLongRenderer,
} from '../../utils/explorerCurrencyRenderer';
import { DashboardTitle } from '../../../../../../components/UIWidgets/DashboardTitle/DashboardTitle';
import { useExplorerContext } from '../../../../../../services/context/ExplorerContext';
import { usePortfolioInfo } from '../../../../../../services/hooks/usePortfolioInfo';
import s from './AllocationBreakdown.module.scss';
export const AllocationBreakdown = ({ height = 400, pnl }: IExplorerWidgetProps) => {
  const breakdown = useAllocationBreakdown();
  const { selectedAllocationType } = useExplorerContext();
  const { portfolioInfo } = usePortfolioInfo();
  const riskAssetClass = portfolioInfo.data?.riskAssetView === 'RISK_ASSET_CLASS';
  // const portfolio = usePortfolio();
  // const portfolioCurrencySymbol = portfolio?.portfolioSymbol;

  const getAllocationName = (allocationType: AllocationType | null) => {
    switch (allocationType) {
      case 'IndustrySector':
        return 'Sector';
      case 'AssetClass':
        return riskAssetClass ? 'Risk Asset Class' : 'Asset Class';
    }

    return allocationType ?? '';
  };
  const columns = React.useMemo(() => {
    const cols: ColumnProps[] = [
      { label: 'Name', dataKey: 'name', width: 200, flexGrow: 1 },
      {
        label: 'MKT Value',
        dataKey: 'marketValue',
        width: 120,
        align: 'right',
        flexGrow: 1,
        cellRenderer: explorerShortLongRenderer,
      },
      {
        label: '% of NAV',
        dataKey: 'percentageOfNav',
        width: 120,
        align: 'right',
        flexGrow: 1,
        cellRenderer: explorerPercentageRenderer,
      },
      {
        label: '1d',
        dataKey: 'pnlOneDay',
        width: 120,
        cellRenderer: explorerCurrencyRenderer,
        align: 'right',
        flexGrow: 1,
      },
      {
        label: 'Period P&L',
        dataKey: 'pnlBetweenPeriod',
        width: 120,
        cellRenderer: explorerCurrencyRenderer,
        flexGrow: 1,
        align: 'right',
      },
      {
        label: 'Lifetime',
        dataKey: 'pnlLifetime',
        width: 120,
        cellRenderer: explorerCurrencyRenderer,
        flexGrow: 1,
        align: 'right',
      },
    ];
    return cols;
  }, []);

  const TOTAL_PORTFOLIO_ALLOCATION: IPortfolioAllocations = {
    marketValue: 0,
    percentageOfNav: 0,
    pnlBetweenPeriod: 0,
    pnlLifetime: 0,
    pnlOneDay: 0,
    name: 'Total',
    subAllocations: [],
  };
  const data = React.useMemo(() => {
    if (!breakdown?.data?.allocations) {
      return [];
    }
    const grandTotalRow: IPortfolioAllocations = (
      breakdown?.data?.allocations || ([] as IPortfolioAllocations[])
    ).reduce((prev, curr) => {
      return {
        marketValue: prev.marketValue + curr.marketValue,
        percentageOfNav: prev.percentageOfNav + curr.percentageOfNav,
        pnlBetweenPeriod: prev.pnlBetweenPeriod + curr.pnlBetweenPeriod,
        pnlLifetime: prev.pnlLifetime + curr.pnlLifetime,
        pnlOneDay: prev.pnlOneDay + curr.pnlOneDay,
        name: 'Total',
        subAllocations: [],
      };
    }, TOTAL_PORTFOLIO_ALLOCATION);
    return (
      [
        grandTotalRow,
        ...breakdown?.data?.allocations?.map((item) =>
          item.subAllocations.length ? { ...item, children: item.subAllocations } : item
        ),
      ] ?? []
    );
  }, [breakdown.data?.allocations]);

  return (
    <Card style={{ padding: 0 }}>
      <DashboardTitle
        title={pnl ? 'P&L Summary by Risk Asset Class' : `${getAllocationName(selectedAllocationType)} Breakdown`}
        style={{ marginBottom: 10, padding: '10px 15px' }}
      />

      <div className={s.tableContainer} style={{ height: height - 60, overflow: 'scroll', position: 'relative' }}>
        {data && (
          <Table
            rowHeight={25}
            headerHeight={35}
            defaultExpandAllRows={data.length < 20}
            rowKey={'name'}
            columns={columns}
            data={data}
            isTree={true}
          />
        )}
      </div>
    </Card>
  );
};
