import React from 'react';
import { AutoCompleteField } from './AutoCompleteField';
import { useCurrencies } from '../../../../../../../../services/hooks/apiHooks/useCurrencies';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';

interface IOwnProps<T> {
  label?: string;
  name?: string;
  limitToBase?: boolean;
}

export const CurrencyList = <T,>({ label = 'Currency', name = 'currency', limitToBase = false }: IOwnProps<T>) => {
  const {portfolioCurrency} = usePortfolioInfo();
  const { currencyOptions } = useCurrencies();

  const options = limitToBase ? currencyOptions.filter(c => c.isBase) : currencyOptions;

  const value = options.find(curr => curr.value === portfolioCurrency);
  // return <div>{portfolioCurrency}: {JSON.stringify(value, null, 1)}</div>;
  return <AutoCompleteField name={name} label={label} options={options} value={null} />;
};
