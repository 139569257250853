/* eslint-disable */
export const COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_USER_POOLS_ID = process.env.REACT_APP_COGNITO_USER_POOLS_ID;
export const COGNITO_USER_POOLS_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID;

const awsconfig = {
  aws_project_region: 'eu-west-2',
  aws_cognito_identity_pool_id: COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: 'eu-west-2',
  // "aws_user_pools_id": COGNITO_USER_POOLS_ID,
  // "aws_user_pools_web_client_id": COGNITO_USER_POOLS_WEB_CLIENT_ID,
  oauth: {}
};

export default awsconfig;
