export const filterSnapshots = (snapshotFilters: Record<string, string | undefined>, portfolioCurrency: string) => (
  snapshot: IPortfolioSnapshot
) => {
  if (
    snapshotFilters.instrumentAssetClass &&
    (snapshot.instrumentAssetClassId || '') !== snapshotFilters.instrumentAssetClass
  ) {
    return false;
  }
  if (snapshotFilters.currency) {
    if (snapshotFilters.currency === 'non-base' && snapshot.currencyNative === portfolioCurrency) {
      return false;
    }
    if (snapshotFilters.currency !== 'non-base' && snapshot.currencyNative !== snapshotFilters.currency) {
      return false;
    }
  }
  if (snapshotFilters.country && snapshot.country !== snapshotFilters.country) {
    return false;
  }
  if (snapshotFilters.custodian && snapshot.custodian !== snapshotFilters.custodian) {
    return false;
  }

  if (snapshotFilters.sector && snapshot.sector !== snapshotFilters.sector) {
    return false;
  }

  if (snapshotFilters.riskAssetClass && snapshot.riskAssetClass !== snapshotFilters.riskAssetClass) {
    return false;
  }

  if (snapshotFilters.investmentVehicle && snapshot.investmentVehicle !== snapshotFilters.investmentVehicle) {
    return false;
  }

  if (
    snapshotFilters.search &&
    !(
      snapshot.name?.toUpperCase().includes(snapshotFilters.search.toUpperCase()) ||
      snapshot.displayId?.toUpperCase().includes(snapshotFilters.search.toUpperCase())
    )
  ) {
    return false;
  }

  if (snapshotFilters.position) {
    if (snapshotFilters.position === 'long' && (typeof snapshot.amount === 'undefined' || snapshot.amount < 0)) {
      return false;
    }
    if (snapshotFilters.position === 'short' && (typeof snapshot.amount === 'undefined' || snapshot.amount >= 0)) {
      return false;
    }
    if (snapshotFilters.position === 'open' && (typeof snapshot.quantity === 'undefined' || snapshot.quantity === 0)) {
      return false;
    }
    if (snapshotFilters.position === 'closed' && typeof snapshot.quantity !== 'undefined' && snapshot.quantity !== 0) {
      return false;
    }
  }
  return true;
};
