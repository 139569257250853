import { useTypedSelector } from '../../../../../../services/hooks/useTypedSelector';

export const useTradeType = (assetSubClassId?: string, tradeTypeId?: string) => {
  const tradeType = assetSubClassId || tradeTypeId;
  const assetSubClasses = useTypedSelector((state) => state.assetsSubClasses.data);

  switch (tradeType) {
    case 'RaAircraft':
    case 'RealEstate':
    case 'RealAssets':
      return 'RealAssets';
    case 'EqOptions':
    case 'CshAdjustments':
    case 'CryptoCurrencies':
    case 'EqFutures':
    case 'SubCash':
    case 'EqWarrants':
      return tradeType;
    case 'EqShares':
    case 'EqETFs':
    case 'Equities':
      return 'Equities';
    case 'Funds':
    case 'FunEquity':
      return 'Funds';
    case 'AdjFXSpot':
    case 'CshFXSpot':
    case 'CshCash':
      return 'AdjFXSpot';
    case 'FixedIncome':
    case 'FiBonds':
    case 'FiLoans':
      return 'FixedIncome';
    case 'Commodity':
    case 'ComPhysical':
      return 'Commodity';
  }

  return assetSubClasses.find((subClass) => subClass.id === assetSubClassId)?.assetClassId || tradeTypeId;
};
