import React from 'react';
import { AutoCompleteField } from './AutoCompleteField';
import { useCustodiansFilter } from '../../../../../../../../services/hooks/useCustodiansFilter';
import { useFormContext } from 'react-hook-form';

interface IOwnProps<T> {
  label?: string;
  name?: string;
}

export const CustodianField = <T,>({ label = 'Custodian', name = 'custodian' }: IOwnProps<T>) => {
  const { custodianOptions } = useCustodiansFilter();

  const { setValue, getValues } = useFormContext();

  React.useEffect(() => {
    const defaultCustodian = custodianOptions?.find((custodian) => custodian.value.default === true);
    const existingCustodian = getValues()?.custodian;

    if (!existingCustodian) {
      setValue(name, defaultCustodian);
    }
  }, [custodianOptions]);


  return (
    <AutoCompleteField
      name={name}
      label={label}
      options={custodianOptions}
      value={null}
      placeholder={'Select'}
      required={true}
    />
  );
};
