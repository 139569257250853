import { Reducer } from 'redux';

const DEFAULT_STATE: AuthState = { accountStatus: 'NOT_SET', authStatus: 'loading', stripeId: '', accountId: -1 };

const accountReducer: Reducer<AuthState, AuthAction> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'LOGOUT_SUCCESS': {
      return DEFAULT_STATE;
    }
    case 'SET_AUTH_STATUS': {
      return {
        ...state,
        authStatus: action.value,
      };
    }
    case 'SET_ACCOUNT_STATUS': {
      return {
        ...state,
        accountStatus: action.value,
      };
    }
    case 'UPDATE_SUBSCRIPTION_INFO': {
      const { stripeId, accountId } = action.payload;
      return {
        ...state,
        stripeId,
        accountId,
      };
    }
    default:
      return state;
  }
};

export default accountReducer;
