import '@aws-amplify/ui/dist/style.css';

import axios, { AxiosPromise } from 'axios';
import { History } from 'history';
import mixpanel from 'mixpanel-browser';
import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { matchPath, RouteComponentProps, Switch, useHistory, withRouter } from 'react-router-dom';

import { Notification, Sidebar } from './components';

import { IRealAsset } from './models/IRealAsset';
import { IRouteConfig } from './models/IRouteConfig';
import { MODALS_ROUTES } from './router/modalRoutes';
import { SCENE_ROUTES } from './router/sceneRoutes';
import { CustodianActions } from './services/actions';
import { AppActions } from './services/actions/AppActions';
import { AssetsActions } from './services/actions/AssetsActions';
import CountryActions from './services/actions/CountryActions';
import { CurrencyActions } from './services/actions/CurrencyActions';
import { IndustryActions } from './services/actions/IndustryActions';
import { RealAssetActions } from './services/actions/RealAssetActions';
import { IPortfolioInfoState, IPortfolioStructureState } from './services/reducers/PortfolioReducers';
import { IRootState } from './services/store';
import { AsyncActionDispatch } from './services/utils/ReduxHelper';
import { RouterHelper } from './services/utils/RouterHelper';
import { UserCapabilitiesActions } from './services/actions/UserCapabilitiesActions';
import { AccountsActions } from './services/actions/AccountsActions';

import { AuthGuard } from './components/Auth/AuthGuard';
import { useAccount } from './services/context/AuthContext';
import { PusherHandler } from './pusher-env-config';
import { Modal } from 'rsuite';
import AddCustodianFormContainer from './modals/AddCustodianForm/AddCustodianFormContainer';
import { useModal } from './services/context/ModalContext';
import { usePageTracking } from './services/hooks/usePageTracking';
import { useIframeMode } from './services/hooks/useIframeMode';

PusherHandler.init();

mixpanel.init('7fc0347c61e0a1fa32cec0584340a165');

interface IMapStateToProps {
  authInfo: IAuthInfo | null;
  authStatus: AuthStatus;
  accountStatus: AccountStatus;
  notification: INotification | null;
  portfolioInfo: IPortfolio | null;
  portfolioStructure: IPortfolioInfoState;
  portfolioCoreStructure: IPortfolioStructureState;
  contextHelpState: IContextHelpToggle | null;
  accounts: IRootState['accounts'];
  userCapabilities: IRootState['userCapabilities']['data'];
}

interface IDispatchToProps {
  clearNotification: () => void;
  toggleContextHelp: () => void;
  fetchCustodians: () => AxiosPromise<ICustodian[]>;
  fetchAllAssetsClasses: () => AxiosPromise<IAssetClass[]>;
  fetchAllAssetSubClasses: () => AxiosPromise<IAssetSubClass[]>;
  fetchCurrency: () => AxiosPromise<ICurrency[]>;
  fetchIndustry: () => AxiosPromise<IIndustry[]>;
  fetchCountry: () => AxiosPromise<ICountry[]>;
  fetchAssetLight: () => AxiosPromise<IAssetClass[]>;
  fetchRealAssets: () => AxiosPromise<IRealAsset[]>;
  fetchUserCapabilities: () => AxiosPromise<IUserCapabilities>;
  fetchAccounts: () => AxiosPromise<IAccountSummaries[]>;
  resetAccounts(): void;
  signInSuccess(email: string, firstName: string, lastName: string, role: string, status: string, id: string): void;
  signOutSuccess(): void;
  updateAuthStatus: (value: AuthStatus) => void;
  authorise: () => void;
}

interface IProps {
  themeMetaData?: IThemeMetaData;
  selectIllioAccount(id: string): void;
  signOut(): Promise<any>;
}

type Props = IMapStateToProps & IDispatchToProps & RouteComponentProps & IProps;
// type AppState = { signedIn: boolean };

const App = (props: Props) => {
  const { open, setOpen } = useModal();
  const prevLocation: React.MutableRefObject<any> = useRef<any>({
    pathname: '/',
    search: '',
    hash: '',
    state: {},
  });
  usePageTracking();

  // DO NOT REMOVE CAUSES BUG REDIRECTING TO WORKGROUP
  useEffect(() => {
    if (props.location !== prevLocation.current) {
      if (props.location && !location.state && !checkPathIsModal(location.pathname, MODALS_ROUTES)) {
        prevLocation.current = props.location;
      }
    }
  }, [props.location]);

  const {
    location,
    portfolioInfo,
    portfolioStructure,
    portfolioCoreStructure,
    authInfo,
    notification,
    clearNotification,
  } = props;

  const isModal: boolean = checkPathIsModal(location.pathname, MODALS_ROUTES);

  const hideSideBar = useIframeMode();

  return (
    <AuthGuard>
      {notification && (
        <Notification notification={notification} outSideClick={() => setTimeout(clearNotification, 3000)} />
      )}
      <Fragment>
        <div className="App-container">

          <div className="App-content">
            <Switch location={isModal ? prevLocation.current : location}>
              {RouterHelper.createSceneRoutesFromConfig(SCENE_ROUTES)}
            </Switch>
          </div>
          {!hideSideBar && (
            <Sidebar
              userInfo={authInfo}
              portfolioInfo={portfolioInfo}
              portfolioStructure={portfolioStructure}
              portfolioCoreStructure={portfolioCoreStructure}
              themeMetaData={props.themeMetaData}
              onLogOut={props.signOut}
              selectIllioAccount={props.selectIllioAccount}
              accounts={props.accounts}
              userCapabilities={props.userCapabilities}
            />
          )}
        </div>
        <Switch location={location}>{RouterHelper.createModalRoutesFromConfig(MODALS_ROUTES)}</Switch>
        <Modal full={true} show={open} onHide={() => setOpen(false)}>
          <AddCustodianFormContainer />
        </Modal>
      </Fragment>
    </AuthGuard>
  );
};

const checkPathIsModal = (checkPath: string, routes: IRouteConfig[]): boolean => {
  let isModal = false;
  routes.forEach((route) => {
    const path = route.path;
    if (matchPath(checkPath, { path })) {
      isModal = true;
    }
  });

  return isModal;
};

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    authStatus: state.app.account.authStatus,
    accountStatus: state.app.account.accountStatus,
    authInfo: state.app.authInfo.data,
    notification: state.app.appNotification,
    portfolioInfo: state.portfolio.portfolioInfo.data,
    portfolioStructure: state.portfolio.portfolioInfo,
    portfolioCoreStructure: state.portfolio.portfolioStructure,
    contextHelpState: state.app.contextHelp,
    accounts: state.accounts,
    userCapabilities: state.userCapabilities.data,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  clearNotification: () => dispatch(AppActions.clearNotification()),
  toggleContextHelp: () => dispatch(AppActions.toggleContextHelp()),
  fetchCustodians: () => dispatch(CustodianActions.fetchCustodiansList()),
  fetchAllAssetsClasses: () => dispatch(AssetsActions.fetchAllAssetsClasses()),
  fetchAllAssetSubClasses: () => dispatch(AssetsActions.fetchAllAssetSubClasses()),
  fetchCurrency: () => dispatch(CurrencyActions.fetchAllCurrency()),
  fetchIndustry: () => dispatch(IndustryActions.fetchAllIndustry()),
  fetchCountry: () => dispatch(CountryActions.fetchAllCountries()),
  fetchAssetLight: () => dispatch(AssetsActions.fetchAllAssetLight()),
  fetchRealAssets: () => dispatch(RealAssetActions.fetchRealAssetsList()),
  signInSuccess: (email: string, firstName: string, lastName: string, role: string, status: string, id: string) => {
    dispatch(AppActions.login(email, firstName, lastName, role, status, id));
  },
  fetchUserCapabilities: () => dispatch(UserCapabilitiesActions.fetchUserCapabilities()),
  fetchAccounts: () => dispatch(AccountsActions.fetchAccounts()),
  resetAccounts: () => {
    dispatch({ type: AccountsActions.ACCOUNTS_RESET });
  },
  signOutSuccess: () => dispatch(AppActions.logout()),
  updateAuthStatus: (value: AuthStatus) => dispatch(AppActions.updateAuthStatus(value)),
  authorise: () => dispatch(AppActions.authorise()),
});

const Wrapper = (props: any) => {
  const { currentTheme, selectIllioAccount, signOut, illioSelectedAccount } = useAccount();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (illioSelectedAccount) {
      axios.defaults.headers['illio-selected-account'] = illioSelectedAccount || undefined;
      dispatch(AppActions.authorise());
      dispatch(UserCapabilitiesActions.fetchUserCapabilities());
    }
  }, [illioSelectedAccount]);

  return <App {...props} themeMetaData={currentTheme} selectIllioAccount={selectIllioAccount} signOut={signOut} />;
};

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wrapper));
