import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { PageHeader } from '../../../../components/PageHeader';
import { Upload } from '../../../../components/Upload';

const InstrumentsUpload: FunctionComponent = () => {
  return (
    <div className="separate-page-content">
      <div className={cn('separate-page-content__item', 'separate-page-content__item--top')}>
        <PageHeader title="Instruments" />
        <Upload />
      </div>
      <div className={cn('separate-page-content__item', 'separate-page-content__item--bottom')} />
    </div>
  );
};

export default InstrumentsUpload;
