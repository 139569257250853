import React, { useEffect, useRef } from "react";
import { select } from "d3-selection";
import { transition } from "d3-transition";
import { Data } from "../IBarChart";

type Line = {
  data: Data;
  lineGenerator: any;
  color: string;
  strokeWidth: number;
};
const Line = ({ data, lineGenerator, color, strokeWidth }: Line) => {
  const node = useRef() as React.MutableRefObject<SVGSVGElement>;

  useEffect(() => {
    select(node.current)
      .append("path")
      .datum(data)
      .attr("stroke", color)
      .attr("stroke-width", strokeWidth)
      .attr("fill", "none")
      .attr("d", lineGenerator);

    updateChart();
  }, []);

  const updateChart = () => {
    const t:any = transition().duration(1000);
    const line = select(node.current);
    line.datum(data).transition(t).attr("d", lineGenerator);
  };

  return <g className="line-group" ref={node} />;
};

export default Line;
