import { AxiosPromise } from 'axios';
import debounce from 'lodash.debounce';
import memoizeOne from 'memoize-one';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../components/HOC/withPortfolioInfo';
import { ITreeMapData } from '../../../../components/TreeMap/TreeMap';
import { Loader } from '../../../../components/UIWidgets/Loader';

import { IRecommendation } from '../../../../models/IRecommendation';
import { RecommendationsActions } from '../../../../services/actions';
import { ALLOCATION_TYPES } from '../../../../services/constants/constants';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { IRootState } from '../../../../services/store';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import Recommendations from './Recommendations';

interface IMapStateToProps {
  assetClasses: IAssetClass[];
  classesAllocationData: ITreeMapData[];
  personalPerformance: IPerformanceData;
  performancePeriod: IPeriod;
  geographyAllocationData: ITreeMapData[];
  portfolioRecommendation: Record<string, IRecommendation[]>;
  resultPerformance: IPerformanceData;
  selectedAssetClass: IAssetClass | null;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  fetchPersonalPerformance: (portfolioId: string, period: IPeriod) => AxiosPromise<IPerformanceData>;
  fetchResultPerformance: (
    portfolioId: string,
    recommendations: IRecommendation[],
    period: IPeriod
  ) => AxiosPromise<IPerformanceData>;
  fetchClassesAllocations: (portfolioId: string, recommendations: IRecommendation[]) => AxiosPromise<ITreeMapData[]>;
  fetchGeographyAllocations: (portfolioId: string, recommendations: IRecommendation[]) => AxiosPromise<ITreeMapData[]>;
  fetchPortfolioRecommendations: (portfolioId: string) => AxiosPromise<Record<string, IRecommendation>>;
  toggleRecommendation: (assetClassId: string, recommendationIdx: number) => void;
  selectAssetClass: (assetClass: IAssetClass) => void;
  setPerformancePeriod: (period: IPeriod) => void;
  resetRecommendationData: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps & IWithPortfolioInfoProps;

interface IState {
  allLoaded: boolean;
}

class RecommendationsContainer extends Component<IProps, IState> {
  readonly state: IState = {
    allLoaded: false,
  };

  constructor(props: IProps) {
    super(props);
    this._filterAssetsByRecommendations = memoizeOne(this._filterAssetsByRecommendations);
    this._applyRecommendations = debounce(this._applyRecommendations, 300);
  }

  componentDidMount(): void {
    this._fetchData().then(() => {
      this.setState({ allLoaded: true }, () => {
        this._selectActiveAssetTab();
      });
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const {
      portfolioInfo,
      portfolioRecommendation,
      performancePeriod,
      fetchResultPerformance,
      fetchPersonalPerformance,
    } = this.props;

    if (prevProps.portfolioRecommendation !== portfolioRecommendation) {
      const checkedRecommendations: IRecommendation[] = this._getActiveRecommendations();
      this._applyRecommendations(portfolioInfo.id, checkedRecommendations);
    }

    if (prevProps.performancePeriod !== performancePeriod) {
      const checkedRecommendations: IRecommendation[] = this._getActiveRecommendations();
      fetchResultPerformance(portfolioInfo.id, checkedRecommendations, performancePeriod);
      fetchPersonalPerformance(portfolioInfo.id, performancePeriod);
    }
  }

  componentWillUnmount(): void {
    this.props.resetRecommendationData();
  }

  handleAssetClassChanged = (asset: IAssetClass) => {
    this.props.selectAssetClass(asset);
  };

  handleToggleRecommendation = (recommendationIdx: number) => {
    const { selectedAssetClass } = this.props;
    if (!selectedAssetClass) {
      return;
    }
    this.props.toggleRecommendation(selectedAssetClass.id, recommendationIdx);
  };

  handlePerformancePeriodChange = (period: IPeriod) => {
    this.props.setPerformancePeriod(period);
  };

  render() {
    const { allLoaded } = this.state;
    const {
      assetClasses,
      classesAllocationData,
      geographyAllocationData,
      portfolioRecommendation,
      personalPerformance,
      resultPerformance,
      selectedAssetClass,
      performancePeriod,
      portfolioCurrencyFormatter,
    } = this.props;

    const recommendations = selectedAssetClass ? portfolioRecommendation[selectedAssetClass.id] : [];
    const classesWithRecommendations = this._filterAssetsByRecommendations(assetClasses, portfolioRecommendation);

    if (!allLoaded) {
      return <Loader />;
    }
    return (
      <Recommendations
        assetClasses={classesWithRecommendations}
        classesAllocationData={classesAllocationData}
        geographyAllocationData={geographyAllocationData}
        portfolioPerformance={resultPerformance}
        portfolioRecommendation={recommendations}
        recommendedPerformance={personalPerformance}
        performancePeriod={performancePeriod}
        onAssetClassChange={this.handleAssetClassChanged}
        onToggleRecommendation={this.handleToggleRecommendation}
        onPerformancePeriodChanged={this.handlePerformancePeriodChange}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
      />
    );
  }

  private _selectActiveAssetTab() {
    // TODO: think of better solution (use route for active tab or something else)
    const { assetClasses, selectedAssetClass, portfolioRecommendation, selectAssetClass } = this.props;
    const classesWithRecommendations = this._filterAssetsByRecommendations(assetClasses, portfolioRecommendation);
    if (!selectedAssetClass && classesWithRecommendations.length) {
      selectAssetClass(classesWithRecommendations[0]);
    }
  }

  private _applyRecommendations(portfolioId: string, checkedRecommendations: IRecommendation[]) {
    const { performancePeriod, fetchResultPerformance, fetchClassesAllocations, fetchGeographyAllocations } = this.props;
    fetchResultPerformance(portfolioId, checkedRecommendations, performancePeriod);
    fetchClassesAllocations(portfolioId, checkedRecommendations);
    fetchGeographyAllocations(portfolioId, checkedRecommendations);
  }

  private _fetchData(): Promise<any> {
    const { portfolioInfo, performancePeriod, fetchPersonalPerformance, fetchPortfolioRecommendations } = this.props;
    const portfolioId = portfolioInfo.id;

    return Promise.all([
      fetchPortfolioRecommendations(portfolioId),
      fetchPersonalPerformance(portfolioId, performancePeriod),
      fetchPortfolioRecommendations(portfolioId),
    ]);
  }

  private readonly _filterAssetsByRecommendations = (
    assetClasses: IAssetClass[],
    recommendations: Record<string, IRecommendation[]>
  ): IAssetClass[] => {
    return assetClasses.filter((item) => {
      return recommendations.hasOwnProperty(item.id) && recommendations[item.id].length;
    });
  };

  private _getActiveRecommendations(): IRecommendation[] {
    const { portfolioRecommendation } = this.props;

    let checkedRecommendations: IRecommendation[] = [];
    if (!Object.keys(portfolioRecommendation).length) {
      return [];
    }
    Object.keys(portfolioRecommendation).forEach((key) => {
      const checked: IRecommendation[] = portfolioRecommendation[key].filter((rec) => rec.active);
      if (!checked.length) {
        return;
      }
      checkedRecommendations = [...checkedRecommendations, ...checked];
    });
    return checkedRecommendations;
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    assetClasses: state.assetsClasses.data,
    personalPerformance: state.risk.recommendations.personalPerformance.data,
    performancePeriod: state.risk.recommendations.performancePeriod,
    classesAllocationData: state.risk.recommendations.allocations.classes.data[ALLOCATION_TYPES.AssetClass],
    geographyAllocationData: state.risk.recommendations.allocations.geography.data[ALLOCATION_TYPES.Region],
    portfolioRecommendation: state.risk.recommendations.portfolioRecommendation.data,
    resultPerformance: state.risk.recommendations.resultPerformance.data,
    selectedAssetClass: state.risk.recommendations.assetsClasses.selectedAsset,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPersonalPerformance: (portfolioId: string, period: IPeriod) =>
    dispatch(RecommendationsActions.fetchPersonalPerformance(portfolioId, period)),
  fetchResultPerformance: (portfolioId: string, recommendations: IRecommendation[], period: IPeriod) =>
    dispatch(RecommendationsActions.fetchResultPerformance(portfolioId, recommendations, period)),
  fetchClassesAllocations: (portfolioId: string, recommendations: IRecommendation[]) =>
    dispatch(RecommendationsActions.fetchClassesAllocations(portfolioId, recommendations)),
  fetchGeographyAllocations: (portfolioId: string, recommendations: IRecommendation[]) =>
    dispatch(RecommendationsActions.fetchGeographyAllocations(portfolioId, recommendations)),
  fetchPortfolioRecommendations: (portfolioId: string) =>
    dispatch(RecommendationsActions.fetchPortfolioRecommendations(portfolioId)),
  toggleRecommendation: (assetClassId: string, recommendationIdx: number) =>
    dispatch(RecommendationsActions.toggleRecommendation(assetClassId, recommendationIdx)),
  selectAssetClass: (assetClass: IAssetClass) => dispatch(RecommendationsActions.selectAssetClass(assetClass)),
  setPerformancePeriod: (period: IPeriod) => dispatch(RecommendationsActions.setPerformancePeriod(period)),
  resetRecommendationData: () => dispatch(RecommendationsActions.resetRecommendationData()),
});

export default withPortfolioInfo(connect(mapStateToProps, mapDispatchToProps)(RecommendationsContainer));
