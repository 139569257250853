import React, { Fragment, PureComponent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { Button, Input, Loader, Select, Tab, Tabs } from '../../components/UIWidgets';
import { IOptionType } from '../../components/UIWidgets/Select/Select';

import { IPortfoliosFilters } from '../../models/IPortfoliosFilters';
import { PATHS } from '../../router/paths';
import { FILTER_VIEW, PortfolioType, SORT_VIEW, UserRole } from '../../services/constants/constants';
import { PortfolioItem } from './components';
import s from './PortfoliosManager.module.scss';
import { IRootState } from '../../services/store';
import CapabilitiesHelper from '../../services/utils/CapabilitiesHelper';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';
import { ISubRoutesProps } from '../../models/ISubRoutesProps';
import useApi, { sendRequest } from '../../services/hooks/useApi';
import { getApiUrl } from '../../services/constants/endpoints';
import { Utils } from '../../services/utils/Utils';
import { NotificationType } from '../../models/NotifictionType';

const PORTFOLIO_HEIGHT = 150;

// const sortOptions: Array<IOptionType<SORT_VIEW>> = [
//   { value: SORT_VIEW.NAME, label: 'Name' },
//   { value: SORT_VIEW.PNL, label: 'P&L' },
//   // { value: SORT_VIEW.PERFORMANCE, label: 'Performance' },
//   { value: SORT_VIEW.WEALTH, label: 'Portfolio Value' },
// ];
//
// const filterOptions: Array<IOptionType<FILTER_VIEW>> = [
//   { value: FILTER_VIEW.ALL, label: 'All' },
//   { value: FILTER_VIEW.CURRENCY, label: 'Currency' },
//   { value: FILTER_VIEW.BUSINESS, label: 'Client' },
//   { value: FILTER_VIEW.ASSET, label: 'Asset Class' },
// ];

interface IProps {
  showNotification: (notification: INotification) => void;
  portfoliosFilters: IPortfoliosFilters;
  authInfo: IAuthInfo | null;
  sortBy: SORT_VIEW;
  filterBy: FILTER_VIEW;
  onFiltersChanged: (filters: IPortfoliosFilters) => void;
  onPortfolioEdit: (portfolio: IPortfolio) => void;
  onSortBy: (sortBy: SORT_VIEW) => void;
  onFilterBy: (filterBy: FILTER_VIEW) => void;
  userCapabilities: IRootState['userCapabilities']['data'];
  workgroupId: string | number;
}

interface ITab {
  type: PortfolioType;
  label: string;
  enable?: boolean | null;
  visible?: boolean | null;
}

interface IState {
  filterOptionSelected: IOptionType<string> | null;
  filteredPortfolios: IPortfolio[];
  search: string;
}

// TODO: UserRoles to be refactored soon...
const getTabs = (authInfo: IAuthInfo | null) => [
  {
    type: PortfolioType.BUSINESS,
    label: `CLIENT PORTFOLIOS`,
    visible: authInfo && (authInfo.role === UserRole.ADMIN || authInfo.role === UserRole.MANAGER),
  },
  {
    type: PortfolioType.PERSONAL,
    label: 'MY PORTFOLIOS',
    visible: authInfo && authInfo.role === UserRole.CUSTOMER,
  },
  {
    type: PortfolioType.MODEL,
    label: `${PortfolioType.MODEL} PORTFOLIOS`,
    visible: true,
    disabled: true,
  },
];

type Props = ISubRoutesProps & IProps;

const PortfoliosManager = ({ showNotification, workgroupId, userCapabilities, onPortfolioEdit }: Props) => {
  const history = useHistory();
  const isD2c = userCapabilities?.metadata?.allowedViews?.includes('d2c');
  const { data, refetch, isFetching, isFetched } = useApi(
    getApiUrl('portfolioManager.list'),
    {
      method: 'POST',
      data: {
        displayCurrency: 'USD',
      },
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const handlePortfolioEdit = (portfolio: IPortfolio) => {
    sendRequest(getApiUrl('portfolioManager.update'), {
      data: {
        portfolioId: portfolio.id,
        name: portfolio.name,
        accessType: portfolio.accessType,
      },
    })
      .then((res) => {
        console.log('success');
        refetch();
      })
      .catch((err) => {
        console.log('rr');
      });
  };

  const handlePortfolioDelete = (portfolio: IPortfolio) => {
    Utils.createConfirm({
      text: `Do you really want to remove <b>${portfolio.name}</b> portfolio? This action cannot be undone.`,
      confirmBtnText: 'Remove',
      onConfirm: async () => {
        try {
          const result = await sendRequest(
            getApiUrl('portfolioManager.delete'),
            {
              method: 'POST',
            },
            { portfolioId: portfolio.id }
          );
          console.log('passed');
          refetch();
        } catch (e) {
          console.log('error', e);
          showNotification({ text: 'Portfolio hasn`t been removed', type: NotificationType.ERROR });
        }
        console.log('passed down');
      },
    });
  };

  const portfoliosList = (list: IPortfolio[]) =>
    [...list].filter((p: IPortfolio) => p.workgroup.id.toString() === workgroupId);

  const [filteredPortfolios, setFilteredPortfolios] = useState<IPortfolio[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isFetched) {
      setFilteredPortfolios(
        portfoliosList(data)
          .slice(0)
          .filter((p) => p.name.toUpperCase().includes(search.toUpperCase()))
      );
    }
  }, [isFetched, search, data]);

  // const handleFilterOptionSelection = (option: any) => this.setState({ filterOptionSelected: option });

  // const resetFilterOption = () => this.setState({ filterOptionSelected: null });

  const handleSearchChange = (what: string) => setSearch(what);

  /*
    const renderFilters = (filterOption: FILTER_VIEW): React.ReactNode => {
    let options: Array<IOptionType<string>>;

    switch (filterOption) {
      case FILTER_VIEW.BUSINESS:
        const clients = this.props.portfoliosList.map((item: IPortfolio) => item.clientName);
        options = clients
          .filter((item: string, index) => item && clients.indexOf(item) === index)
          .map((item: string) => ({ value: item, label: item }));
        break;
      case FILTER_VIEW.CURRENCY:
        const currency = this.props.portfoliosList.map((item: IPortfolio) => item.currency.name);
        options = currency
          .filter((item: string, index) => item && currency.indexOf(item) === index)
          .map((item: string) => ({ value: item, label: item }));
        break;
      case FILTER_VIEW.ASSET:
        const assets = this.props.portfoliosList
          .map((item: IPortfolio) => item.allocation.AssetClass)
          .flat()
          .filter((allocation) => !!allocation);
        options = Object.values(
          assets.reduce((acc, item) => Object.assign(acc, { [item.name]: item }), {})
        ).map((item: any) => ({ value: item.id, label: item.name }));
        break;
      default:
        options = [];
    }

  const handleFiltersChange = (filters: Partial<IPortfoliosFilters>, portfolioTab?: ITab) => {
    if (portfolioTab && !portfolioTab.enable) {
      return;
    }
    onFiltersChanged({ ...portfoliosFilters, ...filters });
  };

   const renderTabs = (): React.ReactNode => {
    const PortfoliosTabs = getTabs(authInfo).filter((item) => item.visible);

    return (
      <Tabs
        onTabSelected={(idx: number) =>
          handleFiltersChange({ type: PortfoliosTabs[idx].type }, PortfoliosTabs[idx])
        }
      >
        {PortfoliosTabs.map((item, idx) => (
          <Tab disabled={item.disabled} key={idx} label={item.label} />
        ))}
      </Tabs>
    );
  };

  const filterFunction(item: IPortfolio, filterBy: FILTER_VIEW): boolean {
    if (!filterBy || !this.state.filterOptionSelected) {
      return true;
    }
    switch (filterBy) {
      case FILTER_VIEW.CURRENCY:
        return item.currency.name === this.state.filterOptionSelected.toString();
      case FILTER_VIEW.ASSET:
        return (
          item.allocation.AssetClass?.find((asset: any) => asset.id === this.state.filterOptionSelected!.toString()) ??
          false
        );
      case FILTER_VIEW.BUSINESS:
        return item.clientName === this.state.filterOptionSelected.toString();
      default:
        return true;
    }
  }
  */

  const getLink = (portfolio: IPortfolio) => {
    if (portfolio.unconfirmedTrades || portfolio.confirmedTrades === 0) {
      return PATHS.portfolio.snapshot.edit.path.replace(':portfolioId', portfolio.id);
    }
    return PATHS.portfolio.dashboardNew.path.replace(':portfolioId', portfolio.id);
  };

  const renderList = (): React.ReactNode => {
    // const filteredPortfolios = portfoliosList.filter((item) => this.filterFunction(item, this.props.filterBy));

    if (isFetching) {
      return <Loader />;
    }

    return (
      <div style={{ height: '100%', width: '100%', flex: 1 }}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              rowCount={filteredPortfolios.length}
              rowHeight={PORTFOLIO_HEIGHT + 20}
              width={width}
              height={height}
              rowRenderer={renderRow(filteredPortfolios)}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  const renderRow: (filteredPortfolios: IPortfolio[]) => ListRowRenderer = (filtered) => ({ key, index, style }) => {
    const portfolio = filtered[index];

    return (
      <div key={key} style={{ ...style, height: PORTFOLIO_HEIGHT }}>
        <Link to={getLink(portfolio)} className={s.portfoliosListItem}>
          <PortfolioItem
            isD2c={isD2c}
            portfolio={portfolio}
            onEdit={handlePortfolioEdit}
            onDelete={handlePortfolioDelete}
            userCapabilities={userCapabilities}
          />
        </Link>
      </div>
    );
  };

  const canCreatePortfolio = CapabilitiesHelper.isActionAllowed('ADD_PORTFOLIO', userCapabilities);

  return (
    <Fragment>
      <PageHeader title="Portfolios">
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Input
            value={search}
            placeholder="Search"
            className={s.searchInp}
            type="search"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          {canCreatePortfolio && (
            <Link
              to={{
                pathname: PATHS.portfoliosManager.portfolioCreator.path,
                state: { modal: true },
              }}
            >
              <Button size={'small'}>Create new</Button>
            </Link>
          )}
        </div>
      </PageHeader>

      {/* <div className={s.tabs}>{this.renderTabs()}</div>
        <div className={s.filters}>
          <div className={s.filterItem}>
            <span>Sort by:</span>
            <Select
              size="small"
              options={sortOptions}
              selectedValue={sortOptions.find((item) => item.value === this.props.sortBy)}
              onChange={onSortBy}
            />
          </div>
          <div className={s.filterItem}>
            <span>Filter by:</span>
            <Select
              size="small"
              options={filterOptions}
              selectedValue={filterOptions.find((item) => item.value === this.props.filterBy)}
              onChange={(item) => {
                this.resetFilterOption();
                onFilterBy(item);
              }}
            />
          </div>
          <div className={s.filterOptions}>{this.renderFilters(this.props.filterBy)}</div>
        </div>
         */}

      {renderList()}
    </Fragment>
  );
};

export default PortfoliosManager;
