import { RouterState } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { FormStateMap } from 'redux-form';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { IAppState } from '../reducers/AppReducers';
import { IAssetsClassesState } from '../reducers/AssetsClassReducers';
import { IAssetsSubClassesState } from '../reducers/AssetsSubClassReducer';

import { ICurrencyState } from '../reducers/CurrencyReducers';
import { ICustodianState } from '../reducers/CustodiansReducers';
import { IDashboardState } from '../reducers/DashboardReducers';
import { IIndustryState } from '../reducers/IndustryReducers';
import { IInstrumentsState } from '../reducers/InstrumentsReducers';
import { IPortfolioCurrencyState } from '../reducers/PortfolioCurrencyReducers';
import { IPortfolioIndexState } from '../reducers/PortfolioIndexReducers';
import { IPortfolioClassesState } from '../reducers/PortfolioReducers';
import { IPortfoliosListState } from '../reducers/PortfoliosListReducers';
import { IRecommendationsState } from '../reducers/RecommendationsReducers';
import { IReportsState } from '../reducers/ReportsReducers';
import { IScenarioState } from '../reducers/ScenariosReducers';
import { ISnapshotState } from '../reducers/SnapshotReducers';
import { IUsersState } from '../reducers/UsersReducers';
import { history } from './history';
import { IRealAssetsState } from '../reducers/RealAssetReducers';
// import { IPortfolioPnlByAttributionState } from '../reducers/DashboardReducers/PortfolioPnlByAttributionReducer';
import { IUserCapabilitiesState } from '../reducers/UserCapabilitiesReducer';
import { ICountryState } from '../reducers/CountryReducer';
import { IAccountsState } from '../reducers/AccountsReducer';
import { IRiskAssetsClassesState } from '../reducers/RiskAssetClassesReducers';
import { ISectorState } from '../reducers/SectorsReducers';

export interface IRootState {
  form: FormStateMap;
  app: IAppState;
  assetsClasses: IAssetsClassesState;
  riskAssetClasses: IRiskAssetsClassesState;
  sectors: ISectorState;
  instrumentsLightClasses: IAssetsClassesState;
  assetsSubClasses: IAssetsSubClassesState;
  custodians: ICustodianState;
  dashboard: IDashboardState;
  instruments: IInstrumentsState;
  portfolio: IPortfolioClassesState;
  router: RouterState;
  reports: IReportsState;
  scenario: IScenarioState;
  risk: {
    recommendations: IRecommendationsState;
  };
  portfoliosList: IPortfoliosListState;
  snapshot: ISnapshotState;
  users: IUsersState;
  industry: IIndustryState;
  currency: ICurrencyState;
  portfolioCurrency: IPortfolioCurrencyState;
  portfolioIndex: IPortfolioIndexState;
  country: ICountryState;
  realAssets: IRealAssetsState;
  userCapabilities: IUserCapabilitiesState;
  accounts: IAccountsState;
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['portfoliosList'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default function configureStore(preloadedState = {}) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const dev = [thunk];
  const middleware: Middleware[] = process.env.NODE_ENV === 'production' ? [thunk] : dev;

  const store = createStore(persistedReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));
  return { store, persistor: persistStore(store) };
}
