import { store } from '../../index';

import { PATHS } from '../../router/paths';
import dashboard from '../../static/images/dashboard/dashboard.png';
import help from '../../static/images/menu/help.svg';
import snapshot from '../../static/images/menu/snapshot.svg';
import backtest from '../../static/images/dashboard/backtest.svg';
import sensitivity from '../../static/images/dashboard/balance-scale.svg';
import { ASSET_CLASSES_CONFIG } from './assets';
import { FEATURE_FLAGS } from './featureFlags';
import CapabilitiesHelper from '../utils/CapabilitiesHelper';
import {
  AiFillDashboard,
  AiOutlineLineChart,
  AiTwotonePieChart,
  FaPuzzlePiece,
  FaSlidersH,
  GoDashboard,
  BsPieChartFill,
  BsCashCoin,
  FaBalanceScaleLeft,
  FaCertificate,
  FaUserCog,
  ImStatsBars2,
  IoDiamondSharp,
  RiBuilding2Fill,
  FaRegNewspaper,
} from 'react-icons/all';

import React from 'react';

export const ACCOUNT_MANAGEMENT_MENU: IMenuItem[] = [
  {
    id: 'otcInstruments',
    title: 'Manage Custom Assets',
    icon: FaCertificate,
    exact: false,
    // showEmpty: true,
    // renderAsAnchor: true,
    requiredCapabilities: [CapabilitiesHelper.Actions.MANAGE_OTC_INSTRUMENT, CapabilitiesHelper.Actions.ADD_TRADE],
    path: `/administration/unlisted/Equities`,
  },
  {
    id: 'manageAccounts',
    title: 'Manage Account',
    icon: FaUserCog,
    exact: false,
    // showEmpty: true,
    // renderAsAnchor: true,
    path: `/administration/manage-account/preferences`,
    // path: `${getSysadminUrl()}/manage-account/CompanyDetails`,
    requiredCapabilities: [CapabilitiesHelper.Actions.MANAGE_OTC_USER],
  },
  {
    id: 'help',
    title: 'Help',
    // icon: help,
    path: PATHS.help.path,
    disabled: FEATURE_FLAGS.help,
  },
];

export const PORTFOLIO_MENU: IMenuItem[] = [
  {
    id: 'pnlExplorer',
    title: 'Dashboard',
    path: PATHS.portfolio.dashboardNew.path,
    exact: true,
    disabled: FEATURE_FLAGS.twr,
    icon: AiFillDashboard,
  },
  {
    id: 'allocation',
    title: 'Allocation',
    icon: ImStatsBars2,
    path: PATHS.portfolio.explorer.path,
    submenu: [
      {
        id: 'allocation',
        title: 'Allocation - Explorer',
        path: PATHS.portfolio.explorer.path,
      },
      {
        id: 'esg',
        title: 'ESG Scores',
        path: PATHS.portfolio.esg.path,
      },
      {
        id: 'summary',
        title: 'Structure - Summary',
        icon: AiTwotonePieChart,
        exact: false,
        path: PATHS.portfolio.structure.path.replace(':classId?', 'All').replace(':page?', 'structure'),
      },
      {
        id: 'Equities',
        title: 'Structure - Equities',
        icon: AiTwotonePieChart,
        exact: false,
        path: PATHS.portfolio.structure.path.replace(':classId?', 'Equities').replace(':page?', 'structure'),
      },
      {
        id: 'Funds',
        title: 'Structure - Funds',
        icon: AiTwotonePieChart,
        exact: false,
        path: PATHS.portfolio.structure.path.replace(':classId?', 'Funds').replace(':page?', 'structure'),
      },
      {
        id: 'FixedIncome',
        title: 'Structure - Fixed Income',
        icon: AiTwotonePieChart,
        exact: false,
        path: PATHS.portfolio.structure.path.replace(':classId?', 'FixedIncome').replace(':page?', 'structure'),
      },
    ],
  },

  {
    id: 'pnl',
    title: 'P&L',
    icon: AiOutlineLineChart,
    path: PATHS.portfolio.pnlExplorer.path,
    submenu: [
      {
        id: 'pnlExplorer',
        title: 'P&L - Explorer',
        // icon: risk,
        path: PATHS.portfolio.pnlExplorer.path,
      },
      {
        id: 'pnlPosition',
        title: 'P&L - Position',
        // icon: risk,
        path: PATHS.portfolio.pnl.path,
      },
      {
        id: 'pnlByPerformance',
        title: 'P&L - Performance',
        path: PATHS.portfolio.performance.path,
      },
      {
        id: 'pnlByAttribution',
        title: 'P&L - Attribution',
        path: PATHS.portfolio.dashboard.classDashboard.path.replace(':classId', 'All').replace(':page?', 'pnl'),
      },
    ],
  },

  {
    id: 'snapshot',
    title: 'Positions / Transactions',
    icon: FaBalanceScaleLeft,
    path: PATHS.portfolio.snapshot.path,
    collapsible: false,
    exact: false,
    showEmpty: true,
    submenu: [
      {
        id: 'positions',
        title: 'Positions',
        path: PATHS.portfolio.snapshot.positions.path,
        exact: true,
      },
      {
        id: 'transactions',
        title: 'Transactions',
        path: PATHS.portfolio.snapshot.transactions.path,
        exact: true,
      },
      {
        id: 'addTransactions',
        title: 'Add Transactions',
        path: PATHS.portfolio.snapshot.edit.path,
        requiredCapabilities: [CapabilitiesHelper.Actions.ADD_TRADE],
        exact: true,
      },
      {
        id: 'importTransactions',
        title: 'Import Transactions',
        path: PATHS.portfolio.snapshot.upload.path,
        requiredCapabilities: [CapabilitiesHelper.Actions.ADD_TRADE],
        exact: true,
      },
    ],
  },

  {
    id: 'income',
    title: 'Income',
    icon: BsCashCoin,
    collapsible: false,
    path: PATHS.portfolio.income.path.replace(':classId?', 'All').replace(':page?', 'income'),
    exact: false,
    submenu: [
      {
        id: 'summary',
        title: 'Summary',
        path: PATHS.portfolio.income.path.replace(':classId?', 'All').replace(':page?', 'income'),
      },
      {
        id: 'dividends',
        title: 'Dividends Detail',
        path: PATHS.portfolio.dashboard.classDashboard.path.replace(':classId', 'Equities').replace(':page?', 'income'),
      },
      {
        id: 'distributions',
        title: 'Distributions Detail',
        path: PATHS.portfolio.dashboard.classDashboard.path.replace(':classId', 'Funds').replace(':page?', 'income'),
      },
      {
        id: 'crypto',
        title: 'Crypto Earn Detail',
        path: PATHS.portfolio.income.crypto.path,
      },
      {
        id: 'coupons',
        title: 'Coupons Detail',
        path: PATHS.portfolio.dashboard.classDashboard.path
          .replace(':classId', 'FixedIncome')
          .replace(':page?', 'income'),
      },
    ],
  },

  {
    id: 'riskV2',
    title: 'Risk',
    icon: GoDashboard,
    path: PATHS.portfolio.risk.path,
    requiredCapabilities: [CapabilitiesHelper.Actions.VIEW_RISK],
    sensibleToPnlValue: true,
    submenu: [
      {
        id: 'riskSubTitle',
        title: 'Historic Risk Analytics',
        // icon: risk,
        path: PATHS.portfolio.risk.path,
        sensibleToPnlValue: true,
      },

      {
        id: 'backtest',
        title: 'Backtest',
        // icon: backtest,
        path: PATHS.portfolio.backtest.path,
        sensibleToPnlValue: true,
      },
      {
        id: 'riskVsReturn',
        title: 'Risk vs Return',
        // icon: sensitivity,
        minimumSixMonths: true,
        path: PATHS.portfolio.sensitivity.path,
        sensibleToPnlValue: true,
      },
      {
        id: 'sensitivity',
        title: 'Sensitivity',
        path: PATHS.portfolio.risk_scenarios.path,
      },
      {
        id: 'analytics',
        title: 'Analytics',
        sensibleToPnlValue: true,
        path: PATHS.portfolio.risk.analytics.path,
      },
    ],
  },
  {
    id: 'other',
    title: 'More',
    icon: FaSlidersH,
    path: PATHS.portfolio.risk_scenarios.path,
    requiredCapabilities: [CapabilitiesHelper.Actions.VIEW_SCENARIOS],
    collapsible: true,
    submenu: [
      {
        id: 'reporting',
        title: 'Reporting',
        path: PATHS.portfolio.reportGenerator.path,
        sensibleToPnlValue: true,
      },
      {
        id: 'feesAndCharges',
        title: 'Fees & Charges',
        path: PATHS.portfolio.dashboard.classDashboard.path.replace(':classId', 'All').replace(':page?', 'fees'),
      },

      {
        id: 'equityFundamentals',
        title: 'Equity Fundamentals',
        path: PATHS.portfolio.dashboard.classDashboard.path
          .replace(':classId', 'Equities')
          .replace(':page?', 'fundamentals'),
      },

      {
        id: 'bondFundamentals',
        title: 'Bond Details',
        path: PATHS.portfolio.dashboard.classDashboard.path
          .replace(':classId', 'FixedIncome')
          .replace(':page?', 'fundamentals'),
      },
      {
        id: 'options',
        title: 'Option Detail',
        path: PATHS.portfolio.dashboard.classDashboard.path.replace(':classId', 'Equities').replace(':page?', 'options'),
        disabled: FEATURE_FLAGS.twr,
      },
    ],
  },

  // {
  //   id: 'cashFlow',
  //   title: 'Cash Flow',
  //   icon: BsCashCoin,
  //   path: PATHS.portfolio.income.path.replace(':classId?', 'All').replace(':page?', 'income'),
  //   disabled: FEATURE_FLAGS.twr,
  //   submenu: [
  //     {
  //       id: 'income',
  //       title: 'Income',
  //       path: PATHS.portfolio.income.path.replace(':classId?', 'All').replace(':page?', 'income'),
  //       disabled: FEATURE_FLAGS.twr,
  //     },
  //     {
  //       id: 'fees',
  //       title: 'Fees and Charges',
  //       path: PATHS.portfolio.fees.path.replace(':classId?', 'All').replace(':page?', 'fees'),
  //       disabled: FEATURE_FLAGS.twr,
  //     },
  //   ],
  // },
  // {
  //   id: 'reports',
  //   title: 'Reports',
  //   icon: FaRegNewspaper,
  //   path: PATHS.portfolio.reportGenerator.path,
  //   sensibleToPnlValue: true,
  // },
];

export const CLIENT_MENU: IMenuItem[] = [
  {
    id: 'otcInstruments',
    title: 'Manage Custom Assets',
    icon: FaCertificate,
    exact: false,
    showEmpty: false,
    // renderAsAnchor: true,
    requiredCapabilities: [CapabilitiesHelper.Actions.MANAGE_OTC_INSTRUMENT, CapabilitiesHelper.Actions.ADD_TRADE],
    // requiresPortfolioInfo: true,
    path: `/administration/unlisted/Equities`,
    submenu: [
      {
        id: 'realEstate',
        title: 'Real Estate',
        icon: IoDiamondSharp,
        exact: false,
        path: PATHS.instruments.path.replace(':assetClassId', 'RealEstate'),
        requiredCapabilities: [CapabilitiesHelper.Actions.ADD_TRADE],
      },
      {
        id: 'realAssets',
        title: 'Real Assets',
        icon: IoDiamondSharp,
        exact: false,
        path: PATHS.instruments.path.replace(':assetClassId', 'RealAssets'),
        requiredCapabilities: [CapabilitiesHelper.Actions.ADD_TRADE],
      },
      {
        id: 'realEstate',
        title: 'Unlisted Assets',
        icon: RiBuilding2Fill,
        exact: false,
        path: `/administration/unlisted/Equities`,
        requiredCapabilities: [CapabilitiesHelper.Actions.ADD_TRADE],
      },
    ],
  },
  {
    id: 'manageAccounts',
    title: 'Manage Account',
    icon: FaUserCog,
    exact: false,
    showEmpty: true,
    // renderAsAnchor: true,
    path: `/administration/manage-account/preferences`,
    // path: `${getSysadminUrl()}/manage-account/CompanyDetails`,
    requiredCapabilities: [CapabilitiesHelper.Actions.MANAGE_OTC_USER],
  },
];

// generate report navigation from config
export const generateDashboardMenu = (assetIds: string[], hasOptions?: boolean) => {
  const portfolioData = store.getState().portfolio.portfolioInfo.data;
  if (!portfolioData) {
    return [];
  }
  // const portfolioId = portfolioData.id;

  const menuItems = assetIds
    .filter((assetId) => ASSET_CLASSES_CONFIG[assetId] && ASSET_CLASSES_CONFIG[assetId].inMenu)
    .map((assetId) => {
      const asset = ASSET_CLASSES_CONFIG[assetId];
      return {
        id: assetId,
        title: asset.name,
        icon: asset.icon,
        // path: generatePath(PATHS.portfolio.dashboard.classDashboard.path, {
        //   portfolioId,
        //   classId: assetId
        // })
      };
    });

  // menuItems.unshift({
  //   id: 'All',
  //   // icon: allAssetsIcon,
  //   title: 'All Assets',
  //   path: generatePath(PATHS.portfolio.dashboard.classDashboard.path, {
  //     portfolioId,
  //     classId: 'All'
  //   }),
  //   submenu: [
  //     { id: 'summary', title: 'Summary', path: '' },
  //     { id: 'pnl', title: 'P&L', path: '' },
  //     { id: 'structure', title: 'Structure', path: '' },
  //     { id: 'income', title: 'Income', path: '' },
  //     { id: 'fees', title: 'Fees & Charges', path: '' }
  //   ]
  // });

  return menuItems;
};
