import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  label?: string;
  accruedInterestIsCalculated?: boolean;
}

const AccruedInterest: FC<IProps> = ({ label = 'Accrued interest', accruedInterestIsCalculated }) => {
  return (
    <FormFieldWrapper
      labelClassName={accruedInterestIsCalculated ? s.warningText : undefined}
      label={label}
      required={false}
      className={s.minField}
    >
      <Field
        placeholder="(Auto)"
        name="accruedInterestLocal"
        type="text"
        theme="inverse"
        component={renderInput}
        className="input--small"
        normalize={(value: number) => positiveNumber(floatNumberV2(value, 2)).toString()}
      />
    </FormFieldWrapper>
  );
};

export default AccruedInterest;
