import { StylesConfig } from 'react-select/lib/styles';

export const customStyles: StylesConfig = {
  container: () => ({
    position: 'relative',
    padding: 8,
    backgroundColor: 'var(--dark-two)',
    borderWidth: 0,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? 'var(--selected-bg)' : 'var(--secondary-bg)',
    color: isSelected ? 'var(--text-white)' : 'var(--text-color)',
    padding: 7,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': { backgroundColor: 'var(--dark-two)' },
    '&:active': {
      backgroundColor: 'var(--dark-two)',
    },
  }),
  control: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    boxShadow: 'none',
    cursor: 'pointer',
    font: 'var(--input-font)',
    color: 'var(--light)',
    height: '20px',
  }),
  indicatorSeparator: () => ({}),
  singleValue: () => ({}),
  placeholder: () => ({
    color: 'var(--darkgray)',
  }),
  menuList: () => ({
    backgroundColor: 'var(--secondary-bg)',
    width: '100%',
    position: 'absolute',
    right: '0',
    borderRadius: 4,
    overflow: 'hidden',
    zIndex: 1000,
  }),
  dropdownIndicator: () => ({
    transform: 'scale(0.5)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: -20,
  }),
};
