import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

interface IProps {
  disabled?: boolean;
  types: IAdjustmentType[];
}

const FeeType: FC<IProps> = ({ disabled, types }) => {
  const options = types
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((type) => ({
      id: type.sourceId.sourceId,
      name: type.name,
      value: type,
    }));

  const ready = !!options.length;

  return (
    <FormFieldWrapper label="Fee Type" className={cn(s.nameField, s.fieldsWrapper, s.strikeField)} required={true}>
      <Field
        component={renderAutocomplete}
        name="instrument"
        options={options}
        theme="inverse"
        disableReset={true}
        disabled={disabled}
        inputDisabled={false}
        inputProps={{
          theme: 'inverse',
          className: 'input--small',
          placeholder: ready ? 'Select' : 'Select Currency',
        }}
      />
    </FormFieldWrapper>
  );
};

const FEE_TYPES: Array<IOption<string>> = [
  { id: 'managementFee', value: 'managementFee', name: 'Management Fee' },
  { id: 'custodianFee', value: 'custodianFee', name: 'Custodian/Trustee Fee' },
  { id: 'performanceFee', value: 'performanceFee', name: 'Performance Fee' },
  { id: 'interest', value: 'interest', name: 'Interest' },
  { id: 'tax', value: 'tax', name: 'Tax' },
  { id: 'other', value: 'other', name: 'Other' },
];

export default FeeType;
