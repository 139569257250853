import React from 'react';
import { useExplorerContext } from '../../../../../../services/context/ExplorerContext';
import { Card } from '@iliotech/storybook';
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  // ChartSeriesLabels,
} from '@progress/kendo-react-charts';
import StructureDonut from './StructureDonut/StructureDonut';
import useCurrentWidth from '../../../../../../services/hooks/useCurrentWidth';
import { DashboardTitle } from '../../../../../../components/UIWidgets/DashboardTitle/DashboardTitle';
import { usePortfolioInfo } from '../../../../../../services/hooks/usePortfolioInfo';

const getAllocationName = (allocationType: AllocationType) => {
  switch (allocationType) {
    case 'IndustrySector':
      return 'Sector';
    case 'AssetClass':
      return 'Asset Class';
  }
  return allocationType;
};

export const StructureWheel = ({ height = 400, expanded, pnl = false }: IExplorerWidgetProps) => {
  const [hasRendered, setHasRendered] = React.useState(false);

  const {
    selectedAllocationType,
    setSelectedAllocationType,
    setSelectedAllocationSubType,
    availableAllocationTypes,
    donutData,
  } = useExplorerContext();
  const { portfolioInfo } = usePortfolioInfo();
  const riskAssetView = portfolioInfo.data?.riskAssetView;
  const width = useCurrentWidth();
  const dmap = donutData as IDonutMap;
  const title = React.useMemo(() => {
    let val = pnl ? 'P&L by ' : 'Net Worth by ';
    switch (selectedAllocationType) {
      case 'AssetClass':
        if (riskAssetView === 'RISK_ASSET_CLASS') {
          val += 'Risk Asset Class and Investment Type';
        } else {
          val += 'Asset Class and Subclass';
        }
        break;
      // case 'RiskAssetClass':
      //   break;
      case 'Custodian':
      case 'Currency':
        val += selectedAllocationType;
        break;
      case 'IndustrySector':
        val += 'Sector';
        break;
      case 'Region':
        val += 'Region / Country';
    }
    return val;
  }, [selectedAllocationType, riskAssetView]);

  const renderChoices = React.useMemo(() => {
    if (!donutData) {
      return null;
    }

    return availableAllocationTypes
      .filter((allocationType) => !!dmap[allocationType])
      .map((allocationType) => (
        <div
          onClick={() => {
            setSelectedAllocationType(allocationType);
            setSelectedAllocationSubType({ subClassId: null, subSubClassId: null });
          }}
          key={allocationType}
          style={{
            height: 60,
            width: width > 1500 ? 150 : 120,
            backgroundColor: '#485461',
            backgroundImage: 'linear-gradient(90deg, rgb(25, 29, 41) 0%, rgb(33, 38, 51) 74%)',
            borderRadius: 'var(--btn-border-radius)',
            cursor: 'pointer',
            color: selectedAllocationType === allocationType ? 'var(--confirm)' : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '0.5rem',
            marginBottom: 5,
          }}
        >
          <div>{getAllocationName(allocationType)}</div>
          {
            <Chart
              onRender={() => !hasRendered && setTimeout(() => setHasRendered(true), 1000)}
              transitions={!hasRendered || selectedAllocationType === allocationType}
              style={{ height: 60, width: 60 }}
            >
              <ChartArea background={'transparent'} />
              <ChartSeries>
                <ChartSeriesItem type="donut" data={dmap?.[allocationType]} categoryField="name" field="percentage" />
              </ChartSeries>
              <ChartLegend visible={false} />
            </Chart>
          }
        </div>
      ));
  }, [donutData, selectedAllocationType, width]);

  const renderInner = React.useMemo(() => {
    if (expanded) {
      return (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StructureDonut donutMap={dmap} expanded={true} />
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 0.8 }}>{renderChoices}</div>
          <div style={{ flex: 1.5 }}>
            <StructureDonut donutMap={dmap} expanded={false} />
          </div>
        </div>
      );
    }
  }, [dmap, selectedAllocationType, width]);

  if (!dmap) {
    return null;
  }
  return (
    <Card style={{ height, padding: 10, paddingLeft: 15, paddingRight: 15 }}>
      <DashboardTitle title={title} />
      {renderInner}
    </Card>
  );
};

export default StructureWheel;
