import React from 'react';
import s from '../BetaVsPortfolioBenchmarkChart.module.css';
import cn from 'classnames';

import { ICustodianPosition } from '../../../interfaces';

interface IBetaBarColumn {
  slotPosition: number;
  name: string;
  ticker: string;
  baseCurrencySymbol: string;
  custodians: ICustodianPosition[];
  totalValue: string;
  beta: number;
  barColors: string[];
  currency?: string;
  tooltipDisplaced?: boolean;
}

const inRange = (val: number, min: number, max: number) => {
  return (val - min) * (val - max) <= 0;
};

export function BarColumn(props: IBetaBarColumn) {
  const barColors = props.barColors;
  const slotPosition = props.slotPosition;
  const barLabel = props.ticker;
  const custodians = props.custodians;
  // @ts-ignore
  const totalValue = props.totalValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  const instrumentBeta = props.beta;
  const currency = props.baseCurrencySymbol;

  const custodianPercentages: number[] = [];

  let barColor = barColors[9];

  switch (true) {
    // case inRange(instrumentBeta, -3, -1.5):
    // case inRange(instrumentBeta, 1.5, 3):
    //   bc = barColors[9];
    //   break;
    case inRange(instrumentBeta, -1.5, -0.5):
    case inRange(instrumentBeta, 0.5, 1.5):
      barColor = barColors[7];
      break;
    case inRange(instrumentBeta, -0.5, -0.25):
    case inRange(instrumentBeta, 0.25, 0.5):
      barColor = barColors[4];
      break;
    case inRange(instrumentBeta, -0.25, 0.25):
      barColor = barColors[1];
      break;
  }

  custodians.map((el) => custodianPercentages.push(el.marketValueBasePercentage));

  const tooltipInfo = (
    <div className={cn(s.tooltipBody)}>
      <div className={s.listItem}>
        <div className={cn(s.tooltipCaption, s.leftCol)}>{props.name}</div>
        <div className={cn(s.tooltipCaptionValue, s.rightCol)}>&beta;&nbsp;{instrumentBeta}</div>
      </div>
      <hr className={s.separatorLine} />
      {custodians.map((cust, index) => {
        const formattedCurrency = cust.marketValueBase.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return (
          <div className={s.listItem} key={index}>
            <div className={s.leftCol}>{cust.custodian}:</div>{' '}
            <div className={s.rightCol}>{currency + formattedCurrency}</div>
          </div>
        );
      })}
      <hr className={s.separatorLineFinal} />
      <div className={s.listItem}>
        <div className={s.leftCol}>Total value:</div>{' '}
        <div className={s.rightCol}>
          <strong>{currency + totalValue}</strong>
        </div>
      </div>
    </div>
  );

  return (
    <div className={cn(s.BarColumn, s.tooltip)}>
      <div className={cn(s.tooltiptext, props.tooltipDisplaced ? s.tooltipLast : '')}>{tooltipInfo}</div>
      <div className={s.BarItem} style={{ backgroundColor: barColor }}>
        {barLabel}
        <br />
        &beta;&nbsp;{instrumentBeta}
      </div>
      {custodianPercentages.map((el, index) => {
        return (
          <div key={index} className={s.BarItem} style={{ backgroundColor: barColor, height: 20 + el + 'px' }}>
            {el}%
          </div>
        );
      })}
    </div>
  );
}
