import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { AssetsActions } from '../actions/AssetsActions';
import { ReduxHelper } from '../utils/ReduxHelper';

export interface IAssetsClassesState extends ILoadingDataState {
  data: IAssetClass[];
}

const INITIAL_STATE: IAssetsClassesState = ReduxHelper.createInitialState<IAssetClass[]>([]);

const assetsClassesLightReducer = ReduxHelper.createHttpRequestReducer<IAssetsClassesState>(
  INITIAL_STATE,
  AssetsActions.LIGHT_ASSETS_REQUEST,
  AssetsActions.LIGHT_ASSETS_SUCCESS,
  AssetsActions.LIGHT_ASSETS_ERROR
);

export default assetsClassesLightReducer;
