import { Widget } from '../../../../../../../components';
import s from '../../../Dashboard.module.scss';
import { DASHBOARD_PORTFOLIO_INCOME_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import ReactEcharts from 'echarts-for-react';
import React, { createRef } from 'react';
import { getIncomeChartOptions } from '../../../../../../Reports/reportsChartOptions';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import useBarDataToggableLegend from '../../../../../../../../../services/hooks/useBarDataToggableLegend';
import { ToggableChartLegend } from '../../../../../../../../../components/ToggableChartLegend';
import { chartData } from '../../../../../../Risk/components/RollingVsVolatilityChart/__testdata__/sampleRollingVolatilityChartData';

interface IProps {
  incomeData: IBarData[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency: string;
  incomePeriod: IPeriod;
}

const SinceInceptionIncomeChart = ({
  incomeData,
  portfolioCurrencyFormatter,
  selectedCurrency,
  incomePeriod,
}: IProps) => {
  const eChartsRef = createRef<any>();
  const { toggledData, legend, onLegendItemClick } = useBarDataToggableLegend({
    chartData: incomeData,
    portfolioCurrencyFormatter,
    selectedCurrency,
    toggableTotal: false,
  });

  const incomePastOpt = getIncomeChartOptions(toggledData, portfolioCurrencyFormatter, selectedCurrency, incomePeriod);

  return (
    <Widget
      title="Income"
      colNum={12}
      className={s.defaultContainer}
      titleInfo={DASHBOARD_PORTFOLIO_INCOME_HELP_TEXT.ChartIncome}
    >
      <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
      {incomePastOpt && (
        <ReactEcharts
          style={{ height: '420px', width: '100%', flexGrow: '1' }}
          option={incomePastOpt}
          ref={eChartsRef}
          notMerge={true}
          lazyUpdate={true}
        />
      )}
    </Widget>
  );
};
export default SinceInceptionIncomeChart;
