import React, { FunctionComponent, PropsWithChildren } from 'react';
import s from '../../WorkGroupManager.module.scss';
import ComboList from '../../../../components/ComboList/ComboList';

export interface ISearchContainer {
  comboItems: IComboItemMulti[];
  onValueSelected: (item: IComboItemWithCategory) => void;
  className?: string;
  placeholder?: string;
}

type IProps = PropsWithChildren<ISearchContainer>;

const SearchBar: FunctionComponent<IProps> = ({
  comboItems,
  className,
  onValueSelected,
  placeholder = 'Search portfolios and clients',
}) => {
  return (
    <div className={s.searchContainer}>
      <ComboList
        className={className}
        onValueSelected={onValueSelected}
        name={'Search'}
        items={comboItems}
        placeholder={placeholder}
        // clearOnSelected={true}
      />
    </div>
  );
};

export default React.memo(SearchBar);
