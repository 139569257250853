import React, { FunctionComponent } from 'react';

import { Button } from '../../UIWidgets/Button';

import s from '../Upload.module.scss';

const UploadLoading: FunctionComponent<{ progress: number; onReset: () => void }> = (props) => {
  return (
    <div className={s.contentWrapper}>
      <div className={s.loadingWrapper}>
        <div className={s.loadingTitle}>
          <span className="text-md">Uploading…</span>
          <Button className="button--close" variant="empty" onClick={props.onReset} />
        </div>
        <div className={s.fullProcess}>
          <div style={{ width: `${props.progress}%` }} className={s.process} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(UploadLoading);
