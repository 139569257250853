import React from 'react';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { Widget } from '../../../../../../components';
import { Card } from '@iliotech/storybook';
import {
  DASHBOARD_PORTFOLIO_HELP_TEXT,
  DASHBOARD_PORTFOLIO_PNL_HELP_TEXT,
} from '../../../../../../../../services/constants/tooltipContextHelp';
import s from '../../Dashboard.module.scss';
import TopListByPnLWidgetsContainer from '../../../../../../components/TopListByPnLWidget/TopListByPnLWidgetsContainer';
import { getTopPositionsByPnLColumns } from '../../topPositionsColumns';
import { dashboardCellRenderer } from '../../utils/DashboardCellRenderer';
import PnlByAttributionChartContainer from '../../components/PnlByAttributionChartContainer/PnlByAttributionChartContainer';
import { PnlByAssetClassChartContainer } from '../../components/PnlByAssClassChartContainer';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';

interface IProps {
  period: IPeriod;
  portfolioId: string;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  viewPositionHistory(dateString: string): void;
}

const Pnl = ({ period, portfolioId, viewPositionHistory, portfolioCurrencyFormatter }: IProps) => {
  const { preferences } = useCapabilities();
  const { portfolioInfo } = usePortfolio();
  const riskAssetView = portfolioInfo.data?.riskAssetView ?? preferences.defaultRiskAssetView;
  const assetClassChartTitle = riskAssetView === 'RISK_ASSET_CLASS' ? 'P&L By Risk Asset Class' : 'P&L By Asset Class';

  return (
    <Widget>
      <div>
        <Card>
          <Widget colNum={8} title={assetClassChartTitle} titleInfo={DASHBOARD_PORTFOLIO_HELP_TEXT.ChartPnlByAssetClass}>
            <PnlByAssetClassChartContainer
              portfolioId={portfolioId}
              viewPositionHistory={viewPositionHistory}
              hidePeriodSelector={true}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              period={period}
            />
            {/*
                // DO NOT Remove the below component, it still may be needed.
                <PerformanceChartWidgetContainer hidePeriodSelector={true} />*/}
          </Widget>
        </Card>
        <br />
        <br />
        <Card>
          <Widget title="P&L by Attribution" colNum={8} titleInfo={DASHBOARD_PORTFOLIO_HELP_TEXT.ChartPnlByAttribution}>
            <PnlByAttributionChartContainer
              portfolioId={portfolioId}
              viewPositionHistory={viewPositionHistory}
              hidePeriodSelector={true}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              period={period}
            />
            {/*
                // DO NOT Remove the below component, it still may be needed.
                <PortfolioProfitWidgetsContainer hidePeriodSelector={true} /> */}
          </Widget>
        </Card>
      </div>
      <div style={{ marginTop: 30 }}>
        <Widget title="Top 10 by P&L" className={s.contaienr} titleInfo={DASHBOARD_PORTFOLIO_PNL_HELP_TEXT.tableTop10}>
          <TopListByPnLWidgetsContainer
            portfolioId={portfolioId}
            showCategories={true}
            columns={getTopPositionsByPnLColumns(portfolioCurrencyFormatter, riskAssetView)}
            cellRenderer={(data) => dashboardCellRenderer(data, portfolioCurrencyFormatter)}
          />
        </Widget>
      </div>
    </Widget>
  );
};

export default Pnl;
