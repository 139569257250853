import React from 'react';
import { PositionEditor } from './PositionEditor';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import cn from 'classnames';
import s from './PositionDetailRow.module.css';
import { TransactionList } from './TransactionList';
import { CashflowList } from './CashflowList';
import { titleCase } from '../../../../../../../../services/utils/StringHelpers';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import CapabilitiesHelper from '../../../../../../../../services/utils/CapabilitiesHelper';
import { DividendCreator } from './DividendCreator';
import { EarnCreator } from './EarnCreator';
import { CryptoWallets } from './CryptoWallets/CryptoWallets';

interface IOwnProps {
  position: IPortfolioSnapshot;
  transactions: IPortfolioTrade[];
  fetchPositionsAndTransactions(): void;
  portfolioId: string;
}

type TabType = 'position' | 'transactions' | 'edit' | 'cashFlow' | 'dividend' | 'earn' | 'wallets';

export const PositionDetailRow = ({ portfolioId, position, transactions, fetchPositionsAndTransactions }: IOwnProps) => {
  const [tab, setTab] = React.useState<TabType>('transactions');
  React.useEffect(() => {
    let fallbackTab = tab;

    switch (position.assetClassId) {
      case 'CashAndEquivalents':
        fallbackTab = 'cashFlow';
    }

    if (fallbackTab !== tab) {
      setTab(fallbackTab);
    }
  }, [position, tab]);

  const { actions } = useCapabilities();
  const canAddTrades = actions.includes(CapabilitiesHelper.Actions.ADD_TRADE);
  const canEditTrades = actions.includes(CapabilitiesHelper.Actions.EDIT_TRADE);

  const stopPropagation = (e: any) => {
    e.preventDefault?.();
    e.stopPropagation?.();
  };

  const renderContent = () => {
    switch (tab) {
      case 'edit':
        return <PositionEditor position={position} fetchPositionsAndTransactions={fetchPositionsAndTransactions} />;
      // case 'position':
      //   return <div>Something fun could go here</div>;
      case 'transactions':
        return (
          <TransactionList
            portfolioId={portfolioId}
            position={position}
            transactions={transactions}
            canEditTrades={canEditTrades}
          />
        );
      case 'cashFlow':
        return (
          <CashflowList
            portfolioId={portfolioId}
            position={position}
            canEditTrades={canEditTrades}
            fromDate="2021-01-01"
            toDate="2021-06-05"
          />
        );
      case 'dividend':
        return (
          <DividendCreator
            portfolioId={portfolioId}
            position={position}
            canEditTrades={canEditTrades}
            transactions={transactions}
          />
        );
      case 'earn':
        return (
          <EarnCreator
            portfolioId={portfolioId}
            position={position}
            canEditTrades={canEditTrades}
            transactions={transactions}
          />
        );
      case 'wallets':
        return <CryptoWallets portfolioId={portfolioId} position={position} canEditTrades={canEditTrades} />;
    }
  };

  return (
    <div onClick={stopPropagation} className={cn(s.surround)}>
      <NavTabs {...{ tab, setTab, position, canEditTrades, canAddTrades }} />
      <div className={s.noScrollbar} style={{ padding: '1rem', flex: 1 }}>
        {renderContent()}
      </div>
    </div>
  );
};

type NavTabsProps = {
  tab: TabType;
  setTab: React.Dispatch<React.SetStateAction<TabType>>;
  position: IPortfolioSnapshot;
  canAddTrades: boolean;
};
const NavTabs = ({ tab, setTab, position, canAddTrades }: NavTabsProps) => {
  const Tab = ({ id, label }: { id: TabType; label?: string }) => {
    return (
      <div className={cn(s.navButton, tab === id && s.active)} onClick={() => setTab(id)}>
        {titleCase(label ?? id)}
      </div>
    );
  };

  const isCashAsset = position.instrumentAssetClassId === 'CashAndEquivalents';
  const isCryptoAsset = position.instrumentAssetClassId === 'CryptoCurrencies';
  const showNewTransactions = !isCashAsset && position.instrumentAssetClassId !== 'Adjustments' && canAddTrades;
  const showDividendTab = position.instrumentAssetClassId === 'Equities' || position.instrumentAssetClassId === 'Funds';

  return (
    <nav className={cn(s.navSurround, 'bg')}>
      {/*<Tab id={'position'} />*/}
      {!isCashAsset && <Tab id={'transactions'} label={'Related Transactions'} />}
      {isCashAsset && <Tab id={'cashFlow'} label={'Cash Flow'} />}
      {showNewTransactions && <Tab id={'edit'} label={'New Transaction'} />}
      {showDividendTab && (
        <Tab id={'dividend'} label={`New ${position.instrumentAssetClassId === 'Funds' ? 'Distribution' : 'Dividend'}`} />
      )}
      {isCryptoAsset && <Tab id={'earn'} label={`Add Earn event`} />}
      {isCryptoAsset && <Tab id={'wallets'} label={`Wallets`} />}
      {/*<Tab id={'edit'} label={position.assetClassId} />*/}
    </nav>
  );
};
