import React, { FC } from 'react';
import cn from 'classnames';
import s from '../UniversalUpload.module.css';

import { PortfolioHeader } from '../../../../../components/PortfolioHeader';
import { IRootState } from '../../../../../../../services/store';
import { connect, useDispatch } from 'react-redux';
import getAssetIdentifier from './getAssetIdentifier';
import { TableWrapper } from '../../../../../../../components/UIWidgets/TableWrapper';
import { ITableColumn } from '../../../../../../../components/UIWidgets/TableWrapper/models';
import { getApiUrl, getCustomApiUrl } from '../../../../../../../services/constants/endpoints';
import axios from 'axios';
import { Button, Confirm, Loader } from '../../../../../../../components/UIWidgets';
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom';
import { PATHS } from '../../../../../../../router/paths';
import getAssetSubclassFromId from './getAssetSubclassFromId';
import triageCellRenderer from './triageCellRenderer';
import { IOption } from '../../../../../../../components/UIWidgets/Autocomplete';
import { CognitoHelper } from '../../../../../../../services/utils/CognitoHelper';
import { Notification } from 'rsuite';
import { UploadSettings } from './components/UploadSettings';
import { CustodianResolution, ExtendedCustodianOption } from './components/CustodianResolution';
import { uniq } from 'lodash';
import { sendRequest } from '../../../../../../../services/hooks/useApi';
import { CustodianActions } from '../../../../../../../services/actions';
import { utils, writeFile } from 'xlsx';
import moment from 'moment';
import useCustodiansOption from '../../../../../../../services/hooks/useCustodiansOption';
import { useIframeMode } from '../../../../../../../services/hooks/useIframeMode';

interface IProps {
  a?: string;
}

interface IMapStateToProps {
  data: IRootState['snapshot']['uploadTemplateResult']['data'];
  unparsedData: IRootState['snapshot']['uploadTemplateResult']['unparsedData'];
  assetsClasses: IRootState['assetsClasses']['data'];
  assetsSubClasses: IRootState['assetsSubClasses']['data'];
  portfolioInfo: IRootState['portfolio']['portfolioInfo']['data'];
}

type Props = IProps & IMapStateToProps;

const UniversalUploadTriage: FC<Props> = ({ unparsedData, data, assetsSubClasses, assetsClasses, portfolioInfo }) => {
  const assetSubClassOptions: Array<IOption<IAssetSubClass>> = React.useMemo(
    () =>
      assetsSubClasses.map((sub) => ({
        id: sub.id,
        name: sub.name,
        value: sub,
      })),
    [assetsSubClasses]
  );

  const assetInformation = React.useRef<Record<string, IUploadTemplateResult>>({});
  const [baseCurrencySettlement, setBaseCurrencySettlement] = React.useState<SettlementOption>('BASE');
  const [localCurrencySettlement, setLocalCurrencySettlement] = React.useState<SettlementOption>('BASE');
  const [resultMessage, setResultMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [custodiansMappingError, setCustodiansMappingError] = React.useState(false);
  const [custodian, setCustodian] = React.useState<IOption<ICustodian> | null>(
    getDefaultCustodian(portfolioInfo?.custodians)
  );
  const { pureCustodians } = useCustodiansOption();
  const [custodianOptions, setCustodianOptions] = React.useState<IOption<ICustodian>[]>([]);
  const [custodianMappings, setCustodianMappings] = React.useState<Record<string, string>>({});
  const [, setRenderTrack] = React.useState<number>(0);
  const [syntheticDividends, setSyntheticDividends] = React.useState<Record<string, boolean>>({});
  const history = useHistory();
  const iframeMode = useIframeMode();
  const {
    params: { portfolioId },
  } = useRouteMatch<{ portfolioId: string }>();
  const portfolioCurrency = portfolioInfo?.currency.name;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Object.values(unparsedData).length > 0) {
      setErrorMessage(
        Object.values(unparsedData).length +
          ` rows in the file have invalid values. \n Rather than try to guess what they should be we've prepared them to download into a separate file.`
      );
    }
  }, [unparsedData]);

  /** Set off searches for each individual asset, once the summary data is received from the server **/
  React.useEffect(() => {
    if (data) {
      const newAssetInformation: Record<string, IUploadTemplateResult> = {};
      data.forEach((item) => {
        const identifier = getAssetIdentifier(item);
        if (!newAssetInformation[identifier]) {
          newAssetInformation[identifier] = {
            ...item,
            showSettlementOption: !item.settlementOption,
            settlementOption: item.settlementOption || 'BASE',
          };
        } else {
          newAssetInformation[identifier].showSettlementOption =
            newAssetInformation[identifier].showSettlementOption || !item.settlementOption;
          newAssetInformation[identifier].settlementOption =
            newAssetInformation[identifier].settlementOption === 'BASE' ? 'BASE' : item.settlementOption || 'BASE';
        }
      });
      // Promise.all(
      Object.entries(newAssetInformation).map(([_, item]) => {
        return loadAssetData(item);
      });
      // );
      assetInformation.current = newAssetInformation;
      setRenderTrack(Math.random());
    }
  }, [data]);

  React.useEffect(() => {
    const newCustodianOptions =
      pureCustodians.map((c) => ({
        id: c.id,
        name: c.name,
        value: c,
        onlyManualDividends: c.onlyManualDividends,
      })) ?? [];
    setCustodianOptions(newCustodianOptions);
    setCustodian(getDefaultCustodian(pureCustodians));
  }, [pureCustodians]);

  /** Search for the details of a particular asset **/
  const loadAssetData = (item: IUploadTemplateResult) => {
    const { assetSubClass, ticker: query, exchange, country } = item;
    // * Handle the special case of subscriptions, where the currency should default to portfolio currency
    const currency = item.assetSubClass === 'SubCash' ? item.currency ?? portfolioCurrency : item.currency;

    const params = Object.entries({ assetSubClass, currency, query, exchange, country })
      .filter(([_k, value]) => value !== null)
      .map(([key, v]) => `${key}=${encodeURIComponent(String(v))}`)
      .join('&');

    const url = `${getApiUrl(`instruments.search`)}?${params}`;
    axios.get(url).then((result) => {
      if (result?.data) {
        const resolvedAssets = result.data.sort((a: IResolvedAsset, b: IResolvedAsset) => a.name.localeCompare(b.name));

        const value: Partial<IUploadTemplateResult> = {
          resolvedAssets,
          ...(result.data.length === 1 ? { selectedResolvedAsset: 0 } : {}),
          resolved: checkResolved(item, resolvedAssets),
        };

        const c = result.data[0]?.currency;
        if (c) {
          value.currency = c;
        }
        // value.settlementOption = undefined;
        updateAssetInformation(getAssetIdentifier(item))(value);
      }
    });
  };

  const updateAssetInformation = React.useCallback(
    (identifier: string) => (value: Partial<IUploadTemplateResult>) => {
      const newInfo = { ...assetInformation.current[identifier], ...value };
      if (!!value.assetSubClass) {
        newInfo.resolved = false;
        loadAssetData(newInfo);
      }
      assetInformation.current[identifier] = newInfo;
      setRenderTrack(Math.random());
    },
    [assetInformation.current, setRenderTrack]
  );

  const selectResolvedAsset = React.useCallback(
    (identifier: string, selectedResolvedAsset?: number) => {
      const asset = assetInformation.current[identifier];
      const value: Partial<IUploadTemplateResult> = { selectedResolvedAsset, resolved: true };

      if (typeof selectedResolvedAsset === 'number' && asset.resolvedAssets?.[selectedResolvedAsset]) {
        if (asset.resolvedAssets?.[selectedResolvedAsset]?.currency) {
          value.currency = asset.resolvedAssets![selectedResolvedAsset]!.currency;
        }
      }
      updateAssetInformation(identifier)(value);
    },
    [updateAssetInformation]
  );

  const getAssetSubclass = React.useCallback((id?: string) => getAssetSubclassFromId(id, assetsSubClasses), [
    assetsSubClasses,
  ]);

  const cancel = () => {
    if (iframeMode) {
      window.opener?.postMessage?.('close-window-from-iframe', '*');
      return;
    }
    history.push(generatePath(PATHS.portfolio.snapshot.upload.path, { portfolioId }));
  };

  const triageCellRendererCallback = React.useCallback(
    triageCellRenderer({
      selectResolvedAsset,
      updateAssetInformation,
      getAssetSubclass,
      baseCurrencySettlement,
      localCurrencySettlement,
      portfolioCurrency,
      assetSubClassOptions,
    }),
    [
      selectResolvedAsset,
      updateAssetInformation,
      getAssetSubclass,
      baseCurrencySettlement,
      localCurrencySettlement,
      portfolioCurrency,
      assetSubClassOptions,
    ]
  );

  /** Change the default settlement currency for either Portfolio Currency or Non-Portfolio Currency assets */
  const changeSettlementCurrency = (type: 'BASE' | 'LOCAL') => (value: SettlementOption) => {
    const matchBase = type === 'BASE';
    const current = matchBase ? baseCurrencySettlement : localCurrencySettlement;
    if (value !== current) {
      Object.entries(assetInformation.current).forEach(([key, asset]) => {
        if ((asset.currency === portfolioInfo?.currency.name) === matchBase) {
          assetInformation.current[key] = {
            ...asset,
            settlementOption: value,
          };
        }
      });
      if (matchBase) {
        setBaseCurrencySettlement(value);
      } else {
        setLocalCurrencySettlement(value);
      }
    }
  };

  /* * Custodian helpers */
  const detectedCustodians = React.useMemo(() => {
    if (!data?.length) {
      return [];
    }
    return uniq(data.map(({ custodian: cust }) => cust)).filter((value) => !!value) as string[];
  }, [data]);

  const existingCustodians: ExtendedCustodianOption[] = React.useMemo(() => {
    const custodians = custodianOptions.map(({ id, value, name, code }) => ({ id, value, name, code, label: name }));

    const newCustodianMappings: Record<string, string> = {};

    custodians.forEach((cust) => {
      const identifiedCustodian = detectedCustodians.find(
        (detectedCust) => detectedCust?.toLowerCase().trim() === cust.name.toLowerCase().trim()
      );
      if (!!identifiedCustodian) {
        newCustodianMappings[cust.name] = identifiedCustodian;
      }
    });

    setCustodianMappings(newCustodianMappings);

    return custodians;
  }, [custodianOptions, detectedCustodians]);

  const assertCustodiansMapped = () => {
    const allMapped = detectedCustodians.every((c) => !!custodianMappings[c]);

    setCustodiansMappingError(!allMapped);

    return allMapped;
  };

  const prepareCustodians = React.useCallback(async () => {
    const custodianNames = new Map();
    // value is the custodian name
    Object.entries(custodianMappings).forEach(([key, value]) => custodianNames.set(key, syntheticDividends[key]));

    if (custodianNames.size > 0) {
      await sendRequest(`/api/v1/portfolio/${portfolioInfo?.id}/addCustodians`, {
        data: Object.fromEntries(custodianNames),
        method: 'PUT',
      });
    }
    return dispatch(CustodianActions.fetchCustodiansList());
  }, [custodianMappings, syntheticDividends]);

  /** Submit trades to go to blotter / trade transit **/
  const submit = React.useCallback(async () => {
    if (!assertCustodiansMapped()) {
      return;
    }

    await prepareCustodians();

    setUploadInProgress(true);
    const trades = data.map((uploadResult) => {
      const item: any = { ...uploadResult };
      const currentAssetInformation = assetInformation.current[getAssetIdentifier(item)];
      const resolvedAssets = currentAssetInformation?.resolvedAssets;

      if (resolvedAssets && typeof currentAssetInformation?.selectedResolvedAsset === 'number') {
        item.sourceId = resolvedAssets[currentAssetInformation!.selectedResolvedAsset!].sourceId;
      }

      if (!item.settlementOption) {
        /** If settlementOption isn't set in spreadsheet, fallback to what has been selected at the asset level,
         * and then to the default for the type (based on whether asset matches portfolio currency or not) */
        item.settlementOption =
          currentAssetInformation.settlementOption ??
          (currentAssetInformation.currency === portfolioCurrency ? baseCurrencySettlement : localCurrencySettlement);
      }

      return {
        ...item,
        custodian: custodianMappings[item.custodian] || item.custodian || custodian?.name,
      };
    });
    const uploadUrl = getCustomApiUrl('snapshot.tradeUpload', [{ idLabel: ':portfolioId', idValue: portfolioId }]);

    CognitoHelper.withAuthorisationToken().then(async () => {
      setResultMessage(SUBMITTING);
      axios
        .post(uploadUrl, trades)
        .then((result) => {
          setResultMessage(result?.data?.toString());
        })
        .catch((error) => {
          setResultMessage('');
          Notification.error({ title: 'An error occurred', description: error.message });
        })
        .finally(() => {
          setUploadInProgress(false);
        });
    });
  }, [
    data,
    assetInformation.current,
    custodian,
    baseCurrencySettlement,
    localCurrencySettlement,
    prepareCustodians,
    custodianMappings,
    assertCustodiansMapped,
  ]);

  const downloadInvalidEntries = () => {
    const dictionary = {
      A: 'Transaction Date',
      B: 'Quantity/ Nominal',
      C: 'Ticker or ISIN (e.g. MSFT US)',
      D: 'Local Price',
      E: 'Broker / Custodian',
      F: 'Commission (in local currency)',
      G: 'Trade Cost (in local currency)',
      H: 'Total Transaction Amount',
      I: 'Settle in Local Currency',
      J: 'Stock Name',
      K: 'Notes',
      L: 'Currency',
      M: 'Settlement Date',
      N: 'Accrued Interest',
      O: 'Qty 2\n (FX Only)',
      P: 'Operation',
      error: 'error',
    };
    const toParse: any[] = [];
    Object.values(unparsedData).forEach((row) => {
      const toRet = {};
      Object.keys(dictionary).forEach((key) => {
        // @ts-ignore
        toRet[dictionary[key]] = row[key] || '';
      });

      toParse.push(toRet);
    });
    const newWorkbook = utils.book_new();
    const worksheetTrades = utils.json_to_sheet(toParse);
    utils.book_append_sheet(newWorkbook, worksheetTrades, 'Transactions');

    writeFile(
      newWorkbook,
      `[${portfolioInfo?.name}]_[${portfolioInfo?.clientName}]_Errored_Transactions_${moment().format(
        'DD-MMM-YYYY'
      )}.xlsx`
    );
  };

  const assets = Object.entries(assetInformation.current)
    .sort(([, a], [, b]) => a.ticker?.localeCompare(b.ticker))
    .map(([key, value]) => value);

  // const invalidTrades = React.useMemo(() => assets.some((item: IUploadTemplateResult) => !item.resolved), [assets]);

  const getSnapshotPath = () => {
    if (iframeMode) {
      return PATHS.portfolio.snapshot.edit.path + '?iframe-mode=true';
    }
    return PATHS.portfolio.snapshot.edit.path;
  };
  return (
    <div style={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <PortfolioHeader title={'Instrument Resolution'} />

      <div className={cn(s.settingsSurround)}>
        <UploadSettings
          baseCurrencySettlement={baseCurrencySettlement}
          changeSettlementCurrency={changeSettlementCurrency}
          custodian={custodian}
          localCurrencySettlement={localCurrencySettlement}
          portfolioInfo={portfolioInfo}
          setCustodian={setCustodian}
        />

        <CustodianResolution
          existingCustodians={existingCustodians}
          custodianMappings={custodianMappings}
          syntheticDividends={syntheticDividends}
          setSyntheticDividends={setSyntheticDividends}
          setCustodianMappings={setCustodianMappings}
          detectedCustodians={detectedCustodians}
          custodiansMappingError={custodiansMappingError}
        />
      </div>

      <div style={{ flex: 1 }}>
        <p>
          <strong>Note:&nbsp;</strong> all transactions can be updated in the next step
        </p>
        {assets.length === 0 && <strong>We were unable to extract any trades from your upload</strong>}
        {!!assets.length && (
          <TableWrapper
            columns={getColumns('TRIAGE')}
            totalCount={assets.length}
            cellRenderer={triageCellRendererCallback}
            rowHeight={30}
            headerHeight={24}
            tableData={assets}
          />
        )}
      </div>
      <div className={cn(s.buttonArea)}>
        <Button onClick={cancel}>Cancel</Button>
        {/*{invalidTrades && <span className={s.errorString}>Resolve all invalid trades</span>}*/}
        {!!assets.length && (
          <Button onClick={submit} disabled={uploadInProgress || !custodian}>
            Submit
          </Button>
        )}
      </div>

      {!!errorMessage && (
        <div style={{ zIndex: 999 }}>
          <Confirm
            title={'Upload Issues found'}
            confirmBtnText="OK"
            info={true}
            onCancel={() => {
              setErrorMessage('');
            }}
            onConfirm={() => {
              setErrorMessage('');
            }}
          >
            <div>
              <span>{errorMessage}</span>
            </div>
            <Button style={{ marginTop: 20, marginBottom: 20 }} onClick={downloadInvalidEntries}>
              Download invalid entries
            </Button>
          </Confirm>
        </div>
      )}

      {!!resultMessage && (
        <div style={{ zIndex: 999 }}>
          <Confirm
            title={resultMessage === SUBMITTING ? 'Submitting trades' : 'Trades saved'}
            confirmBtnText="OK"
            info={true}
            onConfirm={() => history.push(generatePath(getSnapshotPath(), { portfolioId }))}
          >
            <div>{resultMessage === SUBMITTING ? <Loader /> : resultMessage}</div>
          </Confirm>
        </div>
      )}
    </div>
  );
};

const checkResolved = (item: IUploadTemplateResult, resolvedAssets: IResolvedAsset[]) => {
  switch (item.assetSubClass) {
    case 'CshAdjustments':
      return true;
    default:
      return resolvedAssets.length === 1;
  }
};

const getDefaultCustodian = (custodians?: ICustodian[]): IOption<ICustodian> | null => {
  const defaultCustodian = custodians?.filter((c) => c.default)[0];
  if (!defaultCustodian) {
    return null;
  }
  return {
    id: defaultCustodian.id,
    name: defaultCustodian.name,
    value: defaultCustodian,
  };
};

const SUBMITTING = 'Submitting';

const getColumns = (mode: 'TRIAGE' | 'PREVIEW'): ITableColumn[] => [
  { dataKey: 'resolved', label: '', flexGrow: 2, width: 10, disableSort: true, display: mode === 'TRIAGE' },
  { dataKey: 'activityDate', label: 'Date', flexGrow: 10, width: 30, disableSort: true, display: mode === 'PREVIEW' },
  { dataKey: 'assetSubClass', label: 'Asset Class', flexGrow: 10, width: 40, disableSort: true, display: true },
  {
    dataKey: 'resolvedAssets',
    label: 'Instrument',
    flexGrow: 3,
    width: 50,
    disableSort: true,
    display: mode === 'PREVIEW',
  },
  { dataKey: 'buySell', label: 'Type', flexGrow: 10, width: 30, disableSort: true, display: mode === 'PREVIEW' },
  { dataKey: 'price', label: 'Price', flexGrow: 10, width: 30, disableSort: true, display: mode === 'PREVIEW' },
  { dataKey: 'label', label: 'Instrument', flexGrow: 10, width: 80, disableSort: true, display: true },
  { dataKey: 'currency', label: 'Currency', flexGrow: 3, width: 30, disableSort: true, display: true },
  {
    dataKey: 'settlementOption',
    label: mode === 'PREVIEW' ? 'Settlement' : 'Settlement Option for Blank Rows',
    flexGrow: 10,
    width: 80,
    disableSort: true,
    display: true,
  },
  { dataKey: 'exchange', label: 'Exchange', flexGrow: 5, width: 20, disableSort: true, display: true },
  {
    dataKey: 'resolvedAssets',
    label: 'Resolved Instrument',
    flexGrow: 30,
    width: 100,
    disableSort: true,
    display: mode === 'TRIAGE',
  },
  { dataKey: 'notes', label: 'Notes', flexGrow: 10, width: 200, disableSort: true, display: mode === 'PREVIEW' },
];

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  data: state.snapshot.uploadTemplateResult.data,
  unparsedData: state.snapshot.uploadTemplateResult.unparsedData,
  assetsClasses: state.assetsClasses.data,
  assetsSubClasses: state.assetsSubClasses.data,
  portfolioInfo: state.portfolio.portfolioInfo.data,
});

export default connect(mapStateToProps)(UniversalUploadTriage);
