import React, { CSSProperties } from 'react';
import { CSSTransition } from 'react-transition-group';

import { IOption } from '../../UIWidgets/Autocomplete';
import Input from '../Input/Input';
import s from '../../UIWidgets/DropDown/DropDown.module.scss';
import { Portal } from '../../Portal';
import fadeTransition from '../../../transitions/fade.module.css';
import { ClickOutside } from '../../ClickOutside';

interface IOwnProps<T> {
  items: Array<IOption<T>>;
  selectedItem?: IOption<T> | null;
  defaultText?: string;
  state?: string;
  searchTextColor?: string;
  onSelect(item: IOption<T> | null, index: number): void;
  onSearch(text?: string): void;
}

const SimpleAutocomplete = <T,>({
  onSelect,
  onSearch,
  defaultText = '',
  items,
  state = '',
  searchTextColor,
}: IOwnProps<T>): JSX.Element => {
  const [text, setText] = React.useState<string>(defaultText);
  const [showContent, setShowContent] = React.useState(false);
  const contentRef = React.useRef(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [dropStyle, setDropStyle] = React.useState<CSSProperties>({});
  // const inputRef = React.useRef<HTMLInputElement>(null)

  const onChangeText = (t?: string) => {
    setText(t ?? '');
    onSearch(t);
    if (t && !showContent) {
      show();
    } else if (!t) {
      setShowContent(false);
    }
  };

  const handleSelect = (newItem: IOption<T>, index: number) => {
    onSelect(newItem, index);
    setShowContent(false);
  };

  const show = () => {
    if (!wrapperRef.current) {
      return;
    }
    const wrapperCoords: ClientRect = wrapperRef.current.getBoundingClientRect();

    setDropStyle({
      top: wrapperCoords.bottom,
      left: wrapperCoords.left,
      width: wrapperCoords.width,
    });
    setShowContent(true);
  };

  return (
    <div style={{ width: '100%' }} ref={wrapperRef}>
      {/*<ClickOutside clickOutside={() => setShowContent(false)}>*/}
      <Input
        type={'text'}
        value={text}
        onChange={(e) => onChangeText(e.target.value)}
        onBlur={() => setShowContent(false)}
        onFocus={show}
        style={{ color: searchTextColor }}
        // ref={inputRef}
      />
      {/*</ClickOutside>*/}
      <CSSTransition in={showContent} classNames={fadeTransition} timeout={200} unmountOnExit={true}>
        <Portal target={undefined}>
          <div ref={contentRef} className={s.dropContent} style={dropStyle}>
            {state === 'LOADING' && 'Loading'}
            {state !== 'LOADING' &&
              items.map((item, index) => (
                <div onClick={() => handleSelect(item, index)} key={`${item}${index}`}>
                  {item.name}
                </div>
              ))}
            {state !== 'LOADING' && !items.length && 'No results found'}
          </div>
        </Portal>
      </CSSTransition>

      {/*{item.label}*/}
      {/*<Autocomplete*/}
      {/*  placeholder={"Not found - search to resolve"}*/}
      {/*  value={null}*/}
      {/*  onSelect={onChange}*/}
      {/*  options={[]}*/}
      {/*  // @ts-ignore*/}
      {/*  inputProps={{*/}
      {/*    value: text,*/}
      {/*    onChange: e => setText(e.target.value)*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  );
};

export default SimpleAutocomplete;
