import React, { FC, useRef } from 'react';
import s from './SvgScatterChart.module.scss';
import WhisperWrap from './utils/WhisperWrap';

interface IOwnProps {
  data: VolatilityScatterData;
  benchmarkSelected: boolean;
  portfolioSelected: boolean;
  width: number;
  portfolioBaseCcySymbol: string;
}

const Y_AXIS_WIDTH = 60;
const X_AXIS_HEIGHT = 20;
const EXCESS = 0.05;
const BENCHMARK_RHOMBUS_SIZE = 14;
const SQUARE_MARKER_SIZE = 14;
const AXIS_COLOR = '#94A7BE';
const Y_GRID_LINE_COLOR = '#E0E0E0';
const X_GRID_LINE_COLOR = '#E0E0E0';
const PORTFOLIO_RETURN_LINE_COLOR = '#a266cc';

type Coord = [number, number];

const getInterval = (range: number) => {
  const breakPoints = [100, 200, 500, 1000, 2000, 5000, 10000];
  if (range < 1) {
    return 0.1;
  }
  if (range < 2) {
    return 0.5;
  }
  if (range < 10) {
    return 1;
  }
  if (range < 50) {
    return 5;
  }
  let i = 0;
  let interval = 10;
  while (range > breakPoints[i] && i < breakPoints.length) {
    interval = breakPoints[i] / 10;
    i++;
  }
  return interval;
};

const SvgScatterChart: FC<IOwnProps> = ({
  portfolioBaseCcySymbol,
  width = 200,
  benchmarkSelected,
  data,
  portfolioSelected,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const height = width * 0.4;
  const returnBounds = scaleUp(data.returnBounds, EXCESS);
  const volatilityBounds = scaleUp(data.volatilityBounds, EXCESS);

  const yRange = returnBounds[1] - returnBounds[0];
  const xRange = volatilityBounds[1] - volatilityBounds[0];

  const getYPos = (val: number) => ((returnBounds[1] - val) * height) / yRange;
  const getXPos = (val: number) => ((val - volatilityBounds[0]) * width) / xRange;
  const getIntersectingLine = (pointA: Coord, pointB: Coord) => {
    const x1 = pointA[0];
    const y1 = pointA[1];

    const x2 = pointB[0];
    const y2 = pointB[1];
    const dy = y2 - y1;
    const dx = x2 - x1;
    const m = dy / dx;
    const b = -m * x1;

    return {
      x1: getXPos(volatilityBounds[0]),
      y1: getYPos(m * volatilityBounds[0] + b),
      x2: getXPos((returnBounds[1] - b) / m),
      y2: getYPos(returnBounds[1]),
    };
  };

  const yInterval = getInterval(yRange);
  const xInterval = getInterval(xRange);
  const yTicks = [];
  for (let i = Math.round(returnBounds[0] / yInterval) * yInterval; i <= returnBounds[1]; i += yInterval) {
    yTicks.push({ value: i === -0 ? Math.abs(i) : i, y: getYPos(i) });
  }

  const xTicks = [];
  for (let i = Math.round(volatilityBounds[0] / xInterval) * xInterval; i <= volatilityBounds[1]; i += xInterval) {
    xTicks.push({ value: i === -0 ? Math.abs(i) : i, x: getXPos(i) });
  }

  return (
    <div ref={containerRef} style={{ flex: 1, position: 'relative' }}>
      <span className={s.axisLabel}>Return</span>
      <div style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
        <div style={styles.yAxis} />
        <div
          style={{
            height,
            width,
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderColor: 'grey',
          }}
        >
          <svg {...{ height, width }} viewBox={`0 0 ${width} ${height}`}>
            {/*! Grid lines & ticks */}
            {yTicks.map(
              (tick) =>
                tick.value !== 0 && (
                  <React.Fragment key={tick.value}>
                    <line x1={0} x2={width} y1={tick.y} y2={tick.y} stroke={Y_GRID_LINE_COLOR} strokeWidth={0.1} />
                  </React.Fragment>
                )
            )}
            {xTicks.map((tick) => (
              <React.Fragment key={tick.value}>
                <line x1={tick.x} x2={tick.x} y1={height} y2={height - 4} stroke={X_GRID_LINE_COLOR} strokeWidth={1} />
              </React.Fragment>
            ))}

            {/*! x-axis */}
            <line x1={0} x2={width} y1={getYPos(0)} y2={getYPos(0)} stroke={AXIS_COLOR} strokeWidth={2} />

            {/*! y-axis */}
            <line x1={getXPos(0)} x2={getXPos(0)} y1={height} y2={0} stroke={AXIS_COLOR} strokeWidth={2} />

            {/*! Portfolio return line */}
            <line
              {...getIntersectingLine([0, 0], [data.portfolio.volatility, data.portfolio.return])}
              stroke={PORTFOLIO_RETURN_LINE_COLOR}
              strokeWidth={1}
            />

            {/*! Portfolio */}
            {portfolioSelected && (
              <WhisperWrap currency={portfolioBaseCcySymbol} point={data.portfolio} containerRef={containerRef}>
                <circle
                  className={s.scatterPoint}
                  cx={getXPos(data.portfolio.volatility)}
                  cy={getYPos(data.portfolio.return)}
                  fill={data.portfolio.color}
                  r={10}
                />
              </WhisperWrap>
            )}

            {/*! Benchmark */}
            {benchmarkSelected && (
              <WhisperWrap currency={portfolioBaseCcySymbol} point={data.benchmark} containerRef={containerRef}>
                <rect
                  className={s.scatterPoint}
                  x={getXPos(data.benchmark.volatility) - BENCHMARK_RHOMBUS_SIZE / 2}
                  y={getYPos(data.benchmark.return) - BENCHMARK_RHOMBUS_SIZE / 2}
                  fill={data.benchmark.color}
                  height={BENCHMARK_RHOMBUS_SIZE}
                  width={BENCHMARK_RHOMBUS_SIZE}
                  style={{
                    transform: 'rotate(45deg)',
                    transformBox: 'fill-box',
                  }}
                />
              </WhisperWrap>
            )}

            {/*! Asset classes */}
            {data.assetClasses.map((asset) => (
              <WhisperWrap
                currency={portfolioBaseCcySymbol}
                key={`chart-${asset.key}`}
                point={asset}
                containerRef={containerRef}
              >
                <circle
                  className={s.scatterPoint}
                  key={asset.key}
                  cx={getXPos(asset.volatility)}
                  cy={getYPos(asset.return)}
                  fill={asset.color}
                  r={5}
                />
              </WhisperWrap>
            ))}

            {data.topPositions
              .filter((asset) => typeof asset.volatility === 'number')
              .map((asset) => (
                <WhisperWrap
                  currency={portfolioBaseCcySymbol}
                  key={`chart-${asset.key}`}
                  point={asset}
                  containerRef={containerRef}
                >
                  <g className={s.scatterPoint}>
                    <rect
                      x={getXPos(asset.volatility) - SQUARE_MARKER_SIZE / 2}
                      y={getYPos(asset.return) - SQUARE_MARKER_SIZE / 2}
                      fill={asset.activeFor && asset.activeFor > 12 ? asset.color : '#1C202F'}
                      stroke={asset.activeFor && asset.activeFor < 12 ? asset.color : 'transparent'}
                      strokeWidth={2.2}
                      height={SQUARE_MARKER_SIZE}
                      width={SQUARE_MARKER_SIZE}
                    />
                    <text
                      style={{
                        fontSize: 10,
                        width: SQUARE_MARKER_SIZE,
                      }}
                      x={
                        getXPos(asset.volatility) -
                        SQUARE_MARKER_SIZE / 2 +
                        (parseInt(asset.key, undefined) > 9 ? 1.5 : 4)
                      }
                      y={getYPos(asset.return) - SQUARE_MARKER_SIZE / 2 + 10}
                      fill={asset.activeFor && asset.activeFor > 12 ? 'white' : asset.color}
                    >
                      {asset.index}
                    </text>
                  </g>
                </WhisperWrap>
              ))}
          </svg>

          {/* !Axis labels */}
          {yTicks.map((tick) =>
            tick.value >= returnBounds[0] ? (
              <span key={tick.value} className={s.yTick} style={{ top: tick.y + 20 }}>
                {tick.value.toLocaleString(undefined, { maximumFractionDigits: 1 })}%
              </span>
            ) : null
          )}
          {xTicks.map((tick) =>
            tick.value >= volatilityBounds[0] ? (
              <span key={tick.value} className={s.yTick} style={{ left: tick.x - 5, top: height + 30 }}>
                {tick.value.toLocaleString(undefined, { maximumFractionDigits: 1 })}%
              </span>
            ) : null
          )}

          {/*{*/}
          {/*  data.assetClasses.map(asset => (*/}
          {/*    <BrandText*/}
          {/*      key={asset.key}*/}
          {/*      style={{*/}
          {/*        position: "absolute",*/}
          {/*        left: getXPos(asset.volatility) + 15,*/}
          {/*        top: getYPos(asset.return) - 10*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {asset.label}{`: [${asset.volatility.toFixed(1)}, ${asset.return.toFixed(1)}]`}*/}
          {/*    </BrandText>*/}
          {/*  ))*/}
          {/*}*/}
        </div>
      </div>
      <div style={styles.xAxis} />
    </div>
  );
};

const scaleUp = (bounds: [number, number], amount: number) => {
  const range = bounds[1] - bounds[0];
  return [bounds[0] - range * amount, bounds[1] + range * amount];
};

const styles = {
  xAxis: { height: X_AXIS_HEIGHT },
  yAxis: { width: Y_AXIS_WIDTH },
};

export default SvgScatterChart;
