import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';

import { TABLE_PAGE_SIZE } from '../constants/constants';

import { IPaginationContent } from '../../models/redux/ILoadingPaginationDataState';

import { IActionPayload } from '../../models/redux/IActionPayload';

export class IncomeActions {
  static readonly INCOMES_REQUEST = 'INCOMES_REQUEST';
  static readonly INCOMES_SUCCESS = 'INCOMES_SUCCESS';
  static readonly INCOMES_ERROR = 'INCOMES_ERROR';
  static readonly INCOMES_RESET = 'INCOMES_RESET';
  static readonly INCOMES_CHANGE_SORT = 'INCOMES_CHANGE_SORT';
  static readonly INCOMES_CHANGE_PERIOD = 'INCOMES_CHANGE_PERIOD';

  static readonly INCOME_CREATE_REQUEST = 'INCOME_CREATE_REQUEST';
  static readonly INCOME_CREATE_SUCCESS = 'INCOME_CREATE_SUCCESS';
  static readonly INCOME_CREATE_ERROR = 'INCOME_CREATE_ERROR';

  static readonly INCOME_UPDATE_REQUEST = 'INCOME_UPDATE_REQUEST';
  static readonly INCOME_UPDATE_SUCCESS = 'INCOME_UPDATE_SUCCESS';
  static readonly INCOME_UPDATE_ERROR = 'INCOME_UPDATE_ERROR';

  static readonly INCOME_DELETE_REQUEST = 'INCOME_DELETE_REQUEST';
  static readonly INCOME_DELETE_SUCCESS = 'INCOME_DELETE_SUCCESS';
  static readonly INCOME_DELETE_ERROR = 'INCOME_DELETE_ERROR';
  static readonly INCOME_PRICES_REQUEST = 'INCOME_PRICES_REQUEST';

  static fetchAllIncomes(instrumentId: string, order: IOrder, period: IPeriod, page: number = 0) {
    return ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IIncome>>(
      {
        url: getApiUrl('income.list'),
        data: {
          instrumentId,
          page,
          size: TABLE_PAGE_SIZE,
          order,
          from: period.from,
          to: period.to,
        },
      },
      IncomeActions.INCOMES_REQUEST,
      IncomeActions.INCOMES_SUCCESS,
      IncomeActions.INCOMES_ERROR
    );
  }

  static changeIncomeSort(order: IOrder): IActionPayload<IOrder> {
    return { type: IncomeActions.INCOMES_CHANGE_SORT, payload: order };
  }

  static changeIncomePeriod(period: IPeriod): IActionPayload<IPeriod> {
    return { type: IncomeActions.INCOMES_CHANGE_PERIOD, payload: period };
  }

  static createIncome(income: IIncome) {
    return ReduxHelper.createHttpRequestActionBundle<IIncome>(
      {
        url: getApiUrl('income.create'),
        data: {
          ...income,
        },
      },
      IncomeActions.INCOME_CREATE_REQUEST,
      IncomeActions.INCOME_CREATE_SUCCESS,
      IncomeActions.INCOME_CREATE_ERROR
    );
  }

  static updateIncome(income: IIncome) {
    return ReduxHelper.createHttpRequestActionBundle<IIncome>(
      {
        url: getApiUrl('income.update'),
        data: {
          ...income,
        },
      },
      IncomeActions.INCOME_UPDATE_REQUEST,
      IncomeActions.INCOME_UPDATE_SUCCESS,
      IncomeActions.INCOME_UPDATE_ERROR
    );
  }

  static deleteIncome(income: IIncome) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('income.delete'),
        data: {
          ids: [income.id],
        },
      },
      IncomeActions.INCOME_DELETE_REQUEST,
      IncomeActions.INCOME_DELETE_SUCCESS,
      IncomeActions.INCOME_DELETE_ERROR
    );
  }

  static resetIncomeList(): IActionPayload {
    return { type: IncomeActions.INCOMES_RESET };
  }
}

export default IncomeActions;
