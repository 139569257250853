import echarts from 'echarts/lib/echarts';
import moment from 'moment';
import merge from 'lodash.merge';

import { ChartHelper } from '../../../../../../../../../../services/utils/ChartHelper';

import { FormatHelper, VALUE_TYPE } from '../../../../../../../../../../services/utils/FormatHelper';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';

export function getRepaymentsChartOptions(
  chartData: IBarData,
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  const chartOpt = ChartHelper.getChartOptions({
    tooltipValueType: VALUE_TYPE.MONEY,
    portfolioCurrencyFormatter,
  });
  const seriesOpt = ChartHelper.getBarSeriesOptions();
  const mergeOpt: echarts.EChartOption = {
    xAxis: {
      axisLabel: {
        interval: 0,
        formatter(value: string): string {
          if (value.toLowerCase() !== 'perpetual' && moment(value).isValid()) {
            return moment(value).format('YYYY');
          }

          return value;
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return portfolioCurrencyFormatter(value);
        },
      },
    },
    series: [
      {
        ...seriesOpt,
        data: chartData.data,
      },
    ],
  };

  return merge({}, chartOpt, mergeOpt);
}
