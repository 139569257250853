import React, { Fragment, PureComponent } from 'react';
import Slider, { HandleProps, SliderProps } from 'rc-slider';

import s from './SlidersBlock.module.scss';
import cn from 'classnames';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../components/ContextHelp';
import { SCENARIO_CONTAINER_HELP_TEXT } from '../../../../../services/constants/tooltipContextHelp';
import { Card } from '@iliotech/storybook';
type IProps = any;

interface IState {
  equitySldVal: number;
  fxSldVal: number;
  yieldSldVal: number;
  creditSpreadSldVal: number;
  interestRateSldVal: number;
  volatilitySldVal: number;
}

class SlidersBlock extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      equitySldVal: 0,
      fxSldVal: 0,
      yieldSldVal: 0,
      creditSpreadSldVal: 0,
      interestRateSldVal: 0,
      volatilitySldVal: 0,
    };
  }

  updateEquityVal = (value: number) => {
    this.setState({ equitySldVal: value });
  };

  equityValUpdated = (value: number) => {
    this.props.onChange(value, 1);
  };

  updateFxVal = (value: number) => {
    this.setState({ fxSldVal: value });
  };

  fxValUpdated = (value: number) => {
    this.props.onChange(value, 2);
  };

  updateYieldVal = (value: number) => {
    this.setState({ yieldSldVal: value });
  };

  yieldValUpdated = (value: number) => {
    this.props.onChange(value, 3);
  };

  updateVolatilityVal = (value: number) => {
    this.setState({ volatilitySldVal: value });
  };

  volatilityValUpdated = (value: number) => {
    this.props.onChange(value, 5);
  };

  render() {
    const equityVal = this.state.equitySldVal;
    const fxVal = this.state.fxSldVal;
    const yieldVal = this.state.yieldSldVal;
    const baseCurrencyName = this.props.currency;
    const interestRatesVal = 0;
    const volatilityVal = this.state.volatilitySldVal;

    const contextHelpEquity = SCENARIO_CONTAINER_HELP_TEXT.SliderEquityPrice;
    const contextHelpFx = SCENARIO_CONTAINER_HELP_TEXT.SliderFx;
    const contextHelpYield = SCENARIO_CONTAINER_HELP_TEXT.SliderYield;
    const contextHelpInterest = SCENARIO_CONTAINER_HELP_TEXT.SliderInterest;
    const contextHelpVolatility = SCENARIO_CONTAINER_HELP_TEXT.SliderVolatility;

    return (
      <div className={cn(s.scenariosItems)}>
        <div className={s.scenariosItem}>
          <div className={s.sliderBlock}>
            <div className={s.sliderBlockHeader}>
              Move in {this.props.benchmarkName} (%)
              <ContextHelpIcon title={'Benchmark Move'} elementId={'Equity Prices'} />
            </div>
            <div className={s.sliderBlockContent}>
              <div className={s.sliderBlockSlider}>
                <Slider
                  disabled={false}
                  defaultValue={0}
                  min={-20}
                  max={20}
                  marks={{ '-20': '-20', '-10': '-10', '0': '0', '10': '10', '20': '20' }}
                  step={1}
                  onChange={this.updateEquityVal}
                  onAfterChange={this.equityValUpdated}
                />
              </div>
              <div className={s.sliderBlockSliderValue}>{equityVal}%</div>
              <ContextHelpTooltip
                elementId={'Equity Prices'}
                tooltipTitle={'Benchmark Move'}
                tooltipText={contextHelpEquity}
              />
            </div>
          </div>
        </div>

        <div className={s.scenariosItem}>
          <div className={s.sliderBlock}>
            <div className={s.sliderBlockHeader}>
              Volatility (%)
              <ContextHelpIcon title={'Volatility'} elementId={'Volatility'} />
            </div>
            <div className={s.sliderBlockContent}>
              <div className={s.sliderBlockSlider}>
                <Slider
                  className={s.sliderDisabled}
                  disabled={!this.props.affectedByVolatilityChange}
                  defaultValue={0}
                  min={-50}
                  max={50}
                  marks={{ '-50': '-50', '-25': '-25', '0': '0', '25': '25', '50': '50' }}
                  step={1}
                  onChange={this.updateVolatilityVal}
                  onAfterChange={this.volatilityValUpdated}
                />
              </div>
              <div className={s.sliderBlockSliderValue}>{volatilityVal}%</div>
              <ContextHelpTooltip
                elementId={'Volatility'}
                tooltipTitle={'Volatility'}
                tooltipText={contextHelpVolatility}
              />
            </div>
          </div>
        </div>

        <div className={s.scenariosItem}>
          <div className={s.sliderBlock}>
            <div className={s.sliderBlockHeader}>
              Yield (%)
              <ContextHelpIcon title={'Yield'} elementId={'Yield'} />
            </div>
            <div className={s.sliderBlockContent}>
              <div className={s.sliderBlockSlider}>
                <Slider
                  className={s.sliderDisabled}
                  disabled={!this.props.affectedByYieldChange}
                  defaultValue={0}
                  min={-50}
                  max={50}
                  marks={{ '-50': '-50', '-25': '-25', '0': '0', '25': '25', '50': '50' }}
                  step={1}
                  onChange={this.updateYieldVal}
                  onAfterChange={this.yieldValUpdated}
                />
              </div>
              <div className={s.sliderBlockSliderValue}>{yieldVal}%</div>
              <ContextHelpTooltip elementId={'Yield'} tooltipTitle={'Yield'} tooltipText={contextHelpYield} />
            </div>
          </div>
        </div>

        <div className={s.scenariosItem}>
          <div className={s.sliderBlock}>
            <div className={s.sliderBlockHeader}>
              FX vs {baseCurrencyName} (%)
              <ContextHelpIcon title={'Fx Vs Base Currency'} elementId={'Fx Vs Base Currency'} />
            </div>
            <div className={s.sliderBlockContent}>
              <div className={s.sliderBlockSlider}>
                <Slider
                  disabled={false}
                  defaultValue={0}
                  min={-10}
                  max={10}
                  marks={{ '-10': '-10', '-5': '-5', '0': '0', '5': '5', '10': '10' }}
                  step={1}
                  onChange={this.updateFxVal}
                  onAfterChange={this.fxValUpdated}
                />
              </div>
              <div className={s.sliderBlockSliderValue}>{fxVal}%</div>
              <ContextHelpTooltip
                elementId={'Fx Vs Base Currency'}
                tooltipTitle={'Fx Vs Base Currency'}
                tooltipText={contextHelpFx}
              />
            </div>
          </div>
        </div>

        {/*
        DON'T REMOVE THE BELOW! TURNED OFF TEMPORARILY BY BUSINESS.
*/}

        {/*        <div className={s.scenariosItem}>
          <div className={s.sliderBlock}>
            <div className={s.sliderBlockHeader}>
              Interest Rates (bps)
              <ContextHelpIcon title={'Interest Rates'} elementId={'Interest Rates'} />
            </div>
            <div className={s.sliderBlockContent}>
              <div className={s.sliderBlockSlider}>
                <Slider
                  className={s.sliderDisabled}
                  disabled={true}
                  defaultValue={0}
                  min={-100}
                  max={100}
                  marks={{ '-100': '-100', '-50': '-50', '0': '0', '50': '50', '100': '100' }}
                  step={10}
                />
              </div>
              <div className={s.sliderBlockSliderValue}>{interestRatesVal}bps</div>
              <ContextHelpTooltip
                elementId={'Interest Rates'}
                tooltipTitle={'Interest Rates'}
                tooltipText={contextHelpInterest}
              />
            </div>
          </div>
        </div>*/}
      </div>
    );
  }
}

export default SlidersBlock;
