import { useTypedSelector } from './useTypedSelector';

export const useCurrencies = () => {
  const { currencies } = useTypedSelector((state) => ({
    currencies: state.currency.data,
  }));

  return {
    currencies,
  };
};
