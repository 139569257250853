import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';

import { ChartHelper } from '../../../../../../../../../../services/utils/ChartHelper';
import { VALUE_TYPE } from '../../../../../../../../../../services/utils/FormatHelper';

import { LineData } from '../../../../../../../../../../models/LineData';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';

export function getMaturityChartOptions(
  data: LineData,
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  const chartOpt = ChartHelper.getTimelineChartOptions({
    tooltipValueType: VALUE_TYPE.PERCENTAGE,
    zoom: true,
    portfolioCurrencyFormatter,
  });

  const lineOpt = ChartHelper.getLineSeriesOptionsWithRadialGradient();
  const mergeOpt: echarts.EChartOption = {
    yAxis: {
      axisLabel: {
        formatter(value: string): string {
          return `${value}%`;
        },
      },
    },
    series: [
      {
        ...lineOpt,
        lineStyle: {
          ...lineOpt.lineStyle,
          width: 1,
        },
        showSymbol: true,
        data,
      },
    ],
  };

  return merge({}, chartOpt, mergeOpt);
}
