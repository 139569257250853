import React, { FunctionComponent } from 'react';
import { Autocomplete, FormFieldWrapper } from '../../../../../../../components';
import cn from 'classnames';

import s from '../Transaction.module.scss';
import { IOption } from '../../../../../../../components/UIWidgets/Autocomplete';
import { TRADE_TYPES } from '../../../../../../../services/constants/constants';

interface IProps {
  tradeType: IOption<TradeType> | null;
  onSelectOption(type: IOption<TradeType>): void;
  capabilities: ICapability[];
}

const TradeTypeSelector: FunctionComponent<IProps> = (props) => {
  const { tradeType, onSelectOption, capabilities } = props;

  const capabilityNames = capabilities.map((capability) => capability.key);
  const options = TRADE_TYPES.filter(
    (option) => capabilityNames.indexOf(option.capability) > -1 && option.defaultAssetSubclass !== 'AdjFXSpot'
  );

  React.useEffect(() => {
    return () => {
      onSelectOption(TRADE_TYPES[0]); // Reset so that Options aren't displayed as default next time a portfolio is opened
    };
  }, []);

  return (
    <div style={{ width: 170 }}>
      <FormFieldWrapper label="Instrument Type" required={true} className={cn(s.typeField)}>
        <Autocomplete
          placeholder={'Select'}
          haveError={false}
          haveWarn={false}
          value={tradeType ?? null}
          onSelectOption={(type) => {
            onSelectOption(type as IOption<TradeType>);
          }}
          onSelect={(type) => onSelectOption(type as IOption<TradeType>)}
          options={options}
          disableReset={true}
          inputDisabled={true}
        />
      </FormFieldWrapper>
    </div>
  );
};

export default TradeTypeSelector;
