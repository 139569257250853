import ReactEcharts from 'echarts-for-react';
import React, { Fragment, PureComponent } from 'react';
import { ChartLegend } from '../../../../../../../../../../../../components/ChartLegend';
import {
  ILegendItemProps,
  LegendType,
} from '../../../../../../../../../../../../components/ChartLegend/components/LegendItem';
import { ICurrencyFormatter } from '../../../../../../../../../../../../services/selectors/portfolio';
import { generateChartOptions } from './StockVsBuysChartConfig';

interface IOwnProps {
  instrumentName: string;
  instrumentCurrency: ICurrency;
  baseCurrency: ICurrency;
  stockPricesData: IStockPrices[];
  income: IDividends[];
  earnEventsData: IEarnDataInfo[];
  splitsData: ISplits[];
  buysAndSellsData: ITradesInfo[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  assetType: string;
}

type IProps = IOwnProps;

interface IState {
  chartWidth: number;
}

class StockVsBuysChart extends PureComponent<IProps, IState> {
  readonly state: IState = {
    chartWidth: 0,
  };

  private _echartsReact: React.RefObject<ReactEcharts> = React.createRef();

  constructor(props: IProps) {
    super(props);
    // this.handleZoomChange = debounce(this.handleZoomChange, 200);
  }

  render() {
    const chartOpt = generateChartOptions(
      this.props.stockPricesData,
      this.props.income,
      this.props.splitsData,
      this.props.buysAndSellsData,
      this.props.baseCurrency,
      this.props.instrumentCurrency,
      this.props.instrumentName,
      this.props.assetType,
      this.props.portfolioCurrencyFormatter,
      this.props.earnEventsData,
    );
    const legendOpt: ILegendItemProps[] = [
      { name: 'Local price', color: '#6677cc' },
      // { name: 'FX Adjusted Price', color: '#c2b261' },
    ];

    return (
      <Fragment>
        <ChartLegend legend={legendOpt} type={LegendType.LINE} />
        <ReactEcharts
          style={{ flex: 1, height: '420px', width: '100%', maxHeight: '400px' }}
          option={chartOpt}
          notMerge={true}
        />
      </Fragment>
    );
  }
}

export default StockVsBuysChart;
