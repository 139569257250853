import React, { useEffect, useState } from 'react';
import { TopListByPnLWidgetsContainer, Widget } from '../../../../../../../components';
import s from '../shared.module.scss';
import { Card } from '@iliotech/storybook';
import { DASHBOARD_FUND_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import PerformanceChartWidget from '../../../../../../../components/PerformanceChartWidget/PerformanceChartWidget';
import { ALLOCATION_TYPES } from '../../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import { PeriodHelper } from '../../../../../../../../../services/utils/PeriodHelper';
import useApi from '../../../../../../../../../services/hooks/useApi';
import { Loader } from '../../../../../../../../../components';
import { ITableColumn } from '../../../../../../../../../components/UIWidgets/TableWrapper/models';
import StockVsBuysChartContainer from '../../Equilty/scenes/PnlBreakdownDetail/components/StockVsBuysChart/StockVsBuysChartContainer';
import CumulativePnLChartContainer from '../../Equilty/scenes/PnlBreakdownDetail/components/CumulativePnL/CumulativePnLChartContainer';
import { getCustomApiUrl } from '../../../../../../../../../services/constants/endpoints';
import { PnlByAttributionChartContainer } from '../../../../PortfolioDashboard/components/PnlByAttributionChartContainer';

interface IProps {
  period: IPeriod;
  handlePeriodChange: (period: IPeriod) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioId: string;
  getTopPositionColumns: (f: ICurrencyFormatter) => ITableColumn[];
  cellRenderer: (data: any, f: ICurrencyFormatter) => React.ReactNode;
  assetClass: string;
}

const GlobalPnl = ({
  portfolioId,
  handlePeriodChange,
  period,
  portfolioCurrencyFormatter,
  getTopPositionColumns,
  cellRenderer,
  assetClass,
}: IProps) => {
  const [positionId, setPositionId] = useState('');
  const prepPeriod = PeriodHelper.preparePeriodForRequest(period);
  const cumulativePerformanceByAsset = useApi('/api/v1/twr/pnl/' + portfolioId, {
    method: 'GET',
    params: {
      assetClass,
      fromDate: prepPeriod.from || '',
      toDate: prepPeriod.to || '',
    },
  });

  const breakdownPositionReport = useApi(
    getCustomApiUrl('portfolio.profit.breakdownPosition', [
      { idLabel: ':portfolioId', idValue: portfolioId },
      { idLabel: ':positionId', idValue: positionId },
    ]),
    { method: 'get', responseType: 'json' },
    { enabled: false }
  );

  const type: ALLOCATION_TYPES = ALLOCATION_TYPES.AssetClass;

  useEffect(() => {
    cumulativePerformanceByAsset.refetch();
  }, [period]);

  useEffect(() => {
    if (positionId.length > 0) {
      breakdownPositionReport.refetch();
    }
  }, [positionId]);

  if (cumulativePerformanceByAsset.isLoading) {
    return <Loader />;
  }

  return (
    <Widget>
      <div className={s.defaultContainer}>
        <Card style={{ flex: 1, marginRight: 10 }}>
          <Widget colNum={4} title={'Cumulative P&L'} titleInfo={DASHBOARD_FUND_HELP_TEXT.ChartPnlPerformance}>
            <PerformanceChartWidget
              selectedPeriod={period}
              isDataLoaded={true}
              portfolioPerformance={cumulativePerformanceByAsset.data}
              indexPerformance={[]}
              indexName={''}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              hidePeriodSelector={true}
              performanceToggle={1}
              useWidgetTitle={true}
            />
          </Widget>
        </Card>
        <Card style={{ flex: 1, marginLeft: 10 }}>
          <Widget title={'Monthly P&L by attribution'} titleInfo={DASHBOARD_FUND_HELP_TEXT.ChartPnlPnl}>
            <PnlByAttributionChartContainer
              period={period}
              portfolioId={portfolioId}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              hidePeriodSelector={true}
              assetClassId={assetClass}
            />
          </Widget>
        </Card>
      </div>
      <div style={{ marginTop: 30 }}>
        <Widget title="All Positions" subtitle="(select to see detail)">
          <TopListByPnLWidgetsContainer
            filter={{ type, id: assetClass }}
            portfolioId={portfolioId}
            showCategories={true}
            onRowClick={({ rowData, index }: { rowData: any; index: number }) => setPositionId(rowData.positionId)}
            columns={getTopPositionColumns(portfolioCurrencyFormatter)}
            cellRenderer={(data) => cellRenderer(data, portfolioCurrencyFormatter)}
          />
          {breakdownPositionReport.isFetched && (
            <div style={{ marginTop: 30 }}>
              <Widget>
                <div className={s.defaultContainer}>
                  <Widget colNum={4}>
                    <Card style={{ flex: 1, marginRight: 10 }}>
                      <StockVsBuysChartContainer
                        stockPricesData={breakdownPositionReport.data.stockPrices}
                        income={breakdownPositionReport.data.income}
                        splitsData={breakdownPositionReport.data.splits}
                        earnEventsData={breakdownPositionReport.data.earns}
                        buysAndSellsData={breakdownPositionReport.data.buysAndSells}
                        baseCurrency={breakdownPositionReport.data.currency}
                        instrumentCurrency={breakdownPositionReport.data.portfolioCurrency}
                        instrumentName={breakdownPositionReport.data.instrumentName}
                        assetClassId={assetClass}
                      />
                    </Card>
                  </Widget>
                  <Widget colNum={4}>
                    <Card style={{ flex: 1, marginLeft: 10 }}>
                      <CumulativePnLChartContainer
                        cumulativePnl={breakdownPositionReport.data.cumulativePnl}
                        baseCurrency={breakdownPositionReport.data.currency.name}
                        baseCurrencySymbol={breakdownPositionReport.data.currency.symbol}
                        instrumentName={breakdownPositionReport.data.instrumentName}
                        assetClassId={assetClass}
                      />
                    </Card>
                  </Widget>
                </div>
              </Widget>
            </div>
          )}
        </Widget>
      </div>
    </Widget>
  );
};

export default GlobalPnl;
