import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';

import { IScenarioItem } from '../interfaces';

import { generateChartOptions } from './BarChartConfig';

interface IProps {
  baseValue: number;
  bar1Correction: number;
  bar2Correction: number;
  bar3Correction: number;
  bar4Correction: number;
  bar5Correction: number;
  barData: IScenarioItem[];
  baseCurrencyName: string;
  baseCurrencySymbol: string;
  isDataFromBackend: boolean;
}

class BarChart extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const {
      baseValue,
      bar1Correction,
      bar2Correction,
      bar3Correction,
      bar4Correction,
      bar5Correction,
      barData,
      baseCurrencyName,
      baseCurrencySymbol,
      isDataFromBackend,
    } = this.props;

    const chartData: IScenarioItem[] = barData.map((bar) => {
      return {
        ...bar,
      };
    });

    // ILLDV001-2675 - Applying a local formula when Portfolio doesn't contain Options otherwise apply straight backend data

    chartData.forEach((element) => {
      element.fxMultiplier = element.fxValue === undefined ? bar2Correction * element.fxMultiplier : element.fxValue;

      element.yieldMultiplier =
        element.yieldValue === undefined ? bar3Correction * element.yieldMultiplier : element.yieldValue;
      element.volatilityMultiplier =
        element.volatilityValue === undefined ? bar5Correction * element.volatilityMultiplier : element.volatilityValue;
      element.priceMultiplier1 =
        element.priceValue === undefined
          ? bar1Correction * element.priceMultiplier1 +
            bar1Correction * Math.abs(bar1Correction) * element.priceMultiplier2
          : element.priceValue + element.yieldMultiplier + element.volatilityMultiplier; // New requirement to sum Yield and Volatility to Price and not show these series: JIRA-2809
    });

    const sliders = {
      priceSlider: bar1Correction,
      volatilitySlider: bar5Correction,
      yieldSlider: bar3Correction,
      fxSlider: bar2Correction,
    }

    return (
      <ReactEcharts
        style={{ height: '420px', width: '100%' }}
        option={generateChartOptions(chartData, baseCurrencyName, baseCurrencySymbol, sliders)}
        notMerge={true}
      />
    );
  }
}

export default BarChart;
