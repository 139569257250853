import mixpanel, { Dict } from 'mixpanel-browser';

type AnalyticsEventName = 'Save Trades';

const Analytics = {
  track: (eventName: AnalyticsEventName, properties?: Dict) => {
    mixpanel.track(eventName, properties);
  },
  trackPageView: (pageName: string) => {
    mixpanel.track('Page View', { pageName });
  },
};

export default Analytics;
