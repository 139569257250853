import { FormatHelper } from '../../../../../../../../../services/utils/FormatHelper';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import { defaultTableCellRenderer, TableCellProps } from 'react-virtualized';
import React from 'react';

function formatNumber(num: number, decimal = 2) {
  if (num) {
    return num
      .toFixed(decimal)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return num;
}

function _getCellValue(cellName: string, cellValue: string | number, calculated: boolean): string | number {
  switch (cellName) {
    case 'portfolioPercentage':
      return cellValue ? FormatHelper.formatPercentage(Number(cellValue), 100) : 0;
    default:
      return cellValue;
  }
}

export const realEstateCellRenderer = (
  data: TableCellProps,
  portfolioCurrencyFormatter: ICurrencyFormatter
): React.ReactNode => {
  if (data.dataKey === 'marketValueLocal') {
    return (data.cellData = `${formatNumber(data.cellData, 0)} ${data?.rowData.currency.name} `);
  }

  if (data.dataKey === 'marketValueBase') {
    return (data.cellData = `${portfolioCurrencyFormatter()} ${formatNumber(data.cellData, 0)}`);
  }

  if (data.dataKey === 'pnlBase') {
    return (data.cellData = `${portfolioCurrencyFormatter()} ${formatNumber(data.cellData, 0)}`);
  }

  if (data.dataKey === 'pnlMarketBase') {
    return (data.cellData = `${portfolioCurrencyFormatter()} ${formatNumber(data.cellData, 0)}`);
  }

  if (data.dataKey === 'portfolioPercentage') {
    return (data.cellData = `${data.cellData ? FormatHelper.formatPercentage(Number(data.cellData), 100) : 0}`);
  }

  data.cellData = _getCellValue(data.dataKey, data.cellData, data.rowData.calculated);
  return defaultTableCellRenderer(data);
};
