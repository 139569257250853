import React, { PureComponent, Fragment } from 'react';
import ReactEcharts from 'echarts-for-react';

import { ISimulatedReturnData } from '../../interfaces';
import { generateChartOptions } from './SimulatedReturnChartConfig';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';

interface IOwnProps {
  data: ISimulatedReturnData;
  chartTitle: string;
  chartPeriodInfo?: string;
  helpText?: string;
}

type IProps = IOwnProps;

class SimulatedReturnChart extends PureComponent<IProps> {
  render() {
    const periodInfo = this.props.chartPeriodInfo;
    const title = this.props.chartTitle;
    const chartOpt = generateChartOptions(this.props.data);
    const contextHelpText = this.props.helpText;

    return (
      <Fragment>
        <h3>
          {title}
          <ContextHelpIcon title={title} elementId={title} />
        </h3>
        <h4>{periodInfo}</h4>
        <div>
          <ReactEcharts
            option={chartOpt}
            style={{ width: '100%', padding: '0', margin: '0', flex: '1', height: '400px' }}
          />
        </div>
        <ContextHelpTooltip elementId={title} tooltipTitle={title} tooltipText={contextHelpText} />
      </Fragment>
    );
  }
}

export default SimulatedReturnChart;
