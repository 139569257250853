import React, { FunctionComponent, useState } from 'react';
import { Route, useRouteMatch, useHistory, Switch, Link } from 'react-router-dom';
import { createSelector } from 'reselect';

import UnlistedTableContainer from './UnlistedTable/UnlistedTableContainer';

import realAssetsIcon from '../../static/images/icons/real-assets.svg';
import realEstateIcon from '../../static/images/icons/real-estate.svg';
import { PageHeader } from '../../components';
import { useSelector } from 'react-redux';
import { IRootState } from '../../services/store';
import { PortfolioHeader } from '../Portfolio/components';

type ViewMode = 'add' | 'edit' | 'list';

const routes: Array<{ assetClassId: AssetClassId; icon: string; assetClassName: string }> = [
  { assetClassId: 'RealAssets', icon: realAssetsIcon, assetClassName: 'Real Assets' },
  { assetClassId: 'RealEstate', icon: realEstateIcon, assetClassName: 'Real Estate' },
];

const getMode = (pathname: string) => {
  const params = pathname.split('/');
  return (params[3] ?? 'list') as ViewMode;
};

const selector = (assetClassId: AssetClassId) =>
  createSelector(
    (state: IRootState) => ({
      portfolioInfo: state.portfolio.portfolioInfo?.data,
      assetSubClassComboItems: state.assetsSubClasses.data
        .filter((item) => item.assetClassId === assetClassId)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((subClass) => ({ value: subClass.id, label: subClass.name })),
    }),
    (s) => s
  );
const UnlistedContainer: FunctionComponent = (props) => {
  const { params } = useRouteMatch<{ assetClassId: AssetClassId }>();
  const [workgroupId, setWorkgroupId] = React.useState<number>();
  const assetClassId = params.assetClassId;
  const assetClassName = assetClassId === 'RealEstate' ? 'Real Estate' : 'Real Assets';

  const { portfolioInfo } = useSelector(selector(assetClassId));

  React.useEffect(() => {
    const newWorkgroupId = portfolioInfo?.workgroup.id;
    if (newWorkgroupId) {
      setWorkgroupId(newWorkgroupId);
    }
  }, [portfolioInfo?.id]);

  return (
    <div>
      <div style={{ marginBottom: '2rem' }}>
        <PortfolioHeader showDashboardMenu={false} subtitle={`${assetClassName}`} />
      </div>
      {typeof workgroupId !== 'undefined' && <UnlistedTableContainer {...{ assetClassId }} />}
    </div>
  );
};

export default UnlistedContainer;
