import { Input, InputGroup, InputNumber, InputProps, Loader } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import ClearIcon from '@rsuite/icons/Close';
import s from './ControlledAutocomplete.module.scss';
import React, { useEffect, useState } from 'react';
import ClickOutside from '../ClickOutside/ClickOutside';

export interface IControlledAutocompleteProps extends InputProps {
  fetchItems: (s: string) => Promise<IComboItemGeneric[]>;
  onItemClick: (item: IComboItem) => void;
  onClear?: () => void;
  defaultValue?: string;
}

const ControlledAutocomplete = React.forwardRef<HTMLInputElement, IControlledAutocompleteProps>(
  ({ fetchItems, onItemClick, onClear, defaultValue, ...restProps }: IControlledAutocompleteProps, ref) => {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState<IComboItem[]>([]);
    const [open, setOpen] = useState(false);
    const [clearable, setClearable] = useState(false);

    useEffect(() => {
      if (defaultValue) {
        setSearchTerm(defaultValue);
        setClearable(true);
      }
    }, []);

    const search = async () => {
      setOpen(true);
      setLoading(true);
      let items: IComboItemGeneric[] = [];
      try {
        items = await fetchItems(searchTerm);
      } catch (e) {}
      setData(items);
      setLoading(false);
    };

    const onClick = (item: IComboItemGeneric) => {
      onItemClick(item);
      setSearchTerm(item.label || '');
      setOpen(false);
      setClearable(true);
    };

    const clear = () => {
      setSearchTerm('');
      onClear?.();
      setClearable(false);
    };
    const onInputFocus = () => {
      if (data.length > 0) {
        setOpen(true);
      }
    };

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <ClickOutside style={{ display: 'flex', flex: 1, position: 'relative' }} clickOutside={() => setOpen(false)}>
          <InputGroup style={{ border: 'none', flex: 1 }}>
            <input type="hidden" value="prayer" />
            <Input
              className={s.input}
              ref={ref}
              onFocus={onInputFocus}
              onPressEnter={search}
              autoComplete={'off'}
              {...restProps}
              onChange={(value, event) => {
                // @ts-ignore
                setSearchTerm(value);
              }}
              value={searchTerm}
            />
            {clearable ? (
              <InputGroup.Button onClick={clear}>
                <ClearIcon />
              </InputGroup.Button>
            ) : (
              <InputGroup.Button onClick={search}>
                <SearchIcon />
              </InputGroup.Button>
            )}
          </InputGroup>
          <div className={s.resultsWrapper}>
            {!open ? null : loading ? (
              <div className={s.userCard} style={{ textAlign: 'center' }}>
                <Loader />
              </div>
            ) : (
              data.map((item) => (
                <div className={s.userCard} onClick={() => onClick(item)}>
                  {item.label}
                </div>
              ))
            )}
          </div>
        </ClickOutside>
      </div>
    );
  }
);

export default ControlledAutocomplete;
