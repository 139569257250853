import * as React from 'react';
import { Fragment } from 'react';
import s from './SidePeriod.module.scss';
import { usePeriod } from '../../../../services/context/PeriodContext';
import moment from 'moment';
import { DatePicker, Select } from '../../../UIWidgets';
import { CHARTS_PERIODS, PERIOD_TYPE } from '../../../../services/constants/constants';
import { IOptionType } from '../../../UIWidgets/Select/Select';
import { PeriodHelper } from '../../../../services/utils/PeriodHelper';
import cn from 'classnames';
import Period from '../../../../static/images/period.svg';

interface IProps {
  inceptionDate?: string;
  options?: Array<IOptionType<PERIOD_TYPE>>;
  isOpen?: boolean;
}

const SidePeriod = ({ inceptionDate, isOpen = true }: IProps) => {
  const { period, updatePeriod } = usePeriod();
  const selectedType: PERIOD_TYPE | undefined = period && period.type;
  const lastQuarter = moment().quarter(moment().quarter()).subtract(1, 'Q');

  const handleDatepickerChange = (date: Date | null, type: 'to' | 'from') => {
    updatePeriod({ ...period!, [type]: date });
  };

  const getYears = () => {
    const isFirstOfJan = inceptionDate!.endsWith('01-01');
    const minYear = isFirstOfJan ? moment(inceptionDate).year() : moment(inceptionDate).year() + 1;
    const maxYear = moment().year() - 1;

    if (minYear > maxYear) {
      return [];
    }
    // return range
    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i);
  };

  const getChartOptions = () => {
    let tempOptions = CHARTS_PERIODS;

    // check for CALENDAR_YEAR
    if (getYears().length === 0) {
      tempOptions = [...tempOptions].filter((p) => p.value !== PERIOD_TYPE.CALENDAR_YEAR);
    }

    const currentYear = new Date().getFullYear();
    const isInceptionBeforeSecondOfJan = moment(inceptionDate).isBefore(moment(currentYear + '-01-02'));
    // check for the YTD
    if (!isInceptionBeforeSecondOfJan) {
      tempOptions = [...tempOptions].filter((p) => p.value !== PERIOD_TYPE.YTD);
    }

    const currentMonth = new Date().getMonth();
    const isInceptionBeforeSecondOfMonth = moment(inceptionDate).isBefore(moment(currentYear + `-${currentMonth}-02`));
    // check for MTD
    if (!isInceptionBeforeSecondOfMonth) {
      tempOptions = [...tempOptions].filter((p) => p.value !== PERIOD_TYPE.MTD);
    }

    const isInceptionBefore1YearAgo = moment().subtract(1, 'year').isBetween(moment(inceptionDate));
    // check for 1 year
    if (!isInceptionBefore1YearAgo) {
      tempOptions = [...tempOptions].filter((p) => p.value !== PERIOD_TYPE.YEAR);
    }

    const inInceptionBefore30Days = moment().subtract(30, 'days').isBetween(moment(inceptionDate));
    // check for 30 days
    if (!inInceptionBefore30Days) {
      tempOptions = [...tempOptions].filter((p) => p.value !== PERIOD_TYPE.THIRTY_DAYS);
    }

    if (lastQuarter.startOf('quarter').isBefore(inceptionDate)) {
      tempOptions = [...tempOptions].filter((p) => p.value !== PERIOD_TYPE.CALENDAR_QUARTER);
    } else {
      tempOptions = tempOptions.map((t) =>
        t.value === PERIOD_TYPE.CALENDAR_QUARTER ? { ...t, label: `Calendar Q${moment(lastQuarter).quarter()}` } : t
      );
    }
    return tempOptions;
  };

  const options = getChartOptions();

  const handleChangePeriod = (type: PERIOD_TYPE) => {
    switch (type) {
      case PERIOD_TYPE.CALENDAR_YEAR:
        const years = getYears();
        if (!years.length) {
          return;
        }
        handleChangeYear(years[years.length - 1].toString());
        break;
      case PERIOD_TYPE.CALENDAR_QUARTER:
        const from = lastQuarter.startOf('quarter').toDate();
        const to = lastQuarter.endOf('quarter').toDate();
        updatePeriod({ from, to, type });
        break;
      default:
        updatePeriod({ ...PeriodHelper.getPeriod(type), type });
    }
  };

  const handleChangeYear = (year: string) => {
    const from = moment(year).startOf('year').toDate();
    const to = moment(year).endOf('year').toDate();
    updatePeriod({ ...period!, from, to, type: PERIOD_TYPE.CALENDAR_YEAR });
  };

  const renderSelect = () => {
    const selectedValue: IOptionType<PERIOD_TYPE> | null | undefined =
      period !== undefined ? options.find((item) => item.value === selectedType) : null;

    return (
      <Fragment>
        <div className={s.selectContainer}>
          <Select
            size="small"
            styles={{ singleValue: () => ({ display: 'block', color: 'var(--text-white)' }) }}
            options={getChartOptions()}
            selectedValue={selectedValue}
            onChange={handleChangePeriod}
          />
        </div>
      </Fragment>
    );
  };

  const renderYearPicker = () => {
    const years = getYears();

    const defaultValue = {
      label: years[years.length - 1].toString(),
      value: years[years.length - 1].toString(),
    };

    const yearOptions = years.map((y) => ({
      label: y.toString(),
      value: y.toString(),
    }));

    const findYearInPeriod = (item: IOptionType) => item.value === (period.from as Date).getFullYear().toString();
    const selectedValue = yearOptions.some(findYearInPeriod) ? yearOptions.find(findYearInPeriod) : defaultValue;

    return (
      <div className={s.periodRow} style={{ marginTop: 10, marginBottom: 15 }}>
        <span style={{ width: 100, marginTop: 0 }} className={s.label}>
          Calendar Year
        </span>
        <div className={s.selectContainer}>
          <Select
            size="small"
            styles={{ singleValue: () => ({ display: 'block', color: 'var(--text-white)' }) }}
            options={yearOptions}
            selectedValue={selectedValue as IOptionType}
            onChange={handleChangeYear}
          />
        </div>
      </div>
    );
  };

  const renderDatePickers = () => {
    const fromDate = period ? period.from : moment().subtract(4, 'month').add(1, 'day').format('DD-MMM-YYYY');
    const toDate = period ? period.to : new Date();

    return (
      <div className={s.datePickers}>
        <div className={s.periodRow}>
          <span className={s.label}>from</span>
          <DatePicker
            hideWeekends={true}
            max={new Date()}
            min={inceptionDate ? new Date(inceptionDate) : undefined}
            className={s.datePicker}
            value={fromDate}
            onChange={(date) => handleDatepickerChange(date.value, 'from')}
          />
        </div>
        <div className={s.periodRow}>
          <span className={s.label}>to</span>
          <DatePicker
            value={toDate}
            hideWeekends={true}
            max={new Date()}
            min={fromDate || (inceptionDate && new Date(inceptionDate)) || undefined}
            onChange={(date) => handleDatepickerChange(date.value, 'to')}
            className={s.datePicker}
          />
        </div>
        <div style={{ height: 20 }} />
      </div>
    );
  };

  let periodFrom = '';
  let periodTo = '';

  if (period) {
    periodFrom = period.from ? moment(period.from).format('DD-MMM-YYYY') : moment(inceptionDate).format('DD-MMM-YYYY');
    periodTo = moment(period.to).format('DD-MMM-YYYY');
  }

  return (
    <div className={cn(s.container, !isOpen && s.marginTop)}>
      <div className={s.periodRow}>
        {isOpen ? <span className={s.periodLabel}>Period</span> : <img src={Period} className={s.periodImage} />}
        <div className={cn(!isOpen && s.noClose)}>{renderSelect()}</div>
      </div>
      <div className={cn(!isOpen && s.noClose)}>
        {selectedType === PERIOD_TYPE.MANUAL && period && renderDatePickers()}
        {selectedType === PERIOD_TYPE.CALENDAR_YEAR && renderYearPicker()}
        {selectedType === PERIOD_TYPE.MANUAL ? null : (
          <div className={s.periodDetail}>{periodFrom + ' to ' + periodTo}</div>
        )}
      </div>
    </div>
  );
};

export default SidePeriod;
