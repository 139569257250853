import { AnyAction, combineReducers } from 'redux';
import { ITreeMapData } from '../../components/TreeMap/TreeMap';

import { IRecommendation } from '../../models/IRecommendation';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { RecommendationsActions } from '../actions';
import { ALLOCATION_TYPES, PERIOD_TYPE } from '../constants/constants';
import { PeriodHelper } from '../utils/PeriodHelper';
import { ReduxHelper } from '../utils/ReduxHelper';

export type IPerformanceDataState = ILoadingDataState<IPerformanceData>;
export type IPortfolioRecommendationsState = ILoadingDataState<Record<string, IRecommendation[]>>;
export type IAssetAllocation = Record<ALLOCATION_TYPES.AssetClass, ITreeMapData[]>;
export type IRegionAllocation = Record<ALLOCATION_TYPES.Region, ITreeMapData[]>;

export interface IAssetsState {
  selectedAsset: IAssetClass | null;
}

export interface IRecommendationsState {
  personalPerformance: IPerformanceDataState;
  resultPerformance: IPerformanceDataState;
  performancePeriod: IPeriod;
  portfolioRecommendation: IPortfolioRecommendationsState;
  assetsClasses: IAssetsState;
  allocations: {
    classes: ILoadingDataState<IAssetAllocation>;
    geography: ILoadingDataState<IRegionAllocation>;
  };
}

/**
 * recommendations
 */
const INITIAL_PERFORMANCE_DATA: IPerformanceData = {
  data: [],
};

const INITIAL_RECOMMENDATIONS_STATE: IPortfolioRecommendationsState = ReduxHelper.createInitialState({});

const portfolioRecommendationDefault = ReduxHelper.createHttpRequestReducer<IPortfolioRecommendationsState>(
  INITIAL_RECOMMENDATIONS_STATE,
  RecommendationsActions.RECOMMENDATIONS_REQUEST,
  RecommendationsActions.RECOMMENDATIONS_SUCCESS,
  RecommendationsActions.RECOMMENDATIONS_ERROR,
  RecommendationsActions.RECOMMENDATIONS_RESET
);

const portfolioRecommendation = (
  state: IPortfolioRecommendationsState = INITIAL_RECOMMENDATIONS_STATE,
  action: AnyAction
): IPortfolioRecommendationsState => {
  switch (action.type) {
    case RecommendationsActions.TOGGLE_RECOMMENDATION:
      const changedRecommendation = state.data[action.payload.assetClassId].map((item, idx) => {
        if (idx === action.payload.recommendationIdx) {
          return { ...item, active: !item.active };
        }
        return item;
      });
      return {
        ...state,
        data: { ...state.data, [action.payload.assetClassId]: changedRecommendation },
      };
    default:
      return portfolioRecommendationDefault(state, action);
  }
};

/**
 * Selected Asset
 */

// TODO: remove selected asset from reducers (maybe use route)
const INITIAL_ASSETS_STATE: IAssetsState = {
  selectedAsset: null,
};

const assetsClasses = (state: IAssetsState = INITIAL_ASSETS_STATE, action: AnyAction): IAssetsState => {
  switch (action.type) {
    case RecommendationsActions.SELECT_RECOMMENDATION_ASSET_CLASS:
      return {
        ...state,
        selectedAsset: action.payload.assetClass,
      };
    case RecommendationsActions.RESET_RECOMMENDATION_ASSET_CLASS:
      return {
        ...state,
        selectedAsset: INITIAL_ASSETS_STATE.selectedAsset,
      };
    default:
      return state;
  }
};

const INITIAL_ALLOCATION_CLASS_DATA: IAssetAllocation = {
  [ALLOCATION_TYPES.AssetClass]: [],
};

const INITIAL_ALLOCATION_REGION_DATA: IRegionAllocation = {
  [ALLOCATION_TYPES.Region]: [],
};

const INITIAL_PERIODS: IPeriod = { ...PeriodHelper.getPeriod(PERIOD_TYPE.YEAR), type: PERIOD_TYPE.YEAR };

const periodsReducer = (state = INITIAL_PERIODS, action: AnyAction) => {
  switch (action.type) {
    case RecommendationsActions.SET_RESULT_PERFORMANCE_PERIOD:
      return {
        ...state,
        ...action.payload,
      };
    case RecommendationsActions.RESET_RESULT_PERFORMANCE_PERIODS:
      return { ...INITIAL_PERIODS };
    default:
      return state;
  }
};

export default combineReducers<IRecommendationsState>({
  personalPerformance: ReduxHelper.createHttpRequestReducer<IPerformanceDataState>(
    ReduxHelper.createInitialState(INITIAL_PERFORMANCE_DATA),
    RecommendationsActions.PERSONAL_PERFORMANCE_REQUEST,
    RecommendationsActions.PERSONAL_PERFORMANCE_SUCCESS,
    RecommendationsActions.PERSONAL_PERFORMANCE_ERROR,
    RecommendationsActions.PERSONAL_PERFORMANCE_RESET
  ),
  resultPerformance: ReduxHelper.createHttpRequestReducer<IPerformanceDataState>(
    ReduxHelper.createInitialState(INITIAL_PERFORMANCE_DATA),
    RecommendationsActions.RESULT_PERFORMANCE_REQUEST,
    RecommendationsActions.RESULT_PERFORMANCE_SUCCESS,
    RecommendationsActions.RESULT_PERFORMANCE_ERROR,
    RecommendationsActions.RESULT_PERFORMANCE_RESET
  ),
  performancePeriod: periodsReducer,
  portfolioRecommendation,
  assetsClasses,
  allocations: combineReducers({
    classes: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IAssetAllocation>>(
      ReduxHelper.createInitialState<IAssetAllocation>(INITIAL_ALLOCATION_CLASS_DATA),
      RecommendationsActions.CLASSES_ALLOCATION_DATA_REQUEST,
      RecommendationsActions.CLASSES_ALLOCATION_DATA_SUCCESS,
      RecommendationsActions.CLASSES_ALLOCATION_DATA_ERROR,
      RecommendationsActions.CLASSES_ALLOCATION_DATA_RESET
    ),
    geography: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IRegionAllocation>>(
      ReduxHelper.createInitialState<IRegionAllocation>(INITIAL_ALLOCATION_REGION_DATA),
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_REQUEST,
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_SUCCESS,
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_ERROR,
      RecommendationsActions.GEOGRAPHY_ALLOCATION_DATA_RESET
    ),
  }),
});
