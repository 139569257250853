import { ReduxHelper } from '../utils/ReduxHelper';

import { CurrencyActions } from '../actions/CurrencyActions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

export interface ICurrencyState extends ILoadingDataState {
  data: ICurrency[];
}

const INITIAL_STATE: ICurrencyState = ReduxHelper.createInitialState<ICurrency[]>([]);

const currencyReducer = ReduxHelper.createHttpRequestReducer<ICurrencyState>(
  INITIAL_STATE,
  CurrencyActions.CURRENCY_REQUEST,
  CurrencyActions.CURRENCY_SUCCESS,
  CurrencyActions.CURRENCY_ERROR
);

export default currencyReducer;
