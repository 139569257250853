import { IRootState } from '../store';

import { createSelector } from 'reselect';
import { IOption } from '../../components/UIWidgets/Autocomplete';

export function getPortfolioCurrency(state: IRootState): ICurrency[] {
  return state.portfolioCurrency.data;
}

export function getAllCurrency(state: IRootState): ICurrency[] {
  return state.currency.data;
}

export const getPortfolioCurrencyOptions = createSelector<IRootState, ICurrency[], Array<IOption<ICurrency>>>(
  getPortfolioCurrency,
  (currencyList: ICurrency[]): Array<IOption<ICurrency>> => {
    return currencyList.map((currency) => {
      return {
        id: currency.id,
        value: currency,
        name: currency.name,
      };
    });
  }
);

export const getAllCurrencyOptions = createSelector<IRootState, ICurrency[], Array<IOption<ICurrency>>>(
  getAllCurrency,
  (currencyList: ICurrency[]): Array<IOption<ICurrency>> => {
    return currencyList.map((currency) => {
      return {
        id: currency.id,
        value: currency,
        name: currency.name,
      };
    });
  }
);
