import s from './ToggableItem.module.scss';
import { Color } from 'csstype';
import React from 'react';

export interface ILegendItem {
  label: string;
  value: string;
  color: Color;
}

interface IProps {
  item: ILegendItem;
  onPress: (key: string) => void;
}

const labelMapper = new Map();

labelMapper.set('CashAndEquivalents', 'Cash And Equivalents');
labelMapper.set('FixedIncome', 'Fixed Income');
labelMapper.set('CryptoCurrencies', 'Crypto Currencies');

const ToggableItem = ({ item, onPress }: IProps) => {
  return (
    <div onClick={() => onPress(item.label)} className={s.itemContainer}>
      <div className={s.legendColor} style={{ backgroundColor: item.color === 'white' ? 'transparent' : item.color }} />
      <div>
        <span className={s.legendItemName}>
          {labelMapper.get(item.label) ? labelMapper.get(item.label) : item.label}
        </span>
        <span className={s.legendItemValue}>{item.value}</span>
      </div>
    </div>
  );
};

export default ToggableItem;
