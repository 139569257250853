import { ClickOutside, NestedList } from '../index';
import React, { FocusEvent, useCallback, useState } from 'react';
import cn from 'classnames';
import { NestedSet } from '../NestedList/NestedSet';
import s from './NestedSelect.module.scss';

export interface ISelectHandlerCallbackOptions {
  isVisible: boolean | undefined;
}

export interface ISelectHandlerProps {
  indexes: number[] | null;
  nestedSet: NestedSet<any>;
}

export interface IProps {
  selectHandler: (selectHandlerProps: ISelectHandlerProps) => ISelectHandlerCallbackOptions;
  nestedSet: NestedSet<any>;
  text: string;
  haveError?: boolean;
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
  onFocus?: (e: FocusEvent<HTMLElement>) => void;
}

function NestedSelect(props: IProps) {
  const [visible, setVisible] = useState(false);

  const onSelect = useCallback(
    (indexes: number[]) => {
      const { isVisible } = props.selectHandler({
        indexes,
        nestedSet: props.nestedSet,
      });
      if (typeof isVisible === 'boolean') {
        setVisible(isVisible);
      }
    },
    [props.nestedSet, props.selectHandler]
  );

  const reset = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      const { isVisible } = props.selectHandler({
        indexes: null,
        nestedSet: props.nestedSet,
      });
      if (typeof isVisible === 'boolean') {
        setVisible(isVisible);
      }
    },
    [props.nestedSet, props.selectHandler]
  );
  const inputClass = cn(s.input, {
    [s.input_error]: typeof props.haveError === 'boolean' && props.haveError,
  });

  return (
    <ClickOutside clickOutside={() => setVisible(false)} className={s.assetFilter}>
      <div
        className={inputClass}
        onClick={() => setVisible(true)}
        tabIndex={0}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      >
        {props.text}
        <i className={s.resetIcon} onClick={reset} />
      </div>
      {visible && <NestedList nestedSet={props.nestedSet} onSelect={onSelect} />}
    </ClickOutside>
  );
}

export default NestedSelect;
