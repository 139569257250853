import React, { PureComponent } from 'react';
import { DropEvent } from 'react-dropzone';

import { UploadFile, UploadLoading, UploadSuccess } from './components';

interface IProps {
  isLoading: boolean;
  isLoaded: boolean;
  loadedCount: number;
  uploadProgress: number;
  onUpload: (file: File) => void;
  onReset: () => void;
}

export class Upload extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    isLoading: false,
    isLoaded: false,
    loadedCount: 0,
    uploadProgress: 0,
    onUpload: (file: File) => {},
    onReset: () => {},
  };

  handleOnDrop = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
    this.props.onUpload(acceptedFiles[0]);
  };

  render() {
    const { isLoading, isLoaded, loadedCount, uploadProgress, onReset } = this.props;
    if (isLoading) {
      return <UploadLoading progress={uploadProgress} onReset={onReset} />;
    }

    if (isLoaded && loadedCount) {
      return <UploadSuccess loadedCount={loadedCount} onReset={onReset} />;
    }

    return <UploadFile onDrop={this.handleOnDrop} />;
  }
}

export default Upload;
