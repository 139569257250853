import React, { FC } from 'react';
import { TRANSACTION_TYPES } from '../../../../../../services/mock/transactionTypes';
import { generatePath, Link } from 'react-router-dom';
import { PATHS } from '../../../../../../router/paths';
import cn from 'classnames';
import s from './SnapshotTable.module.scss';
import { FormatHelper } from '../../../../../../services/utils/FormatHelper';
import { Utils } from '../../../../../../services/utils/Utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../services/constants/constants';
import { Icon, Whisper, Popover } from 'rsuite';
import InstrumentImage from '../../../../../../components/InstrumentImage/InstrumentImage';

type Renderer = (rowData: IPortfolioSnapshot, dataKey: keyof IPortfolioSnapshot) => React.ReactNode;

const Cell: FC<{ rowData: IPortfolioSnapshot; rightAligned?: boolean }> = ({
  children,
  rowData,
  rightAligned = true,
}) => {
  return (
    <div
      className={cn(
        rightAligned && s.rightAligned,
        typeof rowData.amount !== 'undefined' && rowData.amount < 0 && s.blue
      )}
    >
      {children}
    </div>
  );
};

function currencyFormat(num: number, decimalPlaces: number = 2) {
  if (!num) {
    return undefined;
  }
  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

const DefaultCellRenderer: Renderer = (rowData, dataKey) => {
  return (
    <Cell rowData={rowData} rightAligned={false}>
      <Whisper placement={'top'} speaker={<Popover>{rowData[dataKey]}</Popover>}>
        <div>{rowData[dataKey]}</div>
      </Whisper>
    </Cell>
  );
};

const PNLCell = (rowData: IPortfolioSnapshot, dataKey: keyof IPortfolioSnapshot) => {
  const cellData = rowData[dataKey] as number;
  return (
    <Cell rowData={rowData}>
      <span className={cn(cellData < 0 && s.red, cellData > 0 && s.green, s.lastItem)}>
        {cellData === 0 ? cellData : currencyFormat(cellData, 0)}
      </span>
    </Cell>
  );
};
const CurrencyCell = (rowData: IPortfolioSnapshot, dataKey: keyof IPortfolioSnapshot) => {
  const cellData = rowData[dataKey] as number;

  return (
    <Cell rowData={rowData}>
      <div className={cn(cellData < 0 ? s.blue : null)}>{cellData === 0 ? cellData : currencyFormat(cellData, 0)}</div>
    </Cell>
  );
};

const DateCell: Renderer = (rowData, dataKey) => {
  const cellData = rowData[dataKey] as number;
  return (
    <Cell rowData={rowData}>
      <div
        className={cn(
          typeof rowData.amount !== 'undefined' &&
            moment(rowData.pricingDate).format('DD-MMM-YYYY') !== moment().format('DD-MMM-YYYY') &&
            s.fade
        )}
      >
        {moment(cellData).format(DATE_FORMAT)}
      </div>
    </Cell>
  );
};

const ZeroCell = (assetClasses: IAssetClass[], minDecimals = 0, maxDecimals?: number): Renderer => (
  rowData,
  dataKey
) => {
  const cellData = rowData[dataKey] as number;

  const assetClass = rowData.assetClass;
  const content =
    cellData === 0
      ? cellData
      : FormatHelper.localePriceValueFormatter(
          cellData,
          typeof maxDecimals !== 'undefined'
            ? maxDecimals
            : !!assetClass
            ? Utils.getPriceDecimals(assetClasses, assetClass)
            : 2,
          minDecimals
        );
  return <Cell rowData={rowData}>{content}</Cell>;
};

const NumberCellWithDecimals = (decimalPlaces?: number): Renderer => (rowData, dataKey) => {
  const value = rowData[dataKey] as number;
  if (!value) {
    return '';
  }

  const wholes = Number(value.toString().split('.')[0]);

  if (Number.isInteger(value)) {
    return (
      <Cell rowData={rowData}>
        <Whisper
          speaker={<Popover>{rowData[dataKey]?.toLocaleString(undefined, { maximumFractionDigits: 10 })}</Popover>}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ width: 80 }}>
              {(wholes * (value < 0 ? -1 : 1)).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            <div style={{ color: 'transparent', width: 8, textAlign: 'center' }}>.</div>
            <div style={{ width: 40, textAlign: 'left', opacity: 0 }}>00</div>
          </div>
        </Whisper>
      </Cell>
    );
  }

  const assetClass = rowData.assetClass;
  const maxDecimals = typeof decimalPlaces === 'number' ? decimalPlaces : assetClass === 'Crypto-Currencies' ? 7 : 2;
  const parsedValue = value.toLocaleString(undefined, { maximumFractionDigits: maxDecimals });
  const decimals = parsedValue.toString().split('.')[1];

  const content = (
    <Whisper speaker={<Popover>{rowData[dataKey]?.toLocaleString(undefined, { maximumFractionDigits: 10 })}</Popover>}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: 80 }}>
          {(wholes * (value < 0 ? -1 : 1)).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
        <div style={{ color: decimals?.length === 0 ? 'transparent' : undefined, width: 8, textAlign: 'center' }}>.</div>
        <div style={{ width: 40, textAlign: 'left', opacity: 0.5 }}>{decimals}</div>
      </div>
    </Whisper>
  );

  return <Cell rowData={rowData}>{content}</Cell>;
};
const NumberCell = NumberCellWithDecimals();

export function getSnapshotColumns(
  portfolioCurrency: string = '',
  portfolioId: string,
  assetClasses: IAssetClass[],
  expandedRowKeys: string[],
  toggleRow: (id?: string) => (e: any) => void
): any[] {
  const currencyTotalFormatter = (colored: boolean = false) => (val: number) => (
    <div
      style={{
        color: !colored ? undefined : val > 0 ? 'var(--text-success-color)' : 'var(--text-alert-color)',
        textAlign: 'right',
      }}
    >
      {Math.round(val).toLocaleString()}
    </div>
  );
  const percentFormatter = (val: number) => `${Math.round(val)}%`;

  return [
    {
      label: '',
      dataKey: 'assetClass',
      flexGrow: 0,
      flexShrink: 0,
      width: 50,
      display: true,
      sortable: true,
      isOptional: false,
      cellRenderer: (rowData: IPortfolioSnapshot, dataKey: keyof IPortfolioSnapshot) => {
        let content;
        // const cleanedAssetClass = rowData.assetClass?.replace(/([ \-])+/g, '') ?? '';
        const chevron = (
          <div onClick={toggleRow(rowData.positionId)}>
            <Icon
              icon={expandedRowKeys.includes(rowData?.positionId || '') ? 'chevron-up' : 'chevron-down'}
              style={{ fontSize: 10, marginRight: 5, cursor: 'pointer' }}
            />
          </div>
        );

        content = (
          <div className={cn(s.chevronImageSurround)}>
            {chevron}
            <InstrumentImage size={15} instrument={rowData} />
          </div>
        );

        // if (
        //   [
        //     TRANSACTION_TYPES.alternatives,
        //     TRANSACTION_TYPES.equities,
        //     TRANSACTION_TYPES.funds,
        //     TRANSACTION_TYPES.fixedIncome,
        //     TRANSACTION_TYPES.cryptoCurrencies
        //   ]
        //     .includes(cleanedAssetClass)
        // ) {
        //   content = (
        //     <div className={cn(s.chevronImageSurround)}>
        //       {chevron}
        //       <Link
        //         to={generatePath(PATHS.portfolio.dashboard.breakdownDetailDashboard.path, {
        //           portfolioId,
        //           classId: cleanedAssetClass,
        //           positionId: rowData.positionId || '/'
        //         })}
        //       >
        //         <InstrumentImage size={15} instrument={rowData}/>
        //       </Link>
        //     </div>
        //   );
        // } else {
        //
        // }
        return <Cell {...{ rowData, dataKey }}>{content}</Cell>;
      },
    },
    {
      label: 'Instrument',
      dataKey: 'name',
      flexGrow: 3,
      display: true,
      // width: 220,
      isOptional: true,
      sortable: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Asset Subclass',
      dataKey: 'assetSubClass',
      flexGrow: 0,
      width: 120,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Risk Asset Class',
      dataKey: 'riskAssetClass',
      flexGrow: 1,
      // width: 120,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Investment Vehicle',
      dataKey: 'investmentVehicle',
      flexGrow: 1,
      // width: 120,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Sector',
      dataKey: 'sector',
      flexGrow: 0,
      width: 180,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'ID',
      dataKey: 'displayId',
      flexGrow: 1,
      display: true,
      // width: 100,
      flexShrink: 2,
      sortable: true,
      isOptional: false,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Country',
      dataKey: 'country',
      flexGrow: 1,
      // width: 120,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Custodian',
      dataKey: 'custodian',
      flexGrow: 1,
      // width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Quantity',
      dataKey: 'quantity',
      // flexGrow: 1,
      width: 120,
      display: true,
      sortable: true,
      isOptional: false,
      align: 'right',
      cellRenderer: NumberCell,
    },
    {
      label: 'Ccy',
      dataKey: 'currencyNative',
      flexGrow: 0,
      flexShrink: 0,
      width: 50,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: 'Cost Price',
      dataKey: 'costPrice',
      // flexGrow: 1,
      width: 120,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      cellRenderer: NumberCell,
    },
    {
      label: 'Last Price',
      dataKey: 'currentPriceNative',
      // flexGrow: 1,
      width: 125,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      cellRenderer: NumberCell,
    },
    {
      label: 'Portfolio %',
      dataKey: 'percentOfPortfolio',
      flexGrow: 0.5,
      flexShrink: 0,
      width: 80,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: percentFormatter,
      },
    },
    {
      label: 'Gross NAV %',
      dataKey: 'percentOfGrossPortfolio',
      flexGrow: 0.5,
      flexShrink: 0,
      width: 80,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: DefaultCellRenderer,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: percentFormatter,
      },
    },
    {
      label: 'Accrued Int',
      dataKey: 'accruedLocal',
      flexGrow: 0.5,
      flexShrink: 0,
      width: 80,
      display: true,
      sortable: true,
      isOptional: true,
      cellRenderer: NumberCell,
      align: 'right',
    },
    {
      label: 'Dirty Price',
      dataKey: 'dirtyPrice',
      flexGrow: 0.5,
      flexShrink: 0,
      width: 80,
      display: true,
      sortable: false,
      isOptional: true,
      cellRenderer: NumberCellWithDecimals(4),
      align: 'right',
    },
    {
      label: 'Price Move vs Cost',
      dataKey: 'priceGain',
      flexGrow: 0,
      flexShrink: 0,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      cellRenderer: (rowData: IPortfolioSnapshot, dataKey: keyof IPortfolioSnapshot) => {
        const val = (rowData.priceGain === null ? rowData.priceMove : rowData.priceGain) as number;
        const favourable = (rowData.amount ?? 0) < 0 ? val < 0 : val > 0;
        return (
          <Cell {...{ rowData, dataKey }}>
            <span
              className={cn(
                rowData.priceGain !== 0 && favourable && s.green,
                rowData.priceGain !== 0 && !favourable && s.red
              )}
            >
              {val === 0 ? val : Math.round(val * 100)}%
            </span>
          </Cell>
        );
      },
    },
    {
      label: 'Pricing Date',
      dataKey: 'pricingDate',
      flexGrow: 0,
      flexShrink: 0,
      width: 80,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      cellRenderer: DateCell,
    },
    {
      label: 'Region',
      dataKey: 'region',
      flexGrow: 0,
      flexShrink: 0,
      width: 120,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      cellRenderer: DefaultCellRenderer,
    },
    {
      label: ['Cost', portfolioCurrency].join(' '),
      dataKey: 'totalCostBase',
      flexGrow: 1,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },
    {
      label: ['Notional', portfolioCurrency].join(' '),
      dataKey: 'notionBase',
      flexGrow: 1,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },
    {
      label: ['∆ Exposure', portfolioCurrency].join(' '),
      dataKey: 'exposureBase',
      flexGrow: 1,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },
    {
      label: ['Income', portfolioCurrency].join(' '),
      dataKey: 'incomeHistoricBase',
      flexGrow: 1,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },
    {
      label: ['Income', '(Local)'].join(' '),
      dataKey: 'incomeLocal',
      flexGrow: 1,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },
    {
      label: ['Realised', portfolioCurrency].join(' '),
      dataKey: 'realisedBase',
      flexGrow: 1,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },
    {
      label: ['Value', portfolioCurrency].join(' '),
      dataKey: 'amount',
      flexGrow: 0,
      flexShrink: 0,
      width: 100,
      display: true,
      sortable: true,
      isOptional: false,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(false),
      },
      cellRenderer: CurrencyCell,
    },

    {
      label: ['P&L', portfolioCurrency].join(' '),
      dataKey: 'profitAndLoss',
      flexGrow: 0,
      flexShrink: 0,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(true),
      },
      cellRenderer: PNLCell,
    },
    {
      label: ['P&L', '(Local)'].join(' '),
      dataKey: 'pnlLocal',
      flexGrow: 0,
      flexShrink: 0,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(true),
      },
      cellRenderer: PNLCell,
    },
    {
      label: ['P&L', '(Fx)'].join(' '),
      dataKey: 'pnlFxBase',
      flexGrow: 0,
      flexShrink: 0,
      width: 100,
      display: true,
      sortable: true,
      isOptional: true,
      align: 'right',
      aggregate: {
        type: 'sum',
        renderer: currencyTotalFormatter(true),
      },
      cellRenderer: PNLCell,
    },
  ];
}
