import cn from 'classnames';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../components/ContextHelp';
import { renderInput } from '../../../components/ReduxForm';
import renderAutocomplete from '../../../components/ReduxForm/renderAutocomplete';
import { Button } from '../../../components/UIWidgets';
import { IOption } from '../../../components/UIWidgets/Autocomplete';

import { UserRole } from '../../../services/constants/constants';
import { FORMS_NAME } from '../../../services/constants/forms';
import { PORTFOLIO_CREATE_HELP_TEXT } from '../../../services/constants/tooltipContextHelp';
import { IRootState } from '../../../services/store';
import { required, maxLength, dbFieldNamingRules } from '../../../services/utils/FormValidations';
import sortComparator from '../../../services/utils/sortComparator';
import { IPortfolioCreateFormData } from './models';
import s from './PortfolioCreateForm.module.scss';
import c from '../components/PortfolioFormsCommon.module.scss';
import SimpleTypeAhead from '../../../components/Reusable/SimpleTypeAhead/SimpleTypeAhead';
import { WorkgroupActions } from '../../../services/actions/WorkgroupActions';
import { Panel } from 'rsuite';
import { useAccount } from '../../../services/context/AuthContext';
import { AdvancedSettingsPanel } from '../components/AdvancedSettingsPanel';
import { PortfolioCustodianListPanel } from '../components/PortfolioCustodianListPanel';
import _ from 'lodash';

interface IContainerProps {
  currencyOptions: Array<IOption<ICurrency>>;
  performanceIndexOptions: Array<IOption<IIndex>>;
  serverCustodians: ICustodian[];
  portfolioType: string | null;
  authInfo: IAuthInfo | null;
  showNotification: (notification: INotification) => void;
}

interface IMapStateToProps {
  custodian: ICustodian | null;
  portFolioCustodians: ICustodian[];
  onlyManualDividendsDefault: boolean;
  customCustodian: string;
  workgroups: IWorkgroup[];
}

interface IDispatchToProps {
  updateType: (type: string) => void;
  updatePerformanceIndex: (index: IIndex) => void;
  updateCustodian: (custodian: ICustodian | null) => void;
  updatePortFolioCustodians: (portFolioCustodians: ICustodian[]) => void;
  toggleSystemDividends: (onlyManualDividendsDefault?: boolean) => void;
  updateCustomCustodian: (custodian: string) => void;
  updateRiskAssetViewType(viewType: RiskAssetViewType): void;
  // fetchWorkgroups(): void;
}

type IProps = IContainerProps &
  InjectedFormProps<IPortfolioCreateFormData, IContainerProps> &
  IDispatchToProps &
  IMapStateToProps;

const maxLength50 = maxLength(50);

const PortfolioForm = (props: IProps) => {
  const dispatch = useDispatch();
  const { userCapabilities } = useAccount();
  const { authInfo, updateType } = props;
  // const [defaultRiskAssetView, setDefaultRiskAssetView] = React.useState<RiskAssetViewType>('INSTRUMENT_ASSET_CLASS');
  const preferences = userCapabilities?.preferences;
  const metadata = userCapabilities?.metadata;
  const isD2c = metadata?.allowedViews?.includes('d2c');

  React.useEffect(() => {
    if (authInfo && [UserRole.ADMIN, UserRole.MANAGER].includes(authInfo.role)) {
      updateType('CLIENT');
    }
    // props.fetchWorkgroups();
    dispatch(WorkgroupActions.fetchWorkgroups());
  }, [authInfo, updateType, dispatch]);

  const custodianError = () => {
    if (props.customCustodian && !_.inRange(props.customCustodian.length, 2, 41)) {
      return 'Must be between 2 and 40 characters.';
    }
    if (props.customCustodian && !/^([a-zA-Z0-9-_ & *.])+()$/i.test(props.customCustodian)) {
      return 'Only alphanumeric, spaces, underscores, ampersand, full stop, asterisk and dash characters.';
    }
    return null;
  };

  // this workaround is needed because redux-form field validator is misbehaving in render() when used as a closure

  const {
    handleSubmit,
    invalid,
    pristine,
    submitting,
    currencyOptions,
    workgroups,
    onlyManualDividendsDefault,
    serverCustodians,
  } = props;

  const isWithError = custodianError();

  return (
    <div className={c.wrapper}>
      <ReactTooltip type="light" effect="solid" />

      <h1>New portfolio</h1>
      <form className={c.formWrapper} onSubmit={() => {}}>
        <Panel style={{ background: 'var(--dark)', marginBottom: '1.5rem' }} shaded={true}>
          <div>
            <div className={cn(s.labelCotaioner, c.labelContainer)}>
              <h4>Portfolio name*</h4>
            </div>
            <div className={cn(c.indexRow)}>
              <Field
                name="name"
                component={renderInput}
                placeholder={'Portfolio name'}
                validate={[required, maxLength50, dbFieldNamingRules]}
              />
            </div>
          </div>
          {preferences?.groupClients && (
            <div>
              <div className={c.labelContainer}>
                <h4>Client Account ID*</h4>
              </div>
              <div className={cn(c.indexRow)}>
                <Field
                  name="newWorkgroupName"
                  component={SimpleTypeAhead}
                  options={workgroups.map((workgroup) => ({
                    id: workgroup.id,
                    name: workgroup.name,
                    value: workgroup,
                  }))}
                  validate={[required, maxLength50, dbFieldNamingRules]}
                  placeholder={'Client Account ID'}
                />
              </div>
            </div>
          )}
        </Panel>
        <Panel style={{ background: 'var(--dark)', marginBottom: '1.5rem', overflow: 'visible' }} shaded={true}>
          <div>
            <div className={c.labelContainer}>
              <h4>
                Base currency* <ContextHelpIcon title={'Base Currency'} elementId={'lblBaseCurrency'} />
              </h4>
            </div>
            <div className={cn(c.indexRow, s.comboPseudoContainer)}>
              <Field
                className={s.comboPseudoField}
                name="currency"
                component={renderAutocomplete}
                options={currencyOptions}
                filter={(search: string, option: IOption<ICurrency>) =>
                  option.value.name.toLowerCase().includes(search.toLowerCase())
                }
                validate={[required]}
                inverseTheme={false}
                inputProps={{
                  placeholder: 'Base currency',
                  required: true,
                }}
              />
              <div className={s.comboPseudoArrow} />
            </div>
            <ContextHelpTooltip
              elementId={'lblBaseCurrency'}
              tooltipTitle={'Base Currency'}
              tooltipText={PORTFOLIO_CREATE_HELP_TEXT.lblBaseCurrency}
            />
          </div>
        </Panel>
        <Panel style={{ background: 'var(--dark)', marginBottom: '1.5rem', overflow: 'visible' }} shaded={true}>
          <PortfolioCustodianListPanel
            temp={true}
            required={true}
            custodian={props.custodian}
            portfolioCustodians={props.portFolioCustodians}
            customCustodian={props.customCustodian}
            onlyManualDividendsDefault={onlyManualDividendsDefault}
            serverCustodians={serverCustodians}
            updateCustomCustodian={props.updateCustomCustodian}
            updateCustodian={props.updateCustodian}
            updatePortfolioCustodians={props.updatePortFolioCustodians}
            showNotification={props.showNotification}
          />
        </Panel>

        <AdvancedSettingsPanel
          performanceIndexOptions={props.performanceIndexOptions}
          updateRiskAssetViewType={!isD2c ? props.updateRiskAssetViewType : undefined}
        />

        <div className={c.row} style={{ marginTop: 10 }}>
          <Button
            onClick={handleSubmit}
            size="big"
            className={c.createBtn}
            disabled={
              invalid || pristine || submitting || (props.portFolioCustodians && props.portFolioCustodians.length === 0)
            }
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const selector = formValueSelector(FORMS_NAME.portfolio);
  const portFolioCustodians = selector(state, 'userPortfolioCustodians') || [];
  const onlyManualDividendsDefault = selector(state, 'onlyManualDividendsDefault') || false;
  return {
    custodian: selector(state, 'custodian'),
    portFolioCustodians: portFolioCustodians.sort(
      sortComparator<ICustodian>((c) => c.name.toLocaleLowerCase())
    ),
    onlyManualDividendsDefault,
    customCustodian: selector(state, 'customCustodian'),
    workgroups: state.users.workgroups.data,
  };
};

const mapDispatchToProps = {
  updateType: (type: string) => change(FORMS_NAME.portfolio, 'type', type),
  updatePerformanceIndex: (index: IIndex) => change(FORMS_NAME.portfolio, 'performanceIndex', index),
  updatePortFolioCustodians: (portFolioCustodians: ICustodian[]) =>
    change(FORMS_NAME.portfolio, 'userPortfolioCustodians', portFolioCustodians),
  updateCustodian: (custodian: ICustodian | null) => change(FORMS_NAME.portfolio, 'custodian', custodian),
  toggleSystemDividends: (onlyManualDividendsDefault?: boolean) =>
    change(FORMS_NAME.portfolio, 'onlyManualDividendsDefault', !onlyManualDividendsDefault),
  updateCustomCustodian: (custodian: string) => change(FORMS_NAME.portfolio, 'customCustodian', custodian),
  updateRiskAssetViewType: (viewType: RiskAssetViewType) =>
    change(FORMS_NAME.portfolio, 'defaultRiskAssetView', viewType),
};

export default reduxForm<IPortfolioCreateFormData, IContainerProps>({
  form: FORMS_NAME.portfolio,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(PortfolioForm));
