import React, { useState } from 'react';
import { Period } from '../../../../../../../../components/Period';

import { ASSETS } from '../../../../../../../../services/constants/assetClasses';
import { getTopPositionsByPnLColumns } from './topPositionsColumns';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import moment from 'moment';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { INITIAL_PERIOD } from '../../../../../../../../services/reducers/DashboardReducers/PerformanceReducers';
import { cryptoCurrencyCellRenderer } from './utils/CryptoCurrencyCellRenderer';
import { GlobalPnlWithBreakdownPositions } from '../Shared/GlobalPnlWithBreakdownPositions';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { Loader } from '../../../../../../../../components';
import AssetReportWrapper from '../../components/AssetReportWrapper/AssetReportWrapper';
import { PortfolioHeader } from '../../../../../../components';

const CryptoCurrency = () => {
  const { isLoading, hasData, portfolioInfo, dashboardInfo, portfolioCurrencyFormatter } = usePortfolio(
    ASSETS.cryptoCurrencies
  );
  const [period, setPeriod] = useState(INITIAL_PERIOD);

  const handlePeriodChange = (currrentPeriod: IPeriod) => {
    setPeriod(currrentPeriod);
  };

  const renderPeriodSelector = (): React.ReactNode => {
    if (period.from === null || period.from === undefined) {
      period.from =
        typeof portfolioInfo.data?.inceptionDate === 'string' ? moment(portfolioInfo.data?.inceptionDate) : null;
    }
    return <Period options={CHARTS_PERIODS} selectedPeriod={period} onPeriodChange={handlePeriodChange} />;
  };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo.totalPnlBase.value);
  const lifeTimeTWR = `${dashboardInfo.inceptionPerformance || 0}%`;

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: lifeTimeTWR,
      label: 'Performance (TWR)',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: total,
      label: 'Total Cryptocurrency value',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoTotalEquity },
    },
  ];
  return (
    <AssetReportWrapper title="Crypto Currency" allLoaded={!isLoading} noData={hasData}>
      <PortfolioHeader title="Crypto Currency" />
      <div>
        <PortfolioSubHeader stats={stats} title={'Cryptocurrency'} />
        <GlobalPnlWithBreakdownPositions
          period={period}
          handlePeriodChange={handlePeriodChange}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          portfolioId={portfolioInfo.data?.id!}
          getTopPositionColumns={getTopPositionsByPnLColumns}
          cellRenderer={cryptoCurrencyCellRenderer}
          assetClass={ASSETS.cryptoCurrencies}
        />
      </div>
    </AssetReportWrapper>
  );
};

export default CryptoCurrency;
