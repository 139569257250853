import { TableCellProps } from 'react-virtualized';
import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../services/constants/constants';
import ValueCell from '../TempBlotterTable/components/ValueCell';
import { FormatHelper } from '../../../../../../services/utils/FormatHelper';
import { Utils } from '../../../../../../services/utils/Utils';
import { floatNumberV2 } from '../../../../../../services/utils/FormNormalize';
import { NotesCell, TypeCell } from './components';
import { CellRenderer } from '../../../../../../components/UIWidgets/TableWrapper/components';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { TradesTableProps } from './TradesTableProps';
import { Checkbox, Icon, Popover, PopoverProps, Whisper } from 'rsuite';
import s from './TradesTable.module.scss';
import cn from 'classnames';
import { TiTick, TiDelete } from 'react-icons/all';
export const cellRenderer = (
  props: TradesTableProps,
  selectedTradeKeys: string[],
  navigateToEdit: (key: string) => void,
  toggleTradeSelected?: (key: string) => void
) => (data: TableCellProps): React.ReactNode => {
  const { portfolioCurrencyFormatter, portfolioCurrency } = props;
  if (data.dataKey === 'tradeTime') {
    return moment(data.rowData.tradeTime).format(DATE_FORMAT);
  }

  if (data.dataKey === 'historic') {
    return data.cellData ? (
      <TiTick style={{ margin: 'auto' }} color={'var(--text-success-color)'} />
    ) : (
      <TiDelete style={{ margin: 'auto' }} color={'var(--warm-grey)'} />
    );
  }

  if (data.dataKey === 'historicPnl') {
    return (
      <span>
        {data.cellData &&
          (data.cellData as number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>
    );
  }

  if (data.dataKey === 'netAmountBase') {
    return <ValueCell isSell={data.rowData.operation === 'SELL'} isCurrency={true} value={data.cellData} />;
  }

  if (data.dataKey === 'quantity' || data.dataKey === 'price' || data.dataKey === 'historicPrice') {
    const value = data.cellData as number;

    const assetClass = data.rowData.instrument?.assetClassId;

    const wholes = Math.floor(Math.abs(value));
    const maxDecimals = assetClass === 'CryptoCurrencies' ? 7 : 2;
    // const decimals = `${Math.abs((Math.abs(value) - wholes) * (value < 0 ? -1 : 1))}`.substr(2, maxDecimals);
    const decimals = value?.toString()?.split('.')[1]?.substring(0, maxDecimals);
    const hideDecimals = !decimals || decimals === '00';
    if (!value || value === 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }} />
        </div>
      );
    }

    const content = (
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <div style={{ flex: 1 }} />
        <div>
          {(wholes * (value < 0 ? -1 : 1)).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
        {!hideDecimals && (
          <>
            <div style={{ color: decimals?.length === 0 ? 'transparent' : undefined, textAlign: 'right' }}>.</div>
            <div style={{ textAlign: 'left', opacity: 0.5, color: decimals?.length === 0 ? 'transparent' : undefined }}>
              {decimals || '00'}
            </div>
          </>
        )}
      </div>
    );

    return content;
  }

  if (data.dataKey === 'fxRate') {
    const currencies = [];
    currencies[data.rowData.currencyDetails.hierarchy] = data.rowData.currencyDetails.name;
    currencies[portfolioCurrency.hierarchy] = portfolioCurrency.name;
    const convention = currencies.filter(Boolean).join('-');
    const localToBase =
      data.rowData.currencyDetails.name !== portfolioCurrency.name
        ? `${data.rowData.currencyDetails.name}-${portfolioCurrency.name}`
        : `${data.rowData.currencyDetails.name}`;
    const value = convention === localToBase ? data.cellData : 1 / data.cellData;
    return <div>{data.cellData === 1 ? data.cellData : floatNumberV2(value, 4)}</div>;
  }

  if (data.dataKey === 'assetSubClass') {
    return <div style={{ margin: 'auto' }}>{data.rowData.assetSubClass.assetClassId}</div>;
  }

  if (data.dataKey === 'operation') {
    return <TypeCell type={data.rowData.operation} />;
  }

  if (data.dataKey === 'notes') {
    const tooltipContent = {
      multiplier: data.rowData.multiplier,
      currency: data.rowData.currencyDetails.name,
      notes: data.rowData.notes ?? '',
      baseSettlementCurrency: data.rowData.baseSettlementCurrency,
      settlementOption: data.rowData.settlementOption,
    };
    return (
      <div data-tip={JSON.stringify(tooltipContent)} data-for={'notes'}>
        <NotesCell />
      </div>
    );
  }

  if (data.dataKey.toLowerCase() === 'selected') {
    if (!toggleTradeSelected) {
      return null;
    }
    const isRowEditable = data.rowData.operation !== 'DIVIDEND_RECEIVED' && data.rowData.operation !== 'EARN';
    const speaker = (
      <Popover className={cn('rs-popover', 'narrow')}>
        <Icon onClick={() => navigateToEdit(data.rowData.key)} icon={'pencil'} className={s.pencilAppear} />
      </Popover>
    );

    if (isRowEditable) {
      return (
        <Whisper trigger="hover" enterable={true} delayShow={200} placement={'right'} speaker={speaker}>
          <div>
            <Checkbox
              checked={selectedTradeKeys.includes(data.rowData.key)}
              onChange={() => toggleTradeSelected(data.rowData.key)}
            />
          </div>
        </Whisper>
      );
    }
    return (
      <Checkbox
        checked={selectedTradeKeys.includes(data.rowData.key)}
        onChange={() => toggleTradeSelected(data.rowData.key)}
      />
    );

    // return (
    //   <ActionsCell
    //     trade={data.rowData}
    //     onEditTransaction={onEditTransaction}
    //     onRemoveTransaction={onRemoveTransaction}
    //   />
    // );
  }

  data.cellData = _getCellValue(data, portfolioCurrencyFormatter);
  return <CellRenderer {...data} />;
};

const _getCellValue = (data: TableCellProps, portfolioCurrencyFormatter: ICurrencyFormatter): string | number => {
  const { dataKey, cellData, rowData } = data;
  switch (dataKey.toLowerCase()) {
    case 'amount':
      return cellData ? FormatHelper.formatNumber(Number(cellData)) : 0;
    case 'name':
      return `${rowData.displayId}`; // [${rowData.instrument.code}]`;
    case 'ticker':
      return `${rowData.instrument.code}`;
    case 'custodian':
      return cellData ? (cellData as ICustodian).name : '';
    default:
      return cellData;
  }
};
