import { ILoadingPaginationDataState } from '../../models/redux/ILoadingPaginationDataState';
import { combineReducers } from 'redux';
import { ReduxHelper } from '../utils/ReduxHelper';

import { PeriodHelper } from '../utils/PeriodHelper';
import { IncomeActions, InstrumentsActions, MarketActions } from '../actions';
import { PERIOD_TYPE } from '../constants/constants';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { IFirterableDataState } from '../../models/redux/IFilteredDataState';

type IInstrumentsListState = ILoadingPaginationDataState<IInstrument> & IFirterableDataState<IInstrumentsFilters>;
type IMarketListState = ILoadingPaginationDataState<IMarket> & {
  period: IPeriod;
};
type IIncomeListState = ILoadingPaginationDataState<IIncome> & {
  period: IPeriod;
};

interface IInstrumentInfoState {
  info: ILoadingDataState<IInstrument | null>;
  market: IMarketListState;
  income: IIncomeListState;
}

type IInstrumentUsersState = ILoadingDataState<IUser[]>;

export interface IInstrumentsState {
  list: IInstrumentsListState;
  instrumentInfo: IInstrumentInfoState;
  users: IInstrumentUsersState;
}

const INITIAL_LIST_STATE = ReduxHelper.createInitialPaginationState([], {
  name: 'name',
  direction: 'ASC',
});

const instrumentsDefaultReducer = ReduxHelper.createHttpPaginationRequestReducer<
  ILoadingPaginationDataState<IInstrument>
>(
  INITIAL_LIST_STATE,
  InstrumentsActions.INSTRUMENTS_REQUEST,
  InstrumentsActions.INSTRUMENTS_SUCCESS,
  InstrumentsActions.INSTRUMENTS_ERROR,
  InstrumentsActions.INSTRUMENTS_RESET,
  InstrumentsActions.INSTRUMENTS_CHANGE_SORT
);

const INITIAL_INSTRUMENTS_STATE: IInstrumentsListState = {
  ...INITIAL_LIST_STATE,
  filters: {
    user: null,
    code: null,
    name: null,
    assetClass: null,
    assetSubClass: null,
    currency: null,
    country: null,
    industrySector: null,
    nonMarket: false,
  },
};

const instrumentsListReducers = (
  state: IInstrumentsListState = INITIAL_INSTRUMENTS_STATE,
  action: IActionPayload
): IInstrumentsListState => {
  switch (action.type) {
    case InstrumentsActions.INSTRUMENTS_SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    default:
      return { ...state, ...instrumentsDefaultReducer(state, action) };
  }
};

/* MARKET */

const INITIAL_MARKET_STATE: IMarketListState = {
  ...ReduxHelper.createInitialPaginationState([], { name: 'closeTimestamp', direction: 'DESC' }),
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.MTD), type: PERIOD_TYPE.MTD },
};

const marketDefaultReducer = ReduxHelper.createHttpPaginationRequestReducer<IMarketListState>(
  INITIAL_MARKET_STATE,
  MarketActions.MARKETS_REQUEST,
  MarketActions.MARKETS_SUCCESS,
  MarketActions.MARKETS_ERROR,
  MarketActions.MARKETS_RESET,
  MarketActions.MARKETS_CHANGE_SORT
);

const marketListReducers = (
  state: IMarketListState = INITIAL_MARKET_STATE,
  action: IActionPayload
): IMarketListState => {
  switch (action.type) {
    case MarketActions.MARKETS_CHANGE_PERIOD:
      return { ...state, period: action.payload };
    default:
      return { ...state, ...marketDefaultReducer(state, action) };
  }
};

/* INCOME */

const INITIAL_INCOME_STATE: IIncomeListState = {
  ...ReduxHelper.createInitialPaginationState([], { name: 'businessDate', direction: 'DESC' }),
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.MTD), type: PERIOD_TYPE.MTD },
};

const incomeDefaultReducer = ReduxHelper.createHttpPaginationRequestReducer<IIncomeListState>(
  INITIAL_INCOME_STATE,
  IncomeActions.INCOMES_REQUEST,
  IncomeActions.INCOMES_SUCCESS,
  IncomeActions.INCOMES_ERROR,
  IncomeActions.INCOMES_RESET,
  IncomeActions.INCOMES_CHANGE_SORT
);

const incomeListReducers = (
  state: IIncomeListState = INITIAL_INCOME_STATE,
  action: IActionPayload
): IIncomeListState => {
  switch (action.type) {
    case IncomeActions.INCOMES_CHANGE_PERIOD:
      return { ...state, period: action.payload };
    default:
      return { ...state, ...incomeDefaultReducer(state, action) };
  }
};

const instrumentInfo = ReduxHelper.createHttpRequestReducer<ILoadingDataState<IInstrument | null>>(
  ReduxHelper.createInitialState(null),
  InstrumentsActions.INSTRUMENT_INFO_REQUEST,
  InstrumentsActions.INSTRUMENT_INFO_SUCCESS,
  InstrumentsActions.INSTRUMENT_INFO_ERROR,
  InstrumentsActions.INSTRUMENT_INFO_RESET
);

const instrumentUserListReducer = ReduxHelper.createHttpRequestReducer<ILoadingDataState<IUser[]>>(
  ReduxHelper.createInitialState([]),
  InstrumentsActions.INSTRUMENT_USER_LIST_REQUEST,
  InstrumentsActions.INSTRUMENT_USER_LIST_SUCCESS,
  InstrumentsActions.INSTRUMENT_USER_LIST_ERROR
);

export default combineReducers<IInstrumentsState>({
  list: instrumentsListReducers,
  instrumentInfo: combineReducers<IInstrumentInfoState>({
    info: instrumentInfo,
    market: marketListReducers,
    income: incomeListReducers,
  }),
  users: instrumentUserListReducer,
});
