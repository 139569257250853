import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';

import { IActionPayload } from '../../models/redux/IActionPayload';

export class WorkgroupActions {
  static readonly WORKGROUPS_LIST_REQUEST = 'WORKGROUPS_LIST_REQUEST';
  static readonly WORKGROUPS_LIST_SUCCESS = 'WORKGROUPS_LIST_SUCCESS';
  static readonly WORKGROUPS_LIST_ERROR = 'WORKGROUPS_LIST_ERROR';
  static readonly WORKGROUPS_LIST_RESET = 'WORKGROUPS_LIST_RESET';

  static fetchWorkgroups() {
    return ReduxHelper.createHttpRequestActionBundle<IUser[]>(
      {
        url: getApiUrl('workgroups.list'),
        method: 'get',
      },
      WorkgroupActions.WORKGROUPS_LIST_REQUEST,
      WorkgroupActions.WORKGROUPS_LIST_SUCCESS,
      WorkgroupActions.WORKGROUPS_LIST_ERROR
    );
  }

  static resetUserList(): IActionPayload {
    return { type: WorkgroupActions.WORKGROUPS_LIST_RESET };
  }
}
