import * as React from 'react';
import s from './EmptyPortfolio.module.scss';
import { PortfolioHeader } from '../../components';
import empty from '../../../../static/images/empty.svg';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { PATHS } from '../../../../router/paths';

type OwnProps = RouteComponentProps<{ portfolioId: string }>;

const EmptyPortfolio = ({ match, location }: OwnProps) => {
  return (
    <div>
      <PortfolioHeader showDashboardMenu={false} />
      <div className={s.emptyPortfolioContainer}>
        <img src={empty} />
        <p>
          Your portfolio does not contain any positions yet.
          <br />
          <span>
            <Link
              className={s.blueLink}
              to={PATHS.portfolio.snapshot.positions.add.path.replace(':portfolioId', match.params.portfolioId)}
            >
              &nbsp; Add a position{' '}
            </Link>
            or{' '}
            <Link
              className={s.blueLink}
              to={{ pathname: '/administration/manage-account/OAuthManagement', state: { prevPath: location.pathname } }}
            >
              map a broker
            </Link>{' '}
            to start
          </span>
        </p>
      </div>
    </div>
  );
};

export default withRouter(EmptyPortfolio);
