import React, { FunctionComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../services/store';

import { IPortfolioStructure } from '../../models/IPortfolioStructure';

export interface IWithPortfolioInfoProps {
  portfolioInfo: IPortfolio;
  portfolioStructure: IPortfolioStructure;
}

interface IMapStateToProps {
  portfolioInfo: IPortfolio | null;
  portfolioStructure: IPortfolioStructure;
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    portfolioInfo: state.portfolio.portfolioInfo.data,
    portfolioStructure: state.portfolio.portfolioStructure.data,
  };
};

// TODO: find out correct way to write HOC using typescript without any types
export function withPortfolioInfo<T extends IWithPortfolioInfoProps>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<any> {
  const wrapper: FunctionComponent<T & IMapStateToProps> = (props: T & IMapStateToProps) => {
    const { portfolioInfo, portfolioStructure } = props;
    if (!portfolioInfo) {
      return <Fragment />;
    }
    return <WrappedComponent {...props} />;
  };

  return connect(mapStateToProps)(React.memo(wrapper) as any);
}

export default withPortfolioInfo;
