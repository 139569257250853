import React from 'react';
import cn from 'classnames';
import s from './CloseButton.module.css';
interface IProps {
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
}

const CloseButton = ({ height = 14, width = 14, className, onClick }: IProps) => (
  <div className={cn(className ? className : s.clearButton)} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
      <g fill="none" fillRule="evenodd" stroke="var(--warm-grey)" transform="translate(1 1)">
        <circle cx="12" cy="12" r="12" />
        <path strokeLinecap="round" d="M12 12L8 8l4 4 4-4-4 4zm0 0l4 4-4-4-4 4 4-4z" />
      </g>
    </svg>
  </div>
);

export default CloseButton;
