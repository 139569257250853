export class DOMHelpers {
  /**
   * Returns value of style property
   * @param {HTMLElement} node
   * @param {string} prop
   * @returns {string}
   * @private
   */
  static getStyle(node: HTMLElement, prop: string): string {
    return getComputedStyle(node, null).getPropertyValue(prop);
  }

  /**
   * Method for finding scroll parent
   * @param {HTMLElement} node current element
   * @returns {HTMLElement}
   * @private
   */
  static getScrollParent(node: HTMLElement | null): HTMLElement | undefined {
    if (node == null) {
      return undefined;
    }

    const [overflow, overflowY] = [
      ['auto', 'scroll'].indexOf(this.getStyle(node, 'overflow')),
      ['auto', 'scroll'].indexOf(this.getStyle(node, 'overflow-y')),
    ];

    if (node.localName === 'html' || node.localName === 'body') {
      return node;
    }

    if (node.scrollHeight > node.clientHeight && (overflow !== -1 || overflowY) !== -1) {
      return node;
    } else {
      return this.getScrollParent(node.parentElement);
    }
  }
}
