import { Popover, Whisper } from 'rsuite';
import s from '../SvgScatterChart.module.scss';
import React from 'react';

const Speaker = ({ content, ...props }: any) => {
  const style = {
    background: 'white',
    color: '#a9b2d1',
    padding: 5,
    border: 'none',
  };
  return (
    <Popover {...props} style={{ ...style, ...props.style }}>
      {content}
    </Popover>
  );
};

export const WhisperWrapSimple = ({
  text,
  containerRef,
  children,
}: {
  text: string;
  containerRef: any;
  children: React.ReactElement;
}) => {
  return (
    <Whisper
      container={containerRef.current ?? undefined}
      trigger="hover"
      placement={'right'}
      speaker={
        <Speaker
          content={
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div className={s.popoverRow}>
                <span> {text} </span>
              </div>
            </div>
          }
        />
      }
    >
      {children}
    </Whisper>
  );
};

const WhisperWrap = ({
  point,
  containerRef,
  children,
  currency,
}: {
  point: VolatilityScatterPoint;
  containerRef: any;
  children: React.ReactElement;
  currency: string;
}) => {
  const currentValue =
    isNaN(point.marketValue) || typeof point.marketValue !== 'number'
      ? 'n/a'
      : currency +
        point.marketValue.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
  return (
    <Whisper
      key={point.key}
      container={containerRef.current ?? undefined}
      trigger="hover"
      placement={'right'}
      speaker={
        <Speaker
          content={
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div className={s.popoverRow}>
                <span
                  className={s.marker}
                  style={{ marginLeft: 0, height: 8, width: 8, borderRadius: 9, backgroundColor: point.color }}
                />
                <span style={{ fontWeight: 'bold' }}>{point.label}</span>
              </div>
              <div className={s.popoverRow}>
                <span className={s.marker} />
                <span> Period return: {point.return}%</span>
              </div>
              <div className={s.popoverRow}>
                <span className={s.marker} />
                <span> Volatility: {point.volatility}%</span>
              </div>
              <div className={s.popoverRow}>
                <span className={s.marker} />
                <span> Current value: {currentValue}</span>
              </div>
              {point.activeFor && (
                <div className={s.popoverRow}>
                  <span className={s.marker} />
                  <span> Active for {point.activeFor} months</span>
                </div>
              )}
            </div>
          }
        />
      }
    >
      {children}
    </Whisper>
  );
};

export default WhisperWrap;
