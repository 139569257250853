import * as React from 'react';
import useApi from '../../../../services/hooks/useApi';
import { getCustomApiUrl } from '../../../../services/constants/endpoints';
import { usePortfolio } from '../../../../services/hooks/usePortfolio';
import EnvCircle from '../../../../static/images/icons/esg/e_circled.svg';
import SCircle from '../../../../static/images/icons/esg/s_circled.svg';
import GCircle from '../../../../static/images/icons/esg/g_circled.svg';
import EsgCircle from '../../../../static/images/icons/esg/esgCircle.svg';
import s from './Esg.module.scss';
import LegendCard from './component/LegendCard/LegendCard';
import { ENVIRONMENTAL_COLOR, ESG_COLOR, GOVERNANCE_COLOR, SOCIAL_COLOR } from './utils/esgColors';
import { PortfolioHeader } from '../../components';
import { FaSlidersH, FaChartBar } from 'react-icons/all';
import ProgressBars from './component/ProgressBars/ProgressBars';
import EsgLegend from './component/EsgLegend/EsgLegend';
import { Loader } from '../../../../components';
import { useEffect, useState } from 'react';
import TopEsgPortfolioTable from './component/TopEsgPortfolioTable/TopEsgPortfolioTable';
import TopBottomTables from './component/TopBottomTables/TopBottomTables';
import { Card } from '@iliotech/storybook';

const Esg = () => {
  const portfolio = usePortfolio();
  const [loaded, setLoaded] = useState(false);
  const portfolioId = portfolio.portfolioInfo?.data?.id;
  const [expanded, setExpanded] = useState(false);
  const esg = useApi<EsgPageData>(
    getCustomApiUrl(`portfolio.esg.scores`, [{ idLabel: `:portfolioId`, idValue: portfolioId! }]),
    {
      method: 'GET',
    },
    { enabled: !!portfolioId }
  );

  const hasShort =
    (esg.data?.short.esg.e && esg.data?.short.esg.s && esg.data?.short.esg.g && esg.data?.short.esg.total) !== 0;

  useEffect(() => {
    if (!esg.isLoading && esg.data) {
      grow();
    }
  }, [esg.isLoading]);

  if (esg.isLoading || !esg.data) {
    return <Loader />;
  }

  const grow = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 25);
  };
  // 71 : 86.30 = x : 100
  return (
    <div>
      <PortfolioHeader showDashboardMenu={false} />
      <div className={s.legendRow}>
        <LegendCard
          hasShort={hasShort}
          loaded={loaded}
          percentiles={{ long: esg.data.long.percentiles.total, short: esg.data.short.percentiles.total }}
          onClick={() => setExpanded(!expanded)}
          expanded={expanded}
          icon={EsgCircle}
          text={'ESG'}
          color={ESG_COLOR}
        />
        <LegendCard
          hasShort={hasShort}
          loaded={loaded}
          percentiles={{ long: esg.data.long.percentiles.e, short: esg.data.short.percentiles.e }}
          onClick={() => setExpanded(!expanded)}
          expanded={expanded}
          icon={EnvCircle}
          text={'Environmental'}
          color={ENVIRONMENTAL_COLOR}
        />
        <LegendCard
          hasShort={hasShort}
          loaded={loaded}
          percentiles={{ long: esg.data.long.percentiles.s, short: esg.data.short.percentiles.s }}
          onClick={() => setExpanded(!expanded)}
          expanded={expanded}
          icon={SCircle}
          text={'Social'}
          color={SOCIAL_COLOR}
        />
        <LegendCard
          hasShort={hasShort}
          loaded={loaded}
          percentiles={{ long: esg.data.long.percentiles.g, short: esg.data.short.percentiles.g }}
          onClick={() => setExpanded(!expanded)}
          expanded={expanded}
          icon={GCircle}
          text={'Governance'}
          color={GOVERNANCE_COLOR}
        />
      </div>

      <Card style={{ paddingRight: 30, paddingLeft: 30 }}>
        <div className={s.progressRow}>
          <ProgressBars type={'long'} loaded={loaded} title={'Long Positions'} item={esg.data!.long} />
          <EsgLegend loaded={loaded} />
          {hasShort ? (
            <ProgressBars type={'short'} loaded={loaded} title={'Short Positions'} item={esg.data!.short} />
          ) : null}
        </div>
      </Card>
      <TopEsgPortfolioTable data={esg.data.topTen} />
      <div className={s.separator} style={{ marginTop: 50 }}>
        <FaChartBar size={30} style={{ margin: 3, marginRight: 15 }} />
        <h1>Portfolio ESG Scores</h1>
      </div>
      <div className={s.progressRow}>
        <TopBottomTables portfolioId={portfolioId} type={'Equities'} />
        <div />
        <TopBottomTables portfolioId={portfolioId} type={'Funds'} />
      </div>
      {/*<div className={s.separator} style={{ marginTop: 30 }}>
        <FaChartBar size={30} style={{ margin: 3, marginRight: 15 }} />
        <h1>Overall ESG Scores</h1>
      </div>
        <div className={s.progressRow}>
        <TopBottomTables type={'Equities'} />
        <div />
        <TopBottomTables type={'Funds'} />
        </div>*/}
    </div>
  );
};

export default Esg;
