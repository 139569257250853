import { getApiUrl } from '../../constants/endpoints';
import moment from 'moment';
import useApi from '../useApi';
import { usePortfolio } from '../usePortfolio';
import { usePeriod } from '../../context/PeriodContext';
import { useExplorerContext } from '../../context/ExplorerContext';

export const useAllocationBreakdown = () => {
  const url = getApiUrl('portfolio.profit.allocationBreakdown');
  const { selectedAllocationType } = useExplorerContext();
  const portfolio = usePortfolio();
  const portfolioId = portfolio.portfolioInfo?.data?.id;

  const { period } = usePeriod();

  const from = period.from ? moment(period.from).format('YYYY-MM-DD') : '';
  const to = period.to ? moment(period.to).format('YYYY-MM-DD') : '';

  return useApi<{ allocations: IPortfolioAllocations[] }>(
    url,
    {
      method: 'POST',
      data: {
        portfolioId,
        period: {
          from,
          to,
        },
        // @ts-ignore
        allocationType: selectedAllocationType,
      },
    },
    {
      enabled: !!portfolioId,
    }
  );
};
