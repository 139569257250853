import * as React from 'react';
import s from './PercentileVertical.module.scss';
import { useEffect, useState } from 'react';

interface IProps {
  percent: number;
  color: string;
  type: 'Long' | 'Short' | '';
}

const PercentileVertical = ({ percent, color, type }: IProps) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    animatePercentage();
  }, [currentPercentage]);

  const animatePercentage = () => {
    if (currentPercentage < percent) {
      setTimeout(() => setCurrentPercentage(currentPercentage + 0.1), 125);
    }
  };

  const renderPercentItems = () => {
    const items = [];
    const percentage = (currentPercentage * 10) / 2;
    let i = 0;
    while (i < 5) {
      items.push(
        <div
          key={'vertical-' + i}
          style={{ backgroundColor: i < percentage ? color : '#37383B' }}
          className={s.percentItem}
        />
      );
      i++;
    }

    return items.reverse();
  };

  return (
    <div style={{ padding: 3 }}>
      <span className={s.percentileHeader}>{type && type + ' Positions'}</span>
      <div className={s.percentBarContainer}>
        <div className={s.percentBar}>{renderPercentItems()}</div>
        <span className={s.percentileText}>
          {(percent * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })}th <br /> percentile
        </span>
      </div>
    </div>
  );
};

export default PercentileVertical;
