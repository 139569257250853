import React, { Fragment, useEffect, useState } from 'react';
import { Select } from '../../../../../../../../components/UIWidgets';
import { ASSETS } from '../../../../../../../../services/constants/assetClasses';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';

import s from './Fund.module.scss';
import { getTopPositionsByPnLColumns, getTopPositionsColumns } from './topPositionsColumns';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { GlobalPnl } from '../Shared/GlobalPnl';
import { fundCellRenderer } from './utils/fundCellRenderer';
import { GlobalStructure } from '../Shared/GlobalStructure';
import { GlobalPnlBreakdown } from '../Shared/GlobalPnlBreakdown';
import { GlobalIncome } from '../Shared/GlobalIncome';
import { RouteComponentProps, withRouter } from 'react-router';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import AssetReportWrapper from '../../components/AssetReportWrapper/AssetReportWrapper';
import { PortfolioHeader } from '../../../../../../components';
import { Loader } from '../../../../../../../../components';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';
import { Helmet } from 'react-helmet';

type IProps = RouteComponentProps<{ page: string }>;
const Fund = ({ match }: IProps) => {
  const page = match?.params?.page;
  const {
    hasData,
    isLoading,
    isFetched,
    portfolioCurrencyFormatter,
    dashboardInfo,
    portfolioInfo,
    refetchTopPositions,
  } = usePortfolio(ASSETS.fund);
  const instrumentCurrencies = portfolioInfo.data?.instrumentCurrencies ? portfolioInfo.data?.instrumentCurrencies : [];
  const portfolioId = portfolioInfo.data?.id;
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'All', label: 'All' });
  const { period, updatePeriod } = usePeriod();

  useEffect(() => {
    if (portfolioId) {
      refetchTopPositions?.();
    }
  }, [portfolioId]);

  const handlePeriodChange = (currentPeriod: IPeriod) => {
    updatePeriod(currentPeriod);
  };

  const handleCurrencyFilterChange = (e: string) => {
    setSelectedCurrency({ value: e, label: e });
  };

  const renderCurrencySelector = (): React.ReactNode => {
    const currencyOptions = [{ value: 'All', label: 'All' }];
    instrumentCurrencies.map((el) => currencyOptions.push({ value: el, label: el }));

    return (
      <Fragment>
        <span className={s.currencyFilterLabel}>{page === 'income' && 'Instrument'} Currency:</span>
        <Select
          options={currencyOptions}
          size="small"
          className={s.currencyFilter}
          selectedValue={selectedCurrency}
          onChange={handleCurrencyFilterChange}
        />
      </Fragment>
    );
  };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo.totalPnlBase.value);
  const lifetimeDividends = portfolioCurrencyFormatter(dashboardInfo.past.value);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: total,
      label: 'Current funds value',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoTotalEquity },
    },
    {
      value: lifetimeDividends,
      label: 'Lifetime distributions',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
  ];

  const renderSections = (tabId: string) => {
    switch (tabId) {
      case 'pnl':
        return (
          <GlobalPnl
            period={period}
            handlePeriodChange={handlePeriodChange}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsByPnLColumns}
            cellRenderer={fundCellRenderer}
            assetClass={ASSETS.fund}
          />
        );
      case 'structure':
        return (
          <GlobalStructure
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsColumns}
            cellRenderer={fundCellRenderer}
            assetClass={ASSETS.fund}
          />
        );
      case 'pnlBreakdown':
        return (
          <GlobalPnlBreakdown
            portfolioInfo={portfolioInfo}
            portfolioId={portfolioId!}
            assetClass={ASSETS.fund}
            incomeLabel={'Distributions'}
          />
        );
      case 'income':
        return (
          <GlobalIncome
            portfolioId={portfolioId!}
            renderCurrencySelector={renderCurrencySelector}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCurrency={selectedCurrency}
            profitPeriod={period}
            assetClass={ASSETS.fund}
            chartOneInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartDistributions}
            chartTwoInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartEstimatedDistributionsNextYear}
          />
        );
    }
  };

  return (
    <AssetReportWrapper title="Fund" allLoaded={isFetched} noData={hasData}>
      <Helmet><title>illio - Funds</title></Helmet>
      <PortfolioHeader title="Funds" />
      <div>
        <PortfolioSubHeader stats={stats} title={page} />
        <div className={s.assetsDashboardGrid}>{renderSections(page || 'pnl')}</div>
      </div>
    </AssetReportWrapper>
  );
};

export default withRouter(Fund);
