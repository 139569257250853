import React, { PureComponent } from 'react';
import cn from 'classnames';

import { SliderBlock } from '../../../../components/SliderBlock';
import { SCENARIOS_SETTINGS } from '../../../../services/constants/scenarios';
import { IScenarioBasicConfig } from '../../../../models/IScenarioBasicConfig';
import { SETTINGS_VIEW_TYPE } from '../../../../services/constants/constants';

import s from './BasicScenarioSettings.module.css';

interface IProps {
  settingsValues: IScenarioBasicConfig;
  viewType: SETTINGS_VIEW_TYPE;
  onSettingsChanged: (settings: IScenarioBasicConfig) => void;
}

class BasicScenarioSettings extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    viewType: SETTINGS_VIEW_TYPE.ROW,
  };

  handlerSettingChange = (setting: string, value: number) => {
    this.props.onSettingsChanged({ ...this.props.settingsValues, [setting]: value });
  };

  render() {
    const { viewType, settingsValues } = this.props;

    return (
      <div className={cn(s.scenariosItems, { [s.scenariosItemsColumn]: viewType === SETTINGS_VIEW_TYPE.COLUMN })}>
        {Object.keys(settingsValues)
          .filter((key) => SCENARIOS_SETTINGS[key])
          .map((key: string, idx: number) => {
            const { name, units, value: sliderValue, ...sliderOpt } = SCENARIOS_SETTINGS[key];

            return (
              <div key={idx} className={s.scenariosItem}>
                <SliderBlock
                  label={name}
                  sliderOpt={sliderOpt}
                  value={sliderValue}
                  units={units}
                  onChange={(value) => this.handlerSettingChange(key, value)}
                />
              </div>
            );
          })}
      </div>
    );
  }
}

export default BasicScenarioSettings;
