import ReactEcharts from 'echarts-for-react';
import React, { Fragment, PureComponent } from 'react';
import { ChartLegend } from '../../../../components/ChartLegend';
import { ILegendItemProps } from '../../../../components/ChartLegend/components/LegendItem';

import { BarType, LegendVariant } from '../../../../services/constants/charts';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { FormatHelper, VALUE_TYPE } from '../../../../services/utils/FormatHelper';
import { getProfitChartOptions, getWaterFallChartOptions } from './chartConfigs';
import { LineData } from '../../../../models/LineData';

interface IProps {
  profitChartData: IBarData[];
  isWaterFall: boolean;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  legendVariant: string;
  onItemClick?(data: [string, number, number]): void;
  performanceToggle?: number | 1;
  showLegend?: boolean | true;
}

class ProfitChart extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    isWaterFall: false,
  };

  getLegend(chartData: IBarData[], portfolioCurrencyFormatter: ICurrencyFormatter, variant: string): ILegendItemProps[] {
    let totalValue = 0;
    let legendCompiled = chartData.map((item) => {
      const value: number = item.data.reduce<number>((acum: number, cur: [string, number]) => {
        return acum + cur[1];
      }, 0);

      totalValue += value;

      const splitData =
        this.props.performanceToggle === 0
          ? chartData[0].data.map((el: any[]) => [el[0], el[2]])
          : chartData[0].data.map((el: any[]) => [el[0], el[1]]);

      const totalVal = splitData.reduce<number>((acum: number, cur: any[]) => {
        return acum + cur[1];
      }, 0);

      const toggledValue =
        this.props.performanceToggle === 0
          ? `${value > 0 ? '+' : ''}${FormatHelper.round(totalVal, 100)}%`
          : portfolioCurrencyFormatter(totalVal);

      const legendItemName =
        this.props.performanceToggle === 0 ? 'Portfolio' : this.props.performanceToggle === 1 ? 'P&L' : item.name;

      return {
        name: legendItemName,
        hatching: item.type === BarType.Hatching,
        value: toggledValue,
        color: '#6677cc',
      };
    });

    if (variant === LegendVariant.Totals) {
      legendCompiled = [
        {
          name: 'Total',
          hatching: false,
          value: portfolioCurrencyFormatter(totalValue),
          color: '#6677cc',
        },
      ];
    }

    return legendCompiled;
  }

  render() {
    const { profitChartData, isWaterFall, portfolioCurrencyFormatter, performanceToggle, showLegend } = this.props;
    const legendVariant = this.props.legendVariant; // Display the Total instead of Increase and Decrease values

    const hasLegend = showLegend === undefined ? 'true' : 'false';

    let legend = <span />;

    if (showLegend || showLegend === undefined) {
      legend = <ChartLegend legend={this.getLegend(profitChartData, portfolioCurrencyFormatter, legendVariant)} />;
    }

    const chartOpt = isWaterFall
      ? getWaterFallChartOptions(profitChartData, portfolioCurrencyFormatter)
      : getProfitChartOptions(profitChartData, VALUE_TYPE.MONEY, portfolioCurrencyFormatter, performanceToggle);

    return (
      <div style={{ flex: 1 }}>
        <Fragment>
          {legend}
          <ReactEcharts
            style={{ flex: 1, height: '420px', width: '100%', maxHeight: '350px' }}
            option={{
              ...chartOpt,
            }}
            onEvents={{
              click: (o) => this.props.onItemClick?.(o.data),
            }}
            notMerge={true}
          />
        </Fragment>
      </div>
    );
  }
}

export default ProfitChart;
