import React, { useCallback, useRef, useState, SyntheticEvent, useEffect } from 'react';
import { NestedSet } from './NestedSet';
import styles from './NestedList.module.scss';
import lodashDebounce from 'lodash/debounce';
import ArrowRight from './Arrow';

interface IProps {
  onSelect: (indexes: number[]) => void;
  nestedSet: NestedSet<any>;
}

function defaultNodeRenderer(nestedSet: NestedSet<any>) {
  return (
    <div className={styles.captionGroup}>
      <div className={styles.text}>{nestedSet.label}</div>
      <div className={styles.iconContainer}>
        {nestedSet.group && nestedSet.group.length > 0 && (
          <ArrowRight width="14px" height="13px" className={styles.icon} />
        )}
      </div>
    </div>
  );
}

function renderNode(nestedSet: NestedSet<any>) {
  if (nestedSet.nodeRenderer) {
    return nestedSet.nodeRenderer(nestedSet);
  }
  return defaultNodeRenderer(nestedSet);
}

function NestedList(props: IProps) {
  const [indexOfVisible, setIndexOfVisible] = useState(-1);
  const debouncedMouseMove = useRef(
    lodashDebounce((e: SyntheticEvent<HTMLElement, MouseEvent>, index: number) => {
      setIndexOfVisible(index);
    }, 160)
  );
  const onSelect = useCallback(
    (indexes: number[]) => {
      return props.onSelect([indexOfVisible, ...indexes]);
    },
    [indexOfVisible]
  );

  useEffect(() => {
    return () => {
      debouncedMouseMove.current.cancel();
    };
  });

  return (
    <ul className={styles.list} onMouseLeave={(e) => debouncedMouseMove.current(e, -1)}>
      {!!props.nestedSet.group &&
        props.nestedSet.group.map((childSet: NestedSet<any>, index: number) => (
          <li
            key={childSet.id}
            className={styles.item}
            onMouseMove={(e) => debouncedMouseMove.current(e, index)}
            onClick={(e) => {
              e.stopPropagation();
              props.onSelect([index]);
            }}
          >
            {renderNode(childSet)}
            {indexOfVisible === index && childSet.group && childSet.group.length > 0 && (
              <NestedList nestedSet={childSet} onSelect={onSelect} />
            )}
          </li>
        ))}
    </ul>
  );
}

export default NestedList;
