import React from 'react';
import { DatePicker as KendoDatePicker, DatePickerProps } from '@progress/kendo-react-dateinputs';
import s from './DatePicker.module.scss';
import cn from 'classnames';

interface IProps extends DatePickerProps {
  hideWeekends?: boolean;
  className?: any;
}

const DatePicker = ({ hideWeekends = true, className, ...props }: IProps) => {
  // todo: use customInput everywhere (have problems with onBlur in period component)
  return (
    <KendoDatePicker
      {...props}
      format={'dd-MMM-yyyy'}
      popupSettings={{
        popupClass: cn(hideWeekends && s.hideWeekends),
      }}
      className={cn(s.datePicker, hideWeekends && s.hideWeekends, className)}
    />
  );
};

export default React.memo(DatePicker);
