import { addDays, dateAsString } from './DateHelpers';

/**
 * Returns a map with instrument codes as the key, and an array of prices as the value
 * @param prices
 */
export interface IPriceMap {
  [index: string]: {
    prices: {
      [index: string]: IPrice;
    };
    lastPriceDate: Date;
  };
}

/**
 * Returns a map of prices by instrument, by date, e.g:
 * {
 *   instrument1: {
 *     "2020-05-01": {code: "xxx", price: 100, valuationDate: [[date 2020-05-01]]},
 *     "2020-05-02": {code: "yyy", price: 101, valuationDate: [[date 2020-05-02]]},
 *     "2020-05-03": {code: "zzz", price: 102, valuationDate: [[date 2020-05-03]]},
 *   },
 *   instrument2: {
 *     "2020-05-01": {code: "aaa", price: 200, valuationDate: [[date 2020-05-01]]}
 *   },
 * }
 * @param prices
 */
export const groupPricesByInstrument = (prices: any): IPriceMap => {
  const getLastPriceDate = (trial: string | Date, prev: Date = new Date(0)) => {
    const trialPriceDate = new Date(trial as string);
    if (trialPriceDate > new Date()) {
      return prev;
    } else {
      if (trialPriceDate > prev) {
        return trialPriceDate;
      }
      return prev;
    }
  };

  return prices.sort(sortPricesByDateDesc).reduce((acc: IPriceMap, curr: any) => {
    const identifier = curr.instrumentCode ?? 'UNDEFINED';
    if (typeof acc[identifier] === 'undefined') {
      acc[identifier] = { prices: {}, lastPriceDate: getLastPriceDate(curr.valuationDate as string) };
    }

    // Handle different references that could be given to the code / id by the server
    curr.code = curr.code || curr.id || curr._links?.self.href.split('/price/')?.[1] || '';
    acc[identifier].prices[dateAsString(curr.valuationDate)] = curr;
    acc[identifier].lastPriceDate = getLastPriceDate(curr.valuationDate as string, acc[identifier].lastPriceDate);

    return acc;
  }, {});
};
/**
 * Returns distinct dates between min & max based on the prices provided.
 *
 * @param prices - array of prices
 * @param min - first possible date to include
 * @param max - last possible date to include
 * @param fillWeekdays - if provided, includes all weekdays in the range, regardless of whether a price is included for that date or not
 */
export const getDistinctPriceDates = (
  prices: IPrice[],
  min: Date = new Date(1970, 1, 1),
  max: Date = new Date(2100, 12, 31),
  fillWeekdays: boolean = false
): string[] => {
  const datesObject: { [index: string]: boolean } = {};
  prices.forEach((price) => {
    if (typeof price.valuationDate !== 'undefined') {
      const valuationDate = new Date(price.valuationDate);
      if (valuationDate > min && valuationDate < max) {
        datesObject[dateAsString(valuationDate)] = true;
      }
    }
  });

  if (fillWeekdays) {
    let nextDate = min;
    while (nextDate <= max) {
      if (nextDate.getDay() !== 0 && nextDate.getDay() !== 6) {
        datesObject[dateAsString(nextDate)] = true;
      }
      nextDate = addDays(1, nextDate);
    }
  }

  return Object.keys(datesObject)
    .filter((key) => datesObject.hasOwnProperty(key))
    .sort();
};

/**
 * Array.sort function for prices to order by date
 */
export const sortPricesByDate = (a: IPrice, b: IPrice) =>
  typeof a.valuationDate === 'undefined'
    ? 1
    : typeof b.valuationDate === 'undefined'
    ? -1
    : a.valuationDate > b.valuationDate
    ? 1
    : -1;
export const sortPricesByDateDesc = (a: IPrice, b: IPrice) =>
  typeof a.valuationDate === 'undefined'
    ? -1
    : typeof b.valuationDate === 'undefined'
    ? 1
    : a.valuationDate > b.valuationDate
    ? -1
    : 1;

/**
 * Array.sort function for prices to order by code
 */
export const sortPricesByCode = (a: IPrice, b: IPrice) => (typeof a.code > b.code ? 1 : -1);
