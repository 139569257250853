import React, { FunctionComponent, PropsWithChildren } from 'react';
import cn from 'classnames';

import s from './WidgetsBlock.module.css';

const WidgetsBlock: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({ className, children }) => {
  return (
    <div className={s.wrapper}>
      <div className={cn(s.widgetsBlock, className)}>{children}</div>
    </div>
  );
};

export default React.memo(WidgetsBlock);
