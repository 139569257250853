import React from 'react';
import s from '../VolatilityContributionChart.module.css';

interface IBarArrowsProps {
  bars?: any;
}

export function BarArrows(props: IBarArrowsProps) {
  const esmaColors = ['#3863ff', '#41d9a0', '#00b26f', '#d9bf41', '#fb9900', '#e17300', '#e13800', '#a50009'];

  const splitValue = props.bars.length > 12 ? props.bars.length : 12;

  const markerStartPositions: number[] = [];
  for (let i = 1; i <= splitValue; i++) {
    markerStartPositions.push((100 / splitValue) * i - 100 / (splitValue * 2));
  }

  const markers = esmaColors.map((el: any, index: number) => {
    return (
      <marker key={index} id={'arrowhead' + index} markerWidth="10" markerHeight="8" refX="0" refY="4" orient="auto">
        <polygon points="0 0, 8 4, 0 8" stroke={el} fill={el} />
      </marker>
    );
  });

  const lineSets = props.bars.map((el: any, index: number) => {
    if (el.emptySlot) {
      return '';
    } else {
      const endPosition = Math.round(
        (100 / 7) * (el.riskBand - 1) + 0.142 * (el.riskBandPercentage > 100 ? 100 : el.riskBandPercentage)
      );
      return (
        <g key={index}>
          <line
            x1={markerStartPositions[index] + '%'}
            y1="0"
            x2={markerStartPositions[index] + '%'}
            y2="25%"
            stroke={esmaColors[el.riskBand]}
          />
          <line
            x1={markerStartPositions[index] + '%'}
            y1="25%"
            x2={endPosition + '%'}
            y2="65%"
            stroke={esmaColors[el.riskBand]}
          />
          <line
            x1={endPosition + '%'}
            y1="65%"
            x2={endPosition + '%'}
            y2="90%"
            stroke={esmaColors[el.riskBand]}
            strokeWidth="1"
            markerEnd={'url(#arrowhead' + el.riskBand + ')'}
          />
        </g>
      );
    }
  });

  return (
    <svg width="100%" height="100%">
      <defs>{markers}</defs>
      {lineSets}
    </svg>
  );
}
