import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { LOCAL_STORAGE_KEYS } from '../constants/constants';
import { AccountsActions } from '../actions/AccountsActions';
import { AssetsActions } from '../actions/AssetsActions';
import { CurrencyActions } from '../actions/CurrencyActions';
import { IndustryActions } from '../actions/IndustryActions';
import CountryActions from '../actions/CountryActions';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { AppActions } from '../actions/AppActions';
import { applyTheme, clearTheme } from '../utils/Theme';
import { UserCapabilitiesActions } from '../actions/UserCapabilitiesActions';
import { persistor } from '../../index';
import { IRootState } from '../store';
import { SectorActions } from '../actions/SectorActions';
import { RiskAssetClassesAction } from '../actions/RiskAssetClasses';
import { useQueryClient } from 'react-query';

interface IAccountContext {
  accounts?: IRootState['accounts'];
  userCapabilities?: IRootState['userCapabilities']['data'];
  illioSelectedAccount?: string | null;
  selectIllioAccount(id: string | number | null): void;
  currentTheme?: IThemeMetaData;
  signedIn: boolean;
  signOut(): void;
  signIn(email: string, firstName: string, lastName: string, role: string, status: string, id: string): void;
}

const noop = () => {};

export const AccountContext = React.createContext<IAccountContext>({
  signedIn: false,
  signOut: noop,
  signIn: noop,
  selectIllioAccount: noop,
});

export const useAccount = () => React.useContext(AccountContext);

export const AccountProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { accounts, userCapabilities } = useTypedSelector((state) => ({
    accounts: state.accounts,
    userCapabilities: state.userCapabilities.data,
  }));
  const [referenceDataLoaded, setReferenceDataLoaded] = React.useState(false);

  const queryClient = useQueryClient();

  const [signedIn, setSignedIn] = React.useState(false);
  const [illioSelectedAccount, setIllioSelectedAccount] = React.useState<string | null | undefined>(
    localStorage.getItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT)
  );

  React.useEffect(() => {
    if (signedIn) {
      if (!referenceDataLoaded) {
        loadReferenceData();
        setReferenceDataLoaded(true);
      }
      dispatch(AccountsActions.fetchAccounts());
    }
  }, [signedIn, setReferenceDataLoaded, dispatch]);

  const loadReferenceData = () => {
    dispatch(AssetsActions.fetchAllAssetsClasses());
    dispatch(AssetsActions.fetchAllAssetSubClasses());
    dispatch(CurrencyActions.fetchAllCurrency());
    dispatch(RiskAssetClassesAction.fetchAllRiskAssetClasses());
    dispatch(IndustryActions.fetchAllIndustry());
    dispatch(SectorActions.fetchAllSector());
    dispatch(CountryActions.fetchAllCountries());
    dispatch(AssetsActions.fetchAllAssetLight());
  };

  const signIn = (email: string, firstName: string, lastName: string, role: string, status: string, id: string) => {
    setSignedIn(true);
    try {
      Auth.currentSession().then(async (session) => {
        // ToDo: To Move getting access token to the Reducers level and make calls using Thunk from there
        axios.defaults.timeout = 20000;
        axios.defaults.headers.common.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;

        dispatch(AppActions.login(email, firstName, lastName, role, status, id));
      });
    } catch (err) {
      console.log('there is no user');
    }
  };

  const getTheme = React.useCallback(
    (id: string | number | null) =>
      [...accounts.data].find((a) => String(a.id) === String(id))?.themeMetaData as IThemeMetaData | undefined,
    [accounts]
  );

  const currentTheme = getTheme(illioSelectedAccount || null); // [...accounts.data].find((a) => String(a.id) === String(illioSelectedAccount))?.themeMetaData as IThemeMetaData | undefined;

  React.useEffect(() => {
    if (currentTheme) {
      applyTheme(currentTheme);
    }
  }, [currentTheme]);

  const selectIllioAccount = (id: string | number | null) => {
    if (!id) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT);
      axios.defaults.headers['illio-selected-account'] = undefined;
      clearTheme();
      setIllioSelectedAccount(undefined);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT, String(id));
      axios.defaults.headers['illio-selected-account'] = id;
      const newTheme = getTheme(id);
      if (newTheme) {
        applyTheme(newTheme);
      }
      dispatch(UserCapabilitiesActions.fetchUserCapabilities());
      setIllioSelectedAccount(typeof id === 'number' ? String(id) : id ?? undefined);
    }
  };

  const signOut = () => {
    setSignedIn(false);
    setIllioSelectedAccount(null);
    dispatch({ type: AccountsActions.ACCOUNTS_RESET });
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT);
    clearTheme();
    dispatch(AppActions.logout());
    queryClient.clear();
    return persistor.purge();
  };

  return (
    <AccountContext.Provider
      value={{
        accounts,
        userCapabilities,
        illioSelectedAccount,
        selectIllioAccount,
        currentTheme,
        signedIn,
        signOut,
        signIn,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
