import cn from 'classnames';
import React, { memo } from 'react';
import { IPortfolioErrorsTrade } from '../../../../../../../models/IPortfolioTrade';
import s from '../TempBlotterTable.module.scss';

const StatusCell = ({ errors }: { errors: IPortfolioErrorsTrade[] }) => {
  const hasError = errors && errors.filter((o) => o.type !== 'TRADE' && o.status === 'ERROR').length > 0;
  const hasWarn = errors && errors.filter((o) => o.type !== 'TRADE' && o.status === 'WARN').length > 0;
  const hasInfo = errors && errors.filter((o) => o.type !== 'TRADE' && o.status === 'INFO').length > 0;
  const tradeErrors = errors && errors.filter((o) => o.type === 'TRADE');
  const isTradeError = tradeErrors && tradeErrors.length > 0;

  const validationStyles = {
    [s.error]: hasError || isTradeError,
    [s.warning]: !hasError && hasWarn,
    [s.info]: !hasError && !hasWarn && hasInfo,
  };

  return <i className={cn(s.hintButton, validationStyles)} />;
};

export default memo(StatusCell);
