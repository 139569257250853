import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import memoizeOne from 'memoize-one';
import moment, { Moment } from 'moment';

import { LineData } from '../../../../../../../../models/LineData';
import { ASSET_COLORS, ASSETS, PERIOD_TYPE } from '../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../../../../../services/utils/FormatHelper';
import { IPortfolioPnlByAttribution } from '../../../../../../../../models/IPortfolioPnlByAttribution';
import { DEFAULT_XAXIS_OPTIONS } from '../../../../../../../../services/constants/charts';

const LABEL_WIDTH: number = 30;

enum AxisLabelType {
  DAY,
  MONTH,
  QUARTER,
}

// fixme: put all methods from file in class to avoid global definitions
const labelsMap: Map<number, { value: string; index: number }> = new Map<number, { value: string; index: number }>();

const filterAxisLabels = memoizeOne(
  (data: LineData, labelType: AxisLabelType): LineData => {
    switch (labelType) {
      case AxisLabelType.MONTH:
        return data.filter((item) => moment(item[0]).date() === 1);
      case AxisLabelType.QUARTER:
        return data.filter((item) => {
          const date: Moment = moment(item[0]);
          return date.month() % 3 === 0 && date.date() === 1;
        });
      default:
        return data;
    }
  }
);

const prepareXAisLabels = memoizeOne(
  (data: LineData, period: IPeriod): Map<number, { value: string; index: number }> => {
    labelsMap.clear();
    const labelType: AxisLabelType = getLabelAxisType(data, period);
    filterAxisLabels(data, labelType).forEach((item) =>
      labelsMap.set(moment(item[0]).startOf('day').valueOf(), {
        value: getAxisLabel(item[0], labelType),
        index: labelsMap.size + 1,
      })
    );
    return labelsMap;
  }
);

export function getPnlByAttributionChartOptions(
  portfolio: LineData,
  index: LineData,
  period: IPeriod,
  chartWidth: number,
  portfolioPnlByAttribution: IPortfolioPnlByAttribution,
  portfolioCurrencyFormatter: ICurrencyFormatter,
  indexName: string
): echarts.EChartOption {
  prepareXAisLabels(portfolio, period);

  const seriesTotal: LineData = [];
  const seriesPrice: LineData = [];
  const seriesFx: LineData = [];
  const seriesIncome: LineData = [];
  const seriesRealized: LineData = [];

  portfolioPnlByAttribution.data.map((item) => {
    /*seriesTotal.push([
      moment(moment(item.valuationDate).startOf('month')).format('YYYY-MM-DD'),
      item.price + item.income + item.fx, // + item.realized, // DO NOT REMOVE: Disabled by Business
    ]);
    seriesPrice.push([moment(moment(item.valuationDate).startOf('month')).format('YYYY-MM-DD'), item.price]);
    seriesFx.push([moment(moment(item.valuationDate).startOf('month')).format('YYYY-MM-DD'), item.fx]);
    seriesIncome.push([moment(moment(item.valuationDate).startOf('month')).format('YYYY-MM-DD'), item.income]);
    seriesRealized.push([moment(moment(item.valuationDate).startOf('month')).format('YYYY-MM-DD'), item.realized]);*/

    seriesTotal.push([item.valuationDate, item.price + item.income + item.fx]); // + item.realized]); // DO NOT REMOVE: Disabled by Business

    if (item.price !== 0) {
      seriesPrice.push([item.valuationDate, item.price]);
    }
    if (item.fx !== 0) {
      seriesFx.push([item.valuationDate, item.fx]);
    }
    if (item.income !== 0) {
      seriesIncome.push([item.valuationDate, item.income]);
    }
    if (item.realized !== 0) {
      seriesRealized.push([item.valuationDate, item.realized]);
    }
  });

  const chartOpt = ChartHelper.getTimelineChartOptions({
    tooltipValueType: VALUE_TYPE.MONEY,
    portfolioCurrencyFormatter,
  });
  const mergedOpt: any = {
    grid: {
      y2: 40,
      x2: 82,
      containLabel: false,
      tooltip: {
        formatter: 'a: {a0}',
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return portfolioCurrencyFormatter(value);
        },
      },
    },
    xAxis: {
      ...DEFAULT_XAXIS_OPTIONS,
      axisLine: {
        show: false,
      },
      // offset: 5,
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(0, 0, 0, 0.1)', 'rgba(28, 32, 46, 0.1)'],
        },
      },
      show: true,
      splitLine: {
        show: false,
      },
      type: 'category',
      interval: 3600 * 1000 * 24, // 1 day
      axisTick: {
        show: false,
      },
      axisLabel: {
        //   show: true,
        //  color: '#a9b2d1',
        //  fontSize: 11,
        rotate: 30,
        showMaxLabel: true,
        showMinLabel: true,
        //  interval: 0,
      },
    },
    series: [
      {
        ...ChartHelper.getLineSeriesOptions('#a9b2d1'),
        name: 'Total',
        data: seriesTotal,
      },
      {
        name: 'Price',
        type: 'bar',
        data: seriesPrice,
        stack: 'one',
        barMaxWidth: 24,
        barMinHeight: 4,
        itemStyle: {
          color: '#6677cc',
          barBorderWidth: 1,
        },
      },
      {
        name: 'Fx',
        type: 'bar',
        data: seriesFx,
        stack: 'one',
        barMaxWidth: 24,
        barMinHeight: 4,
        itemStyle: {
          color: '#C2B361',
          barBorderWidth: 1,
        },
      },
      {
        name: 'Income',
        type: 'bar',
        data: seriesIncome,
        stack: 'one',
        barMaxWidth: 24,
        barMinHeight: 4,
        itemStyle: {
          color: '#59abb3',
          barBorderWidth: 1,
        },
      },
      /*{
      // DO NOT REMOVE! It's temporarily Disabled by Business.
        ...ChartHelper.getBarSeriesOptions('#b87f24'),
        name: 'Realized',
        data: seriesRealized,
        stack: 'one',
      },*/
    ],
  };

  return merge({}, chartOpt, mergedOpt, ChartHelper.getTimeAxisLabelFormatter(period)); // getChangedChartOptions(portfolio, period, chartWidth));
}

// ToDo: Move or the below method to the PnlByAssetClassChartContainer.tsx as it's imported from here - still used there for zooming which actually doesn't work ATM. Probably needs to be removed at all.

export function getChangedChartOptions(dataInView: LineData, period: IPeriod, chartWidth: number): echarts.EChartOption {
  const labelType: AxisLabelType = getLabelAxisType(dataInView, period);
  const filteredLabels = filterAxisLabels(dataInView, labelType);
  const canBeShown: number = chartWidth / LABEL_WIDTH;
  let showEveryIndex: number = Math.ceil(FormatHelper.round(filteredLabels.length / canBeShown, 10));

  if (!showEveryIndex) {
    showEveryIndex = 1;
  }

  return {
    xAxis: {
      axisLabel: {
        formatter(value: number): any {
          const formattedVal = moment(value);

          const from = moment(period.from ? period.from.toString() : '');
          const to = moment(period.to ? period.to.toString() : '');

          if (period.type === PERIOD_TYPE.MTD || (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'days') < 31)) {
            return formattedVal.format("DD MMM'YY");
          }

          if (
            period.type === PERIOD_TYPE.YEAR ||
            period.type === PERIOD_TYPE.YTD ||
            (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'months') < 13 && to.diff(from, 'months') >= 1)
          ) {
            return formattedVal.format('DD') === '01' ? formattedVal.format("MMM'YY") : '';
          }

          if (
            period.type === PERIOD_TYPE.INCEPTION ||
            (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'months') > 12)
          ) {
            if (formattedVal.format('DD') !== '01') {
              return '';
            }
            switch (formattedVal.format('M')) {
              case '1':
                return 'Q' + formattedVal.format("Q'YY");
              case '4':
                return 'Q' + formattedVal.format("Q'YY");
              case '7':
                return 'Q' + formattedVal.format("Q'YY");
              case '10':
                return 'Q' + formattedVal.format("Q'YY");
              default:
                return '';
            }
          }
        },
      },
    },
  };
}

function getLabelAxisType(dataInView: LineData, period: IPeriod): AxisLabelType {
  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return AxisLabelType.MONTH;
    case PERIOD_TYPE.INCEPTION:
      return AxisLabelType.QUARTER;
    case PERIOD_TYPE.MANUAL:
      const firstDate = period.from ? period.from : undefined;
      const lastDate = period.to ? period.to : undefined;

      const diffMonths = moment(lastDate).diff(firstDate, 'months');
      if (!diffMonths) {
        return AxisLabelType.DAY;
      }
      if (diffMonths >= 1 && diffMonths <= 12) {
        return AxisLabelType.MONTH;
      }
      return AxisLabelType.QUARTER;
    default:
      return AxisLabelType.DAY;
  }
}

function getAxisLabel(value: string | number, labelType: AxisLabelType): string {
  const date: Moment = moment(value);
  switch (labelType) {
    case AxisLabelType.MONTH:
      return date.format("MMM'YY");
    case AxisLabelType.QUARTER:
      return `Q${date.quarter()}'${date.format('YY')}`;
    default:
      return date.format("DD MMM'YY");
  }
}

function getZoomInterval(period: IPeriod): number {
  const day: number = 3600 * 1000 * 24;
  const week: number = day * 7;
  const month: number = day * 31;
  const quarter: number = day * 92;

  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return month;
    case PERIOD_TYPE.INCEPTION:
      return quarter;
    case PERIOD_TYPE.MANUAL:
      if (!period.to || !period.from) {
        return week;
      }

      const diffMonths: number = moment(period.to).diff(period.from, 'months');
      const diffYears: number = moment(period.to).diff(period.from, 'years');
      if (!diffMonths) {
        return week;
      }
      if (diffYears <= 1) {
        return month;
      }
      return quarter;
    default:
      return week;
  }
}
