import React, { CSSProperties, PureComponent } from 'react';
import cn from 'classnames';
import { Color } from 'csstype';

import { CanvasHelpers } from '../../../services/utils/CanvasHelpers';
import s from '../ChartLegend.module.scss';

export enum LegendType {
  BAR,
  LINE,
}

export interface ILegendItemProps {
  name: string;
  value?: string;
  color: Color;
  hatching?: boolean;
  type?: LegendType;
}

export class LegendItem extends PureComponent<ILegendItemProps> {
  static readonly defaultProps: Partial<ILegendItemProps> = {
    hatching: false,
    type: LegendType.BAR,
  };

  render() {
    const { name, value, type, color, hatching } = this.props;
    const legendStyle: CSSProperties = this._getCssStyles(color, hatching) || {};

    return (
      <div className={s.legendItem}>
        <div
          className={cn(s.legendItemColor, {
            [s.bar]: type === LegendType.BAR,
            [s.line]: type === LegendType.LINE,
          })}
          style={legendStyle}
        />
        <div>
          <span className={s.legendItemName}>{name}</span>
          <span className={s.legendItemValue}>{value}</span>
        </div>
      </div>
    );
  }

  private _getCssStyles(color: Color, hatching?: boolean): CSSProperties | undefined {
    if (hatching) {
      return {
        background: `url(${CanvasHelpers.getHatchingCanvas(color).toDataURL()})`,
      };
    }

    return {
      background: color,
    };
  }
}
