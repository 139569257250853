import { IPortfolioTrade } from '../../../../../../../models/IPortfolioTrade';
import React from 'react';
import cn from 'classnames';
import s from './TradeTypeChanger.module.css';

import { useCapabilities } from '../../../../../../../services/hooks/apiHooks/useCapabilities';
import { TRADE_TYPES } from '../../../../../../../services/constants/constants';
import { Button, Modal } from 'rsuite';

type TradeTypeChangerProps = {
  trade: IPortfolioTrade & { index: number };
  handleEditTransaction: (trade: IPortfolioTrade & { index: number }) => void;
};
export const TradeTypeChanger = ({ trade, handleEditTransaction }: TradeTypeChangerProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const [assetSubclass, setAssetSubclass] = React.useState(
    trade.instrument?.assetSubClass?.name || trade.assetSubClass?.id
  );
  const { capabilityNames } = useCapabilities();
  const types = TRADE_TYPES.filter((type) => capabilityNames.indexOf(type.capability) > -1);

  const validSubClassSelected = types.some(
    (type) =>
      type.defaultAssetSubclass === assetSubclass ||
      type.additionalAssetSubclasses?.some((subclass) => subclass === assetSubclass)
  );

  const edit = (assetSubClassId: string) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const { instrument, ...rest } = trade;

    const transaction = {
      ...rest,
      tradeType: {
        id: assetSubClassId,
      },
    } as any;
    handleEditTransaction(transaction);
    setShowModal(false);
  };

  const instrumentType =
    trade.assetSubClass?.assetClassId === 'Funds'
      ? 'Funds'
      : trade.tradeType?.id || trade.assetSubClass?.name || 'Select';

  return (
    <React.Fragment>
      <Button style={{ marginTop: -5 }} size={'xs'} onClick={() => setShowModal(true)}>
        {TRADE_TYPES.find((item) => item.defaultAssetSubclass === assetSubclass)?.name || instrumentType}
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <h2>Change trade type</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={cn(s.horizontal)}>
            {types.map((type) => (
              <Button
                appearance={type.defaultAssetSubclass === assetSubclass ? 'primary' : 'default'}
                key={type.id}
                onClick={() => setAssetSubclass(type.defaultAssetSubclass)}
              >
                {type.name}
              </Button>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance={'primary'} disabled={!validSubClassSelected} onClick={edit(assetSubclass)}>
            OK
          </Button>
          <Button appearance={'subtle'} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
