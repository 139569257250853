import { Widget } from '../../../../../../../components';
import { Card } from '@iliotech/storybook';
import s from '../shared.module.scss';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import React, { useEffect } from 'react';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import useApi from '../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../../services/utils/PeriodHelper';
import { Loader } from '../../../../../../../../../components';
import { DividendsChart } from '../../Equilty/scenes/PnlBreakdownDetail/components/DividendsChart';
import { AssetClass } from '../../../../../../../../../services/constants/assetClasses';
import { Helmet } from 'react-helmet';

interface IProps {
  portfolioId: string;
  renderCurrencySelector: () => React.ReactNode;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency: { value: string; label: string };
  profitPeriod: IPeriod;
  assetClass: AssetClass;
  chartOneInfo?: string;
  chartTwoInfo?: string;
}

export const getDividends = (assetClass: AssetClass) => {
  switch (assetClass) {
    case 'Funds':
      return 'Distributions';

    case 'FixedIncome':
      return 'Coupons';

    case 'RealAssets':
      return 'Income';

    case 'RealEstate':
      return 'Income';

    default:
      return 'Dividends';
  }
};

const GlobalIncome = ({
  portfolioId,
  profitPeriod,
  selectedCurrency,
  renderCurrencySelector,
  portfolioCurrencyFormatter,
  assetClass,
  chartOneInfo = DASHBOARD_EQUITY_HELP_TEXT.ChartDividends,
  chartTwoInfo = DASHBOARD_EQUITY_HELP_TEXT.ChartEstimatedDividendsNextYear,
}: IProps) => {
  const defaultPeriod = PeriodHelper.preparePeriodForRequest(profitPeriod, 'DEFAULT');

  const dividends = useApi(getApiUrl('equity.dividendsDetailed'), {
    method: 'POST',
    data: {
      portfolioId,
      assetClass,
      period: {
        from: defaultPeriod.from,
        to: defaultPeriod.to,
      },
      currency: selectedCurrency.label === 'All' ? undefined : selectedCurrency.value,
    },
  });

  useEffect(() => {
    dividends.refetch();
  }, [profitPeriod, selectedCurrency]);

  if (dividends.isLoading) {
    return <Loader />;
  }

  return (
    <Widget renderHeaderTools={renderCurrencySelector}>
      <Helmet><title>illio - {assetClass} Income</title></Helmet>

      <Card>
        <Widget className={s.defaultContainer}>
          <Widget title={getDividends(assetClass)} colNum={8} titleInfo={chartOneInfo}>
            <DividendsChart
              chartData={[dividends.data[0]]}
              assetClass={assetClass}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              selectedCurrency={selectedCurrency}
            />
          </Widget>
        </Widget>
      </Card>
      <div style={{ height: 40 }} />
      <Card>
        <Widget className={s.defaultContainer}>
          <Widget title={`Estimated ${getDividends(assetClass)} Next 12 months`} colNum={8} titleInfo={chartTwoInfo}>
            <DividendsChart
              chartData={[dividends.data[1]]}
              assetClass={assetClass}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              selectedCurrency={selectedCurrency}
            />
          </Widget>
        </Widget>
      </Card>
    </Widget>
  );
};

export default GlobalIncome;
