import cn from 'classnames';
import React, { PureComponent, ReactNode } from 'react';
import { ClickOutside } from '../../ClickOutside';
import { Button } from '../Button';
import s from './Info.module.scss';

export type IInfoOptions = IProps;

interface IProps {
  title: string;
  text: string;
  confirmBtnText: string;
  onCancel: () => void;
  info?: boolean;
  children?: ReactNode;
  footerContent?: ReactNode;
  icon?: ReactNode;
}

class Info extends PureComponent<IProps> {
  static readonly defaultProps = {
    confirmBtnText: 'Info',
    title: 'Are you sure?',
    text: '',
    onCancel: () => {},
  };

  render() {
    const { confirmBtnText, title, text, onCancel, info, icon, footerContent } = this.props;

    return (
      <div className={s.confirmWrapper}>
        <ClickOutside clickOutside={onCancel}>
          <div className={s.confirmWindow}>
            <div className={s.confirmWindowTitle}>{title}</div>
            <div className={s.textContainer}>
              {icon}
              <div className={cn('text-xs', s.confirmWindowText)} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            {this.props.children ? <div className={s.childrenContainer}>{this.props.children}</div> : null}
            <footer className={s.confirmWindowFooter}>
              <Button className={cn(s.confirmBtn, { [s.infoBtn]: info })} size="small" onClick={onCancel}>
                {confirmBtnText}
              </Button>
            </footer>
              {footerContent}
          </div>
        </ClickOutside>
      </div>
    );
  }
}

export default React.memo(Info);
