import React from 'react';
import { createSimpleInstrumentForm, ISimpleInstrumentFormValues } from './SimpleInstrumentEntry';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { IRootState } from '../../../../../../../../services/store';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';

type Props = IAssetEntryComponentProps<ISimpleInstrumentFormValues>;
type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

export const createSimpleInstrumentContainer = (
  formName: FORMS_NAME,
  assetSearchType: 'equity' | 'crypto' | 'realAssets' | 'fund' | 'commodity' | 'warrant',
  tradeTypeId: string,
  quantityLabel?: string
) => ({ editedUnconfirmedTrade, ...props }: Props) => {
  const Form = createSimpleInstrumentForm(formName, assetSearchType, tradeTypeId, quantityLabel);

  return (
    <Form
      {...props}
      initialValues={props.editMode ? getInitialValuesFromTrade(editedUnconfirmedTrade) : props.initialValues}
    />
  );
};

const getInitialValuesFromTrade = (t?: UnconfirmedTrade) => {
  if (!t?.trade) {
    return {};
  }
  const { quantity, operation, currency, instrument } = t!.trade;
  const values: Partial<ISimpleInstrumentFormValues> = {
    ...t.trade,
    operation: operation as 'BUY' | 'SELL',
    amount: quantity,
    price: (t.trade.historicPrice as number) || t.trade.price,
  };

  if (instrument?.sourceId) {
    values.instrument = {
      ...instrument,
      value: {
        ...instrument,
        currency,

        sourceId: {
          sourceId: instrument?.sourceId,
          sourceData: instrument?.sourceData,
        },
      },
    };
  }

  return values;
};
