import React, { PureComponent, Fragment } from 'react';
import { IRootState } from '../../services/store';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import s from './ContextHelp.module.scss';
import { connect } from 'react-redux';

interface IProp {
  title: string;
  elementId: string;
}

interface IMapStateToProps {
  contextHelpToggle: boolean;
}

type IProps = IProp & IMapStateToProps;

class ContextHelpIcon extends PureComponent<IProps> {
  render() {
    const { title, elementId, contextHelpToggle } = this.props;
    return (
      <AiOutlineInfoCircle
        size={16}
        visibility={contextHelpToggle ? '' : 'hidden'}
        data-tip={title}
        data-event="click"
        data-for={elementId}
        className={s.helpIcon}
      />
    );
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    contextHelpToggle: !!state?.app?.contextHelp?.contextHelp,
  };
};

export default connect(mapStateToProps)(ContextHelpIcon);
