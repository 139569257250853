import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import moment from 'moment';

// const currentYear = new Date().getFullYear();

const Year: FC<{ disabled?: boolean; tradeTime?: string }> = ({ disabled, tradeTime }) => {
  let currentYear = tradeTime ? moment(tradeTime).toDate().getFullYear() : new Date().getFullYear();
  if (isNaN(currentYear) || !currentYear) {
    currentYear = new Date().getFullYear();
  }

  const years: Array<IOption<number>> = Array.from({ length: 4 }).map((_val, index) => ({
    name: `${currentYear + index}`,
    value: currentYear + index,
    id: currentYear + index,
  }));
  return (
    <FormFieldWrapper label="Year" className={cn(s.nameField, s.fieldsWrapper, s.yearField)} required={true}>
      <Field
        component={renderAutocomplete}
        name="year"
        options={years}
        theme="inverse"
        disableReset={true}
        disabled={disabled}
        inputDisabled={false}
        inputProps={{
          theme: 'inverse',
          className: 'input--small',
          placeholder: 'Select',
        }}
      />
    </FormFieldWrapper>
  );
};

export default Year;
