import { IOrderingTopListState } from '../../../models/ITopListState';
import { IActionPayload } from '../../../models/redux/IActionPayload';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE, TOP_LIST_ORDER } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';

const INITIAL_TOPLIST_STATE: IOrderingTopListState = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  order: { name: TOP_LIST_ORDER.SECURITY, direction: 'DESC' },
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const topListDefault = ReduxHelper.createHttpRequestReducer<IOrderingTopListState>(
  INITIAL_TOPLIST_STATE,
  DashboardActions.TOPLIST_REQUEST,
  DashboardActions.TOPLIST_SUCCESS,
  DashboardActions.TOPLIST_ERROR,
  DashboardActions.TOPLIST_RESET
);

const topListReducer = (state = INITIAL_TOPLIST_STATE, action: IActionPayload) => {
  switch (action.type) {
    case DashboardActions.TOPLIST_CHANGE_ORDER: {
      return {
        ...state,
        order: action.payload,
      };
    }
    case DashboardActions.TOPLIST_CHANGE_PERIOD: {
      return {
        ...state,
        period: action.payload,
      };
    }
    default:
      return topListDefault(state, action);
  }
};

export default topListReducer;
