import { ASSET_LABEL_NAMES } from '../../../../../../../services/constants/constants';

export const mayConcatErrorLabel = (instrument: InValidPosition | ValidPosition) => {
  const errorReason = (instrument as InValidPosition).errorReason;
  if (errorReason) {
    const errorString = ERROR_LABELS[errorReason];
    return instrument.name + errorString;
  }
  return instrument.name;
};

export const mayConcatErrorLabelAsset = (label: string, errorReason?: ErrorReason) => {
  if (errorReason) {
    const errorString = ERROR_LABELS[errorReason];
    return label + errorString;
  }
  return label;
};

const ERROR_LABELS: Record<ErrorReason, string> = {
  PRICE_GAPS: ' [Price gap error]',
  NOT_ENOUGH_DATA: ' [Not enough data]',
};
