import { IPortfolioAssetClass, IPortfolioVolatilityData } from '../../interfaces';
import merge from 'lodash.merge';
import { DEFAULT_TOOLTIP_OPTIONS } from '../../../../../../services/constants/charts';
import { ChartHelper } from '../../../../../../services/utils/ChartHelper';
import { ASSET_COLORS, ASSETS } from '../../../../../../services/constants/assetClasses';

import emptyCircle from '../../../../../../static/images/empty-circle.svg';

export function generateChartOptions(chartData: IPortfolioVolatilityData) {
  const portfolioAssets: IPortfolioAssetClass[] = chartData.portfolio.assets;

  // const dataInView: any[] = [];

  const baseCcySymbol = chartData.portfolio.baseCurrencySymbol;

  const dataForTooltip: any[] = []; // See Dataset Documentation and Tutorial on eChart for more details
  dataForTooltip.push([
    'AssetName',
    'Annualised Return',
    'Volatility',
    'Current Value',
    'Percentage of Gross Exp. (ex cash)',
    'IsShort',
    'AssetClass',
    'AssetPosition',
  ]);

  dataForTooltip.push([
    'Portfolio',
    chartData.portfolio.annualReturn,
    chartData.portfolio.volatility,
    chartData.portfolio.totalMarketValueBase,
    'n/a',
    'Portfolio',
    'Portfolio',
    'Portfolio',
  ]);
  dataForTooltip.push([
    chartData.benchmark.indexName.split('.')[0],
    chartData.benchmark.annualReturn,
    chartData.benchmark.volatility,
    'n/a',
    'n/a',
    'Benchmark',
    'Benchmark',
    'Benchmark',
  ]);

  const positionTypes: string[] = ['Short', 'Long', 'Portfolio', 'Benchmark'];
  const positionSubtypes: string[] = ['ShortBase', 'LongBase', 'ShortNonBase', 'LongNonBase', 'Portfolio', 'Benchmark'];

  const assetTypes: string[] = ['Portfolio', 'Benchmark'];
  portfolioAssets.map((el) => {
    // dataInView.push([el.volatility, el.weight]);
    if (!el.isCash) {
      dataForTooltip.push([
        el.name,
        el.annualReturn,
        el.volatility,
        el.totalMarketValueBase,
        el.weight,
        el.isShort ? 'Short' : 'Long',
        el.assetClass,
        el.isShort && el.inBaseCurrency
          ? 'ShortBase'
          : el.isShort
          ? 'ShortNonBase'
          : el.inBaseCurrency
          ? 'LongBase'
          : 'LongNonBase',
      ]);
    }
    assetTypes.push(el.assetClass);
  });

  const assetClasses = assetTypes.filter((value, index) => assetTypes.indexOf(value) === index);

  const dataForSeries = [...dataForTooltip];
  dataForSeries.shift();

  const chartTooltip = {
    tooltip: {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (value: any) => {
        const seriesMarker: string = ChartHelper.getTooltipLegend(value);
        const genericMarker = `<span style="display:inline-block;margin-left:4px;border-radius:6px;width:6px;height:6px;background-color: #6677cc"></span>`;
        const seriesName = value.value[0];
        const posType = value.value[5] === 'Short' ? '(Short)' : '';
        const currentValue = isNaN(value.value[3])
          ? 'n/a'
          : baseCcySymbol +
            value.value[3].toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
        const percOfGrossExp = isNaN(value.value[4]) ? 'n/a' : value.value[4] + '%';

        const tooltipText = `${seriesMarker}${seriesName} ${posType}<br>
                                ${genericMarker}  1 year Return: ${value.value[1]}%<br/>
                                ${genericMarker}  Volatility: ${value.value[2]}%<br/>
                                ${genericMarker}  Current Value: ${currentValue}<br/>
                                ${genericMarker}  Percentage of Gross Exp. (ex cash): ${percOfGrossExp}`;

        return tooltipText;
      },
      borderWidth: 1,
      extraCssText: 'border-radius:4px;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);}',
      padding: [0, 3, 1, 7],
      trigger: 'item',
    },
  };

  const chartOpt = {
    grid: {
      left: 70,
      top: 50,
      bottom: 30,
      right: 30,
      containLabel: false,
    },
    dataZoom: [
      {
        type: 'inside',
      },
    ],
    legend: {
      show: false,
    },
    dataset: {
      source: dataForTooltip,
    },
    xAxis: {
      name: 'Vol',
      nameGap: 5,
      nameTextStyle: {
        paddingTop: 10,
        height: 30,
        verticalAlign: 'top',
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#747b96',
        },
      },
      axisLabel: {
        show: true,
        color: '#a9b2d1',
        fontSize: 11,
        formatter: (value: any) => {
          return value + '%';
        },
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      name: 'Return',
      nameGap: 5,
      nameTextStyle: {},
      axisLabel: {
        show: true,
        color: '#a9b2d1',
        fontSize: 11,
        formatter: (value: any) => {
          return value + '%';
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#747b96',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#747b96',
          opacity: 0.5,
        },
      },
    },
    visualMap: [
      {
        type: 'piecewise',
        show: false,
        left: null,
        right: 0,
        dimension: 6,
        categories: assetClasses,
        inRange: {
          symbolSize: {
            Portfolio: 26,
            Benchmark: 26,
            '': 14,
          },
          symbol: {
            Portfolio: 'circle',
            Benchmark: 'diamond',
            // '': 'circle',
          },
          color: {
            Long: '#59abb3',
            Short: '#59abb3',
            Portfolio: '#6677cc',
            Benchmark: '#138eb0',
            Alternatives: ASSET_COLORS[ASSETS.alternatives],
            CashAndEquivalents: ASSET_COLORS[ASSETS.cash],
            Commodities: ASSET_COLORS[ASSETS.commodities],
            FixedIncome: ASSET_COLORS[ASSETS.fixedIncome],
            Equities: ASSET_COLORS[ASSETS.equity],
            Funds: ASSET_COLORS[ASSETS.fund],
            RealEstate: ASSET_COLORS[ASSETS.realEstate],
            RealAssets: ASSET_COLORS[ASSETS.realAssets],
            CryptoCurrencies: ASSET_COLORS[ASSETS.cryptoCurrencies],
            SubscriptionWithdrawal: ASSET_COLORS[ASSETS.subscriptionWithdrawal],
            // '': '#6677cc',
          },
          opacity: {
            Long: 1,
            Short: 1,
            Portfolio: 1,
            Benchmark: 1,
          },
        },
      },

      /*

    // DON"T REMOVE THE BELOW OBJECT. IT IS TEMPORARILY DISABLED BY BUSINESS
      {
        show: false,
        left: null,
        right: 0,
        dimension: 7,
        categories: positionSubtypes,
        inRange: {
          symbol: {
            Portfolio: 'circle',
            Benchmark: 'diamond',
            ShortBase: 'path://M 100 100 L 300 100 L 200 300 z',
            LongBase: 'circle',
            ShortNonBase: 'path://M 100 100 L 300 100 L 200 300 z',
            LongNonBase: 'path://d=M150,0C67.29,0,0,67.29,0,150s67.29,150,150,150s150-67.29,150-150S232.71,0,150,0z M150,270c-66.169,0-120-53.832-120-120 S83.831,30,150,30s120,53.832,120,120S216.168,270,150,270z',
            // '': 'circle',
          },
          symbolRotate: 180,
        },
      },
      */
    ],
    series: [
      {
        name: '',
        symbol: 'circle',
        symbolSize: 14,
        type: 'scatter',
        encode: {
          x: 'Volatility',
          y: 'Annualised Return',
          tooltip: [1, 2, 3, 4],
        },
        label: {
          show: false,
          position: 'bottom',
          formatter: '{@AssetName}',
        },
      },
    ],
  };

  return merge({}, chartOpt, chartTooltip);
}
