import React from 'react';
import { PERSONAL_USE_CUSTODIAN } from '../../../../../../../services/constants/constants';
import dateFormat from 'dateformat';
import { usePortfolioInfo } from '../../../../../../../services/hooks/usePortfolioInfo';
import { useCustodiansFilter } from '../../../../../../../services/hooks/useCustodiansFilter';
import { SnapshotActions } from '../../../../../../../services/actions';
import { useDispatch } from 'react-redux';
import { IPortfolioTrade } from '../../../../../../../models/IPortfolioTrade';
import { Utils } from '../../../../../../../services/utils/Utils';
import { NotificationType } from '../../../../../../../models/NotifictionType';
import { AppActions } from '../../../../../../../services/actions/AppActions';
import { useTypedSelector } from '../../../../../../../services/hooks/useTypedSelector';
import { useForm, FormContext } from 'react-hook-form';
import { IUnconnectedAssetEntryProps } from '../../../../../../../types/IUnconnectedAssetEntryProps';

interface IOwnProps {
  ChildComponent:
    | React.FunctionComponent<IUnconnectedAssetEntryProps>
    | React.ComponentClass<IUnconnectedAssetEntryProps>;
  tradeType: TradeType;
  editMode?: boolean;
  checkedOut?: boolean;
}

const savedTradesOrder: { name: string; direction: 'ASC' | 'DESC' } = { name: 'isCorrect', direction: 'ASC' };

export const UnconnectedAssetEntryContainer = ({ ChildComponent, tradeType, editMode, checkedOut }: IOwnProps) => {
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const portfolioInfo = usePortfolioInfo().portfolioInfo.data;
  const { custodianOptions } = useCustodiansFilter();
  const baseCurrencyCode = portfolioInfo?.currency.name;

  const isRealAsset = tradeType === 'RealAssets' || tradeType === 'RealEstate';

  const dispatch = useDispatch();
  const { editedUnconfirmedTrade } = useTypedSelector((state) => ({
    editedUnconfirmedTrade: state.snapshot.snapshotEdit.editedUnconfirmedTrade,
  }));

  const defaults = React.useMemo(() => {
    // * Defaults that will be set for all asset classes
    const baseDefaults = {
      operation: 'BUY',
      feeOperation: 'DEDUCTION',
      settlementOption: 'BASE',
      baseSettlementCurrency: 'BASE',

      custodian: isRealAsset
        ? PERSONAL_USE_CUSTODIAN
        : custodianOptions.find((custodian) => custodian.value.default === true),
      tradeTime: dateFormat(new Date(), 'yyyy-mm-dd'),
      // quantity: 0,
      type: 'CALL' as 'CALL',
      currency: {
        ...portfolioInfo?.currency,
        value: portfolioInfo?.currency?.name
      },
    };

    // * Extend / overwrite with defaults that only apply to trade types
    let tradeTypeDefaults: Partial<any> = {};

    switch (tradeType) {
      case 'CshAdjustments':
        tradeTypeDefaults = {
          adjustmentOperation: 'deduction',
          sellCurrency: {
            id: portfolioInfo?.currency.id,
            label: baseCurrencyCode,
            name: `${baseCurrencyCode} (${portfolioInfo?.currency.symbol ?? ''})`,
            value: baseCurrencyCode,
          },
        };
    }

    let nextDefaults: any = {
      ...baseDefaults,
      ...tradeTypeDefaults,
    };

      // console.log("EUC", editedUnconfirmedTrade)
    if (editedUnconfirmedTrade?.trade) {
      nextDefaults = {
        ...nextDefaults,
        ...editedUnconfirmedTrade.trade,
      };
    }
    return nextDefaults;
  }, [custodianOptions, tradeType, portfolioInfo, baseCurrencyCode, editedUnconfirmedTrade]);

  // const defaults = {}

  const fetchTradeBlotter = () =>
    dispatch(SnapshotActions.fetchPortfolioSavedTrades(portfolioInfo?.id!, savedTradesOrder, 0));
  const resetEditTrade = () => {
    dispatch(SnapshotActions.finishEditUnconfirmedTrade());
    fetchTradeBlotter();
  };
  const updateTempTrade = React.useCallback(
    async (trades: IPortfolioTrade[]) => dispatch(SnapshotActions.updateTempTrade(portfolioInfo?.id!, trades)),
    [portfolioInfo?.id]
  );

  const removeTrade = (trade: any) => {
    Utils.createConfirm({
      text: 'Do you really want to remove this item? This action cannot be undone.',
      confirmBtnText: 'Remove',
      onConfirm: () => {
        dispatch((SnapshotActions.removeTrades(portfolioInfo!.id!, [trade]) as any) as Promise<any>)
          .then(() => {
            resetEditTrade();
          })
          .catch(() => {
            dispatch(AppActions.showNotification({ text: "Trade hasn't been removed", type: NotificationType.ERROR }));
          });
      },
    });
  };

  const saveTrade = (trade: IPortfolioTrade) => dispatch(SnapshotActions.saveTrades(portfolioInfo?.id!, [trade]));

  const onSubmit = () => {};

  return (
    <div>
      <div>
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} >
            <ChildComponent
              initialValues={defaults}
              portfolioInfo={portfolioInfo}
              custodianOptions={isRealAsset ? [...custodianOptions, PERSONAL_USE_CUSTODIAN] : custodianOptions}
              resetEditTrade={resetEditTrade}
              removeTrade={removeTrade}
              editedUnconfirmedTrade={editedUnconfirmedTrade}
              updateTempTrade={updateTempTrade}
              saveTrade={saveTrade as any}
              fetchTradeBlotter={fetchTradeBlotter}
              editMode={editMode}
            />
          </form>
        </FormContext>
      </div>
      {/*<div style={{ display: 'flex' }}>*/}
      {/*  <pre>{JSON.stringify(watch(), null, 2)}</pre>*/}
      {/*  <pre>{JSON.stringify(defaults, null, 2)}</pre>*/}
      {/*</div>*/}
    </div>
  );
};
