import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';

import { IActionPayload } from '../../models/redux/IActionPayload';

export class UsersActions {
  static readonly USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
  static readonly USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
  static readonly USERS_LIST_ERROR = 'USERS_LIST_ERROR';
  static readonly USERS_LIST_RESET = 'USERS_LIST_RESET';

  static readonly USERS_FILTERS_CHANGE = 'USERS_LIST_FILTERS_CHANGE';
  static readonly USERS_SORT_CHANGE = 'USERS_SORT_CHANGE';

  static readonly USER_INFO_REQUEST = 'USER_INFO_REQUEST';
  static readonly USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
  static readonly USER_INFO_ERROR = 'USER_INFO_ERROR';
  static readonly USER_INFO_RESET = 'USER_INFO_RESET';

  static fetchUsers() {
    return ReduxHelper.createHttpRequestActionBundle<IUser[]>(
      {
        url: getApiUrl('account.users.list'),
        method: 'get',
      },
      UsersActions.USERS_LIST_REQUEST,
      UsersActions.USERS_LIST_SUCCESS,
      UsersActions.USERS_LIST_ERROR
    );
  }

  static resetUserList(): IActionPayload {
    return { type: UsersActions.USERS_LIST_RESET };
  }
}
