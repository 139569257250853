import { useTypedSelector } from './useTypedSelector';
import React from 'react';
import { getPortfolioCurrencyFormatter } from '../selectors/portfolio';

export const usePortfolioInfo = () => {
  const { portfolioInfo, portfolioStructure, dashboardInfo, portfolioCurrencyFormatter } = useTypedSelector((state) => ({
    portfolioInfo: state.portfolio.portfolioInfo,
    dashboardInfo: state.dashboard.totalValues.info.data,
    portfolioStructure: state.portfolio.portfolioStructure,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  }));

  return React.useMemo(() => {
    const portfolioAssetsIds: string[] = [];
    let portfolioCurrency = '';
    let portfolioSymbol = '';

    if (portfolioInfo.data) {
      portfolioInfo.data.allocation.AssetClass.map((item: any) => portfolioAssetsIds.push(item.id));
      portfolioCurrency = portfolioInfo.data.currency.name;
      portfolioSymbol = portfolioInfo.data.currency.symbol;
    }

    return {
      portfolioInfo,
      dashboardInfo,
      portfolioStructure,
      portfolioCurrencyFormatter,
      portfolioCurrency,
      portfolioSymbol,
    };
  }, [portfolioInfo, portfolioStructure, dashboardInfo, portfolioCurrencyFormatter]);
};
