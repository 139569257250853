import React from 'react';
import useApi from '../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../services/constants/endpoints';
import SearchBar from './SearchBar';
import { getPortfolioLink } from '../../../PortfoliosManager/utils';
import { useHistory } from 'react-router-dom';
import { Icon } from 'rsuite';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { DashboardActions } from '../../../../services/actions';

interface IProps {
  iconOnly?: boolean;
  onFocus?(): void;
  className?: string;
}

export const SearchContainer = ({ iconOnly, onFocus, className }: IProps) => {
  const [portfoliosList, setPortfoliosList] = React.useState<IPortfolio[]>([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const { data, isFetching, refetch, dataUpdatedAt } = useApi(
    getApiUrl('portfolioManager.list'),
    {
      method: 'POST',
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    if (dataUpdatedAt) {
      setPortfoliosList(data);
    }
  }, [data, dataUpdatedAt]);

  const onComboValueSelected = (item: IComboItemWithCategory) => {
    const portfolioId = item.value;
    const portfolio = Array.isArray(portfoliosList) ? portfoliosList?.find((p) => p.id === portfolioId) : undefined;
    if (portfolio) {
      axios.defaults.headers['illio-selected-workgroup'] = portfolio.workgroup?.id;
      history.push(getPortfolioLink(portfolio));
      dispatch(DashboardActions.resetCustodiansFilter());
    }
  };

  const comboItems = () => {
    const portfolios: IComboItemMulti = {
      title: 'Portfolios',
      items: Array.isArray(portfoliosList)
        ? portfoliosList?.map((p) => ({ value: p.id, label: p.name, category: 'PORTFOLIOS' })) ?? []
        : [],
    };
    return [portfolios];
  };

  const handleFocus = () => {
    onFocus?.();
  };

  return (
    <React.Fragment>
      {iconOnly && <Icon icon={'search'} onClick={handleFocus} style={{ marginLeft: '1.4rem' }} size={'lg'} />}
      <div style={iconOnly ? { height: 0, width: 0, overflow: 'hidden' } : { padding: '12px' }}>
        <SearchBar
          className={className}
          onValueSelected={onComboValueSelected}
          comboItems={comboItems()}
          placeholder={'Search portfolios'}
        />
      </div>
    </React.Fragment>
  );
};
