import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { DashboardActions } from '../../actions';
import { ReduxHelper } from '../../utils/ReduxHelper';

export interface IBreakdownPositionState extends ILoadingDataState {
  data: IBreakdownPosition | null;
}

const INITIAL_STATE: IBreakdownPositionState = ReduxHelper.createInitialState(null);

const breakdownPositionReducer = ReduxHelper.createHttpRequestReducer<ILoadingDataState<IBreakdownPosition | null>>(
  INITIAL_STATE,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_REQUEST,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_SUCCESS,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_ERROR,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_POSITION_RESET
);

export default breakdownPositionReducer;
