import moment from 'moment';
import React, { Fragment, FunctionComponent } from 'react';
import { defaultTableCellRenderer, SortDirectionType, TableCellProps } from 'react-virtualized';
import { TableWrapper } from '../../../../../../../../components/UIWidgets/TableWrapper';

import { DATE_FORMAT } from '../../../../../../../../services/constants/constants';
import { MarketActionsCell } from './components';
import { MARKETS_COLUMNS } from './marketsTableColumns';

interface IProps {
  marketData: IMarket[];
  marketOrder: IOrder;
  onSortChange: (order: IOrder) => void;
  onEditMarket: (market: IMarket) => void;
  onRemoveMarket: (market: IMarket) => void;
}

interface IInstrumentTableCellProps extends TableCellProps {
  rowData: IMarket;
}

const MarketsTable: FunctionComponent<IProps> = ({
  marketData,
  marketOrder,
  onSortChange,
  onEditMarket,
  onRemoveMarket,
}) => {
  const cellRenderer = (data: IInstrumentTableCellProps): React.ReactNode => {
    if (data.dataKey === 'priceAction') {
      return <MarketActionsCell market={data.rowData} onRemove={onRemoveMarket} onEdit={onEditMarket} />;
    }

    if (data.dataKey === 'closeTimestamp') {
      return <Fragment>{moment(data.cellData).format(DATE_FORMAT)}</Fragment>;
    }

    return defaultTableCellRenderer(data);
  };

  const handleSortChange = (sortBy: string, sortDirection: SortDirectionType) => {
    onSortChange({ name: sortBy, direction: sortDirection });
  };

  return (
    <TableWrapper
      columns={MARKETS_COLUMNS}
      tableData={marketData}
      totalCount={marketData.length}
      headerHeight={24}
      rowHeight={24}
      sortBy={marketOrder.name}
      sortDirection={marketOrder.direction}
      cellRenderer={cellRenderer}
      onSortData={handleSortChange}
    />
  );
};

export default React.memo(MarketsTable);
