import cn from 'classnames';
import React, { PureComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Button } from '../../components/UIWidgets';
import { IOption } from '../../components/UIWidgets/Autocomplete';

import { FORMS_NAME } from '../../services/constants/forms';
import { IRootState } from '../../services/store';
import { required, validateCustomeCustodian } from '../../services/utils/FormValidations';
import { IPortfolioSettingsFormData } from './models';
import c from '../PortfolioForms/components/PortfolioFormsCommon.module.scss';
import { PortfolioCustodianListPanel } from '../PortfolioForms/components/PortfolioCustodianListPanel';
import { useModal } from '../../services/context/ModalContext';

interface IContainerProps {
  currencyOptions: Array<IOption<ICurrency>>;
  performanceIndexOptions: Array<IOption<IIndex>>;
  listOfAllCustodians: ICustodian[];
  portfolioType: string | null;
  authInfo: IAuthInfo | null;
  portfolioInfo: IPortfolio | null;
  initialValues: any;
  showNotification: (notification: INotification) => void;
  onSubmit: (formValues: any) => void;
}

interface IDispatchToProps {
  updateType: (type: string) => void;
  updatePerformanceIndex: (index: IIndex) => void;
  updateCustodian: (userPortfolioCustodians: ICustodian | null) => void;
  toggleSystemDividends: (onlyManualDividendsDefault?: boolean) => void;
  updateUserPortfolioCustodians: (userPortfolioCustodians: ICustodian[]) => void;
  updateCustomCustodian: (custodian: string) => void;
  updateRiskAssetViewType: (viewType: RiskAssetViewType) => void;
}

interface IMapStateToProps {
  custodian: any | null;
  userPortfolioCustodians: ICustodian[];
  onlyManualDividendsDefault: boolean;
  customCustodian: string;
  formValues: any;
}

type IProps = IContainerProps &
  InjectedFormProps<IPortfolioSettingsFormData, IContainerProps> &
  IDispatchToProps &
  IMapStateToProps;

const AddCustodianForm = (props: IProps) => {
  useEffect(() => {
    if (props.portfolioInfo?.id) {
      props.updateUserPortfolioCustodians(props.userPortfolioCustodians);
    }
  }, [props.portfolioInfo?.id]);

  const {
    onSubmit,
    invalid,
    submitting,
    performanceIndexOptions,
    listOfAllCustodians,
    portfolioInfo,
    formValues,
    onlyManualDividendsDefault,
  } = props;

  const submit = (...args: any[]) => {
    onSubmit(formValues.values);
  };

  return portfolioInfo ? (
    <div className={c.wrapper}>
      <ReactTooltip type="light" effect="solid" />

      <h1>Add a new custodian</h1>
      <form className={cn(c.formWrapper, c.formWrapper)} onSubmit={() => {}}>
        <div>
          <PortfolioCustodianListPanel
            temp={false}
            required={false}
            custodian={props.custodian}
            onlyManualDividendsDefault={props.onlyManualDividendsDefault}
            portfolioCustodians={props.userPortfolioCustodians}
            customCustodian={props.customCustodian}
            serverCustodians={listOfAllCustodians}
            updateCustomCustodian={props.updateCustomCustodian}
            updateCustodian={props.updateCustodian}
            updatePortfolioCustodians={props.updateUserPortfolioCustodians}
            showNotification={props.showNotification}
          />
        </div>
        <div className={c.row}>
          <Button size="big" className={c.createBtn} onClick={submit} disabled={invalid || submitting}>
            Update
          </Button>
        </div>
      </form>
    </div>
  ) : null;
};

const mapDispatchToProps = {
  updateType: (type: string) => change(FORMS_NAME.portfolio, 'type', type),
  updatePerformanceIndex: (index: IIndex) => change(FORMS_NAME.portfolio, 'performanceIndex', index),
  updateCustodian: (custodian: ICustodian | null) => change(FORMS_NAME.portfolio, 'custodian', custodian),
  updateUserPortfolioCustodians: (userPortfolioCustodians: ICustodian[]) =>
    change(FORMS_NAME.portfolio, 'userPortfolioCustodians', userPortfolioCustodians),
  updateCustomCustodian: (custodian: string) => change(FORMS_NAME.portfolio, 'customCustodian', custodian),
  toggleSystemDividends: (onlyManualDividendsDefault?: boolean) =>
    change(FORMS_NAME.portfolio, 'onlyManualDividendsDefault', !onlyManualDividendsDefault),
  updateRiskAssetViewType: (viewType: RiskAssetViewType) =>
    change(FORMS_NAME.portfolio, 'defaultRiskAssetView', viewType),
};

const mapStateToProps = (state: IRootState) => {
  const selector = formValueSelector(FORMS_NAME.portfolio);
  const userPortfolioCustodians = selector(state, 'userPortfolioCustodians');
  const onlyManualDividendsDefault = selector(state, 'onlyManualDividendsDefault') || false;

  return {
    formValues: state.form[FORMS_NAME.portfolio],
    custodian: selector(state, 'custodian'),
    userPortfolioCustodians,
    onlyManualDividendsDefault,
    customCustodian: selector(state, 'customCustodian'),
  };
};

export default reduxForm<IPortfolioSettingsFormData, IContainerProps>({
  form: FORMS_NAME.portfolio,
  validate: validateCustomeCustodian,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(AddCustodianForm));
