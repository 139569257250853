import React, { Fragment, FunctionComponent, useState } from 'react';
import moment from 'moment';
import { AxiosPromise } from 'axios';

import { IncomeTable, IncomeForm } from './components';
import { Period } from '../../../../../../components/Period';
import { PERIOD_TYPE } from '../../../../../../services/constants/constants';

import { IOptionType } from '../../../../../../components/UIWidgets/Select/Select';
import { IIncomeForm } from './components/IncomeForm/IIncomeForm';
import { IOption } from '../../../../../../components/UIWidgets/Autocomplete';

import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';

import s from './InstrumentIncome.module.css';

const CHARTS_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.MTD, label: 'MTD' },
  { value: PERIOD_TYPE.YTD, label: 'YTD' },
  { value: PERIOD_TYPE.YEAR, label: '1 Year' },
  { value: PERIOD_TYPE.MANUAL, label: 'Date range' },
];

interface IProps {
  instrumentId: string;
  instrumentCurrency: number;
  incomeData: IIncome[];
  incomeDataIsFetched: boolean;
  incomePeriod: IPeriod;
  incomeOrder: IOrder;
  currencyOptions: Array<IOption<ICurrency>>;
  onPeriodChange: (period: IPeriod) => void;
  onIncomeSave: (income: IIncome) => AxiosPromise<IIncome | void>;
  onIncomeUpdate: (income: IIncome) => AxiosPromise<IIncome>;
  onIncomeDelete: (income: IIncome) => void;
  onChangeMarketSort: (order: IOrder) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

const InstrumentIncome: FunctionComponent<IProps> = ({
  instrumentId,
  instrumentCurrency,
  incomeData,
  incomeDataIsFetched,
  incomePeriod,
  incomeOrder,
  currencyOptions,
  onIncomeDelete,
  onIncomeSave,
  onIncomeUpdate,
  onPeriodChange,
  onChangeMarketSort,
  portfolioCurrencyFormatter,
}) => {
  const [selectedIncome, setSelectedIncome] = useState<IIncome | null>(null);

  const handleSaveIncome = (formData: IIncomeForm) => {
    if (selectedIncome) {
      onIncomeUpdate({
        ...selectedIncome,
        businessDate: moment(formData.date).format('YYYY-MM-DD'),
        amount: parseFloat(formData.amount.toString()),
        currencyId: formData.currency ? formData.currency.id : null,
      }).then(() => {
        setSelectedIncome(null);
      });
    } else {
      onIncomeSave({
        instrumentId,
        businessDate: moment(formData.date).format('YYYY-MM-DD'),
        amount: parseFloat(formData.amount.toString()),
        currencyId: formData.currency ? formData.currency.id : null,
      });
    }
  };

  return (
    <Fragment>
      <h3 className={s.marketBlockTitle}>Income dynamic</h3>
      <div className={s.incomeForm}>
        <IncomeForm
          instrumentCurrency={instrumentCurrency}
          income={selectedIncome}
          currencyOptions={currencyOptions}
          onSubmit={handleSaveIncome}
        />
      </div>

      <div className={s.historyTitle}>
        <h3>Income history</h3>
        <Period options={CHARTS_PERIODS} onPeriodChange={onPeriodChange} selectedPeriod={incomePeriod} />
      </div>
      {incomeDataIsFetched && !incomeData.length && <>No data for current period</>}
      <div className={s.incomeTable}>
        {incomeData.length > 0 && (
          <IncomeTable
            incomeData={incomeData}
            incomeOrder={incomeOrder}
            onSortChange={onChangeMarketSort}
            onEditIncome={(data) => {
              setSelectedIncome(data);
            }}
            onRemoveIncome={onIncomeDelete}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          />
        )}
      </div>
    </Fragment>
  );
};

export default React.memo(InstrumentIncome);
