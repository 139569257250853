import React from 'react';
import { ToggableChartLegend } from '../../../../../../../components/ToggableChartLegend';
import useToggableLegend from '../../../../../../../services/hooks/useToggableLegend';
import { KendoLineChart } from './KendoLineChart/KendoLineChart';
import { ASSET_COLORS, ASSETS } from '../../../../../../../services/constants/assetClasses';
interface ISeriesItem {
  name: string;
  data: [string, number][];
}

interface IHasData {
  data: [string, number][];
}

function getLastValue<Type extends IHasData>(current: Type): number {
  try {
    if(!current?.data || !Array.isArray(current.data)){
      return 0;
    }
    const [_, lastValue] = [...current.data].pop()!;
    return lastValue;

  } catch(e){
    console.log("Error calling getLastValue", {e, current});
    return 0;
  }
}

export interface IChartWithLegendProps {
  allSeries: ISeriesItem[];
  allDates: Date[];
  expanded?: boolean;
  selectedCurrency: string;
  portfolioCurrencyFormatter: (val: number | undefined) => string;
  transitions: boolean;
}

const colorsf = [
  ASSET_COLORS[ASSETS.cash],
  ASSET_COLORS[ASSETS.alternatives],
  ASSET_COLORS[ASSETS.futures],
  ASSET_COLORS[ASSETS.mixed],
  ASSET_COLORS[ASSETS.commodities],
  ASSET_COLORS[ASSETS.fx],
  ASSET_COLORS[ASSETS.subscriptionWithdrawal],
  ASSET_COLORS[ASSETS.realAssets],
];

export const ChartWithLegend = ({
  expanded,
  allSeries,
  allDates,
  selectedCurrency,
  portfolioCurrencyFormatter,
  transitions,
}: IChartWithLegendProps) => {
  const chartData = React.useMemo(() => {
    return allSeries.map((seriesItem, idx) => {
      return {
        ...seriesItem,
        id: seriesItem.name,
      };
    });
  }, [allSeries]);

  const { onLegendItemClick, legend, toggledData } = useToggableLegend({
    selectedCurrency,
    portfolioCurrencyFormatter,
    chartData,
    customBarDataTotal: getLastValue,
  });
  const categories = React.useMemo(() => allDates, [allDates]);
  const chartValues = React.useMemo(() => {
    return toggledData.map((item, idx) => {
      return {
        ...item,
        values: item.data.map(([date, value]) => ({ date, value })),
        color: legend.find((p) => p.label === item.name)?.color || colorsf[idx],
      };
    });
  }, [toggledData, legend]);

  return (
    <div>
      <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
      <div>
        <KendoLineChart
          transitions={false}
          expanded={expanded || false}
          series={chartValues}
          categories={categories}
          name={'Test Line Chart'}
          currencyFormatter={portfolioCurrencyFormatter}
          nameHidden={true}
          height={expanded ? 500 : 270}
        />
      </div>
    </div>
  );
};
