type IComparatorResult = 1 | 0 | -1;

function sortComparator<T>(selector: (t: T) => number | string) {
  return (a: T, b: T): IComparatorResult => {
    if (selector(a) < selector(b)) {
      return -1;
    }
    if (selector(a) > selector(b)) {
      return 1;
    }
    return 0;
  };
}

export default sortComparator;
