import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import { FormContext, FormContextValues, useForm } from 'react-hook-form';
import Combo from '../Combo/Combo';
import s from './DynamicForm.module.css';

interface IProps {
  groups: FormGroup[];
  submitButton?: boolean;
  defaultValues?: { [index: string]: string | number | undefined | Date };
  formContext?: FormContextValues;

  onSubmit(data: any): void;
}

const DynamicForm: FunctionComponent<IProps> = ({
  onSubmit,
  groups,
  submitButton = true,
  defaultValues = {},
  formContext,
  children,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const methods = formContext || useForm();

  const errors = methods.errors || {};
  const { register } = methods;

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={cn(s.gridSurround)}>
          {groups.map((group, groupKey) => {
            const groupAfter = group.items[group.items.length - 1].after ?? 0;
            const groupCols = group.items.reduce((acc, curr) => acc + (curr.cols ?? 0) + (curr.after ?? 0), 0);

            return (
              <div
                key={groupKey}
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${groupCols}, 1fr)`,
                  gridColumn: `span ${groupCols}`,
                  gridGap: `0rem 0.5rem`,
                }}
              >
                {group.label.length > 0 && <Label cols={groupCols - groupAfter} label={group.label} />}
                {group.label.length > 0 && groupAfter > 0 && <span style={{ gridColumn: `span ${groupAfter}` }} />}
                {group.items.map((item) => {
                  const { label, cols, after } = item;
                  switch (item.itemType) {
                    case 'number':
                    case 'text': {
                      const { name, placeholder, required = false, readOnly, validate } = item;
                      let maxLength: number | undefined;
                      if (item.itemType === 'text') {
                        maxLength = item.maxLength;
                      }
                      return (
                        <InputWrapper key={name} {...{ label, error: errors[name], cols, after, required }}>
                          <input
                            ref={register({ required, maxLength, validate })}
                            data-testid={`${name}Input`}
                            type={item.itemType}
                            name={name}
                            placeholder={placeholder}
                            defaultValue={defaultValues[name] ? String(defaultValues[name]) : undefined}
                            className={cn(s.input)}
                            readOnly={readOnly}
                          />
                          {/* TODO: Add close button; make input controlled component*/}
                          {/*<CloseButton/>*/}
                        </InputWrapper>
                      );
                    }
                    case 'combo': {
                      const { name, placeholder, comboItems, onSelect, required } = item;
                      return (
                        <InputWrapper key={name} {...{ label, error: errors[name], cols, after, required }}>
                          <Combo
                            name={name}
                            placeholder={placeholder}
                            items={comboItems}
                            defaultValue={defaultValues ? (defaultValues[name] as string) : undefined}
                            onSelect={onSelect}
                            validationOptions={{ required }}
                          />
                        </InputWrapper>
                      );
                    }

                    case 'date': {
                      const { name, placeholder, onSelect, value, required = false } = item;
                      return (
                        <InputWrapper key={name} {...{ label, error: errors[name], cols, after, required }}>
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            placeholderText={placeholder}
                            disabled={false}
                            customInput={<DateInput />}
                            autoComplete="off"
                            calendarClassName="wm-datepicker"
                            selected={value}
                            onChange={(d) => {
                              if (d !== null) {
                                onSelect(d);
                              }
                            }}
                            disabledKeyboardNavigation={false}
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '0, 10px',
                              },
                            }}
                          />
                          <span>val: {JSON.stringify(value)}</span>
                        </InputWrapper>
                      );
                    }
                    case 'hidden': {
                      const { name } = item;
                      return (
                        <input
                          key={name}
                          ref={register}
                          data-testid={`${name}Input`}
                          type={'hidden'}
                          name={name}
                          value={defaultValues[name] ? String(defaultValues[name]) : undefined}
                          defaultValue={defaultValues[name] ? String(defaultValues[name]) : undefined}
                        />
                      );
                    }
                    default:
                      return <Label key={label} cols={cols} label={label} after={after} />;
                  }
                })}
              </div>
            );
          })}
        </div>
        {children && (
          <div>
            <br />
            {children}
            <br />
          </div>
        )}
        {submitButton && <input type="submit" className={cn(s.submit)} />}
      </form>
    </FormContext>
  );
};

interface IInputWrapper {
  label: string;
  error?: object | boolean;
  required?: boolean;
  children: React.ReactNode;
  cols?: number;
  after?: number;
}

const InputWrapper = ({ label, error, required, children, cols = 3, after = 0 }: IInputWrapper) => {
  return (
    <React.Fragment>
      <div
        style={{ gridColumn: `span ${cols}`, position: 'relative' }}
        className={cn(s.inputSurround, error && s.inputSurroundError)}
      >
        {children}
        <span className={cn(s.inputLabel)}>
          {label}
          {required && '*'}
        </span>
        {/*{error && <div className={s.error}>error: {console.log(error)} </div>}*/}
      </div>
      {after > 0 && <div style={{ gridColumn: `span ${after}` }} />}
    </React.Fragment>
  );
};

const Label = ({ label, cols }: ILabel) => (
  <div className={cn(s.label)} style={{ gridColumn: `span ${cols ?? 0}` }}>
    {label}
  </div>
);

const DateInput = ({
  value,
  onClick,
  placeholder = 'Select date',
}: {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
}) => <input className={cn('input')} value={value} onClick={onClick} placeholder={placeholder} />;

export default DynamicForm;
