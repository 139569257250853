import Downshift from 'downshift';
import cn from 'classnames';
import s from './ComboList.module.scss';
import React, { useState, Fragment } from 'react';
import CloseButton from '../Combo/Widgets/CloseButton';
import { capitaliseFirstLetter } from '../../services/utils/StringHelpers';

interface IProps {
  items: IComboItemMulti[];
  placeholder: string;
  name: string;
  itemToString?: <K extends IComboItem>(item?: K | null) => string;
  onValueSelected?: (selectedItem: IComboItemWithCategory) => void;
  className?: string;
  clearOnSelected?: boolean;
}

const defaultItemToString = (item?: IComboItem | null) =>
  item?.label || (typeof item?.value === 'undefined' ? '' : String(item?.value));

const Combo = ({
  items,
  placeholder,
  className,
  name,
  itemToString = defaultItemToString,
  onValueSelected,
  clearOnSelected,
}: IProps) => {
  const [filter, setFilter] = useState('');

  return (
    <Downshift
      onChange={(selection) => {}}
      onSelect={() => setFilter('')}
      onInputValueChange={(inputValue) => {
        setFilter(inputValue);
        // setSelectedValue(selectedItem?.value ?? inputValue);
      }}
      itemToString={itemToString}
    >
      {(downShiftMethods) => {
        const clear = () => {
          downShiftMethods.clearSelection();
          setFilter('');
        };

        return (
          <div className={s.comboSurround}>
            <ComboBox
              {...{
                downShiftMethods,
                setFilter,
                placeholder,
                name,
                items,
                filter,
                clear,
                clearOnSelected,
                onValueSelected,
                className,
              }}
            />
            <input
              type={'text'}
              onChange={() => {}} // Stop TS complaining about being controlled
              name={name}
              data-testid={name}
              style={{ display: 'none' }}
            />
          </div>
        );
      }}
    </Downshift>
  );
};

const ComboBox = ({
  downShiftMethods,
  setFilter,
  placeholder,
  clear,
  name,
  items,
  filter,
  comboItemSurroundStyle,
  onValueSelected,
  className,
  clearOnSelected,
}: any) => {
  const { getInputProps, isOpen, getItemProps, highlightedIndex, selectedItem, openMenu } = downShiftMethods;

  React.useEffect(() => {
    if (selectedItem && typeof onValueSelected === 'function') {
      onValueSelected(selectedItem);
      if (clearOnSelected) {
        clear?.();
      }
    }
  }, [selectedItem]);

  const inputProps = getInputProps({
    onFocus: () => {
      setFilter('');
      clear?.();
      openMenu();
    },
    onClick: () => {
      setFilter('');
      openMenu();
    },
  });

  const filterFunction = React.useCallback(
    (item: IComboItem) =>
      String(item.value).toUpperCase().includes(filter.toUpperCase()) ||
      item.label?.toUpperCase().includes(filter.toUpperCase()),
    [filter]
  );

  // const onMouseEnter = (listIndex: number, itemIndex: number) => {
  //   // setListHovered(listIndex);
  //   setItemHovered(itemIndex);
  // };

  const preppedItems = React.useMemo(() => {
    const arr: IComboItemWithCategory[] = [];
    const categoryCounts: Record<string, number> = {};
    items
      // .filter((combo: IComboItemMulti) => combo.items.find(filterFunction))
      .forEach((combo: IComboItemMulti) => {
        combo.items.forEach((item) => {
          if ((categoryCounts[item.category] ?? 0) > 4) {
            return;
          }
          if (filterFunction(item)) {
            categoryCounts[item.category] = (categoryCounts[item.category] ?? 0) + 1;
            arr.push(item);
          }
        });
      });
    return arr;
  }, [items, filterFunction]);

  return (
    <div className={cn(s.comboContainer)}>
      <input
        type={'text'}
        className={cn(s.comboInput, isOpen ? s.open : null, className, 'bg')}
        data-testid={`${name}Input`}
        {...inputProps}
        placeholder={placeholder}
      />

      {!!inputProps.value && <CloseButton onClick={clear} color={'white'} />}
      {isOpen && (
        <div className={cn(s.comboItemSurround, 'shadow')} style={comboItemSurroundStyle}>
          {preppedItems.map((item, index) => {
            const itemProps = getItemProps({
              key: item.value,
              className: cn(s.comboItem),
              item,
              style: {
                backgroundColor: index === highlightedIndex ? 'var(--dark-blue)' : '',
                fontWeight: selectedItem === item ? 'bold' : 'normal',
              },
            });

            return (
              <React.Fragment key={item.value}>
                {(index === 0 || item.category !== preppedItems[index - 1]?.category) && (
                  <div className={s.comboTitle}>{capitaliseFirstLetter(item.category)}</div>
                )}

                <li
                  // onMouseEnter={() => {
                  //   onMouseEnter(0, index);
                  // }}
                  {...itemProps}
                >
                  {item.label}
                </li>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Combo;
