import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';
import { IActionPayload } from '../../models/redux/IActionPayload';

class CustodianActions {
  static CUSTODIAN_REQUEST = 'CUSTODIAN_REQUEST';
  static CUSTODIAN_SUCCESS = 'CUSTODIAN_SUCCESS';
  static CUSTODIAN_ERROR = 'CUSTODIAN_ERROR';

  static fetchCustodiansList(hidden = false) {
    return ReduxHelper.createHttpRequestActionBundle<ICustodian[]>(
      {
        method: 'post',
        url: getApiUrl('custodian.all'),
        params: {
          hidden,
        },
      },
      CustodianActions.CUSTODIAN_REQUEST,
      CustodianActions.CUSTODIAN_SUCCESS,
      CustodianActions.CUSTODIAN_ERROR
    );
  }
}

export default CustodianActions;
