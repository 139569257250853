import React, { Fragment, PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { generateChartOptions } from './HistoricDrawdownChartConfig';
import s from './HistoricDrawdownChart.module.css';

interface IProps {
  chartData: any;
  chartVariant?: string;
}

class HistoricDrawdownChart extends PureComponent<IProps> {
  render() {
    const drawdownData = this.props.chartData.portfolio.drawdownData;
    const rangeType = this.props.chartVariant || 'DAILY';

    const chartOpt = generateChartOptions(drawdownData, rangeType);

    return (
      <Fragment>
        <ReactEcharts
          style={{ flex: 1, height: '590px', width: '100%', maxHeight: '600px' }}
          option={chartOpt}
          notMerge={true}
        />
      </Fragment>
    );
  }
}

export default HistoricDrawdownChart;
