import React, { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../services/store';

export interface IWithBasesInfoProps {
  assetsClasses: IAssetClass[];
}

interface IMapStateToProps {
  assetsClasses: IAssetClass[];
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    assetsClasses: state.assetsClasses.data,
  };
};

export function withBasesInfo<T extends IWithBasesInfoProps>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<any> {
  const wrapper: FunctionComponent<T & IMapStateToProps> = (props: T & IMapStateToProps) => {
    const { assetsClasses } = props;
    if (!assetsClasses) {
      return <Fragment />;
    }
    return <WrappedComponent {...props} />;
  };

  return connect(mapStateToProps)(React.memo(wrapper) as any);
}

export default withBasesInfo;
