import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { combineReducers } from 'redux';

import { ReduxHelper } from '../utils/ReduxHelper';

import { UsersActions } from '../actions/UsersActions';
import { IActionPayload } from '../../models/redux/IActionPayload';
import { ISortableDataState } from '../../models/redux/ISortableDataState';
import { IUsersFilter } from '../../models/IUsersFilters';
import { IFirterableDataState } from '../../models/redux/IFilteredDataState';
import { WorkgroupActions } from '../actions/WorkgroupActions';

type IUsersListState = ILoadingDataState<IAccountUser[]> & ISortableDataState & IFirterableDataState<IUsersFilter>;
type IUserInfoState = ILoadingDataState<IAccountUser | null>;
type IWorkgroupsState = ILoadingDataState<IWorkgroup[]>;

export interface IUsersState {
  usersList: IUsersListState;
  usersInfo: IUserInfoState;
  workgroups: IWorkgroupsState;
}

const INITIAL_USER_LIST: IUsersListState = {
  ...ReduxHelper.createInitialState([]),
  sort: {
    name: 'registeredAt',
    direction: 'ASC',
  },
  filters: {},
};

const loadingReducer = ReduxHelper.createHttpRequestReducer<IUsersListState>(
  INITIAL_USER_LIST,
  UsersActions.USERS_LIST_REQUEST,
  UsersActions.USERS_LIST_SUCCESS,
  UsersActions.USERS_LIST_ERROR,
  UsersActions.USERS_LIST_RESET
);

const usersList = (state: IUsersListState = INITIAL_USER_LIST, action: IActionPayload): IUsersListState => {
  switch (action.type) {
    case UsersActions.USERS_SORT_CHANGE:
      return { ...state, sort: { ...state.sort, ...action.payload } };
    case UsersActions.USERS_FILTERS_CHANGE:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    default:
      return loadingReducer(state, action);
  }
};

const usersInfo = ReduxHelper.createHttpRequestReducer<IUserInfoState>(
  ReduxHelper.createInitialState(null),
  UsersActions.USER_INFO_REQUEST,
  UsersActions.USER_INFO_SUCCESS,
  UsersActions.USER_INFO_ERROR,
  UsersActions.USER_INFO_RESET
);

const workgroups = ReduxHelper.createHttpRequestReducer<IWorkgroupsState>(
  ReduxHelper.createInitialState([]),
  WorkgroupActions.WORKGROUPS_LIST_REQUEST,
  WorkgroupActions.WORKGROUPS_LIST_SUCCESS,
  WorkgroupActions.WORKGROUPS_LIST_ERROR,
  WorkgroupActions.WORKGROUPS_LIST_RESET
);

export default combineReducers<IUsersState>({
  usersList,
  usersInfo,
  workgroups,
});
