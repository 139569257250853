import React, { Fragment, PureComponent } from 'react';
import { PageHeader } from '../../../../components/PageHeader';
import { Link } from 'react-router-dom';
import { defaultTableCellRenderer, SortDirectionType, TableCellProps } from 'react-virtualized';

import { Autocomplete, Button, FormFieldWrapper, Input, TableWrapper, TabsV2 } from '../../../../components/UIWidgets';
import { InstrumentActionsCell } from './components';

import { getInstrumentColumns } from './instrumentsTableColumns';
import { PATHS } from '../../../../router/paths';

import s from './InstrumentsList.module.scss';

import { IOption } from '../../../../components/UIWidgets/Autocomplete';

import { UserRole } from '../../../../services/constants/constants';

import { NestedSet } from '../../../../components/NestedList/NestedSet';
import { NestedSelect } from '../../../../components';
import { ISelectHandlerCallbackOptions, ISelectHandlerProps } from '../../../../components/NestedSelect/NestedSelect';

interface IInstrumentTableCellProps extends TableCellProps {
  rowData: IInstrument;
}

interface IProps {
  assetClasses: IAssetClass[];
  instrumentsList: IInstrument[];
  instrumentsSort: IOrder;
  instrumentsFilters: IInstrumentsFilters;
  onFiltersChange: (filters: IInstrumentsFilters) => void;
  onSortChange: (order: IOrder) => void;
  onRemoveInstrument: (instrument: IInstrument) => void;
  setUserOption: (user: IOption<IUser> | null) => void;
  userListOptions: Array<IOption<IUser>>;
  searchUser: (search: string) => void;
  assetClassOptions: Array<IOption<IAssetClass>>;
  assetSubClassOptions: Array<IOption<IAssetSubClass>>;
  currencyOptions: Array<IOption<ICurrency>>;
  countryOptions: Array<IOption<ICountry>>;
  industryOptions: Array<IOption<IIndustry>>;
  authInfo: IAuthInfo;
  nestedSetFromAssetClasses: NestedSet<any>;
}

interface IState {
  showFilterPanel: boolean;
  currentUser: IOption<IUser> | null;
}

function getAssetClassName(assetClass: IAssetClass | null, assetSubClass: IAssetSubClass | null) {
  if (assetClass) {
    return assetClass.name;
  } else if (assetSubClass) {
    return assetSubClass.name;
  }
  return '';
}

function defaultFilter<T>(filter: string, option: IOption<T>) {
  return option.name.toLowerCase().includes(filter.toLowerCase());
}

class InstrumentsList extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showFilterPanel: false,
      currentUser: null,
    };
  }

  componentWillUnmount(): void {
    this.resetAllFilters();
  }

  cellRenderer = (data: IInstrumentTableCellProps): React.ReactNode => {
    const { onRemoveInstrument } = this.props;
    if (data.dataKey === 'action') {
      return <InstrumentActionsCell instrument={data.rowData} onRemove={onRemoveInstrument} />;
    }

    if (['assetClass', 'assetSubClass'].includes(data.dataKey)) {
      return <Fragment>{`${(data.cellData as IAssetClass).name}`}</Fragment>;
    }

    return defaultTableCellRenderer(data);
  };

  handleSortChange = (sortBy: string, sortDirection: SortDirectionType) => {
    this.props.onSortChange({ name: sortBy, direction: sortDirection });
  };

  toggleFilterPanel = () => {
    this.setState({
      showFilterPanel: !this.state.showFilterPanel,
    });
  };

  updateFilters = (partial: Partial<IInstrumentsFilters>) => {
    const { instrumentsFilters, onFiltersChange } = this.props;

    const filters = {
      ...instrumentsFilters,
      ...partial,
    };

    onFiltersChange(filters);
  };

  resetAllFilters = () => {
    this.updateFilters({
      nonMarket: true,
      assetSubClass: null,
      assetClass: null,
      country: null,
      code: null,
      user: null,
      name: null,
      currency: null,
      industrySector: null,
    });
  };

  selectAssetClass = ({ indexes, nestedSet }: ISelectHandlerProps): ISelectHandlerCallbackOptions => {
    const { updateFilters } = this;
    if (!indexes) {
      updateFilters({ assetClass: null, assetSubClass: null });
      return {
        isVisible: undefined,
      };
    }
    const selectedSet = nestedSet.getByIndexes(indexes);
    if (!selectedSet) {
      console.error('BL error');
      return {
        isVisible: undefined,
      };
    }
    // todo: make universal field 'assetClass'
    if (!selectedSet) {
      updateFilters({ assetClass: null, assetSubClass: null });
    } else if (selectedSet.data.assetClassId) {
      updateFilters({ assetSubClass: selectedSet.data, assetClass: null });
    } else {
      updateFilters({ assetClass: selectedSet.data, assetSubClass: null });
    }
    return {
      isVisible: false,
    };
  };

  renderFilters = () => {
    const { updateFilters, selectAssetClass, resetAllFilters } = this;
    const {
      instrumentsFilters,
      currencyOptions,
      countryOptions,
      nestedSetFromAssetClasses,
      industryOptions,
    } = this.props;

    return (
      <div className={s.filterGrid}>
        <FormFieldWrapper label="Code">
          <Input
            value={instrumentsFilters.code || ''}
            onChange={(e) => updateFilters({ code: e.target.value })}
            className="input--small"
            theme="inverse"
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Instrument name">
          <Input
            value={instrumentsFilters.name || ''}
            onChange={(e) => updateFilters({ name: e.target.value })}
            className="input--small"
            theme="inverse"
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Asset class">
          <NestedSelect
            selectHandler={selectAssetClass}
            nestedSet={nestedSetFromAssetClasses}
            text={getAssetClassName(instrumentsFilters.assetClass, instrumentsFilters.assetSubClass)}
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Industry Sector">
          <Autocomplete
            options={industryOptions}
            onSelect={(option: IOption<IIndustry> | null) => updateFilters({ industrySector: option })}
            value={instrumentsFilters.industrySector}
            filter={defaultFilter}
            inputProps={{
              className: 'input--small',
            }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Country">
          <Autocomplete
            options={countryOptions}
            onSelect={(option: IOption<ICountry> | null) => updateFilters({ country: option })}
            value={instrumentsFilters.country}
            filter={defaultFilter}
            inputProps={{
              className: 'input--small',
            }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Currency">
          <Autocomplete
            options={currencyOptions}
            onSelect={(option: IOption<ICurrency> | null) => updateFilters({ currency: option })}
            value={instrumentsFilters.currency}
            filter={defaultFilter}
            inputProps={{
              className: 'input--small',
            }}
          />
        </FormFieldWrapper>
        <Button type="button" className={s.resetBtn} onClick={resetAllFilters}>
          Reset
        </Button>
      </div>
    );
  };

  render() {
    const { instrumentsList, instrumentsSort, userListOptions, searchUser, authInfo } = this.props;

    const { showFilterPanel, currentUser } = this.state;

    const { renderFilters, toggleFilterPanel, updateFilters } = this;

    return (
      <Fragment>
        <PageHeader title="Instruments" toolsWrapperClassName={s.header}>
          {(authInfo.role === UserRole.ADMIN || authInfo.role === UserRole.MANAGER) && (
            <Autocomplete
              options={userListOptions}
              onSelect={(option: IOption<IUser> | null) => updateFilters({ user: option })}
              onTyping={(search: string) => searchUser(search)}
              value={currentUser}
              inputProps={{
                placeholder: 'Client',
                className: 'input--small',
              }}
            />
          )}
          <div onClick={toggleFilterPanel} className={s.filterBtn}>
            Filter
          </div>
          <Link
            to={{
              pathname: PATHS.instruments.addInstrument.path,
            }}
          >
            <Button size={'small'}>Add</Button>
          </Link>
          <Link
            to={{
              pathname: PATHS.instruments.uploadInstrument.path,
            }}
          >
            <Button size={'small'}>Upload</Button>
          </Link>
        </PageHeader>
        {showFilterPanel && renderFilters()}
        <TableWrapper
          columns={getInstrumentColumns(authInfo.role === UserRole.ADMIN || authInfo.role === UserRole.MANAGER)}
          tableData={instrumentsList}
          totalCount={instrumentsList.length}
          headerHeight={24}
          rowHeight={24}
          cellRenderer={this.cellRenderer}
          sortBy={instrumentsSort.name}
          sortDirection={instrumentsSort.direction}
          onSortData={this.handleSortChange}
        />
      </Fragment>
    );
  }
}

export default InstrumentsList;
