import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment from 'moment';

import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { FormatHelper, VALUE_TYPE } from '../../../../../../services/utils/FormatHelper';
import { ChartHelper } from '../../../../../../services/utils/ChartHelper';
import { ColorsHelper } from '../../../../../../services/utils/ColorsHelper';
import { floatNumberV2 } from '../../../../../../services/utils/FormNormalize';

type FormatterParam = echarts.EChartOption.Tooltip.Format;

/** waterfall chart data consist only from 2 element
 *
 * @param chartData [IBarData, IBarData] 0 - increase data, 1 - decrease data
 */
export function getWaterFallPositionChartOptions(
  chartData: IBarData[],
  baseCurrencySymbol: string,
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  // copy chartData not to edit data by link
  chartData = chartData.map((item) => ({ ...item }));

  const chartOpt = ChartHelper.getChartOptions({ tooltipValueType: VALUE_TYPE.MONEY, portfolioCurrencyFormatter });

  if (!chartData.length) {
    return chartOpt;
  }

  const mergeOpt: echarts.EChartOption = {
    xAxis: {
      data: chartData[1].data.map((item) => item[0]),
      axisLabel: {
        showMaxLabel: true,
        rotate: 30,
        interval: 0,
        formatter(value: string): any {
          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const momentVal = moment(value);
            return momentVal.format('DD-MMM-YY');
          }
          const words = value.split(' ');
          if (words.length > 2) {
            return words[0];
          }
          return value;
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return FormatHelper.formatNumber(Number(value));
        },
      },
    },
    tooltip: {
      formatter: (params: FormatterParam | FormatterParam[]): string => {
        if (!Array.isArray(params)) {
          return portfolioCurrencyFormatter(Math.abs(params.value as number));
        }
        const sumIncrease = Math.abs(params[1].value as number) + Math.abs(params[2].value as number);
        const sumDecrease = Math.abs(params[3].value as number) + Math.abs(params[4].value as number);
        return params[3].value !== 0 ? `-${floatNumberV2(sumDecrease, 2)}` : `${floatNumberV2(sumIncrease, 2)}`;
      },
    },
    series: prepareSeries(chartData),
  };

  return merge({}, chartOpt, mergeOpt);
}

function prepareSeries(data: IBarData[]): echarts.EChartOption.SeriesBar[] {
  const [helpBarData, increaseData, deltaIncreaseData, decreaseData, deltaDecreaseData] = getWaterfallSeriesData([
    data[0],
    data[1],
  ]);

  // chart should contains the 3rd series with aggregation values that will be hidden
  const aggregationSeries: echarts.EChartOption.SeriesBar = getAgrSeries(helpBarData, data);
  const increaseSeries: echarts.EChartOption.SeriesBar = getChartSeries(increaseData);
  const deltaIncreaseSeries: echarts.EChartOption.SeriesBar = getChartSeries(deltaIncreaseData);

  const decreaseSeries: echarts.EChartOption.SeriesBar = getChartSeries(decreaseData, true);
  const deltaDecreaseSeries: echarts.EChartOption.SeriesBar = getChartSeries(deltaDecreaseData, true);

  return [aggregationSeries, increaseSeries, deltaIncreaseSeries, decreaseSeries, deltaDecreaseSeries];
}

function getAgrSeries(chartData: number[], realData: IBarData[]): echarts.EChartOption.SeriesBar {
  const barOpt = ChartHelper.getBarSeriesOptions();
  return {
    ...barOpt,
    name: '',
    data: chartData,
    stack: 'waterfall',
    barCategoryGap: '0',
    itemStyle: {
      normal: {
        barBorderColor: 'rgba(0,0,0,0)',
        color: 'rgba(0,0,0,0)',
      },
    },
    markLine: {
      symbol: 'none',
      lineStyle: {
        color: ColorsHelper.toRGBA('#6677cc', 50),
        type: 'solid',
      },
      data: getLinesPath(chartData, realData),
    },
  };
}

function getChartSeries(chartData: any[], hatching: boolean = false): echarts.EChartOption.SeriesBar {
  const lastElem = chartData[chartData.length - 1];
  if (lastElem !== 0) {
    const index = chartData.length - 1;

    if (index !== -1) {
      chartData[index] = {
        value: lastElem,
        itemStyle: {
          color: 'rgb(191, 127, 63)',
        },
      };
    }
  }

  const barOpt = hatching ? ChartHelper.getBar2SeriesOptions('#ff6972') : ChartHelper.getBarSeriesOptions('#41d9a0');
  const mergedOpt: echarts.EChartOption.SeriesBar = {
    name: '',
    data: chartData,
    stack: 'waterfall',
    barCategoryGap: '0',
  };

  return merge({}, barOpt, mergedOpt);
}

/**
 *
 * @param chartData
 * return [
 *  helped data for waterfall view to set baseline of next bar,
 *  increase values (exclude delta),
 *  delta for increase values if sign has been changed
 *  decrease values (exclude delta),
 *  delta for decrease values if sign has been changed
 * ]
 */
function getWaterfallSeriesData(chartData: [IBarData, IBarData]): [number[], number[], number[], number[], number[]] {
  const increaseData: number[] = [];
  const decreaseData: number[] = [];

  const helpBarData: number[] = [0];

  const deltaIncreaseData: number[] = [];
  const deltaDecreaseData: number[] = [];

  // foreach by increase array because data length is the same
  chartData[0].data.forEach((item, index) => {
    const increaseVal = item[1];
    const decreaseVal = chartData[1].data[index][1];

    // get new base value for the next bar
    const newBaseLine = helpBarData[index] + increaseVal + decreaseVal;

    // check that sign have been changed
    if (!(helpBarData[index] && newBaseLine && Math.sign(helpBarData[index]) !== Math.sign(newBaseLine))) {
      // if now is increase value but next will be negative then baseline should be compensated on it
      if (helpBarData[index] > 0 && decreaseVal) {
        helpBarData[index] = helpBarData[index] + decreaseVal;
      }

      if (helpBarData[index] < 0 && increaseVal) {
        helpBarData[index] = helpBarData[index] + increaseVal;
      }

      // if we are at another side of axis than change sign of value
      increaseData.push(newBaseLine <= 0 ? -increaseVal : increaseVal);
      decreaseData.push(newBaseLine >= 0 ? Math.abs(decreaseVal) : decreaseVal);
      deltaIncreaseData.push(0);
      deltaDecreaseData.push(0);
      helpBarData.push(newBaseLine);
      return;
    }

    // data should be decoupled into two series before zero line and after
    const prevHelpData = helpBarData[index];
    helpBarData[index] = 0;

    if (increaseVal) {
      const incrDelta = increaseVal - Math.abs(prevHelpData);
      increaseData.push(-increaseVal + incrDelta);
      deltaIncreaseData.push(incrDelta);
      decreaseData.push(0);
      deltaDecreaseData.push(0);
    } else {
      const decrDelta = decreaseVal + Math.abs(prevHelpData);
      decreaseData.push(decrDelta);
      deltaDecreaseData.push(prevHelpData);
      increaseData.push(0);
      deltaIncreaseData.push(0);
    }

    helpBarData.push(newBaseLine);
  });
  return [helpBarData, increaseData, deltaIncreaseData, decreaseData, deltaDecreaseData];
}

function getLinesPath(seriesDate: number[], chartData: IBarData[]): any {
  const xAxis: string[] = chartData[0].data.map((item) => item[0]);
  const linesPoints: number[] = chartData[0].data.reduce(
    (accumValue: number[], curVal: [string, number], index: number) => {
      const prevValue = accumValue[index - 1] ? accumValue[index - 1] : 0;
      accumValue.push(prevValue + curVal[1] + chartData[1].data[index][1]);
      return accumValue;
    },
    []
  );

  return linesPoints.map((number, index) => [
    {
      xAxis: xAxis[index],
      yAxis: number,
    },
    {
      xAxis: xAxis[index + 1],
      yAxis: number,
    },
  ]);
}
