import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';

export class PortfolioCurrencyActions {
  static readonly PORTFOLIO_CURRENCY_REQUEST = 'PORTFOLIO_CURRENCY_REQUEST';
  static readonly PORTFOLIO_CURRENCY_SUCCESS = 'PORTFOLIO_CURRENCY_SUCCESS';
  static readonly PORTFOLIO_CURRENCY_ERROR = 'PORTFOLIO_CURRENCY_ERROR';

  static fetchPortfolioCurrency() {
    return ReduxHelper.createHttpRequestActionBundle<ICurrency[]>(
      {
        url: getApiUrl('portfolio.currencies'),
        method: 'GET',
      },
      PortfolioCurrencyActions.PORTFOLIO_CURRENCY_REQUEST,
      PortfolioCurrencyActions.PORTFOLIO_CURRENCY_SUCCESS,
      PortfolioCurrencyActions.PORTFOLIO_CURRENCY_ERROR
    );
  }
}
