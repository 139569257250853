import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DashboardActions } from '../../../../../../../../../../services/actions';
import { IPortfolioInfoState } from '../../../../../../../../../../services/reducers/PortfolioReducers';
import {
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../../../../../../../services/selectors/portfolio';
import { AsyncActionDispatch } from '../../../../../../../../../../services/utils/ReduxHelper';
import { PortfolioHeader } from '../../../../../../../../components/PortfolioHeader';
import { AssetReportWrapper } from '../../../../components/AssetReportWrapper';
import { withAssetLogic } from '../../../../HOC';
import { IAssetReportProps } from '../../../../HOC/withAssetLogic';
import PnlBreakdownDetail from './PnlBreakdownDetail';
import s from './PnlBreakdownDetail.module.css';

interface IMapStateToProps {
  portfolioInfo: IPortfolioInfoState;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  assetClasses: IAssetClass[];
  breakdownPosition: IBreakdownPosition;
}

interface IDispatchToProps {
  resetfetchBreakdownPositionReport: () => void;
  fetchBreakdownPositionReport: (portfolioId: string, positionId: string, classId: string) => void;
}

interface IPathParamsType {
  positionId: string;
  classId: string;
}

type IProps = IMapStateToProps & IDispatchToProps & IAssetReportProps & RouteComponentProps<IPathParamsType>;

interface IState {
  allLoaded: boolean;
}

class EquityContainer extends PureComponent<IProps, IState> {
  readonly state: IState = {
    allLoaded: false,
  };

  componentDidMount(): void {
    this._fetchData().then(() => {
      this.setState({ allLoaded: true });
    });
  }

  componentWillUnmount(): void {
    this.props.resetfetchBreakdownPositionReport();
  }

  handleCloseClick = (event: any) => {
    localStorage.setItem('selectedTab', '2');
  };

  render() {
    const { allLoaded } = this.state;
    const { portfolioInfo, assetDataLoaded, portfolioCurrencyFormatter } = this.props;

    const portfolioAssetsIds: string[] = [];
    if (portfolioInfo.data) {
      portfolioInfo.data.allocation.AssetClass.map((item: any) => portfolioAssetsIds.push(item.id));
    }

    const headerName = this.props.breakdownPosition
      ? `${this.props.match.params.classId} • ${this.props.breakdownPosition.instrumentName}`
      : '';

    return (
      <AssetReportWrapper title={this.props.match.params.classId} allLoaded={assetDataLoaded && allLoaded} noData={true}>
        <PortfolioHeader title={headerName}>
          <div>
            <div className={s.closeBtn} onClick={this.handleCloseClick}>
              <a onClick={() => this.props.history.goBack()}>X</a>
            </div>
          </div>
        </PortfolioHeader>
        <PnlBreakdownDetail
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          portfolioInfo={this.props.portfolioInfo}
          breakdownPosition={this.props.breakdownPosition}
          classId={this.props.match.params.classId}
        />
      </AssetReportWrapper>
    );
  }

  private _fetchData(): Promise<any> {
    return Promise.all([
      this.props.fetchBreakdownPositionReport(
        this.props.portfolioId,
        this.props.match.params.positionId,
        this.props.match.params.classId
      ),
    ]);
  }
}

// const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
const mapStateToProps: (state: any) => IMapStateToProps = (state: any) => {
  return {
    portfolioInfo: state.portfolio.portfolioInfo,
    assetClasses: state.assetsClasses.data,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
    breakdownPosition: state.dashboard.breakdownPosition.data || [],
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  resetfetchBreakdownPositionReport: () => dispatch(DashboardActions.resetfetchBreakdownPositionReport()),
  fetchBreakdownPositionReport: (portfolioId: string, positionId: string) =>
    dispatch(DashboardActions.fetchBreakdownPositionReport(portfolioId, positionId)),
});

export default withRouter(withAssetLogic(connect(mapStateToProps, mapDispatchToProps)(EquityContainer)));
