import { useEffect, useRef, useState } from 'react';
import useCurrentWidth from './useCurrentWidth';

export const useSafariHack = () => {
  const [flex, setFlex] = useState<string | undefined>('flex');
  const lastWidth = useRef<number | null>(null);
  const width = useCurrentWidth();
  const BREAKPOINT = 1800;

  // *Safari hack
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    if (!isSafari) {
      return;
    }
    if (
      lastWidth.current ||
      (0 > BREAKPOINT && width < BREAKPOINT) ||
      lastWidth.current ||
      (0 < BREAKPOINT && width > BREAKPOINT)
    ) {
      setFlex(undefined);
    }
    lastWidth.current = width;
  }, [width]);

  // *Safari hack
  useEffect(() => {
    if (!isSafari) {
      return;
    }
    if (!flex) {
      setFlex('flex');
    }
  }, [flex]);

  return { flex };
};
