import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { IScenarioBasicConfig } from '../../../../../models/IScenarioBasicConfig';
import { IRootState } from '../../../../../services/store';
import { ScenariosActions } from '../../../../../services/actions';
import { ScenarioConfig } from '../../../../../components/ScenarioConfig';

interface IMapStateToProps {
  basicSettings: IScenarioBasicConfig;
  historyEvent: string | null;
  historyPeriod: number;
}

interface IDispatchToProps {
  applySettings: (settings: IScenarioBasicConfig) => void;
  selectHistoryEvent: (event: string) => void;
  changeHistoryPeriod: (period: number) => void;
}

type IProps = IMapStateToProps & IDispatchToProps;

class ScenarioConfigContainer extends PureComponent<IProps> {
  render() {
    const {
      applySettings,
      basicSettings,
      changeHistoryPeriod,
      selectHistoryEvent,
      historyEvent,
      historyPeriod,
    } = this.props;
    return (
      <ScenarioConfig
        basicSettings={basicSettings}
        historyEvent={historyEvent}
        historyPeriod={historyPeriod}
        onSettingsChanged={applySettings}
        onEventSelect={selectHistoryEvent}
        onEventPeriodChanged={changeHistoryPeriod}
      />
    );
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    basicSettings: state.scenario.configurations.basic,
    historyEvent: state.scenario.configurations.historyEvent.selected,
    historyPeriod: state.scenario.configurations.historyEvent.period,
  };
};

const mapDispatchToProps: IDispatchToProps = {
  applySettings: ScenariosActions.applyScenarioConfig,
  selectHistoryEvent: ScenariosActions.selectHistoryEvent,
  changeHistoryPeriod: ScenariosActions.selectHistoryPeriod,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioConfigContainer);
