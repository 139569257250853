import React, {
  ButtonHTMLAttributes,
  ChangeEventHandler,
  HTMLAttributes,
  InputHTMLAttributes,
  PureComponent,
} from 'react';
import cn from 'classnames';
import s from './Checkbox.module.scss';

interface IOwnProps {
  label?: string;
}

type IProps = IOwnProps & InputHTMLAttributes<HTMLInputElement>;

class Checkbox extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    className: '',
    disabled: false,
    name: '',
    onChange: () => {},
  };

  componentDidMount() {
    const { checked } = this.props;
    this.setState({ isChecked: checked });
  }

  render() {
    const { className, checked, label, name, disabled, children, onChange } = this.props;

    return (
      <label className={cn(s.label, className)}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          className={s.checkbox}
          disabled={disabled}
        />
        <span className={s.checkboxView} />
        {!children && label && <span>{label}</span>}
        {children}
      </label>
    );
  }
}

export default Checkbox;
