import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import s from './Registration.module.css';
import logo from '../../../../../static/images/logo_auth.png';
import { AsyncActionDispatch } from '../../../../../services/utils/ReduxHelper';
import { AppActions } from '../../../../../services/actions/AppActions';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

interface IOwnProps {
  showLogout?: boolean;
}

interface IDispatchProps {
  logout(): void;
}

type Props = IOwnProps & IDispatchProps;

const RegistrationTemplate: FunctionComponent<Props> = ({ children, logout, showLogout = true }) => {
  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.left)}>
        <img src={logo} className={cn(s.logo)} />
      </div>

      <div className={cn(s.right)}>
        {children}
        {showLogout && (
          <div onClick={logout} className={cn(s.logout)}>
            Log out
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchProps => ({
  logout: () => {
    // console.log('logging out');
    Auth.signOut().then(() => {
      dispatch(AppActions.logout());
    });
  },
});

export default connect(undefined, mapDispatchToProps)(RegistrationTemplate);
