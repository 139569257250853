import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

interface IProps {
  availableStrikes: Array<IOption<IOptionIdentifier>>;
  disabled?: boolean;
  underlyingPriceLabel?: string;
}

const OptionsStrikeSelector: FC<IProps> = ({ availableStrikes, disabled, underlyingPriceLabel }) => {
  return (
    <FormFieldWrapper
      label={`Option${underlyingPriceLabel ? ` (${underlyingPriceLabel})` : ''}`}
      className={cn(s.nameField, s.fieldsWrapper, s.strikeField)}
      required={true}
    >
      <Field
        component={renderAutocomplete}
        name="option"
        options={availableStrikes}
        theme="inverse"
        disableReset={true}
        disabled={disabled}
        inputDisabled={false}
        inputProps={{
          theme: 'inverse',
          className: 'input--small',
          placeholder: 'Select',
        }}
      />
    </FormFieldWrapper>
  );
};

export default OptionsStrikeSelector;
