import React, { FC, useEffect, useState } from 'react';
import { IRootState } from '../../../../../../../../services/store';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { connect } from 'react-redux';
import s from '../../Transaction.module.scss';
import { renderDatePicker, renderInput } from '../../../../../../../../components/ReduxForm';
import cn from 'classnames';
import { SYSTEM_EPOCH } from '../../../../../../../../services/constants/constants';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { getCustodianOptions } from '../../../../../../../../services/selectors/snapshots';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import { IPortfolioTrade, TradeType } from '../../../../../../../../models/IPortfolioTrade';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { renderToggleBtns } from '../../components';
import Quantity from '../../components/ConnectedTransactionInputs/Quantity';
import TradeCost from '../../components/ConnectedTransactionInputs/TradeCost';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import Commission from '../../components/ConnectedTransactionInputs/Commission';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import _ from 'lodash';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';

import { formatFxConvention } from '../../../../../../../../services/utils/FXHelper';
import { getMonthName } from '../../../../../../../../services/utils/DateHelpers';
import SettlementOptions from '../../components/ConnectedTransactionInputs/SettlementOptions';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import dateFormat from 'dateformat';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import WarrantTicker from '../../components/ConnectedTransactionInputs/WarrantTicker/WarrantTicker';
import { currencyValueFormatter } from '../../../../../../../../services/utils/CurrencyHelpers';
import { getFxConversionLabel } from '../../helpers';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: IWarrantFormValues;
  custodianOptions: Array<IOption<ICustodian>>;
  availableDates: Array<IOption<{ month: number; date: number }>>;
  availableStrikes: Array<IOption<IOptionIdentifier>>;
  selectedOption: IWarrantFormValues['warrant'];
  snapshotFxRate: IFxRate | null;
  baseCurrencyCode?: string | null; // move to AssetEntryContainer
  optionsLoading?: boolean;
  warrants: IOptionIdentifier[] | null;
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  updateTradeType(data: IOption<TradeType>): void;
  updateWarrant(data: IOption<IOptionIdentifier> | null): void;
  updateExpiryDate(data: IOption<number> | null): void;
  updatePrice(data: number | null): void;
  updateMultiplier(data: number | null): void;
  updateFxRate(rate: number | null): void;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
  dynamicSearchWarrantByName(name: string, date: string): AxiosPromise<IOptionIdentifier[]>;
}

type FormUpdater = <T extends keyof IWarrantFormValues>(field: T, data: IWarrantFormValues[T] | null) => void;

export interface IWarrantFormValues {
  tradeType: IOption<TradeType>;
  tradeTime: string;
  commission: number;
  operation: string;
  quantity: number;
  custodian: string | IOption<ICustodian> | any;
  underlyingPrice?: string | number;
  price: string;
  tradeCosts: string;
  notes: string;
  settlementOption: SettlementOption;
  type: 'CALL' | 'PUT';
  year: IOption<number>;
  strike: IOption<number>;
  instrument: IOption<IOptionIdentifier>;
  warrant: IOption<IOptionIdentifier>;
  expiryDate: IOption<{ month: number; date: number }>;
  multiplier: string;
  fxRate: string | number;
}

type Props = IAssetEntryComponentProps<IWarrantFormValues> &
  IMapStateToProps &
  IDispatchToProps &
  InjectedFormProps<IWarrantFormValues>;

const WarrantEntry: FC<Props> = ({
  formValues,
  warrants,
  editMode,
  dynamicSearchWarrantByName,
  custodianOptions,
  availableDates,
  availableStrikes,
  updateWarrant,
  updateExpiryDate,
  updateTempTrade,
  updateFormValue,
  editedUnconfirmedTrade,
  resetForm,
  updatePrice,
  updateMultiplier,
  updateFxRate,
  selectedOption,
  optionsLoading,
  fetchFxRate,
  baseCurrencyCode,
  snapshotFxRate,
  portfolioCurrencyFormatter,
  resetEditTrade,
  removeTrade,
  currencies,
  portfolioInfo,
  saveTrades,
  fetchTradeBlotter,
  savedTradesOrder,
  initialValues,
  checkedOut,
}) => {
  const { tradeTime, instrument, warrant } = formValues || {};
  const { sourceId } = warrant?.value || {};
  const [instrumentOptions, setInstrumentOptions] = useState<IOption<IOptionIdentifier>[]>([]);

  const instrumentCurrencyCode = String((instrument?.value?.currency ?? baseCurrencyCode) || '');

  const fx = useFx();
  const OPERATIONS = [
    { text: 'Buy', value: 'BUY' },
    { text: 'Sell', value: 'SELL' },
  ];

  const searchAsset = async (str: string) => {
    const res = await dynamicSearchWarrantByName(str, tradeTime);
    const parsed: Array<IComboItemGeneric> = res.data.map((option) => {
      return {
        id: option.id,
        label: getWarrantString(option),
        value: option,
      };
    });
    return parsed;
  };

  const convention = fx({ fromCurrencyName: instrumentCurrencyCode, toCurrencyName: baseCurrencyCode ?? undefined });

  const defaultValues = React.useCallback<() => Partial<any>>(
    () => ({
      operation: TradeType.BUY.toUpperCase(),
      settlementOption: 'BASE',
      baseSettlementCurrency: 'BASE',
      custodian: custodianOptions.find((custodian) => custodian.value.default === true) || custodianOptions[0],
      tradeTime: dateFormat(new Date(), 'yyyy-mm-dd'),
      quantity: 0,
      type: 'CALL' as 'CALL',
    }),
    [custodianOptions]
  )();

  const getWarrantString = (warr: IOptionIdentifier) => {
    return `${warr.displayId}`;
  };

  // if we delete warrant, we reset the price
  React.useEffect(() => {
    if (!instrument && !editMode) {
      updateFormValue('price', null);
    }
  }, [instrument]);

  // * if we select a ticker/underlying name, we populate the warrant selections
  React.useEffect(() => {
    updateFormValue('warrant', null);

    if (!instrument) {
      return;
    }

    const filteredWarrants =
      warrants
        ?.filter((w) => w.name === instrument.value.name)
        .map((inst) => ({
          name: getWarrantString(inst),
          id: inst.id,
          value: inst,
        })) ?? [];
    setInstrumentOptions(filteredWarrants);
    if (filteredWarrants.length === 1) {
      updateFormValue('warrant', filteredWarrants[0]);
    }
  }, [instrument]);

  // * If the user picks a new expiry date, clear the selected option
  React.useEffect(() => {
    if (!editMode) {
      updateWarrant(null);
    }
  }, [formValues?.expiryDate]);

  // * Load FX Rate for the day of the trade
  React.useEffect(() => {
    if (!!String(instrumentCurrencyCode) && baseCurrencyCode && tradeTime && !editMode) {
      fetchFxRate(String(instrumentCurrencyCode), baseCurrencyCode, tradeTime).then((result) => {
        if (result.data?.firstFxRate) {
          updateFxRate(result.data?.firstFxRate);
        }
      });
    }
  }, [instrumentCurrencyCode, baseCurrencyCode, tradeTime, sourceId, editMode]);

  // * When the user selects a new option, set the price to the EoD price for the selected option
  React.useEffect(() => {
    if (!!selectedOption?.value?.price && !editMode) {
      updateMultiplier(selectedOption?.value?.multiplier);
      updatePrice(selectedOption?.value?.price);
    }
  }, [selectedOption, editMode]);

  React.useEffect(() => {
    console.log({ initialValues });
    resetForm(initialValues);
  }, [initialValues]);

  const currencyValue = selectedOption?.value.currency;
  const pointValue = selectedOption?.value.multiplier;
  const fxRate = formatFxConvention(snapshotFxRate);
  const grossTradeAmount = React.useCallback(() => {
    return (fxRate?.fromToFxRate ?? 0) > 0
      ? nz(formValues.price) * nz(Number(formValues.quantity)) * n1(formValues.multiplier)
      : 0;
  }, [snapshotFxRate, formValues.price, formValues.quantity, formValues.multiplier, fxRate?.fromToFxRate])();

  const availableSettlementOptions = () => {
    const settlementBtns: Array<{ text: string; value: any; subText?: string }> = [
      { text: baseCurrencyCode ?? 'NA', value: 'BASE' },
      { text: `TRANSFER`, value: 'AUTO' },
    ];

    if (currencyValue && baseCurrencyCode !== currencyValue) {
      settlementBtns.splice(1, 0, { text: currencyValue ?? 'NA', value: 'LOCAL' });
    }
    return settlementBtns;
  };

  const isFormInvalid = React.useCallback(() => {
    return (
      isValueInvalid(formValues.fxRate) ||
      isValueInvalid(formValues.quantity) ||
      isValueInvalid(formValues.price, true) ||
      formValues.warrant === null
    );
  }, [formValues.fxRate, formValues.quantity, formValues.price, formValues.warrant])();

  const fxConversionLabel = React.useCallback(
    () => getFxConversionLabel(currencies, baseCurrencyCode, instrumentCurrencyCode, snapshotFxRate?.firstFxRate),
    [currencies, baseCurrencyCode, instrumentCurrencyCode, snapshotFxRate?.firstFxRate]
  )();

  const handleSaveOption = (e: any) => {
    e.preventDefault?.();

    const portfolioId = portfolioInfo?.id ?? '';

    const trade: IPortfolioTrade = {
      // @ts-ignore
      tradeType: { id: 'EqWarrant' },
      commission: formValues.commission,
      instrument: formValues.warrant.value,
      quantity: formValues.quantity,
      price: parseFloat(formValues.price),
      fxRate: convention.conventionToNatural((formValues.fxRate as number) ?? 1),
      currency: formValues.warrant.value.currency,
      tradeTime: formValues.tradeTime,
      settlementDate: undefined,
      settlementOption: formValues.settlementOption,
      operation: formValues.operation,
      custodian:
        typeof formValues.custodian === 'string'
          ? formValues.custodian
          : formValues.custodian?.value || (undefined as any),
      notes: formValues.notes,
      tradeCosts: parseFloat(formValues.tradeCosts),
      type: formValues.type,
      strikePrice: formValues.warrant.value.strike,
      multiplier: parseFloat(formValues.multiplier),
      // @ts-ignore
      optionTicker: {
        sourceId: {
          sourceId: formValues.warrant.value.sourceId || formValues.warrant.value.id,
          sourceData: selectedOption?.value?.sourceData,
        },
      },
    };
    saveTrades(portfolioId, [trade]).then(() => {
      fetchTradeBlotter(portfolioId, savedTradesOrder);
      resetForm(defaultValues);
    });
  };

  // const tradeIsInPortfolioCurrency = !currencyValue || baseCurrencyCode === currencyValue;
  // const fxDescription = convention.getDescription({ conventionRate: formValues.fxRate });

  const currencyLabel = convention.fromCurrency?.name ?? String('');
  const conversionString = convention.getConversionString(
    { conventionRate: formValues.fxRate as number },
    grossTradeAmount
  );

  const handleUpdateOption = (e: any) => {
    e.preventDefault?.();
    const portfolioId = portfolioInfo?.id ?? '';

    const trade: IPortfolioTrade = {
      ...editedUnconfirmedTrade?.trade,
      key: initialValues?.key,
      // @ts-ignore
      tradeType: { id: 'EqWarrant' },
      commission: formValues.commission,
      instrument: formValues.warrant.value,
      quantity: formValues.quantity,
      price: parseFloat(formValues.price),
      fxRate: convention.conventionToNatural((formValues.fxRate as number) ?? 1),
      currency: formValues.warrant.value.currency,
      tradeTime: formValues.tradeTime,
      settlementDate: undefined,
      operation: formValues.operation,
      settlementOption: formValues.settlementOption,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      notes: formValues.notes,
      tradeCosts: parseFloat(formValues.tradeCosts),
      type: formValues.type,
      strikePrice: formValues.warrant.value.strike,
      multiplier: parseFloat(formValues.multiplier),
      optionTicker: {
        sourceId: {
          sourceId: formValues.warrant.value.sourceId || formValues.warrant.value.id,
          sourceData: selectedOption?.value?.sourceData,
        },
      }, // formValues.option.value,
    };

    updateTempTrade?.(portfolioId, [trade]).then(() => {
      fetchTradeBlotter(portfolioId, savedTradesOrder);
      resetEditTrade();
    });
  };

  return (
    <form className={s.formGrid} onSubmit={editMode ? handleUpdateOption : handleSaveOption}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <FormFieldWrapper label="Transaction date" required={true} className={s.minField}>
          <Field
            name="tradeTime"
            className={s.datePicker}
            component={renderDatePicker}
            placeholder={'Date'}
            minDate={SYSTEM_EPOCH}
            maxDate={new Date()}
            hideWeekends={false}
          />
        </FormFieldWrapper>
        {!(editMode && checkedOut) && (
          <WarrantTicker
            label={'Ticker (Full ticker required)'}
            fetchItems={searchAsset}
            disabled={editMode && checkedOut}
            defaultValue={initialValues?.warrant ? getWarrantString(initialValues as any) : ''}
          />
        )}
        <FormFieldWrapper label="Multiplier" required={true} className={s.minField}>
          <Field
            placeholder="0"
            name="multiplier"
            type="number"
            theme="inverse"
            component={renderInput}
            disabled={true}
            value={warrant?.value.multiplier}
            className="input--small"
            normalize={(value: number) => positiveNumber(floatNumberV2(value, 4)).toString()}
          />
        </FormFieldWrapper>
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper className={s.settlementField} label="Operation">
          <Field name="operation" values={OPERATIONS} className="input--small" component={renderToggleBtns} />
        </FormFieldWrapper>
        <Quantity quantityDecimals={0} />
        <FormFieldWrapper
          label={`Price ${currencyValueFormatter(pointValue, (currencyValue as string) ?? '')}`}
          required={true}
          className={s.minField}
        >
          <Field
            placeholder="0"
            name="price"
            type="text"
            theme="inverse"
            component={renderInput}
            value={selectedOption?.value?.price}
            className="input--small"
            normalize={(value: number) => positiveNumber(floatNumberV2(value, 4)).toString()}
          />
        </FormFieldWrapper>
        <Commission currencyValue={currencyLabel} />
        <TradeCost currencyCode={currencyLabel} />
        <FXRate label={fxConversionLabel} />
        <SettlementOptions options={availableSettlementOptions()} />
        <Custodian />
        <AddCustodianButton />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>
      <div className={s.formFooter}>
        <span className={cn('text-gray', s.amountWrapper)}>Trade Gross Amount: {conversionString}</span>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.asset = required(value.instrument);
  errors.price = required(value.price) || number(value.price);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);

  return errors;
};

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};

const getAvailableDates = (optionsMatchingYear: IOptionIdentifier[] | null) => {
  if (!optionsMatchingYear) {
    return [];
  }
  const datesObj: Record<string, boolean> = {};
  optionsMatchingYear.forEach(
    (value) => (datesObj[`${String(value.expMonth).padStart(2, '0')}-${String(value.expDay).padStart(2, '0')}`] = true)
  );
  const availableDates: Array<IOption<{ month: number; date: number }>> = [];

  Object.entries(datesObj)
    .sort(([a], [b]) => a.localeCompare(b))
    .forEach(([key, value]) => {
      if (value) {
        const parts = key.split('-');
        availableDates.push({
          id: key,
          value: { month: parseInt(parts[0], 10), date: parseInt(parts[1], 10) },
          name: `${parts[1]} ${getMonthName(parts[0])}`,
        });
      }
    });
  return availableDates;
};

const mapStateToProps = (state: IRootState) => {
  const formValues = state.form.transactionWarrantForm.values as IWarrantFormValues;
  const optionsMatchingYear = state.snapshot.snapshotEdit.searchWarrants.data;
  const availableDates = getAvailableDates(optionsMatchingYear);
  const expiryDate = formValues?.expiryDate;
  const optionsMatchingExpiry: Array<IOption<IOptionIdentifier>> =
    !!expiryDate?.value.month && optionsMatchingYear
      ? optionsMatchingYear
          .filter(
            (optionIdentifier) =>
              optionIdentifier.expMonth === expiryDate?.value?.month &&
              optionIdentifier.expDay === expiryDate?.value?.date
          )
          .map((optionIdentifier) => ({
            name: `${String(optionIdentifier.expMonth).padStart(2, '0')}-
            ${String(optionIdentifier.expDay).padStart(2, '0')}: ${optionIdentifier.name}`,
            id: optionIdentifier.id,
            value: optionIdentifier,
          }))
      : [];

  // @ts-ignore
  const availableStrikes: Array<IOption<IOptionIdentifier>> = _.uniqBy(
    optionsMatchingExpiry ?? [],
    (item) => item.value.name
  ).map(({ value }) => ({
    name: `${value.strike} Strike   --  [${value.name}]`, // String(value.strike),
    id: value.name,
    value,
  }));
  const strike = state.form.transactionWarrantForm.values?.strike;

  return {
    warrants: state.snapshot.snapshotEdit.searchWarrants.data,
    custodianOptions: getCustodianOptions(state),
    snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data,
    formValues,
    availableDates,
    availableStrikes,
    strike,
    selectedOption: formValues.instrument,
    optionsLoading: state.snapshot.snapshotEdit.searchWarrants.isFetching,
  };
};

const nz = (val?: string | number | null) => parseFloat(String(val)) || 0;
const n1 = (val?: string | number | null) => parseFloat(String(val)) || 1;

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateTradeType: (data: IOption<TradeType>) => dispatch(change(FORMS_NAME.transactionWarrant, 'tradeType', data)),
  updateWarrant: (data) => dispatch(change(FORMS_NAME.transactionWarrant, 'warrant', data)),
  updateExpiryDate: (data) => dispatch(change(FORMS_NAME.transactionWarrant, 'expiryDate', data)),
  updateFormValue: (key, data) => dispatch(change(FORMS_NAME.transactionWarrant, key, data)),
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
  updatePrice: (data: number | null) => dispatch(change(FORMS_NAME.transactionWarrant, 'price', data)),
  updateMultiplier: (data: number | null) => dispatch(change(FORMS_NAME.transactionWarrant, 'multiplier', data)),
  updateFxRate: (data: number | null) => dispatch(change(FORMS_NAME.transactionWarrant, 'fxRate', data)),
  dynamicSearchWarrantByName: (name: string, date: string) => dispatch(SnapshotActions.dynamicSearchWarrant(name, date)),
});

export default reduxForm<IWarrantFormValues, IAssetEntryComponentProps<IWarrantFormValues>>({
  form: FORMS_NAME.transactionWarrant,
  validate,
  destroyOnUnmount: false,
  // @ts-ignore
})(connect(mapStateToProps, mapDispatchToProps)(WarrantEntry));
