import React, { PureComponent, Fragment } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { Button, FormFieldWrapper } from '../../components/UIWidgets';
import { renderInput } from '../../components/ReduxForm';
import validate from './validate';
import { FORMS_NAME } from '../../services/constants/forms';

import s from './ChangePasswordForm.module.scss';

type IProps = InjectedFormProps<IPasswordFormData> & { handleSubmit(formValue: any): void };

class ChangePasswordForm extends PureComponent<IProps> {
  render() {
    const { invalid, pristine, submitting, handleSubmit } = this.props;

    return (
      <Fragment>
        <h1>Change password</h1>
        <form className={s.formWrapper} onSubmit={handleSubmit}>
          <FormFieldWrapper label="Current password" className={s.fieldWrapper}>
            <Field type="password" name="currentPassword" component={renderInput} />
          </FormFieldWrapper>
          <FormFieldWrapper label="New Password" className={s.fieldWrapper}>
            <Field type="password" component={renderInput} name="newPassword" />
          </FormFieldWrapper>
          <FormFieldWrapper label="ConfirmWithTextInput Password" className={s.fieldWrapper}>
            <Field type="password" component={renderInput} name="newPassword2" />
          </FormFieldWrapper>
          <div>
            <Button className={s.createBtn} type="submit" disabled={invalid || pristine || submitting}>
              Change password
            </Button>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default reduxForm<IPasswordFormData>({
  form: FORMS_NAME.password,
  validate,
})(ChangePasswordForm);
