import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';
import { RouteComponentProps, withRouter } from 'react-router';

import { AsyncActionDispatch } from '../../services/utils/ReduxHelper';
import { PortfolioActions } from '../../services/actions';

import { ISubRoutesProps } from '../../models/ISubRoutesProps';
import { IRoutesArray, RouterHelper } from '../../services/utils/RouterHelper';

import { IPortfolioStructure } from '../../models/IPortfolioStructure';
import { PnlStatus } from '../../components/PnlStatus';
import { IRootState } from '../../services/store';

interface IMapStateToProps {
  portfolioInfo: IRootState['portfolio']['portfolioInfo'];
}

interface IDispatchToProps {
  fetchPortfolioInfo: (portfolioId: string) => AxiosPromise<IPortfolio>;
  fetchPortfolioCustodians: (portfolioId: string) => AxiosPromise<ICustodian[]>;
  fetchPortfolioStructure: (portfolioId: string) => AxiosPromise<IPortfolioStructure>;
  resetPortfolioInfo: () => void;
}

type IProps = ISubRoutesProps & IMapStateToProps & IDispatchToProps & RouteComponentProps<{ portfolioId: string }>;

class PortfolioContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.match.params.portfolioId !== prevProps?.match?.params.portfolioId) {
      this.fetchData();
    }
  }

  fetchData(): void {
    const { fetchPortfolioInfo, fetchPortfolioStructure, fetchPortfolioCustodians, match } = this.props;
    const portfolioId = match.params.portfolioId;
    fetchPortfolioInfo(portfolioId).then(() => {
      fetchPortfolioStructure(portfolioId);
      fetchPortfolioCustodians(portfolioId);
    });
  }

  componentWillUnmount(): void {
    this.props.resetPortfolioInfo();
  }

  render() {
    const { subRoutes } = this.props;
    const routes: IRoutesArray = (subRoutes && RouterHelper.createSceneRoutesFromConfig(subRoutes)) || [];

    return (
      <Fragment>
        {routes}
        {!!this.props.portfolioInfo.data && (
          <PnlStatus
            portfolioInfo={this.props.portfolioInfo.data}
            modalActive={true}
            showIcon={false}
            id={'Portfolio Container'}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  portfolioInfo: state.portfolio.portfolioInfo,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPortfolioInfo: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioInfo(portfolioId)),
  fetchPortfolioCustodians: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioCustodians(portfolioId)),
  fetchPortfolioStructure: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioStructure(portfolioId)),
  resetPortfolioInfo: () => dispatch(PortfolioActions.resetPortfolioInfo()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioContainer));
