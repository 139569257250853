import React from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import TradeTime from '../../components/UnconnectedTransactionInputs/TradeTime';
import { NumberField } from '../../components/UnconnectedTransactionInputs/NumberField';
import { useFormContext } from 'react-hook-form';
import { CurrencyList } from '../../components/UnconnectedTransactionInputs/CurrencyList';
import { TextField } from '../../components/UnconnectedTransactionInputs/TextField';
import { CryptoPair } from '../../components/UnconnectedTransactionInputs/CryptoPair';
import { CustodianField } from '../../components/UnconnectedTransactionInputs/CustodianField';
import { CryptoInvisibleFxCalc } from '../../components/UnconnectedTransactionInputs/CryptoInvisibleFxCalc';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import moment from 'moment';
import { IUnconnectedAssetEntryProps } from '../../../../../../../../types/IUnconnectedAssetEntryProps';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

export const CryptoSwapEntry = ({
  editMode,
  portfolioInfo,
  editedUnconfirmedTrade,
  resetEditTrade,
  removeTrade,
  saveTrade,
  fetchTradeBlotter,
  updateTempTrade,
  initialValues,
}: IUnconnectedAssetEntryProps) => {
  const baseCurrency = portfolioInfo?.currency?.name;

  const { getValues, reset } = useFormContext();

  // Convert the data from standard trade into values suitable for driving the form
  React.useEffect(() => {
    const t = initialValues;

    const currency = editMode
      ? { value: t.currency, id: t.currency, name: t.currency, label: t.currency }
      : {
          ...t.currency,
          value: t.currency,
        };

    const formData = {
      buyQuantity: t.quantity,
      sellQuantity: t.otherQuantity,
      commission: t.commission,
      currency,
      custodian: {
        ...t.custodian,
        value: t.custodian,
      },
      // fxRate,
      notes: t.notes,
      operation: 'SWAP_L',
      settlementOption: 'LOCAL',
      tradeTime: moment(t.tradeTime).format('YYYY-MM-DD'),
      sellCurrency: t.otherSourceId
        ? {
            id: t.otherSourceId.sourceId,
            name: t.otherInstrumentName,
            value: {
              sourceId: t.otherSourceId.sourceId,
              sourceData: t.otherSourceId.sourceData,
            },
          }
        : undefined,
      buyCurrency: t.instrument
        ? {
            id: t.instrument.sourceId,
            name: t.instrument.name,
            value: {
              sourceId: t.instrument.sourceId,
              sourceData: t.instrument.sourceData,
            },
          }
        : undefined,
    };

    console.log({ formData, initialValues });

    reset(formData);
  }, [initialValues]);

  const submit = () => {
    saveTrade(getTradeDetails()).then(() => {
      fetchTradeBlotter();
      reset({
        buyCurrency: null,
        buyQuantity: null,
        sellCurrency: null,
        sellQuantity: null,
        commission: null,
      });
    });
  };

  const update = () => {
    const trade = getTradeDetails();
    updateTempTrade([{ ...trade, key: String(editedUnconfirmedTrade?.trade.key) }]).then(() => {
      fetchTradeBlotter();
      reset({
        buyCurrency: null,
        buyQuantity: null,
        sellCurrency: null,
        sellQuantity: null,
        commission: null,
      });
    });
  };

  const getTradeDetails = () => {
    const values = getValues();
    const {
      buyCurrency,
      buyQuantity,
      commission,
      currency,
      custodian,
      fxRate,
      notes,
      tradeCosts,
      sellCurrency,
      sellQuantity,
      tradeTime,
    } = values;
    const currencyString = typeof currency?.value === 'object' ? currency.value?.name : currency.value;
    const t = {
      instrument: { sourceId: buyCurrency?.value },
      otherSourceId: sellCurrency?.value,
      quantity: buyQuantity,
      otherQuantity: sellQuantity,
      commission,
      currency: currencyString,
      custodian: custodian.id,
      // fxRate,
      notes,
      operation: 'SWAP_L',
      settlementOption: 'LOCAL',
      tradeCosts,
      tradeTime: moment(tradeTime).format('YYYY-MM-DD'),
    } as any;

    return t;
  };

  return (
    <div className={cn(s.formGrid)}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime />
        <CryptoPair includeFiat={false} />
        <CryptoInvisibleFxCalc portfolioCurrency={baseCurrency ?? ''} />
      </div>
      <div className={s.formRow}>
        <NumberField label={'Commission'} name={'commission'} />
        <CurrencyList label={'Commission Ccy'} limitToBase={true} />
        <CustodianField />
        <AddCustodianButton />
        <TextField label={'Notes'} name={'notes'} />
      </div>
      <div className={s.formFooter}>
        {/*<span className={cn('text-gray', s.amountWrapper)}>Trade Gross Amount: </span>*/}
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            if (editedUnconfirmedTrade?.key) {
              removeTrade?.({ key: editedUnconfirmedTrade?.key });
            }
          }}
          isFormInvalid={false}
          editMode={editMode}
          onSaveOverride={submit}
          onUpdate={update}
        />
      </div>
    </div>
  );
};
