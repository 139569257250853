import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  label?: string;
  quantityDecimals?: number;
}

const Amount: FC<IProps> = ({ label = 'Amount', quantityDecimals }) => {
  return (
    <FormFieldWrapper label={label} required={true} className={s.minField}>
      <Field
        placeholder="0"
        name="amount"
        type="text"
        theme="inverse"
        component={renderInput}
        className="input--small"
        normalize={(value: number) => positiveNumber(floatNumberV2(value, quantityDecimals)).toString()}
      />
    </FormFieldWrapper>
  );
};

export default Amount;
