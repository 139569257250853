import React from 'react';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import { Table } from '@iliotech/storybook';
import { capitaliseFirstLetter } from '../../../../../../../../services/utils/StringHelpers';
import { Icon } from 'rsuite';
import { bulkDeleteTrades, bulkEditTrades } from '../../../TradesTable/TradesTable';
import { useHistory } from 'react-router-dom';
import { usePortfolioTrades } from '../../../../../../../../services/hooks/apiHooks/usePortfolioTrades';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { useDispatch } from 'react-redux';
import s from '../SnapshotHeader.module.scss';
import { explorerDecimalNumberRenderer } from '../../../../../PortfolioExplorer/utils/explorerCurrencyRenderer';
import useApi from '../../../../../../../../services/hooks/useApi';
interface IOwnProps {
  position: IPortfolioSnapshot;
  transactions: IPortfolioTrade[];
  portfolioId: string;
  canEditTrades: boolean;
}


export const TransactionList = React.memo(({ position, transactions, portfolioId, canEditTrades }: IOwnProps) => {

  const { isLoading, data } = useApi(`/api/v1/portfolio/${portfolioId}/position/${position.positionId}/history`);

  const dispatch = useDispatch();
  const { refetch: refetchTransactions } = usePortfolioTrades(undefined, true);

  const history = useHistory();

  const editTrade = (trade: IPositionEvent) => {
    const key = trade.type === 'SWAP_S' ? trade.relatesToKey! : trade.key!;
    bulkEditTrades(portfolioId, [key], (result) => {
      goToEdit({
        ...trade,
        key: trade.key ? result?.[trade.key] : undefined,
      });
    });
  };

  const goToEdit = (trade: IPositionEvent) => {
    const tradeToEdit = trade.type === 'SWAP_S' ? transactions.find((t) => t.key === trade.relatesToKey) : transactions.find((t) => t.key === trade.key);
    if (tradeToEdit) {
      dispatch(SnapshotActions.startEditUnconfirmedTrade(tradeToEdit, []));
    }
    history.push(`/portfolio/${portfolioId}/transactions/add/`);
  };

  const columns: {
    label: string;
    dataKey: any;
    width: number;
    align?: 'left' | 'center' | 'right';
    cellRenderer?(rowData: IPositionEvent, dataKey: any): React.ReactNode;
  }[] = [
    {
      label: 'Trade Date',
      dataKey: 'date',
      width: 90,
    },
    {
      label: 'Type',
      dataKey: 'type',
      // cellRenderer: operationCellRenderer,
      width: 120,
    },
    {
      label: 'Quantity',
      dataKey: 'quantity',
      width: 120,
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
    },
    {
      label: 'Price',
      dataKey: 'price',
      width: 120,
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
    },
    {
      label: 'Other Charges',
      dataKey: 'tradeCosts',
      align: 'right',
      width: 120,
    },
    {
      label: 'Commission',
      dataKey: 'commission',
      align: 'right',
      width: 120,
    },
    {
      label: 'Amount Local',
      dataKey: 'amount',
      width: 120,
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
    },

    {
      label: 'Historic Pnl',
      dataKey: 'historicPnl',
      width: 120,
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
    },
    {
      label: 'Trade Source',
      dataKey: 'source',
      align: 'center',
      width: 120,
      cellRenderer: (rowData) => (
        <div>{rowData.source === 'USER' ? 'User entered' : capitaliseFirstLetter(rowData.source)}</div>
      ),
    },
    ...(canEditTrades
      ? [
          {
            label: 'Actions',
            dataKey: 'key',
            width: 80,
            cellRenderer: (rowData: IPositionEvent) => {
              // if(!rowData.key){
              //   return null;
              // }

              if (['DIVIDEND_RECEIVED', 'EARN'].includes(rowData.type)) {
                return (
                  <Icon
                    onClick={() => bulkDeleteTrades(portfolioId, [rowData.key!], () => refetchTransactions?.())}
                    icon="trash"
                  />
                );
              }

              return <Icon onClick={() => editTrade(rowData)} icon="pencil" />;
            },
          },
        ]
      : []),
  ];

  return (
    <div className={s.noScrollBar} style={{ height: '100%' }}>
      <Table loading={isLoading} rowHeight={24} headerHeight={24} data={data?.events} columns={columns} />
    </div>
  );
});
