import { IActionPayload } from '../../models/redux/IActionPayload';
import { PROFIT_VIEW } from '../constants/constants';

class DashboardViewActions {
  static readonly CHANGE_PROFIT_CHART_VIEW = 'CHANGE_PROFIT_CHART_VIEW';
  static readonly RESET_PROFIT_CHART_VIEW = 'RESET_PROFIT_CHART_VIEW';

  static changeProfitView(view: PROFIT_VIEW): IActionPayload<PROFIT_VIEW> {
    return {
      type: DashboardViewActions.CHANGE_PROFIT_CHART_VIEW,
      payload: view,
    };
  }

  static resetProfitView(): IActionPayload {
    return {
      type: DashboardViewActions.RESET_PROFIT_CHART_VIEW,
    };
  }
}

export default DashboardViewActions;
