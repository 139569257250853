import React from 'react';
import { TableCellProps } from 'react-virtualized';
import getAssetIdentifier from './getAssetIdentifier';
import Toggle from '../../../../../../../components/Reusable/Toggle/Toggle';
import AssetResolver from './components/AssetResolver';
import tick from '../../../../../../../static/images/valid.svg';
import cross from '../../../../../../../static/images/warning.svg';
import AssetClassResolver from './components/AssetClassResolver';
import { IOption } from '../../../../../../../components/UIWidgets/Autocomplete';

type TriageCellRendererArgs = {
  selectResolvedAsset: (identifier: string, selectedResolvedAsset?: number) => void;
  updateAssetInformation: (identifier: string) => (value: any) => void;
  getAssetSubclass: (id?: string) => IAssetSubClass | undefined;
  baseCurrencySettlement: SettlementOption;
  localCurrencySettlement: SettlementOption;
  portfolioCurrency?: string;
  assetSubClassOptions: Array<IOption<IAssetSubClass>>;
};
const triageCellRenderer = ({
  selectResolvedAsset,
  updateAssetInformation,
  getAssetSubclass,
  baseCurrencySettlement,
  localCurrencySettlement,
  portfolioCurrency,
  assetSubClassOptions,
}: TriageCellRendererArgs) => (row: TableCellProps & { rowData: IUploadTemplateResult }): React.ReactNode => {
  const { dataKey, cellData, rowData } = row;

  const resolvedAsset =
    typeof rowData.selectedResolvedAsset === 'number'
      ? rowData.resolvedAssets[rowData.selectedResolvedAsset]
      : undefined;

  switch (dataKey) {
    case 'resolved':
      if (cellData) {
        return <img alt={'tick'} src={tick} style={{ height: 20, width: 20 }} />;
      } else {
        return <img alt={'cross'} src={cross} style={{ height: 20, width: 20 }} />;
      }
    case 'assetSubClass':
      return (
        <AssetClassResolver
          assetSubclassId={getAssetSubclass(cellData)?.id}
          item={rowData}
          updateAssetInformation={updateAssetInformation}
          onSelect={() => {}}
        />
      );
    case 'resolvedAssets':
      const { ticker, otherTicker } = rowData;

      if (rowData.assetSubClass === 'CshFXSpot') {
        return (
          <div>
            FX Spot Trade: {ticker} {otherTicker ? `- ${otherTicker}` : ''}
          </div>
        );
      }

      return (
        <AssetResolver
          item={rowData}
          onSelect={(val?: number) => {
            selectResolvedAsset(getAssetIdentifier(rowData), val);
          }}
          updateAssetInformation={updateAssetInformation}
        />
      );

    case 'currency':
      const currency = resolvedAsset?.currency;
      if (currency) {
        return <div>{currency}</div>;
      }
      break;

    case 'exchange':
      const exchange = resolvedAsset?.exchange;
      if (exchange) {
        return <div>{exchange}</div>;
      }
      break;

    case 'settlementOption':
      if (!rowData.showSettlementOption) {
        return <div />;
      }
      // Users shouldn't specify a settlement account for Cash subscriptions and withdrawals or FX trades
      if (
        resolvedAsset?.assetSubClass === 'SubCash' ||
        rowData?.assetSubClass === 'CshFXSpot' ||
        rowData?.assetSubClass === 'CshCash'
      ) {
        return <div style={{ color: 'var(--text-disabled-color)' }}>N/A</div>;
      }

      const isInBase = rowData.currency === portfolioCurrency || !rowData.currency;
      const settlementOption = cellData ?? (isInBase ? baseCurrencySettlement : localCurrencySettlement);

      return (
        <React.Fragment>
          <Toggle
            values={[
              {
                text: portfolioCurrency ?? 'Base',
                value: 'BASE',
              },
              ...(isInBase ? [] : [{ text: rowData.currency, value: 'LOCAL' }]),
              { text: 'Transfer', value: 'AUTO' },
            ]}
            onChange={(val) => updateAssetInformation(getAssetIdentifier(rowData))({ settlementOption: val })}
            selectedValue={settlementOption}
          />
          {/*{cellData}*/}
        </React.Fragment>
      );

    case 'label':
      return <div>{cellData ?? rowData.ticker ?? ''}</div>;
  }
  return <div>{typeof cellData === 'string' ? cellData : cellData === null ? '' : JSON.stringify(cellData)}</div>;
};

export default triageCellRenderer;
