import React from 'react';

export const explorerShortLongRenderer = <T,>(rowData: T, dataKey: keyof T) => {
  return (
    <div
      style={{
        textAlign: 'right',
        color: ((rowData as any).quantity as any) < 0 ? 'var(--light-blue)' : 'var(--cloudy-blue)',
      }}
    >
      {typeof rowData[dataKey] === 'number'
        ? ((rowData[dataKey] as any) as number).toLocaleString(undefined, { maximumFractionDigits: 0 })
        : rowData[dataKey]}
    </div>
  );
};

export const explorerCurrencyRenderer = <T,>(rowData: T, dataKey: keyof T) => (
  <div style={{ textAlign: 'right', color: (rowData[dataKey] as any) > 0 ? 'var(--confirm)' : 'var(--alert)' }}>
    {typeof rowData[dataKey] === 'number'
      ? ((rowData[dataKey] as any) as number).toLocaleString(undefined, { maximumFractionDigits: 0 })
      : rowData[dataKey]}
  </div>
);

export const explorerPercentageRenderer = <T,>(rowData: T, dataKey: keyof T) => (
  <div style={{ textAlign: 'right' }}>
    {typeof rowData[dataKey] === 'number' ? explorerDecimalNumberRenderer(rowData, dataKey) : rowData[dataKey]}
  </div>
);

export const explorerDecimalNumberRenderer = <T,>(
  rowData: T,
  dataKey: keyof T,
  showZeros?: boolean,
  percentage?: boolean
) => {
  const value = (rowData[dataKey] as unknown) as number;
  const assetClass = (rowData as any).assetClass || '';
  const wholes = Math.floor(Math.abs(value));
  const maxDecimals = assetClass === 'Crypto-Currencies' && (wholes + '').length <= 2 ? 7 : 2;

  let decimals = `${Math.abs((Math.abs(value) - wholes) * (value < 0 ? -1 : 1))}`.substr(2, maxDecimals);

  decimals = decimals.length === 1 ? decimals + '0' : decimals;
  if (!value || value === 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }} />
      </div>
    );
  }
  const content = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 1 }} />
      <div style={{ minWidth: 20 }}>
        {(wholes * (value < 0 ? -1 : 1)).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </div>
      <div style={{ textAlign: 'right', color: decimals.length === 0 && !showZeros ? 'transparent' : undefined }}>.</div>
      <div
        style={{
          textAlign: 'left',
          opacity: 0.5,
          color: decimals.length === 0 && !showZeros ? 'transparent' : undefined,
        }}
      >
        {decimals || '00'}
        {percentage && <span style={{ color: 'white' }}>%</span>}
      </div>
    </div>
  );

  return <div>{content}</div>;
};
