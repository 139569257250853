import * as React from 'react';
import { Checkbox, Message } from 'rsuite';
import { usePreferences } from '../../services/context/PreferencesContext';
import { useEffect, useState } from 'react';

interface IProps {
  text: string;
  prefKey: string;
}

const HintBox = ({ text, prefKey }: IProps) => {
  const { updateMiscPreference, preferences } = usePreferences();
  const [checked, setChecked] = useState(false);

  const onClose = async () => {
    if (checked) {
      await updateMiscPreference('hiddenHints', { ...preferences.misc.hiddenHints, [prefKey]: true });
    }
  };

  if (preferences.misc.hiddenHints?.[prefKey]) {
    return null;
  }
  return (
    <div>
      <Message
        onClose={onClose}
        style={{ backgroundColor: '#00BCD4', margin: 5, marginBottom: 15 }}
        showIcon={true}
        description={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{text}</span>
            <Checkbox
              style={{ marginRight: 15, marginBottom: -5 }}
              checked={checked}
              onClick={() => setChecked(!checked)}
            >
              Do not show this again
            </Checkbox>
          </div>
        }
        closable={true}
        type="info"
        header={'informational'}
      />
    </div>
  );
};

export default HintBox;
