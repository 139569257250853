import React, { Fragment, PureComponent } from 'react';
import s from './WorkGroupItemIndicator.module.scss';
import { Button } from 'rsuite';

interface IProps {
  workGroup: IPortfolio[];
}

class WorkGroupItemIndicator extends PureComponent<IProps> {
  render() {
    const { workGroup } = this.props;
    return <div className={s.indicator}>{workGroup.length}</div>;
  }
}

export default WorkGroupItemIndicator;
