import React, { Fragment, useEffect, useState } from 'react';
import { Period } from '../../../../../../../../components/Period';
import { ASSETS } from '../../../../../../../../services/constants/assetClasses';
import s from './RealAsset.module.scss';
import { getTopPositionsColumns } from './topPositionsColumns';
import {
  DASHBOARD_EQUITY_HELP_TEXT,
  DASHBOARD_REALASSET_HELP_TEXT,
} from '../../../../../../../../services/constants/tooltipContextHelp';
import Analytics from '../../../../../../../../services/utils/Analytics';
import moment from 'moment';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { realAssetCellRenderer } from './utils';
import { GlobalPnl } from '../Shared/GlobalPnl';
import { GlobalStructure } from '../Shared/GlobalStructure';
import { GlobalIncome } from '../Shared/GlobalIncome';
import { RouteComponentProps, withRouter } from 'react-router';
import { INITIAL_PERIOD } from '../../../../../../../../services/reducers/DashboardReducers/PerformanceReducers';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import AssetReportWrapper from '../../components/AssetReportWrapper/AssetReportWrapper';
import { Loader, Select } from '../../../../../../../../components';
import { PortfolioHeader } from '../../../../../../components';
import { getTopPositionsByPnLColumns } from '../Equilty/topPositionsColumns';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';

type IProps = RouteComponentProps<{ page: string }>;

const RealAssets = ({ match }: IProps) => {
  const {
    hasData,
    isLoading,
    portfolioCurrencyFormatter,
    dashboardInfo,
    portfolioInfo,
    isFetched,
    refetchTopPositions,
  } = usePortfolio(ASSETS.realAssets);
  const portfolioId = portfolioInfo.data?.id;

  const instrumentCurrencies = portfolioInfo.data?.instrumentCurrencies ? portfolioInfo.data?.instrumentCurrencies : [];
  const page = match?.params?.page || 'pnl';
  const { period, updatePeriod } = usePeriod();

  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'All', label: 'All' });
  localStorage.removeItem('selectedTab');

  useEffect(() => {
    if (portfolioId) {
      refetchTopPositions?.();
    }
  }, [portfolioId]);

  const handlePeriodChange = (newPeriod: IPeriod) => {
    updatePeriod(newPeriod);
  };

  const handleCurrencyFilterChange = (e: string) => {
    setSelectedCurrency({ value: e, label: e });
  };

  const renderCurrencySelector = (): React.ReactNode => {
    // ToDO: The Income period needs to have its OWN Period redux variable as it may differ from the performance period used in Performance chart. it's a Potential BUG
    const currencyOptions = [{ value: 'All', label: 'All' }];
    instrumentCurrencies.map((el) => currencyOptions.push({ value: el, label: el }));

    return (
      <Fragment>
        <span className={s.currencyFilterLabel}>Currency:</span>
        <Select
          options={currencyOptions}
          size="small"
          className={s.currencyFilter}
          selectedValue={selectedCurrency}
          onChange={handleCurrencyFilterChange}
        />
      </Fragment>
    );
  };

  const renderSections = (tabId: string) => {
    switch (tabId) {
      case 'pnl':
        Analytics.trackPageView('Real Assets P&L');
        return (
          <GlobalPnl
            cellRenderer={realAssetCellRenderer}
            getTopPositionColumns={getTopPositionsByPnLColumns}
            assetClass={ASSETS.realAssets}
            period={period}
            handlePeriodChange={handlePeriodChange}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
          />
        );
      case 'structure':
        Analytics.trackPageView('Real Assets Structure');
        return (
          <GlobalStructure
            getTopPositionColumns={getTopPositionsColumns}
            assetClass={ASSETS.realAssets}
            cellRenderer={realAssetCellRenderer}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
          />
        );
      case 'income':
        Analytics.trackPageView('Real Assets Income');
        return (
          <GlobalIncome
            assetClass={ASSETS.realAssets}
            renderCurrencySelector={renderCurrencySelector}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCurrency={selectedCurrency}
            portfolioId={portfolioId!}
            profitPeriod={period}
            chartOneInfo={DASHBOARD_EQUITY_HELP_TEXT.IncomeDistributions}
            chartTwoInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartEstimatedIncomeNextYear}
          />
        );
    }
  };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo.totalPnlBase.value);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_REALASSET_HELP_TEXT.PnLTotal },
    },
    {
      value: total,
      label: 'Total Real Assets',
      colored: true,
      info: { text: DASHBOARD_REALASSET_HELP_TEXT.InfoTotal },
    },
  ];

  return (
    <AssetReportWrapper title="Real Assets" noData={hasData} allLoaded={isFetched}>
      <PortfolioHeader title="Real Assets" />
      <div>
        <PortfolioSubHeader stats={stats} title={page} />
        <div className={s.assetsDashboardGrid}>{renderSections(page)}</div>
      </div>
    </AssetReportWrapper>
  );
};

export default withRouter(RealAssets);
