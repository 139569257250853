import React, { useEffect } from 'react';
// import PnlByAttributionChart from '../PortfolioLevelPnlChart/PnlByAttributionChart';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { LineData } from '../../../../../../../../models/LineData';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';
import { Loader } from '../../../../../../../../components';
import PnlByAssetClassChartNew from '../PortfolioLevelPnlChart/PnlByAssetClassChartNew';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';
import { useCustodiansFilter } from '../../../../../../../../services/hooks/useCustodiansFilter';

interface IProps {
  viewPositionHistory?(dateString: string): void;
  hideIncome?: boolean;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  period: IPeriod;
  portfolioId: string;
  assetClassId?: string;
  changePerformancePeriod?: (period: IPeriod) => void;
  indexName?: string;
  indexPerformance?: LineData;
  hidePeriodSelector?: boolean;
}

const PnlByAssetClassChartContainer = ({
  // indexPerformance,
  // indexName,
  // changePerformancePeriod,
  period,
  portfolioCurrencyFormatter,
  viewPositionHistory,
  // hideIncome,
  portfolioId,
  hidePeriodSelector,
  assetClassId,
}: IProps) => {
  /* const portfolioPerformance = useApi(getApiUrl('portfolio.performance'), {
    method: 'POST',
    data: {
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(period),
      detalization: DetalizationType.Daily,
      custodians: [],
    },
  }); */

  const { preferences } = useCapabilities();
  const { portfolioInfo } = usePortfolioInfo();
  const riskAssetView = portfolioInfo.data?.riskAssetView ?? preferences.defaultRiskAssetView;
  const isRiskAssetView = riskAssetView === 'RISK_ASSET_CLASS';
  const { selectedCustodiansArray } = useCustodiansFilter();
  const noCustodians = !!assetClassId;

  const portfolioPnlByAttribution = useApi(getApiUrl('portfolio.profit.pnlByAssetClass'), {
    method: 'POST',
    data: {
      assetType: isRiskAssetView ? 'RiskAssetClass' : 'AssetClass',
      portfolioId,
      assetClassId,
      period: PeriodHelper.preparePeriodForRequest(period),
      custodians: noCustodians ? [] : selectedCustodiansArray,
    },
  });

  useEffect(() => {
    portfolioPnlByAttribution.refetch();
  }, [period]);

  const isFetched = portfolioPnlByAttribution.isFetched;

  if (!isFetched) {
    return <Loader />;
  }

  return (
    <PnlByAssetClassChartNew
      selectedPeriod={period}
      hidePeriodSelector={hidePeriodSelector}
      isDataLoaded={portfolioPnlByAttribution.isFetched}
      chartData={portfolioPnlByAttribution.data}
      portfolioCurrencyFormatter={portfolioCurrencyFormatter}
      onItemClick={viewPositionHistory}
    />
  );
};

export default PnlByAssetClassChartContainer;
