import { IRootState } from '../store';

import { createSelector } from 'reselect';
import { IOption } from '../../components/UIWidgets/Autocomplete';

export function getCountry(state: IRootState): ICountry[] {
  return state.country.data;
}

export const getCountryOptions = createSelector<IRootState, ICountry[], Array<IOption<ICountry>>>(
  getCountry,
  (countryList: ICountry[]): Array<IOption<ICountry>> => {
    return countryList.map((country) => {
      return {
        id: country.code,
        value: country,
        name: country.countryName,
      };
    });
  }
);
