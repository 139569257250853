export const BRAND_PRIMARY_ACCENT = '#6677CC';
export const BRAND_WARN = '#F2994A';
export const CHART_COLORS = [
  '#11141E',
  '#c2b261',
  '#59abb3',
  '#4162d9',
  '#3C3A3B',
  '#445369',
  '#6677cc',
  '#59abb3',
  '#c2b261',
  '#cc6f66',
  '#56b877',
  '#cc66aa',
  '#85b861',
  '#a266cc',
  '#a50009',
  '#6397ff',
  '#41d9a0',
  '#fb9900',
  '#e13800',
  '#0077b2',
  '#e13800',
  '#0077b2',
  '#f27800',
  '#fb9900',
  '#00b26f',
  '#d9bf41',
];
