import React, { useCallback, useMemo } from 'react';
import { IPortfolioInfoState, IPortfolioStructureState } from '../../services/reducers/PortfolioReducers';
import { IRootState } from '../../services/store';
import { Auth } from 'aws-amplify';
import { persistor } from '../../index';
import { RouteComponentProps, withRouter } from 'react-router';
import cn from 'classnames';
import s from './Sidebar.module.css';
import { CLIENT_MENU, ACCOUNT_MANAGEMENT_MENU, PORTFOLIO_MENU } from '../../services/constants/navigation';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from '../../router/paths';
import Menu, { MenuItem } from './components/Menu';
import { Button } from '../UIWidgets';
import UserAvatar from '../UserAvatar/UserAvatar';
import { Icon, Popover, Whisper } from 'rsuite';
import { SearchContainer } from '../../scenes/WorkGroupManager/components/SearchBar/SearchContainer';
import moment from 'moment';
import { AiOutlineInfoCircle, AiOutlineSetting, AiTwotoneAlert } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import CapabilitiesHelper from '../../services/utils/CapabilitiesHelper';
import { SidePeriod } from './components/SidePeriod';
import { ALLOCATION_TYPES, CHARTS_PERIODS } from '../../services/constants/constants';
import { usePreferences } from '../../services/context/PreferencesContext';
import { usePortfolio } from '../../services/hooks/usePortfolio';
import { portfolio } from '../../scenes/Portfolio/scenes/Snapshot/components/SnapshotTable/__testdata__/sampleSnapshotData';

interface IProps {
  userInfo: IUser | null;
  portfolioInfo: IPortfolio | null;
  portfolioStructure: IPortfolioInfoState;
  portfolioCoreStructure: IPortfolioStructureState;
  accounts: IRootState['accounts'];
  accountImageUrl?: string;
  themeMetaData?: IThemeMetaData;
  userCapabilities?: IRootState['userCapabilities']['data'];
  onLogOut(): Promise<any>;
  selectIllioAccount?(id: string | number | null): void;
}

type Props = IProps & RouteComponentProps;

const SidebarInner = ({
  onLogOut,
  history,
  selectIllioAccount,
  portfolioInfo,
  portfolioCoreStructure,
  userCapabilities,
  accounts,
  themeMetaData,
  userInfo,
}: Props) => {
  const portfolioId = portfolioInfo?.id;
  const showESG = portfolioInfo?.allocation?.AssetClass.find((el: any) => el.name === 'Equities' || el.name === 'Funds');
  const [isOpen, setIsOpen] = React.useState(true);
  const { preferences, updateMiscPreference } = usePreferences();
  const { portfolioAssetClassIds } = usePortfolio();

  const getHomeLink = '/workgroups';

  React.useEffect(() => {
    if (portfolioId && portfolioInfo?.confirmedTrades === 0 && portfolioInfo?.unconfirmedTrades === 0) {
      history.push(PATHS.portfolio.empty.path.replace(':portfolioId', portfolioId));
    }
  }, [portfolioId]);

  React.useEffect(() => {
    if (typeof preferences?.misc?.menu?.expanded !== 'undefined') {
      setIsOpen(!!preferences?.misc?.menu?.expanded);
    }
  }, [preferences?.misc]);

  const actions = userCapabilities?.actions;

  const canAddTrades = actions?.includes(CapabilitiesHelper.Actions.ADD_TRADE);

  const dispatch = useDispatch();

  const handleLogout = () => {
    onLogOut().then(() => {
      Auth.signOut().then(() => {
        window.location.reload();
      });
    });
  };

  const toggleMenu = () =>
    setIsOpen((prev) => {
      updateMiscPreference('menu', {
        ...preferences.misc?.menu,
        expanded: !prev,
      });
      return !prev;
    });

  const switchAccount = () => {
    persistor.purge().then(() => {
      selectIllioAccount?.(null);
      history.push('/');
    });
  };

  const addTransaction = () => {
    if (history.location.pathname === `/portfolio/${portfolioId}/transactions/add`) {
      window.location.reload();
    } else {
      history.push(`/portfolio/${portfolioId}/transactions/add`);
    }
  };

  const renderPeriodselector = () => {
    if (!portfolioId) {
      return <div />;
    }
    return <SidePeriod isOpen={isOpen} inceptionDate={portfolioInfo?.inceptionDate} options={CHARTS_PERIODS} />;
  };

  const CURRENT_MENU = useMemo(() => {
    let currentMenu = PORTFOLIO_MENU;
    if (!showESG) {
      currentMenu = currentMenu.filter((item) => item.id !== 'esg');
    }

    return currentMenu;
  }, [showESG, portfolioInfo]);

  const renderPortfolioMenu = () => {
    if (!portfolioId) {
      return <div />;
    }

    const menu = portfolioInfo?.confirmedTrades === 0 ? [...CURRENT_MENU].filter((o) => o.showEmpty) : [...CURRENT_MENU];

    // menu.splice(-1, 0, workgroupMenu);

    const canEditPortfolio =
      CapabilitiesHelper.isActionAllowed(CapabilitiesHelper.Actions.SHARE_PORTFOLIO, userCapabilities) ||
      CapabilitiesHelper.isActionAllowed(CapabilitiesHelper.Actions.DELETE_PORTFOLIO, userCapabilities);

    const isAdmin = CapabilitiesHelper.isActionAllowed(CapabilitiesHelper.Actions.LIST_ACCOUNTS, userCapabilities);

    const recursiveSubmenuHandler: (nodes: IMenuItem[]) => IMenuItem[] = (nodes) => {
      return nodes
        .filter((o) => !o.disabled)
        .map((node) => {
          const newNode = {
            ...node,
            path: node.path.replace(':portfolioId', portfolioId),
          };

          if (node.submenu) {
            newNode.submenu = recursiveSubmenuHandler(node.submenu);
          }

          return newNode;
        });
    };

    const enabledMenuItems = menu.map((item) => {
      let submenu: IMenuItem[] = [];
      if (Array.isArray(item.submenu)) {
        const enabledSubMenu =
          item.id === 'structure'
            ? [...item.submenu.filter((current) => portfolioAssetClassIds.includes(current.id))]
            : item.submenu;
        submenu = enabledSubMenu.map((submenuItem: IMenuItem) => ({
          ...submenuItem,
        }));
      }
      return {
        ...item,
        submenu,
      };
    });

    const menuItems = recursiveSubmenuHandler(enabledMenuItems);

    const portfolioHasNegativeValue = (portfolioInfo?.totalWealth || 0) < 0 || (portfolioInfo?.performance || 0) <= -100;
    const lessThanSixMonths = moment().diff(moment(portfolioInfo?.inceptionDate), 'months') < 6;
    return (
      <div className={cn(s.menuSection, s.noBorder)}>
        <Menu
          showLabel={isOpen}
          portfolioHasNegativePnl={portfolioHasNegativeValue}
          items={menuItems}
          userCapabilities={userCapabilities}
          lessThanSixMonths={lessThanSixMonths}
        />
        {isOpen && canAddTrades && (
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button className={cn(s.fullWidthButton)} onClick={addTransaction}>
              Add Transaction
            </Button>
          </div>
        )}
        {canEditPortfolio && (
          <MenuItem
            id={'portfolioSettings'}
            hasSubMenu={false}
            path={`/portfolios/settings/${portfolioInfo?.id}`}
            title={'Portfolio Settings'}
            showLabel={isOpen}
            icon={<AiOutlineSetting size={20} />}
            speaker={
              isOpen ? (
                <div />
              ) : (
                <Popover>
                  <div style={{ fontSize: '0.9rem' }}>Portfolio Settings</div>
                </Popover>
              )
            }
          />
        )}
        {isAdmin && (
          <MenuItem
            id={'insights'}
            hasSubMenu={false}
            path={`/portfolio/${portfolioInfo?.id}/insights`}
            title={'Portfolio Insights'}
            showLabel={isOpen}
            icon={<AiTwotoneAlert size={20} />}
            speaker={
              isOpen ? (
                <div />
              ) : (
                <Popover>
                  <div style={{ fontSize: '0.9rem' }}>Portfolio Insights</div>
                </Popover>
              )
            }
          />
        )}
      </div>
    );
  };

  const renderWorkgroupMenu = () => {
    // if (!portfolioInfo) {
    //   return null;
    // }

    const show = CapabilitiesHelper.isActionAllowed(CapabilitiesHelper.Actions.ADD_REALASSET, userCapabilities);
    const showClient = CapabilitiesHelper.isActionAllowed(CapabilitiesHelper.Actions.ASSIGN_WORKGROUP, userCapabilities);
    const hasPortfolioInfo = !!portfolioInfo;
    if (!show) {
      return null;
    }
    return (
      <div className={cn(s.menuSection)}>
        {isOpen && hasPortfolioInfo && showClient && (
          <div className={cn(s.portfolioLabelSurround, !isOpen && s.closed)}>
            <div className={cn(s.label)}>{`Client: ${portfolioInfo?.clientName ?? 'Unassigned'}`}</div>
          </div>
        )}
        <Menu
          showLabel={isOpen}
          items={CLIENT_MENU.filter((item) => hasPortfolioInfo || item.showEmpty).map((item) => ({
            ...item,
            path: item.path.replace(':portfolioId', portfolioId ?? ''),
            submenu: item.submenu?.map((subItem) => ({
              ...subItem,
              path: subItem.path.replace(':portfolioId', portfolioId ?? ''),
            })),
          }))}
          userCapabilities={userCapabilities}
        />
      </div>
    );
  };

  const renderAccountMenu = () => {
    // commenting out a
    const show = false;

    if (!show) {
      return null;
    }

    return (
      <div className={cn(s.menuSection)}>
        {isOpen && <label className={cn(s.label)}>Account</label>}
        <Menu showLabel={isOpen} items={ACCOUNT_MANAGEMENT_MENU} userCapabilities={userCapabilities} />
      </div>
    );
  };
  const renderProfile = () => {
    const profileMenu = (
      <Popover>
        <div className={s.userMenu}>
          <Link id="settings" to={PATHS.account.settings.path}>
            Settings
          </Link>
          <a id="legal" href={'https://www.illio.com/privacy-policy'} target={'_blank'}>
            Privacy Policy
          </a>
          {accounts?.data?.length > 1 && (
            <Button style={{ fontSize: '0.9rem' }} id="switchAccount" variant={'empty'} onClick={switchAccount}>
              Switch Account
            </Button>
          )}
          <Button style={{ fontSize: '0.9rem' }} id="logout" variant="empty" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Popover>
    );

    return (
      <React.Fragment>
        <Whisper speaker={profileMenu} placement={'rightEnd'} enterable={true} trigger={'hover'}>
          <Button id="userInfo" variant="empty" style={{ width: '100%' }}>
            <div className={cn(s.avatarWrapper)}>
              <div className={s.avatarContainer}>
                {<UserAvatar user={userInfo} size={34} overrideColor={themeMetaData?.colors?.['--primary-color']} />}
              </div>
              {isOpen && (
                <span className={s.navigationText}>
                  {userInfo !== null ? `${userInfo.firstName ?? 'Profile'} ${userInfo.lastName ?? ''}` : 'Profile'}
                </span>
              )}
            </div>
          </Button>
        </Whisper>
      </React.Fragment>
    );
  };
  const renderToggle = () => {
    const systemDate = `System date: ${moment().format('D MMM YYYY')}`;

    return (
      <div onClick={toggleMenu} className={cn(s.menuToggleSurround)}>
        <div className={cn(s.systemDate, !isOpen && s.closed)}>{systemDate}</div>

        <Icon
          className={cn(s.menuToggle, isOpen && s.open)}
          icon={'angle-double-right'}
          size={'2x'}
          color={'var(--darkgray)'}
        />
      </div>
    );
  };

  const renderLogoArea = () => {
    if (themeMetaData?.icon) {
      if (isOpen) {
        return (
          <Link to={'/workgroups'}>
            <div className={cn(s.top)}>
              <img src={themeMetaData.icon} alt={'Company Logo'} className={cn(s.whiteLabelLogo)} />
              <div className={cn(s.poweredBySurround)}>
                <div className={cn(s.poweredByText)}>Powered by</div>
                <div className={cn(s.logo)} />
              </div>
            </div>
          </Link>
        );
      }
      return (
        <Link to={'/workgroups'}>
          <div className={cn(s.top, s.flexEnd)}>
            <img src={themeMetaData.icon} alt={'Company Logo'} className={cn(s.whiteLabelLogo, s.closed)} />
          </div>
        </Link>
      );
    }

    return (
      <Link to={getHomeLink}>
        <div className={cn(s.top, s.flexEnd)}>
          <div className={cn(s.logo, isOpen && s.open)} />
          {isOpen && <div className={cn(s.brandName)}>Home</div>}
        </div>
      </Link>
    );
  };

  const canCreatePortfolio = CapabilitiesHelper.isActionAllowed('ADD_PORTFOLIO', userCapabilities);

  return (
    <div className={cn(s.surround, !isOpen && s.closed)}>
      <div>
        {renderLogoArea()}

        {canCreatePortfolio && isOpen && (
          <Link
            to={{
              pathname: PATHS.portfoliosManager.portfolioCreator.path,
              state: { modal: true },
            }}
          >
            <div style={{ display: 'flex' }}>
              <Button size={'small'} className={cn(s.fullWidthButton)}>
                Create New Portfolio
              </Button>
            </div>
          </Link>
        )}
        <SearchContainer
          key={portfolioInfo?.id}
          className={cn(s.searchBar)}
          iconOnly={!isOpen}
          onFocus={() => setIsOpen(true)}
        />
        {renderPeriodselector()}
      </div>
      <div className={cn(s.menuContent)}>
        {/*<div style={{ flex: 1 }} />*/}
        {renderPortfolioMenu()}
        {renderWorkgroupMenu()}
        <div style={{ flex: 1 }} />
        <div>
          {renderAccountMenu()}
          <div className={cn(s.menuSection)}>
            <Whisper
              speaker={
                !isOpen ? (
                  <Popover>
                    <div style={{ fontSize: '0.9rem' }}>Show / Hide Hints</div>
                  </Popover>
                ) : (
                  <div />
                )
              }
            >
              <div
                onClick={() => dispatch({ type: 'TOGGLE_CONTEXT_HELP' })}
                className={cn(s.infoButtonSurround, !isOpen && s.closed)}
              >
                <div className={cn(s.infoButton)}>
                  <AiOutlineInfoCircle size={20} />
                </div>
                {isOpen && <div className={cn(s.infoText)}>Show / Hide Hints</div>}
              </div>
            </Whisper>
          </div>
          {renderProfile()}
          {renderToggle()}
        </div>
      </div>
    </div>
  );
};

export const Sidebar = withRouter(SidebarInner);
