import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';

import Scenarios from './Scenarios';
import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../components/HOC/withPortfolioInfo';

import ScenariosActions from '../../../../services/actions/ScenariosActions';
import { IAllocationDict } from '../../../../models/IAllocationDict';
import { IRootState } from '../../../../services/store';

import { IScenarioModel } from '../../../../services/reducers/ScenariosReducers';
import { Loader } from '../../../../components';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';

import {
  getPortfolioCurrencyCode,
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../services/selectors/portfolio';
import Analytics from '../../../../services/utils/Analytics';

interface IMapStateToProps {
  allocations: IAllocationDict;
  portfolioModels: IScenarioModel[];
  profitChartData: IBarData[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  fetchPortfolioModels: (portfolioId: string) => void;
  fetchAllocationsList: (portfolioId: string) => AxiosPromise<IAllocationDict>;
  fetchProfit: (portfolioId: string) => AxiosPromise<IBarData[]>;
  resetScenarioData: () => void;
}

type IProps = IMapStateToProps & IDispatchToProps & IWithPortfolioInfoProps;

interface IState {
  allLoaded: boolean;
}

class ScenariosContainer extends PureComponent<IProps, IState> {
  readonly state: IState = {
    allLoaded: false,
  };

  componentDidMount(): void {
    this._fetchData().then(() => {
      this.setState({ allLoaded: true });
    });
    Analytics.trackPageView('Scenario');
  }

  componentWillUnmount(): void {
    this.props.resetScenarioData();
  }

  render() {
    const { allLoaded } = this.state;
    const { allocations, portfolioModels, profitChartData, portfolioCurrencyFormatter } = this.props;
    if (!allLoaded) {
      return <Loader />;
    }
    const totalPL = profitChartData.length
      ? profitChartData[0].data.reduce((accum: number, cur: [string, number]) => {
          return accum + cur[1];
        }, 0)
      : 0;

    return (
      <Scenarios
        models={portfolioModels}
        allocations={allocations}
        profitChartData={profitChartData}
        totalPortfolio={totalPL}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
      />
    );
  }

  private _fetchData(): Promise<any> {
    const { portfolioInfo, fetchPortfolioModels, fetchAllocationsList, fetchProfit } = this.props;
    const portfolioId = portfolioInfo.id;

    // todo: move it in promise all after test data will be removed
    fetchPortfolioModels(portfolioId);

    return Promise.all([fetchAllocationsList(portfolioId), fetchProfit(portfolioId)]);
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    portfolioModels: state.scenario.modelsList.data,
    allocations: state.scenario.allocations.data,
    profitChartData: state.scenario.profit.data,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchAllocationsList: (portfolioId: string) => dispatch(ScenariosActions.fetchScenarioAllocationsList(portfolioId)),
  fetchPortfolioModels: (portfolioId: string) => dispatch(ScenariosActions.fetchPortfolioModels(portfolioId)),
  fetchProfit: (portfolioId: string) => dispatch(ScenariosActions.fetchProfit(portfolioId)),
  resetScenarioData: () => dispatch(ScenariosActions.resetScenarioData()),
});

export default withPortfolioInfo(connect(mapStateToProps, mapDispatchToProps)(ScenariosContainer));
