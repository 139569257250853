import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { ReduxHelper } from '../utils/ReduxHelper';
import { UserCapabilitiesActions } from '../actions/UserCapabilitiesActions';

export interface IUserCapabilitiesState extends ILoadingDataState {
  data: IUserCapabilities;
}

const INITIAL_STATE: IUserCapabilitiesState = ReduxHelper.createInitialState<IUserCapabilities>({
  capabilities: [{ key: 'Equities' }],
  actions: [],
  preferences: {},
  accounts: [],
});

const userCapabilitiesReducer = ReduxHelper.createHttpRequestReducer<IUserCapabilitiesState>(
  INITIAL_STATE,
  UserCapabilitiesActions.USER_CAPABILITIES_REQUEST,
  UserCapabilitiesActions.USER_CAPABILITIES_SUCCESS,
  UserCapabilitiesActions.USER_CAPABILITIES_ERROR
);

export default userCapabilitiesReducer;
