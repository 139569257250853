import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment from 'moment';

import { BarType, DEFAULT_CHART_OPTIONS, DEFAULT_XAXIS_OPTIONS } from '../../../../../services/constants/charts';
import { ICurrencyFormatter } from '../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../../services/utils/FormatHelper';

export function getProfitChartOptions(
  // chartData: IBarData[],
  chartData: any[],
  yAxisValueType: VALUE_TYPE = VALUE_TYPE.MONEY,
  portfolioCurrencyFormatter: ICurrencyFormatter,
  performanceToggle?: number | 1
): echarts.EChartOption {
  const yAxisToggledValueType = performanceToggle === 0 ? VALUE_TYPE.PERCENTAGE : VALUE_TYPE.MONEY;

  const chartOpt = ChartHelper.getChartOptions({
    tooltipValueType: yAxisToggledValueType,
    portfolioCurrencyFormatter,
  });

  // @ts-ignore
  const mergeOpt: echarts.EChartOption = {
    grid: {
      y2: 15,
      x2: 18,
    },
    ...DEFAULT_CHART_OPTIONS,
    xAxis: {
      ...DEFAULT_XAXIS_OPTIONS,
      axisLabel: {
        rotate: 30,
        textShadowOffsetX: 20,
        // showMinLabel: false,
        //   showMaxLabel: true,
        // interval: 0,
        formatter(value: string): any {
          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const momentVal = moment(value);
            return momentVal.format("MMM'YY");
          }
          const words = value.toString().split(' ');
          if (words.length > 2) {
            return words[0];
          }
          return value;
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return performanceToggle === 0 ? FormatHelper.formatPercentage(value) : portfolioCurrencyFormatter(value);
        },
      },
    },
    visualMap: {
      // @ts-ignore
      type: 'piecewise',
      show: false,
      pieces: [
        {
          gt: 0,
          color: '#6677cc',
        },
        {
          lte: 0,
          color: '#3b4680',
        },
      ],
    },
    series: prepareSeries(chartData, performanceToggle),
  };

  return merge({}, chartOpt, mergeOpt);
}

function prepareSeries(data: IBarData[], performanceToggle?: number | 0): echarts.EChartOption.SeriesBar[] {
  return data.map((item: IBarData) => {
    const barOpt =
      item.type === BarType.Hatching ? ChartHelper.getHatchingBarSeriesOptions() : ChartHelper.getBarSeriesOptions();

    const seriesName = performanceToggle === 0 ? 'Portfolio' : performanceToggle === 1 ? 'P&L' : item.name;

    const splitData =
      performanceToggle === 0
        ? data[0].data.map((el: any[]) => [el[0], el[2]])
        : data[0].data.map((el: any[]) => [el[0], el[1]]);

    return {
      ...barOpt,
      name: seriesName,
      data: splitData,
      stack: 'profit',
    };
  });
}
