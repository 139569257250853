import React, { Fragment, PureComponent, useEffect, useRef, useState } from 'react';
import { IRootState } from '../../services/store';
import { Button, Loader } from '../../components';
import s from './WorkGroupManager.module.scss';
import { PortfolioWorkGroupItem } from './components/WorkGroupItem';
import GroupWorkViewInfo from './components/WorkGoupViewInfo/WorkGroupViewInfo';
import SearchBar from './components/SearchBar/SearchBar';
import NotificationContainer from './components/WarningsContainer';
import { getPortfolioLink, getPortfoliosLink } from '../PortfoliosManager/utils';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOption } from '../../components/UIWidgets/Autocomplete';
import _ from 'lodash';
import useApi, { sendRequest } from '../../services/hooks/useApi';
import { getApiUrl, getCustomApiUrl } from '../../services/constants/endpoints';
import { Utils } from '../../services/utils/Utils';
import { NotificationType } from '../../models/NotifictionType';
import WorkGroupEmptyContainer from './components/WorkGroupEmptyContainer/WorkGroupEmptyContainer';
import { PageHeader } from '../Portfolio/components/PortfolioHeader/PortfolioHeader';

interface IProps {
  isFetched: boolean;
  showNotification: any;
  isFetching?: boolean;
  currencyOptions: Array<IOption<ICurrency>>;
  authInfo: IAuthInfo | null;
  userCapabilities: IRootState['userCapabilities']['data'];
}

type Props = IProps & RouteComponentProps;

const WorkGroupManager = ({ showNotification, currencyOptions, userCapabilities, history }: Props) => {
  const [currency, setCurrency] = useState<ICurrencyShort>({ name: 'USD', symbol: '$' });

  const [portfolioWorkGroups, setPortfoliosWorkgroup] = useState<IPortfolio[][]>([]);
  const [filteredWorkGroups, setFilteredWorkGroups] = useState<IPortfolio[][]>([]);
  const [portfoliosList, setPortfoliosList] = useState<IPortfolio[]>([]);

  // const [cardSize, setCardSize] = useState<'Small' | 'Big'>(portfolioWorkGroups.length > 16 ? 'Small' : 'Big');

  const [filters, setFilters] = useState<IWorkGroupWarningTypes[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const metadata = userCapabilities?.metadata;
  const canViewWorkgroups = metadata?.allowedViews?.includes('listWorkgroups');

  const { data, isFetching, refetch, dataUpdatedAt } = useApi(
    getApiUrl('portfolioManager.list'),
    {
      method: 'POST',
      data: {
        displayCurrency: currency.name,
      },
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (res: IPortfolio[]) => {
        setPortfoliosList(res);
        const workgroups: IPortfolio[][] = Object.values(_.groupBy(res, (i) => i?.workgroup?.id));
        if (!canViewWorkgroups && workgroups.length > 0) {
          history.push('portfolios/' + workgroups[0][0].workgroup.id);
        }
        setPortfoliosWorkgroup(workgroups);
        if (filters.length > 0) {
          filterList(workgroups);
        } else {
          setFilteredWorkGroups(workgroups);
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [currency]);

  useEffect(() => {
    filterList();
  }, [filters]);

  const handleWorkGroupRename = (workgroup: IPortfolio[]) => {
    Utils.createConfirmWithInput({
      title: 'New name',
      defaultValue: workgroup[0].workgroup.name,
      confirmBtnText: 'Submit',
      onConfirm: (name: string) => {
        const hasName = portfoliosList.find((w) => w.workgroup.name === name);
        if (hasName) {
          return showNotification({ text: 'Workgroup name already exists', type: NotificationType.ERROR });
        }
        sendRequest(
          getCustomApiUrl('workgroups.updateName', [
            { idLabel: ':workgroupId', idValue: `${workgroup[0]?.workgroup?.id}` },
          ]),
          {
            method: 'PUT',
          },
          { name }
        )
          .then((res) => {
            refetch();
          })
          .catch((err) => {
            showNotification({ text: 'Workgroup hasn`t been updated', type: NotificationType.ERROR });
          });
      },
    });
  };

  const handlePortfolioDelete = (portfolio: IPortfolio) => {
    Utils.createConfirm({
      text: `Do you really want to remove <b>${portfolio.name}</b> portfolio? This action cannot be undone.`,
      confirmBtnText: 'Remove',
      onConfirm: () => {
        sendRequest(
          getApiUrl('portfolioManager.delete'),
          {
            method: 'POST',
          },
          { portfolioId: portfolio.id }
        )
          .then((res) => {
            refetch();
          })
          .catch((err) => {
            showNotification({ text: 'Portfolio hasn`t been removed', type: NotificationType.ERROR });
          });
      },
    });
  };

  const onValueSelected = (item: ICurrencyShort) => {
    setCurrency(item);
  };

  const renderList = (): React.ReactNode => {
    if (isFetching) {
      return <Loader />;
    }

    if (filteredWorkGroups.length === 0) {
      return <WorkGroupEmptyContainer />;
    }

    return (
      <div ref={containerRef} className={s.cardsContainer}>
        {filteredWorkGroups
          .filter((w) => w[0].workgroup)
          .sort((a, b) => a[0].workgroup.clientUser?.localeCompare(b[0].workgroup.clientUser))
          .map((item) => (
            <PortfolioWorkGroupItem
              parentRef={containerRef}
              onDelete={handlePortfolioDelete}
              onRename={handleWorkGroupRename}
              cardSize={'Big'}
              key={item[0].id}
              workGroup={item}
              userCapabilities={userCapabilities}
            />
          ))}
      </div>
    );
  };

  const onComboValueSelected = (item: IComboItemWithCategory) => {
    if (item.category === 'CLIENTS') {
      const workgroupId = item.value;
      history.push(getPortfoliosLink(workgroupId.toString()));
    } else {
      const portfolioId = item.value;
      const portfolio = Array.isArray(portfoliosList) ? portfoliosList?.find((p) => p.id === portfolioId) : undefined;
      if (portfolio) {
        history.push(getPortfolioLink(portfolio));
      }
    }
  };

  const comboItems = () => {
    const portfolios: IComboItemMulti = {
      title: 'Portfolios',
      items: Array.isArray(portfoliosList)
        ? portfoliosList?.map((p) => ({ value: p.id, label: p.name, category: 'PORTFOLIOS' })) ?? []
        : [],
    };

    const clients: IComboItemMulti = {
      title: 'Clients',
      items: portfolioWorkGroups
        .filter((p) => p[0].workgroup)
        ?.map((p) => ({
          value: p[0].workgroup?.id,
          label: p[0].clientName,
          category: 'CLIENTS',
        })),
    };

    return [portfolios, clients];
  };

  const workgroupUnassigned = (w: IPortfolio[]) => w[0].workgroup?.clientUser === null;
  const hasNegativeValues = (w: IPortfolio[]) => w.some((p) => p.totalWealth < 0);
  const hasTradeInTheBlotter = (w: IPortfolio[]) => w.some((p) => p.blotterTrades?.totalTrades > 0);

  const filterList = (workgroups?: IPortfolio[][]) => {
    let newWorkGroups: IPortfolio[][] = workgroups || portfolioWorkGroups;
    if (filters.includes('UNASSIGNED')) {
      newWorkGroups = newWorkGroups.filter(workgroupUnassigned);
    }
    if (filters.includes('BLOTTER')) {
      newWorkGroups = newWorkGroups.filter(hasTradeInTheBlotter);
    }
    if (filters.includes('NEGATIVES')) {
      newWorkGroups = newWorkGroups.filter(hasNegativeValues);
    }
    setFilteredWorkGroups(newWorkGroups);
  };

  const removeFilter = (filter: IWorkGroupWarningTypes) => {
    setFilters((oldFilters) => oldFilters.filter((f) => f !== filter));
  };

  const getWarnings = () => {
    const warnings: IWarning[] = [];

    const unassignedWorkgroups = portfolioWorkGroups.filter(workgroupUnassigned);
    if (unassignedWorkgroups.length > 0) {
      const unassignedWarning: IWarning = {
        type: 'UNASSIGNED',
        count: unassignedWorkgroups.length,
      };
      warnings.push(unassignedWarning);
    }

    const negativeValuesWorkgroups = portfolioWorkGroups.filter(hasNegativeValues);
    if (negativeValuesWorkgroups.length > 0) {
      const negativeWarning: IWarning = {
        type: 'NEGATIVES',
        count: negativeValuesWorkgroups.length,
      };
      warnings.push(negativeWarning);
    }

    const blotterWorkgroups = portfolioWorkGroups.filter(hasTradeInTheBlotter);
    if (blotterWorkgroups.length > 0) {
      const blotterWarning: IWarning = {
        type: 'BLOTTER',
        count: blotterWorkgroups.length,
      };
      warnings.push(blotterWarning);
    }

    return warnings;
  };

  const renderFilters = () => {
    return filters.map((filter) => (
      <div key={filter} className={s.filterItem}>
        <span>Showing {filter.toLowerCase()}</span>
        <span onClick={() => removeFilter(filter)} className={s.close}>
          x
        </span>
      </div>
    ));
  };

  if (isFetching) {
    return <Loader />;
  }
  return (
    <div>
      <PageHeader title="Client Manager" />
      <div className={s.groupWorkHeader}>
        <GroupWorkViewInfo onValueSelected={onValueSelected} portfoliosList={data!} currencyOptions={currencyOptions} />
        <SearchBar onValueSelected={onComboValueSelected} comboItems={comboItems()} />
        <NotificationContainer activeWarnings={filters} applyFilters={setFilters} warnings={getWarnings()} />
      </div>
      <div className={s.workGroupListHeader} />
      {renderList()}
    </div>
  );
};

export default withRouter(WorkGroupManager);
