import React, { useRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getSysadminUrl } from '../../services/constants/endpoints';

export const AdminContainer = () => {
  const initialPath = React.useMemo(() => {
    if (window.location.pathname.indexOf('/administration/') === 0) {
      return window.location.pathname.substr('/administration/'.length);
    }
    return '';
  }, []);
  const iframe = useRef<any>(null);
  const [iframePath, setIframePath] = React.useState(initialPath);
  const [path, setPath] = React.useState(initialPath);
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  React.useEffect(() => {
    if (!history.location.search.includes('loadframe=true')) {
      return;
    }
    const route = history.location.pathname.substr('/administration/'.length);
    if (!!route) {
      setIframePath(route);
      history.push(history.location.pathname);
    }
  }, [match, history.location]);

  React.useEffect(() => {
    const handleMessages = (event: any) => {
      if (event?.data?.message === 'changePage' && !!event.data?.value) {
        setPath(event.data.value);
      } else if (event?.data?.message === 'goBack' && !!event.data?.value) {
        const url: any = event.data?.value;
        history.push(url);
      } else if (event?.data?.message === 'loaded' && !!event.data?.value) {
        iframe.current?.contentWindow?.postMessage(
          { message: 'prevPath', value: (location?.state as any)?.prevPath },
          '*'
        );
      }
    };

    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);

  React.useEffect(() => {
    const cleanPath = path.charAt(0) === '/' ? path.substr(1) : path;
    history.push(`/administration/${cleanPath}`);
  }, [path]);

  return (
    <div style={{ position: 'absolute', height: '100vh', width: '100%', marginLeft: -30, top: 0, border: 'none' }}>
      <iframe ref={iframe} height={'100%'} width={'100%'} src={`${getSysadminUrl()}/${iframePath}`} frameBorder={'0'} />
    </div>
  );
};
