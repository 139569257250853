import * as React from 'react';
import s from '../Risk/RiskContainer.module.scss';
import { Card } from '@iliotech/storybook';
import { VolatilityContributionChart } from '../Risk/components/VolatilityContributionChart';
import {
  DASHBOARD_PORTFOLIO_HELP_TEXT,
  RISK_CONTAINER_HELP_TEXT,
} from '../../../../services/constants/tooltipContextHelp';
import { TopTenVolatilityChart } from '../Risk/components/BetaVsPortfolioBenchmarkChart';
import { VolatilityPeriodToggle } from '../Risk/components/VolatilityPeriodToggle';
import { useMemo, useState } from 'react';
import { usePortfolioInfo } from '../../../../services/hooks/usePortfolioInfo';
import useApi from '../../../../services/hooks/useApi';
import { API_URL, BASE_URL, getApiUrl, getCustomApiUrl } from '../../../../services/constants/endpoints';
import { Loader } from 'rsuite';
import { PortfolioSubHeader } from '../../components/PortfolioHeader/PortfolioSubHeader';
import { PortfolioHeader } from '../../components';
import PerformanceAndVolatilityTable from '../Risk/components/PerformanceAndVolatilityTable/PerformanceAndVolatilityTable';
import moment from 'moment';
import { usePeriod } from '../../../../services/context/PeriodContext';
import axios from 'axios';

const RiskAnalytics = () => {
  const [volatilityPeriod, setVolatilityPeriod] = useState('LONG');
  const { portfolioInfo } = usePortfolioInfo();
  const { period } = usePeriod();
  const toggleVolatilityPeriod = () => {
    if (volatilityPeriod === 'LONG') {
      setVolatilityPeriod('SHORT');
    } else {
      setVolatilityPeriod('LONG');
    }
  };

  const API_BENCHMARK_VOLATILITY = API_URL + 'api/risk/v1/volatility/portfolio/';

  const periodFrom = period.from
    ? moment(period.from).format('YYYY-MM-DD')
    : moment(portfolioInfo?.data?.inceptionDate).format('YYYY-MM-DD');

  const cancelTokenSource = axios.CancelToken.source();
  const portfolioHistoricVolatility = useApi(
    API_BENCHMARK_VOLATILITY + portfolioInfo.data?.id + '/historic/',
    {
      params: {
        includeInstrumentScatterData: true,
        dataFrequency: volatilityPeriod === 'SHORT' ? 'DAILY' : 'MONTHLY',
        fromDate: periodFrom,
        toDate: moment(period.to).format('YYYY-MM-DD'),
      },
    },
    {
      enabled: !!portfolioInfo.data?.id,
    }
  );

  const portfolioVolatilityData = useApi(
    API_BENCHMARK_VOLATILITY + portfolioInfo.data?.id,
    {
      params: {
        term: volatilityPeriod,
      },
    },
    {
      enabled: !!portfolioInfo.data?.id,
    }
  );

  if (!portfolioInfo.isFetched || portfolioVolatilityData.isLoading || portfolioHistoricVolatility.isLoading) {
    return <Loader />;
  }
  const portfolio = portfolioInfo.data!;
  const periodConverted = volatilityPeriod === 'LONG' ? '260-day ' : '100-day ';
  const portfolioBenchmarkIndexName = portfolio.benchmarkIndex.name || '';
  const portfolioBaseCcy = portfolio.currency.name || '';

  const stats: IStat[] = [
    {
      value: portfolio.totalProfitAndLoss,
      label: 'Inception P&L',
      colored: true,
      type: 'currency',
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: portfolio.performance,
      label: 'Performance (TWR)',
      type: 'percentage',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
    },
    {
      value: portfolio.totalWealth,
      label: 'Total Portfolio Value',
      type: 'currency',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false} />
      <PortfolioSubHeader title={'Analytics'} stats={stats} />
      <VolatilityPeriodToggle currentPeriod={volatilityPeriod} clickFunction={toggleVolatilityPeriod} />
      <div className={s.containerRow}>
        <Card className={s.contributionToVolatilityChartContainer}>
          <VolatilityContributionChart
            data={portfolioVolatilityData.data}
            period={volatilityPeriod}
            chartTitle={'Breakdown of portfolio volatility (' + periodConverted + ' period)'}
            helpText={RISK_CONTAINER_HELP_TEXT.ChartBreakdownOfPortfolioVolatility}
          />
        </Card>
      </div>
      <div style={{ height: 40 }} />
      <div className={s.containerRow}>
        <Card className={s.containerColumn}>
          <TopTenVolatilityChart
            data={portfolioVolatilityData.data}
            portfolioBaseCcy={portfolioBaseCcy}
            chartTitle={'260-day Beta vs benchmark (' + portfolioBenchmarkIndexName + ') in ' + portfolioBaseCcy}
            helpText={RISK_CONTAINER_HELP_TEXT.ChartBetaVsPortfolioVolatility}
          />
        </Card>
      </div>
    </div>
  );
};

export default RiskAnalytics;
