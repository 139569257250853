import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';
import { IPortfolioPnlByAttribution } from '../../../models/IPortfolioPnlByAttribution';

import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
/*
const INITIAL_STATE: IPortfolioProfitAttribution = {
  ...ReduxHelper.createInitialState([]),
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION }
};*/

export interface IPortfolioPnlByAttributionState extends ILoadingDataState {
  data: IPortfolioPnlByAttribution;
}

/*const INITIAL_STATE: IPortfolioProfitAttributionState = ReduxHelper.createInitialState<IPortfolioProfitAttribution>({
  fxTotal: 0,
  incomeTotal: 0,
  priceTotal: 0,
  realizedTotal: 0,
  total: 0,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION }

});*/

const INITIAL_STATE: any = {
  fxTotal: 0,
  incomeTotal: 0,
  priceTotal: 0,
  realizedTotal: 0,
  total: 0,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const portfolioPnlByAttributionReducer = ReduxHelper.createHttpRequestReducer<
  ILoadingDataState<IPortfolioPnlByAttribution>
>(
  INITIAL_STATE,
  DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_REQUEST,
  DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_SUCCESS,
  DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_ERROR,
  DashboardActions.SET_PORTFOLIO_PNL_BY_ATTRIBUTION_PERIOD
  /*
  DashboardActions.PORTFOLIO_PNL_BY_ATTRIBUTION_RESET
*/
);

export default portfolioPnlByAttributionReducer;
