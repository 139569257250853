import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import s from '../../../Transaction.module.scss';
import { Field } from 'redux-form';
import { FormFieldWrapper } from '../../../../../../../../../components/UIWidgets/FormFieldWrapper';
import renderControlledAutocomplete from '../../../../../../../../../components/ReduxForm/renderControlledAutocomplete';

interface IProps {
  label: string;
  disabled?: boolean;
  isLoading?: boolean;
  isAsync?: boolean;
  defaultValue?: string;
  fetchItems: (s: string) => Promise<IComboItemGeneric[]>;
}

const WarrantTicker: FC<IProps> = ({ fetchItems, disabled, label, defaultValue }) => {
  return (
    <FormFieldWrapper label={label} className={cn(s.nameField, s.fieldsWrapper, s.underlyingField)} required={true}>
      <Field
        fetchItems={fetchItems}
        name={'instrument'}
        defaultValue={defaultValue}
        component={renderControlledAutocomplete}
        disabled={disabled}
        format={(value: any) => value}
        inputProps={{
          className: 'input--small',
        }}
      />
    </FormFieldWrapper>
  );
};

export default WarrantTicker;
