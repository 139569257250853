import React from 'react';

import { BarChart } from 'src/components/illioleo.ui-react/ui/bar-chart';
import { IRootState } from '../../../../../../../../../../services/store';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';

interface IProps {
  portfolioInfo: IRootState['portfolio']['portfolioInfo'];
  profitPeriod: IPeriod;
  optionsPnlByMonth: IRootState['dashboard']['options']['pnlByMonth'];
  width: number;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

const OptionsPNLByMonth = ({ portfolioInfo, optionsPnlByMonth, width, portfolioCurrencyFormatter }: IProps) => {
  const [values, setValues] = React.useState<{ x: string; y: number }[]>([]);

  React.useEffect(() => {
    setValues(optionsPnlByMonth?.data?.[0]?.data.map((item) => ({ x: item[0], y: item[1] })) ?? []);
  }, [optionsPnlByMonth?.data?.[0]?.data]);

  const portfolioId = portfolioInfo.data?.id;
  const currencySymbol = portfolioInfo.data?.currency.symbol;

  return (
    // <div>
    <BarChart
      key={`${portfolioId}-${optionsPnlByMonth.data?.[0]?.data?.[0]}-${
        optionsPnlByMonth.data?.[optionsPnlByMonth.data?.[0]?.data?.length - 1]?.data?.[0]
      }-width`}
      series={[
        {
          color: 'var(--chart-1)',
          label: 'P&L',
          values,
        },
      ]}
      settings={{
        tooltipFormatter: (tooltip) => {
          if (!tooltip) {
            return <div />;
          }

          const { date, data } = tooltip;
          return (
            <div style={{ backgroundColor: 'white', padding: '0.5rem', borderRadius: 6, color: 'black' }}>
              <strong>{date}</strong>
              {data.map(({ color, label, value }) => (
                <div
                  style={{
                    display: 'flex',
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: color,
                      marginRight: 5,
                      width: 15,
                      height: 15,
                      borderRadius: '50%',
                    }}
                  />
                  <span>{`${label} ${portfolioCurrencyFormatter(value)}`}</span>
                </div>
              ))}
            </div>
          );
        },
        height: 300,
        width,
        yAxisLabel: 'Return (GBP)',
        yAxisUnit: currencySymbol ?? '',
        xAxisLabel: '',
        bgColor: 'transparent',
        labelColor: '#70768D',
        yTicks: 10,
        gridOpacity: 100,
        gridColor: '#70768D',
        marginTop: 40,
        marginBottom: 45,
        marginRight: 5,
        marginLeft: 40,
        strokeWidth: 20,
        // isLoading: !optionsPnlByMonth ?? optionsPnlByMonth?.isFetching,
        tooltipWidth: 220,
        tooltipHeight: 120,
        indicatorLineColor: 'yellow',
      }}
    />
  );
};

export default OptionsPNLByMonth;
