// @ts-ignore

export enum VALUE_TYPE {
  MONEY = 'MONEY',
  PERCENTAGE = 'PERCENTAGE',
}

export class FormatHelper {
  static formatPercentage(value: number, round = 10) {
    return `${FormatHelper.round(value, round)}%`;
  }

  static fixPercentage(value: number) {
    return `${value}%`;
  }

  static fixNumber(num: number) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  static formatNumber(value: number, round: number = 1) {
    return new Intl.NumberFormat('en').format(FormatHelper.round(value, round));
  }

  static formatCurrencyShort(
    value: number,
    baseCurrencySymbol: string | null,
    round: number = 1,
    inverted?: boolean
  ): string {
    if (!baseCurrencySymbol) {
      return FormatHelper.round(value, round).toString(10);
    }
    if (inverted) {
      return `${FormatHelper.formatNumber(value, round)} ${baseCurrencySymbol}`;
    }
    return `${baseCurrencySymbol} ${FormatHelper.formatNumber(value, round)}`;
  }

  static formatNumberAsValue(num: number, decimals = 2) {
    if (num) {
      return num
        .toFixed(decimals)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return num;
  }

  static prettierCurrencyValue(value: number, baseCurrencySymbol: string | null, round: number = 1): string {
    const sign = value > 0 ? '' : '-';
    const millions = Math.abs(value / 1000000);
    const thousands = Math.abs(value / 1000);

    if (value === 0) {
      return value.toString();
    }

    if (millions >= 1) {
      return `${sign}${FormatHelper.formatCurrencyShort(millions, baseCurrencySymbol, round)}M`;
    }
    if (thousands >= 1) {
      return `${sign}${FormatHelper.formatCurrencyShort(thousands, baseCurrencySymbol, round)}K`;
    }

    return `${sign}${FormatHelper.formatCurrencyShort(value, baseCurrencySymbol, round)}`;
  }

  static round(value: number, roundBy: number = 1): number {
    if (roundBy === 0) {
      return Math.round(value);
    }
    return Math.round(value * roundBy) / roundBy;
  }

  static capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  static roundV2(value: number, roundBy: number = 1): number {
    return Math.trunc(value * roundBy) / roundBy;
  }

  static localePriceValueFormatter(number: number, maxDecimals: number, minDecimal?: number) {
    if (!number) {
      return '0';
    }
    return number.toLocaleString(undefined, {
      minimumFractionDigits: minDecimal !== undefined ? minDecimal : maxDecimals,
      maximumFractionDigits: maxDecimals,
    });
  }
}
