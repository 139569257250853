import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { connect } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import Amount from '../../components/ConnectedTransactionInputs/Amount';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import CurrencyList from '../../components/ConnectedTransactionInputs/CurrencyList';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import { sendRequest } from '../../../../../../../../services/hooks/useApi';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: ITransferFormValues;
  snapshotFxRate: IRootState['snapshot']['snapshotEdit']['fxRate']['data'];
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
}

export interface ITransferFormValues {
  instrument?: IOption<Partial<IAsset>>;
  other: string;
  currency: IOption<string>;
  tradeTime: string;
  fxRate: number | null;
  amount: number;
  settlementOption: IPortfolioTrade['settlementOption'];
  tradeType: IAssetClass;
  operation: 'ADD' | 'WITHDRAW';
  fromCustodian: string | IOption<ICustodian> | any;
  toCustodian: string | IOption<ICustodian> | any;
  notes?: string;
  asset: 'TransferWithdrawal';
  key?: string;
}

type FormUpdater = <T extends keyof ITransferFormValues>(field: T, data: ITransferFormValues[T] | null) => void;

type Props = IAssetEntryComponentProps<ITransferFormValues> &
  InjectedFormProps<ITransferFormValues> &
  IMapStateToProps &
  IDispatchToProps;

const TransferEntry: FC<Props> = ({
  editMode,
  custodianOptions,
  formValues,
  resetForm,
  resetEditTrade,
  removeTrade,
  initialValues,
  portfolioInfo,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  checkedOut,
  updateFormValue,
}) => {
  React.useEffect(() => {
    resetForm(initialValues);
  }, [initialValues]);

  React.useEffect(() => {
    if (!!formValues.currency?.value && !!portfolioInfo?.currency?.name) {
      if (formValues.currency.value === portfolioInfo?.currency?.name) {
        updateFormValue('fxRate', 1);
        return;
      }
      sendRequest(
        `/api/v1/fx-rate?from=${formValues.currency.value}&to=${portfolioInfo?.currency?.name}&date=${formValues.tradeTime}`
      ).then((result) => {
        if (result.fromToFxRate) {
          updateFormValue('fxRate', parseFloat(result.fromToFxRate));
        }
      });
    }
  }, [formValues.currency?.value, formValues.tradeTime, portfolioInfo?.currency?.name]);

  React.useEffect(() => {
    if (!!formValues.fromCustodian?.name && !!formValues.toCustodian?.name) {
      const newNote = `Transfer from ${formValues.fromCustodian.name} to ${formValues.toCustodian.name}`;

      const oldNote = formValues.notes;
      if (oldNote === newNote) {
        return;
      }
      if (!!oldNote) {
        if (oldNote.substr(0, 14) !== 'Transfer from ') {
          return;
        }

        const oldCustodians = oldNote?.substr(14).split(' to ');
        const custodianNames = custodianOptions?.map((custodian) => custodian.name);

        if (!oldCustodians.every((custodian) => custodianNames?.includes(custodian))) {
          return;
        }
      }

      updateFormValue('notes', newNote);
    }
  }, [formValues.fromCustodian, formValues.toCustodian, formValues.notes]);

  const isFormInvalid = React.useCallback(() => {
    return (
      isValueInvalid(formValues.amount) ||
      isValueInvalid(formValues.fromCustodian) ||
      isValueInvalid(formValues.toCustodian)
    );
  }, [formValues.amount, formValues.fromCustodian, formValues.toCustodian])();

  const makeInstrumentOption = (curr: string) => {
    const instrumentId = `${curr}SUBS`;
    const instrumentName = `${curr} Subscription`;

    return {
      id: instrumentId,
      name: instrumentName,
      code: instrumentId,
      value: {
        instrumentId,
        code: instrumentId,
        name: instrumentName,
        sourceId: {
          sourceId: instrumentId,
          sourceData: 'SystemInstrumentService',
        },
      },
    };
  };

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const tradeOutline: IPortfolioTrade = {
      settlementOption: 'LOCAL',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value ?? makeInstrumentOption(formValues.currency?.value)?.value,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: formValues.fxRate ?? 1,
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      notes: formValues.notes,
      tradeCosts: 0,
      type: formValues.instrument?.name,
      commission: 0,
      price: 0,
    } as any;

    const withdrawalTrade: IPortfolioTrade = {
      ...tradeOutline,
      custodian:
        typeof formValues.fromCustodian === 'string'
          ? formValues.fromCustodian
          : formValues.fromCustodian?.value || (undefined as any),
      amount: formValues.amount,
      operation: 'WITHDRAW',
    };

    const depositTrade: IPortfolioTrade = {
      ...tradeOutline,
      custodian:
        typeof formValues.toCustodian === 'string'
          ? formValues.toCustodian
          : formValues.toCustodian?.value || (undefined as any),
      amount: formValues.amount,
      operation: 'ADD',
    };

    saveTrades(portfolioInfo!.id, [withdrawalTrade, depositTrade]).then(() => {
      resetForm(initialValues);
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
    });

    resetForm({ ...initialValues });
  };

  return (
    <form className={s.formGrid} onSubmit={saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime />
        <CurrencyList disabled={editMode && checkedOut} baseCurrenciesOnly={false} />
        <Amount quantityDecimals={5} />
        <Custodian name={'fromCustodian'} label={'Withdraw from'} />
        <Custodian name={'toCustodian'} label={'Deposit to'} />
        <AddCustodianButton />
      </div>

      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>

      <div className={s.formFooter}>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  formValues: state.form[FORMS_NAME.transfer].values as ITransferFormValues,
  snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data as any, // .fxRate.data,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(FORMS_NAME.transfer, field, data));
  },
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const OPERATIONS = [
  { text: 'Deposit', value: 'ADD' },
  { text: 'Withdraw', value: 'WITHDRAW' },
];

export default reduxForm<ITransferFormValues, IAssetEntryComponentProps<ITransferFormValues>>({
  form: FORMS_NAME.transfer,
  validate,
  destroyOnUnmount: false,
})(connect(mapStateToProps, mapDispatchToProps)(TransferEntry));

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};
