import { ReduxHelper } from '../utils/ReduxHelper';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { AssetsActions } from '../actions/AssetsActions';

export interface IAssetsSubClassesState extends ILoadingDataState {
  data: IAssetSubClass[];
}

const INITIAL_STATE: IAssetsSubClassesState = ReduxHelper.createInitialState<IAssetSubClass[]>([]);

const assetsSubClassesReducer = ReduxHelper.createHttpRequestReducer<IAssetsSubClassesState>(
  INITIAL_STATE,
  AssetsActions.ASSETS_SUBCLASS_REQUEST,
  AssetsActions.ASSETS_SUBCLASS_SUCCESS,
  AssetsActions.ASSETS_SUBCLASS_ERROR
);

export default assetsSubClassesReducer;
