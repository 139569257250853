import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { TradeType } from '../../../../../../../models/IPortfolioTrade';

interface IProps {
  type: string;
}

const TypeCell: FunctionComponent<IProps> = ({ type }: IProps) => {
  const isBuy = type === TradeType.BUY.toUpperCase() || type === TradeType.ADD.toUpperCase();

  if (!type) {
    return null;
  }

  return (
    <span className={cn('text-success', { 'text-blue': !isBuy })}>
      {type.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
    </span>
  );
};

export default React.memo(TypeCell);
