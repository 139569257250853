import React from 'react';
import { ICashflowItem } from '../../../../../../../../models/IPortfolioTrade';
import { Table } from '@iliotech/storybook';
import { Icon } from 'rsuite';
import { bulkEditTrades } from '../../../TradesTable/TradesTable';
import { usePositionCashflow } from '../../../../../../../../services/hooks/apiHooks/usePositionCashflow';
import { useTypedSelector } from '../../../../../../../../services/hooks/useTypedSelector';
import { useHistory } from 'react-router-dom';

interface IOwnProps extends IPositionDetailsTab {
  fromDate: string;
  toDate: string;
}

const numberRenderer = (maximumFractionDigits = 2) => (rowData: ICashflowItem, dataKey: keyof ICashflowItem) => (
  <div>{(rowData[dataKey as keyof ICashflowItem] as number).toLocaleString(undefined, { maximumFractionDigits })}</div>
);

export const CashflowList = ({ position, portfolioId, canEditTrades }: IOwnProps) => {
  const history = useHistory();
  const goToEdit = () => {
    history.push(`/portfolio/${portfolioId}/transactions/add/`);
  };
  const columns: {
    label: string;
    dataKey: any;
    width: number;
    cellRenderer?(rowData: ICashflowItem, dataKey: any): React.ReactNode;
  }[] = [
    {
      label: 'Booking Date',
      dataKey: 'bookingDate',
      width: 90,
    },
    {
      label: 'Event Name',
      dataKey: 'eventName',
      width: 140,
    },
    {
      label: 'Settled On',
      dataKey: 'settledOnDate',
      width: 90,
    },
    {
      label: 'Amount',
      dataKey: 'amountLocal',
      width: 90,
      cellRenderer: numberRenderer(2),
    },
    {
      label: 'Balance',
      dataKey: 'balance',
      width: 90,
      cellRenderer: numberRenderer(2),
    },
    {
      label: 'Reference',
      dataKey: 'reference',
      width: 340,
    },
    ...(canEditTrades
      ? [
          {
            label: 'Edit',
            dataKey: 'key',
            width: 60,
            cellRenderer: (rowData: ICashflowItem) =>
              rowData.tradeId && (
                <Icon onClick={() => bulkEditTrades(portfolioId, [rowData.tradeId!], goToEdit)} icon="pencil" />
              ),
          },
        ]
      : []),
  ];
  const { snapshotFilters } = useTypedSelector((state) => ({
    snapshotFilters: state.snapshot.snapshotFilters,
  }));
  const { cashflowData } = usePositionCashflow({
    positionId: position.positionId!,
    fromDate: snapshotFilters.positionsFrom,
    toDate: snapshotFilters.positionsTo,
    quantity: position.quantity,
  });

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      {cashflowData.length && <Table rowHeight={24} headerHeight={24} data={cashflowData} columns={columns} />}
    </div>
  );
};
