import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { connect, useDispatch } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import Amount from '../../components/ConnectedTransactionInputs/Amount';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import { renderToggleBtns } from '../../components';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import { getFxConversionLabel } from '../../helpers';
import { formatFxConvention } from '../../../../../../../../services/utils/FXHelper';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import AssetSearch from '../../components/ConnectedTransactionInputs/AssetSearch';
import Multiplier from '../../components/ConnectedTransactionInputs/Multiplier';
import { getAssetOptions } from '../../../../../../../../services/selectors/snapshots';
import Price from '../../components/ConnectedTransactionInputs/Price';
import TradeCost from '../../components/ConnectedTransactionInputs/TradeCost';
import Commission from '../../components/ConnectedTransactionInputs/Commission';
import cn from 'classnames';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: IEquityFutureFormValues;
  snapshotFxRate: IRootState['snapshot']['snapshotEdit']['fxRate']['data'];
  instrumentOptions: IOption<IAsset>[];
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
}

export interface IEquityFutureFormValues {
  instrument?: IOption<IAsset>;
  other: string;
  currency: IOption<string>;
  tradeTime: string;
  fxRate: number | null;
  amount: number;
  price: number;
  settlementOption: IPortfolioTrade['settlementOption'];
  tradeType: IAssetClass;
  operation: 'BUY' | 'SELL';
  custodian: string | IOption<ICustodian> | any;
  notes?: string;
  asset: 'CshAdjustment';
  key?: string;
  tradeCosts?: number;
  commission?: number;
}

type FormUpdater = <T extends keyof IEquityFutureFormValues>(field: T, data: IEquityFutureFormValues[T] | null) => void;

type Props = IAssetEntryComponentProps<IEquityFutureFormValues> &
  InjectedFormProps<IEquityFutureFormValues> &
  IMapStateToProps &
  IDispatchToProps;

const EquityFutureEntry: FC<Props> = ({
  editMode,
  custodianOptions,
  baseCurrencyCode,
  formValues,
  resetForm,
  resetEditTrade,
  removeTrade,
  initialValues,
  snapshotFxRate,
  fetchFxRate,
  portfolioInfo,
  updateFormValue,
  currencies,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  updateTempTrade,
  instrumentOptions,
  portfolioCurrencyFormatter,
}) => {
  const dispatch: AsyncActionDispatch = useDispatch();

  const fx = useFx();
  const convention = fx({ fromCurrencyName: formValues.currency?.value, toCurrencyName: portfolioInfo?.currency?.name });

  React.useEffect(() => {
    return () => resetForm(null);
  }, []);

  React.useEffect(() => {
    resetForm(initialValues);
  }, [initialValues?.tradeType, initialValues?.key]);

  React.useEffect(() => {
    if (initialValues.checkedOut) {
      return;
    }
    if (portfolioInfo?.currency?.name && formValues?.tradeTime && formValues?.currency?.value) {
      fetchFxRate(portfolioInfo.currency.name, formValues?.currency?.value, formValues?.tradeTime);
    }
  }, [portfolioInfo?.currency?.name, formValues?.tradeTime, formValues?.currency?.value]);

  React.useEffect(() => {
    // console.log('getting price');
    if (formValues?.instrument?.value) {
      if (initialValues.checkedOut) {
        return;
      }
      const { sourceId, sourceData } = formValues?.instrument?.value.sourceId;
      dispatch(SnapshotActions.fetchPrice(sourceId, sourceData, formValues.tradeTime)).then((result) => {
        if (typeof result.data === 'number') {
          updateFormValue('price', (result.data as unknown) as number);
        }
      });
    }
  }, [formValues?.instrument?.value?.sourceId?.sourceId, formValues.tradeTime]);

  React.useEffect(() => {
    updateFormValue('fxRate', formatFxConvention(snapshotFxRate).rate);
  }, [snapshotFxRate]);

  const isFormInvalid = React.useCallback(() => {
    return (
      isValueInvalid(formValues.fxRate) ||
      isValueInvalid(formValues.amount) ||
      isValueInvalid(formValues.instrument?.id || formValues.instrument?.value?.id || formValues.instrument?.name)
    );
  }, [formValues.fxRate, formValues.amount, formValues.instrument])();

  const fxConversionLabel = React.useCallback(
    () =>
      getFxConversionLabel(currencies || [], baseCurrencyCode, formValues.currency?.value, snapshotFxRate?.firstFxRate),
    [currencies, baseCurrencyCode, formValues?.currency?.value, snapshotFxRate?.firstFxRate]
  )();

  const searchFutures = (str: string) => {
    dispatch(SnapshotActions.dynamicSearchAssetByName(str, 'futures'));
  };

  const updateTrade = (e: any) => {
    e.preventDefault?.();

    const isConvention = convention?.isConvention;

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: tradeIsInPortfolioCurrency ? 1 : (isConvention ? 1 / (formValues.fxRate || 1) : formValues.fxRate) ?? 1,
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      notes: formValues.notes,
      tradeCosts: formValues.tradeCosts ?? 0,
      type: formValues.instrument?.name,
      commission: formValues.commission ?? 0,
      price: formValues.price,
      key: formValues.key,
    };

    updateTempTrade!(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetEditTrade();
    });
  };

  const currencyValue = formValues?.instrument?.value?.currency;

  const tradeIsInPortfolioCurrency = !currencyValue || baseCurrencyCode === currencyValue;

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const isConvention = convention?.isConvention;

    const trade: IPortfolioTrade = {
      settlementOption: 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value,
      quantity: formValues.amount,
      currency: formValues.currency?.value,
      fxRate: tradeIsInPortfolioCurrency ? 1 : (isConvention ? 1 / (formValues.fxRate || 1) : formValues.fxRate) ?? 1,
      tradeTime: formValues.tradeTime,
      operation: formValues.operation,
      custodian:
        typeof formValues.custodian === 'string'
          ? formValues.custodian
          : formValues.custodian?.value || (undefined as any),
      notes: formValues.notes,
      tradeCosts: formValues.tradeCosts ?? 0,
      type: 'EqFutures',
      commission: formValues.commission ?? 0,
      price: formValues.price,
    };

    saveTrades(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
    });
  };

  const grossTradeAmount = (formValues.price ?? 0) * (formValues.amount ?? 0);

  return (
    <form className={s.formGrid} onSubmit={editMode ? updateTrade : saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime />
        <AssetSearch onTyping={searchFutures} instrumentOptions={instrumentOptions} />
        <Multiplier quantityDecimals={0} />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper className={s.settlementField} label="Operation">
          <Field name="operation" values={OPERATIONS} className="input--small" component={renderToggleBtns} />
        </FormFieldWrapper>
        <Amount label={'Quantity'} />
        <Price
          label={'Price'}
          sourceId={formValues.instrument?.value?.sourceId}
          tradeTime={formValues.tradeTime}
          editMode={editMode}
        />
        <TradeCost />
        <Commission />
        <FXRate label={fxConversionLabel} disabled={tradeIsInPortfolioCurrency} />
        <Custodian />
        <AddCustodianButton />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>
      <div className={s.formFooter}>
        <span className={cn('text-gray', s.amountWrapper)}>
          Notional: {portfolioCurrencyFormatter(grossTradeAmount)}
        </span>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  formValues: state.form[FORMS_NAME.equityFutures].values as IEquityFutureFormValues,
  snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data as any,
  instrumentOptions: getAssetOptions(state),
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(FORMS_NAME.equityFutures, field, data));
  },
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const OPERATIONS = [
  { text: 'Buy', value: 'BUY' },
  { text: 'Sell', value: 'SELL' },
];

export default reduxForm<IEquityFutureFormValues, IAssetEntryComponentProps<IEquityFutureFormValues>>({
  form: FORMS_NAME.equityFutures,
  validate,
  destroyOnUnmount: false,
})(connect(mapStateToProps, mapDispatchToProps)(EquityFutureEntry));

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};
