import { ReduxHelper } from '../utils/ReduxHelper';

import { CustodianActions } from '../actions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

export interface ICustodianState extends ILoadingDataState {
  data: ICustodian[];
}

const INITIAL_STATE: ICustodianState = ReduxHelper.createInitialState<ICustodian[]>([]);

const custodianReducer = ReduxHelper.createHttpRequestReducer<ICustodianState>(
  INITIAL_STATE,
  CustodianActions.CUSTODIAN_REQUEST,
  CustodianActions.CUSTODIAN_SUCCESS,
  CustodianActions.CUSTODIAN_ERROR
);

export default custodianReducer;
