import React from 'react';
import s from '../VolatilityContributionChart.module.css';

export function YAxisCanvas(props: any) {
  return (
    <div className={s.yAxisCanvas}>
      <div className={s.AxisLine} style={{ height: '20px' }}>
        <div className={s.yAxisLabel}>100%</div>
      </div>
      <div className={s.AxisLine}>
        <div className={s.yAxisLabel}>75%</div>
      </div>
      <div className={s.AxisLine}>
        <div className={s.yAxisLabel}>50%</div>
      </div>
      <div className={s.AxisLine}>
        <div className={s.yAxisLabel}>25%</div>
      </div>
      <div className={s.AxisLine}>
        <div className={s.yAxisLabel}>0&nbsp;&nbsp;</div>
      </div>
    </div>
  );
}
