import { Reducer } from 'redux';

const subscriptionProductsReducer: Reducer<SubscriptionProductState, SubscriptionProductAction> = (
  state = defaultProducts,
  action
) => {
  switch (action.type) {
    case 'UPDATE_SUBSCRIPTION_PRODUCTS': {
      return action.payload;
    }
    default:
      return state;
  }
};

const sampleProducts: SubscriptionProductState = [];

// const defaultProducts: SubscriptionProduct[] = [];
const defaultProducts = sampleProducts;

export default subscriptionProductsReducer;
