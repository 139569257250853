import React, { FunctionComponent } from 'react';
import alternativesPic from '../../../../../../../static/images/assets/alternatives.svg';
import cashPic from '../../../../../../../static/images/assets/cash.svg';
import commoditiesPic from '../../../../../../../static/images/assets/commodities.svg';
import creditPic from '../../../../../../../static/images/assets/credit.svg';
import cryptoPic from '../../../../../../../static/images/assets/crypto-currency.svg';
import equityPic from '../../../../../../../static/images/assets/equity.svg';
import fundPic from '../../../../../../../static/images/assets/fund.svg';
import realAssetsPic from '../../../../../../../static/images/assets/realAssets.svg';
import realEstatePic from '../../../../../../../static/images/assets/realEstate.svg';
import sharedPic from '../../../../../../../static/images/assets/shared.svg';

const ASSETS_PICS: { [index: string]: string } = {
  Alternatives: alternativesPic,
  CashAndEquivalents: cashPic,
  'Cash Adjustments': cashPic,
  'FX Spot Trades': cashPic,
  'Cash Balances': cashPic,
  Commodities: commoditiesPic,
  FixedIncome: creditPic,
  Equities: equityPic,
  Funds: fundPic,
  RealAssets: realAssetsPic,
  RealEstate: realEstatePic,
  Shared: sharedPic,
  'Fixed Income': creditPic,
  'Real Assets': realAssetsPic,
  'Real Estate': realEstatePic,
  'Crypto-Currencies': cryptoPic,
};

interface IProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  assetClass: string;
  altName?: string;
}

const AssetImage: FunctionComponent<IProps> = ({ assetClass, altName, ...props }) => {
  if (typeof ASSETS_PICS[assetClass] === 'undefined') {
    console.log(assetClass);
    return <span>{altName || assetClass}</span>;
  }
  return (
    <img
      src={assetClass && ASSETS_PICS[assetClass]}
      style={{ height: 15, width: 15 }}
      title={assetClass}
      alt={assetClass}
      {...props}
    />
  );
};

export default AssetImage;
