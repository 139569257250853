export enum FORMS_NAME {
  auth = 'authForm',
  fx = 'fxForm',
  instrument = 'instrumentForm',
  instrumentPrice = 'instrumentPriceForm',
  instrumentIncome = 'instrumentIncome',
  password = 'passwordForm',
  portfolio = 'portfolioForm',
  reset = 'resetForm',
  transaction = 'transactionForm',
  transactionOption = 'transactionOptionForm',
  transactionWarrant = 'transactionWarrantForm',
  transactionFee = 'transactionFeeForm',
  equity = 'equityForm',
  warrant = 'warrantForm',
  commodity = 'commodityForm',
  funds = 'fundsForm',
  fixedIncome = 'fixedIncomeForm',
  equityFutures = 'equityFuturesForm',
  realAssets = 'realAssetsForm',
  crypto = 'cryptoForm',
  subscriptionWithdrawal = 'subscriptionWithdrawal',
  transfer = 'transferEntry',
  unconfirmedTransactions = 'unconfirmedTransactionsForm',
  user = 'userForm',
}
