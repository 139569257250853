const inRange = (val: number, min: number, max: number) => {
  return (val - min) * (val - max) <= 0;
};

export const getBetaColor = (
  val: number,
  barColors: [string, string, string, string, string, string, string, string, string, string]
) => {
  let barColor = barColors[9];
  switch (true) {
    case inRange(val, -1.5, -0.5):
    case inRange(val, 0.5, 1.5):
      barColor = barColors[7];
      break;
    case inRange(val, -0.5, -0.25):
    case inRange(val, 0.25, 0.5):
      barColor = barColors[4];
      break;
    case inRange(val, -0.25, 0.25):
      barColor = barColors[1];
      break;
  }

  return barColor;
};
