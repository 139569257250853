import { IScenario } from '../../models/IScenario';

export const SCENARIOS_SETTINGS: Record<string, IScenario> = {
  volatility: {
    name: 'Volatility',
    value: 0,
    min: -50,
    max: 50,
    units: '%',
    marks: {
      '-50': '',
      '-25': '',
      '0': '',
      '25': '',
      '50': '',
    },
    step: 25,
  },
  price: {
    name: 'Price',
    value: 0,
    min: -10,
    max: 10,
    units: '%',
    marks: {
      '-10': '',
      '-5': '',
      '0': '',
      '5': '',
      '10': '',
    },
    step: 5,
  },
  credit: {
    name: 'Credit',
    value: 0,
    min: -50,
    max: 50,
    units: 'bps',
    marks: {
      '-50': '',
      '-25': '',
      '0': '',
      '25': '',
      '50': '',
    },
    step: 25,
  },
  interest: {
    name: 'Interest rates',
    value: 0,
    min: -25,
    max: 25,
    units: 'bps',
    marks: {
      '-25': '',
      '0': '',
      '25': '',
    },
    step: 25,
  },
  fx: {
    name: 'FX',
    value: 0,
    min: -3,
    max: 3,
    units: '%',
    marks: {
      '-3': '',
      '0': '',
      '3': '',
    },
    step: 3,
  },
};

export const HISTORIC_EVENTS: any[] = [
  'Lehman',
  'CNY.DeRebound',
  'Greek.Crisis',
  'Debt.Ceiling',
  'Taper.Tantrum',
  'CNY.Devaluation',
  'VIX.explosion',
];
