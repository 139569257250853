import React from 'react';
import { usePortfolioSnapshot } from '../../../../../../services/hooks/apiHooks/usePortfolioSnapshot';
import { useExplorerContext } from '../../../../../../services/context/ExplorerContext';
import { Table, Card } from '@iliotech/storybook';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { usePortfolio } from '../../../../../../services/hooks/usePortfolio';
import { explorerCurrencyRenderer, explorerShortLongRenderer } from '../../utils/explorerCurrencyRenderer';
import InstrumentImage from '../../../../../../components/InstrumentImage/InstrumentImage';
import s from './TopPositionBreakdown.module.scss';
import cn from 'classnames';
import { DashboardTitle } from '../../../../../../components/UIWidgets/DashboardTitle/DashboardTitle';

const EXPANDED_COLUMNS = [
  { label: 'Asset Subclass', dataKey: 'assetSubClass' },
  { label: 'Asset Ticker', dataKey: 'code' },
];

export const TopPositionBreakdown = ({ height = 400, expanded, pnl }: IExplorerWidgetProps) => {
  const { selectedAllocationType, selectedAllocationSubType, setSelectedPosition } = useExplorerContext();
  const positions = usePortfolioSnapshot();
  const portfolio = usePortfolio();

  const portfolioCurrencySymbol = portfolio?.portfolioSymbol;

  const columns = React.useMemo(() => {
    const dynamicColumn = getColumn(selectedAllocationType);
    const cols: ColumnProps[] = [
      {
        label: 'Instrument',
        dataKey: 'name',
        width: 200,
        fixed: 'left',
        resizable: true,
        cellRenderer: (rowData: any) => {
          return (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <InstrumentImage size={15} instrument={rowData} />
              <div>{rowData.name}</div>
            </div>
          );
        },
      },
      { label: dynamicColumn.label, dataKey: dynamicColumn.dataKey as string, width: 120, resizable: true },
      ...(expanded ? EXPANDED_COLUMNS : []),

      {
        label: 'Qty',
        dataKey: 'quantity',
        width: 70,
        align: 'right',
        cellRenderer: explorerShortLongRenderer,
        flexGrow: 1,
      },
      {
        label: `Value (${portfolioCurrencySymbol})`,
        dataKey: 'amount',
        width: 100,
        align: 'right',
        cellRenderer: explorerShortLongRenderer,
        flexGrow: 1,
      },
      {
        label: `P&L (${portfolioCurrencySymbol})`,
        dataKey: 'profitAndLoss',
        width: 100,
        align: 'right',
        cellRenderer: explorerCurrencyRenderer,
        flexGrow: 1,
      },
    ];
    return cols;
  }, [selectedAllocationType, portfolioCurrencySymbol]);

  const data = React.useMemo(() => {
    return (
      positions.data?.content
        ?.filter(getFilter(selectedAllocationType, selectedAllocationSubType))
        .filter((item) =>
          pnl ? item.assetClassId !== 'Adjustments' : item.assetClassId !== 'Adjustments' && item.amount !== 0
        )
        .sort(
          (a, b) => ((pnl ? b.profitAndLoss : b.amount) ?? -Infinity) - ((pnl ? a.profitAndLoss : a.amount) ?? -Infinity)
        )
        .slice(0, !expanded ? 10 : 50) ?? []
    );
  }, [positions.data?.content, selectedAllocationType, selectedAllocationSubType, expanded]);

  return (
    <Card style={{ padding: 0 }}>
      <div
        style={{ height: height - 15, overflow: 'scroll', position: 'relative' }}
        className={cn('hover-table-surround', s.noScrollbar)}
      >
        <DashboardTitle
          title={`Top Positions by ${pnl ? 'P&L' : 'Value'}`}
          style={{ marginBottom: 10, padding: '10px 15px' }}
        />

        <Table
          columns={columns}
          data={data}
          height={height}
          rowHeight={25}
          headerHeight={35}
          onRowClick={(snapshot) => setSelectedPosition(snapshot)}
        />
      </div>
    </Card>
  );
};

const getColumn = (allocationType: AllocationType | null): { dataKey: keyof IPortfolioSnapshot; label: string } => {
  switch (allocationType) {
    case 'AssetClass':
      return { dataKey: 'assetClass', label: 'Asset Class' };
    case 'Region':
      return { dataKey: 'country', label: 'Country' };
    case 'Currency':
      return { dataKey: 'currencyNative', label: 'Currency' };
    case 'Custodian':
      return { dataKey: 'custodian', label: 'Custodian' };
    case 'IndustrySector':
      return { dataKey: 'sector', label: 'Sector' };
    default:
      return { dataKey: 'displayId', label: 'ID' };
  }
};
const getFilter = (
  allocationType: AllocationType | null,
  allocationSubType: { subClassId: string | null; subSubClassId: string | null }
): ((snapshot: IPortfolioSnapshot) => boolean) => {
  if (allocationSubType.subClassId === null && allocationSubType.subSubClassId === null) {
    return () => true;
  }

  switch (allocationType) {
    case 'AssetClass':
      return allocationSubType.subSubClassId && allocationSubType.subClassId
        ? (snapshot) =>
            snapshot.assetSubClassId === allocationSubType.subSubClassId &&
            snapshot.assetClassId === allocationSubType.subClassId
        : allocationSubType.subSubClassId
        ? (snapshot) => snapshot.assetSubClassId === allocationSubType.subSubClassId
        : (snapshot) => snapshot.assetClassId === allocationSubType.subClassId;
    case 'Custodian':
      return (snapshot) => snapshot.custodian === allocationSubType.subClassId;
    case 'IndustrySector':
      return (snapshot) => snapshot.sector === allocationSubType.subClassId;
    case 'Currency':
      return (snapshot) => snapshot.currencyNative === allocationSubType.subClassId;
    case 'Region':
      return allocationSubType.subSubClassId
        ? (snapshot) => snapshot.country === allocationSubType.subSubClassId
        : (snapshot) => snapshot.region === allocationSubType.subClassId;

    default:
      return () => true;
  }
};
