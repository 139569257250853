import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { SortDirectionType } from 'react-virtualized';
import { IOptionType } from '../../../../../../components/UIWidgets/Select/Select';
import { TableWrapper } from '../../../../../../components/UIWidgets/TableWrapper';

import { IPortfolioTrade } from '../../../../../../models/IPortfolioTrade';
import TradeHeader from './components/TradeHeader';
import s from './TradesTable.module.scss';
import { getTradesColumns } from './tradesTableColumns';
import { filterTrades } from './filterTrades';
import CapabilitiesHelper from '../../../../../../services/utils/CapabilitiesHelper';
import TableColumnsFilter from '../TableColumnsFilter/TableColumnsFilter';
import { ITableColumn } from '../../../../../../components/UIWidgets/TableWrapper/models';
import { TradesTableProps } from './TradesTableProps';
import { cellRenderer } from './cellRenderer';
import { ActionsButton, IAction } from '../../../../../../components/ActionsButton/ActionsButton';
import { sendRequest } from '../../../../../../services/hooks/useApi';
import { Button, Modal, Notification, Icon } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { DEFAULT_TRANSACTION_FIELDS } from '../../../../../../services/constants/preferences';
import { usePreferences } from '../../../../../../services/context/PreferencesContext';

type PortfolioProperty = keyof IPortfolioTrade | keyof IAsset;

const getAvailableAssetClasses = (trades: IPortfolioTrade[], assetsClasses: IAssetClass[]) => {
  const availableAssetClasses: { [index: string]: IOptionType } = {};
  const emptyOption = { label: 'All', value: '' };

  trades.forEach((trade) => {
    const assetClass = assetsClasses.filter((o) => o.id === trade.tradeType.id)[0];
    if (assetClass && typeof availableAssetClasses[assetClass.id] === 'undefined') {
      availableAssetClasses[assetClass.id] = { label: assetClass.name, value: assetClass.id };
    }
  });
  const options = Object.entries(availableAssetClasses).map(([label, value]) => value);
  return [emptyOption, ...options];
};

// * Get a map whose keys are attributes of a portfolioSnapshot entry, and values are Select options of all present values
const getDistinctPropertiesFromTrade = <T extends keyof IPortfolioTrade>(
  trades: IPortfolioTrade[],
  properties: PortfolioProperty[]
) => {
  const options: { [index: string]: { [index: string]: IOptionType } } = {};
  properties.forEach((property) => {
    options[property] = { default: { label: 'All', value: '' } };
    const propertyOptions = options[property];
    trades.forEach((trade) => {
      const preOption = trade[property as keyof IPortfolioTrade];
      const option = typeof preOption === 'object' ? preOption.name : preOption;
      if (typeof propertyOptions[option] === 'undefined') {
        propertyOptions[option] = {
          label: option,
          value: option,
        };
      }
    });
  });

  const distinctProperties: { [index: string]: IOptionType[] } = {};

  Object.entries(options).map(([prop, values]) => {
    distinctProperties[prop] = Object.entries(values).map(([_, value]) => value);
  });

  return distinctProperties;
};

export const bulkEditTrades = (portfolioId: string, keys: string[], onComplete?: (result?: any) => void) => {
  return sendRequest('/api/v1/portfolio.amend.trades', { method: 'POST', data: { portfolioId, keys } })
    .then((result) => {
      Notification.success({ title: 'Trades marked for editing', description: `${keys.length} trades ready to edit` });
      onComplete?.(result);
      return result;
    })
    .catch((error) => {
      Notification.error({
        title: 'Error editing trades',
        error: error?.message || 'Unable to mark trades for editing',
      });
    });
};
export const bulkDeleteTrades = (portfolioId: string, keys: string[], onComplete?: () => void) => {
  return sendRequest('/api/v1/portfolio.delete.trades', { method: 'DELETE', data: { tradeIds: keys } })
    .then(() => {
      Notification.success({ title: 'Trades deleted', description: `${keys.length} trades deleted` });
      onComplete?.();
    })
    .catch((error) => {
      Notification.error({
        title: 'Error deleting trades',
        error: error?.message || 'Unable to mark trades for deletion',
      });
    });
};

const TradesTable = (props: TradesTableProps) => {
  const {
    trades,
    totalTrades,
    tradesOrder,
    onSortData,
    portfolioCurrencyFormatter,
    onLoadData,
    portfolioCurrency,
    assetsClasses,
    userCapabilities,
    portfolioInfo,
    fetchData,
  } = props;
  const { preferences } = usePreferences();
  const [selectedTradeKeys, setSelectedTradeKeys] = React.useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const { snapshotFilters } = props;
  const availableAssetClasses = React.useMemo(() => getAvailableAssetClasses(trades, assetsClasses), [
    trades,
    assetsClasses,
  ]);
  const filteredTrades = React.useMemo(() => trades.filter(filterTrades(snapshotFilters, portfolioCurrency.name)), [
    trades,
    snapshotFilters,
    portfolioCurrency.name,
  ]);

  const isKeyEditable = (key: string) => {
    const trade = trades.find((item) => item.key === key);
    return trade?.operation !== 'DIVIDEND_RECEIVED' && trade?.operation !== 'EARN';
  };

  const editableKeys = React.useMemo(() => {
    return selectedTradeKeys.filter((key) => isKeyEditable(key));
  }, [selectedTradeKeys]);

  const tableFilterOptions = React.useMemo(
    () => getDistinctPropertiesFromTrade(trades, ['currency', 'operation', 'custodian']),
    [trades]
  );

  React.useEffect(() => {
    setSelectedTradeKeys((prev) =>
      filteredTrades.filter((trade) => !!trade.key && prev.includes(trade.key)).map((t) => t.key!)
    );
  }, [filteredTrades]);

  const history = useHistory();

  const isEditable =
    props.editable && CapabilitiesHelper.isActionAllowed(CapabilitiesHelper.Actions.EDIT_TRADE, userCapabilities);

  const toggleTradeSelected = (key: string) => {
    if (selectedTradeKeys.includes(key)) {
      setSelectedTradeKeys((prev) => [...prev].filter((k) => k !== key));
    } else {
      setSelectedTradeKeys((prev) => [...prev, key]);
    }
  };

  const selectAllTrades = (val: boolean) => {
    if (!val) {
      setSelectedTradeKeys([]);
    } else {
      setSelectedTradeKeys(filteredTrades.map((trade) => trade.key!));
    }
  };

  const goToEdit = () => {
    history.push(`/portfolio/${portfolioInfo.id}/transactions/add/`);
  };

  const bulkOptions: IAction[] = [
    { label: 'Select All', action: () => selectAllTrades(true) },
    { label: 'Select None', action: () => selectAllTrades(false), bottomBorder: true },
    {
      label: `Edit Selected (${editableKeys.length})`,
      action: () => bulkEditTrades(portfolioInfo.id, editableKeys, goToEdit),
      icon: 'pencil',
      disabled: !editableKeys.length,
    },
    {
      label: `Delete Selected (${selectedTradeKeys.length})`,
      action: () => setShowDeleteModal(true),
      icon: 'trash',
      disabled: !selectedTradeKeys.length,
    },
  ];

  const setPeriod = (from: Date | null, to: Date | null) => {
    if (from !== (props.snapshotFilters.transactionsFrom ? new Date(props.snapshotFilters.transactionsFrom) : null)) {
      props.updateSnapshotFilter('transactionsFrom', from ? from.toString() : '');
    }
    if (to !== (props.snapshotFilters.transactionsTo ? new Date(props.snapshotFilters.transactionsTo) : null)) {
      props.updateSnapshotFilter('transactionsTo', to ? to.toString() : '');
    }
  };

  const updateFilter = (key: string) => (value: string | undefined) => {
    props.updateSnapshotFilter(key, value);
  };

  const headerFilters = [
    {
      label: 'Asset Class',
      options: availableAssetClasses,
      value: snapshotFilters.assetClass,
      onChange: updateFilter('assetClass'),
      minWidth: 150,
    },
    {
      label: 'Currency',
      options: [...tableFilterOptions.currency, { label: `Non-${portfolioCurrency.name}`, value: 'non-base' }],
      value: snapshotFilters.currency,
      onChange: updateFilter('currency'),
    },
    {
      label: 'Custodian',
      options: tableFilterOptions.custodian,
      value: snapshotFilters.custodian,
      onChange: updateFilter('custodian'),
      minWidth: 150,
    },
    {
      label: 'Operation',
      options: tableFilterOptions.operation,
      value: snapshotFilters.operation,
      onChange: updateFilter('operation'),
    },
  ];

  const resetFilters = () => {
    props.resetSnapshotFilters();
  };

  const visibleColumns: ITableColumn[] = [];
  const allCols: ITableColumn[] = getTradesColumns(isEditable, portfolioCurrency.symbol);
  const matchingOptCols: ITableColumn[] = [];

  const visibleTransactionFields = !!Object.keys(preferences.misc?.visibleTransactionFields || {}).length
    ? preferences.misc?.visibleTransactionFields
    : DEFAULT_TRANSACTION_FIELDS;

  allCols.forEach((col) => {
    if (!col.isOptional) {
      visibleColumns.push(col);
    } else {
      matchingOptCols.push(col);
    }
    const columnSetting = visibleTransactionFields[col.dataKey];
    if (columnSetting) {
      visibleColumns.push(col);
    }
  });

  const navigateToEdit = (key: string) => bulkEditTrades(portfolioInfo.id, [key], goToEdit);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <TradeHeader
          inceptionDate={portfolioInfo?.inceptionDate!}
          trades={filteredTrades}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          filters={headerFilters}
          onSearch={updateFilter('search')}
          searchValue={snapshotFilters.search}
          period={{
            from: snapshotFilters.transactionsFrom ? new Date(snapshotFilters.transactionsFrom) : null,
            to: snapshotFilters.transactionsTo ? new Date(snapshotFilters.transactionsTo) : null,
          }}
          onPeriodChange={setPeriod}
          resetFilters={resetFilters}
        />
        <div style={{ display: 'flex' }}>
          <TableColumnsFilter
            optionalColumns={matchingOptCols}
            preferenceKey={'visibleTransactionFields'}
            defaults={DEFAULT_TRANSACTION_FIELDS}
          />
          {/*<div className={s.actionsButtonSurround}>*/}
          {isEditable && <ActionsButton options={bulkOptions} />}

          {/*</div>*/}
        </div>
      </div>

      <TableWrapper
        columnClass={s.tableColumn}
        headerClass={s.headerContainer}
        rowClassName={s.tableRow}
        columns={visibleColumns}
        sortBy={tradesOrder.name}
        sortDirection={tradesOrder.direction}
        tableData={filteredTrades}
        totalCount={totalTrades}
        cellRenderer={cellRenderer(
          props,
          selectedTradeKeys,
          navigateToEdit,
          isEditable ? toggleTradeSelected : undefined
        )}
        headerHeight={24}
        rowHeight={24}
        onRowsRendered={() => {
          ReactTooltip.rebuild();
        }}
        onLoadData={(page: number) => onLoadData(page)}
        onSortData={(sortBy: string, sortDirection: SortDirectionType) =>
          onSortData({
            name: sortBy,
            direction: sortDirection,
          })
        }
      />
      <ReactTooltip
        id={'notes'}
        multiline={true}
        place={'left'}
        aria-haspopup={true}
        backgroundColor={'#a9b2d1'}
        textColor={'#171b24'}
        getContent={(dataTip: string | null) => {
          if (!dataTip) {
            return null;
          }

          const { multiplier, currency, notes, settlementOption } = JSON.parse(dataTip ?? '{}');

          return (
            <div className={s.tooltipContainer}>
              <div className={s.tooltipItem}>
                <span>Multiplier:</span> {multiplier}
              </div>
              <div className={s.tooltipItem}>
                <span>Settlement:</span>{' '}
                {settlementOption === 'AUTO'
                  ? 'Transfer'
                  : settlementOption === 'BASE'
                  ? props.portfolioInfo.currency.name
                  : currency}
              </div>
              <div className={s.tooltipNotesItem}>
                <span>Notes:</span> {notes}
              </div>
            </div>
          );
        }}
      />

      <Modal size={'xs'} show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Header>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                icon="remind"
                style={{
                  color: 'var(--alert)',
                  // fontSize: 24,
                  marginRight: 10,
                }}
              />
              <Modal.Title>Delete trades</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete {selectedTradeKeys.length} transactions?</Modal.Body>
          <Modal.Footer>
            <Button
              appearance={'primary'}
              onClick={() => {
                bulkDeleteTrades(portfolioInfo.id, selectedTradeKeys, fetchData);
                setShowDeleteModal(false);
              }}
            >
              Delete trades
            </Button>
            <Button appearance={'subtle'} onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Header>
      </Modal>
    </div>
  );
};

export default TradesTable;
