import React, { FC } from 'react';
import s from '../../Equity.module.scss';
import OptionsPNLChart from './OptionsPNLChart';
import OptionsPNLByMonth from './OptionsPNLByMonth';
import OptionsPremiums from './OptionsPremiums';
import OptionsTables from './OptionsTables';
import { IPortfolioInfoState } from '../../../../../../../../../../services/reducers/PortfolioReducers';
import { IRootState } from '../../../../../../../../../../services/store';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { Loader } from '../../../../../../../../../../components';
import moment from 'moment';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../../../services/constants/tooltipContextHelp';
import { Widget } from '../../../../../../../../components';
import { Card } from '@iliotech/storybook';
import PerformanceChartWidget from '../../../../../../../../components/PerformanceChartWidget/PerformanceChartWidget';
import { LineData } from '../../../../../../../../../../models/LineData';

interface IOwnProps {
  portfolioCurrency: { value: string; label: string };
  profitPeriod: IPeriod;
  portfolioInfo: IPortfolioInfoState;
  optionsPerformance: IRootState['dashboard']['options']['performance'];
  optionsPnlByMonth: IRootState['dashboard']['options']['pnlByMonth'];
  optionsDashboard: IRootState['dashboard']['options']['dashboard'];
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

const OptionsPage: FC<IOwnProps> = ({
  portfolioInfo,
  profitPeriod,
  optionsPerformance,
  optionsPnlByMonth,
  portfolioCurrencyFormatter,
  optionsDashboard,
  portfolioCurrency,
}) => {
  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });

  React.useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dataLoading = optionsPerformance.isFetching && optionsPnlByMonth.isFetching;

  const fullWidth = dimensions.width - 335;
  const halfWidth = fullWidth / 2;

  if (dataLoading) {
    return <Loader />;
  }

  const periodKey = `${dimensions.width}-${moment(profitPeriod.from).format('YYYY-MM-DD')}-${moment(
    profitPeriod.to
  ).format('YYYY-MM-DD')}`;
  // console.log(periodKey)

  const baseCurrency = portfolioInfo?.data?.currency?.name ?? '';

  return (
    <div>
      <div className={s.defaultContainer} style={{ width: dimensions.width - 335 }}>
        <Card style={{ flex: 1, marginRight: 10 }}>
          <Widget colNum={4} title={'Cumulative P&L'} titleInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartPnlPerformance}>
            <PerformanceChartWidget
              selectedPeriod={profitPeriod}
              isDataLoaded={true}
              indexPerformance={[]}
              indexName={''}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              portfolioPerformance={(optionsPerformance.data.map((i) => [new Date(i[0]), i[3]]) as unknown) as LineData}
              hidePeriodSelector={true}
              performanceToggle={1}
            />
          </Widget>
        </Card>
        <Card style={{ flex: 1, marginLeft: 10 }}>
          <Widget title={'P&L by month'} titleInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartPnlOptions}>
            <OptionsPNLByMonth
              // key={`OptionsPNLByMonth${dimensions.width}-${periodKey}`}
              portfolioInfo={portfolioInfo}
              profitPeriod={profitPeriod}
              optionsPnlByMonth={optionsPnlByMonth}
              width={halfWidth - 172.5}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            />
          </Widget>
        </Card>
      </div>
      <div style={{ height: 25 }} />
      <Card style={{ width: dimensions.width - 305 }}>
        <Widget
          title="Premium By Month"
          className={s.contaienr}
          titleInfo={DASHBOARD_EQUITY_HELP_TEXT.OptionsPremiumByMonthChart}
        />
        <OptionsPremiums
          optionsDashboard={optionsDashboard}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          selectedCurrency={portfolioCurrency}
          portfolioCurrency={baseCurrency}
          width={dimensions.width - 450}
        />
      </Card>
      <div style={{ height: 25 }} />
      <Widget
        title="Options Summary"
        className={s.contaienr}
        titleInfo={DASHBOARD_EQUITY_HELP_TEXT.OptionsSummaryChart}
      />
      <OptionsTables width={fullWidth} optionsDashboard={optionsDashboard} portfolioInfo={portfolioInfo} />
    </div>
  );
};

const MemoizedOptionsPage = React.memo(OptionsPage);

class OptionsPageContainer extends React.PureComponent<IOwnProps> {
  render() {
    return <MemoizedOptionsPage {...this.props} />;
  }
}

export default OptionsPageContainer;
