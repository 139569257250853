import React, { useEffect, useState } from 'react';
import { PastNextChart, Widget } from '../../../../../../../../components';
import s from '../../FixedIncome.module.scss';
import { Card } from '@iliotech/storybook';
import YieldToMaturityGraph from '../../../../../../../../components/YieldToMaturityGraph/YieldToMaturityGraph';
import { Loader, ToggleLeftRight } from '../../../../../../../../../../components';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { IOptionType } from '../../../../../../../../../../components/UIWidgets/Select/Select';
import useApi from '../../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../../services/constants/endpoints';
import { ASSETS } from '../../../../../../../../../../services/constants/assetClasses';
import { FEATURE_FLAGS } from '../../../../../../../../../../services/constants/featureFlags';
import { BarType } from '../../../../../../../../../../services/constants/charts';
import { usePeriod } from '../../../../../../../../../../services/context/PeriodContext';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../../../services/constants/tooltipContextHelp';
import { Helmet } from 'react-helmet';

interface IProps {
  renderCurrencySelector: () => React.ReactNode;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioId: string;
  currency: IOptionType<any> | undefined;
}

const FixedIncomeFundamentals = ({
  currency,
  portfolioId,
  renderCurrencySelector,
  portfolioCurrencyFormatter,
}: IProps) => {
  const { period } = usePeriod();
  const [parsedRepayments, setParsedRepayments] = useState<any>([]);
  const [toFirstEvent, setEventToggle] = useState<any>(true);

  const fixedIncomeYield = useApi(getApiUrl('income.yield'), {
    method: 'POST',
    data: {
      portfolioId,
      period: {
        from: period.from,
        to: period.to,
      },
      currency: currency?.value === 'All' ? undefined : currency?.value,
    },
  });

  const coupons = useApi(getApiUrl('income.detailed'), {
    method: 'POST',
    data: {
      currency: currency?.value === 'All' ? undefined : currency?.value,
      portfolioId,
      assetClass: ASSETS.fixedIncome,
      period: {
        from: period.from,
        to: period.to,
      },
    },
  });

  const repayments = useApi(
    getApiUrl('income.repayments'),
    {
      method: 'POST',
      data: {
        portfolioId,
        toFirstEvent,
        currency: !currency || currency.value === 'All' ? undefined : currency.value,
      },
    },
    {
      onSuccess: (data) =>
        setParsedRepayments([
          {
            data,
            id: 'repaymentsDataSet',
            name: 'Repayments',
            type: BarType.Solid,
          },
        ]),
    }
  );

  useEffect(() => {
    coupons.refetch();
    fixedIncomeYield.refetch();
  }, [period, currency]);

  useEffect(() => {
    repayments.refetch();
  }, [currency, toFirstEvent]);

  if (coupons.isLoading || repayments.isLoading || !parsedRepayments.length || fixedIncomeYield.isLoading) {
    return <Loader />;
  }
  return (
    <Widget renderHeaderTools={renderCurrencySelector}>
      <Helmet><title>illio - Fixed Income</title></Helmet>
      <Widget className={s.defaultContainer}>
        <Card>
          <Widget title="Coupons" colNum={8} titleInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartCoupons}>
            <PastNextChart
              chartData={FEATURE_FLAGS.futureCoupons ? [coupons.data[0]] : coupons.data}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              barWidth={24}
              assetClass="FixedIncome"
              selectedCurrency={currency}
              selectedPeriod={period}
            />
          </Widget>
        </Card>
      </Widget>
      <Widget className={s.defaultContainer}>
        <Card>
          <Widget
            title="Estimated Coupons Next 12 months"
            colNum={8}
            titleInfo={DASHBOARD_EQUITY_HELP_TEXT.ChartEstimatedCouponsNextYear}
          >
            <PastNextChart
              chartData={[coupons.data[1]]}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              barWidth={24}
              assetClass="FixedIncome"
              selectedCurrency={currency}
              selectedPeriod={period}
            />
          </Widget>
        </Card>
      </Widget>

      <Widget>
        <div className={s.defaultContainer}>
          <Widget>
            <Card style={{ flex: 1, marginRight: 10 }}>
              <Widget title="Monthly Yield" colNum={4} className={s.defaultContainer}>
                {fixedIncomeYield.data ? (
                  <YieldToMaturityGraph fixedIncomeYield={fixedIncomeYield.data} />
                ) : (
                  <small className={s.noData}>
                    <i>No data to display.</i>
                  </small>
                )}
              </Widget>
            </Card>
          </Widget>
          <Widget>
            <Card style={{ flex: 1, marginLeft: 10 }}>
              <Widget title="Principal repayments" colNum={4} className={s.defaultContainer}>
                <div>
                  <ToggleLeftRight
                    leftLabel={'To First Event'}
                    rightLabel={'To Maturity'}
                    clickFunction={() => setEventToggle(!toFirstEvent)}
                    toggleCurrentPosition={toFirstEvent}
                  />
                </div>
                <PastNextChart
                  chartData={parsedRepayments as any}
                  portfolioCurrencyFormatter={portfolioCurrencyFormatter}
                  barWidth={24}
                  assetClass="FixedIncome"
                  hideLegend={true}
                  selectedCurrency={currency}
                  dateFormat={'YYYY'}
                />
              </Widget>
            </Card>
          </Widget>
        </div>
      </Widget>
    </Widget>
  );
};

export default FixedIncomeFundamentals;
