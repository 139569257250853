import * as React from 'react';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';
import { DatePicker, Loader, Select } from '../../../../../../../../components';
import s from './PositionsHeader.module.scss';
import moment from 'moment';
import PositionsUpload from './components/PositionsUpload/PositionsUpload';
import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';
import { Dispatch, SetStateAction } from 'react';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../../../components/ContextHelp';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';
import { SYSTEM_EPOCH_PLUS_ONE } from '../../../../../../../../services/constants/constants';
import { BsDownload } from 'react-icons/all';

interface IProps {
  options: IOptionType[];
  custodian: IOption<ICustodian>;
  setCustodian: Dispatch<SetStateAction<ICustodian>>;
  onDrop: (f: File[]) => void;
  onToggle: (b: boolean) => void;
  checked: boolean;
  totalWeight: number;
  setTotalWeight: any;
  tradeTime: Date;
  setTradeTime: any;
}

const PositionsHeader = ({ tradeTime, setTradeTime, options, custodian, setCustodian, onDrop }: IProps) => {
  const { portfolioInfo } = usePortfolioInfo();
  const selectedCustodian = options.find((c) => c.value?.id === custodian?.id);

  if (portfolioInfo.isFetching) {
    return <Loader />;
  }

  const TextWithTooltip = ({ title, tooltipText }: { title: string; tooltipText: string }) => {
    return (
      <div className={s.textWithTooltip}>
        <span id={title} className={s.textBold}>
          {title}
        </span>
        <ContextHelpIcon title={title} elementId={title || 'tmpId'} />
        <ContextHelpTooltip elementId={title} tooltipTitle={title} tooltipText={tooltipText} />
      </div>
    );
  };
  return (
    <div className={s.container}>
      <div className={s.column}>
        <div className={s.row}>
          <TextWithTooltip title={'Base Currency:'} tooltipText={'The base currency of your portfolio'} />
          <span className={s.text}>{portfolioInfo.data?.currency.name}</span>
        </div>
        <div className={s.row}>
          <TextWithTooltip title={'Position Date:'} tooltipText={'The trade date of your positions'} />
          <div className={s.datePickerContainer}>
            <DatePicker
              max={new Date()}
              min={SYSTEM_EPOCH_PLUS_ONE}
              value={moment(tradeTime).toDate()}
              onChange={(date) =>
                moment(date.value as any).isBefore(new Date()) &&
                moment(date.value as any).isAfter(SYSTEM_EPOCH_PLUS_ONE)
                  ? setTradeTime(date.value)
                  : null
              }
            />
          </div>
        </div>
        <div className={s.row}>
          <TextWithTooltip title={'Custodian:'} tooltipText={'The default custodian of your positions'} />
          <div className={s.selectContainer}>
            <Select
              size="small"
              styles={{
                menuList: () => ({ minWidth: 200, marginLeft: -10 }),
                singleValue: () => ({ display: 'block', color: 'var(--text-white)' }),
              }}
              options={options}
              selectedValue={selectedCustodian}
              onChange={(value: IOption<ICustodian>) => {
                setCustodian(value.value);
              }}
            />
          </div>
          <AddCustodianButton extraStyles={{ marginTop: -24, marginLeft: 18 }} />
        </div>
      </div>
      <div className={s.column}>
        <a
          onClick={(e) => e.stopPropagation()}
          href={`${process.env.PUBLIC_URL}/downloads/Position_Upload_Template.xlsx`}
          download={true}
        >
          <div className={s.uploadContainer}>
            <PositionsUpload onDrop={onDrop} />
            <div className={s.downloadBig}>
              <BsDownload size={20} />
              <span>Download Template</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default PositionsHeader;
