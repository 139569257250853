export const SAMPLE_TCs = `
**ILLIO – TERMS OF USE**

These Terms of Use are effective as of 18th June 2021.

**PLEASE READ THESE TERMS OF USE CAREFULLY.**

**These Terms of Use cover important information about the Platform for use of the Platform by each (i) Mobile User, (ii) Corporate User; and (iii) Individual User, including, but not limited to, each Trial User). All Users who access this website are subject to all applicable terms, whether or not you are a registered user.**

Welcome to illio.

These Terms of Use (including the Schedule appended hereto containing the definitions) and any other terms and documents expressly incorporated herein, each as may be amended, supplemented or replaced from time to time (together, the &quot;Terms of Use&quot;) apply to your use and subscription to the illio website or one of our other services, all of which are part of illio&#39;s platform (the &quot;Platform&quot;).

These Terms of Use are entered into by and between you as a user (referred to as &quot;User&quot;
or &quot;you&quot; or &quot;your&quot;, such defined terms shall be construed as the same when used in these Terms of Use) and illio Technology Limited (a company registered in Hong Kong (No. 2845489)) and its affiliates (referred to as &quot; **illio**&quot;, &quot;we&quot; or &quot;us&quot;), and supersede and replace any terms and conditions of services that you may have previously agreed with illio in connection with the Platform. In relation to a Corporate User, the definition of User or &quot;you&quot; or &quot;your&quot; (as applicable) includes all Authorised Users of that (Corporate) User.

Your access to and use of the Platform (whether as a registered user or otherwise) constitutes your agreement to be bound by these Terms of Use. The offering of the Platform to you is conditional on your acceptance of these Terms of Use. If you do not agree to these Terms of Use, you must not access or use the Platform.

For more details about illio&#39;s privacy practices, please refer to our Privacy Policy available at the following webpage: www.illio.com.

The information available on this Platform is general in nature and is provided for information purposes only. Information provided to you on this Platform does not and is not intended to constitute advice on, or a solicitation or offer of, or recommendation on, any investment or financial products of any nature, or constitute an invitation or inducement to engage in investment activity. You may wish to seek your own professional advice suitable to your particular circumstances prior to making any investment or if you are in doubt as to the information on this Platform.

V1 18.06.2021

1. **USER**

1.1. **Eligibility**

By accessing or using the Platform, you confirm that:

(a) you are of legal age in your jurisdiction and not under 18 years old. If you are under 18 years old, you must not access or use the Platform;

(b) you have full power, capacity and authority to agree to these Terms of Use or, if you are acting on behalf of a company or entity, that you have the authority to bind such entity to agree to these Terms of Use, and have not been previously suspended or removed from using the Platform;

(c) you acknowledge and agree that (i) no investment, tax or legal advice is being, or will be, provided on the Platform, and (ii) you shall be responsible for your own investment decisions based on your own independent research and analysis and seeking independent investment advice; and

(d) you are not accessing the Platform in any jurisdiction where it is prohibited under Applicable Laws or where we do not authorise usage.

It is your responsibility to check whether any restrictions under Applicable Laws apply to you in relation to the Platform. No information referred to on the Platform are directed at, or offered to, any one in a jurisdiction where: (i) advertising, offering or selling by us is not allowed by Applicable Laws, or (ii) illio would have to register or obtain a licence registration, authorization or other approval which we do not currently have. Distributing information in certain jurisdictions may be restricted by Applicable Law. As a result, you must be aware of, and comply with any such restrictions.

1.2. **User Account Creation**
To use certain features of the Platform, you (or such Authorised User) will need to create a user

account (each, a &quot;User Account&quot;):

(a) _ **Individual User Account** _: you will need to provide your first and last names, an email address, a password, your date of birth and other information that we may require from time to time (for example, if you wish to receive postal invoices, we may request a billing address); or

(b) _ **Corporate User Account** _: you will need to provide the first and last names of the individual completing the registration process as authorised contact on behalf of the relevant entity, the name of the entity that will be the User and certain other information relating to and identifying that entity. In these Terms of Use, save where the context requires otherwise, &quot; **you**&quot; will refer to the Corporate User and not the individual contact person; or

V1 18.06.2021

(c) _ **Mobile User Account** _: you will need to provide your first and last names, an email address, a password, your date of birth and other information that we may require from time to time (for example, if you wish to receive postal invoices, we may request a billing address).

For the purposes of these Terms of Use, references to an &quot; **Individual User**&quot; mean a User who who accesses the Platform as an individual; references to a &quot; **Corporate User**&quot; means a User that accesses the Platform as a company, corporation or other legal entity regardless of where the entity is incorporated or established and includes any User that is not an individual or natural person; references to a &quot; **Mobile User**&quot; mean a User who access the Platform through the Mobile App.

You will also be required to create a username and password for the User Account in order to access the Platform. Please provide accurate and current information when creating your User Account.

illio may, at its sole discretion:
(a) limit the number of User Accounts that you may create or maintain;

(b) stipulate additional conditions and requirements for the opening or maintaining of User Accounts; and/or

(c) refuse to create a User Account for you. 1.3. **Security**

By using the User Account, you will be responsible for maintaining the security of your User Account, for all activities that occur under the User Account and any other actions in relation to the User Account (with or without your permission). illio is not responsible for any loss or activity that results from the unauthorised use of your User Account.

Neither Individual Users nor Mobile Users should share the access details of your User Account (including passwords) with any other person or allow any other person to access your User Account.

Corporate Users must take all necessary measures to ensure the access details of the User Account (including passwords) are accessible only to persons it has authorised to access the User Account and act on its behalf in connection with the User Account.

You must immediately notify the illio team in writing at illio@illio.com of any unauthorised use of your User Account or any other breaches of security.

1.4. **Suspension and termination**

V1 18.06.2021

illio will have the right to suspend access to your User Account, or to terminate your User Account, at any time and for any reason (including where you are in breach of any of the provisions in this Term of Use), subject to Applicable Laws.

Upon final termination of your User Account, illio will permanently delete information relating to your portfolio of investments (&quot; **Portfolio Data**&quot;) and, in the event that you re-register in future, your past Portfolio Data can therefore not be restored.

You acknowledge and agree that these Terms of Use continue to apply even after your User Account expires or is terminated, or where you have stopped using the Platform.

1.5 **Hosted Services**

The Hosted Services are proprietary applications authored and/or collated by illio and hosted on illio&#39;s choice of cloud provider combined with SaaS/PaaS utility functions which together form the platform. The major functions of the platform are: 1) the portfolio management system which provides insight and information about multi-asset portfolios and may be accessed via a compliant web browser over https and the mobile application and 2) the management portal which provides user, account and instrument management functions and which may only be accessed via a compliant web browser.

illio shall create an Account for you and shall provide to you login details for that Account on or promptly following the Effective Date.

Subject to you purchasing the User Subscriptions, the restrictions set out in this clause 1.5 and the other terms and conditions of these Terms of Use, illio will grants to you a non-exclusive, non-transferable right, without the right to grant sublicences, to permit the Authorised Users to use the Hosted Services and the Documentation during the applicable Subscription Term.

In relation to the Authorised Users, you agree that:

1. (a)  the maximum number of Authorised Users that you authorise to access and use the Hosted Services and the Documentation shall not exceed the number of User Subscriptions you have purchased from time to time;
2. (b)  you will not allow or suffer any User Subscription to be used by more than one individual Authorised User unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior Authorised User shall no longer have any right to access or use the Hosted Services and/or Documentation;
3. (c)  each Authorised User shall keep a secure password for his use of the Hosted Services and Documentation and that each Authorised User shall keep his password confidential;

V1 18.06.2021

1. (d)  you shall maintain a written, up to date list of current Authorised Users and provide such list to illio within five (5) Business Days of illio&#39;s written request at any time or times;
2. (e)  you shall permit illio or illio&#39;s designated auditor to audit the Hosted Services in order to establish the name and password of each Authorised User and your data processing facilities to audit compliance with these Terms of Use. Each such audit may be conducted no more than once per quarter, at illio&#39;s expense, and this right shall be exercised with reasonable prior notice, in such a manner as not to substantially interfere with your normal conduct of business;
3. (f)  if any of the audits referred to in (e) above reveal that any password has been provided to any individual who is not an Authorised User, then without prejudice to the illio&#39;s other rights, you shall promptly disable such passwords and illio shall not issue any new passwords to any such individual; and
4. (g)  if any of the audits referred to in (e) above reveal that you have underpaid Subscription Fees to illio, then without prejudice to illio&#39;s other rights, you shall pay to illio an amount equal to such underpayment as calculated in accordance with the prices set out in the SaaS Agreement within ten (10) Business Days of the date of the relevant audit.

Except to the extent expressly permitted in these Terms of Use or required by law on a non- excludable basis, the licence granted by illio to you under the SaaS Agreement is subject to the following prohibitions, you must not:

1. (a)  attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Platform and/or Documentation (as applicable) in any form or media or by any means; or
2. (b)  attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Platform; or
3. (c)  sub-license its right to access and use the Hosted Services; or
4. (d)  permit any unauthorised person to access or use the Hosted Services; or
5. (e)  use the Hosted Services to provide services to third parties other than the Permitted Services; or
6. (f)  republish or redistribute any content or material from the Hosted Services other than Permitted Materials; or

V1 18.06.2021

1. (g)  access all or any part of the Platform and Documentation in order to build a product or service which competes with the Platform and/or the Documentation; or
2. (h)  make any alteration to the Platform, except as permitted by the Documentation; or
3. (i)  introduce or permit the introduction of, any Virus into illio&#39;s network and information systems; or
4. (j)  conduct or request that any other person conduct any load testing or penetration testing on the Platform or Hosted Services without the prior written consent of illio.

You shall use all reasonable endeavours, including all reasonable security measures relating to Account access details, to ensure that no unauthorised person may gain access to the Hosted Services using an Account.

illio shall use reasonable endeavours to maintain the availability of the Hosted Services to the User at the gateway between the public internet and the network of the hosting services provider for the Hosted Services, but does not guarantee 100% availability.

Where practicable, illio shall give to You at least 10 Business Days&#39; prior written notice of scheduled Maintenance Services that are likely to affect the availability of the Hosted Services or are likely to have a material negative impact upon the Hosted Services, without prejudice to illio&#39;s other notice obligations under these Terms of Use.

illio shall give you at least 10 Business Days&#39; prior written notice of the application of an Upgrade to the Platform.

illio shall provide the Maintenance Services with reasonable skill and care.

illio may suspend the provision of the Maintenance Services if any amount due to be paid by you under these Terms of Use and/or the SaaS Agreement is overdue, and illio has given you at least 30 days&#39; written notice, following the amount becoming overdue, of illio&#39;s intention to suspend the Maintenance Services on this basis.

For the avoidance of doubt, downtime caused directly or indirectly by any of the following shall not be considered a breach of these Terms of Use by illio:

1. (a)  a Force Majeure Event;
2. (b)  a fault or failure of the internet or any public telecommunications network;
3. (c)  a fault or failure of the User&#39;s computer systems or networks;
4. (d)  any breach by the User of these Terms of Use; or

V1 18.06.2021

(e) scheduled maintenance carried out in accordance with these Terms of Use.

You must not use the Hosted Services in any way that causes, or may cause, damage to the Hosted Services or Platform or impairment of the availability or accessibility of the Hosted Services.

You shall not access, store, distribute or transmit any Viruses, or any material during the course of its use of the Platform that:

1. (a)  is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;
2. (b)  facilitates illegal activity;
3. (c)  depicts sexually explicit images;
4. (d)  promotes unlawful violence;
5. (e)  is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or
6. (f)  is otherwise unlawful, illegal, fraudulent or causes damage or injury to any person or property;

and illio reserves the right, without liability or prejudice to its other rights to you, to disable your access to any material that breaches the provisions of this clause.

For the avoidance of doubt, you have no right to access the software code (including object code, intermediate code and source code) of the Platform, either during or after the Subscription Term.

illio may suspend the provision of the Hosted Services if any amount due to be paid by you to illio under the SaaS Agreement is overdue, and illio has given to you at least 30 days&#39; written notice, following the amount becoming overdue, of its intention to suspend the Hosted Services on this basis.

You shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Hosted Services and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify illio.

The rights provided under the SaaS Agreement are granted to you only, and shall not be considered granted to any subsidiary or holding company of yours.

1.6 **Support Services**
illio shall provide the Support Services with reasonable skill and care.

V1 18.06.2021

You may contact illio for the purposes of requesting and, where applicable, receiving the Support Services.

illio shall respond as soon as reasonably practicable to all requests for Support Services made by you.

illio may suspend the provision of the Support Services if any amount due to be paid by you to illio under these Terms of Use and/or the SaaS Agreement is overdue, and illio has given you at least 30 days&#39; written notice, following the amount becoming overdue, of illio&#39;s intention to suspend the Support Services on this basis.

2. **SUBSCRIPTION** 2.1. **Subscription**

You can choose to become a subscriber to the Platform on an annual basis, or for such other periods that illio may offer from time to time. The applicable fees are set forth on the illio webpage www.illio.com.

Eligibility for any promotions or discounts is ascertained at the time you become a subscriber and cannot be changed during the term of your subscription. You are responsible for reading and understanding the specific terms and conditions applicable to any promotions or discounts.

illio reserves the right to revise and update the applicable fees for subscriptions, and the different subscription packages available, at any time at its sole discretion. We may revise or update fees from time to time. However, any price changes to your subscription plan will apply to subsequent billing cycles following notice of the change(s) to you. Prior to the subsequent billing cycle, if you do not agree to the revised fees, you may cancel your subscription by contacting illio using the details set out in Clause 10. Otherwise, you will be deemed to have agreed to the revised fees, which will be automatically applied on the subsequent billing cycle.

2.2. **Payment details**

2.2.1 **Individual Users &amp; Mobile Users**

Subscription fees are payable on the illio website through a credit card payment system.

The currency in which your subscription is payable will be specified during the order process, and may vary depending on your jurisdiction of residence.

When you purchase a subscription, you must provide us with complete and accurate payment information. By submitting payment details you promise that you are entitled to purchase a subscription using those payment details. If we do not receive payment authorisation or any authorisation is subsequently cancelled, we may immediately terminate or suspend your access to your subscription. illio reserves the right to contact the issuing bank/payment provider and/or law enforcement authorities or other appropriate third parties if there is suspicious activity.

V1 18.06.2021

In addition to the subscription fee you are charged, certain banks and credit card issuers will charge a foreign transaction fee on transactions which take place abroad or in a foreign currency. You are responsible for paying any such fees.

All amounts stated in or in relation to the subscription, unless the context requires otherwise, stated exclusive of any applicable value added taxes, which will be added to those amounts and payable by you to illio.

illio may elect to vary any element of the Charges by giving you not less than 30 days&#39; written notice of the variation.

illio shall issue invoices for the Charges to you from time to time during the Subscription Term.

You must pay the Charges to illio within the period of 30 days following the issue of an invoice in accordance with this Clause 2.2.1 providing that the Charges must in all cases be paid before the commencement of the period to which they relate.

You must pay the Charges using such payment details as are notified by illio to you from time to time.

2.2.2 **Corporate Users**
Subscription fees are payable in accordance with your SaaS Agreement.
If you do not pay any amount properly due to illio under the SaaS Agreement, illio may:

1. (a)  charge the User interest on the overdue amount at the rate of 4% per annum above the Bank of England base rate from time to time (which interest will accrue daily until the date of actual payment and be compounded at the end of each calendar month); or
2. (b)  claim interest and statutory compensation from you pursuant to the Late Payment of Commercial Debts (Interest) Act 1998; and

if illio has not received payment within 30 days after the due date, and without prejudice to any other rights and remedies of illio, it may, without liability to you, disable your password, account and access to all or part of the Hosted Services and illio shall be under no obligation to provide any or all of the Hosted Services while the invoice(s) concerned remain unpaid.

Subject to (a) and (b) below, you may, from time to time during any Subscription Term, purchase additional User Subscriptions in excess of the number set out the SaaS Agreement and illio shall grant access to the Hosted Services and the Documentation to such additional Authorised Users in accordance with the provisions of these Terms of Use.

V1 18.06.2021

If you wish to purchase additional User Subscriptions, you shall notify illio in writing. Illio shall evaluate such request for additional User Subscriptions and respond to you with approval or rejection of the request (such approval not to be unreasonably withheld). Where illio approves the request, illio shall activate the additional User Subscriptions within five (5) Business Days of its approval of your request.

If illio approves the your request to purchase additional User Subscriptions, you shall, within 30 days of the date of illio&#39;s invoice, pay to illio the relevant fees for such additional User Subscriptions as set out in the SaaS Agreement and, if such additional User Subscriptions are purchased by you part way through the Initial Subscription Term or any Renewal Period (as applicable), such fees shall be pro-rated from the date of activation by illio for the remainder of the Initial Subscription Term or then current Renewal Period (as applicable).

All amounts and fees stated or referred to in the SaaS Agreement:

1. (a)  shall be payable in US Dollars;
2. (b)  are, unless specified in these Terms of Use, non-cancellable and non-refundable;
3. (c)  are exclusive of value added tax, which, if applicable, shall be added to the illio&#39;s invoice(s) at the appropriate rate.

2.3. **Automatic renewal of subscription**
Your subscription will automatically renew unless you cancel at least ninety (90) days before the

end of the current billing period.
2.4. **Cancellation and refund of subscription fees**

Except as permitted in accordance with Clause 2.3 above, subscriptions are non-cancellable and non-refundable and there is no right to refunds or credits unless required by Applicable Laws. Although you may notify us of your intention to cancel your subscription at any time, such notice will only take effect at the end of your then current subscription period, and you will not receive a refund.

3. **USE OF THE PLATFORM**
3.1. By using the Platform, you confirm that you will not use the Platform for any of the

following:

(a) _Unlawful Activity_ – any activity that: (i) involves proceeds from any illegal or unlawful activity; (ii) publishes, distributes or disseminates any illegal or unlawful material or information; or (iii) otherwise violates, or could possibly violate, any civil and common laws, statutes, subordinate legislation, treaties, regulations, directives, decisions, by-laws, ordinances, circulars, codes, orders, notices, demands, decrees, injunctions, resolutions and judgments of any government, quasi-government, statutory, administrative or regulatory body, court, agency or

V1 18.06.2021

association by which illio or the User are bound in any jurisdiction applicable to the access and use of the Platform (&quot;Applicable Laws&quot;).

(b) _Unauthorised Use_ – any activity that: (i) interferes with, disrupts, negatively affects or inhibits other Users from accessing or fully enjoying the Platform; (ii) introduces to the Platform any malware, virus, trojan horse, worms, logic bombs or any other programme that would otherwise result in any technical glitch, malfunction, failure, delay, default or security breach; (iii) attempts to gain unauthorised access, whether through password mining or otherwise, to other User Accounts, computing systems or networks connected to the Trading Platform; (iv) undermines the security or integrity of the computing systems or networks on which the Platform is hosted; (v) attempts to modify, copy, reproduce, reverse engineer or decompile the Platform or the computer programs used to deliver the Platform; (vi) uses the User Account information of another person to access or use the Platform; or (vii) transfers access or rights to your User Account to a third party.

(c) _Abusive Acts_ – any act that: (i) defames, abuses, extorts, harasses, stalks, threatens or otherwise violates or infringes the legal rights (such as, but not limited to, rights of privacy, publicity and intellectual property) of any other person; (ii) incites, threatens, facilitates, promotes, or encourages hate, racial intolerance or violent acts towards any other person; or (iii) harvests or otherwise collects information from the Platform, including about other Users.

(d) _Fraud_ – any act that: (i) attempts to defraud illio or any other person; or (ii) provides false, inaccurate or misleading information to illio.

3.2. You understand and agree that the information and any other content contained in or provided to you on this Platform is general in nature and is provided for information purposes only. Information provided to you on this Platform does not take into account your specific investment objectives, financial situation, tax situation or particular needs, and (a) does not and is not intended to constitute advice on, or a solicitation or offer of, or recommendation on, any investment or financial products of any nature, and (b) does not and is not intended to constitute an invitation or inducement to engage in investment activity. Nothing on this Platform shall be construed as giving any recommendation or advice on any securities or investments or as inducing any person to buy or sell securities or investments. You may wish to seek your own professional advice (including financial, legal and tax advice) suitable to your particular circumstances prior to making any investment or if you are in doubt as to the information on this Platform. The information contained on this Platform is not directed at or intended for distribution or publication to, or use by any person who is a resident of or located in any jurisdiction in which the direction, distribution or publication or use of such information would be contrary to Applicable Laws or which would subject illio (or any other person referred to on this Platform) to any registration or licensing requirements in respect of that jurisdiction. illio is not licensed, registered, authorised or otherwise regulated in any jurisdiction by any regulatory authority.

V1 18.06.2021

4. **AVAILABILITY OF SERVICES AND SECURITY**

4.1. illio shall use commercially reasonable efforts to ensure that the Platform is available to you. However, access to the Platform may be disrupted from time to time due to necessary maintenance, technical issues, network and system capacity constraints or other events outside of illio&#39;s control. illio will use commercially reasonable efforts to avoid downtime of the Platform, but assumes no liability if the Platform or any part thereof is unavailable at any time or for any period.

4.2. You acknowledge and agree that you are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for antivirus protection and accuracy of data input and output, and for maintaining a means external to the Platform for any reconstruction of any lost data.

4.3. You acknowledge and agree that illio shall not have any liability or be responsible in any way for: (a) your use of the internet to connect to the Platform or any technical problems, system failures or security breaches; (b) the hardware that you use to access the Platform (including in respect of viruses and malicious software, and any inappropriate material) and the integrity and proper storage of any of your data associated with the Platform that is stored on your own hardware; or (c) any fees you may incur in order to connect to the internet for the purpose of using or accessing the Platform.

4.4. You must immediately notify the illio team in the event that you become aware of any part of the Platform malfunctioning or if you otherwise experience any material malfunction or other connectivity problem that adversely affects your access to or use of the Platform.

5. **INTELLECTUAL PROPERTY AND USE LICENCE** 5.1. **illio&#39;s ownership of the Platform**

All of the patents, trademarks, logos, trade names, rights in domain names, copyrights, moral rights, design rights, database rights, rights in undisclosed or confidential information (such as know-how, trade secrets and inventions (whether or not patentable) and other similar intellectual property rights (whether registered or not)) and applications for such rights as may exist anywhere in the world (collectively, &quot;Intellectual Property Rights&quot;) in the Platform and the material published on and through it are owned by illio, its licensors and other providers of such material and are protected by applicable intellectual property laws and treaties around the world. You may not engage in any activity on or through the Platform that infringes or otherwise makes unauthorised use of another party&#39;s Intellectual Property Rights.

5.2. **User&#39;s licence to use Platform**
You are not granted any right to use, and may not use, any of illio&#39;s Intellectual Property Rights

other than as set out in these Terms of Use and subject to the following conditions:

V1 18.06.2021

(a) you are granted a limited, personal, non-transferable, non-assignable, non-sublicensable and revocable license to access and use the Platform (or any part of it or its contents) for your own personal use and may not copy, reproduce, republish, upload, re-post, modify, transmit, distribute or otherwise use the Platform (or any part of it or its content) in any way for non- personal, public or commercial use without prior written consent from illio;

(b) you may not remove or modify any copyright, trademark or other proprietary notices that have been placed in any part of the Platform; and

(c) you may not use any data mining, robots or similar data-gathering or extraction methods.

illio reserves the right to monitor your use of the Platform and to alter or revoke your license or your access to the Platform at any time and for any reason. Any monitoring by illio will be restricted to technical purposes only (e.g. Platform maintenance), but illio will not, subject to Clause 7.4, access your Portfolio Data or grant such access to another other person, other than where you have provided consent to such access or in the event of urgent technical necessity.

Your license shall terminate upon the expiry or termination of your User Account.

6. **THIRD PARTY SERVICES AND CONTENT**

In using the Platform, you may view content provided by third-parties, including links to web pages and services of such parties (&quot;Third Party Content&quot;). Unless expressly stated otherwise, illio does not control, endorse or adopt any Third Party Content and has no responsibility for Third Party Content including material that may be misleading, incomplete, erroneous, offensive, indecent or otherwise objectionable in your jurisdiction.

Your dealings or correspondence with such third parties are solely between you and the third party. illio is not responsible or liable for any loss or damage of any sort incurred as a result of any such dealings, and you understand that your use of Third Party Content, and your interactions with third parties, is at your own risk.

If you access the Platform through or using any services or software provided by third parties, you acknowledge and agree that illio is not responsible or liable for any loss or damage of any kind incurred as a result of your use of such third party services or software.

7. **LIMITATIONS OF LIABILITY, DISCLAIMER, INDEMNITY** 7.1. **Limitation of Liability**

(a) Nothing in these Terms of Use shall exclude or restrict illio&#39;s liability for: (a) death or personal injury resulting from the negligence of illio or its Associated Parties (defined below); (b) fraud or fraudulent misrepresentation; or (c) any other matter that cannot be excluded or limited under Applicable Laws.

(b) Subject to the foregoing, to the maximum extent permitted by Applicable Law:

V1 18.06.2021

(i) in no event shall illio, its affiliates and its and their respective shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers and contractors (collectively, &quot;Associated Parties&quot;) be liable for any:

(A) indirect or consequential loss, including any loss or damage of a kind or extent which was not reasonably foreseeable at the time of entering into these Terms of Use; or

(B) loss of profit, business opportunity, anticipated savings, revenue or goodwill,

in each case, whether arising from breach of contract, tort (including negligence), breach of statutory duty or otherwise, arising out of or in connection with authorised or unauthorised use of the Platform, or these Terms of Use; and

(ii) illio and the Associated Parties&#39; aggregate liability whether arising from breach of (including negligence) contract, tort, breach of statutory duty or otherwise (including any liability for any negligent act or omission) howsoever arising out of, or in connection with, the performance of its obligations under these Terms of Use in respect of any one or more incidents or occurrences shall be limited to the greater of: (i) HKD 1,000; and (ii) the total amount of fees received by illio or the Associated Parties from you in connection with your use of the Platform in the twelve (12) month period prior to the date of the first incident or series of connected incidents giving rise to a claim made by you under these Terms of Use.

7.2. **Disclaimers**

To the maximum extent permitted under applicable law, the platform and any product, hosted services, service or other item provided by illio or on behalf of illio by a third party provider (&quot;illio platform&quot;) are provided on an &quot;as is&quot; and &quot;as available&quot; basis and illio expressly disclaims, and you waive, any and all other warranties of any kind, whether express or implied, including implied warranties of merchantability, fitness for a particular purpose, title or infringement or non-infringement or warranties arising from course of performance, course of dealing or usage in trade and no warranties are made as to results to be obtained from use of the same.

Without limiting the foregoing, illio nor any of its third party providers does not represent or warrant that the illio platform is accurate, complete, reliable, uninterrupted, timely, current or error-free, or free of viruses or other harmful components. Use of the same and reliance thereon is at your sole risk. Neither illio or its third party providers will in any way be liable to you or any user or any other entity or person for their inability to use the illio platform, or for any inaccuracies, errors, omissions, delays, computer virus or other infirmity or corruption, damages, claims, liabilities or losses, regardless of cause, in or arising from the use of the illio platform.

In no event will illio or its third party providers be liable for any damages, including without limitation direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with illio platform even if illio or its third party providers or their representatives are advised of the possibility of such damages, losses or expenses. Further, illio or its third party providers shall not be liable in any manner for redistributor&#39;s products or services.

V1 18.06.2021

7.3. **Indemnification**

To the maximum extent permitted by Applicable Law, you agree to indemnify and hold harmless immediately upon demand illio and the Associated Parties from any claim, demand, lawsuit, action, proceeding, investigation, liability, damage, loss, cost or expense including reasonable attorneys&#39; fees, in any way arising out of, in relation to or in connection with directly or indirectly:

(a) your use of, or your Customer&#39;s use of, or conduct in, or Customer&#39;s conduct in, connection with, the Platform;

(b) your breach of these Terms of Use;

(c) feedback or submissions you provide; or

(d) your violation of any Applicable Law or the rights of any other person or entity.

You will provide illio and the Associated Parties with any assistance that illio and the Associated Parties reasonably requests in defending any such action or proceeding.

7.4. **Confidentiality**

illio will keep your Portfolio Data confidential on the terms herein and exercise at least the same degree of care with respect to the Portfolio Data that illio exercises to protect its own confidential information of a similar nature, and in any event, no less than reasonable care.

You grant illio the right to use and disclose the Portfolio Data to the extent necessary (i) for the purposes of storage and purely technical processing at illio&#39;s direction, subject to industry- standard security measures (e.g. storage on a secure cloud); (ii) where illio is required to do so pursuant to Applicable Laws; and (iii) in the event of a sale of the illio business as a whole. illio may disclose Portfolio Data to Associated Parties who have a &quot;need to know&quot; such information and only to the extent necessary to provide the services on the Platform.

8. **CHANGES AND UPDATES TO THE PLATFORM AND TERMS OF USE**

illio may terminate or modify any feature or part of the Platform at any time for any reason whatsoever without notice but will use commercially reasonable endeavours to notify you in advance of any change which would materially impact your use of the Platform. illio may also revise and update these Terms of Use at any time in its sole discretion by posting an updated Terms of Use on the Platform. All such changes to the Terms of Use are effective immediately when posted to the Platform and apply to all access to and use of the Platform (including orders placed on the Platform) thereafter. Your continued use of the Platform following the posting of such revised Terms of Use constitutes your acceptance and agreement to the changes which are binding on you. If a partial termination or modification in accordance with this Clause 8 fundamentally and detrimentally changes the nature of or the rights granted in the Platform, you

V1 18.06.2021

may terminate the affected Hosted Services by providing illio with notice no later than 30 days after the date of illio&#39;s notice of partial termination or modification.

9. **WARRANTIES**

1. 9.1  illio warrants to you that:
1. (a)  illio will comply with all applicable legal and regulatory requirements applying to the exercise of illio&#39;s rights and the fulfilment of illio&#39;s obligations under these Terms of Use; and
2. (b)  illio has or has access to all necessary know-how, expertise and experience to perform its obligations under these Terms of Use.
2. 9.2  illio warrants to you that:
1. (a)  the Platform and Hosted Services will conform in all material respects with the Hosted Services Specification;
2. (b)  the Hosted Services will be free from Hosted Services Defects;
3. (c)  the application of Updates and Upgrades to the Platform by illio will not introduce any Hosted Services Defects into the Hosted Services;
4. (d)  the Platform will be free from viruses, worms, Trojan horses, ransomware, spyware, adware and other malicious software programs; and
5. (e)  the Platform will incorporate security features reflecting the requirements of good industry practice.
3. 9.3  illio warrants to you that the Hosted Services, when used by you in accordance with these Terms of Use, will not breach any laws, statutes or regulations applicable under English law.
4. 9.4  illio warrants to you that the Hosted Services, when used by you in accordance with these Terms of Use, will not infringe the Intellectual Property Rights of any person in any jurisdiction and under any applicable law.
5. 9.5  If illio reasonably determines, or any third party alleges, that the use of the Hosted Services by the User in accordance with these Terms of Use infringes any person&#39;s Intellectual Property Rights, illio may at its own cost and expense:

(a) modify the Hosted Services in such a way that they no longer infringe the relevant Intellectual Property Rights; or

V1 18.06.2021

(b) procure for you the right to use the Hosted Services in accordance with these Terms of Use.

1. 9.6  You warrant to illio that you have the legal right and authority to enter into these Terms of Use and to perform your obligations under these Terms of Use.
2. 9.7  You shall:
1. (a)  provide illio with:
1. (i)  all necessary co-operation in relation to these Terms of Use; and
2. (ii)  all necessary access to such information as may be required by illio;

in order to provide the Hosted Services, including but not limited to Customer Personal Data;

1. (b)  without affecting its other obligations under these Terms of Use, comply with all applicable laws and regulations with respect to its activities under these Terms of Use;
2. (c)  carry out all other responsibilities set out in these Terms of Use in a timely and efficient manner. In the event of any delays in your provision of such assistance as agreed by the parties, illio may adjust any agreed timetable or delivery schedule as reasonably necessary;
3. (d)  ensure that the Authorised Users use the Hosted Services and the Documentation in accordance with the terms and conditions of these Terms of Use and shall be responsible for any Authorised User&#39;s breach of these Terms of Use;
4. (e)  obtain and shall maintain all necessary licences, consents, and permissions necessary for illio, its contractors and agents to perform their obligations under these Terms of Use, including without limitation the Hosted Services;
5. (f)  ensure that its network and systems comply with the relevant specifications provided by illio from time to time; and
6. (g)  be, to the extent permitted by law and except as otherwise expressly provided in these Terms of Use, solely responsible for procuring, maintaining and securing its network connections and telecommunications links from its systems to the Supplier&#39;s data centres, and all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to your network connections or telecommunications links or caused by the internet.
1. 9.8  All of the parties&#39; warranties and representations in respect of the subject matter of these Terms of Use are expressly set out in these Terms of Use. To the maximum extent

V1 18.06.2021

permitted by applicable law, no other warranties or representations concerning the subject matter of these Terms of Use will be implied into these Terms of Use or any related contract.

1. 9.9  These Terms of Use shall not prevent illio from independently developing, using, selling or licensing documentation, products and/or services which are similar to those provided under these Terms of Use.
2. 9.10  You acknowledge that the Hosted Services are designed to be compatible only with that software and those systems specified as compatible in the Hosted Services Specification; and illio does not warrant or represent that the Hosted Services will be compatible with any other software or systems.
3. 9.11  You acknowledge that pursuant to these Terms of Use, illio will not provide any legal, financial, accountancy or taxation advice under these Terms of Use or in relation to the Hosted Services; and, except to the extent expressly provided otherwise in these Terms of Use, illio does not make any warranties or representations, and will not accept any legal liability from you or any other person, in relation to the Hosted Services, the use of the Hosted Services or use of or reliance on any market data accessible via the Platform by you.
4. 9.12  You acknowledge that the Hosted Services may enable or assist it to access the website content of, correspond with, and purchase products and services from, third parties via third-party websites and that it does so solely at its own risk. illio makes no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third- party website, or any transactions completed, and any contract entered into by you, with any such third party. Any contract entered into and any transaction completed via any third-party website is between you and the relevant third party, and not illio. illio recommends that you refer to the third party&#39;s website terms and conditions and privacy policy prior to using the relevant third-party website. illio does not endorse or approve any third-party website nor the content of any of the third-party website made available via the Hosted Services and/or relied on by you.
5. 9.13  illio is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and you acknowledge that the Hosted Services and Documentation may be subject to limitations, delays and other problems inherent in the use of such communications facilities.

**10. COMPLAINTS, GOVERNING LAW AND DISPUTE RESOLUTION**

10.1. If you have any questions, feedback or complaints, please contact the illio team at the following email address: illio@illio.com. Alternatively, you can contact the illio team by completing a contact form (available at this webpage: www.illio.com) or through a chat function on the illio website.

V1 18.06.2021

1. 10.2.  These Terms of Use (including a dispute relating to its existence, validity or termination) and any non-contractual obligation or other matter arising out of or in connection with it are governed by the laws of England. The courts of England have exclusive jurisdiction to settle any dispute arising from or connected with these Terms of Use.
2. 10.3.  If you are a consumer for the purposes of the laws of the jurisdiction of which you are resident, you may benefit from any mandatory provisions of such laws. Nothing in these Terms of Use, including this Clause 10, affects your rights as a consumer to rely on such mandatory provisions of law.

11. **GENERAL** 11.1. **Data Privacy**

We will only use your personal information as set out in illio&#39;s Privacy Policy (as amended from time to time) available at the following webpage: www.illio.com.

11.2. **Transfers**

illio may transfer its rights and obligations under these Terms of Use to another entity, but this will not affect your rights or illio&#39;s obligations under these Terms of Use or the Privacy Policy. You may not transfer your rights and obligations under these Terms of Use to another person.

11.3. **Validity**

If any provision of these Terms of Use is deemed unlawful, void or unenforceable, that provision shall be deemed severed from the remaining provisions and shall not affect their validity and enforceability.

11.4. **Waiver**

No waiver of any provision hereof shall be valid unless in writing and signed by the parties. Any failure to enforce any right or remedy hereunder shall not operate as a waiver of the right to enforce such right or remedy in the future or of any other right or remedy.

11.5. **Relationship of the parties**

Nothing in the Terms of Use: (a) is intended to, nor shall create or be deemed to create, any partnership, joint venture, agency, consultancy or trusteeship, or (b) shall give rise to any fiduciary or equitable duties owed by illio to you.

11.6. **Third party rights** V1 18.06.2021

A person who is not a party to these Terms of Use has no right whether by applicable statute or otherwise to enforce any term of these Terms of Use.

11.7. **Force Majeure Event**

A &quot;Force Majeure Event&quot; means any event beyond illio&#39;s reasonable control, including flood, extraordinary weather conditions or earthquake, or other act of God, fire, war, insurrection, riot, labour dispute, accident, action of government, communications, power failure, or third party system.

If a Force Majeure Event occurs that affects illio&#39;s ability to perform its obligations under these Terms of Use, the illio team will contact you as soon as reasonably possible to notify you and illio&#39;s obligations under these Terms of Use will be suspended and the time of performance of our obligations will be extended for the duration of the Force Majeure Event.

Where a Force Majeure Event takes place and you no longer wish to be a subscriber, you may cancel your subscription and receive a full refund.

11.8 **Mobile App**

You acknowledge and agree that the use of the Mobile App, yours and illio&#39;s respective rights and obligations in relation to the Mobile App and any liabilities of either party arising out of the use of the Mobile App shall be subject to these Terms of Use.

V1 18.06.2021

**1.**

1.1

**Definitions**

**SCHEDULE**

In these Terms of Use, except to the extent expressly provided otherwise:

&quot; **Account**&quot; means an account enabling a person to access and use the Hosted Services;

&quot; **Authorised Users**&quot; means those employees, agents and independent contractors of you/the User or such third parties affiliated to you/the User who are authorised and/or provided access by you/the User to use, view or access to the Platform and the Documentation;

&quot; **Business Day**&quot; a day other than a Saturday, Sunday or public holiday in Hong Kong when banks in Hong Kong are open for business;

&quot; **Business Hours**&quot; means the hours of 09:00 to 17:00 HKT/UTC+8 on a Business Day; &quot; **Charges**&quot; means the applicable amounts specified in the Terms of Use and the SaaS

Agreement;

&quot; **Customer Confidential Information**&quot; means:

1. (a)  any information disclosed by or on behalf of the User to illio during the Subscription Term or at any time before the termination of the SaaS Agreement (whether disclosed in writing, orally or otherwise) that at the time of disclosure:
1. (i)  was marked as &quot;confidential&quot;; or
2. (ii)  should have been reasonably understood by the Provider to be confidential; and
2. (b)  the Customer Data;

&quot; **Customer Data**&quot; means all data, works and materials: uploaded to or stored on the Platform by the User transmitted by the Platform at the instigation of the User; supplied by the User to illio for uploading to, transmission by or storage on the Platform; or generated by the Platform as a result of the use of the Hosted Services by the User (but excluding analytics data relating to the use of the Platform and server log files);

&quot; **Customer Personal Data**&quot; means any Personal Data that is processed by illio on behalf of the User in relation to this Agreement, but excluding data with respect to which illio is a data controller;

&quot; **Data Protection Laws**&quot; means all legislation and regulatory requirements in force from time to time relating to the use of personal data and the privacy of electronic communications, including, without limitation (i) any data protection legislation from time to time in force in the UK including the Data Protection Act 2018 or any successor

V1 18.06.2021

legislation, as well as (ii) the General Data Protection Regulation ((EU) 2016/679) and any other directly applicable European Union regulation relating to data protection and privacy (for so long as and to the extent that the law of the European Union has legal effect in the UK) and (iii) (where applicable and not covered in (i) or (ii) above) any data protection legislation from time to time in force in Hong Kong including the Personal Data (Privacy) Ordinance (PDPO) as amended in 2012 or any successor legislation;

&quot; **Documentation**&quot; means any documentation for the Hosted Services produced by illio and delivered or made available by illio to the User;

&quot; **Electronic Signature**&quot; means Adobe EchoSign, Verisign, Docu-Sign or any other form of electronic signature illio may agree to accept.

&quot; **Hosted Services**&quot; means the Services specified in the Hosted Services Specification, which will be made available by illio to the User as a service via the Platform in accordance with these Terms of Use;

&quot; **Hosted Services Defect**&quot; means a defect, error or bug in the Platform having a material adverse effect on the appearance, operation, functionality or performance of the Hosted Services, but excluding any defect, error or bug caused by or arising as a result of:

1. (a)  any act or omission of the User or any person authorised by the User to use the Platform or Hosted Services;
2. (b)  any use of the Platform or Hosted Services contrary to the Documentation, whether by the User or by any person authorised by the User;
3. (c)  a failure of the User to perform or observe any of its obligations in these Terms of Use; and/or
4. (d)  an incompatibility between the Platform or Hosted Services and any other system, network, application, program, hardware or software not specified as compatible in the Hosted Services Specification;

&quot; **Hosted Services Specification**&quot; means the specification for the Platform and Hosted Services set out in the SaaS Agreement and in the Documentation;

&quot; **Initial Subscription Term&quot;** means 12 months immediately following the end of the Trial Period, if any;

&quot; **Intellectual Property Rights**&quot; means all intellectual property rights wherever in the world, whether registrable or unregistrable, registered or unregistered, including any application or right of application for such rights (and these &quot;intellectual property rights&quot; include copyright and related rights, database rights, confidential information, trade secrets, know-how,

V1 18.06.2021

business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, semi-conductor topography rights and rights in designs);

&quot; **Maintenance Services**&quot; means the general maintenance of the Platform and Hosted Services, and the application of Updates and Upgrades;

&quot; **Mobile App**&quot; means the mobile application known as illio that is made available by illio to the User through a Supported Web Browser and more specifically from any of the &quot;Google Play Store&quot;, &quot;Apple App Store&quot; or such other third party portal from which the Mobile App is accessible;

&quot; **Permitted Materials**&quot; means any such reports published or downloadable from the Platform;

&quot; **Permitted Services**&quot; means any such services provided by the User exclusively to its clients in relation to the Platform in the ordinary course of business without liability to illio;

&quot; **Personal Data**&quot; has the meaning given to it in the Data Protection Laws applicable in the United Kingdom and (where applicable) Hong Kong from time to time;

&quot; **Platform**&quot; means the internet platform managed by illio and used by illio to provide the Hosted Services, including the application and database software for the Hosted Services, the system and server software used to provide the Hosted Services, and the computer hardware on which that application, database, system and server software is installed;

&quot; **Portfolio**&quot; means a collection of financial or other instruments, whether held individually or within a collective investment scheme and may or may not be managed by, but not limited to, an appointed representative such as a financial advisor or investment manager.

&quot; **SaaS Agreement**&quot; means the SaaS agreement between illio and the applicable Corporate User and including any Schedules, and any amendments to that agreement as may be agreed in writing between the parties from time to time;

&quot; **Schedule**&quot; means any schedule attached to the main body of any applicable SaaS Agreement;

&quot; **Services**&quot; means any services that illio provides to the User, or has an obligation to provide to the User, under these Terms of Use;

&quot; **Subscription Term**&quot; has the meaning given in the SaaS Agreement (being the Trial Period and the Initial Subscription Term together with any subsequent Renewal Periods);

&quot; **Subscription Fees**&quot; means the subscription fees payable by the User to illio for the User Subscriptions, as set out (i) for Individual Users, on the illio webpage www.illio.com and



V1 18.06.2021

(ii) for Corporate Users, in the applicable SaaS Agreement; and (iii) for Mobile Users, in the Mobile App;

&quot; **Support Services**&quot; means support in relation to the use of, and the identification and resolution of errors in, the Hosted Services, but shall not include the provision of training services;

&quot; **Supported Web Browser**&quot; means the current release from time to time of Microsoft Edge, Mozilla Firefox, Google Chrome or Apple Safari, or any other web browser that the Provider agrees in writing shall be supported;

&quot; **Trial Period**&quot; means a period of one month, or such other period as agreed between the parties;

&quot; **Trial User**&quot; means a User who is accessing the Platform in accordance with the Terms of Use during the Trial Period;

&quot; **Upgrade**&quot; means a major version upgrade of any Platform software; and

&quot; **User Subscriptions**&quot; means the user subscriptions purchased by (i) the Individual User and the Mobile User pursuant to these Terms of Use or (ii) the Corporate User, pursuant to the SaaS Agreement and these Terms of Use, each of which entitle Authorised Users to access and use the Platform and the Documentation.

&quot; **Virus**&quot; means any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re- arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices.

&quot; **Vulnerability**&quot; means a weakness in the computational logic (for example, code) found in software and hardware components that when exploited, results in a negative impact to the confidentiality, integrity, or availability, and the term **Vulnerabilities** shall be construed accordingly.

V1 18.06.2021
`;
