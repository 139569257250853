import { ReduxHelper } from '../utils/ReduxHelper';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import CountryActions from '../actions/CountryActions';

export interface ICountryState extends ILoadingDataState {
  data: ICountry[];
}

const INITIAL_STATE: ICountryState = ReduxHelper.createInitialState<ICountry[]>([]);

const countryReducer = ReduxHelper.createHttpRequestReducer<ICountryState>(
  INITIAL_STATE,
  CountryActions.COUNTRIES_REQUEST,
  CountryActions.COUNTRIES_SUCCESS,
  CountryActions.COUNTRIES_ERROR
);

export default countryReducer;
