import { ReduxHelper } from '../utils/ReduxHelper';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { UploadTemplateActions } from '../actions/UploadTemplateActions';
import { AnyAction } from 'redux';

export interface IUploadTemplateResultState extends ILoadingDataState {
  data: IUploadTemplateResult[];
  unparsedData: object;
  items: Array<Partial<IUploadTemplateResult>>;
}

const INITIAL_STATE: IUploadTemplateResultState = {
  ...ReduxHelper.createInitialState<IUploadTemplateResult[]>([]),
  items: [],
  unparsedData: [],
};

const uploadTemplateResultHttpReducer = ReduxHelper.createHttpRequestReducer<IUploadTemplateResultState>(
  INITIAL_STATE,
  UploadTemplateActions.UPLOAD_TEMPLATE_REQUEST,
  UploadTemplateActions.UPLOAD_TEMPLATE_SUCCESS,
  UploadTemplateActions.UPLOAD_TEMPLATE_ERROR
);

const uploadTemplateResultReducer = (state: IUploadTemplateResultState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case UploadTemplateActions.UPLOAD_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        data: action.payload.data.parsedRows,
        unparsedData: action.payload.data.unparsedRows,
        items: action.payload.data.parsedRows,
        cancelTokenSource: null,
        error: null,
      };
    }
  }

  return uploadTemplateResultHttpReducer(state, action);
};

export default uploadTemplateResultReducer;
