import { useEffect } from "react";


import {useHistory} from "react-router-dom";
import ReactGA from "react-ga4";

const {REACT_APP_GA_ID} = window.gaSettings?.gaId;

if(REACT_APP_GA_ID){
  ReactGA.initialize(REACT_APP_GA_ID!);
}


export const usePageTracking = () => {
  const {listen} = useHistory();
  useEffect(() => {
    return listen(async location => {
      setTimeout(() => {
        // ga4.pageview(location.pathname + location.search, window.location, document.title);
        if(REACT_APP_GA_ID){
          ReactGA.send({hitType: "pageview", path: ` ${location.pathname}${location.search}`, title: document.title})
        }
      }, 250)
    });
  }, [listen]);
}
