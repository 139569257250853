import React, { PureComponent, ReactElement } from 'react';
import cn from 'classnames';
import { Color } from 'csstype';

import { AllocationItem } from './components';
import { DrawConnections } from '../UIWidgets';
import { IConnections } from '../UIWidgets/DrawConnections/DrawConnections';

import s from './AllocationBar.module.css';
import { ColorsHelper } from '../../services/utils/ColorsHelper';
import { IAllocationItemRenderProps } from './components/AllocationItem/AllocationItem';

export interface IAllocationBar {
  id: string;
  name: string;
  value: number;
  moneyValue: any;
  diff?: number;
  isProfit?: boolean;
}

interface IProps {
  prefix: string;
  items: IAllocationBar[];
  color?: Color;
  title?: string;
  onItemClick?: (itemId: string) => void;
  renderItem?: (props: IAllocationItemRenderProps) => ReactElement;
}

export class AllocationBar extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    title: '',
    color: '#6677cc',
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.items !== this.props.items) {
      this._prepareConnections();
      this.renderBars();
      this.forceUpdate();
    }
  }

  renderBars = () => {
    const { items, prefix, color } = this.props;
    // without increment length by 1 last element opacity  would be near 0;
    const opacityStep = Math.floor(100 / (items.length + 1));

    return items.map((item: IAllocationBar, idx: number) => {
      let blockColor: Color | undefined = color;
      const opacity: number = 100 - opacityStep * idx;
      if (color) {
        blockColor = ColorsHelper.toRGBA(color, opacity);
      }

      return (
        <div key={idx} className={s.valueBarListBar} style={{ height: `${item.value}%`, backgroundColor: blockColor }}>
          <div id={`connect-from-${prefix}-${idx}`} className={s.valueBarListBarConnect} />
        </div>
      );
    });
  };

  renderValues = () => {
    const { items, prefix, renderItem, onItemClick } = this.props;
    return items.map((item, idx) => {
      return (
        <AllocationItem
          {...item}
          key={idx}
          id={item.id}
          connectId={`${prefix}-${idx}`}
          name={item.name}
          onItemClick={onItemClick}
          renderItem={renderItem}
        />
      );
    });
  };

  render() {
    const { color, title } = this.props;
    const connections: IConnections = this._prepareConnections();

    return (
      <div className={s.valueBarListWrapper}>
        <DrawConnections connections={connections} redraw={true} color={color} />
        <div className={cn(s.valueBarListBlockBars)}>{this.renderBars()}</div>
        <div className={cn(s.valueBarListBlockValues)}>
          {title && <h4 className={s.valueBarListBlockTitle}>{title}</h4>}
          <div className={s.valueBarListValuesList}>{this.renderValues()}</div>
        </div>
      </div>
    );
  }

  private _prepareConnections(): IConnections {
    const { items, prefix } = this.props;
    const connections: IConnections = {};

    items.forEach((item: IAllocationBar, idx: number) => {
      connections[`${prefix}-${idx}`] = [`${prefix}-${idx}`];
    });
    return connections;
  }
}
