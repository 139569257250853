import { ITableColumn } from '../../../../../../../../components/UIWidgets/TableWrapper/models';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import {
  explorerDecimalNumberRenderer,
  explorerPercentageRenderer,
} from '../../../../../PortfolioExplorer/utils/explorerCurrencyRenderer';

export function getTopPositionsColumns(portfolioCurrencyFormatter: ICurrencyFormatter): ColumnProps[] {
  return [
    {
      label: 'Instrument Name',
      dataKey: 'name',
      flexGrow: 2,
      width: 400,
    },
    {
      label: 'Custodian',
      dataKey: 'custodianName',
      flexGrow: 2,
      width: 200,
    },
    {
      label: 'Local Amount',
      dataKey: 'marketValueLocal',
      flexGrow: 2,
      width: 200,
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
    },
    {
      label: `Value (${portfolioCurrencyFormatter()})`,
      dataKey: 'marketValueBase',
      flexGrow: 2,
      width: 200,
      align: 'right',

      cellRenderer: explorerDecimalNumberRenderer,
    },
    {
      label: '% of Portfolio',
      dataKey: 'portfolioPercentage',
      flexGrow: 2,
      width: 200,
      align: 'right',

      cellRenderer: explorerPercentageRenderer,
    },
  ];
}
