import React, { PureComponent } from 'react';

import { Widget } from '../WidgetsBlock/Widget';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { Select } from '../../../../components/UIWidgets/Select';

import { IAllocationDict } from '../../../../models/IAllocationDict';
import { AllocationsList } from '../AllocationsList';
import { IAllocationListProps } from '../AllocationsList/AllocationsList';
import {
  EQUITY_EXPOSURE_TYPES,
  EXPOSURE_TYPES,
  EXPOSURE_TYPES_WITH_SHORTS,
  GEOGRAPHY_ALLOCATION_TYPES,
  GEOGRAPHY_TYPES,
} from '../../../../services/constants/constants';
import { IOptionType } from '../../../../components/UIWidgets/Select/Select';

import s from './StructureWidget.module.scss';
import { usePortfolioInfo } from '../../../../services/hooks/usePortfolioInfo';

interface IOwnProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
  allocations: IAllocationDict;
  containsShorts: boolean;
  onChangeAllocateBy: (event: any) => void;
  currentAllocateByValue: EQUITY_EXPOSURE_TYPES;
  onChangeAllocateGeographyBy: (event: any) => void;
  currentAllocateGeographyByValue: GEOGRAPHY_ALLOCATION_TYPES;
}

type IProps = Partial<Pick<IAllocationListProps, 'inRow'>> &
  IOwnProps &
  Omit<IWidgetProps, 'renderHeaderTools' | 'isDataLoaded'>;

const StructureWidgetContainer = ({
  colNum,
  title,
  allocations,
  containsShorts,
  titleInfo,
  inRow = 4,
  full = true,
  onChangeAllocateGeographyBy,
  portfolioCurrencyFormatter,
  onChangeAllocateBy,
  currentAllocateByValue,
  currentAllocateGeographyByValue,
}: IProps) => {
  const exposureTypes: Array<IOptionType<EQUITY_EXPOSURE_TYPES>> = containsShorts
    ? EXPOSURE_TYPES_WITH_SHORTS
    : EXPOSURE_TYPES;
  const selectedValue =
    exposureTypes.filter((exposureType) => exposureType.value === currentAllocateByValue)[0] ?? exposureTypes[0];

  const geographyTypes = GEOGRAPHY_TYPES;

  const geographySelectedValue =
    geographyTypes.filter((geographyType) => geographyType.value === currentAllocateGeographyByValue)[0] ??
    geographyTypes[0];

  return (
    <Widget title={title} colNum={colNum} full={full} titleInfo={titleInfo}>
      <div className={s.selectorsContainer}>
        <div className={s.selectWidget} />
        <div className={s.selectWidget}>
          <div className={s.selectLabel}>Allocate Geography by:</div>
          <Select
            className={s.dropdownBox}
            options={geographyTypes}
            size={'default'}
            onChange={onChangeAllocateGeographyBy}
            selectedValue={geographySelectedValue}
          />
        </div>
        <div className={s.selectWidget}>
          <div className={s.selectLabel}>Show by:</div>
          <Select
            className={s.dropdownBox}
            options={exposureTypes}
            size={'default'}
            onChange={onChangeAllocateBy}
            selectedValue={selectedValue}
          />
        </div>
      </div>
      <AllocationsList allocations={allocations} inRow={5} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
    </Widget>
  );
};

export default StructureWidgetContainer;
