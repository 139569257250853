import React, { Fragment, PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import s from './ReturnVsRiskScatterChart.module.css';

import { IPortfolioVolatilityData } from '../../interfaces';

import { generateChartOptions } from './ReturnVsRiskScatterChartConfig';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';
import { ASSET_COLORS, ASSET_LABEL_NAMES } from '../../../../../../services/constants/assetClasses';

interface IOwnProps {
  data: IPortfolioVolatilityData;
  chartTitle: string;
  chartPeriodText: string;
  helpText?: string;
  chartVariant?: string;
}

type IProps = IOwnProps;

class ReturnVsRiskScatterChartOld extends PureComponent<IProps> {
  render() {
    const { chartTitle, helpText, chartPeriodText } = this.props;

    const positionTypes: string[] = [];

    this.props.data.portfolio.assets.map((el) => positionTypes.push(el.assetClass));

    const assetClasses = positionTypes.filter((value, index) => positionTypes.indexOf(value) === index);

    const assetsLegend = assetClasses.map((el, idx) => {
      const assetColor = ASSET_COLORS[el];

      return (
        <div key={idx} className={s.legendItem}>
          <div className={s.longPositionSymbol} style={{ backgroundColor: assetColor }} />
          <div className={s.labelItem}>{ASSET_LABEL_NAMES[el]}</div>
        </div>
      );
    });

    return (
      <Fragment>
        <div>
          <h3 className={s.chartTitle}>
            {chartTitle}
            <ContextHelpIcon title={chartTitle} elementId={chartTitle} />
          </h3>
          <span className={s.chartPeriodText}>{chartPeriodText}</span>
        </div>

        <div className={s.chartLegendContainer}>
          <div className={s.legendItem}>
            <div className={s.portfolioSymbol} />
            <div className={s.labelItem}>Portfolio</div>
          </div>
          <div className={s.legendItem}>
            <div className={s.benchmarkSymbol} />
            <div className={s.labelItem}>{this.props.data.benchmark.indexName.split('.')[0]}</div>
          </div>
          {assetsLegend}
          {/*<div className={s.legendItem}>
            <div className={s.longPositionSymbol} />
            <div className={s.labelItem}>Return (Long)</div>
          </div>
          <div className={s.legendItem}>
            <div className={s.longPositionSymbol} />
            <div className={s.labelItem}>Return (Short)</div>
          </div>*/}
        </div>

        <ReactEcharts
          style={{ width: '100%', height: '450px', marginLeft: '-60px' }}
          option={generateChartOptions(this.props.data)}
          notMerge={true}
        />
        <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={helpText} />
      </Fragment>
    );
  }
}

export default ReturnVsRiskScatterChartOld;
