import React, { Fragment, PureComponent } from 'react';

import {
  PerformanceChartWidgetContainer,
  ProfitAttributionWidgetContainer,
  Widget,
  WidgetsBlock,
  PastNextChart,
  TopListWidgetsContainer,
  InfoBlock,
  AllocationsWidgetContainer,
} from '../../../../../../components';

import { PortfolioHeader } from '../../../../../../components/PortfolioHeader';
import { TOP_LIST_ORDER } from '../../../../../../../../services/constants/constants';

import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';

import calc from '../../../../../../../../static/images/dashboard/calc.svg';
import past from '../../../../../../../../static/images/dashboard/past.svg';

import s from '../../../PortfolioDashboard/Dashboard.module.scss';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';

const orderOptions: Array<IOptionType<TOP_LIST_ORDER>> = [
  { value: TOP_LIST_ORDER.SECURITY, label: 'By security' },
  { value: TOP_LIST_ORDER.SUBCLASS, label: 'By subclass' },
];

interface IProps {
  distributions: IBarData[];
  dashboardInfo: IDashboardAssetClass;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class Alternatives extends PureComponent<IProps> {
  render() {
    const { distributions, dashboardInfo, portfolioCurrencyFormatter } = this.props;

    const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
    const prev = portfolioCurrencyFormatter(dashboardInfo.past.value);
    const next = portfolioCurrencyFormatter(dashboardInfo.next.value);

    const prevDiff = dashboardInfo.past.diff * 100;
    const nextDiff = dashboardInfo.next.diff * 100;

    return (
      <Fragment>
        <PortfolioHeader title="Alternatives" />
        <WidgetsBlock className={s.assetsDashboardGrid}>
          <Widget title="" full={true}>
            <div className={s.dashboardInfo}>
              <div className={s.dashboardInfoItem}>
                <InfoBlock image={calc} title="Total Alternatives value" value={total} />
              </div>
              <div className={s.dashboardInfoItem}>
                <InfoBlock image={past} title="Distributions, past 12 months" value={prev} diff={prevDiff} />
              </div>
              <div className={s.dashboardInfoItem}>
                <InfoBlock image={past} title="Distributions, next 12 months" value={next} diff={nextDiff} />
              </div>
            </div>
          </Widget>

          <PerformanceChartWidgetContainer />
          <ProfitAttributionWidgetContainer />

          <TopListWidgetsContainer orderList={orderOptions} />
          <Widget title="Distributions" colNum={8}>
            <PastNextChart chartData={distributions} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
          </Widget>

          <AllocationsWidgetContainer title="Alternatives Breakdown" />
        </WidgetsBlock>
      </Fragment>
    );
  }
}

export default Alternatives;
