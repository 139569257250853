import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  label?: 'Quantity' | 'Nominal';
  quantityDecimals?: number;
}

const Quantity: FC<IProps> = ({ label = 'Quantity', quantityDecimals = 3 }) => {
  return (
    <FormFieldWrapper label={label} required={true} className={cn(s.minField)}>
      <Field
        name="quantity"
        placeholder="0"
        type="string"
        className="input--small"
        theme="inverse"
        component={renderInput}
        disabled={false}
        normalize={(value: number) => positiveNumber(floatNumberV2(value, quantityDecimals)).toString()}
      />
    </FormFieldWrapper>
  );
};

export default Quantity;
