import React from 'react';
import { MdOpenInNew } from 'react-icons/all';

export const ExpandButton = React.memo(({ onClick }: { onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        right: 5,
        borderRadius: 5,
        height: 20,
        width: 20,
        background: 'var(--primary-color)',
        textAlign: 'center',
      }}
    >
      <MdOpenInNew style={{ fontSize: 18, padding: 2, paddingBottom: 0, margin: 'auto' }} />
    </div>
  );
});
