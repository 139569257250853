import { getApiUrl } from '../../constants/endpoints';
import moment from 'moment';
import useApi from '../useApi';
import { usePortfolio } from '../usePortfolio';
import { usePeriod } from '../../context/PeriodContext';

export const usePortfolioTrades = (period?: IPeriod, loadingDisabled?: boolean) => {
  const url = getApiUrl('snapshot.trades.all');
  const portfolio = usePortfolio();
  const portfolioId = portfolio.portfolioInfo?.data?.id;

  const { period: globalPeriod } = usePeriod();
  const calculatedPeriod = period ?? globalPeriod;

  const order = { name: 'name', direction: 'ASC' };
  const page = 0;
  const size = 1000;
  const fromDate = calculatedPeriod.from ? moment(calculatedPeriod.from).format('YYYY-MM-DD') : '';
  const toDate = calculatedPeriod.to ? moment(calculatedPeriod.to).format('YYYY-MM-DD') : '';

  return useApi<{ content: IPortfolioSnapshot[] }>(
    url,
    {
      method: 'POST',
      data: {
        portfolioId,
        order,
        page,
        confirmed: true,
        size,
        fromDate,
        toDate,
      },
    },
    {
      enabled: !!portfolioId && !loadingDisabled,
      refetchOnMount: false,
    }
  )

};
