import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import memoizeOne from 'memoize-one';
import moment, { Moment } from 'moment';

import { LineData } from '../../../../models/LineData';
import { PERIOD_TYPE } from '../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../services/utils/FormatHelper';

const LABEL_WIDTH: number = 30;

enum AxisLabelType {
  DAY,
  MONTH,
  QUARTER,
}

// fixme: put all methods from file in class to avoid global definitions
const labelsMap: Map<number, { value: string; index: number }> = new Map<number, { value: string; index: number }>();

const filterAxisLabels = memoizeOne(
  (data: LineData, labelType: AxisLabelType): LineData => {
    switch (labelType) {
      case AxisLabelType.MONTH:
        return data.filter((item) => moment(item[0]).date() === 1);
      case AxisLabelType.QUARTER:
        return data.filter((item) => {
          const date: Moment = moment(item[0]);
          return date.month() % 3 === 0 && date.date() === 1;
        });
      default:
        return data;
    }
  }
);

const prepareXAisLabels = memoizeOne(
  (data: LineData, period: IPeriod): Map<number, { value: string; index: number }> => {
    labelsMap.clear();
    const labelType: AxisLabelType = getLabelAxisType(data, period);
    filterAxisLabels(data, labelType).forEach((item) =>
      labelsMap.set(moment(item[0]).startOf('day').valueOf(), {
        value: getAxisLabel(item[0], labelType),
        index: labelsMap.size + 1,
      })
    );
    return labelsMap;
  }
);

export function getPerformanceChartOptions(
  portfolio: LineData,
  index: LineData,
  marketValueBase: LineData,
  netInvestments: LineData,
  period: IPeriod,
  chartWidth: number,
  portfolioCurrencyFormatter: ICurrencyFormatter,
  indexName: string,
  performanceToggle?: number,
  pnl?: LineData
): echarts.EChartOption {
  prepareXAisLabels(portfolio, period);

  const prepSeries = [];

  if (performanceToggle === 1) {
    prepSeries.push(
      {
        ...ChartHelper.getLineSeriesOptions('rgba(128, 255, 165)'),
        name: 'Market Value',
        data: portfolio,
        z: 2,
        symbolSize: 5,
        type: 'line',
        showSymbol: false,
        animation: true,
        animationDuration: 100,
        // smooth: true,
        lineStyle: {
          width: 0,
        },
        emphasis: {
          focus: 'series',
        },
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(128, 255, 165)',
            },
            {
              offset: 1,
              color: 'rgba(1, 191, 236)',
            },
          ]),
        },
      },

      {
        ...ChartHelper.getLineSeriesOptions('rgba(255, 191, 0)'),
        name: 'Net Investments',
        symbolSize: 5,
        type: 'line',
        showSymbol: false,
        animation: true,
        animationDuration: 100,
        // smooth: true,
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 191, 0)',
            },
            {
              offset: 1,
              color: 'rgba(224, 62, 76)',
            },
          ]),
        },
        data: netInvestments,
        z: 1,
      },
      {
        ...ChartHelper.getLineSeriesOptions('transparent'),
        name: 'P&L',
        data: pnl,
        z: 2,
        symbolSize: 5,
        type: 'line',
        showSymbol: false,
        animation: true,
        animationDuration: 100,
        // smooth: true,
        lineStyle: {
          width: 0,
          color: 'transaprent',
        },
        emphasis: {
          focus: 'series',
        },
        // areaStyle: {
        //   opacity: 0.5,
        //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //     {
        //       offset: 0,
        //       color: 'rgba(128, 255, 165)',
        //     },
        //     {
        //       offset: 1,
        //       color: 'rgba(1, 191, 236)',
        //     },
        //   ]),
        // },
      }
      /*{
        ...ChartHelper.getLineSeriesOptions('#a9b2d1'),
        name: 'Value',
        data: marketValueBase,
        z: 1,
      }*/
    );
  } else {
    prepSeries.push(
      {
        ...ChartHelper.getLineSeriesOptions('#6677cc'),
        name: 'Portfolio',
        data: portfolio,
        z: 2,
      },
      {
        ...ChartHelper.getLineSeriesOptions('#c2b261'),
        name: indexName,
        data: index,
        z: 1,
      }
    );
  }

  const chartOpt = ChartHelper.getTimelineChartOptions({
    tooltipValueType: performanceToggle === 1 ? VALUE_TYPE.MONEY : VALUE_TYPE.PERCENTAGE,
    zoom: true,
    portfolioCurrencyFormatter,
  });

  const mergedOpt: any = {
    grid: {
      y2: 40,
      x2: performanceToggle === 1 ? 82 : 55,
      containLabel: false,
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return performanceToggle === 1 ? portfolioCurrencyFormatter(value) : FormatHelper.formatPercentage(value);
        },
      },
    },
    xAxis: {
      interval: 3600 * 1000 * 24, // 1 day
      axisTick: {
        show: false,
      },
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
      },
    },
    series: prepSeries,
    dataZoom: [
      {
        type: 'inside',
        filterMode: 'none',
      },
    ],
  };

  return merge({}, chartOpt, mergedOpt, getChangedChartOptions(portfolio, period, chartWidth));
}

export function getChangedChartOptions(dataInView: LineData, period: IPeriod, chartWidth: number): echarts.EChartOption {
  const labelType: AxisLabelType = getLabelAxisType(dataInView, period);
  const filteredLabels = filterAxisLabels(dataInView, labelType);
  const canBeShown: number = chartWidth / LABEL_WIDTH;
  let showEveryIndex: number = Math.ceil(FormatHelper.round(filteredLabels.length / canBeShown, 10));

  if (!showEveryIndex) {
    showEveryIndex = 1;
  }

  return {
    xAxis: {
      axisLabel: {
        // rotate: 90,
        fontSize: 10,
        formatter(value: number): any {
          const formattedVal = moment(value);

          const from = moment(period.from ? period.from.toString() : '');
          const to = moment(period.to ? period.to.toString() : '');

          // return (format);

          if (to.diff(from, 'days') < 15) {
            return formattedVal.format(`DD MMM'YY`);
          }

          // if (to.diff(from, 'days') < 15) {
          //   return formattedVal.isBusinessDay() ? formattedVal.format("DD MMM'YY") : "";
          // }

          // return `${to.diff(from, 'days')}`

          if (to.diff(from, 'days') < 60) {
            return formattedVal.day() === 5 ? formattedVal.format("DD MMM'YY") : '';
          }

          if (
            period.type === PERIOD_TYPE.YEAR ||
            period.type === PERIOD_TYPE.YTD ||
            period.type === PERIOD_TYPE.CALENDAR_YEAR ||
            period.type === PERIOD_TYPE.CALENDAR_QUARTER ||
            (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'months') < 13 && to.diff(from, 'months') >= 1)
          ) {
            return formattedVal.format('DD') === '01' ? formattedVal.format("MMM'YY") : '';
          }

          if (
            period.type === PERIOD_TYPE.INCEPTION ||
            (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'months') > 12)
          ) {
            if (formattedVal.format('DD') !== '01') {
              return '';
            }
            switch (formattedVal.format('M')) {
              case '1':
                return 'Q' + formattedVal.format("Q'YY");
              case '4':
                return 'Q' + formattedVal.format("Q'YY");
              case '7':
                return 'Q' + formattedVal.format("Q'YY");
              case '10':
                return 'Q' + formattedVal.format("Q'YY");
              default:
                return '';
            }
          }
        },
      },
    },
    dataZoom: [
      {
        minValueSpan: getZoomInterval(period) * showEveryIndex,
      },
    ],
  };
}

function getLabelAxisType(dataInView: LineData, period: IPeriod): AxisLabelType {
  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return AxisLabelType.MONTH;
    case PERIOD_TYPE.INCEPTION:
      return AxisLabelType.QUARTER;
    case PERIOD_TYPE.MANUAL:
      const firstDate = period.from ? period.from : undefined;
      const lastDate = period.to ? period.to : undefined;

      const diffMonths = moment(lastDate).diff(firstDate, 'months');
      if (!diffMonths) {
        return AxisLabelType.DAY;
      }
      if (diffMonths >= 1 && diffMonths <= 12) {
        return AxisLabelType.MONTH;
      }
      return AxisLabelType.QUARTER;
    default:
      return AxisLabelType.DAY;
  }
}

function getAxisLabel(value: string | number, labelType: AxisLabelType): string {
  const date: Moment = moment(value);
  switch (labelType) {
    case AxisLabelType.MONTH:
      return date.format("MMM'YY");
    case AxisLabelType.QUARTER:
      return `Q${date.quarter()}'${date.format('YY')}`;
    default:
      return date.format("DD MMM'YY");
  }
}

function getZoomInterval(period: IPeriod): number {
  const day: number = 3600 * 1000 * 24;
  const week: number = day * 7;
  const month: number = day * 31;
  const quarter: number = day * 92;

  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return month;
    case PERIOD_TYPE.INCEPTION:
      return quarter;
    case PERIOD_TYPE.MANUAL:
      if (!period.to || !period.from) {
        return week;
      }

      const diffMonths: number = moment(period.to).diff(period.from, 'months');
      const diffYears: number = moment(period.to).diff(period.from, 'years');
      if (!diffMonths) {
        return week;
      }
      if (diffYears <= 1) {
        return month;
      }
      return quarter;
    default:
      return week;
  }
}
