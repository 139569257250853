import React, { PureComponent, ReactNode } from 'react';

import { MaturityChart } from './index';
import { Widget } from '../../../../../../../../components/WidgetsBlock/Widget';
import { Period } from '../../../../../../../../../../components/Period';

import { IWidgetProps } from '../../../../../../../../components/WidgetsBlock/Widget/Widget';

import { IOptionType } from '../../../../../../../../../../components/UIWidgets/Select/Select';
import { LineData } from '../../../../../../../../../../models/LineData';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { PERIOD_TYPE } from '../../../../../../../../../../services/constants/constants';

interface IOwnProps {
  maturityData: LineData;
  selectedPeriod: IPeriod;
  onPeriodChange: (period: IPeriod) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

type IProps = IOwnProps & Exclude<IWidgetProps, 'type | renderHeaderTools'>;

const periodOptions: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.YEAR, label: '1Y' },
  { value: PERIOD_TYPE.THREE_YEARS, label: '3Y' },
  { value: PERIOD_TYPE.INCEPTION, label: 'Since inception' },
];

class MaturityChartWidget extends PureComponent<IProps> {
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {}

  renderPeriod = (): ReactNode => {
    const { selectedPeriod, onPeriodChange } = this.props;
    return (
      <Period options={periodOptions} viewList="tabs" selectedPeriod={selectedPeriod} onPeriodChange={onPeriodChange} />
    );
  };

  render() {
    const { maturityData, colNum, portfolioCurrencyFormatter } = this.props;
    return (
      <Widget title="Yield to maturity" colNum={colNum} renderHeaderTools={this.renderPeriod}>
        <MaturityChart chartData={maturityData} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
      </Widget>
    );
  }
}

export default MaturityChartWidget;
