import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment from 'moment';

import { DEFAULT_TOOLTIP_OPTIONS } from '../../../../services/constants/charts';
import { ChartHelper } from '../../../../services/utils/ChartHelper';
import { ColorsHelper } from '../../../../services/utils/ColorsHelper';
import { FormatHelper } from '../../../../services/utils/FormatHelper';

type FormatterParam = echarts.EChartOption.Tooltip.Format;

export function getChartOptions(chartData: IBarData[]): echarts.EChartOption {
  const chartOpt = ChartHelper.getChartOptions();
  if (!chartData.length) {
    return chartOpt;
  }

  const ytmData = prepareSeries(chartData[0], '#6677cc', 2);
  const ytcData = prepareSeries(chartData[1], '#c2b261', 1);

  const categories = chartData[0].data.map((value: any) => value.date);
  const mergeOpt: echarts.EChartOption = {
    xAxis: {
      data: categories,
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
        interval: 0,
        formatter(value: string): any {
          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const momentVal = moment(value);
            return momentVal.format("DD MMM'YY");
          }
          return value;
        },
      },
    },
    yAxis: {
      axisLabel: {
        showMaxLabel: true,
        interval: 0,
        formatter(value: string): any {
          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const momentVal = moment(value);
            return momentVal.format("DD MMM'YY");
          }
          return `${value}%`;
        },
      },
    },
    series: [ytmData, ytcData],
    tooltip: {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (params: FormatterParam | FormatterParam[]): string => {
        let tooltipText = '';

        const textGen = (item: FormatterParam, instruments: any = []) => {
          const value = Array.isArray(item.value) ? item.value[1] : item.value;
          const valueText = FormatHelper.fixPercentage(value);

          const serieMarker: string = ChartHelper.getTooltipLegend(item);
          const serieName = item.seriesName;

          const instrumentsText: any = [];

          instruments.map((i: string) => {
            const marker = `<span style="display:inline-block;margin-left:2px;margin-right:5px;border-radius:6px;width:6px;height:6px;background-color:#a9b2d1;"></span>`;
            instrumentsText.push(`${marker}${i}`);
          });

          tooltipText += `${serieMarker}${serieName}: ${valueText}<br/>${instrumentsText.join('<br>')}<br/><br/>`;
        };

        if (Array.isArray(params)) {
          const instruments: any[] = [];
          chartData.map((el) => {
            instruments.push(el.data[params[0].dataIndex!]);
          });

          const filteredInstr: any[] = [];

          instruments.map((ee) => filteredInstr.push(ee.details));

          params.forEach((item: FormatterParam, idx) => textGen(item, filteredInstr[idx]));
        } else {
          textGen(params);
        }
        return tooltipText;
      },
    },
  };
  return merge({}, chartOpt, mergeOpt);
}

function prepareSeries(data: IBarData, barColor: string, zindex?: number) {
  return {
    type: 'line',
    barMaxWidth: 24,
    name: data.name,
    z: zindex ? zindex : 0,
    itemStyle: {
      color: barColor,
    },
    data: data.data.map((value: any) => value.value),
  };
}
