import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { connect } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import { getAssetOptions } from '../../../../../../../../services/selectors/snapshots';
import FXPair, { round } from '../../components/ConnectedTransactionInputs/FXPair';
import { FormUpdater, IFXFormValues } from './types';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { sendRequest } from '../../../../../../../../services/hooks/useApi';
import TradeCost from '../../components/ConnectedTransactionInputs/TradeCost';
import Commission from '../../components/ConnectedTransactionInputs/Commission';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: IFXFormValues;
  instrumentOptions: IOption<IAsset>[];
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
}

type Props = IAssetEntryComponentProps<IFXFormValues> &
  InjectedFormProps<IFXFormValues> &
  IMapStateToProps &
  IDispatchToProps;

const FXEntry: FC<Props> = ({
  editMode,
  custodianOptions,
  formValues,
  resetForm,
  resetEditTrade,
  removeTrade,
  initialValues,
  fetchFxRate,
  portfolioInfo,
  updateFormValue,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  updateTempTrade,
}) => {
  const { buyCurrency, sellCurrency, tradeTime } = formValues;

  React.useEffect(() => {
    return () => resetForm(null);
  }, []);

  React.useEffect(() => {
    resetForm(initialValues);
  }, [initialValues?.tradeType, initialValues?.key, initialValues?.buyCurrency]);

  const isFormInvalid = React.useCallback(() => {
    return (
      !formValues.buyCurrency?.value ||
      !formValues.sellCurrency?.value ||
      isValueInvalid(formValues.buyQuantity) ||
      isValueInvalid(formValues.sellQuantity)
    );
  }, [formValues.buyCurrency, formValues.sellCurrency, formValues.buyQuantity, formValues.sellQuantity])();

  const getTrade = (): IPortfolioTrade => {
    const sourceId = {
      sourceId: `${buyCurrency.value}SPOT`,
      sourceData: 'SystemInstrumentService',
    };
    return {
      quantity: parseFloat(String(formValues.buyQuantity)),
      fxRate: parseFloat(String(formValues.fromToFxRate)),
      price: parseFloat(String(formValues.fromToFxRate)),
      settlementOption: 'LOCAL',
      tradeType: formValues.tradeType,
      instrument: {
        ...(editMode ? sourceId : { sourceId }),
      },
      currency: buyCurrency.value,
      tradeTime: formValues.tradeTime,
      operation: 'BUY', // All FX trades should be submitted as buy
      custodian: formValues.custodian,
      // custodian:
      //   typeof formValues.custodian === 'string'
      //     ? formValues.custodian
      //     : formValues.custodian?.value || (undefined as any),
      notes: formValues.notes,
      otherSourceId: {
        sourceId: `${sellCurrency.value}SPOT`,
        sourceData: 'SystemInstrumentService',
      },
      otherQuantity: parseFloat(String(formValues.sellQuantity)),
      tradeCosts: formValues.tradeCosts ?? 0,
      commission: formValues.commission ?? 0,
      key: parseFloat(formValues.key ?? '0') as any,
    };
  };

  const onTradeTimeChange = (time: string) => {
    if (initialValues.checkedOut) {
      return;
    }
    const buy = buyCurrency?.value;
    const sell = sellCurrency?.value;
    getFxRate(buy, sell, time);
  };

  const updateTrade = (e: any) => {
    e.preventDefault?.();

    const trade = getTrade();

    updateTempTrade!(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetEditTrade();
    });
  };

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const trade = getTrade();

    saveTrades(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetForm(initialValues);
    });
  };

  function getFxRate(buy?: string, sell?: string, inputTradeTime?: string) {
    const time = inputTradeTime || tradeTime;
    if (!(buy && sell && time)) {
      return Promise.resolve(null);
    }
    return sendRequest(`/api/v1/fx-rate?from=${buy}&to=${sell}&date=${time}`).then((result) => {
      const buyQuantity = parseFloat(String(formValues.buyQuantity ?? '0'));
      const sellQuantity = buyQuantity * result.fromToFxRate;
      updateFormValue('sellQuantity', round(sellQuantity));
      updateFormValue('fromToFxRate', round(result.fromToFxRate));
      updateFormValue('toFromFxRate', round(1 / result.fromToFxRate));
      return result.fromToFxRate as any;
    });
  }

  return (
    <form className={s.formGrid} onSubmit={editMode ? updateTrade : saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime className={s.fxWrapper} onChange={onTradeTimeChange} maxDaysAhead={0} />
        <FXPair editMode={!!editMode} formValues={formValues} updateFormValue={updateFormValue} getFxRate={getFxRate} />
      </div>
      <div className={s.formRow}>
        <Commission currencyValue={portfolioInfo?.currency.name} />
        <TradeCost currencyCode={portfolioInfo?.currency.name} />
        <Custodian className={s.custodianSmall} />
        <AddCustodianButton />
        <FormFieldWrapper label="Notes" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>

      <div className={s.formFooter}>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  formValues: state.form[FORMS_NAME.fx].values as IFXFormValues,
  instrumentOptions: getAssetOptions(state),
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(FORMS_NAME.fx, field, data));
  },
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

export default reduxForm<IFXFormValues, IAssetEntryComponentProps<IFXFormValues>>({
  form: FORMS_NAME.fx,
  validate,
  destroyOnUnmount: false,
})(connect(mapStateToProps, mapDispatchToProps)(FXEntry));
