import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';
import { RouteComponentProps, withRouter } from 'react-router';
import get from 'lodash.get';

import InstrumentsList from './InstrumentsList';

import { InstrumentsActions } from '../../../../services/actions';

import { IRootState } from '../../../../services/store';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import { Utils } from '../../../../services/utils/Utils';

import { AppActions } from '../../../../services/actions/AppActions';

import { getUserOptions } from '../../../../services/selectors/instrument';
import { IOption } from '../../../../components/UIWidgets/Autocomplete';
import {
  getAssetClassOptions,
  getAssetSubClassOptions,
  getNestedSetFromAssetClasses,
} from '../../../../services/selectors/asset';

import { getCurrencyOptions } from '../../../../services/selectors/currency';
import { getCountryOptions } from '../../../../services/selectors/country';

import { NestedSet } from '../../../../components/NestedList/NestedSet';

import { getIndustryOptions } from '../../../../services/selectors/industry';
import { NotificationType } from '../../../../models/NotifictionType';

interface IMapStateToProps {
  assetClasses: IAssetClass[];
  instrumentsList: IInstrument[];
  instrumentsSort: IOrder;
  instrumentsFilters: IInstrumentsFilters;
  authInfo: IAuthInfo | null;
  userOptions: Array<IOption<IUser>>;
  assetSubClassOptions: Array<IOption<IAssetSubClass>>;
  assetClassOptions: Array<IOption<IAssetClass>>;
  currencyOptions: Array<IOption<ICurrency>>;
  countryOptions: Array<IOption<ICountry>>;
  industryOptions: Array<IOption<IIndustry>>;
  nestedSetFromAssetClasses: NestedSet<any>;
}

interface IDispatchToProps {
  changeInstrumentsSort: (order: IOrder) => void;
  changeInstrumentsFilters: (filters: IInstrumentsFilters) => void;
  deleteInstrument: (instrument: IInstrument) => AxiosPromise<void>;
  fetchInstrumentsList: (sort: IOrder, filters: IInstrumentsFilters, page?: number) => void;
  showNotification: (notification: INotification) => void;
  resetInstrumentsList: () => void;
  fetchUsers: (search: string) => AxiosPromise<IUser[]>;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps;

class InstrumentsListContainer extends PureComponent<IProps> {
  componentDidMount(): void {
    this._fetchData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { instrumentsSort, instrumentsFilters, fetchInstrumentsList } = this.props;
    if (prevProps.instrumentsSort !== instrumentsSort || prevProps.instrumentsFilters !== instrumentsFilters) {
      fetchInstrumentsList(instrumentsSort, instrumentsFilters, 0);
    }
  }

  componentWillUnmount(): void {
    this.props.resetInstrumentsList();
  }

  handleRemoveInstrument = (instrument: IInstrument) => {
    const { deleteInstrument, showNotification } = this.props;
    Utils.createConfirm({
      text: `Do you really want to remove <b>${instrument.name}</b>? This action cannot be undone.`,
      confirmBtnText: 'Remove',
      onConfirm: () => {
        deleteInstrument(instrument)
          .then(() => {
            this._fetchData();
          })
          .catch((err) => {
            const errorMsg = get(err, 'response.data.error.data', "Instrument hasn't been deleted");

            showNotification({
              text: errorMsg,
              type: NotificationType.ERROR,
            });
          });
      },
    });
  };

  setUserOption(user: IOption<IUser> | null) {
    this.setState({ currentUser: user }, () => {
      this._fetchData();
    });
  }

  render() {
    const {
      assetClasses,
      instrumentsList,
      instrumentsSort,
      instrumentsFilters,
      changeInstrumentsSort,
      changeInstrumentsFilters,
      userOptions,
      fetchUsers,
      assetClassOptions,
      assetSubClassOptions,
      currencyOptions,
      authInfo,
      countryOptions,
      nestedSetFromAssetClasses,
      industryOptions,
    } = this.props;

    const { setUserOption, handleRemoveInstrument } = this;

    if (!authInfo) {
      return null;
    }

    return (
      <InstrumentsList
        assetClasses={assetClasses}
        instrumentsList={instrumentsList}
        instrumentsSort={instrumentsSort}
        instrumentsFilters={instrumentsFilters}
        onFiltersChange={changeInstrumentsFilters}
        onSortChange={changeInstrumentsSort}
        onRemoveInstrument={handleRemoveInstrument}
        searchUser={(search) => fetchUsers(search)}
        setUserOption={setUserOption}
        userListOptions={userOptions}
        assetClassOptions={assetClassOptions}
        assetSubClassOptions={assetSubClassOptions}
        authInfo={authInfo as IAuthInfo}
        currencyOptions={currencyOptions}
        countryOptions={countryOptions}
        nestedSetFromAssetClasses={nestedSetFromAssetClasses}
        industryOptions={industryOptions}
      />
    );
  }

  private _fetchData() {
    const { instrumentsSort, instrumentsFilters, fetchInstrumentsList } = this.props;
    fetchInstrumentsList(instrumentsSort, instrumentsFilters);
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    assetClasses: state.assetsClasses.data,
    instrumentsList: state.instruments.list.content,
    instrumentsSort: state.instruments.list.order,
    instrumentsFilters: state.instruments.list.filters,
    authInfo: state.app.authInfo.data,
    userOptions: getUserOptions(state),
    assetSubClassOptions: getAssetSubClassOptions(state),
    assetClassOptions: getAssetClassOptions(state),
    currencyOptions: getCurrencyOptions(state),
    countryOptions: getCountryOptions(state),
    nestedSetFromAssetClasses: getNestedSetFromAssetClasses(state),
    industryOptions: getIndustryOptions(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  changeInstrumentsSort: (order: IOrder) => dispatch(InstrumentsActions.changeInstrumentsSort(order)),
  changeInstrumentsFilters: (filters: IInstrumentsFilters) =>
    dispatch(InstrumentsActions.changeInstrumentsFilters(filters)),
  deleteInstrument: (instrument: IInstrument) => dispatch(InstrumentsActions.deleteInstrument(instrument)),
  fetchInstrumentsList: (sort: IOrder, filters: IInstrumentsFilters, page?: number) =>
    dispatch(InstrumentsActions.fetchAllInstruments(sort, filters, page)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  resetInstrumentsList: () => dispatch(InstrumentsActions.resetInstrumentsList()),
  fetchUsers: (search: string) => dispatch(InstrumentsActions.fetchUsers(search)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstrumentsListContainer));
