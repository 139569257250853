import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { ReduxHelper } from '../utils/ReduxHelper';
import { AccountsActions } from '../actions/AccountsActions';

export interface IAccountsState extends ILoadingDataState {
  data: IAccountSummaries[];
}

const INITIAL_STATE: IAccountsState = ReduxHelper.createInitialState<IAccountSummaries[]>([]);

const accountsReducer = ReduxHelper.createHttpRequestReducer<IAccountsState>(
  INITIAL_STATE,
  AccountsActions.ACCOUNTS_REQUEST,
  AccountsActions.ACCOUNTS_SUCCESS,
  AccountsActions.ACCOUNTS_ERROR,
  AccountsActions.ACCOUNTS_RESET
);

export default accountsReducer;
