import React, { FunctionComponent } from 'react';
import { Button } from '../../UIWidgets/Button';

import s from '../Upload.module.scss';

const UploadSuccess: FunctionComponent<{ loadedCount: number; onReset: () => void }> = (props) => {
  return (
    <div className={s.contentWrapper}>
      <div className="text-md">{props.loadedCount} transactions added</div>
      <Button className="text-md text-blue" variant="empty" onClick={props.onReset}>
        Upload more
      </Button>
    </div>
  );
};

export default React.memo(UploadSuccess);
