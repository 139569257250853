import { IRealAsset } from '../../models/IRealAsset';

type RealAssetFormGroupFactory = (type: AssetClassId, settings: RealAssetFormSettings) => FormGroup[];

type RealAssetFormSettings = {
  mode: EditMode;
  assetSubClassesComboItems: IComboItem[];
  currencyComboItems: IComboItem[];
  countryComboItems: IComboItem[];
  selectedUniqueIdType?: string;
  setSelectedUniqueIdType(s?: string): void;
  realAssets: IRealAsset[] | null;
};

// TODO: Becoming convoluted... separate Real Asset and Real Estate groups into separate arrays, rather than calculating.
export const getFormGroups: RealAssetFormGroupFactory = (
  type,
  {
    mode,
    assetSubClassesComboItems,
    currencyComboItems,
    countryComboItems,
    selectedUniqueIdType,
    setSelectedUniqueIdType,
    realAssets,
  }
) => {
  let typeName = '';

  let identificationFields: FormGroup['items'] = [];
  if (type === 'RealEstate') {
    typeName = 'Property';
    identificationFields = [
      {
        itemType: 'combo',
        name: 'uniqueIdType',
        label: 'Property Sector',
        placeholder: 'Select',
        onSelect: (i) => setSelectedUniqueIdType(typeof i?.value === 'string' ? i?.value : undefined),
        comboItems: [
          { label: 'Commercial Office', value: 'CommercialOffice' },
          { label: 'Hospitality', value: 'Hospitality' },
          { label: 'Hotel', value: 'Hotel' },
          { label: 'Industrial', value: 'Industrial' },
          { label: 'Residential', value: 'Residential' },
          { label: 'Retail', value: 'Retail' },
          { label: 'Agricultural', value: 'Agricultural' },
          { label: 'Other', value: 'Other' },
        ],
        required: false,
        cols: 3,
      },
      {
        itemType: 'combo',
        name: 'country',
        label: 'Country',
        placeholder: 'Enter value',
        cols: 3,
        after: 1,
        comboItems: countryComboItems,
        required: true,
      },
      {
        itemType: 'text',
        name: 'uniqueId',
        label: 'Address',
        placeholder: 'Enter value',
        required: false,
        cols: 11,
      },
    ];
  } else {
    typeName = 'Asset';
    identificationFields = [
      {
        itemType: 'combo',
        name: 'uniqueIdType',
        label: 'Unique ID Type',
        placeholder: 'Select',
        onSelect: (i) => setSelectedUniqueIdType(typeof i?.value === 'string' ? i?.value : undefined),
        comboItems: [
          { label: 'Serial Number', value: 'SerialNumber' },
          { label: 'Vehicle Identification Number', value: 'VIN' },
          { label: 'Hull Identification Number', value: 'HIN' },
          { label: 'Other', value: 'Other' },
        ],
        required: false,
        cols: 3,
      },
      {
        itemType: 'text',
        name: 'uniqueId',
        label: 'Unique ID',
        placeholder: 'Enter value',
        required: !!selectedUniqueIdType,
        cols: 3,
        after: 1,
      },
      {
        itemType: 'combo',
        name: 'country',
        label: 'Country',
        placeholder: 'Enter value',
        cols: 4,
        after: 7,
        comboItems: countryComboItems,
        required: true,
      },
    ];
  }

  const assetSubClassSelector: FormGroup['items'][0] = {
    itemType: 'combo',
    name: 'assetSubClass',
    label: `${typeName} Subclass`,
    placeholder: 'Enter value',
    cols: 6,
    comboItems: assetSubClassesComboItems,
    required: true,
  };

  const groups: FormGroup[] = [
    ...(type === 'RealEstate' ? [{ label: 'Asset Subclass', items: [assetSubClassSelector] }] : []),
    {
      label: type === 'RealEstate' ? 'DETAILS' : 'Asset DETAILS',
      items: [
        ...(type === 'RealAssets' ? [assetSubClassSelector] : []),
        {
          itemType: 'text',
          name: 'name',
          label: `${type === 'RealEstate' ? 'Display' : typeName} Name`,
          placeholder: 'Enter value',
          required: true,
          maxLength: 50,
          validate: (value: string) => {
            if (!/^[ a-zA-Z-\'_0-9]+$/.test(value)) {
              return false;
              //  return {error: {type: 'pattern', message: "Can't contain special characters."}};
            }
            return true;
          },
          cols: 6,
          after: type === 'RealEstate' ? 7 : 1,
        },
        {
          itemType: 'text',
          name: 'description',
          label: 'Description / Notes',
          placeholder: 'Enter value',
          required: false,
          cols: 11,
        },
      ],
    },
    {
      label: '',
      items: [
        {
          itemType: 'text',
          name: 'code',
          label: 'Short Code',
          placeholder: mode === 'edit' ? '(Fixed)' : 'Enter value',
          required: mode !== 'edit',
          cols: 3,
          readOnly: mode === 'edit',
          maxLength: 16,
          validate: (code: string = '') => {
            if (!/^[ a-zA-Z-\'_0-9]+$/.test(code)) {
              return false;
            }
            if (mode === 'edit') {
              return true;
            }
            if (!code) {
              return false;
            }
            const existingAssetCodes = realAssets?.length ? realAssets.map((element) => element.code) : undefined;
            const alreadyExists = (existingAssetCodes?.indexOf(code) ?? -1) > -1;
            return !alreadyExists;
          },
        },
        {
          itemType: 'combo',
          name: 'instrumentStatus',
          label: 'Status',
          placeholder: 'Select',
          comboItems: [{ value: 'ACTIVE' }, { value: 'INACTIVE' }, { value: 'DEPRECATED' }],
          required: true,
          cols: 3,
        },

        ...identificationFields,
      ],
    },

    {
      label: 'VALUATION DETAILS',
      items: [
        {
          itemType: 'combo',
          name: 'valuationCurrency',
          label: 'Currency',
          placeholder: 'Select',
          comboItems: currencyComboItems,
          cols: 3,
          required: true,
        },
      ],
    },
  ];

  return groups;
};
