import React from 'react';
import InstrumentsUpload from './InstrumentsUpload';

class InstrumentsUploadContainer extends React.Component {
  render() {
    return <InstrumentsUpload />;
  }
}

export default InstrumentsUploadContainer;
