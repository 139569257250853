import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Simulate } from 'react-dom/test-utils';
import { useFx } from '../../../../../../../../services/hooks/useFx';

interface IOwnProps {
  portfolioCurrency: string;
}

export const CryptoInvisibleFxCalc = ({ portfolioCurrency }: IOwnProps) => {
  const { watch, setValue, register } = useFormContext();

  const fx = useFx()({ fromCurrencyName: 'USD', toCurrencyName: portfolioCurrency });
  const { tradeTime } = watch();

  React.useEffect(() => {
    fx.getRate(tradeTime).then((result) => {
      if (!result) {
        return;
      }
      setValue('fxRate', result.firstFxRate);
    });
  }, [tradeTime, portfolioCurrency]);

  return <input type={'hidden'} name={'fxRate'} ref={register} />;
  return (
    <div>
      FX:
      {/*{String(tradeTime)}*/}
      {/*{fxy}*/}
    </div>
  );
};
