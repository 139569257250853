import React, { useEffect, useState } from 'react';
import { TopListWidgetsContainer, Widget } from '../../../../../../components';
import { OptionsExposureWidgetContainer } from '../../../../../../components/StructureWidget';
import s from '../../Dashboard.module.scss';
import { DASHBOARD_PORTFOLIO_STRUCTURE_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import { getTopPositionsColumns } from '../../topPositionsColumns';
import { Card } from '@iliotech/storybook';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import {
  ALLOCATION_TYPES,
  EQUITY_EXPOSURE_TYPES,
  GEOGRAPHY_ALLOCATION_TYPES,
} from '../../../../../../../../services/constants/constants';
import { DASHBOARD_CASH_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';

import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';
import { Loader, Select } from '../../../../../../../../components';
import { dashboardCellRenderer } from '../../utils/DashboardCellRenderer';
import { BreakdownChart } from './components/BreakdownChart';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
// import TopPositionsChart from '../../../AssetsDashboard/scenes/Cash/TopPositionsChart';
import { cashCellRenderer } from '../../../AssetsDashboard/scenes/Cash/utils/CashCellRenderer';

interface IProps {
  portfolioId: string;
  historyPeriod: IPeriod;
  selectedCurrency: IOptionType<any>;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCustodians: string[];
}

const Structure = ({
  portfolioId,
  historyPeriod,
  selectedCurrency,
  portfolioCurrencyFormatter,
  selectedCustodians,
}: IProps) => {
  const { preferences } = useCapabilities();
  const { portfolioInfo } = usePortfolio();
  const riskAssetView = portfolioInfo.data?.riskAssetView ?? preferences.defaultRiskAssetView;
  const [topListOrder, setTopListOrder] = useState<IOrder>({ direction: 'DESC', name: 'marketValueBase' });
  const [topCashOrder, setTopCashOrder] = useState<IOrder>({ direction: 'DESC', name: 'marketValueBase' });
  const [allocateBy, setAllocateBy] = useState<EQUITY_EXPOSURE_TYPES>(EQUITY_EXPOSURE_TYPES.MarketValueBase);
  const [allocateGeographyBy, setAllocateGeographyBy] = useState<GEOGRAPHY_ALLOCATION_TYPES>(
    GEOGRAPHY_ALLOCATION_TYPES.Region
  );
  const [selectedCashChartFilter, setSelectedCashChartFilter] = useState(0);

  const historyData = useApi(getApiUrl('reports.history'), {
    method: 'POST',
    data: {
      byRiskAssetClass: riskAssetView === 'RISK_ASSET_CLASS',
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(historyPeriod),
      custodians: selectedCustodians || [],
    },
  });

  const structure = useApi(getApiUrl('portfolio.allocations'), {
    method: 'POST',
    data: {
      portfolioId,
      withChildren: false,
      allocations: [
        riskAssetView === 'RISK_ASSET_CLASS' ? ALLOCATION_TYPES.RiskAssetClass : ALLOCATION_TYPES.AssetClass,
        allocateGeographyBy,
        ALLOCATION_TYPES.Currency,
        ALLOCATION_TYPES.Custodian,
        ALLOCATION_TYPES.Sectors,
        ALLOCATION_TYPES.Industry,
      ],
      valuationDate: PeriodHelper.preparePeriodForRequest(historyPeriod).to,
      custodians: selectedCustodians || [],
      allocateBy,
    },
  });

  const topList = useApi(getApiUrl('topPositions'), {
    method: 'POST',
    data: {
      portfolioId,
      filter: {
        type: 'AssetClass',
        filterType: 'EXCLUDE',
        ids: ['CashAndEquivalents'],
      },
      number: 10,
      order: topListOrder,
      custodians: selectedCustodians || [],
      valuationDate: historyPeriod ? PeriodHelper.preparePeriodForRequest(historyPeriod).to : undefined,
    },
  });

  const topCash = useApi(getApiUrl('topPositions'), {
    method: 'POST',
    data: {
      portfolioId,
      filter: {
        type: 'AssetClass',
        filterType: 'INCLUDE',
        id: 'CashAndEquivalents',
      },
      number: 10,
      order: topCashOrder,
      custodians: selectedCustodians || [],
      valuationDate: historyPeriod ? PeriodHelper.preparePeriodForRequest(historyPeriod).to : undefined,
    },
  });

  useEffect(() => {
    topList.refetch();
    topCash.refetch();
    structure.refetch();
    historyData.refetch();
  }, [historyPeriod, selectedCustodians]);

  useEffect(() => {
    topList.refetch();
  }, [topListOrder]);

  useEffect(() => {
    topCash.refetch();
  }, [topCashOrder]);

  if (historyData.isLoading || structure.isLoading) {
    return <Loader />;
  }

  const onChangeAllocateBy = (value: any) => {
    setAllocateBy(value);
    structure.refetch();
  };

  const onChangeAllocateGeographyBy = (value: any) => {
    setAllocateGeographyBy(value);
    structure.refetch();
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Widget>
        {/* <AllocationsList
                allocations={allocations}
                inRow={5}
                portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              />*/}
        <Card>
          <OptionsExposureWidgetContainer
            titleInfo={DASHBOARD_PORTFOLIO_STRUCTURE_HELP_TEXT.ChartPortfolioAllocation}
            title="Portfolio Allocation"
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            allocations={structure.data.allocations}
            containsShorts={structure.data.containsShorts}
            onChangeAllocateBy={onChangeAllocateBy}
            currentAllocateByValue={allocateBy}
            onChangeAllocateGeographyBy={onChangeAllocateGeographyBy}
            currentAllocateGeographyByValue={allocateGeographyBy}
          />
        </Card>
      </Widget>

      <Widget
        title="Top 10 Positions"
        className={s.contaienr}
        titleInfo={DASHBOARD_PORTFOLIO_STRUCTURE_HELP_TEXT.TableTop10Positions}
      >
        <TopListWidgetsContainer
          topListOrder={topListOrder}
          changeOrder={setTopListOrder}
          showCategories={true}
          topList={topList.data}
          columns={getTopPositionsColumns(portfolioCurrencyFormatter, riskAssetView)}
          cellRenderer={(data) => dashboardCellRenderer(data, portfolioCurrencyFormatter)}
          columnClass={s.tableColumn}
          headerClass={s.headerContainer}
        />
      </Widget>

      <div style={{ height: 30 }} />
      <div>
        <Widget title="Cash Positions" titleInfo={DASHBOARD_CASH_HELP_TEXT.TableTop10Positions}>
          <TopListWidgetsContainer
            topList={topCash.data}
            topListOrder={topCashOrder}
            changeOrder={setTopCashOrder}
            columns={getTopPositionsColumns(portfolioCurrencyFormatter, riskAssetView)}
            cellRenderer={(data) => cashCellRenderer(data, portfolioCurrencyFormatter)}
            columnClass={s.tableColumn}
            headerClass={s.headerContainer}
          />
        </Widget>
      </div>

      <BreakdownChart
        chartData={historyData.data}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        selectedCurrency={selectedCurrency.value}
        period={historyPeriod}
      />
    </div>
  );
};

export default Structure;
