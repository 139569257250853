import React, { FunctionComponent, PropsWithChildren } from 'react';
import s from './WorkGroupEmptyContainer.module.scss';
import image from '../../../../static/images/next.png';
import { PATHS } from '../../../../router/paths';
import { Link } from 'react-router-dom';

const WorkGroupEmptyContainer: FunctionComponent<{}> = () => {
  return (
    <Link
      className={s.cardsContainerEmpty}
      to={{
        pathname: PATHS.portfoliosManager.portfolioCreator.path,
        state: { modal: true },
      }}
    >
      <div className={s.wrapper}>
        <span className={s.title}>Create your first portfolio</span>
        <div className={s.arrowImage}>
          <img src={image} alt={'arrow-right'} />
        </div>
      </div>
    </Link>
  );
};

export default WorkGroupEmptyContainer;
