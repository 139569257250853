import React from 'react';

import cn from 'classnames';
import s from './PortfolioHeader.module.css';
import { useSize } from 'react-use';
import { DASHBOARD_TITLES } from '../../../../services/constants/constants';
import { useTypedSelector } from '../../../../services/hooks/useTypedSelector';
import { getPortfolioCurrencyFormatter } from '../../../../services/selectors/portfolio';

interface IOwnProps {
  title?: string;
  stats?: IStat[];
}

export const PortfolioSubHeader = ({ stats, title: titleIn = '' }: IOwnProps) => {
  const title = DASHBOARD_TITLES[titleIn] || titleIn;

  const { portfolioCurrencyFormatter } = useTypedSelector((state) => ({
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  }));

  const [sized, { height }] = useSize(
    <div className={cn(s.subHeaderSurround)}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <div className={cn(s.subHeader)}>{title.charAt(0).toUpperCase() + title.slice(1)}</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {stats?.map(({ label, colored, value, type = 'number', info }) => (
            <div className={cn(s.statSurround)} key={label}>
              <div className={cn(s.statLabel)}>{label}</div>
              <div
                className={cn(
                  s.statValue,
                  { [s.plus]: (colored && value > 0) || (colored && !value.toString().includes('-')) },
                  { [s.minus]: (colored && value < 0) || (colored && value.toString().includes('-')) }
                )}
              >
                {type === 'currency'
                  ? portfolioCurrencyFormatter(value as number)
                  : type === 'percentage'
                  ? `${value}%`
                  : value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {sized}
      <div style={{ height }} />
    </React.Fragment>
  );
};
