import React from 'react';
import { IPortfolioTrade } from '../../../../../../../models/IPortfolioTrade';
import { SnapshotActions } from '../../../../../../../services/actions';
import { useDispatch } from 'react-redux';
import { Button, InputPicker } from 'rsuite';
import Combo from '../../../../../../../components/Combo/Combo';
import { TRADE_TYPES } from '../../../../../../../services/constants/constants';

interface IOwnProps {
  trade: IPortfolioTrade;
}

const UnknownTradeType = ({ trade }: IOwnProps) => {
  const dispatch = useDispatch();

  const onSelect = (value: any, item: any) => {
    console.log({ value, item });
    updateTrade(item.label, value.id, value.defaultAssetSubclass);
  };

  const updateTrade = (assetClassName: string, assetClassId: string, assetSubClassId: string) => {
    console.log({ assetClassId, assetSubClassId });
    dispatch(
      SnapshotActions.startEditUnconfirmedTrade(
        {
          ...trade,
          tradeType: {
            id: assetClassId,
            name: assetClassName,
            quantityDecimals: 6,
            priceDecimals: 6,
            assetSubClasses: [],
          },
          instrument: {
            ...trade.instrument,
            assetSubClassId,
          },
        },
        []
      )
    );
  };

  return (
    <div>
      Please select asset type:
      {/*<Combo items={TRADE_TYPES} name={"blah"} />*/}
      <InputPicker data={TRADE_TYPES.map((type) => ({ label: type.name, value: type }))} onSelect={onSelect} />
      {/*<Button onClick={updateAssetClassId}>Equities</Button>*/}
      {/*<pre>{JSON.stringify({trade}, null, 4)}</pre>*/}
    </div>
  );
};

export default UnknownTradeType;
