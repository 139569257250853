import React, { PureComponent, WheelEvent } from 'react';
import cn from 'classnames';

import { IScenarioModel } from '../../../../../../services/reducers/ScenariosReducers';
import { Button } from '../../../../../../components/UIWidgets/Button';

import s from './ModelsList.module.scss';

interface IProps {
  models: IScenarioModel[];
}

interface IState {
  showPreviousButton: boolean;
  showNextButton: boolean;
}

class ModelsList extends PureComponent<IProps, IState> {
  readonly state: IState = {
    showPreviousButton: false,
    showNextButton: false,
  };

  private _listRef = React.createRef<HTMLUListElement>();
  private _itemWidth = 0;

  componentDidMount(): void {
    this._itemWidth = this._getItemWidth();
    this._checkNavigation();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { models } = this.props;
    if (prevProps.models !== models) {
      this._itemWidth = this._getItemWidth();
      this._checkNavigation();
    }
  }

  handlerClickNext = () => {
    const modelsListEl = this._listRef.current;
    if (!modelsListEl) {
      return;
    }

    const { scrollLeft, parentWidth } = this._getScrollRect(modelsListEl);
    const length = scrollLeft + parentWidth;

    const newOffsetItems = Math.trunc(length / this._itemWidth);
    const newScrollValue = newOffsetItems * this._itemWidth;
    const maxScrollValue = modelsListEl.offsetWidth - parentWidth;

    modelsListEl.style.left = newScrollValue > maxScrollValue ? `${-maxScrollValue}px` : `${-newScrollValue}px`;

    this._checkNavigation();
  };

  handlerClickPrevious = () => {
    const modelsListEl = this._listRef.current;
    if (!modelsListEl) {
      return;
    }

    const { scrollLeft, parentWidth } = this._getScrollRect(modelsListEl);

    const visibleItems = Math.trunc(parentWidth / this._itemWidth);
    const offsetItems = scrollLeft / this._itemWidth;

    const newOffsetItems = offsetItems - visibleItems;

    const newScrollValue = newOffsetItems * this._itemWidth;
    const minScrollValue = 0;

    modelsListEl.style.left = newScrollValue <= minScrollValue ? `${minScrollValue}px` : `${-newScrollValue}px`;
    this._checkNavigation();
  };

  handlerScroll = (event: WheelEvent) => {
    const modelsListEl = this._listRef.current;
    if (!modelsListEl) {
      return;
    }

    const { scrollLeft, parentWidth } = this._getScrollRect(modelsListEl);
    const offsetWidth = modelsListEl.offsetWidth;

    // no wheelDelta in event type
    const delta = (event.nativeEvent as any).wheelDelta > 0 ? 50 : -50;

    if (delta > 0 && scrollLeft - delta <= 0) {
      modelsListEl.style.left = `${0}px`;
    } else if (delta < 0 && scrollLeft + parentWidth + Math.abs(delta) >= offsetWidth) {
      modelsListEl.style.left = `${-offsetWidth + parentWidth}px`;
    } else {
      modelsListEl.style.left = `${-scrollLeft + delta}px`;
    }

    this._checkNavigation();
    event.nativeEvent.returnValue = false;
  };

  handlerMouseEnter = () => {
    document.body.classList.add('noScroll');
  };

  handlerMouseLeave = () => {
    document.body.classList.remove('noScroll');
  };

  render() {
    const models = this.props.models.map((item, idx) => ({ ...item, name: idx }));
    const { showPreviousButton, showNextButton } = this.state;
    return (
      <div
        className={s.wrapper}
        onWheel={this.handlerScroll}
        onMouseEnter={this.handlerMouseEnter}
        onMouseLeave={this.handlerMouseLeave}
      >
        <ul ref={this._listRef} className={s.modelsList}>
          {models.map((item, idx) => (
            <li key={idx} className={s.modelsItem}>
              <button className={s.modelsItemButton} type="button">
                <div className={s.modelsItemName}>{item.name}</div>
                <div className={s.modelsItemRisk}>Risk: {item.risk}</div>
              </button>
            </li>
          ))}
        </ul>
        <div className={s.listNavigation}>
          {showPreviousButton && (
            <Button
              className={cn(s.listNavigationBtn, s.previous)}
              variant="empty"
              onClick={this.handlerClickPrevious}
            />
          )}
          {showNextButton && (
            <Button className={cn(s.listNavigationBtn, s.next)} variant="empty" onClick={this.handlerClickNext} />
          )}
        </div>
      </div>
    );
  }

  private _getScrollRect(el: HTMLElement): { parentWidth: number; scrollLeft: number } {
    const parentWidth = el.parentElement ? el.parentElement.offsetWidth : 0;
    const scrollLeft = el.style.left ? Math.abs(parseFloat(el.style.left)) : 0;

    return {
      parentWidth,
      scrollLeft,
    };
  }

  private _checkNavigation() {
    const { models } = this.props;
    const scrollList = this._listRef.current;
    if (!scrollList) {
      return;
    }

    const scrollLeft = scrollList.style.left ? Math.abs(parseFloat(scrollList.style.left)) : 0;
    const offsetWidth = scrollList.parentElement ? scrollList.parentElement.offsetWidth : 0;

    this.setState({
      showPreviousButton: scrollLeft > 0,
      showNextButton: this._itemWidth * models.length - offsetWidth - scrollLeft > 0,
    });
  }

  private _getItemWidth(): number {
    const modelsListEl = this._listRef.current;
    if (!modelsListEl) {
      return 0;
    }

    const firstItem: HTMLLIElement = modelsListEl.firstChild as HTMLLIElement;
    if (!firstItem) {
      return 0;
    }

    const computedStyles: CSSStyleDeclaration = getComputedStyle(firstItem);
    const chartMarginLeft = computedStyles.marginLeft ? parseFloat(computedStyles.marginLeft) : 0;
    const chartMarginRight = computedStyles.marginRight ? parseFloat(computedStyles.marginRight) : 0;
    return firstItem.offsetWidth + chartMarginLeft + chartMarginRight;
  }
}

export default ModelsList;
