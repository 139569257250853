import React, { FC } from 'react';
import { IRootState } from '../../../../../../services/store';
import { AsyncActionDispatch } from '../../../../../../services/utils/ReduxHelper';
import { PortfolioActions, SnapshotActions } from '../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import { IPortfolioStructure } from '../../../../../../models/IPortfolioStructure';
import { connect } from 'react-redux';
import OptionEditContainer from '../Transaction/Forms/Option/OptionEditContainer';
import AssetEntryContainer from '../Transaction/Forms/AssetEntryContainer';
import { FORMS_NAME } from '../../../../../../services/constants/forms';
import CashAdjustmentEditContainer from '../Transaction/Forms/CashAdjustment/CashAdjustmentEditContainer';
import EquityFutureEditContainer from '../Transaction/Forms/EquityFuture/EquityFutureEditContainer';
import SubscriptionEditContainer from '../Transaction/Forms/Subscriptions/SubscriptionEditContainer';
import FXEditContainer from '../Transaction/Forms/FX/FXEditContainer';
import { createSimpleInstrumentContainer } from '../Transaction/Forms/SimpleInstrument/SimpleInstrumentEditContainer';
import FixedIncomeEditContainer from '../Transaction/Forms/FixedIncome/FixedIncomeEditContainer';
import UnknownTradeType from '../Transaction/components/UnknownTradeType';
import { useTradeType } from './useTradeType';
import { CryptoSwapEntry } from '../Transaction/Forms/CryptoSwap/CryptoSwapEntry';
import { UnconnectedAssetEntryContainer } from '../Transaction/Forms/UnconnectedAssetEntryContainer';
import WarrantEditContainer from '../Transaction/Forms/Warrant/WarrantEditContainer';

interface IMapStateToProps {
  editedUnconfirmedTrade: IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];
}

interface IDispatchToProps {
  changeTradesSort: (order: IOrder) => void;
  // confirm: (portfolioId: string, delta: number | undefined) => AxiosPromise<void>;
  // discard: (portfolioId: string) => AxiosPromise<void>;

  fetchPortfolioStructure: (portfolioId: string) => AxiosPromise<IPortfolioStructure>;
  fetchPortfolioInfo: (portfolioId: string) => AxiosPromise<IPortfolio>;
  resetTrades: () => void;
  resetEditTrade: () => void;
  decimals?: { quantityDecimals: number; priceDecimals: number };
}

type Props = IMapStateToProps & IDispatchToProps;

const EditTransactionContainer: FC<Props> = ({ editedUnconfirmedTrade, resetEditTrade }) => {
  const { assetSubClassId } = editedUnconfirmedTrade?.trade?.instrument || {};

  React.useEffect(() => {
    return () => {
      resetEditTrade?.();
    };
  }, [resetEditTrade]);

  const tradeTypeId = editedUnconfirmedTrade?.trade?.tradeType?.id;

  const EquityEditContainer = createSimpleInstrumentContainer(FORMS_NAME.equity, 'equity', tradeTypeId);
  const FundEditContainer = createSimpleInstrumentContainer(FORMS_NAME.funds, 'fund', tradeTypeId);
  const CryptoEditContainer = createSimpleInstrumentContainer(FORMS_NAME.crypto, 'crypto', tradeTypeId);
  const RealAssetEditContainer = createSimpleInstrumentContainer(FORMS_NAME.realAssets, 'realAssets', tradeTypeId);
  const CommodityEditContainer = createSimpleInstrumentContainer(
    FORMS_NAME.commodity,
    'commodity',
    tradeTypeId,
    'Quantity (Oz)'
  );

  const checkedOut = editedUnconfirmedTrade?.trade?.checkedOut;
  const tradeType = useTradeType(assetSubClassId, tradeTypeId);

  const renderForm = () => {
    switch (tradeType) {
      case 'RealAssets':
      case 'RealEstate':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.realAssets}
            ChildComponent={RealAssetEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'Commodity':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.commodity}
            ChildComponent={CommodityEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'EqOptions':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.transactionOption}
            ChildComponent={OptionEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'EqWarrants':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.warrant}
            ChildComponent={WarrantEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'AdjFXSpot':
        return <AssetEntryContainer formName={FORMS_NAME.fx} ChildComponent={FXEditContainer} editMode={true} />;
      case 'CshAdjustments':
      case 'Adjustments':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.transactionFee}
            ChildComponent={CashAdjustmentEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'Equities':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.equity}
            ChildComponent={EquityEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'Funds':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.funds}
            ChildComponent={FundEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'CryptoCurrencies':
        if (['SWAP_L', 'SWAP_S'].includes(editedUnconfirmedTrade.trade.operation)) {
          return (
            <UnconnectedAssetEntryContainer
              ChildComponent={CryptoSwapEntry}
              tradeType={'CryptoCurrencies'}
              editMode={true}
            />
          );
        }
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.crypto}
            ChildComponent={CryptoEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );

      case 'EqFutures':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.equityFutures}
            ChildComponent={EquityFutureEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'FixedIncome':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.fixedIncome}
            ChildComponent={FixedIncomeEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case 'SubCash':
        return (
          <AssetEntryContainer
            formName={FORMS_NAME.subscriptionWithdrawal}
            ChildComponent={SubscriptionEditContainer}
            editMode={true}
            checkedOut={checkedOut}
          />
        );
      case null:
        return <UnknownTradeType trade={editedUnconfirmedTrade.trade} />;
    }
  };

  return (
    <div style={{ width: '100%', marginTop: '1rem' }}>
      {renderForm()}
      {/*<h2>{tradeType}</h2>*/}
    </div>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  editedUnconfirmedTrade: state.snapshot.snapshotEdit.editedUnconfirmedTrade,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  fetchPortfolioStructure: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioStructure(portfolioId)),
  fetchPortfolioInfo: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioInfo(portfolioId)),
  // confirm: (portfolioId: string, delta: number | undefined) =>
  //   dispatch(SnapshotActions.confirmSavedTrades(portfolioId, delta)),
  // discard: (portfolioId: string) => dispatch(SnapshotActions.discardSavedTrades(portfolioId)),
  changeTradesSort: (order: IOrder) => dispatch(SnapshotActions.changeSavedTradesSort(order)),
  resetTrades: () => dispatch(SnapshotActions.resetSavedTrades()),
  resetEditTrade: () => dispatch(SnapshotActions.finishEditUnconfirmedTrade()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionContainer);
