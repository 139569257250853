import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AxiosPromise } from 'axios';

import { IWithPortfolioInfoProps, withPortfolioInfo } from '../../../../../../../../components/HOC/withPortfolioInfo';

import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { IPortfolioTrade, IPortfolioValidatedTrade } from '../../../../../../../../models/IPortfolioTrade';
import { IRootState } from '../../../../../../../../services/store';

import { IPaginationContent } from '../../../../../../../../models/redux/ILoadingPaginationDataState';

import { AppActions } from '../../../../../../../../services/actions/AppActions';
import { Upload } from '../../../../../../../../components/Upload';

interface IMapStateToProps {
  isUploading: boolean;
  isUploaded: boolean;
  uploadProgress: number;
  savedTradesOrder: IOrder;
}

interface IDispatchToProps {
  uploadTrades: (portfolioId: string, file: File) => AxiosPromise<void>;
  fetchSavedTrades: (portfolioId: string, order: IOrder) => AxiosPromise<IPaginationContent<IPortfolioValidatedTrade>>;
  showNotification: (notification: INotification) => void;
}

interface IState {
  loadedCount: number;
}

enum NotificationType {
  ERROR,
  SUCCESS,
}

type IProps = IWithPortfolioInfoProps & IMapStateToProps & IDispatchToProps;

class UploadTransactionContainer extends PureComponent<IProps, IState> {
  readonly state: IState = {
    loadedCount: 0,
  };

  handleUpload = (file: File) => {
    const { portfolioInfo, savedTradesOrder, uploadTrades, fetchSavedTrades, showNotification } = this.props;
    const portfolioId = portfolioInfo.id;
    console.log('UPLOAD HANDEL');
    uploadTrades(portfolioId, file)
      .then((data) => {
        showNotification({
          text: 'File has been loaded',
          type: NotificationType.SUCCESS,
        });
        fetchSavedTrades(portfolioId, savedTradesOrder);
      })
      .catch(() => {
        showNotification({
          text: 'File hasn`t been loaded',
          type: NotificationType.ERROR,
        });
      });
  };

  handleReset = () => {};

  render() {
    const { isUploading, isUploaded, uploadProgress } = this.props;
    const { loadedCount } = this.state;
    return (
      <Upload
        isLoading={isUploading}
        isLoaded={isUploaded}
        loadedCount={loadedCount}
        uploadProgress={uploadProgress}
        onUpload={this.handleUpload}
        onReset={this.handleReset}
      />
    );
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  isUploading: state.snapshot.snapshotEdit.uploadTrades.isFetching,
  isUploaded: state.snapshot.snapshotEdit.uploadTrades.isFetched,
  uploadProgress: state.snapshot.snapshotEdit.uploadingProcess.progress,
  savedTradesOrder: state.snapshot.snapshotEdit.savedTrades.order,
});

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  uploadTrades: (portfolioId: string, file: File) => dispatch(SnapshotActions.uploadTrades(portfolioId, file)),
  fetchSavedTrades: (portfolioId: string, order: IOrder) =>
    dispatch(SnapshotActions.fetchPortfolioSavedTrades(portfolioId, order)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
});

export default withPortfolioInfo(connect(mapStateToProps, mapDispatchToProps)(UploadTransactionContainer));
