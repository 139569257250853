import React, { PureComponent } from 'react';
import { Color } from 'csstype';

import s from './DrawConnections.module.css';

export interface IConnections {
  [key: string]: string[];
}

interface IScreenPoint {
  x: number;
  y: number;
}

/**
 * connections // key - fromId , value - toIds
 */
interface IProps {
  connections: IConnections;
  color: Color;
  selectedColor: Color;

  classPrefixFrom?: string;
  classPrefixTo?: string;
  redraw?: boolean;
  selected?: string;
  onDrawEnd?: () => void;
}

class DrawConnections extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    color: '#6677cc',
    selectedColor: '#cc0414',
    classPrefixFrom: 'connect-from-',
    classPrefixTo: 'connect-to-',
    redraw: false,
    onDrawEnd: () => {},
  };

  timerId: NodeJS.Timeout | undefined;
  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  scale: number = window.devicePixelRatio || 1;
  parentTop: number = 0;
  parentLeft: number = 0;

  componentDidMount() {
    this.timerId = setTimeout(() => {
      this._prepareCanvas();
      this._drawAll();
    }, 100);

    window.addEventListener('resize', this._resizeHandler);
    window.addEventListener('scroll', this._scrollHandler);
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.canvas && this.ctx) {
      window.requestAnimationFrame(() => {
        const { redraw } = this.props;
        if (!redraw) {
          return;
        }
        this._drawAll();
        this._resizeHandler();
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler);
    window.removeEventListener('scroll', this._scrollHandler);
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  render() {
    return (
      <canvas
        ref={(e) => {
          this.canvas = e;
        }}
        className={s.connectionsPlot}
      />
    );
  }

  private _resizeHandler = () => {
    this._prepareCanvas();
    this._drawAll();
  };

  private _scrollHandler = () => {
    this._setParentOffsets();
  };

  private _prepareCanvas = () => {
    if (!this.canvas) {
      return;
    }

    this._setParentOffsets();

    this.ctx = this.canvas.getContext('2d');

    let width = 0;
    let height = 0;

    if (this.canvas.parentElement) {
      width = this.canvas.parentElement.offsetWidth;
      height = this.canvas.parentElement.offsetHeight;
    }
    this.canvas.width = width * this.scale;
    this.canvas.height = height * this.scale;

    this.canvas.style.width = `${width}px`;
    this.canvas.style.height = `${height}px`;

    if (this.ctx) {
      this.ctx.scale(this.scale, this.scale);
    }
  };

  private _drawConnections() {
    const { connections, classPrefixFrom, classPrefixTo, selected } = this.props;
    Object.keys(connections).forEach((key: string) => {
      const startPoint = document.getElementById(`${classPrefixFrom}${key}`);
      connections[key].forEach((to: string) => {
        const isSelected = Boolean(selected && to === selected);
        const endPoint: HTMLElement | null = document.getElementById(`${classPrefixTo}${to}`);
        this._drawLine(this._preparePoint(startPoint), this._preparePoint(endPoint), isSelected);
      });
    });
  }

  private _drawAll() {
    const { onDrawEnd } = this.props;
    this._clearArea();
    this._drawConnections();

    if (onDrawEnd) {
      onDrawEnd();
    }
  }

  private _clearArea() {
    if (!this.canvas) {
      return;
    }

    const { width, height } = this.canvas;
    const contWidth = width * this.scale;
    const contHeight = height * this.scale;
    if (this.ctx) {
      this.ctx.clearRect(0, 0, contWidth, contHeight);
    }
  }

  private _drawLine(c1: IScreenPoint | null, c2: IScreenPoint | null, selected: boolean) {
    const { ctx } = this;
    if (!c1 || !c2 || !ctx) {
      return;
    }

    const { selectedColor, color } = this.props;
    const dx = c2.x - c1.x;
    ctx.beginPath();
    ctx.strokeStyle = selected ? selectedColor : color;
    ctx.lineWidth = selected ? 2 : 1;
    ctx.lineJoin = 'round';
    ctx.moveTo(c1.x, c1.y);
    ctx.bezierCurveTo(c1.x + dx * 0.33, c1.y, c1.x + dx * 0.67, c2.y, c2.x, c2.y);
    ctx.stroke();
  }

  private _setParentOffsets() {
    if (!this.canvas || !this.canvas.parentElement) {
      return;
    }

    const rect = this.canvas.parentElement.getBoundingClientRect();
    this.parentLeft = rect.left;
    this.parentTop = rect.top;
  }

  private _preparePoint(target: HTMLElement | null): IScreenPoint | null {
    if (!target) {
      return null;
    }

    const { left, top, width, height } = target.getBoundingClientRect();
    const deltaY = this.parentTop - height / 2; // adjustment by Y axis
    const deltaX = this.parentLeft - width / 2; // adjustment by X axis
    return { x: left - deltaX, y: top - deltaY };
  }
}

export default DrawConnections;
