import React, { FC } from 'react';
import SimpleDropDown, {
  SimpleDropDownProps,
} from '../../../../../../../../components/Reusable/SimpleDropDown/SimpleDropDown';
import { IAssetResolverProps } from './IAssetResolverProps';
import { TRADE_TYPES } from '../../../../../../../../services/constants/constants';
import getAssetIdentifier from '../getAssetIdentifier';

type Props = IAssetResolverProps & {
  assetSubclassId?: string;
};

const AssetClassResolver: FC<Props> = ({ item, updateAssetInformation, assetSubclassId }) => {
  const assetClasses = TRADE_TYPES.filter((t) => t.id !== 'CashAndEquivalents');

  const selectedOption = assetSubclassId
    ? assetClasses.find(
        (type) =>
          type.defaultAssetSubclass === assetSubclassId ||
          type.additionalAssetSubclasses?.some((a) => a === assetSubclassId)
      ) ?? null
    : null;

  const updateAsset = updateAssetInformation(getAssetIdentifier(item));

  const handleSelect: SimpleDropDownProps<TradeType>['onSelect'] = ({ selectedItem }) => {
    updateAsset({
      // TODO: Better address the typing here to allow extension of IOption
      // @ts-ignore
      assetSubClass: (selectedItem?.defaultAssetSubclass ?? '') as string,
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <SimpleDropDown
        // Exclude Real Assets and Real Estate
        items={assetClasses}
        onSelect={handleSelect}
        selectedItem={selectedOption}
        backgroundColor={'rgba(0,0,0,0.2)'}
      />
    </div>
  );
};

export default AssetClassResolver;
