import { Auth } from 'aws-amplify';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordFormContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const handleSubmit = (formValue: IPasswordFormData) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, formValue.currentPassword, formValue.newPassword);
      })
      .then(() => {
        Auth.signOut().then(() => {
          history.push('/');
        });
      })
      .catch(() => {
        alert(
          "Sorry, an unknown error occurred whilst changing passwords. Please try using the 'forgot password' if the issue persists"
        );
      });
  };

  return <ChangePasswordForm onSubmit={handleSubmit} />;
};

export default withRouter(ChangePasswordFormContainer);
