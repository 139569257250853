import _ from 'lodash';

export const required = (value: any): undefined | string =>
  (value || value === 0) && value.toString().trim() ? undefined : 'Required';

export const maxLength = (max: number) => (value: string): undefined | string =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const number = (value: string | number): string | undefined =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const email = (value: string): string | undefined =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

export const dbFieldNamingRules = (value: string): string | undefined =>
  value && !/^[ a-zA-Z-\'_0-9]+$/.test(value)
    ? 'Only alphanumeric, spaces, underscores, and dash characters are allowed.'
    : undefined;

export const passwordAreEqual = (pass1: string, pass2: string): string | undefined =>
  pass1 && pass2 && pass1 !== pass2 ? 'Password should be equal' : undefined;

export const password = (value: string): string | undefined =>
  value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/g.test(value)
    ? 'Should contains at least 1 lowercase letter, 1 uppercase letter, 1 special symbol, 1 number and be not less than 8 character'
    : undefined;

export const pattern = (value: string, regExp: string): string | undefined => {
  const reg: RegExp = new RegExp(regExp, 'gi');
  return value && !reg.test(value) ? 'Invalid value' : undefined;
};

export const notEmpty = (value: any): undefined | string => {
  return value === null || value === undefined ? 'Required' : undefined;
};

export const positiveNumber = (value: any): undefined | string => {
  const isPositive = /^([0-9]*\.?[0-9]+|[0-9]+\.?[0-9]*)?$/.test(value);
  return isPositive ? undefined : 'Must be a positive';
};

export const validateCustomeCustodian = (values: any) => {
  const errors = { customCustodian: '' };
  if (values.customCustodian && !_.inRange(values.customCustodian.length, 2, 41)) {
    errors.customCustodian = 'Must be between 2 and 40 characters';
  }

  if (values.customCustodian && !/^([a-zA-Z0-9-_ & *.])+()$/i.test(values.customCustodian)) {
    errors.customCustodian =
      'Only alphanumeric, spaces, underscores, ampersand, full stop, asterisk and dash characters.';
  }
  return errors;
};
