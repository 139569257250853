import React from 'react';
import s from './TableColumnsFilter.module.scss';
import { Checkbox } from '../../../../../../components';
import { ITableColumn } from '../../../../../../components/UIWidgets/TableWrapper/models';
import { Popover, Whisper } from 'rsuite';
import { usePreferences } from '../../../../../../services/context/PreferencesContext';
import { DEFAULT_POSITION_FIELDS } from '../../../../../../services/constants/preferences';

interface IProps {
  optionalColumns: ITableColumn[];
  preferenceKey: keyof IUserPreferences['misc'];
  defaults?: Record<string, boolean>;
}

const TableColumnsFilter = ({ preferenceKey, optionalColumns, defaults }: IProps) => {
  const { preferences, updateMiscPreference } = usePreferences();
  const visiblePositionFields = preferences?.misc[preferenceKey] ?? DEFAULT_POSITION_FIELDS;

  const handleCheckboxChange = (event: any) => {
    const { name } = event.target;

    updateMiscPreference(preferenceKey, {
      ...visiblePositionFields,
      [name]: !visiblePositionFields[name],
    });
  };

  const checkboxItems = optionalColumns.map((col) => ({
    label: col.label,
    dataKey: col.dataKey,
    isVisible: !!visiblePositionFields[col.dataKey], // props.optionalColumnsCheckboxesState.filter((el) => el.dataKey === col.dataKey)[0].isVisible
  }));

  const reset = () => {
    if (defaults) {
      updateMiscPreference(preferenceKey, defaults);
    }
  };

  const setAll = (value: boolean) => {
    const newSettings: Record<string, boolean> = {};

    optionalColumns.map((col) => (newSettings[col.dataKey] = value));
    updateMiscPreference(preferenceKey, newSettings);
  };

  const selectAll = () => setAll(true);
  const selectNone = () => setAll(false);

  const speaker = (
    <Popover style={{ maxHeight: 600 }}>
      <div>
        {checkboxItems.map((el, idx) => (
          <Checkbox
            label={el.label}
            name={el.dataKey}
            checked={el.isVisible}
            onChange={handleCheckboxChange}
            key={idx}
            className={s.checklistItem}
          />
        ))}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem', cursor: 'pointer' }}>
        {!!defaults && <div onClick={reset}>Defaults</div>}
        <div onClick={selectAll}>Select All</div>
        <div onClick={selectNone}>Select None</div>
      </div>
    </Popover>
  );

  return (
    <div className={s.columnFilterContainer}>
      <Whisper speaker={speaker} placement={'bottomEnd'} trigger={'click'} className={s.filterToggleButton}>
        <div className={s.filterToggleButton}>Show/Hide Fields</div>
      </Whisper>
    </div>
  );
};

export default React.memo(TableColumnsFilter);
