import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

interface IProps {
  availableDates: Array<IOption<{ month: number; date: number }>>;
  disabled?: boolean;
  loading?: boolean;
}

const OptionsExpirySelector: FC<IProps> = ({ availableDates, disabled, loading }) => {
  return (
    <FormFieldWrapper label="Expiry" className={cn(s.nameField, s.fieldsWrapper, s.expiryField)} required={true}>
      <Field
        component={renderAutocomplete}
        name="expiryDate"
        options={availableDates}
        theme="inverse"
        disableReset={true}
        disabled={disabled}
        inputDisabled={false}
        inputProps={{
          theme: 'inverse',
          className: 'input--small',
          placeholder: loading ? 'Loading' : 'Select',
        }}
      />
    </FormFieldWrapper>
  );
};

export default OptionsExpirySelector;
