import React from 'react';
import FixedIncomeEntry, { IFixedIncomeFormValues } from './FixedIncomeEntry';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { IRootState } from '../../../../../../../../services/store';

type Props = IAssetEntryComponentProps<IFixedIncomeFormValues>;
type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

export default ({ editedUnconfirmedTrade, ...props }: Props) => {
  return (
    <FixedIncomeEntry
      key={editedUnconfirmedTrade?.trade?.key}
      {...props}
      initialValues={props.editMode ? getInitialValuesFromTrade(editedUnconfirmedTrade) : props.initialValues}
    />
  );
};

const getInitialValuesFromTrade = (t?: UnconfirmedTrade) => {
  if (!t?.trade) {
    return {};
  }
  const { quantity, operation, currency, instrument } = t!.trade;
  const values: Partial<IFixedIncomeFormValues> = {
    ...t.trade,
    operation: operation as 'BUY' | 'SELL',
    amount: quantity,
  };

  if (instrument?.sourceId) {
    values.instrument = {
      ...instrument,
      value: {
        ...instrument,
        currency,
        sourceId: {
          sourceId: instrument.sourceId,
          sourceData: instrument.sourceData,
        },
      },
    };
  }
  // console.log("t", t);

  return values;
};
