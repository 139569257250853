import s from '../component/TopEsgPortfolioTable/TopEsgPortfolioTable.module.scss';
import * as React from 'react';

export const CIRCLE_RED = '#B9424A';
export const CIRCLE_AMBER_RED = '#b97642';
export const CIRCLE_AMBER = '#c2a55a';
export const CIRCLE_AMBER_GREEN = '#afc25a';
export const CIRCLE_GREEN = '#56B877';

export const getCircleColor = (value: number) => {
  let color;
  if (value < 50) {
    color = CIRCLE_RED;
  } else if (value <= 75) {
    color = CIRCLE_AMBER_RED;
  } else {
    color = CIRCLE_GREEN;
  }
  return color;
};

export const circleRenderer = (e: string) => (rowData: any, dataKey: any) => {
  const value = rowData[dataKey][e] as number;

  return <div className={s.circle} style={{ backgroundColor: getCircleColor(value) }} />;
};

export const circleAndTextRenderer = (rowData: any, dataKey: any) => {
  const value = rowData[dataKey].value;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
      <div style={{ minWidth: 10 }}>
        <div className={s.circleSm} style={{ backgroundColor: getCircleColor(value), marginRight: 3.5 }} />
      </div>
      <span>{rowData[dataKey].label}</span>
    </div>
  );
};
