export const sampleSimulatedReturn = {
  mean: 36.3,
  bins: [
    {
      lowerBound: 21.9,
      upperBound: 22.4,
      value: 17.7,
    },
    {
      lowerBound: 22.4,
      upperBound: 22.9,
      value: 10.4,
    },
    {
      lowerBound: 22.9,
      upperBound: 23.4,
      value: 2.7,
    },
    {
      lowerBound: 23.4,
      upperBound: 23.9,
      value: 8.5,
    },
    {
      lowerBound: 23.9,
      upperBound: 24.4,
      value: 5.8,
    },
    {
      lowerBound: 24.4,
      upperBound: 24.9,
      value: 3.1,
    },
    {
      lowerBound: 24.9,
      upperBound: 25.4,
      value: 5.4,
    },
    {
      lowerBound: 25.4,
      upperBound: 25.9,
      value: 0,
    },
    {
      lowerBound: 25.9,
      upperBound: 26.4,
      value: 0.4,
    },
    {
      lowerBound: 26.4,
      upperBound: 26.9,
      value: 0,
    },
    {
      lowerBound: 26.9,
      upperBound: 27.4,
      value: 0.8,
    },
    {
      lowerBound: 27.4,
      upperBound: 27.9,
      value: 0,
    },
    {
      lowerBound: 27.9,
      upperBound: 28.4,
      value: 0,
    },
    {
      lowerBound: 28.4,
      upperBound: 28.9,
      value: 0,
    },
    {
      lowerBound: 28.9,
      upperBound: 29.4,
      value: 0.4,
    },
    {
      lowerBound: 29.4,
      upperBound: 29.9,
      value: 0,
    },
    {
      lowerBound: 29.9,
      upperBound: 30.4,
      value: 0,
    },
    {
      lowerBound: 30.4,
      upperBound: 30.9,
      value: 0.4,
    },
    {
      lowerBound: 30.9,
      upperBound: 31.4,
      value: 0,
    },
    {
      lowerBound: 31.4,
      upperBound: 31.9,
      value: 5.8,
    },
    {
      lowerBound: 31.9,
      upperBound: 32.4,
      value: 9.6,
    },
    {
      lowerBound: 32.4,
      upperBound: 32.9,
      value: 3.8,
    },
    {
      lowerBound: 32.9,
      upperBound: 33.4,
      value: 5.8,
    },
    {
      lowerBound: 33.4,
      upperBound: 33.9,
      value: 3.1,
    },
    {
      lowerBound: 33.9,
      upperBound: 34.4,
      value: 2.3,
    },
    {
      lowerBound: 34.4,
      upperBound: 34.9,
      value: 2.3,
    },
    {
      lowerBound: 34.9,
      upperBound: 35.4,
      value: 7.3,
    },
    {
      lowerBound: 35.4,
      upperBound: 35.9,
      value: 1.2,
    },
    {
      lowerBound: 35.9,
      upperBound: 36.4,
      value: 3.5,
    },
  ],
};
