import { StylesConfig } from 'react-select/lib/styles';

export const customSmallStyles: StylesConfig = {
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? 'var(--selected-bg)' : 'var(--secondary-bg)',
    color: isSelected ? 'var(--text-white)' : 'var(--text-color)',
    padding: 7,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {},
    '&:active': {
      backgroundColor: 'var(--secondary-bg)',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 24,
    borderWidth: 0,
    borderRadius: 4,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({}),

  placeholder: () => ({}),
  menuList: () => ({
    backgroundColor: 'var(--secondary-bg)',
    minWidth: 110,
    position: 'absolute',
    right: '0',
    borderRadius: 4,
    overflow: 'hidden',
  }),
  dropdownIndicator: () => ({
    transform: 'scale(0.5)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
    paddingLeft: 0,
    height: 28,
    '& > div': {
      marginTop: -18,
    },
  }),
};
