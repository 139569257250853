import React, { FC } from 'react';
import WarrantEntry, { IWarrantFormValues } from './WarrantEntry';
import { IRootState } from '../../../../../../../../services/store';

import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { ICashAdjustmentFormValues } from '../CashAdjustment/CashAdjustmentEntry';

type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

type Props = IAssetEntryComponentProps<ICashAdjustmentFormValues>;

const WarrantEditContainer: FC<Props> = ({ editedUnconfirmedTrade, ...props }) => {
  return <WarrantEntry {...props} editMode={true} initialValues={getInitialValuesFromTrade(editedUnconfirmedTrade)} />;
};

const getInitialValuesFromTrade = (editedUnconfirmedTrade?: UnconfirmedTrade) => {
  if (typeof editedUnconfirmedTrade === 'undefined') {
    return {};
  }
  const { instrument, multiplier, tradeType, tradeCosts, strikePrice, price, fxRate } = editedUnconfirmedTrade.trade;

  let values: Partial<IWarrantFormValues> = {
    ...editedUnconfirmedTrade.trade,
    custodian: editedUnconfirmedTrade.trade.custodian,
    tradeType: tradeType as any,
    price: !!price ? String(price) : '',
    tradeCosts: !!tradeCosts ? String(tradeCosts) : '',
    // type: type as 'PUT' | 'CALL' | undefined,
    type: instrument?.specificMetadata?.type as 'CALL' | 'PUT' | undefined,
  } as any;

  if (instrument?.instrumentId) {
    const expirationDate = instrument?.specificMetadata?.expirationDate;
    const expiryParts = expirationDate?.split('-') || ['1', '1', '1'];
    values = {
      ...values,
      expiryDate: {
        id: expirationDate,
        name: expirationDate?.substr(5) ?? '',
        value: { month: parseInt(expiryParts[1], 10), date: parseInt(expiryParts[2], 10) },
      },
      multiplier: !!multiplier ? String(multiplier) : undefined,
      fxRate: !!fxRate ? String(fxRate) : undefined,
      strike: {
        value: strikePrice ?? 0,
        id: String(strikePrice),
        name: String(strikePrice),
      },
      instrument: {
        id: instrument.instrumentId,
        name: instrument.name,
        value: {
          ...instrument,
          id: instrument.instrumentId,
        },
      },
      warrant: {
        id: instrument.instrumentId,
        name: instrument.name,
        value: {
          ...instrument,
          id: instrument.instrumentId,
        },
      },
      year: {
        id: expiryParts[0],
        value: parseInt(expiryParts[0], 10),
        name: expiryParts[0],
      },
    };
  }

  return values;
};
export default WarrantEditContainer;
