import React, { FC } from 'react';
import cn from 'classnames';
import s from '../PortfolioTransfer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useUserList } from '../../../services/hooks/apiHooks/useUserList';
import { Loader } from '../../../components';

interface IOwnProps {
  role: 'MANAGER' | 'CUSTOMER';
  selectedUsers: string[];
  includeAssigned?: boolean;
  includeInactive?: boolean;
  onSelectUser(user: IAccountUser): void;
  filterFunction?(user: IAccountUser): boolean;
}

const LINE_HEIGHT = 20;

const UserList: FC<IOwnProps> = ({
  role,
  selectedUsers,
  onSelectUser,
  filterFunction = Boolean,
  includeAssigned = true,
  includeInactive = false,
}) => {
  const { isFetching, data: userList } = useUserList(role, { includeAssigned, includeInactive });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div
      className={cn(s.userListScrollWrap)}
      style={{
        height: `${Math.min(userList?.length ?? 0, 8) * LINE_HEIGHT}rem`,
        maxHeight: 200,
      }}
    >
      <div className={cn(s.userListSurround)}>
        {userList
          ?.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
          .filter(filterFunction)
          .map((user) => {
            const selected = selectedUsers.indexOf(user.id!) > -1;
            const color = selected ? 'var(--text-success-color)' : 'var(--text-color)';
            return (
              <div onClick={() => onSelectUser?.(user)} key={user.id} className={cn(s.userSurround)}>
                <div
                  className={cn(s.indicator)}
                  style={{
                    background: selected ? color : undefined,
                    border: `2px solid ${color}`,
                  }}
                >
                  {selected && <FontAwesomeIcon icon={faCheck} color={'white'} />}
                </div>
                {user.firstName} {user.lastName} ({user.email})
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserList;
