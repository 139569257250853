import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';
import { DashboardActions } from '../../actions';
import { ReduxHelper } from '../../utils/ReduxHelper';

export interface IBreakdownReportState extends ILoadingDataState {
  data: IBreakdownReport[];
}

const INITIAL_STATE: IBreakdownReportState = ReduxHelper.createInitialState<IBreakdownReport[]>([]);

const profitReportBreakdonReducer = ReduxHelper.createHttpRequestReducer<ILoadingDataState<IBreakdownReport[]>>(
  INITIAL_STATE,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_REQUEST,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_SUCCESS,
  DashboardActions.PROFIT_REPORT_BREAKDOWN_ERROR
);

export default profitReportBreakdonReducer;
