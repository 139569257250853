import React from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import useCustodiansOption from '../../../../../../../../services/hooks/useCustodiansOption';

interface IProps {
  className?: string;
  name?: string;
  label?: string;
}

const Custodian = ({ className, name = 'custodian', label = 'Custodian' }: IProps) => {
  const { custodians } = useCustodiansOption();
  const defaultCustodian = custodians.find((custodian) => custodian.value.default === true);
  return (
    <FormFieldWrapper
      label={label}
      className={cn(s.nameField, s.fieldsWrapper, s.custodianField, className)}
      required={true}
    >
      <Field
        component={renderAutocomplete}
        name={name}
        options={custodians}
        defaultValue={defaultCustodian as any}
        theme="inverse"
        disableReset={true}
        inputDisabled={false}
        inputProps={{
          theme: 'inverse',
          className: 'input--small',
          placeholder: 'Not Selected',
        }}
      />
    </FormFieldWrapper>
  );
};

export default Custodian;
