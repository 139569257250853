import React, { Fragment, PureComponent } from 'react';
import cn from 'classnames';

import { Button, DropDown } from '../../../../../../../../../components/UIWidgets';

interface IProps {
  market: IMarket;
  onRemove: (market: IMarket) => void;
  onEdit: (market: IMarket) => void;
}

class MarketActionsCell extends PureComponent<IProps> {
  renderDropDownContent = (market: IMarket): React.ReactNode => {
    const { onRemove, onEdit } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => {
            onEdit(market);
          }}
          className={'drop-down__item'}
          variant="empty"
        >
          Edit
        </Button>
        {/*        <Button
          onClick={() => {
            onRemove(market);
          }}
          variant="empty"
          className={cn('drop-down__item', 'button--remove')}
        >
          Remove
        </Button>*/}
      </Fragment>
    );
  };

  render() {
    const { market } = this.props;
    return <DropDown>{this.renderDropDownContent(market)}</DropDown>;
  }
}

export default MarketActionsCell;
