import React, { Fragment, PureComponent } from 'react';
import cn from 'classnames';

import { Button, DropDown } from '../../../../../../../../../components/UIWidgets';

interface IProps {
  market: IIncome;
  onRemove: (market: IIncome) => void;
  onEdit: (market: IIncome) => void;
}

class IncomeActionsCell extends PureComponent<IProps> {
  renderDropDownContent = (income: IIncome): React.ReactNode => {
    const { onRemove, onEdit } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => {
            onEdit(income);
          }}
          className={'drop-down__item'}
          variant="empty"
        >
          Edit
        </Button>
        <Button
          onClick={() => {
            onRemove(income);
          }}
          variant="empty"
          className={cn('drop-down__item', 'button--remove')}
        >
          Remove
        </Button>
      </Fragment>
    );
  };

  render() {
    const { market } = this.props;
    return <DropDown>{this.renderDropDownContent(market)}</DropDown>;
  }
}

export default IncomeActionsCell;
