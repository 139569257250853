import { useTypedSelector } from './useTypedSelector';

export const useAllocationColors = () => {
  const { sectors, currency, riskAssetClasses, countries } = useTypedSelector((state) => ({
    sectors: state.sectors.data,
    currency: state.currency.data,
    riskAssetClasses: state.riskAssetClasses.data,
    countries: state.country.data,
  }));

  return {
    sectors: (sectors as unknown) as IAllocation[],
    currency: (currency as unknown) as IAllocation[],
    riskAssetClasses: (riskAssetClasses as unknown) as IAllocation[],
    countries: (countries as unknown) as IAllocation[],
  };
};
