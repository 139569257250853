import moment from 'moment';
import React, { FormEvent, FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderDatePicker, renderInput } from '../../../../../../../../components/ReduxForm';
import renderAutocomplete from '../../../../../../../../components/ReduxForm/renderAutocomplete';
import { Button, FormFieldWrapper } from '../../../../../../../../components/UIWidgets';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

import { DATE_FORMAT } from '../../../../../../../../services/constants/constants';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import firstOrDefault from '../../../../../../../../services/utils/firstOrDefault';
import { floatNumber, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { required } from '../../../../../../../../services/utils/FormValidations';
import { IIncomeForm } from './IIncomeForm';
import s from './IncomeForm.module.css';

interface IOwnProps {
  instrumentCurrency: number;
  income: IIncome | null;
  currencyOptions: Array<IOption<ICurrency>>;
}

type IProps = IOwnProps & InjectedFormProps<IIncomeForm, IOwnProps>;

function defaultFilter<T>(filter: string, option: IOption<T>) {
  return option.name.toLowerCase().includes(filter.toLowerCase());
}

const IncomeForm: FunctionComponent<IProps> = (props) => {
  const { income, currencyOptions, instrumentCurrency, invalid, handleSubmit, reset, initialize } = props;

  const defaultCurrencyOption = useMemo(() => {
    if (!currencyOptions.length) {
      return undefined;
    }
    return firstOrDefault<IOption<ICurrency>>(currencyOptions, (option) => option.id === instrumentCurrency);
  }, [currencyOptions, instrumentCurrency]);

  useEffect(() => {
    if (!income || !currencyOptions) {
      initialize({
        date: undefined,
        amount: undefined,
        currency: defaultCurrencyOption,
      });
      return;
    }

    const currencyId = income.currency && income.currency.id;
    const currencyOption = firstOrDefault<IOption<ICurrency>>(currencyOptions, (option) => option.id === currencyId);

    initialize({
      date: moment(income.businessDate, 'YYYY-MM-DD').format(DATE_FORMAT),
      amount: income.amount,
      currency: currencyOption,
    });
    return () => {
      reset();
    };
  }, [income, currencyOptions]);

  const handlerSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit(event);
      reset();
    },
    [handleSubmit, reset]
  );

  return (
    <form onSubmit={handlerSubmit} className={s.form}>
      <FormFieldWrapper label="Transaction date" required={true}>
        <Field name="date" className="input--small" type="text" component={renderDatePicker} validate={[required]} />
      </FormFieldWrapper>
      <FormFieldWrapper label="Income" required={true}>
        <Field
          name="amount"
          placeholder="100.00"
          component={renderInput}
          validate={[required]}
          className="input--small"
          normalize={(value: number) => positiveNumber(floatNumber(value))}
        />
      </FormFieldWrapper>

      <FormFieldWrapper label="Income currency" required={true}>
        <Field
          name="currency"
          component={renderAutocomplete}
          validate={[required]}
          options={currencyOptions}
          filter={defaultFilter}
          inputProps={{
            className: 'input--small',
          }}
        />
      </FormFieldWrapper>
      <Button className={s.formButton} type="submit" size="small" disabled={invalid}>
        {income ? 'Update' : 'Add'}
      </Button>
    </form>
  );
};

export default reduxForm<IIncomeForm, IOwnProps>({
  form: FORMS_NAME.instrumentIncome,
})(IncomeForm);
