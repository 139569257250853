import React, { Fragment, PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import ReactTooltip from 'react-tooltip';

import s from './VolatilityScoreChart.module.css';

import { getChartOptions } from './VolatilityScoreChartConfig';

import { IPortfolioVolatilityData } from '../../interfaces';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';

interface IProps {
  data: IPortfolioVolatilityData;
  portfolioBenchmarkIndexName: string;
  chartTitle: string;
  helpText?: string;
}

class VolatilityScoreChart extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const chartOpt = getChartOptions(this.props.data);
    const dataQualityCat = this.props.data.portfolio.dataQuality;
    const portfolioRiskBand = this.props.data.portfolio.riskBand; // was used to be displayed in the center of needle. to be removed when completely confirmed.
    const benchmarkIdx = this.props.portfolioBenchmarkIndexName;

    const esmaColors = ['#3863ff', '#41d9a0', '#00b26f', '#d9bf41', '#fb9900', '#e17300', '#e13800', '#a50009'];

    const chartTitle = this.props.chartTitle;

    if (dataQualityCat === 'C') {
      return <h3>Not sufficient data for calculating the Risk Score.</h3>;
    }
    return (
      <Fragment>
        <h3 style={{ textAlign: 'center' }}>
          {chartTitle}
          <ContextHelpIcon title={chartTitle} elementId={chartTitle} />
        </h3>
        <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={this.props.helpText} />
        <div className={s.scoreChartContainer}>
          <ReactEcharts option={chartOpt} notMerge={true} lazyUpdate={true} className={s.gaugeChart} />
          <div className={s.volatilityScoreLabel} style={{ color: esmaColors[portfolioRiskBand] }}>
            {portfolioRiskBand}
          </div>
          <div data-tip={''} data-for={'benchmarkIndexName'} className={s.indexScoreTooltipSpot} />
          <ReactTooltip textColor={'#171b24'} backgroundColor={'#a9b2d1'} id={'benchmarkIndexName'}>
            <strong>Benchmark:</strong>
            <br />
            <span>{benchmarkIdx}</span>
          </ReactTooltip>
        </div>
      </Fragment>
    );
  }
}

export default VolatilityScoreChart;
