import { IRootState } from '../../../../../../services/store';

export const getFxConversionLabel = (
  currencies: IRootState['currency']['data'],
  baseCurrencyCode?: string | null,
  instrumentCurrencyCode?: string | null,
  fxRate?: number
) => {
  if (!baseCurrencyCode || !instrumentCurrencyCode || !fxRate || baseCurrencyCode === instrumentCurrencyCode) {
    return '';
  }
  const instrumentCurrencyHierarchy = currencies?.find((value) => value.name === instrumentCurrencyCode)?.hierarchy;
  const baseCurrencyHierarchy = currencies?.find((value) => value.name === baseCurrencyCode)?.hierarchy;

  const baseFirst = (baseCurrencyHierarchy ?? 0) < (instrumentCurrencyHierarchy ?? 0);
  return `1${baseFirst ? baseCurrencyCode : instrumentCurrencyCode} ~ ${String(fxRate).substr(0, 5)}${
    !baseFirst ? baseCurrencyCode : instrumentCurrencyCode
  }`;
};
