import ReactEcharts from 'echarts-for-react';
import React, { PureComponent } from 'react';

import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { getPerformanceChartOptions } from './performanceChartOptions';

interface IProps {
  portfolioPerformance: IPerformanceData;
  recommendedPerformance: IPerformanceData;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class PerformanceChart extends PureComponent<IProps> {
  private _chartRef: echarts.ECharts | null = null;

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { portfolioPerformance, recommendedPerformance } = this.props;
    if (
      this._chartRef &&
      (prevProps.portfolioPerformance !== portfolioPerformance ||
        prevProps.recommendedPerformance !== recommendedPerformance)
    ) {
      (this._chartRef as echarts.ECharts).dispatchAction({
        type: 'dataZoom',
        start: 0,
        end: 100,
      });
    }
  }

  render() {
    const { portfolioPerformance, recommendedPerformance, portfolioCurrencyFormatter } = this.props;
    return (
      <ReactEcharts
        ref={(e) => {
          this._chartRef = e && (e as any).getEchartsInstance();
        }}
        style={{ height: '420px', width: '100%', flexGrow: '1' }}
        option={getPerformanceChartOptions(portfolioPerformance, recommendedPerformance, portfolioCurrencyFormatter)}
      />
    );
  }
}

export default PerformanceChart;
