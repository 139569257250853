import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { history } from './services/store/history';
import App from './App';
import React from 'react';
import { API_URL } from './services/constants/endpoints';
import { persistor, store } from './index';
import { Amplify } from 'aws-amplify';
import awsconfig, { COGNITO_USER_POOLS_ID, COGNITO_USER_POOLS_WEB_CLIENT_ID } from './aws-env-config';
import { fetchRetry } from './services/utils/fetchRetry';
import { reduxErrorStatusHandler } from './services/utils/ReduxHelper';
import { AccountProvider } from './services/context/AuthContext';
import { PreferencesContextProvider } from './services/context/PreferencesContext';
import { PeriodContextProvider } from './services/context/PeriodContext';
import { ModalContextProvider } from './services/context/ModalContext';
import { CookieStorage } from '@aws-amplify/auth';

const stripePromise = loadStripe('pk_test_OW5j9ZYlt5LE8sAoVi6uzNpz00QgI4CtKb');

const queryClient = new QueryClient();

const AppProvider = () => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchRetry(API_URL + 'api/public/frontend/properties', 200, 5, { method: 'GET' })
      .then()
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        } else {
          console.error('UNABLE TO LOAD POOL ID');
          response = { pid: COGNITO_USER_POOLS_ID, acid: COGNITO_USER_POOLS_WEB_CLIENT_ID };
          return response;
        }
      })
      .then((result) => {
        // @ts-ignore;
        const environment = window.environmentSettings.env;

        const isLocalhost = '${REACT_APP_ENVIRONMENT_NAME}' === environment;
        const domain = isLocalhost ? 'localhost' : environment ? environment + '.illio.com' : 'illio.com';

        console.log({ domain, environment });
        Amplify.configure({
          ...awsconfig,
          aws_user_pools_id: result.pid,
          aws_user_pools_web_client_id: result.acid,
          cookieStorage: {
            domain,
          },
        });
        setLoaded(true);
      })
      .catch((error) => {
        reduxErrorStatusHandler(error);
      });
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <Elements stripe={stripePromise}>
            <Router>
              <ConnectedRouter history={history}>
                <AccountProvider>
                  <PreferencesContextProvider>
                    <ModalContextProvider>
                      <PeriodContextProvider>{loaded && <App />}</PeriodContextProvider>
                    </ModalContextProvider>
                  </PreferencesContextProvider>
                </AccountProvider>
                <ToastContainer />
              </ConnectedRouter>
            </Router>
          </Elements>
        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
      </QueryClientProvider>
    </Provider>
  );
};

// Enable hot reloading
// export default hot(module)(AppProvider);
export default AppProvider;
