import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

interface IProps {
  label?: string;
  disabled?: boolean;
}

const FXRate: FC<IProps> = ({ label = '', disabled }) => {
  return (
    <FormFieldWrapper label={`FX ${label || 'Rate'}`} required={true} className={s.mediumField}>
      <Field
        name="fxRate"
        placeholder="1"
        theme="inverse"
        component={renderInput}
        disabled={disabled}
        className="input--small"
        normalize={(value: number) => {
          return positiveNumber(floatNumberV2(value, 7)).toString();
        }}
      />
    </FormFieldWrapper>
  );
};

export default FXRate;
