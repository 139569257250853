import moment from 'moment';
import React, { Fragment, useRef } from 'react';

import withBasesInfo, { IWithBasesInfoProps } from '../../../../../../components/HOC/withBasesInfo';

import {
  IPortfolioErrorsTrade,
  IPortfolioTrade,
  IPortfolioValidatedTrade,
} from '../../../../../../models/IPortfolioTrade';
import { DATE_FORMAT, TRANSIT_TRADE_CONFIRM } from '../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { Utils } from '../../../../../../services/utils/Utils';
import { DeleteCell, StatusCell, TypeCell } from './components';
import ValueCell from './components/ValueCell';
import s from './TempBlotterTable.module.scss';
import { NotificationType } from '../../../../../../models/NotifictionType';
import { Checkbox, Table } from 'rsuite';
import { TradeTypeChanger } from './components/TradeTypeChanger';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import BlotterSubmissionButtons from './components/BlotterSubmissionButtons';
import { PusherHandler } from 'src/pusher-env-config';
import { sendRequest } from 'src/services/hooks/useApi';
import { BASE_URL } from 'src/services/constants/endpoints';
import { Loader } from 'src/components';
import { useDispatch } from 'react-redux';
import { PortfolioActions, SnapshotActions } from 'src/services/actions';
import debounce from 'lodash.debounce';
import { AppActions } from 'src/services/actions/AppActions';
import { useIframeMode } from 'src/services/hooks/useIframeMode';
import { PATHS } from 'src/router/paths';

interface IOwnProps {
  baseCurrency: ICurrency;
  editable: boolean;
  trades: IPortfolioValidatedTrade[];
  tradesOrder: IOrder;
  totalTrades: number;
  // onLoadData: (page: number) => void;
  onEditTransaction: (trade: IPortfolioTrade, errors: IPortfolioErrorsTrade[]) => void;
  onRemoveTransaction: (trade: IPortfolioTrade) => void;
  onSortData: (order: IOrder) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  // selectedTrade: IPortfolioTrade;
  showNotification: (notification: INotification) => void;
}

type IProps = IOwnProps & IWithBasesInfoProps & RouteComponentProps<{ portfolioId: string }>;

const noop = () => {};

const TempBlotterTable = ({
  trades,
  onEditTransaction = noop,
  onRemoveTransaction = noop,
  portfolioCurrencyFormatter,
  match,
  totalTrades,
  tradesOrder,
  onSortData,
  // onLoadData,
  showNotification,
  baseCurrency,
  // selectedTrade,
  assetsClasses,
}: IProps) => {
  const [selectedKeys, setSelectedKeys] = React.useState<Record<string, boolean>>({});
  const [dirtyKeys, setDirtyKeys] = React.useState<Record<string, boolean>>({});
  const [tradeIdsLength, setTradeIdsLength] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);
  const portfolioId = match.params.portfolioId;
  const dispatch = useDispatch();
  const history = useHistory();

  const iframeMode = useIframeMode();
  React.useEffect(() => {
    PusherHandler.addListener(portfolioId, TRANSIT_TRADE_CONFIRM, (data: any) => {
      console.log({ message: data });
      setLoading(false);
      dispatch(
        AppActions.showNotification({ text: data.message + ' trades confirmed', type: NotificationType.SUCCESS })
      );
      dispatch(PortfolioActions.fetchPortfolioInfo(portfolioId!));
      dispatch(PortfolioActions.fetchPortfolioStructure(portfolioId!));
      if (iframeMode) {
        window.opener?.postMessage?.('close-window-from-iframe-with-success', '*');
      } else {
        history.push(PATHS.portfolio.snapshot.path.replace(':portfolioId', portfolioId!));
      }
    });
  }, [portfolioId]);

  React.useEffect(() => {
    const newKeys = trades.map((trade) => trade.trade.key ?? '');
    const tempSelectedKeys = { ...selectedKeys };

    trades.forEach((t) => {
      const k = t.trade.key!;
      if (!dirtyKeys[k]) {
        tempSelectedKeys[k] = !t?.errors?.length;
      }
    });

    const newSelectedKeys = { ...tempSelectedKeys };

    Object.entries(tempSelectedKeys).forEach(([key]) => {
      if (!newKeys.includes(key)) {
        delete newSelectedKeys[key];
      }
    });

    setSelectedKeys(newSelectedKeys);
  }, [trades]);

  const handleEditTransaction = (rowData: IPortfolioTrade & { index: number }) => {
    const { errors } = trades[rowData.index];
    // set flag here
    if (rowData.instrument?.name?.includes('DRAFT')) {
      showNotification({
        text: `The instrument is being initialised, waiting for response from data provider`,
        type: NotificationType.ERROR,
      });
    } else {
      onEditTransaction(rowData, errors);
    }
  };

  const tradesWithoutErrors = trades.map((trade, index) => ({ ...trade.trade, index }));

  const toggleKey = (key: string) => () => {
    setDirtyKeys((prev) => ({ ...prev, [key]: true }));
    setSelectedKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onConfirm = async (tradeIds: number[]) => {
    setLoading(true);
    setTradeIdsLength(tradeIds.length);
    await sendRequest(`${BASE_URL}/api/v3/portfolio/${portfolioId}/trades/confirm`, {
      method: 'POST',
      data: { tradeIds: tradeIds },
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={s.blotterTableSurround}>
      <BlotterSubmissionButtons onSubmitTrades={onConfirm} selectedKeys={selectedKeys} />
      <Table
        style={{ backgroundImage: 'var(--gradient-dark)', marginBottom: 90 }}
        data={tradesWithoutErrors}
        onRowClick={handleEditTransaction}
        autoHeight={true}
        rowHeight={28}
        headerHeight={30}
        rowClassName={s.clear}
        className={s.blotterTable}
        sortColumn={tradesOrder.name}
        sortType={tradesOrder.direction.toLowerCase() as 'asc' | 'desc'}
        onSortColumn={(dataKey, sortType) =>
          onSortData({ name: dataKey, direction: sortType.toUpperCase() as 'ASC' | 'DESC' })
        }
      >
        <Table.Column sortable={false} fixed={'left'} flexGrow={0} width={30} minWidth={30}>
          <Table.HeaderCell />
          <Table.Cell dataKey={'tradeTime'}>
            {(data: IPortfolioTrade & any) => {
              const hasErrors = !!trades[data.index]?.errors?.filter((error) => error.status === 'ERROR').length;
              if (hasErrors) {
                return null;
              }

              return (
                <Checkbox
                  style={{ top: 0, left: 0 }}
                  checked={!!data.key && selectedKeys[data.key] && !hasErrors}
                  onClick={hasErrors ? undefined : toggleKey(data.key!)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} fixed={'left'} flexGrow={0} width={90} minWidth={90}>
          <Table.HeaderCell>Trade Date</Table.HeaderCell>
          <Table.Cell dataKey={'tradeTime'}>
            {(data: IPortfolioTrade) => <div>{moment(data.tradeTime).format(DATE_FORMAT)}</div>}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={false} fixed={'left'} flexGrow={0} width={100} minWidth={100}>
          <Table.HeaderCell>Instrument Type</Table.HeaderCell>
          <Table.Cell dataKey={'assetType'}>
            {(trade: any) =>
              trade.checkedOut ? (
                <div>{trade.assetSubClass?.assetClassId === 'Funds' ? 'Funds' : trade.assetSubClass?.name}</div>
              ) : (
                <Link
                  to={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <TradeTypeChanger trade={trade} handleEditTransaction={handleEditTransaction} />
                </Link>
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} fixed={'left'} flexGrow={3} width={200} minWidth={160}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey={'displayId'} />
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.Cell dataKey={'operationName'}>
            {(data: IPortfolioTrade) => <TypeCell type={data.operationName!} />}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={false} width={60} flexGrow={1}>
          <Table.HeaderCell>Qty</Table.HeaderCell>
          <Table.Cell dataKey={'displayQuantity'}>
            {(data: IPortfolioTrade) => (
              <div>
                {`${data.quantity?.toLocaleString(undefined, {
                  maximumFractionDigits: Utils.getQuantityDecimals(assetsClasses, data.instrument.assetClassId),
                })} ${data.assetSubClass?.id === 'CshFXSpot' ? data.currency : ''}`}
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Cost Price</Table.HeaderCell>
          <Table.Cell dataKey={'price'}>
            {(data: IPortfolioTrade) => (
              <div>
                {data.price?.toLocaleString(undefined, {
                  maximumFractionDigits: Utils.getPriceDecimals(assetsClasses, data.instrument.assetClassId),
                })}
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Settlement</Table.HeaderCell>
          <Table.Cell dataKey={'currency'}>
            {(data: IPortfolioTrade) => <div>{getSettlementLabel(data, baseCurrency.name)}</div>}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Other Qty</Table.HeaderCell>
          <Table.Cell dataKey={'otherQuantity'} />
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Multiplier</Table.HeaderCell>
          <Table.Cell dataKey={'multiplier'} />
        </Table.Column>
        <Table.Column sortable={true} width={80} flexGrow={1}>
          <Table.HeaderCell>Gross Value</Table.HeaderCell>
          <Table.Cell dataKey={'amount'}>
            {(data: IPortfolioTrade) => <div>{data.amount?.toLocaleString()}</div>}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Other Charge</Table.HeaderCell>
          <Table.Cell dataKey={'tradeCosts'} />
        </Table.Column>
        <Table.Column sortable={true} width={60} flexGrow={1}>
          <Table.HeaderCell>Commis'n</Table.HeaderCell>
          <Table.Cell dataKey={'commission'} />
        </Table.Column>

        <Table.Column sortable={true} width={100} flexGrow={1}>
          <Table.HeaderCell>Base FX</Table.HeaderCell>
          <Table.Cell dataKey={'displayBaseFx'}>
            {(data: IPortfolioTrade) => {
              if (data.displayBaseFx === null) {
                return <p className={s.textWarning}>(?)</p>;
              }
              return <div>{data.displayBaseFx?.toLocaleString(undefined, { maximumFractionDigits: 6 })}</div>;
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={80} flexGrow={1} fixed={'right'}>
          <Table.HeaderCell>Value ({baseCurrency.name})</Table.HeaderCell>
          <Table.Cell dataKey={'netAmountBase'}>
            {(data: IPortfolioTrade) => (
              <ValueCell isSell={data.operation === 'SELL'} isCurrency={true} value={data.netAmountBase || 0} />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={100} flexGrow={1} fixed={'right'}>
          <Table.HeaderCell>Custodian</Table.HeaderCell>
          <Table.Cell dataKey={'custodian'}>{(data: IPortfolioTrade) => <div>{data.custodian?.name}</div>}</Table.Cell>
        </Table.Column>
        <Table.Column sortable={true} width={200} flexGrow={1} fixed={'right'}>
          <Table.HeaderCell>Note</Table.HeaderCell>
          <Table.Cell dataKey={'notes'} />
        </Table.Column>
        <Table.Column sortable={false} width={20} flexGrow={1} fixed={'right'}>
          <Table.HeaderCell />
          <Table.Cell dataKey={'status'}>
            {(data: any) => {
              const { errors } = trades[data.index];

              const errorMessage = errors
                ? errors.map((error, index) => (index ? ' ' : '') + error.message).join(',')
                : '';

              return (
                <Fragment>
                  <div className={s.actionsContainer}>
                    <a title={errorMessage}>
                      <StatusCell errors={errors} />
                    </a>
                    <DeleteCell onRemoveTransaction={onRemoveTransaction} trade={data as IPortfolioTrade} />
                  </div>
                </Fragment>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>

      {trades.length > 5 && <BlotterSubmissionButtons selectedKeys={selectedKeys} />}

      {/*<pre style={{fontSize: 12}}>{JSON.stringify({selectedKeys}, null, 2)}</pre>*/}
    </div>
  );
};

const getSettlementLabel = (data: IPortfolioTrade, baseCurrency: string) => {
  if (data.tradeType?.id === 'CashAndEquivalents') {
    return data.operation === 'SELL' ? data.otherCurrency : data.currency;
  }

  return !data.settlementOption
    ? 'N/A'
    : data.settlementOption === 'BASE'
    ? baseCurrency
    : data.settlementOption === 'LOCAL'
    ? data.currency
    : 'AUTO';
};

export default withRouter(withBasesInfo(React.memo(TempBlotterTable)));
