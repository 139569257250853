import { Auth } from 'aws-amplify';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppActions } from '../../services/actions/AppActions';
import { IRootState } from '../../services/store';
import { AsyncActionDispatch } from '../../services/utils/ReduxHelper';
import UserSettings from './UserSettings';

interface IMapStateToProps {
  user: IUser | null;
  formUser: any;
}

interface IDispatchToProps {
  updateAccountInfo: (user: IUser) => void;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps;

const UserSettingsContainer: FC<IProps> = (props) => {
  const [successMessage, setSuccessMessage] = React.useState<string | undefined>();

  const dismissNotification = React.useCallback(() => {
    setSuccessMessage(undefined);
  }, []);

  const handleSubmit = async (details: IUser) => {
    const { firstName, lastName } = details;
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(currentUser, { name: firstName, family_name: lastName });
      props.updateAccountInfo(details);
      setSuccessMessage('Account settings changed');
    } catch (e) {
      alert("We're sorry, but an error occurred whilst trying to update your name");
    }
  };

  const { user, history, formUser } = props;
  return (
    <UserSettings
      user={formUser || user}
      onCancel={history.goBack}
      onSubmit={handleSubmit}
      successMessage={successMessage}
      dismissNotification={dismissNotification}
    />
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    user: state.app.authInfo.data,
    formUser: state.form.userForm ? state.form.userForm.values : null,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateAccountInfo: (user: IUser) => dispatch(AppActions.updateAccountInfo(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSettingsContainer));
