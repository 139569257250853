import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardHeader } from '../scenes/PortfolioDashboard/components/DashboardHeader';
import PortfolioHeader from '../../../components/PortfolioHeader/PortfolioHeader';
import { TopBottomTable } from './components/TopBottomTable';
import { Card } from '@iliotech/storybook/build/components/Card/Card';
import { PnlByInstrumentChartContainer } from '../scenes/PortfolioDashboard/components/PnlByInstrumentChartContainer';
import { useRouteMatch } from 'react-router-dom';
import PortfolioUpcomingEvents from '../../../components/PortfolioUpcomingEvents/PortfolioUpcomingEvents';
import { usePortfolioSnapshot } from '../../../../../services/hooks/apiHooks/usePortfolioSnapshot';
import useApi from '../../../../../services/hooks/useApi';
import { getCustomApiUrl } from '../../../../../services/constants/endpoints';
import CumulativePnLChartContainer from '../scenes/AssetsDashboard/scenes/Equilty/scenes/PnlBreakdownDetail/components/CumulativePnL/CumulativePnLChartContainer';
import StockVsBuysChartContainer from '../scenes/AssetsDashboard/scenes/Equilty/scenes/PnlBreakdownDetail/components/StockVsBuysChart/StockVsBuysChartContainer';
import { usePortfolioInfo } from '../../../../../services/hooks/usePortfolioInfo';
import useCurrentWidth from '../../../../../services/hooks/useCurrentWidth';
import { useSafariHack } from '../../../../../services/hooks/useSafariHack';

export const PortfolioDashboard = () => {
  const { params } = useRouteMatch<{ portfolioId: string }>();
  const [selectedPosition, setSelectedPosition] = useState<IPortfolioSnapshot | null>(null);
  const [assetClass, setAssetClass] = useState('');
  const { portfolioId } = params;
  const portfolioInfo = usePortfolioInfo();
  const snapshotsQuery = usePortfolioSnapshot();
  const snapshots = snapshotsQuery.data?.content ?? [];

  const { flex } = useSafariHack();
  const setSelectedNonCashPosition = (p: IPortfolioSnapshot | null) => {
    if (p?.assetClassId !== 'CashAndEquivalents') {
      setSelectedPosition(p);
    }
  };
  const topPositions = snapshots
    .sort((a, b) => (b.profitAndLoss ?? 0) - (a.profitAndLoss ?? 0))
    .slice(0, 5)
    .filter((position) => (position.profitAndLoss ?? 0) > 0);
  const bottomPositions = snapshots
    .sort((a, b) => (a.profitAndLoss ?? 0) - (b.profitAndLoss ?? 0))
    .slice(0, 5)
    .filter((position) => (position.profitAndLoss ?? 0) < 0);

  const breakdownPositionReport = useApi(
    getCustomApiUrl('portfolio.profit.breakdownPosition', [
      { idLabel: ':portfolioId', idValue: portfolioId },
      { idLabel: ':positionId', idValue: selectedPosition?.positionId || '' },
    ]),
    { method: 'get', responseType: 'json' },
    { enabled: false }
  );

  useEffect(() => {
    if ((selectedPosition?.positionId || '').length > 0) {
      breakdownPositionReport.refetch();
    }
  }, [selectedPosition]);

  return (
    <div>
      <PortfolioHeader />
      <DashboardHeader page={'P&L Overview'} hideCustodianFilter={true} />
      <div style={{ display: flex }}>
        <div style={{ flex: 1, marginRight: '3rem' }}>
          <div
            style={{
              display: 'flex',
              // flexDirection: "column"
            }}
          >
            <div style={{ flex: 1, marginRight: '1rem' }}>
              <TopBottomTable
                data={topPositions}
                onSelectPosition={setSelectedNonCashPosition}
                loading={snapshotsQuery.isFetching}
                title={'Top 5 Movers by P&L'}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TopBottomTable
                data={bottomPositions}
                onSelectPosition={setSelectedNonCashPosition}
                loading={snapshotsQuery.isFetching}
                title={'Bottom 5 Movers by P&L'}
              />
            </div>
          </div>
          <Card style={{ marginTop: '1rem' }}>
            <PnlByInstrumentChartContainer portfolioId={portfolioId} onSelectPosition={setSelectedPosition} />
          </Card>
        </div>
        <div style={{ height: '100%', overflow: 'hidden' }}>
          <PortfolioUpcomingEvents
            deselectPosition={() => setSelectedPosition(null)}
            selectedPosition={selectedPosition}
          />
        </div>
      </div>
      {breakdownPositionReport.isFetched && (
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <Card style={{ flex: 1, marginRight: 10 }}>
            <StockVsBuysChartContainer
              stockPricesData={breakdownPositionReport?.data?.stockPrices}
              income={breakdownPositionReport?.data?.income}
              splitsData={breakdownPositionReport?.data?.splits}
              buysAndSellsData={breakdownPositionReport?.data?.buysAndSells}
              baseCurrency={breakdownPositionReport?.data?.currency}
              earnEventsData={breakdownPositionReport?.data?.earns}
              instrumentCurrency={breakdownPositionReport?.data?.portfolioCurrency}
              instrumentName={breakdownPositionReport?.data?.instrumentName}
              assetClassId={assetClass}
            />
          </Card>
          <Card style={{ flex: 1, marginLeft: 10 }}>
            <CumulativePnLChartContainer
              cumulativePnl={breakdownPositionReport.data.cumulativePnl}
              baseCurrency={portfolioInfo.portfolioCurrency}
              baseCurrencySymbol={portfolioInfo.portfolioSymbol}
              instrumentName={breakdownPositionReport.data.instrumentName}
              assetClassId={assetClass}
            />
          </Card>
        </div>
      )}
    </div>
  );
};
