import React, { PureComponent, ReactElement, Fragment } from 'react';
import cn from 'classnames';

import s from './Tabs.module.scss';

interface IProps {
  activeTabIndex: number;
  children: any;
  className: string;
  onTabSelected: (selectedId: number) => void;
}

interface IState {
  activeTabIndex: number;
}

class Tabs extends PureComponent<IProps, IState> {
  static readonly defaultProps: Partial<IProps> = {
    activeTabIndex: 0,
    children: null,
    className: '',
    onTabSelected: (id: number) => {},
  };

  readonly state: IState = {
    activeTabIndex: 0,
  };

  componentDidMount(): void {
    const { activeTabIndex } = this.props;
    if (activeTabIndex) {
      this.setState({ activeTabIndex });
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { activeTabIndex } = this.props;
    if (prevProps.activeTabIndex !== activeTabIndex) {
      this.setState({ activeTabIndex });
    }
  }

  handleTabClick = (tabIndex: number) => {
    this.setState({
      activeTabIndex: tabIndex === this.state.activeTabIndex ? this.props.activeTabIndex : tabIndex,
    });
    this.props.onTabSelected(tabIndex);
  };

  renderTabs() {
    return React.Children.map(this.props.children, (child: ReactElement, index) => {
      return (
        child &&
        React.cloneElement(child, {
          ...child.props,
          onSelect: () => {
            if (child.props.onSelect) {
              child.props.onSelect();
            }
            this.handleTabClick(index);
          },
          tabIndex: index,
          isActive: index === this.state.activeTabIndex,
        })
      );
    });
  }

  // Render current active tab content
  renderActiveTabContent() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children && children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  }

  render() {
    if (!this.props.children.length) {
      return <Fragment />;
    }
    const { children, className } = this.props;
    const { activeTabIndex } = this.state;

    const hasTabChildren: boolean = (children[activeTabIndex] && children[activeTabIndex].props.children) || false;
    const activePanel: ReactElement = children[activeTabIndex];
    return (
      <div className={cn(s.tabsWrapper, className)}>
        <ul className={s.tabsList}>{this.renderTabs()}</ul>
        {hasTabChildren && (
          <div className={cn(s.tabsContent, { [s.contentWrapper]: activePanel.props.wrapContent })}>
            {this.renderActiveTabContent()}
          </div>
        )}
      </div>
    );
  }
}

export default Tabs;
