import React from 'react';
import { useExplorerContext } from '../../../../../../../services/context/ExplorerContext';
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
} from '@progress/kendo-react-charts';

import 'hammerjs';
import cn from 'classnames';
import s from './StructureDonut.module.scss';
import { useSubClassLabel } from '../../../../../../../services/hooks/useSubClassLabel';
import { ASSET_LABEL_NAMES } from '../../../../../../../services/constants/assetClasses';
import { usePortfolio } from '../../../../../../../services/hooks/usePortfolio';
import { GrPowerReset } from 'react-icons/gr';
import { Button } from 'rsuite';

interface IProps {
  donutMap: IDonutMap;
  expanded: boolean;
}
const DEFAULT_SUBTYPE = { subClassId: null, subSubClassId: null };

const StructureDonut = ({ expanded, donutMap }: IProps) => {
  const { selectedAllocationType, setSelectedAllocationSubType, selectedAllocationSubType } = useExplorerContext();
  const { convertIdToLabel } = useSubClassLabel();
  const { portfolioCurrencyFormatter } = usePortfolio();

  const isDefaultSubtype = (!["AssetClass", "Region"].includes(selectedAllocationType ?? "")) || (!selectedAllocationSubType.subClassId && !selectedAllocationSubType.subSubClassId);

  const selectedAllocations: DonutMapEntry[] | undefined = React.useMemo(() => {
    return selectedAllocationType ? donutMap[selectedAllocationType] : [];
  }, [selectedAllocationType, donutMap]);

  const data = React.useMemo(() => {
    if (
      (selectedAllocationType === 'AssetClass' || selectedAllocationType === 'Region') &&
      selectedAllocationSubType.subClassId
    ) {
      const toRet: DonutMapEntry[] = [];
      selectedAllocations
        ?.filter((item) => item.name === selectedAllocationSubType.subClassId)
        .forEach((item: DonutMapEntry) => {
          if (item.subClasses?.length) {
            toRet.push(...item.subClasses);
          }
        });
      return toRet;
    }

    return selectedAllocations?.map((item, i) => ({
      ...item,
    }));
  }, [selectedAllocationType, selectedAllocations, selectedAllocationSubType]);
  const labelColor = (e: any) => {
    const color: string = e.dataItem.percentage > 0 ? 'var(--text-success-color)' : 'var(--text-alert-color)';
    return color;
  };

  const labelContent = (e: any) => {
    // it it is subClass we convert the id to label
    if (e.dataItem.type === 'AssetSubClass') {
      return convertIdToLabel!(e.category);
    } else if (e.dataItem.type === 'AssetClass' && e.value > 2) {
      return ASSET_LABEL_NAMES[e.category];
    }

    const label = e.category.length > 12 ? e.category.substr(0, 12) + '...' : e.category;
    return e.value > 2 || e.value < -2 ? label : '';
  };

  const renderTooltip = (context: any) => {
    const { dataItem, category } = context.point || context;
    const label = () => {
      if (dataItem.type === 'AssetClass') {
        return ASSET_LABEL_NAMES[category];
      }
      // it it is subClass we convert the id to label
      if (dataItem.type === 'AssetSubClass') {
        return convertIdToLabel!(category);
      } else {
        return category;
      }
    };

    const value = () => {};

    return (
      <div>
        <span>
          {label()}: {dataItem.percentage.toLocaleString(undefined, { maximumFractionDigits: 2 })}%
        </span>
        <br />
        <span>Value: {portfolioCurrencyFormatter(dataItem.value)}</span>
      </div>
    );
  };

  const renderCenter = () => {
    const showBackButton = JSON.stringify(data) !== JSON.stringify(selectedAllocations);
    if (isDefaultSubtype || !showBackButton) {
      return <span style={{ color: 'white' }}>{''}</span>;
    }
    return (
      <Button onClick={() => setSelectedAllocationSubType(DEFAULT_SUBTYPE)} style={{ borderRadius: 20 }}>
        <GrPowerReset style={{ filter: 'invert(1)' }} />
      </Button>
    );
  };

  const nav =
    !!donutMap && selectedAllocationType
      ? donutMap[selectedAllocationType]?.reduce((acc, category) => acc + category.value, 0)
      : '';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Chart
        transitions={false}
        className={cn(s.donutContainer, expanded && s.donutContainerExpanded)}
        donutCenterRender={renderCenter}
        onSeriesClick={(event) => {
          if (selectedAllocationType === 'AssetClass') {
            if (event.dataItem.type === 'AssetClass') {
              setSelectedAllocationSubType({ subClassId: event.category, subSubClassId: null });
            } else {
              setSelectedAllocationSubType({ subClassId: event.dataItem.id, subSubClassId: event.category });
            }
          } else if (selectedAllocationType === 'Region') {
            if (event.dataItem.type === 'Region') {
              setSelectedAllocationSubType({ subClassId: event.category, subSubClassId: null });
            } else {
              setSelectedAllocationSubType({ subClassId: event.dataItem.id, subSubClassId: event.category });
            }
          } else {
            setSelectedAllocationSubType({ subSubClassId: null, subClassId: event.category });
          }
        }}
      >
        <ChartTooltip render={renderTooltip} />
        <ChartArea background={'transparent'} />
        <ChartSeries>
          <ChartSeriesItem
            type="donut"
            data={data}
            margin={20}
            categoryField="name"
            field="percentage"
            border={{
              width: 1,
              color: 'var(--dark-blue)',
            }}
            overlay={{
              gradient: 'roundedBevel',
            }}
          >
            <ChartSeriesLabels
              position="outsideEnd"
              color={labelColor as any}
              background="none"
              content={labelContent}
            />
          </ChartSeriesItem>
          s
        </ChartSeries>
        <ChartLegend visible={false} />
      </Chart>
      <div style={{textAlign: "center"}}>
        {typeof nav !== 'undefined' ? (
          <strong>
            Net worth at end of period:{' '}
            <strong style={{ color: (nav ?? 0) > 0 ? 'var(--text-success-color)' : 'var(--text-alert-color)' }}>
              {portfolioCurrencyFormatter(parseFloat(String(nav)))}
            </strong>
          </strong>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(StructureDonut);
