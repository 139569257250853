import React, { Fragment, PureComponent } from 'react';
import { IScenarioItem } from '../interfaces';

import s from '../RiskScenarios.module.scss';
import investPic from '../../../../../static/images/dashboard/investV2.svg';
import BarChart from './BarChart';

interface IProps {
  selectedDataset: IScenarioItem[];
  totalMarketValueBase: number;
  bar1Correction: number;
  bar2Correction: number;
  bar3Correction: number;
  bar4Correction: number;
  bar5Correction: number;
  baseCurrencySymbol: string;
  baseCurrencyName: string;
  portfolioTotalWealth: number;
  isDataFromBackend: boolean;
}

class ChartLegend extends PureComponent<IProps> {
  render() {
    const {
      selectedDataset,
      bar1Correction,
      bar2Correction,
      bar3Correction,
      bar4Correction,
      bar5Correction,
      baseCurrencySymbol,
      baseCurrencyName,
      portfolioTotalWealth,
      isDataFromBackend,
    } = this.props;

    let calculatedPriceValue = 0;
    let calculatedFxValue = 0;
    let calculatedYieldValue = 0;
    let calculatedVolatilityValue = 0;

    let priceMultiplier1 = 0;
    let priceMultiplier2 = 0;
    let fxMultiplier = 0;
    let yieldMultiplier = 0;
    let volatilityMultiplier = 0;

    selectedDataset.forEach((element: IScenarioItem) => {
      calculatedPriceValue +=
        element.priceValue === undefined ? element.priceMultiplier1 * bar1Correction : element.priceValue;
      calculatedFxValue += element.fxValue === undefined ? element.fxMultiplier * bar2Correction : element.fxValue;
      calculatedYieldValue +=
        element.yieldValue === undefined ? element.yieldMultiplier * bar3Correction : element.yieldValue;
      calculatedVolatilityValue +=
        element.volatilityValue === undefined ? element.volatilityMultiplier * bar5Correction : element.volatilityValue;

      priceMultiplier1 += element.priceMultiplier1;
      priceMultiplier2 += element.priceMultiplier2;
      fxMultiplier += element.fxMultiplier;
      yieldMultiplier += element.yieldMultiplier;
      volatilityMultiplier += element.volatilityMultiplier;
    });

    let pnlImpactValueValue = 0;

    if (isDataFromBackend) {
      pnlImpactValueValue = calculatedPriceValue + calculatedFxValue; // + calculatedYieldValue + calculatedVolatilityValue;
    } else {
      calculatedPriceValue += calculatedYieldValue + calculatedVolatilityValue;
      pnlImpactValueValue =
        bar1Correction * priceMultiplier1 +
        bar1Correction * Math.abs(bar1Correction) * priceMultiplier2 +
        bar2Correction * fxMultiplier +
        bar3Correction * yieldMultiplier +
        bar5Correction * volatilityMultiplier;
    }

    const calculatedTotalPriceMove =
      bar1Correction * priceMultiplier1 + bar1Correction * Math.abs(bar1Correction) * priceMultiplier2;

    const percentageOfTotalWealth =
      Math.round((((calculatedPriceValue + calculatedFxValue) * 100) / portfolioTotalWealth + Number.EPSILON) * 100) /
      100;
    const sign = pnlImpactValueValue > 0 ? '+' : pnlImpactValueValue === 0 ? '' : '-';

    return (
      <Fragment>
        <div className={s.legendTitle}>
          <img className={s.legendIcon} src={investPic} /> P&L impact:&nbsp;&nbsp;&nbsp;
          <span className={pnlImpactValueValue >= 0 ? s.textGreen : s.textRed}>
            {sign + baseCurrencySymbol}
            {Math.abs(pnlImpactValueValue).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            {' (' + percentageOfTotalWealth + '% of portfolio)'}
          </span>
        </div>
        <div className={s.legendItem}>
          <div className={s.equityColor} />
          Price:&nbsp;
          {baseCurrencySymbol}
          {calculatedPriceValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </div>
        <div className={s.legendItem}>
          <div className={s.fxColor} />
          FX:&nbsp;
          {baseCurrencySymbol}
          {calculatedFxValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </div>
        {/* <div className={s.legendItem}>
          <div className={s.yieldColor} />
          Yield:&nbsp;
          {baseCurrencySymbol}
          {calculatedYieldValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </div>
        <div className={s.legendItem}>
          <div className={s.volatilityColor} />
          Volatility:&nbsp;
          {baseCurrencySymbol}
          {calculatedVolatilityValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </div>*/}
      </Fragment>
    );
  }
}

export default ChartLegend;
