import { ITableColumn } from '../../../../../../components/UIWidgets/TableWrapper/models';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import {
  explorerCurrencyRenderer,
  explorerDecimalNumberRenderer,
  explorerPercentageRenderer,
} from '../../../PortfolioExplorer/utils/explorerCurrencyRenderer';

export function getTopPositionsByPnLColumns(
  portfolioCurrencyFormatter: ICurrencyFormatter,
  defaultRiskAssetView?: 'INSTRUMENT_ASSET_CLASS' | 'RISK_ASSET_CLASS'
): ColumnProps[] {
  return [
    {
      label: 'Instrument Name',
      dataKey: 'instrumentName',
      flexGrow: 2,
      width: 430,
    },
    {
      label: 'Custodian',
      dataKey: 'custodianName',
      flexGrow: 2,
      width: 200,
    },
    {
      label: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'Risk Asset Class' : 'Asset Class',
      dataKey: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'riskAssetClass' : 'assetClassId',
      flexGrow: 2,
      width: 170,
    },
    {
      label: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'Investment Vehicle' : 'Asset SubClass',
      dataKey: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'investmentVehicle' : 'assetSubClass',
      flexGrow: 2,
      width: 200,
    },
    {
      label: `Current Value (${portfolioCurrencyFormatter()})`,
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
      dataKey: 'pnlMarketBase',
      flexGrow: 2,
      width: 200,
    },
    {
      label: `P&L (${portfolioCurrencyFormatter()})`,
      dataKey: 'pnlBase',
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,
      flexGrow: 2,
      width: 200,
    },
    /*{
      label: '% of portfolio P&L',
      dataKey: 'portfolioPercentage',
      flexGrow: 2,
      display: true,
      width: 200,
      disableSort: true,
    },*/
  ];
}

export function getTopPositionsColumns(
  portfolioCurrencyFormatter: ICurrencyFormatter,
  defaultRiskAssetView?: 'INSTRUMENT_ASSET_CLASS' | 'RISK_ASSET_CLASS'
): ColumnProps[] {
  return [
    {
      label: 'Instrument Name',
      dataKey: 'name',
      flexGrow: 2,
      width: 430,
    },
    {
      label: 'Custodian',
      dataKey: 'custodianName',
      flexGrow: 2,
      width: 200,
    },
    {
      label: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'Risk Asset Class' : 'Asset Class',
      dataKey: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'riskAssetClass' : 'assetClass',
      flexGrow: 2,
      width: 170,
    },
    {
      label: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'Investment Vehicle' : 'Asset SubClass',
      dataKey: defaultRiskAssetView === 'RISK_ASSET_CLASS' ? 'investmentVehicle' : 'assetSubClass',
      flexGrow: 2,
      width: 200,
    },
    {
      label: 'Local Amount',
      dataKey: 'marketValueLocal',
      align: 'right',
      cellRenderer: explorerDecimalNumberRenderer,

      flexGrow: 2,
      width: 200,
    },
    {
      label: `Value (${portfolioCurrencyFormatter()})`,
      dataKey: 'marketValueBase',
      flexGrow: 2,
      width: 200,
      cellRenderer: explorerDecimalNumberRenderer,
      align: 'right',
    },
    {
      label: '% of Portfolio',
      dataKey: 'portfolioPercentage',
      flexGrow: 2,
      width: 200,
      align: 'right',
      cellRenderer: explorerPercentageRenderer,
    },
  ];
}
