import React from 'react';
import cn from 'classnames';
import s from './PositionDetailRow.module.css';
import { Button, ButtonGroup, Checkbox, Input, InputNumber, Modal, Notification } from 'rsuite';
import dateFormat from 'dateformat';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { sendRequest } from '../../../../../../../../services/hooks/useApi';
import { API } from '../../../../../../../../services/constants/endpoints';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment-business-days';
import { DatePicker } from '../../../../../../../../components';

interface IOwnProps {
  position: IPortfolioSnapshot;
  fetchPositionsAndTransactions(): void;
}

interface IPositionUpdateValues {
  tradeTime: Date;
  quantity?: string;
  price?: string;
  fxRate?: string;
  settlementOption?: string;
  commission?: string;
  tradeCosts?: string;
  accruedInterestLocal?: number | null;
  settlementDate?: Date;
}

const capitaliseFirstLetter = (str?: string) =>
  !str ? '' : `${str.substr(0, 1).toUpperCase()}${str.substr(1).toLowerCase()}`;

const formatServerDate = (d: Date | string | number) => dateFormat(new Date(d), 'yyyy-mm-dd');

const DEFAULT_STATE = {
  tradeTime: new Date(),
  fxRate: '1',
  tradeCosts: '0',
  commission: '0',
  settlementOption: 'BASE',
  settlementDate: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
};

export const PositionEditor = ({ position, fetchPositionsAndTransactions }: IOwnProps) => {
  const [mode, setMode] = React.useState<'BUY' | 'SELL'>();
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false);
  const [tradeAdded, setTradeAdded] = React.useState(false);
  const [values, setValues] = React.useState<IPositionUpdateValues>({ ...DEFAULT_STATE, tradeTime: new Date() });
  const [saveAsDraft, setSaveAsDraft] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<{ message: string; status: 'Success' | 'Failed' | 'Staged' }>();

  const { portfolioInfo } = usePortfolio();

  const instrumentCurrency = position.currencyNative;
  const portfolioCurrency = portfolioInfo.data?.currency?.name;

  const history = useHistory();
  const fx = useFx();
  const convention = fx({ fromCurrencyName: instrumentCurrency, toCurrencyName: portfolioCurrency });
  const fxRate = convention.isConvention ? parseFloat(values.fxRate ?? '1') : 1 / parseFloat(values.fxRate ?? '1');

  const settlementOptions = React.useMemo(() => {
    const btns: Array<{ text: string; value: any; subText?: string }> = [
      { text: portfolioCurrency ?? 'NA', value: 'BASE' },
      { text: `TRANSFER`, value: 'AUTO' },
    ];

    if (instrumentCurrency && instrumentCurrency !== portfolioCurrency) {
      btns.splice(1, 0, { text: String(instrumentCurrency) ?? 'NA', value: 'LOCAL' });
    }
    return btns;
  }, []);

  React.useEffect(() => {
    // * Fetch FX rate when trade time or currencies change
    if (!values.tradeTime || !instrumentCurrency || !portfolioCurrency) {
      return;
    }
    const params = { date: formatServerDate(values.tradeTime), from: instrumentCurrency, to: portfolioCurrency };
    sendRequest(`/api/v1/${API.endpoints.snapshot.fxRate.get}`, { params }).then((result) => {
      if (typeof result?.firstFxRate === 'undefined') {
        return;
      }
      updateValue('fxRate')(result?.firstFxRate);
    });
  }, [values.tradeTime, instrumentCurrency, portfolioCurrency]);

  React.useEffect(() => {
    // * Fetch price and update settlement date when trade time changes
    if (position.assetClassId === 'FixedIncome') {
      updateValue('settlementDate')(moment(values.tradeTime).businessAdd(1).toDate());
    }
    sendRequest(`/api/v1/${API.endpoints.snapshot.price.getByInstrumentId}/${position.instrumentId}`, {
      params: { date: formatServerDate(values.tradeTime) },
    }).then((result) => {
      if (typeof result?.price === 'undefined') {
        return;
      }
      updateValue('price')(result?.price);
    });
  }, [values.tradeTime]);

  const updateMode = (newMode: 'BUY' | 'SELL') => (e: any) => {
    setMode(newMode);
  };

  const prepareCloseTrade = () => {
    const quantity = position.quantity ?? 0;
    if (quantity < 0) {
      setMode('BUY');
    } else {
      setMode('SELL');
    }
    updateValue('quantity')(Math.abs(quantity));
  };

  const reset = () => {
    setValues({ ...DEFAULT_STATE, tradeTime: new Date() });
    setTradeAdded(false);
  };

  const updateValue = <T extends keyof IPositionUpdateValues>(key: T) => (value: IPositionUpdateValues[T] | any) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const submit = () => {
    const data: any = {
      ...values,
      operation: mode,
      fxRate: Math.round(fxRate * 1000000) / 1000000,
      portfolioId: portfolioInfo.data?.id,
      positionId: position.positionId,
      tradeTime: formatServerDate(values.tradeTime),
      directAdd: !saveAsDraft,
    };

    if (position.assetClassId === 'FixedIncome') {
      data.accruedInterestLocal = typeof data.accruedInterestLocal === 'undefined' ? null : data.accruedInterestLocal;
      data.settlementDate = values.settlementDate ? formatServerDate(values.settlementDate) : undefined;
    } else {
      delete data.settlementDate;
      delete data.accruedInterestLocal;
    }

    sendRequest(`/api/v1/${API.endpoints.snapshot.trades.addFromPosition}`, { method: 'POST', data })
      .then((result) => {
        // console.log({result})
        setModalContent(result);
        if (result.status === 'Success') {
        }
        switch (result.status) {
          case 'Success':
            fetchPositionsAndTransactions();
            break;
          // case "Failed":
          //   setFailureModalContent({message: result.message});
          //   break;
          case 'Staged':
            setTradeAdded(true);
        }
        // Notification.success({ title: 'Trade added' });
      })
      .catch((error) => {
        Notification.error({ title: 'Unable to create trade', description: error?.message });
      });
  };

  const formIsInvalid = (values.quantity ?? 0) <= 0;

  const renderInputs = () => {
    if (!mode) {
      return null;
    }
    return (
      <div className={cn(s.horizontal)}>
        <table className={cn(s.inputsTable)}>
          <tbody>
            <tr>
              <td style={{ minWidth: 80 }}>Date</td>
              <td>
                <div className={s.datePickerWrapper}>
                  <DatePicker
                    value={values.tradeTime}
                    max={new Date()}
                    onChange={(date) => updateValue('tradeTime')(date.value)}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>{capitaliseFirstLetter(mode)} Quantity</td>
              <td>
                <InputNumber min={0} value={values.quantity} onChange={updateValue('quantity')} />
              </td>
            </tr>
            <tr>
              <td>Price</td>
              <td>
                <InputNumber value={values.price} onChange={updateValue('price')} />
              </td>
            </tr>
            <tr>
              <td />
              {!showAdvancedSettings && (
                <td
                  onClick={() => {
                    setShowAdvancedSettings(true);
                  }}
                  className={cn(s.moreSettings)}
                >
                  More settings
                </td>
              )}
            </tr>
          </tbody>
        </table>

        {showAdvancedSettings && (
          <div style={{ marginLeft: 20 }}>
            <table className={cn(s.inputsTable)}>
              <tbody>
                <tr>
                  <td style={{ minWidth: 150 }}>Commission</td>
                  <td>
                    <InputNumber value={values.commission} onChange={updateValue('commission')} />
                  </td>
                </tr>
                <tr>
                  <td>Other Charges</td>
                  <td>
                    <InputNumber value={values.tradeCosts} onChange={updateValue('tradeCosts')} />
                  </td>
                </tr>
                <tr>
                  <td>FX Rate {convention.getDescription({ conventionRate: parseFloat(values.fxRate ?? '1') })}</td>
                  <td>
                    <InputNumber value={values.fxRate} onChange={updateValue('fxRate')} />
                  </td>
                </tr>
                <tr>
                  <td>Settlement</td>
                  <td>
                    <ButtonGroup justified={true}>
                      {settlementOptions.map((option) => (
                        <Button
                          active={values.settlementOption === option.value}
                          onClick={() => {
                            updateValue('settlementOption')(option.value);
                          }}
                        >
                          {option.text}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </td>
                </tr>
                <tr>
                  <td>Save as draft</td>
                  <Checkbox
                    checked={saveAsDraft}
                    onClick={() => {
                      setSaveAsDraft((prev) => !prev);
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {showAdvancedSettings && position.assetClassId === 'FixedIncome' && (
          <div style={{ marginLeft: 20 }}>
            <table className={cn(s.inputsTable)}>
              <tbody>
                <tr>
                  <td style={{ minWidth: 80 }}>Settlement Date</td>
                  <td>
                    <div className={s.datePickerWrapper}>
                      <DatePicker
                        value={values.settlementDate}
                        onChange={(date) => updateValue('settlementDate')(date.value)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ minWidth: 150 }}>Accrued Interest</td>
                  <td>
                    <InputNumber
                      placeholder={'(Auto)'}
                      value={values.accruedInterestLocal ?? undefined}
                      onChange={updateValue('accruedInterestLocal')}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div style={{ marginLeft: 20, alignSelf: 'flex-end' }}>
          <Button onClick={submit} appearance={'primary'} disabled={formIsInvalid}>
            Save
          </Button>
        </div>
      </div>
    );
  };

  if (tradeAdded) {
    return (
      <div>
        Transaction prepared:{' '}
        <Button
          onClick={() =>
            history.push(
              generatePath('/portfolio/:portfolioId/transactions/add', { portfolioId: portfolioInfo.data?.id })
            )
          }
        >
          View Transaction
        </Button>{' '}
        or <Button onClick={reset}>Prepare another transaction</Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <label className={cn(s.label)}>Alter position</label>
        <div className={cn(s.horizontal)}>
          <div>
            <div>
              <Button className={cn(s.button, mode === 'BUY' && s.active)} onClick={updateMode('BUY')}>
                Buy
              </Button>
            </div>
            <div>
              <Button className={cn(s.button, mode === 'SELL' && s.active)} onClick={updateMode('SELL')}>
                Sell
              </Button>
            </div>
            <div>
              <div className={cn(s.button)} onClick={prepareCloseTrade}>
                Close Position
              </div>
            </div>
          </div>
          <div className={cn(s.inputsSurround, !!mode && s.open)}>{renderInputs()}</div>
        </div>
      </div>
      <Modal show={!!modalContent}>
        <Modal.Header>
          <Modal.Title>
            {modalContent?.status === 'Success'
              ? 'Trade Saved'
              : modalContent?.status === 'Staged'
              ? 'Draft trade saved'
              : modalContent?.status === 'Failed'
              ? 'Error Saving Trade'
              : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent?.message}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalContent(undefined);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
