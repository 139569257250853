export function getMockedScenarioData() {
  return {
    requiresBackendCalculations: false,
    totalMarketValueBase: -1188957731,
    baseCurrencyName: 'USD',
    baseCurrencySymbol: '$',
    assetClasses: {
      totalMarketValueBase: 1087885590,
      effectiveBeta: -1829099982,
      entries: [
        {
          priceMultiplier1: 1,
          priceMultiplier2: 1,
          yieldMultiplier: 1,
          fxMultiplier: 1,
          volatilityMultiplier: 1,
          name: 'eOMtThyhVNLWUZNRcBaQKxI',
          instruments: ['JxkyvRnL'],
          priceValue: -1188957731,
          volatilityValue: 1018954901,
          yieldValue: -39088943,
          fxValue: 1295249578,
        },
      ],
    },
    sectors: {
      totalMarketValueBase: 308881275,
      effectiveBeta: -1366603797,
      entries: [
        {
          volatilityMultiplier: 1,
          priceMultiplier1: 1,
          priceMultiplier2: 1,
          yieldMultiplier: 1,
          fxMultiplier: 1,
          name: 'dpHYZGhtgdntugzvvKAXLhM',
          instruments: ['UfzQhdgLLfDTDGspDb'],
          priceValue: -535098017,
          volatilityValue: -1935747844,
          yieldValue: -1219562352,
          fxValue: 696711130,
        },
      ],
    },
    countries: {
      totalMarketValueBase: 1823070661,
      effectiveBeta: -292028230,
      entries: [
        {
          volatilityMultiplier: 1,
          priceMultiplier1: 1,
          priceMultiplier2: 1,
          yieldMultiplier: 1,
          fxMultiplier: 1,
          name: 'IoTtOmcBeivNUYv',
          instruments: ['dwprLNbgNvZUMpqAXLJbjE'],
          priceValue: 298577043,
          volatilityValue: 525072488,
          yieldValue: 389778123,
          fxValue: 1452179944,
        },
      ],
    },
    currencies: {
      volatilityMultiplier: 1,
      totalMarketValueBase: 656237286,
      effectiveBeta: -851237395,
      entries: [
        {
          volatilityMultiplier: 1,
          priceMultiplier1: 1,
          priceMultiplier2: 1,
          yieldMultiplier: 1,
          fxMultiplier: 1,
          name: 'oBeMdQkAoYZDjfWhbzkmA',
          instruments: ['DiACnbEKIFidn'],
          priceValue: 1702941070,
          volatilityValue: 2146898372,
          yieldValue: 1109455496,
          fxValue: -82323612,
        },
      ],
    },
  };
}
