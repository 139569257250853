// disabled if true
export const FEATURE_FLAGS = {
  instruments: false,
  risk: true,
  riskV2: false,
  report: true,
  scenario: true,
  scenarioV2: false,
  twr: false,
  help: true,
  futureDividends: true,
  futureCoupons: true,
  futureIncome: true,
  users: true,
};
