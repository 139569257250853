import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Widget from '../../../../../../../../../../components/WidgetsBlock/Widget/Widget';

import CumulativePnLChart from './CumulativePnLChart';
import {
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../../../../../../../../../services/selectors/portfolio';
import { IRootState } from '../../../../../../../../../../../../services/store';
import { TRANSACTION_TYPES } from '../../../../../../../../../../../../services/mock/transactionTypes';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../../../../../../../components/ContextHelp';
import { EQUITY_PNL_BREAKDOWN_HELP_TEXT } from '../../../../../../../../../../../../services/constants/tooltipContextHelp';

interface IMapStateToProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IOwnProps {
  cumulativePnl: ICumulativePnl[];
  instrumentName: string;
  instrumentCurrency?: string;
  baseCurrency: string;
  baseCurrencySymbol: string;
  assetClassId?: string;
}

type IProps = IMapStateToProps & IOwnProps;

class CumulativePnLChartContainer extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    // const chartTitle = 'Stock Price and ' + this.props.instrumentCurrency + '/' + this.props.baseCurrency + ' FX Adjusted Price' ;
    const chartTitle = `Cumulative P&L on ${this.props.instrumentName}`;

    return (
      <Widget colNum={1}>
        <h3>
          {chartTitle}
          <ContextHelpIcon title={chartTitle || ''} elementId={chartTitle || 'tmpID'} />
        </h3>
        <ContextHelpTooltip
          elementId={chartTitle}
          tooltipTitle={chartTitle}
          tooltipText={EQUITY_PNL_BREAKDOWN_HELP_TEXT.ChartCumulativePnlSinceInception}
        />
        <br />
        <CumulativePnLChart
          baseCurrency={this.props.baseCurrency}
          baseCurrencySymbol={this.props.baseCurrencySymbol}
          chartData={this.props.cumulativePnl}
          portfolioCurrencyFormatter={this.props.portfolioCurrencyFormatter}
          instrumentName={this.props.instrumentName}
        />
      </Widget>
    );
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

export default connect(mapStateToProps)(CumulativePnLChartContainer);
