import { IRootState } from '../store';

import { createSelector } from 'reselect';
import { IOption } from '../../components/UIWidgets/Autocomplete';

export function getIndustry(state: IRootState): IIndustry[] {
  return state.industry.data;
}

export const getIndustryOptions = createSelector<IRootState, IIndustry[], Array<IOption<IIndustry>>>(
  getIndustry,
  (industryList: IIndustry[]): Array<IOption<IIndustry>> => {
    return industryList.map((industry) => {
      return {
        id: industry.id,
        value: industry,
        name: industry.name,
      };
    });
  }
);
