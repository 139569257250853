import React, { FunctionComponent, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

const Portal: FunctionComponent<PropsWithChildren<{ target?: HTMLElement }>> = ({
  children,
  target = document.body,
}) => {
  return ReactDOM.createPortal(children, target);
};

export default Portal;
