import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { AnyAction, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { store } from '../../index';

import { IActionPayload } from '../../models/redux/IActionPayload';
import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { ILoadingPaginationDataState } from '../../models/redux/ILoadingPaginationDataState';
import { ILoadingPeriodDataState } from '../../models/redux/ILoadingPeriodDataState';
import { TABLE_PAGE_SIZE } from '../constants/constants';
import { IRootState } from '../store';
import { CognitoHelper } from './CognitoHelper';
import { errorStatusHandler } from './HTTPErrors/errorStatusHandler';

export type AsyncActionResult<R> = ThunkAction<R, IRootState, undefined, IActionPayload>;
export type AsyncActionDispatch = ThunkDispatch<IRootState, undefined, IActionPayload>;

export type SuccessAction<T = any> = (data: T) => IActionPayload;
export type ErrorAction = (error: string) => IActionPayload;
export type RequestAction<T = any> = (request: AxiosPromise<T>, cancelToken: CancelTokenSource) => IActionPayload;

export class ReduxHelper {
  static createInitialState<T = any>(data: T): ILoadingDataState<T> {
    return {
      isFetching: false,
      isFetched: false,
      data,
      error: null,
      cancelTokenSource: null,
    };
  }

  static createInitialPeriodState<T = any>(data: T): ILoadingPeriodDataState<T> {
    return {
      isFetching: false,
      isFetched: false,
      data,
      error: null,
      cancelTokenSource: null,
      period: {
        from: null,
        to: null,
        type: 0,
      },
    };
  }

  static createHttpRequestReducer<T extends ILoadingDataState>(
    initialState: T,
    requestActionName: string,
    successActionName: string,
    errorActionName: string,
    resetActionName?: string
  ): Reducer<T, AnyAction> {
    return (state = initialState, action: AnyAction) => {
      switch (action.type) {
        case requestActionName:
          if (state.cancelTokenSource && state.cancelTokenSource.cancel) {
            state.cancelTokenSource.cancel();
          }
          return {
            ...state,
            isFetching: true,
            isFetched: false,
            data: state.data,
            cancelTokenSource: action.payload.cancelTokenSource,
            error: null,
          };
        case successActionName:
          return {
            ...state,
            isFetching: false,
            isFetched: true,
            data: action.payload.data,
            cancelTokenSource: null,
            error: null,
          };
        case errorActionName:
          return {
            ...state,
            isFetching: false,
            isFetched: true,
            cancelTokenSource: null,
            error: action.payload.error,
          };
        case resetActionName:
          if (state.cancelTokenSource && state.cancelTokenSource.cancel) {
            state.cancelTokenSource.cancel();
          }
          return { ...initialState };
        default:
          return state;
      }
    };
  }

  static createHttpRequestWithPeriodReducer<T extends ILoadingPeriodDataState>(
    initialState: T,
    requestActionName: string,
    successActionName: string,
    errorActionName: string,
    setPeriodActionName: string,
    resetActionName?: string
  ): Reducer<T, AnyAction> {
    const defaultReducer = ReduxHelper.createHttpRequestReducer(
      initialState,
      requestActionName,
      successActionName,
      errorActionName,
      resetActionName
    );

    return (state = initialState, action: AnyAction) => {
      switch (action.type) {
        case setPeriodActionName:
          return {
            ...state,
            period: action.payload,
          };
        default:
          return defaultReducer(state, action);
      }
    };
  }

  static createInitialPaginationState<T = any[]>(data: T[], order: IOrder): ILoadingPaginationDataState<T> {
    return {
      isFetching: false,
      isFetched: false,
      content: data,
      page: 0,
      totalElements: 0,
      size: TABLE_PAGE_SIZE,
      order,
      error: null,
      cancelTokenSource: null,
    };
  }

  static createHttpPaginationRequestReducer<T extends ILoadingPaginationDataState>(
    initialState: T,
    requestActionName: string,
    successActionName: string,
    errorActionName: string,
    resetActionName: string,
    sortActionName: string
  ): Reducer<T, AnyAction> {
    return (state = initialState, action: AnyAction) => {
      switch (action.type) {
        case requestActionName:
          if (state.cancelTokenSource) {
            state.cancelTokenSource.cancel();
          }
          return {
            ...state,
            isFetching: true,
            isFetched: false,
            cancelTokenSource: action.payload.cancelTokenSource,
            error: null,
          };
        case successActionName:
          const { order, content, ...rest } = action.payload.data;
          return {
            ...state,
            ...rest,
            isFetching: false,
            isFetched: true,
            cancelTokenSource: null,
            error: null,
            content:
              action.payload.data.page === 0
                ? action.payload.data.content
                : [...state.content, ...action.payload.data.content],
          };
        case errorActionName:
          return {
            ...state,
            isFetching: false,
            isFetched: false,
            cancelTokenSource: null,
            error: action.payload.error,
          };
        case resetActionName:
          if (state.cancelTokenSource) {
            state.cancelTokenSource.cancel();
          }
          return { ...initialState };
        case sortActionName:
          return { ...state, order: action.payload };
        default:
          return state;
      }
    };
  }

  static createHttpRequestActionBundle<T = any>(
    axiosOptions: AxiosRequestConfig,
    requestAction: string,
    successAction: string,
    errorAction: string,
    mapResponseFunc?: (response: any) => T
  ): AsyncActionResult<AxiosPromise<T>> {
    const requestAct: RequestAction<T> = createRequestAction(requestAction);
    const successAct: SuccessAction<T> = createSuccessAction<T>(successAction);
    const errorAct = createErrorAction(errorAction);

    const fetchAction = (dispatch: AsyncActionDispatch): AxiosPromise<T> => {
      const cancelTokenSource = axios.CancelToken.source();

      let apiRequest: AxiosPromise<T>;

      return CognitoHelper.withAuthorisationToken().then(() => {
        apiRequest = axios({
          method: 'post',
          responseType: 'json',
          withCredentials: true,
          cancelToken: cancelTokenSource.token,
          ...axiosOptions,
        });

        const request: AxiosPromise<T> = apiRequest
          .then((response) => {
            const responseData = mapResponseFunc ? mapResponseFunc(response.data) : response.data;
            dispatch(successAct(responseData));
            return response;
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return error;
            }
            errorStatusHandler(error, store);

            const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
            dispatch(errorAct(errorMsg));
            throw error;
          });

        dispatch(requestAct(request, cancelTokenSource));
        return request;
      });
    };

    return fetchAction;
  }
}

export const reduxErrorStatusHandler = (error: any) => errorStatusHandler(error, store);

function createSuccessAction<T = any>(successAction: string): (data: T) => IActionPayload {
  return (data: T) => ({
    type: successAction,
    payload: { data },
  });
}

function createRequestAction(
  requestAction: string
): (request: AxiosPromise, cancelTokenSource: CancelTokenSource) => IActionPayload {
  return (request, cancelTokenSource) => ({
    type: requestAction,
    payload: {
      cancelTokenSource,
    },
  });
}

function createErrorAction(errorAction: string): (error: string) => IActionPayload {
  return (error: string) => ({
    type: errorAction,
    payload: {
      error,
    },
  });
}
