import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Button, Checkbox } from '../../../../../../components';

import style from './RecommendationList.module.css';
import { IRecommendation } from '../../../../../../models/IRecommendation';
import { FormatHelper } from '../../../../../../services/utils/FormatHelper';
import { ICurrencyFormatter } from '../../../../../../services/selectors/portfolio';

interface IProps {
  recommendations: IRecommendation[];
  onToggle: (recommendationIdx: number) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class RecommendationList extends PureComponent<IProps> {
  onClickRecommendation = (e: React.MouseEvent<HTMLButtonElement>, recommendationIdx: number) => {
    e.preventDefault();
    this.props.onToggle(recommendationIdx);
  };

  renderBtns = () => {
    const { portfolioCurrencyFormatter } = this.props;
    const recommendations = this.props.recommendations || [];

    return recommendations.map((item: IRecommendation, idx: number) => {
      const recommendationText = item.fraction > 0 ? 'Invest' : 'Divest';
      const active = item.active || false;
      let label = `${recommendationText}`;
      if (item.change_amount) {
        label += ` ${portfolioCurrencyFormatter(Math.abs(item.change_amount))}`;
      }
      label += item.fraction > 0 ? ' in' : ' from';

      return (
        <Button
          key={idx}
          active={active}
          onClick={(e) => this.onClickRecommendation(e, idx)}
          className={cn(style.recommendationItem, {
            [style.recommendationItemBuy]: item.change_amount > 0,
            [style.recommendationItemSell]: item.change_amount < 0,
          })}
        >
          <Checkbox checked={active}>
            <span>
              {label}
              <b className={style.country}>{item.country}</b>
            </span>
          </Checkbox>
        </Button>
      );
    });
  };

  render() {
    return <div className={style.recommendationList}>{this.renderBtns()}</div>;
  }
}

export default RecommendationList;
