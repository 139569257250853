import { combineReducers } from 'redux';
import { ReduxHelper } from '../../utils/ReduxHelper';
import { DashboardActions } from '../../actions';

import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';

const INITIAL_TOTAL_VALUES_DASHBOARD_INFO: IDashboardAssetClass = {
  total: { value: 0, diff: 0 },
  totalPnlBase: { value: 0, diff: 0 },
  next: { value: 0, diff: 0 },
  past: { value: 0, diff: 0 },
  inceptionPerformance: 0,
};

const totalValuesReducers = combineReducers({
  info: ReduxHelper.createHttpRequestReducer<ILoadingDataState<IDashboardAssetClass>>(
    ReduxHelper.createInitialState(INITIAL_TOTAL_VALUES_DASHBOARD_INFO),
    DashboardActions.DASHBOARD_TOTAL_VALUES_REQUEST,
    DashboardActions.DASHBOARD_TOTAL_VALUES_SUCCESS,
    DashboardActions.DASHBOARD_TOTAL_VALUES_ERROR,
    DashboardActions.DASHBOARD_TOTAL_VALUES_RESET
  ),
});

export default totalValuesReducers;
