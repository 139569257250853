export const PATHS = {
  account: {
    path: '/account',
    settings: { path: '/account/settings' },
    password: {
      path: '/account/password',
    },
  },
  admin: {
    path: '/administration',
    account: {
      path: '/administration/manage-account',
    },
    unlisted: {
      path: '/administration/unlisted',
    },
    users: {
      path: '/administration/users',
    },
  },
  workGroupsManager: {
    path: '/workgroups',
  },

  portfoliosManager: {
    path: '/portfolios/:workgroupId',
    custodiansManager: {
      path: '/portfolios/custodians/:portfolioId',
    },
    portfolioCreator: {
      path: '/portfolios/new',
    },
    portfolioAssign: {
      path: `/portfolios/assign/:portfolioId`,
    },
    portfolioShare: {
      path: `/portfolios/share/:portfolioId`,
    },
    portfolioTransfer: {
      path: `/portfolios/transfer/:portfolioId`,
    },
    // editAccess: {
    //   path: '/portfolios/access/:portfolioId',
    // },
    editSettings: {
      path: '/portfolios/settings/:portfolioId',
    },
  },
  users: {
    path: '/users',
    // editUser: { path: '/users/:userId/edit' },
    // createUser: { path: '/users/new' },
  },
  instruments: {
    path: '/portfolio/:portfolioId/instruments/:assetClassId',
    editInstrument: { path: '/portfolio/:portfolioId/instruments/:assetClassId/edit/:instrumentId' },
    addInstrument: { path: '/portfolio/:portfolioId/instruments/:assetClassId/add' },
    uploadInstrument: { path: '/portfolio/:portfolioId/instruments/upload' },
  },
  help: {
    path: '/help',
  },
  portfolio: {
    path: '/portfolio/:portfolioId',
    empty: {
      path: '/portfolio/:portfolioId/empty',
    },
    esg: {
      path: '/portfolio/:portfolioId/esg',
    },
    performance: {
      path: '/portfolio/:portfolioId/performance',
    },
    esgPage: {
      path: '/portfolio/:portfolioId/esg',
    },
    pnl: {
      path: '/portfolio/:portfolioId/pnl',
    },
    recommendations: {
      path: '/portfolio/:portfolioId/recommendations',
    },
    reportGenerator: {
      path: '/portfolio/:portfolioId/reports',
    },
    risk: {
      path: '/portfolio/:portfolioId/risk',
      analytics: {
        path: '/portfolio/:portfolioId/analytics',
      },
    },
    risk_scenarios: {
      path: '/portfolio/:portfolioId/risk_scenarios',
    },
    backtest: {
      path: '/portfolio/:portfolioId/backtest',
    },
    sensitivity: {
      path: '/portfolio/:portfolioId/sensitivity',
    },
    twr: {
      path: '/portfolio/:portfolioId/twr',
    },
    structure: { path: '/portfolio/:portfolioId/structure/:classId?/:page?' },
    income: {
      path: '/portfolio/:portfolioId/income/:classId?/:page?',
      crypto: { path: '/portfolio/:portfolioId/dashboard/Crypto/income' },
    },
    fees: { path: '/portfolio/:portfolioId/fees/:classId?/:page?' },
    dashboardNew: {
      path: '/portfolio/:portfolioId/dash/',
    },
    dashboard: {
      path: '/portfolio/:portfolioId/dashboard/',
      classDashboard: { path: '/portfolio/:portfolioId/dashboard/:classId/:page?' },
      subClassDashboard: { path: '/portfolio/:portfolioId/dashboard/:classId/:page' },
      breakdownDetailDashboard: { path: '/portfolio/:portfolioId/BreakdownDetail/:classId/:positionId' },
    },
    explorer: {
      path: '/portfolio/:portfolioId/explorer/',
    },
    pnlExplorer: {
      path: '/portfolio/:portfolioId/pnl/explorer',
    },
    report: {
      path: '/portfolio/:portfolioId/report',
    },
    scenarios: {
      path: '/portfolio/:portfolioId/scenarios/',
    },
    insightsBrowser: {
      path: '/portfolio/:portfolioId/insights/',
    },
    snapshot: {
      path: '/portfolio/:portfolioId/snapshot/',
      positions: {
        path: '/portfolio/:portfolioId/snapshot/positions',
        add: {
          path: '/portfolio/:portfolioId/snapshot/positions/add',
        },
      },
      transactions: {
        path: '/portfolio/:portfolioId/snapshot/transactions',
      },
      edit: {
        path: '/portfolio/:portfolioId/transactions/add/',
      },
      upload: {
        process: '/portfolio/:portfolioId/transactions/upload/process',
        path: '/portfolio/:portfolioId/transactions/upload/',
      },
    },
  },
};
