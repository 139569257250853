import React from 'react';
import { Autocomplete, FormFieldWrapper } from '../../../../../../../../components';
import { useFormContext, Controller } from 'react-hook-form';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';

export const AutoCompleteField = <T extends any>({
  label,
  name,
  className,
  required,
  ...props
}: IAutoCompleteFieldProps<T> & { label: string }) => {
  const { control } = useFormContext();

  return (
    <FormFieldWrapper label={label} required={required} className={cn(s.typeField, className)}>
      <Controller name={name} as={AutoCompleteInner} {...props} control={control} />
    </FormFieldWrapper>
  );
};

interface IAutoCompleteFieldProps<T> {
  name: string;
  options: IOption<T>[];
  value: IOption<T> | null;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  className?: string;
  onTyping?(val: string): void;
  // onChange?(val: any): void;
}

type AutoCompleteInnerProps<T> = IAutoCompleteFieldProps<T> & { onChange(val: any): void };
const AutoCompleteInner = <T,>({
  disabled,
  placeholder = 'Search',
  required,
  options,
  value,
  onChange,
  onTyping,
  ...props
}: AutoCompleteInnerProps<T>) => {

  // return (<div>{JSON.stringify(value, null, 2)}</div>)
  return (
    <Autocomplete
      {...props}
      inputDisabled={disabled}
      placeholder={placeholder}
      onTyping={onTyping}
      options={options}
      inputProps={{
        style: { width: 100 },
      }}

      disableReset={required}
      value={value || null}
      onSelect={onChange}
    />
  );
};
