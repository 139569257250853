import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { Loader } from '../../../../../../../../components';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';
import PnlByInstruments from '../PortfolioLevelPnlChart/PnlByInstruments';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { Toggle } from 'rsuite';
import s from './PnlByInstrumentChartContainer.module.scss';
import { ASSET_COLORS } from '../../../../../../../../services/constants/assetClasses';
import { BarType } from '../../../../../../../../services/constants/charts';
import moment from 'moment';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';

interface IProps {
  portfolioId: string;
  onSelectPosition: Dispatch<SetStateAction<IPortfolioSnapshot | null>>;
}

const PnlByInstrumentChartContainer = ({ portfolioId, onSelectPosition }: IProps) => {
  const { period } = usePeriod();
  const { portfolioCurrencyFormatter } = usePortfolio();
  const [barData, setBarData] = useState<IBarData[]>([]);
  const [serverData, setServerData] = useState<IPortfolioSnapshot[]>([]);

  const [all, setAll] = useState(false);

  useEffect(() => {
    if (all) {
      parseBarData(serverData);
    } else {
      parseBarData(serverData.filter((item) => item.quantity !== 0));
    }
  }, [serverData, all]);

  useEffect(() => {
    portfolioSnapshots.refetch();
  }, [period]);

  const parseBarData = (content: IPortfolioSnapshot[]) => {
    const flatData: IBarData[] = [];

    const data = content.filter(
      (item) => item.assetClassId !== 'CashAndEquivalents' && item.assetClass !== 'Cash Adjustments'
    );

    data.forEach((snap) => {
      const found = flatData.find((el) => el.name === snap.assetClassId);

      if (!!found) {
        const newFound = found;
        newFound.data.push([snap.name, snap.profitAndLoss!] as [string, number]);
        flatData.filter((f) => f.name !== found.name).push(newFound);
      } else {
        flatData.push({
          id: snap.positionId,
          instrumentId: snap.instrumentId,
          name: snap.assetClassId!,
          data: [[snap.name, snap.profitAndLoss!]],
          color: ASSET_COLORS[snap.assetClassId!],
          type: BarType.Solid,
        });
      }
    });

    setBarData(flatData);
  };

  const prepPeriod = PeriodHelper.preparePeriodForRequest(period);

  const portfolioSnapshots = useApi(
    getApiUrl('snapshot.snapshots'),
    {
      method: 'POST',
      data: {
        confirmed: true,
        order: { name: 'profitAndLoss', direction: 'DESC' },
        portfolioId,
        fromDate: period.from ? prepPeriod.from : null,
        toDate: prepPeriod.to,
        page: 0,
        size: 1000,
      },
    },
    {
      onSuccess: ({ content }: { content: IPortfolioSnapshot[] }) => {
        setServerData(content);
      },
    }
  );

  const isFetching = portfolioSnapshots.isFetching;

  if (isFetching) {
    return <Loader />;
  }

  const toggle = () => {
    return (
      <div className={s.toggleContainer}>
        <span>Open</span>
        <Toggle className={s.toggle} onChange={setAll} />
        <span>All</span>
      </div>
    );
  };

  return (
    <PnlByInstruments
      title={'Positions by Absolute P&L'}
      renderHeaderTools={toggle}
      barData={barData}
      onSelectPosition={onSelectPosition}
      serverData={serverData}
      portfolioCurrencyFormatter={portfolioCurrencyFormatter}
    />
  );
};

export default PnlByInstrumentChartContainer;
