import cn from 'classnames';
import React, { Fragment, PureComponent } from 'react';
import { FormatHelper } from '../../../../services/utils/FormatHelper';
import s from './InfoBlock.module.scss';
import ReactTooltip from 'react-tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Card } from '@iliotech/storybook';

import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../src/components/ContextHelp';

interface IProps {
  image?: string;
  title: string;
  value: number | string;
  diff?: number;
  colorValue?: boolean;
  currency?: string;
  percentage?: boolean;
  contextHelpText?: string;
}

class InfoBlock extends PureComponent<IProps> {
  render() {
    const { image, title, value, diff, colorValue, currency, percentage, contextHelpText } = this.props;
    return (
      <div>
        <div className={s.infoBlock}>
          {image && (
            <div className={s.infoBlockImage}>
              <img src={image} alt={title} />
            </div>
          )}
          <div className={s.infoBlockContent}>
            <div className={cn(s.infoBlockTitle, s.noCard)}>
              {title}
              {contextHelpText === undefined ? (
                ''
              ) : contextHelpText.length === 0 ? (
                ''
              ) : (
                <ContextHelpIcon title={title || ''} elementId={title || 'tmpID'} />
              )}
            </div>
            <div
              className={cn(
                s.infoBlockValue,
                s.noCard,
                { [s.plus]: (colorValue && value > 0) || (colorValue && !value.toString().includes('-')) },
                { [s.minus]: (colorValue && value < 0) || (colorValue && value.toString().includes('-')) }
              )}
            >
              {currency} {value}
              {percentage && '%'}
              {!!diff && <span className={s.infoBlockDiff}>{FormatHelper.formatPercentage(diff)}</span>}
            </div>
          </div>
        </div>
        <ContextHelpTooltip elementId={title} tooltipTitle={title} tooltipText={contextHelpText} />
      </div>
    );
  }
}

export default InfoBlock;
