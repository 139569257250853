import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';

export class UserCapabilitiesActions {
  static readonly USER_CAPABILITIES_REQUEST = 'USER_CAPABILITIES_REQUEST';
  static readonly USER_CAPABILITIES_SUCCESS = 'USER_CAPABILITIES_SUCCESS';
  static readonly USER_CAPABILITIES_ERROR = 'USER_CAPABILITIES_ERROR';

  static fetchUserCapabilities() {
    return ReduxHelper.createHttpRequestActionBundle<IUserCapabilities>(
      {
        url: getApiUrl('userCapabilities'),
        method: 'GET',
      },
      UserCapabilitiesActions.USER_CAPABILITIES_REQUEST,
      UserCapabilitiesActions.USER_CAPABILITIES_SUCCESS,
      UserCapabilitiesActions.USER_CAPABILITIES_ERROR
    );
  }
}
