import React, { FC } from 'react';
import cn from 'classnames';
import s from '../ReportGenerator.module.css';
import { Button } from 'rsuite';
import { Toggle } from './Toggle';

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
}

const RiskSettings: FC<IOwnProps> = ({ settings, updateSettings }) => {
  const isAllSelected =
    !!settings.returnVolatilityDaily &&
    !!settings.returnVolatilityMonthly &&
    !!settings.returnsHistogramMonthly &&
    !!settings.returnsHistogramDaily;

  const toggleAll = () => {
    updateSettings({
      returnVolatilityDaily: !isAllSelected,
      returnVolatilityMonthly: !isAllSelected,
      returnsHistogramMonthly: !isAllSelected,
      returnsHistogramDaily: !isAllSelected,
    });
  };

  const RiskToggle = ({ field }: { field: string }) => {
    return <Toggle field={field} settings={settings} updateSettings={updateSettings} />;
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <h3>Risk</h3>
      <br />
      <div className={cn(s.settingsRow)}>
        <span>Daily Return vs Volatility Scatter</span>
        <RiskToggle field={'returnVolatilityDaily'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Monthly Return vs Volatility Scatter</span>
        <RiskToggle field={'returnVolatilityMonthly'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Monthly Returns Histogram & Key Perf/Vol Stats</span>
        <RiskToggle field={'returnsHistogramMonthly'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Daily Returns Histogram & Key Perf/Vol Stats</span>
        <RiskToggle field={'returnsHistogramDaily'} />
      </div>

      <Button style={{ float: "right" }} size={'xs'} appearance={'subtle'} onClick={toggleAll}>
        Select {isAllSelected ? 'none' : 'all'}
      </Button>
    </div>
  );
};

export default RiskSettings;
