import type { CancelToken } from 'axios';
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { Auth } from 'aws-amplify';
import { errorStatusHandler } from '../utils/HTTPErrors/errorStatusHandler';

/*
 * sendRequest
 * Can be used for requests that shouldn't be included in the cache (e.g. non-idempotent / unsafe calls)
 * Sets appropriate headers for illio services
 * */
export const sendRequest = <T = any>(
  endpoint: string,
  options?: AxiosRequestConfig,
  data?: any,
  cancelToken?: CancelToken
): Promise<T> =>
  Auth.currentSession()
    .then(async (session) => {
      axios.defaults.timeout = 20000;
      axios.defaults.headers.common.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
    })
    .then(() => {
      return axios({
        url: endpoint,
        method: options?.method || 'get',
        responseType: 'json',
        data,
        cancelToken,
        withCredentials: true,
        ...options,
        headers: {
          ...options?.headers,
          // illio-selected-account is handled in App.tsx
        },
      })
        .then((result) => result.data)
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log('Request cancelled');
          } else {
            throw(thrown);
          }
        });
    });

const queryFn = <T = any>(endpoint: string, options?: AxiosRequestConfig, data?: any) => () =>
  sendRequest<T>(endpoint, options, data).catch(errorStatusHandler);

/*
 * useAPI
 * Sets appropriate headers for illio services
 * Sets some defaults for React Query
 * Uses url as default for cache key (can be overridden in queryConfig)
 * */
export const useApi = <T = any>(endpoint: string, options?: AxiosRequestConfig, queryConfig?: UseQueryOptions<T>) => {
  return useQuery<T>(
    [endpoint, JSON.stringify({ data: options?.data, headers: options?.headers, params: options?.params })],
    queryFn<T>(endpoint, options),
    {
      retry: 1,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...queryConfig,
    }
  );
};
export default useApi;
