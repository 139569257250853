import ReactEcharts from 'echarts-for-react';
import React, { PureComponent } from 'react';

import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { getRepaymentsChartOptions } from './repaymentsChartOptions';

interface IProps {
  chartData: IBarData;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class RepaymentsChart extends PureComponent<IProps> {
  render() {
    const { chartData, portfolioCurrencyFormatter } = this.props;
    return (
      <ReactEcharts
        style={{ height: '420px', width: '100%', flexGrow: '1' }}
        option={getRepaymentsChartOptions(chartData, portfolioCurrencyFormatter)}
      />
    );
  }
}

export default RepaymentsChart;
