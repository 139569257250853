import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { LineData } from '../../../../models/LineData';
import { DashboardActions } from '../../../../services/actions';
import { IRootState } from '../../../../services/store';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import PerformanceChartWidget from './PerformanceChartWidget';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../services/selectors/portfolio';

interface IMapStateToProps {
  isLoaded: boolean;
  indexPerformance: LineData;
  //  portfolioPerformance:  LineData;
  portfolioPerformance: any[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  performancePeriod: IPeriod;
  indexName: string;
}

interface IDispatchToProps {
  changePerformancePeriod: (period: IPeriod) => void;
}

type IProps = IMapStateToProps &
  IDispatchToProps &
  Pick<IWidgetProps, 'colNum' | 'title' | 'titleInfo' | 'full'> & { hidePeriodSelector?: boolean } & {
    performanceToggle?: number | 0;
  };

class PerformanceChartWidgetContainer extends PureComponent<IProps> {
  render() {
    const {
      performancePeriod,
      changePerformancePeriod,
      isLoaded,
      portfolioPerformance,
      performanceToggle,
      portfolioCurrencyFormatter,
      ...restProps
    } = this.props;

    const toggledPerformance: any[] = [];
    const marketValueBase: any[] = [];

    if (performanceToggle === 1) {
      portfolioPerformance.map((el) => {
        toggledPerformance.push([el[0], el[3]]);
        marketValueBase.push([el[0], el[2]]);
      });
    } else {
      portfolioPerformance.map((el) => {
        toggledPerformance.push([el[0], el[1]]);
      });
    }

    return (
      <PerformanceChartWidget
        selectedPeriod={performancePeriod}
        isDataLoaded={isLoaded}
        portfolioPerformance={toggledPerformance}
        marketValueBase={marketValueBase}
        performanceToggle={performanceToggle}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        {...restProps}
      />
    );
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    isLoaded:
      state.dashboard.performance.indexPerformanceData.isFetched &&
      state.dashboard.performance.performanceData.isFetched,
    indexPerformance: state.dashboard.performance.indexPerformanceData.data,
    indexName: state.dashboard.performance.indexPerformanceData.name,
    portfolioPerformance: state.dashboard.performance.performanceData.data,
    performancePeriod: state.dashboard.performance.period,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  changePerformancePeriod: (period: IPeriod) => dispatch(DashboardActions.setPerformancePeriod(period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceChartWidgetContainer);
