import React, { useState, ReactElement, useCallback, useMemo, ReactNode } from 'react';
import { ITab } from './types';
import TabComponent, { IProps as ITabProps } from './Tab';
import sortComparator from '../../../services/utils/sortComparator';
import defaultClassModule from './Tabs.module.scss';

interface IClassModule {
  tabsWrapper: string;
  tabsList: string;
  tabsItem: string;
  tabsBtn: string;
  tabsContent: string;
  active: string;
  [key: string]: string;
}

interface IProps {
  classModule?: IClassModule;
  defaultId?: number | string;
  tabs: ITab[];
  Tab?: (tabProps: ITabProps) => ReactElement | null;
  children: (currentId: string | number | null) => ReactNode | null;
  onSelect?: (tab: ITab) => void;
}

function Tabs(props: IProps) {
  const Tab = props.Tab || TabComponent;
  const [currentId, setCurrentId] = useState(props.defaultId || null);

  const onSelectHandler = useCallback((tab: ITab) => {
    setCurrentId(tab.id);
    if (props.onSelect) {
      props.onSelect(tab);
    }
  }, []);

  const memoizedTabs = useMemo(() => props.tabs.sort(sortComparator<ITab>((tab) => tab.index)), [props.tabs]);

  if (props.tabs.length === 0) {
    return null;
  }

  const classModule = props.classModule || (defaultClassModule as IClassModule);

  return (
    <div className={classModule.tabsWrapper}>
      <div className={classModule.tabsList}>
        {memoizedTabs.map((tab: ITab) => {
          return (
            <Tab
              isActive={tab.id === currentId}
              classModule={classModule}
              tab={tab}
              onSelect={onSelectHandler}
              key={tab.id}
            />
          );
        })}
      </div>
      <div className={classModule.tabsContent}>{props.children(currentId)}</div>
    </div>
  );
}

export default Tabs;
