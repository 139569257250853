import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import MaturityChartWidget from './MaturityChartWidget';

import { CreditActions } from '../../../../../../../../../../services/actions';
import { AsyncActionDispatch } from '../../../../../../../../../../services/utils/ReduxHelper';

import { IWidgetProps } from '../../../../../../../../components/WidgetsBlock/Widget/Widget';
import { IRootState } from '../../../../../../../../../../services/store';
import { LineData } from '../../../../../../../../../../models/LineData';
import {
  getPortfolioCurrencyCode,
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../../../../../../../services/selectors/portfolio';

interface IMapStateToProps {
  maturityData: LineData;
  selectedPeriod: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  onPeriodChange: (period: IPeriod) => void;
}

type IProps = IMapStateToProps & IDispatchToProps & Pick<IWidgetProps, 'colNum' | 'title' | 'full'>;

class MaturityChartContainer extends PureComponent<IProps> {
  render() {
    return <MaturityChartWidget {...this.props} />;
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    maturityData: state.dashboard.credit.maturity.data,
    selectedPeriod: state.dashboard.credit.maturity.period,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  onPeriodChange: (period: IPeriod) => dispatch(CreditActions.setMaturityPeriod(period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaturityChartContainer);
