import { faCheck, faMinus, faPen } from '@fortawesome/free-solid-svg-icons'; // TODO: Replace with Illio icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // TODO: Replace with Illio icons
import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import Trend from 'react-trend';
import validator from 'validator';
import { IRealAsset, IRealAssetPrice } from '../../../models/IRealAsset';
import { IRootState } from '../../../services/store';
import { currencyFormat } from '../../../services/utils/CurrencyHelpers';
import s from './UnlistedTable.module.css';
import { generatePath } from 'react-router';

interface IProps {
  instruments: IRealAsset[] | null;
  state: REAL_ASSET_TABLE_STATE;
  assetClassId: AssetClassId;
}

interface IMapProps {
  assetSubClasses: IRootState['assetsSubClasses']['data'];
}

export enum REAL_ASSET_TABLE_STATE {
  EMPTY,
  LOADING,
  READY,
}

type Props = IProps & IMapProps;

const UnlistedTable: FunctionComponent<Props> = ({ instruments, state, assetClassId, assetSubClasses }) => {
  const { path, params } = useRouteMatch<{ assetClassId: AssetClassId; portfolioId: string }>();

  if (state === REAL_ASSET_TABLE_STATE.EMPTY) {
    return <p className={cn(s.info)}>No instruments found</p>;
  }

  if (state === REAL_ASSET_TABLE_STATE.LOADING) {
    return <p className={cn(s.info)}>Loading</p>;
  }

  const getAssetSubClassName = (id: string) => assetSubClasses.filter((subClass) => subClass.id === id)?.[0]?.name ?? id;

  /**
   * Build out the header, given a set of dates for additional column names
   * @param dates
   * @param changeDateRange
   */
  const renderHeader = () => {
    return (
      <thead>
        <tr className={cn(s.titleRow)}>
          <Header label={''} />
          <Header label={'Asset Type'} />
          <Header label={'Code'} />
          <Header label={'Name'} />
          {/*<Header label={'Pricing source'} />*/}
          <Header label={'Unique Id Type'} />
          <Header label={'Unique Id'} />
          <Header label={'Country'} />
          <Header label={'Currency'} />
          <Header label={'Prices'} />
        </tr>
      </thead>
    );
  };

  /**
   * Build the matrix of instruments and prices by date
   */
  const renderBody = () => {
    return (
      <tbody>
        {Array.isArray(instruments) &&
          instruments.map((instrument) => {
            const { instrumentStatus } = instrument;
            return (
              <tr key={instrument.code} className={cn(s.row)}>
                <td>
                  {/* TODO: Replace statuses with enum*/}
                  <FontAwesomeIcon
                    color={instrumentStatus === 'ACTIVE' ? 'var(--text-success-color)' : 'var(--text-warning-color)'}
                    icon={instrumentStatus === 'ACTIVE' ? faCheck : faMinus}
                  />
                </td>
                <Cell value={getAssetSubClassName(instrument.assetSubClass ?? '')} />
                <Cell value={instrument.code ?? ''} />
                <Cell value={instrument.name ?? ''} />
                <Cell value={instrument.uniqueIdType ?? ''} />
                <Cell value={instrument.uniqueId ?? ''} />
                <Cell value={instrument.country ?? ''} />
                <Cell value={instrument.acquisitionCurrency ?? ''} />
                <PricesCell prices={instrument.prices ?? []} />

                <td>
                  <Link
                    style={{ marginLeft: 6 }}
                    to={generatePath(`${path}/edit/${instrument.id}`, { assetClassId, portfolioId: params.portfolioId })}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Link>
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  return (
    <div className={cn(s.surround)}>
      <table className={cn(s.table)}>
        {renderHeader()}
        {renderBody()}
      </table>
    </div>
  );
};

interface IHeader {
  label: string;
  highlighted?: boolean;
  shaded?: boolean;
}

const Header = ({ label, highlighted = false, shaded = false }: IHeader) => {
  return (
    <th className={cn(s.titleCell)}>
      <span className={cn(s.titleLabel, highlighted ? s.highlighted : shaded ? s.shaded : null)}>{label}</span>
    </th>
  );
};

interface ICell {
  value: string | number | undefined;
  maxWidth?: string;
  link?: string;
}

const Cell = ({ value, maxWidth, link }: ICell) => {
  const style = { maxWidth };
  return (
    <td className={cn(s.cell)} style={style}>
      {link && validator.isURL(link) ? (
        <div className={cn(s.cellText)}>
          <a href={link}>{link.substr(0, 40)}</a>
        </div>
      ) : (
        <div className={cn(s.cellText)}>{value}</div>
      )}
    </td>
  );
};

interface IPricesCell {
  prices: IRealAssetPrice[];
}

const PricesCell = ({ prices }: IPricesCell) => {
  const dataSet = prices.map((realAssetPrice: IRealAssetPrice) => realAssetPrice.price);

  if (prices.length > 0) {
    dataSet.unshift(0);
  }

  return (
    <td className={cn(s.cell)} style={{ width: 150 }}>
      <div className={cn(s.cellPrice)}>
        <Trend
          smooth={true}
          data={dataSet}
          gradient={['#42b3f4']}
          radius={10}
          strokeWidth={4}
          strokeLinecap={'butt'}
          height={27}
        />
        <p className={cn(s.priceText)}>
          <small>
            {dataSet.length ? (
              currencyFormat(dataSet[dataSet.length - 1], 2)
            ) : (
              <span className={cn(s.textError)}>N/D</span>
            )}
          </small>
        </p>
      </div>
    </td>
  );
};

const mapStateToProps = (state: IRootState) => ({
  assetSubClasses: state.assetsSubClasses.data,
});

export default connect(mapStateToProps)(UnlistedTable);
