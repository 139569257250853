import React from 'react';
import cn from 'classnames';
import s from './PositionDetailRow.module.css';
import { Button, ButtonGroup, InputNumber, Notification } from 'rsuite';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';
import { uniq } from 'lodash';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import { sendRequest } from '../../../../../../../../services/hooks/useApi';
import { API } from '../../../../../../../../services/constants/endpoints';
import moment from 'moment';
import { SERVER_DATE_FORMAT_MOMENT } from '../../../../../../../../services/constants/constants';
import { DatePicker } from '../../../../../../../../components';

type EarnCreatorValues = {
  earnDate: Date;
  earnAmount: number;
};

interface IProps extends IPositionDetailsTab {
  transactions: IPortfolioTrade[];
}

export const EarnCreator = ({ position, portfolioId }: IProps) => {
  const [values, setValues] = React.useState<Partial<EarnCreatorValues>>({
    earnDate: new Date(),
  });

  const updateValue = <K extends keyof EarnCreatorValues>(key: K) => (value: EarnCreatorValues[K]) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formIsInvalid = !(!!values.earnDate && !!values.earnAmount);

  const submit = () => {
    const trade = {
      settlementDate: moment(values.earnDate).format(SERVER_DATE_FORMAT_MOMENT),
      tradeTime: moment(values.earnDate).format(SERVER_DATE_FORMAT_MOMENT),
      quantity: values.earnAmount,
      positionId: position.positionId,
      portfolioId,
    };

    // const data = {
    //   portfolioId,
    //   trade
    // };

    sendRequest(`/api/v1/${API.endpoints.snapshot.trades.addEarnsFromPosition}`, { method: 'POST', data: trade })
      .then((result) => {
        Notification.success({ title: `Earn added` });
      })
      .catch((error) => {
        Notification.error({ title: 'Unable to create trade', description: error?.message });
      });
  };

  return (
    <div className={cn(s.horizontal)}>
      <table className={cn(s.inputsTable)}>
        <tbody>
          <tr>
            <td style={{ minWidth: 100 }}>Earn Date</td>
            <td>
              <div className={s.datePickerWrapper}>
                <DatePicker
                  value={values.earnDate}
                  max={new Date()}
                  onChange={(date) => updateValue('earnDate')(date.value!)}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>Earn Amount</td>
            <td>
              <InputNumber step={0.0001} min={0} value={values.earnAmount} onChange={updateValue('earnAmount') as any} />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          marginLeft: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '0.5rem',
        }}
      >
        <Button appearance={'primary'} disabled={formIsInvalid} onClick={submit}>
          Submit
        </Button>
        {/*<pre style={{fontSize: 12}}>{JSON.stringify({transactions}, null, 2)}</pre>*/}
      </div>
    </div>
  );
};
