import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../UniversalUpload.module.css';

import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import SimpleDropDown from '../../../../../../../../components/Reusable/SimpleDropDown/SimpleDropDown';
import SimpleAutocomplete from '../../../../../../../../components/Reusable/SimpleAutocomplete/SimpleAutocomplete';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import axios from 'axios';
import getAssetIdentifier from '../getAssetIdentifier';
import debounce from 'lodash.debounce';
import { oneOf } from '../../universalUploadHelpers';
import { IAssetResolverProps } from './IAssetResolverProps';

const AssetResolver: FC<IAssetResolverProps> = ({ item, onSelect, updateAssetInformation }) => {
  if (item.assetSubClass === 'CshAdjustments') {
    return <div>Cash Adjustment</div>;
  }

  const updateAsset = updateAssetInformation(getAssetIdentifier(item));

  if (item.resolvedAssets && !item.resolvedAssets?.length) {
    if (
      oneOf(
        ['EqShares', 'EqETFs', 'EQStructuredProducts', 'FIBonds', 'CshAdjustments', 'CryptoCurrencies', 'FunEquity'],
        item.assetSubClass
      )
    ) {
      return <AutoCompleteResolver row={item} updateAsset={updateAsset} />;
    }

    return <div className={cn(s.error)}>TBC (Resolve in next steps)</div>;
  }

  if ((item.resolvedAssets?.length ?? 0) >= 1) {
    const options = item.resolvedAssets!.map((asset, i) => ({
      id: asset.code,
      name: `${asset.name} - ${asset.code ?? ''}${!!asset.code ? '.' : ''}${asset.exchange ?? ''} (${asset.currency})`,
      value: i,
    }));
    const handleSelect = ({ selectedItem }: { selectedItem?: IOption<number> | null }) => {
      onSelect(selectedItem?.value);
    };

    const selectedOption =
      typeof item.selectedResolvedAsset !== 'undefined' ? options[item.selectedResolvedAsset] : null;

    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, maxWidth: 'calc(100% - 20px)', marginRight: 8 }}>
          <SimpleDropDown
            dropDownClass={s.dropDownSurround}
            items={options}
            onSelect={handleSelect}
            selectedItem={selectedOption}
            backgroundColor={'rgba(0,0,0,0.2)'}
            foreColor={typeof item.selectedResolvedAsset === 'number' ? undefined : 'var(--text-warning-color)'}
          />
        </div>
        {!!selectedOption && (
          <div
            className={cn(s.closeIcon)}
            onClick={() => updateAsset({ resolvedAssets: [], selectedResolvedAsset: undefined, resolved: false })}
          />
        )}
      </div>
    );
  }

  if ((item.resolvedAssets?.length ?? 0) > 1) {
    return <div>{item.resolvedAssets!.map((i) => i.name).join(', ')}</div>;
  }

  return <div>Working</div>;
};

const AutoCompleteResolver = ({ row, updateAsset }: { row: IUploadTemplateResult; updateAsset: (obj: any) => void }) => {
  const [resolvedAssets, setResolvedAssets] = React.useState<IResolvedAsset[]>([]);
  const [searchState, setSearchState] = React.useState('LOADING');

  React.useEffect(() => {
    if (row.ticker) {
      search(row.ticker);
    }
  }, [row.ticker]);

  const resolvedAsset =
    typeof row.selectedResolvedAsset !== 'undefined' ? row.resolvedAssets?.[row.selectedResolvedAsset] ?? null : null;

  const selectedItem = resolvedAsset
    ? {
        id: resolvedAsset.code,
        name: resolvedAsset.name,
        value: resolvedAsset,
      }
    : null;

  const onSelect = (a: any, index: number) => {
    updateAsset({
      resolvedAssets,
      selectedResolvedAsset: index,
      resolved: true,
    });
  };

  const search = (text?: string) => {
    if (!text) {
      return;
    }
    setSearchState('LOADING');
    const { assetSubClass, currency, exchange, country } = row;

    const values = { assetSubClass, query: text, fuzzyMatch: true };
    // if (assetSubClass === 'CryptoCurrencies') {
    //   delete values.currency;
    // }
    const params = Object.entries(values)
      .filter(([_k, value]) => value !== null)
      .map(([key, v]) => `${key}=${encodeURIComponent(String(v))}`)
      .join('&');
    const url = `${getApiUrl(`instruments.search`)}?${params}`;

    axios
      .get(url)
      .then((result) => {
        if (result?.data) {
          setResolvedAssets(result.data);
        }
      })
      .finally(() => {
        setSearchState('DONE');
      });
  };

  const items = React.useCallback(
    () =>
      resolvedAssets.map((asset: IResolvedAsset) => ({
        id: asset.code,
        name: `${asset.name} - ${asset.code}.${asset.exchange} (${asset.currency})`,
        value: asset,
      })),
    [resolvedAssets]
  )();

  const debouncedSearch = debounce(search, 1000);

  return (
    <SimpleAutocomplete
      selectedItem={selectedItem}
      onSelect={onSelect}
      onSearch={debouncedSearch}
      items={items}
      defaultText={row.ticker ?? ''}
      state={searchState}
      searchTextColor={
        typeof row.selectedResolvedAsset !== 'number' ? 'var(--text-warning-color)' : 'var(--text-on-secondary-bg)'
      }
    />
  );
};

export default AssetResolver;
