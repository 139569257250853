import { ReduxHelper } from '../utils/ReduxHelper';

import { getApiUrl } from '../constants/endpoints';

export class CurrencyActions {
  static readonly CURRENCY_REQUEST = 'CURRENCY_REQUEST';
  static readonly CURRENCY_SUCCESS = 'CURRENCY_SUCCESS';
  static readonly CURRENCY_ERROR = 'CURRENCY_ERROR';

  static fetchAllCurrency() {
    return ReduxHelper.createHttpRequestActionBundle<ICurrency[]>(
      {
        url: getApiUrl('currency.all'),
      },
      CurrencyActions.CURRENCY_REQUEST,
      CurrencyActions.CURRENCY_SUCCESS,
      CurrencyActions.CURRENCY_ERROR
    );
  }
}
