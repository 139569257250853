import React from 'react';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import cn from 'classnames';
import { renderDatePicker } from '../../../../../../../../components/ReduxForm';
import { addDays } from '../../../../../../../../services/utils/DateHelpers';

type Props = {
  onChange?(val: string): void;
  tradeTime: string;
  maxDaysAhead?: number;
};

const SettlementDate = ({ onChange, tradeTime, maxDaysAhead = 0 }: Props) => {
  return (
    <FormFieldWrapper label={'Settlement Date'} required={true} className={s.minField}>
      <Field
        name={'settlementDate'}
        className={s.datePicker}
        component={renderDatePicker}
        placeholder={'Date'}
        hideWeekends={false}
        minDate={new Date(tradeTime)}
        maxDate={addDays(maxDaysAhead, new Date(tradeTime))}
        onChange={(e: any) => onChange?.(e)}
      />
    </FormFieldWrapper>
  );
};

export default SettlementDate;
