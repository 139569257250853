import React, { Fragment, PureComponent } from 'react';

import { RISK_CONTAINER_HELP_TEXT } from '../../../../../../services/constants/tooltipContextHelp';
import s from './VolatilityPeriodToggle.module.css';
import { Button } from '../../../../../../components/UIWidgets/Button';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';
import { Widget } from '../../../../components';

interface IOwnProps {
  currentPeriod: string;
  clickFunction: () => void;
}

type IProps = IOwnProps;

const VolatilityPeriodToggle = ({ currentPeriod, clickFunction }: IProps) => {
  const selectedPeriod = currentPeriod === 'LONG' ? '260d' : '100d';
  const title = 'Period Toggle';
  const tooltipText = RISK_CONTAINER_HELP_TEXT.TogglePeriodChange;
  return (
    <div className={s.periodToggleContainer}>
      <div>
        <span>Toggle Period</span>
        <ContextHelpIcon title={'TogglePeriodChange'} elementId={'TogglePeriodChange'} />
      </div>
      <div className={selectedPeriod === '260d' ? s.toggleBox : s.toggleBoxRev}>
        <div className={s.toggleLabel}>{selectedPeriod}</div>
        <Button className={s.toggleButton} onClick={clickFunction} title={tooltipText}>
          {'||'}
        </Button>
      </div>
      <ContextHelpTooltip
        tooltipTitle={'Toggle Period'}
        elementId={'TogglePeriodChange'}
        tooltipText={RISK_CONTAINER_HELP_TEXT.TogglePeriodChange}
      />
    </div>
  );
};

export default VolatilityPeriodToggle;
