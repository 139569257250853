import cn from 'classnames';
import _ from 'lodash';
import React, { PureComponent, useEffect } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../components/ReduxForm';
import renderAutocomplete from '../../../components/ReduxForm/renderAutocomplete';
import { Button } from '../../../components/UIWidgets';
import { IOption } from '../../../components/UIWidgets/Autocomplete';

import { FORMS_NAME } from '../../../services/constants/forms';
import { IRootState } from '../../../services/store';
import { required, validateCustomeCustodian } from '../../../services/utils/FormValidations';
import sortComparator from '../../../services/utils/sortComparator';
import { IPortfolioSettingsFormData } from './models';
import s from './PortfolioSettingsForm.module.scss';
import c from '../components/PortfolioFormsCommon.module.scss';
import { PortfolioCustodianListPanel } from '../components/PortfolioCustodianListPanel';
import { AdvancedSettingsPanel } from '../components/AdvancedSettingsPanel';
import { Panel } from 'rsuite';
import { useAccount } from '../../../services/context/AuthContext';
import { Helmet } from 'react-helmet';

interface IContainerProps {
  currencyOptions: Array<IOption<ICurrency>>;
  performanceIndexOptions: Array<IOption<IIndex>>;
  listOfAllCustodians: ICustodian[];
  portfolioType: string | null;
  authInfo: IAuthInfo | null;
  portfolioInfo: IPortfolio | null;
  portfolioCustodians: ICustodian[];
  initialValues: any;
  showNotification: (notification: INotification) => void;
  onSubmit: (formValues: any) => void;
}

interface IDispatchToProps {
  updateType: (type: string) => void;
  updatePerformanceIndex: (index: IIndex) => void;
  updateCustodian: (userPortfolioCustodians: ICustodian | null) => void;
  toggleSystemDividends: (onlyManualDividendsDefault?: boolean) => void;
  updateUserPortfolioCustodians: (userPortfolioCustodians: ICustodian[]) => void;
  updateCustomCustodian: (custodian: string) => void;
  updateRiskAssetViewType: (viewType: RiskAssetViewType) => void;
}

interface IMapStateToProps {
  custodian: any | null;
  userPortfolioCustodians: ICustodian[];
  onlyManualDividendsDefault: boolean;
  customCustodian: string;
  formValues: any;
}

type IProps = IContainerProps &
  InjectedFormProps<IPortfolioSettingsFormData, IContainerProps> &
  IDispatchToProps &
  IMapStateToProps;

const PortfolioSettingsForm = ({
  onSubmit,
  invalid,
  submitting,
  performanceIndexOptions,
  listOfAllCustodians,
  portfolioInfo,
  formValues,
  onlyManualDividendsDefault,
  custodian,
  userPortfolioCustodians,
  customCustodian,
  updateCustomCustodian,
  updateCustodian,
  showNotification,
  updateRiskAssetViewType,
  updateUserPortfolioCustodians,
}: IProps) => {
  const { userCapabilities } = useAccount();
  const metadata = userCapabilities?.metadata;
  const isD2c = metadata?.allowedViews?.includes('d2c');

  useEffect(() => {
    if (portfolioInfo) {
      updateUserPortfolioCustodians(portfolioInfo.custodians);
    }
  }, [portfolioInfo]);

  const submit = (...args: any[]) => {
    onSubmit(formValues.values);
  };

  return portfolioInfo ? (
    <div className={c.wrapper}>
      <ReactTooltip type="light" effect="solid" />
      <Helmet>
        <title>illio - Portfolio Settings</title>
      </Helmet>

      <h1>Portfolio Settings • {portfolioInfo.name}</h1>
      <br />
      <h4>PREFERENCES</h4>
      <form className={cn(c.formWrapper, c.formWrapper)} onSubmit={() => {}}>
        <Panel style={{ background: 'var(--dark)', marginBottom: '1.5rem', overflow: 'visible' }} shaded={true}>
          <div>
            <div className={c.labelContainer}>
              <h4>Name*</h4>
            </div>
            <div className={cn(c.indexRow)}>
              <Field name="name" component={renderInput} validate={[required]} placeholder={'Portfolio name'} />
            </div>
          </div>
        </Panel>
        <div>
          <PortfolioCustodianListPanel
            temp={false}
            required={false}
            custodian={custodian}
            onlyManualDividendsDefault={onlyManualDividendsDefault}
            portfolioCustodians={userPortfolioCustodians}
            customCustodian={customCustodian}
            serverCustodians={listOfAllCustodians}
            updateCustomCustodian={updateCustomCustodian}
            updateCustodian={updateCustodian}
            updatePortfolioCustodians={updateUserPortfolioCustodians}
            showNotification={showNotification}
          />
        </div>
        <AdvancedSettingsPanel
          performanceIndexOptions={performanceIndexOptions}
          updateRiskAssetViewType={!isD2c ? updateRiskAssetViewType : undefined}
        />
        <div className={c.row}>
          <Button size="big" className={c.createBtn} onClick={submit} disabled={invalid || submitting}>
            Update
          </Button>
        </div>
      </form>
    </div>
  ) : null;
};

const mapDispatchToProps = {
  updateType: (type: string) => change(FORMS_NAME.portfolio, 'type', type),
  updatePerformanceIndex: (index: IIndex) => change(FORMS_NAME.portfolio, 'performanceIndex', index),
  updateCustodian: (custodian: ICustodian | null) => change(FORMS_NAME.portfolio, 'custodian', custodian),
  updateUserPortfolioCustodians: (userPortfolioCustodians: ICustodian[]) =>
    change(FORMS_NAME.portfolio, 'userPortfolioCustodians', userPortfolioCustodians),
  updateCustomCustodian: (custodian: string) => change(FORMS_NAME.portfolio, 'customCustodian', custodian),
  toggleSystemDividends: (onlyManualDividendsDefault?: boolean) =>
    change(FORMS_NAME.portfolio, 'onlyManualDividendsDefault', !onlyManualDividendsDefault),
  updateRiskAssetViewType: (viewType: RiskAssetViewType) =>
    change(FORMS_NAME.portfolio, 'defaultRiskAssetView', viewType),
};

const mapStateToProps = (state: IRootState) => {
  const selector = formValueSelector(FORMS_NAME.portfolio);
  const userPortfolioCustodians = selector(state, 'userPortfolioCustodians');
  const onlyManualDividendsDefault = selector(state, 'onlyManualDividendsDefault') || false;

  return {
    formValues: state.form[FORMS_NAME.portfolio],
    custodian: selector(state, 'custodian'),
    userPortfolioCustodians,
    onlyManualDividendsDefault,
    customCustodian: selector(state, 'customCustodian'),
  };
};

export default reduxForm<IPortfolioSettingsFormData, IContainerProps>({
  form: FORMS_NAME.portfolio,
  validate: validateCustomeCustodian,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(PortfolioSettingsForm));
