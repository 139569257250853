import { IPortfolioTrade } from '../../../../../../models/IPortfolioTrade';
import moment from 'moment';

export const filterTrades = (snapshotFilters: Record<string, string>, portfolioCurrency: string) => (
  trade: IPortfolioTrade
) => {
  if(trade.operation === "SWAP_S"){
    return false;
  }
  if (snapshotFilters.assetClass && (trade.tradeType.id || '') !== snapshotFilters.assetClass) {
    return false;
  }
  if (snapshotFilters.currency) {
    if (snapshotFilters.currency === 'non-base' && trade.currency === portfolioCurrency) {
      return false;
    }
    if (snapshotFilters.currency !== 'non-base' && trade.currency !== snapshotFilters.currency) {
      return false;
    }
  }

  // if (snapshotFilters.country && trade.instrument.country !== snapshotFilters.country) {
  //   return false;
  // }
  if (snapshotFilters.custodian && trade.custodian.name !== snapshotFilters.custodian) {
    return false;
  }
  if (snapshotFilters.operation && trade.operation !== snapshotFilters.operation) {
    return false;
  }
  if (snapshotFilters.transactionsFrom && moment(trade.tradeTime).isBefore(snapshotFilters.transactionsFrom)) {
    return false;
  }
  if (snapshotFilters.transactionsTo && moment(trade.tradeTime).isAfter(snapshotFilters.transactionsTo)) {
    return false;
  }
  return !(
    snapshotFilters.search &&
    !trade.instrument.name.toLowerCase().includes(snapshotFilters.search.toLowerCase()) &&
    !trade.instrument.code.toLowerCase().includes(snapshotFilters.search.toLowerCase())
  );
};
