import echarts from 'echarts/lib/echarts';
import { ISimulatedReturnData } from '../../interfaces';

function renderItem(params: any, api: any) {
  const yValue = api.value(2);
  const start = api.coord([api.value(0), yValue]);
  const size = api.size([api.value(1) - api.value(0), yValue]);
  const barStyle = api.style();

  return {
    type: 'rect',
    shape: {
      x: start[0] + 1,
      y: start[1],
      width: size[0] - 2,
      height: size[1],
    },
    style: barStyle,
  };
}

export function generateChartOptions(chartData: ISimulatedReturnData) {
  // const chartData2 = [[0, 10, 3, 'A'], [10, 20, 15, 'B'], [20, 30, 12, 'C'], [30, 40, 22, 'D'], [40, 50, 7, 'E'], [50, 60, 17, 'F']];

  const dataBins = chartData.bins;
  const minXVal = chartData.bins[0].lowerBound;
  const maxXVal = chartData.bins[chartData.bins.length - 1].upperBound;
  const splitXVal = chartData.bins.length - 1 > 15 ? 15 : chartData.bins.length - 1;

  const meanVal = Math.round(chartData.mean * 100) / 100;
  const meanTopBoundary = Math.max.apply(
    Math,
    chartData.bins.map((el) => el.value)
  );

  const seriesMean: any[] = [];
  seriesMean.push([meanVal, 0]);
  seriesMean.push([meanVal, meanTopBoundary + 1]);

  const chartBins: any[] = [];

  dataBins.map((el) => {
    chartBins.push([el.lowerBound, el.upperBound, el.value]);
  });

  // @ts-ignore
  chartData = echarts.util.map(chartData, (item, index) => {
    return {
      value: item,
      itemStyle: {
        color: '#a9b2d1',
      },
    };
  });

  const chartOpt2: object = {
    grid: {
      bottom: 20,
      left: 30,
      top: 50,
      right: 50,
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#6677cc',
          width: 1,
          type: 'dashed',
        },
        type: 'line',
      },
      backgroundColor: '#fff',
      borderColor: '#9a9a9a',
      borderWidth: 1,
      extraCssText: 'border-radius:10px;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);}',
      padding: [3, 7],
      show: true,
      textAlign: 'left',
      textStyle: {
        color: '#555555',
        fontSize: 11,
        textAlign: 'left',
      },
      formatter: (val = '{c}.data') => {
        // @ts-ignore
        return val.data[0] + ' to ' + val.data[1] + '% move occurs ' + val.data[2] + '% of the time';
      },
      trigger: 'item',
    },
    xAxis: {
      scale: true,
      show: true,
      min: minXVal,
      max: maxXVal,
      splitNumber: splitXVal,
      axisLine: {
        show: true,
      },
      axisLabel: {
        showMaxLabel: true,
        showMinLabel: true,
        show: true,
        color: '#a9b2d1',
        fontSize: 11,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: false,
      axisTick: {
        show: true,
      },
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        type: 'custom',
        renderItem,
        data: chartBins,
        itemStyle: {
          color: '#6677cc',
        },
        emphasis: {
          itemStyle: {
            color: 'rgba(0,0,0,1)',
          },
        },
        label: {
          show: false,
          position: 'top',
        },
        dimensions: ['Lower Bound', 'Upper Bound', 'Value'],
        encode: {
          x: [0, 1],
          y: 2,
          tooltip: [0, 1, 2],
          itemName: 'Simulated Volatility:',
        },
      },
      {
        name: 'Mean',
        type: 'line',
        data: seriesMean,
        barWidth: '2px',
        itemStyle: {
          color: '#ffffff',
        },
        tooltip: {
          show: true,
          /*formatter: '{a0}: {c0}'*/
          formatter: (value: any) => {
            return value.seriesName + ': ' + value.data[0];
          },
        },
        label: {
          show: false,
          position: 'top',
        },
      },
    ],
  };

  return chartOpt2;
}
