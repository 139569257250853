import { Auth } from 'aws-amplify';
import axios from 'axios';
import { store } from '../../index';
import { PortfolioActions } from '../actions';
import { AppActions } from '../actions/AppActions';

export class CognitoHelper {
  static withAuthorisationToken(cb?: () => void) {
    return Auth.currentSession()
      .then(async (session) => {
        axios.defaults.timeout = 20000;
        axios.defaults.headers.common.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
        if (typeof cb === 'function') {
          cb();
        }
      })
      .catch(() => {
        Auth.signOut();
        store.dispatch({ type: AppActions.LOGOUT_SUCCESS });
        store.dispatch({ type: PortfolioActions.PORTFOLIOS_LIST_RESET });
      });
  }
}
