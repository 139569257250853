import React from 'react';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import RegistrationTemplate from '../Registration/components/RegistrationTemplate';
import get from 'lodash.get';
import { UserStatus } from '../../../../services/constants/constants';
import { AppActions } from '../../../../services/actions/AppActions';
import { useDispatch } from 'react-redux';
import { useAccount } from '../../../../services/context/AuthContext';
import { useHistory } from 'react-router-dom';

const SignInScreen = () => {
  const { signOut, signIn, signedIn } = useAccount();
  const dispatch = useDispatch();
  const history = useHistory();
  const updateAuthStatus = (value: AuthStatus) => dispatch(AppActions.updateAuthStatus(value));

  const onSignedIn = async (payload: any) => {
    let { email, name: firstName, family_name: lastName, sub: id } = payload.attributes || {};
    if ((!firstName && !lastName) || (firstName === '' && lastName === '')) {
      email = payload?.signInUserSession?.idToken?.payload?.email;
      firstName = payload?.signInUserSession?.idToken?.payload?.name;
      lastName = payload?.signInUserSession?.idToken?.payload?.family_name;
      id = payload?.signInUserSession?.idToken?.payload?.sub;
    }
    if ((!firstName && !lastName) || (firstName === '' && lastName === '')) {
      email = payload?.challengeParam?.userAttributes?.email;
      firstName = payload?.challengeParam?.userAttributes?.name;
      lastName = payload?.challengeParam?.userAttributes?.family_name;
    }

    const role = get(payload, `signInUserSession.accessToken.payload["cognito:groups"][0]`, '').toUpperCase();
    const status = UserStatus.ACTIVE; // TODO: Move user status to cognito, consume from payload
    if (!signedIn) {
      signIn(email, firstName, lastName, role, status, id);
    }
  };

  const handleAuthStateChange = (status: string, payload: any) => {
    // console.log('auth status', { status, payload });
    switch (status) {
      case 'signedin':
        onSignedIn(payload);
        break;
      case 'signout':
      case 'signin':
        history.push('/workgroups');
        signOut();
    }
    updateAuthStatus(status as AuthStatus);
  };

  return (
    <RegistrationTemplate showLogout={false}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <AmplifyAuthenticator usernameAlias={'email'} handleAuthStateChange={handleAuthStateChange}>
          <AmplifySignIn slot="sign-in" usernameAlias="email">
            <div slot="secondary-footer-content" style={{ fontSize: 10 }}>
              By signing in, you agree to illio's{' '}
              <strong>
                <a href={'https://www.illio.com/terms-of-use'} target={'_blank'} style={{ color: 'black' }}>
                  Terms & Conditions
                </a>
              </strong>{' '}
              and{' '}
              <strong>
                <a href={'https://www.illio.com/privacy-policy'} target={'_blank'} style={{ color: 'black' }}>
                  Privacy Policy
                </a>
              </strong>
            </div>
          </AmplifySignIn>
        </AmplifyAuthenticator>
      </div>
    </RegistrationTemplate>
  );
};

export default SignInScreen;
