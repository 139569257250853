import { WorkBook } from 'xlsx';

export const oneOf = <T>(validItems: T[] | T, item: T) =>
  (Array.isArray(validItems) && validItems.indexOf(item) > -1) || validItems === item;

export const getSheetName = (workbook?: WorkBook, selectedSheetIndex?: number) =>
  typeof selectedSheetIndex === 'undefined'
    ? undefined
    : workbook?.Workbook?.Sheets?.[selectedSheetIndex]?.name || workbook?.SheetNames?.[selectedSheetIndex];

export const checkMapping = (
  columnMappings: Record<string, string>,
  item: { label: string; value: string; required?: boolean }
) => {
  const selfSatisfied = item.required ? !!columnMappings[item.value] : true;
  if (selfSatisfied) {
    return true;
  }

  switch (item.value) {
    case 'credit':
    case 'debit':
      return !!columnMappings.buySell;
    case 'buySell':
      return !!columnMappings.credit && !!columnMappings.debit;
    case 'ticker':
      return !!columnMappings.isin;
    case 'isin':
      return !!columnMappings.ticker;
  }

  return false;
};
