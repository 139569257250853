import * as React from 'react';
import s from './ProgressBar.module.scss';
import { useEffect, useState } from 'react';

interface IProps {
  percentage: number;
  color: string;
  loaded: boolean;
  withPercentage?: boolean;
}

const ProgressBar = ({ percentage, color, withPercentage, loaded }: IProps) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(percentage);
  }, [loaded]);

  return (
    <div>
      <div className={s.barContainer}>
        <div className={s.barContent} style={{ width: `${width}%`, backgroundColor: color }} />
      </div>
      <div style={{ height: 10 }} />
      <span className={s.percentage} style={{ marginLeft: `${percentage - 2}%`, opacity: loaded ? 1 : 0 }}>
        {percentage}
        {withPercentage && '%'}
      </span>
    </div>
  );
};

export default ProgressBar;
