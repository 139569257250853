import React, { PureComponent, Fragment } from 'react';
import { SliderBlock } from '../../../../components/SliderBlock';

import { Button } from '../../../../components/UIWidgets';
import { HISTORIC_EVENTS } from '../../../../services/constants/scenarios';
import s from './HistoricEvents.module.css';

interface IProps {
  selectedEvent: string | null;
  period: number;
  onEventSelect: (event: string) => void;
  onPeriodChange: (period: number) => void;
}

class HistoricEvents extends PureComponent<IProps> {
  render() {
    const { selectedEvent, period, onEventSelect, onPeriodChange } = this.props;
    return (
      <Fragment>
        <SliderBlock
          sliderOpt={{ min: 1, max: 24 }}
          label="Result after"
          units="m"
          value={period}
          onChange={onPeriodChange}
        />
        <div className={s.eventItemsList}>
          {HISTORIC_EVENTS.map((event, idx) => (
            <Button
              key={idx}
              active={event === selectedEvent}
              className={s.eventItem}
              onClick={() => onEventSelect(event)}
            >
              {event}
            </Button>
          ))}
        </div>
      </Fragment>
    );
  }
}

export default HistoricEvents;
