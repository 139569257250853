import React from 'react';
import { ALLOCATION_COLORS_STACK, ALLOCATION_TYPES } from '../constants/constants';
import _ from 'lodash';
import { usePortfolioSnapshot } from './apiHooks/usePortfolioSnapshot';
import { useAllocationColors } from './useAllocationColors';

export const useDonutdata = () => {
  const positions = usePortfolioSnapshot();
  const { sectors, currency, riskAssetClasses, countries } = useAllocationColors();

  const getColor = (name: string, allocationtype: ALLOCATION_TYPES) => {
    let allocations: IAllocation[] = [];

    switch (allocationtype) {
      case ALLOCATION_TYPES.AssetClass:
        allocations = riskAssetClasses;
        break;
      case ALLOCATION_TYPES.RiskAssetClass:
        allocations = riskAssetClasses;
        break;
      case ALLOCATION_TYPES.Sectors:
        allocations = sectors;
        break;
      case ALLOCATION_TYPES.Currency:
        allocations = currency;
        break;
      case ALLOCATION_TYPES.Region:
        allocations = countries.map((item: any) => ({ name: item.countryName, ...item }));
        break;
      default:
        allocations = [];
    }
    const found = allocations.find((item) => item.name === name);

    return found?.hexColourCode?.replace('0x', '#') || '#fbfbfb';
  };

  function getAllocationsWithInstrument(
    data: IPortfolioSnapshot[],
    field: keyof IPortfolioSnapshot,
    type: DonutEntryType = 'DEFAULT'
  ) {
    const STACK = ALLOCATION_COLORS_STACK;
    const getStack = () => {
      const tempStack = cloneStack(STACK);
      const howManyTimes = data.length / tempStack.length;
      for (let i = 0; i < howManyTimes - 1; i++) {
        tempStack.push(...cloneStack(STACK));
      }
      return tempStack;
    };

    const stack = getStack();
    // groups snapshot by assetSubClass
    const groupedAssetClass = _.groupBy(data, (item) =>
      field === 'assetSubClassId' ? item.assetSubClassId! + item.assetClassId! : item[field]
    );

    // get values array for each assetSubClass
    const values = Object.values(groupedAssetClass);
    const entries = values
      .map(
        (arr) =>
          arr.reduce((prev, current) => {
            return {
              ...prev,
              amount: prev.amount! + current.amount!,
              absoluteAmount: Math.abs((prev as any).absoluteAmount ?? 0) + Math.abs((current as any).amount! ?? 0),
              percentOfPortfolio: prev.percentOfPortfolio + current.percentOfPortfolio,
            };
          }) as { absoluteAmount: number } & IPortfolioSnapshot
      )
      .map((obj) => {
        const currentColor =
          field === 'assetSubClassId'
            ? getColor(obj.assetClassId!, ALLOCATION_TYPES.RiskAssetClass)
            : field === 'currencyNative'
            ? getColor(obj.currencyNative!, ALLOCATION_TYPES.Currency)
            : field === 'sector'
            ? getColor(obj.sector!, ALLOCATION_TYPES.Sectors)
            : stack.pop();
        const group = field === 'assetSubClassId' ? obj.assetSubClassId! + obj.assetClassId : (obj[field] as any);
        const id = field === 'assetSubClassId' ? obj.assetClassId : field === 'country' ? obj.region : obj[field];
        const name = field === 'assetSubClassId' ? obj.assetClassId : obj[field];
        return {
          type,
          name: obj[field],
          percentage: obj.percentOfPortfolio,
          value: obj.amount!,
          border: {
            color: 'red',
            width: 3,
          },
          absoluteValue: obj.absoluteAmount ?? 0, // (console.log(isNaN(parseFloat(String(obj.absoluteAmount))) ? "NAN!!!" : "OK", ((String(obj.absoluteAmount)))), parseFloat(String(obj.absoluteAmount))),
          id,
          instruments: groupedAssetClass[group].map((item: IPortfolioSnapshot, index: number) => ({
            type: 'INSTRUMENT',
            name: item.name,
            percentage: item.percentOfGrossPortfolio,
            id: item.name,
            value: item.amount,
            absoluteValue: Math.abs(item.amount!),
            color: addAlpha(currentColor!, index, groupedAssetClass[group].length),
          })),
          color: currentColor,
        };
      });

    return entries;
  }

  function getRegionEntries(data: IPortfolioSnapshot[]) {
    // we group by asset class
    const groupedRegion = _.groupBy(data, (item) => item.region);
    const values = Object.values(groupedRegion);
    const STACK = ALLOCATION_COLORS_STACK;
    const getStack = () => {
      const tempStack = cloneStack(STACK);
      const howManyTimes = data.length / tempStack.length;
      for (let i = 0; i < howManyTimes - 1; i++) {
        tempStack.push(...cloneStack(STACK));
      }
      return tempStack;
    };

    const stack = getStack();

    const regions: DonutMapEntry[] = values
      .map((arr) =>
        arr.reduce((prev, current) => ({
          ...prev,
          amount: prev.amount! + current.amount!,
          absoluteAmount: Math.abs((prev as any).absoluteAmount! ?? 0) + Math.abs(current.amount!),
          percentOfPortfolio: prev.percentOfPortfolio + current.percentOfPortfolio,
        }))
      )
      .map((obj) => {
        const color = stack.pop();
        return {
          name: obj.region!,
          percentage: obj.percentOfPortfolio,
          value: obj.amount,
          absoluteValue: Math.abs((obj as any).absoluteAmount!),
          id: obj.region!,
          color: getColor(obj.region!, ALLOCATION_TYPES.Region),
          type: 'Region',
        };
      });

    // const toReturn = Object.values(groupedAssetClass).reduce((prev, curr) => ({name:prev.name, value:prev.amount + curr.value, percentage: }), Object.create(null));
    const subClasses = getAllocationsWithInstrument(data, 'country');

    return regions.map((ass) => ({
      ...ass,
      subClasses: subClasses
        .filter((sub) => sub.id === ass.name)
        .map((item) => ({
          ...item,
          color: ass.color,
          instruments: item.instruments.map((ins, index) => ({
            ...ins,
            color: addAlpha(ass.color, index, item.instruments.length),
          })),
        })),
    }));
  }

  function getAssetClassEntries(data: IPortfolioSnapshot[]) {
    // we group by asset class
    const groupedAssetClass = _.groupBy(data, (item) => item.assetClassId);
    const values = Object.values(groupedAssetClass);

    const totals: Record<string, { long: number; short: number; total: number; abs: number }> = {};

    Object.entries(groupedAssetClass).forEach(([key, value]) => {
      if (typeof totals[key] === 'undefined') {
        totals[key] = {
          long: 0,
          short: 0,
          abs: 0,
          total: 0,
        };
      }

      totals[key].long += value.reduce((acc, curr) => acc + Math.max(curr.amount ?? 0, 0), 0);
      totals[key].short += value.reduce((acc, curr) => acc + Math.min(curr.amount ?? 0, 0), 0);
      totals[key].total += totals[key].long + totals[key].short;
      totals[key].abs += totals[key].long - totals[key].short;
    });
    const assetClasses: DonutMapEntry[] = values
      .map((arr) =>
        arr.reduce((acc, current) => ({
          ...acc,
          // amount: (acc.amount!) + (current.amount!),
          // absoluteAmount: Math.abs((acc as any).absoluteAmount! ?? 0) + Math.abs(current.amount!),
          percentOfPortfolio: acc.percentOfPortfolio + current.percentOfPortfolio,
        }))
      )
      .map((obj) => ({
        name: obj.assetClassId!,
        percentage: obj.percentOfPortfolio,
        value: totals[obj.assetClassId!].total,
        absoluteValue: totals[obj.assetClassId!].abs,
        id: obj.assetClassId!,
        color: getColor(obj.assetClassId!, ALLOCATION_TYPES.RiskAssetClass),
        type: 'AssetClass',
      }));

    const subClasses = getAllocationsWithInstrument(data, 'assetSubClassId', 'AssetSubClass');

    return assetClasses.map((ass) => ({ ...ass, subClasses: subClasses.filter((sub) => sub.id === ass.name) }));
  }

  function getDonutMapEntries(content: IPortfolioSnapshot[], allocationType: string) {
    const filteredContent = content;
    switch (allocationType) {
      // if asset class
      case ALLOCATION_TYPES.AssetClass:
        return getAssetClassEntries(filteredContent);
      case ALLOCATION_TYPES.Region:
        return getRegionEntries(filteredContent);
      case ALLOCATION_TYPES.Custodian:
        return getAllocationsWithInstrument(filteredContent, 'custodian');
      case ALLOCATION_TYPES.Currency:
        return getAllocationsWithInstrument(filteredContent, 'currencyNative');
      case ALLOCATION_TYPES.Sectors:
        return getAllocationsWithInstrument(filteredContent, 'sector');
      default:
        return [];
    }
  }

  const donutMap = React.useMemo(() => {
    const content = positions.data?.content;

    if (!content) {
      return null;
    }
    const data = {
      [ALLOCATION_TYPES.AssetClass]: getDonutMapEntries(content, ALLOCATION_TYPES.AssetClass),
      [ALLOCATION_TYPES.Region]: getDonutMapEntries(content, ALLOCATION_TYPES.Region),
      [ALLOCATION_TYPES.Custodian]: getDonutMapEntries(content, ALLOCATION_TYPES.Custodian),
      [ALLOCATION_TYPES.Currency]: getDonutMapEntries(content, ALLOCATION_TYPES.Currency),
      [ALLOCATION_TYPES.Sectors]: getDonutMapEntries(content, ALLOCATION_TYPES.Sectors),
    };

    return data;
  }, [positions.data]);

  return {
    positions,
    donutMap,
  };
};

// we group by asset class
function cloneStack(STACK: string[]): string[] {
  return [...STACK];
}

function increase_brightness(hex: string, percent: number) {
  // strip the leading # if it's there
  hex = hex?.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex?.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}

function addAlpha(color: string, index: number, length: number) {
  let interval;
  if (length > 39) {
    interval = 0.25;
  } else if (length > 29) {
    interval = 1.25;
  } else if (length > 19) {
    interval = 2.5;
  } else if (length > 9) {
    interval = 5;
  } else {
    interval = 10;
  }
  const percent = interval * index;
  // if 20 element we want 95 90 85
  // if 10 we want 90 80 70 60
  // if 5 we want 90 80 70 60
  return increase_brightness(color, percent);
}
