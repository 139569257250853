import { toast } from 'react-toastify';
import get from 'lodash/get';

export const handle404 = (e: any) => {
  const message = get(e, 'response.data.error.message');

  if (handlePriceService(e)) {
    return;
  }

  if (message) {
    toast.warning(message);
  } else {
    toast.warning('404 - resource not found', {
      toastId: 'resourceNotFound',
    });
  }
};

const handlePriceService = (e: any) => {
  const url = get(e, 'config.url');
  const match = /api\/v.+\/price/;
  const isPriceError = !!url && match.test(url);
  if (!isPriceError) {
    return false;
  }

  const instrument = get(e, 'config.params.sourceId') || 'that instrument';
  const date = get(e, 'config.params.date') || 'that date';

  toast.info(`No price found for ${instrument} on ${date}`);

  return true;
};
