import React, { Component, Fragment, ReactNode } from 'react';

import calcPic from '../../../../static/images/dashboard/calcV2.svg';
import s from './WorkGroupItem.module.scss';
import { IRootState } from '../../../../services/store';
import {
  formatPriceValue,
  formatPriceValueCurrency,
  getPortfolioBlotterLink,
  getPortfolioLink,
  getPortfoliosLink,
  getTradeColor,
  getValueColor,
} from '../../../PortfoliosManager/utils';
import { WorkGroupInfoBlock } from './WorkGroupItemInfoblock';
import { WorkGroupItemIndicator } from './WorkGroupItemIndicator';
import { Link } from 'react-router-dom';
import { Button, DropDown } from '../../../../components';
import cn from 'classnames';
import CapabilitiesHelper from '../../../../services/utils/CapabilitiesHelper';
import { PATHS } from '../../../../router/paths';
import PopoverDropdown from '../../../../components/PopoverDropdown/PopoverDropdown';
import image from '../../../../static/images/progress.svg';

interface IProps {
  workGroup: IPortfolio[];
  userCapabilities: IRootState['userCapabilities']['data'];
  cardSize: 'Small' | 'Big';
  onDelete: (portfolio: IPortfolio) => void;
  onRename: (portfolio: IPortfolio[]) => void;
  parentRef: React.RefObject<HTMLDivElement>;
}

interface IState {
  editMode: boolean;
  editText: string;
}

class WorkGroupItem extends Component<IProps, IState> {
  readonly state: IState = {
    editMode: false,
    editText: '',
  };

  renderWorkGroupTitle(workgroup: IPortfolio[]) {
    const { cardSize } = this.props;
    return (
      <Fragment>
        <div style={{ flexDirection: 'row' }}>
          {!workgroup[0].workgroup.clientUser && (
            <span
              style={{
                fontSize: cardSize === 'Small' ? 10 : 13,
                color: '#576382',
              }}
              className={s.idSpan}
            >
              Unassigned &nbsp;
            </span>
          )}
          <span style={{ fontSize: cardSize === 'Small' ? 10 : 13 }} className={s.idSpan}>
            {workgroup[0].workgroup.name.slice(0, 20)}
          </span>
          <div
            style={{
              zIndex: 999,
              display: 'flex',
              float: 'right',
              marginTop: -10,
              flexDirection: 'row',
              width: 35,
              justifyContent: 'flex-end',
              marginRight: -15,
              alignItems: 'center',
            }}
          >
            {this.hasTradesInTheBlotter && (
              <PopoverDropdown
                style={{ marginRight: 8.5 }}
                parentRef={this.props.parentRef}
                placeholderContent={
                  <div className={s.blotterIcon}>
                    <img height={20} src={image} alt={'trades in the blotter trades'} />
                  </div>
                }
              >
                {this.renderBlotterDropDownContent()}
              </PopoverDropdown>
            )}
            {workgroup.length > 1 && (
              <PopoverDropdown
                style={{ marginRight: 5, marginTop: 2.5 }}
                parentRef={this.props.parentRef}
                placeholderContent={<WorkGroupItemIndicator workGroup={workgroup} />}
              >
                {this.renderIndicatorDropDownContent()}
              </PopoverDropdown>
            )}
            <PopoverDropdown parentRef={this.props.parentRef}>{this.renderEllipsisDropDown()}</PopoverDropdown>
          </div>
        </div>
      </Fragment>
    );
  }

  renderEllipsisDropDown = (): ReactNode => {
    const { workGroup, onDelete, onRename } = this.props;

    const canDeletePortfolio = CapabilitiesHelper.isActionAllowed('DELETE_PORTFOLIO', this.props.userCapabilities);

    return (
      <Fragment>
        {canDeletePortfolio && (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {workGroup.length === 1 && (
              <Link
                to={{
                  pathname: PATHS.portfoliosManager.editSettings.path.replace(':portfolioId', workGroup[0].id),
                }}
                className={'drop-down__item'}
              >
                <Button variant="empty">Preferences</Button>
              </Link>
            )}

            <Link
              to={{
                pathname: PATHS.portfoliosManager.portfolioShare.path.replace(':portfolioId', workGroup[0].id),
              }}
              className={'drop-down__item'}
            >
              <Button variant="empty">Manager Sharing</Button>
            </Link>
            {!workGroup[0].workgroup.clientUser && (
              <Link
                to={{
                  pathname: PATHS.portfoliosManager.portfolioAssign.path.replace(':portfolioId', workGroup[0].id),
                }}
                className={'drop-down__item'}
              >
                <Button variant="empty">Assign to Client</Button>
              </Link>
            )}
            {workGroup.length === 1 && (
              <div className={'drop-down__item'}>
                <Button variant="empty" onClick={() => onDelete(workGroup[0])}>
                  Delete
                </Button>
              </div>
            )}
            <div className={'drop-down__item'}>
              <Button variant="empty" onClick={() => onRename(workGroup)}>
                Rename
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  renderBlotterDropDownContent = (): ReactNode => {
    const { workGroup } = this.props;
    const links = workGroup
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((p) => p.blotterTrades.totalTrades > 0)
      .map((portfolio) => (
        <Link key={portfolio.id} to={getPortfolioBlotterLink(portfolio)} className={s.dropdownItem}>
          <Button variant="empty">
            <div className={s.dropdownContainer}>
              <span>{portfolio.name.slice(0, 25)}</span>
              <span style={{ color: getTradeColor(portfolio) }}>
                {portfolio.blotterTrades.totalTrades} {portfolio.blotterTrades.totalTrades > 1 ? 'trades' : 'trade'}
              </span>
            </div>
          </Button>
        </Link>
      ));

    return (
      <Fragment>
        <span className={s.dropdownTitle}>Trades in the blotter:</span>
        {links}
      </Fragment>
    );
  };

  renderIndicatorDropDownContent = (): ReactNode => {
    const { workGroup } = this.props;
    const links = workGroup
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((portfolio) => (
        <Link key={portfolio.id} to={getPortfolioLink(portfolio)} className={s.dropdownItem}>
          <Button variant="empty">
            <div className={s.dropdownContainer}>
              <span>{portfolio.name.slice(0, 25)}</span>
              <span style={{ color: getValueColor(portfolio.totalWealth) }}>
                {formatPriceValueCurrency(portfolio.totalWealth, portfolio.currency.name)}
              </span>
            </div>
          </Button>
        </Link>
      ));

    return <Fragment>{links}</Fragment>;
  };

  renderWorkGroupBody(workgroup: IPortfolio[]) {
    const { cardSize } = this.props;
    const profitAndLoss = workgroup.reduce((accumulator, current) => accumulator + current.rollup.pnl, 0);
    const totalValue = workgroup.reduce((accumulator, current) => accumulator + current.rollup.value, 0);

    if (cardSize === 'Big') {
      return (
        <Fragment>
          <div className={s.workGroupItemContainer} style={{ marginTop: 10 }}>
            <div className={s.workGroupItemInfoBlock}>
              <WorkGroupInfoBlock
                currencySymbol={workgroup[0].rollup.currencyCode}
                title={'Profit & Loss'}
                value={profitAndLoss}
              />
            </div>
            <div className={s.workGroupItemInfoBlock}>
              <WorkGroupInfoBlock currencySymbol={workgroup[0].rollup.currencyCode} title={'Value'} value={totalValue} />
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className={s.workGroupItemContainer} style={{ marginTop: 5 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: 2 }} className={s.label}>
              P&L
            </span>
            <span style={{ color: getValueColor(profitAndLoss) }} className={s.label}>
              {' '}
              {formatPriceValueCurrency(profitAndLoss, workgroup[0].rollup.currencyCode)}
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: 2 }} className={s.label}>
              Value
            </span>
            <span style={{ color: getValueColor(totalValue) }} className={s.label}>
              {' '}
              {formatPriceValueCurrency(totalValue, workgroup[0].rollup.currencyCode)}
            </span>
          </div>
        </div>
      </Fragment>
    );
  }

  hasTradesInTheBlotter = this.props.workGroup.some((p) => p.blotterTrades.totalTrades > 0);

  hasWarnings = () => {
    const { workGroup } = this.props;
    return workGroup[0].workgroup.clientUser === null || workGroup.some((w) => w.totalWealth < 0);
  };

  getItemBorder = () => {
    const { workGroup } = this.props;

    // priority on totalWealth < 0
    if (workGroup.some((w) => w.totalWealth < 0)) {
      return { border: '0.5px solid rgb(254 211 48 / 52%)' };
    }
    if (workGroup[0].workgroup.clientUser === null) {
      return { border: '0.5px solid #576382' };
    }

    return {};
  };

  getItemWidth = () => {
    const { cardSize } = this.props;
    if (cardSize === 'Small') {
      return { width: 'calc(20% - 10px)' };
    }
  };

  render() {
    const { workGroup, cardSize } = this.props;
    return (
      <Link
        to={
          workGroup.length === 1
            ? getPortfolioLink(workGroup[0])
            : getPortfoliosLink(workGroup[0].workgroup.id.toString())
        }
        style={{
          ...this.getItemBorder(),
        }}
        className={cn(s.wrapper, cardSize === 'Big' ? s.cardBig : s.cardSmall)}
      >
        {this.renderWorkGroupTitle(workGroup)}
        {this.renderWorkGroupBody(workGroup)}
      </Link>
    );
  }
}

export default WorkGroupItem;
