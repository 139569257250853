import React, { PureComponent } from 'react';
import s from './ModalWindow.module.css';

import { Button } from '../UIWidgets/Button';

export interface IModalWindowProps {
  withEsc: boolean;
}

class ModalWindow extends PureComponent<IModalWindowProps> {
  static readonly defaultProps: Partial<IModalWindowProps> = {
    withEsc: true,
  };

  escFunction = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      window.history.back();
    }
  };

  handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.history.back();
  };

  componentDidMount() {
    if (!this.props.withEsc) {
      return;
    }

    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    if (!this.props.withEsc) {
      return;
    }

    document.removeEventListener('keydown', this.escFunction, false);
  }

  render() {
    return (
      <div className={s.overlay}>
        {this.props.withEsc && (
          <Button variant="empty" className={s.closeModalBtn} onClick={this.handleCloseClick}>
            ESC
          </Button>
        )}
        <div className={s.modalWindow}>{this.props.children}</div>
      </div>
    );
  }
}

export default ModalWindow;
