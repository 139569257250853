import { Color } from 'csstype';
import echarts from 'echarts/lib/echarts';
import moment from 'moment';
import {
  areaGradient,
  chartColor,
  DEFAULT_BAR_SERIES_OPTIONS,
  DEFAULT_CHART_OPTIONS,
  DEFAULT_LINE_SERIES_OPTIONS,
  DEFAULT_TOOLTIP_OPTIONS,
  DEFAULT_XAXIS_TIME_OPTIONS,
  DEFAULT_ZOOM_OPTIONS,
} from '../constants/charts';
import { DATE_FORMAT, PERIOD_TYPE } from '../constants/constants';
import { ICurrencyFormatter } from '../selectors/portfolio';
import { CanvasHelpers } from './CanvasHelpers';
import { ColorsHelper } from './ColorsHelper';
import { FormatHelper, VALUE_TYPE } from './FormatHelper';

type FormatterParam = echarts.EChartOption.Tooltip.Format;
type BarSeries = echarts.EChartOption.SeriesBar;
type LineSeries = echarts.EChartOption.SeriesLine;
type EChartOption = echarts.EChartOption;

interface IGradientOptions {
  color: Color;
  from: Color;
  to: Color;
}

export class ChartHelper {
  static getChartOptions(
    opt: {
      tooltip?: boolean;
      tooltipValueType?: VALUE_TYPE;
      showTotal?: boolean;
      portfolioCurrencyFormatter: ICurrencyFormatter;
      hideTooltipZero?: boolean;
    } = {
      showTotal: false,
      tooltip: true,
      tooltipValueType: VALUE_TYPE.PERCENTAGE,
      portfolioCurrencyFormatter: (price?: number) => (price ? price.toString() : ''),
      hideTooltipZero: false,
    }
  ): EChartOption {
    return {
      ...DEFAULT_CHART_OPTIONS,
      tooltip:
        opt.tooltip || opt.tooltipValueType
          ? ChartHelper.formatTooltip(
              opt.portfolioCurrencyFormatter,
              opt.tooltipValueType,
              undefined,
              opt.showTotal,
              opt.hideTooltipZero
            )
          : {},
    };
  }

  static getTimelineChartOptions(
    opt: {
      tooltip?: boolean;
      tooltipValueType?: VALUE_TYPE;
      zoom?: boolean;
      portfolioCurrencyFormatter: ICurrencyFormatter;
    } = {
      tooltip: true,
      tooltipValueType: VALUE_TYPE.MONEY,
      portfolioCurrencyFormatter: (price?: number) => (price ? price.toString() : ''),
      zoom: true,
    }
  ): EChartOption {
    return {
      ...DEFAULT_CHART_OPTIONS,
      xAxis: { ...DEFAULT_XAXIS_TIME_OPTIONS },
      tooltip:
        opt.tooltip || opt.tooltipValueType
          ? ChartHelper.formatTooltip(opt.portfolioCurrencyFormatter, opt.tooltipValueType)
          : {},
      dataZoom: opt.zoom ? [...DEFAULT_ZOOM_OPTIONS] : [],
    };
  }

  static getTimelineChartOptionsNoTooltip(
    opt: {
      tooltip?: boolean;
      tooltipValueType?: VALUE_TYPE;
      zoom?: boolean;
      portfolioCurrencyFormatter?: ICurrencyFormatter;
    } = {
      tooltip: true,
      zoom: true,
      portfolioCurrencyFormatter: (price?: number) => (price ? price.toString() : ''),
    }
  ): EChartOption {
    return {
      ...DEFAULT_CHART_OPTIONS,
      xAxis: { ...DEFAULT_XAXIS_TIME_OPTIONS },
      dataZoom: opt.zoom ? [...DEFAULT_ZOOM_OPTIONS] : [],
    };
  }

  // SeriesLine
  static getLineSeriesOptions(color: Color = chartColor): LineSeries {
    const lineSeries = {
      ...DEFAULT_LINE_SERIES_OPTIONS,
    };
    lineSeries.itemStyle = {
      ...DEFAULT_LINE_SERIES_OPTIONS.itemStyle,
      color,
    };
    return lineSeries;
  }

  static getLineSeriesOptionsWithGradient(
    params: IGradientOptions = { color: chartColor, ...areaGradient }
  ): LineSeries {
    const lineSeries = { ...this.getLineSeriesOptions(params.color) };
    lineSeries.areaStyle = {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: params.from,
          },
          {
            offset: 1,
            color: params.to,
          },
        ],
      },
    };
    return lineSeries;
  }

  static getLineSeriesOptionsWithRadialGradient(
    params: IGradientOptions = { color: chartColor, ...areaGradient }
  ): LineSeries {
    const lineSeries = { ...this.getLineSeriesOptions(params.color) };
    lineSeries.areaStyle = {
      origin: 'start',
      color: {
        type: 'radial',
        x: 0.5,
        y: 0.1,
        r: 0.8,
        colorStops: [
          {
            offset: 0,
            color: ColorsHelper.toRGBA(params.from, 40),
          },
          {
            offset: 1,
            color: params.to,
          },
        ],
        global: false,
      },
    };
    return lineSeries;
  }

  // SeriesBar
  static getBarSeriesOptions(color: Color = chartColor): BarSeries {
    const barSeries: BarSeries = {
      ...DEFAULT_BAR_SERIES_OPTIONS,
      type: 'bar',
      barMaxWidth: 24,
      itemStyle: {
        color: chartColor,
      },
    };
    barSeries.itemStyle = {
      color,
    };
    return barSeries;
  }
  static getLineInBarSeriesOptions2(color: Color = chartColor): any {
    const barSeries: any = {
      ...DEFAULT_LINE_SERIES_OPTIONS,
      type: 'line',
      showSymbol: true,
      symbol: 'circle',
      hoverAnimation: false,
      symbolSize: 4,
      itemStyle: {
        color: chartColor,
      },
    };
    barSeries.itemStyle = {
      color,
    };
    return barSeries;
  }
  static getBar2SeriesOptions(color: Color = chartColor): BarSeries {
    const barSeries: BarSeries = {
      ...DEFAULT_BAR_SERIES_OPTIONS,
      type: 'bar',
      barMaxWidth: 24,
    };
    barSeries.itemStyle = {
      color,
    };

    return barSeries;
  }

  static getHatchingBarSeriesOptions(color: Color = chartColor): BarSeries {
    const barsOptions = this.getBarSeriesOptions(color);
    return {
      ...barsOptions,
      itemStyle: {
        color: {
          image: CanvasHelpers.getHatchingCanvas(color),
          repeat: 'repeat',
        },
      },
    };
  }

  static getLineInBarSeriesOptions(color: Color = chartColor): BarSeries {
    const lineOptions = this.getLineInBarSeriesOptions2(color);
    return {
      ...lineOptions,
    };
  }

  static getBarSeriesOptionsWithGradient(gradient: { from: Color; to: Color } = areaGradient): BarSeries {
    const color: echarts.EChartOption.Gradient = {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: gradient.from,
        },
        {
          offset: 1,
          color: gradient.to,
        },
      ],
    };

    const barSeries: BarSeries = this.getBarSeriesOptions();
    return { ...barSeries, itemStyle: { ...barSeries.itemStyle, color } };
  }

  static formatTooltip(
    portfolioCurrencyFormatter?: ICurrencyFormatter,
    valueType?: VALUE_TYPE,
    selectedCurrency: string = 'All',
    showTotal?: boolean,
    hideTooltipZero?: boolean
  ) {
    return {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (params: FormatterParam | FormatterParam[]): string => {
        let date = Array.isArray(params) ? params[0].axisValue : params.axisValue;
        if (date && moment(new Date(date)).isValid()) {
          date = moment(date).format(DATE_FORMAT);
        }
        const valueFormatter = (value: number) =>
          portfolioCurrencyFormatter && selectedCurrency === 'All'
            ? portfolioCurrencyFormatter(value)
            : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(value, 0);

        // calculating the total to show on the tooltip
        let tooltipText = `${date}<br>`;

        if (showTotal) {
          const total = Array.isArray(params)
            ? params.reduce((acc, cur) => acc + (Array.isArray(cur.value) ? cur.value[1] : cur.value), 0)
            : (params.value as number);
          tooltipText += `<span>Total: ${valueFormatter(total)}</span><br>`;
        }

        const formatName = (item: FormatterParam) => {
          if (!item.seriesName || item.seriesName.includes('series')) {
            return '';
          }
          return `${item.seriesName}: `;
        };

        const textGen = (item: FormatterParam) => {
          const value = Array.isArray(item.value) ? item.value[1] : item.value;
          let valueText = '';
          if (valueType === VALUE_TYPE.MONEY) {
            valueText = valueFormatter(value);
          } else if (valueType === VALUE_TYPE.PERCENTAGE) {
            valueText = FormatHelper.formatPercentage(value);
          } else {
            valueText = value.toString();
            // console.error(`Unknown type ${valueType}`);
          }

          const serieMarker: string = ChartHelper.getTooltipLegend(item);
          const serieName = formatName(item);

          tooltipText += `${serieMarker}${serieName} ${valueText}<br>`;
        };

        // hiding tooltip zero
        if (Array.isArray(params)) {
          (hideTooltipZero
            ? params.filter((item: any) => item.value[1] !== 0)
            : params
          ).forEach((item: FormatterParam) => textGen(item));
        } else {
          textGen(params);
        }
        return tooltipText;
      },
    };
  }

  static formatDataMappedChartTooltip() {
    const tTip = {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (params: any) => {
        return `${moment(params.value[0]).format(
          'DD-MMM-YYYY'
        )}<br> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
          params.color
        };"></span>${params.seriesName}: ${params.value[1]}`;
      },
    };

    return tTip;
  }

  /*let date = Array.isArray(params) ? params.value[params.encode.y[0]].axisValue : params.axisValue;
        if (date && moment(new Date(date)).isValid()) {
          date = moment(date).format(DATE_FORMAT);
        }

        let tooltipText = `${date}<br>`;

        const formatName = (item: FormatterParam) => {
          if (!item.seriesName || item.seriesName.includes('series')) {
            return '';
          }
          return `${item.seriesName}: `;
        };

        const textGen = (item: FormatterParam) => {
          const value = Array.isArray(item.value) ? item.value[1] : item.value;
          let valueText = '';
          if (valueType === VALUE_TYPE.MONEY) {
            valueText = portfolioCurrencyFormatter &&
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(value)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(value, 0);
          } else if (valueType === VALUE_TYPE.PERCENTAGE) {
            valueText = FormatHelper.formatPercentage(value);
          } else {
            valueText = value.toString();
            // console.error(`Unknown type ${valueType}`);
          }

          const serieMarker: string = ChartHelper.getTooltipLegend(item);
          const serieName = formatName(item);

          tooltipText += `${serieMarker}${serieName} ${valueText}<br>`;
        };

        if (Array.isArray(params)) {
          params.forEach((item: FormatterParam) => textGen(item));
        } else {
          textGen(params);
        }*/

  static getTooltipLegend(item: FormatterParam): string {
    let serieMarker: string = item.marker;
    // if color is object (hatching background)
    if (typeof item.color !== 'string') {
      serieMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:url(${item.color.image.toDataURL()});"></span>`;
    }
    return serieMarker;
  }

  static getTimeAxisLabelFormatter(period: IPeriod) {
    const from = moment(period.from ? period.from.toString() : '');
    const to = moment(period.to ? period.to.toString() : '');

    if (period.type === PERIOD_TYPE.MTD || (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'days') < 31)) {
      return {
        xAxis: {
          axisLabel: {
            formatter(value: string): any {
              if (moment(value, 'YYYY-MM-DD', true).isValid()) {
                const momentVal = moment(value);
                return momentVal.format("DD MMM'YY");
              }
              const words = value.toString().split(' ');
              if (words.length > 2) {
                return words[0];
              }
              return value;
            },
          },
        },
      };
    } else {
      return {
        xAxis: {
          axisLabel: {
            formatter(value: string): any {
              if (moment(value, 'YYYY-MM-DD', true).isValid()) {
                const momentVal = moment(value);
                return momentVal.format("MMM'YY");
              }
              const words = value.toString().split(' ');
              if (words.length > 2) {
                return words[0];
              }
              return value;
            },
          },
        },
      };
    }
  }

  static getStaticTimeAxisLabelFormatter() {
    return {
      xAxis: {
        axisLabel: {
          formatter(value: string): any {
            if (moment(value, 'YYYY-MM-DD', true).isValid()) {
              const momentVal = moment(value);
              return momentVal.format("DD MMM'YY");
            }
            const words = value.toString().split(' ');
            if (words.length > 2) {
              return words[0];
            }
            return value;
          },
        },
      },
    };
  }
}
