import React, { useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { Field } from 'redux-form';

import renderAutocomplete from '../../../components/ReduxForm/renderAutocomplete';
import RenderList from '../../../components/ReduxForm/renderList';
import s from './PortfolioFormsCommon.module.scss';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../components/ContextHelp';
import { IOption } from '../../../components/UIWidgets/Autocomplete';
import { NotificationType } from '../../../models/NotifictionType';
import { PORTFOLIO_CREATE_HELP_TEXT } from '../../../services/constants/tooltipContextHelp';
import { useAccount } from '../../../services/context/AuthContext';

import { Panel } from 'rsuite';
import { useModal } from '../../../services/context/ModalContext';

interface IPorfolioCustodianListPanel {
  temp: boolean;
  required: boolean;
  custodian: ICustodian | null;
  customCustodian: string;
  portfolioCustodians: ICustodian[];
  serverCustodians: ICustodian[];
  onlyManualDividendsDefault: boolean;
  updateCustomCustodian: (custodian: string) => void;
  updateCustodian: (custodian: ICustodian | null) => void;
  updatePortfolioCustodians: (portfolioCustodians: ICustodian[]) => void;
  showNotification: (notification: INotification) => void;
}

export const PortfolioCustodianListPanel = (props: IPorfolioCustodianListPanel) => {
  const { userCapabilities } = useAccount();
  const { customCustodian, portfolioCustodians } = props;
  const preferences = userCapabilities?.preferences;
  const { search, setSearch } = useModal();

  useEffect(() => {
    if (search.length) {
      const found = props.serverCustodians.find((item) => item.name === search);
      if (found) {
        setTimeout(() => {
          handleAddCustodian({ ...found, temp: true, onlyManualDividends: preferences?.onlyManualDividendsDefault });
          setSearch('');
        }, 500);
      } else {
        setTimeout(() => {
          handleAddCustodian(
            {
              id: '',
              code: search.trim(),
              name: search.trim(),
              onlyManualDividends: preferences?.onlyManualDividendsDefault,
              temp: true,
              default: props.portfolioCustodians?.length < 1 ? true : false,
            },
            'custom'
          );
        }, 500);
      }
    }
  }, [search]);

  const handleAddCustodian = (newCustodian: ICustodian, type: string = 'default') => {
    const newCustodianIndex = portfolioCustodians.findIndex((c) => nameAndCodeMatch(c, newCustodian));
    const serverportfolioCustodiansIndex = props.serverCustodians.findIndex((c) => nameAndCodeMatch(c, newCustodian));
    const generateDefaultCustodian = (fill?: { default: boolean }) =>
      props.portfolioCustodians.length === 0 ? { ...newCustodian, ...fill } : newCustodian;
    const showError = () => {
      props.showNotification({
        text: `${newCustodian.name} exists. The custodian was not added!`,
        type: NotificationType.ERROR,
      });
    };
    if (type === 'default') {
      if (newCustodianIndex === -1) {
        props.updatePortfolioCustodians([...props.portfolioCustodians, generateDefaultCustodian({ default: true })]);
      } else {
        showError();
      }
    } else {
      if (newCustodianIndex === -1 && serverportfolioCustodiansIndex === -1) {
        props.updatePortfolioCustodians([...props.portfolioCustodians, generateDefaultCustodian()]);
      } else {
        showError();
      }
    }
    props.updateCustodian(null);
    props.updateCustomCustodian('');
  };

  const handleRemoveCustodian = (custodian: ICustodian) => {
    props.updatePortfolioCustodians(props.portfolioCustodians?.filter((item) => custodian.code !== item.code));
  };

  const toggleDividendsForCustodian = (custodian: ICustodian) => {
    const portfolioCustodians = props.portfolioCustodians?.map((c: ICustodian) => {
      if (c.code === custodian.code) {
        c.onlyManualDividends = !custodian.onlyManualDividends;
      }
      return c;
    });
    props.updatePortfolioCustodians(portfolioCustodians);
  };

  const handleMakeDefaultCustodian = (custodian: ICustodian) => {
    const portfolioCustodians = props.portfolioCustodians?.map((c: ICustodian) => {
      c.default = c.code === custodian.code;
      return c;
    });
    props.updatePortfolioCustodians(portfolioCustodians);
  };

  const isWithError = getTextValidationErrors(customCustodian);

  return (
    <Panel
      style={{ background: 'var(--dark)', marginBottom: '1.5rem', overflow: 'visible' }}
      shaded={true}
      collapsible={false}
    >
      <div>
        <div className={s.labelContainer}>
          <h4>
            Add broker / custodian for trades* <ContextHelpIcon title={'Broker/Custodian'} elementId={'lblCustodian'} />
          </h4>
        </div>

        <div className={cn(s.custodianRow, s.comboPseudoContainer)}>
          <Field
            name="custodian"
            component={renderAutocomplete}
            options={props.serverCustodians}
            onTyping={(search: any) => {
              props.updateCustomCustodian(search);
            }}
            filter={(search: string, option: IOption<ICustodian>) =>
              option.name.toLowerCase().includes(search.toLowerCase())
            }
            isCreatable={true}
            onSelectOption={(item: string | ICustodian) => {
              switch (typeof item) {
                case 'string':
                  if (!_.inRange(item.length, 2, 31) || !/^[ a-zA-Z-\'_0-9]+$/.test(item)) {
                    props.showNotification({
                      text: `Only alphanumeric, spaces, underscores, and dash characters between 2 and 30 characters`,
                      type: NotificationType.ERROR,
                    });
                    break;
                  }
                  handleAddCustodian(
                    {
                      id: '',
                      code: item.trim(),
                      name: item.trim(),
                      onlyManualDividends: preferences?.onlyManualDividendsDefault,
                      temp: true,
                      default: props.portfolioCustodians?.length < 1 ? true : false,
                    },
                    'custom'
                  );
                  break;
                case 'object':
                  console.log('WITH OBJECT');
                  handleAddCustodian({
                    ...item,
                    temp: true,
                    onlyManualDividends: preferences?.onlyManualDividendsDefault,
                  });
                  break;
                default:
                  break;
              }
            }}
            inverseTheme={false}
            inputProps={{
              placeholder: 'Select or enter a custom custodian - press Enter when done',
              required: props.required,
            }}
          />
          <div className={s.comboPseudoArrow} />
          <ContextHelpTooltip
            elementId={'lblCustodian'}
            tooltipTitle={'Broker/Custodian'}
            tooltipText={PORTFOLIO_CREATE_HELP_TEXT.lblCustodian}
          />
        </div>
        {isWithError && (
          <div className="input__error-text" style={{ paddingLeft: 15, marginBottom: 15 }}>
            {isWithError}
          </div>
        )}
      </div>
      <div>
        <div className={s.labelTable} style={{ marginTop: '1rem', marginBottom: 0 }}>
          <div className={s.labelContainer} style={{ width: '20rem' }}>
            <h4>Brokers / custodians</h4>
          </div>
          <div className={s.labelContainer}>
            <h4>
              Dividend Source <ContextHelpIcon title={'Dividend Source'} elementId={'lblTrades'} />
            </h4>
            <ContextHelpTooltip
              tooltipTitle={'Dividend Source'}
              elementId={'lblTrades'}
              tooltipText={PORTFOLIO_CREATE_HELP_TEXT.lblTrades}
            />
          </div>
        </div>
        <div className={cn(s.indexRow)}>
          <RenderList
            elements={props.portfolioCustodians?.map((c) => ({ ...c, unremovable: c.hasTradesAssociated }))}
            toggleDividends={toggleDividendsForCustodian}
            toggleStateOnCreate={!props.onlyManualDividendsDefault}
            onRemove={handleRemoveCustodian}
            onDefault={handleMakeDefaultCustodian}
          />
        </div>
      </div>
    </Panel>
  );
};

const ERRORS = {
  BAD_TEXT_LENGTH: 'Must be between 2 and 40 characters.',
  BAD_TEXT_FORMAT: 'Only alphanumeric, spaces, underscores, ampersand, full stop, asterisk and dash characters.',
};

function getTextValidationErrors(txt: string) {
  if (txt && !_.inRange(txt.length, 2, 41)) {
    return ERRORS.BAD_TEXT_LENGTH;
  }
  if (txt && !/^([a-zA-Z0-9-_ & *.])+()$/i.test(txt)) {
    return ERRORS.BAD_TEXT_FORMAT;
  }
  return null;
}

type CanBeCompared = Pick<ICustodian, 'name' | 'code'>;

// accepts any shape looking having code and name
function nameAndCodeMatch(listElement: CanBeCompared, toFind: CanBeCompared) {
  const lName = listElement.name.toLowerCase();
  const lCode = listElement.code.toLowerCase();
  const fName = toFind.name.toLowerCase();
  const fCode = toFind.code.toLowerCase();

  return lName === fName || lCode === fCode;
}
