import React, { PureComponent } from 'react';
import { SortDirectionType, TableCellProps } from 'react-virtualized';
import { Table } from '@iliotech/storybook';

import { ITopListItem } from '../../../../models/ITopListItem';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';

interface IProps {
  items: ITopListItem[];
  itemsOrder: IOrder;
  totalItems: number;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  columns: ColumnProps[];
  onRowClick?: any;
  onLoadData(page: number): void;
  cellRenderer(data: TableCellProps): React.ReactNode;
  onSortData?(order: IOrder): void;
}

class TopList extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    items: [],
  };

  render() {
    const { items, itemsOrder, onSortData, onRowClick, portfolioCurrencyFormatter } = this.props;

    const totalCount = items.length;

    return (
      <Table
        {...this.props}
        // onRowClick={onRowClick}
        columns={this.props.columns}
        sortBy={itemsOrder.name}
        sortDirection={itemsOrder.direction}
        data={items}
        totalCount={totalCount}
        headerHeight={24}
        rowHeight={24}
        cellRenderer={this.props.cellRenderer}
        onSortData={(sortBy: string, sortDirection: SortDirectionType) =>
          onSortData?.({
            name: sortBy,
            direction: sortDirection,
          })
        }
      />
    );
  }
}

export default TopList;
