import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Loader } from '../../../../components';

import { API_URL } from '../../../../services/constants/endpoints';

import { ReturnVsRiskScatterChart } from './components/ReturnVsRiskScatterChart';
import s from './RiskContainer.module.scss';
import Analytics from '../../../../services/utils/Analytics';
import {
  DASHBOARD_PORTFOLIO_HELP_TEXT,
  RISK_CONTAINER_HELP_TEXT,
} from '../../../../services/constants/tooltipContextHelp';
import { PortfolioHeader } from '../../components';
import moment from 'moment';
import { PortfolioSubHeader } from '../../components/PortfolioHeader/PortfolioSubHeader';
import { usePortfolio } from '../../../../services/hooks/usePortfolio';
import { usePeriod } from '../../../../services/context/PeriodContext';
import useApi from '../../../../services/hooks/useApi';

const API_SCATTER = API_URL + 'api/risk/v1/scatter/portfolio/';

const SensitivityContainer = () => {
  const { portfolioInfo } = usePortfolio();
  const { period } = usePeriod();

  const portfolio = portfolioInfo.data as IPortfolio;
  const [volatilityData, setData] = useState<any>(null);
  const [isDataLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Analytics.trackPageView('Sensitivity Dashboard');
  }, []);

  useEffect(() => {
    if (!!portfolio?.id) {
      fetchData();
    }
  }, [portfolio?.id, period]);

  const fetchData = async () => {
    setLoading(true);
    const compiledApiUrl =
      !period.from || !period.to
        ? API_SCATTER + portfolio?.id + '/historic?dataFrequency=DAILY'
        : API_SCATTER +
          portfolio?.id +
          '/historic' +
          `?dataFrequency=DAILY&fromDate=${moment(period.from).format('YYYY-MM-DD')}&toDate=${moment(period.to).format(
            'YYYY-MM-DD'
          )}`;

    const response: any = await axios.get(compiledApiUrl);
    console.log(response);

    setData(response.data);
    setLoading(false);
  };

  const portfolioHeader = <PortfolioHeader showPortfolioInfo={true} showDashboardMenu={false} />;

  const loading = !volatilityData || isDataLoading || portfolioInfo.isFetching;

  const error = false;

  const insufficientData = volatilityData?.insufficientData;

  const insufficientDataMessage = volatilityData?.message;

  if (insufficientData) {
    return (
      <div>
        <h4> {insufficientDataMessage} </h4>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <div className={s.dashboardTitleContainer}>
          <h4>There was an error loading data. Please try again later.</h4>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  const stats: IStat[] = [
    {
      value: portfolio.totalProfitAndLoss,
      label: 'Inception P&L',
      colored: true,
      type: 'currency',
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoInceptionPnL },
    },
    {
      value: portfolio.performance,
      label: 'Performance (TWR)',
      type: 'percentage',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoPerformance },
    },
    {
      value: portfolio.totalWealth,
      label: 'Total Portfolio Value',
      type: 'currency',
      colored: true,
      info: { text: DASHBOARD_PORTFOLIO_HELP_TEXT.InfoTotalWealth },
    },
  ];

  return (
    <div>
      {portfolioHeader}
      <PortfolioSubHeader title={'Risk vs Return'} stats={stats} />

      <div className={s.defaultContainer}>
        <div className={s.defaultContainer}>
          <div className={s.containerRow}>
            <div className={s.returnVsRiskScatterChartContainer}>
              <ReturnVsRiskScatterChart
                serverData={volatilityData}
                portfolioBaseCcySymbol={portfolio.currency.symbol}
                chartTitle={'Annualised Return vs 1 Yr Daily Volatility'}
                helpText={RISK_CONTAINER_HELP_TEXT.ChartSimulatedPortfolioReturnVsVolatility}
                insufficientData={insufficientData}
                insufficientDataMessage={insufficientDataMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensitivityContainer;
