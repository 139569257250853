import React, { Fragment, FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { ASSET_CLASSES_CONFIG } from '../../../../../../services/constants/assets';
import DashboardPortfolio from '../PortfolioDashboard/DashboardPortfolio';

type IProps = RouteComponentProps<{
  classId: string;
  page?: string;
}>;

const AssetDashboardContainer: FunctionComponent<IProps> = (props) => {
  const { classId } = props.match.params;

  if (!classId || classId.toLowerCase() === 'all') {
    return <DashboardPortfolio />;
  }
  const AssetInfo = ASSET_CLASSES_CONFIG[classId];
  const AssetContainer = AssetInfo?.component;

  return <Fragment>{!!AssetContainer && <AssetContainer />}</Fragment>;
};

export default withRouter(AssetDashboardContainer);
