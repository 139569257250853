import cn from 'classnames';
import React, { InputHTMLAttributes, PureComponent } from 'react';
import s from './Input.module.scss';

type InputType = 'text' | 'password' | 'search';

// todo: check why can't combine all types from IProps to IInputComponentProps
export interface IInputComponentProps {
  theme?: 'inverse';
}

interface IOwnProps {
  value: string;
  type: InputType;
  focus: boolean;
  error: boolean;
  warning: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
}

type IProps = InputHTMLAttributes<HTMLInputElement> & IOwnProps & IInputComponentProps;

class Input extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    value: '',
    type: 'text',
    focus: false,
    warning: false,
    onChange: () => {},
  };

  private _refInp: React.RefObject<HTMLInputElement> = React.createRef();

  componentDidMount(): void {
    if (this.props.focus && this._refInp.current) {
      this._refInp.current.focus();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { focus } = this.props;

    if (prevProps.focus !== focus && focus && this._refInp.current) {
      this._refInp.current.focus();
    }
  }

  handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event);
    }
  };

  render() {
    const { value, type, error, warning, className, theme, focus, innerRef, onClick, ...rest } = this.props;
    const classes: string[] = [];
    if (theme) {
      classes.push(s[theme]);
    }

    // todo: check how to assign ref to multiply refs variables
    return (
      <input
        ref={this._refInp}
        className={cn(s.input, s[type], ...classes, className, {
          [s.error]: error,
          [s.warning]: warning,
          [s.disabled]: rest.disabled,
        })}
        type={type}
        value={rest.onChange ? value : undefined}
        defaultValue={!rest.onChange ? value : undefined}
        spellCheck={false}
        onFocus={(e) => {
          const val = e.target.value;
          e.target.value = '';
          e.target.value = val;
        }}
        onClick={this.handleClick}
        {...rest}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
      />
    );
  }
}

export default React.forwardRef<HTMLInputElement, Partial<IProps>>((props, ref?: React.Ref<HTMLInputElement>) => (
  <Input innerRef={ref} {...props} />
));
