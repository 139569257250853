import { useEffect, useState } from 'react';
import  _ from "lodash";
import { currencyFormatterV2 } from '../utils/CurrencyHelpers';
import { getBarColor } from '../../scenes/Portfolio/scenes/Reports/reportsChartOptions';
import { ICurrencyFormatter } from '../selectors/portfolio';

interface IProps {
  selectedCurrency:string;
  portfolioCurrencyFormatter:ICurrencyFormatter;
  chartData: IChartDataItem[]
  customBarDataTotal?: <T extends IHasData>(current: T) => number;
  toggableTotal?:boolean;
}

const useToggableLegend = ({
    chartData,
    portfolioCurrencyFormatter,
    selectedCurrency,
    customBarDataTotal,
  toggableTotal
}: IProps) => {
  const [toggledData, setToggledData] = useState<IChartDataItem[]>(chartData);
  const [hideTotal, setHideTotal] = useState<boolean>(false);
  const [legend, setLegend] = useState<{color: string, label: string, value: string}[]>([]);
  // These values will refresh if the values above refresh
  const {
    onLegendItemClick,
    formatCurrency
  } = inject({
    chartData,
    toggledData,
    setToggledData,
    isItemToggled: (key: string) => toggledData.some((item) => item.name === key),
    hideTotal,
    setHideTotal,
    selectedCurrency,
    portfolioCurrencyFormatter,
    toggableTotal
  })

  useEffect(() => {
    setToggledData(chartData)
  },[ chartData])

  useEffect(() => {
    const isItemToggled = (key: string) => toggledData.some((item) => item.name === key);
    const currentLegend = getLegend(
      toggledData,
      chartData,
      hideTotal,
      formatCurrency,
      isItemToggled,
      customBarDataTotal
    )
    const sameLegend = _.isEqual(currentLegend, legend);

    !sameLegend && setLegend(currentLegend)
  }, [customBarDataTotal, toggledData, chartData, hideTotal, formatCurrency, legend])

return {toggledData, legend, onLegendItemClick}
}

function inject({
  chartData,
  toggledData,
  setToggledData,
  isItemToggled,
  hideTotal,
  setHideTotal,
  selectedCurrency,
  portfolioCurrencyFormatter,
  toggableTotal
}:{
  toggledData: IChartDataItem[],
  setToggledData: (data: IChartDataItem[]) => void,
  setHideTotal: (isHidden: boolean) => void,
  selectedCurrency: string,
  portfolioCurrencyFormatter: (val: number) => string,
  chartData: IChartDataItem[],
  hideTotal: boolean,
  isItemToggled: (val: string) => boolean,
  toggableTotal?:boolean
}) {

  function removeItem (key: string) {
    setToggledData([...toggledData.filter((item) => item.name !== key)]);
  };

  function addItem (key: string) {
    const itemToAdd = chartData.find((item: any) => item.name === key);

    if (itemToAdd) {
      setToggledData([ ...toggledData,itemToAdd]);
    }
  };

  function toggleTotal() {
    setHideTotal(!hideTotal);
  };

  function onLegendItemClick (key: string) {
    if (key === 'Total' && toggableTotal) {
      toggleTotal();
      return;
    }
    const hasItem = isItemToggled(key);

    if (hasItem) {
      removeItem(key);
    } else {
      addItem(key);
    }
  };

  function formatCurrency (val: number): string {
    if (selectedCurrency === 'All' || selectedCurrency === undefined) {
      return portfolioCurrencyFormatter(val);
    }
    return selectedCurrency + ' ' + currencyFormatterV2(val, 0, 3);
  };

  return {
    addItem,
    removeItem,
    toggleTotal,
    onLegendItemClick,
    formatCurrency
  }
}

interface IHasData {
  data: [string, number][];
}

function getLegend (
  toggledData: IChartDataItem[],
  chartData: IChartDataItem[],
  hideTotal: boolean,
  formatCurrency: (val: number) => string,
  isItemToggled: (val: string) => boolean,
  customBarDataTotal?:<T extends IHasData>(current: T) => number,
  ) {
  const getBarName = (barData: IChartDataItem): string | undefined => {
    return barData.name;
  };
  let barDataTotal = (current: IChartDataItem) => current.data.reduce((acc, curr) => acc + curr[1], 0);
  if(customBarDataTotal){
    barDataTotal = customBarDataTotal
  }
  const total = toggledData.reduce((acc, curr) => acc + barDataTotal(curr), 0);
  const assembledLegend = [
    {
      label: 'Total',
      value: formatCurrency(total),
      color: hideTotal ? 'white' : '#a9b2d1',
    },
  ];

  chartData.filter((el) => barDataTotal(el) !== 0).forEach((el: IChartDataItem, idx: number) => {
    assembledLegend.push({
      label: getBarName(el) || '',
      value: formatCurrency(barDataTotal(el)),
      color: isItemToggled(getBarName(el) || '') ? getBarColor(el) || '' : 'white',
    });
  });

  return assembledLegend;
};


export default useToggableLegend


