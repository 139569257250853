import React, { FC } from 'react';
import FXEntry from './FXEntry';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { IRootState } from '../../../../../../../../services/store';
import { IFXFormValues } from './types';

type Props = IAssetEntryComponentProps<IFXFormValues>;
type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

const FXEditContainer: FC<Props> = ({ editedUnconfirmedTrade, ...props }) => {
  return <FXEntry editMode={true} {...props} initialValues={getInitialValuesFromTrade(editedUnconfirmedTrade)} />;
};

const getInitialValuesFromTrade = (t?: UnconfirmedTrade) => {
  if (typeof t === 'undefined') {
    return {};
  }

  const { instrument, custodian, tradeType, operation, key, quantity, otherQuantity } = t!.trade;

  const buyCurrency = operation === 'SELL' ? t.trade.otherSourceId?.sourceId : t.trade.instrument?.sourceId;
  const sellCurrency = operation === 'SELL' ? t.trade.instrument?.sourceId : t.trade.otherSourceId?.sourceId;

  const fromToFxRate = Math.pow(t.trade.quantity / (t.trade.otherQuantity ?? 1), operation === 'SELL' ? 1 : -1);
  const toFromFxRate = 1 / fromToFxRate;

  const buyCurrencyName = getCurrencyFromSourceId(buyCurrency);
  const sellCurrencyName = getCurrencyFromSourceId(sellCurrency);

  const values: Partial<IFXFormValues> = {
    ...t.trade,
    instrument,
    currency: makeCurrency(buyCurrencyName),
    buyCurrency: makeCurrency(buyCurrencyName),
    sellCurrency: makeCurrency(sellCurrencyName),
    custodian,
    key,
    operation: operation as 'BUY' | 'SELL',
    buyQuantity: operation === 'SELL' ? otherQuantity : quantity,
    sellQuantity: operation === 'SELL' ? quantity : otherQuantity,
    toFromFxRate,
    fromToFxRate,
    tradeType,
  };

  return values;
};

const getCurrencyFromSourceId = (str?: string) => str?.substr(0, str.indexOf('SPOT')) ?? str;

const makeCurrency = (currencyName?: string) =>
  !currencyName
    ? undefined
    : {
        id: currencyName,
        value: currencyName,
        name: currencyName,
      };

export default FXEditContainer;
