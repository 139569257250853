import React from 'react';
import useApi, { sendRequest } from '../useApi';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';

export const useInstrumentSearch = () => {
  const currentRequest = React.useRef<CancelTokenSource>();

  const search = React.useCallback(
    (query: string, assetClass: string) => {
      if (!!currentRequest.current) {
        currentRequest.current?.cancel();
      }
      currentRequest.current = axios.CancelToken.source();

      return sendRequest(
        `/api/v1/instruments?query=${query}&${assetClass}=true`,
        {
          method: 'GET',
        },
        {},
        currentRequest.current?.token
      );
    },
    [currentRequest]
  );

  return { search };
};
