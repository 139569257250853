import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IRootState } from '../../services/store';
import cn from 'classnames';
import s from './PortfolioTransfer.module.css';
import { PortfolioActions } from '../../services/actions';
import PortfoliosList from './components/PortfoliosList';
import UserList from './components/UserList';
import { Loader } from '../../components';
import useApi, { sendRequest } from '../../services/hooks/useApi';
import { getCustomApiUrl } from '../../services/constants/endpoints';
import { toast } from 'react-toastify';

const PortfolioShare = () => {
  const match = useRouteMatch<{ portfolioId: string }>();
  const { portfolioInfo } = useSelector(createSelector(mapStateToProps, (st) => st));
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
  const history = useHistory();

  const { isFetching: isPortfolioFetching, data: portfolio } = portfolioInfo;

  const workgroupUrl = getCustomApiUrl('workgroups.get', [
    { idLabel: ':workgroupId', idValue: `${portfolio?.workgroup?.id}` },
  ]);
  const workgroup = useApi(workgroupUrl, undefined, { enabled: !!portfolio?.workgroup?.id, refetchOnMount: true });

  React.useEffect(() => {
    dispatch(PortfolioActions.fetchPortfolioInfo(match.params.portfolioId));
  }, [match.params.portfolioId]);

  React.useEffect(() => {
    if (workgroup.data?.managers) {
      setSelectedUsers(workgroup.data?.managers);
    }
  }, [workgroup.dataUpdatedAt]);

  const onSelectUser = (user: IAccountUser) => {
    if (selectedUsers.indexOf(user.id!) > -1) {
      setSelectedUsers((prev) => prev.filter((id) => id !== user.id));
    } else {
      setSelectedUsers((prev) => [...prev, user.id ?? 'unknown']);
    }
  };

  const submit = () => {
    const updateUrl = getCustomApiUrl('workgroups.share', [
      { idLabel: ':workgroupId', idValue: `${portfolio?.workgroup?.id}` },
    ]);

    sendRequest(updateUrl, { method: 'PUT' }, { userIds: selectedUsers })
      .then(() => {
        toast.success('Successfully shared portfolio');
        window.history.back();
      })
      .catch((error) => {
        toast.error('An error occurred whilst trying to share portfolio');
        console.log({ error });
      });
  };

  return (
    <div style={{ width: 500 }}>
      <h1>Manager assignment</h1>
      <div style={{ height: '2rem' }} />
      <PortfoliosList />
      {isPortfolioFetching ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={cn(s.row)}>
            <div className={cn(s.rowLabel)}>Client Account:</div>
            <div>{portfolio?.workgroup?.name}</div>
          </div>
          <div className={cn(s.row)}>
            <div className={cn(s.rowLabel)}>Client Name:</div>
            <div>{portfolio?.workgroup?.clientUser ?? '(Unassigned)'}</div>
          </div>
        </React.Fragment>
      )}

      <div className={cn(s.info)}>
        <strong>Select the managers who should have access to this Client's portfolios:</strong>
      </div>

      <UserList role={'MANAGER'} selectedUsers={selectedUsers} onSelectUser={onSelectUser} />

      <div className={cn(s.buttonSurround)}>
        <div onClick={() => history.push('/portfolios')} className={cn(s.cancelButton)}>
          Cancel
        </div>
        <button onClick={submit}>Confirm</button>
      </div>

      {/*<JSONTree data={{ portfolioInfo, portfoliosInWorkgroup }}  shouldExpandNode={(path) => path[0] === "root" || path[0] === "data" || path[0] === "workgroup"} />*/}
    </div>
  );
};
const mapStateToProps = (state: IRootState) => ({
  portfolioInfo: state.portfolio.portfolioInfo,
});

export default PortfolioShare;
