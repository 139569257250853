export default function firstOrDefault<T>(a: T[], predicate: (t: T) => boolean, defaultValue?: T): T | null {
  for (const t of a) {
    if (predicate(t)) {
      return t;
    }
  }
  if (defaultValue === undefined) {
    return null;
  }
  return defaultValue;
}
