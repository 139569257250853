export const TRANSACTION_TYPES = {
  equities: 'Equities',
  funds: 'Funds',
  fixedIncome: 'FixedIncome',
  cashAndEquivalents: 'CashAndEquivalents',
  subscriptionAndRedemption: 'SubscriptionWithdrawal',
  realAssets: 'RealAssets',
  realEstate: 'RealEstate',
  cryptoCurrencies: 'CryptoCurrencies',
  options: 'EqOptions',
  equityFutures: 'EqFutures',
  alternatives: 'Alternatives',
};

export const isCashAndEquivalents = (instrument?: any, tradeType?: IAssetSubClass) => {
  return (
    (instrument && instrument.assetClassId === TRANSACTION_TYPES.cashAndEquivalents) ||
    (tradeType && tradeType.id === TRANSACTION_TYPES.cashAndEquivalents)
  );
};

export const isSubscriptionAndRedemption = (instrument?: any, tradeType?: IAssetSubClass) => {
  return (
    (instrument && instrument.assetClassId === TRANSACTION_TYPES.subscriptionAndRedemption) ||
    (tradeType && tradeType.id === TRANSACTION_TYPES.subscriptionAndRedemption)
  );
};

export const isEquities = (instrument?: any, tradeType?: IAssetSubClass) => {
  return (
    (instrument && instrument.assetClassId === TRANSACTION_TYPES.equities) ||
    (tradeType && tradeType.id === TRANSACTION_TYPES.equities)
  );
};

export const isFunds = (instrument?: any, tradeType?: IAssetSubClass) => {
  return (
    (instrument && instrument.assetClassId === TRANSACTION_TYPES.funds) ||
    (tradeType && tradeType.id === TRANSACTION_TYPES.funds)
  );
};

export const isFixedIncome = (instrument?: any, tradeType?: IAssetSubClass) => {
  return (
    (instrument && instrument.assetClassId === TRANSACTION_TYPES.fixedIncome) ||
    (tradeType && tradeType.id === TRANSACTION_TYPES.fixedIncome)
  );
};

export const isRealAssets = (instrument?: any, tradeType?: IAssetSubClass) => {
  return (
    (instrument && instrument.assetClassId === TRANSACTION_TYPES.realAssets) ||
    (tradeType && tradeType.id === TRANSACTION_TYPES.realAssets)
  );
};

export const FIELDS = {
  tradeType: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  tradeTime: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  asset: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
  ],
  // underlying: [TRANSACTION_TYPES.options],
  // type: [TRANSACTION_TYPES.options],
  // strikePrice: [TRANSACTION_TYPES.options],
  // expiryDate: [TRANSACTION_TYPES.options],
  // multiplier: [TRANSACTION_TYPES.options],
  // optionTicker: [TRANSACTION_TYPES.options],
  operation: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  quantity: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  price: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  tradeCosts: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  commission: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  fxRate: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  settlementOption: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
  ],
  custodian: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  notes: [
    TRANSACTION_TYPES.equities,
    TRANSACTION_TYPES.funds,
    TRANSACTION_TYPES.fixedIncome,
    TRANSACTION_TYPES.cashAndEquivalents,
    TRANSACTION_TYPES.subscriptionAndRedemption,
    TRANSACTION_TYPES.realAssets,
    TRANSACTION_TYPES.realEstate,
    TRANSACTION_TYPES.cryptoCurrencies,
    TRANSACTION_TYPES.options,
  ],
  settelmentDate: [TRANSACTION_TYPES.fixedIncome],
  currency: [TRANSACTION_TYPES.fixedIncome],
  accruedInterestLocal: [TRANSACTION_TYPES.fixedIncome],
};
