import React, { Fragment } from 'react';

import s from '../BetaVsPortfolioBenchmarkChart.module.css';

export function XAxisCanvas(props: any) {
  const minVal = props.minValue;
  const maxVal = props.maxValue;

  let renderedAxis;

  if (minVal < 0) {
    renderedAxis = (
      <Fragment>
        <div className={s.xAxisMarkings}>
          <div className={s.xAxisMark} style={{ marginLeft: '-5px' }}>
            <span>
              {-3}
              <br />|
            </span>
          </div>
          <div className={s.xAxisMark}>
            <span>
              {-2}
              <br />|
            </span>
          </div>
          <div className={s.xAxisMark}>
            <span>
              {-1}
              <br />|
            </span>
          </div>
          <div className={s.xAxisMark}>
            <span>
              {0}
              <br />|
            </span>
          </div>
          <div className={s.xAxisMark}>
            <span>
              {1}
              <br />|
            </span>
          </div>
          <div className={s.xAxisMark}>
            <span>
              {2}
              <br />|
            </span>
          </div>
          <div className={s.xAxisMark}>
            <span>
              {3}
              <br />|
            </span>
          </div>
        </div>
        <div className={s.xAxisLineAll} />
      </Fragment>
    );
  } else {
    renderedAxis = (
      <Fragment>
        <div className={s.xAxisMarkings}>
          <div className={s.xAxisMark}>
            <br />
            <br />
            {minVal}
            <br />|
          </div>
          <div className={s.midMark}>
            Benchmark
            <br />
            index data
            <br />1<br />|
          </div>
          <div className={s.midMark}>
            <br />
            <br />2<br />|
          </div>
          <div className={s.xAxisMark}>
            <br />
            <br />
            {maxVal}
            <br />
            &nbsp;|
          </div>
        </div>
        <div className={s.xAxisLinePositiveOnly} />
      </Fragment>
    );
  }

  return <Fragment>{renderedAxis}</Fragment>;
}
