import { AnyAction, combineReducers } from 'redux';

import { ILineChartDataState } from '../../../models/redux/ILineChartDataState';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';

export interface IPerformanceState {
  performanceData: ILineChartDataState;
  indexPerformanceData: ILineChartDataState & { name: string };
  period: IPeriod;
}

export const INITIAL_PERIOD: IPeriod = {
  ...PeriodHelper.getPeriod(PERIOD_TYPE.THIRTY_DAYS),
  type: PERIOD_TYPE.THIRTY_DAYS,
};

export const INITIAL_PERIOD_ALTERNATIVE: IPeriod = {
  ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION),
  type: PERIOD_TYPE.INCEPTION,
};

const periodsReducer = (state = INITIAL_PERIOD, action: AnyAction) => {
  switch (action.type) {
    case DashboardActions.SET_PERFORMANCE_PERIOD:
      return {
        ...state,
        ...action.payload,
      };
    case DashboardActions.RESET_PERFORMANCE_PERIOD:
      return { ...INITIAL_PERIOD };
    default:
      return state;
  }
};

const INITIAL_INDEX: ILineChartDataState & { name: string } = {
  isFetching: false,
  isFetched: false,
  data: [],
  error: null,
  cancelTokenSource: null,
  name: '',
};

const indexPerformanceDataReducer = (state = INITIAL_INDEX, action: AnyAction) => {
  switch (action.type) {
    case DashboardActions.INDEX_PERFORMANCE_REQUEST:
      if (state.cancelTokenSource && state.cancelTokenSource.cancel) {
        state.cancelTokenSource.cancel();
      }
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        data: state.data,
        cancelTokenSource: action.payload.cancelTokenSource,
        error: null,
        name: '',
        inceptionPerformance: 0,
      };
    case DashboardActions.INDEX_PERFORMANCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        data: action.payload.data.data,
        cancelTokenSource: null,
        error: null,
        name: action.payload.data.name,
        inceptionPerformance: action.payload.data.inceptionPerformance,
      };
    case DashboardActions.INDEX_PERFORMANCE_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        cancelTokenSource: null,
        error: action.payload.error,
        name: '',
      };
    case DashboardActions.INDEX_PERFORMANCE_RESET:
      if (state.cancelTokenSource && state.cancelTokenSource.cancel) {
        state.cancelTokenSource.cancel();
      }
      return { ...INITIAL_INDEX };
    default:
      return state;
  }
};

const performanceReducers = combineReducers<IPerformanceState>({
  performanceData: ReduxHelper.createHttpRequestReducer<ILineChartDataState>(
    ReduxHelper.createInitialState([]),
    DashboardActions.PERFORMANCE_REQUEST,
    DashboardActions.PERFORMANCE_SUCCESS,
    DashboardActions.PERFORMANCE_ERROR,
    DashboardActions.PERFORMANCE_RESET
  ),
  indexPerformanceData: indexPerformanceDataReducer,
  period: periodsReducer,
});

export default performanceReducers;
