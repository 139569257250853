import ReactEcharts from 'echarts-for-react';
import debounce from 'lodash.debounce';
import React, { PureComponent } from 'react';
import { ChartLegend } from '../../../../components/ChartLegend';
import { ILegendItemProps, LegendType } from '../../../../components/ChartLegend/components/LegendItem';

import { LineData } from '../../../../models/LineData';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { FormatHelper } from '../../../../services/utils/FormatHelper';
import { Widget } from '../WidgetsBlock/Widget';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import { getChangedChartOptions, getPerformanceChartOptions } from './performanceChartOptions';

interface IOwnProps {
  portfolioPerformance: LineData;
  marketValueBase?: LineData;
  indexPerformance: LineData;
  pnl?: LineData;
  selectedPeriod: IPeriod;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  hidePeriodSelector?: boolean;
  performanceToggle?: number | 0;
  indexName: string;
  useWidgetTitle?: boolean;
  customLegendTitles?: string[];
  netInvestments: [string, number][];
  height?: number;
}

type IProps = IOwnProps & Exclude<IWidgetProps, 'type | renderHeaderTools'>;

interface IState {
  chartWidth: number;
}

class PerformanceChartWidget extends PureComponent<IProps, IState> {
  static readonly defaultProps: Partial<IProps> = {
    height: 485,
  };

  readonly state: IState = {
    chartWidth: 0,
  };

  private _echartsReact: React.RefObject<ReactEcharts> = React.createRef();

  constructor(props: IProps) {
    super(props);
    this.handleZoomChange = debounce(this.handleZoomChange, 200);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this._echartsReact) {
        throw Error('No echart component');
      }
      this._updateWidth();
    });
    window.addEventListener('resize', () => this._updateWidth());
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', () => this._updateWidth());
  }

  getLegend(
    portfolioData: LineData,
    indexData: LineData,
    marketValueBase: LineData,
    netInvestments: LineData,
    pnl?: LineData
  ): ILegendItemProps[] {
    const portfolioValue = this._getLegendValue(portfolioData);
    const indexValue = this._getLegendValue(indexData);
    const marketValue = this._getLegendValue(marketValueBase);
    const netInvestmentsValue = this._getLegendValue(netInvestments);
    const pnlValue = typeof pnl !== 'undefined' ? this._getLegendValue(pnl) : undefined;
    const legendLabel1Text = this.props.customLegendTitles ? this.props.customLegendTitles[0] : 'Portfolio';
    // const {totalPnl} = this.props;

    // const totalPnl = portfolioValue - netInvestmentsValue;
    // const pnl = portfolioData[portfolioData.length - 1][3]

    const toggledLegend = [];

    if (this.props.performanceToggle === 1) {
      toggledLegend.push(
        {
          name: 'Market Value',
          value: this.props.portfolioCurrencyFormatter(portfolioValue),
          color: 'rgba(128, 255, 165)',
        },
        {
          name: 'Net Investments',
          value: this.props.portfolioCurrencyFormatter(netInvestmentsValue),
          color: 'rgba(255, 191, 0)',
        }

        /*{
          name: 'Value',
          value: this.props.portfolioCurrencyFormatter(marketValue),
          color: 'var(--cloudy-blue)',
        }*/
      );
      if (typeof pnlValue !== 'undefined') {
        toggledLegend.push({
          name: 'P&L',
          value: this.props.portfolioCurrencyFormatter(pnlValue),
          color: 'transparent',
        });
      }
    } else {
      toggledLegend.push(
        {
          name: legendLabel1Text,
          value: `${portfolioValue > 0 ? '+' : ''}${FormatHelper.round(portfolioValue, 100)}%`,
          // value: `${portfolioValue > 0 ? '+' : ''}${portfolioValue}%`,

          color: 'var(--chart-1)',
        },
        {
          name: this.props.indexName,
          value: `${indexValue > 0 ? '+' : ''}${FormatHelper.round(indexValue, 100)}%`,
          color: 'var(--chart-2)',
        }
      );
    }

    return toggledLegend;
  }

  // TODO: should be used to filter labels on x Axis but it cause bad performance
  handleZoomChange = (e: any) => {
    const { portfolioPerformance } = this.props;
    if (!this._echartsReact.current) {
      return;
    }

    const { selectedPeriod } = this.props;
    const { chartWidth } = this.state;

    let zoomData = e;
    if (e.batch) {
      zoomData = e.batch[0];
    }
    const { start, end } = zoomData;
    const chartDataNum = portfolioPerformance.length - 1;
    const dataInView = portfolioPerformance.slice(
      Math.floor((chartDataNum * start) / 100),
      Math.floor((chartDataNum * end) / 100)
    );

    const echart: echarts.ECharts = (this._echartsReact.current as any).getEchartsInstance();
    echart.setOption(getChangedChartOptions(dataInView, selectedPeriod, chartWidth));
  };

  render() {
    const { chartWidth } = this.state;
    const {
      portfolioPerformance,
      indexPerformance,
      selectedPeriod,
      portfolioCurrencyFormatter,
      indexName,
      netInvestments,
      pnl,
      performanceToggle,
      ...widgetProps
    } = this.props;

    const height = typeof this.props.height === 'number' ? this.props.height - 65 : 420;

    const marketValueBase = this.props.marketValueBase === undefined ? [] : this.props.marketValueBase;

    return (
      <Widget {...widgetProps}>
        <ChartLegend
          legend={this.getLegend(portfolioPerformance, indexPerformance, marketValueBase, netInvestments, pnl)}
          type={LegendType.LINE}
        />
        <ReactEcharts
          ref={this._echartsReact}
          onEvents={{ datazoom: this.handleZoomChange }}
          style={{ height, width: '100%', flex: '1', maxHeight: height - 20 }}
          notMerge={true}
          option={getPerformanceChartOptions(
            portfolioPerformance,
            indexPerformance,
            marketValueBase,
            netInvestments,
            selectedPeriod,
            chartWidth,
            portfolioCurrencyFormatter,
            indexName,
            performanceToggle,
            pnl
          )}
        />
      </Widget>
    );
  }

  private _updateWidth() {
    // todo: expand ReactEcharts type definition
    if (!this._echartsReact.current) {
      return;
    }
    const echart: echarts.ECharts = (this._echartsReact.current as any).getEchartsInstance();
    this.setState({ chartWidth: echart.getWidth() });
  }

  private _getLegendValue(data: LineData): number {
    if (!data || !data.length) {
      return 0;
    }
    if (data.length === 1) {
      return data[0][1];
    }
    return data[data.length - 1][1]; // - data[0][1];  - // This difference weirdly worked correctly so far... but only because we always had zero as the initial value and difference didn't make any difference :)
  }
}

export default PerformanceChartWidget;
