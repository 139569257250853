import memoizeOne from 'memoize-one';
import React, { PureComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TableCellProps } from 'react-virtualized';
import { IOptionType } from '../../../../components/UIWidgets/Select/Select';
import { ITableColumn } from '../../../../components/UIWidgets/TableWrapper/models';

// import { ITopListItem } from '../../../../models/ITopListItem';
import { DashboardActions } from '../../../../services/actions';
import { ALLOCATION_TYPES, TOP_LIST_ORDER } from '../../../../services/constants/constants';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { IRootState } from '../../../../services/store';
import { AsyncActionDispatch } from '../../../../services/utils/ReduxHelper';
import { TopList } from '../TopList';
import { Widget } from '../WidgetsBlock/Widget';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import { Loader } from '../../../../components';
import useApi from '../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../services/utils/PeriodHelper';
import { usePeriod } from '../../../../services/context/PeriodContext';
import { useCustodiansFilter } from '../../../../services/hooks/useCustodiansFilter';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';

interface IMapStateToProps {
  assetClasses: IAssetClass[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  changeOrder: (order: IOrder) => void;
}

interface IOwnProps {
  orderList?: Array<IOptionType<TOP_LIST_ORDER>>;
  showCategories?: boolean;
  columns?: ColumnProps[];
  cellRenderer?: (data: TableCellProps) => React.ReactNode;
  onRowClick?: any;
  portfolioId: string;
  filter?: { id: string | undefined; type: ALLOCATION_TYPES };
}

type IProps = IOwnProps & IMapStateToProps & IDispatchToProps & Exclude<IWidgetProps, 'isChart | renderHeaderTool'>;

const TopListByPnLWidgetsContainer = ({
  cellRenderer,
  assetClasses,
  title,
  className,
  portfolioCurrencyFormatter,
  columns,
  filter,
  portfolioId,
  ...restProps
}: IProps) => {
  // const [colNum, setColNum] = useState(4);
  // const [showCategories, setShowCategories] = useState(false);
  // const [order, setOrder] = useState<IOrder>({ direction: 'DESC', name: 'pnlBase' });
  const order: IOrder = { direction: 'DESC', name: 'pnlBase' };
  const { period } = usePeriod();
  const { selectedCustodiansArray } = useCustodiansFilter();
  const shouldRespectCustodians = filter?.type !== 'AssetClass';

  const topList = useApi(getApiUrl('topPositionsByPnL'), {
    method: 'POST',
    data: {
      number: 10,
      portfolioId,
      period: period ? PeriodHelper.preparePeriodForRequest(period) : undefined,
      order: {
        name: order?.name,
        direction: order!.direction,
      },
      filter,
      custodians: shouldRespectCustodians ? selectedCustodiansArray : [],
    },
  });

  useEffect(() => {
    topList.refetch();
  }, [period]);

  const itemsList = topList.data;

  if (!topList.isFetched) {
    return <Loader />;
  }
  return (
    <Widget title={title} className={className} colNum={4}>
      <div style={{ minHeight: 24 + 24 * (itemsList?.length ?? 0) }}>
        <TopList
          {...restProps}
          items={itemsList}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          // onSortData={setOrder}
          itemsOrder={order}
          columns={columns}
          cellRenderer={cellRenderer}
        />
      </div>
    </Widget>
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    assetClasses: state.assetsClasses.data,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  changeOrder: (order: IOrder) => dispatch(DashboardActions.changeTopListByPnlOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopListByPnLWidgetsContainer);
