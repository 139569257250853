import { IActionPayload } from '../../../models/redux/IActionPayload';
import { DashboardActions } from '../../actions';

const INITIAL_PORTFOLIO_CUSTODIANS_STATE: ICustodian[] = [];

const PortfolioCustodiansReducers = (state = INITIAL_PORTFOLIO_CUSTODIANS_STATE, action: IActionPayload) => {
  switch (action.type) {
    case DashboardActions.SET_CUSTODIANS_FILTER: {
      return action.payload;
    }
    case DashboardActions.RESET_CUSTODIANS_FILTER: {
      return [];
    }
    default:
      return state;
  }
};

export default PortfolioCustodiansReducers;
