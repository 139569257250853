import React from 'react';
import RegistrationTemplate from '../../../scenes/Account/scenes/Registration/components/RegistrationTemplate';
import cn from 'classnames';
import s from './TermsAcknowledgement.module.css';
import { Button, Checkbox, Notification } from 'rsuite';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../services/actions/AppActions';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import md5 from 'crypto-js/md5';

import { SAMPLE_TCs as tcs } from '../TermsAcknowledgement/SAMPLE_TCs';
import dateFormat from 'dateformat';
import useApi, { sendRequest } from '../../../services/hooks/useApi';
import { getApiUrl, API } from '../../../services/constants/endpoints';

export const TermsAcknowledgement = () => {
  const dispatch = useDispatch();

  const [agreed, setAgreed] = React.useState(false);
  const [version, setVersion] = React.useState('');

  // const {isFetching} = useApi(getApiUrl('account.terms'));

  const proceed = () => {
    const data = {
      md5: md5(tcs).toString(),
      localTime: new Date(),
      version,
    };

    sendRequest(`/api/v1/${API.endpoints.account.terms}`, { method: 'POST', data })
      .then(() => {
        dispatch(AppActions.authorise());
      })
      .catch(() => {
        Notification.error({
          title: 'Unable to save terms',
          description: 'An error occurred whilst trying to save your latest agreement to terms',
        });
      });
  };

  const date = new Date();

  return (
    <RegistrationTemplate>
      <div className={cn(s.content)}>
        <h1>Terms & Conditions</h1>
        <div className={cn(s.terms)}>
          <div style={{ textAlign: 'right', opacity: 0.5 }}>{md5(tcs).toString()}</div>

          {/*<MarkdownRenderer markdown={tcs} />*/}
          <ReactMarkdown remarkPlugins={[gfm]} children={tcs} />
        </div>
        <Checkbox checked={agreed} onChange={(_, checked) => setAgreed(checked)}>
          I Agree to the terms and conditions above on {dateFormat(date, 'dddd, dS mmmm yyyy')}
        </Checkbox>
        <Button appearance={'primary'} disabled={!agreed} onClick={proceed} className={cn(s.rsBtn, s.rsBtnPrimary)}>
          Agree & Proceed
        </Button>
      </div>
    </RegistrationTemplate>
  );
};
