import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Select } from '../../../../../../../components/UIWidgets/Select';
import { IOptionType } from '../../../../../../../components/UIWidgets/Select/Select';
import s from './SnapshotHeader.module.scss';
import { DatePicker } from '../../../../../../../components/UIWidgets/DatePicker';
import { Input } from '../../../../../../../components/UIWidgets/Input';
import moment from 'moment';
import { useDebounce } from 'react-use';

interface IFilter {
  label: string;
  key: string;
  options: IOptionType[];
  minWidth?: number;
}

interface IProps {
  filterSettings: IFilter[];
  inceptionDate: string;
  filterValues: Record<string, string | undefined>;
  updateFilter(key: string): (value?: string) => void;
  resetFilters(): void;
}

const SnapshotHeader: FunctionComponent<IProps> = ({
  filterSettings,
  filterValues,
  updateFilter,
  inceptionDate,
  resetFilters,
}) => {
  const [internalFrom, setInternalFrom] = React.useState<string | undefined>('');
  const [internalTo, setInternalTo] = React.useState<string | undefined>('');
  const [searchString, setSearchString] = React.useState('');

  React.useEffect(() => {
    resetFilters?.();
  }, [resetFilters]);

  useDebounce(
    () => {
      updateFilter('search')(searchString);
    },
    200,
    [searchString]
  );

  React.useEffect(() => {
    setInternalFrom(filterValues.positionsFrom);
    setInternalTo(filterValues.positionsTo);
  }, [filterValues.positionsFrom, filterValues.positionsTo]);

  const applyDateFilter = () => {
    if (internalFrom !== filterValues.positionsFrom) {
      updateFilter('positionsFrom')(internalFrom);
    }
    if (internalTo !== filterValues.positionsTo) {
      updateFilter('positionsTo')(internalTo);
    }
  };

  const debouncedSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const resetDateFilter = () => {
    updateFilter('positionsFrom')('');
    updateFilter('positionsTo')('');
    setInternalFrom('');
    setInternalTo('');
  };

  return (
    <div data-testid={'snapshot-header'} className={cn(s.surround)}>
      {/* Filters */}
      <div className={cn(s.filterSurround)}>
        <div className={cn(s.dropdownSurround)}>
          <span className={cn(s.dropdownLabel)}>Instrument Search</span>
          <div className={cn(s.dropdown)}>
            <Input
              value={searchString}
              placeholder="Search"
              className={cn(s.inputSearch)}
              type="search"
              onChange={debouncedSearch}
            />
          </div>
        </div>
        <div className={cn(s.dropdownSurround)}>
          <span className={cn(s.dropdownLabel)}>Date Range</span>
          <div className={cn(s.dropdown)}>
            <DatePicker
              className={cn(s.dropdown, s.dateRangeField, s.picker)}
              placeholder="From"
              value={internalFrom ? new Date(internalFrom) : null}
              onChange={({ value }) => setInternalFrom(value === null ? '' : value.toString())}
              min={moment(inceptionDate).toDate()}
              max={new Date()}
              hideWeekends={false}
            />
            <div style={{ marginTop: '0.2rem' }}>
              <DatePicker
                className={cn(s.dropdown, s.dateRangeField, s.picker)}
                placeholder="To"
                value={internalTo ? new Date(internalTo) : null}
                onChange={({ value }) => setInternalTo(value === null ? '' : value.toString())}
                min={internalFrom ? moment(internalFrom).toDate() : moment(inceptionDate).toDate()}
                max={new Date()}
                hideWeekends={false}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={cn(s.dateButton, s.apply)} onClick={applyDateFilter}>
            Apply
          </div>
          <div className={cn(s.dateButton, s.reset)} onClick={resetDateFilter}>
            Clear
          </div>
        </div>

        {filterSettings.map(({ label, options, key, minWidth }) => (
          <div key={label} className={cn(s.dropdownSurround)}>
            <span className={cn(s.dropdownLabel)}>{label}</span>
            <div className={cn(s.dropdown)} style={{ minWidth: typeof minWidth === 'undefined' ? 100 : minWidth }}>
              <Select
                onChange={updateFilter(key)}
                options={options}
                selectedValue={options.filter((o) => o.value === filterValues[key])[0]}
              />
            </div>
          </div>
        ))}
        <div className={cn(s.dropdownSurround)}>
          <span className={cn(s.dropdownLabel)} />
          <div className={cn(s.resetButton)} onClick={resetFilters}>
            Reset Filters
          </div>
        </div>
      </div>
      {filterValues.search}
    </div>
  );
};

export default SnapshotHeader;
