import { PATHS } from '../../router/paths';

export const getValueColor = (totalNav: number) => {
  return totalNav > 0 ? 'var(--text-success-color)' : 'var(--text-alert-color)';
};

export const getTradeColor = (porfolio: IPortfolio) => {
  const { blotterTrades } = porfolio;
  if (blotterTrades.errorTradeCount > 0) {
    return 'var(--text-alert-color)';
  }
  if (blotterTrades.warnTradeCount > 0) {
    return 'var(--text-warning-color)';
  }
  if (blotterTrades.infoTradeCount > 0) {
    return 'var(--text-disabled-color)';
  }
  if (blotterTrades.validTradeCount > 0) {
    return 'var(--text-success-color)';
  }
};

export const formatPriceValue = (price: number) => {
  return '$ ' + price.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

export const formatPriceValueNoCurrency = (price: number) => {
  return price.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

export const formatPriceValueCurrency = (price: number, currency: string) => {
  return currency + ' ' + price.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

export const getPortfoliosLink = (workgroupId: string) => {
  return PATHS.portfoliosManager.path.replace(':workgroupId', workgroupId);
};

export const getPortfolioLink = (portfolio: IPortfolio) => {
  if (portfolio.unconfirmedTrades || portfolio.confirmedTrades === 0) {
    return PATHS.portfolio.snapshot.edit.path.replace(':portfolioId', portfolio.id);
  }
  return PATHS.portfolio.dashboardNew.path.replace(':portfolioId', portfolio.id);
};

export const getPortfolioBlotterLink = (portfolio: IPortfolio) => {
  return PATHS.portfolio.snapshot.edit.path.replace(':portfolioId', portfolio.id);
};
