import { IActionPayload } from '../../../models/redux/IActionPayload';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';
import { LineData } from '../../../models/LineData';

const INITIAL_CUMULATIVE_PERFORMANCE_BY_ASSET_STATE: any = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const cumulativePerformanceByAssetReducerDefault = ReduxHelper.createHttpRequestWithPeriodReducer<
  ILoadingPeriodDataState<LineData>
>(
  INITIAL_CUMULATIVE_PERFORMANCE_BY_ASSET_STATE,
  DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_REQUEST,
  DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_SUCCESS,
  DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_ERROR,
  DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_RESET
);

const cumulativePerformanceByAssetReducer = (
  state = INITIAL_CUMULATIVE_PERFORMANCE_BY_ASSET_STATE,
  action: IActionPayload
) => {
  switch (action.type) {
    case DashboardActions.CUMULATIVE_PERFORMANCE_BY_ASSET_CHANGE_PERIOD: {
      return {
        ...state,
        period: action.payload.period,
      };
    }
    default:
      return cumulativePerformanceByAssetReducerDefault(state, action);
  }
};

export default cumulativePerformanceByAssetReducer;
