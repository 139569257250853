import React from 'react';
import { getApiUrl } from '../../constants/endpoints';
import { usePortfolio } from '../usePortfolio';
import { usePeriod } from '../../context/PeriodContext';
import moment from 'moment';
import useApi from '../useApi';
import { useCustodiansFilter } from '../useCustodiansFilter';
import { ALLOCATION_TYPES } from '../../constants/constants';

export const DEFAULT_AVAILABLE_ALLOCATION_TYPES: AllocationType[] = [
  ALLOCATION_TYPES.AssetClass,
  ALLOCATION_TYPES.Custodian,
  ALLOCATION_TYPES.Sectors,
  ALLOCATION_TYPES.Currency,
  ALLOCATION_TYPES.Region,
];

export const usePortfolioAllocation = (
  allocateBy: 'MarketValueBase' = 'MarketValueBase',
  allocationTypes: AllocationType[] = DEFAULT_AVAILABLE_ALLOCATION_TYPES,
  custodiansOverride?: string[]
) => {
  const url = getApiUrl('portfolio.allocations');
  const portfolio = usePortfolio();
  const portfolioId = portfolio.portfolioInfo?.data?.id;

  const { selectedCustodiansArray } = useCustodiansFilter();

  const custodians = selectedCustodiansArray;

  const { period } = usePeriod();

  const valuationDate = period.to ? moment(period.to).format('YYYY-MM-DD') : '';

  const request = useApi<{ containsShorts?: boolean; allocations: AllocationMap }>(
    url,
    {
      method: 'POST',
      data: {
        allocateBy,
        allocations: allocationTypes,
        custodians: custodiansOverride ?? custodians,
        portfolioId,
        valuationDate,
        withChildren: false,
      },
    },
    {
      enabled: !!portfolioId,
    }
  );

  const allocations = request.data?.allocations;

  const allocationsArray = React.useMemo(
    () =>
      !!request.data?.allocations
        ? Object.entries(request.data?.allocations).map(([key, value]) => ({
            name: key as AllocationType,
            entries: value as AllocationEntry[],
          }))
        : [],
    [request.data?.allocations, request.dataUpdatedAt]
  );

  return {
    allocations,
    allocationsArray,
    ...request,
  };
};

// allocateBy: "MarketValueBase"
// allocations: ["RiskAssetClass", "Region", "Currency", "Custodian", "IndustrySector", "Industry"]
// custodians: []
// portfolioId: "934cdc50-9f87-4d19-8ad0-af448e5618dd"
// valuationDate: "2021-10-21"
// withChildren: false
