import React from 'react';
import { PortfolioHeader } from '../../components';
import { ExplorerContextProvider, useExplorerContext } from '../../../../services/context/ExplorerContext';
import { Col, Grid, Modal, Row } from 'rsuite';
import { StructureWheel } from '../PortfolioExplorer/components/StructureWheel';
import { ExpandButton } from '../../../../components/UIWidgets/ExpandButton';
import PortfolioUpcomingEvents from '../../components/PortfolioUpcomingEvents/PortfolioUpcomingEvents';
import { PortfolioPerformance } from '../PortfolioExplorer/components/PortfolioPerformance/PortfolioPerformance';
import { GainsLosses } from '../PortfolioExplorer/components/GainsLosses/GainsLosses';
import s from '../PortfolioExplorer/PortfolioExplorer.module.scss';
import { DashboardNewHeader } from './DashboardNewHeader';
import { useSafariHack } from '../../../../services/hooks/useSafariHack';
import { useDispatch } from 'react-redux';
import { SnapshotActions } from '../../../../services/actions';
import { MultiLinePNL } from '../PortfolioExplorer/components/MultiLinePNL/MultiLinePNL';
import HintBox from '../../../../components/HintBox/HintBox';

const components = new Map();
components.set('StructureWheelLarge', {
  Comp: StructureWheel,
  colWidth: 12,
  label: 'Portfolio Structure',
  mdHidden: true
});
components.set('PortfolioPerformance', { Comp: PortfolioPerformance, colWidth: 12, label: 'Portfolio Performance' });

components.set('MultiLinePNL', {
  Comp: MultiLinePNL,
  colWidth: 12
});
components.set('StructureWheelSmall', {
  Comp: StructureWheel,
  colWidth: 12,
  label: 'Portfolio Structure',
  lgHidden: true
});
components.set('GainsLosses', { Comp: GainsLosses, colWidth: 12, label: 'Gains and Losses' });

export const DashboardNewInner = () => {
  const [expandedItem, setExpandedItem] = React.useState<string>();
  const { flex } = useSafariHack();
  const { selectedPosition, setSelectedPosition } = useExplorerContext();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SnapshotActions.updateSnapshotFilter('positionsFrom', undefined));
    dispatch(SnapshotActions.updateSnapshotFilter('positionsTo', undefined));
  }, []);

  const renderModalContent = React.useMemo(() => {
    const component = !!expandedItem ? components.get(expandedItem) : undefined;
    if (!component) {
      return null;
    }
    const { Comp } = component;
    return (
      <div>
        <Modal.Header>
          <Modal.Title>{component.label}</Modal.Title>
        </Modal.Header>
        <div style={{ height: 20 }}/>
        <Comp height={800} expanded={true}/>
      </div>
    );
  }, [expandedItem]);

  return (
    <div>
      <PortfolioHeader showDashboardMenu={false}/>
      <DashboardNewHeader/>
      <HintBox text={'Click on a segment of the donut to show data specific to that segment'} prefKey={'DashboardNew'}/>
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({selectedPosition}, null, 2)}</pre>*/}
      <div style={{ display: flex }}>
        <div style={{ flex: 1, marginRight: '0.25rem' }}>
          <Grid fluid={true}>
            <Row>
              {[...components].map(([key, value]) => {
                const { colWidth, Comp, mdHidden, lgHidden } = value;

                return (
                  <Col key={key} xs={24} lg={12} style={{ marginBottom: 10 }} {...{ mdHidden, lgHidden }}>
                    <div style={{ position: 'relative', height: 400 }}>
                      <Comp height={400} expanded={false}/>
                      <ExpandButton onClick={() => setExpandedItem(key)}/>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Grid>
        </div>

        <div style={{ height: '100%', overflow: 'hidden' }}>
          <PortfolioUpcomingEvents
            // setExpandedItem={setExpandedItem}
            deselectPosition={() => setSelectedPosition(null)}
            selectedPosition={selectedPosition}
          />
        </div>
      </div>
      <Modal full={true} show={!!expandedItem} onHide={() => setExpandedItem(undefined)} className={s.modalContainer}>
        {renderModalContent}
      </Modal>
    </div>
  );
};

export const DashboardNew = () => {
  return (
    <ExplorerContextProvider>
      <DashboardNewInner/>
    </ExplorerContextProvider>
  );
};
