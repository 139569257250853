import memoizeOne from 'memoize-one';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TableCellProps } from 'react-virtualized';
import { IOptionType } from '../../../../components/UIWidgets/Select/Select';
import { ITableColumn } from '../../../../components/UIWidgets/TableWrapper/models';

import { ITopListItem } from '../../../../models/ITopListItem';
import { TOP_LIST_ORDER } from '../../../../services/constants/constants';
import { getPortfolioCurrencyFormatter, ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { IRootState } from '../../../../services/store';
import { TopList } from '../TopList';
import { Widget } from '../WidgetsBlock/Widget';
import { IWidgetProps } from '../WidgetsBlock/Widget/Widget';
import { Loader } from '../../../../components';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';

interface IMapStateToProps {
  assetClasses: IAssetClass[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IOwnProps {
  topListOrder: IOrder;
  changeOrder: (order: IOrder) => void;
  topList: ITopListItem[];
  orderList?: Array<IOptionType<TOP_LIST_ORDER>>;
  showCategories?: boolean;
  columns?: ColumnProps[];
  cellRenderer?: (data: TableCellProps) => React.ReactNode;
  onRowClick?: any;
  columnClass?: string;
  headerClass?: string;
}

type IProps = IOwnProps & IMapStateToProps & Exclude<IWidgetProps, 'isChart | renderHeaderTool'>;

class TopListWidgetsContainer extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    colNum: 4,
    showCategories: false,
  };

  constructor(props: IProps) {
    super(props);
    this.setTopListCategories = memoizeOne(this.setTopListCategories);
  }

  // changeSortValue = (name: string) => {
  //   this.props.changeOrder({ name, direction: this.props.topListOrder.direction });
  // };
  //
  // changeSortOrder = (value: IOrder['direction']) => {
  //   this.props.changeOrder({ name: this.props.topListOrder.name, direction: value });
  // };

  setTopListCategories = (topList: ITopListItem[]): ITopListItem[] => {
    const { assetClasses } = this.props;
    if (!assetClasses) {
      return [];
    }
    return topList.map((item) => {
      const category = assetClasses.find((asset) => asset.id === item.assetClassId);
      return { ...item, category: category ? category.name : '' };
    });
  };

  render() {
    const { title, topList, className, colNum, showCategories, portfolioCurrencyFormatter } = this.props;

    if (!this.props.assetClasses || !this.props.topList) {
      return <Loader />;
    }

    const itemsList = showCategories ? this.setTopListCategories(topList) : topList;

    console.log({ itemsList });
    return (
      <Widget title={title} className={className} colNum={colNum}>
        <div style={{ minHeight: 24 + 24 * (itemsList?.length ?? 0) }}>
          <TopList
            {...this.props}
            items={itemsList}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            // onSortData={this.props.changeOrder}
            itemsOrder={this.props.topListOrder}
            columns={this.props.columns}
            cellRenderer={this.props.cellRenderer}
          />
        </div>
      </Widget>
    );
  }
}

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    assetClasses: state.assetsClasses.data,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
    // topListOrder: state.dashboard.topList.order,
  };
};

export default connect(mapStateToProps)(TopListWidgetsContainer);
