import { FormatHelper } from './FormatHelper';

export const onlyLetters = (value: string): string => {
  value = value.replace(/[^[a-zA-z\s]/g, '');
  return value ? value : '';
};

export const positiveNumber = (value: string | number): string | number => {
  if (typeof value === 'string' && value === 'NaN') {
    return '';
  }

  if (typeof value === 'string') {
    return value.replace('-', '');
  }
  return value ? Math.abs(value) : 0;
};

export const integerNumber = (value: string | number): string | number => {
  if (value === '') {
    return '';
  }
  value = value.toString().replace(/[^\d]/g, '');
  return value ? parseInt(value.toString(), 10) : 0;
};

export const floatNumber = (value: string | number, decimals: string | number = 2): string | number => {
  const floatRegExp: RegExp = new RegExp('^(\\d{1}|([1-9]\\d+))(([.])|([.]\\d{1,' + decimals + '}))?$');

  let valueStr = value.toString();
  valueStr = valueStr.replace(/[^\d,.]/g, '');
  valueStr = valueStr.replace(',', '.');

  return floatRegExp.test(valueStr)
    ? valueStr
    : FormatHelper.round(parseFloat(valueStr), Math.pow(10, decimals as number));
};

export const floatNumberV2 = (value: string | number, decimals: string | number = 2): string | number => {
  if (!value) {
    return value;
  }

  const valString = String(value);
  let str;

  const reg = /^\d*\.?\d*$/;

  if (reg.test(valString)) {
    str = valString;
  } else {
    str = valString.replace(/[^0-9\.]/g, '');
  }

  const decimalPosition = str.indexOf('.');

  if (decimalPosition < 0) {
    return str;
  }

  const charactersFromPoint = decimals < 1 ? decimalPosition : decimalPosition + 1 + parseInt(String(decimals), 10);

  let parsed = str.toString().substr(0, charactersFromPoint);
  parsed = String(parsed.substr(0, 1) === '.' ? floatNumberV2(`0${parsed}`, decimals) : parsed);
  return /(\d+.?\d*)/.exec(parsed)?.[0] ?? '';
  // return parsed.replace(/\d+.?\d+(.*)/, "")
};

export const invertTo4dp = (num: number): string => {
  if (!!num && num !== 0) {
    return (1 / num).toFixed(4);
  } else {
    return '0.0000';
  }
};

export const invert = (num: number) => {
  if (!!num && num !== 0) {
    return 1 / num;
  }
  return num;
};
