import React, { FC } from 'react';
import cn from 'classnames';
import s from '../ReportGenerator.module.css';
import { Button } from 'rsuite';
import { Toggle } from './Toggle';
import SimpleDropDown from 'src/components/Reusable/SimpleDropDown/SimpleDropDown';
import { POSITION_GROUPING_OPTIONS } from '../utils/options';

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
}

const AllocationSettings: FC<IOwnProps> = ({ settings, updateSettings }) => {
  const isAllSelected = !!settings.structure && !!settings.openPositions && !!settings.transactionHistory;

  const toggleAll = () => {
    updateSettings({ structure: !isAllSelected, openPositions: !isAllSelected, transactionHistory: !isAllSelected });
  };

  const AllocationToggle = ({ field }: { field: string }) => {
    return <Toggle field={field} settings={settings} updateSettings={updateSettings} />;
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <h3>Allocation</h3>
      <br />
      <div className={cn(s.settingsRow)}>
        <span>Portfolio structure</span>
        <AllocationToggle field={'structure'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Open positions by asset class</span>
        <AllocationToggle field={'openPositions'} />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Group by</span>
        <SimpleDropDown
          disabled={!settings.openPositions}
          items={POSITION_GROUPING_OPTIONS}
          selectedItem={settings.positionGrouping}
          onSelect={({ selectedItem }) => updateSettings({ positionGrouping: selectedItem })}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span>Transaction History</span>
        <AllocationToggle field={'transactionHistory'} />
      </div>
      <Button style={{ float: 'right' }} size={'xs'} appearance={'subtle'} onClick={toggleAll}>
        Select {isAllSelected ? 'none' : 'all'}
      </Button>
    </div>
  );
};

export default AllocationSettings;
