import ReactEcharts from 'echarts-for-react';
import React, { PureComponent } from 'react';
import { LineData } from '../../../../../../../../../../models/LineData';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { getMaturityChartOptions } from './maturityChartOptions';

interface IProps {
  chartData: LineData;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class MaturityChart extends PureComponent<IProps> {
  private _chartRef: echarts.ECharts | null = null;

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { chartData } = this.props;
    if (this._chartRef && prevProps.chartData !== chartData) {
      (this._chartRef as echarts.ECharts).dispatchAction({
        type: 'dataZoom',
        start: 0,
        end: 100,
      });
    }
  }

  render() {
    const { chartData, portfolioCurrencyFormatter } = this.props;
    return (
      <ReactEcharts
        ref={(e) => {
          this._chartRef = e && (e as any).getEchartsInstance();
        }}
        style={{ height: '420px', width: '100%', flexGrow: '1' }}
        option={getMaturityChartOptions(chartData, portfolioCurrencyFormatter)}
      />
    );
  }
}

export default MaturityChart;
