import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment, { Moment } from 'moment';

import { BarType, DEFAULT_TOOLTIP_OPTIONS } from '../../../../services/constants/charts';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../services/utils/ChartHelper';
import { ColorsHelper } from '../../../../services/utils/ColorsHelper';
import { FormatHelper } from '../../../../services/utils/FormatHelper';
import memoizeOne from 'memoize-one';
import { LineData } from '../../../../models/LineData';
import { PERIOD_TYPE } from '../../../../services/constants/constants';

type FormatterParam = echarts.EChartOption.Tooltip.Format;

const LABEL_WIDTH: number = 30;

enum AxisLabelType {
  DAY,
  MONTH,
  QUARTER,
}

// fixme: put all methods from file in class to avoid global definitions
const labelsMap: Map<number, { value: string; index: number }> = new Map<number, { value: string; index: number }>();

const filterAxisLabels = memoizeOne(
  (data: LineData, labelType: AxisLabelType): LineData => {
    switch (labelType) {
      case AxisLabelType.MONTH:
        return data.filter((item) => moment(item[0]).date() === 1);
      case AxisLabelType.QUARTER:
        return data.filter((item) => {
          const date: Moment = moment(item[0]);
          return date.month() % 3 === 0 && date.date() === 1;
        });
      default:
        return data;
    }
  }
);

const prepareXAisLabels = memoizeOne(
  (data: LineData, period: IPeriod): Map<number, { value: string; index: number }> => {
    labelsMap.clear();
    const labelType: AxisLabelType = getLabelAxisType(data, period);
    filterAxisLabels(data, labelType).forEach((item) =>
      labelsMap.set(moment(item[0]).startOf('day').valueOf(), {
        value: getAxisLabel(item[0], labelType),
        index: labelsMap.size + 1,
      })
    );
    return labelsMap;
  }
);

export function getChartOptions(
  chartData: IBarData[],
  portfolioCurrencyFormatter: ICurrencyFormatter,
  barWidth: number | undefined,
  selectedCurrency: string = 'All',
  period?: IPeriod,
  dateFormat?: string,
  longColor: string = '#6677cc'
): echarts.EChartOption {
  const axisData: LineData = [];
  chartData[0].data.map((el: any) => {
    axisData.push([el.date, el.longTotal]);
  });

  if (period) {
    prepareXAisLabels(axisData, period);
  }

  const chartOpt = ChartHelper.getChartOptions();
  if (!chartData.length) {
    return chartOpt;
  }

  const chartSeries: any = [];

  chartData[0].data.map((item: any) => {
    chartSeries.push([item.date, item.longTotal, item.shortTotal]);
  });

  const series1Color = longColor; // Long Positions
  const series2Color = '#3b4680'; // Short Positions

  // const categories = chartData[0].data.map((value: any) => value.date);
  const categories = chartSeries.map((value: any) => value[0]);
  const longPositionsSeries = chartSeries.map((value: any) => value[1]);
  const shortPositionsSeries = chartSeries.map((value: any) => value[2]);

  const categoriesDateFormat = dateFormat || "DD MMM'YY";

  const mergeOpt: echarts.EChartOption = {
    xAxis: {
      data: categories,
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
        interval: 0,
        formatter(value: string): any {
          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const momentVal = moment(value);
            return momentVal.format(categoriesDateFormat);
          }
          return value;
        },
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return selectedCurrency === 'All'
            ? portfolioCurrencyFormatter(value)
            : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(value, 0);
        },
      },
    },
    // series: prepareSeries(chartData, barWidth),
    series: [
      {
        name: 'Long Positions',
        data: longPositionsSeries,
        type: 'bar',
        stack: 'one',
        barMaxWidth: 24,
        itemStyle: {
          color: series1Color,
        },
      },
      {
        name: 'Short Positions',
        data: shortPositionsSeries,
        type: 'bar',
        stack: 'one',
        barMaxWidth: 24,
        itemStyle: {
          color: series2Color,
        },
      },
    ],
    tooltip: {
      ...DEFAULT_TOOLTIP_OPTIONS,
      formatter: (params: FormatterParam | FormatterParam[]): string => {
        let tooltipText = '';

        /*const formatName = (item: FormatterParam) => {
          if (!item.seriesName) {
            return '';
          }
          if (item.seriesName.toLowerCase().includes('past')) {
            return moment(item.axisValue).format("MMM 'YY");
          } else {
            return moment(item.axisValue).format("MMM 'YY");
          }
        };*/

        const textGen = (item: FormatterParam, instruments: any = [], longVal: any, shortVal: any) => {
          const value = shortVal + longVal;

          const valueText =
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(value)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(value, 0);

          const serieMarker: string = ChartHelper.getTooltipLegend(item);
          const serieName = moment(item.name).format('MMM') + `'` + moment(item.name).format('YY');

          const longPosMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: ${series1Color}"></span>`;
          const longPosVal =
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(longVal)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(longVal, 0);
          const shortPosMarker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color: ${series2Color}"></span>`;
          const shortPosVal =
            selectedCurrency === 'All'
              ? portfolioCurrencyFormatter(shortVal)
              : selectedCurrency + ' ' + FormatHelper.formatNumberAsValue(shortVal, 0);

          const instrumentsText: any = [];
          instruments.map((i: any) => {
            const marker = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:${ColorsHelper.stringToColor(
              i.instrumentName
            )};"></span>`;
            instrumentsText.push(
              `${marker}${i.instrumentName}: ${portfolioCurrencyFormatter(i.valueBase)} ${
                i.valueBase !== i.valueLocal
                  ? ` /  ${FormatHelper.formatCurrencyShort(i.valueLocal, i.currencyLocal.symbol)}`
                  : ''
              }`
            );
          });

          // tooltipText += `${serieMarker}${serieName}: ${valueText}<br><br>Assets:<br>${instrumentsText.join('<br>')}`;
          tooltipText += `${serieMarker}${serieName}: ${valueText}<br/>${longPosMarker}Long Positions: ${longPosVal}<br/>${shortPosMarker}Short Positions: ${shortPosVal}<br></br>Assets:<br> ${instrumentsText.join(
            '<br>'
          )}`;
        };

        if (Array.isArray(params)) {
          const longVal = params[0].value;
          const shortVal = params[1].value;
          const instruments = chartData[0].data[params[0].dataIndex!] as any;
          textGen(
            params[0],
            [...instruments.longDetails, ...instruments.shortDetails.filter((i: any) => i.valueBase !== 0)],
            longVal,
            shortVal
          );
          // params.forEach((item: FormatterParam) => textGen(item, instruments.longDetails, longVal, shortVal));
        } else {
          // @ts-ignore
          textGen(params);
        }
        return tooltipText;
      },
    },
  };

  const timeSeinsibleAxisLabels = period ? ChartHelper.getTimeAxisLabelFormatter(period) : {};

  return merge({}, chartOpt, mergeOpt, timeSeinsibleAxisLabels);
}

function getLabelAxisType(dataInView: LineData, period: IPeriod): AxisLabelType {
  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return AxisLabelType.MONTH;
    case PERIOD_TYPE.INCEPTION:
      return AxisLabelType.QUARTER;
    case PERIOD_TYPE.MANUAL:
      const firstDate = period.from ? period.from : undefined;
      const lastDate = period.to ? period.to : undefined;

      const diffMonths = moment(lastDate).diff(firstDate, 'months');
      if (!diffMonths) {
        return AxisLabelType.DAY;
      }
      if (diffMonths >= 1 && diffMonths <= 12) {
        return AxisLabelType.MONTH;
      }
      return AxisLabelType.QUARTER;
    default:
      return AxisLabelType.DAY;
  }
}

function getAxisLabel(value: string | number, labelType: AxisLabelType): string {
  const date: Moment = moment(value);
  switch (labelType) {
    case AxisLabelType.MONTH:
      return date.format("MMM'YY");
    case AxisLabelType.QUARTER:
      return `Q${date.quarter()}'${date.format('YY')}`;
    default:
      return date.format("DD MMM'YY");
  }
}

function prepareSeries(data: IBarData[], barWidth: number | undefined): echarts.EChartOption.SeriesBar[] {
  return data.map((item: IBarData) => {
    const barOpt =
      item.type === BarType.Hatching ? ChartHelper.getHatchingBarSeriesOptions() : ChartHelper.getBarSeriesOptions();
    return {
      ...barOpt,
      name: item.name,
      data: item.data.map((value: any) => value.longTotal),
      barMaxWidth: barWidth || 8,
    };
  });
}
