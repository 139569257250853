import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { IPortfolioTrade } from '../../../../../../../models/IPortfolioTrade';
import s from '../TempBlotterTable.module.scss';

interface IProps {
  onRemoveTransaction: (trade: IPortfolioTrade) => void;
  trade: IPortfolioTrade;
}

const DeleteCell: FunctionComponent<IProps> = ({ onRemoveTransaction, trade }: IProps) => {
  return (
    <i
      onClick={(event) => {
        event.stopPropagation();
        onRemoveTransaction(trade);
      }}
      className={cn(s.removeButton)}
    />
  );
};

export default React.memo(DeleteCell);
