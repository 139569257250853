export function getMockedVolatilityData() {
  return {
    insufficientData: false,
    portfolio: {
      volatility: 4.27,
      beta: 1.2794,
      baseCurrencySymbol: '£',
      riskBand: 2,
      riskBandPercentage: 75,
      dataFrequency: 'DAILY',
      annualisationStartDate: '2020-10-10',
      annualisationEndtDate: '2021-01-01',
      dataQuality: 'A',
      insufficientDataExposurePercentage: 30,
      assets: [
        {
          id: 10001,
          name: 'Cash USD',
          weight: -7,
          volatility: 0.1,
          riskBand: 1,
          riskBandPercentage: 45,
          isShort: false,
          assetClass: 'CashAndEquivalents',
          inBaseCurrency: true,
        },
        {
          id: 10002,
          name: 'Cash Non-USD',
          weight: 2,
          volatility: 0.3,
          riskBand: 2,
          riskBandPercentage: 20,
          isShort: true,
          assetClass: 'CashAndEquivalents',
          inBaseCurrency: false,
        },
        {
          id: 10003,
          name: 'Equities USD',
          weight: 50,
          volatility: 11.4,
          riskBand: 7,
          riskBandPercentage: 98,
          isShort: false,
          assetClass: 'Equities',
          inBaseCurrency: true,
        },
        {
          id: 10005,
          name: 'Funds USD',
          weight: 75,
          volatility: 11.4,
          riskBand: 5,
          riskBandPercentage: 20,
          isShort: true,
          assetClass: 'Funds',
          inBaseCurrency: true,
        },
        {
          id: 10004,
          name: 'Developed Non-USD',
          weight: 16,
          volatility: 14.3,
          riskBand: 1,
          riskBandPercentage: 60,
          isShort: false,
          assetClass: 'Funds',
          inBaseCurrency: false,
        },
      ],
      topInstruments: [
        {
          ticker: 'MSFT',
          name: 'Microsoft',
          beta: 1.98,
          totalMarketValueBase: 23018.13,
          positions: [
            {
              custodian: 'HSBC',
              marketValueBase: 17627.13,
              marketValueBasePercentage: 11.7,
            },
            {
              custodian: 'JP Morgan',
              marketValueBase: 5391.82,
              marketValueBasePercentage: 5.2,
            },
          ],
        },
        {
          ticker: 'TSLA',
          name: 'Tesla',
          beta: 0.73,
          totalMarketValueBase: 18525.52,
          positions: [
            {
              custodian: 'HSBC',
              marketValueBase: 17627.2,
              marketValueBasePercentage: 16,
            },
          ],
        },
        {
          ticker: 'NFLX',
          name: 'Netflix',
          beta: 1.88,
          totalMarketValueBase: 15525.55,
          positions: [
            {
              custodian: 'JP Morgan',
              marketValueBase: 12342.21,
              marketValueBasePercentage: 18.4,
            },
          ],
        },
      ],
      drawdownData: [
        {
          date: '019-10-31',
          performanceIndex: 100,
          watermark: 100,
          drawdown: 0,
        },
        {
          date: '2019-11-29',
          performanceIndex: 100,
          watermark: 100,
          drawdown: 0,
        },
        {
          date: '2020-11-30',
          performanceIndex: 100.2,
          watermark: 100.2,
          drawdown: -0.1,
        },
        {
          date: '2021-03-31',
          performanceIndex: 100.6,
          watermark: 100.6,
          drawdown: 0,
        },
      ],
      sharpeRatio: 0.6,
      statistics: {
        performanceMtd: 58.7,
        performancePreviousMonth: 24.5,
        performanceYtd: 92.1,
        monthlyBestReturn: 24.5,
        monthlyWorstReturn: -5,
        percentageUpMonths: 88.2,
        alpha: 0.3,
        longestDrawdownMonths: 1,
        biggestDrawdown: -0.1,
        rollingBeta1Year: 1.5922,
      },
    },
    benchmark: {
      volatility: 1.9,
      riskBand: 2,
      riskBandPercentage: 90,
      indexName: 'uzSYdaFcz',
    },
    volatilityRatio: 0.3,
  };
}
