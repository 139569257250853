import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import s from './PositionDetailRow.module.css';
import { Button, ButtonGroup, InputNumber, Notification } from 'rsuite';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';
import { uniq } from 'lodash';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import useApi, { sendRequest } from '../../../../../../../../services/hooks/useApi';
import { API, BASE_URL, getCustomApiUrl } from '../../../../../../../../services/constants/endpoints';
import moment from 'moment';
import { SERVER_DATE_FORMAT_MOMENT } from '../../../../../../../../services/constants/constants';
import { DatePicker } from '../../../../../../../../components';
import { generatePath } from 'react-router';

type DividendCreatorValues = {
  exDate: Date;
  paymentDate: Date;
  currency: string;
  shares: number;
  cash: number;
  tax?: number;
} & ({ awardedShares: number } | { awardedCash: number });

interface IProps extends IPositionDetailsTab {
  transactions: IPortfolioTrade[];
}

export const DividendCreator = ({ position, portfolioId, transactions }: IProps) => {
  const [values, setValues] = React.useState<Partial<DividendCreatorValues>>({
    exDate: new Date(),
    paymentDate: moment().add(1, 'week').toDate(),
  });

  const { portfolioCurrency } = usePortfolioInfo();
  const positionSummary = useApi(`${BASE_URL}/api/v3/portfolio/${portfolioId}/position/${position.positionId}/summary`, {
    params: {
      date: moment(values.exDate).format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    setValues({ ...values, paymentDate: moment(values.exDate).add(1, 'week').toDate() });
  }, [values.exDate]);

  const updateValue = <K extends keyof DividendCreatorValues>(key: K) => (value: DividendCreatorValues[K]) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formIsInvalid = !(
    !!values.exDate &&
    !!values.paymentDate &&
    !!values.currency &&
    (!!values.shares || !!values.cash)
  );

  const submit = () => {
    const trade = {
      commission: 0,
      currency: values.currency,
      cashQuantity: values.cash ? parseFloat(String(values.cash)) : undefined,
      settlementDate: moment(values.paymentDate).format(SERVER_DATE_FORMAT_MOMENT),
      tradeTime: moment(values.exDate).format(SERVER_DATE_FORMAT_MOMENT),
      quantity: values.shares ? parseFloat(String(values.shares)) : undefined,
      tradeCosts: values.tax,
      positionId: position.positionId,
      portfolioId,
    };

    // const data = {
    //   portfolioId,
    //   trade
    // };

    sendRequest(`/api/v1/${API.endpoints.snapshot.trades.addDividendsFromPosition}`, { method: 'POST', data: trade })
      .then((result) => {
        Notification.success({ title: `${position.assetClassId === 'Funds' ? 'Distribution' : 'Dividend'} added` });
      })
      .catch((error) => {
        Notification.error({ title: 'Unable to create trade', description: error?.message });
      });
  };

  const currencyOptions = React.useMemo(() => {
    const nextOptions = uniq([position.currencyNative, portfolioCurrency]).map((currency) => ({
      id: currency,
      text: currency.toUpperCase(),
      value: currency.toUpperCase(),
    }));

    if (nextOptions[0].value) {
      updateValue('currency')(nextOptions[0].value);
    }

    return nextOptions;
  }, [position.currencyNative, portfolioCurrency]);

  const unitsHeld = React.useMemo(() => {
    return positionSummary?.data?.openQuantity;
  }, [positionSummary]);

  return (
    <div className={cn(s.horizontal)}>
      <table className={cn(s.inputsTable)}>
        <tbody>
          <tr>
            <td style={{ minWidth: 100 }}>Ex Date</td>
            <td>
              <div className={s.datePickerWrapper}>
                <DatePicker
                  value={values.exDate}
                  min={moment(positionSummary?.data?.openingDate).add(1, 'days').toDate()}
                  max={new Date()}
                  onChange={(date) => updateValue('exDate')(date.value!)}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Payment Date</td>
            <td>
              <div className={s.datePickerWrapper}>
                <DatePicker value={values.paymentDate} onChange={(date) => updateValue('paymentDate')(date.value!)} />
              </div>
            </td>
          </tr>

          <tr>
            <td>Awarded Shares</td>
            <td>
              <InputNumber min={0} value={values.shares} onChange={updateValue('shares') as any} />
            </td>
          </tr>
          <tr>
            <td>Awarded Cash</td>
            <td>
              <InputNumber value={values.cash} onChange={updateValue('cash') as any} />
            </td>
          </tr>
          <tr>
            <td>Tax</td>
            <td>
              <InputNumber value={values.tax} onChange={updateValue('tax') as any} />
            </td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>
              <ButtonGroup justified={true}>
                {currencyOptions.map((option) => (
                  <Button
                    active={values.currency === option.value}
                    onClick={() => {
                      updateValue('currency')(option.value);
                    }}
                  >
                    {option.text}
                  </Button>
                ))}
              </ButtonGroup>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          marginLeft: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '0.5rem',
        }}
      >
        <div>{unitsHeld} units held on ex-date</div>
        <Button appearance={'primary'} disabled={formIsInvalid} onClick={submit}>
          Submit
        </Button>
        {/*<pre style={{fontSize: 12}}>{JSON.stringify({transactions}, null, 2)}</pre>*/}
      </div>
    </div>
  );
};
