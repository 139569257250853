import React, { CSSProperties } from 'react';

interface IOwnProps {
  title: string;
  style?: CSSProperties;
}

export const DashboardTitle = ({ title, style }: IOwnProps) => {
  return <div style={{ fontSize: '1rem', ...style }}>{title}</div>;
};
