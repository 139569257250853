import { IActionPayload } from '../../../models/redux/IActionPayload';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { EquityActions, DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';

const INITIAL_INCOME_DETAILED_STATE: any = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const incomeDetailedyReducerDefault = ReduxHelper.createHttpRequestWithPeriodReducer<
  ILoadingPeriodDataState<IBarData[]>
>(
  INITIAL_INCOME_DETAILED_STATE,
  DashboardActions.INCOME_DETAILED_REQUEST,
  DashboardActions.INCOME_DETAILED_SUCCESS,
  DashboardActions.INCOME_DETAILED_ERROR,
  DashboardActions.INCOME_DETAILED_RESET
);

const incomeDetailedReducer = (state = INITIAL_INCOME_DETAILED_STATE, action: IActionPayload) => {
  switch (action.type) {
    case EquityActions.DIVIDENDS_CHANGE_PERIOD: {
      return {
        ...state,
        period: action.payload.period,
        currency: action.payload.currency,
      };
    }
    default:
      return incomeDetailedyReducerDefault(state, action);
  }
};

export default incomeDetailedReducer;
