import React, { ReactText } from 'react';
import { BarChart } from 'src/components/illioleo.ui-react/ui/bar-chart';
import { Tooltip } from 'src/components/illioleo.ui-react/ui/bar-chart/IBarChart';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { COLORS } from './OptionsConstants';
import { IRootState } from '../../../../../../../../../../services/store';

interface ISettingsProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency: { value: string; label: string };
  portfolioCurrency: string;
  optionsDashboard: IRootState['dashboard']['options']['dashboard'];
  width?: number;
}

type Series = {
  label: string;
  values: Record<string, { x: ReactText; y: number; d: string }>;
  color?: string;
  type?: string;
};
type OptionType = 'cl' | 'pl' | 'cs' | 'ps';

const OptionsPremiums = ({
  width = 800,
  optionsDashboard,
  portfolioCurrencyFormatter,
  selectedCurrency,
  portfolioCurrency,
}: ISettingsProps) => {
  const [ready, setReady] = React.useState(false);

  const optionsData = optionsDashboard.data;

  // console.log(JSON.stringify({portfolioCurrency}, null, 2))

  const chartValues = React.useMemo(() => {
    if (!optionsData?.chartData) {
      return [];
    }

    const series: { cl: Series; cs: Series; pl: Series; ps: Series } = {
      cl: { label: 'Long Calls', values: {}, color: COLORS.LONG_CALL },
      pl: { label: 'Long Puts', values: {}, color: COLORS.LONG_PUT },
      cs: { label: 'Short Calls', values: {}, color: COLORS.SHORT_CALL },
      ps: { label: 'Short Puts', values: {}, color: COLORS.SHORT_PUT },
    };

    optionsData?.chartData.forEach((item) => {
      item.entries.forEach((entry) => {
        const optionType = `${entry.callOrPut === 'PUT' ? 'p' : 'c'}${
          entry.marketValueBase >= 0 ? 'l' : 's'
        }` as OptionType;
        const others = ['cl', 'cs', 'pl', 'ps'].filter((key) => key !== optionType) as OptionType[];

        series[optionType].values[item.monthYear] = series[optionType].values[item.monthYear] ?? {
          x: new Date(item.monthStartDate),
          y: 0,
          d: item.monthStartDate,
        };
        series[optionType].values[item.monthYear].y += entry.marketValueBase ?? 0;

        others.forEach((other) => {
          series[other].values[item.monthYear] = series[other].values[item.monthYear] ?? {
            x: new Date(item.monthStartDate),
            y: 0,
            d: item.monthStartDate,
          };
        });
      });
    });

    const result = Object.entries(series).map(([key, val]) => ({
      ...val,
      values: Object.entries(series[key as OptionType].values)
        .sort((a, b) => a[1]?.d?.localeCompare(b[1]?.d ?? 'zzz') ?? -1)
        .map(([_, v]) => ({ x: v.x, y: v.y })),
    }));

    // console.log({result})
    setReady(true);
    return result;
  }, [optionsData?.chartData]);

  if (!optionsData?.hasOptions) {
    return null;
  }

  const yAxisUnit = selectedCurrency.value === 'All' ? portfolioCurrency ?? 'All' : selectedCurrency.value ?? '';
  return (
    <div>
      <BarChart
        series={chartValues as any}
        key={width}
        settings={{
          tooltipFormatter: tooltipFormatter(portfolioCurrencyFormatter),
          height: 300,
          width,
          yAxisUnit,
          xAxisLabel: '',
          bgColor: 'transparent',
          labelColor: '#70768D',
          yTicks: 10,
          gridOpacity: 100,
          gridColor: '#70768D',
          marginTop: 40,
          marginBottom: 45,
          marginRight: 5,
          marginLeft: 40,
          strokeWidth: 20,
          isLoading: !ready || !optionsDashboard || optionsDashboard?.isFetching,
          tooltipWidth: 220,
          tooltipHeight: 120,
          indicatorLineColor: 'yellow',
        }}
      />
    </div>
  );
};

const tooltipFormatter = (portfolioCurrencyFormatter: ICurrencyFormatter) => (tooltip: Tooltip | null) => {
  if (!tooltip) {
    return <div />;
  }
  const { date, data } = tooltip;
  return (
    <div style={{ backgroundColor: 'white', padding: '0.5rem', borderRadius: 6, color: 'black' }}>
      <strong>{String(date).substr(0, 7)}</strong>
      {data.map(({ color, label, value }) => (
        <div
          style={{
            display: 'flex',
            marginTop: 5,
          }}
        >
          <div
            style={{
              backgroundColor: color,
              marginRight: 5,
              width: 15,
              height: 15,
              borderRadius: '50%',
            }}
          />
          <span style={{ color: 'black' }}>{`${label} ${portfolioCurrencyFormatter(value)}`}</span>
        </div>
      ))}
    </div>
  );
};

export default OptionsPremiums;
