import React, { FC } from 'react';
import cn from 'classnames';
import s from './AccountSelector.module.css';

import RegistrationTemplate from '../../../scenes/Account/scenes/Registration/components/RegistrationTemplate';
import { IRootState } from '../../../services/store';
import Loader from '../../UIWidgets/Loader/Loader';
import portfolio from '../../../static/images/logo_auth.png';
import { useHistory } from 'react-router-dom';

interface IProps {
  illioSelectedAccount: string | null;
  selectIllioAccount(id: string | number | null): void;
  accounts: IRootState['accounts'];
}

const AccountSelector: FC<IProps> = ({ selectIllioAccount, accounts }) => {
  const history = useHistory();
  React.useEffect(() => {
    if (accounts.data?.length === 1) {
      selectIllioAccount(accounts.data[0].id);
    } else if (accounts.data?.length >= 2) {
      history.push('/');
    }
  }, [accounts]);

  if (accounts.isFetching || accounts.data?.length < 2) {
    return <Loader />;
  }

  return (
    <RegistrationTemplate>
      <div>
        <h1>Select Account</h1>
        <div className={cn(s.accountsSurround)}>
          {accounts.data?.map((account) => (
            <article key={account.id} className={cn(s.accountSurround)} onClick={() => selectIllioAccount(account.id)}>
              {!!account.themeMetaData?.icon && <img src={account.themeMetaData!.icon} className={cn(s.companyLogo)} />}
              {!account.themeMetaData?.icon && <img src={portfolio} className={cn(s.companyLogo)} />}
              <div className={cn(s.accountName)}>{account.name}</div>
            </article>
          ))}
        </div>
      </div>
    </RegistrationTemplate>
  );
};

export default AccountSelector;
