import { ITableColumn } from '../../../../../../components/UIWidgets/TableWrapper/models';
import React from 'react';

export function getTradesColumns(editable: boolean = false, portfolioCurrency: string): ITableColumn[] {
  return [
    {
      label: 'Trade Date',
      dataKey: 'tradeTime',
      flexGrow: 0,
      flexShrink: 0,
      width: 80,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 2,
      width: 300,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: 'Ticker',
      dataKey: 'ticker',
      flexGrow: 2,
      width: 180,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: 'Type',
      dataKey: 'operationName',
      flexGrow: 0,
      width: 80,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: 'Quantity',
      dataKey: 'quantity',
      flexGrow: 0,
      flexShrink: 0,
      width: 120,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: `Cost Price`,
      dataKey: 'price',
      flexGrow: 1,
      width: 200,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: 'Ccy',
      dataKey: 'currency',
      flexGrow: 0,
      flexShrink: 0,
      width: 40,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Settlement',
      dataKey: 'settlementOption',
      flexGrow: 0,
      flexShrink: 0,
      width: 80,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Other Qty',
      dataKey: 'otherQuantity',
      flexGrow: 1,
      width: 120,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Source',
      dataKey: 'tradeSource',
      flexGrow: 0,
      flexShrink: 0,
      width: 80,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Multiplier',
      dataKey: 'multiplier',
      flexGrow: 1,
      width: 60,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: `Gross Value`,
      dataKey: 'amount',
      flexGrow: 1,
      width: 220,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Commission',
      dataKey: 'commission',
      flexGrow: 1,
      width: 150,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Other Charges',
      dataKey: 'tradeCosts',
      flexGrow: 1,
      width: 150,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Base FX',
      dataKey: 'displayBaseFx',
      flexGrow: 1,
      width: 130,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: `Value ${portfolioCurrency}`,
      dataKey: 'netAmountBase',
      flexGrow: 1,
      width: 150,
      display: true,
      disableSort: false,
      isOptional: false,
    },
    {
      label: 'Asset Class',
      dataKey: 'assetSubClass',
      flexGrow: 1,
      width: 140,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Historic',
      dataKey: 'historic',
      flexGrow: 1,
      width: 140,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Historic P&L',
      dataKey: 'historicPnl',
      flexGrow: 1,
      width: 140,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Historic Price',
      dataKey: 'historicPrice',
      flexGrow: 1,
      width: 140,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: 'Custodian',
      dataKey: 'custodian',
      flexGrow: 1,
      width: 140,
      display: true,
      disableSort: false,
      isOptional: true,
    },
    {
      label: '',
      dataKey: 'notes',
      flexGrow: 0,
      flexShrink: 0,
      width: 14,
      display: true,
      disableSort: true,
      isOptional: false,
    },
    {
      label: '',
      dataKey: 'baseSettlementCurrency',
      flexGrow: 2,
      width: 22,
      display: false,
      disableSort: true,
      isOptional: false,
    },

    ...(editable
      ? [
          {
            label: '',
            dataKey: 'selected',
            flexGrow: 0,
            flexShrink: 0,
            width: 30,
            display: editable,
            disableSort: true,
            isOptional: false,
          },
        ]
      : []),
  ];
}
