import * as React from 'react';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import debounce from 'lodash.debounce';
import { TRANSACTION_TYPES } from '../../../../../../../../services/mock/transactionTypes';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import axios from 'axios';
import { currencyValueFormatter } from '../../../../../../../../services/utils/CurrencyHelpers';
import { Autocomplete } from '../../../../../../../../components/UIWidgets/Autocomplete';
import s from './InstrumentSearchInput.module.scss';
import { useEffect, useState } from 'react';

interface IProps {
  onAdd: (el: IOption<IAdjustmentType> | null) => void;
  params: string;
  placeholder: string;
  disabled: boolean;
  presearch?: string;
}

const InstrumentSearchInput = ({ onAdd, params, placeholder, disabled, presearch }: IProps) => {
  useEffect(() => {
    if (!disabled && presearch) {
      search(presearch).then((resp) => setDefaultOptions(mapAssets(resp)));
    }
  }, [disabled]);

  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);

  const mapAssets = (res: any) =>
    res.data.map((asset: IAdjustmentType) => {
      let name = asset.name;
      // console.log(asseInputt);

      if (asset.code !== '' && asset.code !== null) {
        if (
          typeof asset.assetClass === 'string' &&
          (asset.assetClass === 'Alternatives' || asset.assetClass === TRANSACTION_TYPES.cryptoCurrencies)
        ) {
          name = `${asset.name} [${asset.code}]`;
        } else {
          name = `${asset.name} [${asset.code} - ${asset.currency}]`;
        }
      }
      return {
        id: asset.code + asset.currency,
        name,
        value: asset,
      };
    });

  const search = (str: string) => {
    const url = `${getApiUrl('instruments.query')}?query=${str}${params}`;
    return axios.get(url);
  };

  const _loadSuggestions = (query: any, callback: any) => {
    search(query).then((resp) => callback(mapAssets(resp)));
  };

  const debouncedSearch = debounce(_loadSuggestions, 300);

  return (
    <div className={s.wrapper}>
      <Autocomplete
        disabled={disabled}
        defaultOptions={defaultOptions || []}
        value={null}
        placeholder={presearch || placeholder}
        onSelect={onAdd}
        loadOptions={debouncedSearch}
      />
    </div>
  );
};

export default InstrumentSearchInput;
