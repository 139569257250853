import React, { useEffect } from 'react';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import cn from 'classnames';
import s from '../../UniversalUpload.module.css';

import { InputPicker, Checkbox } from 'rsuite';
import { useCapabilities } from '../../../../../../../../services/hooks/apiHooks/useCapabilities';
import { usePortfolioInfo } from '../../../../../../../../services/hooks/usePortfolioInfo';
import { Button, ToggleLeftRight } from '../../../../../../../../components';
import { useModal } from '../../../../../../../../services/context/ModalContext';

interface IOwnProps {
  existingCustodians: ExtendedCustodianOption[];
  detectedCustodians?: string[];
  custodianMappings: Record<string, string>;
  syntheticDividends: Record<string, boolean>;
  setSyntheticDividends: (mapping: Record<string, boolean>) => void;
  setCustodianMappings: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  custodiansMappingError?: boolean;
}

export type ExtendedCustodianOption = IOption<ICustodian> & { label: IOption<ICustodian>['name'] };
const leftLabel = 'Auto-Generated';
const rightLabel = 'Manual Entry';

export const CustodianResolution = ({
  existingCustodians,
  detectedCustodians,
  custodianMappings,
  syntheticDividends,
  setSyntheticDividends,
  setCustodianMappings,
  custodiansMappingError,
}: IOwnProps) => {
  const [allCustodians, setAllCustodians] = React.useState(existingCustodians);

  useEffect(() => {
    setAllCustodians(existingCustodians);
  }, [existingCustodians]);

  const { setSearch } = useModal();

  const onChange = (custodian: string) => async (value: string | ICustodian) => {
    setCustodianMappings({ ...custodianMappings, [custodian]: typeof value === 'string' ? value : value?.name });
    setSyntheticDividends({ ...syntheticDividends, [custodian]: false });
  };
  const { preferences } = useCapabilities();
  const { portfolioInfo } = usePortfolioInfo();
  const shouldDisplaySyntheticDividends =
    !portfolioInfo.data?.hasOwnProperty('onlyManualDividendsDefault') || !preferences.onlyManualDividendsDefault;

  const hasDefaultCustodian = existingCustodians.some((c) => c.value.default);

  if (!detectedCustodians?.length) {
    return null;
  }

  const quickAddCustodian = (name: string) => {
    if (!hasDefaultCustodian) {
      setSearch(name);
    }

    const onlyManualDividends = shouldDisplaySyntheticDividends
      ? syntheticDividends[name]
      : portfolioInfo.data?.onlyManualDividendsDefault;

    setAllCustodians((prev) => [
      ...prev,
      {
        value: {
          id: name,
          code: name,
          name,
          onlyManualDividends,
          default: false,
          justAdded: true,
        },
        id: name,
        label: name,
        name,
      },
    ]);
    onChange(name)(name);
  };

  const toggleDividendsForCustodian = (custodian: ICustodian) => {
    setSyntheticDividends({ ...syntheticDividends, [custodian.name]: true });
    setAllCustodians((prev) =>
      prev.map((item) =>
        item.value.code === custodian.code
          ? { ...item, value: { ...item.value, onlyManualDividends: !item.value.onlyManualDividends } }
          : item
      )
    );
  };

  return (
    <div className={cn(s.settingsArea, custodiansMappingError && s.error)} style={{ width: 410 }}>
      <strong style={{ fontSize: 16 }}>Map custodians</strong>
      <div className={cn(s.custodianSurround)}>
        <div style={{ flex: 1 }}>Custodian in file</div>
        <div style={{ flex: 1 }}>Your custodians</div>
        <div style={{ flex: 2.5 }}>Dividend source</div>
      </div>
      <div className={cn(s.custodiansSurround)}>
        {detectedCustodians.map((custodianName) => {
          const resolvedCustodian = allCustodians.find(
            (custodian) => custodian.name === custodianMappings[custodianName]
          )?.value;
          return (
            <div key={custodianName} className={cn(s.custodianSurround)}>
              <div style={{ flex: 1 }}>
                <div className={cn(s.custodianAddSurround)}>
                  {!resolvedCustodian && (
                    <Button size={'xsmall'} onClick={() => quickAddCustodian(custodianName)}>
                      + Add {custodianName}
                    </Button>
                  )}
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <InputPicker
                  value={resolvedCustodian}
                  data={allCustodians}
                  creatable={true}
                  cleanable={false}
                  onChange={onChange(custodianName!)}
                  className={cn(s.error)}
                  placeholder={'Select'}
                />
              </div>
              {(resolvedCustodian as any)?.justAdded ? (
                <div className={s.toggleContainer}>
                  <ToggleLeftRight
                    leftLabel={leftLabel}
                    rightLabel={rightLabel}
                    toggleCurrentPosition={
                      resolvedCustodian !== undefined
                        ? !resolvedCustodian.onlyManualDividends
                        : preferences?.onlyManualDividendsDefault
                    }
                    clickFunction={() => toggleDividendsForCustodian(resolvedCustodian!)}
                  />
                </div>
              ) : (
                <div className={s.toggleContainer} />
              )}
            </div>
          );
        })}
      </div>
      {custodiansMappingError && <strong>Please provide a mapping for all custodians</strong>}
      {/*<pre style={{ fontSize: 12 }}>{JSON.stringify({ custodianMappings }, null, 2)}</pre>*/}
    </div>
  );
};
