import React, { Fragment, ReactNode, useEffect } from 'react';
import { InfoBlockV2 } from '../../../../../../components';
import past from '../../../../../../../../static/images/dashboard/past.svg';
import s from '../../Dashboard.module.scss';
import { Card } from '@iliotech/storybook';
import future from '../../../../../../../../static/images/dashboard/future.svg';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import useApi from '../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../services/constants/endpoints';
import { PeriodHelper } from '../../../../../../../../services/utils/PeriodHelper';
import { FormatHelper } from '../../../../../../../../services/utils/FormatHelper';
import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';
import { Loader } from '../../../../../../../../components/UIWidgets/Loader';
import SinceInceptionIncomeChart from './components/SinceInceptionIncomeChart';
import NextYearIncomeChart from './components/NextYearIncomeChart';

interface IProps {
  incomePeriod: IPeriod;
  portfolioId: string;
  selectedCurrency: IOptionType<any>;
  selectedCustodians: string[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  renderCurrencySelector: () => ReactNode;
  eChartsRef: any;
}

const readablePeriod = {
  0: 'Month to Date',
  1: 'Year to Date',
  2: 'Since Inception',
  3: '1 Year',
  4: '3 Years',
  5: 'Range',
  6: 'Previous 3m',
  7: 'Previous 6m',
  8: 'Last year',
  9: 'Calendar year',
  10: 'Last Quarter',
  11: '1 Day',
  12: '1 Week',
  13: '30 Days',
};

const Income = ({
  incomePeriod,
  portfolioId,
  selectedCustodians,
  selectedCurrency,
  portfolioCurrencyFormatter,
  renderCurrencySelector,
  eChartsRef,
}: IProps) => {
  const income = useApi(getApiUrl('reports.income'), {
    method: 'POST',
    data: {
      portfolioId,
      period: PeriodHelper.preparePeriodForRequest(incomePeriod),
      custodians: selectedCustodians || [],
      currency: selectedCurrency.value === 'All' ? undefined : selectedCurrency.value,
    },
  });

  useEffect(() => {
    income.refetch();
  }, [incomePeriod, portfolioId, selectedCustodians, selectedCurrency]);

  if (income.isLoading) {
    return <Loader />;
  }

  let pastVal = portfolioCurrencyFormatter(income.data.pastData ? income.data.pastData.total : 0);
  let nextVal = portfolioCurrencyFormatter(income.data.futureData ? income.data.futureData.total : 0);

  if (selectedCurrency.value !== 'All') {
    pastVal =
      selectedCurrency.label +
      ' ' +
      (income.data.pastData ? FormatHelper.formatNumberAsValue(income.data.pastData.total, 0) : 0 + '');
    nextVal =
      selectedCurrency.value +
      ' ' +
      (income.data.futureData ? FormatHelper.formatNumberAsValue(income.data.futureData.total, 0) : 0 + '');
  }

  return (
    <Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ maxWidth: 300 }}>
          <InfoBlockV2
            image={past}
            title={'Total ' + readablePeriod[incomePeriod.type]}
            value={pastVal}
            colorValue={true}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>{renderCurrencySelector()}</div>
      </div>

      <div className={s.defaultContainer}>
        <Card style={{ flex: 1, paddingTop: 20 }}>
          <SinceInceptionIncomeChart
            incomeData={income.data.pastData.barData}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCurrency={selectedCurrency.value}
            incomePeriod={incomePeriod}
          />
        </Card>
      </div>
      <div style={{ height: 30 }} />
      <div style={{ maxWidth: 300 }}>
        <InfoBlockV2 image={future} title="Total Next 12 months" value={nextVal} colorValue={true} />
      </div>
      <div className={s.defaultContainer}>
        <Card style={{ flex: 1 }}>
          <NextYearIncomeChart
            incomeData={income.data.futureData.barData}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCurrency={selectedCurrency.value}
            incomePeriod={incomePeriod}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default Income;
