import React from 'react';
import useApi from '../useApi';
import { API } from '../../constants/endpoints';
import { IOption } from '../../../components/UIWidgets/Autocomplete';

export const useCurrencies = () => {
  const [currencies, setCurrencies] = React.useState<ICurrency[]>([]);
  const [currencyOptions, setCurrencyOptions] = React.useState<(IOption<string> & {isBase?: boolean })[]>([]);
  const [currencyInstruments, setCurrencyInstruments] = React.useState<
    IOption<{ sourceId: string; sourceData: string }>[]
  >([]);

  const { error, isFetching, data, dataUpdatedAt } = useApi<ICurrency[]>(
    `/${API.url}/${API.version}/${API.endpoints.currency.all}`,
    { method: 'POST' }
  );

  React.useEffect(() => {
    if (data) {
      setCurrencies(data);

      const newCurrencyInstruments: IOption<{ sourceId: string; sourceData: string, isBase?: boolean  }>[] = [];
      const newCurrencyOptions =
        data?.map((currency) => {
          newCurrencyInstruments.push({
            id: currency.id,
            name: `${currency.name} (${currency.symbol})`,
            value: {
              sourceId: `${currency.name.toUpperCase()}SPOT`,
              sourceData: 'SystemInstrumentService',
            },
          });

          return {
            id: currency.id,
            name: `${currency.name} (${currency.symbol})`,
            isBase: currency.isBase,
            value: currency.name,
          };
        }) || [];
      setCurrencyInstruments(newCurrencyInstruments);
      setCurrencyOptions(newCurrencyOptions);
    }
  }, [dataUpdatedAt]);

  return React.useMemo(
    () => ({
      currenciesFetching: isFetching,
      currencyList: currencies,
      currencyOptions,
      currencyInstruments,
    }),
    [data, isFetching, currencyInstruments]
  );
};
