import echarts from 'echarts/lib/echarts';
import { DEFAULT_TOOLTIP_OPTIONS } from '../../services/constants/charts';
import { ASSET_COLORS } from '../../services/constants/assetClasses';
import { ICurrencyFormatter } from '../../services/selectors/portfolio';
import { ChartHelper } from '../../services/utils/ChartHelper';
import { FormatHelper } from '../../services/utils/FormatHelper';
import { ITreeMapData } from './TreeMap';

type FormatterParam = echarts.EChartOption.Tooltip.Format;
type TreeMapSeries = echarts.EChartOption.SeriesTreemap;

export function getTreeMapChartOptions(
  treeData: ITreeMapData[],
  portfolioCurrencyFormatter: ICurrencyFormatter
): echarts.EChartOption {
  treeData = setColors(treeData);
  const chartOpt = ChartHelper.getChartOptions({ tooltip: false, portfolioCurrencyFormatter });
  const { trigger, ...tooltipOpt } = DEFAULT_TOOLTIP_OPTIONS;
  return {
    ...chartOpt,
    tooltip: {
      ...tooltipOpt,
      formatter: (params: FormatterParam | FormatterParam[]) => {
        if (Array.isArray(params)) {
          return `${params[0].data.name} ${FormatHelper.round(params[0].data.percentage, 100)}%`;
        }

        return `${params.data.name} ${FormatHelper.round(params.data.percentage, 100)}%`;
      },
    },
    series: getSeriesOption(treeData),
  };
}

function getSeriesOption(treeData: ITreeMapData[]): TreeMapSeries[] {
  const seriesOpt: TreeMapSeries = {
    name: '',
    type: 'treemap',
    width: '100%',
    height: '100%',
    roam: false,
    label: {
      show: true,
      position: 'insideTopLeft',
      formatter: (params: { name: string; value: number; data: { percentage: number } }) => {
        const arr = [`{name|${params.name}}`, `{value|${FormatHelper.round(params.data.percentage, 100)} %}`];
        return arr.join('\n');
      },
      rich: {
        value: {
          fontSize: 16,
          lineHeight: 18,
          color: 'var(--text-white)',
        },
        name: {
          fontSize: 12,
          lineHeight: 20,
          color: 'var(--text-white)',
        },
      },
    },
    itemStyle: {
      borderColor: 'transparent',
    },
    nodeClick: false,
    levels: [
      {
        itemStyle: {
          gapWidth: 2,
        },
      },
      {
        itemStyle: {
          gapWidth: 1,
        },
      },
    ],
    breadcrumb: {
      show: false,
    },
    data: treeData,
  };
  return [seriesOpt];
}

function setColors(treeData: ITreeMapData[]): ITreeMapData[] {
  return treeData.map((item) => {
    if (item.id && item.id in ASSET_COLORS) {
      return { ...item, itemStyle: { color: ASSET_COLORS[item.id] } };
    }
    return item;
  });
}
