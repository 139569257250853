import React, { Fragment, FunctionComponent, InputHTMLAttributes } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'; // TODO: Replace with Illio icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // TODO: Replace with Illio icons
import cn from 'classnames';
import s from './SimpleTypeAhead.module.css';

import Input, { IInputComponentProps } from '../../UIWidgets/Input/Input';
import { IOption } from '../../UIWidgets/Autocomplete';

type IProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
  IInputComponentProps &
  WrappedFieldProps & { tradeTypeChanged?: string; editMode?: boolean; options?: IOption<any>[] };

const SimpleComponent: FunctionComponent<IProps> = ({ input, meta, editMode, disabled, options, ...props }: IProps) => {
  const haveError =
    props.tradeTypeChanged === 'true' || editMode ? meta.error && meta.pristine : meta.error && meta.touched;
  const haveWarn =
    props.tradeTypeChanged === 'true' || editMode ? meta.warning && meta.pristine : meta.warning && meta.touched;
  const ref = React.createRef<HTMLInputElement>();

  // let hasExactMatch = false;

  // if (options?.some((option) => option.name.toUpperCase() === input.value.toUpperCase())) {
  //   hasExactMatch = true;
  // }

  const filteredOptions = options
    ?.filter((option) => option.name.toUpperCase().indexOf(input.value.toUpperCase()) > -1)
    .sort((a, b) => (a.name === input.value ? -10000 : 0) + a.name.localeCompare(b.name));

  return (
    <Fragment>
      <Input
        ref={ref}
        autoComplete="off"
        error={haveError}
        warning={haveWarn}
        {...input}
        {...props}
        disabled={disabled}
      />
      <div className={cn(s.optionsScrollWrap)}>
        <div className={cn(s.options)} style={{ height: `${(filteredOptions?.length || 0) * 1.4}rem` }}>
          {filteredOptions?.map((option) => (
            <div
              className={cn(s.row, option.name.toUpperCase() === input.value.toUpperCase() && s.selected)}
              onClick={() => input.onChange(option.name)}
            >
              <span>{option.name}</span>
              {option.name.toUpperCase() === input.value.toUpperCase() && (
                <FontAwesomeIcon icon={faCheck} color={'var(--bg-dark)'} />
              )}
            </div>
          ))}
        </div>
      </div>
      {haveError && <div className="input__error-text">{meta.error}</div>}
      {haveWarn && <div className="input__warning-text">{meta.warning}</div>}
    </Fragment>
  );
};

export default React.memo(SimpleComponent);
