import React, { FC, Fragment } from 'react';
import cn from 'classnames';
import s from './Toggle.module.scss';

import { Button } from '../../UIWidgets/Button';

interface IOwnProps<T> {
  values: Array<{ value: T; text: string; subText?: string }>;
  selectedValue?: T | null;
  onChange(value: T): void;
  disabled?: boolean;
  error?: string;
}

const Toggle = <T,>({ values, disabled, onChange, error, selectedValue = null }: IOwnProps<T>): JSX.Element => {
  return (
    <Fragment>
      <div className={s.toggleSurround}>
        {values.map((item, id) => (
          <Button
            disabled={disabled}
            key={id}
            active={selectedValue === item.value}
            size="small"
            className={cn(s.formBtn, s.toggleBtn, {
              [s.active]: selectedValue === item.value,
              [s.smallLineHeight]: !!item.subText,
            })}
            onClick={() => onChange(item.value)}
          >
            {`${item.text}`}
            <br />
            {item.subText ? item.subText : ``}
          </Button>
        ))}
      </div>
      {!!error && <div className="input__error-text">{error}</div>}
    </Fragment>
  );
};

export default Toggle;
