import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { DropEvent, useDropzone } from 'react-dropzone';

import s from '../Upload.module.scss';

interface IProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => void;
  allowedExtensions?: string[];
  label?: string;
  logoUrl?: string;
  logoBgColor?: IUploadTemplate['bgColor'];
}

const ALLOWED_EXT: string[] = ['.xlsx'];
export const ALL_EXCEL_EXTENSIONS: string[] = ['.xlsx', '.xls', '.csv'];

const UploadFile: FunctionComponent<IProps> = ({
  onDrop,
  allowedExtensions = ALLOWED_EXT,
  label,
  logoUrl,
  logoBgColor = 'dark',
}) => {
  const { getRootProps, getInputProps } = useDropzone({ accept: allowedExtensions, onDrop });
  return (
    <div {...getRootProps({ className: s.dropzone })}>
      <input {...getInputProps()} />
      <div className="text-md">
        <span className="text-blue">Browse your files or</span> <br />
        or drag and drop file here to upload
      </div>

      {!!logoUrl && (
        <div
          className={cn(s.logoBg)}
          style={{ backgroundColor: logoBgColor === 'light' ? 'var(--light)' : 'var(--dark)' }}
        >
          <img src={logoUrl} className={cn(s.logo)} />
        </div>
      )}
      {!!label && <span className={cn(s.label)}>{label}</span>}

      {/*<div>*/}
      {/*  Download example*/}
      {/*  <a*/}
      {/*    onClick={(e) => e.stopPropagation()}*/}
      {/*    className={cn('text-blue', s.exampleLink)}*/}
      {/*    href={`${process.env.PUBLIC_URL}/downloads/Trade_Upload_Template.xlsx`}*/}
      {/*    download={true}*/}
      {/*  >*/}
      {/*    Transactions*/}
      {/*  </a>{' '}*/}
      {/*</div>*/}
    </div>
  );
};

export default React.memo(UploadFile);
