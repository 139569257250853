import React from 'react';
import {
  Chart,
  ChartArea,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartCategoryAxisLabels,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartPanes,
  ChartPane,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import moment from 'moment';
import 'hammerjs';
import { ChartProps } from '@progress/kendo-react-charts/dist/npm/ChartProps';

export interface IKendoLineChartProps extends ChartProps {
  categories: unknown[];
  expanded: boolean;
  name: string;
  series: { name: string; values: { date: string; value: number }[]; color: string }[];
  nameHidden: boolean;
  height: number;
  currencyFormatter: (val: number | undefined) => string;
}

interface ISharedToolTipProps {
  categoryText: string;
  points: { series: { name: string; color: string }; value: number }[];
}

const tooltipLegendMapping = {
  CashAndEquivalents: 'Cash and Equivalents',
  CryptoCurrencies: 'Crypto-Currencies',
  RealAssets: 'Real Assets',
  FixedIncome: 'Fixed Income',
};
const tooltipLegendMap = new Map(Object.entries(tooltipLegendMapping));

const SharedTooltip = (props: ISharedToolTipProps & { currencyFormatter: (val: number | undefined) => string }) => {
  const { points, categoryText } = props;
  return (
    <div>
      <div
        style={{
          fontSize: '0.6rem',
          marginBottom: '0.5rem',
        }}
      >
        {moment(categoryText).format('YYYY-MM-DD')}
      </div>
      {points.sort(sortPointsByValue).map(
        (point) =>
          point.series.name && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.2rem',
              }}
            >
              <div
                style={{
                  backgroundColor: point.series.color,
                  height: '1rem',
                  width: '1rem',
                  borderRadius: '0.1rem',
                  marginRight: '0.5rem',
                }}
              ></div>
              <div
                style={{
                  fontSize: '0.6rem',
                }}
              >
                {tooltipLegendMap.get(point.series.name) ?? point.series.name}: {props.currencyFormatter(point.value)}
              </div>
            </div>
          )
      )}
    </div>
  );
};

// of course this will be refactored
export const KendoLineChart = ({
  expanded,
  categories,
  name,
  currencyFormatter,
  series,
  nameHidden = false,
  height = 200,
  ...props
}: IKendoLineChartProps) => {
  const sharedTooltipRender = (context: ISharedToolTipProps) => (
      <SharedTooltip currencyFormatter={currencyFormatter} {...context} />
  );

  return (
    <Chart {...props} style={{ fontSize: '40px' }}>
      {/** @ts-ignore */}
      <ChartTooltip
        opacity={0.9}
        render={sharedTooltipRender}
        shared={true}
      />
      <ChartPanes>
        <ChartPane name="top" />
        <ChartPane name="bottom" height={expanded ? 55 : 35} />
      </ChartPanes>
      <ChartArea height={height} background={'transparent'} />
      <ChartLegend visible={false} />
      {!nameHidden && <ChartTitle text={name} color={'white'} />}
      <ChartSeries>
        {series.map((seriesItem, idx) => {
          return (
            <ChartSeriesItem
              name={seriesItem.name}
              type="line"
              width={1}
              color={seriesItem.color}
              maxSize={1}
              markers={{
                visible: false,
              }}
              field={'value'}
              categoryField={'date'}
              data={seriesItem.values}
              key={`series-${idx}`}
            />
          );
        })}
        <ChartSeriesItem type="bar" data={series[0]?.values?.map((val) => 0)} axis="bottom" />
      </ChartSeries>
      <ChartValueAxis>
        <ChartValueAxisItem
          majorGridLines={{
            color: '#3a466d',
          }}
        >
          <ChartCategoryAxisLabels
            font={`${expanded ? '12' : '9'}px 'Nunito'`}
            color={'var(--cloudy-blue)'}
            content={(e) => currencyFormatter(e.value)}
          />
        </ChartValueAxisItem>
        <ChartValueAxisItem name="bottom" pane="bottom">
          <ChartCategoryAxisLabels color={'transparent'} content={(e) => ''} />
        </ChartValueAxisItem>
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          type={'date'}
          baseUnit={'days'}
          pane={'top'}
          crosshair={{
            visible: true,
            color: 'white',
          }}
          maxDivisions={expanded ? 55 : 20}
          labels={{
            color: 'var(--cloudy-blue)',
            rotation: 'auto',
            content: (e) => '',
          }}
          categories={categories}
        ></ChartCategoryAxisItem>
        <ChartCategoryAxisItem
          type={'date'}
          baseUnit={'days'}
          pane={'bottom'}
          maxDivisions={expanded ? 55 : 20}
          labels={{
            color: 'var(--cloudy-blue)',
            rotation: 'auto',
            font: `${expanded ? '12' : '9'}px 'Nunito'`,
            content: (e) => moment(e.value).format('MMM-YY'),
          }}
          categories={categories}
        />
      </ChartCategoryAxis>
    </Chart>
  );
};

function sortPointsByValue(a: { value: number }, b: { value: number }) {
  return b.value - a.value;
}
