import React, { Fragment, useEffect, useState } from 'react';
import { Period } from '../../../../../../../../components/Period';
import { Select } from '../../../../../../../../components/UIWidgets';
import { ASSETS } from '../../../../../../../../services/constants/assetClasses';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import { AssetReportWrapper } from '../../components/AssetReportWrapper';
import Analytics from '../../../../../../../../services/utils/Analytics';
import s from './Equity.module.scss';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';
import { EquityOptions } from './scenes/EquityOptions';
import { GlobalPnl } from '../Shared/GlobalPnl';
import { getTopPositionsByPnLColumns, getTopPositionsColumns } from './topPositionsColumns';
import { equityCellRenderer } from './utils/EquityCellRenderer';
import { GlobalStructure } from '../Shared/GlobalStructure';
import { GlobalPnlBreakdown } from '../Shared/GlobalPnlBreakdown';
import { GlobalIncome } from '../Shared/GlobalIncome';
import { GlobalFundamentals } from '../Shared/GlobalFundamentals';
import { RouteComponentProps, withRouter } from 'react-router';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { PortfolioHeader } from '../../../../../../components';
import { Loader } from '../../../../../../../../components';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';
import { Helmet } from 'react-helmet';

type IProps = RouteComponentProps<{ page: string }>;

const Equity = ({ match }: IProps) => {
  const page = match?.params?.page;
  const {
    hasData,
    isLoading,
    portfolioCurrencyFormatter,
    dashboardInfo,
    portfolioInfo,
    isFetched,
    refetchTopPositions,
  } = usePortfolio(ASSETS.equity);
  const instrumentCurrencies = portfolioInfo.data?.instrumentCurrencies ? portfolioInfo.data?.instrumentCurrencies : [];
  const portfolioId = portfolioInfo.data?.id;
  const { period, updatePeriod } = usePeriod();
  const [sectionId, setSectionId] = useState(page || 'pnl');
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'All', label: 'All' });

  useEffect(() => {
    setSectionId(page || 'pnl');
  }, [page]);

  useEffect(() => {
    if (portfolioId) {
      refetchTopPositions?.();
    }
  }, [portfolioId]);

  const handleCurrencyFilterChange = (e: string) => {
    setSelectedCurrency({ value: e, label: e });
  };

  const renderCurrencySelector = (): React.ReactNode => {
    // ToDO: The Income period needs to have its OWN Period redux variable as it may differ from the performance period used in Performance chart. it's a Potential BUG
    const currencyOptions = [{ value: 'All', label: 'All' }];
    instrumentCurrencies.map((el) => currencyOptions.push({ value: el, label: el }));

    return (
      <Fragment>
        <span className={s.currencyFilterLabel}>{sectionId === 'income' && 'Instrument'} Currency:</span>
        <Select
          options={currencyOptions}
          size="small"
          className={s.currencyFilter}
          selectedValue={selectedCurrency}
          onChange={handleCurrencyFilterChange}
        />
      </Fragment>
    );
  };

  const renderSections = (tabId: string) => {
    switch (tabId) {
      case 'pnl':
        Analytics.trackPageView('Equity P&L');
        return (
          <GlobalPnl
            getTopPositionColumns={getTopPositionsByPnLColumns}
            cellRenderer={equityCellRenderer}
            assetClass={ASSETS.equity}
            portfolioId={portfolioId!}
            period={period}
            handlePeriodChange={updatePeriod}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
          />
        );

      case 'structure':
        Analytics.trackPageView('Equity Structure');
        return (
          <GlobalStructure
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            portfolioId={portfolioId!}
            getTopPositionColumns={getTopPositionsColumns}
            cellRenderer={equityCellRenderer}
            assetClass={ASSETS.equity}
          />
        );
      case 'pnlBreakdown':
        Analytics.trackPageView('Equity P&L - Breakdown');
        return (
          <GlobalPnlBreakdown assetClass={ASSETS.equity} portfolioInfo={portfolioInfo} portfolioId={portfolioId!} />
        );
      case 'fundamentals':
        Analytics.trackPageView('Equity Fundamentals');
        return (
          <GlobalFundamentals assetClass={ASSETS.equity} portfolioInfo={portfolioInfo} portfolioId={portfolioId!} />
        );
      case 'income':
        Analytics.trackPageView('Equity Income');
        return (
          <GlobalIncome
            assetClass={ASSETS.equity}
            portfolioId={portfolioId!}
            renderCurrencySelector={renderCurrencySelector}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            selectedCurrency={selectedCurrency}
            profitPeriod={period}
          />
        );
      case 'options':
        Analytics.trackPageView('Equity Options');
        return (
          <EquityOptions
            portfolioCurrency={selectedCurrency}
            portfolioInfo={portfolioInfo}
            portfolioId={portfolioId!}
            renderCurrencySelector={renderCurrencySelector}
            portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            profitPeriod={period}
          />
        );
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo?.total.value);
  const totalPnlBase = portfolioCurrencyFormatter(dashboardInfo?.totalPnlBase.value);
  const lifetimeDividends = portfolioCurrencyFormatter(dashboardInfo?.past.value);

  const stats = [
    {
      value: totalPnlBase,
      label: 'Inception P&L',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
    { value: total, label: 'Total Equity', colored: true, info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoTotalEquity } },
    {
      value: lifetimeDividends,
      label: 'Lifetime dividends',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
    },
  ];

  return (
    <AssetReportWrapper
      title="Equity"
      allLoaded={isFetched}
      noData={hasData}
      message={"You haven't added any equities yet"}
    >
      <div>
        <PortfolioHeader title="Equity" />
        <PortfolioSubHeader stats={stats} title={page} />
        <div className={s.assetsDashboardGrid}>{renderSections(page || 'pnl')}</div>
      </div>
    </AssetReportWrapper>
  );
};

export default withRouter(Equity);
