import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import s from './Input.module.css';

interface IProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
}

const Input: FC<IProps & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  children,
  className,
  leftChildren,
  rightChildren,
  ...rest
}) => {
  return (
    <div className={cn(s.inputSurround)}>
      {leftChildren}
      <input className={cn(s.input, className)} {...rest} />
      {rightChildren}
    </div>
  );
};

export default Input;
