import axios, { AxiosPromise } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { formValueSelector } from 'redux-form';
import { IOption } from '../../../components/UIWidgets/Autocomplete';

import { PATHS } from '../../../router/paths';
import { CustodianActions, PortfolioActions } from '../../../services/actions';
import { PortfolioCurrencyActions } from '../../../services/actions/PortfolioCurrencyActions';
import { PortfolioIndexActions } from '../../../services/actions/PortfolioIndexActions';
import { FORMS_NAME } from '../../../services/constants/forms';
import { getPortfolioCurrencyOptions } from '../../../services/selectors/portfolioCurrency';
import { getPortfolioIndexOptions } from '../../../services/selectors/portfolioIndex';
import { IRootState } from '../../../services/store';
import { AsyncActionDispatch } from '../../../services/utils/ReduxHelper';
import { IPortfolioCreateFormData, IPortfolioServerData } from './models';
import PortfolioForm from './PortfolioCreateForm';
import { useAccount } from '../../../services/context/AuthContext';
import { Loader } from '../../../components';

interface IMapStateToProps {
  authInfo: IAuthInfo | null;
  currencyOptions: Array<IOption<ICurrency>>;
  performanceIndexOptions: Array<IOption<IIndex>>;
  custodiansFromServer: ICustodian[];
  portfolioType: string | null;
  workgroups: IWorkgroup[];
}

interface IDispatchToProps {
  createPortfolio: (formData: IPortfolioServerData) => AxiosPromise<IPortfolio>;
  fetchPortfolioCurrency: () => AxiosPromise<ICurrency[]>;
  showNotification: (notification: INotification) => void;
  fetchPortfolioIndex: () => AxiosPromise<IIndex[]>;
  fetchCustodians: () => AxiosPromise<ICustodian[]>;
}

type IProps = IMapStateToProps & IDispatchToProps & RouteComponentProps;

const PortfolioFormContainer = (props: IProps) => {
  const [ready, setReady] = React.useState(false);

  const { userCapabilities } = useAccount();
  const isD2c = userCapabilities?.metadata?.allowedViews?.includes('d2c');
  React.useEffect(() => {
    Promise.all([props.fetchCustodians(), props.fetchPortfolioCurrency(), props.fetchPortfolioIndex()]).then(() => {
      setReady(true);
    });
  }, []);

  const preferences = userCapabilities?.preferences;

  const defaultPerformanceIndex: IOption<IIndex> = {
    id: 0,
    name: 'MSCI World (DM and EM)',
    value: {
      name: 'MSCI World (DM and EM)',
      code: 'MSCIALL.INDX',
    },
  };

  const defaultCurrency: IOption<ICurrency> = {
    value: {
      digits: 2,
      hierarchy: 140,
      id: 840,
      name: 'USD',
      symbol: '$',
      isBase: true,
      hexColourCode: '0xF15D0A',
    },
    name: 'USD',
    id: 0,
  };

  const initialValues = {
    name: isD2c ? 'Main Portfolio' : '',
    customCustodian: '',
    custodian: null,
    onlyManualDividendsDefault: preferences?.onlyManualDividendsDefault,
    defaultRiskAssetView: preferences?.defaultRiskAssetView || 'RISK_ASSET_CLASS',
    currency: preferences?.defaultCurrency
      ? props.currencyOptions.find((currency) => currency.name === preferences?.defaultCurrency)
      : defaultCurrency,
    benchmarkIndex: !!preferences?.defaultBenchmarkIndex
      ? props.performanceIndexOptions.find((index) => index.id === preferences.defaultBenchmarkIndex)
      : defaultPerformanceIndex,
  };

  const handleSubmitForm = (formValues: IPortfolioCreateFormData) => {
    const { history } = props;

    const existingWorkgroupId = props.workgroups.find(
      (workgroup) => workgroup.name?.toUpperCase() === formValues.newWorkgroupName?.toUpperCase()
    )?.id;
    const serverData = {
      name: formValues.name!,
      portfolioType: 'CLIENT', // TODO: portfolioType has apparently been deprecated, but is still expected in the server payload
      currency: formValues.currency.name,
      clientName: formValues.clientName ?? 'self',
      riskAssetView: formValues.defaultRiskAssetView,
      onlyManualDividendsDefault: formValues.onlyManualDividendsDefault,
      benchmarkIndex: formValues.benchmarkIndex.value,
      custodians: (formValues.userPortfolioCustodians ?? []).map((c) => ({
        name: c.name,
        code: c.code,
        onlyManualDividends: c.onlyManualDividends,
        default: c.default,
        id: c.id,
      })),
      customCustodian: formValues.customCustodian,
      workgroupId: existingWorkgroupId,
      workgroup: !!existingWorkgroupId
        ? undefined
        : {
            name: formValues.newWorkgroupName!,
            distribution: 'CLIENT',
          },
    };
    if (serverData.customCustodian === '') {
      delete (serverData as any).customCustodian;
    }
    props
      .createPortfolio(serverData)
      .then((data) => data.data)
      .then((created: IPortfolio) => {
        // if (!authInfo) {
        //   return;
        // }
        axios.defaults.headers['illio-selected-workgroup'] = created.workgroup?.id;
        history.push(PATHS.portfolio.snapshot.edit.path.replace(':portfolioId', created.id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!ready) {
    return <Loader />;
  }

  return (
    <PortfolioForm
      onSubmit={handleSubmitForm}
      currencyOptions={props.currencyOptions}
      performanceIndexOptions={props.performanceIndexOptions}
      portfolioType={props.portfolioType}
      serverCustodians={props.custodiansFromServer}
      authInfo={props.authInfo}
      initialValues={initialValues}
      showNotification={props.showNotification}
    />
  );
};

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    portfolioType: selector(state, 'type'),
    authInfo: state.app.authInfo.data,
    currencyOptions: getPortfolioCurrencyOptions(state),
    custodiansFromServer: state.custodians.data,
    performanceIndexOptions: getPortfolioIndexOptions(state),
    workgroups: state.users.workgroups.data,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  createPortfolio: (formValues: IPortfolioServerData) => dispatch(PortfolioActions.createPortfolio(formValues)),
  fetchPortfolioCurrency: () => dispatch(PortfolioCurrencyActions.fetchPortfolioCurrency()),
  showNotification: (notification: INotification) => dispatch(PortfolioActions.showNotification(notification)),
  fetchPortfolioIndex: () => dispatch(PortfolioIndexActions.fetchPortfolioIndex()),
  fetchCustodians: () => dispatch(CustodianActions.fetchCustodiansList()),
});

const selector = formValueSelector(FORMS_NAME.portfolio);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioFormContainer));
