import tick from '../../../../../../../static/images/valid.svg';
import error from '../../../../../../../static/images/alert.svg';
import warning from '../../../../../../../static/images/warning.svg';
import React, { useEffect, useState } from 'react';
import SimpleDropDown from '../../../../../../../components/Reusable/SimpleDropDown/SimpleDropDown';
import { IOption } from '../../../../../../../components/UIWidgets/Autocomplete';
import { AiOutlineClose } from 'react-icons/all';
import s from '../PositionEntry.module.scss';
import { Button, Input } from 'rsuite';

import IllioTooltip from '../../../../../../../components/Tooltip/Tooltip';
import cn from 'classnames';
interface IPositionCellRendererArgs {
  updateRowData: (rowData: IPosition, newValue: IPosition, selectedIndex?: number) => void;
  removeRow: (rowData: IPosition) => void;
  setPositionModal: (index: number) => any;
  addToUpdateStack: any;
}

const getInstrumentString = (i: IUploadInstrument) => `${i.name} - ${i.code} (${i.currency})`;

const formatNumber = (n: number) => n.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const PositionEntryCellRenderer = ({
  addToUpdateStack,
  updateRowData,
  removeRow,
  setPositionModal,
}: IPositionCellRendererArgs) => (rowData: IPosition, dataKey: string) => {
  const selectedInstrument = rowData;
  let icon = tick;
  switch (dataKey) {
    case 'status':
      const warningText = () => {
        if (selectedInstrument.searchResult?.length && !selectedInstrument.selected) {
          icon = warning;
          return 'More than one instrument option were found, select instrument option to resolve.';
        }
        if (!selectedInstrument.name) {
          icon = error;
          return 'The instrument could not be found. Please remove this instrument.';
        }
        if (selectedInstrument?.instrumentStatus === 'DRAFT' || !selectedInstrument?.instrumentStatus) {
          icon = warning;
          return 'Instrument status is not ready, please wait for completion.';
        }
        if (selectedInstrument?.instrumentStatus === 'ERROR') {
          icon = error;
          return 'Error loading the instrument. Please remove it';
        }

        return '';
      };

      const tooltipText = warningText();
      if (tooltipText.length > 0) {
        return (
          <IllioTooltip text={tooltipText}>
            <img alt={'cross'} src={icon} style={{ height: 20, width: 20 }} />
          </IllioTooltip>
        );
      } else {
        return <img alt={'tick'} src={icon} style={{ height: 20, width: 20 }} />;
      }
      break;
    case 'ticker':
      return <span>{rowData.ticker}</span>;

    case 'instrument':
      if (!rowData.searchResult?.length) {
        if (!rowData.name) {
          return (
            <div className={s.cellRow}>
              <Button onClick={() => setPositionModal(rowData.index)} appearance={'primary'} size={'xs'}>
                Search instrument
              </Button>
            </div>
          );
        }
        if (rowData.isCurrency || false) {
          return (
            <div className={s.cellRow}>
              <span style={{ overflow: 'hidden' }}>{rowData.name}</span>
            </div>
          );
        }
        return (
          <div className={s.cellRow}>
            <span style={{ overflow: 'hidden' }}>{getInstrumentString(rowData)}</span>
          </div>
        );
      } else {
        return (
          <div className={s.cellRow}>
            <AssetResolver addToUpdateStack={addToUpdateStack} updateRowData={updateRowData} rowData={rowData} />
          </div>
        );
      }
    case 'price':
      const updatePrice = (newPrice: number | string) => {
        updateRowData(rowData, {
          ...rowData,
          historicPrice: newPrice as number,
        });
      };
      return (
        <div className={s.cellRow}>
          <div className={cn(s.inputWrapper, !rowData.historicPrice && s.error)}>
            {rowData.isCurrency ? (
              <div style={{ padding: 6 }}>N/A</div>
            ) : (
              <Input
                type={'number'}
                disabled={rowData.isCurrency}
                value={rowData.historicPrice + ''}
                onChange={updatePrice}
              />
            )}
          </div>
        </div>
      );

      break;
    case 'closePrice':
      return (
        <div className={s.cellRow}>
          <span className={s.systemPrice}> {formatNumber(rowData.price || 1)}</span>
        </div>
      );
      break;
    case 'quantity':
      const updateQuantity = (newQuantity: number | string) => {
        updateRowData(rowData, {
          ...rowData,
          quantity: newQuantity as number,
        });
      };
      return (
        <div className={cn(s.inputWrapper, !rowData.quantity && s.error)}>
          <Input type={'number'} value={rowData.quantity + ''} onChange={updateQuantity} />
        </div>
      );
      break;
    case 'currency':
      return <span>{selectedInstrument?.currency || ''}</span>;
      break;
    case 'total':
      const CloseBtn = ({ ...props }) => (
        <div
          style={{ backgroundColor: rowData.index % 2 === 0 ? '#24293C' : '#1C202E' }}
          className={s.closeIconContainer}
        >
          <AiOutlineClose {...props} className={s.closeIcon} onClick={() => removeRow(rowData)} />
        </div>
      );
      return (
        <div className={s.cellRow}>
          {selectedInstrument && (
            <span>
              {selectedInstrument?.currency + ' '}
              {(
                rowData.quantity *
                (rowData.historicPrice || rowData.price || 1) *
                (selectedInstrument?.pointValue || 1)
              ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          )}
          <CloseBtn />
        </div>
      );
    default:
      return <span>{selectedInstrument?.fxRate || ''}</span>;
      break;
  }
};

interface IAssetResolverProps {
  rowData: IPosition;
  updateRowData: (rowData: IPosition, newData: IPosition, selectedIndex?: number) => void;
  addToUpdateStack: any;
}

export const AssetResolver = ({ rowData, updateRowData, addToUpdateStack }: IAssetResolverProps) => {
  const options: IOption<number>[] = rowData.searchResult!.map((opt, i) => ({
    id: opt.name,
    name: getInstrumentString(opt),
    value: i,
  }));

  const [selectedItem, setSelectedItem] = useState(-1);
  const [lock, setLock] = useState(false);

  const currentItem: IOption<number> =
    selectedItem === -1 ? { id: -1, value: -1, name: `${options.length} found` } : options[selectedItem];

  const selectAsset = (index: number) => {
    const currInstrument = rowData.searchResult![index];
    const newData = {
      ...rowData,
      assetClass: currInstrument.assetClass,
      assetSubClass: currInstrument.assetSubClass,
      code: currInstrument.code,
      currency: currInstrument.currency,
      instrumentStatus: currInstrument.instrumentStatus,
      instrumentId: currInstrument.instrumentId,
      name: currInstrument.name,
      pointValue: currInstrument.pointValue,
      price: currInstrument.price as number,
      historicPrice: rowData.historicPrice || currInstrument.price,
      fxRate: currInstrument.fxRate,
      isCurrency: false,
      selected: true,
      sourceId: {
        ...currInstrument.sourceId,
      },
    };
    addToUpdateStack(newData);
  };

  useEffect(() => {
    if (lock) {
      setSelectedItem(0);
      selectAsset(0);
    }
  }, [lock]);

  useEffect(() => {
    if (options.length === 1 && selectedItem !== 0) {
      setTimeout(() => {
        setLock(true);
      }, 10);
    }
  }, [options]);

  return (
    <SimpleDropDown
      className={s.dropDown}
      backgroundColor={'var(--darker-blue)'}
      foreColor={selectedItem === -1 ? ' var(--text-warning-color)' : undefined}
      selectedItem={currentItem}
      onSelect={(item) => {
        setSelectedItem(item.selectedItem?.value!);
        selectAsset(item.selectedItem?.value!);
      }}
      items={options}
    />
  );
};
