import { ReduxHelper } from '../utils/ReduxHelper';
import { getApiUrl } from '../constants/endpoints';

import { TABLE_PAGE_SIZE } from '../constants/constants';

import { IPaginationContent } from '../../models/redux/ILoadingPaginationDataState';

import { IActionPayload } from '../../models/redux/IActionPayload';

export class MarketActions {
  static readonly MARKETS_REQUEST = 'MARKETS_REQUEST';
  static readonly MARKETS_SUCCESS = 'MARKETS_SUCCESS';
  static readonly MARKETS_ERROR = 'MARKETS_ERROR';
  static readonly MARKETS_RESET = 'MARKETS_RESET';
  static readonly MARKETS_CHANGE_SORT = 'MARKETS_CHANGE_SORT';
  static readonly MARKETS_CHANGE_PERIOD = 'MARKETS_CHANGE_PERIOD';

  static readonly MARKET_CREATE_REQUEST = 'MARKET_CREATE_REQUEST';
  static readonly MARKET_CREATE_SUCCESS = 'MARKET_CREATE_SUCCESS';
  static readonly MARKET_CREATE_ERROR = 'MARKET_CREATE_ERROR';

  static readonly MARKET_UPDATE_REQUEST = 'MARKET_UPDATE_REQUEST';
  static readonly MARKET_UPDATE_SUCCESS = 'MARKET_UPDATE_SUCCESS';
  static readonly MARKET_UPDATE_ERROR = 'MARKET_UPDATE_ERROR';

  static readonly MARKET_DELETE_REQUEST = 'MARKET_DELETE_REQUEST';
  static readonly MARKET_DELETE_SUCCESS = 'MARKET_DELETE_SUCCESS';
  static readonly MARKET_DELETE_ERROR = 'MARKET_DELETE_ERROR';
  static readonly MARKET_PRICES_REQUEST = 'MARKET_PRICES_REQUEST';

  static fetchAllMarkets(instrumentId: string, order: IOrder, period: IPeriod, page: number = 0) {
    return ReduxHelper.createHttpRequestActionBundle<IPaginationContent<IMarket>>(
      {
        url: getApiUrl('market.list'),
        data: {
          ids: [instrumentId],
          page,
          size: TABLE_PAGE_SIZE,
          order,
          from: period.from,
          to: period.to,
        },
      },
      MarketActions.MARKETS_REQUEST,
      MarketActions.MARKETS_SUCCESS,
      MarketActions.MARKETS_ERROR
    );
  }

  static changeMarketSort(order: IOrder): IActionPayload<IOrder> {
    return { type: MarketActions.MARKETS_CHANGE_SORT, payload: order };
  }

  static changeMarketPeriod(period: IPeriod): IActionPayload<IPeriod> {
    return { type: MarketActions.MARKETS_CHANGE_PERIOD, payload: period };
  }

  static createMarket(market: IMarket) {
    return ReduxHelper.createHttpRequestActionBundle<IMarket>(
      {
        url: getApiUrl('market.create'),
        data: {
          ...market,
        },
      },
      MarketActions.MARKET_CREATE_REQUEST,
      MarketActions.MARKET_CREATE_SUCCESS,
      MarketActions.MARKET_CREATE_ERROR
    );
  }

  static updateMarket(market: IMarket) {
    return ReduxHelper.createHttpRequestActionBundle<IMarket>(
      {
        url: getApiUrl('market.update'),
        data: {
          ...market,
        },
      },
      MarketActions.MARKET_UPDATE_REQUEST,
      MarketActions.MARKET_UPDATE_SUCCESS,
      MarketActions.MARKET_UPDATE_ERROR
    );
  }

  static deleteMarket(market: IMarket) {
    return ReduxHelper.createHttpRequestActionBundle<void>(
      {
        url: getApiUrl('market.delete'),
        data: {
          ids: [market.id],
        },
      },
      MarketActions.MARKET_DELETE_REQUEST,
      MarketActions.MARKET_DELETE_SUCCESS,
      MarketActions.MARKET_DELETE_ERROR
    );
  }

  static resetMarketList(): IActionPayload {
    return { type: MarketActions.MARKETS_RESET };
  }
}

export default MarketActions;
