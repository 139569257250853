import echarts from 'echarts/lib/echarts';
import moment from 'moment';
import { FormatHelper } from '../../../../../../services/utils/FormatHelper';

import merge from 'lodash.merge';
import {
  chartColor,
  chartColorLoss,
  DEFAULT_GRID_OPTIONS,
  DEFAULT_XAXIS_OPTIONS,
  DEFAULT_XAXIS_TIME_OPTIONS,
  DEFAULT_YAXIS_OPTIONS,
} from '../../../../../../services/constants/charts';
import { BoxShadowProperty, Color } from 'csstype';
import dateFormat from 'dateformat';
import { ReactEchartsPropsTypes } from 'echarts-for-react';
// import Series = echarts.EChartOption.Series;
// import {EChartOption} from "echarts";

export function generateChartOptions(chartData: any[], rangeType?: string): echarts.EChartOption {
  const axisLabels: any[] = ['indices'];
  const indexSeries: any[] = ['Performance Index'];
  const watermarkSeries: any[] = ['Watermark'];
  const drawdownSeries: any[] = ['Drawdown'];

  const visibleLabels: Record<string, boolean> = {};

  chartData.map((el: any, index) => {
    // console.log(index, index % 12, dateFormat(el.date, "yyyy-mm-dd"));
    if (index % 4 === 0) {
      visibleLabels[dateFormat(el.date, 'yyyy-mm-dd')] = true;
    }
    axisLabels.push(el.date);
    indexSeries.push(el.performanceIndex);
    watermarkSeries.push(el.watermark);
    drawdownSeries.push(el.drawdown);
  });

  const labelDensity = axisLabels.length < 20 ? 1 : axisLabels.length < 60 ? 2 : 10;

  const tooltipDateFormat = rangeType === 'DAILY' ? 'DD-MMM-YYYY' : 'MMM-YYYY';

  // console.log('*** Chart Data ***');
  // console.log(axisLabels);
  // console.log(indexSeries);
  // console.log(watermarkSeries);
  // console.log(drawdownSeries);

  const textColor: Color = '#a9b2d1';
  const textSize = 11;

  const tooltipColor: Color = '#555555';
  const tooltipShadow: BoxShadowProperty = '0 2px 4px 0 rgba(0,0,0,0.1)';
  const tooltipBorderRadius = 10;

  // ToDo: To complete this formatter as a function return from chartHelper and make it more generic
  // const tooltipOpt = ChartHelper.formatDataMappedChartTooltip();

  const generalChartOptions: ReactEchartsPropsTypes['option'] = {
    // const generalChartOptions: EChartOption<Series> = {
    dataset: {
      source: [axisLabels, watermarkSeries, indexSeries, drawdownSeries],
    },
    legend: {
      top: 5,
      left: 0,
      selectedMode: false,
      textStyle: {
        color: textColor,
      },
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      padding: [3, 7],
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: chartColor,
          width: 1,
          type: 'dashed',
        },
      },
      textStyle: {
        color: tooltipColor,
        fontSize: textSize,
      },
      backgroundColor: 'var(--text-white)',
      borderColor: 'var(--warm-grey)',
      borderWidth: 1,
      extraCssText: `border-radius:${tooltipBorderRadius}px;` + `box-shadow: ${tooltipShadow};}` + `textAlign: left;}`,
      formatter: (params: any) => {
        if (params.length > 1) {
          return `${moment(params[0].name).format(
            tooltipDateFormat
          )}<br> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
            params[0].color
          };"></span>${params[0].seriesName}: ${params[0].value[1]}
           <br> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
             params[1].color
           };"></span>${params[1].seriesName}: ${params[0].value[2]}`;
        } else {
          return `${moment(params[0].name).format(
            tooltipDateFormat
          )}<br> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
            params[0].color
          };"></span>${params[0].seriesName}: ${params[0].value[3]}`;
        }
      },
    },
    grid: [
      {
        ...DEFAULT_GRID_OPTIONS,
        height: 370,
        bottom: 515,
        top: 35,
      },
      {
        ...DEFAULT_GRID_OPTIONS,
        height: 150,
        top: 415,
      },
    ],
    xAxis: [
      {
        ...DEFAULT_XAXIS_TIME_OPTIONS,
        type: 'category',
        gridIndex: 0,
        // show: false,
        labelFilter: (a: any) => console.log("AAA'", a),
        axisTick: {
          alignWithLabel: true,
        },

        axisLabel: {
          ...DEFAULT_XAXIS_OPTIONS.axisLabel,
          rotate: 90,
          showMaxLabel: true,
          showMinLabel: true,
          formatter(label: string, index: number): string {
            if (index % labelDensity === 0) {
              return moment(label).format("DD MMM'YY");
            }
            return '';
          },
        },
        offset: 5,
      },
      {
        ...DEFAULT_XAXIS_OPTIONS,
        type: 'category',
        gridIndex: 1,
        axisLabel: {
          show: false,
          color: textColor,
          fontSize: textSize,
        },
        offset: 5,
      },
    ],
    yAxis: [
      {
        ...DEFAULT_YAXIS_OPTIONS,
        gridIndex: 0,
        type: 'value',
        min: (v: any) => v.min,
        axisLabel: {
          color: textColor,
          fontSize: textSize,
          margin: 10,
          // formatter(value: number): string {
          //   return FormatHelper.formatPercentage(value, 2);
          // },
        },
      },
      {
        ...DEFAULT_YAXIS_OPTIONS,
        gridIndex: 1,
        axisLabel: {
          color: textColor,
          fontSize: textSize,
          margin: 10,
          formatter(value: number): string {
            return FormatHelper.formatPercentage(value);
          },
        },
      },
    ],
    series: [
      {
        type: 'line',
        seriesLayoutBy: 'row',
        name: 'Watermark',
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: true,
        trigger: 'axis',
        itemStyle: {
          color: '#fb9900',
        },
      },
      {
        type: 'line',
        seriesLayoutBy: 'row',
        name: 'Performance Index',
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: true,
        trigger: 'axis',
        itemStyle: {
          color: chartColor,
        },
      },
      {
        type: 'line',
        seriesLayoutBy: 'row',
        name: 'Drawdown',
        areaStyle: {},
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbol: 'circle',
        symbolSize: 5,
        trigger: 'axis',
        showSymbol: true,
        itemStyle: {
          color: chartColorLoss,
        },
      },
    ],
  };
  return generalChartOptions;
}
