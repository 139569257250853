import React, { useEffect, useRef } from "react";
import { select, selectAll } from "d3-selection";
import { axisBottom, axisRight } from "d3-axis";
import dateFormat from "dateformat";
import { numberWithCommas } from "../utils";

type AxisType = {
  scale: any;
  orient: "bottom" | "right";
  ticks?: number;
  formatter?: (date: Date) => string;
  currency?: string;
  fontFamily: string;
  labelColor: string;
  width: number;
  gridOpacity: number;
  gridColor: string;
  transform: string;
};

const Axis = ({
  scale,
  orient,
  ticks,
  currency,
  fontFamily,
  labelColor,
  width,
  gridOpacity,
  gridColor,
  transform,
}: AxisType) => {
  const node = useRef() as React.MutableRefObject<SVGSVGElement>;

  useEffect(() => {
    renderAxis();
  }, [width, scale]);

  const renderAxis = () => {
    let axis;
    if (orient === "bottom") {
      axis = axisBottom(scale).tickSize(0)
        .tickFormat(val => dateFormat(new Date(String(val)), "mmm yy"))

      select(node.current)
        .call(axis)
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dy", "1.5em")
        .attr("transform", "rotate(-35)")
        .attr("font-family", () => fontFamily)
        .attr("fill", () => labelColor);

      select(node.current).call(axis).select(".domain").remove();
    }

    if (orient === "right") {
      axis = axisRight(scale)
        .ticks(ticks)
        .tickSizeInner(-width)
        .tickFormat((value: any) => {
          return `${currency} ${numberWithCommas(value)}`;
        });

      select(node.current)
        .call(axis)
        .selectAll("text")
        .attr("font-family", () => fontFamily)
        .attr("dx", "1em")
        .attr("fill", () => labelColor);

      selectAll(".tick line")
        .attr("opacity", gridOpacity)
        .attr("color", () => gridColor);

      select(node.current).call(axis).select(".domain").remove();
    }
  };

  return <g ref={node} transform={transform} className={`${orient} axis`} />;
};

export default Axis;
