import React from 'react';
import { Loader } from '../../../../../../../../components';

import { ASSETS } from '../../../../../../../../services/constants/assetClasses';
import { DASHBOARD_EQUITY_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import { PortfolioSubHeader } from '../../../../../../components/PortfolioHeader/PortfolioSubHeader';

import Summary from './scenes/Summary/Summary';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { AssetReportWrapper } from '../../components';
import { PortfolioHeader } from '../../../../../../components/PortfolioHeader';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';

const Cash = () => {
  const {
    dashboardInfo,
    portfolioCurrencyFormatter,
    portfolioInfo,
    portfolioCurrency,
    portfolioSymbol,
    isLoading,
    isFetched,
    hasData,
  } = usePortfolio(ASSETS.cash);
  const portfolioId = portfolioInfo.data?.id;
  const { period, updatePeriod } = usePeriod();

  // const handlePeriodChange = (newPeriod: IPeriod) => {
  //   updatePeriod(newPeriod);
  // };

  if (isLoading || !dashboardInfo) {
    return <Loader />;
  }

  const total = portfolioCurrencyFormatter(dashboardInfo.total.value);

  const stats = [
    {
      value: total,
      label: 'Total Cash Balances',
      colored: true,
      info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoTotalEquity },
    },
  ];

  return (
    <AssetReportWrapper title="Cash Balances" allLoaded={isFetched} noData={true}>
      <PortfolioHeader title="Cash Balances" />
      <PortfolioSubHeader stats={stats} title={'Cash Balances'} />
      <Summary
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        portfolioCurrency={portfolioCurrency}
        portfolioSymbol={portfolioSymbol}
        portfolioId={portfolioId!}
        assetClass={ASSETS.cash}
        profitPeriod={period}
      />
    </AssetReportWrapper>
  );
};

export default Cash;
