import useApi from '../useApi';
import { getApiUrl } from '../../constants/endpoints';
import React from 'react';
import { QueryObserverResult } from 'react-query';

type Options = {
  includeAssigned?: boolean;
  includeInactive?: boolean;
};

export const useUserList = (role: 'MANAGER' | 'CUSTOMER', options: Options = {}) => {
  const [value, setValue] = React.useState<QueryObserverResult<IAccountUser[]>>({ data: [] } as any);
  const params: Record<string, string> = { role };

  const { includeAssigned, includeInactive } = options;

  if (typeof includeAssigned !== 'undefined') {
    params.includeAssigned = includeAssigned ? 'true' : 'false';
  }

  const paramString = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

  const endpoint = `${getApiUrl('account.users.list')}?${paramString}`;

  const d = useApi<IAccountUser[]>(endpoint);

  React.useEffect(() => {
    setValue(d);
    let nextList = d.data?.filter((user) => user.role === role) ?? ([] as any);

    if (!includeInactive) {
      nextList = nextList.filter((user: IAccountUser) => user.status === 'ACTIVE');
    }

    setValue({
      ...d,
      data: nextList,
    });
  }, [d.dataUpdatedAt]);

  return value;
};
