import React from "react";
import { Data } from "../IBarChart";

type BarType = {
  data: Data;
  xScale: (ScaleBand: string) => any;
  yScale: (y: number) => number;
  barWidth: number;
  bandGap?: number;
};
const Bar = ({ data, xScale, yScale, barWidth, bandGap = 0 }: BarType) => {
  
  return <React.Fragment>
    {
      data.map(d => (
        d.values.map(item => (
          <rect
            key={`${item.label}-${d.label}-${item.value}-${item.start}`}
            x={xScale(d.label)}
            y={item.value < 0 ? yScale(item.start) : yScale(item.end)}
            fill={item.color}
            width={barWidth}
            height={item.value < 0
              ? yScale(item.end) - yScale(item.start)
              : yScale(item.start) - yScale(item.end)}
            transform={`translate(${bandGap},0)`}
          />
        ))
      ))
    }
  </React.Fragment>;

};

export default Bar;
