import { ChangePasswordFormContainer, PortfolioFormContainer, PortfolioSettingsFormContainer } from '../modals';
import { UserRole } from '../services/constants/constants';
import { IModalRouteConfig } from '../services/utils/RouterHelper';
import { PATHS } from './paths';
import PortfolioTransferContainer from '../modals/PortfolioTranser/PortfolioTransferContainer';
import PortfolioShare from '../modals/PortfolioTranser/PortfolioShare';
import PortfolioAssign from '../modals/PortfolioTranser/PortfolioAssign';
import AddCustodianFormContainer from '../modals/AddCustodianForm/AddCustodianFormContainer';

export const MODALS_ROUTES: IModalRouteConfig[] = [
  {
    path: PATHS.portfoliosManager.portfolioCreator.path,
    component: PortfolioFormContainer,
  },
  {
    path: PATHS.portfoliosManager.custodiansManager.path,
    component: AddCustodianFormContainer,
  },
  // {
  //   path: PATHS.portfoliosManager.portfolioTransfer.path,
  //   component: PortfolioTransferContainer,
  // },
  {
    path: PATHS.portfoliosManager.portfolioShare.path,
    component: PortfolioShare,
  },
  {
    path: PATHS.portfoliosManager.portfolioAssign.path,
    component: PortfolioAssign,
  },
  {
    path: PATHS.account.password.path,
    component: ChangePasswordFormContainer,
  },
  // {
  //   path: PATHS.portfoliosManager.editAccess.path,
  //   component: PortfolioAccessContainer,
  //   allowedRoles: [UserRole.ADMIN, UserRole.MANAGER],
  // },
  {
    path: PATHS.portfoliosManager.editSettings.path,
    component: PortfolioSettingsFormContainer,
    allowedRoles: [UserRole.ADMIN, UserRole.MANAGER],
  },
];
