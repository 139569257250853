import { IOption } from '../components/UIWidgets/Autocomplete';

export const TradeType = {
  BUY: 'Buy',
  SELL: 'Sell',
  ADD: 'Add',
  WITHDRAW: 'Withdraw',
  IN_KIND: 'Transfer',
};

export interface IPortfolioTrade {
  tradeType: IAssetClass;
  amount?: number;
  assetSubClass?: IAssetSubClass;
  currency?: string | ICurrency | null | undefined;
  commission: number;
  name?: string;
  displayId?: string;
  quantity: number;
  displayQuantity?: string;
  price: number;
  fxRate: number;
  displayBaseFx?: number;
  tradeTime: string;
  operation: string;
  operationName?: string;
  baseSettlementCurrency?: string;
  settlementOption?: 'LOCAL' | 'BASE' | 'AUTO';
  key?: string;
  relatesToKey?: string;
  custodian: ICustodian;
  notes?: string;
  netAmountBase?: number;
  netAmountLocal?: number;
  tradeCosts: number;
  totalTransactionAmount?: number;
  stockName?: string;
  instrument: any;
  checkedOut?: boolean;
  settlementDate?: string;
  accruedInterestLocal?: number | null;
  accruedInterestIsCalculated?: boolean;
  currencyDetails?: IOption<ICurrency>;
  type?: string;
  strikePrice?: number;
  expiryDate?: string;
  multiplier?: number;
  historic?: boolean;
  otherCurrency?: string;
  optionTicker?: Pick<IOptions, 'sourceId'> | undefined;
  otherSourceId?: {
    // Used in FX Spot trades
    sourceId: string;
    sourceData: string;
  };
  otherQuantity?: number; // Used in FX Spot trades
  tradeSource?: string;
  historicPrice?: string | number;
  historicPnl?: string | number;
}

export interface IPortfolioErrorsTrade {
  fieldName: string;
  message: string;
  status: string;
  type: string;
  userInput: any;
}

export interface IPortfolioValidatedTrade {
  trade: IPortfolioTrade;
  errors: IPortfolioErrorsTrade[];
  delta: null;
  totalTradeCost: null;
}

export interface ICashflowAPIRequestPayload {
  fromDate: string /** YYYY-MM-DD */;
  toDate: string /** YYYY-MM-DD */;
  positionId: string /** uuid-v4 */;
}

export interface ICashflowAPIResponse {
  data: ICashflowItem[];
  status: number;
  error?: { message: string };
}

export interface ICashflowItem {
  bookingDate: string /** YYYY-MM-DD */;
  eventName: string /** eg: Instrument Expiry */;
  settledOnDate: string /** YYYY-MM-DD */;
  amountLocal: number | string | BigInt;
  balance: number | string | BigInt;
  reference: string;
  tradeId?: string;
}
