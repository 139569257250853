import moment from 'moment';
import React, { FormEvent, FunctionComponent, useEffect } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderDatePicker, renderInput } from '../../../../../../../../components/ReduxForm';
import { Button } from '../../../../../../../../components/UIWidgets/Button';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';

import { DATE_FORMAT } from '../../../../../../../../services/constants/constants';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { floatNumber, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { required } from '../../../../../../../../services/utils/FormValidations';
import { IMarketForm } from './IMarketForm';
import s from './MarketForm.module.css';

interface IOwnProps {
  market: IMarket | null;
}

type IProps = IOwnProps & InjectedFormProps<IMarketForm, IOwnProps>;

const MarketForm: FunctionComponent<IProps> = (props) => {
  const { market, invalid, handleSubmit, reset, initialize } = props;

  useEffect(() => {
    if (!market) {
      initialize({
        date: undefined,
        price: undefined,
      });
      return;
    }

    initialize({
      date: moment(market.closeTimestamp).format(DATE_FORMAT),
      price: market.price,
    });
  }, [market]);

  const handlerSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(event);
    reset();
  };

  return (
    <form onSubmit={handlerSubmit} className={s.form}>
      <FormFieldWrapper label="Transaction date" required={true}>
        <Field name="date" className="input--small" type="text" component={renderDatePicker} validate={[required]} />
      </FormFieldWrapper>
      <FormFieldWrapper label="Price" required={true}>
        <Field
          name="price"
          placeholder="100.00"
          component={renderInput}
          validate={[required]}
          className="input--small"
          normalize={(value: number) => positiveNumber(floatNumber(value))}
        />
      </FormFieldWrapper>
      <Button type="submit" size="small" disabled={invalid}>
        {market ? 'Update' : 'Add'}
      </Button>
    </form>
  );
};

export default reduxForm<IMarketForm, IOwnProps>({
  form: FORMS_NAME.instrumentPrice,
})(MarketForm);
