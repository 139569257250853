import React, { Fragment, useCallback, useState } from 'react';
import s from './ReturnVsRiskScatterChart.module.css';
import { Card } from '@iliotech/storybook';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../components/ContextHelp';
import { getScatterChartData } from './utils/getScatterData';
import SvgScatterChart from './SvgScatterChart';
import ScatterTable from './components/ScatterTable';
import useCurrentWidth from '../../../../../../services/hooks/useCurrentWidth';

interface IOwnProps {
  serverData: ScatterChartData;
  portfolioBaseCcySymbol: string;
  chartTitle: string;
  insufficientData: boolean;
  insufficientDataMessage: string;
  helpText?: string;
  chartVariant?: string;
}

type IProps = IOwnProps;

const ReturnVsRiskScatterChart = (props: IProps) => {
  const {
    chartTitle,
    serverData,

    insufficientData,
    insufficientDataMessage,
    portfolioBaseCcySymbol,
    helpText,
  } = props;
  const ASSETS_INDEXES: Record<string, number> = serverData?.positions
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((o, key, index) => ({ ...o, [key.name]: index + 1 }), {});
  const [portfolioSelected, setPortfolioSelected] = useState(true);
  const [benchmarkSelected, setBenchmarkSelected] = useState(true);
  const [selectedPositions, setSelectedPosition] = useState(serverData?.positions);
  const [selectedAssets, setSelectedAssets] = useState(serverData?.assets);
  const currentWidth = useCurrentWidth();
  const scatterFlatData: () => IScatterTableItem[] = useCallback(() => {
    const { portfolio, benchmark, positions, assets } = serverData;
    const flatData: IScatterTableItem[] = [
      {
        type: 'PORTFOLIO',
        name: 'Portfolio',
        ...portfolio,
      },
      {
        type: 'BENCHMARK',
        name: benchmark.indexName.split('.')[0],
        ...benchmark,
      },
    ];

    assets.forEach((asset) => {
      flatData.push({
        ...asset,
        type: 'ASSETCLASS',
        name: asset.assetClass,
      });
    });

    positions
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 20)
      .forEach((pos) => {
        flatData.push({
          ...pos,
          type: 'POSITION',
        });
      });

    // console.log(flatData);
    return flatData;
  }, [serverData]);

  const data = useCallback(() => {
    return getScatterChartData(
      {
        ...serverData,
        positions: selectedPositions,
        assets: selectedAssets,
      },
      benchmarkSelected,
      portfolioSelected,
      ASSETS_INDEXES
    );
  }, [selectedPositions, selectedAssets, portfolioSelected, benchmarkSelected, serverData]);

  const isAssetSelected = useCallback(
    (key: string) => {
      return selectedAssets.some((sel) => sel.assetClass === key);
    },
    [selectedAssets]
  );

  const onAssetClick = (key: string) => {
    if (isAssetSelected(key)) {
      setSelectedPosition(selectedPositions.filter((pos) => pos.assetClass !== key));
      setSelectedAssets(selectedAssets.filter((sel) => sel.assetClass !== key));
      return;
    }
    setSelectedPosition([
      ...selectedPositions.filter((pos) => pos.assetClass !== key),
      ...serverData.positions.filter((pos) => pos.assetClass === key),
    ]);
    setSelectedAssets([...selectedAssets, serverData.assets.find((pos) => pos.assetClass === key)!]);
  };

  const isPositionSelected = useCallback(
    (key: string) => {
      return selectedPositions.some((sel) => sel.name === key);
    },
    [selectedPositions]
  );

  const onPositionClick = (key: string) => {
    if (isPositionSelected(key)) {
      setSelectedPosition(selectedPositions.filter((sel) => sel.name !== key));
      return;
    }
    setSelectedPosition([...selectedPositions, serverData.positions.find((pos) => pos.name === key)!]);
  };

  const toggleBenchmark = () => setBenchmarkSelected((selected) => !selected);
  const togglePortfolio = () => setPortfolioSelected((selected) => !selected);

  return (
    <Fragment>
      {insufficientData ? (
        <h4 style={{ marginTop: 20 }}> {insufficientDataMessage} </h4>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
          <Card>
            <h3 className={s.chartTitle}>
              {chartTitle}
              <ContextHelpIcon title={chartTitle} elementId={chartTitle} />
            </h3>
            <div style={{ height: 20 }} />
            <div style={{ marginLeft: 35 }}>
              <SvgScatterChart
                width={currentWidth * 0.45}
                portfolioBaseCcySymbol={portfolioBaseCcySymbol}
                portfolioSelected={portfolioSelected}
                benchmarkSelected={benchmarkSelected}
                data={data()}
              />
            </div>
          </Card>
          <ScatterTable
            width={currentWidth * 0.4}
            portfolioSelected={portfolioSelected}
            benchmarkSelected={benchmarkSelected}
            toggleBenchmark={toggleBenchmark}
            togglePortfolio={togglePortfolio}
            isAssetSelected={isAssetSelected}
            selectedPositions={selectedPositions}
            isPositionSelected={isPositionSelected}
            onPositionClick={onPositionClick}
            onAssetClick={onAssetClick}
            indexOfAsset={ASSETS_INDEXES}
            scatterFlatData={scatterFlatData()}
          />
        </div>
      )}
      <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={helpText} />
    </Fragment>
  );
};

export default ReturnVsRiskScatterChart;
