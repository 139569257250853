import React, { PureComponent, Fragment } from 'react';

import { Button, Period, Tab, Tabs, TreeMap } from '../../../../components';
import { RecommendationList, PerformanceChart, ScenarioAnalysisContainer } from './components';
import { PortfolioHeader } from '../../components/PortfolioHeader';
import { Widget } from '../../components/WidgetsBlock/Widget';

import { PERIOD_TYPE } from '../../../../services/constants/constants';

import { ITreeMapData } from '../../../../components/TreeMap/TreeMap';
import { IRecommendation } from '../../../../models/IRecommendation';

import { IOptionType } from '../../../../components/UIWidgets/Select/Select';

import s from './Recommendations.module.css';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';

const periodOptions: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.YEAR, label: '1Y' },
  { value: PERIOD_TYPE.THREE_YEARS, label: '3Y' },
  { value: PERIOD_TYPE.INCEPTION, label: 'Since inception' },
];

interface IProps {
  assetClasses: IAssetClass[];
  portfolioRecommendation: IRecommendation[];
  performancePeriod: IPeriod;
  classesAllocationData: ITreeMapData[];
  geographyAllocationData: ITreeMapData[];
  recommendedPerformance: IPerformanceData;
  portfolioPerformance: IPerformanceData;
  onAssetClassChange: (assetClass: IAssetClass) => void;
  onToggleRecommendation: (recommendationIdx: number) => void;
  onPerformancePeriodChanged: (period: IPeriod) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IState {
  selectedAssetId: string | null;
}

class Recommendations extends PureComponent<IProps, IState> {
  readonly state: IState = {
    selectedAssetId: null,
  };

  handlerToggleRecommendation = (recommendationIdx: number) => {
    this.props.onToggleRecommendation(recommendationIdx);
  };

  handlerSelectAssetClass = (item: number) => {
    this.props.onAssetClassChange(this.props.assetClasses[item]);
  };

  renderAssetTabs = () => {
    const { assetClasses } = this.props;
    return (
      <Tabs onTabSelected={this.handlerSelectAssetClass}>
        {assetClasses.map((item, idx) => (
          <Tab key={idx} label={item.name} />
        ))}
      </Tabs>
    );
  };

  renderPerformancePeriod = () => {
    const { performancePeriod, onPerformancePeriodChanged } = this.props;
    return (
      <Period
        options={periodOptions}
        viewList="tabs"
        selectedPeriod={performancePeriod}
        onPeriodChange={onPerformancePeriodChanged}
      />
    );
  };

  renderChartsTabs = () => {
    const {
      classesAllocationData,
      geographyAllocationData,
      recommendedPerformance,
      portfolioPerformance,
      portfolioCurrencyFormatter,
    } = this.props;
    return (
      <Tabs className={s.chartTabs}>
        <Tab label="Performance">
          <Widget title="" renderHeaderTools={this.renderPerformancePeriod}>
            <PerformanceChart
              portfolioPerformance={portfolioPerformance}
              recommendedPerformance={recommendedPerformance}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
            />
          </Widget>
        </Tab>
        <Tab label="Classes allocation">
          <TreeMap treeData={classesAllocationData} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
        </Tab>
        <Tab label="Geography allocation">
          <TreeMap treeData={geographyAllocationData} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
        </Tab>
        <Tab label="Scenario analysis" wrapContent={true}>
          <ScenarioAnalysisContainer />
        </Tab>
      </Tabs>
    );
  };

  render() {
    const { portfolioRecommendation, portfolioCurrencyFormatter } = this.props;
    return (
      <Fragment>
        <PortfolioHeader title="Recommended changes" />
        <div className={s.assetTabs}>{this.renderAssetTabs()}</div>
        <RecommendationList
          recommendations={portfolioRecommendation}
          onToggle={this.handlerToggleRecommendation}
          portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        />
        <h3>Result portfolio</h3>
        <div className={s.resultsContent}>{this.renderChartsTabs()}</div>
        <footer>
          <Button className={s.footerBtn} variant="outline" active={false}>
            Back
          </Button>
          <Button className={s.footerBtn} variant="outline" active={false}>
            Download high level structure
          </Button>
          <Button className={s.footerBtn}>Request details</Button>
        </footer>
      </Fragment>
    );
  }
}

export default Recommendations;
