import { IRollingVolatilityData } from '../../interfaces';
import { FormatHelper, VALUE_TYPE } from '../../../../../../services/utils/FormatHelper';
import { ChartHelper } from '../../../../../../services/utils/ChartHelper';
import { LineData } from '../../../../../../models/LineData';
import memoizeOne from 'memoize-one';
import moment, { Moment } from 'moment';

import echarts from 'echarts/lib/echarts';
import { DATE_FORMAT, PERIOD_TYPE } from '../../../../../../services/constants/constants';
import merge from 'lodash.merge';

type FormatterParam = echarts.EChartOption.Tooltip.Format;

const LABEL_WIDTH: number = 30;

enum AxisLabelType {
  DAY,
  MONTH,
  QUARTER,
}

// fixme: put all methods from file in class to avoid global definitions
const labelsMap: Map<number, { value: string; index: number }> = new Map<number, { value: string; index: number }>();

const filterAxisLabels = memoizeOne(
  (data: LineData, labelType: AxisLabelType): LineData => {
    switch (labelType) {
      case AxisLabelType.MONTH:
        return data.filter((item) => moment(item[0]).date() === 1);
      case AxisLabelType.QUARTER:
        return data.filter((item) => {
          const date: Moment = moment(item[0]);
          return date.month() % 3 === 0 && date.date() === 1;
        });
      default:
        return data;
    }
  }
);

const prepareXAisLabels = memoizeOne(
  (data: LineData, period: IPeriod): Map<number, { value: string; index: number }> => {
    labelsMap.clear();
    const labelType: AxisLabelType = getLabelAxisType(data, period);
    filterAxisLabels(data, labelType).forEach((item) =>
      labelsMap.set(moment(item[0]).startOf('day').valueOf(), {
        value: getAxisLabel(item[0], labelType),
        index: labelsMap.size + 1,
      })
    );
    return labelsMap;
  }
);

export function generateChartOptions(chartData: IRollingVolatilityData[]): echarts.EChartOption {
  const chartWidth: number = 0;
  const benchmarkVolatility: LineData = [];
  const benchmarkAnnualisedReturn: LineData = [];
  const portfolioVolatility: LineData = [];
  const portfolioAnnualisedReturn: LineData = [];

  chartData.map((el) => {
    benchmarkVolatility.push([el.date.toString(), el.benchmarkVolatility]);
    benchmarkAnnualisedReturn.push([el.date.toString(), el.benchmarkAnnualisedReturn]);
    portfolioVolatility.push([el.date.toString(), el.portfolioVolatility]);
    portfolioAnnualisedReturn.push([el.date.toString(), el.portfolioAnnualisedReturn]);
  });

  // @ts-ignore
  const period: IPeriod = { from: chartData[0].date, to: chartData[chartData.length - 1].date, type: 1 };

  const chartOpt = ChartHelper.getTimelineChartOptionsNoTooltip({
    tooltipValueType: VALUE_TYPE.MONEY,
    zoom: true,
  });

  const mergedOpt: any = {
    grid: {
      y2: 40,
      x2: 70,
      containLabel: false,
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#6677cc',
          width: 1,
          type: 'dashed',
        },
        type: 'line',
      },
      backgroundColor: '#fff',
      borderColor: '#9a9a9a',
      borderWidth: 1,
      extraCssText: 'border-radius:10px;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);}',
      padding: [3, 7],
      show: true,
      textStyle: {
        color: '#555555',
        fontSize: 11,
      },
      trigger: 'axis',
      formatter: (params: any[]) => {
        let date = params[0].axisValue;
        if (date && moment(new Date(date)).isValid()) {
          date = moment(date).format(DATE_FORMAT);
        }
        let tooltipText = `${date}<br>`;
        params.map((item) => {
          // const valText = item.value.substr(0, 2) + ':' + item.value.substr(2, 2) + item.value.substr(4, 2);
          tooltipText += item.marker + item.seriesName + ': ' + item.value[1] + '%' + '<br/>';
        });

        return tooltipText;
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return FormatHelper.formatNumber(value, 2);
        },
      },
    },
    xAxis: {
      interval: 3600 * 1000 * 24, // 1 day
      axisTick: {
        show: false,
      },
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
      },
    },
    series: [
      {
        ...ChartHelper.getLineSeriesOptions('#138eb0'),
        name: 'Benchmark Volatility',
        data: benchmarkVolatility,
      },
      {
        ...ChartHelper.getLineSeriesOptions('#c2b261'),
        name: 'Benchmark 1 year Return',
        data: benchmarkAnnualisedReturn,
      },
      {
        ...ChartHelper.getLineSeriesOptions('#56b877'),
        name: 'Portfolio Volatility',
        data: portfolioVolatility,
      },
      {
        ...ChartHelper.getLineSeriesOptions('#6677cc'),
        name: 'Portfolio 1 year Return',
        data: portfolioAnnualisedReturn,
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        filterMode: 'none',
      },
    ],
  };
  return merge({}, chartOpt, mergedOpt, getChangedChartOptions(benchmarkVolatility, period, chartWidth));
}

function getChangedChartOptions(dataInView: LineData, period: IPeriod, chartWidth: number): echarts.EChartOption {
  const labelType: AxisLabelType = getLabelAxisType(dataInView, period);
  const filteredLabels = filterAxisLabels(dataInView, labelType);
  const canBeShown: number = chartWidth / LABEL_WIDTH;
  let showEveryIndex: number = Math.ceil(FormatHelper.round(filteredLabels.length / canBeShown, 10));

  if (!showEveryIndex) {
    showEveryIndex = 1;
  }

  return {
    xAxis: {
      axisLabel: {
        formatter(value: number): any {
          const formattedVal = moment(value);

          const from = moment(period.from ? period.from.toString() : '');
          const to = moment(period.to ? period.to.toString() : '');

          if (period.type === PERIOD_TYPE.MTD || (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'days') < 31)) {
            return formattedVal.format("DD MMM'YY");
          }

          if (
            period.type === PERIOD_TYPE.YEAR ||
            period.type === PERIOD_TYPE.YTD ||
            (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'months') < 13 && to.diff(from, 'months') >= 1)
          ) {
            return formattedVal.format('DD') === '01' ? formattedVal.format("MMM'YY") : '';
          }

          if (
            period.type === PERIOD_TYPE.INCEPTION ||
            (period.type === PERIOD_TYPE.MANUAL && to.diff(from, 'months') > 12)
          ) {
            if (formattedVal.format('DD') !== '01') {
              return '';
            }
            switch (formattedVal.format('M')) {
              case '1':
                return 'Q' + formattedVal.format("Q'YY");
              case '4':
                return 'Q' + formattedVal.format("Q'YY");
              case '7':
                return 'Q' + formattedVal.format("Q'YY");
              case '10':
                return 'Q' + formattedVal.format("Q'YY");
              default:
                return '';
            }
          }
        },
      },
    },
    dataZoom: [
      {
        minValueSpan: getZoomInterval(period) * showEveryIndex,
      },
    ],
  };
}

function getLabelAxisType(dataInView: LineData, period: IPeriod): AxisLabelType {
  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return AxisLabelType.MONTH;
    case PERIOD_TYPE.INCEPTION:
      return AxisLabelType.QUARTER;
    case PERIOD_TYPE.MANUAL:
      const firstDate = period.from ? period.from : undefined;
      const lastDate = period.to ? period.to : undefined;

      const diffMonths = moment(lastDate).diff(firstDate, 'months');
      if (!diffMonths) {
        return AxisLabelType.DAY;
      }
      if (diffMonths >= 1 && diffMonths <= 12) {
        return AxisLabelType.MONTH;
      }
      return AxisLabelType.QUARTER;
    default:
      return AxisLabelType.DAY;
  }
}

function getAxisLabel(value: string | number, labelType: AxisLabelType): string {
  const date: Moment = moment(value);
  switch (labelType) {
    case AxisLabelType.MONTH:
      return date.format("MMM'YY");
    case AxisLabelType.QUARTER:
      return `Q${date.quarter()}'${date.format('YY')}`;
    default:
      return date.format("DD MMM'YY");
  }
}

function getZoomInterval(period: IPeriod): number {
  const day: number = 3600 * 1000 * 24;
  const week: number = day * 7;
  const month: number = day * 31;
  const quarter: number = day * 92;

  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return month;
    case PERIOD_TYPE.INCEPTION:
      return quarter;
    case PERIOD_TYPE.MANUAL:
      if (!period.to || !period.from) {
        return week;
      }

      const diffMonths: number = moment(period.to).diff(period.from, 'months');
      const diffYears: number = moment(period.to).diff(period.from, 'years');
      if (!diffMonths) {
        return week;
      }
      if (diffYears <= 1) {
        return month;
      }
      return quarter;
    default:
      return week;
  }
}
