import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { ProfitChart } from '../../../components/ProfitChart';
import { ScenarioConfig } from '../../../../../components/ScenarioConfig';

import { RecommendationsActions } from '../../../../../services/actions';
import { IScenarioBasicConfig } from '../../../../../models/IScenarioBasicConfig';
import { IRootState } from '../../../../../services/store';
import { SETTINGS_VIEW_TYPE } from '../../../../../services/constants/constants';

import s from '../Recommendations.module.css';
import {
  getPortfolioCurrencyCode,
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../../services/selectors/portfolio';

interface IMapStateToProps {
  basicSettings: IScenarioBasicConfig;
  historyEvent: string | null;
  historyPeriod: number;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IDispatchToProps {
  applySettings: (settings: IScenarioBasicConfig) => void;
  selectHistoryEvent: (event: string) => void;
  changeHistoryPeriod: (period: number) => void;
}

type IProps = IMapStateToProps & IDispatchToProps;

class ScenarioAnalysisContainer extends PureComponent<IProps> {
  render() {
    const {
      applySettings,
      basicSettings,
      historyEvent,
      historyPeriod,
      selectHistoryEvent,
      changeHistoryPeriod,
      portfolioCurrencyFormatter,
    } = this.props;
    return (
      <div className={s.scenarioBlocks}>
        <div className={s.scenarioBlock}>
          <ScenarioConfig
            historyEvent={historyEvent}
            historyPeriod={historyPeriod}
            slidersTypeView={SETTINGS_VIEW_TYPE.COLUMN}
            basicSettings={basicSettings}
            onSettingsChanged={applySettings}
            onEventSelect={selectHistoryEvent}
            onEventPeriodChanged={changeHistoryPeriod}
          />
        </div>
        <div className={cn(s.scenarioBlock, s.scenarioBlockChart)}>
          <header className={s.scenarioBlockHeader}>
            <h3>Profit and Loss: $0 </h3>
          </header>
          <div className={s.scenarioBlockContent}>
            <ProfitChart profitChartData={[]} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    basicSettings: state.scenario.configurations.basic,
    historyEvent: state.scenario.configurations.historyEvent.selected,
    historyPeriod: state.scenario.configurations.historyEvent.period,
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

const mapDispatchToProps: IDispatchToProps = {
  applySettings: RecommendationsActions.applyScenarioConfig,
  selectHistoryEvent: RecommendationsActions.selectHistoryEvent,
  changeHistoryPeriod: RecommendationsActions.selectHistoryPeriod,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioAnalysisContainer);
