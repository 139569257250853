import React, { FunctionComponent, PropsWithChildren } from 'react';
import s from '../../WorkGroupManager.module.scss';
import { Card } from '@iliotech/storybook';
import investPic from '../../../../static/images/dashboard/investV2.svg';
import clients from '../../../../static/images/assets/shared.svg';
import portfolios from '../../../../static/images/menu/portfolio.svg';
import { formatPriceValueNoCurrency, getValueColor } from '../../../PortfoliosManager/utils';
import CurrencyDropdown from './components/CurrencyDropdown';
import { IOption } from '../../../../components/UIWidgets/Autocomplete';
import _ from 'lodash';

export interface IGroupWorkViewInfo {
  portfoliosList: IPortfolio[];
  currencyOptions: Array<IOption<ICurrency>>;
  onValueSelected: (i: ICurrencyShort) => void;
}

type IProps = PropsWithChildren<IGroupWorkViewInfo>;

const icons = {
  invest: <img alt="Total Nav" src={investPic} style={{ width: 25 }} />,
  user: <img alt="Total clients" src={clients} style={{ maxWidth: 25 }} />,
  portfolios: <img alt="Total portfolios" src={portfolios} style={{ maxWidth: 25 }} />,
};

const WorkGroupViewInfo: FunctionComponent<IProps> = ({ onValueSelected, currencyOptions, portfoliosList }) => {
  const totalNav: number = Array.isArray(portfoliosList)
    ? portfoliosList
        .filter((w) => w.workgroup.clientUser !== null)
        .reduce((accumulator: number, current: IPortfolio) => accumulator + (current.rollup?.value ?? 0), 0)
    : 0;

  const totalPortfolios: number = portfoliosList ? portfoliosList.length : 0;
  const totalClients: number = portfoliosList ? Object.values(_.groupBy(portfoliosList, (p) => p.clientName)).length : 0;
  return (
    <Card className={s.info} style={{ paddingTop: 10, paddingBottom: 10 }}>
      <div className={s.infoItem}>
        <div className={s.labelContainer}>
          {icons.invest} <span className={s.label}>Total NAV</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CurrencyDropdown
            items={currencyOptions.map((item) => ({ name: item.value.name, symbol: item.value.symbol }))}
            onValueSelected={onValueSelected}
            currency={{
              symbol: portfoliosList?.[0]?.rollup?.currencySymbol || 'USD',
              name: portfoliosList?.[0]?.rollup?.currencyCode || 'USD',
            }}
          />
          <span style={{ color: getValueColor(totalNav), marginLeft: 10 }} className={s.value}>
            {formatPriceValueNoCurrency(totalNav)}
          </span>
        </div>
      </div>
      <div className={s.infoItem}>
        <div className={s.labelContainer}>
          {icons.user} <span className={s.label}>Clients</span>
        </div>
        <span className={s.value}>{totalClients}</span>
      </div>
      <div className={s.infoItem}>
        <div className={s.labelContainer}>
          {icons.portfolios} <span className={s.label}>Portfolios</span>
        </div>
        <span className={s.value}>{totalPortfolios}</span>
      </div>
    </Card>
  );
};

export default React.memo(WorkGroupViewInfo);
