import {
  IPortfolioAssetClassItem,
  IPortfolioPnlByAssetClass,
} from '../../../../../../../../models/IPortfolioPnlByAttribution';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { LineData } from '../../../../../../../../models/LineData';
import { ASSET_COLORS, ASSET_LABEL_NAMES } from '../../../../../../../../services/constants/assetClasses';
import { Color } from 'csstype';
import { ChartHelper } from '../../../../../../../../services/utils/ChartHelper';
import { VALUE_TYPE } from '../../../../../../../../services/utils/FormatHelper';
import merge from 'lodash.merge';

export const getColor = (asset: IPortfolioAssetClassItem): Color | undefined => {
  if (asset.assetClass && asset.assetClass in ASSET_COLORS) {
    return ASSET_COLORS[asset.assetClass];
  }
};

export const getPnlByAssetClassChartOptionsNew = (
  period: IPeriod,
  chartData: IPortfolioPnlByAssetClass,
  portfolioCurrencyFormatter: ICurrencyFormatter,
  hideTotal: boolean
) => {
  // remove 0 values
  const seriesTotal: LineData = [];
  const rawTotals: any[] = [];
  const assembledSeries: any = [];
  const filteredData: IPortfolioAssetClassItem[] = chartData.data.map((item) => ({
    ...item,
    data: item.data.filter((el) => Math.floor(Math.abs(el.value)) !== 0),
  }));

  console.log('filteredData', filteredData);
  filteredData.map((item) => {
    const seriesData: LineData = [];
    item.data.map((el) => {
      // seriesData.push([moment(moment(el.date).startOf('month')).format('YYYY-MM-DD'), el.value]);
      seriesData.push([el.date, el.value]);
      rawTotals.push({ date: el.date, value: el.value });
    });

    console.log('seriesData', seriesData);
    assembledSeries.push({
      name: ASSET_LABEL_NAMES[item.assetClass] ?? item.assetClass,
      type: 'bar',
      data: seriesData,
      stack: 'one',
      barMaxWidth: 24,
      barMinHeight: 4,
      itemStyle: {
        color: getColor(item),
        barBorderWidth: 1,
      },
    });
    console.log('assembledSeries', assembledSeries);
  });

  // calculating totals

  const reducedTotals: any[] = [];
  rawTotals.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

  // Grouping Series by Date and Calculate the total for each date
  rawTotals.reduce((res, val) => {
    if (!res[val.date]) {
      res[val.date] = { date: val.date, value: 0 };
      reducedTotals.push(res[val.date]);
    }
    res[val.date].value += val.value;
    return res;
  }, {});

  reducedTotals.map((el) => {
    // seriesTotal.push([moment(moment(el.date).startOf('month')).format('YYYY-MM-DD'), el.value]);
    seriesTotal.push([el.date, el.value]);
  });

  let finalSeries: any[] = [];

  finalSeries = [
    {
      // removing total is causing a bug in date sorting, so instead we are only setting total line color to transparent
      ...ChartHelper.getLineSeriesOptions(hideTotal ? 'transparent' : '#a9b2d1'),
      stack: 'three',
      name: 'Total',
      data: seriesTotal,
    },
  ];

  // Then add all the other dynamically constructed Series after Total!
  assembledSeries.map((el: any) => finalSeries.push(el));

  const chartOpt = ChartHelper.getTimelineChartOptions({
    tooltipValueType: VALUE_TYPE.MONEY,
    portfolioCurrencyFormatter,
  });

  const mergedOpt = {
    grid: {
      y2: 40,
      x2: 82,
      containLabel: false,
      tooltip: {
        formatter: 'a: {a0}',
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return portfolioCurrencyFormatter(value);
        },
      },
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      offset: 5,
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(0, 0, 0, 0.1)', 'rgba(28, 32, 46, 0.1)'],
        },
      },
      show: true,
      splitLine: {
        show: false,
      },
      type: 'category',
      interval: 3600 * 1000 * 24, // 1 day
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        color: '#a9b2d1',
        fontSize: 11,
        rotate: 30,
        showMaxLabel: true,
        interval: 0,
      },
    },
    series: finalSeries,
  };

  return merge({}, chartOpt, mergedOpt, ChartHelper.getTimeAxisLabelFormatter(period));
};
