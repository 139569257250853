import React, { PureComponent } from 'react';
import s from './ToggleLeftRight.module.scss';

interface IOwnProps {
  leftLabel?: string;
  rightLabel?: string;
  toggleCurrentPosition?: boolean;
  disabled?: boolean;
  clickFunction: () => void;
}

type IProps = IOwnProps;

class ToggleLeftRight extends PureComponent<IProps> {
  render() {
    const { leftLabel, rightLabel, toggleCurrentPosition, disabled } = this.props;

    return (
      <div onClick={this.props.clickFunction} className={s.toggleContainer}>
        <div className={s.leftLabel}>{leftLabel}</div>
        <div className={toggleCurrentPosition ? s.toggleTrack : s.toggleTrackRev}>
          <div className={s.toggleKnob} />
        </div>
        <div className={s.rightLabel}>{rightLabel}</div>
      </div>
    );
  }
}

export default ToggleLeftRight;
