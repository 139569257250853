import { FormatHelper } from '../../../../../../../../../services/utils/FormatHelper';
import { defaultTableCellRenderer, TableCellProps } from 'react-virtualized';
import React from 'react';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';

function _getCellValue(cellName: string, cellValue: string | number, calculated: boolean): string | number {
  switch (cellName) {
    case 'portfolioPercentage':
      return cellValue ? FormatHelper.formatPercentage(Number(cellValue)) : 0;
    default:
      return cellValue;
  }
}

function formatNumber(num: number, decimals = 2) {
  if (num) {
    return num
      .toFixed(decimals)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return num;
}

export const commoditiesCellRenderer = (
  data: TableCellProps,
  portfolioCurrencyFormatter: ICurrencyFormatter
): React.ReactNode => {
  if (
    data.dataKey === 'pnlBase' ||
    data.dataKey === 'pnlMarketBase' ||
    data.dataKey === 'realizedPL' ||
    data.dataKey === 'unrealizedPL'
  ) {
    return (data.cellData = `${portfolioCurrencyFormatter()} ${formatNumber(data.cellData, 0)}`);
  }

  if (data.dataKey === 'portfolioPercentage') {
    return (data.cellData = `${FormatHelper.formatPercentage(Number(data.cellData), 100)}`);
  }

  data.cellData = _getCellValue(data.dataKey, data.cellData, data.rowData.calculated);
  return defaultTableCellRenderer(data);
};
