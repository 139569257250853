import { Color } from 'csstype';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import moment from 'moment';

import { BarType, DEFAULT_CHART_OPTIONS } from '../../../../services/constants/charts';
import { ASSET_COLORS } from '../../../../services/constants/assetClasses';
import { FEES_COLORS } from '../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../services/utils/FormatHelper';
import { currencyFormatterV2 } from '../../../../services/utils/CurrencyHelpers';

export function getHistoryChartOptions(
  data: IBarData[],
  portfolioCurrencyFormatter: ICurrencyFormatter,
  selectedCurrency?: string,
  period?: IPeriod,
  showTotal?: boolean,
  hideTooltipZero?: boolean
): echarts.EChartOption {
  const selCurrencyTooltip = ChartHelper.formatTooltip(
    portfolioCurrencyFormatter,
    VALUE_TYPE.MONEY,
    selectedCurrency,
    showTotal,
    hideTooltipZero
  );

  let defaultOpt: echarts.EChartOption = {};

  const displayedCurrency = selectedCurrency === 'All' ? undefined : selectedCurrency;

  const xAxisLabelFormatter = period
    ? ChartHelper.getTimeAxisLabelFormatter(period)
    : ChartHelper.getStaticTimeAxisLabelFormatter();

  if (selectedCurrency === 'All') {
    defaultOpt = ChartHelper.getChartOptions({
      tooltipValueType: VALUE_TYPE.MONEY,
      // portfolioCurrencyFormatter: (price?: number) => (price ? price.toString() : ''),
      portfolioCurrencyFormatter,
      showTotal,
      hideTooltipZero,
    });
  } else {
    defaultOpt = {
      ...DEFAULT_CHART_OPTIONS,
      tooltip: selCurrencyTooltip,
    };
  }

  if (!data.length) {
    return defaultOpt;
  }

  const mergeOpt: any = {
    grid: {
      y: 30,
      x: 40,
    },

    xAxis: {
      formatter(value: string): string {
        const momentVal = moment(value);
        return momentVal.format("MMM'YY");
      },
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return displayedCurrency === undefined
            ? portfolioCurrencyFormatter(value, { numberWithLetter: false })
            : displayedCurrency + ' ' + FormatHelper.formatNumberAsValue(value, 0);
        },
      },
    },
    series: data.map((item) => prepareHistorySeries(item)),
  };

  return merge({}, defaultOpt, mergeOpt, xAxisLabelFormatter);
}

export function getIncomeChartOptions(
  data: IBarData[],
  portfolioCurrencyFormatter: ICurrencyFormatter,
  selectedCurrency?: string | 'All',
  period?: IPeriod,
  showTotal?: boolean,
  hideTooltipZero?: boolean
): echarts.EChartOption {
  const defaultOpt: echarts.EChartOption = getHistoryChartOptions(
    data,
    portfolioCurrencyFormatter,
    selectedCurrency,
    period,
    showTotal,
    hideTooltipZero
  );

  if (!data.length) {
    return defaultOpt;
  }

  const categories = data.filter((item) => item.type === BarType.Solid)[0].data.map((value) => value[0]);

  const mergeOpt: any = {
    xAxis: {
      data: categories,
      axisLabel: {
        formatter(value: string): string {
          const momentVal = moment(value);
          return momentVal.format("MMM'YY");
        },
      },
    },

    series: data.map((item) => prepareIncomeSeries(item)),
  };

  return merge({}, defaultOpt, mergeOpt);
}

function prepareHistorySeries(data: IBarData): echarts.EChartOption.SeriesBar {
  const barOpt =
    data.type === BarType.Solid
      ? ChartHelper.getBarSeriesOptions(getBarColor(data))
      : ChartHelper.getHatchingBarSeriesOptions(getBarColor(data));

  const mergeOpt: echarts.EChartOption.SeriesBar = {
    name: data.name,
    data: data.data,
    barMaxWidth: 12,
    stack: data.type,
  };
  return merge({}, barOpt, mergeOpt);
}

function prepareIncomeSeries(incomeData: IBarData): echarts.EChartOption.SeriesBar {
  return {
    ...prepareHistorySeries(incomeData),
    data: incomeData.data,
  };
}

export function getBarColor(barData: IChartDataItem): Color | undefined {
  if (barData.color) {
    return barData.color;
  }
  if (barData.id && barData.id in FEES_COLORS) {
    return FEES_COLORS[barData.id];
  }
  if (barData.id && barData.id in ASSET_COLORS) {
    return ASSET_COLORS[barData.id];
  }
  return '#fafafa' as Color;
}
