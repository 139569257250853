import { Checkbox } from 'rsuite';
import React from 'react';

export const Toggle = ({
  field,
  settings,
  updateSettings,
}: {
  field: string;
  settings: Record<string, any>;
  updateSettings: any;
}) => {
  return <Checkbox checked={!!settings[field]} onChange={(value, checked) => updateSettings({ [field]: checked })} />;
};
