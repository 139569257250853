import echarts from 'echarts/lib/echarts';
import merge from 'lodash.merge';
import memoizeOne from 'memoize-one';
import moment, { Moment } from 'moment';

import { LineData } from '../../../../../../../../../../../../models/LineData';
import { PERIOD_TYPE } from '../../../../../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../../../../../services/selectors/portfolio';
import { ChartHelper } from '../../../../../../../../../../../../services/utils/ChartHelper';
import { FormatHelper, VALUE_TYPE } from '../../../../../../../../../../../../services/utils/FormatHelper';
import { TRANSACTION_TYPES } from '../../../../../../../../../../../../services/mock/transactionTypes';

const LABEL_WIDTH: number = 30;

enum AxisLabelType {
  DAY,
  MONTH,
  QUARTER,
}

// fixme: put all methods from file in class to avoid global definitions
const labelsMap: Map<number, { value: string; index: number }> = new Map<number, { value: string; index: number }>();

const filterAxisLabels = memoizeOne(
  (data: LineData, labelType: AxisLabelType): LineData => {
    switch (labelType) {
      case AxisLabelType.MONTH:
        return data.filter((item) => moment(item[0]).date() === 1);
      case AxisLabelType.QUARTER:
        return data.filter((item) => {
          const date: Moment = moment(item[0]);
          return date.month() % 3 === 0 && date.date() === 1;
        });
      default:
        return data;
    }
  }
);

const prepareXAisLabels = memoizeOne(
  (data: LineData, period: IPeriod): Map<number, { value: string; index: number }> => {
    labelsMap.clear();
    const labelType: AxisLabelType = getLabelAxisType(data, period);
    filterAxisLabels(data, labelType).forEach((item) =>
      labelsMap.set(moment(item[0]).startOf('day').valueOf(), {
        value: getAxisLabel(item[0], labelType),
        index: labelsMap.size + 1,
      })
    );
    return labelsMap;
  }
);

export function generateChartOptions(
  stockPricesData: any[],
  dividendsData: IDividends[],
  splitsData: ISplits[],
  buyAndSellsData: any[],
  baseCurrency: ICurrency,
  instrumentCurrency: ICurrency,
  instrumentName: string,
  assetType: string,
  portfolioCurrencyFormatter: ICurrencyFormatter,
  earnEventsData: IEarnDataInfo[],
): echarts.EChartOption {
  const chartData = stockPricesData;

  const dataInView: LineData = [];

  const dataForTooltip: any[] = []; // Dataset (eCharts) used here
  dataForTooltip.push([
    'BuyDate',
    'SellDate',
    'SplitDate',
    'DivDate',
    'Stock Price',
    'Operation',
    'Net Amt',
    'Net Amt Base',
    'Quantity',
    'Divisor',
    'Multiplier',
    'Date',
    'Price',
    'Amt/Share',
    'Total',
    'EarnDate',
    'Date',
    'Amt/Coin',
    'Monthly Earn Qty',
    'Monthly Earn Value'
  ]);

  if (!chartData?.length) {
    return {};
  };

  const period: IPeriod = { from: chartData[0].priceDate, to: chartData[chartData.length - 1].priceDate, type: 1 };
  const chartWidth: number = 0;
  const indexName: string = 'FX.ADJ';
  const index: LineData = [];
  const stockName: string = instrumentName;

  const chartVariant = assetType === TRANSACTION_TYPES.fixedIncome ? 'Coupons' : 'Dividends';
  const chartVariantTooltip = assetType === TRANSACTION_TYPES.fixedIncome ? [11, 6] : [11, 13, 8, 14];
  const priceAdjust = assetType === TRANSACTION_TYPES.fixedIncome ? 100 : 1;

  const adjustedBuysAndSells: any[] = [];
  // ToDo: Refactor so not the dates are moved to work days BUT dates get the closest workday stock price.
  buyAndSellsData.map((el) => {
    // Moving the dates of Buys and Sells to workdays when we have Stock Prices figures which are needed
    // Not needed anymore - backend sends prices for weekends too either don't send weekends
    const adjDate: Date = new Date(el.date);
    if (adjDate.getDay() === 6) {
      adjDate.setDate(adjDate.getDate() - 1);
    }
    if (adjDate.getDay() === 0) {
      adjDate.setDate(adjDate.getDate() + 1);
    }
    const formDate = moment(adjDate).format('YYYY-MM-DD');

    adjustedBuysAndSells.push({
      // date: formDate,
      date: el.date,
      isBuy: el.isBuy,
      operationName: el.operationName,
      netAmountBase: el.netAmountBase,
      netAmountLocal: el.netAmountLocal,
      quantity: el.quantity,
      averagePriceLocal: el.averagePriceLocal,
    });
  });


  // Same holidays dates adjustment is needed for Dividends/Coupons data
  // - Same as above - date readjustment not needed anymore
  // ToDo: Refactor - same issue as above.
  const adjustedDividends: any[] = [];

  let monthlyEarnEvents = new Map<string, IEarnDataInfo[]>();

  dividendsData.map((el) => {
    const adjDate: Date = new Date(el.date);
    if (adjDate.getDay() === 6) {
      adjDate.setDate(adjDate.getDate() - 1);
    }
    if (adjDate.getDay() === 0) {
      adjDate.setDate(adjDate.getDate() + 1);
    }
    const formDate = moment(adjDate).format('YYYY-MM-DD');
    adjustedDividends.push({
      // date: formDate,
      date: el.date,
      netAmountLocal: el.netAmountLocal,
      netAmountBase: el.netAmountBase,
      amountPerShare: el.amountPerShare,
      quantity: el.quantity,
    });
  });

  chartData.map((el) => {
    dataInView.push([el.priceDate, el.closePrice]);
    index.push([el.priceDate, el.fxAdjustedPrice]);
    adjustedBuysAndSells.map((el2) => {
      if (el2.date === el.priceDate) {
        if (el2.isBuy) {
          dataForTooltip.push([
            el.priceDate,
            '',
            '',
            '',
            el.closePrice,
            'Buy',
            '',
            '',
            el2.quantity,
            '',
            '',
            `${moment(el.priceDate).format('DD-MMM-YYYY')}`,
            el2.averagePriceLocal,
            // FormatHelper.localePriceValueFormatter(Math.abs((el2.netAmountLocal / el2.quantity) * priceAdjust), 2),
            '',
            '',
            '',
            '',
            '',
            ''
          ]);
        } else {
          dataForTooltip.push([
            '',
            el.priceDate,
            '',
            '',
            el.closePrice,
            'Sell',
            '',
            '',
            el2.quantity,
            '',
            '',
            `${moment(el.priceDate).format('DD-MMM-YYYY')}`,
            el2.averagePriceLocal,
            // FormatHelper.localePriceValueFormatter(Math.abs((el2.netAmountLocal / el2.quantity) * priceAdjust), 2),
            '',
            '',
            '',
            '',
            '',
            ''
          ]);
        }
      }
    });
    (adjustedDividends?.length ? adjustedDividends: []).map((el3) => {
      if (el3.date === el.priceDate) {
        const totalAmt =
          baseCurrency.symbol +
          FormatHelper.formatNumberAsValue(el3.netAmountBase, 0) +
          (baseCurrency.name !== instrumentCurrency.name
            ? ' / ' + instrumentCurrency.symbol + FormatHelper.formatNumberAsValue(el3.netAmountLocal, 0)
            : '');
        dataForTooltip.push([
          '',
          '',
          '',
          el.priceDate,
          el.closePrice,
          chartVariant,
          el3.netAmountLocal,
          el3.netAmountBase,
          el3.quantity,
          '',
          '',
          `${moment(el.priceDate).format('DD-MMM-YYYY')}`,
          '',
          el3.amountPerShare,
          totalAmt,
          '',
          '',
          '',
          ''
        ]);
      }
    });
    (splitsData?.length ? splitsData : []).map((el4) => {
      if (el4.date === el.priceDate) {
        dataForTooltip.push([
          '',
          '',
          el.priceDate,
          '',
          el.closePrice,
          `Split on ${moment(el.priceDate).format('DD-MMM-YYYY')}`,
          '',
          '',
          '',
          el4.divisor,
          el4.multiplier,
          '',
          '',
          '',
          '',
          '',
          ''
        ]);
      }
    });
    (earnEventsData?.length ? earnEventsData : []).map((el5) => {
      if (el5.date === el.priceDate) {
        if(moment(el.priceDate) === moment(el.priceDate).endOf('month')) {
          const currentMonth = moment(el.priceDate).format('MMMM');
          const aggregateEarn = monthlyEarnEvents?.get(currentMonth)?.reduce((acc, curr) => {
            return {
              baseEarn: acc.baseEarn + curr.baseEarn,
              localEarn: acc.localEarn + curr.localEarn,
              quantity: acc.quantity + curr.quantity
            }
          }, {
            baseEarn: 0,
            localEarn: 0,
            quantity: 0
          });

          const totalAmt =
          baseCurrency.symbol +
          FormatHelper.formatNumberAsValue(aggregateEarn?.baseEarn as number, 0) +
          (baseCurrency.name !== instrumentCurrency.name
            ? ' / ' + instrumentCurrency.symbol + FormatHelper.formatNumberAsValue(aggregateEarn?.localEarn as number, 0)
            : '');
          dataForTooltip.push([
            '',
            '',
            '',
            '',
            el.closePrice,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            el5.date,
            `${moment(el.priceDate).format('DD-MMM-YYYY')}`,
            el5.earnPerCoin,
            aggregateEarn?.quantity,
            totalAmt
          ]);
        } else {
          const currentMonth = moment(el.priceDate).format('MMMM');
          const earnsOfMonth = monthlyEarnEvents.get(currentMonth) ?? [];
          monthlyEarnEvents.set(currentMonth, [...earnsOfMonth, el5])
        }
      }
    });
  });

  // console.log('DATA FOR TOOLTIP');
  // console.log(dataForTooltip);

  const chartOpt = ChartHelper.getTimelineChartOptionsNoTooltip({
    tooltipValueType: VALUE_TYPE.MONEY,
    zoom: true,
    portfolioCurrencyFormatter,
  });

  const chartTooltip = {
    tooltip: {
      show: true,
      axisPointer: {
        lineStyle: {
          color: '#6677cc',
          type: 'dashed',
          width: 1,
        },
        type: 'line',
      },
      backgroundColor: '#fff',
      borderColor: '#ccc',
      textStyle: {
        color: '#555',
        fontSize: 11,
      },
      borderWidth: 1,
      extraCssText: 'border-radius:4px;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);}',
      padding: [0, 3, 1, 7],
      trigger: 'item',
    },
  };

  const mergedOpt: any = {
    dataset: {
      source: dataForTooltip,
    },
    grid: {
      y2: 40,
      x2: 70,
      containLabel: false,
    },
    yAxis: {
      axisLabel: {
        formatter(value: number): string {
          return FormatHelper.formatCurrencyShort(value, '', 1, true);
        },
      },
    },
    xAxis: {
      interval: 3600 * 1000 * 24, // 1 day
      axisTick: {
        show: false,
      },
      axisLabel: {
        rotate: 30,
        showMaxLabel: true,
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#6677cc',
          type: 'dashed',
          width: 1,
        },
        label: {
          show: false,
          formatter: (params: any) => {
            return `${moment(params.value).format('DD-MMM-YYYY')}`;
          },
        },
      },
    },
    series: [
      // {
      //   ...ChartHelper.getLineSeriesOptions('#c2b261'),
      //   name: indexName,
      //   data: index,
      //   tooltip: {
      //     formatter: (params: any) => {
      //       return `${moment(params.value[0]).format(
      //         'DD-MMM-YYYY'
      //       )}<br> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
      //         params.color
      //       };"></span>${params.seriesName}: ${params.value[1]}`;
      //     },
      //   },
      // },
      {
        ...ChartHelper.getLineSeriesOptionsWithRadialGradient(),
        name: stockName,
        data: dataInView,
        tooltip: {
          formatter: (params: any) => {
            return `${moment(params.value[0]).format(
              'DD-MMM-YYYY'
            )}<br> <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              params.color
            };"></span>${params.seriesName}: ${params.value[1]}`;
          },
        },
      },
      {
        // This series pulls data from the DataSource object (DataForTooltip)
        name: 'Buy / Transfer In',
        type: 'line',
        symbol: 'triangle',
        symbolSize: 15,
        symbolOffset: [0, 20],
        itemStyle: {
          color: '#56b877',
        },
        lineStyle: {
          width: 0,
        },
        encode: {
          x: 'BuyDate',
          y: 'Stock Price',
          tooltip: [11, 12, 8],
        },
      },
      {
        name: 'Sell / Transfer Out',
        type: 'line',
        symbol: 'triangle',
        symbolRotate: 180,
        symbolSize: 15,
        symbolOffset: [0, -20],
        itemStyle: {
          color: '#cc6f66',
        },
        lineStyle: {
          width: 0,
        },
        encode: {
          x: 'SellDate',
          y: 'Stock Price',
          tooltip: [11, 12, 8],
        },
      },
      {
        type: 'line',
        name: chartVariant,
        symbol: 'triangle',
        symbolSize: 15,
        symbolRotate: 180,
        symbolOffset: [0, -15],
        itemStyle: {
          color: '#59abb3',
        },
        lineStyle: {
          width: 0,
        },
        encode: {
          x: 'DivDate',
          y: 'Stock Price',
          tooltip: chartVariantTooltip,
        },
      },
      {
        type: 'line',
        name: 'Splits',
        symbol: 'triangle',
        symbolSize: 15,
        symbolRotate: 180,
        symbolOffset: [0, -15],
        itemStyle: {
          color: 'rgb(150, 167, 188)',
        },
        lineStyle: {
          width: 0,
        },
        encode: {
          x: 'SplitDate',
          y: 'Stock Price',
          tooltip: [5, 9, 10],
        },
      },
      {
        type: 'line',
        name: 'Earn',
        symbol: getSvgAsDataUri(),
        symbolSize: 15,
        symbolRotate: 0,
        symbolOffset: [0, -15],
        itemStyle: {
          color: 'rgb(150, 167, 188)',
        },
        lineStyle: {
          width: 0,
        },
        encode: {
          x: 'EarnDate',
          y: 'Stock Price',
          tooltip: [16, 17, 18, 19],
        },
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        filterMode: 'none',
      },
    ],
  };

  const allData =  merge({}, chartOpt, chartTooltip, mergedOpt, getChangedChartOptions(dataInView, period, chartWidth));

  return allData;
}

function getChangedChartOptions(dataInView: LineData, period: IPeriod, chartWidth: number): echarts.EChartOption {
  const labelType: AxisLabelType = getLabelAxisType(dataInView, period);

  const filteredLabels = filterAxisLabels(dataInView, labelType);
  const canBeShown: number = chartWidth / LABEL_WIDTH;
  let showEveryIndex: number = Math.ceil(FormatHelper.round(filteredLabels.length / canBeShown, 10));

  if (!showEveryIndex) {
    showEveryIndex = 1;
  }

  return {
    xAxis: {
      axisLabel: {
        formatter(value: number): any {
          const formattedVal = moment(value);

          const from = moment(period.from ? period.from.toString() : '');
          const to = moment(period.to ? period.to.toString() : '');

          if (to.diff(from, 'days') < 19) {
            return formattedVal.format('DD') === '01' ? formattedVal.format("DD'MMM'YY") : '';
          } else if (to.diff(from, 'days') > 19 && to.diff(from, 'days') < 541) {
            return formattedVal.format('DD') === '01' ? formattedVal.format("MMM'YY") : '';
          } else {
            switch (formattedVal.format('M')) {
              case '1':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              case '4':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              case '7':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              case '10':
                if (formattedVal.format('DD') === '15') {
                  return 'Q' + formattedVal.format("Q'YY");
                }
                break;
              default:
                return '';
            }
          }
        },
      },
    },
    dataZoom: [
      {
        minValueSpan: getZoomInterval(period) * showEveryIndex,
      },
    ],
  };
}

function getLabelAxisType(dataInView: LineData, period: IPeriod): AxisLabelType {
  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return AxisLabelType.MONTH;
    case PERIOD_TYPE.INCEPTION:
      return AxisLabelType.QUARTER;
    case PERIOD_TYPE.MANUAL:
      const firstDate = period.from ? period.from : undefined;
      const lastDate = period.to ? period.to : undefined;

      const diffMonths = moment(lastDate).diff(firstDate, 'months');
      if (!diffMonths) {
        return AxisLabelType.DAY;
      }
      if (diffMonths >= 1 && diffMonths <= 12) {
        return AxisLabelType.MONTH;
      }
      return AxisLabelType.QUARTER;
    default:
      return AxisLabelType.DAY;
  }
}

function getAxisLabel(value: string | number, labelType: AxisLabelType): string {
  const date: Moment = moment(value);
  switch (labelType) {
    case AxisLabelType.MONTH:
      return date.format("MMM'YY");
    case AxisLabelType.QUARTER:
      return `Q${date.quarter()}'${date.format('YY')}`;
    default:
      return date.format('DD-MMM-YYYY');
  }
}

function getZoomInterval(period: IPeriod): number {
  const day: number = 3600 * 1000 * 24;
  const week: number = day * 7;
  const month: number = day * 31;
  const quarter: number = day * 92;

  switch (period.type) {
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.YTD:
      return month;
    case PERIOD_TYPE.INCEPTION:
      return quarter;
    case PERIOD_TYPE.MANUAL:
      if (!period.to || !period.from) {
        return week;
      }

      const diffMonths: number = moment(period.to).diff(period.from, 'months');
      const diffYears: number = moment(period.to).diff(period.from, 'years');
      if (!diffMonths) {
        return week;
      }
      if (diffYears <= 1) {
        return month;
      }
      return quarter;
    default:
      return week;
  }
}


function getSvgAsDataUri () {
  return `image://data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80'%3E%3Cg%3E%3Ccircle cx='40' cy='40' r='40' stroke='green' stroke-width='0' fill='orange'%3E%3C/circle%3E%3Ctext fill='%23000' font-size='80px' font-family='Nunito sans-serif' x='20' y='65'%3E$%3C/text%3E%3C/g%3E%3C/svg%3E`
}
