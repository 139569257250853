import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';
import { IPortfolioPnlByAssetClass } from '../../../models/IPortfolioPnlByAttribution';

import { ILoadingDataState } from '../../../models/redux/ILoadingDataState';

export interface IPortfolioPnlByAssetClassState extends ILoadingDataState {
  data: IPortfolioPnlByAssetClass;
}

const INITIAL_STATE: any = {
  fxTotal: 0,
  incomeTotal: 0,
  priceTotal: 0,
  realizedTotal: 0,
  total: 0,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
};

const portfolioPnlByAssetClassReducer = ReduxHelper.createHttpRequestReducer<
  ILoadingDataState<IPortfolioPnlByAssetClass>
>(
  INITIAL_STATE,
  DashboardActions.PORTFOLIO_PNL_BY_ASSET_CLASS_REQUEST,
  DashboardActions.PORTFOLIO_PNL_BY_ASSET_CLASS_SUCCESS,
  DashboardActions.PORTFOLIO_PNL_BY_ASSET_CLASS_ERROR,
  DashboardActions.SET_PORTFOLIO_PNL_BY_ASSET_CLASS_PERIOD
);

export default portfolioPnlByAssetClassReducer;
