import * as React from 'react';
import s from './Tooltip.module.scss';
import { FunctionComponent, Props, PropsWithChildren, ReactNode } from 'react';
import { Button, Tooltip, Whisper } from 'rsuite';

interface IProps {
  text: string;
  children: ReactNode[] | ReactNode;
}

const IllioTooltip = ({ text, children }: IProps) => {
  const tooltip = <Tooltip>{text}</Tooltip>;

  return (
    <Whisper placement={'auto'} trigger={'hover'} speaker={tooltip}>
      <Button className={s.button}> {children}</Button>
    </Whisper>
  );
};

export default IllioTooltip;
