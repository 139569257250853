import React, { Fragment, PureComponent } from 'react';
import { TopListItem } from '../../../../../../../../models/ITopListItem';

import s from './Cash.module.scss';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../../../components/ContextHelp';
import { DASHBOARD_CASH_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';

interface IProps {
  chartData: TopListItem[];
  selectedFilter: number;
  portfolioCurrency: string;
  portfolioSymbol: string;
}

class TopPositionsChart extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const filterBy = this.props.selectedFilter;
    let chartDt: TopListItem[] = this.props.chartData.map((item) => {
      return {
        ...item,
      };
    });

    // Remodelling data object for Filter By Custodian
    if (filterBy === 1) {
      const uniqueCustodians = [...new Set(chartDt.map((item) => item.custodianName))];
      chartDt = [];

      uniqueCustodians.map((uniqueCustodian) => {
        const oneCustodianPositions: TopListItem[] = [];

        this.props.chartData.map((position) => {
          if (position.custodianName === uniqueCustodian) {
            oneCustodianPositions.push(position);
          }
        });

        let currencyName = '';
        let currencySymbol = '';
        let custPositiveTotalValueBase = 0;
        let custNegativeTotalValueBase = 0;

        oneCustodianPositions.map((el) => {
          currencyName = el.currency.name;
          currencySymbol = el.currency.symbol;

          if (el.marketValueBase >= 0) {
            custPositiveTotalValueBase += el.marketValueBase;
          } else {
            custNegativeTotalValueBase += el.marketValueBase;
          }
        });

        // Creating a matching array of objects containing custodian data for the chart
        if (custPositiveTotalValueBase >= 0) {
          chartDt.push({
            custodianName: uniqueCustodian,
            marketValueBase: custPositiveTotalValueBase,
            marketValueLocal: 0,
            currency: { name: currencyName, symbol: currencySymbol },
            name: '',
            percentage: 0,
            value: 0,
            assetClassId: '',
          });
        }
        if (custNegativeTotalValueBase < 0) {
          chartDt.push({
            custodianName: uniqueCustodian,
            marketValueBase: custNegativeTotalValueBase,
            marketValueLocal: 0,
            currency: { name: currencyName, symbol: currencySymbol },
            name: '',
            percentage: 0,
            value: 0,
            assetClassId: '',
          });
        }
      });

      // Sorting to descending order
      chartDt.sort((a, b) => {
        if (a.marketValueBase === b.marketValueBase) {
          return a.marketValueBase - b.marketValueBase;
        }
        return a.marketValueBase < b.marketValueBase ? 1 : -1;
      });
    } // END OF Custodian DTO transformation

    const maxVal = Math.max.apply(
      Math,
      chartDt.map((o) => o.marketValueBase)
    );
    const minVal = Math.min.apply(
      Math,
      chartDt.map((o) => o.marketValueBase)
    );

    let BarMaxValue = maxVal;

    if (maxVal < 0) {
      BarMaxValue = -Math.min.apply(
        Math,
        chartDt.map((o) => o.marketValueBase)
      );
    }

    if (maxVal < Math.abs(minVal)) {
      BarMaxValue = -minVal;
    }

    let lgShortValue = 0;
    let lgLongValue = 0;

    chartDt.map((el) => {
      if (el.marketValueBase > 0) {
        lgLongValue += el.marketValueBase;
      } else {
        lgShortValue += el.marketValueBase;
      }
    });

    const lgNetValue = lgLongValue + lgShortValue;

    const baseCurrency = this.props.portfolioCurrency;
    const baseSymbol = this.props.portfolioSymbol;

    return (
      <Fragment>
        <div className={s.chartLegendContainer}>
          <div className={s.legendShort}>
            Short: {baseSymbol}
            {lgShortValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            <ContextHelpIcon title={'Short'} elementId={'ShortLbl'} />
            <ContextHelpTooltip
              elementId={'ShortLbl'}
              tooltipTitle={'Short'}
              tooltipText={DASHBOARD_CASH_HELP_TEXT.LabelShortValue}
            />
          </div>
          <div className={lgNetValue > 0 ? s.legendNetPos : s.legendNetNeg}>
            Net: {baseSymbol}
            {lgNetValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            <ContextHelpIcon title={'Net'} elementId={'NetLbl'} />
            <ContextHelpTooltip
              elementId={'NetLbl'}
              tooltipTitle={'Net'}
              tooltipText={DASHBOARD_CASH_HELP_TEXT.LabelNetValue}
            />
          </div>
          <div className={s.legendLong}>
            Long: {baseSymbol}
            {lgLongValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            <ContextHelpIcon title={'Long'} elementId={'LongLbl'} />
            <ContextHelpTooltip
              elementId={'LongLbl'}
              tooltipTitle={'Long'}
              tooltipText={DASHBOARD_CASH_HELP_TEXT.LabelLongValue}
            />
          </div>
        </div>
        <div className={s.CashPositionsChartContainer}>
          {chartDt.map((elem, index) => {
            const localValue = elem.marketValueLocal.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            const baseValue = elem.marketValueBase.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            let barWidth = 1;
            if (elem.marketValueBase > 0) {
              barWidth = (elem.marketValueBase * 100) / BarMaxValue;
            } else {
              barWidth = (-elem.marketValueBase * 100) / BarMaxValue;
            }

            let barLabel = '';
            if (filterBy === 1) {
              barLabel = elem.custodianName + ': ' + this.props.portfolioSymbol + baseValue;
            } else {
              barLabel = elem.currency.name + ': ' + localValue + ' (' + this.props.portfolioSymbol + baseValue + ')';
            }

            return (
              <div key={index}>
                <div className={elem.marketValueBase > 0 ? s.positiveBarItem : s.negativeBarItem}>
                  <div className={s.barLabel}>{barLabel}</div>
                  <div className={s.bar} style={{ width: barWidth + '%' }} />
                </div>
                <div className={s.barSpacer} />
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

export default TopPositionsChart;
