import React, { Fragment, useState } from 'react';
import s from '../../Dashboard.module.scss';
import { PortfolioHeader, Widget } from '../../../../../../components';
import { ToggleLeftRight } from '../../../../../../../../components';
import { Card } from '@iliotech/storybook';
import { DASHBOARD_PORTFOLIO_HELP_TEXT } from '../../../../../../../../services/constants/tooltipContextHelp';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';
import { SummaryProfitChart } from '../../components/SummaryProfitChart';
import { LegendVariant } from '../../../../../../../../services/constants/charts';
import { SummaryPerformanceChart } from '../../components/SummaryPerformanceChart';
import { useDispatch } from 'react-redux';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { useHistory, generatePath } from 'react-router-dom';
import { PATHS } from '../../../../../../../../router/paths';
import moment from 'moment';
import { usePortfolio } from '../../../../../../../../services/hooks/usePortfolio';
import { usePeriod } from '../../../../../../../../services/context/PeriodContext';
import { useCustodiansFilter } from '../../../../../../../../services/hooks/useCustodiansFilter';
import { DashboardHeader } from '../../components/DashboardHeader';

interface IProps {
  baseCurrency: ICurrency;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  period: IPeriod;
  selectedCustodians: string[];
  portfolioId: string;
  handlePeriodChange: (period: IPeriod) => void;
  viewPositionHistory: any;
}

const Summary = ({}: // portfolioCurrencyFormatter,
// baseCurrency,
// selectedCustodians,
// period,
// portfolioId,
// handlePeriodChange,
IProps) => {
  const [performanceTogglePosition, setPerformanceTogglePosition] = useState<0 | 1>(0);

  const { portfolioCurrencyFormatter, portfolioInfo } = usePortfolio();
  const portfolioId = portfolioInfo.data?.id;
  const baseCurrency = portfolioInfo?.data?.currency;
  const { period, updatePeriod } = usePeriod();
  const { selectedCustodiansArray } = useCustodiansFilter();

  // const {} = usePortfolio

  const dispatch = useDispatch();
  const history = useHistory();

  const performanceChartTitle =
    performanceTogglePosition === 0
      ? 'Daily Performance'
      : performanceTogglePosition === 1
      ? 'Portfolio value vs net investment'
      : 'Performance';

  const profitChartTitle =
    performanceTogglePosition === 0 ? 'Monthly Performance' : performanceTogglePosition === 1 ? 'Monthly P&L' : 'P&L';

  const handleToggleProfitVsPerformance = () => {
    if (performanceTogglePosition === 0) {
      setPerformanceTogglePosition(1);
    } else {
      setPerformanceTogglePosition(0);
    }
  };

  const onBarChartItemClick = (data: [string, number, number]) => {
    const dateParts = data[0].split('-');
    const toDate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10), 0);

    dispatch(SnapshotActions.updateSnapshotFilter('positionsFrom', `${dateParts[0]}-${dateParts[1]}-01`));
    dispatch(SnapshotActions.updateSnapshotFilter('positionsTo', moment(toDate).format('YYYY-MM-DD')));

    const url = generatePath(PATHS.portfolio.snapshot.positions.path, { portfolioId });
    history.push(url);
  };

  return (
    <div>
      <PortfolioHeader />
      <DashboardHeader page={'P&L'} />
      <div className={s.chartToolsContainer}>
        <ToggleLeftRight
          leftLabel={'Performance (%)'}
          rightLabel={'Value (' + baseCurrency?.symbol + ')'}
          clickFunction={handleToggleProfitVsPerformance}
          toggleCurrentPosition={!performanceTogglePosition}
        />
      </div>
      <div className={s.verticalContainer}>
        <div style={{ flex: 3 }}>
          <Card>
            <Widget
              colNum={4}
              title={performanceChartTitle}
              titleInfo={
                performanceTogglePosition === 0
                  ? DASHBOARD_PORTFOLIO_HELP_TEXT.ChartPerformance
                  : DASHBOARD_PORTFOLIO_HELP_TEXT.ChartCumulativeDailyPnl
              }
            >
              <SummaryPerformanceChart
                period={period}
                portfolioId={portfolioId!}
                portfolioCurrencyFormatter={portfolioCurrencyFormatter}
                hidePeriodSelector={true}
                performanceToggle={performanceTogglePosition}
                selectedCustodians={selectedCustodiansArray}
              />
            </Widget>
          </Card>
          <br />
          <div style={{ height: 20 }} />
          <Card>
            <Widget
              title={profitChartTitle}
              colNum={4}
              titleInfo={
                performanceTogglePosition === 0
                  ? DASHBOARD_PORTFOLIO_HELP_TEXT.ChartPnL
                  : DASHBOARD_PORTFOLIO_HELP_TEXT.ChartMonthlyPnl
              }
            >
              <SummaryProfitChart
                portfolioId={portfolioId!}
                legendVariant={LegendVariant.Default}
                isWaterFall={false}
                period={period}
                selectedCustodians={selectedCustodiansArray}
                portfolioCurrencyFormatter={portfolioCurrencyFormatter}
                performanceToggle={performanceTogglePosition}
                showLegend={false}
                onItemClick={onBarChartItemClick}
              />
            </Widget>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Summary;
