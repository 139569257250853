import * as React from 'react';
import s from './PositionsUpload.module.scss';
import { UploadFile } from '../../../../../../../../../../components/Upload/components';
import { ALL_EXCEL_EXTENSIONS } from '../../../../../../../../../../components/Upload/components/UploadFile';

interface IProps {
  onDrop: (f: File[]) => void;
}
const PositionsUpload = ({ onDrop }: IProps) => {
  return (
    <div className={s.container}>
      <UploadFile onDrop={onDrop} allowedExtensions={ALL_EXCEL_EXTENSIONS} />
    </div>
  );
};

export default PositionsUpload;
