import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';

export class AccountsActions {
  static readonly ACCOUNTS_REQUEST = 'ACCOUNTS_REQUEST';
  static readonly ACCOUNTS_SUCCESS = 'ACCOUNTS_SUCCESS';
  static readonly ACCOUNTS_ERROR = 'ACCOUNTS_ERROR';
  static readonly ACCOUNTS_RESET = 'ACCOUNTS_RESET';

  static fetchAccounts() {
    return ReduxHelper.createHttpRequestActionBundle<IAccountSummaries[]>(
      {
        url: getApiUrl('accounts'),
        method: 'GET',
      },
      AccountsActions.ACCOUNTS_REQUEST,
      AccountsActions.ACCOUNTS_SUCCESS,
      AccountsActions.ACCOUNTS_ERROR
    );
  }
}
