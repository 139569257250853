import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './services/store';
import { unregister } from './serviceWorker';
import 'rsuite/lib/styles/themes/dark/index.less';
import './static/styles/main.css';
import './static/styles/print.css';
import './static/styles/popover.css';
import 'react-toastify/dist/ReactToastify.css';
import './static/styles/_kendo_ui_overrides.scss';
import './static/styles/rsuite-overrides.less';
import AppProvider from './AppProvider';

export const { persistor, store } = configureStore();

const script = document.createElement('script');
script.type = 'text/javascript';
script.id = 'hs-script-loader';
script.async = true;
script.defer = true;
document.head.appendChild(script);

// fix for IPnone, IPad, IPod touch for document click
const userAgent = navigator.userAgent;
const checkAppleType: string[] = ['iphone', 'ipad', 'ipod touch'];
if (checkAppleType.some((device) => userAgent.toLowerCase().includes(device))) {
  const style = document.createElement('style');
  style.innerHTML = 'html {cursor: pointer}';

  document.head.append(style);
}

ReactDOM.render(<AppProvider />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
