import React, { ComponentType } from 'react';
import {
  AlternativesContainer,
  Cash,
  Commodities,
  CreditContainer,
  CryptoCurrency,
  Equity,
  Fund,
  RealAsset,
  RealEstate,
  FixedIncome,
} from '../../scenes/Portfolio/scenes/Dashboard/scenes/AssetsDashboard/scenes';
import alternatives from '../../static/images/icons/alternatives.svg';
import cash from '../../static/images/icons/cash.svg';
import commodities from '../../static/images/icons/commodities.svg';
import credit from '../../static/images/icons/credit.svg';
import cryptoCurrency from '../../static/images/icons/crypto-currency.svg';
import equity from '../../static/images/icons/equity.svg';
import fund from '../../static/images/icons/funds.svg';
import realAssets from '../../static/images/icons/real-assets.svg';
import realEstate from '../../static/images/icons/real-estate.svg';
import { ALLOCATION_TYPES, SUB_ASSETS } from './constants';
import { ASSETS } from './assetClasses';
import allAssetsIcon from '../../static/images/icons/allAssets.svg';

export const ASSET_SUB_CLASSES_IDS: Record<string, string> = {};

export interface IAssetClassOptions {
  allocations: ALLOCATION_TYPES[];
  component: ComponentType;
  name: string;
  inMenu: boolean;
  subClassesIds: string[];
  icon?: string;
  pages?: { id: string; title: string }[];
}

export interface IAssetSubClassOptions extends Omit<IAssetClassOptions, 'subClassesIds'> {
  parentId: string;
}

// <Tab key={0} label="SUMMARY" />
// <Tab key={1} label="P&L" />
// <Tab key={2} label="STRUCTURE" />
// <Tab key={3} label="INCOME" />
// <Tab key={4} label="FEES & CHARGES" />

export const ASSET_CLASSES_CONFIG: Record<string, IAssetClassOptions> = {
  [ASSETS.equity]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
      ALLOCATION_TYPES.Custodian,
      ALLOCATION_TYPES.Industry,
    ],
    name: 'Equity',
    icon: equity,
    component: Equity,
    subClassesIds: [SUB_ASSETS.options],
    inMenu: true,
    pages: [
      { id: 'pnl', title: 'P&L' },
      { id: 'structure', title: 'STRUCTURE' },
      { id: 'pnlBreakdown', title: 'P&L BREAKDOWN' },
      { id: 'fundamentals', title: 'FUNDAMENTALS' },
      { id: 'income', title: 'INCOME' },
      { id: 'options', title: 'OPTIONS' },
    ],
  },
  [ASSETS.cryptoCurrencies]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
      ALLOCATION_TYPES.Custodian,
    ],
    name: 'Cryptocurrency',
    icon: cryptoCurrency,
    component: CryptoCurrency,
    subClassesIds: [SUB_ASSETS.options],
    inMenu: true,
  },
  [ASSETS.adjustments]: {
    allocations: [],
    name: 'Adjustments',
    icon: fund,
    component: () => null,
    subClassesIds: [],
    inMenu: false,
  },
  [ASSETS.fund]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.FundStrategy,
      ALLOCATION_TYPES.Custodian,
      ALLOCATION_TYPES.ETFSubClass,
    ],
    name: 'Funds',
    icon: fund,
    component: Fund,
    subClassesIds: [SUB_ASSETS.options],
    inMenu: true,
    pages: [
      { id: 'pnl', title: 'P&L' },
      { id: 'structure', title: 'STRUCTURE' },
      { id: 'pnlBreakdown', title: 'P&L BREAKDOWN' },
      { id: 'income', title: 'INCOME' },
    ],
  },
  [ASSETS.credit]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
      ALLOCATION_TYPES.CreditRating,
    ],
    name: 'Credit',
    icon: credit,
    component: CreditContainer,
    subClassesIds: [],
    inMenu: true,
  },
  [ASSETS.alternatives]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
    ],
    name: 'Alternatives',
    icon: alternatives,
    component: AlternativesContainer,
    subClassesIds: [],
    inMenu: true,
  },
  [ASSETS.realEstate]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Custodian,
      ALLOCATION_TYPES.PropertySector,
      ALLOCATION_TYPES.Industry,
    ],
    name: 'Real Estate',
    icon: realEstate,
    component: RealEstate,
    subClassesIds: [],
    inMenu: true,
    pages: [
      { id: 'pnl', title: 'P&L' },
      { id: 'structure', title: 'STRUCTURE' },
      { id: 'income', title: 'INCOME' },
    ],
  },
  [ASSETS.realAssets]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Custodian,
      ALLOCATION_TYPES.Industry,
    ],
    name: 'Real Assets',
    icon: realAssets,
    component: RealAsset,
    subClassesIds: [],
    inMenu: true,
    pages: [
      { id: 'pnl', title: 'P&L' },
      { id: 'structure', title: 'STRUCTURE' },
      { id: 'income', title: 'INCOME' },
    ],
  },
  [ASSETS.cash]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
    ],
    name: 'Cash Balances',
    icon: cash,
    component: Cash,
    subClassesIds: [],
    inMenu: true,
  },
  [ASSETS.commodities]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
    ],
    name: 'Commodities',
    component: Commodities,
    subClassesIds: [],
    icon: commodities,
    inMenu: true,
  },
  [ASSETS.fixedIncome]: {
    allocations: [
      ALLOCATION_TYPES.AssetSubClass,
      ALLOCATION_TYPES.Region,
      ALLOCATION_TYPES.Currency,
      ALLOCATION_TYPES.Sectors,
      ALLOCATION_TYPES.Custodian,
      ALLOCATION_TYPES.CreditRating,
    ],
    name: 'Fixed Income',
    icon: credit,
    component: FixedIncome,
    subClassesIds: [],
    inMenu: true,
    pages: [
      { id: 'pnl', title: 'P&L' },
      { id: 'structure', title: 'STRUCTURE' },
      { id: 'pnlBreakdown', title: 'P&L BREAKDOWN' },
      { id: 'fundamentals', title: 'FUNDAMENTALS' },
      { id: 'income', title: 'INCOME' },
    ],
  },
};

export const ASSET_SUB_CLASSES_CONFIG: Record<string, IAssetSubClassOptions> = {
  // [SUB_ASSETS.options]: {
  //   allocations: [ALLOCATION_TYPES.Region, ALLOCATION_TYPES.Currency, ALLOCATION_TYPES.Sectors],
  //   name: 'Options',
  //   component: OptionsContainer,
  //   parentId: ASSETS.equity,
  //   inMenu: true,
  // },
  // [SUB_ASSETS.hedgeFund]: {
  //   allocations: [ALLOCATION_TYPES.Region, ALLOCATION_TYPES.Currency, ALLOCATION_TYPES.Sectors],
  //   name: 'Hedge Fund',
  //   component: HedgeFundContainer,
  //   parentId: ASSETS.alternatives,
  //   inMenu: true,
  // },
  // [SUB_ASSETS.privateEquity]: {
  //   allocations: [ALLOCATION_TYPES.Region, ALLOCATION_TYPES.Currency, ALLOCATION_TYPES.Sectors],
  //   name: 'Private Equity',
  //   component: PrivateEquityContainer,
  //   parentId: ASSETS.alternatives,
  //   inMenu: true,
  // },
  // [SUB_ASSETS.directInvestments]: {
  //   allocations: [ALLOCATION_TYPES.Region, ALLOCATION_TYPES.Currency, ALLOCATION_TYPES.Sectors],
  //   name: 'Direct Investments',
  //   component: DirectInvestmentsContainer,
  //   parentId: ASSETS.alternatives,
  //   inMenu: true,
  // },
};
