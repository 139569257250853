import cn from 'classnames';
import React, { PureComponent } from 'react';
import { Button } from '../../';
import s from '../Tabs.module.scss';

interface IProps {
  isActive: boolean;
  wrapContent?: boolean;
  label: string;
  onSelect: (idx: number | undefined) => void; // will be monkey patched in tabs component
  className?: string;
  tabIndex?: number | undefined;
  disabled?: boolean;
}

class Tab extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    isActive: false,
    label: '',
    onSelect: () => {},
    disabled: false,
  };

  onClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { isActive, tabIndex, onSelect } = this.props;

    if (isActive) {
      return;
    }
    e.preventDefault();
    onSelect(tabIndex);
  }

  render() {
    const { isActive, className, label, disabled } = this.props;

    return (
      <li className={cn(className, [s.tabsItem], { [s.active]: isActive })}>
        <Button
          className={cn([s.tabsBtn], { [s.active]: isActive })}
          type="button"
          variant="empty"
          onClick={(event) => {
            this.onClick(event);
          }}
          disabled={disabled}
        >
          {label}
        </Button>
      </li>
    );
  }
}

export default Tab;
