import * as React from 'react';
import s from '../../../../Dashboard.module.scss';
import { Card } from '@iliotech/storybook';
import { Widget } from '../../../../../../../../components';
import { DASHBOARD_PORTFOLIO_STRUCTURE_HELP_TEXT } from '../../../../../../../../../../services/constants/tooltipContextHelp';
import { ToggableChartLegend } from '../../../../../../../../../../components/ToggableChartLegend';
import ReactEcharts from 'echarts-for-react';
import useBarDataToggableLegend from '../../../../../../../../../../services/hooks/useBarDataToggableLegend';
import { ICurrencyFormatter } from '../../../../../../../../../../services/selectors/portfolio';
import { getHistoryChartOptions } from '../../../../../../../Reports/reportsChartOptions';

interface IProps {
  chartData: IBarData[];
  portfolioCurrencyFormatter: ICurrencyFormatter;
  selectedCurrency: string;
  period: IPeriod;
}

const BreakdownChart = ({ period, chartData, portfolioCurrencyFormatter, selectedCurrency }: IProps) => {
  const { toggledData, legend, onLegendItemClick } = useBarDataToggableLegend({
    chartData,
    portfolioCurrencyFormatter,
    selectedCurrency,
    customBarDataTotal: (current: IBarData): number => current.data[current.data.length - 1][1],
  });

  const historyOpt = getHistoryChartOptions(toggledData, portfolioCurrencyFormatter, selectedCurrency, period, true);

  return (
    <div className={s.defaultContainer}>
      <Card style={{ flex: 1, marginTop: 20 }}>
        <Widget
          title="Portfolio breakdown history"
          colNum={8}
          titleInfo={DASHBOARD_PORTFOLIO_STRUCTURE_HELP_TEXT.ChartPortfolioBreakdownHistory}
        >
          <ToggableChartLegend legend={legend} onItemPress={onLegendItemClick} />
          <ReactEcharts style={{ height: '420px', width: '100%', flexGrow: '1' }} option={historyOpt} notMerge={true} />
        </Widget>
      </Card>
    </div>
  );
};

export default BreakdownChart;
