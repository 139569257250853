import Pusher, { Channel } from 'pusher-js';
import React, { Fragment, FunctionComponent, PureComponent } from 'react';
import { AiFillWarning } from 'react-icons/ai';

import { PusherHandler } from '../../pusher-env-config';
import { PNL_CALCULATION_EVENT } from '../../services/constants/constants';
import { Info } from '../UIWidgets/Info';
import { Checkbox, Popover, Whisper } from 'rsuite';
import { useDispatch } from 'react-redux';
import { PortfolioActions } from '../../services/actions';
import { portfolio } from '../../scenes/Portfolio/scenes/Snapshot/components/SnapshotTable/__testdata__/sampleSnapshotData';
import { usePreferences } from '../../services/context/PreferencesContext';

export enum pnlStatus {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  AGNOSTIC = 'AGNOSTIC',
}

interface IProps {
  portfolioInfo: IPortfolio;
  debugging?: boolean;
  size?: number;
  modalActive?: boolean;
  onGreen?: () => void;
  id?: string;
  showIcon?: boolean;
}

interface IState {
  pnlStatus: pnlStatus;
  pnlModalShow: boolean;
}

const STATUS_COLOR: Record<pnlStatus, string> = {
  GREEN: '#41d9a0',
  YELLOW: '#ccc202',
  AGNOSTIC: '#ff6972'
};

const PnlStatus: FunctionComponent<IProps> = ({
                                                id,
                                                portfolioInfo,
                                                size = 20,
                                                modalActive = false,
                                                onGreen,
                                                showIcon = true
                                              }) => {
  const [status, setStatus] = React.useState<pnlStatus>(portfolioInfo.pnlStatus);
  const [pnlModalShow, setPnlModalShow] = React.useState(false);
  const { preferences } = usePreferences();
  const hidePortfolioUpdatingModal = preferences.misc?.hidePortfolioUpdatingModal;
  const dispatch = useDispatch();

  React.useEffect(() => {
    PusherHandler.subscribeToChannel(portfolioInfo.id);
    PusherHandler.addListener(portfolioInfo.id, PNL_CALCULATION_EVENT, (data: any) => {
      const message = data.message.toUpperCase().split(',')[0];
      setStatus(message);
      setPnlModalShow(message !== pnlStatus.GREEN);

      if (message === pnlStatus.GREEN) {
        onGreen?.();
        dispatch(PortfolioActions.fetchPortfolioInfo(portfolioInfo.id));
        // update portfolio
      }
    });

    return () => {
      PusherHandler.unbind(portfolioInfo.id);
    };
  }, [portfolioInfo]);

  React.useEffect(() => {
    setStatus(portfolioInfo.pnlStatus);
  }, [portfolioInfo.pnlStatus]);

  // return (
  //   <Info
  //     title="Updating your portfolio"
  //     text="Numbers are subject to change while this is in progress"
  //     confirmBtnText="Continue"
  //     info={true}
  //     icon={<AiFillWarning size={50} color={STATUS_COLOR[status]} />}
  //     onCancel={() => setPnlModalShow(false)}
  //   />
  // )

  return status !== pnlStatus.GREEN ? (
    <Fragment>
      {showIcon && (
        <Whisper placement={'bottom'} trigger={'hover'} speaker={<Popover>{'P&L update in progress'}</Popover>}>
          {/*<span data-tip="Update in progress.">*/}
          <AiFillWarning size={size} color={STATUS_COLOR[status]}/>
          {/*</span>*/}
        </Whisper>
      )}

      {
        pnlModalShow
        && modalActive
        && !hidePortfolioUpdatingModal
          ? (
            <Info
              title="Updating your portfolio"
              text="Numbers are subject to change while this is in progress"
              confirmBtnText="Continue"
              info={true}
              icon={<AiFillWarning size={50} color={STATUS_COLOR[status]}/>}
              onCancel={() => setPnlModalShow(false)}
              footerContent={<DontShowBox/>}
            />
          ) : null}
    </Fragment>
  ) : null;
};

const DontShowBox = () => {
  const { preferences, updateMiscPreference } = usePreferences();
  const hidePortfolioUpdatingModal = preferences.misc?.hidePortfolioUpdatingModal;
  // const [checked, setChecked] = React.useState(hidePortfolioUpdatingModal);
  const [working, setWorking] = React.useState(false);

  const updateValue = async (checked: boolean) => {
    setWorking(true);
    await updateMiscPreference('hidePortfolioUpdatingModal', checked);
    setWorking(false);
  };

  return (
    <div onClick={working ? undefined : () => updateValue(!hidePortfolioUpdatingModal)} style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: working ? 'wait' : 'pointer'
    }}>
      <span>Don't show this again </span><Checkbox checked={hidePortfolioUpdatingModal}
                                                   onChange={(val, checked) => updateValue(checked)}/>
    </div>
  );
};

export default PnlStatus;
