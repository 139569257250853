import { IRootState } from '../store';
import { createSelector } from 'reselect';

import { FormatHelper } from '../utils/FormatHelper';
import { getCurrency } from './currency';
import firstOrDefault from '../utils/firstOrDefault';

export function getPortfolioCurrencyCode(state: IRootState): string | null {
  const portfolio = state.portfolio.portfolioInfo.data;

  if (!portfolio) {
    return null;
  }
  if (portfolio.currency) {
    return portfolio.currency.name;
  }
  return null;
}

export function getPortfolioCurrency(state: IRootState): ICurrency | null {
  const portfolio = state.portfolio.portfolioInfo.data;
  if (!portfolio) {
    return null;
  }
  if (portfolio.currency) {
    return portfolio.currency;
  }
  return null;
}

export function getPortfolioBreakdownReport(state: IRootState, breakdown?: IBreakdownReport[]): IBreakdown {
  const breakdownReport = (breakdown ? breakdown : state.dashboard.profitReportBreakdon.data).sort(
    (a: IBreakdownReport, b: IBreakdownReport) => {
      return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
    }
  );

  const mtd = {
    price: 0,
    income: 0,
    fx: 0,
    realized: 0,
    total: 0,
  };

  const ytd = {
    price: 0,
    income: 0,
    fx: 0,
    realized: 0,
    total: 0,
  };

  const inception = {
    price: 0,
    income: 0,
    fx: 0,
    realized: 0,
    total: 0,
  };

  breakdownReport
    .map((a) => a.mtd)
    .forEach((el) => {
      mtd.price += el.price;
      mtd.income += el.income;
      mtd.fx += el.fx;
      mtd.realized += el.realized;
      mtd.total += el.total;
    });

  breakdownReport
    .map((a) => a.ytd)
    .forEach((el) => {
      ytd.price += el.price;
      ytd.income += el.income;
      ytd.fx += el.fx;
      ytd.realized += el.realized;
      ytd.total += el.total;
    });

  breakdownReport
    .map((a) => a.sinceInception)
    .forEach((el) => {
      inception.price += el.price;
      inception.income += el.income;
      inception.fx += el.fx;
      inception.realized += el.realized;
      inception.total += el.total;
    });

  const dailyTotal: number = breakdownReport.length ? breakdownReport.map((a) => a.daily).reduce((a, b) => a + b) : 0;

  return {
    data: breakdownReport,
    totals: {
      totalPnlDaily: dailyTotal,
      totalPnlMTD: mtd,
      totalPnlYTD: ytd,
      totalPnlInception: inception,
    },
  };
}

interface ICurrencyFormatterOpt {
  numberWithLetter?: boolean;
  currencyCode?: string;
  round?: number;
}
export type ICurrencyFormatter = (price?: number, opt?: ICurrencyFormatterOpt) => string;
export const getPortfolioCurrencyFormatter = createSelector<
  IRootState,
  ICurrency | null,
  ICurrency[],
  ICurrencyFormatter
>(getPortfolioCurrency, getCurrency, (currency: ICurrency | null, currencyList: ICurrency[]) => {
  return (price?: number, opt = {}) => {
    let formatter = FormatHelper.formatCurrencyShort;
    if (opt.numberWithLetter) {
      formatter = FormatHelper.prettierCurrencyValue;
    }
    const round = opt.round || 1;
    if (typeof price === 'number') {
      if (opt.hasOwnProperty('currencyCode') && opt.currencyCode) {
        const firstCurrency = firstOrDefault(currencyList, (c) => c.name === opt.currencyCode, undefined);
        if (firstCurrency) {
          return formatter(price, firstCurrency.symbol, round);
        }
        return formatter(price, null, round);
      }
      if (currency) {
        return formatter(price, currency.symbol, round);
      }
    }
    if (currency) {
      return currency.symbol;
    }
    return '';
  };
});
