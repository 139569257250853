import { createSelector } from 'reselect';
import { IRootState } from '../store';
import { IOption } from '../../components/UIWidgets/Autocomplete';
import { ITab } from '../../components/UIWidgets/Tabs.v2/types';
import { FormatHelper } from '../utils/FormatHelper';
import { NestedSet } from '../../components/NestedList/NestedSet';
import { create } from 'domain';

export function getAssetClasses(state: IRootState): IAssetClass[] {
  return state.assetsClasses.data;
}

export function getAssetSubClasses(state: IRootState): IAssetSubClass[] {
  return state.assetsSubClasses.data;
}

export const getAssetClassTabs = createSelector<IRootState, IAssetClass[], ITab[]>(
  getAssetClasses,
  (assetClasses: IAssetClass[]): ITab[] => {
    return assetClasses.map((assetClass, index) => {
      return {
        id: assetClass.id,
        label: FormatHelper.capitalize(assetClass.name),
        index,
      };
    });
  }
);

export const getAssetClassOptions = createSelector<IRootState, IAssetClass[], Array<IOption<IAssetClass>>>(
  getAssetClasses,
  (assetClasses: IAssetClass[]): Array<IOption<IAssetClass>> => {
    return assetClasses.map((assetClass) => {
      return {
        id: assetClass.id,
        value: assetClass,
        name: assetClass.name,
      };
    });
  }
);

export const getAssetSubClassOptions = createSelector<IRootState, IAssetSubClass[], Array<IOption<IAssetSubClass>>>(
  getAssetSubClasses,
  (assetSubClasses: IAssetSubClass[]): Array<IOption<IAssetSubClass>> => {
    return assetSubClasses.map((assetSubClass) => {
      return {
        id: assetSubClass.id,
        value: assetSubClass,
        name: assetSubClass.name,
      };
    });
  }
);

export const getNestedSetFromAssetClasses = createSelector<IRootState, IAssetClass[], NestedSet<any>>(
  getAssetClasses,
  (assetClasses: IAssetClass[]) => {
    return new NestedSet({
      id: 'root',
      group: assetClasses.map((assetClass: IAssetClass) => {
        return new NestedSet({
          id: assetClass.id,
          label: assetClass.name,
          data: assetClass,
          group: assetClass.assetSubClasses.map((assetSubClass: IAssetSubClass) => {
            return new NestedSet<any>({
              id: assetSubClass.id,
              label: assetSubClass.name,
              data: assetSubClass,
            });
          }),
        });
      }),
    });
  }
);
