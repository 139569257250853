import React, { FormEvent, Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { UserAvatar } from '../../components';
import { PageHeader } from '../../components/PageHeader';
import { renderInput } from '../../components/ReduxForm';
import { Button, FormFieldWrapper } from '../../components/UIWidgets';

import { PATHS } from '../../router/paths';
import { FORMS_NAME } from '../../services/constants/forms';
import { onlyLetters } from '../../services/utils/FormNormalize';
import { required } from '../../services/utils/FormValidations';
import s from './UserSettings.module.css';
import Notification from '../../components/UIWidgets/Notification/Notification';
import { NotificationType } from '../../models/NotifictionType';

interface IOwnProps {
  user: IUser | null;
  successMessage?: string;
  dismissNotification(): void;
  onCancel: () => void;
}

type IProps = IOwnProps & InjectedFormProps<IUser, IOwnProps>;

class UserSettings extends PureComponent<IProps> {
  componentDidMount(): void {
    const user = this.props.user;
    if (user && user.firstName && user.lastName) {
      this._setInitialFormValues(user);
    }
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { handleSubmit } = this.props;
    handleSubmit(event);
  };

  render() {
    const { pristine, invalid, onCancel, successMessage, dismissNotification } = this.props;
    return (
      <Fragment>
        <PageHeader title="Account settings" />

        <form className={s.formWrapper} onSubmit={this.handleSubmit}>
          <div className={s.formContent}>
            <div className={s.avatarWrapper}>
              <UserAvatar user={this.props.user} size={180} />
            </div>
            <div className={s.fieldsWrapper}>
              <FormFieldWrapper className={s.fieldWrapper} label="First name">
                <Field
                  name="firstName"
                  type="text"
                  component={renderInput}
                  validate={[required]}
                  normalize={onlyLetters}
                />
              </FormFieldWrapper>
              <FormFieldWrapper className={s.fieldWrapper} label="Last name">
                <Field
                  name="lastName"
                  type="text"
                  component={renderInput}
                  validate={[required]}
                  normalize={onlyLetters}
                />
              </FormFieldWrapper>
              <Link
                to={{
                  pathname: PATHS.account.password.path,
                }}
              >
                Change password
              </Link>
            </div>
          </div>
          <div className="separator" />
          <div className={s.formFooter}>
            <Button className={s.formButton} size="small" variant="outline" onClick={() => onCancel()} active={false}>
              Cancel
            </Button>
            <Button className={s.formButton} size="small" type="submit" disabled={invalid || pristine}>
              Confirm
            </Button>
          </div>
        </form>
        {successMessage && (
          <Notification
            notification={{ text: successMessage, type: NotificationType.SUCCESS }}
            outSideClick={dismissNotification}
          />
        )}
      </Fragment>
    );
  }

  private _setInitialFormValues = (user: IUser) => {
    this.props.initialize({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      status: user.status,
      role: user.role,
    });
  };
}

export default reduxForm<IUser, IOwnProps>({
  form: FORMS_NAME.user,
})(UserSettings);
