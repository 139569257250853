import { IActionPayload } from '../../../models/redux/IActionPayload';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { PERIOD_TYPE } from '../../constants/constants';
import { PeriodHelper } from '../../utils/PeriodHelper';
import { ReduxHelper } from '../../utils/ReduxHelper';
import { BarType } from '../../constants/charts';

const INITIAL_INCOME_YIELDTOMATURITY_STATE: any = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
  period: { ...PeriodHelper.getPeriod(PERIOD_TYPE.INCEPTION), type: PERIOD_TYPE.INCEPTION },
  currency: 'All',
};

const incomeYieldToMaturityReducerDefault = ReduxHelper.createHttpRequestWithPeriodReducer<
  ILoadingPeriodDataState<IBarData[]>
>(
  INITIAL_INCOME_YIELDTOMATURITY_STATE,
  DashboardActions.FIXED_INCOME_YIELD_REQUEST,
  DashboardActions.FIXED_INCOME_YIELD_SUCCESS,
  DashboardActions.FIXED_INCOME_YIELD_ERROR,
  DashboardActions.FIXED_INCOME_YIELD_RESET
);

const incomeYieldToMaturityReducer = (state = INITIAL_INCOME_YIELDTOMATURITY_STATE, action: IActionPayload) => {
  switch (action.type) {
    case DashboardActions.FIXED_INCOME_YIELD_CHANGE_PERIOD: {
      return {
        ...state,
        period: action.payload.period,
        currency: action.payload.currency,
      };
    }
    case DashboardActions.FIXED_INCOME_YIELD_SUCCESS: {
      return {
        ...state,
        data: [
          {
            ...action.payload,
            name: 'Repayments',
            type: BarType.Line,
          },
        ],
      };
    }
    default:
      return incomeYieldToMaturityReducerDefault(state, action);
  }
};

export default incomeYieldToMaturityReducer;
