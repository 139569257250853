import React, { ChangeEvent, FC } from 'react';
import cn from 'classnames';
import s from './FXPair.module.css';

import FXBuyLeg from './FXBuyLeg';
import { FormUpdater, IFXFormValues } from '../../Forms/FX/types';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { floatNumberV2, positiveNumber } from '../../../../../../../../services/utils/FormNormalize';
import { Field } from 'redux-form';
import FXSellLeg from './FXSellLeg';

interface IProps {
  formValues: IFXFormValues;
  updateFormValue: FormUpdater;
  getFxRate(buy?: string, sell?: string): void;
  editMode: boolean;
}

export const round = (value: number) => parseFloat(String(positiveNumber(floatNumberV2(value, 6))));

const processNumber = (wrapperFunction: (val: number) => void) => (e: ChangeEvent<HTMLInputElement>) => {
  wrapperFunction(parseFloat(e.target.value));
};

const FXPair: FC<IProps> = ({ updateFormValue, formValues, getFxRate, editMode }) => {
  function updateBuyQuantity(value: number) {
    const fromToFxRate = parseFloat(String(formValues.fromToFxRate ?? '0'));
    updateFormValue('buyQuantity', value);
    updateFormValue('sellQuantity', round(value * fromToFxRate));
  }

  function updateSellQuantity(value: number) {
    const buyQuantity = parseFloat(String(formValues.buyQuantity ?? '0'));

    updateFormValue('sellQuantity', value);
    updateFormValue('fromToFxRate', round(value / buyQuantity));
    updateFormValue('toFromFxRate', round(buyQuantity / value));
  }

  function updateFromToFxRate(value: number) {
    const buyQuantity = parseFloat(String(formValues.buyQuantity ?? '0'));
    updateFormValue('fromToFxRate', value);
    updateFormValue('toFromFxRate', round(1 / value));
    updateFormValue('sellQuantity', round(buyQuantity * value));
  }

  function updateToFromFxRate(value: number) {
    const buyQuantity = parseFloat(String(formValues.buyQuantity ?? '0'));
    updateFormValue('fromToFxRate', round(1 / value));
    updateFormValue('toFromFxRate', value);
    updateFormValue('sellQuantity', round(buyQuantity / value));
  }

  function updateBuyCurrency(obj: { value: string }) {
    const value = obj?.value;
    const sellCurrency = formValues.sellCurrency?.value;
    if (!sellCurrency || !value) {
      return;
    }
    getFxRate(value, sellCurrency);
  }

  function updateSellCurrency(obj: { value: string }) {
    const value = obj?.value;
    const buyCurrency = formValues.buyCurrency?.value;
    if (!buyCurrency || !value) {
      return;
    }
    getFxRate(buyCurrency, value);
  }

  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.horizontal)}>
        <FXBuyLeg
          editMode={editMode}
          onCurrencyChange={updateBuyCurrency}
          onQuantityChange={processNumber(updateBuyQuantity)}
        />
        <div className={cn(s.middle)} />
        <FXSellLeg
          editMode={editMode}
          onCurrencyChange={updateSellCurrency}
          onQuantityChange={processNumber(updateSellQuantity)}
        />
      </div>
      <div className={cn(s.horizontal)} style={{ marginTop: 10 }}>
        <div className={cn(s.side, s.rightAlign)}>Buy 1 {formValues.buyCurrency?.value} for</div>
        <div className={cn(s.middle)}>
          <Field
            placeholder="0"
            name={`fromToFxRate`}
            type="text"
            theme="inverse"
            component={renderInput}
            onChange={processNumber(updateFromToFxRate)}
            className="input--small"
            normalize={(value: number) => positiveNumber(floatNumberV2(value, 6)).toString()}
          />
        </div>
        <div className={cn(s.side)}>{formValues.sellCurrency?.value}</div>
      </div>
      <div className={cn(s.horizontal)}>
        <div className={cn(s.side, s.rightAlign)}>Sell 1 {formValues.sellCurrency?.value} for</div>
        <div className={cn(s.middle)}>
          <Field
            placeholder="0"
            name={`toFromFxRate`}
            type="text"
            theme="inverse"
            component={renderInput}
            onChange={processNumber(updateToFromFxRate)}
            className="input--small"
            normalize={(value: number) => positiveNumber(floatNumberV2(value, 6)).toString()}
          />
        </div>
        <div className={cn(s.currencyLabel)}>{formValues.buyCurrency?.value}</div>
      </div>
    </div>
  );
};

export default FXPair;
