import React, { FC } from 'react';
import CashAdjustmentEntry, { ICashAdjustmentFormValues } from './CashAdjustmentEntry';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { IRootState } from '../../../../../../../../services/store';

type Props = IAssetEntryComponentProps<ICashAdjustmentFormValues>;
type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

const CashAdjustmentEditContainer: FC<Props> = ({ editedUnconfirmedTrade, ...props }) => {
  return (
    <CashAdjustmentEntry editMode={true} {...props} initialValues={getInitialValuesFromTrade(editedUnconfirmedTrade)} />
  );
};

const getInitialValuesFromTrade = (t?: UnconfirmedTrade) => {
  if (typeof t === 'undefined') {
    return {};
  }
  const { instrument, custodian, tradeType, quantity, price, fxRate, operation, currencyDetails, key } = t!.trade;

  const currencyName = currencyDetails?.name ?? '';

  const values: Partial<ICashAdjustmentFormValues> = {
    ...t.trade,
    instrument,
    currency: {
      id: currencyName,
      value: currencyName,
      name: currencyName,
    },
    custodian,
    key,
    feeOperation: operation as 'DEDUCTION' | 'REBATE',
    amount: quantity,
    fxRate,
    tradeType,
    // adjustmentOperation: quantity >= 0 ? 'DEDUCTION' : 'REBATE',
  };

  return values;
};

export default CashAdjustmentEditContainer;
