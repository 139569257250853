import React, { FunctionComponent } from 'react';
import DynamicForm from '../../../../components/DynamicForm/DynamicForm';
import { FormContextValues } from 'react-hook-form';

interface IProps {
  onSubmit: (values: any) => void;
  assetClassId: AssetClassId;
  formContext: FormContextValues;
  defaultValues: any;
  mode: EditMode;
  groups: FormGroup[];
}

const UnlistedModifyForm: FunctionComponent<IProps> = ({
  onSubmit,
  assetClassId,
  formContext,
  defaultValues,
  groups,
}) => {
  return (
    <DynamicForm
      {...{
        formContext,
        onSubmit,
        submitButton: false,
        groups,
        assetClassId,
        defaultValues,
      }}
    />
  );
};

export default UnlistedModifyForm;
