import { AxiosPromise } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { formValueSelector } from 'redux-form';
import { IOption } from '../../../components/UIWidgets/Autocomplete';

import { PATHS } from '../../../router/paths';
import { CustodianActions, PortfolioActions } from '../../../services/actions';
import { AppActions } from '../../../services/actions/AppActions';
import { PortfolioIndexActions } from '../../../services/actions/PortfolioIndexActions';
import { FORMS_NAME } from '../../../services/constants/forms';
import { getPortfolioCurrencyOptions } from '../../../services/selectors/portfolioCurrency';
import { getPortfolioIndexOptions } from '../../../services/selectors/portfolioIndex';
import { IRootState } from '../../../services/store';
import { AsyncActionDispatch } from '../../../services/utils/ReduxHelper';
import { IPortfolioSettingsFormData } from './models';
import PortfolioSettingsForm from './PortfolioSettingsForm';
import { NotificationType } from '../../../models/NotifictionType';
import { useAccount } from '../../../services/context/AuthContext';

interface IMapStateToProps {
  authInfo: IAuthInfo | null;
  currencyOptions: Array<IOption<ICurrency>>;
  performanceIndexOptions: Array<IOption<IIndex>>;
  custodiansFromServer: ICustodian[];
  portfolioType: string | null;
  portfolioInfo: IPortfolio | null;
  portfolioCustodians: ICustodian[];
}

interface IDispatchToProps {
  updatePortfolioSettings: (formData: any) => AxiosPromise<IPortfolio>;
  fetchPortfolioIndex: () => AxiosPromise<IIndex[]>;
  fetchPortfolioInfo: (portfolioId: string) => AxiosPromise<IPortfolio>;
  showNotification: (notification: INotification) => void;
  fetchCustodians: () => AxiosPromise<ICustodian[]>;
  fetchPortfolioCustodians: (portfolioId: string) => AxiosPromise<ICustodian[]>;
}

type IProps = IDispatchToProps & IMapStateToProps & RouteComponentProps<{ portfolioId: string }>;

const PortfolioSettingsFormContainer = React.memo((props: IProps) => {
  const {
    portfolioInfo,
    fetchCustodians,
    fetchPortfolioIndex,
    fetchPortfolioInfo,
    match,
    fetchPortfolioCustodians,
  } = props;
  const portfolioId = match.params.portfolioId;
  React.useEffect(() => {
    fetchCustodians();
    fetchPortfolioIndex();
    fetchPortfolioInfo(portfolioId);
    fetchPortfolioCustodians(portfolioId);
  }, [portfolioId]);
  const { userCapabilities } = useAccount();

  const handleSubmitForm = (formValues: IPortfolioSettingsFormData) => {
    const { authInfo, history } = props;
    const serverData = {
      ...props.portfolioInfo,
      name: formValues.name,
      benchmarkIndex: formValues.benchmarkIndex.value,
      onlyManualDividendsDefault: formValues.onlyManualDividendsDefault,
      custodians: formValues.userPortfolioCustodians.map((c) => ({
        name: c.name,
        code: c.code,
        onlyManualDividends: c.onlyManualDividends,
        default: c.default,
        id: c.id,
      })),
      riskAssetView: formValues.defaultRiskAssetView,
    };

    props
      .updatePortfolioSettings(serverData)
      .then(() => fetchPortfolioInfo(portfolioInfo!.id))
      .then((data) => data.data)
      .then((updated: IPortfolio) => {
        if (!authInfo) {
          return;
        }
        fetchPortfolioCustodians(portfolioInfo!.id);
        history.push(PATHS.portfolio.dashboardNew.path.replace(':portfolioId', updated.id));
        props.showNotification({ text: 'Portfolio has been updated', type: NotificationType.SUCCESS });
      });
  };

  const portfolioCustodians = props.portfolioCustodians;
  if (portfolioCustodians.length === 1) {
    portfolioCustodians[0].default = true;
  }

  if (!portfolioInfo) {
    return null;
  }

  return (
    <PortfolioSettingsForm
      onSubmit={handleSubmitForm}
      currencyOptions={props.currencyOptions}
      performanceIndexOptions={props.performanceIndexOptions}
      portfolioType={props.portfolioType}
      listOfAllCustodians={props.custodiansFromServer}
      authInfo={props.authInfo}
      portfolioCustodians={props.portfolioCustodians}
      portfolioInfo={props.portfolioInfo}
      initialValues={{
        name: portfolioInfo ? portfolioInfo.name : '',
        benchmarkIndex: portfolioInfo ? portfolioInfo.benchmarkIndex : {},
        userPortfolioCustodians: portfolioCustodians,
        onlyManualDividendsDefault:
          portfolioInfo?.onlyManualDividendsDefault ??
          userCapabilities?.preferences?.onlyManualDividendsDefault ??
          false,
        defaultRiskAssetView: portfolioInfo?.riskAssetView ?? userCapabilities?.preferences?.defaultRiskAssetView,
      }}
      showNotification={props.showNotification}
    />
  );
});

const mapStateToProps = (state: IRootState): IMapStateToProps => {
  return {
    portfolioType: selector(state, 'type'),
    authInfo: state.app.authInfo.data,
    currencyOptions: getPortfolioCurrencyOptions(state),
    custodiansFromServer: state.custodians.data,
    performanceIndexOptions: getPortfolioIndexOptions(state),
    portfolioInfo: state.portfolio.portfolioInfo.data,
    portfolioCustodians: state.portfolio.portfolioCustodians.data,
  };
};

const mapDispatchToProps = (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updatePortfolioSettings: (formValues: any) => dispatch(PortfolioActions.updatePortfolioSettings(formValues)),
  fetchPortfolioIndex: () => dispatch(PortfolioIndexActions.fetchPortfolioIndex()),
  fetchPortfolioInfo: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioInfo(portfolioId)),
  fetchPortfolioCustodians: (portfolioId: string) => dispatch(PortfolioActions.fetchPortfolioCustodians(portfolioId)),
  showNotification: (notification: INotification) => dispatch(AppActions.showNotification(notification)),
  fetchCustodians: () => dispatch(CustodianActions.fetchCustodiansList()),
});

const selector = formValueSelector(FORMS_NAME.portfolio);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioSettingsFormContainer));
