import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Button } from '../../../../../../components/UIWidgets/Button';
import { FormFieldWrapper } from '../../../../../../components/UIWidgets/FormFieldWrapper';
import { renderInput, renderNestedSelect } from '../../../../../../components/ReduxForm';

import { FORMS_NAME } from '../../../../../../services/constants/forms';

import s from './InstrumentForm.module.css';
import renderAutocomplete from '../../../../../../components/ReduxForm/renderAutocomplete';
import { validate } from './validate';
import { IOption } from '../../../../../../components/UIWidgets/Autocomplete';

import firstOrDefault from '../../../../../../services/utils/firstOrDefault';

import { UserRole } from '../../../../../../services/constants/constants';
import { NestedSet } from '../../../../../../components/NestedList/NestedSet';
import { ISelectHandlerProps } from '../../../../../../components/NestedSelect/NestedSelect';
import { IFieldISelectHandlerCallbackOptions } from '../../../../../../components/ReduxForm/renderNestedSelect';

interface IOwnProps {
  instrument: IInstrument | null;
  currencyOptions: Array<IOption<ICurrency>>;
  industryOptions: Array<IOption<IIndustry>>;
  countryOptions: Array<IOption<ICountry>>;
  userListOptions: Array<IOption<IUser>>;
  authInfo: IAuthInfo | null;
  searchUser: (search: string) => void;
  nestedSetFromAssetClasses: NestedSet<any>;
}

export interface IFormValues {
  id?: string;
  name: string;
  code: string;
  assetSubClass: NestedSet<IAssetSubClass> | null;
  industry: IOption<IIndustry> | null;
  currency: IOption<ICurrency> | null;
  country: IOption<ICountry> | null;
  note: string;
  user?: IOption<IUser> | null;
}

export type IProps = IOwnProps & InjectedFormProps<IFormValues, IOwnProps>;

function defaultFilter<T>(filter: string, option: IOption<T>) {
  return option.name.toLowerCase().includes(filter.toLowerCase());
}

const InstrumentForm: FunctionComponent<IProps> = (props: IProps) => {
  const {
    invalid,
    instrument,
    currencyOptions,
    industryOptions,
    countryOptions,
    userListOptions,
    authInfo,
    nestedSetFromAssetClasses,
  } = props;

  const [isUserDisabled, setUserDisable] = useState(false);

  const handlerSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { handleSubmit } = props;
      handleSubmit(event);
    },
    [props.handleSubmit]
  );

  const selectAssetClass = useCallback(
    ({ nestedSet, indexes }: ISelectHandlerProps): IFieldISelectHandlerCallbackOptions => {
      if (!indexes) {
        return {
          isVisible: undefined,
          selectedSet: null,
        };
      }
      const selectedSet = nestedSet.getByIndexes(indexes);
      if (!selectedSet) {
        return {
          isVisible: undefined,
          selectedSet: null,
        };
      }

      if ('assetClassId' in selectedSet.data) {
        return {
          selectedSet,
          isVisible: false,
        };
      }

      return {
        selectedSet: null,
        isVisible: undefined,
      };
    },
    []
  );

  useEffect(() => {
    if (!instrument || !currencyOptions || !countryOptions) {
      return;
    }
    const currencyOption = firstOrDefault<IOption<ICurrency>>(
      currencyOptions,
      (option) => option.id === instrument.currency.id
    );
    let industryOption = null;
    if (instrument.industrySector) {
      industryOption = firstOrDefault<IOption<IIndustry>>(
        industryOptions,
        (option) => option.id === instrument.industrySector.id
      );
    }
    let countryOption = null;
    if (instrument.country) {
      countryOption = firstOrDefault<IOption<ICountry>>(countryOptions, (option) => option.name === instrument.country);
    }

    const user = instrument.user;
    let userOption = null;
    if (userListOptions && user && user.id) {
      const userId = user.id;
      userOption = firstOrDefault<IOption<IUser>>(userListOptions, (option) => option.id === userId);
      setUserDisable(true);
    }

    const assetSubClass = nestedSetFromAssetClasses.getFirstByPredicate((nestedSet) => {
      return (
        nestedSet.data.assetClassId === instrument.assetClass.id && nestedSet.data.id === instrument.assetSubClass.id
      );
    });

    const initialValues: IFormValues = {
      name: instrument.name,
      code: instrument.code,
      assetSubClass,
      industry: industryOption,
      currency: currencyOption,
      country: countryOption,
      note: instrument.description || '',
      user: userOption,
    };

    if (instrument.id) {
      initialValues.id = instrument.id;
    }

    props.initialize(initialValues);
  }, [instrument, currencyOptions, industryOptions, countryOptions, userListOptions]);

  return (
    <form onSubmit={handlerSubmit} className={s.instrumentForm}>
      <FormFieldWrapper label="Instrument name" required={true}>
        <Field name="name" type="text" component={renderInput} className="input--small" theme="inverse" />
      </FormFieldWrapper>
      <FormFieldWrapper label="Code" required={true}>
        <Field name="code" type="text" component={renderInput} className="input--small" theme="inverse" />
      </FormFieldWrapper>
      <FormFieldWrapper label="Asset SubClass" required={true}>
        <Field
          name="assetSubClass"
          component={renderNestedSelect}
          selectSetHandler={selectAssetClass}
          nestedSet={nestedSetFromAssetClasses}
          textSelector={(nestedSet: NestedSet<any> | null) => (nestedSet ? nestedSet.data.name : '')}
        />
      </FormFieldWrapper>
      <FormFieldWrapper label="Currency" required={true}>
        <Field
          name="currency"
          component={renderAutocomplete}
          options={currencyOptions}
          filter={defaultFilter}
          inputProps={{
            className: 'input--small',
          }}
        />
      </FormFieldWrapper>
      <FormFieldWrapper label="Industry" required={true}>
        <Field
          name="industry"
          type="text"
          options={industryOptions}
          filter={defaultFilter}
          component={renderAutocomplete}
          inputProps={{
            className: 'input--small',
          }}
        />
      </FormFieldWrapper>
      <FormFieldWrapper label="Country" required={true}>
        <Field
          name="country"
          component={renderAutocomplete}
          options={countryOptions}
          filter={defaultFilter}
          inputProps={{
            className: 'input--small',
          }}
        />
      </FormFieldWrapper>
      <FormFieldWrapper label="Note" className={s.noteField}>
        <Field name="note" type="text" component={renderInput} className="input--small" theme="inverse" />
      </FormFieldWrapper>
      {authInfo && (authInfo.role === UserRole.ADMIN || authInfo.role === UserRole.MANAGER) && (
        <FormFieldWrapper label="User" required={true}>
          <Field
            name="user"
            component={renderAutocomplete}
            filter={defaultFilter}
            options={userListOptions}
            inputProps={{
              className: 'input--small',
              disabled: isUserDisabled,
            }}
          />
        </FormFieldWrapper>
      )}
      {instrument && (
        <div className={s.formFooter}>
          <Button type="submit" size="small" disabled={invalid}>
            Confirm
          </Button>
        </div>
      )}
    </form>
  );
};

export default reduxForm<IFormValues, IOwnProps>({
  form: FORMS_NAME.instrument,
  validate,
})(React.memo(InstrumentForm));
