import { usePeriod } from '../../../../../../services/context/PeriodContext';
import { usePortfolioInfo } from '../../../../../../services/hooks/usePortfolioInfo';
import { useCustodiansFilter } from '../../../../../../services/hooks/useCustodiansFilter';
import { Card } from '@iliotech/storybook';
import { SummaryPerformanceChart } from '../../../Dashboard/scenes/PortfolioDashboard/components/SummaryPerformanceChart';
import React from 'react';
import { ToggleLeftRight } from '../../../../../../components';
import { DashboardTitle } from '../../../../../../components/UIWidgets/DashboardTitle/DashboardTitle';

export const PortfolioPerformance = () => {
  const { period } = usePeriod();
  const portfolio = usePortfolioInfo();
  const { portfolioInfo, portfolioCurrencyFormatter } = portfolio;
  const [performanceTogglePosition, setPerformanceTogglePosition] = React.useState<0 | 1>(0);

  const portfolioId = portfolioInfo.data?.id;
  const baseCurrency = portfolioInfo.data?.currency;
  if (!portfolioId) {
    return null;
  }

  const handleToggleProfitVsPerformance = () => {
    setPerformanceTogglePosition((prev) => (prev === 0 ? 1 : 0));
  };

  return (
    <Card
      style={{
        height: 400,
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <DashboardTitle
        title={performanceTogglePosition ? 'Net Worth vs Investment' : 'Performance vs Benchmark'}
        style={{ marginBottom: 10 }}
      />
      <SummaryPerformanceChart
        period={period}
        portfolioId={portfolioId!}
        portfolioCurrencyFormatter={portfolioCurrencyFormatter}
        hidePeriodSelector={true}
        performanceToggle={performanceTogglePosition}
        selectedCustodians={[]}
        height={400}
      />
      <div style={{ position: 'absolute', top: '0.5rem', right: 40 }}>
        <ToggleLeftRight
          leftLabel={'Performance (%)'}
          rightLabel={'Value (' + baseCurrency?.symbol + ')'}
          clickFunction={handleToggleProfitVsPerformance}
          toggleCurrentPosition={!performanceTogglePosition}
        />
      </div>
    </Card>
  );
};
