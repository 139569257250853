import cn from 'classnames';
import React, { FC, PureComponent, ReactNode } from 'react';
import { Button } from '../Button';
import s from './Confirm.module.scss';

export type IConfirmOptions = IProps;

interface IProps {
  title?: string;
  text?: string;
  confirmBtnText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  info?: boolean;
  children?: ReactNode;
  disabled?: boolean;
}

const Confirm: FC<IProps> = ({
  confirmBtnText = 'Confirm',
  title = 'Are you sure?',
  text = '',
  onCancel,
  onConfirm,
  info,
  children,
  disabled,
}) => {
  const confirmRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    confirmRef.current?.focus();
  }, [confirmRef.current]);

  return (
    <div className={s.confirmWrapper}>
      <div className={s.confirmWindow}>
        <div className={s.confirmWindowTitle}>{title}</div>
        <div className={cn('text-xs', s.confirmWindowText)} dangerouslySetInnerHTML={{ __html: text }} />
        {children ? <div className={s.childrenContainer}>{children}</div> : null}
        <footer className={s.confirmWindowFooter}>
          {onCancel && (
            <Button variant="outline" className={s.cancelBtn} size="small" onClick={onCancel} active={false}>
              Cancel
            </Button>
          )}
          {onConfirm && (
            <div ref={confirmRef}>
              <Button
                disabled={disabled}
                className={cn(s.confirmBtn, { [s.infoBtn]: info })}
                size="small"
                onClick={onConfirm}
              >
                {confirmBtnText}
              </Button>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

export default React.memo(Confirm);
