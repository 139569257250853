import { BRAND_PRIMARY_ACCENT, BRAND_WARN, CHART_COLORS } from './Colors';
import { ASSET_COLORS, ASSET_LABEL_NAMES } from '../../../../../../../services/constants/assetClasses';
import { mayConcatErrorLabel } from './mayConcatErrorLabel';

export const getScatterChartData = (
  serverData: ScatterChartData,
  benchmarkSelected: boolean,
  portfolioSelected: boolean,
  assetIndex: Record<string, number>
): VolatilityScatterData => {
  const benchmarkData = serverData.benchmark;
  const portfolioData = serverData.portfolio;
  let volatilityBounds: [number, number] = [0, 0];
  let returnBounds: [number, number] = [0, 0];

  const benchmark: VolatilityScatterPoint = {
    return: benchmarkData.periodReturn,
    volatility: benchmarkData.periodVolatility,
    marketValue: '' as any,
    key: 'benchmark',
    label: benchmarkData.indexName.split('.')[0],
    color: BRAND_WARN,
    shape: 'rhombus',
  };

  if (benchmarkSelected) {
    volatilityBounds = [
      Math.min(volatilityBounds[0], benchmark.volatility),
      Math.max(volatilityBounds[1], benchmark.volatility),
    ];
    returnBounds = [Math.min(returnBounds[0], benchmark.return), Math.max(returnBounds[1], benchmark.return)];
  }

  const portfolio: VolatilityScatterPoint = {
    return: portfolioData.periodReturn,
    volatility: portfolioData.periodVolatility,
    marketValue: portfolioData.totalMarketValueBase,
    key: 'portfolio',
    label: 'Portfolio',
    color: BRAND_PRIMARY_ACCENT,
  };

  if (portfolioSelected) {
    volatilityBounds = [
      Math.min(volatilityBounds[0], portfolio.volatility),
      Math.max(volatilityBounds[1], portfolio.volatility),
    ];
    returnBounds = [Math.min(returnBounds[0], portfolio.return), Math.max(returnBounds[1], portfolio.return)];
  }

  let i = 0;

  const assetClasses: VolatilityScatterPoint[] = serverData.assets
    .filter((asset) => asset.periodVolatility)
    .map((asset) => {
      volatilityBounds = [
        Math.min(volatilityBounds[0], asset.periodVolatility),
        Math.max(volatilityBounds[1], asset.periodVolatility),
      ];
      returnBounds = [Math.min(returnBounds[0], asset.periodReturn), Math.max(returnBounds[1], asset.periodReturn)];
      return {
        return: asset.periodReturn,
        volatility: asset.periodVolatility,
        marketValue: asset.totalMarketValueBase,
        key: asset.assetClass,
        label: ASSET_LABEL_NAMES[asset.assetClass] ?? asset.assetClass,
        color: ASSET_COLORS[asset.assetClass] ?? CHART_COLORS[i++ % CHART_COLORS.length],
      };
    });

  const topPositions: VolatilityScatterPoint[] = serverData.positions
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((position) => (position as ValidPosition).periodVolatility)
    .map((instrument, index) => {
      volatilityBounds = [
        Math.min(volatilityBounds[0], (instrument as ValidPosition).periodVolatility),
        Math.max(volatilityBounds[1], (instrument as ValidPosition).periodVolatility),
      ];
      returnBounds = [
        Math.min(returnBounds[0], (instrument as ValidPosition).periodReturn),
        Math.max(returnBounds[1], (instrument as ValidPosition).periodReturn),
      ];

      return {
        shape: 'square',
        label: instrument.name,
        color: ASSET_COLORS[instrument.assetClass] ?? CHART_COLORS[i++ % CHART_COLORS.length],
        index: assetIndex[instrument.name].toString(),
        key: `key-${instrument.name}`,
        return: (instrument as ValidPosition).periodReturn || ('' as any),
        volatility: (instrument as ValidPosition).periodVolatility || ('' as any),
        marketValue: instrument.exposureBase,
        activeFor: instrument.activeFor,
      };
    });

  if (volatilityBounds[0] > 0) {
    volatilityBounds[0] = 0;
  }

  if (returnBounds[0] > 0) {
    returnBounds[0] = 0;
  }

  return {
    benchmark,
    portfolio,
    assetClasses,
    topPositions,
    volatilityBounds,
    returnBounds,
  };
};
