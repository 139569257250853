import { IRootState } from '../../../../../../../../../services/store';

export function getPortfolioBreakdownReport(breakdownReport: IBreakdownReport[]): IBreakdown {
  const data = breakdownReport.sort((a: IBreakdownReport, b: IBreakdownReport) => {
    return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
  });

  const mtd = {
    price: 0,
    income: 0,
    fx: 0,
    realized: 0,
    total: 0,
  };

  const ytd = {
    price: 0,
    income: 0,
    fx: 0,
    realized: 0,
    total: 0,
  };

  const inception = {
    price: 0,
    income: 0,
    fx: 0,
    realized: 0,
    total: 0,
  };

  data
    .map((a) => a.mtd)
    .forEach((el) => {
      mtd.price += el.price;
      mtd.income += el.income;
      mtd.fx += el.fx;
      mtd.realized += el.realized;
      mtd.total += el.total;
    });

  data
    .map((a) => a.ytd)
    .forEach((el) => {
      ytd.price += el.price;
      ytd.income += el.income;
      ytd.fx += el.fx;
      ytd.realized += el.realized;
      ytd.total += el.total;
    });

  data
    .map((a) => a.sinceInception)
    .forEach((el) => {
      inception.price += el.price;
      inception.income += el.income;
      inception.fx += el.fx;
      inception.realized += el.realized;
      inception.total += el.total;
    });

  const dailyTotal: number = data.length ? data.map((a) => a.daily).reduce((a, b) => a + b) : 0;

  return {
    data,
    totals: {
      totalPnlDaily: dailyTotal,
      totalPnlMTD: mtd,
      totalPnlYTD: ytd,
      totalPnlInception: inception,
    },
  };
}
