import React, { FC } from 'react';
import cn from 'classnames';
import s from '../../Transaction.module.scss';
import { Field } from 'redux-form';
import { renderAutocomplete } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import { IAutocompleteProps } from '../../../../../../../../components/UIWidgets/Autocomplete/Autocomplete';
import { InputProps } from 'react-select';

interface IProps {
  instrumentOptions: Array<IOption<IAsset>>;
  disabled?: boolean;
  label?: string;
  isLoading?: boolean;
  onTyping?(text: string): void;
  onChange?(val: any): void;
}

const AssetSearch: FC<IProps> = ({ onTyping, instrumentOptions, disabled, label = 'Code', onChange, isLoading  }) => {
  return (
    <FormFieldWrapper label={label} className={cn(s.nameField, s.fieldsWrapper, s.underlyingField)} required={true}>
      <Field
        isLoading={isLoading}
        name={'instrument'}
        // loadOptions={loadOptions}
        options={instrumentOptions}
        component={renderAutocomplete}
        disabled={disabled}
        onChange={onChange}

        onTyping={onTyping}
        filterOption={() => true}
        format={(value: any) => {
          if (!value || (value && value.name === null)) {
            return 'none';
          }
          return {
            ...value,
            name: `${value.name} ${value.code ? `[${value.code}${value.currency ? ` - ${value.currency}` : ''}]` : ''}`,
          };
        }}
        inputProps={{
          className: 'input--small',
        }}
      />
    </FormFieldWrapper>
  );
};

export default AssetSearch;
