import React, { FC } from 'react';
import s from '../../Transaction.module.scss';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderInput } from '../../../../../../../../components/ReduxForm';
import { FormFieldWrapper } from '../../../../../../../../components/UIWidgets/FormFieldWrapper';
import { connect, useDispatch } from 'react-redux';
import { number, required } from '../../../../../../../../services/utils/FormValidations';
import { AsyncActionDispatch } from '../../../../../../../../services/utils/ReduxHelper';
import { IRootState } from '../../../../../../../../services/store';
import { IOption } from '../../../../../../../../components/UIWidgets/Autocomplete';
import Amount from '../../components/ConnectedTransactionInputs/Amount';
import FXRate from '../../components/ConnectedTransactionInputs/FXRate';
import Custodian from '../../components/ConnectedTransactionInputs/Custodian';
import { renderToggleBtns } from '../../components';
import { SnapshotActions } from '../../../../../../../../services/actions';
import { AxiosPromise } from 'axios';
import TradeTime from '../../components/ConnectedTransactionInputs/TradeTime';
import SaveUpdateButtons from '../../components/SaveUpdateButtons';
import { IPortfolioTrade } from '../../../../../../../../models/IPortfolioTrade';
import AssetSearch from '../../components/ConnectedTransactionInputs/AssetSearch';
import { getAssetOptions } from '../../../../../../../../services/selectors/snapshots';
import Price from '../../components/ConnectedTransactionInputs/Price';
import TradeCost from '../../components/ConnectedTransactionInputs/TradeCost';
import Commission from '../../components/ConnectedTransactionInputs/Commission';
import cn from 'classnames';
import SettlementOptions from '../../components/ConnectedTransactionInputs/SettlementOptions';
import { FORMS_NAME } from '../../../../../../../../services/constants/forms';
import { addDays, makeDateString } from '../../../../../../../../services/utils/DateHelpers';
import AccruedInterest from '../../components/ConnectedTransactionInputs/AccruedInterest';
import SettlementDate from '../../components/ConnectedTransactionInputs/SettlementDate';
import { useFx } from '../../../../../../../../services/hooks/useFx';
import debounce from 'lodash.debounce';
import AddCustodianButton from '../../../../../../../../components/AddCustodianButton/AddCustodianButton';

interface IMapStateToProps {
  formValues: IFixedIncomeFormValues;
  snapshotFxRate: IRootState['snapshot']['snapshotEdit']['fxRate']['data'];
  instrumentOptions: IOption<IAsset>[];
}

interface IDispatchToProps {
  updateFormValue: FormUpdater;
  fetchFxRate(fromCurrency: string, toCurrency: string, date: string): AxiosPromise<any>;
}

export interface IFixedIncomeFormValues {
  instrument?: IOption<IAsset>;
  other: string;
  tradeTime: string;
  settlementDate?: string;
  fxRate: number | null;
  amount: number;
  price: number;
  currency?: string | ICurrency | null | undefined; // string; // Currency of instrument from blotter
  settlementOption: IPortfolioTrade['settlementOption'];
  tradeType: IAssetClass;
  operation: 'BUY' | 'SELL';
  custodian: string | IOption<ICustodian> | any;
  notes?: string;
  asset: 'CshAdjustment';
  key?: string;
  tradeCosts?: number;
  commission?: number;
  accruedInterestIsCalculated?: boolean;
  accruedInterestLocal?: number | null;
}

type FormUpdater = <T extends keyof IFixedIncomeFormValues>(field: T, data: IFixedIncomeFormValues[T] | null) => void;

type Props = IAssetEntryComponentProps<IFixedIncomeFormValues> &
  InjectedFormProps<IFixedIncomeFormValues> &
  IMapStateToProps &
  IDispatchToProps & {
    assetSearchType?: string;
  };

const FixedIncomeEntry: FC<Props> = ({
  editMode,
  custodianOptions,
  baseCurrencyCode,
  formValues,
  resetForm,
  resetEditTrade,
  removeTrade,
  snapshotFxRate,
  initialValues,
  fetchFxRate,
  portfolioInfo,
  updateFormValue,
  fetchTradeBlotter,
  savedTradesOrder,
  saveTrades,
  updateTempTrade,
  instrumentOptions,
  assetSearchType = 'fixedIncome',
}) => {
  const dispatch: AsyncActionDispatch = useDispatch();

  const fx = useFx();

  const currencyValue = formValues?.instrument?.value?.currency;
  const instrumentCurrency = formValues?.instrument?.value?.currency
    ? String(formValues?.instrument?.value?.currency)
    : typeof initialValues.currency === 'object'
    ? initialValues.currency?.name
    : initialValues.currency;

  React.useEffect(() => {
    resetForm(initialValues);
    const currency = initialValues.instrument?.value?.currency;
    if (typeof currency === 'string') {
      const conv = fx({ fromCurrencyName: currency, toCurrencyName: portfolioInfo?.currency?.name });
      updateFormValue('fxRate', conv.naturalToConvention(initialValues.fxRate ?? 1));
    }
  }, [initialValues]);

  const convention = fx({
    fromCurrencyName: formValues?.instrument?.value?.currency
      ? String(formValues?.instrument?.value?.currency)
      : undefined,
    toCurrencyName: portfolioInfo?.currency?.name,
  });

  const updateSettlementDate = (val: string) => {
    const date = new Date(val);
    const offset = date.getDay() === 4 ? 4 : date.getDay() === 5 ? 3 : 1;
    const newSettlementDate = addDays(offset, date);
    updateFormValue('settlementDate', makeDateString(newSettlementDate));
    getPriceOnDateChange(val);
  };

  const isFormInvalid = React.useCallback(() => {
    return (
      isValueInvalid(formValues.fxRate) ||
      isValueInvalid(formValues.amount) ||
      isValueInvalid(formValues.instrument?.id || formValues.instrument?.value?.id || formValues.instrument?.name)
    );
  }, [formValues.fxRate, formValues.amount, formValues.instrument])();

  const searchAsset = debounce((str: string) => {
    dispatch(SnapshotActions.dynamicSearchAssetByName(encodeURIComponent(str), assetSearchType!, formValues.tradeTime));
  }, 400);

  const availableSettlementOptions = React.useMemo(() => {
    const settlementBtns: Array<{ text: string; value: any; subText?: string }> = [
      { text: baseCurrencyCode ?? 'NA', value: 'BASE' },
      { text: `TRANSFER`, value: 'AUTO' },
    ];

    if (currencyValue && baseCurrencyCode !== currencyValue) {
      settlementBtns.splice(1, 0, { text: String(currencyValue) ?? 'NA', value: 'LOCAL' });
    }
    return settlementBtns;
  }, [baseCurrencyCode, currencyValue]);

  const updateTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption ?? 'BASE',
      tradeType: formValues.tradeType,
      instrument: formValues.instrument,
      quantity: formValues.amount,
      fxRate: convention.conventionToNatural(formValues.fxRate ?? 1),
      tradeTime: formValues.tradeTime,
      settlementDate: formValues.settlementDate,
      operation: formValues.operation,
      custodian: formValues.custodian?.value || formValues.custodian || (undefined as any),
      currency: typeof formValues.currency === 'string' ? formValues.currency : formValues.currency?.name,
      notes: formValues.notes,
      tradeCosts: formValues.tradeCosts ?? 0,
      commission: formValues.commission ?? 0,
      accruedInterestLocal: formValues.accruedInterestLocal,
      price: formValues.price,
      key: formValues.key,
    };

    updateTempTrade!(portfolioInfo!.id, [trade]).then(() => {
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
      resetEditTrade();
    });
  };

  const tradeIsInPortfolioCurrency = !currencyValue || baseCurrencyCode === currencyValue;

  const saveTrade = (e: any) => {
    e.preventDefault?.();

    const trade: IPortfolioTrade = {
      settlementOption: formValues.settlementOption,
      tradeType: formValues.tradeType,
      instrument: formValues.instrument?.value,
      quantity: formValues.amount,
      fxRate: convention.conventionToNatural(formValues.fxRate ?? 1),
      tradeTime: formValues.tradeTime,
      settlementDate: formValues.settlementDate,
      operation: formValues.operation,
      custodian:
        typeof formValues.custodian === 'string'
          ? formValues.custodian
          : formValues.custodian?.value || (undefined as any),
      currency: typeof formValues.currency === 'string' ? formValues.currency : formValues.currency?.name,
      notes: formValues.notes,
      tradeCosts: formValues.tradeCosts ?? 0,
      commission: formValues.commission ?? 0,
      accruedInterestLocal: formValues.accruedInterestLocal,
      price: formValues.price,
    };

    saveTrades(portfolioInfo!.id, [trade]).then(() => {
      resetForm(initialValues);
      fetchTradeBlotter(portfolioInfo!.id, savedTradesOrder);
    });
  };

  const grossTradeAmount =
    nz(formValues.price) * nz(formValues.amount) * nz(formValues.instrument?.value?.pointValue ?? 0.01) +
    nz(formValues.tradeCosts) +
    nz(formValues.commission);

  const getFx = (tradeTime?: string, currency?: string) => {
    if (!portfolioInfo?.currency?.name || !currency || !tradeTime) {
      return;
    }
    fetchFxRate(portfolioInfo.currency.name, currency, tradeTime)
      .then((result) => {
        if (result.data?.firstFxRate) {
          updateFormValue('fxRate', result.data.firstFxRate);
        }
      })
      .catch(() => {});
  };

  const getPrice = (sourceId?: string, sourceData?: string, tradeTime?: string) => {
    if (!sourceId || !sourceData || !tradeTime) {
      return;
    }
    dispatch(SnapshotActions.fetchPrice(sourceId, sourceData, tradeTime)).then((result) => {
      if (typeof result.data === 'number') {
        updateFormValue('price', (result.data as unknown) as number);
      }
    });
  };

  const getPriceOnInstrumentChange = (newInstrument: any = { value: undefined }) => {
    const value = newInstrument?.value;
    const { sourceId, sourceData } = value?.sourceId ?? {};
    if (!sourceId || !sourceData || !formValues.tradeTime) {
      return;
    }
    const currency = value?.currency;
    updateFormValue('currency', currency);
    updateFormValue('accruedInterestLocal', null);
    getPrice(sourceId, sourceData, formValues.tradeTime);
    getFx(formValues.tradeTime, currency);
  };

  const getPriceOnDateChange = (date: string) => {
    if (initialValues.checkedOut) {
      return;
    }
    const source = formValues.instrument?.value?.sourceId;
    const { sourceId, sourceData } = source ?? {};
    if (!sourceId || !sourceData || !date) {
      return;
    }
    const currency = formValues.instrument?.value?.currency;

    getPrice(sourceId, sourceData, date);
    getFx(date, String(currency));
  };

  const convertor = fx({ fromCurrencyName: instrumentCurrency, toCurrencyName: portfolioInfo?.currency?.name });

  const fxDescription = convertor.getDescription({ conventionRate: formValues.fxRate });
  const conversionString = convertor.getConversionString({ conventionRate: formValues.fxRate }, grossTradeAmount);

  const currencyLabel = convention.fromCurrency?.name ?? String(formValues.instrument?.value?.currency ?? '');

  return (
    <form className={s.formGrid} onSubmit={editMode ? updateTrade : saveTrade}>
      <div className={s.formRow}>
        {!editMode && <div style={{ width: 170, minWidth: 170 }} />}
        <TradeTime onChange={updateSettlementDate} />
        <SettlementDate tradeTime={formValues.tradeTime} maxDaysAhead={10} />
        <FormFieldWrapper className={s.settlementField} label="Operation">
          <Field name="operation" values={OPERATIONS} className="input--small" component={renderToggleBtns} />
        </FormFieldWrapper>
        <AssetSearch
          onChange={getPriceOnInstrumentChange}
          label={'Name or ISIN'}
          disabled={editMode}
          onTyping={searchAsset}
          instrumentOptions={instrumentOptions}
        />
      </div>
      <div className={s.formRow}>
        <Amount label={`Nominal, ${currencyLabel}`} />
        <Price
          label={`Price (%)`}
          sourceId={formValues.instrument?.value?.sourceId}
          tradeTime={formValues.tradeTime}
          editMode={editMode}
        />
        <AccruedInterest accruedInterestIsCalculated={formValues.accruedInterestIsCalculated} />
        <Commission currencyValue={currencyLabel} />
        <TradeCost currencyCode={currencyLabel} />
        <FXRate label={fxDescription} disabled={tradeIsInPortfolioCurrency} />
        <SettlementOptions options={availableSettlementOptions} />
        <Custodian />
        <AddCustodianButton />
      </div>
      <div className={s.formRow}>
        <FormFieldWrapper label="Note" className={s.noteField}>
          <Field name="notes" theme="inverse" className="input--small" component={renderInput} />
        </FormFieldWrapper>
      </div>
      <div className={s.formFooter}>
        <span className={cn('text-gray', s.amountWrapper)}>Trade Gross Amount: {conversionString}</span>
        <SaveUpdateButtons
          onCancel={() => resetEditTrade?.()}
          onRemove={() => {
            removeTrade?.(formValues as any);
          }}
          isFormInvalid={isFormInvalid}
          editMode={editMode}
        />
      </div>
    </form>
  );
};

const nz = (val?: number | null) => parseFloat(String(val)) || 0;

const mapStateToProps = (formName: string) => (state: IRootState): IMapStateToProps => ({
  formValues: state.form[formName].values as IFixedIncomeFormValues,
  snapshotFxRate: state.snapshot.snapshotEdit.fxRate.data as any,
  instrumentOptions: getAssetOptions(state),
});

const mapDispatchToProps = (formName: string) => (dispatch: AsyncActionDispatch): IDispatchToProps => ({
  updateFormValue: (field, data) => {
    dispatch(change(formName, field, data));
  },
  fetchFxRate: (fromCurrency: string, toCurrency: string, date: string) =>
    dispatch(SnapshotActions.fetchFxRate(fromCurrency, toCurrency, date)),
});

const validate = (value: any) => {
  const errors: { [key: string]: string | undefined | Array<string | undefined> } = {};
  errors.tradeType = required(value.tradeType);
  errors.quantity = required(value.quantity) || number(value.quantity);
  errors.fxRate = required(value.fxRate) || number(value.fxRate);
  errors.instrument = required(value.instrument?.id);

  return errors;
};

const OPERATIONS = [
  { text: 'Buy', value: 'BUY' },
  { text: 'Sell', value: 'SELL' },
];

const FORM_NAME = FORMS_NAME.fixedIncome;
export default reduxForm<IFixedIncomeFormValues, IAssetEntryComponentProps<IFixedIncomeFormValues>>({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
})(
  connect(mapStateToProps(FORM_NAME), mapDispatchToProps(FORM_NAME), (ownProps, mapProps, dispatchProps) => ({
    ...ownProps,
    ...mapProps,
    ...dispatchProps,
    assetSearchType: 'fixedIncome',
  }))(FixedIncomeEntry)
);

const isValueInvalid = (value: string | number | null | undefined, allowZero = false): boolean => {
  if (allowZero) {
    return value === '';
  }
  return value === 0 || value === '0' || value === '' || value === undefined || value === null;
};
