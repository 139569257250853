import Pusher from 'pusher-js';
import { PureComponent } from 'react';

import { pusherComponent } from '../../pusher-env-config';
import { TRANSIT_TRADE_EVENT } from '../../services/constants/constants';
import _ from 'lodash';

// const pusher = new Pusher(pusherconfig.app_key!, { cluster: pusherconfig.cluster });

interface IProps {
  portfolioInfo: IPortfolio;
  onGreen?: any;
}

class BlotterTradesStatus extends PureComponent<IProps> {
  channel = pusherComponent.subscribe(this.props.portfolioInfo.id);

  componentDidMount(): void {
    Pusher.logToConsole = false;

    const debouncedOnGreen = _.debounce(() => {
      this.props.onGreen(this.props.portfolioInfo);
    }, 400, {leading: true});

    this.channel.bind(TRANSIT_TRADE_EVENT, (data: any) => {
      if (data.message === 'transit trades updated' && this.props.onGreen) {
        debouncedOnGreen();
      }
    });
  }

  componentWillUnmount(): void {
    this.channel.unbind();
  }

  render() {
    return null;
  }
}

export default BlotterTradesStatus;
