import React, { FC, PureComponent } from 'react';

interface IProps {
  clickOutside: () => void;
  [key: string]: any;
}

const ClickOutside: FC<IProps> = ({ clickOutside, ...rest }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef && !wrapperRef.current?.contains(event.target as Node)) {
        setTimeout(() => clickOutside(), 500);
      }
    };

    const handleGlobalKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        clickOutside();
      }
    };
    document.removeEventListener('click', handleClickOutside);
    window.removeEventListener('keydown', handleGlobalKeydown);

    document.addEventListener('click', handleClickOutside);
    window.addEventListener('keydown', handleGlobalKeydown);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleGlobalKeydown);
    };
  }, [clickOutside, wrapperRef.current]);

  return <div ref={wrapperRef} {...rest} />;
};

export default ClickOutside;
