import { IRealAsset } from '../../models/IRealAsset';
import { getApiUrl } from '../constants/endpoints';
import { ReduxHelper } from '../utils/ReduxHelper';

export class RealAssetActions {
  static readonly REAL_ASSETS_LIST_REQUEST = 'REAL_ASSETS_LIST_REQUEST';
  static readonly REAL_ASSETS_LIST_SUCCESS = 'REAL_ASSETS_LIST_SUCCESS';
  static readonly REAL_ASSETS_LIST_ERROR = 'REAL_ASSETS_LIST_ERROR';
  static readonly REAL_ASSETS_LIST_RESET = 'REAL_ASSETS_LIST_RESET';

  static fetchRealAssetsList(assetId?: string) {
    return ReduxHelper.createHttpRequestActionBundle<IRealAsset[]>(
      {
        url: getApiUrl('realAssets.list'),
        // data: {
        //   assetId
        // },
        method: 'GET',
      },
      RealAssetActions.REAL_ASSETS_LIST_REQUEST,
      RealAssetActions.REAL_ASSETS_LIST_SUCCESS,
      RealAssetActions.REAL_ASSETS_LIST_ERROR,
      undefined
    );
  }
}
