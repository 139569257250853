import React, { useEffect } from 'react';
import { TopListByPnLWidgetsContainer, Widget } from '../../../../../../../components';
import s from '../shared.module.scss';
import { Card } from '@iliotech/storybook';
import { DASHBOARD_FUND_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import PerformanceChartWidget from '../../../../../../../components/PerformanceChartWidget/PerformanceChartWidget';
import { ALLOCATION_TYPES } from '../../../../../../../../../services/constants/constants';
import { ICurrencyFormatter } from '../../../../../../../../../services/selectors/portfolio';
import { PeriodHelper } from '../../../../../../../../../services/utils/PeriodHelper';
import useApi from '../../../../../../../../../services/hooks/useApi';
import { Loader } from '../../../../../../../../../components';
import { ITableColumn } from '../../../../../../../../../components/UIWidgets/TableWrapper/models';
import { PnlByAttributionChartContainer } from '../../../../PortfolioDashboard/components/PnlByAttributionChartContainer';

interface IProps {
  period: IPeriod;
  handlePeriodChange: (period: IPeriod) => void;
  portfolioCurrencyFormatter: ICurrencyFormatter;
  portfolioId: string;
  getTopPositionColumns: (f: ICurrencyFormatter) => ITableColumn[];
  cellRenderer: (data: any, f: ICurrencyFormatter) => React.ReactNode;
  assetClass: string;
}

const GlobalPnl = ({
  portfolioId,
  handlePeriodChange,
  period,
  portfolioCurrencyFormatter,
  getTopPositionColumns,
  cellRenderer,
  assetClass,
}: IProps) => {
  const prepPeriod = PeriodHelper.preparePeriodForRequest(period);
  const cumulativePerformanceByAsset = useApi('/api/v1/twr/pnl/' + portfolioId, {
    method: 'GET',
    params: {
      assetClass,
      fromDate: prepPeriod.from || '',
      toDate: prepPeriod.to || '',
    },
  });

  const type: ALLOCATION_TYPES = ALLOCATION_TYPES.AssetClass;

  useEffect(() => {
    cumulativePerformanceByAsset.refetch();
  }, [period]);

  if (cumulativePerformanceByAsset.isLoading) {
    return <Loader />;
  }

  return (
    <Widget>
      <div className={s.defaultContainer}>
        <Card style={{ flex: 1, marginRight: 10 }}>
          <Widget colNum={4} title={'Cumulative P&L'} titleInfo={DASHBOARD_FUND_HELP_TEXT.ChartPnlPerformance}>
            <PerformanceChartWidget
              selectedPeriod={period}
              isDataLoaded={true}
              portfolioPerformance={cumulativePerformanceByAsset.data}
              indexPerformance={[]}
              indexName={''}
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              hidePeriodSelector={true}
              performanceToggle={1}
              useWidgetTitle={true}
            />
          </Widget>
        </Card>
        <Card style={{ flex: 1, marginLeft: 10 }}>
          <Widget title={'Monthly P&L by attribution'} titleInfo={DASHBOARD_FUND_HELP_TEXT.ChartPnlPnl}>
            <PnlByAttributionChartContainer
              portfolioCurrencyFormatter={portfolioCurrencyFormatter}
              period={period}
              portfolioId={portfolioId}
              assetClassId={assetClass}
              hidePeriodSelector={true}
            />
          </Widget>
        </Card>
      </div>
      <div style={{ marginTop: 30 }}>
        <Widget title="Top 10 by P&L" className={s.contaienr} titleInfo={DASHBOARD_FUND_HELP_TEXT.TableTop10ByPnl}>
          <TopListByPnLWidgetsContainer
            filter={{ type, id: assetClass }}
            portfolioId={portfolioId}
            showCategories={true}
            columns={getTopPositionColumns(portfolioCurrencyFormatter)}
            cellRenderer={(data) => cellRenderer(data, portfolioCurrencyFormatter)}
          />
        </Widget>
      </div>
    </Widget>
  );
};

export default GlobalPnl;
