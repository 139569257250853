import ReactEcharts from 'echarts-for-react';
import React, { Fragment, PureComponent } from 'react';

import { getChartOptions } from './yieldToMaturityOptions';
import { BarType } from '../../../../services/constants/charts';
import s from './YieldToMaturityGraph.module.scss';

interface IProps {
  fixedIncomeYield: IFixedIncomeYield[];
}

class YieldToMaturityGraph extends PureComponent<IProps> {
  render() {
    const { fixedIncomeYield } = this.props;

    const ytmData: any[] = [];
    const ytcData: any[] = [];

    fixedIncomeYield.map((el) => {
      ytmData.push({ date: el.date, value: el.yieldToMaturity.value, details: el.yieldToMaturity.details });
      ytcData.push({ date: el.date, value: el.yieldToWorse.value, details: el.yieldToWorse.details });
    });

    const chartData: IBarData[] = [
      {
        name: 'YTM',
        type: BarType.Solid,
        data: ytmData,
      },
      {
        name: 'YTW',
        type: BarType.Solid,
        data: ytcData,
      },
    ];

    return (
      <Fragment>
        <div className={s.chartLegendContainer}>
          <div className={s.legendItem}>
            <div className={s.ytmSymbol} />
            <div className={s.labelItem}>YTM</div>
          </div>
          <div className={s.legendItem}>
            <div className={s.ytcSymbol} />
            <div className={s.labelItem}>YTW</div>
          </div>
        </div>
        <ReactEcharts
          style={{ height: '420px', width: '100%', flexGrow: '1' }}
          option={getChartOptions(chartData)}
          notMerge={true}
          lazyUpdate={true}
        />
      </Fragment>
    );
  }
}

export default YieldToMaturityGraph;
