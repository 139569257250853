import React, { FC } from 'react';
import { DEFAULT_AVAILABLE_ALLOCATION_TYPES } from '../hooks/apiHooks/usePortfolioAllocation';
import { ALLOCATION_TYPES } from '../constants/constants';
import { useDonutdata } from '../hooks/useDonutData';

const noop = () => {
  console.log('ExplorerContext not yet initialised or not available');
};

const ExplorerContext = React.createContext<IExplorerContext>({
  selectedAllocationType: null,
  selectedAllocationSubType: { subClassId: null, subSubClassId: null },
  setSelectedAllocationType: noop,
  setSelectedAllocationSubType: noop,
  availableAllocationTypes: DEFAULT_AVAILABLE_ALLOCATION_TYPES,
  donutData: null,
  selectedPosition: null,
  setSelectedPosition: noop,
});

export const ExplorerContextProvider: FC = ({ children }) => {
  const [selectedAllocationType, setSelectedAllocationType] = React.useState<AllocationType | null>(
    ALLOCATION_TYPES.AssetClass
  );
  const [selectedAllocationSubType, setSelectedAllocationSubType] = React.useState<{
    subClassId: string | null;
    subSubClassId: string | null;
  }>({ subClassId: null, subSubClassId: null });
  const [selectedPosition, setSelectedPosition] = React.useState<IPortfolioSnapshot | null>(null);

  const { donutMap } = useDonutdata();

  const values: IExplorerContext = {
    selectedAllocationType,
    setSelectedAllocationType,
    selectedAllocationSubType,
    setSelectedAllocationSubType,
    selectedPosition,
    setSelectedPosition,
    availableAllocationTypes: DEFAULT_AVAILABLE_ALLOCATION_TYPES,
    donutData: donutMap,
  };

  return <ExplorerContext.Provider value={values}>{children}</ExplorerContext.Provider>;
};

export const useExplorerContext = () => React.useContext(ExplorerContext);
