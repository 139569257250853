import { ReduxHelper } from '../utils/ReduxHelper';

import { PortfolioCurrencyActions } from '../actions/PortfolioCurrencyActions';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';

export interface IPortfolioCurrencyState extends ILoadingDataState {
  data: ICurrency[];
}

const INITIAL_STATE: IPortfolioCurrencyState = ReduxHelper.createInitialState<ICurrency[]>([]);

const currencyPortfolioReducer = ReduxHelper.createHttpRequestReducer<IPortfolioCurrencyState>(
  INITIAL_STATE,
  PortfolioCurrencyActions.PORTFOLIO_CURRENCY_REQUEST,
  PortfolioCurrencyActions.PORTFOLIO_CURRENCY_SUCCESS,
  PortfolioCurrencyActions.PORTFOLIO_CURRENCY_ERROR
);

export default currencyPortfolioReducer;
