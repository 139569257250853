import { Color } from 'csstype';
import { IOption } from '../../components/UIWidgets/Autocomplete';
import { IOptionType } from '../../components/UIWidgets/Select/Select';

import { TRANSACTION_TYPES } from '../mock/transactionTypes';
import { ASSET_COLORS_ENUM, ASSETS } from './assetClasses';

export const SERVER_DATE_FORMAT_MOMENT = 'YYYY-MM-DD';

export const DASHBOARD_TITLES: Record<string, string> = {
  pnl: 'Profit and Loss',
  income: 'Income',
  structure: 'Structure',
  pnlBreakdown: 'P&L Breakdown',
  options: 'Options',
  fundamentals: 'Fundamentals',
  summary: 'Summary',
};

export const ALLOCATION_COLORS_STACK = [
  ASSET_COLORS_ENUM.Alternatives,
  ASSET_COLORS_ENUM.CashAndEquivalents,
  ASSET_COLORS_ENUM.Adjustments,
  ASSET_COLORS_ENUM.FixedIncome,
  ASSET_COLORS_ENUM.Equities,
  ASSET_COLORS_ENUM.Funds,
  ASSET_COLORS_ENUM.RealEstate,
  ASSET_COLORS_ENUM.RealAssets,
  ASSET_COLORS_ENUM.CryptoCurrencies,
  ASSET_COLORS_ENUM.Commodities,
  ASSET_COLORS_ENUM.Futures,
  ASSET_COLORS_ENUM.MixedAllocation,
  ASSET_COLORS_ENUM.Fx,
  ASSET_COLORS_ENUM.Other,
];

export const SUB_ASSETS: Record<string, string> = {
  options: 'EqOptions',
  privateEquity: 'AltPrivateEquity',
  directInvestments: 'AltDirectInvestments',
  hedgeFund: 'AltHedgeFunds',
};

export const TRADE_TYPES: Array<
  IOption<TradeType> & { capability: string; defaultAssetSubclass: string; additionalAssetSubclasses?: string[] }
> = [
  {
    capability: 'EquityFunds',
    defaultAssetSubclass: 'EqShares',
    // additionalAssetSubclasses: ['FunEquity'], // Handle various names by which the backend refers to Equities & Funds
    value: 'Equities',
    id: 'Equities',
    name: 'Equities',
  },
  {
    capability: 'EquityFunds',
    defaultAssetSubclass: 'FunEquity',
    additionalAssetSubclasses: ['FunETF'], // Handle various names by which the backend refers to Equities & Funds
    value: 'Funds',
    id: 'Funds',
    name: 'Funds & ETFs',
  },
  // {
  //   capability: 'EquityFunds',
  //   defaultAssetSubclass: 'EqFutures',
  //   // additionalAssetSubclasses: ['FunEquity'],
  //   value: 'EqFutures',
  //   id: 'EqFutures',
  //   name: 'Futures',
  // },
  {
    capability: 'CryptoCurrencies',
    defaultAssetSubclass: 'CryptoCurrencies',
    value: 'CryptoCurrencies',
    id: 'CryptoCurrencies',
    name: 'Cryptocurrencies',
  },
  {
    capability: 'CryptoCurrencies',
    defaultAssetSubclass: 'CryptoCurrencies',
    value: 'CryptoSwap',
    id: 'CryptoSwap',
    name: 'Crypto Swap',
  },
  {
    capability: 'CashAndEquivalents',
    defaultAssetSubclass: 'CshCash',
    value: 'CashAndEquivalents',
    id: 'CashAndEquivalents',
    name: 'FX Spot Trades',
  },
  {
    capability: 'SubscriptionWithdrawal',
    defaultAssetSubclass: 'SubCash',
    value: 'Transfer',
    id: 'Transfer',
    name: 'Transfer',
  },
  {
    capability: 'Commodities',
    defaultAssetSubclass: 'ComPhysical',
    value: 'Commodities',
    id: 'Commodities',
    name: 'Commodities',
  },
  {
    capability: 'FixedIncome',
    defaultAssetSubclass: 'FiBonds',
    value: 'FixedIncome',
    id: 'FixedIncome',
    name: 'Fixed Income',
  },
  {
    capability: 'SubscriptionWithdrawal',
    defaultAssetSubclass: 'SubCash',
    value: 'SubscriptionWithdrawal',
    id: 'SubscriptionWithdrawal',
    name: 'Deposits and Withdrawals',
  },
  { capability: 'Options', defaultAssetSubclass: 'EqOptions', value: 'EqOptions', id: 'EqOptions', name: 'Options' },
  {
    capability: 'Options',
    defaultAssetSubclass: 'EqWarrants',
    value: 'EqWarrants',
    id: 'EqWarrants',
    name: 'Warrants',
  },
  {
    capability: 'CashAndEquivalents',
    defaultAssetSubclass: 'AdjFees',
    value: 'Adjustments',
    id: 'AdjFees',
    name: 'Cash Adjustments',
    additionalAssetSubclasses: ['AdjFees', 'AdjIncome', 'AdjPnL', 'AdjInterest'],
  },
  {
    capability: 'CashAndEquivalents',
    defaultAssetSubclass: 'AdjFXSpot',
    additionalAssetSubclasses: ['CshFXSpot'],
    value: 'Adjustments',
    id: 'Adjustments',
    name: 'FX Spot Trades',
  },

  {
    capability: 'RealAssets',
    defaultAssetSubclass: 'RealAssets',
    value: 'RealAssets',
    id: 'RealAssets',
    name: 'Real Assets / Real Estate',
  },
];

// ToDo: AssetSub~Classes added only for Interface compatibility - in fact these values are not used anywhere. Needs to be removed and refactored AddTransaction Screen.
export const ASSET_CLASSES_LIGHT_LOCAL: IAssetClass[] = [
  {
    id: TRANSACTION_TYPES.equities,
    name: 'Equities/Funds',
    priceDecimals: 2,
    quantityDecimals: 3,
    assetSubClasses: [{ id: 'eqLocalShares', name: 'Local Light Shares', assetClassId: 'Equities' }],
  },
  {
    id: TRANSACTION_TYPES.cryptoCurrencies,
    name: 'Crypto-Currencies',
    priceDecimals: 3,
    quantityDecimals: 7,
    assetSubClasses: [
      { id: 'CryptoCurrencies', name: 'Crypto-Currencies', assetClassId: 'CryptoCurrencies' },
      { id: 'CryptoIndex', name: 'Index', assetClassId: 'CryptoCurrencies' },
      { id: 'CryptoTokens', name: 'Tokens', assetClassId: 'CryptoCurrencies' },
    ],
  },
  {
    id: TRANSACTION_TYPES.cashAndEquivalents,
    name: 'Cash Balances',
    priceDecimals: 2,
    quantityDecimals: 2,
    assetSubClasses: [{ id: 'eqLocalCash', name: 'Local Light Cash', assetClassId: 'CashAndEquivalents' }],
  },
  {
    id: TRANSACTION_TYPES.fixedIncome,
    name: 'Fixed Income',
    priceDecimals: 3,
    quantityDecimals: 0,
    assetSubClasses: [{ id: 'eqLocalBonds', name: 'Local Light Bonds', assetClassId: 'FixedIncome' }],
  },
  {
    id: TRANSACTION_TYPES.subscriptionAndRedemption,
    name: 'Deposits and Withdrawals',
    priceDecimals: 2,
    quantityDecimals: 2,
    assetSubClasses: [
      { id: 'eqLocalSubscription', name: 'Local Light Subscriptions', assetClassId: 'SubscriptionWithdrawal' },
    ],
  },
  {
    id: TRANSACTION_TYPES.options,
    name: 'Options',
    priceDecimals: 2,
    quantityDecimals: 3,
    assetSubClasses: [],
  },
  {
    id: TRANSACTION_TYPES.realAssets,
    name: 'Real Assets',
    priceDecimals: 0,
    quantityDecimals: 0,
    assetSubClasses: [
      {
        id: 'RaArt',
        name: 'Art',
        assetClassId: 'RealAssets',
      },
      {
        id: 'RaWineAndSpirits',
        name: 'Wine and spirits',
        assetClassId: 'RealAssets',
      },
      {
        id: 'RaMotorVehicle',
        name: 'Motor vehicle',
        assetClassId: 'RealAssets',
      },
      {
        id: 'RaYacht',
        name: 'Yacht',
        assetClassId: 'RealAssets',
      },
      {
        id: 'RaJewellery',
        name: 'Jewellery',
        assetClassId: 'RealAssets',
      },
      {
        id: 'RaAircraft',
        name: 'Aircraft',
        assetClassId: 'RealAssets',
      },
    ],
  },
];

export enum UserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  CUSTOMER = 'CUSTOMER',
}

export enum UserStatus {
  UNCONFIRMED = 'UNCONFIRMED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PortfolioType {
  BUSINESS = 'CLIENT',
  PERSONAL = 'PERSONAL',
  MODEL = 'MODEL',
}

export enum PortfolioAccessType {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  HIDDEN = 'HIDDEN',
}

export enum SORT_VIEW {
  NAME = 'name',
  PNL = 'totalProfitAndLoss',
  PERFORMANCE = 'performance',
  WEALTH = 'totalWealth',
}

export enum FILTER_VIEW {
  ALL,
  CURRENCY,
  BUSINESS,
  ASSET,
}

export enum PROFIT_VIEW {
  ASSET,
  ATTRIBUTION,
}

export enum PERIOD_TYPE {
  MTD,
  YTD,
  INCEPTION,
  YEAR,
  THREE_YEARS,
  MANUAL,
  THREE_MONTHS,
  SIX_MONTHS,
  LAST_YEAR,
  CALENDAR_YEAR,
  CALENDAR_QUARTER,
  A_DAY,
  A_WEEK,
  THIRTY_DAYS,
}

export enum TOP_LIST_ORDER {
  ISSUER = 'company.name',
  SECURITY = 'name',
  SUBCLASS = 'subclass',
  TABLE_COLUMN = 'pnlBase',
}

export enum TOPLIST_TYPE {
  MONEY = 'MONEY',
  PERCENTAGE = 'PERCENTAGE',
}

export enum SETTINGS_VIEW_TYPE {
  ROW,
  COLUMN,
}

export enum ALLOCATION_TYPES {
  AssetClass = 'AssetClass',
  RiskAssetClass = 'RiskAssetClass',
  AssetSubClass = 'AssetSubClass',
  Region = 'Region',
  RegionOfIssue = 'RegionOfIssue',
  MSCIRegion = 'MSCIRegion',
  MSCIClassification = 'MSCIClassification',
  Sectors = 'IndustrySector',
  Country = 'Country',
  Currency = 'Currency',
  CreditRating = 'CreditRating',
  Custodian = 'Custodian',
  ETFSubClass = 'ETFSubClass',
  Industry = 'Industry',
  PropertySector = 'PropertySector',
  FundStrategy = 'FundStrategy',
  RealEstate = 'RealEstate',
}

export enum GEOGRAPHY_ALLOCATION_TYPES {
  Region = 'Region',
  RegionOfIssue = 'RegionOfIssue',
  MSCIRegion = 'MSCIRegion',
  MSCIClassification = 'MSCIClassification',
}

export const GEOGRAPHY_TYPES = [
  { value: GEOGRAPHY_ALLOCATION_TYPES.Region, label: 'Region of Risk' },
  { value: GEOGRAPHY_ALLOCATION_TYPES.RegionOfIssue, label: 'Region of Issue' },
  { value: GEOGRAPHY_ALLOCATION_TYPES.MSCIRegion, label: 'Alt. Region of Risk' },
  { value: GEOGRAPHY_ALLOCATION_TYPES.MSCIClassification, label: 'Classification' },
];

export enum EQUITY_EXPOSURE_TYPES {
  MarketValueBase = 'MarketValueBase',
  NotionalBase = 'NotionalBase',
  ExposureBase = 'ExposureBase',
  MarketValueBaseGross = 'MarketValueBaseGross',
  NotionalBaseGross = 'NotionalBaseGross',
  ExposureBaseGross = 'ExposureBaseGross',
}

export const EXPOSURE_TYPES = [
  { value: EQUITY_EXPOSURE_TYPES.MarketValueBase, label: 'Market Value' },
  { value: EQUITY_EXPOSURE_TYPES.NotionalBase, label: 'Notional' },
  { value: EQUITY_EXPOSURE_TYPES.ExposureBase, label: 'Exposure' },
];

export const EXPOSURE_TYPES_WITH_SHORTS = [
  { value: EQUITY_EXPOSURE_TYPES.MarketValueBase, label: 'Market Value' },
  { value: EQUITY_EXPOSURE_TYPES.NotionalBase, label: 'Notional' },
  { value: EQUITY_EXPOSURE_TYPES.ExposureBase, label: 'Exposure' },
  { value: EQUITY_EXPOSURE_TYPES.MarketValueBaseGross, label: 'Market Value (Gross)' },
  { value: EQUITY_EXPOSURE_TYPES.NotionalBaseGross, label: 'Notional (Gross)' },
  { value: EQUITY_EXPOSURE_TYPES.ExposureBaseGross, label: 'Exposure (Gross)' },
];

export enum DetalizationType {
  Monthly = 'Monthly',
  Daily = 'Daily',
}

export enum PNL_CHART_TYPE {
  PnlByAttribution = 'PnlByAttribution',
  PnlByAssetClass = 'PnlByAssetClass',
}

export enum SCENARIO_DISPLAY_OPTIONS {
  sectors = 'sectors',
  assets = 'assets',
  countries = 'countries',
  currencies = 'currencies',
}

export const RISK_DAILY_PERIOD_OPTIONS = [
  { value: 'ytd', label: 'Year To Date' },
  { value: 'oneYear', label: 'Previous 1yr' },
  { value: 'sinceInception', label: 'Since Inception' },
  { value: 'custom', label: 'Custom' },
];

export const RISK_MONTHLY_PERIOD_OPTIONS = [
  { value: 'previous3m', label: 'Previous 3m' },
  { value: 'previous6m', label: 'Previous 6m' },
  { value: 'oneYear', label: 'Previous 1yr' },
  { value: 'calendar', label: 'Calendar' },
  { value: 'sinceInception', label: 'Since Inception' },
  { value: 'custom', label: 'Custom' },
];

export const DEFAULT_CUSTODIANS: Record<string, string> = {
  [ASSETS.realAssets]: '013cabfb-ce73-4dba-812e-f330664b193a',
};

export const PERSONAL_USE_CUSTODIAN: IOption<ICustodian> = {
  code: 'Personal Use',
  id: '013cabfb-ce73-4dba-812e-f330664b193a',
  name: 'Personal Use',
  // @ts-ignore
  default: true,
  value: {
    code: 'Personal Use',
    default: true,
    id: '013cabfb-ce73-4dba-812e-f330664b193a',
    name: 'Personal Use',
  },
};

export const FEES_COLORS: Record<string, Color> = {
  Commission: '#40C9A2',
  'Other Charges': '#F3C969',
  'Custodian Fee': '#E07BE0',
  Tax: '#EB5E55',
  Interest: '#05A8AA',
  'Misc Charge': '#56B877',
  'Management Fee': '#832161',
  'Performance Fee': '#593C8F',
  Total: '#A9B3D1',
};

export const ALLOCATION_BARS_OPTIONS: Record<string, { color: Color; title: string }> = {
  [ALLOCATION_TYPES.RiskAssetClass]: { color: '#6677cc', title: 'Risk Asset Class' },
  [ALLOCATION_TYPES.AssetClass]: { color: '#6677cc', title: 'Asset Class' },
  [ALLOCATION_TYPES.AssetSubClass]: { color: '#6677cc', title: 'Subclass' },
  [ALLOCATION_TYPES.Region]: { color: '#c2b261', title: 'Geography' },
  [ALLOCATION_TYPES.RegionOfIssue]: { color: '#c2b261', title: 'Geography' },
  [ALLOCATION_TYPES.MSCIRegion]: { color: '#c2b261', title: 'Geography' },
  [ALLOCATION_TYPES.MSCIClassification]: { color: '#c2b261', title: 'Geography' },
  [ALLOCATION_TYPES.Currency]: { color: '#59abb3', title: 'Currency' },
  [ALLOCATION_TYPES.Sectors]: { color: '#cc6f66', title: 'Sector' },
  [ALLOCATION_TYPES.Industry]: { color: '#56b877', title: 'Industry' },
  [ALLOCATION_TYPES.PropertySector]: { color: '#cc6f66', title: 'PropertySector' },
  [ALLOCATION_TYPES.CreditRating]: { color: '#56b877', title: 'Credit Rating' },
  [ALLOCATION_TYPES.Custodian]: { color: '#b87f24', title: 'Custodian' },
  [ALLOCATION_TYPES.FundStrategy]: { color: '#6677cc', title: 'Strategy' },
  [ALLOCATION_TYPES.ETFSubClass]: { color: '#56b877', title: 'ETF Strategy' },
};

export const TABLE_PAGE_SIZE = 2500;

export const SCATTER_CHART_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.INCEPTION, label: 'Since inception' },
  { value: PERIOD_TYPE.LAST_YEAR, label: 'Last year' },
  { value: PERIOD_TYPE.MANUAL, label: 'Date range' },
];

export const CHARTS_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.MTD, label: 'MTD' },
  { value: PERIOD_TYPE.YTD, label: 'YTD' },
  { value: PERIOD_TYPE.A_DAY, label: '1 day' },
  { value: PERIOD_TYPE.THIRTY_DAYS, label: '30 days' },
  { value: PERIOD_TYPE.YEAR, label: '1 year' },
  { value: PERIOD_TYPE.CALENDAR_YEAR, label: 'Calendar year' },
  { value: PERIOD_TYPE.CALENDAR_QUARTER, label: 'Previous Quarter' },
  { value: PERIOD_TYPE.INCEPTION, label: 'Since inception' },
  { value: PERIOD_TYPE.MANUAL, label: 'Date range' },
];

export const DAILY_RISK_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.YTD, label: 'YTD' },
  { value: PERIOD_TYPE.YEAR, label: '1 Year' },
  { value: PERIOD_TYPE.INCEPTION, label: 'Since inception' },
  { value: PERIOD_TYPE.MANUAL, label: 'Date range' },
];

export const MONTHLY_RISK_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.THREE_MONTHS, label: 'Previous 3m' },
  { value: PERIOD_TYPE.SIX_MONTHS, label: 'Previous 6m' },
  { value: PERIOD_TYPE.YEAR, label: '1 Year' },
  { value: PERIOD_TYPE.INCEPTION, label: 'Since inception' },
  { value: PERIOD_TYPE.MANUAL, label: 'Date range' },
];

export const SYSTEM_EPOCH = new Date('2018-01-01');
export const SYSTEM_EPOCH_PLUS_ONE = new Date('2018-01-02');

export const DATE_FORMAT = 'DD-MMM-YYYY';

export const PNL_CALCULATION_EVENT = 'pnl_calculation';
export const TRANSIT_TRADE_EVENT = 'transit_trade';
export const TRANSIT_TRADE_CONFIRM = 'transit_trade_confirm';

export const LOCAL_STORAGE_KEYS: Record<string, string> = {
  ILLIO_SELECTED_ACCOUNT: 'illio-selected-account',
};

export const UPLOAD_COLUMNS = [
  { label: 'Transaction Date', value: 'date', required: true },
  { label: 'Price', value: 'price', required: true },
  { label: 'ISIN', value: 'isin', required: true },
  { label: 'Ticker', value: 'ticker', required: true },
  // { label: 'Transaction Action', value: 'action', required: true },
  { label: 'Buy / Sell', value: 'buySell', required: true },
  { label: 'Credit', value: 'credit', required: true },
  { label: 'Debit', value: 'debit', required: true },
  { label: 'Quantity', value: 'quantity', required: true },
  { label: 'Currency', value: 'currency', required: false },
];
