import React from 'react';
import { Field } from 'redux-form';
import { Panel } from 'rsuite';
import { ToggleLeftRight } from '../../../components/UIWidgets';
import s from './PortfolioFormsCommon.module.scss';
import c from './PortfolioFormsCommon.module.scss';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../components/ContextHelp';
import { PORTFOLIO_CREATE_HELP_TEXT } from '../../../services/constants/tooltipContextHelp';
import cn from 'classnames';
import renderAutocomplete from '../../../components/ReduxForm/renderAutocomplete';
import sortComparator from '../../../services/utils/sortComparator';
import { IOption } from '../../../components/UIWidgets/Autocomplete';
import { required } from '../../../services/utils/FormValidations';

export const AssetClassToggle = (field: any) => {
  return (
    <>
      <div className={s.labelContainer}>
        <h4>Show Asset Structure / P&L View by:</h4>
      </div>
      <div>
        <ToggleLeftRight
          leftLabel="Instrument Asset Class"
          rightLabel="Risk Asset Class"
          toggleCurrentPosition={field.input.value === 'INSTRUMENT_ASSET_CLASS'}
          clickFunction={() =>
            field.input.value === 'INSTRUMENT_ASSET_CLASS'
              ? field.input.onChange('RISK_ASSET_CLASS')
              : field.input.onChange('INSTRUMENT_ASSET_CLASS')
          }
        />
      </div>
    </>
  );
};

export interface IAdvancedSettingsPanelProps {
  updateRiskAssetViewType?: (viewType: RiskAssetViewType) => void;
  performanceIndexOptions: Array<IOption<IIndex>>;
}

export const AdvancedSettingsPanel = ({
  updateRiskAssetViewType,
  performanceIndexOptions,
}: IAdvancedSettingsPanelProps) => {
  return (
    <Panel
      style={{ background: 'var(--dark)', marginBottom: '1.5rem', overflow: 'visible' }}
      shaded={true}
      collapsible={false}
      header={'Advanced'}
    >
      <div className={c.labelContainer}>
        <h4>
          Index to measure performance against*{' '}
          <ContextHelpIcon title={'Performance Vs Index'} elementId={'lblPerformanceVsIndex'} />
        </h4>
        {/*<AiFillInfoCircle size={20} color="#a9b2d1" data-tip="Select from common benchmark indices" />*/}
      </div>

      <ContextHelpTooltip
        elementId={'lblPerformanceVsIndex'}
        tooltipTitle={'Performance Vs Index'}
        tooltipText={PORTFOLIO_CREATE_HELP_TEXT.lblPerformanceVsIndex}
      />
      <div className={cn(c.indexRow, s.comboPseudoContainer)}>
        <Field
          name="benchmarkIndex"
          component={renderAutocomplete}
          options={performanceIndexOptions.sort(
            sortComparator<IOption<IIndex>>((i) => i.name.toLocaleLowerCase())
          )}
          filter={(search: string, option: IOption<IIndex>) =>
            option.value.name.toLowerCase().includes(search.toLowerCase())
          }
          inverseTheme={false}
          validate={[required]}
          inputProps={{
            placeholder: 'Benchmark Index',
            required: true,
          }}
        />

        <div className={s.comboPseudoArrow} />
      </div>
    </Panel>
  );
};
