import React from 'react';
import { Icon, IconProps, Popover, Whisper } from 'rsuite';
import cn from 'classnames';
import s from './ActionsButton.module.css';

interface IOwnProps {
  options: IAction[];
}

export interface IAction {
  label: string;
  icon?: IconProps['icon'];
  action(): void;
  bottomBorder?: boolean;
  disabled?: boolean;
}

export const ActionsButton = ({ options }: IOwnProps) => {
  const speaker = (
    <Popover>
      <ul>
        {options.map((option) => (
          <li
            onClick={option.disabled ? undefined : option.action}
            key={option.label}
            className={cn(s.item, option.bottomBorder && s.borderBottom, option.disabled && s.disabled)}
          >
            {/*@ts-ignore*/}
            <div className={cn(s.itemInner)}>
              {option.label}
              {option.icon && <Icon icon={option.icon} />}
            </div>
          </li>
        ))}
      </ul>
    </Popover>
  );

  return (
    <Whisper trigger={'active'} speaker={speaker} placement={'left'}>
      <div style={{ padding: '0 15px' }}>
        <Icon icon={'pencil'} />
      </div>
    </Whisper>
  );
};
