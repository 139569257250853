import { INITIAL_PERIOD, INITIAL_PERIOD_ALTERNATIVE } from '../reducers/DashboardReducers/PerformanceReducers';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { usePortfolioInfo } from '../hooks/usePortfolioInfo';
import moment from 'moment';

interface IPeriodContext {
  period: IPeriod;
  updatePeriod: (newPeriod: IPeriod) => void;
}

const noop = (functionName: string) => () => console.log(`${functionName} not initialised`);

const PeriodContext = React.createContext<IPeriodContext>({
  period: INITIAL_PERIOD,
  updatePeriod: noop('updatePeriod'),
});

export const PeriodContextProvider: FunctionComponent = ({ children }) => {
  const { portfolioInfo } = usePortfolioInfo();

  const [period, updatePeriod] = React.useState<IPeriod>(INITIAL_PERIOD);

  useEffect(() => {
    if (portfolioInfo.data) {
      const inceptionDate = portfolioInfo.data?.inceptionDate;
      const inInceptionBefore30Days = moment().subtract(30, 'days').isBetween(moment(inceptionDate));
      if (!inInceptionBefore30Days && period === INITIAL_PERIOD) {
        updatePeriod(INITIAL_PERIOD_ALTERNATIVE);
      }
    }
  }, [portfolioInfo.data]);

  return <PeriodContext.Provider value={{ period, updatePeriod }}>{children}</PeriodContext.Provider>;
};

export const usePeriod = () => useContext(PeriodContext);
