import { IActionPayload } from '../../../models/redux/IActionPayload';
import { ILoadingPeriodDataState } from '../../../models/redux/ILoadingPeriodDataState';
import { DashboardActions } from '../../actions';
import { BarType } from '../../constants/charts';
import { ReduxHelper } from '../../utils/ReduxHelper';

const INITIAL_INCOME_REPAYMENTS_STATE: any = {
  isFetched: false,
  isFetching: false,
  cancelTokenSource: null,
  error: null,
  data: [],
};

const incomeRepaymentyReducerDefault = ReduxHelper.createHttpRequestWithPeriodReducer<
  ILoadingPeriodDataState<IBarData[]>
>(
  INITIAL_INCOME_REPAYMENTS_STATE,
  DashboardActions.INCOME_REPAYMENTS_REQUEST,
  DashboardActions.INCOME_REPAYMENTS_SUCCESS,
  DashboardActions.INCOME_REPAYMENTS_ERROR,
  DashboardActions.INCOME_REPAYMENTS_RESET
);

const incomeRepaymentReducer = (state = INITIAL_INCOME_REPAYMENTS_STATE, action: IActionPayload) => {
  switch (action.type) {
    case DashboardActions.INCOME_REPAYMENTS_SUCCESS: {
      return {
        ...state,
        data: [
          {
            ...action.payload,
            id: 'repaymentsDataSet',
            name: 'Repayments',
            type: BarType.Solid,
          },
        ],
      };
    }
    default:
      return incomeRepaymentyReducerDefault(state, action);
  }
};

export default incomeRepaymentReducer;
