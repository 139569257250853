import React from 'react';
import { connect } from 'react-redux';
import Widget from '../../../../../../../../../../components/WidgetsBlock/Widget/Widget';
import { IRootState } from '../../../../../../../../../../../../services/store';
import {
  getPortfolioCurrencyFormatter,
  ICurrencyFormatter,
} from '../../../../../../../../../../../../services/selectors/portfolio';

import StockVsBuysChart from './StockVsBuysChart';
import { TRANSACTION_TYPES } from '../../../../../../../../../../../../services/mock/transactionTypes';
import { ContextHelpIcon, ContextHelpTooltip } from '../../../../../../../../../../../../components/ContextHelp';
import { EQUITY_PNL_BREAKDOWN_HELP_TEXT } from '../../../../../../../../../../../../services/constants/tooltipContextHelp';

interface IMapStateToProps {
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

interface IOwnProps {
  stockPricesData: IStockPrices[];
  income: IDividends[];
  splitsData: ISplits[];
  buysAndSellsData: ITradesInfo[];
  earnEventsData: IEarnDataInfo[];
  instrumentName: string;
  baseCurrency: ICurrency;
  instrumentCurrency: ICurrency;
  assetClassId?: string;

}

type IProps = IMapStateToProps & IOwnProps;

const StockVsBuysChartContainer = (props:IProps) => {
  const chartTitle = props.assetClassId === TRANSACTION_TYPES.fixedIncome
    ? 'Bond Price Overlaid by Buys, Sells and Coupons'
    : `${props.instrumentName} price with Buys and Sells`;

  const helpText = props.assetClassId === TRANSACTION_TYPES.fixedIncome
      ? EQUITY_PNL_BREAKDOWN_HELP_TEXT.ChartStockPriceByBuysSellsCoupons
      : EQUITY_PNL_BREAKDOWN_HELP_TEXT.ChartStockPriceByBuysSellsDividends;

  return (
    <Widget colNum={1}>
    <h3>
      {chartTitle}
      <ContextHelpIcon title={chartTitle || ''} elementId={chartTitle || 'tmpID'} />
    </h3>
    <ContextHelpTooltip elementId={chartTitle} tooltipTitle={chartTitle} tooltipText={helpText} />
    <br />
    <StockVsBuysChart
      instrumentName={props.instrumentName}
      instrumentCurrency={props.instrumentCurrency}
      baseCurrency={props.baseCurrency}
      stockPricesData={props.stockPricesData}
      income={props.income}
      splitsData={props.splitsData}
      buysAndSellsData={props.buysAndSellsData}
      earnEventsData={props.earnEventsData}
      portfolioCurrencyFormatter={props.portfolioCurrencyFormatter}
      assetType={props.assetClassId || ''}
    />
  </Widget>
  )
}

const mapStateToProps: (state: IRootState) => IMapStateToProps = (state: IRootState) => {
  return {
    portfolioCurrencyFormatter: getPortfolioCurrencyFormatter(state),
  };
};

export default connect(mapStateToProps)(StockVsBuysChartContainer);
