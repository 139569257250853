import React from 'react';
import cn from 'classnames';
import s from './DashboardNewHeader.module.css';

import { usePortfolio } from '../../../../services/hooks/usePortfolio';
import { FcAreaChart, FcDoughnutChart, FcLineChart, FcPieChart, FcPositiveDynamic } from 'react-icons/all';
import EsgImage from '../../../../static/images/assets/esg-dashboard.svg';
import { getCircleColor } from '../Esg/utils/esgCircleRenderer';
import { PATHS } from '../../../../router/paths';
import { Link } from 'react-router-dom';

const numFormat = (val?: number, maximumFractionDigits = 0) =>
  typeof val === 'number' ? val.toLocaleString(undefined, { maximumFractionDigits }) : '';

const percentFormat = (val?: number) => typeof val === "undefined" ? "" : `${numFormat(val, 1)}%`;

const esgRenderer = (val?: number) => {
  const color = getCircleColor(val!);
  return <span style={{ color }}>{numFormat(val, 0)}</span>;
};
const abbreviate = (val?: number, baseCurrency: string = "") => {
  if (typeof val === 'undefined' || !baseCurrency) {
    return '';
  }
  switch (true) {
    case val >= 1000000000:
      return baseCurrency + ' ' + `${numFormat(val / 1000000000, 2)}b`;
    case val >= 1000000:
      return baseCurrency + ' ' + `${numFormat(val / 1000000, 2)}m`;
  }

  const cleanedVal = val < 0 && val > - 0.5 ? 0 : val;
  return baseCurrency + ' ' + numFormat(cleanedVal);

};

export const DashboardNewHeader = () => {
  const { portfolioInfo } = usePortfolio();
  // const snapshot = usePortfolioSnapshot();

  const portfolio = portfolioInfo.data;

  const portfolioValue = portfolio?.totalWealth;

  const lifetimeTWR = portfolio?.performance;
  const totalPnlBase = portfolio?.totalProfitAndLoss;
  const historicPnl = portfolio?.historicPnl;
  const totalInvestment = (portfolioValue ?? 0) - (totalPnlBase ?? 0);
  const esgScore = portfolio?.totalESG;

  const stats = [
    {
      key: 'totalInvestment',
      value: totalInvestment,
      label: 'Total Investment',
      colored: false,
      path: PATHS.portfolio.snapshot.positions.path,
      icon: <FcPositiveDynamic size={32}/>
    },
    {
      key: 'portfolioValue',
      value: portfolioValue,
      label: 'Portfolio Value',
      colored: true,
      path: PATHS.portfolio.explorer.path,
      icon: <FcDoughnutChart size={32}/>
    },
    {
      key: 'totalPnlBase',
      value: totalPnlBase,
      label: 'Inception P&L',
      path: PATHS.portfolio.pnlExplorer.path,
      colored: true,
      icon: <FcAreaChart size={32}/>
    },
    {
      key: 'lifetimeTWR',
      value: lifetimeTWR,
      label: <div>Performance (TWR)</div>,
      colored: true,
      path: PATHS.portfolio.performance.path,
      icon: <FcLineChart size={32}/>,
      isPercentage: true
    },
    {
      key: 'esgScore',
      value: esgScore,
      label: <div>ESG Score</div>,
      colored: true,
      path: PATHS.portfolio.esg.path,
      icon: <img style={{ height: 32 }} src={EsgImage}/>,
      isEsg: true
    },
    ...(historicPnl
      ? [
        {
          key: 'historicPnl',
          value: historicPnl,
          label: 'Historic P&L',
          colored: true,
          // info: { text: DASHBOARD_EQUITY_HELP_TEXT.InfoInceptionPnL },
          icon: <FcAreaChart size={32}/>
        }
      ]
      : [])
  ];

  return (
    <div style={{ position: 'relative' }}>
      <div className={cn(s.spacer)}/>
      <div className={cn(s.subHeaderSurround)}>
        {stats.map((stat) => {

          let value: string | JSX.Element = "";

          if(typeof stat?.value !== "undefined" && (stat.value as any) !== "undefined"){
            value = stat.isEsg
              ? esgRenderer(stat.value)
              : stat.isPercentage
                ? percentFormat(stat.value)
                : abbreviate(stat.value, portfolioInfo.data?.currency.symbol)
          }

          return (
            <Link key={stat.key} to={stat.path?.replace(':portfolioId', portfolioInfo.data?.id!) || ''}>
              <div className={cn(s.statSurround)}>
                <div className={cn(s.statBg)}/>

                <div className={cn(s.statIcon)}>{stat.icon}</div>
                <div
                  className={cn(s.statValue)}
                  style={{
                    color: !stat.colored
                      ? undefined
                      : (stat.value ?? 0) > 0
                        ? 'var(--text-success-color)'
                        : 'var(--alert)'
                  }}
                >
                  {value}
                </div>
                <div className={cn(s.statLabel)}>{stat.label}</div>

              </div>
            </Link>
          )
        })}
      </div>
    </div>
  );
};
