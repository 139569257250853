import s from '../shared.module.scss';
import { BondFundamentalsReport, FundamentalsReport } from '../../../../../../../../../components/FundamentalsReport';
import React from 'react';
import { IPortfolioInfoState } from '../../../../../../../../../services/reducers/PortfolioReducers';
import useApi from '../../../../../../../../../services/hooks/useApi';
import { getApiUrl } from '../../../../../../../../../services/constants/endpoints';
import { Loader } from '../../../../../../../../../components';
import { Helmet } from 'react-helmet';
import { ASSETS } from '../../../../../../../../../services/constants/assetClasses';

interface IProps {
  portfolioInfo: IPortfolioInfoState;
  portfolioId: string;
  assetClass: string;
}

const Fundamentals = ({ portfolioId, portfolioInfo, assetClass }: IProps) => {
  const fundamentalsReport = useApi(
    getApiUrl(assetClass === ASSETS.fixedIncome ? 'portfolio.bondFundamentals' : 'portfolio.fundamentals'),
    {
      method: 'GET',
      params: {
        portfolioId,
      },
    }
  );

  if (fundamentalsReport.isLoading) {
    return <Loader />;
  }

  // console.log(fundamentalsReport.data);

  return (
    <div style={{ marginBottom: 80 }}>
      <Helmet><title>illio - {assetClass === ASSETS.fixedIncome ? 'Fixed Income Details' : 'Equity Fundamentals'}</title></Helmet>

      <h4 className={s.headerTitleContaineer}>
        {assetClass === ASSETS.fixedIncome ? 'Fixed Income' : 'Equity'} fundamentals report
      </h4>
      <div className={s.equityContainer}>
        {assetClass === ASSETS.fixedIncome ? (
          <BondFundamentalsReport
            longs={fundamentalsReport.data?.longs}
            shorts={fundamentalsReport.data?.shorts}
            portfolioInfo={portfolioInfo}
            classId={assetClass}
          />
        ) : (
          <FundamentalsReport
            longs={fundamentalsReport.data?.longs}
            shorts={fundamentalsReport.data?.shorts}
            portfolioInfo={portfolioInfo}
            classId={assetClass}
          />
        )}
      </div>
    </div>
  );
};

export default Fundamentals;
