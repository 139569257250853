import { ReduxHelper } from '../utils/ReduxHelper';

import { getApiUrl } from '../constants/endpoints';

export class SectorActions {
  static readonly SECTOR_REQUEST = 'SECTOR_REQUEST';
  static readonly SECTOR_SUCCESS = 'SECTOR_SUCCESS';
  static readonly SECTOR_ERROR = 'SECTOR_ERROR';

  static fetchAllSector() {
    return ReduxHelper.createHttpRequestActionBundle<ISector[]>(
      {
        url: getApiUrl('sector.all'),
      },
      SectorActions.SECTOR_REQUEST,
      SectorActions.SECTOR_SUCCESS,
      SectorActions.SECTOR_ERROR
    );
  }
}
