import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import s from './UnlistedReview.module.css';

interface IProps {
  prices: IPrice[];
  income: IPrice[];
  values: any;
  groups: FormGroup[];
}

const hasValue = (value?: string | number | null) =>
  typeof value !== 'undefined' && value !== null && typeof value === 'string' ? value.length > 0 : true;

const getLabels = (groups: FormGroup[]) => {
  const labels: Record<string, string> = {};
  groups.forEach((group) => {
    group.items.forEach((item) => {
      labels[item.name ?? 'UNKNOWN'] = item.label;
    });
  });

  return labels;
};

const UnlistedReview: FunctionComponent<IProps> = ({ prices, income, values, groups }) => {
  const labels = getLabels(groups);

  return (
    <React.Fragment>
      <section className={cn(s.section)}>
        <h3>Instrument Header Info</h3>
        <div className={cn(s.gridSurround)}>
          <ul>
            {Object.keys(values).map(
              (key) =>
                values.hasOwnProperty(key) &&
                hasValue(values[key]) && (
                  <li key={key}>
                    <strong>{labels[key] ?? key}: </strong>
                    <span>{values[key]}</span>
                  </li>
                )
            )}
          </ul>
        </div>
      </section>

      <section className={cn(s.section)}>
        <h3>Prices</h3>
        <div className={cn(s.gridSurround)}>
          <ul>
            {prices
              .sort((a, b) => ((a.valuationDate ?? 0) > (b.valuationDate ?? 0) ? 1 : -1))
              .map((price) => (
                <li key={price.code}>
                  <strong>{new Date(price.valuationDate as string).toLocaleDateString()}: </strong>
                  <span>
                    {values.currency}
                    {price.price}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </section>

      {!!income.length && (
        <section className={cn(s.section)}>
          <h3>Income</h3>
          <div className={cn(s.gridSurround)}>
            <ul>
              {income
                .sort((a, b) => ((a.valuationDate ?? 0) > (b.valuationDate ?? 0) ? 1 : -1))
                .map((price) => (
                  <li key={price.code}>
                    <strong>{new Date(price.valuationDate as string).toLocaleDateString()}: </strong>
                    <span>
                      {values.currency}
                      {price.price}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default UnlistedReview;
