import React, { Fragment, FunctionComponent, InputHTMLAttributes } from 'react';
import { WrappedFieldProps } from 'redux-form';
import Input, { IInputComponentProps } from '../UIWidgets/Input/Input';

type IProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
  IInputComponentProps &
  WrappedFieldProps & { tradeTypeChanged?: string; editMode?: boolean; hint?: string };

const renderInput: FunctionComponent<IProps> = ({ input, meta, editMode, hint, disabled, ...props }: IProps) => {
  const haveError =
    props.tradeTypeChanged === 'true' || editMode ? meta.error && meta.pristine : meta.error && meta.touched;
  const haveWarn =
    props.tradeTypeChanged === 'true' || editMode ? meta.warning && meta.pristine : meta.warning && meta.touched;
  const ref = React.createRef<HTMLInputElement>();

  return (
    <Fragment>
      <Input
        ref={ref}
        autoComplete="off"
        error={haveError}
        warning={haveWarn}
        {...input}
        {...props}
        disabled={disabled}
      />
      {hint && <div>{hint}</div>}
      {haveError && <div className="input__error-text">{meta.error}</div>}
      {haveWarn && <div className="input__warning-text">{meta.warning}</div>}
    </Fragment>
  );
};

export default React.memo(renderInput);
