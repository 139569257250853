import { ReduxHelper } from '../utils/ReduxHelper';

import { ILoadingDataState } from '../../models/redux/ILoadingDataState';
import { UploadTemplateActions } from '../actions/UploadTemplateActions';

export interface IUploadTemplatesState extends ILoadingDataState {
  data: IUploadTemplate[];
}

const INITIAL_STATE: IUploadTemplatesState = ReduxHelper.createInitialState<IUploadTemplate[]>([]);

const uploadTemplatesReducer = ReduxHelper.createHttpRequestReducer<IUploadTemplatesState>(
  INITIAL_STATE,
  UploadTemplateActions.UPLOAD_TEMPLATE_LIST_REQUEST,
  UploadTemplateActions.UPLOAD_TEMPLATE_LIST_SUCCESS,
  UploadTemplateActions.UPLOAD_TEMPLATE_LIST_ERROR
);

export default uploadTemplatesReducer;
