import React, { Fragment } from 'react';
import s from './WorkGroupInfoBlock.module.scss';
import { formatPriceValue, formatPriceValueCurrency, getValueColor } from '../../../../PortfoliosManager/utils';

interface IProps {
  title: string;
  value: number;
  currencySymbol: string;
  image?: string;
}

class WorkGroupInfoBlock extends React.Component<IProps> {
  render() {
    const { image, title, value, currencySymbol } = this.props;
    return (
      <Fragment>
        <div className={s.infoBlock}>
          {image && (
            <div className={s.infoBlockImage}>
              <img height={20} src={image} alt={title} />
            </div>
          )}
          <div className={s.infoBlockContent}>
            <div className={s.infoBlockTitle}>{title}</div>
            <div style={{ color: getValueColor(value) }} className={s.infoBlockValue}>
              {formatPriceValueCurrency(value, currencySymbol)}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WorkGroupInfoBlock;
