import React from 'react';
import { useTypedSelector } from './useTypedSelector';
import { useDispatch } from 'react-redux';
import { DashboardActions } from '../actions';
import { getCustodianOptions } from '../selectors/snapshots';

export const useCustodiansFilter = () => {
  const dispatch = useDispatch();

  const { custodianOptions, userPortfolioCustodians, selectedCustodians } = useTypedSelector((state) => ({
    custodianOptions: getCustodianOptions(state),
    selectedCustodians: state.dashboard.selectedCustodians,
    userPortfolioCustodians: state.dashboard.userPortfolioCustodians,
  }));

  const updateCustodiansFilter = (filteredCustodians: Record<string, boolean>) => {
    dispatch(DashboardActions.setCustodiansFilter(filteredCustodians));
  };

  const changeSelectedCustodians = (filteredCustodians: Record<string, boolean>) =>
    dispatch(DashboardActions.setCustodiansFilter(filteredCustodians));

  const selectedCustodiansArray = React.useMemo(() => {
    const temp =
      Object.keys(selectedCustodians).length === 0 && selectedCustodians.constructor === Object
        ? custodianOptions.reduce(
            (options, option) => ({
              ...options,
              [option.id]: true,
            }),
            {}
          )
        : selectedCustodians;

    let custodiansStringArray = Object.entries(temp)
      .filter(([key, val]) => val)
      .map(([key]) => key);

    if (custodianOptions.length === custodiansStringArray.length) {
      custodiansStringArray = [];
    }

    return custodiansStringArray;
  }, [selectedCustodians, custodianOptions]);

  return {
    selectedCustodians,
    custodianOptions,
    userPortfolioCustodians,
    selectedCustodiansArray,
    changeSelectedCustodians,
    updateCustodiansFilter,
  };
};
