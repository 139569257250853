import React, { useCallback, useRef } from 'react';
import { TableCellProps } from 'rsuite';
import { Table } from '@iliotech/storybook';
import { TableWrapper } from '../../../../../../../components';
import { ASSET_COLORS, ASSET_LABEL_NAMES } from '../../../../../../../services/constants/assetClasses';
import { BRAND_PRIMARY_ACCENT, BRAND_WARN } from '../utils/Colors';
import { mayConcatErrorLabel, mayConcatErrorLabelAsset } from '../utils/mayConcatErrorLabel';
import info from '../../../../../../../static/images/ic-info.svg';
import { WhisperWrapSimple } from '../utils/WhisperWrap';

import s from './ScatterTable.module.scss';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { explorerDecimalNumberRenderer } from '../../../../PortfolioExplorer/utils/explorerCurrencyRenderer';

interface IOwnProps {
  scatterFlatData: IScatterTableItem[];
  indexOfAsset: Record<string, number>;
  toggleBenchmark: () => void;
  togglePortfolio: () => void;
  onAssetClick: (key: string) => void;
  isAssetSelected: (key: string) => boolean;
  onPositionClick: (key: string) => void;
  isPositionSelected: (key: string) => boolean;
  benchmarkSelected: boolean;
  portfolioSelected: boolean;
  selectedPositions: (ValidPosition | InValidPosition)[];
  width: number;
}

interface IScatterTableCellProps extends TableCellProps {
  rowData: IScatterTableItem;
}

export default function ScatterTable({
  width,
  indexOfAsset,
  scatterFlatData,
  selectedPositions,
  toggleBenchmark,
  togglePortfolio,
  onAssetClick,
  isAssetSelected,
  onPositionClick,
  isPositionSelected,
  benchmarkSelected,
  portfolioSelected,
}: IOwnProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const renderName = (rowData: IScatterTableItem) => {
    const style = {
      marginRight: 4,
    };
    switch (rowData.type) {
      case 'PORTFOLIO':
        return (
          <div onClick={togglePortfolio} className={s.tableRow}>
            <div
              style={{
                height: 12,
                width: 12,
                borderRadius: 6,
                backgroundColor: portfolioSelected ? BRAND_PRIMARY_ACCENT : 'transparent',
                ...style,
              }}
            />
            <span>{rowData.name}</span>
          </div>
        );
      case 'BENCHMARK':
        return (
          <div onClick={toggleBenchmark} className={s.tableRow}>
            <div
              style={{
                height: 8,
                width: 8,
                backgroundColor: benchmarkSelected ? BRAND_WARN : 'transparent',
                transform: 'rotate(45deg)',
                margin: 'auto',
                marginRight: 5.5,
                marginLeft: 2,
              }}
            />
            <span>{rowData.name}</span>
          </div>
        );
      case 'ASSETCLASS':
        return (
          <div onClick={() => onAssetClick(rowData.assetClass!)} className={s.tableRow}>
            <div
              style={{
                height: 12,
                width: 12,
                borderRadius: 6,
                backgroundColor: isAssetSelected(rowData.assetClass!)
                  ? ASSET_COLORS[rowData.assetClass!]
                  : 'transparent',
                ...style,
              }}
            />
            <span>{mayConcatErrorLabelAsset(ASSET_LABEL_NAMES[rowData.assetClass!], rowData.errorReason)}</span>
          </div>
        );
      case 'POSITION':
        const color = ASSET_COLORS[rowData.assetClass!];
        const isMoreThanYear = rowData.activeFor && rowData.activeFor > 12;
        return (
          <div onClick={() => onPositionClick(rowData.name)} className={s.tableRow}>
            <WhisperWrapSimple
              containerRef={containerRef}
              text={mayConcatErrorLabel((rowData as unknown) as InValidPosition)}
            >
              <div
                style={{
                  height: 12,
                  width: 12,
                  textAlign: 'center',
                  position: 'relative',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: isPositionSelected(rowData.name)
                    ? isMoreThanYear
                      ? 'transparent'
                      : color
                    : 'transparent',
                  backgroundColor: isPositionSelected(rowData.name) && isMoreThanYear ? color : 'transparent',
                  ...style,
                }}
              >
                {isPositionSelected(rowData.name) && (
                  <span
                    style={{
                      color: isMoreThanYear ? 'white' : color,
                      fontSize: 10,
                      position: 'absolute',
                      right: isMoreThanYear ? 3 : 2.5,
                      top: isMoreThanYear ? -0.5 : -1.5,
                    }}
                  >
                    {indexOfAsset[rowData.name]}
                  </span>
                )}
              </div>
            </WhisperWrapSimple>
            <span>{mayConcatErrorLabel((rowData as unknown) as InValidPosition)}</span>
          </div>
        );
      default:
        return (
          <div className={s.tableRow}>
            <div
              style={{
                height: 9,
                width: 9,
                marginLeft: 2,
                backgroundColor: BRAND_WARN,
                transform: 'rotate(45deg)',
              }}
            />
            <span>{rowData.name}</span>
          </div>
        );
    }
  };

  const nameCellRenderer = (rowData: any, dataKey: string) => {
    return <span style={{ overflow: 'visible' }}>{renderName(rowData)}</span>;
  };

  const renderFooter = () => {
    return (
      <div style={{ position: 'absolute', marginTop: 10, alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 14, width: 14, margin: 2 }} src={info} />
        <span style={{ marginLeft: 4 }}>Click on the symbols to include/exclude the data</span>
      </div>
    );
  };

  const SCATTER_COLUMNS: ColumnProps[] = [
    {
      label: '',
      dataKey: 'name',
      flexGrow: 3,
      width: 200,
      cellRenderer: nameCellRenderer,
      align: 'right',
    },
    {
      label: 'Return',
      dataKey: 'periodReturn',
      flexGrow: 1,
      width: 50,
      cellRenderer: explorerDecimalNumberRenderer,
      align: 'right',
    },
    {
      label: 'Volatility',
      dataKey: 'periodVolatility',
      flexGrow: 1,
      width: 50,
      cellRenderer: explorerDecimalNumberRenderer,
      align: 'right',
    },
    {
      label: 'Market val (base)',
      dataKey: 'totalMarketValueBase',
      flexGrow: 2,
      width: 80,
      cellRenderer: explorerDecimalNumberRenderer,
      align: 'right',
    },
  ];

  return (
    <div ref={containerRef} style={{ width, margin: 20 }}>
      <Table
        columns={SCATTER_COLUMNS}
        data={scatterFlatData}
        totalCount={9}
        headerHeight={24}
        rowHeight={24}
        autoHeight={true}
      />
      {renderFooter()}
    </div>
  );
}
