import React, { Fragment, FunctionComponent, PropsWithChildren } from 'react';

import { Loader } from '../../../../../../../../components/UIWidgets/Loader';
import { PortfolioHeader } from '../../../../../../components/PortfolioHeader';
import NoData from '../NoData/NoData';

interface IProps {
  title: string;
  allLoaded: boolean;
  noData: boolean;
  message?: string;
}

const AssetReportWrapper: FunctionComponent<PropsWithChildren<IProps>> = ({
  title,
  allLoaded,
  noData,
  children,
  message,
}) => {
  if (!allLoaded) {
    return <Loader />;
  }

  if (!noData) {
    return (
      <div>
        <PortfolioHeader title={title} />
        <NoData message={message} />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default React.memo(AssetReportWrapper);
