import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import s from './StageIndicator.module.css';

type IStageChangeHandler = ({ toStage, fromStage }: { toStage: number; fromStage: number }) => void;

interface IProps {
  currentStage?: number;
  stages: Array<{ name: string }>;
  onStageChange?: IStageChangeHandler;
  onPressNext?: IStageChangeHandler;
  onPressPrevious?: IStageChangeHandler;
  disableNext?: boolean;
  className?: string | undefined;
}

const StageIndicator: FunctionComponent<IProps> = ({
  stages,
  currentStage = 0,
  onPressNext,
  onPressPrevious,
  onStageChange,
  disableNext = false,
  className,
}) => {
  const guard = (f?: any, ...args: any) => () => (typeof f === 'function' ? f(...args) : null);

  const moveToStage = (toStage: number) => () => {
    if (typeof onStageChange === 'function') {
      onStageChange({ fromStage: currentStage, toStage });
    }
  };

  const renderBreadcrumbs = () => (
    <nav style={{ display: 'flex' }}>
      {stages.map((stage, i) => (
        <div
          key={`${stage.name}${i}`}
          className={cn(s.itemSurround, currentStage >= i ? s.highlighted : null)}
          onClick={moveToStage(i)}
        >
          {i > 0 && <div className={cn(s.line)} />}
          <div className={cn(s.circle)}>{i + 1}</div>
          <span className={cn(s.item)}>{stage.name}</span>
        </div>
      ))}
    </nav>
  );

  const renderNavButtons = () => (
    <div>
      {typeof onPressPrevious === 'function' && currentStage > 0 && (
        <button
          onClick={guard(onPressPrevious, { fromStage: currentStage, toStage: currentStage - 1 })}
          className={cn(s.navButton)}
          children={'Previous'}
        />
      )}
      {typeof onPressNext === 'function' && (
        <button
          disabled={disableNext}
          onClick={
            currentStage < stages.length - 1
              ? guard(onPressNext, { fromStage: currentStage, toStage: currentStage + 1 })
              : undefined
          }
          className={cn(s.navButton, currentStage > stages.length - 2 || disableNext ? s.disabled : null)}
          children={'Next'}
        />
      )}
    </div>
  );

  return (
    <div className={cn(s.surround, className)}>
      {renderBreadcrumbs()}
      {renderNavButtons()}
    </div>
  );
};

export default StageIndicator;
