import React, { Fragment, PureComponent } from 'react';

import {
  PerformanceChartWidgetContainer,
  ProfitAttributionWidgetContainer,
  Widget,
  WidgetsBlock,
  PastNextChart,
  TopListWidgetsContainer,
  InfoBlock,
  AllocationsWidgetContainer,
} from '../../../../../../components';

import { PortfolioHeader } from '../../../../../../components/PortfolioHeader';
import { RepaymentsChart } from './components';
import { MaturityChartContainer } from './components/MaturityChart';

import { FormatHelper, VALUE_TYPE } from '../../../../../../../../services/utils/FormatHelper';
import { TOP_LIST_ORDER } from '../../../../../../../../services/constants/constants';

import { IOptionType } from '../../../../../../../../components/UIWidgets/Select/Select';

import calc from '../../../../../../../../static/images/dashboard/calc.svg';
import future from '../../../../../../../../static/images/dashboard/future.svg';
import past from '../../../../../../../../static/images/dashboard/past.svg';

import s from '../../../PortfolioDashboard/Dashboard.module.scss';
import { ICurrencyFormatter } from '../../../../../../../../services/selectors/portfolio';

const orderOptions: Array<IOptionType<TOP_LIST_ORDER>> = [
  { value: TOP_LIST_ORDER.ISSUER, label: 'By issuer' },
  { value: TOP_LIST_ORDER.SECURITY, label: 'By security' },
];

interface IProps {
  coupons: IBarData[];
  repayments: IBarData | null;
  dashboardInfo: IDashboardAssetClass;
  portfolioCurrencyFormatter: ICurrencyFormatter;
}

class Credit extends PureComponent<IProps> {
  render() {
    const { coupons, dashboardInfo, repayments, portfolioCurrencyFormatter } = this.props;

    const total = portfolioCurrencyFormatter(dashboardInfo.total.value);
    const prev = portfolioCurrencyFormatter(dashboardInfo.past.value);
    const next = portfolioCurrencyFormatter(dashboardInfo.next.value);

    const prevDiff = dashboardInfo.past.diff * 100;
    const nextDiff = dashboardInfo.next.diff * 100;

    return (
      <Fragment>
        <PortfolioHeader title="Credit" />
        <WidgetsBlock className={s.assetsDashboardGrid}>
          <Widget full={true}>
            <div className={s.dashboardInfo}>
              <div className={s.dashboardInfoItem}>
                <InfoBlock image={calc} title="Total credit value" value={total} />
              </div>
              <div className={s.dashboardInfoItem}>
                <InfoBlock image={past} title="Coupons, past 12 months" value={prev} diff={prevDiff} />
              </div>
              <div className={s.dashboardInfoItem}>
                <InfoBlock image={future} title="Coupons, next 12 months" value={next} diff={nextDiff} />
              </div>
            </div>
          </Widget>

          <PerformanceChartWidgetContainer />
          <ProfitAttributionWidgetContainer />

          <TopListWidgetsContainer orderList={orderOptions} />
          <Widget title="Coupons" colNum={8}>
            <PastNextChart chartData={coupons} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
          </Widget>

          <MaturityChartContainer />

          <Widget title="Principal repayments">
            {repayments && (
              <RepaymentsChart chartData={repayments} portfolioCurrencyFormatter={portfolioCurrencyFormatter} />
            )}
          </Widget>

          <AllocationsWidgetContainer title="Credit Breakdown" />
        </WidgetsBlock>
      </Fragment>
    );
  }
}

export default Credit;
