import df from 'dateformat';
const DATE_FORMAT_STRING = 'yyyy-mm-dd';

export const addDays = (days: number, d: Date = new Date()) => {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate() + days);
};

export const subtractDays = (days: number, d?: Date) => addDays(-days, d);

export const getLastMonday = (d: Date = new Date()) => {
  return subtractDays(d.getDay(), d);
};

export const dateAsString = (date: Date | string | undefined) => df(date, DATE_FORMAT_STRING);

export const sameDay = (a: Date, b: Date) => {
  const same = a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
};

export const makeDate = (d?: Date | string) => new Date(String(d));

export const makeDateString = (d?: Date | string) => dateAsString(makeDate(d));

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
export const getMonthName = (monthAsNo: string | number) => {
  return MONTHS[parseInt(String(monthAsNo), 10) - 1];
};
