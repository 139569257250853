import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import s from './CurrencyDropdown.module.scss';
import { useSelect } from 'downshift';
import cn from 'classnames';

export interface IProps {
  items: ICurrencyShort[];
  onValueSelected: (item: ICurrencyShort) => void;
  currency: ICurrencyShort;
}

const CurrencyDropDown = ({ currency, items, onValueSelected }: IProps) => {
  const [selectedItem, setSelectedItem] = useState<ICurrencyShort>(currency);
  const onSelectedItemChange = (item: any) => {
    setSelectedItem(item.selectedItem);
    onValueSelected(item.selectedItem);
  };

  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange,
  });

  return (
    <div className={s.comboContainer}>
      <button style={{ display: 'inline' }} type="button" {...getToggleButtonProps()}>
        <span>{selectedItem.name}</span>
        <span style={{ fontSize: 7, marginLeft: 2 }}> &#x25BC;</span>
      </button>
      <div className={s.comboItemSurround} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <li
              {...getItemProps({
                key: item.name,
                index,
                className: cn(s.comboItem),
                item,
                style: {
                  backgroundColor: highlightedIndex === index || selectedItem === item ? 'var(--dark-blue)' : '',
                  fontWeight: selectedItem === item ? 'bold' : 'normal',
                },
              })}
            >
              {item.name}
            </li>
          ))}
      </div>
    </div>
  );
};

export default CurrencyDropDown;
