import React, { Fragment } from 'react';
import { NestedSelect } from '../NestedSelect';
import {
  IProps as INestedSelectProps,
  ISelectHandlerProps,
  ISelectHandlerCallbackOptions,
} from '../NestedSelect/NestedSelect';
import { blur, WrappedFieldProps } from 'redux-form';
import { NestedSet } from '../NestedList/NestedSet';

export type IFieldISelectHandlerCallbackOptions = ISelectHandlerCallbackOptions & {
  selectedSet: NestedSet<any> | null;
};

type IProps = WrappedFieldProps &
  INestedSelectProps & {
    textSelector: (nestedSet: NestedSet<any> | null) => string;
    selectSetHandler?: (props: ISelectHandlerProps) => IFieldISelectHandlerCallbackOptions;
  };

function renderNestedSelect({
  meta,
  input: { onChange, value, onFocus, ...inputProps },
  textSelector,
  nestedSet,
  selectSetHandler,
}: IProps) {
  const _value = value || null;
  const haveError = meta.error && meta.touched;

  const onSelectDefault = (props: ISelectHandlerProps): ISelectHandlerCallbackOptions => {
    if (!props.indexes) {
      return {
        isVisible: undefined,
      };
    }
    const selectedSet = nestedSet.getByIndexes(props.indexes);
    if (!selectedSet) {
      console.error('BL error');
      return {
        isVisible: undefined,
      };
    }
    onChange(selectedSet);
    return {
      isVisible: false,
    };
  };

  const onSelectWrapper = (props: ISelectHandlerProps): ISelectHandlerCallbackOptions => {
    if (!selectSetHandler) {
      throw new Error('Can not use onSelectWrapper without onSelectData argument');
    }
    const { isVisible, selectedSet } = selectSetHandler({
      indexes: props.indexes,
      nestedSet,
    });

    onChange(selectedSet);

    return {
      isVisible,
    };
  };

  const onBlur = () => {
    setTimeout(() => {
      if (!_value) {
        meta.dispatch(blur(meta.form, inputProps.name, undefined, true));
      }
    }, 100);
  };

  return (
    <>
      <NestedSelect
        selectHandler={selectSetHandler ? onSelectWrapper : onSelectDefault}
        nestedSet={nestedSet}
        text={textSelector(_value)}
        onBlur={onBlur}
        onFocus={onFocus}
        haveError={haveError}
      />
      {haveError && <div className="input__error-text">{meta.error}</div>}
    </>
  );
}

export default renderNestedSelect;
