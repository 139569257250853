import React, { FC } from 'react';
import { IAssetEntryComponentProps } from '../types/IAssetEntryComponentProps';
import { IRootState } from '../../../../../../../../services/store';
import SubscriptionEntry, { ISubscriptionFormValues } from './SubscriptionEntry';

type Props = IAssetEntryComponentProps<ISubscriptionFormValues>;
type UnconfirmedTrade = IRootState['snapshot']['snapshotEdit']['editedUnconfirmedTrade'];

const SubscriptionEditContainer: FC<Props> = ({ editedUnconfirmedTrade, ...props }) => {
  return (
    <SubscriptionEntry editMode={true} {...props} initialValues={getInitialValuesFromTrade(editedUnconfirmedTrade)} />
  );
};

const getInitialValuesFromTrade = (t?: UnconfirmedTrade) => {
  if (typeof t === 'undefined') {
    return {};
  }
  const { instrument, custodian, tradeType, quantity, fxRate, operation, currencyDetails, key } = t!.trade;

  const currencyName = currencyDetails?.name ?? '';

  const values: Partial<ISubscriptionFormValues> = {
    ...t.trade,
    instrument,
    currency: {
      id: currencyName,
      value: currencyName,
      name: currencyName,
    },
    custodian,
    key,
    operation: operation as 'ADD' | 'WITHDRAW',
    amount: quantity,
    fxRate,
    tradeType,
  };

  // console.log(values);
  // console.log(operation);

  return values;
};

export default SubscriptionEditContainer;
