import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { ColorsHelper } from '../../services/utils/ColorsHelper';
import s from './UserAvatar.module.css';

interface IProps {
  user: any;
  size?: number;
  userImg?: string;
  activeColor?: boolean;
  overrideColor?: string;
}

const UserAvatar: FunctionComponent<IProps> = ({
  user,
  overrideColor,
  size = 32,
  activeColor = true,
  ...props
}: IProps) => {
  const firstName = user ? user.firstName : ' ';
  const lastName = user ? user.lastName : ' ';
  const email = user ? user.email : ' ';
  return props.userImg ? (
    <img className={cn(s.avatar)} style={{ height: size || 'initial', width: size || 'initial' }} src={props.userImg} />
  ) : (
    <div
      className={s.textAvatarContainer}
      style={{
        height: size || 'initial',
        width: size || 'initial',
        backgroundColor: overrideColor
          ? overrideColor
          : activeColor
          ? ColorsHelper.stringToColor(email + lastName + firstName)
          : 'var(--blue-grey)',
      }}
    >
      <p style={{ fontSize: size ? size / 2 : 40 }}>
        {getFirstChar(firstName)}
        {getFirstChar(lastName)}
      </p>
    </div>
  );
};

const getFirstChar = (name?: string) => (typeof name === 'string' ? name[0] || ' ' : ' ');

export default React.memo(UserAvatar);
