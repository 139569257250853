import moment from 'moment';
import React, { Fragment, FunctionComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { DatePicker } from '../UIWidgets/DatePicker';
import Input, { IInputComponentProps } from '../UIWidgets/Input/Input';

interface IProps extends WrappedFieldProps, IInputComponentProps {
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  filterDate?(date: Date): boolean;
  hideWeekends?: boolean;
}

const renderDatePicker: FunctionComponent<IProps> = (
  { meta, input, className, placeholder, disabled, minDate, maxDate, filterDate, hideWeekends },
  props: IProps
) => {
  const haveError = meta.error;
  const haveWarn = meta.warning;

  return (
    <Fragment>
      <DatePicker
        hideWeekends={hideWeekends}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        value={input.value ? new Date(moment(input.value).valueOf()) : null}
        onChange={(date) => input.onChange(moment(date.value!).format('YYYY-MM-DD'))}
        min={minDate!}
        max={maxDate!}
      />
      {haveError && <div className="input__error-text">{meta.error}</div>}
      {haveWarn && <div className="input__warning-text">{meta.warning}</div>}
    </Fragment>
  );
};

export default React.memo(renderDatePicker);
