import { IActionPayload } from '../../../models/redux/IActionPayload';
import { CustodianActions, DashboardActions, PortfolioActions } from '../../actions';

const INITIAL_SELECTED_CUSTODIANS_STATE: any = {};

const selectId = (prev: {}, c: { id: string }) => {
  return { ...prev, [c.id]: true };
};

const SelectedCustodiansReducers = (state = INITIAL_SELECTED_CUSTODIANS_STATE, action: IActionPayload) => {
  switch (action.type) {
    case CustodianActions.CUSTODIAN_SUCCESS: {
      return {}; // resetting filters when getting the list of custodians from the autocomplete when settings is triggered
    }
    case PortfolioActions.PORTFOLIO_INFO_SUCCESS: {
      if (!action.payload?.data?.additionalCustodians?.length) {
        return {};
      } else {
        return {
          ...state,
          ...action.payload?.data?.custodians.reduce(selectId, {}),
          ...action.payload?.data?.additionalCustodians.reduce(selectId, {}),
        };
      }
    }
    case DashboardActions.SET_CUSTODIANS_FILTER: {
      return action.payload;
    }
    case DashboardActions.RESET_CUSTODIANS_FILTER: {
      return {};
    }
    default:
      return state;
  }
};

export default SelectedCustodiansReducers;
